import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const toolboxInitialState = {
  isReactQueryDevToolsEnabled: false,
}

const toolboxSlice = createSlice({
  name: 'toolbox',
  initialState: toolboxInitialState,
  reducers: {
    toggleReactQueryDevTools(state) {
      state.isReactQueryDevToolsEnabled = !state.isReactQueryDevToolsEnabled
    },
  },
})

export const { toggleReactQueryDevTools } = toolboxSlice.actions

export default persistReducer(
  {
    key: 'toolbox',
    storage,
  },
  toolboxSlice.reducer
)
