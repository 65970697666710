import { createAsyncThunk } from '@reduxjs/toolkit'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import type { AppState } from '@mobi/betslip/Store'
import { BetSlipErrorResponse, BetSlipResponse } from '@mobi/betslip/types'

export const proposeAllBets = createAsyncThunk<
  BetSlipResponse[],
  void,
  { state: AppState; rejectValue: BetSlipErrorResponse }
>('betslip/proposeAllBets', async (_, thunkAPI) => {
  const hostHelpers = getHostHelpers()
  const response = await hostHelpers.postAllBetsToServer(
    'propose',
    thunkAPI.getState().betslip.bets
  )

  return response
})

export const confirmAllBets = createAsyncThunk<
  BetSlipResponse[],
  void,
  { state: AppState; rejectValue: BetSlipErrorResponse }
>('betslip/confirmAllBets', async (_, thunkAPI) => {
  const hostHelpers = getHostHelpers()
  const response = await hostHelpers.postAllBetsToServer(
    'confirm',
    thunkAPI.getState().betslip.bets
  )

  return response
})

export const refreshAllBets = createAsyncThunk<
  BetSlipResponse[],
  void,
  { state: AppState; rejectValue: BetSlipErrorResponse }
>('betslip/refreshAllBets', async (_, thunkAPI) => {
  const hostHelpers = getHostHelpers()
  const betsState = thunkAPI.getState().betslip.bets

  if (betsState.items.length === 0) return []
  const response = await hostHelpers.postAllBetsToServer('refresh', betsState)

  return response
})
