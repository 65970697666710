import { IWinPlaceProcessor } from './IWinPlaceProcessor'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import WinPlaceToteProcessor from './WinPlaceToteProcessor'
import WinPlaceFobProcessor from './WinPlaceFobProcessor'
import EnhancedWinPlaceProcessor from './EnhancedWinPlaceProcessor'

export default class WinPlaceProcessorFactory {
  constructor(
    private selectionType: ButtonSelectionType,
    private useEnhancedBetslip: boolean
  ) {}

  processor(): IWinPlaceProcessor | null {
    if (this.useEnhancedBetslip) {
      if (this.selectionType === undefined) {
        return null
      }

      return new EnhancedWinPlaceProcessor(this.selectionType)
    }

    if (this.selectionType === ButtonSelectionType.Tote) {
      return new WinPlaceToteProcessor()
    }

    if (this.selectionType === ButtonSelectionType.Fob) {
      return new WinPlaceFobProcessor()
    }

    return null
  }
}
