import styled from '@emotion/styled'
import { Text } from '@core/Components/Text'
import { PillStyled } from '@core/Components/Pill'
import { tap } from '@mobi/utils'
import { hexColors } from '@mobi/settings'
import { GapX } from '@core/Components/Gap'

export const RaceContentTextStyled = tap(
  styled(Text)({
    lineHeight: 'inherit',
    margin: 0,
  }),
  component => {
    component.defaultProps = {
      as: 'p',
      size: 'sm',
    }
  }
)

export const RaceContentBadgeStyled = styled(PillStyled)(() => ({
  background: hexColors.black,
  color: hexColors.white,
  padding: '0.1rem 0.8rem',
  marginLeft: '0.5rem',
}))

export const RaceContentContainerStyled = styled(GapX)({
  display: 'flex',
})
