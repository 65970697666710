import { NextRace } from '@core/Data/Racing/nextRaces'
import type { RacePageDTO } from '@mobi/api-types'

const enum LocalConstants {
  WAGeoLocationCode = 'WA',
  RaceCode = 'Races',
}

export const isThoroughbredRaceInWA = (state?: string, code?: string): boolean =>
  Boolean(state === LocalConstants.WAGeoLocationCode && code === LocalConstants.RaceCode)

export const isThoroughbredRacePageInWA = (race?: RacePageDTO) =>
  !race
    ? null
    : isThoroughbredRaceInWA(
        race.MeetingInformation.GeoLocationCode,
        race.MeetingInformation.MeetingCodeText
      )

export const isThoroughbredNextRaceInWA = (race: NextRace) =>
  isThoroughbredRaceInWA(race.GeoLocationCode, race.RaceType)
