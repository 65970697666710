import { combineReducers } from '@reduxjs/toolkit'
import bets from './Bets'
import workflow from './Workflow'
import config from './Config'

/** IMPORTANT ensure reducer is correctly namespaced on root reducer ie. `reducer: { betslip }` */
export const betslip = combineReducers({ bets, workflow, config })

/** Redux state specific to betslip package @requires Host to use betslip namespace */
export type AppState = { betslip: ReturnType<typeof betslip> }
