import styled from '@emotion/styled'
import { hexColors, colors } from '@mobi/settings'
import { HOME_CSS_SHARED_VALUES } from '@core/Areas/Home/Home.styles'
import { fontFamiliesBold } from '@core/Settings'

export const HomeNavigationContainerStyled = styled('div')({
  padding: `0 ${HOME_CSS_SHARED_VALUES.spacingX}`,
  marginBottom: '1.5rem',

  a: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fontFamiliesBold.brand,
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
  },

  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  // Primary Nav
  '& > div:first-of-type': {
    paddingBottom: HOME_CSS_SHARED_VALUES.spacingY,

    a: {
      width: '24%',
      fontSize: '1.4rem',
      borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
      color: hexColors.white,
      background: colors.brand.primary,

      span: {
        display: 'block',
        transform: 'translateY(-0.5rem)',
      },
    },
  },

  // Secondary Nav
  '& > div:last-of-type': {
    a: {
      alignSelf: 'stretch',
      width: '15.5%',
      lineHeight: 1.1,
      color: colors.brand.primary,

      div: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '5rem',
        borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
        boxShadow: HOME_CSS_SHARED_VALUES.boxShadow,
        background: hexColors.white,
      },

      span: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '0.4rem',
        fontSize: '1.1rem',
        letterSpacing: '-0.1rem',
      },
    },
  },
})
