import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'

export interface PasswordState {
  new: string | null
  newBlurred: boolean
  confirm: string | null
  confirmBlurred: boolean
  validationError: string | false
  confirmValidationError: string | false
  isValidLength: boolean
  hasNumber: boolean
  hasSpecialCharacters: boolean
  hasUpperCase: boolean
  hasLowerCase: boolean
  invalidCharacters: string
  isPasswordVisible: boolean
  isConfirmPasswordVisible: boolean
  hasAttemptedCreatePassword: boolean
  isValidationSuccess: boolean
  isPasswordWithinFiftyChars: boolean
  hasConsecutiveRepeatCharacters: boolean
}

export const defaultPasswordState: PasswordState = {
  new: null,
  newBlurred: false,
  confirm: null,
  confirmBlurred: false,
  validationError: false,
  confirmValidationError: false,
  isValidLength: false,
  hasNumber: false,
  hasSpecialCharacters: false,
  hasUpperCase: false,
  hasLowerCase: false,
  invalidCharacters: '',
  isPasswordVisible: false,
  isConfirmPasswordVisible: false,
  hasAttemptedCreatePassword: false,
  isValidationSuccess: false,
  isPasswordWithinFiftyChars: false,
  hasConsecutiveRepeatCharacters: false,
}

export const UpdateNewPassword = createSignal<string>('UpdateNewPassword')
export const UpdateConfirmPassword = createSignal<string>('UpdateConfirmPassword')
export const NewPasswordOnBlur = createSignal('NewPasswordOnBlur')
export const ConfirmPasswordOnBlur = createSignal('ConfirmPasswordOnBlur')
export const ValidatePassword = createSignal('ValidatePassword')
export const CreatePassword = createSignal('CreatePassword')
export const IsPasswordVisible = createSignal('IsPasswordVisible')
export const IsConfirmPasswordVisible = createSignal('IsConfirmPasswordVisible')
export const Reset = createSignal('Reset')

export interface PasswordStateRecord extends TypedRecord<PasswordStateRecord>, PasswordState {}
export const PasswordStateFactory = makeTypedFactory<PasswordState, PasswordStateRecord>(
  defaultPasswordState
)

export function passwordDriver(
  state = PasswordStateFactory(),
  signal: Signal
): PasswordStateRecord {
  switch (signal.tag) {
    case UpdateNewPassword: {
      const newPassword = signal.data
      const newState = state.merge({
        new: newPassword,
        isValidLength: isPasswordValidLength(newPassword),
        hasNumber: hasNumber(newPassword),
        hasSpecialCharacters: hasSpecialCharacters(newPassword),
        hasUpperCase: hasUpperCase(newPassword),
        hasLowerCase: hasLowerCase(newPassword),
        invalidCharacters: getInvalidCharacters(newPassword),
        isPasswordWithinFiftyChars: isPasswordWithinFiftyChars(newPassword),
        hasConsecutiveRepeatCharacters: hasConsecutiveRepeatCharacters(newPassword),
      })

      if (
        newState.get('hasAttemptedCreatePassword') ||
        newState.get('newBlurred') ||
        !!newState.get('confirm')
      ) {
        ValidatePassword()
      }

      return newState
    }

    case UpdateConfirmPassword: {
      const confirm = signal.data

      if (state.get('hasAttemptedCreatePassword') || state.get('confirmBlurred')) {
        ValidatePassword()
      }

      return state.merge({
        confirm,
      })
    }

    case CreatePassword: {
      ValidatePassword()

      return state.merge({
        hasAttemptedCreatePassword: true,
      })
    }

    case NewPasswordOnBlur: {
      ValidatePassword()

      return state.merge({
        newBlurred: true,
      })
    }

    case ValidatePassword: {
      let newState = state

      if (
        (!!newState.get('new') && !!newState.get('confirm')) ||
        newState.get('hasAttemptedCreatePassword')
      ) {
        newState = newState.merge({
          confirmValidationError: getConfirmPasswordError(newState),
        })
      }

      newState = newState.merge({
        validationError: getPasswordErrorMessageFromState(newState),
      })

      return newState.merge({
        isValidationSuccess:
          !newState.get('validationError') &&
          !!newState.get('confirm') &&
          !newState.get('confirmValidationError'),
      })
    }

    case ConfirmPasswordOnBlur: {
      ValidatePassword()

      return state.merge({
        confirmBlurred: true,
      })
    }

    case IsPasswordVisible: {
      return state.merge({
        isPasswordVisible: !state.get('isPasswordVisible'),
      })
    }

    case IsConfirmPasswordVisible: {
      return state.merge({
        isConfirmPasswordVisible: !state.get('isConfirmPasswordVisible'),
      })
    }

    case Reset: {
      return state.merge({
        ...defaultPasswordState,
      })
    }

    default: {
      return state
    }
  }
}

export function isPasswordValid(password: string) {
  const isValidLength = isPasswordValidLength(password)
  const hasNumberOrSymbol = hasNumber(password) || hasSpecialCharacters(password)
  const hasUpperAndLowerCaseCharacters = hasUpperCase(password) && hasLowerCase(password)
  const hasInvailLetters = getInvalidCharacters(password)
  const isWithinFiftyChars = isPasswordWithinFiftyChars(password)
  const hasConsecutiveRepeatChars = hasConsecutiveRepeatCharacters(password)
  return (
    isValidLength &&
    hasNumberOrSymbol &&
    hasUpperAndLowerCaseCharacters &&
    !hasInvailLetters &&
    isWithinFiftyChars &&
    !hasConsecutiveRepeatChars
  )
}

export function isPasswordValidLength(password: string) {
  return password && password.length >= 8
}

export function hasNumber(password: string) {
  return /[0-9]/.test(password)
}

export function hasSpecialCharacters(password: string) {
  // Special Characters refers to 35 ASCII speical characters not Unicode characters
  // eslint-disable-next-line no-useless-escape
  return /[ -/:-@\[-`{-~]/.test(password) // a group of 4 inclusive ranges: from space to / ,from : to @ ,from [ to `,from { to ~
}

export function hasUpperCase(password: string) {
  return /[A-Z]/.test(password)
}

export function hasLowerCase(password: string) {
  return /[a-z]/.test(password)
}

export function getInvalidCharacters(password: string) {
  return password.replace(/[\x20-\x7E]/g, '') // remove valid ASCII characters from the string and retain Invalid Characters
}

export function isPasswordWithinFiftyChars(password: string) {
  if (!password) {
    return true
  }

  return password.length <= 50
}

export function hasConsecutiveRepeatCharacters(password: string) {
  return /(.)\1\1/.test(password)
}

export function getPasswordErrorMessageFromState(state: PasswordStateRecord): string | false {
  const isValidLength = state.get('isValidLength')
  const hasNumberOrSymbol = state.get('hasNumber') || state.get('hasSpecialCharacters')
  const hasUpperAndLowerCaseCharacters = state.get('hasUpperCase') && state.get('hasLowerCase')
  const invalidCharacters = state.get('invalidCharacters')
  const isWithinFiftyChars = state.get('isPasswordWithinFiftyChars')
  const hasConsecutiveRepeatChars = state.get('hasConsecutiveRepeatCharacters')
  if (
    isValidLength &&
    hasNumberOrSymbol &&
    hasUpperAndLowerCaseCharacters &&
    isWithinFiftyChars &&
    !hasConsecutiveRepeatChars
  ) {
    if (invalidCharacters) {
      return `Your password should not have invalid character(s) '${invalidCharacters}'.`
    }
    return false
  }
  const errorMessage: string[] = []
  if (!isValidLength) {
    errorMessage.push('have 8 or more characters')
  }
  if (!hasNumberOrSymbol) {
    errorMessage.push('have at least one number or special character')
  }
  if (!hasUpperAndLowerCaseCharacters) {
    errorMessage.push('have both upper and lowercase characters')
  }
  if (!isWithinFiftyChars) {
    errorMessage.push('have less than 50 characters')
  }
  if (hasConsecutiveRepeatChars) {
    errorMessage.push(`not contain three or more consecutive repeating characters. I.e. 'ddd'`)
  }
  if (invalidCharacters) {
    errorMessage.push(`and not contain invalid character(s) '${invalidCharacters}'`)
  }
  return `Your password should ${errorMessage.join(', ')}.`
}

export function getConfirmPasswordError(state: PasswordStateRecord): string | false {
  const newPassword = state.get('new')
  const confirmPassword = state.get('confirm')

  if (newPassword !== confirmPassword) {
    return 'The passwords you entered do not match.'
  } else {
    return false
  }
}

export const state$ = attachDriver<PasswordStateRecord>({
  path: 'password',
  driver: passwordDriver,
})
