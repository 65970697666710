import { colors } from '@core/Settings'
import styled from '@emotion/styled'

export const StructuredLocationTimeStyled = styled('div')({
  fontSize: '1rem',
  display: 'flex',
})

export const StructuredRaceNumberStyled = styled('span')({
  paddingLeft: '0.25rem',
  verticalAlign: 'middle',
})

export const StructuredSearchResultIconStyled = styled('span')({
  marginLeft: '0',
})

export const StructuredSearchLocationNameContainerStyled = styled('div')({
  marginLeft: 'auto',
  color: colors.structuredSearch.resultDetails.text,
})

export const StructuredSearchResultASTTimerSTyled = styled('div')({
  'div > strong': {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.structuredSearch.resultDetails.text,
  },
})

export const StructuredSearchLocationNameStyled = styled('span')({
  display: 'inline-block',
  verticalAlign: 'middle',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '7.2rem',
})

export const StructuredSearchResultPricesStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
