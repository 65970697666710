import { SubApplicationHandler, RouteResult } from '../Navigation/SubAppRouter'

const getMainContainerEl = (): HTMLElement | null => document.getElementById('main-container')
const getReactDivEl = (): HTMLElement | null => document.getElementById('js-react-app-routes')

export class ReactRouterHandler implements SubApplicationHandler {
  async enter(): Promise<RouteResult> {
    const mainContainerEl = getMainContainerEl()
    if (mainContainerEl) {
      mainContainerEl.style.display = 'none'
      mainContainerEl.innerHTML = ''
    }

    const reactDivEl = getReactDivEl()
    reactDivEl && (reactDivEl.style.display = 'block')

    document.body.scrollIntoView()

    return RouteResult.IsMatchSuccess
  }

  leave(): void {
    // Clear body id as nav away does not guarantee it is cleared
    document.body.id = ''

    const mainContainerEl = getMainContainerEl()
    mainContainerEl && (mainContainerEl.style.display = 'block')

    const reactDivEl = getReactDivEl()
    reactDivEl && (reactDivEl.style.display = 'none')
  }
}
