import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useFeature } from '@core/Utils/hooks'
import { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { getLegStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { canAllowReBetForTicket } from './helpers/canAllowReBetForTicket'
import {
  createLoadBetDetails,
  loadBet,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/helpers'
import { TicketFooterButtonStyled as FooterButton } from '../../Footer.styles'
import { trackRebet } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const ReBetButton: React.FC<{ ticket: Ticket; activeTab: Tabs }> = ({
  ticket,
  activeTab,
}) => {
  const isReBetFeatureActive = useFeature('REBET_PENDING_TICKET')

  if (!isReBetFeatureActive || !canAllowReBetForTicket(activeTab, ticket)) return null

  return (
    <FooterButton
      data-testid='rebet-button'
      color='link'
      onClick={() => handleReBetClick(ticket, activeTab)}
    >
      <Icon type='redo' size='1.5rem' /> Re-Bet
    </FooterButton>
  )
}

function handleReBetClick(ticket: Ticket, activeTab: Tabs) {
  const pendingLegs = ticket.legs.filter(leg => getLegStatus(leg.result) === 'Pending')

  loadBet(
    createLoadBetDetails({
      ...ticket,
      legs: pendingLegs,
    }),
    'rebet'
  )

  trackRebet({
    source: activeTab,
    resultedCount: ticket.legs.length - pendingLegs.length,
    pendingCount: pendingLegs.length,
  })
}
