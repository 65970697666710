import React from 'react'
import { Observable } from 'rx'
import type { BettingType } from '@mobi/betslip/types'
import { observeImmutable } from '../../../../Components/HOCs'
import { Campaign, state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as investmentState$, InvestmentState } from '../BetInvestment/betInvestmentDriver'
import { state$ as quickbetState$ } from '../../driver'
import { state$ as superPickState$ } from '@core/Components/SuperPick/driver'
import { BonusBetListContainerStyled, BonusBetListItemContainerStyled } from './BonusBetList.styles'
import { BonusBetListItem } from './BonusBetListItem'
import { Swiper } from '@core/Components/Swiper'

const state$ = Observable.combineLatest(
  userAccountState$,
  quickbetState$,
  investmentState$,
  superPickState$,
  (userAccountState, quickbetState, investmentRecord, superPickState): BonusBetListStateProps => {
    const investmentState = investmentRecord.toJS() as InvestmentState

    return {
      isBusy: quickbetState.isBusy,
      canBet: quickbetState.canBet,
      campaigns: userAccountState.activeCampaigns
        ?.filter(x => x.rewardType == 'BonusBetReward')
        .toArray(),
      selectedBonusBetCampaignId: investmentState.bonusBet?.campaignId,
      hasSelectedSuperPick: !!superPickState.selectedSuperPickSeq,
      isEachWay: quickbetState.isEachWay,
    }
  }
)

type BonusBetListProps = BonusBetListStateProps & BonusBetListExternalProps
export type BonusBetListStateProps = {
  isBusy: boolean
  canBet: boolean
  campaigns: Campaign[] | undefined
  selectedBonusBetCampaignId: number | undefined
  hasSelectedSuperPick: boolean
  isEachWay: boolean
}

export type BonusBetListExternalProps = {
  superPickEnabled?: boolean
  setBonusBet: (bonusBet: { campaignId: number; value: number } | null) => void
  bettingType?: BettingType | null
}

const CHUNK_SIZE_LARGE = 6
const CHUNK_SIZE_SMALL = 3

export const BonusBetListComponent = ({
  isBusy,
  campaigns,
  superPickEnabled,
  selectedBonusBetCampaignId,
  hasSelectedSuperPick,
  isEachWay,
  setBonusBet,
  bettingType,
}: BonusBetListProps): JSX.Element | null => {
  const [selectedBonusBets, setSelectedBonusBets] = React.useState<number[]>(
    selectedBonusBetCampaignId ? [selectedBonusBetCampaignId] : []
  )
  const [paginatedBonusBets, setPaginatedBonusBets] = React.useState<Campaign[][] | null>()

  React.useLayoutEffect(() => {
    setPaginatedBonusBets(
      paginate(campaigns, superPickEnabled ? CHUNK_SIZE_LARGE : CHUNK_SIZE_SMALL)
    )
  }, [campaigns, superPickEnabled])

  React.useEffect(() => {
    if (!selectedBonusBetCampaignId) {
      setSelectedBonusBets([])
    } else {
      setSelectedBonusBets([selectedBonusBetCampaignId])
    }
  }, [selectedBonusBetCampaignId])

  React.useEffect(() => {
    if (!campaigns) return

    var campaignId = selectedBonusBets.length ? selectedBonusBets[0] : null
    const selectedCampaign = campaignId ? campaigns.find(x => x.id == campaignId) : null

    var bonusBet = selectedCampaign
      ? { campaignId: selectedCampaign.id, value: selectedCampaign.remainingAmount }
      : null

    if (bonusBet?.campaignId != selectedBonusBetCampaignId) {
      setBonusBet(bonusBet)
    }
  }, [campaigns, selectedBonusBets, selectedBonusBetCampaignId, setBonusBet])

  const updateButtonState = React.useCallback(
    (id: number): void => {
      var isBonusSelected = selectedBonusBets.find(x => x == id)
      var newSelectedBonusBets = []

      if (!isBonusSelected) {
        newSelectedBonusBets.push(id)
      }

      setSelectedBonusBets(newSelectedBonusBets)
    },
    [selectedBonusBets]
  )

  const getButtonState = React.useCallback(
    (id: number): boolean | undefined => {
      return !!selectedBonusBets.find(x => x === id)
    },
    [selectedBonusBets]
  )

  if (!paginatedBonusBets || !paginatedBonusBets.length) {
    return null
  }

  const canShowItem = (rewardType: string) => {
    if (rewardType !== 'BonusCashReward' && bettingType === 'tote-racing') {
      return false
    }
    return true
  }

  return (
    <BonusBetListContainerStyled>
      <Swiper usePagination={true}>
        {paginatedBonusBets.map(chunk => (
          <BonusBetListItemContainerStyled key={paginatedBonusBets.indexOf(chunk)}>
            {chunk.map(
              ({ id, expiry, remainingAmount, title, rewardType }) =>
                canShowItem(rewardType) && (
                  <BonusBetListItem
                    key={id}
                    id={id}
                    name={title}
                    expiry={expiry}
                    amount={remainingAmount}
                    selected={getButtonState(id)}
                    disabled={isBusy || hasSelectedSuperPick || isEachWay}
                    handleClick={() => updateButtonState(id)}
                  ></BonusBetListItem>
                )
            )}
          </BonusBetListItemContainerStyled>
        ))}
      </Swiper>
    </BonusBetListContainerStyled>
  )
}

function paginate(array: Campaign[] | undefined, size: number): Campaign[][] | null {
  if (!array) {
    return null
  }
  const chunks = []
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size)
    chunks.push(chunk)
  }
  return chunks
}

export const BonusBetList = observeImmutable<BonusBetListStateProps, BonusBetListExternalProps>(
  state$,
  ({ record: state, ...externalProps }) => {
    return state.canBet ? <BonusBetListComponent {...externalProps} {...state} /> : null
  }
)
