import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Button } from '@mobi/component-library/Common/Buttons'
import { ButtonBlockStyled, ButtonStyled } from '@mobi/component-library/Common/Buttons'

export const FavNumbersNavigationStyled = styled.ul({
  display: 'flex',
  justifyContent: 'center',
  height: '4rem',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  background: hexColors.nero,

  li: {
    margin: 0,

    a: {
      display: 'flex',
      alignItems: 'center',
      height: '4rem',
      padding: '0 1rem',
      color: hexColors.lightGrey,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textDecoration: 'none',
    },

    '&[data-js-favnum-active="true"] a': {
      backgroundColor: hexColors.charCoal,
      color: hexColors.white,
    },
  },
})

export const HeaderStyled = styled.h3({
  color: hexColors.white,
  backgroundColor: hexColors.nightRider,
  margin: 0,
})

export const NumberButtonsStyled = styled('div')({
  margin: '0 auto',
  maxWidth: '30em',
  clear: 'both',
})

export const NumberButtonStyled = styled(ButtonStyled)({
  display: 'inline-block',
  width: '3.5em',
  minHeight: '3.5em',
  margin: '0.25em',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.5em',
  verticalAlign: 'top',
  border: '1px solid #cecece',
})

export const PoolSelectionButtonStyled = styled(ButtonStyled)({
  width: '100%',
  marginTop: '1em',
  textAlign: 'left',
  textTransform: 'uppercase',
  fontSize: '1.4em',
  minHeight: '4rem',
})

export const PoolSelectionColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '1em',
  '& > button:nth-of-type(1)': {
    minHeight: '6em',
  },
})

export const PoolGroupHeader = styled('div')({
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '1.6em',
})

export const ShowAllPoolsButton = styled(ButtonBlockStyled)({
  width: '14rem',
})

export const FavouriteNumbersFlex = styled('div')({
  textAlign: 'center',
  display: 'flex',
})

export const FavouriteNumbersSection = styled('div')({
  padding: '1em',
  textAlign: 'center',
})

export const FavouriteNumbersPoolTotalStyled = styled('div')({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'normal',
})

export const FavouriteNumbersDataTable = styled('div')({
  padding: '1em',
})

export const FavouriteNumbersRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  textAlign: 'left',
  fontSize: '1.4em',
  marginBottom: '0.5em',
  borderBottom: '1px solid #eee',
  padding: '0.2em 0',
  '&:last-child': {
    border: 0,
    marginBottom: 0,
  },
})

export const FavouriteNumbersColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  flex: 1,
})

export const Filler = styled('div')({
  minWidth: '0.8em',
  float: 'left',
})

export const PotLuckNumbersStyled = styled('div')({
  margin: '0 auto',
  textAlign: 'center',
  maxWidth: '30em',
  padding: '1em',
})

export const PotLuckNumbersButtonStyled = styled(ButtonStyled)({
  minHeight: '3em',
  margin: '0.5em',
  width: '9.4em',
  fontSize: '1.4em',
})

export const PotLuckNumberButtonStyled = styled('div')({
  flexBasis: 'column',
  width: '20%',
})

export const PotLuckNumberCostStyled = styled('div')({
  flexBasis: 'column',
  width: '80%',
  textAlign: 'right',
  fontWeight: 'normal',
})

export const MyNumbersButtonStyled = styled(Button)({
  margin: '0 0.25em 0.5em',
  height: '4rem',
  float: 'right',
})

export const SaveNumbersButtonStyled = styled(MyNumbersButtonStyled)({
  float: 'left',
})
