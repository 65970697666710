import { reverseString } from '../string'

type AddressWithJoinedSuburb = {
  unitNumber?: string
  streetNumber: string
  streetName: string
  streetType: string
  suburb: string
}

type Address = {
  unitNumber?: string
  streetNumber: string
  streetName: string
  streetType: string
  suburb: string
  postcode: string
  state: string
}

export function createAddressMoniker({
  suburb,
  streetType,
  streetNumber,
  streetName,
  unitNumber,
}: AddressWithJoinedSuburb): string {
  const address = `${streetNumber} ${streetName} ${streetType}, ${suburb}`

  if (unitNumber) {
    return `${unitNumber}/${address}`
  }

  return address
}

export function extractFullSuburb(
  fullSuburb: string
): Pick<Address, 'suburb' | 'postcode' | 'state'> {
  const strippedFullSuburb = fullSuburb.replace(/\s+/g, ' ').trim()
  const [postcode, state, ...suburbParts] = reverseString(strippedFullSuburb).split(' ')

  return {
    postcode: reverseString(postcode),
    state: reverseString(state),
    suburb: suburbParts.map(reverseString).reverse().join(' ').trim(),
  }
}

/**
 * Split out the individual Suburb, State and Postcode components of the
 * address returned by the Equifax suburb lookup
 */
export function extractSuburbStatePostcode(
  suburbStateAndPostcode: string
): [suburb: string, state: string, postcode: string] {
  const parts = suburbStateAndPostcode
    .split(',')
    .filter(x => !!x)
    .map(x => x.trim())
  return [parts[0], parts[1], parts[2]]
}

export function createFullSuburb({
  suburb,
  postcode,
  state,
}: Pick<Address, 'suburb' | 'postcode' | 'state'>) {
  return `${suburb}, ${state}, ${postcode}`
}

export function formatAddress({
  unitNumber,
  streetNumber,
  streetName,
  streetType,
  suburbStateAndPostcode,
}: {
  unitNumber?: string
  streetNumber: string
  streetName: string
  streetType: string
  suburbStateAndPostcode: string
}): string {
  const address = `${streetNumber} ${streetName} ${streetType}, ${suburbStateAndPostcode}`

  if (unitNumber) {
    return `${unitNumber}/${address}`
  }

  return address
}
