import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const PillStyled = styled('span')({
  // CSS spec says when corner curves would overlap, they should be reduced, resulting in the desired "pill"
  // http://www.w3.org/TR/css-backgrounds-3/#corner-overlap
  borderRadius: '10rem',
  display: 'inline-block',
  backgroundColor: hexColors.gainsboro,
})
