import * as ko from 'knockout'
import { IRaceDisplayStateManagerViewModel } from './IRaceDisplayStateManagerViewModel'
import { IBettingStateManagerViewModel } from './IBettingStateManagerViewModel'
import container from '../../../IOC/inversify.config'
import bettingStateTemplate from './bettingstate.tpl.html'
import raceDisplayStateTemplate from './race.display.state.tpl.html'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'

export function register(): void {
  if (!ko.components.isRegistered('betting-state')) {
    ko.components.register('betting-state', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableRacePage
        }): IBettingStateManagerViewModel => {
          const viewModel = container.get<IBettingStateManagerViewModel>(
            'IBettingStateManagerViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template: bettingStateTemplate,
    })
  }

  if (!ko.components.isRegistered('race-display-state')) {
    ko.components.register('race-display-state', {
      viewModel: {
        createViewModel: (params: {
          meetingId: string
          raceNumber: number
          meetingDate: Date
          raceStatus: string
        }): IRaceDisplayStateManagerViewModel => {
          const viewModel = container.get<IRaceDisplayStateManagerViewModel>(
            'IRaceDisplayStateManagerViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template: raceDisplayStateTemplate,
    })
  }
}
