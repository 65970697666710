import * as ko from 'knockout'

export default class ObservableRaceReplay {
  constructor() {
    // @ts-expect-error Type issue
    this.hasVideo = ko.observable()
    // @ts-expect-error Type issue
    this.videoKey = ko.observable()
    // @ts-expect-error Type issue
    this.raceLocation = ko.observable()
    // @ts-expect-error Type issue
    this.date = ko.observable()
  }

  public merge(other: ObservableRaceReplay) {
    this.hasVideo(other.hasVideo())
    this.videoKey(other.videoKey())
    this.raceLocation(other.raceLocation())
    this.date(other.date())
  }

  public hasVideo: ko.Observable<boolean>
  public videoKey: ko.Observable<string>
  public raceLocation: ko.Observable<string>
  public date: ko.Observable<string>
}
