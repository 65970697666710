import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '@core/Store/hooks'
import { setSkyVideoPlayerStatus } from '@core/Areas/SkyVideoPlayer/Store'

export const useShrinkSkyOnLocationChange = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const currentLocationRef = React.useRef(location)

  React.useEffect(() => {
    if (location !== currentLocationRef.current) {
      dispatch(setSkyVideoPlayerStatus({ playerStatus: 'floating' }))
    }
  }, [dispatch, location])
}
