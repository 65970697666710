import { HashRoute, RouteStack } from '@core/State/Navigation'

const stackHandlers = new Map<HashRoute, HandlerOptions>()

/** Registers middleware for custom stack handling ie. allowing override of back navigation */
export async function registerStackHandler(key: HashRoute, config: HandlerOptions): Promise<void> {
  stackHandlers.set(key, config)
}

/** Creates new stack, allowing registered middleware to modify - for use ONLY in driver */
export function getNewStack(newRoute: HashRoute, currentStack: RouteStack): RouteStack {
  // Reset stack if home
  if (newRoute === '#') return ['#']

  // Allow top level to go back to home
  if (isNewRouteTopLevelRoute(newRoute)) return ['#', newRoute]

  // Remove last route when user navigates back
  if (newRoute === getBackUrlFromStack(currentStack) && currentStack.length > 1) {
    return currentStack.slice(0, -1) as RouteStack
  }

  // Give stack handler ability to create stack
  const stackHandlerKey = Array.from(stackHandlers.keys()).find(key => newRoute.startsWith(key))
  if (stackHandlerKey) {
    const newStack = stackHandlers.get(stackHandlerKey)?.handler(newRoute, currentStack)
    if (newStack) return newStack
  }

  // Add new route to stack
  return [...currentStack, newRoute]
}

// =======
// Helpers
// =======

export function getBackUrlFromStack(stack: RouteStack): HashRoute {
  return stack[stack.length - 2] || '#'
}

function isNewRouteTopLevelRoute(newRoute: HashRoute): boolean {
  return (
    Array.from(stackHandlers.values())
      .map(({ topLevelRoute }) => topLevelRoute)
      .includes(newRoute) || Array.from(stackHandlers.keys()).includes(newRoute)
  )
}

// =====
// Types
// =====

interface HandlerOptions {
  /** Middleware for creating new stack OR return false to use default behaviour */
  handler(newHashRoute: HashRoute, currentStack: RouteStack): RouteStack | false
  /** Required if root hash route is different from top level ie. #rootRoute/home */
  topLevelRoute?: `#${string}`
}
