export enum RacingCode {
  Races = 'Races',
  Dogs = 'Dogs',
  Trots = 'Trots',
}

export interface StructuredSearchTrack {
  id: string
  name: string
  location: string
  contestType: string
  hitCount: number
  isAustralian: boolean
  isDuplicate: boolean
}

export interface StructuredSearchAggregations {
  tracks: StructuredSearchTrack[]
}
export interface RawStructuredSearchResults {
  statistics: {
    count: number
    acceptorCount: number
    hitCount: number
  }
  results: StructuredSearchResult[]
  aggregations: StructuredSearchAggregations
}

export interface StructuredSearchResult {
  id: string
  metadata: Metadata
  acceptor: AcceptorDetail
}

interface Metadata {
  score?: number
}

export interface AcceptorDetail {
  race: RaceDetail
  name: string
  numberOrBox: number
  driverName: string
  trainerName: string
  riderName: string
  scratchedStatus?: string
  fixedOddsPrices?: Prices | null
}

export interface RaceDetail {
  fixture: FixtureDetail
  name: string
  advertisedStartTime: string
  number: number
  distance: number
  class: string
  status?: string
  isStartTimeRelevant: boolean
}

interface FixtureDetail {
  contestType: RacingCode
  date: string
  name: string
  id: string
}

interface Prices {
  isFavourite: boolean
  win: Price
  place: Price
}

interface Price {
  isValid: boolean
  dividend: number | null
  dividendChange: number | null
  isFirming: boolean | null
  calculationTime: Date | null
}
