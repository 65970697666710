import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { toTitleCase } from '@mobi/utils/string'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { getIconName } from '@core/Areas/Blackbook/helpers'

export const BlackbookMenuButton: React.FC<BlackbookParams> = ({
  starterName,
  starterType,
  entryType,
  source,
  isReady,
}) => {
  const { matchedRunnerInBlackbook, isLoading, clickHandler } = useAddToBlackbook({
    starterName,
    starterType,
    source,
  })

  const isRunnerInBlackbook = Boolean(matchedRunnerInBlackbook)

  const iconName = isRunnerInBlackbook ? 'tickCircleSolid' : getIconName(starterType)

  return (
    <MenuButtonStyled
      onClick={clickHandler}
      disabled={isLoading || !isReady}
      data-js-acc-act-blackbook-btn-highlight={isRunnerInBlackbook}
    >
      <div>
        {!isReady || isLoading ? (
          <SpinnerInlineStyled color='dark' size={1} />
        ) : (
          <Icon type={iconName} size='2rem' />
        )}
      </div>

      <span>
        {isRunnerInBlackbook ? `See ${entryType} in Blackbook` : `Add ${entryType} to Blackbook`}

        <span>{toTitleCase(starterName) || '...'}</span>
      </span>
    </MenuButtonStyled>
  )
}

// ======
// Styles
// ======

const MenuButtonStyled = styled.button({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  minHeight: '4rem',
  padding: 0,
  marginBottom: '0.5rem',
  fontSize: '1.2rem',
  background: hexColors.white,
  border: '0.1rem solid ' + hexColors.gainsboro,
  borderRadius: '0.5rem',

  '> div:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '3.5rem',

    svg: {
      color: hexColors.studio,
    },
  },

  '> span': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    paddingRight: '1rem',
    color: hexColors.black,

    span: {
      marginTop: '0.1rem',
      maxWidth: '20rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '1.1rem',
      color: hexColors.grey,
    },
  },

  '&[data-js-acc-act-blackbook-btn-highlight="true"]': {
    borderColor: hexColors.studio,
    color: hexColors.studio,
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
})

// =====
// Types
// =====

type BlackbookParams = Parameters<typeof useAddToBlackbook>[0] & {
  isReady: boolean
  entryType: 'Runner' | 'Jockey'
}
