import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ICheckBoxPipelineProcessor } from './ICheckBoxPipelineProcessor'
import { CheckBoxPipelineProcessor } from './CheckBoxPipelineProcessor'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import Guard from '../../../../AppUtils/Framework/Guard'
import { ISelectionContext } from './ISelectionContext'

export default class AllwaysCheckBoxPipelineProcessor extends CheckBoxPipelineProcessor {
  constructor(next: ICheckBoxPipelineProcessor) {
    super(next)
  }

  process(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    context: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): Array<IObservableStarter> {
    if (!context.isBoxed())
      return (this.next as ICheckBoxPipelineProcessor).process(
        current,
        all,
        context,
        selectionContext,
        count
      )

    Guard.notNull(current)
    Guard.notNull(all)
    Guard.notNull(context)

    const results: IObservableStarter[] = []
    this.maintainExistingSetOfStartersWithCurrent(current, all, results)

    if (this.currentStarterUnChecked(current)) {
      context.turnOffAllwaysField()
    }

    return results
  }

  private currentStarterUnChecked(current: IObservableStarter): boolean {
    const supplied = current.selection() as CheckBoxSelection
    return !supplied.selectedAt(0)
  }
}
