import React from 'react'
import { PoolDataTransferObject } from '@mobi/api-types'

export const PoolTotalsItem: React.FC<{ pool: PoolDataTransferObject }> = ({ pool }) => {
  return (
    <li className='l-faux-row fz-12'>
      <span className='l-faux-cell phalfem'>
        <span>{pool.Name}</span>

        {pool.RaceNumbers && pool.RaceNumbers.length > 0 && (
          <span className='block'>{`(${pool.RaceNumbers.join(', ')})`}</span>
        )}
      </span>

      <span className='l-faux-cell l-cell-xs-2 phalfem text-center'>
        {pool.PoolingIcon && pool.PoolingIcon.length > 0 && (
          <span className={`icon-pool${pool.PoolingIcon === 'INT' ? ' icon-pool-int' : ''}`}>
            {pool.PoolingIcon}
          </span>
        )}
      </span>

      <span className='l-faux-cell l-cell-xs-7 phalfem text-right'>
        {pool.HasJackpot && (
          <span className='alignmid' style={{ marginRight: '0.3em' }}>
            <span style={{ marginRight: '0.1em' }}>{pool.JackpotTotal}</span>
            <svg className='icon-inline aligntop fz-18'>
              <use xlinkHref='/images/tticons.svg#jackpot-bright' />
            </svg>
          </span>
        )}

        <span className='alignmid'>{pool.Total}</span>
      </span>
    </li>
  )
}
