import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { loadPromotions } from '@core/Areas/Racing/Hooks/RacePromotion/api'
import type { Promotion } from '@core/Areas/Racing/Hooks/RacePromotion/types'
import { makeRaceIdentifier } from '@core/Areas/Racing/Hooks/RacePromotion/helpers'
import { ONE_MINUTE } from '@mobi/utils'
import type { IdentifiableRace } from '@core/Areas/Racing/Types'

export function useRacePromotions(selectedRace: IdentifiableRace) {
  const { data, isError, isLoading } = useQuery([queryKeys.raceCardPromotion], loadPromotions, {
    staleTime: ONE_MINUTE * 15,
  })

  const promotions = useMemo<Promotion[]>(() => {
    if (!data) {
      return []
    }

    const { races, promotions } = data

    const raceKey = makeRaceIdentifier(selectedRace)
    const promotionKeys = races[raceKey] ?? []

    return promotionKeys.map(key => promotions[key])
  }, [data, selectedRace])

  return {
    isLoading,
    isError,
    promotions,
  }
}
