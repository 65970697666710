import ObservableRaceKeyPoolInfo from '../../../Model/Observables/ObservableRaceKeyPoolInfo'
import Guard from '../../../../AppUtils/Framework/Guard'
import { PoolInfo } from '../../../Model/Observables/ObservableRaceKeyPoolInfo'

export class AllUpPoolSelectionViewModel {
  constructor(params: { info: ObservableRaceKeyPoolInfo }) {
    Guard.notNull(params.info)
    this.info = params.info
  }

  public isSelected(arg: PoolInfo): boolean {
    return this.info.selectedPool().type() === arg.type()
  }

  public updateSelectedPool(newSelectedPool: PoolInfo) {
    if (newSelectedPool !== undefined) {
      this.info.selectedPool(newSelectedPool)
    }
  }

  public info: ObservableRaceKeyPoolInfo
}
