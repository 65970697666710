import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

export const InlineAlert: React.FC<{
  text: string
  type: AlertType
}> = ({ text, type }) => {
  return (
    <WrapperStyled style={{ background: alertTypeConfig[type].bgColor }}>
      <Icon name={alertTypeConfig[type].icon} size='2rem' /> {text}
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const alertTypeConfig: Record<AlertType, { bgColor: string; icon: IconName }> = {
  success: { bgColor: colors.success[600], icon: 'DuocolorCheckCircle' },
  warning: { bgColor: colors.warning[700], icon: 'DuocolorAlertTriangle' },
  error: { bgColor: colors.error[600], icon: 'DuocolorAlertTriangle' },
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  gap: spacing.smx2,
  minHeight: '4rem',
  padding: `${spacing.smx1} ${spacing.sm}`,
  color: colors.white,
  fontWeight: font.weight.semibold,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
})

// =====
// Types
// =====

type AlertType = 'success' | 'error' | 'warning'
type IconName = React.ComponentProps<typeof Icon>['name']
