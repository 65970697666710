import React from 'react'
import type { FobSelection, BetLegType } from '@mobi/betslip/types'
import { ToggleMultiLegBetType, ToggleMultiBet } from '@core/Areas/Betslip/signals'
import { GridCell, Grid } from '@mobi/component-library/Common/Grid'
import { Toggle } from '@core/Components/Toggle/Toggle'
import { ToggleIndicator } from '@core/Components/Toggle/ToggleIndicator'
import { InvestmentPriceStyled } from '@core/Areas/Betslip/Components/BetInvestment/BetInvestment.styles'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import { PriceChange, getPriceChange } from '@core/Components/Text/utils'
import {
  ItemErrorTextStyled,
  MultiItemStyled,
} from '@core/Areas/Betslip/Components/MultiItem/MultiItem.styles'
import { BetInfo } from '@core/Areas/Betslip/Components/BetInfo/BetInfo'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { isNonHandledError, isFatalMultiBetLegError } from '@core/Areas/Betslip/helpers/state'

interface BetLegProps {
  showPlace: boolean
  showToggle: boolean
  multiLegBetType?: Extract<BetLegType, 'W' | 'P'>
  disabled: boolean
  hasFatalError: boolean
  showPrice: number
  priceChange: PriceChange
  itemId: string | null
}

const handleToggleMultiLegBetType = (id: string | null) => {
  ToggleMultiLegBetType(id)
}

function SuccessBetLeg({
  showPlace,
  showToggle,
  multiLegBetType,
  disabled,
  hasFatalError,
  showPrice,
  priceChange,
  itemId,
}: BetLegProps) {
  return (
    <>
      <GridCell align='right' valign='middle' flexGrow={0} flexBasis='auto'>
        {showPlace &&
          (showToggle ? (
            <Toggle
              toggledOn={multiLegBetType === 'P'}
              toggleOffCharacter='W'
              toggleOnCharacter='P'
              onToggle={() => handleToggleMultiLegBetType(itemId)}
              isDisabled={disabled || hasFatalError}
              type='allgreen'
            />
          ) : (
            <ToggleIndicator character={multiLegBetType === 'P' ? 'P' : 'W'} />
          ))}
      </GridCell>
      <GridCell align='right' valign='middle' flexGrow={0} flexBasis='5.5rem'>
        <InvestmentPriceStyled isEditable={!disabled} price={showPrice} priceChange={priceChange} />
      </GridCell>
    </>
  )
}

function ErrorBetLeg({ error }: { error: string }) {
  return (
    <GridCell align='right' valign='middle'>
      <ItemErrorTextStyled>{error}</ItemErrorTextStyled>
    </GridCell>
  )
}

interface MultiItemProps {
  item: BetslipItem
  disabled: boolean
  showBetTypeToggle: boolean
  isReceipt?: boolean
}

export const MultiItem = ({
  item,
  disabled,
  showBetTypeToggle,
  isReceipt = false,
}: MultiItemProps) => {
  const { id, isInMulti, multiLegBetType, shouldAllowPlaceInvestment } = item
  const hasMultiLegError =
    !!item.multiBetLegError && isNonHandledError(item.multiBetLegError.betErrorType)
  const prices = item.selection as FobSelection
  const priceToDisplay = multiLegBetType === 'P' ? prices.placePrice : prices.winPrice
  const priceChangeStatus =
    multiLegBetType === 'W'
      ? prices.winPriceLastSeen
        ? getPriceChange(prices.winPriceLastSeen, prices.winPrice)
        : PriceChange.None
      : prices.placePrice && prices.placePriceLastSeen
        ? getPriceChange(prices.placePriceLastSeen, prices.placePrice)
        : PriceChange.None

  if (isReceipt) {
    return (
      <Grid>
        <GridCell flexGrow={1}>
          <BetInfo item={item} isSimpleView={true} />
        </GridCell>
        {!!item.multiBetLegError && isNonHandledError(item.multiBetLegError.betErrorType) ? (
          <ErrorBetLeg error={item.multiBetLegError.errorMessage} />
        ) : (
          <SuccessBetLeg
            showPlace={shouldAllowPlaceInvestment}
            showToggle={showBetTypeToggle}
            showPrice={priceToDisplay ?? 0}
            multiLegBetType={multiLegBetType}
            disabled={disabled}
            hasFatalError={isFatalMultiBetLegError(item)}
            priceChange={priceChangeStatus}
            itemId={item.id}
          />
        )}
      </Grid>
    )
  }

  return (
    <MultiItemStyled hasFatalError={isFatalMultiBetLegError(item)} data-testid='betslip-multi-item'>
      <Grid>
        <GridCell valign='middle' width='3.5rem'>
          <CheckboxInput
            id={id || ''}
            checked={isInMulti}
            label=''
            disabled={disabled || isFatalMultiBetLegError(item)}
            onChange={() => ToggleMultiBet(id)}
          />
        </GridCell>
        <GridCell flexGrow={1}>
          <BetInfo item={item} isSimpleView={true} />
        </GridCell>

        {hasMultiLegError ? (
          <ErrorBetLeg error={item.multiBetLegError?.errorMessage ?? ''} />
        ) : (
          <SuccessBetLeg
            showPlace={shouldAllowPlaceInvestment}
            showToggle={showBetTypeToggle}
            showPrice={priceToDisplay ?? 0}
            multiLegBetType={multiLegBetType}
            disabled={disabled}
            hasFatalError={isFatalMultiBetLegError(item)}
            priceChange={priceChangeStatus}
            itemId={item.id}
          />
        )}
      </Grid>
    </MultiItemStyled>
  )
}
