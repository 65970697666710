import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { HeaderSelectionIconBoxStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'

export const SingleSilkStyled = styled(HeaderSelectionIconBoxStyled)<{ isDogs: boolean }>(
  { flexShrink: 0 },
  ({ isDogs }) => ({
    background: isDogs ? 'none' : hexColors.gainsboro,
  })
)
