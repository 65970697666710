import { RaceStatus } from '../Hooks/useRaceList/types'

export function getSimpleRaceStatusText(raceStatus: RaceStatus) {
  switch (raceStatus) {
    case 'Released':
      return 'Results'
    case 'Abandoned':
      return 'ABND'
    default:
      return raceStatus.toString()
  }
}
