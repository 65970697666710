import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableFixedFlucs {
  constructor() {
    // @ts-expect-error Type issue
    this.winOpeningDividend = ko.observable()
    // @ts-expect-error Type issue
    this.winHighDividend = ko.observable()
    // @ts-expect-error Type issue
    this.winLowDividend = ko.observable()
    // @ts-expect-error Type issue
    this.winDividends = ko.observable()
  }

  public merge(other: ObservableFixedFlucs) {
    Guard.notNull(other)

    this.winOpeningDividend(other.winOpeningDividend())
    this.winHighDividend(other.winHighDividend())
    this.winLowDividend(other.winLowDividend())
    this.winDividends(other.winDividends())
  }

  public winOpeningDividend: ko.Observable<number>
  public winHighDividend: ko.Observable<number>
  public winLowDividend: ko.Observable<number>
  public winDividends: ko.Observable<number[]>
}
