import * as ko from 'knockout'
import { ObservableStarter } from './ObservableStarter'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableStarter } from './IObservableStarter'

export default class ObservableRaceStarter extends ObservableStarter {
  constructor() {
    super()
    // @ts-expect-error Type issue
    this.jockeyWeight = ko.observable<string>()
    // @ts-expect-error Type issue
    this.barrier = ko.observable<number>()
  }

  public merge(other: ObservableRaceStarter) {
    Guard.notNull(other)
    super.merge(other)

    this.jockeyWeight(other.jockeyWeight())
    this.barrier(other.barrier())
  }

  public untypedMerge(other: IObservableStarter) {
    this.merge(other as ObservableRaceStarter)
  }

  public jockeyWeight: ko.Observable<string>
  public barrier: ko.Observable<number>
}
