import styled from '@emotion/styled'

export const LogoStyled = styled('svg')({
  height: '100%',
  width: '100%',
})

export const TABtouchLogoStyled = styled(LogoStyled)({
  fill: 'currentColor',
})
