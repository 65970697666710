import produce from 'immer'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import {
  ToteAcceptorScratchedPushEvent,
  ToteAcceptorUnscratchedPushEvent,
} from '@core/State/PushData'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const pushToteAcceptorScratched = (
  event: ToteAcceptorScratchedPushEvent,
  meetingId: string,
  meetingDate: Date,
  raceNumber: number,
  raceId: string | null,
  raceInformation: ObservableRacePage
) => {
  handleEvent(event, meetingId, meetingDate, raceNumber, raceId, true, raceInformation)
}

export const pushToteAcceptorUnscratched = (
  event: ToteAcceptorUnscratchedPushEvent,
  meetingId: string,
  meetingDate: Date,
  raceNumber: number,
  raceId: string | null,
  raceInformation: ObservableRacePage
) => {
  handleEvent(event, meetingId, meetingDate, raceNumber, raceId, false, raceInformation)
}

function handleEvent(
  event: ToteAcceptorScratchedPushEvent | ToteAcceptorUnscratchedPushEvent,
  meetingId: string,
  meetingDate: Date,
  raceNumber: number,
  raceId: string | null,
  isScratched: boolean,
  raceInformation: ObservableRacePage
) {
  if (!event.raceId || !event.acceptorNumber || event.raceId.toString() != raceId) {
    return
  }

  const queryKey = queryKeys.racePageComplete(meetingId, meetingDate, raceNumber)

  // Update ReactQuery state
  queryClient.setQueryData<RacePageDTO | undefined>(queryKey, current => {
    if (!current) return undefined // key not found
    if (!isRacePageDto(current)) return undefined // only update open races
    return produce(current, draft => {
      const starters = draft.RaceStarters.find(rs => rs.RaceKey.RaceNumber === raceNumber)
      const starter = starters?.Starters.find(s => s.Number === event.acceptorNumber)
      if (starter) starter.IsScratched = isScratched
    })
  })

  // Update KO View Model state
  const starters = raceInformation.getStartersForRace(raceNumber)()

  const scratchedStarter = starters.find(starter => starter.number() === event.acceptorNumber)

  if (scratchedStarter) {
    scratchedStarter.isScratched(isScratched)
    if (!scratchedStarter.hasFixedOdds() || !isScratched) {
      scratchedStarter.isScratchedToteAndFob(isScratched)
    }
    // TODO: Handle late scratchings when regurgitator payload is updated
    // scratchedStarter.scratchType('Late')
  }
}
