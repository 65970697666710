import { CommMessage, Contract } from '.'
import { CommService } from './commShared'
import { v4 } from 'uuid'

// Constants

const reactNativeConstants = window?.ReactNativeConstants
export const OS = reactNativeConstants?.OS

export const isReactNativeApp = !!window.ReactNativeWebView
export const isReactNativeAndroid = isReactNativeApp && OS === 'android'
export const isReactNativeIos = isReactNativeApp && OS === 'ios'

/** OS major version */
export const MajorVersion = reactNativeConstants?.MajorVersion
/** Repo RN app build version */
export const AppVersion = reactNativeConstants?.AppVersion

// RN Comms

let commService: CommService

export const init = (): void => {
  if (!isReactNativeApp) return

  commService = new CommService()

  if (isReactNativeAndroid) {
    document.addEventListener('message', messageHandler, true)
  } else {
    window.addEventListener('message', messageHandler, true)
  }

  // Set up globals for slices
  window.ReactNativeComms = {
    sendToNative,
    subscribeToNative,
  }

  sendToNative('WEB_APP_READY')
}

type ContractParams<K extends keyof Contract> = Contract[K] extends never ? undefined : Contract[K]

export function sendToNative<K extends keyof Contract>(topic: K, data?: ContractParams<K>): void
export function sendToNative<K extends keyof Contract>(
  topic: K,
  data?: ContractParams<K>,
  id?: string
): void
export function sendToNative<K extends keyof Contract>(
  topic: K,
  data?: ContractParams<K>,
  id?: string
): void {
  const message: CommMessage = {
    topic,
    data,
    id: id ?? v4(),
  }
  window.ReactNativeWebView?.postMessage(JSON.stringify(message))
}

export function subscribeToNative<K extends keyof Contract>(
  topic: K,
  callback: (data: Contract[K]) => void,
  options?: {
    cleanupSignal: AbortSignal
  }
): {
  dispose: () => void
} {
  const dispose = () => {
    options?.cleanupSignal.removeEventListener('abort', dispose)
    commService.removeCallback(topic, callback)
  }

  commService.addCallback(topic, callback)
  options?.cleanupSignal.addEventListener('abort', dispose, {
    once: true,
  })

  return {
    dispose,
  }
}

function messageHandler(message: Event): void {
  const { data: payload } = message as MessageEvent<string>
  let messageId: string | undefined = undefined

  try {
    const { topic, data, id = null } = JSON.parse(payload)

    messageId = id

    commService.notifySubscribers(topic, data)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error parsing data from onMessage.nativeEvent.data:', payload, error)
  } finally {
    if (messageId) {
      sendToNative('ACK', undefined, messageId)
    }
  }
}
