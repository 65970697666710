import { useQueries } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRacePageDataFromApi } from './api'
import { getRaceFormDataFromApi } from '../useRaceFormData/api'

export const useRacePageData = ({
  meetingId,
  meetingDate,
  raceNumber,
  withForm = false,
}: {
  meetingId: string
  meetingDate: Date | string
  raceNumber: number | string
  /** default false */
  withForm?: boolean
}) => {
  const [race, form] = useQueries([
    {
      queryKey: queryKeys.racePageComplete(meetingId, meetingDate, +raceNumber),
      queryFn: () => getRacePageDataFromApi(meetingId, meetingDate, +raceNumber),
      staleTime: Infinity,
    },
    {
      queryKey: queryKeys.racePageForm(meetingId, meetingDate, +raceNumber),
      queryFn: () => getRaceFormDataFromApi(meetingId, meetingDate, +raceNumber),
      enabled: withForm,
      staleTime: Infinity,
    },
  ])
  return {
    isLoading: race.isLoading || (withForm && form.isLoading),
    isError: race.isError || (withForm && form.isError),
    isFetching: race.isFetching,
    race: race.data,
    form: form.data,
  }
}
