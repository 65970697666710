import React from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { Icon as IconLegacy } from '@mobi/component-library/Common/Icon'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { Link } from 'react-router-dom'

export const EmptyState = () => {
  const hostHelpers = getHostHelpers()

  return (
    <WrapperStyled>
      <div>
        <div>
          <Icon color={colors.neutral[400]} name='CustomBetSlipWithTabtouchLogo' size='9.5rem' />
        </div>
        <h1>Your Bet Slip is Empty</h1>
        <span>Check out today&apos;s racing and sports</span>
      </div>

      <div>
        <Link
          to={hostHelpers.hostRoutes.Meetings}
          onClick={() => hostHelpers.dismissBetSlip?.()}
          component={RacingButtonLink}
        />
        <Link
          to={{
            pathname: hostHelpers.hostRoutes.Sports,
            state: { tab: 'Upcoming' },
          }}
          component={SportsButtonLink}
          onClick={() => hostHelpers.dismissBetSlip?.()}
        />
      </div>
    </WrapperStyled>
  )
}

const RacingButtonLink = React.forwardRef<
  HTMLButtonElement,
  { onClick: () => void; navigate: () => void }
>(({ ...props }, ref) => (
  <Button
    ref={ref}
    {...props}
    onClick={() => {
      props.navigate()
      props.onClick()
    }}
    color='secondary_color'
    size='sm'
  >
    <IconLegacy type='racesall' size='2rem' />
    Today&apos;s Racing
  </Button>
))

const SportsButtonLink = React.forwardRef<
  HTMLButtonElement,
  { onClick: () => void; navigate: () => void }
>(({ ...props }, ref) => (
  <Button
    ref={ref}
    {...props}
    onClick={() => {
      props.navigate()
      props.onClick()
    }}
    color='secondary_color'
    size='sm'
  >
    <IconLegacy type='footballlight' size='2rem' />
    Upcoming Sports
  </Button>
))

const WrapperStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '1.9rem',
  maxWidth: '20.8rem',
  margin: 'auto',

  'div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '> div': {
      background: colors.neutral[100],
      borderRadius: radius.full,
      height: '16.4rem',
      width: '16.4rem',
    },

    h1: {
      ...font.size.lg,
      fontWeight: font.weight.semibold,
      fontFamily: font.family.primary,
    },

    span: {
      ...font.size.lg,
      fontWeight: font.weight.regular,
    },
  },

  'div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.smx1,
  },
})
