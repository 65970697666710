import React from 'react'
import { TABtouchLogoStyled } from './logo.styles'

export function TABtouchLogo() {
  return (
    <TABtouchLogoStyled
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 602.9 123.8'
      width='602'
      height='123'
      role='img'
    >
      <title>TABtouch</title>
      <path d='M310.2 63.8h-10.1v35.9c0 4 .4 5.1 3.7 5.1h6.1c1.6 0 2.8 1.1 2.8 2.5v12.5c0 1.5-1.2 2.3-2.8 2.7-4.4.9-8.5 1.2-11.9 1.2-14.3 0-23.6-4.4-23.6-24.3V63.8l-8.8-2c-1.5-.4-2.8-1.5-2.8-2.9V47.7c0-1.5 1.2-2.8 2.8-2.8h8.8V34.1c0-1.5 1.3-2.5 2.8-2.8l19.9-3.5c2.4-.4 2.9 1.1 2.9 2.5v14.5h10.1c1.5 0 2.7 1.2 2.7 2.7v13.6c.1 1.5-1.1 2.7-2.6 2.7zM353.4 123.7c-22.3 0-35.1-12.7-35.1-36.1v-7.7c0-23.5 12.8-36.1 35.1-36.1s34.9 12.7 34.9 36.1v7.7c.1 23.4-12.6 36.1-34.9 36.1zm9.5-43.9c0-10.5-3.3-14.7-9.5-14.7-6.1 0-9.6 4-9.6 14.7v7.7c0 10.5 3.5 14.8 9.6 14.8s9.5-4.1 9.5-14.8v-7.7zM462.2 122.5h-18.5c-1.5 0-2.7-1.2-2.7-2.7v-3.1c-4.8 3.6-11.2 6.9-21.1 6.9-19.7 0-24.5-14.8-24.5-30.7V47.8c0-1.5 1.1-2.9 2.7-2.9h20c1.6 0 2.9 1.5 2.9 2.9V93c0 7.1 1.6 9.5 8.3 9.5 3.1 0 6.9-1.3 10-2.9V47.8c0-1.5 1.1-2.9 2.7-2.9h20c1.6 0 2.9 1.5 2.9 2.9v72c0 1.5-1.2 2.7-2.7 2.7zM522.7 121.6c-4.5 1.1-10.4 2.1-18.3 2.1-20.4 0-31.9-13.1-31.9-34.9v-10c0-21.7 11.5-35.1 31.9-35.1 7.9 0 14.5 1.2 18.3 2.1 1.7.4 2.7 1.1 2.7 2.8v15.1c0 1.6-.8 2.8-2.8 2.5-4.5-.4-8.8-.9-15.3-.9-5.7 0-9.1 3.6-9.1 13.5v10c0 9.9 3.3 13.3 9.1 13.3 6.5 0 10.8-.3 15.3-.8 2-.3 2.8.8 2.8 2.5v14.9c0 1.8-1 2.5-2.7 2.9zM600.2 122.5H580c-1.5 0-2.7-1.2-2.7-2.7v-46c0-7.1-2.9-8.8-8.4-8.8-3.1 0-6.7 1.1-10.1 2.9v51.6c0 1.5-1.1 2.9-2.7 2.9h-20c-1.6 0-2.9-1.5-2.9-2.9V22.1c0-1.5 1.2-2.7 2.7-2.7h20.3c1.5 0 2.7 1.2 2.7 2.7v26.8c5.7-4 11.6-5.1 19.7-5.1 19.2 0 24.4 13.5 24.4 30v46c-.1 1.5-1.3 2.7-2.8 2.7z' />
      <g>
        <path d='M161.2 29.5l-4.9-19.1C154.9 4.6 148.5 0 142.1 0h-39.8L72.4 123.8h26.5c4 0 8-3 8.8-6.7l4.1-17.2h32.4l17-70.4zm-42.9 44l8.6-35.5h2.8l8.4 35.5h-19.8zM240.7 55.5c7.1-5.3 11.4-14 11.4-23 0-20.7-14.2-32.5-39-32.5h-37.7L159 68.1v42.8c0 6.7 1.5 12.9 12.7 12.9h42.5c24.9 0 43-16.2 43-38.6 0-13.2-6.1-24.2-16.5-29.7zm-50.9-28h21.4c6 0 9.4 3 9.4 8.3 0 6.1-3.4 9.2-10.2 9.2h-20.5V27.5zM211.7 96h-21.9V70.7h21.9c8.7 0 13.9 4.8 13.9 12.7 0 3-1 12.6-13.9 12.6zM65.3 123.8l5.3-21.9V29.8H88L95.2 0H6.4C1.9 0 0 1.8 0 6v17.8c0 3.8 2.3 5.9 6.4 5.9H36v87.9c0 4 2.5 6.1 7.1 6.1h22.2z' />
      </g>
    </TABtouchLogoStyled>
  )
}
