import React from 'react'
import styled from '@emotion/styled'
import { Currency } from '@mobi/utils/money'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { Investment } from '@mobi/betslip/Components/Common/Investment'
import type { InvestmentType, MultiInvestmentKey } from '@mobi/betslip/types'
import { MULTI_FORMULA_COMBINATIONS } from '@mobi/betslip/helpers/constants'

export const ComboInvestment: React.FC<{
  comboFieldType: InvestmentType
  multiInvestmentKey: MultiInvestmentKey
  label: string
  legCount: number
  isDisabled?: boolean
}> = ({ multiInvestmentKey, comboFieldType, label, legCount, isDisabled }) => {
  const activeInvestment = useAppSelector(selectActiveInvestment)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const value = multiInvestment[multiInvestmentKey]
  const combos = MULTI_FORMULA_COMBINATIONS[multiInvestmentKey]
  const comboCount = combos[`${legCount}`]

  return (
    <WrapperStyled>
      <div>
        <div>
          <span>{label}</span>
          <span>{comboCount} Combos</span>
        </div>

        <Investment
          shouldHideLabel
          itemId={multiInvestmentKey}
          investmentType={comboFieldType}
          isActive={comboFieldType === activeInvestment?.investmentType}
          value={value}
          isEditable
          isDisabled={isDisabled}
          display='inline'
        />
      </div>

      {value > 0 && (
        <div>
          <span>Cost {new Currency(value).multiply(comboCount).format()}</span>
        </div>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: spacing.sm,
  borderBottom: `0.1rem solid ${colors.neutral[200]}`,
  fontFamily: font.family.primary,

  ':first-of-type': { paddingTop: spacing.sm },
  ':last-of-type': { border: 0, paddingBottom: 0 },

  // Label and Input
  '> div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '> div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',

      '> span:first-of-type': {
        fontSize: font.size.lg.fontSize,
        letterSpacing: font.size.lg.letterSpacing,
        lineHeight: font.size.lg.lineHeight,
        fontWeight: font.weight.medium,
        color: colors.black,
      },

      '> span:last-of-type': {
        fontSize: font.size.md.fontSize,
        letterSpacing: font.size.md.letterSpacing,
        lineHeight: font.size.md.lineHeight,
        fontWeight: font.weight.regular,
        color: colors.neutral[800],
      },
    },
  },

  // Cost
  '> div:nth-of-type(2)': {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: spacing.smx2,
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.neutral[800],
  },
})
