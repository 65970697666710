import { createAddressMoniker } from '@mobi/utils/address'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { Address } from '@mobi/api-types'
import { ChangeResidentialAddress, ChangePostalAddress } from '../../driver'

export interface ManualAddressFields {
  unitNumber: string
  streetNumber: string
  streetName: string
  streetType: string | null
  suburb: string | null
}

export type ManualAddressState = {
  isShown: boolean
  streetNumberHasError: boolean
  streetNameHasError: boolean
  streetTypeHasError: boolean
  suburbHasError: boolean
  isPostalAddress: boolean
} & ManualAddressFields

export const defaultManualAddressState: ManualAddressState = {
  isShown: false,
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  streetType: null,
  suburb: null,
  streetNumberHasError: false,
  streetNameHasError: false,
  streetTypeHasError: false,
  suburbHasError: false,
  isPostalAddress: false,
}

export const ShowManualAddressForm = createSignal('ShowManualAddressForm')
export const ShowManualPostalAddressForm = createSignal('ShowManualPostalAddressForm')
export const CancelButtonClicked = createSignal('CancelButtonClicked')
export const SaveButtonClicked = createSignal('SaveButtonClicked')
export const ChangeFieldValue = createSignal<{
  field: keyof ManualAddressFields
  value: string | boolean
}>('ChangeFieldValue')
export const ValidateManualAddressField = createSignal<{ field: keyof ManualAddressFields }>(
  'ValidateManualAddressField'
)

export interface ManualAddressStateRecord
  extends TypedRecord<ManualAddressStateRecord>,
    ManualAddressState {}
const ManualAddressStateFactory = makeTypedFactory<ManualAddressState, ManualAddressStateRecord>(
  defaultManualAddressState
)

export function manualAddressDriver(
  state = ManualAddressStateFactory(),
  signal: Signal
): ManualAddressStateRecord {
  switch (signal.tag) {
    case ShowManualAddressForm: {
      return state.merge({
        isShown: true,
        isPostalAddress: false,
      })
    }

    case ShowManualPostalAddressForm: {
      return state.merge({
        isShown: true,
        isPostalAddress: true,
      })
    }

    case SaveButtonClicked: {
      const validationErrors = validateFields(state)
      const hasError = anyFieldHasError(validationErrors)
      if (hasError) {
        return state.merge({
          ...validationErrors,
        })
      }

      if (state.isPostalAddress) {
        ChangePostalAddress({
          addressPostalMoniker: getManualAddressMoniker(state),
          addressPostal: getManualAddress(state) as Address,
        })
      } else {
        ChangeResidentialAddress({
          addressResidentialMoniker: getManualAddressMoniker(state),
          addressResidential: getManualAddress(state) as Address,
        })
      }

      return state.merge({
        ...defaultManualAddressState,
      })
    }

    case CancelButtonClicked: {
      return state.merge({
        ...defaultManualAddressState,
      })
    }

    case ChangeFieldValue: {
      const { field, value } = signal.data
      const newState = state.set(field, value)
      const validationErrors = validateSingleField(field, newState)
      return newState.merge({
        ...validationErrors,
      })
    }

    case ValidateManualAddressField: {
      const { field } = signal.data

      const validationErrors = validateSingleField(field, state)
      return state.merge({
        ...validationErrors,
      })
    }

    default:
      return state
  }
}

interface FieldValidationErrors {
  streetNumberHasError: boolean
  streetNameHasError: boolean
  streetTypeHasError: boolean
  suburbHasError: boolean
}

export function getErrorKey(field: keyof ManualAddressFields): keyof FieldValidationErrors | '' {
  switch (field) {
    case 'streetNumber':
      return 'streetNumberHasError'
    case 'streetName':
      return 'streetNameHasError'
    case 'streetType':
      return 'streetTypeHasError'
    case 'suburb':
      return 'suburbHasError'
    default:
      return ''
  }
}

export function validateFields(newState: ManualAddressStateRecord): FieldValidationErrors {
  const streetNumber = newState.get('streetNumber')
  const streeName = newState.get('streetName')
  const streetType = newState.get('streetType')
  const suburb = newState.get('suburb')
  const isPostalAddress = newState.get('isPostalAddress')
  return {
    streetNumberHasError: !isPostalAddress && !streetNumber,
    streetNameHasError: !streeName,
    streetTypeHasError: !isPostalAddress && !streetType,
    suburbHasError: !suburb,
  }
}

export function validateSingleField(
  field: keyof ManualAddressFields,
  state: ManualAddressStateRecord
): Partial<FieldValidationErrors> {
  switch (field) {
    case 'streetNumber':
      return { streetNumberHasError: !state.get('isPostalAddress') && !state.get(field) }
    case 'streetName':
      return { streetNameHasError: !state.get(field) }
    case 'streetType':
      return { streetTypeHasError: !state.get('isPostalAddress') && !state.get(field) }
    case 'suburb':
      return { suburbHasError: !state.get(field) }
    default:
      return {}
  }
}

export function getManualAddressMoniker(state: ManualAddressStateRecord): string {
  return createAddressMoniker({
    unitNumber: state.get('unitNumber'),
    streetNumber: state.get('streetNumber'),
    streetName: state.get('streetName'),
    streetType: state.get('streetType'),
    suburb: state.get('suburb'),
  })
}

export function getManualAddress(state: ManualAddressStateRecord): Partial<Address> {
  const UnitNumber = state.get('unitNumber', '')
  const StreetNumber = state.get('streetNumber')
  const StreetName = state.get('streetName')
  const StreetType = state.get('streetType')

  let Suburb
  let State
  let Postcode

  if (state.get('suburb')) {
    const suburbText = (state.get('suburb') as string).split(',')
    Suburb = suburbText[0] && suburbText[0].trim()
    State = suburbText[1] && suburbText[1].trim()
    Postcode = suburbText[2] && suburbText[2].trim()
  }

  return {
    UnitNumber,
    StreetNumber,
    StreetName,
    StreetType,
    Suburb,
    State,
    Postcode,
    Country: 'Australia',
  }
}

export function anyFieldHasError(errors: FieldValidationErrors) {
  const { streetNumberHasError, streetNameHasError, streetTypeHasError, suburbHasError } = errors
  return streetNumberHasError || streetNameHasError || streetTypeHasError || suburbHasError
}

export const state$ = attachDriver<ManualAddressStateRecord>({
  path: 'manualAddress',
  driver: manualAddressDriver,
})
