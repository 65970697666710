import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'
import { bool } from 'aws-sdk/clients/signer'
import { toMoney } from '@mobi/utils/money'
import dayjs from 'dayjs'
import React from 'react'
import {
  BetOfferContainerStyled,
  BonusAmountsHeadingStyled,
  BonusAmountsRowStyled,
  BonusAmountsValueStyled,
  BonusButtonCellStyled,
  BonusButtonRowStyled,
  BonusCellStyled,
  BonusDetailRowStyled,
  BonusFineprintRowStyled,
  BonusHeaderRowStyled,
  BonusIconStyled,
} from '../BetOfferPage.styles'

interface BetOfferItemProps {
  campaign: Campaign
  handleCampaignClick: (campaign: Campaign) => void
}

export const BetOfferItem = (data: BetOfferItemProps): JSX.Element | null => {
  if (!shouldDisplayCampaign(data.campaign)) {
    return null
  }

  return (
    <BetOfferContainerStyled data-tid-bonus-detail>
      <Grid>
        <BonusHeaderRowStyled data-tid-bonus-detail-heading>
          <BonusCellStyled valign='middle' align='left'>
            <BonusIconStyled type={'offer'} size='1.6em' />
            {getTitleText(data.campaign)}
          </BonusCellStyled>
        </BonusHeaderRowStyled>
        <BonusDetailRowStyled data-tid-bonus-detail-description>
          <GridCell valign='middle' align='left'>
            {data.campaign.description}
          </GridCell>
        </BonusDetailRowStyled>

        {shouldDisplayAmounts(data.campaign) && (
          <BonusAmountsRowStyled data-tid-bonus-detail-amount>
            <GridCell valign='middle' align='left' flexGrow={1}>
              <BonusAmountsHeadingStyled>Total</BonusAmountsHeadingStyled>
              <BonusAmountsValueStyled>{getTotalAmount(data.campaign)}</BonusAmountsValueStyled>
            </GridCell>
            <GridCell valign='middle' align='left' flexGrow={1}>
              <BonusAmountsHeadingStyled>Remaining</BonusAmountsHeadingStyled>
              <BonusAmountsValueStyled>{getRemainingAmount(data.campaign)}</BonusAmountsValueStyled>
            </GridCell>
          </BonusAmountsRowStyled>
        )}
        <BonusFineprintRowStyled data-tid-bonus-detail-fineprint>
          <GridCell valign='middle' align='left'>
            {getTermsAndConditions(data.campaign)}{' '}
            <a
              href='https://help.tabtouch.com.au/knowledge-base/bonus-offers-terms-conditions/'
              target='_blank'
              rel='noreferrer'
            >
              Terms and Conditions
            </a>
          </GridCell>
        </BonusFineprintRowStyled>
        {shouldShowButton(data.campaign) && (
          <BonusButtonRowStyled data-tid-bonus-detail-actionbutton>
            <BonusButtonCellStyled valign='middle' align='center'>
              <ButtonBlock
                id='use-bonus'
                type='button'
                color='primary'
                onClick={() => data.handleCampaignClick(data.campaign)}
              >
                {getButtonText(data.campaign)}
              </ButtonBlock>
            </BonusButtonCellStyled>
          </BonusButtonRowStyled>
        )}
      </Grid>
    </BetOfferContainerStyled>
  )
}

const shouldDisplayCampaign = (campaign: Campaign): boolean => {
  return (
    campaign.isActivationPending ||
    (!campaign.isExpired && (campaign.isLive || campaign.isActivated))
  )
}

const getTitleText = (campaign: Campaign): string => {
  switch (campaign.triggerType) {
    case 'AccountDepositTrigger':
      return 'Deposit Bonus'
    case 'AccountBetTrigger':
      return 'Bet Match Bonus'
    default:
      return 'Bonus Offer'
  }
}

const getButtonText = (campaign: Campaign): string => {
  if (campaign.triggerType === 'AccountDepositTrigger') {
    if (campaign.isLive) {
      return 'Deposit Now'
    }
  }

  return 'Bet Now'
}

const shouldDisplayAmounts = (campaign: Campaign): boolean => {
  return campaign.initialAmount > 0
}

const getTotalAmount = (campaign: Campaign): string | null => {
  return campaign.initialAmount ? toMoney(campaign.initialAmount) : null
}

const getRemainingAmount = (campaign: Campaign): string | null => {
  return campaign.remainingAmount ? toMoney(campaign.remainingAmount) : null
}

const getTermsAndConditions = (campaign: Campaign): string => {
  const expiryDate = dayjs(campaign.expiry).format('HH:mm, DD MMMM YYYY')
  const rewardExpiryDate = campaign.rewardExpiry
    ? dayjs(campaign.rewardExpiry).format('HH:mm, DD MMMM YYYY')
    : null
  const minimumBet = campaign.minimumAmount
    ? toMoney(campaign.minimumAmount, { decimalPlaces: 0 })
    : campaign.minimumAmount

  let minimumBetString = ''

  if (minimumBet && campaign.triggerType == 'AccountDepositTrigger') {
    minimumBetString = `Minimum deposit is ${minimumBet}. `
  } else {
    minimumBetString = `Minimum bet is ${minimumBet}. Not valid for multi bets. `
  }

  if (campaign.isActivationPending) {
    return `Your bet has been successfully placed and is waiting to be resulted.`
  }

  if (campaign.isActivated && rewardExpiryDate) {
    return `Redeem offer by ${rewardExpiryDate}.`
  }

  return `${minimumBetString}This offer expires at ${expiryDate}.`
}

const shouldShowButton = (campaign: Campaign): bool => {
  return !campaign.isExpired && (campaign.isLive || campaign.isActivated)
}
