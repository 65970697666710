import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { BettingHeaderComponentTheme } from './themes'

interface Theme {
  theme?: BettingHeaderComponentTheme
}

export const ContainerStyled = styled('div')<Theme>(
  {
    position: 'relative',
  },

  ({ theme }) => ({
    backgroundColor: theme.container.background,
    fontSize: theme.container.text.size,
    color: theme.container.text.color,
    padding: theme.container.padding,
  })
)

export const HeadingStyled = styled('div')<Theme>(
  {
    lineHeight: 1.3,
    fontWeight: 'bold',
  },

  ({
    theme: {
      heading: { padding, text },
    },
  }) => ({
    fontSize: text.size,
    fontWeight: text.weight,
    padding,
  })
)

export const SubheadingStyled = styled('div')<Theme>(
  {
    lineHeight: 1,
  },

  ({ theme }) => ({
    fontSize: theme.subheading.text.size,
    padding: theme.subheading.padding,
  })
)

export const PilledStyled = styled('div')<Theme>(
  {
    borderRadius: '2rem',
    fontWeight: 'bold',
    lineHeight: 1,
    padding: '0.5rem 1rem',
    display: 'inline-block',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },

  ({ theme }) => ({
    backgroundColor: theme.pill.background,
    color: theme.pill.text.color,
    fontSize: theme.pill.text.size,
    margin: theme.pill.margin,
  })
)

export const SingleSelectionStyled = styled('div')<Theme>(
  {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },

  ({ theme }) => ({
    fontSize: theme.selection.text.size,
  })
)

export const RemoveButtonStyled = styled('button')({
  cursor: 'pointer',
  padding: '0.4rem 0 0.4rem 0.4rem',
  border: 0,
  background: 'none',
  fontSize: '2rem',
  color: colors.betslip.removeButton.color,
})
