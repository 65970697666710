import React from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from '@core/Store/hooks'
import { getCurrentRacePageQueryKey } from '@core/Areas/RaceCard/Store/selectors'
import { useRaceFieldSummaryData } from '@core/Areas/RaceCardFixedOnly/Hooks/useRaceFieldSummaryData'
import { Fields } from '@core/Areas/Racing/Components/FieldSummary/Fields'
import { RacePageDataTransferObject } from '@mobi/api-types'
import styled from '@emotion/styled'

export const MatchedFieldSummary: React.FC<{
  starterNumber: number
}> = props => {
  const racePageQueryKey = useAppSelector(getCurrentRacePageQueryKey)
  const { data } = useQuery<RacePageDataTransferObject>({
    queryKey: racePageQueryKey || [],
    enabled: !!racePageQueryKey,
    staleTime: Infinity,
  })

  const { data: fieldSummary } = useRaceFieldSummaryData({
    racecourseSeq: data?.RacecourseSeq,
    selectionDate: data?.MeetingInformation.MeetingDate,
    raceNumber: data?.MeetingInformation.SelectedRace.RaceNumber,
    isFormSummaryFeatureActive: true,
  })

  return (
    <MatchedSummaryStyled>
      {fieldSummary?.fields
        .filter(itemField => itemField.starterNumber === props.starterNumber)
        .map(raceField => {
          return (
            <div data-testid='fields' key={props.starterNumber}>
              <Fields summary={raceField} raceCode={fieldSummary?.raceCode} />
            </div>
          )
        })}
    </MatchedSummaryStyled>
  )
}
// ======
// Styles
// ======

const MatchedSummaryStyled = styled.div({
  '> div > div': {
    paddingLeft: '0',
  },
})
