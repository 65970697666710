import { IWinPlaceProcessor } from './IWinPlaceProcessor'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import { ButtonsSelection } from '../UIElements/ButtonsSelection'
import Guard from '../../../../AppUtils/Framework/Guard'
import { ObservableStarter } from '@classic/Betting-v2/Model/Observables/ObservableStarter'

export default class EnhancedWinPlaceProcessor implements IWinPlaceProcessor {
  constructor(private buttonSelectionType: ButtonSelectionType) {}

  public process(
    selectedStarter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation
  ): Array<IObservableStarter> {
    Guard.notNull(selectedStarter)
    Guard.notNull(starters)
    Guard.notNull(bettingContext)

    bettingContext.turnOffFieldAt(selectedStarter.selection().raceNumber.leg() - 1)
    bettingContext.isFixed(this.buttonSelectionType === ButtonSelectionType.Fob)

    // clear any scratched starters
    // - similar to WinPlaceToteProcessor, this is done here because unfortunately the scratched selections aren't removed elsewhere!!
    //   --> which means unless a selection is made, then scratched starters will exist :(
    // - unlike WinPlaceToteProcessor, we clear scratchings from all starters (i.e. not just the selected starter) to clean all starters
    //   --> this is an exiting bug with the non-EB selections :(
    starters().forEach(starter => {
      let buttonSelection = starter.selection() as ButtonsSelection
      if (!buttonSelection) return

      if (starter.isScratchedToteAndFob() && buttonSelection.hasAny())
        buttonSelection.value(ButtonSelectionType.None)

      if (starter.isScratched() && buttonSelection.hasTote())
        buttonSelection.value(
          buttonSelection.hasFob() ? ButtonSelectionType.Fob : ButtonSelectionType.None
        )

      if (starter.isFixedScratchedOrSuspended() && buttonSelection.hasFob())
        buttonSelection.value(
          buttonSelection.hasTote() ? ButtonSelectionType.Tote : ButtonSelectionType.None
        )
    })

    // return all starters that have selections
    // - starters[] already includes the selected starter, so we can ignore selectedStarter
    const results = starters().filter(starter => {
      return (starter.selection() as ButtonsSelection)?.hasAny() == true
    })

    return results as ObservableStarter[]
  }
}
