import { ISelectionResultProcessor } from './ISelectionResultProcessor'
import { ISelectionResult } from './ISelectionResult'
import * as buttons from '../UIElements/ButtonsSelection'
import ObservableSelectedRaceStarters from '../../../Model/Observables/ObservableSelectedRaceStarters'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ToteSelectionResult } from './ToteSelectionResult'
import { FobSelectionResult } from './FobSelectionResult'
import Guard from '../../../../AppUtils/Framework/Guard'
import { sortNumberAscending } from '@core/Utils'

export default class WinPlaceSelectionResultProcessor implements ISelectionResultProcessor {
  constructor(private winPlaceStarterOverride?: number) {}

  selections(
    bettingContext: BettingInformation,
    raceNumber: number,
    selections: ObservableSelectedRaceStarters
  ): ISelectionResult {
    Guard.notNull(bettingContext)
    Guard.notNull(selections)

    const starters = selections.getStartersForRace(raceNumber)()
    const leg = selections.getLegForRaceNumber(raceNumber)

    if (bettingContext.fieldSelectedAtIndex(leg - 1)) return new ToteSelectionResult('FD')

    if (starters.length === 0 || starters.some(starter => !starter.selection))
      return new ToteSelectionResult('')

    // winPlaceStarterOverride might not be provided, for instance when clicking from betting drawer or preparing an enquiry
    // - if that's the case then mimic the existing behavior.. assume fob if we're a single fob selection
    const starter =
      starters.find(item => this.winPlaceStarterOverride === item.number()) || starters[0]

    if (
      ((bettingContext.isEnhancedBetslip() && bettingContext.isFixed()) || starters.length == 1) &&
      (starter.selection() as buttons.ButtonsSelection)?.hasFob() == true
    ) {
      return new FobSelectionResult(
        starter.number(),
        starter.fixedOddsStarterInfo.playerNo(),
        starter.fixedOddsStarterInfo.propositionSequence(),
        starter.fixedOddsStarterInfo.displayWinDividend(),
        starter.fixedOddsStarterInfo.displayPlaceDividend()
      )
    }

    // assume tote
    const sortedStarterNumbers = starters
      .filter(starter => (starter.selection() as buttons.ButtonsSelection)?.hasTote() == true)
      .map(starters => starters.number())
      .sort(sortNumberAscending)

    return new ToteSelectionResult(sortedStarterNumbers.join('.'))
  }

  getSelectionsResult(bettingContext: BettingInformation, raceNumber: number): ISelectionResult {
    return this.selections(bettingContext, raceNumber, bettingContext.selections)
  }
}
