// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Object = Record<string, any>

/** Deep merge a source into a target, retaining target reference
 *
 * Source values will override target values
 *
 * NOTE: target argument will be mutated
 *
 * eg. deepMerge({ 1: 1, 2: 2 }, { 1: null, 3: 3 }) -> { 1: null, 2: 2, 3: 3 }
 */
export function deepMerge<T extends Object>(
  target: T extends Array<unknown> ? never : T,
  source: Object
): T {
  if (!source || !isObject(target)) return target

  for (const key in source) {
    if (!source.hasOwnProperty(key)) break

    if (isObject(source[key])) {
      if (!target[key] || !isObject(target[key])) {
        ;(target as Object)[key] = {}
      }
      deepMerge(target[key] as Object, source[key] as Object)
    } else {
      ;(target as Object)[key] = source[key]
    }
  }

  return target
}

function isObject(item: unknown): boolean {
  return item === Object(item) && !Array.isArray(item)
}
