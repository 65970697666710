import React from 'react'
import { Observable } from 'rx'
import type { BettingType } from '@mobi/betslip/types'
import { state$ as userAccountState$, Campaign } from '@core/State/UserAccount/userAccountDriver'
import { QuickbetState, state$ as quickbetState$ } from '../../driver'
import { observeImmutable } from '../../../../Components/HOCs'
import {
  ContainerStyled,
  MysteryQuickPickBonusToggleStyled,
} from './MysteryQuickPickBonusToggleButton.styles'

export type MysteryQuickPickBonusToggleButtonStateProps = {
  accountNumber: number | null
  accountBalance: number | null
  bonusBetBalance: number | null
  bonusCashBalance: number | null
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  isBusy: boolean
  canConfirmBet: boolean
  activeCampaigns: Campaign[] | undefined
  presetInvestment: boolean
  bettingType: BettingType | null
}

export type MysteryQuickPickBonusToggleButtonExternalProps = {
  handleClick: () => void
}

const mysteryQuickPickState = Observable.combineLatest(
  userAccountState$,
  quickbetState$,
  (userAccountState, quickbetState): MysteryQuickPickBonusToggleButtonStateProps => {
    const quickbetStateValue = quickbetState.toJS() as QuickbetState
    return {
      accountNumber: userAccountState.accountNumber,
      accountBalance: userAccountState.accountBalance,
      bonusBetBalance: userAccountState.bonusBetBalance,
      bonusCashBalance: userAccountState.bonusCashBalance,
      isUsingBonusBet: quickbetStateValue.isUsingBonusBet,
      isUsingBonusCash: quickbetStateValue.isUsingBonusCash,
      isBusy: quickbetStateValue.isBusy,
      canConfirmBet: quickbetStateValue.canConfirmBet,
      activeCampaigns: userAccountState.activeCampaigns
        ?.filter(x => x.rewardType == 'BonusCashReward')
        .toArray(),
      presetInvestment: quickbetStateValue.presetInvestment,
      bettingType: quickbetStateValue.bettingType,
    }
  }
)

export function MysteryQuickPickBonusToggleButtonComponent(
  props: MysteryQuickPickBonusToggleButtonStateProps &
    MysteryQuickPickBonusToggleButtonExternalProps
): JSX.Element | null {
  const hasActiveBonusCashCampaigns = props.activeCampaigns?.some(
    campaign => campaign.rewardType === 'BonusCashReward'
  )

  if (
    props.bettingType !== 'mystery-quick-pick' ||
    !props.presetInvestment ||
    props.canConfirmBet ||
    // isUsingBonusBet means we see the bonus cash (argh), isUsingBonusCash means we have selected bonus cash to actually use it
    props.isUsingBonusCash ||
    !hasActiveBonusCashCampaigns
  ) {
    return null
  }

  return (
    <ContainerStyled>
      <MysteryQuickPickBonusToggleStyled
        id='quickbet-bonuscash-button'
        onClick={props.handleClick}
        selected={props.isUsingBonusBet}
        disabled={props.isBusy}
      >
        {props.isUsingBonusBet ? 'Use Balance' : 'Use My Bonus'}
      </MysteryQuickPickBonusToggleStyled>
    </ContainerStyled>
  )
}

export const MysteryQuickPickBonusToggleButton = observeImmutable<
  MysteryQuickPickBonusToggleButtonStateProps,
  MysteryQuickPickBonusToggleButtonExternalProps
>(mysteryQuickPickState, ({ record, handleClick }) => (
  <MysteryQuickPickBonusToggleButtonComponent {...record} handleClick={handleClick} />
))
