import braintree, { type Client } from 'braintree-web'
import { useEffect, useMemo, useCallback, useRef } from 'react'
import { withTimeout } from '@mobi/utils'

export function useBraintreeClient(token: string) {
  const client = useRef<Client | undefined>()
  const isReadyResolve = useRef<(isReady: true) => void | undefined>()
  const isReadyPromise = useMemo<Promise<boolean>>(
    () =>
      new Promise<boolean>(resolve => {
        isReadyResolve.current = resolve
      }),
    []
  )
  const isReady = useCallback(() => withTimeout(isReadyPromise, 5_000, false), [isReadyPromise])

  useEffect(() => {
    const currentClient = client.current

    if (currentClient) {
      currentClient.authorization = token
    } else {
      braintree.client
        .create({
          authorization: token,
        })
        .then(clientInstance => {
          client.current = clientInstance
          isReadyResolve.current?.(true)
        })
    }
  }, [token])

  return {
    client,
    isReady,
  } as const
}
