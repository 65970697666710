import { dayjs } from '@mobi/utils'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableScratching {
  public starterNumber: string
  public starterName: string

  public isFixedOdds: boolean
  public scratchedDateTime: string

  public winDivDeduction: string | null
  public placeDivDeduction: string | null

  public isEmergency: boolean
  public meetingCode: string
  public emergencyNum: number

  public raceCodes: {
    Races: string
    Trots: string
    Dogs: string
  }

  constructor() {
    this.starterNumber = ''
    this.starterName = ''

    this.isFixedOdds = false
    this.scratchedDateTime = ''

    this.winDivDeduction = ''
    this.placeDivDeduction = ''

    this.isEmergency = false
    this.meetingCode = ''
    this.emergencyNum = 0

    this.raceCodes = {
      Races: 'R',
      Trots: 'T',
      Dogs: 'D',
    }
  }

  public displayStarterName() {
    if (this.isEmergency) {
      switch (
        this.meetingCode //TODO: Use raceCodes()
      ) {
        case 'Races':
        case 'Trots':
          return this.starterName + ' (Em' + this.emergencyNum + ')'

        case 'Dogs':
          return this.starterName + ' (Res' + this.emergencyNum + ')'
      }
    }

    return this.starterName
  }

  public displayScratchedDateTime() {
    if (this.scratchedDateTime) {
      return dayjs.tz(this.scratchedDateTime).local().format('DD/MM/YYYY HH:mm')
    } else {
      return ''
    }
  }

  public merge(other: ObservableScratching) {
    Guard.notNull(other)

    this.starterNumber = other.starterNumber
    this.starterName = other.starterName

    this.isFixedOdds = other.isFixedOdds
    this.scratchedDateTime = other.scratchedDateTime

    if (other.winDivDeduction !== null) {
      this.winDivDeduction = parseFloat(other.winDivDeduction).toFixed(2)
    } else {
      this.winDivDeduction = ''
    }
    if (other.placeDivDeduction !== null) {
      this.placeDivDeduction = parseFloat(other.placeDivDeduction).toFixed(2)
    } else {
      this.placeDivDeduction = ''
    }

    this.isEmergency = other.isEmergency
    this.meetingCode = other.meetingCode
    this.emergencyNum = other.emergencyNum
  }
}
