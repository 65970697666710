import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { ButtonBlockStyled, type ButtonProps } from '@mobi/component-library/Common/Buttons'

export const LinkStyled = styled('a')({
  color: hexColors.black,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
})

export const LinkBlockStyled = styled(ButtonBlockStyled)<ButtonProps>({
  lineHeight: 2,
  textDecoration: 'none',
}).withComponent('a')
