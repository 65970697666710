import { useQuery } from 'react-query'
import { Map } from 'immutable'

import { get } from '@classic/Foundation/Services/ApiService'
import { logError } from '@classic/Foundation/Services/LoggingService'
import {
  defaultUserAccountState,
  SetSuperpickStatus,
  state$ as userAccountState$,
  SuperpickStatus,
} from '@core/State/UserAccount/userAccountDriver'
import { useObservable } from '@core/Utils/hooks'

export const ONE_HOUR = 3600000
export const DEFAULT_SUPERPICK_STATUS: SuperpickStatus = {
  isLoading: false,
  errorCode: null,
  errorMessage: '',
  tokens: [],
}

let cacheCleaner: () => unknown
export function refetch() {
  cacheCleaner?.()
}

export const useGetSpecialTokens = (isEnabled: boolean = true): SuperpickStatus => {
  const accountState = useObservable(userAccountState$, defaultUserAccountState)
  const accountNumber = accountState.accountNumber
  const superpicksMap = accountState.superpicks as unknown as Map<string, unknown>
  const superpicks = superpicksMap?.toJS?.() || accountState.superpicks
  const query = useQuery({
    queryKey: ['useGetSpecialTokens-special-tokens', accountNumber],
    queryFn: () => querySpecialTokens(superpicks),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: ONE_HOUR,
    enabled: isEnabled && !!accountNumber,
    refetchInterval: ONE_HOUR,
    refetchIntervalInBackground: true,
    onSettled: data => SetSuperpickStatus(data),
  })

  cacheCleaner = () => {
    SetSuperpickStatus({
      tokens: superpicks?.tokens || [],
      errorCode: null,
      errorMessage: '',
      isLoading: true,
    })
    query.remove()
  }
  return superpicks || DEFAULT_SUPERPICK_STATUS
}

export function getSpecialTokenUrl(tokenId: number): Promise<SpecialUrlResponse> {
  const url = `/api/pyos/SpecialUrl/${tokenId}`

  return get<SpecialUrlResponse>({ url })
}

async function querySpecialTokens(currentStatus: SuperpickStatus | null | undefined) {
  const populatedCurrentTokens = currentStatus?.tokens || DEFAULT_SUPERPICK_STATUS.tokens

  SetSuperpickStatus({
    tokens: populatedCurrentTokens,
    isLoading: true,
    errorMessage: currentStatus?.errorMessage || '',
    errorCode: currentStatus?.errorCode == undefined ? null : currentStatus.errorCode,
  })
  const data = await get<RetrieveFobSpecialTokensResponse>({
    url: '/api/pyos/RetrieveFobSpecialTokens',
  })
    .then((json): SuperpickStatus => {
      if (!json || json.errorCode !== undefined || json.errorMessage !== undefined) {
        return {
          tokens: populatedCurrentTokens,
          isLoading: false,
          errorMessage: json?.errorMessage || '',
          errorCode: json?.errorCode,
        }
      }

      const isTokenFault = !!json.data?.TokensFault
      if (!json.success || isTokenFault) {
        const errorMessage = isTokenFault
          ? json.data?.TokensFault?.Message || 'Currently unable to retrieve SuperPicks'
          : ''
        return {
          tokens: populatedCurrentTokens,
          isLoading: false,
          errorMessage,
          errorCode: isTokenFault ? json.data?.TokensFault.ErrorCode : null,
        }
      }

      return {
        tokens: json.data?.Tokens || [],
        isLoading: false,
        errorMessage: '',
        errorCode: null,
      }
    })
    .catch((err): SuperpickStatus => {
      logError(err)
      return {
        tokens: populatedCurrentTokens,
        isLoading: false,
        errorMessage: 'Currently unable to retrieve SuperPicks',
        errorCode: null,
      }
    })

  return data
}

interface ServiceResponse {
  success: boolean
  errorCode: number
  errorMessage: string
  data: unknown
}

interface SpecialUrlResponse extends ServiceResponse {
  data: {
    SpecialUrl: string
  }
}

export interface RetrieveFobSpecialTokensResponse extends ServiceResponse {
  data: {
    Tokens: FobSpecialToken[]
    TokensFault: {
      ErrorCode: number
      ErrorName: string
      ExternalCode: string
      Message: string
    }
  }
}

export interface FobSpecialToken {
  TokenId: number
  TokenName: string
  BetsAvailable: number
  IsBonusToken: boolean
}
