import { injectable } from 'inversify'
import { get, post } from './ApiService'

export interface IApiService {
  get<T>({ url, opts }: { url: string; opts?: RequestInit }): Promise<T>
  post<T>({ url, body }: { url: string; body: unknown }): Promise<T>
}

@injectable()
export class ApiService implements IApiService {
  public get<T>({ url, opts }: { url: string; opts?: RequestInit }): Promise<T> {
    return get<T>({ url, opts })
  }

  public post<T>({ url, body }: { url: string; body: unknown }): Promise<T> {
    return post<T>({ url, body })
  }
}
