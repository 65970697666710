import type { MutableRefObject } from 'react'
import type { Client } from 'braintree-web'
import { addCrumb, coerceIntoError, unwrapErrorMessage } from '@mobi/utils'
import { reportErrorIfNeeded } from '../../Utils/sentry'
import { useSelector } from 'react-redux'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { useDeposit } from '../useDeposit'
import { DepositError } from '../../Areas/Deposit/Components'
import { ThreeDSecureError } from '../../Areas/Deposit/Utils'
import { generatePaymentNonce } from '../../Utils/api'
import { verifyThreeDSecure } from '../../Utils/braintree'
import {
  logLiabilityShiftSuccess,
  logLiabilityShiftRejected,
  logLiabilityShiftError,
} from '../../Utils/logging'
import type {
  ContactDetails,
  CreditCardDepositRequest,
  DepositResponse,
  InitialData,
  PaymentMethod,
} from '@mobi/api-types'
import { type HostApi, useHostContext } from '../../Areas/Deposit/HostContext'
import { track } from '../../Utils/analytics'
import { useUserContactDetails } from '../useUserContactDetails'
import { selectDepositFlow } from '../../Store'
import type { UserCancelable, DepositFlow } from '../../Utils/types'

type UseCreditCardDepositProps = {
  braintreeClient: MutableRefObject<Client | undefined>
  accountNumber: number
  mustUse3DSecure: boolean
  initialData: InitialData
  onDepositing: VoidFunction
}

type DepositProps = Pick<DepositParams, 'creditCard'> & {
  isUsingNewCard: boolean
  depositAmount: number
}

export function useCreditCardDeposit({
  mustUse3DSecure,
  accountNumber,
  initialData,
  braintreeClient,
  onDepositing,
}: UseCreditCardDepositProps) {
  const { depositMutation } = useDeposit<CreditCardDepositRequest>({ braintreeClient })
  const flow = useSelector(selectDepositFlow)
  const hostApi = useHostContext()

  const { data: userData } = useUserContactDetails({
    accountNumber,
    flow,
  })

  const deposit = async ({ creditCard, depositAmount }: DepositProps): Promise<DepositResponse> => {
    const client = braintreeClient.current

    if (!client) {
      // TODO: Move error capture to callsite
      const message = 'Error Braintree client is uninitialized'
      reportErrorIfNeeded({
        message,
      })

      throw new Error(message)
    }

    const response = await prepareDeposit({
      accountNumber,
      creditCard,
      client,
      mustUse3DSecure,
      depositAmount,
      initialData,
      userData,
      hostApi,
      flow,
    })

    if (response.wasUserCanceled) {
      throw DepositError.canceled(initialData.transactionId)
    }

    onDepositing()

    const { paymentMethodToken, paymentMethodNonce } = response

    return depositMutation.mutateAsync({
      amount: depositAmount,
      transactionId: initialData.transactionId,
      paymentMethodNonce,
      paymentMethodToken,
      makeDefault: false,
      forceThreeDSecureClient: false,
      depositSource: 'ExistingPaymentMethod',
    })
  }

  return {
    deposit,
  }
}

async function prepareDeposit({
  accountNumber,
  creditCard,
  client,
  initialData: { transactionId },
  mustUse3DSecure,
  depositAmount,
  userData,
  hostApi,
  flow,
}: DepositParams): Promise<UserCancelable<PrepareDepositResult>> {
  const { token: paymentMethodToken } = creditCard

  const { isSuccess, nonce } = await generatePaymentNonce(
    paymentMethodToken,
    depositAmount,
    transactionId
  )

  if (!isSuccess) {
    throw new DepositError(
      'unable_to_retrieve_tokens',
      transactionId,
      undefined,
      'Failed to retrieve tokens'
    )
  }

  const paymentTokenResult = await getPaymentTokens({
    nonce,
    accountNumber,
    client,
    mustUse3DSecure,
    creditCard,
    depositAmount,
    userData,
    transactionId,
    hostApi,
    flow,
  })

  if (paymentTokenResult.wasUserCanceled) {
    return paymentTokenResult
  }

  const { nonce: paymentMethodNonce, liabilityShiftStatus } = paymentTokenResult

  if (liabilityShiftStatus !== 'did_not_ask' && liabilityShiftStatus !== 'shifted') {
    throw new DepositError(
      'card_verification_failed',
      transactionId,
      undefined,
      'Card verification failed'
    )
  }

  return {
    wasUserCanceled: false,
    paymentMethodNonce,
    paymentMethodToken,
  }
}

async function getPaymentTokens({
  nonce,
  accountNumber,
  client,
  mustUse3DSecure,
  creditCard,
  depositAmount,
  userData,
  transactionId,
  flow,
}: PaymentTokenParams): Promise<UserCancelable<PaymentTokenResult>> {
  const paymentMethod: PaymentMethods = 'CreditCard'

  if (!mustUse3DSecure) {
    track('deposit-non-3d-secure', {
      accountNumber,
      paymentMethod,
      depositVariant: flow,
    })

    addCrumb(
      'deposit',
      'user account to not get verified with 3ds as it has been turned off in Goldmine'
    )

    return {
      wasUserCanceled: false,
      nonce,
      liabilityShiftStatus: 'did_not_ask',
    }
  }

  try {
    const threeDSecure = await verifyThreeDSecure(client, {
      nonce,
      bin: creditCard.bin ?? creditCard.number.slice(0, 6),
      depositAmount,
      userData,
    })

    if (threeDSecure.threeDSecureInfo.liabilityShifted) {
      logLiabilityShiftSuccess({
        accountNumber,
        transactionId,
        paymentMethod,
        flow,
      })

      return {
        wasUserCanceled: false,
        nonce: threeDSecure.nonce,
        liabilityShiftStatus: 'shifted',
      }
    } else {
      logLiabilityShiftRejected({
        accountNumber,
        transactionId,
        paymentMethod,
        flow,
        response: {
          wasUserCanceled: false,
          nonce: threeDSecure.nonce,
          threeDSecureInfo: threeDSecure.threeDSecureInfo,
        },
      })

      return {
        wasUserCanceled: false,
        nonce: threeDSecure.nonce,
        liabilityShiftStatus: 'not_shifted',
      }
    }
  } catch (error) {
    if (error instanceof ThreeDSecureError && error.code === 'user_canceled') {
      addCrumb(
        'deposit',
        'user has cancelled 3ds card verification step therefore deposit was not made'
      )

      return {
        wasUserCanceled: true,
      }
    }

    logLiabilityShiftError({
      accountNumber,
      transactionId,
      errorMessage: unwrapErrorMessage(coerceIntoError(error)),
      paymentMethod,
      flow,
    })

    return {
      wasUserCanceled: false,
      nonce: '',
      liabilityShiftStatus: 'verification_failed',
    }
  }
}

// region Types
type PaymentTokenParams = {
  client: Client
  accountNumber: number
  nonce: string
  mustUse3DSecure: boolean
  creditCard: PaymentMethod
  depositAmount: number
  transactionId: string
  userData?: ContactDetails
  hostApi: HostApi
  flow: DepositFlow
}

type DepositParams = {
  accountNumber: number
  client: Client
  initialData: InitialData
  creditCard: PaymentMethod
  depositAmount: number
  mustUse3DSecure: boolean
  userData?: ContactDetails
  hostApi: HostApi
  flow: DepositFlow
}

type PaymentTokenResult = {
  nonce: string
  liabilityShiftStatus: 'shifted' | 'not_shifted' | 'did_not_ask' | 'verification_failed'
}

type PrepareDepositResult = {
  paymentMethodNonce: string
  paymentMethodToken: string
}
// endregion
