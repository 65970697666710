import { captureException } from '@sentry/react'

export function logError(err: Error | string, logToSentry?: boolean): void {
  // eslint-disable-next-line no-console
  if (console && console.error) {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  if (logToSentry) {
    captureException(err)
  }
}
