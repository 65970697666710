export default class TachoViewModel {
  private readonly radius = 40
  private readonly startX = 10
  private readonly startY = 50
  private readonly defaultPath = 'M0,0'

  public readonly rating: ko.Observable<number>

  constructor({ rating }: { rating: ko.Observable<number> }) {
    this.rating = rating
  }

  blueArcPath() {
    if (this.rating() <= 0) {
      return this.defaultPath
    }
    if (this.rating() <= 100) {
      return this.arcPath(this.rating())
    }
    if (this.rating() <= 150) {
      return this.arcPath(100)
    }
    return this.defaultPath
  }

  orangeArcPath() {
    if (this.rating() <= 100) {
      return this.defaultPath
    }
    if (this.rating() <= 150) {
      return this.arcPath(50)
    }
    return this.arcPath(100)
  }

  private arcPath(clampedRating: number) {
    const θ = Math.PI * 1e-2 * clampedRating
    const endX = this.radius * (1.0 - Math.cos(θ))
    const endY = -this.radius * Math.sin(θ)
    return `M${this.startX},${this.startY}a${this.radius},${this.radius} 0 0 1 ${endX},${endY}`
  }
}
