import type { ElementType } from 'react'
import { attachDriver, type Signal } from 'rwwa-rx-state-machine'
import { makeTypedFactory, type TypedRecord } from 'typed-immutable-record'
import { layering } from '@mobi/component-library/Theme/Common'
import { OverlayClose, OverlayOpen, OverlayReset } from './signals'
import type { OverlayOpenProps } from '@core/Components/Overlay/types'

export interface OverlayState {
  Component: ElementType | null
  layer: typeof layering.overlayLow | typeof layering.overlayHigh
  isOpen: boolean
}
export const defaultState: OverlayState = {
  Component: null,
  layer: layering.overlayLow,
  isOpen: false,
}

export interface OverlayStateRecord extends TypedRecord<OverlayStateRecord>, OverlayState {}
const OverlayStateFactory = makeTypedFactory<OverlayState, OverlayStateRecord>(defaultState)

export const driver = (state = OverlayStateFactory(), signal: Signal) => {
  switch (signal.tag) {
    case OverlayOpen: {
      const data: OverlayOpenProps | null = signal.data

      if (data !== null && typeof data !== 'string' && 'layer' in data) {
        return state.merge({
          Component: data.Component,
          layer: data.layer,
          isOpen: true,
        })
      }

      return state.merge({
        Component: data,
        layer: layering.overlayLow,
        isOpen: true,
      })
    }

    case OverlayClose: {
      return state.merge({
        isOpen: false,
      })
    }

    case OverlayReset: {
      return state.merge({
        ...defaultState,
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver({ driver, path: `Overlay-React` })
