import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { RunnerNumber } from '@core/Areas/RaceCard/Components/RunnerNumber'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { SilkImage } from '@classic/Betting-v2/Model/SilkImages'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IAppWindow } from '@classic/AppUtils/Framework/WindowManagement/IAppWindow'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import Guard from '@classic/AppUtils/Framework/Guard'
import { MatchedSilkImage } from '@core/Areas/Racing/Components/SilkImage/MatchedSilkImage'
import { FeatureFlags } from '@mobi/settings'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'

interface IStarterImageViewModelParams {
  starter: IObservableStarter
  stripes: ko.ObservableArray<number>
  jumperStripes: (arg: number) => string
  numberOfStartersInRace: ko.Computed<number>
}

@injectable()
export class StarterImageViewModel extends Disposable {
  public RunnerNumber: React.ReactNode
  public MatchedSilkImage: React.ReactNode
  public ldSubscription!: Rx.IDisposable
  public isFieldSummaryV2Active: ko.Observable<boolean> = ko.observable(false)

  private appWindow: IAppWindow

  constructor(
    @inject('IAppWindow') appWindow: IAppWindow,
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('$params') params: IStarterImageViewModelParams
  ) {
    super(eventAggregator)

    this.appWindow = appWindow

    this.RunnerNumber = RunnerNumber
    this.MatchedSilkImage = MatchedSilkImage

    Guard.notNull(params)
    Guard.notNull(params.starter)

    this.ldSubscription = launchDarkState$.subscribe(record => {
      const fieldSummaryV2 = record.features.get(FeatureFlags.FIELD_SUMMARY_V2.key)
      if (fieldSummaryV2 !== this.isFieldSummaryV2Active()) {
        this.isFieldSummaryV2Active(fieldSummaryV2)
      }
    })

    this.starter = params.starter
    this.stripes = params.stripes
    this.jumperStripes = params.jumperStripes

    this.key = this.starter.type()[0] + this.starter.name().toUpperCase()

    this.cssClass = this.starter.tag() === 'Dog' ? '' : `silk ${this.starter.tag().toLowerCase()}`

    this.isTrot = ko.pureComputed<boolean>(() => {
      return this.starter.type() === 'Trots'
    }, this)

    this.isDog = ko.pureComputed<boolean>(() => {
      return this.starter.type() === 'Dogs'
    }, this)

    this.hasSilk = ko.pureComputed<boolean>(() => {
      return typeof this.starter.silkImages() !== 'undefined'
    }, this)

    this.silkImage = ko.pureComputed<SilkImage | null>(() => {
      return this.hasSilk()
        ? this.starter.silkImages().getSilkImageFor(this.appWindow.innerWidth())
        : null
    }, this)

    this.widthCss = ko.pureComputed<string>(() => {
      return this.hasSilk() && !this.isDog()
        ? `${(this.silkImage() as SilkImage).size.width}px`
        : ''
    }, this)

    this.heightCss = ko.pureComputed<string>(() => {
      return this.hasSilk() && !this.isDog()
        ? `${(this.silkImage() as SilkImage).size.height}px`
        : ''
    }, this)

    this.backgroundCss = ko.pureComputed<string>(() => {
      return this.hasSilk()
        ? (this.silkImage() as SilkImage).getCssBackgroundForStarter(
            this.starter.number(),
            this.starter.type(),
            params.numberOfStartersInRace ? params.numberOfStartersInRace() : 0
          )
        : ''
    }, this)

    this.configureDisposal()
  }
  private configureDisposal() {
    this.registerDisposals(() => {
      this.ldSubscription && this.ldSubscription.dispose()
    })
  }

  public starter: IObservableStarter
  public key: string
  public cssClass: string
  public stripes: ko.ObservableArray<number>
  public jumperStripes: (arg: number) => string
  public isTrot: ko.PureComputed<boolean>
  public isDog: ko.PureComputed<boolean>
  public hasSilk: ko.PureComputed<boolean>
  public silkImage: ko.PureComputed<SilkImage | null>
  public widthCss: ko.PureComputed<string>
  public heightCss: ko.PureComputed<string>
  public backgroundCss: ko.PureComputed<string>
}
