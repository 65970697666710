import { Footer } from './Components/Footer'
import { Container } from './Components/Container'
import { EventDetails } from './Components/EventDetails'
import { SelectionInfo } from './Components/SelectionInfo'

export const BetCard = {
  Container,
  EventDetails,
  SelectionInfo,
  Footer,
}

export * as FooterButtons from './Components/FooterButtons/FooterButtons'

export type { LinkInfo } from './types'
