import React, { type FC, memo } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { toMoney } from '@mobi/utils/money'
import { colors } from '@mobi/component-library/Theme/Common'
import { PillButton, Icon } from '@mobi/component-library/Common/V2'
import {
  PanelStyled as Panel,
  PanelIconStyled,
  PanelHeaderStyled as Header,
  PanelSubHeaderStyled as Subheader,
  PanelButtonsStyled as Buttons,
} from './Workflow.styled'

type Props = {
  amount: number
  onDone: VoidFunction
}

const Component: FC<Props> = ({ amount, onDone }) => {
  return (
    <Panel>
      <PanelIconStyled backgroundColor={colors.success[50]}>
        <Animation>
          <Icon name='LineCheckCircleBroken' size='32px' color={colors.success[500]} />
        </Animation>
      </PanelIconStyled>
      <Header>{toMoney(amount)}</Header>
      <Subheader>Your deposit was successful</Subheader>
      <Buttons>
        <PillButton color='positive' size='lg' isFullWidth={true} onClick={onDone}>
          Done
        </PillButton>
      </Buttons>
    </Panel>
  )
}

Component.displayName = 'SuccessPanel'

export const SuccessPanel = memo(Component, () => true)

const draw = keyframes({
  '0%': {
    strokeDashoffset: '102.75810241699219px',
    strokeDasharray: '102.75810241699219px',
  },
  '100%': {
    strokeDashoffset: '205.51620483398438px',
    strokeDasharray: '102.75810241699219px',
  },
})

export const Animation = styled.span({
  animation: `${draw} 1.3s ease-in-out 0s both`,
  height: '32px',
})
