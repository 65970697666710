import * as ko from 'knockout'
import { ExactaTopPanelViewModel } from './ExactaTopPanelViewModel'
import template from './exacta.top.panel.tpl.html'

if (!ko.components.isRegistered('exacta-top-panel')) {
  ko.components.register('exacta-top-panel', {
    viewModel: ExactaTopPanelViewModel,
    template,
  })
}
