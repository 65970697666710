import React from 'react'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { BlackbookControlProps } from '@core/Areas/Blackbook/types'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'

export const AddJockeyButton: React.FC<AddJockeyButtonProps> = ({ jockeyName, source }) => {
  const { matchedRunnerInBlackbook, isLoading, clickHandler } = useAddToBlackbook({
    starterName: jockeyName,
    starterType: 'Jockeys',
    source,
  })

  const isJockeyInBlackbook = !!matchedRunnerInBlackbook
  const testId = isJockeyInBlackbook ? 'go-to-blackbook-with-jockey' : 'add-jockey-to-blackbook'

  const iconName = isJockeyInBlackbook ? 'tickCircleSolid' : 'jockey'
  const buttonText = isJockeyInBlackbook ? 'See Jockey in Blackbook' : 'Add Jockey to Blackbook'

  return (
    <ButtonBlock color='link' disabled={isLoading} data-testid={testId} onClick={clickHandler}>
      <Icon type={iconName} size='2rem' /> {buttonText}
    </ButtonBlock>
  )
}

// Types

type AddJockeyButtonProps = WithoutNullableKeys<
  Pick<BlackbookControlProps, 'jockeyName' | 'source'>
>
