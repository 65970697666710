import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { Global } from '@emotion/react'
import { Toast, useToast } from '.'
import { spacing } from '../../../Theme/Common'

const enum LocalConstants {
  TRANSITION_DURATION_MS = 300,
}

export const Toasts = () => {
  const { toasts, dismissToast } = useToast()

  return (
    <ToastsContainerStyled>
      <Global
        styles={{
          '.toast-enter-active, .toast-exit-active': {
            transitionDuration: `${LocalConstants.TRANSITION_DURATION_MS}ms`,
            transitionProperty: 'opacity, transform',
          },
          '.toast-enter': {
            opacity: 0,
            transform: 'translateY(150%)',
          },
          '.toast-enter-active': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          '.toast-exit': {
            pointerEvents: 'none',
            opacity: 1,
            transform: 'translateY(0)',
          },
          '.toast-exit-active': {
            pointerEvents: 'none',
            opacity: 0,
            transform: 'translateY(80%)',
            zIndex: -1,
          },
        }}
      />

      <TransitionGroup component={ToastsListStyled}>
        {toasts.map(toast => (
          <CSSTransition
            nodeRef={toast.elementRef}
            key={toast.id}
            timeout={LocalConstants.TRANSITION_DURATION_MS}
            classNames='toast'
          >
            <ToastsItemStyled>
              <Toast
                ref={toast.elementRef}
                type={toast.type}
                text={toast.text}
                onDismiss={() => {
                  dismissToast(toast)
                }}
              />
            </ToastsItemStyled>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ToastsContainerStyled>
  )
}

Toasts.displayName = 'Toasts'

const ToastsContainerStyled = styled.div({
  left: spacing.md,
  right: spacing.md,
  bottom: '6rem',
  position: 'fixed',
  zIndex: 99999,
})

const ToastsListStyled = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,

  '& > li:not(:first-of-type)': {
    marginTop: '1rem',
  },

  '&:empty': {
    pointerEvents: 'none',
    visibility: 'hidden',
  },
})

const ToastsItemStyled = styled.li({
  position: 'relative',
  zIndex: 0,
})
