import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Pagination } from '@core/Components/Pagination'
import { BannerPaginationStyled as PaginationWrap } from '../Banner.styles'

const AUTO_ADVANCE_TIME_MS = 5_000

export const CarouselWrapper: React.FC<{}> = ({ children }) => {
  const bannerCount = React.Children.count(children)
  const timerTrackMsRef = React.useRef<number>(0)
  const [currentBanner, setCurrentBanner] = React.useState(0)
  const [isAutoAdvanceOn, setIsAutoAdvanceOn] = React.useState(true)

  React.useEffect(() => {
    if (bannerCount === 1 || !isAutoAdvanceOn) return undefined

    // Check if user has interrupted before next advance
    if (timerTrackMsRef.current >= 500) {
      setIsAutoAdvanceOn(false)
      return undefined
    }

    timerTrackMsRef.current = AUTO_ADVANCE_TIME_MS

    const timerId = setInterval(() => {
      timerTrackMsRef.current -= 250

      // Auto advance when interval is reached
      if (timerTrackMsRef.current === 0) {
        setCurrentBanner(currentBanner =>
          currentBanner === bannerCount - 1 ? 0 : currentBanner + 1
        )
      }
    }, 250)

    return () => {
      clearInterval(timerId)
    }
  }, [isAutoAdvanceOn, currentBanner, bannerCount])

  return bannerCount > 1 ? (
    <>
      <SwipeableViews
        enableMouseEvents={true}
        onChangeIndex={setCurrentBanner}
        index={currentBanner}
      >
        {children}
      </SwipeableViews>
      <PaginationWrap>
        <Pagination dotCount={bannerCount} index={currentBanner} />
      </PaginationWrap>
    </>
  ) : (
    <>{children}</>
  )
}
