import styled from '@emotion/styled'

export const HeaderStyled = styled.div({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  textAlign: 'left',
  margin: '0 0 1rem',
  display: 'flex',
})

export const SubheaderStyled = styled.div({
  fontSize: '1.2rem',
  textAlign: 'left',
  marginBottom: '1.5rem',
})

export const SubheaderTextStyled = styled.span({
  backgroundColor: '#281636',
  borderRadius: '10rem',
  fontWeight: 'bold',
  padding: '0.5rem 1rem',
})

export const LineContainerStyled = styled.div({ display: 'flex', padding: '0 1rem' })

export const TextContainerStyled = styled.div({ width: '20%' })

export const LineInfoStyled = styled.div({ display: 'flex', width: '80%' })

export const ToteLineContainerStyled = styled.div({
  display: 'flex',
  backgroundColor: '#281636',
  borderRadius: '10rem',
  fontWeight: 'bold',
  padding: '0.2rem 1rem',
})
