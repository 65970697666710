import { injectable, inject } from 'inversify'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IMessageBoxViewModel } from './IMessageBoxViewModel'

@injectable()
export class MessageBoxViewModel extends Disposable implements IMessageBoxViewModel {
  public showDescription!: boolean
  public showLink!: boolean
  public iconClass!: string
  public title!: string
  public description!: string
  public link!: { text: string; url: string }

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: {
    iconClass: string
    title: string
    description: string
    link: { text: string; url: string }
  }): void {
    this.iconClass = params.iconClass
    this.title = params.title
    this.description = params.description
    this.link = params.link
    this.showDescription =
      this.description !== null && this.description !== undefined && this.description.length > 0
    this.showLink = this.link !== null && this.link !== undefined
  }
}
