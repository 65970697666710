import React, { type FC } from 'react'
import { Constants } from '@mobi/settings'
import { isReactNativeApp as isNative } from '@mobi/web-native-comms/web'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import {
  FooterStyled,
  LogoStyled as Logo,
  SocialsStyled as Socials,
  GamblingHelplineStyled as GamblingHelpline,
  RwwaResponsibleWageringStyled as RwwaResponsibleWagering,
  DownloadAppStyled as DownloadApp,
  CopyrightStyled as Copyright,
} from './Footer.styles'
import {
  TabTouch as TabTouchLogo,
  AppleAppStore as AppleAppStoreLogo,
  GooglePlayStore as GooglePlayStoreLogo,
} from './Logos'
import { SetDepositLimitButton } from './SetDepositLimitButton'
import { ExternalLink } from './ExternalLink'
import { UnbundlingStatusDot } from './UnbundlingStatusDot'

type Props = {
  /** The version number of the web app */
  version: string
  /** Specifies if the toolbox should be shown */
  showToolbox: boolean
  /** Function to track analytics events for internal link navigation */
  trackInternalNavigation: (destination: string) => void
  /** Function to track analytics events for exit link navigation */
  trackExitNavigation: (destination: string) => void
  /** Handler for 'set deposit limit' button click events */
  onSetDepositLimitClick: VoidFunction
  /** Handler for version number click events */
  onVersionNumberClick: VoidFunction
}

export const Footer: FC<Props> = ({
  version,
  showToolbox,
  trackInternalNavigation,
  trackExitNavigation,
  onSetDepositLimitClick,
  onVersionNumberClick,
}) => {
  return (
    <FooterStyled>
      <Logo>
        <TabTouchLogo />
      </Logo>

      <Socials>
        <ExternalLink
          href='https://www.instagram.com/tabtouch'
          onClick={() => trackExitNavigation('instagram')}
          data-msg-publish='nav.exit'
          data-msg-data='instagram'
          data-tid-home-instagram
        >
          <Icon name='LogoInstagram' />
        </ExternalLink>
        <ExternalLink
          href='https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2FTABtouch'
          onClick={() => trackExitNavigation('facebook')}
          data-msg-publish='nav.exit'
          data-msg-data='facebook'
          data-tid-home-facebook
        >
          <Icon name='LogoFacebook' />
        </ExternalLink>
        <ExternalLink
          href='https://twitter.com/tab_touch'
          onClick={() => trackExitNavigation('twitter')}
          data-msg-publish='nav.exit'
          data-msg-data='twitter'
          data-tid-home-twitter
        >
          <Icon name='LogoX' />
        </ExternalLink>
      </Socials>

      <GamblingHelpline>
        <h1>WHAT ARE YOU REALLY GAMBLING WITH?</h1>

        <p>
          For free and confidential support call{' '}
          <strong>
            <a href='tel:1800 858 858' target='_blank' rel='noopener noreferrer'>
              1800 858 858
            </a>
          </strong>{' '}
          or visit{' '}
          <strong>
            <ExternalLink
              href={Constants.GamblingHelpOnlineURL}
              onClick={() => trackExitNavigation('gambling-help-online')}
            >
              gamblinghelponline.org.au
            </ExternalLink>
          </strong>
        </p>

        {onSetDepositLimitClick && <SetDepositLimitButton onClick={onSetDepositLimitClick} />}
      </GamblingHelpline>

      <RwwaResponsibleWagering>
        <p>
          BetStop - the National Self-Exclusion Register&trade; is a free service for people who
          want to exclude from all Australian online wagering providers. Visit{' '}
          <ExternalLink href={Constants.BetStopURL} onClick={() => trackExitNavigation('bet-stop')}>
            www.betstop.gov.au
          </ExternalLink>{' '}
          for more information.
        </p>
        <p>
          RWWA supports{' '}
          <ExternalLink
            href={Constants.ResponsibleWageringURL}
            onClick={() => trackInternalNavigation('responsible-wagering')}
          >
            Responsible Wagering
          </ExternalLink>
        </p>
        <p>
          RWWA&apos;s gambling operations are governed by its{' '}
          <ExternalLink
            href='https://www.rwwa.com.au/docs/rwwalibraries/default-document-library/rwwa-responsible-wagering-code-of-practice-007-1.pdf'
            onClick={() => trackExitNavigation('responsible-gambling-code-of-practice')}
          >
            Responsible Gambling Code of Practice
          </ExternalLink>{' '}
          and for South Australian residents by the{' '}
          <ExternalLink
            href='https://www.cbs.sa.gov.au/documents/FN_ABO-Gambling-Code-of-Practice.pdf'
            onClick={() => trackExitNavigation('responsible-gambling-code-of-practice-sa')}
          >
            South Australian Responsible Gambling Code of Practice.
          </ExternalLink>
        </p>
      </RwwaResponsibleWagering>

      {!isNative && (
        <DownloadApp>
          <h1>Download our app</h1>
          <span className='links'>
            <ExternalLink
              href='https://itunes.apple.com/au/app/tabtouch/id472906558?mt=8'
              data-msg-publish='nav.exit'
              data-msg-data='ios'
              data-tid-home-ios
              onClick={() => trackExitNavigation('apple-app-store')}
            >
              <AppleAppStoreLogo />
            </ExternalLink>
            <ExternalLink
              href='https://play.google.com/store/apps/details?id=com.rwwa.android.tabtouch'
              data-msg-publish='nav.exit'
              data-msg-data='android'
              data-tid-home-android
              onClick={() => trackExitNavigation('android-play-store')}
            >
              <GooglePlayStoreLogo />
            </ExternalLink>
          </span>
        </DownloadApp>
      )}

      <Copyright>
        <p>
          Gamble responsibly. 18+ only. &copy; Copyright RWWA {new Date().getFullYear()}
          <br />
          <span onClick={onVersionNumberClick}>
            <UnbundlingStatusDot /> v{version}
          </span>
          <br />
          {showToolbox && <a href='/#toolbox'>Toolbox</a>}
        </p>
      </Copyright>
    </FooterStyled>
  )
}
