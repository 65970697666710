import { injectable } from 'inversify'
import { IUrlProvider } from './IUrlProvider'
import { getServerVersion } from '../../Foundation/Services/ServerConfig'
import dayjs from 'dayjs'

@injectable()
export class UrlProvider implements IUrlProvider {
  // Betting
  readonly getBetCost = '/tote/bet/betcost'
  readonly proposeBet = '/tote/bet/propose'
  readonly commitBet = '/tote/bet/commit'

  //Multi
  readonly addLegToMultiBet = '/multi/addtoteprop'

  //GiddyUp
  readonly getGiddyUpEntitlement = '/api/entitlements/GetGiddyUpEntitlements'

  //Meetings
  public async getCompleteRaceApiUrl(meetingId: string, meetingDate: Date, raceNumber: number) {
    return getCompleteRaceApiUrl(meetingId, meetingDate, raceNumber)
  }

  public getStartersApiUrl(
    meetingId: string,
    meetingDate: Date,
    raceNumber: number
  ): Promise<string> {
    return Promise.resolve(getStartersApiUrl(meetingId, meetingDate, raceNumber))
  }

  public getRaceStartersApiUrl(
    meetingId: string,
    meetingDate: Date,
    raceNumbers: number[]
  ): Promise<string> {
    return Promise.resolve(getRaceStartersApiUrl(meetingId, meetingDate, raceNumbers))
  }

  public getRacePageWebUrl(
    meetingId: string,
    meetingDate: Date,
    raceNumber: number
  ): Promise<string> {
    const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
    return Promise.resolve(`#tote/meetings/${meetingId}/${raceNumber}?date=${formattedDate}`)
  }
}

const appendVersionCacheBreaker = (url: string): string => {
  return url + `?v=${getServerVersion()}`
}

const getStartersApiUrl = (meetingId: string, meetingDate: Date, raceNumber: number): string => {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  return appendVersionCacheBreaker(
    `/api/meetings/${meetingId}/${formattedDate}/race/${raceNumber}/starters`
  ).toLowerCase()
}

export const getRaceStartersApiUrl = (
  meetingId: string,
  meetingDate: Date,
  raceNumbers: number[]
): string => {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  return appendVersionCacheBreaker(
    `/api/meetings/${meetingId}/${formattedDate}/races/${raceNumbers.join(',')}`
  ).toLowerCase()
}

export const getCompleteRaceApiUrl = (
  meetingId: string,
  meetingDate: Date | string,
  raceNumber: number
) => {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  const url = `/api/meetings/${meetingId}/${formattedDate}/race/${raceNumber}`
  return (appendVersionCacheBreaker(url) + '&includeSrm').toLowerCase()
}
