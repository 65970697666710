import * as ko from 'knockout'
import { IMultiLegViewModel } from './IMultiLegViewModel'
import { BettingInformation } from '../../../Model/BettingInformation'
import container from '../../../IOC/inversify.config'
import ObservableRacePage from '../../../Model/Observables/ObservableRacePage'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import template from './multi.leg.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('multi-leg-bettype')) {
    ko.components.register('multi-leg-bettype', {
      viewModel: {
        createViewModel: (params: {
          data: ObservableRacePage
          selections: ko.ObservableArray<IObservableStarter>
          context: { bettingContext: BettingInformation }
        }): IMultiLegViewModel => {
          const viewModel = container.get<IMultiLegViewModel>('IMultiLegViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
