import { Container } from 'inversify'
import parentContainer from '../../AppUtils/Framework/IOC/inversify.config'
import services from './services.config'
import viewModels from './viewmodels.config'
import { tap } from '@mobi/utils'

const container = tap(new Container(), container => {
  container.parent = parentContainer
  container.load(services)
  container.load(viewModels)
})

export default container
