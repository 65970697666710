import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import type { useMeetingsData } from '@core/Areas/Meetings/hooks'

export const DataError: React.FC<DataErrorProps> = ({ refetch }) => {
  return (
    <NoticeBox
      testId='meetings-data-error'
      hasBorder
      noticeBoxType={NoticeBoxTypes.Error}
      title='Oops, Unable to Get Data'
      subtitle='An error occurred while retrieving data'
      buttonClick={refetch}
      buttonText='Try again'
    />
  )
}

type DataErrorProps = Pick<ReturnType<typeof useMeetingsData>['data'], 'refetch'>
