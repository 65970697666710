import React from 'react'
import { hashChange } from '@classic/Foundation/Navigation/Nav'

// TODO: Phase out legacy router, make react-router primary
export const useLegacyRouter = (): void => {
  React.useEffect(() => {
    // Init legacy router with first call to hashChange
    hashChange()

    // Main listener to control legacy routing
    window.addEventListener('hashchange', hashChange)

    return () => {
      window.removeEventListener('hashchange', hashChange)
    }
  })
}
