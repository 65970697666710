import styled from '@emotion/styled'
import * as CSS from 'csstype'
import { hexColors } from '@mobi/settings'

export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6

export const HeadingStyled = (level: HeadingLevels) => {
  let styles: CSS.Properties

  switch (level) {
    default:
    case 1:
      styles = {}
      break
    case 2:
      styles = {}
      break
    case 3:
      styles = {
        backgroundColor: hexColors.gainsboro,
        color: hexColors.nightRider,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textTransform: 'none',
      }
      break
    case 4:
      styles = {}
      break
    case 5:
      styles = {}
      break
    case 6:
      styles = {}
      break
  }

  return styled(`h${level}` as keyof JSX.IntrinsicElements)({ ...styles })
}

// TODO: Refine proper type scale / system & replace Heading above

// Minor Third Scale - 1.200
const HeadingBase = styled('div')({
  margin: '3rem 0 1.38rem',
  fontWeight: 400,
  lineHeight: 1.3,
  textAlign: 'left',
  textTransform: 'unset',
  color: hexColors.nero,
  background: 'none',
  padding: 0,
})

export const H1 = styled(HeadingBase)({
  marginTop: 0,
  fontSize: '2.488rem',
}).withComponent('h1')

export const H2 = styled(HeadingBase)({
  fontSize: '2.074rem',
}).withComponent('h2')

export const H3 = styled(HeadingBase)({
  fontSize: '1.728rem',
}).withComponent('h3')

export const H4 = styled(HeadingBase)({
  fontSize: '1.44rem',
}).withComponent('h4')

export const H5 = styled(HeadingBase)({
  fontSize: '1.2rem',
}).withComponent('h5')
