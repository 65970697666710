import ko from 'knockout'
import { BettingInformation } from '../../../Model/BettingInformation'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'
import { IObservablePage } from '../../../Model/Observables/IObservablePage'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'

export class FirstFourTopPanelViewModel {
  constructor(params: {
    raceInfo: IObservablePage
    context: {
      raceNumber: ObservableRaceKey
      bettingContext: BettingInformation
      starters: ko.ObservableArray<IObservableStarter>
    }
    useGiddyUp: IUseGiddyUp
  }) {
    this.raceInfo = params.raceInfo
    this.raceNumber = params.context.raceNumber
    this.bettingContext = params.context.bettingContext
    this.starters = params.context.starters
    this.useGiddyUp = params.useGiddyUp
  }

  public hasGiddyUp() {
    return this.useGiddyUp?.showGiddyUpInTopPanel(this.raceNumber.raceNumber())
  }

  public openGiddyUp() {
    this.useGiddyUp?.openGiddyUp()
  }

  public raceInfo: IObservablePage
  public raceNumber: ObservableRaceKey
  public bettingContext: BettingInformation
  public starters: ko.ObservableArray<IObservableStarter>
  public useGiddyUp?: IUseGiddyUp
}
