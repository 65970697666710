import React from 'react'

import { sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

import { trackBiometricsToggle } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

import {
  BiometricsPageSubHeaderStyled,
  ContentBodyStyled,
  GeneralSettingStyled,
} from './BiometricsPage.styles'
import { SingleNotificationSetting } from './Components/SingleNotificationSetting'

export function BiometricsPage(): JSX.Element {
  const testId = { 'data-tid-biometrics-page-container': '' }
  const [isSwitchOn, setIsSwitchOn] = React.useState(false)
  const [toggleClicked, setToggleClicked] = React.useState(false)

  React.useEffect(() => {
    const { dispose } = subscribeToNative('RESPONSE_BIOMETRICS_STATUS', ({ isEnabled }) => {
      setIsSwitchOn(isEnabled)
      if (toggleClicked) {
        setToggleClicked(false)
        trackBiometricsToggle(keys.biometricsTogglePressed, { isEnabled })
      }
    })
    return dispose
  })
  sendToNative('REQUEST_BIOMETRICS_STATUS')

  const handleToggleClick = () => {
    setToggleClicked(true)
    sendToNative('REQUEST_TOGGLE_BIOMETRICS')
  }

  return (
    <MainContainer {...testId}>
      <ContentHeader title='Enable PIN/Biometrics' />
      <ContentBodyStyled>
        <GeneralSettingStyled>
          <BiometricsPageSubHeaderStyled>General Settings</BiometricsPageSubHeaderStyled>
          {isSwitchOn === null ? (
            <LoadingPlaceholder width='100%' height='4rem' />
          ) : (
            <SingleNotificationSetting
              toggleState={isSwitchOn}
              text='Use Face ID, Fingerprint or PIN to login'
              onToggle={handleToggleClick}
              toggleTitle='Enable/Disable'
            />
          )}
        </GeneralSettingStyled>
      </ContentBodyStyled>
    </MainContainer>
  )
}
