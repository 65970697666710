import { useQuery } from 'react-query'
import { queryKeys } from '../../Areas/Deposit/Utils'
import { get } from '../../Utils/network'
import { BetAccount } from '@mobi/api-types'

type UseBetAccountProps<T> = {
  queryKey?: string
  enabled?: boolean
  queryFn: () => Promise<T>
  onSuccess?: (data: T) => void
}

export function useBetAccount<T>(options: UseBetAccountProps<T>) {
  return useQuery({
    queryKey: options.queryKey || queryKeys.betAccount,
    queryFn: options.queryFn,
    cacheTime: 0, // Defer to rwwa-data-access for caching
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    refetchOnWindowFocus: false,
  })
}

export async function fetchBetAccount(): Promise<BetAccount> {
  return await get<BetAccount>(`/$_/api/account/getBetAccountDetails`)
}
