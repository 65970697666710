import { v4 as uuidv4 } from 'uuid'
import type {
  Selection,
  MysterySelection,
  EventDetails,
  BettingType,
  BetError,
  BetPrices,
} from '@mobi/betslip/types'
import {
  isFobSelection,
  isMysterySelection,
  isRaceDetails,
  isSameRaceMultiSelection,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record'
import { ToteRaceStatusChangedPushEvent } from 'tabtouch-push-contract'
import { BetResponseCode, MysteryProposeResponse, BetErrorType } from '@core/Data/betting'
import { isMysteryDetails } from '@core/Data/Betting/selectionDetails'
import {
  ChangeInvestment,
  ProposeBet,
  ProposeBetFailed,
  ProposeBetSucceeded,
  ConfirmBet,
  ConfirmBetFailed,
  ConfirmBetSucceeded,
  ConfirmBetslipBetsSucceeded,
  ConfirmBetslipBetsFailed,
  DepositFundsDisplayed,
  EditBetslipItem,
  QuickbetLoadSelection,
  QuickbetSelection,
  SetActiveInvestment,
  ToggleBonusBetUsage,
  ToggleBonusCashUsage,
  ToggleEachWay,
  SetInvalidInvestmentNotification,
  AddingToBetslip,
  RaceClosedEventReceived,
  SetMysteryQuickPickPresetInvestment,
  ProposeBetslipBetsSucceeded,
  QuickbetClosed,
  SetAllowInvestmentState,
  InsufficientFundsForBet,
  ClearInsufficientFundsForBet,
} from './signals'
import { HasLoggedIn } from '@core/State/UserAccount/userAccountDriver'
import {
  ChangeSelectedSuperPick,
  LoadSuperPicks,
  UpdateSelectedSuperPick,
} from '@core/Components/SuperPick/signals'
import { ToggleFormula } from './Components/Formula/driver'
import { BetslipItem } from '../Betslip/driver'
import { NotificationType } from './Components/Notifications/NotificationTypes'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { toBetErrorType } from '../Betslip/helpers/state'

export interface QuickbetState<TSelectionDetails extends EventDetails = EventDetails> {
  id: string | null
  bettingType: BettingType | null
  canBet: boolean
  canChangeInvestment: boolean
  betPlaced: boolean
  isBusy: boolean
  canProposeBet: boolean
  canConfirmBet: boolean
  selection: Selection | null
  selectionDetails: TSelectionDetails | null
  shouldAllowWinInvestment: boolean
  shouldAllowPlaceInvestment: boolean
  isEachWayAvailable: boolean
  isEachWay: boolean
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  isAddingToBetslip: boolean
  isBetslipItem: boolean
  notificationType: NotificationType | null
  notificationTitle: string | null
  notificationSubtitle: string | null
  presetInvestment: boolean
  promptForInvestment: boolean // is investment input required
  tags?: string[]
  isSameRaceMulti?: boolean
  betSource: string | null
  keepSelections?: null | ((_?: boolean) => boolean | undefined)
  insufficientFundsForBet: boolean
  betCost: number
  shortfall: number
}

export const defaultNotificationState = {
  notificationType: null,
  notificationTitle: null,
  notificationSubtitle: null,
}

export const defaultQuickbetState: QuickbetState = {
  id: null,
  bettingType: null,
  canBet: true,
  canChangeInvestment: true,
  betPlaced: false,
  isBusy: false,
  canProposeBet: true,
  canConfirmBet: false,
  selection: null,
  selectionDetails: null,
  isEachWayAvailable: false,
  isUsingBonusBet: false,
  isUsingBonusCash: true,
  isEachWay: false,
  shouldAllowWinInvestment: true,
  shouldAllowPlaceInvestment: false,
  isAddingToBetslip: false,
  isBetslipItem: false,
  presetInvestment: false,
  promptForInvestment: true,
  tags: [],
  isSameRaceMulti: false,
  ...defaultNotificationState,
  betSource: null,
  keepSelections: null,
  insufficientFundsForBet: false,
  betCost: 0,
  shortfall: 0,
}

const canChangeInvestmentState = {
  canChangeInvestment: true,
  canProposeBet: true,
  canConfirmBet: false,
  ...defaultNotificationState,
}

let isQuickbetOpen = false

export interface QuickbetStateRecord extends TypedRecord<QuickbetStateRecord>, QuickbetState {}
export const QuickbetStateFactory = makeTypedFactory<QuickbetState, QuickbetStateRecord>(
  defaultQuickbetState
)

const shouldShowPriceChangeMessage = (currentPrices: BetPrices, newPrices: BetPrices): boolean =>
  !!(newPrices.winPrice && currentPrices.winPrice && newPrices.winPrice < currentPrices.winPrice) ||
  !!(
    newPrices.placePrice &&
    currentPrices.placePrice &&
    newPrices.placePrice < currentPrices.placePrice
  )

const getEventId = (stateImmu: QuickbetStateRecord) => {
  const selectionDetails = stateImmu.toJS().selectionDetails

  if (isRaceDetails(selectionDetails)) {
    return selectionDetails.races[0].key
  }

  if (isMysteryDetails(selectionDetails)) {
    return selectionDetails.race.key
  }

  return null
}

export function quickbetDriver(
  state = QuickbetStateFactory(),
  signal: Signal
): QuickbetStateRecord {
  switch (signal.tag) {
    case QuickbetLoadSelection: {
      isQuickbetOpen = true
      const {
        bettingType,
        selection,
        selectionDetails,
        isEachWayAvailable,
        shouldAllowPlaceInvestment,
        presetInvestment = false,
        promptForInvestment = true,
        tags,
        betSource,
        keepSelections,
      } = signal.data as QuickbetSelection

      const isSameRaceMulti = isSameRaceMultiSelection(selection)

      return state.merge({
        ...defaultQuickbetState,
        id: uuidv4(),
        bettingType,
        selection,
        selectionDetails,
        isEachWayAvailable,
        shouldAllowPlaceInvestment,
        presetInvestment,
        promptForInvestment,
        canChangeInvestment: promptForInvestment,
        canProposeBet: promptForInvestment,
        canConfirmBet: !promptForInvestment,
        notificationType: !promptForInvestment ? NotificationType.ProposeSucceeded : null,
        tags,
        isSameRaceMulti,
        betSource: betSource ?? null,
        keepSelections: keepSelections ?? null,
      })
    }

    case ToggleFormula:
    case ChangeInvestment: {
      const currentState: QuickbetState = state.toJS()

      const selection = currentState.selection as MysterySelection

      if (
        currentState.bettingType === 'mystery-quick-pick' ||
        currentState.bettingType === 'mystery-custom-bet'
      ) {
        selection.bets = null
        selection.metaData = null
      }

      state = state.merge({
        selection,
        insufficientFundsForBet: false,
        betCost: 0,
        shortfall: 0,
        ...canChangeInvestmentState,
      })

      // Need to force the Notification and canChangeInvestment as canChangeInvestmentState will clear it
      if (currentState.bettingType === 'mystery-quick-pick') {
        state = state.merge({
          canChangeInvestment: false,
          notificationType: NotificationType.MysteryQuickPickFixedInvestment,
        })
      }

      return state
    }

    case EditBetslipItem: {
      const {
        id,
        selection,
        selectionDetails,
        isEachWay,
        isEachWayAvailable,
        bettingType,
        shouldAllowPlaceInvestment,
        isUsingBonusCash,
      }: BetslipItem = signal.data
      return state.merge({
        ...defaultQuickbetState,
        id,
        selection,
        selectionDetails,
        isEachWayAvailable,
        isEachWay,
        bettingType,
        isBetslipItem: true,
        shouldAllowPlaceInvestment,
        isUsingBonusCash: isUsingBonusCash,
      })
    }

    case SetActiveInvestment: {
      return state.merge({
        ...canChangeInvestmentState,
      })
    }

    case ChangeSelectedSuperPick: {
      const isConfirmationStage = !state.canProposeBet && state.canConfirmBet
      const hasInvalidSuperPickSelection = !state.canChangeInvestment && state.canProposeBet
      if (hasInvalidSuperPickSelection || isConfirmationStage) {
        return state.merge({
          ...canChangeInvestmentState,
        })
      }
      return state
    }

    case SetInvalidInvestmentNotification: {
      const { type, title = null, subtitle = null } = signal.data
      return state.merge({
        notificationType: type,
        notificationTitle: title,
        notificationSubtitle: subtitle,
        canChangeInvestment: false,
      })
    }

    case ToggleBonusBetUsage: {
      var forcedValue = signal.data
      const isUsingBonusBet = forcedValue != undefined ? forcedValue : !state.get('isUsingBonusBet')
      return state.merge({
        isUsingBonusBet,
        isUsingBonusCash: isUsingBonusBet ? false : state.get('isUsingBonusCash'),
      })
    }

    case ToggleBonusCashUsage: {
      var forcedValue = signal.data
      const isUsingBonusCash =
        forcedValue != undefined ? forcedValue : !state.get('isUsingBonusCash')
      return state.merge({
        isUsingBonusCash,
        isUsingBonusBet: isUsingBonusCash ? false : state.get('isUsingBonusBet'),
      })
    }

    case SetAllowInvestmentState: {
      return state.merge({
        shouldAllowPlaceInvestment: signal.data.shouldAllowPlaceInvestment,
        shouldAllowWinInvestment: signal.data.shouldAllowWinInvestment,
      })
    }

    case ToggleEachWay: {
      return state.merge({
        ...canChangeInvestmentState,
        isEachWay: !state.get('isEachWay'),
      })
    }

    case ProposeBet: {
      return state.merge({
        isBusy: true,
      })
    }

    case ProposeBetSucceeded:
    case ProposeBetslipBetsSucceeded: {
      const currentState: QuickbetState = { ...state.toJS() }

      if (isMysterySelection(currentState.selection)) {
        const response = signal.data as MysteryProposeResponse
        const bets: MysterySelection['bets'] = response.bets
        const metaData: string = response.metaData
        const selection = currentState.selection
        selection.bets = bets
        selection.metaData = metaData

        state = state.merge({
          selection,
          isBusy: false,
          canChangeInvestment: false,
          canProposeBet: false,
          canConfirmBet: true,
          notificationType: NotificationType.ProposeSucceeded,
        })

        return state
      }

      if (
        isFobSelection(currentState.selection) &&
        !isStartingPriceSelection(currentState.selection)
      ) {
        const currentPrices: BetPrices = {
          winPrice: currentState.selection.winPrice,
          placePrice: currentState.selection.placePrice,
          previousWinPrice: null,
          previousPlacePrice: null,
        }

        const { winPrice: newWinPrice, placePrice: newPlacePrice } = signal.data.prices
        const newPrices: BetPrices = {
          winPrice: newWinPrice === null ? currentPrices.winPrice : newWinPrice,
          placePrice: newPlacePrice === null ? currentPrices.placePrice : newPlacePrice,
          previousWinPrice: null,
          previousPlacePrice: null,
        }

        const specialOffers = signal.data.specialOffers

        if (specialOffers && specialOffers[0]) {
          UpdateSelectedSuperPick({ specialOffers })
        }
        const showPricesChanged = shouldShowPriceChangeMessage(currentPrices, newPrices)
        return state.merge({
          isBusy: false,
          canChangeInvestment: false,
          canProposeBet: false,
          canConfirmBet: true,
          selection: {
            ...currentState.selection,
            winPrice: newPrices.winPrice,
            placePrice: newPrices.placePrice,
          },
          ...defaultNotificationState,
          notificationType: showPricesChanged
            ? NotificationType.PriceChange
            : NotificationType.ProposeSucceeded,
        })
      }

      state = state.merge({
        isBusy: false,
        canChangeInvestment: false,
        canProposeBet: false,
        canConfirmBet: true,
        notificationType: NotificationType.ProposeSucceeded,
      })

      return state
    }

    case ProposeBetFailed: {
      if (signal.data.code === BetResponseCode.Unauthorized) {
        return state.merge({
          notificationType: NotificationType.Unauthorized,
          isBusy: false,
          canChangeInvestment: false,
        })
      }
      if (signal.data.code === BetResponseCode.HandicapChanged) {
        return state.merge({
          isBusy: false,
          canBet: false,
          notificationType: NotificationType.HandicapChanged,
          canChangeInvestment: false,
          canProposeBet: false,
          canConfirmBet: false,
        })
      }
      // Fallback case for errors like "Max stake exceeded", "event or market closed".
      return state.merge({
        isBusy: false,
        canConfirmBet: false,
        canChangeInvestment: false,
        notificationType: NotificationType.NonHandledError,
        notificationTitle: signal.data.response.message,
        canBet: false,
        canProposeBet: false,
      })
    }

    case InsufficientFundsForBet: {
      const { betCost, shortfall } = signal.data
      return state.merge({
        insufficientFundsForBet: true,
        betCost,
        shortfall,
      })
    }

    case ClearInsufficientFundsForBet: {
      return state.merge({
        insufficientFundsForBet: false,
        betCost: 0,
        shortfall: 0,
      })
    }

    case ConfirmBet: {
      return state.merge({
        isBusy: true,
      })
    }

    case ConfirmBetSucceeded:
    case ConfirmBetslipBetsSucceeded: {
      return state.merge({
        isBusy: false,
        canBet: false,
        canConfirmBet: false,
        betPlaced: true,
        ...defaultNotificationState,
      })
    }

    case ConfirmBetslipBetsFailed: {
      state = state.set('betPlaced', false)
      const betslipError = signal.data as BetError[]

      if (
        betslipError.every(error => toBetErrorType(error.type) === BetErrorType.InsufficientFunds)
      ) {
        return state.merge({
          isBusy: false,
          canConfirmBet: false,
          notificationType: NotificationType.InsufficientFunds,
        })
      }

      if (betslipError.every(error => toBetErrorType(error.type) === BetErrorType.Closed)) {
        const message = betslipError[0]?.message
        return state.merge({
          isBusy: false,
          canConfirmBet: false,
          notificationType: NotificationType.NonHandledError,
          notificationTitle: message,
          canBet: false,
        })
      }

      return state.merge({
        isBusy: false,
        notificationType: NotificationType.NonHandledError,
        notificationTitle: 'Unable to place any bets. Please try again later',
        canConfirmBet: false,
        canBet: false,
      })
    }

    case ConfirmBetFailed: {
      state = state.set('betPlaced', false)

      if (signal.data.code === BetResponseCode.PriceChanged) {
        const currentState: QuickbetState = { ...state.toJS() }

        const currentPrices: BetPrices = {
          winPrice: (currentState.selection as { winPrice: number }).winPrice,
          placePrice: (currentState.selection as { placePrice: number }).placePrice,
          previousWinPrice: null,
          previousPlacePrice: null,
        }

        const { winPrice: newWinPrice, placePrice: newPlacePrice } = signal.data.response.prices
        const newPrices: BetPrices = {
          winPrice: newWinPrice === null ? currentPrices.winPrice : newWinPrice,
          placePrice: newPlacePrice === null ? currentPrices.placePrice : newPlacePrice,
          previousWinPrice: null,
          previousPlacePrice: null,
        }

        if (currentState.bettingType === 'fixed-odds-racing') {
          LoadSuperPicks()
        }

        const showPricesChanged = shouldShowPriceChangeMessage(currentPrices, newPrices)
        state = state.merge({
          isBusy: false,
          selection: {
            ...currentState.selection,
            winPrice: newPrices.winPrice,
            placePrice: newPrices.placePrice,
          },
          ...defaultNotificationState,
          notificationType: showPricesChanged ? NotificationType.PriceChange : null,
        })

        return state
      }
      if (signal.data.code === BetResponseCode.HandicapChanged) {
        return state.merge({
          isBusy: false,
          canBet: false,
          notificationType: NotificationType.HandicapChanged,
          canChangeInvestment: false,
          canConfirmBet: false,
          canProposeBet: false,
        })
      }
      if (signal.data.code === BetResponseCode.InsufficientFunds) {
        return state.merge({
          isBusy: false,
          canConfirmBet: false,
          notificationType: NotificationType.InsufficientFunds,
        })
      }
      if (signal.data.code === BetResponseCode.Unauthorized) {
        return state.merge({
          notificationType: NotificationType.Unauthorized,
          isBusy: false,
          canChangeInvestment: false,
        })
      }
      if (signal.data.code === BetResponseCode.Unauthorized) {
        return state.merge({
          notificationType: NotificationType.Unauthorized,
          isBusy: false,
          canChangeInvestment: false,
        })
      }
      if (signal.data.code === BetResponseCode.NetworkError) {
        return state.merge({
          isBusy: false,
          canBet: false,
          canChangeInvestment: false,
          canConfirmBet: false,
          canProposeBet: false,
          notificationType: NotificationType.NonHandledError,
          notificationTitle: signal.data.response.message,
        })
      }
      // Fallback case for errors like "Max stake exceeded", "event or market closed".
      return state.merge({
        isBusy: false,
        notificationType: NotificationType.NonHandledError,
        notificationTitle: signal.data.response.message,
        canConfirmBet: false,
        canBet: false,
      })
    }

    case DepositFundsDisplayed: {
      return state.merge({
        isBusy: false,
        canProposeBet: false,
        canConfirmBet: true,
        ...defaultNotificationState,
        notificationType: NotificationType.ProposeSucceeded,
      })
    }

    case HasLoggedIn: {
      if (state.get('canProposeBet') && !state.get('canConfirmBet')) {
        if (state.get('bettingType') === 'mystery-quick-pick') {
          return state // Don't do anything for Mystery Quick Pick
        }

        return state.merge({
          ...defaultNotificationState,
          canChangeInvestment: true,
        })
      }
      if (state.get('canConfirmBet') && !state.get('canProposeBet')) {
        return state.merge({
          ...defaultNotificationState,
          notificationType: NotificationType.ProposeSucceeded,
        })
      }
      return state
    }

    case AddingToBetslip: {
      return state.merge({
        isAddingToBetslip: true,
      })
    }

    case QuickbetClosed: {
      isQuickbetOpen = false
      return state.merge({
        tags: [],
        betSource: null,
      })
    }

    case RaceClosedEventReceived: {
      if (!isQuickbetOpen) {
        RegisterToast({
          id: signal.data.toastId,
          message: 'Betting Closed',
          type: 'error',
          timeout: 0,
        })
      }
      if (
        isQuickbetOpen &&
        !state.isBusy &&
        !state.betPlaced &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (isToteSelection((state.selection as any).toJS()) ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          isMysterySelection((state.selection as any).toJS())) &&
        (signal.data.event as ToteRaceStatusChangedPushEvent).raceId.toString() ===
          getEventId(state)
      ) {
        return state.merge({
          isBusy: false,
          canConfirmBet: false,
          canChangeInvestment: false,
          notificationType: NotificationType.RaceClosed,
          canBet: false,
          canProposeBet: false,
        })
      }
      return state
    }

    case SetMysteryQuickPickPresetInvestment: {
      return state.merge({
        canChangeInvestment: false,
        notificationType: NotificationType.MysteryQuickPickFixedInvestment,
      })
    }

    default: {
      return state
    }
  }
}

export const state$ = attachDriver<QuickbetStateRecord>({
  path: 'quickbet',
  driver: quickbetDriver,
})

export const immutableState$ = state$.map(state => state.toJS() as Immutable<QuickbetState>)
