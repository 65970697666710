import React from 'react'
import {
  StructureSearchGroupHeader,
  StructureSearchGroupSubHeader,
} from '../../StructuredSearch.styles'
import {
  ButtonGroupItemStyled,
  ButtonGroupStyled,
} from '@core/Components/Buttons/ButtonGroup.styles'
import {
  state$ as structuredSearchState$,
  StructuredSearchFields,
  StructuredSearchValueChanged,
  defaultState,
  StructuredSearchSetSelectedTracks,
} from '../../Driver/StructuredSearchDriver'
import { useObservableImmutable } from '@core/Utils/hooks'
import { OverlayOpen } from '@core/Components/Overlay'
import { StructuredSearchTrackModal, IconTypes } from './StructuredSearchLocationTrackModal'
import { Chip } from '@core/Components/Buttons/Chips/Chips'
import { ChipContainerStyled } from '@core/Components/Buttons/Chips/Chips.styles'
import { getFilteredTracks } from './track-utils'
import { CloseChipIcon, AddChipIcon, ChipWithRightIcon } from './StructuredSearchLocation.styles'
import { colors } from '@core/Settings'

export function StructuredSearchLocation(): JSX.Element {
  const { searchResults, searchCriteria } = useObservableImmutable(structuredSearchState$, [
    'searchResults',
    'searchCriteria',
  ])
  const searchCriteriaJs = searchCriteria?.toJS()
  const aus = searchCriteriaJs
    ? searchCriteriaJs.locationCountryAustralia
    : defaultState.searchCriteria.locationCountryAustralia
  const nz = searchCriteriaJs
    ? searchCriteriaJs.locationCountryNewZealand
    : defaultState.searchCriteria.locationCountryNewZealand
  const int = searchCriteriaJs
    ? searchCriteriaJs.locationCountryInternational
    : defaultState.searchCriteria.locationCountryInternational
  const selectedTracks = searchCriteriaJs
    ? searchCriteriaJs.selectedTracks
    : defaultState.searchCriteria.selectedTracks

  const onChangeCallback = (
    field: keyof StructuredSearchFields,
    value: string | boolean | Date
  ) => {
    StructuredSearchValueChanged({ field, value })
  }

  const addTrack = () => {
    OverlayOpen(StructuredSearchTrackModal)
  }

  const removeTrack = (id: string) => {
    if (selectedTracks) {
      const newSelectedTracks = selectedTracks.filter(track => track != id)
      StructuredSearchSetSelectedTracks(newSelectedTracks)
    }
  }

  const aggregations = searchResults?.get('aggregations')
  const availableTracks = aggregations ? aggregations.get('tracks').toJS() : null

  let mappedChips = null

  if (selectedTracks) {
    const selectedTracksJS = selectedTracks
    const displayableTracks = getFilteredTracks(availableTracks, selectedTracksJS)
    if (displayableTracks) {
      mappedChips = displayableTracks.map(track => {
        const iconType = IconTypes[track.contestType as keyof typeof IconTypes]
        return (
          <ChipWithRightIcon
            onClick={() => removeTrack(track.id)}
            color='filled'
            key={track.id}
            data-tid-structured-search-location-chip={track.id}
          >
            {track.name}
            {!track.isAustralian && <> ({track.location})</>}

            {track.isDuplicate && (
              <CloseChipIcon
                size='2rem'
                type={iconType}
                color={colors.structuredSearchLocationModal.icon.code}
              />
            )}

            <CloseChipIcon type='remove' size='1.1rem' />
          </ChipWithRightIcon>
        )
      })
    }
  }

  return (
    <>
      <StructureSearchGroupHeader>Location</StructureSearchGroupHeader>
      <ButtonGroupStyled>
        <ButtonGroupItemStyled
          id='location-country-australia'
          data-tid-location-country='Australia'
          selected={aus}
          onClick={() => onChangeCallback('locationCountryAustralia', !aus)}
        >
          Australia
        </ButtonGroupItemStyled>
        <ButtonGroupItemStyled
          id='location-country-new-zealand'
          data-tid-location-country='New Zealand'
          selected={nz}
          onClick={() => onChangeCallback('locationCountryNewZealand', !nz)}
        >
          New Zealand
        </ButtonGroupItemStyled>
        <ButtonGroupItemStyled
          id='location-country-international'
          data-tid-location-country='International'
          selected={int}
          onClick={() => onChangeCallback('locationCountryInternational', !int)}
        >
          International
        </ButtonGroupItemStyled>
      </ButtonGroupStyled>

      <StructureSearchGroupSubHeader>Track</StructureSearchGroupSubHeader>

      <ChipContainerStyled>
        {mappedChips}
        <Chip
          onClick={addTrack}
          color='hollow'
          data-tid-structured-search-location-chip='add-track'
        >
          <AddChipIcon type='add' size='1.2rem' /> Add a Track
        </Chip>
      </ChipContainerStyled>
    </>
  )
}
