import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { Text } from '@core/Components/Text'
import { closeQuickDeposit } from '@core/Areas/QuickDeposit/Store'
import { ChangeInvestment } from '@core/Areas/Quickbet/signals'
import {
  DepositButtonComponent,
  EditBetButtonComponent,
} from '@core/Areas/Quickbet/Components/Buttons/Buttons'
import { openDepositModal } from '@core/Areas/Deposit'
import {
  trackQuickDepositEditBetClicked,
  trackQuickDepositOtherDepositClicked,
} from '@core/Areas/QuickDeposit/analytics'

type Props = {
  lastUsedPaymentMethod: PaymentMethods
  isBusy: boolean
}

export const QuickDepositActions = ({ lastUsedPaymentMethod, isBusy }: Props) => {
  const dispatch = useDispatch()

  return (
    <Grid padding='0.5rem'>
      <GridCell>
        <EditBetButtonComponent
          isDisabled={isBusy}
          onClick={() => {
            trackQuickDepositEditBetClicked(lastUsedPaymentMethod)
            ChangeInvestment()
            dispatch(closeQuickDeposit())
          }}
        />
      </GridCell>

      <GridCell>
        <DepositButtonComponent
          color='secondary'
          isDisabled={isBusy}
          onClick={() => {
            trackQuickDepositOtherDepositClicked(lastUsedPaymentMethod)
            ChangeInvestment()
            openDepositModal('quick-deposit')
            dispatch(closeQuickDeposit())
          }}
        >
          <Text>Other Deposit</Text>
        </DepositButtonComponent>
      </GridCell>
    </Grid>
  )
}
