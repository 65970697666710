import React from 'react'
import { SearchLoadingStyled } from './SearchLoading.styles'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

export function SearchLoading() {
  return (
    <SearchLoadingStyled data-testid='search-loading'>
      <LoadingPlaceholder width='auto' height='2.5em' />
      <LoadingPlaceholder width='auto' height='2.5em' />
      <LoadingPlaceholder width='auto' height='2.5em' />
      <LoadingPlaceholder width='auto' height='2.5em' />
    </SearchLoadingStyled>
  )
}
