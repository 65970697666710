import colors from '@core/Settings/colors'
import { RulesThemeSettings } from '@core/Components/InteractiveRules/InteractiveRules'

interface ThemeSettings {
  background: string
  text: {
    color?: string
    size?: string
  }
  color: string
  margin: string
  padding: string
  size: string
  top: string
}

export enum PasswordThemes {
  light = 'light',
  lightSignup = 'lightSignup',
  dark = 'dark',
}
export interface PasswordComponentTheme {
  label: ThemeSettings
  input: ThemeSettings
  rules: RulesThemeSettings
  icon: ThemeSettings
  toggle: ThemeSettings
}

export const lightTheme: PasswordComponentTheme = {
  label: {
    text: {
      color: colors.password.light.label.text,
    },
  } as ThemeSettings,
  input: {
    background: colors.password.light.input.background,
  } as ThemeSettings,
  toggle: {
    top: '0.75rem',
  } as ThemeSettings,
  rules: {
    text: {
      color: colors.password.light.rules.text,
      size: '1.4rem',
    },
    margin: '1rem 0 1.5rem 0',
  } as RulesThemeSettings,
  icon: {
    text: {
      color: colors.password.light.icon.text,
    },
  } as ThemeSettings,
}

const lightSignup: PasswordComponentTheme = {
  label: {
    text: {
      color: colors.password.light.label.text,
      size: '1em',
    },
  } as ThemeSettings,
  input: {
    text: {
      size: '0.85em',
    },
    background: colors.password.light.input.background,
    padding: '0.5em',
    margin: '0.25em 0 0.5em 0',
  } as ThemeSettings,
  toggle: {
    top: '0.3rem',
  } as ThemeSettings,
  rules: {
    text: {
      color: colors.password.light.rules.text,
      size: '1rem',
    },
    margin: '1rem 0 1.5rem 0',
    tickIcon: {
      size: '1rem',
    },
  } as RulesThemeSettings,
  icon: {
    color: colors.password.light.icon.text,
    size: '1.2em',
    text: {
      size: '1.2em',
    },
  } as ThemeSettings,
}

export const darkTheme: PasswordComponentTheme = {
  label: {
    background: colors.password.dark.label.background,
    text: {
      color: colors.password.dark.label.text,
    },
  } as ThemeSettings,
  input: {
    background: colors.password.dark.input.background,
  } as ThemeSettings,
  toggle: {
    top: '0.75rem',
  } as ThemeSettings,
  rules: {
    text: {
      color: colors.password.dark.rules.text,
    },
    margin: '1rem 0 1.5rem 0',
    background: colors.password.dark.icon.background,
  } as RulesThemeSettings,
  icon: {
    text: {
      color: colors.password.dark.icon.text,
    },
    background: colors.password.dark.icon.background,
  } as ThemeSettings,
}

export function getPasswordTheme(theme?: PasswordThemes): PasswordComponentTheme {
  switch (theme) {
    case PasswordThemes.light:
      return lightTheme
    case PasswordThemes.lightSignup:
      return lightSignup
    case PasswordThemes.dark:
      return darkTheme
    default:
      return lightTheme
  }
}
