import { post } from '@classic/Foundation/Services/ApiService'
import { getRacePageDataFromApi } from '@core/Areas/Racing/Hooks/useRacePageData'
import type { Ticket, TicketLeg } from '@core/Areas/AccountActivity/types'
import { ReplayInfo, VideoEntitlementDto } from './types'

export async function getReplayInfoFromApi(ticket: Ticket) {
  const leg = ticket.legs[0]
  const raceReplay = await getRaceReplayDataFromApi(leg)

  if (!raceReplay || !raceReplay.HasVideo) {
    return null
  }

  const entitlement = await getEntitlementUrlFromApi(raceReplay.VideoKey)

  if (!entitlement) {
    return null
  }

  const result: ReplayInfo = {
    raceLocation: raceReplay.RaceLocation,
    raceDate: raceReplay.Date,
    videoSource: entitlement.VideoKey,
  }

  return result
}

function getEntitlementUrlFromApi(videoKey: string) {
  return post<VideoEntitlementDto>({
    url: '/api/videoentitlement',
    body: { encrpytedVideoKey: videoKey },
  })
}

function getRaceReplayDataFromApi(leg: TicketLeg) {
  if (!leg.fixtureId) {
    return Promise.resolve(null)
  }

  return getRacePageDataFromApi(leg.fixtureId, leg.fixtureDate, leg.raceNumber).then(meeting => {
    if ('RaceReplay' in meeting) {
      return meeting.RaceReplay
    } else {
      return null
    }
  })
}
