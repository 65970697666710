import * as ko from 'knockout'
import template from './race.starter.tpl.html'
import { IRaceStarterViewModel } from './IRaceStarterViewModel'
import container from '@classic/Betting-v2/IOC/inversify.config'

if (!ko.components.isRegistered('race-starter')) {
  ko.components.register('race-starter', {
    viewModel: {
      createViewModel: (
        params: Parameters<IRaceStarterViewModel['init']>[0]
      ): IRaceStarterViewModel => {
        const viewModel = container.get<IRaceStarterViewModel>('IRaceStarterViewModel')
        viewModel.init(params)
        return viewModel
      },
    },
    template,
  })
}
