import styled from '@emotion/styled'
import { GridCellProps, GridProps } from './Grid'

const supportMap = {
  valign: {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
  },
  align: {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
  },
}

export const GridStyled = styled('div')<GridProps>(
  {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ({ padding }) =>
    padding
      ? {
          margin: `-${padding}`,
          width: 'auto',
          flex: 1,
        }
      : {
          width: '100%',
        },

  ({ margin, maxWidth }) => ({
    margin,
    maxWidth,
  })
)

export const GridCellStyled = styled('div')<GridCellProps>(
  {
    display: 'flex',
    flex: 1,
  },
  ({ width, valign = 'top', align = 'left', minWidth, flexGrow, flexBasis }) => ({
    minWidth: width || minWidth || 0,
    maxWidth: width || 'none',
    alignItems: supportMap.valign[valign],
    justifyContent: supportMap.align[align],
    textAlign: align || 'left',
    flexGrow,
    flexBasis,
  })
)

export const GridCellInnerStyled = styled('div')<GridCellProps>(
  {
    boxSizing: 'border-box',
  },
  ({ align }) => ({
    width: align ? 'auto' : '100%',
  }),
  ({ padding }) => ({
    padding: padding || undefined,
  })
)

export const GridRowStyled = GridStyled.withComponent('div')
