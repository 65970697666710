import { state$ as kambiState$ } from '@core/Areas/Kambi/driver'
import { kambiLog } from '@core/Areas/Kambi/helpers'
import {
  KAMBI_BETSLIP_DIV_CLASS,
  KAMBI_CSS_HIDDEN_CLASS,
  KAMBI_DIV_ID,
} from '@core/Areas/Kambi/constants'

let hasSetInitialVisibilityState = false

export function setInitialVisibilityState(wapi: Wapi): void {
  if (hasSetInitialVisibilityState) return

  kambiState$.take(1).subscribe(kambiState => {
    const isVisible = kambiState.get('isVisible')
    setKambiClientVisibility(isVisible, wapi)
    setKambiBetslipVisibility(isVisible, wapi)
    hasSetInitialVisibilityState = true
  })
}

export function toggleKambiVisiblity(isVisible: boolean): void {
  if (!hasSetInitialVisibilityState || !window.wapi) return

  setKambiClientVisibility(isVisible, window.wapi)
  kambiLog('set client visibility', isVisible)

  setKambiBetslipVisibility(isVisible, window.wapi)
  kambiLog('set betslip visibility', isVisible)
}

// Local Helpers
function setKambiClientVisibility(isVisible: boolean, wapi: Wapi) {
  // Hidden class used on init to prevent client jump
  isVisible && document.getElementById(KAMBI_DIV_ID)?.classList.remove(KAMBI_CSS_HIDDEN_CLASS)

  wapi.set(isVisible ? wapi.CLIENT_SHOW : wapi.CLIENT_HIDE)
}

function setKambiBetslipVisibility(isVisible: boolean, wapi: Wapi) {
  // Ensure Kambi betslip doesn't display on non-kambi routes (due to fixed positon)
  document
    .querySelector(`.${KAMBI_BETSLIP_DIV_CLASS}`)
    ?.classList.toggle(KAMBI_CSS_HIDDEN_CLASS, !isVisible)

  wapi.set(isVisible ? wapi.BETSLIP_SHOW : wapi.BETSLIP_HIDE)
}
