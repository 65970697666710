type PossibleError = string | Pick<Error, 'message'>

export function unwrapErrorMessage(error: PossibleError): string {
  if (typeof error === 'string') {
    return error
  }

  return error.message
}

export function coerceIntoError(error: unknown): Error {
  if (error instanceof Error) {
    return error
  }

  if (typeof error === 'string') {
    return new Error(error)
  }

  return new Error(JSON.stringify(error))
}
