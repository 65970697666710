import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: BlackbookState = {
  sortOrder: 'Start Time',
  sortOrderJockeys: 'Start Time',
}

const blackbookSlice = createSlice({
  name: 'blackbook',
  initialState,
  reducers: {
    setSortOrder(state, { payload }: PayloadAction<BlackbookState['sortOrder']>) {
      state.sortOrder = payload
    },
    setSortOrderJockeys(state, { payload }: PayloadAction<BlackbookState['sortOrderJockeys']>) {
      state.sortOrderJockeys = payload
    },
  },
})

export const { setSortOrder, setSortOrderJockeys } = blackbookSlice.actions

export default persistReducer(
  {
    key: 'blackbook',
    storage,
    whitelist: ['sortOrder'],
    debug: !PRODUCTION,
  },
  blackbookSlice.reducer
)

// =====
// Types
// =====

export interface BlackbookState {
  sortOrder: 'Alphabetical' | 'Start Time' | 'Win Price ▲' | 'Win Price ▼'
  sortOrderJockeys: 'Alphabetical' | 'Start Time'
}
