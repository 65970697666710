import { scriptLoader } from '@mobi/utils'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { isUserAccountStatePending, isUserResidentOfSA } from '@core/Areas/Kambi/helpers'

export type BootstrapJurisdiction = 'WA' | 'SA'

// Load scripts with correct bootstrap based on jurisdiction
export async function handleScriptLoading(): Promise<BootstrapJurisdiction> {
  const userAccountStateRecord = await userAccountState$
    .skipWhile(isUserAccountStatePending)
    .take(1)
    .toPromise()

  const shouldLoadBootstrapSA = isUserResidentOfSA(userAccountStateRecord.residentialDetail)

  window._kc.offering = shouldLoadBootstrapSA ? 'rwwasa' : 'rwwawa'

  const cacheBust = new Date().getTime()

  const subDomain = window._kc.environment === 'ctn' ? 'client-static.bc.ctn.' : 'client-static.bc.'

  const generateUrl = (scriptName: string) =>
    `https://${subDomain}kambicdn.com/client/${window._kc.offering}/${scriptName}?cb=${cacheBust}`

  await scriptLoader(generateUrl('main.js'))

  await scriptLoader(generateUrl('remoteEntry.js'))

  await scriptLoader('https://static.kambicdn.com/client/widget-api/kambi-widget-api.js')

  return shouldLoadBootstrapSA ? 'SA' : 'WA'
}
