import React, { type FC, memo } from 'react'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'
import {
  PanelStyled as Panel,
  PanelIconStyled as Icon,
  PanelHeaderStyled as Header,
  PanelSubHeaderStyled as Subheader,
} from './Workflow.styled'

type Props = { message: string }

const Component: FC<Props> = ({ message }) => {
  return (
    <Panel>
      <Icon role='img' backgroundColor='none'>
        <LoadingSpinner size='100%' />
      </Icon>
      <Header>Processing Payment</Header>
      <Subheader>{message}</Subheader>
    </Panel>
  )
}

Component.displayName = 'BusyPanel'

export const BusyPanel = memo(Component)
