import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { measurements } from '@mobi/component-library/Theme/Common'
import { colors, fontSizes } from '@core/Settings'

export const FooterStyled = styled.div({
  height: 'auto',
  position: 'fixed',
  zIndex: 1,
  bottom: 0,
  left: 0,
  right: 0,
  maxWidth: measurements.mobi.maxWidth,
  width: '100%',
  margin: '0 auto',
  lineHeight: 1,
})

export const SummaryStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: colors.betslip.summary.background,
  color: colors.betslip.summary.color,
  padding: '1rem',
  width: '100%',
  height: '8rem',
  boxSizing: 'border-box',
})

export const FooterSummaryValueStyled = styled.span({})

export const FooterSummaryHeadingStyled = styled.span({
  fontSize: fontSizes.betslip.summary.fontSize,
  color: colors.betslip.summary.title.text,

  '> span': {
    fontSize: fontSizes.betslip.summary.fontSizeMedium,
    fontWeight: 'bold',
    color: colors.betslip.summary.value.text,
    margin: 0,
  },
})

export const MessagesContainerStyled = styled.div({
  backgroundColor: colors.betslip.errors.container.background,
  padding: '1rem',
  overflow: 'auto',
})

export const FooterNoticeBoxWrapper = styled.div({
  backgroundColor: colors.betslip.errors.noticeBox.background,
  color: colors.betslip.errors.noticeBox.text,

  '& > div': {
    marginBottom: 0,
    borderBottom: `1px solid ${transparentize(0.5, colors.betslip.errors.container.background)}`,
  },

  '& > div:last-child': {
    borderBottom: 'none',
  },
})
