import styled from '@emotion/styled'
import { modalXPadding } from '../../Quickbet.styles'

export const BonusBetListContainerStyled = styled('div')({
  margin: `0 -${modalXPadding}`,
  overflow: 'auto',
})

export const BonusBetListItemContainerStyled = styled('div')({
  margin: `0 ${modalXPadding}`,
  overflow: 'auto',
})
