import React from 'react'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { hexColors } from '@mobi/settings'
import {
  WinPlaceSelectionButtonStyled,
  WinPlaceSelectionButtonTitleStyled,
  WinPlaceSelectionFavouriteStyled,
} from './WinPlaceSelection.styles'
import { PriceChangeDisplay } from '../PriceChangeDisplay'

export interface WinPlaceSelection {
  isVisible: boolean
  isDisabled: boolean
  isFavourite: boolean
  isSelected: boolean
  selectionName?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  priceWin: string
  pricePlace: string
  starterNumber: number
  tidAttribute?: string
  favouritePosition?: 'left' | 'right'
  buttonStyleOverride?: string
  centerContent?: boolean
  arrowLocation?: 'IN' | 'OUT'
  title?: string
}

export const WinPlaceSelection = ({
  isVisible,
  isDisabled,
  isFavourite,
  isSelected,
  selectionName,
  onClick,
  priceWin,
  pricePlace,
  starterNumber,
  buttonStyleOverride,
  tidAttribute = '',
  favouritePosition = 'right',
  centerContent = false,
  arrowLocation = 'OUT',
  title = undefined,
}: WinPlaceSelection) => {
  if (!isVisible) {
    return null
  }

  const extra: { [key: string]: string } = {}
  if (selectionName) extra['data-tid-selection'] = selectionName

  return (
    <WinPlaceSelectionButtonStyled
      isSelected={isSelected}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={onClick}
      className={buttonStyleOverride}
      {...{ [tidAttribute]: starterNumber }}
      {...extra}
    >
      {title && (
        <WinPlaceSelectionButtonTitleStyled isContentCentered={centerContent}>
          <div>{title}</div>
        </WinPlaceSelectionButtonTitleStyled>
      )}

      <PriceChangeDisplay
        priceWin={priceWin}
        pricePlace={pricePlace}
        isSelected={isSelected}
        isContentCentred={centerContent}
        arrowLocation={arrowLocation}
      />

      {isFavourite && (
        <WinPlaceSelectionFavouriteStyled favouritePosition={favouritePosition}>
          <Icon name='SolidStar01' color={hexColors.orange} size='1.5rem' />
        </WinPlaceSelectionFavouriteStyled>
      )}
    </WinPlaceSelectionButtonStyled>
  )
}
