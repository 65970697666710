import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '@core/Settings/index'
import {
  AvailableSuperPickBadgeStyled,
  DisabledSuperPickBadgeStyled,
  SelectedSuperPickTextStyled,
  AvailableSuperPickTextStyled,
  AvailableSuperPickIconStyled,
} from './SuperPick.styles'

interface SuperPickAvailableProps {
  disabled?: boolean
  onClick: () => void
  isEditable: boolean
}
export function SuperPickAvailable({ disabled, onClick, isEditable }: SuperPickAvailableProps) {
  if (disabled) {
    return (
      <DisabledSuperPickBadgeStyled data-tid-superpick-available-button=''>
        <SelectedSuperPickTextStyled>SuperPick</SelectedSuperPickTextStyled>
      </DisabledSuperPickBadgeStyled>
    )
  }
  return (
    <AvailableSuperPickBadgeStyled onClick={onClick} isEditable={isEditable}>
      <AvailableSuperPickIconStyled>
        <Icon size='1.5rem' type='specials' color={colors.specialIndicator.foreground} />
      </AvailableSuperPickIconStyled>
      <AvailableSuperPickTextStyled>SuperPick</AvailableSuperPickTextStyled>
    </AvailableSuperPickBadgeStyled>
  )
}
