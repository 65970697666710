import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IDogFinisherViewModel } from './IDogFinisherViewModel'
import template from './dog.finisher.tpl.html'
import ObservableDogFinisher from '@classic/Betting-v2/Model/Observables/ObservableDogFinisher'

export function register(): void {
  if (!ko.components.isRegistered('dog-finisher')) {
    ko.components.register('dog-finisher', {
      viewModel: {
        createViewModel: (params: {
          finisher: ObservableDogFinisher
          showField: boolean
        }): IDogFinisherViewModel => {
          const viewModel = container.get<IDogFinisherViewModel>('IDogFinisherViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
