import React from 'react'
import { Popover } from '@mobi/component-library/Overlays/Popover'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import type { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'
import { BlackbookJockey } from './BlackbookJockey'
import { BlackbookMenuButton } from './BlackbookMenuButton'

export const BlackbookMenu: React.FC<BlackbookMenuProps> = ({
  blackbookBtnRef,
  starterName,
  starterType,
  fixtureId,
  fixtureDate,
  raceNumber,
  source,
}) => {
  return (
    <Popover referenceElRef={blackbookBtnRef} isOpen placement='top'>
      <div data-testid='blackbook-button-menu'>
        <div>
          <BlackbookMenuButton
            starterName={starterName}
            starterType={starterType}
            entryType='Runner'
            isReady
            source={source}
          />

          {starterType === 'Races' && (
            <BlackbookJockey
              starterName={starterName}
              fixtureId={fixtureId}
              fixtureDate={fixtureDate}
              raceNumber={raceNumber}
              source={source}
            />
          )}
        </div>
      </div>
    </Popover>
  )
}

// =====
// Types
// =====

type BlackbookMenuProps = Parameters<typeof useAddToBlackbook>[0] &
  Pick<Ticket['legs'][0], 'fixtureId' | 'fixtureDate' | 'raceNumber'> & {
    blackbookBtnRef: React.RefObject<HTMLButtonElement>
  }
