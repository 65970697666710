import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { registerBodyScrollLock, deregisterBodyScrollLock } from '@mobi/utils/functions'
import {
  GiddyUpTabChanged,
  QuaddieValueChanged,
} from '@classic/AppUtils/Framework/Intent/giddyUpSignals'
import { setGiddyUpTab, setGiddyUpQuaddieValue } from '@classic/Foundation/Analytics/Analytics'
import { MainContainer } from '@core/Components/Containers'
import { OverlayClose } from '@core/Components/Overlay'
import {
  OverlayCloseButtonStyled,
  OverlayHeaderStyled,
  OverlayHeadingStyled,
} from '@core/Components/Overlay/Overlay.styles'

const GiddyUpSliceLazy = React.lazy(() => import('@rwwa/racing-form/WebClient/dist/giddyUp'))

const Loading = () => null

export const GiddyUpSlice: React.FC<React.ComponentProps<typeof GiddyUpSliceLazy>> = ({
  raceInfo,
}) => {
  const containerElementRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!containerElementRef.current) return

    const containerElement = containerElementRef.current
    registerBodyScrollLock(containerElement)

    return () => deregisterBodyScrollLock(containerElement)
  }, [])

  React.useEffect(() => {
    const tabChangeSubscription = GiddyUpTabChanged.signal$.subscribe(x => {
      setGiddyUpTab(x.data)
    })
    const quaddieChangeSubscription = QuaddieValueChanged.signal$.subscribe(x => {
      setGiddyUpQuaddieValue(x.data)
    })

    return () => {
      tabChangeSubscription.dispose()
      quaddieChangeSubscription.dispose()
    }
  }, [])

  return (
    <div ref={containerElementRef} style={{ overflowY: 'auto' }}>
      <OverlayHeaderStyled>
        <OverlayHeadingStyled>Giddy-Up</OverlayHeadingStyled>
        <OverlayCloseButtonStyled data-testid='close-button' onClick={OverlayClose}>
          <Icon type='cross' title='Close' />
        </OverlayCloseButtonStyled>
      </OverlayHeaderStyled>

      <MainContainer background='grey'>
        <React.Suspense fallback={<Loading />}>
          <GiddyUpSliceLazy raceInfo={raceInfo} />
        </React.Suspense>
      </MainContainer>
    </div>
  )
}
