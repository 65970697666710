import React from 'react'
import { toTitleCase } from '@mobi/utils/string'
import type { BamAccountHolder } from '@mobi/api-types'
import { Paragraph } from '@core/Components/Text/Paragraph'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { InfoCopyStyled as InfoCopy } from './DepositLimitsAnniversaryPopup.styles'

export interface AnniversaryPopupContentProps {
  isDesktop?: boolean
  accountHolder: BamAccountHolder | null
  onKeepLimit(): void
  onChangeLimit(): void
}

export function AnniversaryPopupContent(props: AnniversaryPopupContentProps) {
  const { isDesktop, accountHolder, onKeepLimit, onChangeLimit } = props

  if (accountHolder === null) {
    return null
  }

  return (
    <React.Fragment>
      <Paragraph data-tid-anniversary-introduction='' isDesktop={isDesktop}>
        <strong>Hi {accountHolder.FullName}</strong>, to continue betting, please review your
        deposit limit.
      </Paragraph>
      <NoticeBoxSingle
        title={
          <Paragraph isDesktop={isDesktop} data-tid-current-limit=''>
            <CurrentLimit {...accountHolder} />
          </Paragraph>
        }
        noticeBoxType={NoticeBoxTypes.Info}
        hasBorder={true}
        testId='current-limit'
        isDesktop={isDesktop}
      />
      <Grid padding='0.4rem'>
        <GridRow>
          <GridCell>
            <ButtonBlock
              onClick={onKeepLimit}
              color='primary'
              testId='anniversary-keep'
              isDesktop={isDesktop}
            >
              Keep
            </ButtonBlock>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell>
            <ButtonBlock
              onClick={onChangeLimit}
              color='secondary'
              testId='anniversary-change'
              isDesktop={isDesktop}
            >
              Change
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
      <InfoCopy isDesktop={isDesktop} data-tid-why-ask=''>
        <strong>Why do we ask this?</strong>
        <br />
        At TABtouch we support responsible wagering. To help you manage your betting, this is your
        annual reminder to review your deposit limit. You can also review this at any time by
        visiting deposit limit in My Account menu.
      </InfoCopy>
    </React.Fragment>
  )
}

type ExistingDepositLimitProps = Pick<
  BamAccountHolder,
  'PreCommitmentMaxDepositAmount' | 'PreCommitmentMaxDepositFrequency'
>

function CurrentLimit(props: ExistingDepositLimitProps) {
  return props.PreCommitmentMaxDepositAmount && props.PreCommitmentMaxDepositFrequency ? (
    <ExistingDepositLimit {...props} />
  ) : (
    <NoDepositLimit />
  )
}

export function ExistingDepositLimit({
  PreCommitmentMaxDepositAmount,
  PreCommitmentMaxDepositFrequency,
}: ExistingDepositLimitProps) {
  return (
    <span data-tid-current-value>
      Currently set to{' '}
      <strong>
        ${PreCommitmentMaxDepositAmount} {toTitleCase(PreCommitmentMaxDepositFrequency || '')}
      </strong>
    </span>
  )
}

export function NoDepositLimit() {
  return (
    <span data-tid-no-limit=''>
      <strong>No deposit limit set</strong>
    </span>
  )
}
