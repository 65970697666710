import React from 'react'
import { NextRaceLoading } from '../NextRaceLoading/NextRaceLoading'
import { MaxNumberOfRaces } from '../../utils'
import { Flex } from '@mobi/component-library/Common/Flex'

export function NextRacesLoading(): JSX.Element {
  const races = Array.apply(0, new Array(MaxNumberOfRaces)).map((_, i: number) => (
    <NextRaceLoading key={i} />
  ))

  return <Flex flexDirection='column'>{races}</Flex>
}
