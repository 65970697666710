import * as ko from 'knockout'
import moment from 'moment'
import countdown from 'countdown'
import { injectable, inject } from 'inversify'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { TimeSpanCalculator } from '@classic/Betting-v2/Utils/TimeSpanCalculator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import * as DateTimeProvider from '@classic/Foundation/DateTimeProvider'

interface RaceSubHeaderViewModelParams {
  race: ObservableRaceKey
  context: { bettingContext: BettingInformation; legVisible: ko.Observable<number> }
}

@injectable()
export class RaceSubHeaderViewModel extends Disposable {
  public race: ObservableRaceKey
  public formattedStartTime: ko.PureComputed<string>
  public currentCountDown: ko.Observable<string>
  public bulbColour: ko.Observable<string>
  public bettingContext: BettingInformation
  public legVisible: ko.Observable<number>
  public raceSelectionString: ko.Observable<string>
  public showTapToSelect: ko.PureComputed<boolean>
  public isCurrentRace: ko.PureComputed<boolean>

  constructor(
    @inject('$params') params: RaceSubHeaderViewModelParams,
    @inject('IEventAggregator') eventAggregator: IEventAggregator
  ) {
    super(eventAggregator)

    this.race = params.race
    this.legVisible = params.context.legVisible
    this.bettingContext = params.context.bettingContext

    this.raceSelectionString = ko.observable<string>('')

    this.formattedStartTime = ko.pureComputed<string>(() => {
      var rt = moment(params.race.startTime())
      return `${rt.format('HH:mm')}`
    })

    this.currentCountDown = ko.observable<string>('')
    this.bulbColour = ko.observable<string>('green')

    this.showTapToSelect = ko.pureComputed<boolean>((): boolean => {
      return this.raceSelectionString().length === 0 && this.race.leg() !== this.legVisible()
    })

    this.isCurrentRace = ko.pureComputed<boolean>(() => {
      return this.race.leg() === this.legVisible()
    })

    this.initialiseTimer()

    this.registerDisposals(() => {
      this.formattedStartTime.dispose()
      if (this.selectionsChangedSubscription != undefined) {
        this.selectionsChangedSubscription.dispose()
      }
      if (this.selectedPoolSubscription !== undefined) {
        this.selectedPoolSubscription.dispose()
      }
      this.showTapToSelect.dispose()
      clearInterval(this.timerId)
    })

    this.registerHandlers()
  }

  private registerHandlers() {
    let raceSelectionsObject = this.bettingContext.selections
      .selections()
      .find(s => s.raceKey().raceNumber() === this.race.raceNumber())

    if (raceSelectionsObject != undefined) {
      this.selectionsChangedSubscription = raceSelectionsObject.starters.subscribe(() => {
        this.raceSelectionString(this.selectionStringDisplay())
      })

      this.raceSelectionString(this.selectionStringDisplay())
    }

    if (
      this.bettingContext.selectedBetType().isAllUp() &&
      this.race.poolInfo.selectedPool !== undefined
    ) {
      this.selectedPoolSubscription = this.race.poolInfo.selectedPool.subscribe(() => {
        this.raceSelectionString(this.selectionStringDisplay())
      })

      this.raceSelectionString(this.selectionStringDisplay())
    }
  }

  private selectionStringDisplay() {
    const result = this.bettingContext
      .selectedBetType()
      .selectionStringProcessor.selections(
        this.bettingContext,
        this.race.raceNumber(),
        this.bettingContext.selections
      )
    let leadText =
      result.selectionStrings[0].length > 0 &&
      this.bettingContext.selectedBetType().isAllUp() &&
      this.race.poolInfo.selectedPool() !== undefined
        ? this.race.poolInfo.selectedPool().description() + ' - '
        : ''
    return leadText + result.selectionStrings[0]
  }

  public toggle(): void {
    if (this.legVisible() != this.race.leg()) {
      this.legVisible(this.race.leg())
    } else {
      this.legVisible(-1)
    }
  }

  protected initialiseTimer(): void {
    const raceStartTime = this.race.startTime()
    this.updateTimer(raceStartTime)
    this.timerId = window.setInterval(() => this.updateTimer(raceStartTime), 1000)
  }

  private updateTimer(raceStartTime: Date): void {
    const now = new Date(DateTimeProvider.now())
    const timeSpan = TimeSpanCalculator.process(
      countdown(
        raceStartTime,
        now,
        countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
      ) as countdown.Timespan
    )
    this.currentCountDown(timeSpan.text)
    this.bulbColour(timeSpan.color)
  }

  private selectedPoolSubscription!: ko.Subscription
  private selectionsChangedSubscription!: ko.Subscription
  private timerId!: number
}
