import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BetStatus, Ticket } from '@core/Areas/AccountActivity/types'
import {
  LinkWrapper,
  StatusPillStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { StatusInfoStyled } from './Multi/Multi.styles'

const HeaderWrapperStyled = styled('div')({
  display: 'flex',
  marginBottom: '1rem',
})

const HeaderStyled = styled('div')({
  paddingRight: '2rem',
  minWidth: '5rem',

  '& > div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '2.5rem',
  },

  '& > div:not(:first-of-type)': {
    marginTop: '-0.5rem',
  },

  span: {
    fontSize: '1.1rem',
    color: hexColors.grey,
    lineHeight: 1.2,
  },
})

/** Conditional Wrapper for Selection Header */
export const HeaderWrapper: React.FC<{
  header: string | null
  subHeader?: string | null
  betStatus?: BetStatus
  leg: Ticket['legs'][0]
  raceNumber: number
}> = ({ header, subHeader, betStatus, leg, raceNumber, children }) => {
  if (!header) {
    return <div>{children}</div>
  }

  return (
    <HeaderWrapperStyled>
      <HeaderStyled>
        <div data-testid='details-selection-info-header'>
          <LinkWrapper
            fixtureId={leg.fixtureId}
            fixtureDate={leg.fixtureDate}
            raceNumber={raceNumber}
            label={header}
          />
        </div>
        {subHeader && (
          <div data-testid='details-selection-info-sub-header'>
            <span>{subHeader}</span>
          </div>
        )}
      </HeaderStyled>

      <div style={{ flex: 1 }}>{children}</div>

      {betStatus && (
        <StatusInfoStyled>
          <StatusPillStyled status={betStatus} size='small' data-tid-leg-status>
            {betStatus}
          </StatusPillStyled>
        </StatusInfoStyled>
      )}
    </HeaderWrapperStyled>
  )
}
