import { RacePageDataTransferObject, ResultsPageDataTransferObject } from './types'

export const isRacePageDto = (
  value: RacePageDataTransferObject | ResultsPageDataTransferObject
): value is RacePageDataTransferObject => {
  return value.Tag === 'Starters'
}

export const isResultsPageDto = (
  value: RacePageDataTransferObject | ResultsPageDataTransferObject
): value is ResultsPageDataTransferObject => {
  return value.Tag === 'Results'
}
