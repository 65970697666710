import React from 'react'

export const GooglePayLogo = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='google-pay-logo-title'
      aria-describedby='google-pay-logo-description'
      role='graphics-symbol'
    >
      <title id='google-pay-logo-title'>Google Pay Logo</title>
      <desc id='google-pay-logo-description'>
        The logo for Google Pay, depicting Google&apos;s logo along with the word &quot;Pay&quot; to
        the right.
      </desc>
      <path
        d='M35.234 11H12.766C5.74468 11 0 16.7447 0 23.766C0 30.7873 5.74468 36.5319 12.766 36.5319H35.234C42.2554 36.5319 48 30.7873 48 23.766C48 16.7447 42.2554 11 35.234 11Z'
        fill='white'
      />
      <path
        d='M35.234 12.034C36.8107 12.034 38.3426 12.3468 39.7852 12.9596C41.183 13.5532 42.4339 14.4021 43.5192 15.4809C44.5979 16.5596 45.4468 17.817 46.0404 19.2148C46.6533 20.6574 46.966 22.1893 46.966 23.766C46.966 25.3426 46.6533 26.8745 46.0404 28.3171C45.4468 29.7149 44.5979 30.966 43.5192 32.0511C42.4404 33.1298 41.183 33.9787 39.7852 34.5723C38.3426 35.1852 36.8107 35.498 35.234 35.498H12.766C11.1894 35.498 9.65744 35.1852 8.21489 34.5723C6.81701 33.9787 5.56595 33.1298 4.48086 32.0511C3.40213 30.9723 2.55319 29.7149 1.95957 28.3171C1.34681 26.8745 1.03405 25.3426 1.03405 23.766C1.03405 22.1893 1.34681 20.6574 1.95957 19.2148C2.55319 17.817 3.40213 16.566 4.48086 15.4809C5.55957 14.4021 6.81701 13.5532 8.21489 12.9596C9.65744 12.3468 11.1894 12.034 12.766 12.034H35.234ZM35.234 11H12.766C5.74468 11 0 16.7447 0 23.766C0 30.7873 5.74468 36.5319 12.766 36.5319H35.234C42.2554 36.5319 48 30.7873 48 23.766C48 16.7447 42.2554 11 35.234 11Z'
        fill='#3C4043'
      />
      <path
        d='M22.8896 24.6726V28.5342H21.6641V18.998H24.913C25.7365 18.998 26.4385 19.2726 27.0129 19.8215C27.6003 20.3705 27.8939 21.0406 27.8939 21.8322C27.8939 22.6427 27.6003 23.313 27.0129 23.8556C26.4449 24.398 25.7429 24.6661 24.913 24.6661H22.8896V24.6726ZM22.8896 20.1725V23.4981H24.9386C25.4237 23.4981 25.8323 23.3321 26.1513 23.0066C26.4769 22.6812 26.6428 22.2853 26.6428 21.8384C26.6428 21.398 26.4769 21.0088 26.1513 20.6831C25.8323 20.345 25.4301 20.1789 24.9386 20.1789H22.8896V20.1725Z'
        fill='#3C4043'
      />
      <path
        d='M31.0978 21.7939C32.0041 21.7939 32.7191 22.0365 33.2424 22.5217C33.7657 23.0068 34.0276 23.6706 34.0276 24.5132V28.5344H32.8594V27.6281H32.8084C32.304 28.3748 31.6276 28.7452 30.785 28.7452C30.0637 28.7452 29.4637 28.5344 28.9786 28.1067C28.4935 27.6792 28.251 27.1493 28.251 26.511C28.251 25.8345 28.5062 25.2983 29.0168 24.9024C29.5275 24.5005 30.2104 24.3024 31.0594 24.3024C31.787 24.3024 32.3872 24.4365 32.8531 24.7046V24.4238C32.8531 23.9961 32.6871 23.6386 32.3487 23.3387C32.0104 23.0386 31.6147 22.8919 31.1616 22.8919C30.4785 22.8919 29.9361 23.1791 29.5403 23.76L28.4615 23.0833C29.0551 22.2217 29.936 21.7939 31.0978 21.7939ZM29.5148 26.5301C29.5148 26.8494 29.6488 27.1175 29.9232 27.328C30.1913 27.5387 30.5105 27.6472 30.8744 27.6472C31.3913 27.6472 31.851 27.4558 32.2531 27.0728C32.6551 26.6897 32.8594 26.2429 32.8594 25.7258C32.4763 25.4259 31.9466 25.2728 31.2637 25.2728C30.7657 25.2728 30.3509 25.3939 30.019 25.6302C29.6806 25.8792 29.5148 26.1791 29.5148 26.5301Z'
        fill='#3C4043'
      />
      <path
        d='M40.6915 22.0039L36.6064 31.3998H35.3424L36.8616 28.1126L34.168 22.0039H35.502L37.4425 26.6892H37.4681L39.3574 22.0039H40.6915Z'
        fill='#3C4043'
      />
      <path
        d='M18.0145 23.8938C18.0145 23.4942 17.9788 23.1119 17.9124 22.7441H12.7754V24.8507L15.7339 24.8513C15.6139 25.5521 15.2277 26.1496 14.636 26.5478V27.9145H16.3971C17.4254 26.9627 18.0145 25.5558 18.0145 23.8938Z'
        fill='#4285F4'
      />
      <path
        d='M14.6365 26.5473C14.1463 26.8781 13.515 27.0714 12.7765 27.0714C11.3499 27.0714 10.1397 26.1101 9.70625 24.8145H7.88965V26.2238C8.78965 28.0098 10.6394 29.2353 12.7765 29.2353C14.2535 29.2353 15.4943 28.7495 16.3975 27.9134L14.6365 26.5473Z'
        fill='#34A853'
      />
      <path
        d='M9.5356 23.7691C9.5356 23.4052 9.59624 23.0536 9.70666 22.7228V21.3135H7.89008C7.51794 22.052 7.30859 22.8858 7.30859 23.7691C7.30859 24.6524 7.51858 25.4862 7.89008 26.2247L9.70666 24.8153C9.59624 24.4846 9.5356 24.133 9.5356 23.7691Z'
        fill='#FABB05'
      />
      <path
        d='M12.7765 20.4664C13.5826 20.4664 14.3046 20.7441 14.8745 21.2867L16.4352 19.7273C15.4873 18.8446 14.2516 18.3027 12.7765 18.3027C10.6401 18.3027 8.78965 19.5283 7.88965 21.3142L9.70625 22.7236C10.1397 21.4278 11.3499 20.4664 12.7765 20.4664Z'
        fill='#E94235'
      />
    </svg>
  )
}
