import React from 'react'
import { getSelectionStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components/Common'
import { StatusSilkStyled } from '../StatusSilks.styles'
import { BetStatusIcon } from '../BetStatusIcon/BetStatusIcon'

export const SelectionStatusSilks: React.FC<{
  selections: Ticket['legs'][0]['selections']
  contestType?: RaceCodesLowerCase
}> = ({ contestType, selections }) => {
  return (
    <>
      {selections.map(selection => {
        const betStatus = getSelectionStatus(selection)
        const shouldRenderStatusIcon = betStatus && ['Won', 'Lost'].includes(betStatus)

        return shouldRenderStatusIcon ? (
          <BetStatusIcon betStatus={betStatus} key={selection.header} />
        ) : (
          <StatusSilkStyled isFallback={true} key={selection.header}>
            <SilkFallback contestType={contestType} isSmall={true} />
          </StatusSilkStyled>
        )
      })}
    </>
  )
}
