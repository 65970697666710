import type {
  AllUpSelection,
  ToteSelection,
  MysterySelection,
  AcceptorBase as MysteryAcceptor,
  Acceptor,
  Race,
} from '@mobi/betslip/types'

interface MultiRaceSelections {
  [raceAndNumber: string]: string
}

function getPoolTypeName(poolType: AllUpSelection['details'][0]['poolType']): string {
  switch (poolType) {
    case 'W': {
      return 'Win'
    }
    case 'P': {
      return 'Place'
    }
    case 'EW': {
      return 'Eachway'
    }
    case 'Q': {
      return 'Quinella'
    }
    default: {
      return ''
    }
  }
}

export function parseAllUpSelections(
  { details }: AllUpSelection,
  races: Race[],
  allAcceptors: Acceptor[]
): MultiRaceSelections {
  if (details.length !== races.length) {
    // don't return anything because it's probably wrong
    return {}
  }

  const parsed: MultiRaceSelections = {}
  details.forEach((selectionDetails, index) => {
    const race = races[index]
    const key = `Race ${race.raceNumber}`
    parsed[key] = `(${getPoolTypeName(
      selectionDetails.poolType
    )}) ${addAcceptorNameToSingleSelection(
      selectionDetails.betSelections,
      getAcceptorsForRace(race, allAcceptors)
    )}`
  })

  return parsed
}

export function parseMultiLegSelectionString(
  { selectionString }: ToteSelection,
  races: Race[],
  allAcceptors: Acceptor[]
): MultiRaceSelections {
  const selectionStringParts = selectionString.split('/')

  if (selectionStringParts.length !== races.length) {
    // don't return anything because it's probably wrong
    return {}
  }

  const parsed: MultiRaceSelections = {}
  selectionStringParts.forEach((selection, index) => {
    const race = races[index]
    const key = `Race ${race.raceNumber}`
    parsed[key] = addAcceptorNameToSingleSelection(
      selection,
      getAcceptorsForRace(race, allAcceptors)
    )
  })

  return parsed
}

export interface NoveltySelections {
  '1st'?: string
  '2nd'?: string
  '3rd'?: string
  '4th'?: string
  ALLWAYS?: string
  'LEG-IN'?: string
  RB?: string
  WITH?: string
}

export function parseNoveltySelectionString(
  { selectionString, isAllways, isLegIn, isRovingBanker }: ToteSelection,
  acceptorsForRace: Acceptor[]
): NoveltySelections {
  return parseRawNoveltySelectionString(
    selectionString,
    isAllways,
    isLegIn,
    isRovingBanker,
    acceptorsForRace
  )
}

export function parseMysterySelectionString(
  selectionString: string,
  isAllways: boolean,
  acceptorsForRace: MysteryAcceptor[]
): NoveltySelections {
  return parseRawNoveltySelectionString(selectionString, isAllways, false, false, acceptorsForRace)
}

function parseRawNoveltySelectionString(
  selectionString: string,
  isAllways: boolean,
  isLegIn: boolean,
  isRovingBanker: boolean,
  acceptorsForRace: MysteryAcceptor[]
): NoveltySelections {
  const parts = selectionString
    .split('/')
    .map(part => addAcceptorNameToSingleSelection(part, acceptorsForRace))
  if (isAllways) {
    return { ALLWAYS: parts[0] }
  }

  if (isRovingBanker) {
    return {
      RB: parts[0],
      WITH: parts[1],
    }
  }

  if (isLegIn) {
    return {
      'LEG-IN': parts[0],
      WITH: parts[1],
    }
  }

  return {
    '1st': parts[0],
    '2nd': parts[1],
    '3rd': parts[2],
    '4th': parts[3],
  }
}

export function addAcceptorNameToSingleSelection(
  selectionForRace: string,
  acceptorsForRace: MysteryAcceptor[]
): string {
  if (selectionForRace && /^\d+$/.test(selectionForRace)) {
    const acceptor = getAcceptorName(selectionForRace, acceptorsForRace)

    if (acceptor) {
      return `${selectionForRace} - ${acceptor}`
    }
  }

  return selectionForRace
}

function getAcceptorName(acceptorNumber: string, acceptorsForRace: MysteryAcceptor[]): string {
  const acceptor = acceptorsForRace.find(x => `${x.number}` === acceptorNumber)
  return acceptor ? acceptor.name : ''
}

function getAcceptorsForRace(race: Race, allAcceptors: Acceptor[]): Acceptor[] {
  return allAcceptors.filter(acceptor => race.acceptorKeys.includes(acceptor.key))
}

export function getAcceptorTextForSingleSelection(acceptor: MysteryAcceptor): string | null {
  if (!acceptor) {
    return null
  }
  return acceptor.number + ' - ' + acceptor.name
}

export function getAcceptorsFromMysteryBetLine(
  betLine: NonNullable<MysterySelection['bets']>[0]['betLines'][0]
) {
  let jointArray: MysteryAcceptor[] = []

  betLine.legs.forEach(array => {
    jointArray = jointArray.concat(array.acceptors).concat(array.acceptors)
  })

  const uniqueArray = jointArray.reduce(
    (accumulator: MysteryAcceptor[], acceptor: MysteryAcceptor) => {
      if (accumulator.includes(acceptor)) {
        return accumulator
      } else {
        return [...accumulator, acceptor]
      }
    },
    []
  )
  return uniqueArray
}
