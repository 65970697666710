import { css } from '@emotion/react'

/**
 * Utility function to make writing columnGap easier for browser that
 * don't support it in Flexbox contexts, like Safari < 12 and...
 * no, that's pretty much it
 *
 * @param {string} gap  Any value that `marginRight` accepts
 */
export const gapX = (gap: string) =>
  css({
    columnGap: gap,

    [`@supports not (column-gap: ${gap})`]: {
      '& > *:not(:last-child)': {
        marginRight: gap,
      },
    },
  })

/**
 * Utility function to make writing rowGap easier for browser that
 * don't support it in Flexbox contexts, like Safari < 12 and...
 * no, that's pretty much it
 *
 * @param {string} gap  Any value that `marginBottom` accepts
 */
export const gapY = (gap: string) =>
  css({
    rowGap: gap,

    [`@supports not (row-gap: ${gap})`]: {
      '& > *:not(:last-child)': {
        marginBottom: gap,
      },
    },
  })
