import { FeatureFlagKey } from '../LaunchDarklyService'
import { state$ as launchDarklyState$ } from '../driver'
import { logError } from '@classic/Foundation/Services/LoggingService'

export async function isActive(
  key: FeatureFlagKey,
  defaultValue: boolean = false,
  shouldWaitForUser: boolean = false
): Promise<boolean> {
  try {
    const launchDarkly = await launchDarklyState$
      .where(x => x.features.size > 0)
      .skipWhile(({ isUserAnonymous }) => shouldWaitForUser && isUserAnonymous)
      .take(1)
      .toPromise()

    return launchDarkly && launchDarkly.features.get(key) == true
  } catch (e) {
    // with an abundance or caution.. swallow all errors and return the default value
    logError(
      `failed to retrieve launch darkly flag, key=${key}, defaultValue=${defaultValue}, e=${e}`
    )
    return defaultValue
  }
}
