import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as useAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import type { BlackbookRunner } from '@core/Areas/Blackbook/types'
import { postGetBlackbookRunners } from '../helpers/api'

export const useBlackbookRunners = (): { data: BlackbookRunner[]; isLoading: boolean } => {
  const { isLoggedIn, accountNumber } = useObservableImmutable(useAccountState$, [
    'isLoggedIn',
    'accountNumber',
  ])

  const { data, isError, isLoading } = useQuery({
    queryFn: postGetBlackbookRunners,
    queryKey: queryKeys.blackbookRunners(accountNumber),
    enabled: !!isLoggedIn && !!accountNumber,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const blackbookRunners = isError || !data?.data || !isLoggedIn ? [] : data.data

  return {
    data: blackbookRunners,
    isLoading,
  }
}
