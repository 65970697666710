import Rx from 'rx'
import { recordify, TypedRecord } from 'typed-immutable-record'
import * as apiService from '@classic/Foundation/Services/ApiService'
import { BetAccount } from '@mobi/api-types'
import { declareResourceType } from 'rwwa-data-access'

const BetAccountDetailsKey = 'betAccountDetails'
export const BetAccountKey = 'betAccountKey'

export interface BetAccountRecord extends TypedRecord<BetAccountRecord>, BetAccount {}

/**
 * @deprecated Use useBetAccount React Query hook instead
 */
export const BetAccountRepo = declareResourceType<BetAccountRecord, BetAccount>({
  typeKey: BetAccountDetailsKey,
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 2 * 60 * 60 * 1000, // 2 hours
  map: betAccount => recordify<BetAccount, BetAccountRecord>(betAccount),
})

function fetchMultipleNoPromise(keys: string[]) {
  return Rx.Observable.fromPromise(
    apiService
      .get<BetAccount>({ url: '/$_/api/account/getBetAccountDetails' })
      .then(response => ({
        [BetAccountDetailsKey]: {
          [BetAccountKey]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(error => ({ keys, error }))
  )
}
