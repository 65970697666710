import styled, { CSSObject } from '@emotion/styled'
import { Grid } from '@mobi/component-library/Common/Grid'
import { colors } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'

export const FormDataStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',
  fontSize: '1.2rem',
  lineHeight: 1.4,
  color: hexColors.nero,

  strong: {
    display: 'inline-block',
    minWidth: '9rem',
  },

  '& > div': {
    paddingBottom: '1rem',
    marginBottom: '1rem',
    borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,

    '&:empty': {
      padding: 0,
    },

    '&:last-of-type': {
      border: 0,
      paddingBottom: 0,
    },
  },
})

export const BaseStyledCss: CSSObject = {
  color: colors.neutral['800'],
  fontSize: '1.1rem',
  margin: '8px 0 0 8px',
  padding: '2px 0 2px 6px',
}

export const BaseStyled = styled('div')(BaseStyledCss)

export const HighlightStyledCss: CSSObject = {
  color: hexColors.black,
  backgroundColor: colors.success['50'],
  borderLeftColor: colors.success['600'],
  borderLeftStyle: 'solid',
  borderLeftWidth: '1px',
}

export const HighlightStyled = styled(BaseStyled)(HighlightStyledCss)

export const FormGridStyled = styled(Grid)({
  backgroundColor: colors.surface['100'],
  borderRadius: 5,
  borderBottom: 'none !important',
})
