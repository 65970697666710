import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { FormDataStyled, FormGridStyled } from '../Common/Common.styles'
import { FormHistories } from '../Common/Histories'
import { RacesHistorySingle } from './RacesHistorySingle'
import { HighlightStat } from '../Common/HighlightStat'
import {
  distanceRule,
  firstUpRule,
  secondUpRule,
  trackDistanceRule,
  trackNameRule,
  courseConditionRule,
} from '../Common/HighlightStatRules'

export const Races: React.FC<{
  form?: RacesFormRaceResponse['Forms'][0]
  courseCondition?: string
}> = ({ form, courseCondition }) => {
  if (!form) return null

  return (
    <>
      <FormDataStyled>
        {form.Comments && (
          <>
            <div />
            <div data-testid='runner-form-tips'>{form.Comments}</div>
          </>
        )}

        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-career'>
              <strong>Career</strong> {form.Career}
            </div>
            <div data-testid='runner-form-prize'>
              <strong>Prize</strong> {form.PrizeMoneyWon}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='runner-form-last-starts'>
              <strong>Last Starts</strong> {form.LastTenStarts}
            </div>
            <div data-testid='runner-form-trainer'>
              <strong>Trainer</strong> {form.TrainerName}
            </div>
          </GridCell>
        </Grid>

        <FormGridStyled>
          <GridCell width='50%'>
            <HighlightStat
              title={'Distance'}
              value={form.Distance}
              highlightOn={distanceRule(form.Distance)}
            />
            <HighlightStat
              title={'Track'}
              value={form.TrackName}
              highlightOn={trackNameRule(form.TrackName)}
            />
            <HighlightStat
              title={'Trk/Dist'}
              value={form.TrackDistance}
              highlightOn={trackDistanceRule(form.TrackDistance)}
            />
            <HighlightStat
              title={'1st Up'}
              value={form.FirstUp}
              highlightOn={firstUpRule(form.LastTenStarts, form.FirstUp)}
            />
            <HighlightStat
              title={'2nd Up'}
              value={form.SecondUp}
              highlightOn={secondUpRule(form.LastTenStarts, form.SecondUp)}
            />
          </GridCell>

          <GridCell width='50%'>
            {form.TrackConditions?.map(({ Name, Stats }) => (
              <HighlightStat
                key={Name}
                title={Name}
                value={Stats}
                highlightOn={courseConditionRule(courseCondition, Name, Stats)}
              />
            ))}
          </GridCell>
        </FormGridStyled>

        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-age-sex'>
              <strong>Age / Sex</strong> {form.Age} / {form.Sex}
            </div>
          </GridCell>
          <GridCell width='50%'>
            <div data-testid='runner-form-colour'>
              <strong>Colour</strong> {form.Color}
            </div>
          </GridCell>
          <div data-testid='runner-form-sire-dam'>
            <strong>Sire / Dam</strong> {form.SireName} / {form.DamName}
          </div>
        </Grid>

        {form.GearChanges && (
          <div data-testid='runner-form-gear-changes'>
            <strong>Gear Changes</strong> {form.GearChanges}
          </div>
        )}
      </FormDataStyled>

      <FormHistories>
        {form.RaceHistories.map(history => (
          <RacesHistorySingle key={history.FixtureDate} history={history} />
        ))}
      </FormHistories>
    </>
  )
}
