import type { Promotion } from '@core/Areas/Racing/Hooks/RacePromotion/types'

export function filterRacePromotionsByEntitlement(
  promotions: Promotion[],
  isEntitled: boolean
): Promotion[] {
  if (isEntitled) {
    return promotions
  }

  return promotions.filter(promotion => !promotion.useInducement)
}
