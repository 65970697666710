import type {
  DogsRunnerForm,
  FormRaceResponse,
  DogsFormRaceResponse,
  RacesFormRaceResponse,
  TrotsFormRaceResponse,
  RacesRunnerForm,
  TrotsRunnerForm,
} from './api'

// Type Narrowing - Response

export function isDogsFormData(formData: FormRaceResponse): formData is DogsFormRaceResponse {
  return formData.StarterType === 'Dogs'
}

export function isRacesFormData(formData: FormRaceResponse): formData is RacesFormRaceResponse {
  return formData.StarterType === 'Races'
}

export function isTrotsFormData(formData: FormRaceResponse): formData is TrotsFormRaceResponse {
  return formData.StarterType === 'Trots'
}

// Type Narrowing - Forms

export function isDogsRunnerForm(
  formData?: FormRaceResponse['Forms']
): formData is DogsRunnerForm[] {
  if (!formData?.length) return false
  const historyProperty: keyof DogsRunnerForm = 'Histories'
  return historyProperty in formData[0]
}

export function isRacesRunnerForm(
  formData?: FormRaceResponse['Forms']
): formData is RacesRunnerForm[] {
  if (!formData?.length) return false
  const historyProperty: keyof RacesRunnerForm = 'RaceHistories'
  return historyProperty in formData[0]
}

export function isTrotsRunnerForm(
  formData?: FormRaceResponse['Forms']
): formData is TrotsRunnerForm[] {
  if (!formData?.length) return false
  const historyProperty: keyof TrotsRunnerForm = 'TrotHistories'
  return historyProperty in formData[0]
}
