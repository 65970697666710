import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ICheckBoxPipelineProcessor } from './ICheckBoxPipelineProcessor'
import { CheckBoxPipelineProcessor } from './CheckBoxPipelineProcessor'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import { ISelectionContext } from './ISelectionContext'
import Guard from '../../../../AppUtils/Framework/Guard'

export default class RovingBankerCheckBoxPipelineProcessor extends CheckBoxPipelineProcessor {
  constructor(next: ICheckBoxPipelineProcessor) {
    super(next)
  }

  process(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    context: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): Array<IObservableStarter> {
    if (!context.rovingBanker())
      return (this.next as ICheckBoxPipelineProcessor).process(
        current,
        all,
        context,
        selectionContext,
        count
      )

    Guard.notNull(current)
    Guard.notNull(all)
    Guard.notNull(context)

    const supplied = current.selection() as CheckBoxSelection
    const results: IObservableStarter[] = []

    if (
      this.isStarterRovingBanker(current) &&
      this.totalRovingBankers(all(), current) + 1 >= count
    ) {
      // Trying too add to many roving bankers
      this.maintainExistingSetOfStarters(current, all, results)
      supplied.clearAt(1)
    } else if (
      this.isStarterRovingBanker(current) &&
      !this.isWith(current) &&
      this.totalRovingBankers(all(), current) + 1 < count
    ) {
      // Adding roving banker
      this.maintainExistingSetOfStarters(current, all, results) // Less than max
      context.assignTotalRovingBankers(this.totalRovingBankers(all(), current) + 1)
      context.clearFieldIfNoRovingBanker()
      results.push(current)
    } else if (!this.isStarterRovingBanker(current) && this.isWith(current)) {
      this.maintainExistingSetOfStarters(current, all, results)
      context.assignTotalRovingBankers(this.totalRovingBankers(all(), current))
      results.push(current)
    } else if (!this.isStarterRovingBanker(current) && !this.isWith(current)) {
      this.maintainExistingSetOfStarters(current, all, results)
      context.assignTotalRovingBankers(this.totalRovingBankers(all(), current))
      context.turnOffRovingBankerField()
      supplied.clearAll()
    } else {
      context.assignTotalRovingBankers(this.totalRovingBankers(all(), current))
    }

    return results
  }
}
