import { injectable } from 'inversify'
import ProgressIndicatorBase from './ProgressIndicatorBase'
import * as navLoading from '@classic/Foundation/Navigation/NavigationLoading'

@injectable()
export default class ProgressIndicator extends ProgressIndicatorBase {
  constructor() {
    super()
  }

  beginning() {
    this.setServiceCallCompleted(false)
    navLoading.showLoading()
  }

  finished() {
    this.setServiceCallCompleted(true)
    navLoading.hideLoading()
  }

  displayFor<T>(promise: Promise<T>): Promise<T> {
    return this.pushLoadingFor(promise)
  }

  pushLoadingFor<T>(promise: Promise<T>) {
    return navLoading.pushLoading((hide: () => void) => {
      return promise
        .then(function (value) {
          hide()
          return value
        })
        .catch(function (err) {
          hide()
          throw err
        })
    })
  }
}
