import { useQuery } from 'react-query'
import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { getMysteryBetDetails, queryDefaults } from '@core/Areas/MysteryBet/Data/MysteryBetRequests'
import { queryKeys } from '@core/Data/ReactQuery/constants'

type Props = {
  meetingId: string | undefined
  raceNumber: number | undefined
  date: string | undefined
}

export function useMysteryBetDetails({ raceNumber, date, meetingId }: Props) {
  return useQuery<MysteryDataTransferObject>({
    ...queryDefaults,
    queryKey: [queryKeys.mysteryBetDetails, meetingId, raceNumber, date],
    queryFn: () => getMysteryBetDetails(meetingId, raceNumber, date),
  })
}
