import styled from '@emotion/styled'
import { newTheme as theme } from '@mobi/component-library/Theme'

export const DepositHeaderStyled = styled.header({
  display: 'grid',
  gridTemplateAreas: `
    'close title .'
  `,
  gridTemplateColumns: '4rem 1fr 4rem',
  textAlign: 'center',
  padding: `0rem ${theme.spacing.sm}`,
  borderBottom: '1px solid',
  borderBottomColor: theme.colors.neutral[200],
})

type CellProps = {
  area: 'title' | 'close'
}

export const DepositHeaderGridCellStyled = styled.div<CellProps>(({ area }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  gridArea: area,
}))

export const DepositHeaderTitle = styled.span({
  color: theme.colors.black,
  fontFamily: theme.font.family.primary,
  fontStyle: 'normal',
  fontWeight: theme.font.weight.bold,
  ...theme.font.size.xl,
  padding: `${theme.spacing.sm} ${theme.spacing.smx1}`,
  textAlign: 'center',
})
