import React from 'react'
import dayjs from 'dayjs'
import { Errored, Loading } from 'rwwa-data-access'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Button } from '@mobi/component-library/Common/Buttons'
import { DirectionalIcon } from '../Icons'
import {
  DatePickerStyled,
  DateNavigationContainerStyled,
  DatePickerIcon,
} from './DateNavigation.styles'
import { CalendarDatePicker } from '../DatePicker/DatePicker'
import { MeetingsAPIResponse } from '@core/Areas/Racing/Hooks/useMeetings/types'

type MeetingsResponse = Loading | Errored | MeetingsAPIResponse[0]

interface DateNavigationParams {
  date: Date
  onDateChange: (date: Date) => void
  code?: string
  meetings?: MeetingsResponse
}

export function DateNavigation({ date, onDateChange }: DateNavigationParams): JSX.Element {
  const dateString = dayjs(date).format('ddd MMM Do')
  const nextDay = () => {
    closeCalendar()
    return dayjs(date).add(1, 'day').toDate()
  }

  const prevDay = () => {
    closeCalendar()
    return dayjs(date).subtract(1, 'day').toDate()
  }

  const onValueChange = (value: Date) => {
    closeCalendar()
    onDateChange(value)
  }

  const [showCalendar, setShowCalendar] = React.useState(false)

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar)
  }

  const closeCalendar = () => {
    setShowCalendar(false)
  }

  return (
    <DateNavigationContainerStyled>
      <Grid>
        <GridRow>
          <GridCell width='15%' valign='middle' align='right'>
            <Button
              data-testid='date-nav-prev'
              color='secondary'
              type='button'
              onClick={() => onDateChange(prevDay())}
            >
              <DirectionalIcon direction='left' size='2.2rem' />
            </Button>
          </GridCell>

          <GridCell width='70%' valign='middle'>
            <DatePickerStyled
              data-testid='date-nav-date'
              onClick={toggleCalendar}
              isOpen={showCalendar}
            >
              <DatePickerIcon type='calendar' isOpen={showCalendar} size='2.2rem' />
              {'  '}
              {dateString}
              {'  '}
              <DirectionalIcon direction={showCalendar ? 'up' : 'down'} size='2.2rem' />
            </DatePickerStyled>
          </GridCell>

          <GridCell width='15%' valign='middle' align='left'>
            <Button
              data-testid='date-nav-next'
              color='secondary'
              type='button'
              onClick={() => onDateChange(nextDay())}
            >
              <DirectionalIcon direction='right' size='2.2rem' />
            </Button>
          </GridCell>
        </GridRow>
      </Grid>

      <Popover isOpen={showCalendar}>
        <CalendarDatePicker value={date} onChange={onValueChange} />
      </Popover>
    </DateNavigationContainerStyled>
  )
}

function Popover({
  isOpen,
  children,
}: {
  isOpen: boolean
  children: JSX.Element | null
}): null | JSX.Element {
  if (!isOpen) {
    return null
  }
  return <>{children}</>
}
