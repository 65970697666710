import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { useBlackbookRunners } from '@core/Areas/Blackbook/Hooks/useBlackbookRunners'
import { getMatchedRunnerInBlackbook } from '@core/Areas/Blackbook/helpers/getMatchedRunnerInBlackbook'

/** Jockey name with Blackbook indicator */
export const JockeyName: React.FC<{ jockeyName: string; isRiderChanged: boolean }> = ({
  jockeyName,
  isRiderChanged,
}) => {
  const { data: blackbookRunners } = useBlackbookRunners()
  const isRunnerInBlackbook = !!getMatchedRunnerInBlackbook(blackbookRunners, jockeyName, 'Jockeys')

  const WrapperComponent = isRunnerInBlackbook ? BlackbookIndicatorStyled : React.Fragment

  return (
    <WrapperComponent>
      <strong>J:</strong>{' '}
      <span
        className={!isRunnerInBlackbook && isRiderChanged ? 'rider-change fg-gray-lightest' : ''}
      >
        {jockeyName}
      </span>
    </WrapperComponent>
  )
}

const BlackbookIndicatorStyled = styled.span({
  borderRadius: '0.3rem',
  background: hexColors.nero,
  color: hexColors.white,
  padding: '0.2rem 0.3rem',
})
