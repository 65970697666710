import React from 'react'

import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { useObservableImmutable } from '@core/Utils/hooks'

import { SetSelectedQuickPickBetCount, SetSelectedCustomBetCount, state$ } from '../driver'
import { MysteryHeadingStyled, BetNumberButtonStyled } from '../MysteryBetPage.styles'

type NumberOfBetType = 'QuickPick' | 'Custom'

interface NumberOfBetsProps {
  isDisabled: boolean
  type: NumberOfBetType
}

const BET_BUTTON_VALUES = [1, 2, 3, 4, 5]

export const NumberOfBets = ({ isDisabled, type }: NumberOfBetsProps): JSX.Element => {
  const quickPickCount = useObservableImmutable(state$, [
    'selectedQuickPickBetCount',
  ]).selectedQuickPickBetCount
  const customCount = useObservableImmutable(state$, [
    'selectedCustomBetCount',
  ]).selectedCustomBetCount
  const selectedBetCount = type === 'QuickPick' ? quickPickCount : customCount
  const setBetCount =
    type === 'QuickPick' ? SetSelectedQuickPickBetCount : SetSelectedCustomBetCount
  return (
    <>
      <MysteryHeadingStyled>Number of Bets</MysteryHeadingStyled>
      <Grid>
        <GridRow>
          {BET_BUTTON_VALUES.map(x => (
            <GridCell key={x} flexGrow={1}>
              <BetNumberButtonStyled
                num={x}
                disabled={isDisabled}
                isSelected={selectedBetCount === x}
                onClick={() => setBetCount(selectedBetCount === x ? undefined : x)}
                data-tid-number-of-bets={x}
                data-tid-mystery-qp-type={x}
              >
                {x}
              </BetNumberButtonStyled>
            </GridCell>
          ))}
        </GridRow>
      </Grid>
    </>
  )
}
