import React from 'react'
import dayjs from 'dayjs'
import type { FavouriteNumbersSelection } from '@mobi/betslip/types'
import { hasLoadedWithoutError } from 'rwwa-data-access'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { state$, defaultState } from './driver'
import { useObservable, useDataAccess } from '@core/Utils/hooks'
import {
  FavouriteNumbersPools,
  UpcomingPoolsKey,
  FavouriteNumberBettingPool,
} from '@core/Data/favouriteNumbers'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

export const FavouriteNumbersBettingDrawer = ({ open }: { open: boolean }): JSX.Element => {
  const { gameOne, gameTwo, pools, potLuckBets } = useObservable(state$, defaultState)
  const data = useDataAccess(FavouriteNumbersPools, UpcomingPoolsKey)

  const onQuickbetClick = (): void => {
    if (hasLoadedWithoutError(data.data)) {
      handleBetSelection({
        location: 'Quickbet',
        selection: createFavouriteNumberSelection(gameOne, gameTwo, pools, potLuckBets, [
          ...data.data.midweekPools,
          ...data.data.weekendPools,
        ]),
      })
    }
  }

  return <BettingDrawer open={open} handleQuickbetClick={onQuickbetClick} />
}

// =============
// Local Helpers
// =============

const createFavouriteNumberSelection = (
  gameOne: number[],
  gameTwo: number[],
  pools: Date[],
  numberOfBets: number,
  poolDetails: FavouriteNumberBettingPool[]
): QuickbetSelection => {
  const bets: FavouriteNumbersSelection['bets'] = []
  const selectedPoolDetails: FavouriteNumberBettingPool[] = []

  for (const pool of pools) {
    for (let betNumber = 1; betNumber <= numberOfBets; betNumber++) {
      const foundPoolDetails = poolDetails.find(p => p.poolDate === pool)
      if (foundPoolDetails) {
        selectedPoolDetails.push(foundPoolDetails)
        bets.push({
          fixtureDate: dayjs(pool).format('YYYY-MM-DD'),
          game1Selections: gameOne,
          game2Selections: gameTwo,
        })
      }
    }
  }

  return {
    bettingType: 'favourite-numbers',
    selection: { bets },
    selectionDetails: { pools: selectedPoolDetails },
    presetInvestment: true,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment: false,
    promptForInvestment: false,
  }
}
