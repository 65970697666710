import React, { type ComponentPropsWithoutRef, useState } from 'react'
import { UnstyledButton } from '../../Buttons'
import { Icon } from '../../Icon'
import { Input } from './Input'

type Props = Omit<ComponentPropsWithoutRef<typeof Input>, 'type' | 'suffix'>

export const Password = (props: Props) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const type = isPasswordVisible ? 'text' : 'password'

  const renderSuffix = () => (
    <UnstyledButton
      style={{ height: '100%' }}
      type='button'
      onClick={() => setPasswordVisible(isVisible => !isVisible)}
    >
      <Icon type={isPasswordVisible ? 'visibilityoff' : 'visibilityon'} />
    </UnstyledButton>
  )

  return <Input type={type} {...props} suffix={renderSuffix()} />
}

Password.displayName = 'Password'
