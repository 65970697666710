import {
  Ticket,
  BetStatus,
  RaceStatusSequence as RaceStatus,
  TicketLeg,
} from '@core/Areas/AccountActivity/types'

export function getLegStatus(result: TicketLeg['result']): BetStatus {
  switch (result) {
    case '-':
      return 'Pending'
    case 'LOSE':
      return 'Lost'
    case 'Refund':
      return 'Cancelled'
    case 'SCR':
      return 'Scratched'
    case 'WIN':
      return 'Won'
    case 'ABD':
      return 'Abandoned'
    case 'LINE':
    default:
      return null
  }
}

export function getSelectionStatus(selection: Ticket['legs'][0]['selections'][0]): BetStatus {
  const abandonedSeq = [
    RaceStatus.AbandonedClosed,
    RaceStatus.AbandonedFinalised,
    RaceStatus.AbandonedReleased,
  ]

  if (selection.raceStatusSeq && abandonedSeq.includes(selection.raceStatusSeq)) {
    return 'Abandoned'
  }

  if (!selection.raceResult) {
    return 'Pending'
  }

  for (const acceptor of selection.selectionDetails[0].acceptorDetails) {
    let starterNumber = acceptor.number
    if (acceptor.isScratched) {
      if (!selection.favouriteCombination) return null
      starterNumber = Number.parseInt(selection.favouriteCombination)
    }

    if (selection.raceResult[starterNumber - 1] === '1') {
      return 'Won'
    }
  }
  return 'Lost'
}
