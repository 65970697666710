import { hexColors } from '@mobi/settings'
import { mix, transparentize } from 'polished'

/** @deprecated Please DO NOT use this object, other options:
 * - Use generic mobi { colors } from '@mobi/settings' (ONLY generic high level, ie. brandPrimary etc)
 * - Use local `colors.ts` or `themes.ts` files
 * - Use { hexColors } from '@mobi/settings'
 *
 * This object does not follow the co-location principle, making maintenance difficult.
 */
export const colors = {
  campaigns: {
    receipt: {
      text: hexColors.cobalt,
    },
    panel: {
      text: hexColors.white,
      background: hexColors.summerSky,
    },
  },

  expandablePanel: {
    dark: {
      header: {
        text: hexColors.white,
        background: hexColors.black,
      },
      content: {
        background: hexColors.white,
        text: hexColors.black,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.white,
      },
      inline: {
        background: hexColors.whiteSmoke,
        text: hexColors.black,
        boxShadow: hexColors.nightRider,
      },
      informationIcon: hexColors.summerSky,
    },
    light: {
      header: {
        text: hexColors.black,
        background: hexColors.whiteSmoke,
      },
      content: {
        background: hexColors.whiteSmoke,
        text: hexColors.black,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.black,
      },
      inline: {
        background: hexColors.whiteSmoke,
        text: hexColors.black,
        boxShadow: hexColors.nightRider,
      },
    },
    white: {
      header: {
        text: hexColors.nero,
        background: hexColors.white,
        border: hexColors.gainsboro,
      },
      content: {
        background: hexColors.white,
        text: hexColors.nero,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.darkGrey,
      },
      inline: {
        background: hexColors.white,
        text: hexColors.nero,
        boxShadow: hexColors.nightRider,
      },
    },
    bonusbet: {
      header: {
        text: hexColors.white,
        background: hexColors.summerSky,
      },
      content: {
        background: hexColors.summerSky,
        text: hexColors.white,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.white,
      },
      inline: {
        background: hexColors.summerSky,
        text: hexColors.white,
        boxShadow: hexColors.nightRider,
      },
    },
    purple: {
      header: {
        text: hexColors.white,
        background: hexColors.studio,
        specialIcon: hexColors.white,
      },
      content: {
        background: hexColors.white,
        text: hexColors.black,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.white,
      },
      inline: {
        background: hexColors.whiteSmoke,
        text: hexColors.black,
        boxShadow: hexColors.nightRider,
      },
    },
    greyWhite: {
      header: {
        text: hexColors.grey,
        background: hexColors.white,
      },
      content: {
        background: hexColors.white,
        text: hexColors.black,
        boxShadow: hexColors.veryLightGrey,
      },
      toggle: {
        text: hexColors.grey,
      },
      inline: {
        background: hexColors.whiteSmoke,
        text: hexColors.black,
        boxShadow: hexColors.nightRider,
      },
    },
  },

  subContentHeader: {
    text: hexColors.white,
    background: hexColors.nero,
    detailsText: hexColors.gainsboro,
  },

  nextEvents: {
    distance: {
      text: hexColors.dimGrey,
    },
    toggle: {
      background: hexColors.gainsboro,
      text: hexColors.white,
      selected: {
        text: hexColors.white,
        background: hexColors.chocolate,
      },
      unselected: {
        text: hexColors.darkGrey,
        background: hexColors.white,
        border: hexColors.veryLightGrey,
      },
    },
    jackpot: {
      text: hexColors.orange,
    },
  },

  betslip: {
    background: hexColors.white,
    text: hexColors.black,
    header: {
      text: hexColors.white,
      background: hexColors.blueDiamond,
    },
    expandable: {
      badge: {
        background: hexColors.white,
        text: hexColors.black,
      },
    },
    pill: {
      background: transparentize(0.5, hexColors.black),
      text: hexColors.white,
    },
    item: {
      background: hexColors.white,
      border: hexColors.gainsboro,
      boxShadow: transparentize(0.8, hexColors.black),
      text: hexColors.black,
      disabled: {
        background: hexColors.whiteSmoke,
        text: hexColors.grey,
      },
      empty: {
        text: hexColors.charCoal,
      },
    },
    investment: {
      background: hexColors.whiteSmoke,
      border: hexColors.veryLightGrey,
      boxShadow: transparentize(0.9, hexColors.black),
      text: hexColors.black,
      disabled: {
        background: hexColors.gainsboro,
        text: transparentize(0.6, hexColors.black),
      },
    },
    receipt: {
      header: {
        background: hexColors.sushi,
        color: hexColors.white,
      },
    },
    summary: {
      background: hexColors.black,
      color: hexColors.white,
      title: {
        text: hexColors.darkGrey,
      },
      value: {
        text: hexColors.lemon,
      },
    },
    superPick: {
      available: {
        border: hexColors.veryLightGrey,
      },
      selected: {
        border: hexColors.orange,
        background: hexColors.orange,
      },
      disabled: {
        text: hexColors.white,
        border: hexColors.veryLightGrey,
        background: hexColors.veryLightGrey,
      },
    },
    errors: {
      investment: {
        text: hexColors.freeSpeechRed,
      },
      container: {
        background: hexColors.veryLightGrey,
      },
      noticeBox: {
        background: hexColors.white,
        text: hexColors.black,
      },
      betLeg: {
        color: hexColors.freeSpeechRed,
      },
    },
    removeButton: {
      color: hexColors.darkGrey,
      disabled: {
        color: hexColors.grey,
      },
    },
    multiDetails: {
      border: hexColors.gainsboro,
      label: {
        color: hexColors.dimGrey,
      },
      amount: {
        color: hexColors.black,
      },
    },
    formulas: {
      background: hexColors.white,
      border: hexColors.gainsboro,
      text: hexColors.black,
      comboText: hexColors.dimGrey,
      disabled: {
        background: hexColors.veryLightGrey,
        text: transparentize(0.6, hexColors.black),
      },
    },
  },

  quickbet: {
    header: {
      background: hexColors.blueDiamond,
      text: hexColors.white,
      pill: {
        background: transparentize(0.5, hexColors.black),
      },
    },
    silk: {
      background: transparentize(0.85, hexColors.black),
    },
    investment: {
      background: transparentize(0.88, hexColors.white),
      text: hexColors.white,
      active: {
        background: hexColors.white,
        text: hexColors.blueDiamond,
      },
      legInfo: {
        text: hexColors.blueDiamond,
        background: 'transparent',
        boosted: {
          background: hexColors.orange,
          text: hexColors.black,
        },
      },
    },
  },

  priceChange: {
    increase: {
      text: hexColors.sushi,
    },
    decrease: {
      text: hexColors.cinnabar,
    },
    none: {
      text: hexColors.white,
      active: {
        text: hexColors.blueDiamond,
      },
    },
  },

  messages: {
    error: {
      text: hexColors.freeSpeechRed,
      background: mix(0.9, 'white', hexColors.freeSpeechRed),
      border: mix(0.8, 'white', hexColors.freeSpeechRed),
    },
    success: {
      text: hexColors.sushi,
      background: mix(0.9, 'white', hexColors.sushi),
      border: mix(0.8, 'white', hexColors.sushi),
    },
    info: {
      text: hexColors.summerSky,
      background: mix(0.9, 'white', hexColors.summerSky),
      border: mix(0.8, 'white', hexColors.summerSky),
    },
    warning: {
      text: hexColors.chocolate,
      background: mix(0.9, 'white', hexColors.chocolate),
      border: mix(0.8, 'white', hexColors.chocolate),
    },
  },

  password: {
    light: {
      label: {
        text: hexColors.dimGrey,
      },
      input: {
        background: hexColors.white,
      },
      rules: {
        text: hexColors.dimGrey,
      },
      icon: {
        text: hexColors.black,
      },
    },
    dark: {
      label: {
        text: hexColors.white,
        background: hexColors.blueDiamond,
      },
      input: {
        background: hexColors.gainsboro,
      },
      rules: {
        text: hexColors.white,
      },
      icon: {
        text: hexColors.black,
        background: 'transparent',
      },
    },
  },

  meetingList: {
    dateNavigation: {
      activeBackground: hexColors.chocolate,
      activeBorder: hexColors.chocolate,
      activeText: hexColors.white,
      background: hexColors.white,
      border: hexColors.veryLightGrey,
      text: hexColors.black,
      oldBackground: hexColors.nero,
    },
    calendar: {
      baseBackground: hexColors.gainsboro,
      icon: hexColors.grey,
      directionalArrow: hexColors.black,
      headerText: hexColors.black,
      days: {
        hoverBackground: hexColors.grey,
        hoverText: hexColors.white,
        inThisMonth: {
          inText: hexColors.black,
          outText: hexColors.veryLightGrey,
          inBackground: hexColors.white,
          outBackground: hexColors.whiteSmoke,
          boxShadow: hexColors.whiteSmoke,
        },
        isDayToday: {
          background: hexColors.chocolate,
          text: hexColors.white,
          isNotText: hexColors.black,
        },
        isSelected: {
          background: hexColors.chocolate,
          text: hexColors.white,
        },
      },
      matrix: {
        background: hexColors.whiteSmoke,
      },
      select: {
        text: hexColors.darkGrey,
        border: hexColors.black,
        background: hexColors.white,
      },
    },
  },

  raceList: {
    fixtureStatus: {
      textDark: hexColors.black,
      textLight: hexColors.white,
    },
    quaddieChip: {
      background: hexColors.crimson,
      text: hexColors.white,
    },
    results: {
      text: hexColors.cinnabar,
    },
    class: {
      text: hexColors.dimGrey,
    },
    jackpot: {
      text: hexColors.orange,
    },
    specials: {
      text: hexColors.orange,
    },
  },

  fixedFlucs: {
    separator: hexColors.whiteSmoke,
    dividends: {
      separator: hexColors.veryLightGrey,
    },
    label: hexColors.dimGrey,
  },

  fixtureStatus: {
    text: hexColors.white,
    disabledtext: hexColors.grey,
    green: hexColors.sushi,
    orange: hexColors.tangerine,
    red: hexColors.freeSpeechRed,
    grey: hexColors.gainsboro,
  },

  specialIndicator: {
    foreground: hexColors.orange,
    background: hexColors.white,
  },

  starters: {
    buttonSelection: {
      default: {
        background: `linear-gradient(to bottom, ${hexColors.white}, ${hexColors.whiteSmoke})`,
        color: hexColors.nero,
        secondary: hexColors.grey,
        borderColor: hexColors.veryLightGrey,
      },
      selected: {
        background: hexColors.nero,
        color: hexColors.white,
        borderColor: hexColors.nero,
      },
      disabled: {
        background: hexColors.gainsboro,
        color: hexColors.grey,
      },
      priceChange: {
        background: hexColors.infoBlueLight,
        arrow: {
          borderColor: hexColors.infoBlueDark,
        },
        selected: {
          background: hexColors.infoBlueDark,
        },
      },
    },
  },

  search: {
    launchicon: {
      icon: hexColors.white,
    },
    header: {
      background: hexColors.blueDiamond,
    },
    background: hexColors.white,
    initialText: hexColors.darkGrey,
    container: {
      text: hexColors.white,
      background: hexColors.affair,
    },
    list: {
      background: hexColors.gainsboro,
    },
    input: {
      background: hexColors.affair,
      text: hexColors.white,
      placeholder: hexColors.lola,
    },
    result: {
      background: hexColors.white,
      border: hexColors.gainsboro,
      status: hexColors.freeSpeechRed,
      secondary: hexColors.dimGrey,
      scratched: hexColors.freeSpeechRed,
    },
    highlights: {
      background: hexColors.paleCornflowerBlue,
    },
  },

  structuredSearch: {
    initialText: hexColors.darkGrey,
    container: {
      text: hexColors.white,
      background: hexColors.white,
      reset: hexColors.studio,
    },
    section: {
      background: hexColors.white,
    },
    groupheader: {
      text: hexColors.nightRider,
    },
    tabs: {
      background: hexColors.gainsboro,
      count: {
        background: hexColors.whiteSmoke,
        text: hexColors.grey,
      },
    },
    summary: {
      text: hexColors.lemon,
    },
    separator: {
      border: hexColors.gainsboro,
      min: hexColors.whiteSmoke,
      max: hexColors.veryLightGrey,
    },
    toggle: {
      text: hexColors.grey,
    },
    resultDetails: {
      text: hexColors.dimGrey,
      fixed: hexColors.scooter,
    },
  },

  rangeInput: {
    track: {
      foreground: hexColors.charCoal,
      background: hexColors.veryLightGrey,
    },
    tooltip: {
      background: hexColors.gainsboro,
      color: hexColors.charCoal,
    },
    indicator: {
      notdragged: hexColors.charCoal,
      dragged: hexColors.charCoal,
      shadow: hexColors.darkGrey,
    },
  },

  structuredSearchLocationModal: {
    header: {
      text: hexColors.white,
      background: hexColors.blueDiamond,
      button: hexColors.lilac,
    },
    icon: {
      fill: hexColors.darkGrey,
      code: hexColors.white,
    },
  },
}
export default colors
