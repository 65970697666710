import { useRef } from 'react'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

type RequestTokenCallbacks = {
  onSuccess?: (token: string) => void
  onCancel?: () => void
  onFailure?: (error: string) => void
}

type DisposeFunction = VoidFunction

export const useApplePayInterop = (callbacks: RequestTokenCallbacks) => {
  const disposals = useRef<DisposeFunction[]>([])

  const attachNativeSubscriptions = () => {
    if (isReactNativeApp) {
      const successSubscription = subscribeToNative('RESPONSE_APPLE_PAY_SUCCESS', payload => {
        callbacks?.onSuccess?.(payload.token)
      })
      const failureSubscription = subscribeToNative('RESPONSE_APPLE_PAY_FAILURE', payload => {
        callbacks?.onFailure?.(payload.error)
      })
      const cancelledSubscription = subscribeToNative('APPLE_PAY_CANCELLED', () => {
        callbacks?.onCancel?.()
      })
      disposals.current.push(
        successSubscription.dispose,
        failureSubscription.dispose,
        cancelledSubscription.dispose
      )
    }
  }

  const disposeNativeSubscriptions = () => {
    disposals.current.forEach(dispose => dispose())
    disposals.current.length = 0
  }

  const tokenize = (clientToken: string, depositAmount: number) => {
    sendToNative('REQUEST_APPLE_PAY_BEGIN_PAYMENT', {
      item: 'tabtouch',
      token: clientToken,
      price: depositAmount,
    })
  }

  return { tokenize, attachNativeSubscriptions, disposeNativeSubscriptions }
}
