import { store } from '@core/Store'
import { closeModal, openModal } from '@core/Components/Modal'
import type { Button } from '@mobi/component-library/Common/Buttons'
import { PromptForUserDecision } from '../PromptForUserDecision'

export function promptUserAndReturnDecision<Label extends string>({
  id,
  testId,
  buttons,
  title,
  subtitle,
}: { id: string } & {
  testId: string
  buttons: {
    label: Label
    color: React.ComponentProps<typeof Button>['color']
  }[]
  title: string
  subtitle?: string
}) {
  return new Promise<Label>(resolve => {
    // Callback to capture decision
    const handleSelection = (decision: Label) => {
      store.dispatch(closeModal({ id }))
      resolve(decision)
    }

    // Present prompt to user
    store.dispatch(
      openModal({
        id,
        modalComponent: PromptForUserDecision,
        modalComponentProps: {
          handleSelection,
          testId,
          buttons,
          title,
          subtitle,
        },
        isDismissable: false,
        position: 'middle',
        cornerStyle: 'round',
      })
    )
  })
}

export type PromptForUserDecisionProps = {
  handleSelection: (choice: string) => void
} & Omit<Parameters<typeof promptUserAndReturnDecision>[0], 'id'>
