import React from 'react'
import { SearchLinkStyled } from './SearchButton.styles'
import { SearchIcon } from '@core/Components/Logos/search'
import { OverlayOpen } from '@core/Components/Overlay'
import { Search } from '../../Search'

export const SearchButton = () => (
  <div data-tid-search-button=''>
    <SearchLinkStyled
      onClick={() => OverlayOpen(Search)}
      data-testid='header-search-btn'
      padding='1rem'
      role='button'
      aria-label='Search'
    >
      <SearchIcon size='2rem' />
    </SearchLinkStyled>
  </div>
)
