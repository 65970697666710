import React from 'react'
import { useQuery } from 'react-query'

import { get } from '@classic/Foundation/Services/ApiService'
import {
  AlwaysShow,
  Inducement,
  NonInducement,
  NotificationWireResource,
  TargetChannel,
} from '@core/Data/Contentful/notification'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { Banner } from '@mobi/component-library/Common/V2'
import NotificationLink from './NotificationLink'
import { useLogon } from '@core/Utils/hooks'
import { HasLoggedOut } from '@core/State/UserAccount/userAccountDriver'

/**
 * Dismissed notifications re-appear after a page refresh or when logging out,
 * but stay dismissed if the user navigates to another page
 * A local variable is enough for this storage lifespan
 */
let dismissedNotificationsStore: string[] = []

const refetchInMs = 60_000
export const Notification: React.FC<{ isDesktop: boolean }> = ({ isDesktop }) => {
  const [dismissedNotifications, setDismissedNotifications] = React.useState<string[]>(
    dismissedNotificationsStore
  )
  const { isLoggedIn } = useLogon()

  React.useEffect(() => {
    const hasLoggedOutSubscription = HasLoggedOut.signal$.subscribe(() => {
      resetDismissedNotifications()
    })

    return () => hasLoggedOutSubscription.dispose()
  }, [])

  const resetDismissedNotifications = () => {
    dismissedNotificationsStore = []
    setDismissedNotifications(dismissedNotificationsStore)
  }

  const onClickDismiss = (id: string) => {
    setDismissedNotifications(prevValue => {
      const newValue = prevValue.concat(id)
      dismissedNotificationsStore = newValue
      return newValue
    })
  }

  const {
    isLoading: isLoadingNotifications,
    error: isErrorNotifications,
    data: notifications,
  } = useQuery(
    queryKeys.notifications,
    () =>
      get<NotificationWireResource[]>({
        url: '/api/contentful/notifications',
      }),
    {
      refetchInterval: refetchInMs,
      refetchIntervalInBackground: true,
    }
  )

  if (
    isLoadingNotifications ||
    isErrorNotifications ||
    !notifications ||
    notifications?.length === 0
  )
    return null

  const filterData = notifications.filter(notification => {
    const wasNotificationDismissed = dismissedNotifications.includes(notification.Sys?.Id)
    if (wasNotificationDismissed) return false

    const shouldAppearToCurrentUser =
      notification.Visibility === AlwaysShow ||
      (isLoggedIn && notification.Visibility === Inducement) ||
      (!isLoggedIn && notification.Visibility === NonInducement)
    if (!shouldAppearToCurrentUser) return false

    const shouldAppearOnCurrentChannel =
      notification.TargetChannel === TargetChannel.AllChannels ||
      (isDesktop && notification.TargetChannel === TargetChannel.DesktopOnly) ||
      (!isDesktop && notification.TargetChannel === TargetChannel.MobileOnly)
    if (!shouldAppearOnCurrentChannel) return false

    return true
  })

  return (
    <>
      {filterData.map(notification => (
        <Banner
          type={notification.Type ?? 'neutral'}
          text={notification.TitleMessage}
          button={<NotificationLink notification={notification} />}
          onClickDismiss={
            notification.Dismissible ? () => onClickDismiss(notification.Sys?.Id) : undefined
          }
          hideIcon={notification.HideIcon}
          key={notification.Sys?.Id}
        />
      ))}
    </>
  )
}
