import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import type { SortOrder } from '@core/Areas/AccountActivity/types'

export const accountActivityInitialState: AccountActivityState = {
  activeReplayTicketId: null,
  pendingSortOrder: 'eventStart',
  resultsSortOrder: 'eventStart',
}

const accountActivitySlice = createSlice({
  name: 'account-activity',
  initialState: accountActivityInitialState,
  reducers: {
    setActiveReplayTicketId(state, action: PayloadAction<number>) {
      state.activeReplayTicketId = action.payload
    },

    setPendingSortOrder(state, action: PayloadAction<SortOrder>) {
      state.pendingSortOrder = action.payload
    },

    setResultsSortOrder(state, action: PayloadAction<SortOrder>) {
      state.resultsSortOrder = action.payload
    },
  },
})

export const { setActiveReplayTicketId, setPendingSortOrder, setResultsSortOrder } =
  accountActivitySlice.actions

export default persistReducer(
  {
    key: 'account-activity',
    whitelist: ['pendingSortOrder'],
    storage,
    debug: !PRODUCTION,
  },
  accountActivitySlice.reducer
)

// =====
// Types
// =====
export interface AccountActivityState {
  activeReplayTicketId: number | null
  pendingSortOrder: SortOrder
  resultsSortOrder: SortOrder
}
