const navData: {
  backUrl: string
  urlStack: string[]
} = { backUrl: '#', urlStack: [] }

// set the fixed back url which applies not using stack based nav for this page
export function setBackUrl(backUrl: string) {
  navData.backUrl = backUrl
}

// push url to stack, if url already in stack, bring it to the top
export function pushUrl(url: string) {
  navData.urlStack = navData.urlStack.filter(elem => elem != url)
  navData.urlStack.unshift(url)
}

// get the current back url without removing it
export function peekUrl() {
  if (navData.urlStack.length) {
    return navData.urlStack[0]
  }
  return navData.backUrl
}

// remove all instances of urls starting with url from stack
export function pruneUrl(baseUrl: string) {
  navData.urlStack = navData.urlStack.filter(elem => elem.substring(0, baseUrl.length) != baseUrl)
}

// clear the nav stack, effectivly reverting to the normal fixed structural nav
export function clearUrlStack() {
  navData.urlStack = []
}
