import React from 'react'
import { useAppSelector } from '@core/Store/hooks'
import {
  fetchAccountBalanceAsync,
  fetchCampaignsAsync,
} from '@core/State/UserAccount/async-signals'

export const useRefreshBalanceOnBetsPlaced = () => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)

  React.useEffect(() => {
    if (workflowStatus == 'bets-placed') {
      fetchAccountBalanceAsync()
      fetchCampaignsAsync()
    }
  }, [workflowStatus])
}
