import * as ko from 'knockout'
import { IRaceReplayViewModel } from './IRaceReplayViewModel'
import container from '../../../IOC/inversify.config'
import ObservableRaceReplay from '../../../Model/Observables/ObservableRaceReplay'
import raceReplayTemplate from './race-replay.tpl.html'
import raceReplayVideoTemplate from './race-replay-video.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('race-replay')) {
    ko.components.register('race-replay', {
      viewModel: {
        createViewModel: (params: {
          id: string
          raceReplayData: ObservableRaceReplay
          source: string
        }): IRaceReplayViewModel => {
          const viewModel = container.get<IRaceReplayViewModel>('IRaceReplayViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: raceReplayTemplate,
    })
  }

  if (!ko.components.isRegistered('race-replay-video')) {
    ko.components.register('race-replay-video', {
      template: raceReplayVideoTemplate,
    })
  }
}
