import dayjs from 'dayjs'
import { toTitleCase } from '@mobi/utils/string'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { isFobRacingWithIncorrectStarterNumbers } from './isFobRacingWithIncorrectStarterNumbers'

export function getSingleTicketTitle(
  leg: Ticket['legs'][0],
  planSeq: Ticket['planSeq'],
  shouldReturnNumberSeparate: boolean = false
): { title: string; runnerNumber?: number } {
  const selection = leg.selections[0].selectionDetails[0]

  if (selection.acceptorDetails.length !== 1) {
    return { title: selection.selectionString?.replace(/([.-])/g, '$1\u200B') || '' }
  }

  const title = toTitleCase(selection.acceptorDetails[0].name)
  if (isFobRacingWithIncorrectStarterNumbers(planSeq, leg.betType)) {
    return { title }
  }

  if (shouldReturnNumberSeparate) {
    return { title, runnerNumber: selection.acceptorDetails[0].number }
  }

  const runnerNumber = selection.acceptorDetails[0].number
  return {
    title: (runnerNumber ?? leg.header) + ' - ' + title,
  }
}

export function getTitleOdds(investmentLines: Ticket['investment']['lines']): string {
  const odds: string[] = []
  investmentLines.forEach(x => {
    const match = x.displayName.match(/[\d.]+/)
    if (!match) return
    odds.push(match[0])
  })

  return odds.join(' / ')
}

export function getNumberFromString(string: string): string {
  const match = string.match(/[\d.]+/)
  if (!match) return ''
  const hasDecimal = +match[0] % 1 !== 0
  return hasDecimal ? (+match[0]).toFixed(2) : match[0]
}

export function formatDateTime(date: string): string {
  return dayjs(date).format('ddd D MMM YYYY - h:mm A')
}
