import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon, type IconProps } from '@mobi/component-library/Common/Icon'

export const IconStyled = styled(Icon)<IconProps>({
  margin: '0 1rem 0 0',
  zIndex: 1,
  position: 'relative',
})

export const HeaderStyled = styled.h3({
  color: hexColors.white,
  backgroundColor: hexColors.black,
  margin: '1rem 0',
  display: 'flex',
  alignItems: 'center',
})
