import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import {
  RovingBankerProjection,
  Projection,
  RegularProjection,
  AllwaysProjection,
  LegInProjection,
} from '../MultiSelectionProjections'

export class NoveltySelectionsViewModel {
  public bettingContext: BettingInformation
  public betTypeName: string
  public selectionStringRaws!: Projection[]

  constructor(params: { bettingContext: BettingInformation }) {
    this.bettingContext = params.bettingContext
    this.betTypeName = this.bettingContext.selectedBetType().name()

    this.initialise()
  }

  private initialise(): void {
    const selections = this.bettingContext
      .selectedBetType()
      .selectionStringProcessor.getSelectionsResult(
        this.bettingContext,
        this.bettingContext.raceNumber
      )
    const selectionStrings = selections.selectionStrings
    let index = 1
    this.selectionStringRaws = []

    for (let element of selectionStrings) {
      let selectionRaw: Projection

      if (this.bettingContext.rovingBanker()) {
        selectionRaw = new RovingBankerProjection(index, element)
      } else if (this.bettingContext.isAllwaysBet()) {
        selectionRaw = new AllwaysProjection(index, element)
      } else if (this.bettingContext.isLegIn()) {
        selectionRaw = new LegInProjection(index, element)
      } else if (this.bettingContext.selectedBetType().betType() === BetType.Quinella) {
        selectionRaw = new Projection('Race ' + this.bettingContext.raceNumber, element)
      } else {
        selectionRaw = new RegularProjection(index, element)
      }
      this.selectionStringRaws.push(selectionRaw)
      index++
    }

    //remove rows if either title or data is empty
    this.selectionStringRaws = this.selectionStringRaws
      .filter(raw => raw.data != undefined && raw.data.length > 0)
      .filter(raw => raw.title != undefined && raw.title.length > 0)

    //if only one starter selected for a leg than add starter name to selection string
    let selectedStarters = this.bettingContext.selections.getStartersForRace(
      this.bettingContext.raceNumber
    )
    for (let selectionStringRaw of this.selectionStringRaws) {
      let selectionNumber = Number(selectionStringRaw.data)
      if (Number.isInteger(selectionNumber)) {
        selectionStringRaw.data =
          selectionStringRaw.data +
          ' - ' +
          (
            selectedStarters().find(s => s.number() === selectionNumber) as IObservableStarter
          ).name()
      }

      selectionStringRaw.data = selectionStringRaw.data.split('.').join('.\u200B') //\u200B is a zero-width space so the string will break over multiple lines
    }
  }
}
