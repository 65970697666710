import React from 'react'
import { observeImmutable } from '../../Components/HOCs'
import { state$ as superPickState$, SuperPickStateRecord } from './driver'
import {
  SuperPickButtonStyled,
  RewardStyled,
  SuperPickButtonTextStyled,
  SuperPickButtonSubTextStyled,
  RewardInnerStyled,
  SuperPickButtonSelectedTextStyled,
  SuperBoostButtonStyled,
} from './SuperPick.styles'
import { ChangeSelectedSuperPick } from './signals'
import { BetSpecialOffer, BetSpecialOfferElement } from '@classic/Specials/Model/BetSpecialOffer'
import { useFeature } from '@core/Utils/hooks'

interface SuperPickButtonProps {
  selectedSuperPickSeq?: number | null
  specialOffer: BetSpecialOffer
  projectedReward: number
  isDisabled: boolean
}

const checkDisplayReward = (
  specialOffer: BetSpecialOffer,
  selectedSuperPickSeq?: number | null
): boolean => {
  if (specialOffer.specialTypeCode === 'BST') {
    return false
  }
  if (!selectedSuperPickSeq) {
    return true
  }
  return selectedSuperPickSeq === specialOffer.specialSeq
}

const getMaxReward = (specialOffer: BetSpecialOffer): string => {
  if (!specialOffer.elements) {
    return ''
  }

  const nonBoostElement = specialOffer.elements.find(
    (element: BetSpecialOfferElement) => !element.isBoost
  )
  if (!nonBoostElement) {
    return ''
  }
  return nonBoostElement.maxReward ? ` up to $${nonBoostElement.maxReward}` : ''
}

export function SuperPickButtonComponent({
  specialOffer,
  projectedReward,
  selectedSuperPickSeq,
  isDisabled,
}: SuperPickButtonProps): JSX.Element {
  const isSelected = specialOffer.specialSeq === selectedSuperPickSeq
  const isInsuranceOffer = specialOffer.specialTypeCode === 'WBI'
  const shouldDisplayReward = checkDisplayReward(specialOffer, selectedSuperPickSeq)
  const maxReward = getMaxReward(specialOffer)
  const title = specialOffer.title?.split('-')

  const DisplayReward = () => {
    return (
      <RewardStyled isSelected={isSelected}>
        <RewardInnerStyled>
          {isInsuranceOffer && <strong>Money Back:</strong>} <strong>${projectedReward}</strong>
          {maxReward}
        </RewardInnerStyled>
      </RewardStyled>
    )
  }

  return (
    <div>
      <SuperPickButtonStyled
        data-tid-superpick-btn=''
        disabled={isDisabled}
        isInsuranceOffer={isInsuranceOffer}
        shouldDisplayReward={shouldDisplayReward}
        isSelected={isSelected}
        onClick={ChangeSelectedSuperPick.bind(null, specialOffer.specialSeq ?? undefined)}
        role='switch'
        aria-checked={isSelected}
        color='secondary'
      >
        <SuperPickButtonTextStyled>{title?.[0]}</SuperPickButtonTextStyled>
        {title?.[1] ? (
          <SuperPickButtonSubTextStyled>{title[1]}</SuperPickButtonSubTextStyled>
        ) : null}
        {isSelected ? (
          <SuperPickButtonSelectedTextStyled> (selected)</SuperPickButtonSelectedTextStyled>
        ) : null}
      </SuperPickButtonStyled>
      {shouldDisplayReward && <DisplayReward />}
    </div>
  )
}

const SuperBoostButtonComponent = ({
  specialOffer,
  projectedReward,
  selectedSuperPickSeq,
  isDisabled,
}: SuperPickButtonProps): JSX.Element => {
  const isSelected = specialOffer.specialSeq === selectedSuperPickSeq
  const isInsuranceOffer = specialOffer.specialTypeCode === 'WBI'
  const shouldDisplayReward = checkDisplayReward(specialOffer, selectedSuperPickSeq)
  const maxReward = getMaxReward(specialOffer)
  const title = specialOffer.title?.split('-')

  const DisplayReward = () => {
    return (
      <RewardStyled isSelected={isSelected}>
        <RewardInnerStyled>
          {isInsuranceOffer && <strong>Money Back:</strong>} <strong>${projectedReward}</strong>
          {maxReward}
        </RewardInnerStyled>
      </RewardStyled>
    )
  }

  return (
    <div>
      <SuperBoostButtonStyled
        data-tid-superboost-btn=''
        disabled={isDisabled}
        isInsuranceOffer={isInsuranceOffer}
        shouldDisplayReward={shouldDisplayReward}
        isSelected={isSelected}
        onClick={ChangeSelectedSuperPick.bind(null, specialOffer.specialSeq ?? undefined)}
        role='switch'
        aria-checked={isSelected}
        color='secondary'
      >
        <SuperPickButtonTextStyled>{title?.[0]}</SuperPickButtonTextStyled>
        {title?.[1] ? (
          <SuperPickButtonSubTextStyled>{title[1]}</SuperPickButtonSubTextStyled>
        ) : null}
        {isSelected ? (
          <SuperPickButtonSelectedTextStyled> (selected)</SuperPickButtonSelectedTextStyled>
        ) : null}
      </SuperBoostButtonStyled>
      {shouldDisplayReward && <DisplayReward />}
    </div>
  )
}

export const SuperPickButton = observeImmutable<
  SuperPickStateRecord,
  { specialOffer: BetSpecialOffer; projectedReward: number; isDisabled: boolean }
>(
  superPickState$,
  ({ record: { selectedSuperPickSeq }, specialOffer, projectedReward, isDisabled }) => {
    const isSuperBoostFeatureActive = !!useFeature('HOMEPAGE_SUPERPICKS')

    return isSuperBoostFeatureActive && specialOffer.isSuperBoost ? (
      <SuperBoostButtonComponent
        specialOffer={specialOffer}
        projectedReward={projectedReward}
        selectedSuperPickSeq={selectedSuperPickSeq}
        isDisabled={isDisabled}
      />
    ) : (
      <SuperPickButtonComponent
        specialOffer={specialOffer}
        projectedReward={projectedReward}
        selectedSuperPickSeq={selectedSuperPickSeq}
        isDisabled={isDisabled}
      />
    )
  }
)
