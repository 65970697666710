import jQuery from 'jquery'
import { injectable, inject } from 'inversify'
import { ServiceError } from '../Model/ServiceError'
import type { ILoginHelper } from '../../AppUtils/Framework/Utils/ILoginHelper'
import { IErrorHandler } from './IErrorHandler'
import { store } from '@core/Store'
import { closeModal, openModal } from '@core/Components/Modal'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import * as navLoading from '@classic/Foundation/Navigation/NavigationLoading'

@injectable()
export class ErrorHandler implements IErrorHandler {
  private logInHelper: ILoginHelper

  static readonly ERROR_CODE_NOT_FOUND: number = 404

  constructor(@inject('ILoginHelper') logInHelper: ILoginHelper) {
    this.logInHelper = logInHelper
  }

  private showPopup(popupDiv: JQuery) {
    //delay 50 milliseconds to allow background layout completed
    setTimeout(function () {
      jQuery('div .popup').hide() // hide all other popups (especially any confirmation popup)
      var documentHeight = jQuery(document).height()
      popupDiv.css({ height: documentHeight }).fadeIn('fast')
      popupDiv.children('.overlay').animate({ opacity: 0.6 }, 'fast')

      var scrollTop = window.pageYOffset
        ? window.pageYOffset
        : document.body.parentElement
          ? document.body.parentElement.scrollTop
          : 0

      //Using a minimum scroll top to stop the close popup being over the 'log out' button.
      popupDiv.children('.window').css({ top: Math.max(scrollTop + 20, 40) })

      //Check if the height has changed due to showing the popup, and increase the overlay size accordingly
      var newHeight = popupDiv.children('.window').height() + scrollTop + 100
      if (newHeight > documentHeight) {
        popupDiv.children('.overlay').css({ height: newHeight })
      }
    }, 50)
  }

  private hideErrorPopup() {
    jQuery('#error-window').fadeOut('fast')
  }

  private hideNewErrorPopup() {
    jQuery('#new-error-window').fadeOut('fast')
  }

  public showErrorMessage(title: string, subtitle: string, callback?: Function): void {
    showWarningOrErrorPopup({ title, subtitle, type: 'error', callback })
  }

  public showWarningMessage(title: string, subtitle: string, callback?: Function): void {
    showWarningOrErrorPopup({ title, subtitle, type: 'warning', callback })
  }

  public handleErrorAsIs(statusCode: number, errorResponse: string): void {
    switch (statusCode) {
      case 403:
        this.logInHelper.presentLogIn()
        break
      case 550:
        jQuery('#error-window').html(errorResponse)
        this.showPopup(jQuery('#error-window'))
        jQuery('#error-window .close').click(() => {
          this.hideErrorPopup()
        })
        break
      case 551:
        this.reload()
        break
      default:
        jQuery('#ajax-error').show()
        jQuery('#main-container').html('')
        break
    }
  }

  public handle(error: ServiceError): void {
    if (error.status === 403) {
      return
    } else if (error.status === 550) {
      jQuery('#actual-error-message').html(error.responseText)
      this.showPopup(jQuery('#new-error-window'))
      jQuery('#new-error-window .close').click(() => {
        this.hideNewErrorPopup()
      })
    }
  }

  public reload(): void {
    location.reload()
  }
}

export function showWarningOrErrorPopup({
  title,
  subtitle,
  type,
  callback,
}: {
  title: string
  type: 'error' | 'warning'
  callback?: Function
  subtitle?: string
}) {
  navLoading.hideLoading()

  store.dispatch(
    openModal({
      id: 'betting-v2-error-handler',
      modalComponent: NoticeBox,
      modalComponentProps: {
        title,
        subtitle,
        noticeBoxType: type === 'error' ? NoticeBoxTypes.Error : NoticeBoxTypes.Warning,
        hasBorder: false,
        buttonClick: () => {
          store.dispatch(closeModal({ id: 'betting-v2-error-handler' }))
          callback?.()
        },
      },
      isDismissable: false,
      position: 'middle',
    })
  )
}
