import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SkyPageLimit } from '../Hooks/useNextSkyRaces'

export const skyRacesInitialState: SkyRacesState = {
  filters: { races: true, dogs: true, trots: true, international: true, local: true },
  isSelectedRaceScrolledOutOfView: false,
  resetTimestamp: 0,
  isSkyRaceListViewOpen: false,
  skyRaceLimit: SkyPageLimit,
}

const skyRacesSlice = createSlice({
  name: 'sky-races',
  initialState: skyRacesInitialState,
  reducers: {
    toggleFilter(state, { payload }: PayloadAction<keyof FilterOptions>) {
      const newFilters = {
        ...state.filters,
        [payload]: !state.filters[payload],
      }
      if (validateFilters(newFilters)) state.filters = newFilters
    },

    setIsSelectedRaceScrolledOutOfView(state, { payload }: PayloadAction<boolean>) {
      state.isSelectedRaceScrolledOutOfView = payload
    },

    setResetTimestamp(state) {
      state.resetTimestamp = Date.now()
    },

    toggleIsSkyRaceListViewOpen(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isSkyRaceListViewOpen = payload ?? !state.isSkyRaceListViewOpen
    },
    setSkyRaceLimit(state, { payload }: PayloadAction<number>) {
      state.skyRaceLimit = payload
    },
  },
})

export const {
  toggleFilter,
  setIsSelectedRaceScrolledOutOfView,
  setResetTimestamp,
  toggleIsSkyRaceListViewOpen,
  setSkyRaceLimit,
} = skyRacesSlice.actions

export default skyRacesSlice.reducer

// =============
// Local Helpers
// =============

const validateFilters = (newFilters: FilterOptions) => {
  // Needs at least 1 race code filter set
  if (!newFilters.dogs && !newFilters.races && !newFilters.trots) return false
  // Needs at least Int'l or Local filter set
  if (!newFilters.international && !newFilters.local) return false

  return true
}

// =====
// Types
// =====

export type FilterOptions = {
  races: boolean
  dogs: boolean
  trots: boolean
  international: boolean
  local: boolean
}

export interface SkyRacesState {
  filters: FilterOptions
  isSelectedRaceScrolledOutOfView: boolean
  /** Used to reset to latest race if changed while on sky race route */
  resetTimestamp: number
  isSkyRaceListViewOpen: boolean
  skyRaceLimit: number
}
