import dayjs from 'dayjs'
import React from 'react'

import { GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { useObservableImmutable } from '@core/Utils/hooks'

import { state$ } from '../driver'

import {
  MeetingInformationContainerStyled,
  RaceNumberStyled,
  MeetingInformationSectionStyled,
  RaceTimeStyled,
  MeetingNameStyled,
  RaceNameStyled,
} from '../MysteryBetPage.styles'

export const MeetingInformation = (): JSX.Element | null => {
  const currentData = useObservableImmutable(state$, ['currentData']).currentData?.toJS()

  return (
    <MeetingInformationContainerStyled>
      <GridRow>
        <GridCell flexBasis='auto' flexGrow={0}>
          <MeetingInformationSectionStyled>
            {currentData?.Meeting?.MeetingId}
            <RaceNumberStyled>{currentData?.Race?.RaceNumber}</RaceNumberStyled>
          </MeetingInformationSectionStyled>
          <RaceTimeStyled>{dayjs(currentData?.Race?.RaceTime).format('HH:mm')}</RaceTimeStyled>
        </GridCell>
        <GridCell padding='0 0 0 1.4rem' flexGrow={1}>
          <MeetingNameStyled>{currentData?.Meeting?.MeetingName}</MeetingNameStyled>
          <RaceNameStyled>{currentData?.Race?.RaceName}</RaceNameStyled>
        </GridCell>
      </GridRow>
    </MeetingInformationContainerStyled>
  )
}
