import React from 'react'
import Rx from 'rx'
import { Record } from 'immutable'

export interface ObserveHOCState<TObservedValue> {
  currentValue: TObservedValue
  hasReceivedValue: boolean
}

export function observeImmutable<TRecord, TProps>(
  observable$: Rx.Observable<TRecord>,
  WrappedComponent:
    | React.ComponentClass<{ record: TRecord } & TProps>
    | React.StatelessComponent<{ record: TRecord } & TProps>
) {
  {
    return observe<{ record: TRecord }, TProps>(
      observable$.select((record: TRecord) => ({ record })),
      WrappedComponent
    )
  }
}

export function observe<TObservedValue, TProps>(
  observable$: Rx.Observable<TObservedValue>,
  WrappedComponent:
    | React.StatelessComponent<TObservedValue & TProps>
    | React.ComponentClass<TObservedValue & TProps>
) {
  return class ObservableHOC extends React.Component<TProps, ObserveHOCState<TObservedValue>> {
    sub: Rx.Disposable | undefined

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any) {
      super(props)
      this.state = { currentValue: null as TObservedValue, hasReceivedValue: false }
    }

    public UNSAFE_componentWillMount() {
      this.sub = observable$.distinctUntilChanged().subscribe(x => {
        this.setState({ currentValue: x, hasReceivedValue: true })
      })
    }

    public componentWillUnmount() {
      if (this.sub) this.sub.dispose()
    }

    public render() {
      if (this.state.hasReceivedValue) {
        if (!PRODUCTION) {
          if (this.state.currentValue instanceof Record) {
            return (
              <span style={{ color: 'red' }}>
                Cannot spread an immutable record as properties of a component. Provide a nested
                object or use <code style={{ fontFamily: 'monospace' }}>observeImmutable</code>.
              </span>
            )
          }
        }
        return <WrappedComponent {...this.state.currentValue} {...this.props} />
      }
      return null
    }
  }
}
