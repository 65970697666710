import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const DetailsListStyled = styled.dl({
  textAlign: 'left',
})

export const HorizontalDivider = styled.div({
  borderTop: `1px solid ${hexColors.veryLightGrey}`,
  height: 0,
  margin: '1.5rem 0',
})

export const DescriptionStyled = styled.dt({
  fontSize: '1.1rem',
  lineHeight: '1.1rem',
  color: hexColors.dimGrey,
  marginBottom: '1.0rem',

  // desktop overrides
  float: 'none',
  width: '100%',
})

export const DataStyled = styled.dd({
  fontSize: '1.6rem',
  lineHeight: '2rem',
  margin: 0,

  // desktop overrides
  float: 'none',
  width: '100%',
})

export const AdditionalLinksStyled = styled.a({
  color: hexColors.sushi,
  textDecoration: 'underline',
  fontSize: '1.2rem',
  padding: '1rem 0 0',
  display: 'block',
})

export const NoticeBoxInternationalStyled = styled.div({
  boxSizing: 'border-box',
  padding: '0.2rem 1.5rem',
  border: `2px solid ${hexColors.black}`,
  lineHeight: 2,
})
