import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { hexColors } from '@mobi/settings'
import { toTitleCase } from '@mobi/utils/string'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import { getNextRaceTimeDisplayOptions } from '@core/Areas/Racing/helpers/getNextRaceTimeDisplayOptions'
import { toggleMeetingsFavourite } from '@core/Areas/Meetings/Store'
import {
  trackMeetingsFavouriteAdded,
  trackMeetingsFavouriteRemoved,
  trackMeetingsFavouriteUsed,
} from '@core/Areas/Meetings/analytics'
import type { useMeetingsData } from '@core/Areas/Meetings/hooks'

const enum LocalConstants {
  ActiveFavouriteClass = 'meetings__meeting__favourited',
  RacePendingClass = 'meetings__meeting__pending',
  RowStatusRaceMinWidth = '10rem',
}

export const MeetingRow: React.FC<{
  meeting: Meeting
  newFavRef: React.MutableRefObject<Meeting | undefined>
  isFavourite?: boolean
}> = ({ meeting, isFavourite, newFavRef }) => {
  const dispatch = useDispatch()

  const { name, geoLocationCode } = getNameAndGeoLocationCode(meeting)
  const linkParamsForMeeting = getMeetingLinkParams(meeting)
  const linkToNextRace = getLinkToNextRace(meeting)

  const handleToggleFavourite = () => {
    dispatch(toggleMeetingsFavourite(meeting.name))
    newFavRef.current = isFavourite ? undefined : meeting
    isFavourite
      ? trackMeetingsFavouriteRemoved()
      : trackMeetingsFavouriteAdded({ meetingName: meeting.name, meetingRaceCode: meeting.code })
  }

  const raceTimeOptions = meeting.nextRaceAst && getNextRaceTimeDisplayOptions(meeting.nextRaceAst)

  return (
    <MeetingRowStyled>
      <button
        onClick={handleToggleFavourite}
        className={isFavourite ? LocalConstants.ActiveFavouriteClass : ''}
        data-testid='meeting-row-fav-btn'
      >
        <Icon name='SolidStar01' size='2rem' />
      </button>

      <Link
        to={{ pathname: '/tote/races', search: linkParamsForMeeting, state: { geoLocationCode } }}
        data-testid='meeting-row-link'
        onClick={isFavourite ? trackMeetingsFavouriteUsed : undefined}
      >
        <span>
          <span data-testid='meeting-row-name'>{name}</span>
        </span>

        <div>
          {geoLocationCode && <span data-testid='meeting-row-geo-tag'>{geoLocationCode}</span>}

          {!linkToNextRace && (
            <strong data-testid='meeting-row-status'>
              <span>{meeting.status === 'Finished' ? 'Resulted' : meeting.status}</span>
            </strong>
          )}
        </div>
      </Link>

      {linkToNextRace && (
        <div>
          <Link
            to={linkToNextRace}
            data-testid='meeting-row-next-race'
            onClick={isFavourite ? trackMeetingsFavouriteUsed : undefined}
          >
            <div>
              <span className={meeting.status === 'Pending' ? LocalConstants.RacePendingClass : ''}>
                <strong data-testid='meeting-row-next-race-number'>
                  R{meeting.nextRaceNumber}
                </strong>
              </span>

              {raceTimeOptions &&
                (raceTimeOptions.showCountDown ? (
                  <RaceCountdown
                    raceStartTime={new Date(raceTimeOptions.advertisedStartTime)}
                    displaySize='1.2rem'
                  />
                ) : (
                  <strong data-testid='meeting-row-next-race-time'>
                    {dayjs(raceTimeOptions.advertisedStartTime).format('HH:mm')}
                  </strong>
                ))}
            </div>
          </Link>
        </div>
      )}
    </MeetingRowStyled>
  )
}

// =============
// Local Helpers
// =============

const getNameAndGeoLocationCode = (meeting: Meeting): Pick<Meeting, 'name' | 'geoLocationCode'> => {
  let name = meeting.name
  let geoLocationCode = meeting.geoLocationCode

  const matchResult = meeting.name.match(/ - [A-Za-z]{2,3}$/)
  if (matchResult) {
    name = meeting.name.replace(/ - [A-Za-z]{2,3}$/, '')
    geoLocationCode = matchResult[0].replace('-', '').trim()
  }

  return { name: toTitleCase(name), geoLocationCode }
}

const getMeetingLinkParams = (meeting: Meeting): string => {
  const toteSpecificParams = `&meetingId=${
    /^[A-Z][RTD][A-Z]?$/.test(meeting.id) ? meeting.id : '-'
  }`
  const fixedSpecificParams = meeting.fixed
    ? '&product=' +
      meeting.fixed.racingProduct.toLowerCase() +
      '&compSeq=' +
      meeting.fixed.competitionId
    : '&compSeq=0'

  return `?date=${dayjs(meeting.date).format(
    'YYYY-MM-DD'
  )}${toteSpecificParams}${fixedSpecificParams}`
}

const getLinkToNextRace = (meeting: Meeting): string => {
  if (!meeting.nextRaceNumber) return ''

  const date = dayjs(meeting.date).format('YYYY-MM-DD')

  if (meeting.fixed != null && meeting.id === meeting.fixed.competitionId) {
    const product = meeting.fixed.racingProduct.toLowerCase()
    const event = meeting.fixed.nextRaceEventId
    return `/fobracing/propositions/${product}/races/${event}?selectionDate=${date}&meetingSeq=${meeting.fixed.competitionId}`
  }

  return `/tote/meetings/${meeting.id}/${meeting.nextRaceNumber}?date=${date}`
}

// ======
// Styles
// ======

const MeetingRowStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: '5rem',
  background: hexColors.white,
  borderBottom: `0.1rem solid ${hexColors.gainsboro}`,

  '@keyframes scale-easeOutElastic': {
    '0%': { transform: 'scale(0.6) rotate(-30deg)' },
    '70%': { transform: 'rotate(0)' },
    '100%': { transform: 'scale(1)' },
  },

  // Fav button
  'button:first-of-type': {
    border: 0,
    background: 0,
    height: '5rem',
    width: '4rem',
    color: hexColors.veryLightGrey,

    [`&.${LocalConstants.ActiveFavouriteClass}`]: {
      color: hexColors.orange,
      animation: 'cubic-bezier(0.18,0.89,0.32,1.27) scale-easeOutElastic .8s',
    },
  },

  // Meeting (link)
  '& > a': {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    lineHeight: 1,
    textDecoration: 'none',
    color: hexColors.nero,

    // Name
    '& > span': {
      flex: 1,
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: 2,
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      span: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    // Tags & Status
    '& > div': {
      alignSelf: 'stretch',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.2rem',

      'span, strong': {
        boxSizing: 'border-box',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        height: '2rem',
        padding: '0.2rem',
      },

      span: {
        marginRight: '0.5rem',
        minWidth: '4rem',
        color: hexColors.darkGrey,
        borderRadius: '0.2rem',
      },

      strong: {
        minWidth: LocalConstants.RowStatusRaceMinWidth,

        span: {
          width: '100%',
          border: '0.1rem solid ' + hexColors.gainsboro,
          background: hexColors.whiteSmoke,
          color: hexColors.nero,
        },
      },
    },
  },

  // Next race
  '& > div': {
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: LocalConstants.RowStatusRaceMinWidth,

    a: {
      flex: 1,
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      marginRight: '0.5rem',
      color: hexColors.black,
      textDecoration: 'none',
      fontSize: '1.2rem',

      '& > div': {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        height: '3.5rem',
        padding: '0.2rem 0.4rem',
        lineHeight: 1,
        borderRadius: '0.5rem',
        border: '0.1rem solid ' + hexColors.blueDiamond90L,
        background: hexColors.blueDiamond97L,

        // Race number
        '& > span': {
          color: hexColors.amethyst,

          [`&.${LocalConstants.RacePendingClass}`]: {
            color: hexColors.darkGrey,
          },
        },
      },
    },
  },
})

// =====
// Types
// =====

type Meeting = NonNullable<ReturnType<typeof useMeetingsData>['data']['meetings']>[0]
