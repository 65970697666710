import React, { lazy, Suspense } from 'react'
import { useHistory } from 'react-router-dom'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { useBackNav } from '@core/Utils/hooks/useBackNav'

import { Loading, Error } from './BankAccountComponents'
import {
  BankAccountContainerStyled,
  BankDetailDescriptionTermStyled,
  BankDetailDescriptionDetailsStyled,
  BankNameStyled,
  BankBranchStyled,
  BankBranchEmptyStyled,
} from './BankAccount.styles'
import { useBankDetailsQuery, isBankAccountRegistered } from './BankAccountApi'
import { BankAccountEditRoute } from './constants'
import { useFeature } from '@core/Utils/hooks'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { AppRoutes } from '@core/AppRoutes'

const App = lazy(() => import('@mobi/account/Areas/BankAccount/Components/App'))

export const BankAccount = (): JSX.Element | null => {
  const isNewWithdrawFeatureActive = useFeature('WITHDRAW_UPLIFT')

  useBackNav()
  const history = useHistory()
  const { isLoading, isError, data: bankAccount } = useBankDetailsQuery()

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <Error />
  }

  if (isNewWithdrawFeatureActive) {
    return (
      <Suspense fallback={<Spinner />}>
        <App
          bankAccountRoutePath={AppRoutes.BankAccount}
          bankAccountEditRoutePath={AppRoutes.BankAccountEdit}
          withdrawRoutePath={AppRoutes.Withdraw}
        />
      </Suspense>
    )
  }

  return (
    <MainContainer data-tid-bank-account-container=''>
      <ContentHeader title='Bank Account' />
      <BankAccountContainerStyled>
        {isBankAccountRegistered(bankAccount) && (
          <BankNameStyled data-tid-bank-name=''>{bankAccount.bankName}</BankNameStyled>
        )}
        {!isBankAccountRegistered(bankAccount) ? (
          <BankBranchEmptyStyled>Bank account currently not registered</BankBranchEmptyStyled>
        ) : (
          <>
            <BankBranchStyled data-tid-branch-name=''>{bankAccount.branchName}</BankBranchStyled>

            <dl>
              <BankDetailDescriptionTermStyled>BSB</BankDetailDescriptionTermStyled>
              <BankDetailDescriptionDetailsStyled data-tid-bank-bsb=''>
                {bankAccount.bsb}
              </BankDetailDescriptionDetailsStyled>
              <BankDetailDescriptionTermStyled>Account Name</BankDetailDescriptionTermStyled>
              <BankDetailDescriptionDetailsStyled data-tid-bank-account-name=''>
                {bankAccount.accountName}
              </BankDetailDescriptionDetailsStyled>
              <BankDetailDescriptionTermStyled>Account Number</BankDetailDescriptionTermStyled>
              <BankDetailDescriptionDetailsStyled data-tid-bank-account-name=''>
                {bankAccount.accountNumber}
              </BankDetailDescriptionDetailsStyled>
            </dl>
          </>
        )}

        <ButtonBlock
          testId='edit-bank-account-btn'
          onClick={() => history.push(`/${BankAccountEditRoute}?fromView=BankAccount`)}
        >
          {isBankAccountRegistered(bankAccount) ? 'Edit Details' : 'Register'}
        </ButtonBlock>
      </BankAccountContainerStyled>
    </MainContainer>
  )
}
