import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { BetslipErrorBoundaryContainer } from './BetslipErrorBoundary.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { ClearBetslip, OnClose } from '../../signals'
import { navigateToPendingBets } from '@classic/AppUtils/Framework/Intent/navigation'
import { logError } from '@core/Utils/logging/logging'
import {
  BetslipMainContainerStyled,
  BetslipOverlayHeaderStyled,
  BetslipOverlayHeaderTextStyled,
} from '../../Betslip.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { OverlayClose } from '@core/Components/Overlay'
import { OverlayCloseButtonStyled } from '@core/Components/Overlay/Overlay.styles'

const handleCloseButtonClick = () => OverlayClose()

export class BetslipErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error: Error) {
    logError(`Betslip render error: ${error.toString()}`, true)
    this.setState({
      hasError: true,
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <BetslipMainContainerStyled data-tid-betslip-maincontainer='' background='grey'>
          <BetslipOverlayHeaderStyled>
            <BetslipOverlayHeaderTextStyled>Betslip</BetslipOverlayHeaderTextStyled>
            <OverlayCloseButtonStyled onClick={handleCloseButtonClick} data-testid='close-button'>
              <Icon type='cross' title='Close' />
            </OverlayCloseButtonStyled>
          </BetslipOverlayHeaderStyled>

          <BetslipErrorBoundaryContainer>
            <NoticeBox
              title={'Something went wrong, please start again or check pending bets'}
              noticeBoxType={NoticeBoxTypes.Error}
              hasBorder={true}
            >
              <Grid>
                <GridCell width='50%' padding='0.5rem'>
                  <ButtonBlock onClick={this.handleClearBetslip} color='primary'>
                    Clear Betslip
                  </ButtonBlock>
                </GridCell>
                <GridCell width='50%' padding='0.5rem'>
                  <ButtonBlock
                    data-tid-pending-bets=''
                    onClick={this.handlePendingClick}
                    color='secondary'
                  >
                    Pending Bets
                  </ButtonBlock>
                </GridCell>
              </Grid>
            </NoticeBox>
          </BetslipErrorBoundaryContainer>
        </BetslipMainContainerStyled>
      )
    }

    return this.props.children
  }

  private handlePendingClick = () => {
    OverlayClose()
    OnClose()
    navigateToPendingBets()
  }

  private handleClearBetslip = () => {
    ClearBetslip()
    this.setState({ hasError: false })
  }
}
