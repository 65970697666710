import React from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { useFeature } from '@core/Utils/hooks'
import { Message } from '@core/Components/Messages'
import {
  HapticFeedbackSetting,
  isHapticsSupported,
  EnhancedBetslipSetting,
  PayoutNotification,
  NewSportsSetting,
  KeypadDenominationsSetting,
} from './Components'

export const Settings: React.FC = () => {
  useBackNav()

  const isEnhancedBetslipFeatureToggleActive = useFeature('ENHANCED_BETSLIP')
  const isHapticFeedbackFeatureActive = useFeature('HAPTIC_FEEDBACK')
  const isNewSportsFeatureActive = useFeature('FIXED_ODDS_SPORTS')
  const isNewBetSlipFeatureActive = useFeature('NEW_BET_SLIP_DESIGN')

  const shouldRenderHapticsSetting = isHapticFeedbackFeatureActive && isHapticsSupported

  return (
    <MainContainer data-tid-settings-container>
      <ContentHeader title='Settings' />

      <SettingsContainer>
        {isNewSportsFeatureActive && <NewSportsSetting />}

        {isEnhancedBetslipFeatureToggleActive && <EnhancedBetslipSetting />}

        {shouldRenderHapticsSetting && <HapticFeedbackSetting />}

        {isReactNativeApp && <PayoutNotification />}

        {isNewBetSlipFeatureActive && <KeypadDenominationsSetting />}
      </SettingsContainer>
    </MainContainer>
  )
}

// ===============
// Local Component
// ===============

const SettingsContainer: React.FC<{}> = ({ children }) => {
  if (!React.Children.toArray(children).some(child => !!child))
    return (
      <Message>
        <b>Coming soon!</b>
        <br />
        This feature will come in a future release.
      </Message>
    )

  return <>{children}</>
}
