import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { MainContainer } from '@core/Components/Containers'
import { TabList } from '@core/Components/Tabs'
import { lessThan } from '@mobi/component-library/Utils/media'

export const ResultsDateStyled = styled('div')({
  fontSize: '1.2rem',
  textAlign: 'center',
})

export const SinglePoolStyled = styled('div')({
  borderBottom: `1px solid ${hexColors.gainsboro}`,
  fontSize: '1.1rem',
  color: hexColors.black,
  padding: '0 1rem',
  minHeight: '4rem',
  display: 'flex',
})

export const RowContainerStyled = styled('div')({
  borderBottom: `1px solid ${hexColors.gainsboro}`,
  fontSize: '1.1rem',
  color: hexColors.dimGrey,
  padding: '0 1rem',
})

export const PoolNameStyled = styled('div')({
  fontSize: '1.2rem',
  lineHeight: '1.5rem',
  fontWeight: 'bold',
})

export const CloseTimeStyled = styled('div')({
  lineHeight: '1.6rem',
})

export const PoolTotalStyled = styled('div')({
  fontSize: '1.2rem',
})

export const BoldedTabList = styled(TabList)({
  fontWeight: 'bold',
})

export const TippingMainContainerStyled = styled(MainContainer)({
  backgroundColor: hexColors.whiteSmoke,
})

export const AbandonedStyled = styled.span({
  display: 'inline-block',
  ['&::after']: {
    content: "'Abandoned'",
  },
  [lessThan('small')]: {
    ['&::after']: {
      content: "'Abnd'",
    },
  },
})
