import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AnimatedSwipeContainerStyled } from './SwipeContainer.styles'
import { useFeature } from '@core/Utils/hooks'
import { Direction, X_THRESHOLD } from './types'
import { isSwipeDisabled } from './helpers'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const RaceSwipeContainer = ({
  children,
  onSwipeCallback,
  isFirstRace,
  isLastRace,
}: {
  children: React.ReactNode
  onSwipeCallback: (direction: Direction) => void
  isLastRace: boolean
  isFirstRace: boolean
}) => {
  const isSwipeToChangeRaceActive = useFeature('SWIPE_TO_CHANGE_RACE')

  const onSwipe = useCallback(
    (direction: Direction) => {
      onSwipeCallback(direction)
      // track in GA
      trackEvent('race-card-swiped', { direction })
    },
    [onSwipeCallback]
  )

  const isForwardSwipeDisabled = !isSwipeToChangeRaceActive || isLastRace
  const isBackwardSwipeDisabled = !isSwipeToChangeRaceActive || isFirstRace

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [xOffset, setXOffset] = useState<number>(0)
  const startXRef = useRef<number | null>(null)
  const startYRef = useRef<number | null>(null)

  const [swipeDirection, setSwipeDirection] = useState<Direction | undefined>()

  // Determines if swiping should be disabled based on the event target's attributes.
  // Prevents edge swipes if not on a scrollable components

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (isSwipeDisabled(e) || !isSwipeToChangeRaceActive) {
        return
      }
      startXRef.current = e.touches[0].clientX
      startYRef.current = e.touches[0].clientY
    },
    [isSwipeToChangeRaceActive]
  )

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (
        isSwipeDisabled(e) ||
        !isSwipeToChangeRaceActive ||
        !startXRef.current ||
        !startYRef.current ||
        !containerRef.current
      ) {
        return
      }
      containerRef.current.style.transition = 'transform 0.2s ease-out'
      const xDiff = startXRef.current - e.touches[0].clientX
      const yDiff = startYRef.current - e.touches[0].clientY

      if (Math.abs(yDiff) > Math.abs(xDiff)) {
        return // Early return to disable swiping when vertical scrolling
      } else {
        document.body.style.overflowY = 'hidden' // Disable vertical scrolling
      }

      // Check if the swipe exceeds the threshold
      if (Math.abs(xDiff) > X_THRESHOLD && Math.abs(yDiff) < 25) {
        if (xDiff > 0) {
          if (!isForwardSwipeDisabled) {
            setXOffset(0.5 * xDiff)
          } else {
            // Allow a limited movement for feedback, e.g., 30% of the swipe distance
            setXOffset(0.3 * xDiff)
          }
        } else if (xDiff <= 0) {
          if (!isBackwardSwipeDisabled) {
            setXOffset(0.5 * xDiff)
          } else {
            setXOffset(0.3 * xDiff)
          }
        }
      }
    },
    [isForwardSwipeDisabled, isBackwardSwipeDisabled, isSwipeToChangeRaceActive]
  )

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      if (
        !isSwipeToChangeRaceActive ||
        isSwipeDisabled(e) ||
        !startXRef.current ||
        !startYRef.current ||
        !containerRef.current
      ) {
        return
      }

      containerRef.current.style.transition = 'none'
      document.body.style.overflowY = 'auto' // Re-enable vertical scrolling
      const xDiff = startXRef.current - e.changedTouches[0].clientX

      const yDiff = startYRef.current - e.changedTouches[0].clientY

      const isScrolling = Math.abs(yDiff) > Math.abs(xDiff)

      if (isForwardSwipeDisabled || isBackwardSwipeDisabled || isScrolling) {
        setXOffset(0)
        setSwipeDirection(undefined)
      }

      if (Math.abs(xDiff) > 55 && !isScrolling) {
        if (xDiff > 0 && !isForwardSwipeDisabled) {
          // left swipe
          onSwipe('FORWARD')
          setSwipeDirection('FORWARD')
        } else if (xDiff <= 0 && !isBackwardSwipeDisabled) {
          // right swipe
          onSwipe('BACKWARD')
          setSwipeDirection('BACKWARD')
        }
      } else {
        setXOffset(0)
      }
    },
    [isForwardSwipeDisabled, isBackwardSwipeDisabled, onSwipe, isSwipeToChangeRaceActive]
  )

  const handleAnimationEnd = (event: AnimationEvent) => {
    if (event.animationName.includes('animation')) {
      setXOffset(0)
      setSwipeDirection(undefined)
    }
  }

  useEffect(() => {
    const container = containerRef.current

    if (!container) {
      return
    }
    container.addEventListener('touchstart', handleTouchStart)
    container.addEventListener('touchmove', handleTouchMove, { passive: false })
    container.addEventListener('touchend', handleTouchEnd)
    container.addEventListener('animationend', handleAnimationEnd)

    return () => {
      if (!container) {
        return
      }
      container.removeEventListener('touchstart', handleTouchStart)
      container.removeEventListener('touchmove', handleTouchMove)
      container.removeEventListener('touchend', handleTouchEnd)
      container.removeEventListener('animationend', handleAnimationEnd)
    }
  }, [handleTouchMove, handleTouchStart, handleTouchEnd])

  return (
    <AnimatedSwipeContainerStyled
      style={{
        transform: `translateX(${-xOffset}px)`,
        transition: 'transform 0.1s ease-out',
      }}
      animateDirection={swipeDirection}
      ref={containerRef}
    >
      {children}
    </AnimatedSwipeContainerStyled>
  )
}
