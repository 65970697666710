import * as ko from 'knockout'
import { WinPlaceButtons } from '@core/Areas/RaceCard/Components/WinPlaceButtons'
import ObservableRaceStarter from '@classic/Betting-v2/Model/Observables/ObservableRaceStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import Guard from '@classic/AppUtils/Framework/Guard'
import { SameRaceMultiButtons } from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiButtons/SameRaceMultiButtons'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import EventAggregator from '@classic/AppUtils/Framework/Messaging/EventAggregator'
import type { IAppWindow } from '@classic/AppUtils/Framework/WindowManagement/IAppWindow'
import { ButtonSelectionType } from '../Core/UIElements/ButtonSelectionType'
import type { ISelection } from '../Core/UIElements/ISelection'
import LegSelectionContext from '../Core/Processors/LegSelectionContext'
import type { ISelectionMadeCommand } from '../Core/Processors/ISelectionMadeContext'
import { ButtonsSelection } from '../Core/UIElements/ButtonsSelection'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'

// injected by dog/race/trot.starter.tpl.html
export interface ISelectionViewModel {
  starter: ObservableRaceStarter
  context: {
    bettingContext: BettingInformation
    raceNumber: ObservableRaceKey
    numberOfStartersInRace: ko.Computed<number>
  }
  eventAggregator: IEventAggregator
  appWindow: IAppWindow
}

export class SelectionViewModel extends Disposable {
  constructor(params: ISelectionViewModel) {
    super(params.eventAggregator || new EventAggregator())

    Guard.notNull(params.starter)
    Guard.notNull(params.context.bettingContext)
    Guard.notNull(params.context.raceNumber)
    Guard.notNull(params.context.numberOfStartersInRace)

    this.starter = params.starter
    this.bettingContext = params.context.bettingContext
    this.raceNumber = params.context.raceNumber
    this.numberOfStartersInRace = params.context.numberOfStartersInRace

    if (params.context.bettingContext.selectedBetType().betType() === BetType.WinPlace) {
      this.setupWinAndPlace()
    } else if (
      params.context.bettingContext.selectedBetType().betType() === BetType.SameRaceMulti
    ) {
      this.setupSameRaceMulti(params)
    }
  }

  private setupWinAndPlace() {
    this.ReactElement = WinPlaceButtons

    this.makeFobSelection = this.makeFobSelection.bind(this)
    this.makeToteSelection = this.makeToteSelection.bind(this)

    this.starterNumber = this.starter.number()
    this.isFixedSuspended = this.starter.fixedOddsStarterInfo.isSuspended
    this.isFixedFavourite = this.starter.fixedOddsStarterInfo.isFavourite
    this.fixedPriceWin = this.starter.fixedOddsStarterInfo.displayWinDividend
    this.fixedPricePlace = ko.pureComputed<string>(() => {
      return (
        (this.starter.fixedOddsStarterInfo.hasPlacePool() &&
          this.starter.fixedOddsStarterInfo.displayPlaceDividend()) ||
        ''
      )
    })
    this.isToteFavourite = this.starter.isFavourite
    this.isToteScratched = this.starter.isScratched
    this.toteScratchType = this.starter.scratchType
    this.totePriceWin = this.starter.displayWinDividend
    this.totePricePlace = ko.pureComputed<string>(() => {
      return (this.starter.hasPlacePool() && this.starter.displayPlaceDividend()) || ''
    })

    if (this.starter.selection === null || typeof this.starter.selection === 'undefined') {
      this.starter.selection = ko.observable(
        new ButtonsSelection(ButtonSelectionType.None, this.raceNumber) as ISelection
      )
    }

    if (this.starter.selection() === null || typeof this.starter.selection() === 'undefined') {
      this.starter.selection(new ButtonsSelection(ButtonSelectionType.None, this.raceNumber))
    }

    this.fobSelected = ko.pureComputed<boolean>(() => {
      if (typeof this.starter.selection !== 'function') {
        return false
      } else {
        const starterSelection = this.starter.selection() as ButtonsSelection
        return starterSelection.hasFob()
      }
    })

    this.toteSelected = ko.pureComputed<boolean>(() => {
      if (typeof this.starter.selection !== 'function') {
        return false
      } else {
        const starterSelection = this.starter.selection() as ButtonsSelection
        return starterSelection.hasTote()
      }
    })

    this.displayFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.starter.hasFixedOdds() &&
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        this.starter.fixedOddsStarterInfo.hasWinDividend()
    )

    this.displayScratchedFixedOdds = ko.pureComputed<boolean>(
      () => this.starter.hasFixedOdds() && this.starter.fixedOddsStarterInfo.isScratched()
    )

    this.displaySuspendedFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.starter.hasFixedOdds() &&
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        !this.starter.fixedOddsStarterInfo.hasWinDividend()
    )

    this.displayTote = ko.pureComputed<boolean>(
      () => this.starter.isToteEnabled() && !this.starter.isScratched()
    )

    if (this.starter.number() == this.numberOfStartersInRace()) {
      this.evtAggregator.publish('last-starter-initialised')
    }

    this.registerDisposals(() => {
      this.fixedPricePlace?.dispose()
      this.totePricePlace?.dispose()
      this.fobSelected?.dispose()
      this.toteSelected?.dispose()
      this.displayFixedOdds?.dispose()
      this.displayTote?.dispose()
      this.displayScratchedFixedOdds?.dispose()
      this.displaySuspendedFixedOdds?.dispose()
    })
  }

  private setupSameRaceMulti(params: ISelectionViewModel) {
    this.ReactElement = SameRaceMultiButtons

    this.makeFobLegSelection = this.makeFobLegSelection.bind(this)
    this.isLegSelected = this.isLegSelected.bind(this)

    this.starterNumber = this.starter.number()

    // ko dto objects are always assigned, so no need to guard against missing prices (e.g. cater for scratched/suspended acceptors)
    this.prices = ko.observableArray()
    this.prices.push(this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.win)
    this.prices.push(this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.top2)
    this.prices.push(this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.top3)
    this.prices.push(this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.top4)

    // a single ButtonsSelection contains reference to all of an acceptor's individual leg selections
    let buttonsSelection = new ButtonsSelection(
      ButtonSelectionType.None, // SRM doesn't need to distinguish tote vs fixed, i.e. they are just buttons
      this.raceNumber,
      params.context.bettingContext.selectedBetType().legs() // leg count, not the actual legs
    )
    this.starter.selection = ko.observable<ISelection>(buttonsSelection)

    // as per MOBI-859, SRM scratching is deliberately based on W&P scratching
    this.displayScratchedFixedOdds = ko.pureComputed<boolean>(() =>
      this.starter.fixedOddsStarterInfo.isScratched()
    )

    // as per MOBI-859, SRM suspended is determined by ..
    // 1. W&P suspended - if W&P is NOT suspended, then SRM without win price is used for suspended
    //    - this scenario is specifically possible because betmakers W&P prices are pushed and SRM prices are polled.
    // 2. SRM missing price - two scenarios (both catered for by sameRaceMultiPrices.isSuspended)
    //    - acceptor has NO SRM prices object returned
    //    - acceptor has SRM prices that are null
    this.displaySuspendedFixedOdds = ko.pureComputed<boolean>(
      () =>
        // scratching takes precedence
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        (this.starter.fixedOddsStarterInfo.isSuspended() ||
          // from a customer perspective missing SRM prices (aka undefined) is treated as suspended, i.e. non-selectable
          this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.isSuspended() != false)
    )

    // event used elsewhere to know the starters are ready for use, e.g. ready for rendering (refer StartersPageViewModel.ts)
    if (this.starter.number() == this.numberOfStartersInRace()) {
      this.evtAggregator.publish('last-starter-initialised')
    }

    this.registerDisposals(() => {
      this.displayScratchedFixedOdds?.dispose()
      this.displaySuspendedFixedOdds?.dispose()
    })
  }

  public makeToteSelection() {
    this.selectionMade(ButtonSelectionType.Tote)
  }

  public makeFobSelection() {
    if (!this.starter.fixedOddsStarterInfo.isSuspended()) {
      this.selectionMade(ButtonSelectionType.Fob)
    }
  }

  public makeFobLegSelection(legNumber: number) {
    // it shouldn't happen that a scratched/suspended acceptor is selected since the button/checkbox won't be present/enabled
    // - but with an abundance of caution ignore selection if it's scratched or suspended
    // - also consistent with the W&P implementation.. which likewise also shouldn't happen
    if (this.displayScratchedFixedOdds?.() || this.displaySuspendedFixedOdds?.()) {
      return
    }

    this.makeLegSelection(ButtonSelectionType.Fob, legNumber)
  }

  public isLegSelected(leg: number) {
    const starterSelection = this.starter.selection() as ButtonsSelection

    return starterSelection.values()[leg]() != ButtonSelectionType.None
  }

  private selectionMade(type: ButtonSelectionType) {
    const starterSelection = this.starter.selection() as ButtonsSelection

    if (!this.bettingContext.isEnhancedBetslip()) {
      if (starterSelection.value() === type) {
        starterSelection.value(ButtonSelectionType.None)
      } else {
        starterSelection.value(type)
      }
    } else {
      processEnhancedBetSelectionInternal(starterSelection, type)
    }

    this.publishSelection(0, type)
  }

  private makeLegSelection(type: ButtonSelectionType, legNumber: number) {
    const starterSelection = this.starter.selection() as ButtonsSelection

    // toggle selection.. supports fob and tote
    starterSelection
      .values()
      [
        legNumber
      ](starterSelection.values()[legNumber]() == ButtonSelectionType.None ? type : ButtonSelectionType.None)

    this.publishSelection(legNumber, type)
  }

  private publishSelection(legNumber: number = 0, buttonSelectionType: ButtonSelectionType) {
    // trigger event, e.g. for Processor subscriber to invoke SameRaceMultiSelectionProcessor to create SelectedRaceStarters selections required for quick bet
    // - the selection value/type is passed so consumers can determine 'fixed vs tote' when the ButtonSelection.value == ButtonSelectionType.None, i.e. button is deselected
    this.evtAggregator.publish('selection-made-command', {
      raceNumber: this.raceNumber.raceNumber(),
      starter: this.starter,
      context: new LegSelectionContext(legNumber, buttonSelectionType),
    } as ISelectionMadeCommand)
  }

  // non-react data, e.g. tote novelties
  public starter: ObservableRaceStarter
  public bettingContext: BettingInformation
  public raceNumber: ObservableRaceKey
  public numberOfStartersInRace: ko.Computed<number>

  // react shared data
  public ReactElement?: React.ElementType
  public displayScratchedFixedOdds?: ko.PureComputed<boolean>
  public displaySuspendedFixedOdds?: ko.PureComputed<boolean>

  // react WinPlace data
  public displayFixedOdds?: ko.PureComputed<boolean>
  public displayTote?: ko.PureComputed<boolean>
  public fobSelected?: ko.PureComputed<boolean>
  public toteSelected?: ko.PureComputed<boolean>
  public starterNumber?: number
  public isFixedSuspended?: ko.Observable<boolean>
  public isFixedFavourite?: ko.Observable<boolean>
  public fixedPriceWin?: ko.Observable<string>
  public fixedPricePlace?: ko.PureComputed<string>
  public isToteFavourite?: ko.Observable<boolean>
  public isToteScratched?: ko.Observable<boolean>
  public toteScratchType?: ko.Observable<string>
  public totePriceWin?: ko.Observable<string>
  public totePricePlace?: ko.PureComputed<string>

  // react SameRaceMulti data
  public prices!: ko.ObservableArray<ko.Observable<number | undefined>>
  //#endregion
}

export const isStarterScratched = (starter: IObservableStarter, isFixed: boolean) =>
  starter.isScratchedToteAndFob() ||
  (!isFixed && starter.isScratched()) ||
  (isFixed && starter.isFixedScratchedOrSuspended())

export function processEnhancedBetSelection(
  existingSelection: ButtonsSelection,
  newSelectionIsFixed: boolean
) {
  const newSelectionBetType = newSelectionIsFixed
    ? ButtonSelectionType.Fob
    : ButtonSelectionType.Tote
  processEnhancedBetSelectionInternal(existingSelection, newSelectionBetType)
}

function processEnhancedBetSelectionInternal(
  existingSelection: ButtonsSelection,
  newSelectionType: ButtonSelectionType
) {
  let resultedSelection = getEnhancedBetSelection(existingSelection.value(), newSelectionType)
  existingSelection.value(resultedSelection)
}

const getEnhancedBetSelection = (
  existingSelection: ButtonSelectionType,
  newSelectionType: ButtonSelectionType
) => {
  // enhanced bet selection is similar to regular selection except that it also supports simultaneous Tote and Fob
  if (newSelectionType === ButtonSelectionType.Fob) {
    switch (existingSelection) {
      case ButtonSelectionType.Tote:
        return ButtonSelectionType.ToteAndFob
      case ButtonSelectionType.ToteAndFob:
        return ButtonSelectionType.Tote
      case ButtonSelectionType.Fob:
        return ButtonSelectionType.None
      default:
        return ButtonSelectionType.Fob
    }
  } else {
    switch (existingSelection) {
      case ButtonSelectionType.Fob:
        return ButtonSelectionType.ToteAndFob
      case ButtonSelectionType.ToteAndFob:
        return ButtonSelectionType.Fob
      case ButtonSelectionType.Tote:
        return ButtonSelectionType.None
      default:
        return ButtonSelectionType.Tote
    }
  }
}
