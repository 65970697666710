import React from 'react'
import { TransactionRowStyled } from './TransactionRow.styles'

export const TransactionRow: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  if (!value) return null

  return (
    <TransactionRowStyled data-testid='transaction-detail-line'>
      <dt>{name}</dt>
      <dd className='text-right'>{value}</dd>
    </TransactionRowStyled>
  )
}
