import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

interface FeatureItemAnalyticsData {
  featureItemTitle: string
  featureItemPosition: number
  accountNumber: number | null
  internalTrackingCode: string | null
}

export const trackFeatureItemClicked = (data?: FeatureItemAnalyticsData) => {
  trackEvent(analyticsKeys.featureItemClicked, { ...data })
}
