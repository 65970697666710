import React from 'react'
import dayjs from 'dayjs'
import { hexColors } from '@mobi/settings'
import { DirectionalIcon } from '@core/Components/Icons'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import {
  ExtraInfoDetailStyled,
  ExtraInfoHeaderStyled,
  InfoIconStyled,
  PropositionGridRowStyled,
  PropositionOddsStyled,
  PropositionScatchTimeStyled,
  PropositionStartNumberStyled,
  SeparatorGridRowStyled,
  SpecialsIconStyled,
} from '../RaceCardFixedOnly.styles'
import type { Proposition } from '../Data/types'
import { RaceStatus } from '../constants'

export const ExtraInfo: React.FC<{
  title: string
  type: 'info' | 'specials' | 'deductions'
  items: TextItem[] | Proposition[]
}> = ({ items, type, title }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (!items.length) {
    return null
  }

  return (
    <Grid margin='0 0 1rem 0'>
      <ExtraInfoHeaderStyled>
        <GridCell
          valign='middle'
          align='left'
          flexGrow={1}
          padding='0.4rem 1rem'
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          {title}
        </GridCell>

        <GridCell
          valign='middle'
          align='right'
          padding='0 0.8rem 0 0'
          flexBasis='10em'
          flexGrow={0}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          {type === 'info' && <InfoIconStyled type='info' color={hexColors.black} size='1.3rem' />}
          {type === 'specials' && <SpecialsIconStyled width='2.5rem' height='2.5rem' />}
          <DirectionalIcon
            direction={isExpanded ? 'up' : 'down'}
            size='3em'
            valign='middle'
            color={hexColors.whiteSmoke}
          />
        </GridCell>
      </ExtraInfoHeaderStyled>

      {isExpanded &&
        (isTextItemArray(items)
          ? items.map(
              x =>
                !!x.description && (
                  <ExtraInfoDetailStyled key={x.title}>
                    <strong>{x.title}</strong>
                    {x.description}
                  </ExtraInfoDetailStyled>
                )
            )
          : items
              .filter(
                x => x.racingStatus == RaceStatus.Scratched && (x.winDeduction || x.placeDeduction)
              )
              .map(proposition => (
                <React.Fragment key={proposition.name + proposition.sequenceNumber}>
                  <PropositionGridRowStyled
                    key={proposition.name}
                    highlightWinner={false}
                    scratched={false}
                  >
                    <GridCell valign='middle' align='left' padding='0.4rem 1rem' flexGrow={1}>
                      <div>
                        <PropositionStartNumberStyled>
                          {`${proposition.starterNumber}. `}
                        </PropositionStartNumberStyled>
                        {proposition.name}
                      </div>
                    </GridCell>

                    <GridCell padding='0.4rem 0' valign='middle' align='right'>
                      <PropositionScatchTimeStyled>
                        {dayjs(proposition.scratchTime).format('DD/MM/YYYY hh:mm:ss a')}
                      </PropositionScatchTimeStyled>
                    </GridCell>

                    <GridCell
                      padding='0.8rem 1rem 0.8rem 0'
                      valign='middle'
                      align='right'
                      flexBasis='5em'
                      flexGrow={0}
                    >
                      <PropositionOddsStyled>
                        {proposition.winDeduction.toFixed(2)}
                        <br />
                        {proposition.placeDeduction.toFixed(2)}
                      </PropositionOddsStyled>
                    </GridCell>
                  </PropositionGridRowStyled>
                  <SeparatorGridRowStyled />
                </React.Fragment>
              )))}
    </Grid>
  )
}

// Local Helpers

const isTextItemArray = (input: TextItem[] | Proposition[]): input is TextItem[] => {
  return (input as TextItem[])[0].title !== undefined
}

// Types

interface TextItem {
  title: string
  description: string
}
