import {
  FauxTicketTransaction,
  Ticket,
  TicketLeg,
  TicketResponseData,
  Transaction,
  TransactionsResponseData,
} from '@core/Areas/AccountActivity/types'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { getImageUrlWithSizes } from '@core/Components/SilkImage/utils'

export function isTransactionResponse(
  instance: TicketResponseData | TransactionsResponseData
): instance is TransactionsResponseData {
  const transactionsByIdKey: keyof TransactionsResponseData = 'transactionsById'
  return transactionsByIdKey in instance
}

export function isFauxTicketTransaction(
  instance: Ticket | Transaction | FauxTicketTransaction
): instance is FauxTicketTransaction {
  return 'message' in instance
}

type GetByIdKeys = Extract<
  keyof TransactionsResponseData | keyof TicketResponseData,
  'transactionsById' | 'ticketsById'
>
export function getIdFromSortedString(id: `${GetByIdKeys}.${string}`): string {
  return id.split('.')[1]
}

type GetSilkParams = Pick<Ticket['legs'][0], 'fixtureId' | 'raceNumber' | 'fixtureDate'>

export function getSilk({ fixtureId, raceNumber, fixtureDate }: GetSilkParams) {
  const contestType = meetingIdToContestType(fixtureId)
  const {
    url: silkUrl,
    size: { height: silkHeight, width: silkWidth },
  } = getImageUrlWithSizes({
    meetingId: fixtureId,
    meetingDate: fixtureDate,
    isTrots: contestType === 'trots',
    raceNo: raceNumber,
  }).large

  return {
    silkUrl,
    silkHeight,
    silkWidth,
    isDogsSilk: contestType === 'dogs',
  }
}

export function sameRaceMultiPositionToText(position?: number | null) {
  switch (position) {
    case 1:
      return 'Win'
    case 2:
    case 3:
    case 4:
      return `Top ${position}`
    default:
      return ''
  }
}

// sorts by position first, then by acceptor number
export const sortSameRaceMultiTicketLegs = (ticket: Ticket) =>
  [...ticket.legs].sort(sameRaceMultiLegSortCallback)

const sameRaceMultiLegSortCallback = (leg1: TicketLeg, leg2: TicketLeg) => {
  const position1 = leg1.selections[0].selectionDetails[0].acceptorDetails[0].srmPosition
  const position2 = leg2.selections[0].selectionDetails[0].acceptorDetails[0].srmPosition
  if (!position1 || !position2) return 0
  if (position1 !== position2) return position1 - position2
  const number1 = leg1.selections[0].selectionDetails[0].acceptorDetails[0].number
  const number2 = leg2.selections[0].selectionDetails[0].acceptorDetails[0].number
  return number1 - number2
}
