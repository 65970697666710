import { RootState } from '@core/Store'
import type { SortOrder } from '@core/Areas/AccountActivity/types'

export function getPendingSortOrder(state: RootState): SortOrder {
  return state.accountActivity.pendingSortOrder
}

export function getResultsSortOrder(state: RootState): SortOrder {
  return state.accountActivity.resultsSortOrder
}
