import React from 'react'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useAppDispatch } from '@core/Store/hooks'
import { setCloseSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/Store'

export const useLogout = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const subscription = userAccountState$.subscribe(state => {
      if (!state.isLoggedIn) {
        dispatch(setCloseSkyVideoPlayer())
      }
    })

    return () => {
      subscription.dispose()
    }
  }, [dispatch])
}
