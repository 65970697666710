import * as ko from 'knockout'
import { NoveltySelectionsViewModel } from './NoveltySelectionsViewModel'
import template from './novelty-selections.tpl.html'

if (!ko.components.isRegistered('novelty-selections')) {
  ko.components.register('novelty-selections', {
    viewModel: NoveltySelectionsViewModel,
    template,
  })
}
