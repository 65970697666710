import { getFobPriceChangeTopic, getToteRaceTopic } from '@core/State/PushData'
import dayjs from 'dayjs'
import { getSrmPriceChangedEventTopic } from 'tabtouch-push-contract'
import ObservableRacePage from '../../../../Model/Observables/ObservableRacePage'

interface GetRacePushDataTopics {
  raceId: string | null
  raceInformation: ObservableRacePage
  hasSameRaceMulti: boolean
}

export function getRacePushDataTopics({
  raceId,
  raceInformation,
  hasSameRaceMulti,
}: GetRacePushDataTopics) {
  const topics: string[] = []

  const { meetingId, meetingDate, selectedRace } = raceInformation.meetingInformation

  // Fixed Odds Win Place
  const marketSeq = selectedRace.fixedOddsInfo?.marketSequence?.()
  if (marketSeq) {
    topics.push(getFobPriceChangeTopic(marketSeq.toString()))
  }

  // Tote Price & Acceptor Change
  if (raceId) {
    const priceChangedTopic = getToteRaceTopic(raceId, { type: 'priceChanged' })
    topics.push(priceChangedTopic)

    const acceptorChangedTopic = getToteRaceTopic(raceId, { type: 'acceptorChanged' })
    topics.push(acceptorChangedTopic)
  }

  if (hasSameRaceMulti) {
    const sameRaceMultiChangedTopic = getSrmPriceChangedEventTopic(
      dayjs(meetingDate()).format('YYYY-MM-DD'),
      meetingId(),
      selectedRace.raceNumber()
    )
    topics.push(sameRaceMultiChangedTopic)
  }

  return topics
}
