import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { getDeliveryMethodName } from './helpers'
import type { DeliveryMethod, DeliveryMethodTypes } from './types'

export const DeliveryMethods: React.FC<DeliveryMethodsProps> = ({
  deliveryMethods,
  onDeliveryMethodChange,
  selectedDeliveryMethod,
}) => {
  const isSingle = deliveryMethods.length === 1
  return (
    <DeliveryMethodsStyled data-testid='delivery-method-list'>
      {deliveryMethods.map(({ id, type, redactedValue }) => {
        const method: DeliveryMethodTypes = getDeliveryMethodName(type)
        const isChecked = selectedDeliveryMethod === id

        return (
          <li key={id}>
            <input
              type='radio'
              value={id}
              id={method + id}
              checked={isChecked}
              onChange={onDeliveryMethodChange}
            />

            <label htmlFor={method + id} data-testid={`delivery-method-label-${id}`}>
              <div>
                <Icon
                  type={getDeliveryMethodName(type) === 'Email' ? 'envelopeOpen' : 'phone'}
                  size='2rem'
                />
              </div>

              <div>
                <span>{method} to</span>
                <strong>{redactedValue}</strong>
                {isSingle && <span>Would you like to continue?</span>}
              </div>

              <div>{isChecked && <Icon type='tickCircleSolid' size='3rem' />}</div>
            </label>
          </li>
        )
      })}
    </DeliveryMethodsStyled>
  )
}

// ======
// Styles
// ======

const DeliveryMethodsStyled = styled('ul')({
  listStyle: 'none',
  padding: 0,
  marginBottom: '2rem',

  li: {
    marginBottom: '0.5rem',
  },

  input: {
    display: 'none',
  },

  label: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    background: hexColors.white,
    borderRadius: '0.5rem',
    opacity: 0.8,

    div: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },

    'div:nth-of-type(2)': {
      marginLeft: '1rem',
    },

    'div:last-of-type': {
      marginLeft: 'auto',

      svg: {
        opacity: 1,
        color: hexColors.citrus,
      },
    },

    'span, strong, svg': {
      color: hexColors.black,
    },

    svg: {
      opacity: 0.5,
    },

    span: {
      fontSize: '1.4rem',
      margin: '0.5rem 0',
    },

    strong: {
      fontSize: '2rem',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '&:active': {
      opacity: 1,
    },
  },

  'input[type="radio"]:checked + label': {
    opacity: 1,
    transform: 'scale(1.05)',
    margin: '1rem 0.5rem',
  },
})

// =====
// Types
// =====

interface DeliveryMethodsProps {
  deliveryMethods: DeliveryMethod[]
  onDeliveryMethodChange: React.ChangeEventHandler<HTMLInputElement>
  selectedDeliveryMethod?: number
}
