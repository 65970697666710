import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

type AccountNumberParam = {
  accountNumber: number
}

type LocationParam = {
  location: string
}

type MessageParam = {
  message: string
}

type AnalyticsFunction<TParams extends Record<string, unknown>> = (params: TParams) => void

const sharedParams = {
  verificationEntry: 'verification-banner',
  verificationFlow: 'self-verification',
} as const

type BannerLoadedParams = AccountNumberParam & {
  isVerified: boolean
}

export const trackVerificationBannerLoaded: AnalyticsFunction<BannerLoadedParams> = params =>
  trackEvent('verification_banner_loaded', {
    ...params,
  })

export const trackVerificationCallUsButtonClicked: AnalyticsFunction<
  AccountNumberParam & LocationParam
> = params =>
  trackEvent('verification_call_us', {
    ...params,
  })

export const trackVerificationVerifyNowButtonClicked: AnalyticsFunction<
  AccountNumberParam
> = params =>
  trackEvent('verification_now', {
    ...params,
  })

export const trackVerificationStart = () =>
  trackEvent('verification_start', {
    ...sharedParams,
  })

type ContactDetailsParams = { addressWarning: true | false }

export const trackVerificationDetailsCorrect: AnalyticsFunction<ContactDetailsParams> = params =>
  trackEvent('verification_details_correct', {
    ...sharedParams,
    ...params,
  })

export const trackVerificationEditDetails: AnalyticsFunction<ContactDetailsParams> = params =>
  trackEvent('verification_edit_details', {
    ...sharedParams,
    ...params,
  })

export type IdentityDocument = 'licence' | 'passport' | 'medicare'

type EnterIdentificationParams = (
  | {
      optionSelected: 'none'
    }
  | {
      optionSelected: IdentityDocument
      verifyResult: 'success' | 'fail'
    }
) & { previousStep: string }

export const trackVerificationEnterIdentification: AnalyticsFunction<
  EnterIdentificationParams
> = params =>
  trackEvent('verification_enter_identification', {
    ...sharedParams,
    ...params,
  })

type VerificationSuccessParams = { previousStep: string }

export const trackVerificationSuccess: AnalyticsFunction<VerificationSuccessParams> = params =>
  trackEvent('verification_success', { ...sharedParams, ...params })

export const trackVerificationDepositClick = () =>
  trackEvent('verification_deposit_click', { ...sharedParams })

export const trackVerificationFail = () => trackEvent('verification_fail', { ...sharedParams })

export const trackVerificationEditDetailsLocked = () =>
  trackEvent('verification_edit_details_locked', { ...sharedParams })

export const trackVerificationNeedAssistance: AnalyticsFunction<LocationParam> = params =>
  trackEvent('verification_need_assistance', {
    ...sharedParams,
    ...params,
  })

export const trackVerificationGoBackHomePage: AnalyticsFunction<LocationParam> = params =>
  trackEvent('verification_go_back_home_page', {
    ...sharedParams,
    ...params,
  })

export const trackVerificationError: AnalyticsFunction<LocationParam & MessageParam> = params =>
  trackEvent('verification_error', {
    ...sharedParams,
    ...params,
  })

export const trackVerificationContactUs: AnalyticsFunction<LocationParam> = params =>
  trackEvent('verification_contact_us', {
    ...sharedParams,
    ...params,
  })

export const trackVerificationCallUsLater: AnalyticsFunction<LocationParam> = params =>
  trackEvent('verification_call_us_later', {
    ...sharedParams,
    ...params,
  })

type VerificationBetOfferProps = {
  rewardType: string
  title: string
}

export const trackVerificationBetOffer: AnalyticsFunction<VerificationBetOfferProps> = params => {
  trackEvent('verification_bet_offer', {
    ...sharedParams,
    ...params,
  })
}

export const trackVerificationGetInTouch = () => {
  trackEvent('verification_get_in_touch', {
    ...sharedParams,
  })
}

type VerificationSavedDetailsParams = {
  currentAddressEdit: boolean
  previousAddressEdit: boolean
  personalInfoEdit: boolean
}
export const trackVerificationSavedDetails: AnalyticsFunction<
  VerificationSavedDetailsParams
> = params => {
  trackEvent('verification_saved_details', { ...sharedParams, ...params })
}
