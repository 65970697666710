import React from 'react'
import { ListItemStyled } from './ListItem.styles'
import { ListLinkStyled } from './ListLink.styles'

interface ListItemProps {
  href?: string
  children?: React.ReactChild | React.ReactChild[]
  hideChevron?: boolean
}

export function ListItem(props: ListItemProps) {
  if (props.href) {
    return (
      <ListItemStyled {...props}>
        <ListLinkStyled {...props}>{props.children}</ListLinkStyled>
      </ListItemStyled>
    )
  } else {
    return <ListItemStyled {...props}>{props.children}</ListItemStyled>
  }
}
