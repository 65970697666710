import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { useBlackbookRunners } from '@core/Areas/Blackbook/Hooks/useBlackbookRunners'
import { getMatchedRunnerInBlackbook } from '@core/Areas/Blackbook/helpers/getMatchedRunnerInBlackbook'

export const RunnerNumber: React.FC<{
  starterNumber: number
  starterName: string
  starterType: RaceCodes
}> = ({ starterNumber, starterName, starterType }) => {
  const { data: blackbookRunners } = useBlackbookRunners()
  const isRunnerInBlackbook = !!getMatchedRunnerInBlackbook(
    blackbookRunners,
    starterName,
    starterType
  )

  if (!isRunnerInBlackbook) return <>{starterNumber}</>

  return <WrapperStyled>{starterNumber}</WrapperStyled>
}

const WrapperStyled = styled.span({
  display: 'block',
  height: 'fit-content',
  borderRadius: '0.3rem',
  background: hexColors.nero,
  color: hexColors.white,
})
