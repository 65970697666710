import React from 'react'
import { TrendingBetTile } from './TrendingBetTile'
import { TrendingBetsQueryResponse } from '../types'
import { TrendingSameRaceMultiBetTile } from './TrendingSameRaceMultiBetTile'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { TrendingBetFilterEnum } from './TrendingBetsFilter'
import { TrendingBetTileErrorBoundary } from './TrendingBetTileErrorBoundary'

export type TrendingBetsType = 'Single' | 'SameRaceMulti'

export interface IconTypes {
  Races: keyof typeof iconPaths
  Trots: keyof typeof iconPaths
  Dogs: keyof typeof iconPaths
}

export const IconTypes: IconTypes = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export const TrendingBetsList: React.FC<{
  response: TrendingBetsQueryResponse
  selectedFilter: TrendingBetFilterEnum
  type: TrendingBetsType
}> = ({ response, selectedFilter, type }) => {
  if (type === 'SameRaceMulti') {
    return (
      <>
        {response.srm.results.map((bet, index) => {
          return (
            <TrendingBetTileErrorBoundary key={index + 1}>
              <TrendingSameRaceMultiBetTile
                key={index + 1}
                bet={bet}
                index={index}
                sorting={selectedFilter}
                acceptors={response.acceptors}
              />
            </TrendingBetTileErrorBoundary>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        {response.singles.results.map((bet, index) => (
          <TrendingBetTileErrorBoundary key={index + 1}>
            <TrendingBetTile
              key={index + 1}
              bet={bet}
              index={index}
              sorting={selectedFilter}
              acceptors={response.acceptors}
            />
          </TrendingBetTileErrorBoundary>
        ))}
      </>
    )
  }
}
