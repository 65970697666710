import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { font, radius, spacing, colors } from '../../Theme/Common'

type StatusBadgeColor = 'gray' | 'green' | 'yellow' | 'red' | 'blue'
type StatusBadgeSize = 'S' | 'L'

/**
 * A status badge that displays some text, and has a color to indicate status.
 */
export const StatusBadge: React.FC<{
  children: ReactNode
  color?: StatusBadgeColor
  badgeSize?: StatusBadgeSize
}> = ({ color = 'gray', badgeSize = 'S', children }) => (
  <StatusBadgeStyled badgeColor={color} badgeSize={badgeSize}>
    {children}
  </StatusBadgeStyled>
)

// Styles

const StatusBadgeStyled = styled.span<{ badgeColor: StatusBadgeColor; badgeSize: StatusBadgeSize }>(
  {
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.smx2,

    fontFamily: font.family.primary,
    fontSize: font.size.sm.fontSize,
    lineHeight: font.size.sm.lineHeight,
    letterSpacing: font.size.sm.letterSpacing,
    fontWeight: font.weight.regular,
  },
  ({ badgeColor }) => {
    switch (badgeColor) {
      case 'gray':
        return {
          color: colors.neutral['900'],
          backgroundColor: colors.neutral['200'],
        }
      case 'green':
        return {
          color: colors.success['600'],
          backgroundColor: colors.success['50'],
        }
      case 'yellow':
        return {
          color: colors.chocolate['600'],
          backgroundColor: colors.warning['50'],
        }
      case 'red':
        return {
          color: colors.error['600'],
          backgroundColor: colors.error['50'],
        }
      case 'blue':
        return {
          color: colors.mariner['600'],
          backgroundColor: colors.mariner['50'],
        }
    }
  },
  ({ badgeSize }) => ({
    borderRadius: badgeSize === 'S' ? radius.smx1 : radius.sm,
  })
)
