import StarterBuilder from './StarterBuilder'
import MissingStartersException from '../Exceptions/MissingStartersException'
import ObservableRacePage from '../Model/Observables/ObservableRacePage'
import ObservableRaceKey from '../Model/Observables/ObservableRaceKey'
import ObservableRaceKeyPoolInfo from '../Model/Observables/ObservableRaceKeyPoolInfo'
import PoolMapper from './PoolMapper'
import { IObservableStarter } from '../Model/Observables/IObservableStarter'
import * as DateTimeProvider from '../../Foundation/DateTimeProvider'
import { RacePageDataTransferObject, StarterDataTransferObject } from '@mobi/api-types'

export default class StartersMapper {
  public static map(dto: RacePageDataTransferObject, model: ObservableRacePage) {
    if (!dto || !dto.RaceStarters) throw new MissingStartersException('No starters available')

    for (let leg of dto.RaceStarters) {
      let poolInfo = new ObservableRaceKeyPoolInfo(leg.RaceKey.RaceNumber)
      poolInfo.hasPlacePool(leg.RaceKey.PoolInfo.HasPlacePool)
      poolInfo.hasWinPool(leg.RaceKey.PoolInfo.HasWinPool)
      poolInfo.hasWinPlacePool(leg.RaceKey.PoolInfo.HasWinPlacePool)
      poolInfo.hasQuinellaPool(leg.RaceKey.PoolInfo.HasQuinellaPool)

      let key = new ObservableRaceKey()
      key.raceNumber(leg.RaceKey.RaceNumber)
      key.leg(leg.RaceKey.Leg)
      key.startTime(DateTimeProvider.parseIso8601DateTime(leg.RaceKey.StartTime))
      key.tips(leg.RaceKey.Tips)
      key.tipster(leg.RaceKey.Tipster)
      key.name(leg.RaceKey.Name)
      key.pay(leg.RaceKey.Pay)
      key.distance(leg.RaceKey.Distance)
      key.poolInfo.merge(poolInfo)

      PoolMapper.mapAllUpPools(leg.RaceKey, key)

      key.isDoubleLeg(leg.RaceKey.IsDoubleLeg)
      key.isQuaddieLeg(leg.RaceKey.IsQuaddieLeg)

      let starters = []
      for (let starter of leg.Starters) {
        starters.push(StarterBuilder.from(starter.Type).build(starter))
      }
      model.addStartersForRace(key, starters)
    }
  }

  public static mapStarters(starters: StarterDataTransferObject[]): IObservableStarter[] {
    let observableStarters = []
    for (let starter of starters) {
      observableStarters.push(StarterBuilder.from(starter.Type).build(starter))
    }
    return observableStarters
  }
}
