import React from 'react'
import dayjs from 'dayjs'
import { hexColors } from '@mobi/settings'
import { SkyTvBadge } from '@mobi/component-library/Racing/SkyTvIndicator'
import { getRaceLink, getPrettyRaceStatus } from '@core/Areas/Racing/helpers'
import { RaceListEntry, RaceListResponse } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { AppearanceInheritingLink } from '@core/Components/Links'
import { colors } from '@core/Settings'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import {
  RaceListItemStyled,
  RaceTimeAndNumber,
  RaceStatusNumberStyledProps,
  MeetingStatus,
  RaceTimeStyled,
  RaceListDetails,
  RaceListRaceNameStyled,
  RaceListClassStyled,
  QuaddieChip,
  RaceExtrasIconStyled,
  RaceListResultStyled,
  RaceListResultStatusStyled,
  RaceListClassTypeStyled,
  RaceListResultDistanceStyled as ResultDistance,
} from '../RaceList.styles'

export const RaceElements: React.FC<{
  raceData: RaceListResponse
  isThoroughbredRaceInWA: boolean
}> = ({ raceData, isThoroughbredRaceInWA }) => {
  const { meetingId, meetingDate, meetingCode, races, canShowInducements, fixed } = raceData

  const hourMinuteFormat = 'HH:mm'

  return (
    <>
      {races.map((race: RaceListResponse['races'][0], index: number) => {
        // Race status can be inaccurate when SP is involved
        if (IsFixedOddsBettingOpen(race)) race.raceStatus = 'Open'

        const raceStatusTestingID = 'race-list-race-status-' + (race.raceStatus || '').toLowerCase()

        const toUrl = getRaceLink({
          meetingId,
          raceNumber: race.raceNumber,
          meetingDate,
          code: meetingCode,
          meetingSeq: fixed?.competitionSequence,
          product: race.fixed?.product,
          raceSeq: race.fixed?.eventSequence,
          isFOO: !!race.fixed,
        })

        return (
          <AppearanceInheritingLink key={index} to={toUrl}>
            <RaceListItemStyled data-testid={raceStatusTestingID}>
              <RaceTimeAndNumber>
                <RaceStatusNumberStyledProps
                  raceStatus={race.raceStatus as MeetingStatus}
                  data-tid-race-list-race-number
                >
                  {race.raceNumber}
                </RaceStatusNumberStyledProps>

                <RaceTimeStyled data-tid-race-list-race-ast>
                  {dayjs(race.advertisedStartTime).format(hourMinuteFormat)}
                </RaceTimeStyled>
              </RaceTimeAndNumber>

              <RaceListDetails>
                <RaceListRaceNameStyled data-tid-race-list-race-name>
                  {race.raceName}
                </RaceListRaceNameStyled>
                <RaceListClassStyled>
                  <RaceListClassTypeStyled>{race.raceType}</RaceListClassTypeStyled>

                  <RaceListClassTypeStyled>
                    {race.quaddie.length > 0 ? <QuaddieChip>Q{race.quaddie[0]}</QuaddieChip> : null}
                    {race.quaddie.length > 1 ? <QuaddieChip>Q{race.quaddie[1]}</QuaddieChip> : null}

                    {race.hasJackpot && (
                      <RaceExtrasIconStyled
                        type='jackpot'
                        color={colors.raceList.jackpot.text}
                        size='2.0rem'
                      />
                    )}

                    <RaceListItemFixedOddsInfo race={race} />

                    {canShowInducements && race.hasSpecials && (
                      <RaceExtrasIconStyled
                        data-testid='race-list-specials'
                        type='specials'
                        color={colors.raceList.specials.text}
                        size='2.0rem'
                      />
                    )}

                    {race.skyTvChannelId != null && (
                      <SkyTvBadge
                        channel={getVisionChannelForDisplay(
                          race.skyTvChannelId,
                          isThoroughbredRaceInWA
                        )}
                        size='M'
                      />
                    )}
                  </RaceListClassTypeStyled>
                </RaceListClassStyled>
                <RaceListResultStyled>
                  <ResultDistance data-tid-race-list-race-distance>
                    {race.distance !== 0 ? race.distance + 'm' : ''}
                  </ResultDistance>

                  <RaceListResultStatusStyled data-tid-race-list-race-status>
                    {getPrettyRaceStatus(race.raceStatus, race.shortResultString)}
                  </RaceListResultStatusStyled>
                </RaceListResultStyled>
              </RaceListDetails>
            </RaceListItemStyled>
          </AppearanceInheritingLink>
        )
      })}
    </>
  )
}

export const RaceListItemFixedOddsInfo: React.FC<{ race: RaceListResponse['races'][0] }> = ({
  race,
}) => {
  if (!race.isFixedOddsRace) return null

  const isFixedOddsBettingOpen = IsFixedOddsBettingOpen(race)

  return isFixedOddsBettingOpen ? (
    <RaceExtrasIconStyled
      type='fixed'
      color={hexColors.scooter}
      size='2.0rem'
      data-testid='race-list-fixed-opened'
    />
  ) : (
    <RaceExtrasIconStyled
      type='fixedHollow'
      color={hexColors.grey}
      size='2.0rem'
      data-testid='race-list-fixed-suspended'
    />
  )
}

const IsFixedOddsBettingOpen = (race: RaceListEntry) => {
  const isSpAvailable =
    race.isSpAvailable &&
    (race.raceStatus === 'Open' ||
      race.raceStatus === 'Suspended' ||
      race.raceStatus === 'Pending' ||
      race.isFixedOddsRaceSuspended)

  const isFixedOddsBettingOpen =
    !race.isFixedOddsRaceClosed &&
    (isSpAvailable ||
      (race.raceStatus === 'Open' &&
        !race.isFixedOddsRaceResulted &&
        !race.isFixedOddsRaceSuspended))

  return isFixedOddsBettingOpen
}
