import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { BaseRaceCodeBuilder } from './BaseRaceCodeBuilder'
import { IObservableStarter } from '../Model/Observables/IObservableStarter'
import ObservableDogStarter from '../Model/Observables/ObservableDogStarter'

export default class DogBuilder extends BaseRaceCodeBuilder {
  build(source: dtos.StarterDataTransferObject): IObservableStarter {
    let result = super.addBaseInformation(
      new ObservableDogStarter(),
      source
    ) as ObservableDogStarter

    result.template('dog-starters-template')
    result.boxNumber(source.Box)
    result.tag('Dog')
    result.stripeCount(source.StripeCount)

    return result
  }
}
