import React from 'react'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'
import { ButtonWithIcon } from '@mobi/component-library/Common/Buttons'
import {
  ContentHeaderLinkStyled,
  ContentHeaderStyled,
  ContentHeaderTitleStyled,
} from '@core/Components/ContentHeader/ContentHeader.styles'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'

export default RaceEventsHeader

interface RaceEventsHeaderProps {
  title: string
  backUrl: string
}

function RaceEventsHeader({ title, backUrl }: RaceEventsHeaderProps): JSX.Element {
  return (
    <div>
      <ContentHeaderStyled>
        <Grid>
          <GridCell width='2rem' valign='middle'>
            <ButtonWithIcon
              onClick={navigateHome}
              icon={<Icon type='house' title='Home' />}
              color='light'
            />
          </GridCell>
          <GridCell valign='middle' align='center'>
            <ContentHeaderTitleStyled>
              <ContentHeaderLinkStyled href={`#${backUrl}`} data-nav data-tid-page-title=''>
                {title}
              </ContentHeaderLinkStyled>
            </ContentHeaderTitleStyled>
          </GridCell>
          <GridCell width='2rem' valign='middle'></GridCell>
        </Grid>
      </ContentHeaderStyled>
    </div>
  )
}
