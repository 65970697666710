import { useRef, useCallback, useEffect } from 'react'

/**
 * Ensure given function can only run once until dependencies change
 *
 * @param {Function} fn The function that should run only once
 * @param {Array} deps An undefined amount of dependencies. If any of these change, the function can be called again
 *
 * @return {Function} The function passed in fn that can only be called once until any of deps change
 */
export function useOnce(fn: VoidFunction, ...deps: unknown[]): VoidFunction {
  const wasCalled = useRef(false)

  useEffect(() => {
    wasCalled.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...deps])

  return useCallback(() => {
    wasCalled.current || fn()
    wasCalled.current = true
  }, [fn])
}
