import { useDispatch } from 'react-redux'
import { useDidMount } from 'rooks'
import { setLastUsedPaymentMethod } from '../Store'
import { tryGetLegacyLastUsedPaymentMethod } from '../Utils/localStorage'

export const useMigrateLegacyLocalStorage = (accountNumber: number) => {
  const dispatch = useDispatch()
  useDidMount(() => {
    const [paymentMethod, remove] = tryGetLegacyLastUsedPaymentMethod(accountNumber)
    if (paymentMethod !== 'NoneOrUnknown') {
      dispatch(setLastUsedPaymentMethod({ accountNumber, paymentMethod }))
    }
    remove()
  })
}
