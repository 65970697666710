import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const StarterHighlightStyled = styled('div')<{ isWin: boolean }>(
  {
    height: '2rem',
    width: '2rem',
    lineHeight: '2rem',
    textAlign: 'center',
  },
  ({ isWin }) =>
    isWin && {
      backgroundColor: hexColors.citrus,
      borderRadius: '2rem',
      color: 'white',
    }
)
