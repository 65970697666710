import React from 'react'
import { MoneyGroupedStyled } from './MoneyGrouped.styles'
import { PriceChange } from './utils'

export interface MoneyGroupedProps {
  price: number
  priceChange?: PriceChange
}

export function MoneyGrouped(props: MoneyGroupedProps) {
  return <MoneyGroupedStyled {...props} amount={props.price} />
}
