import React from 'react'
import {
  SuperPickButtonTextStyled,
  SuperPickButtonSubTextStyled,
} from '@core/Components/SuperPick/SuperPick.styles'
import { observeImmutable } from '@core/Components/HOCs'
import { BetslipState, state$ as betslipState$ } from '../../driver'
import { SelectedSuperPickBadgeStyled, SelectedSuperPickTextStyled } from './SuperPick.styles'
import { RemoveButton } from './RemoveButton'

interface SelectedSuperPickProps {
  label: string
  onDeselectClick(): void
}

type SelectedSuperPickComponentProps = SelectedSuperPickProps & { isEditable: boolean }

export function SelectedSuperPickComponent({
  label,
  onDeselectClick,
  isEditable,
}: SelectedSuperPickComponentProps) {
  const [title, subtitle] = label.split('-')
  return (
    <SelectedSuperPickBadgeStyled data-tid-selected-superpick-button=''>
      <SelectedSuperPickTextStyled>
        <SuperPickButtonTextStyled>{title}</SuperPickButtonTextStyled>
        {subtitle ? <SuperPickButtonSubTextStyled>{subtitle}</SuperPickButtonSubTextStyled> : null}
      </SelectedSuperPickTextStyled>
      {isEditable && <RemoveButton onClick={() => onDeselectClick()} />}
    </SelectedSuperPickBadgeStyled>
  )
}

export const SelectedSuperPick = observeImmutable<BetslipState, SelectedSuperPickProps>(
  // @ts-expect-error Legacy - use redux toolkit
  betslipState$,
  ({ record: { isBusy, hasProposed }, label, onDeselectClick }) => (
    <SelectedSuperPickComponent
      label={label}
      onDeselectClick={onDeselectClick}
      isEditable={!isBusy && !hasProposed}
    />
  )
)
