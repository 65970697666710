import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/Icon'
import { measurements } from '@mobi/component-library/Theme/Common'
import { colors } from '@core/Settings'
import { Chip } from '@core/Components/Buttons/Chips/Chips'
import { MainContainer } from '@core/Components/Containers'

export const StructuredSearchLocationTrackModalHeaderTextStyled = styled.h1({
  textTransform: 'uppercase',
  fontSize: '1.8rem',
  color: colors.structuredSearchLocationModal.header.text,
  textAlign: 'center',
  flex: '1',
})

export const StructuredSearchTrackModalMainContainerStyled = styled(MainContainer)({
  overflowY: 'scroll', // scroll, not auto for momentum scroll to work
  WebkitOverflowScrolling: 'touch', // enables momentum scroll < IOS11
  minHeight: 0,
  flex: 1,
  background: colors.search.list.background,
  marginTop: measurements.mobi.headerHeight,
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '6rem', // Height of footer and notification(s)
  },
})

export const StructuredSearchResultComponentStyled = styled.div({
  fontSize: '1.4rem',
  backgroundColor: colors.search.result.background,
  padding: '1rem 0rem 1rem 1rem',
  borderBottom: `1px solid ${colors.search.result.border}`,
})

export const ChipWithRightIcon = styled(Chip)({
  padding: '0.3rem 0.75rem 0.3rem 1.25rem',
})

export const CheckboxInResultStyled = styled.div({
  marginTop: '-0.2rem',
})

export const CloseChipIcon = styled(Icon)({
  marginLeft: '0.75rem',
  verticalAlign: 'middle',
})

export const AddChipIcon = styled(Icon)({
  marginRight: '0.5rem',
  verticalAlign: 'middle',
})
