import ko from 'knockout'
import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { getEventAggregator } from '@core/Utils/events'
import { IRaceReplayViewModel } from '@classic/Betting-v2/Components/General/RaceReplay/IRaceReplayViewModel'
import ObservableRaceReplay from '@classic/Betting-v2/Model/Observables/ObservableRaceReplay'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'

export const RaceReplayKnockout: React.FC<{
  RaceReplay: RacesFormRaceResponse['Forms'][0]['RaceHistories'][0]['RaceReplay']
}> = ({ RaceReplay }) => {
  const koElementRef = React.useRef<HTMLDivElement>(null)
  const raceReplayDataRef = React.useRef(RaceReplay)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)

  React.useEffect(() => {
    if (!koElementRef.current || !RaceReplay.HasVideo) return undefined
    const el = koElementRef.current
    const { HasVideo, VideoKey, RaceLocation, Date } = raceReplayDataRef.current

    const raceReplayData = new ObservableRaceReplay()
    raceReplayData.hasVideo(HasVideo)
    raceReplayData.videoKey(VideoKey)
    raceReplayData.raceLocation(RaceLocation)
    raceReplayData.date(Date)

    const params: Parameters<IRaceReplayViewModel['init']>[0] = {
      id: VideoKey,
      raceReplayData,
    }

    const isKoComponentBinded = !!ko.dataFor(el)
    isKoComponentBinded && ko.cleanNode(el)

    ko.applyBindings({ model: params }, el)
    return () => {
      ko.cleanNode(el)
    }
  }, [RaceReplay.HasVideo])

  React.useEffect(() => {
    if (!RaceReplay.HasVideo) return undefined

    const key = raceReplayDataRef.current.VideoKey
    const setIsOn = () => setIsVideoPlaying(true)
    const setIsOff = () => setIsVideoPlaying(false)
    getEventAggregator().subscribe(`race-replay-started-for-${key}`, setIsOn)
    getEventAggregator().subscribe(`race-replay-stopped-for-${key}`, setIsOff)

    return () => {
      getEventAggregator().unsubscribe(`race-replay-started-for-${key}`, setIsOn)
      getEventAggregator().unsubscribe(`race-replay-stopped-for-${key}`, setIsOff)
    }
  }, [RaceReplay.HasVideo])

  return (
    <>
      {RaceReplay.HasVideo && (
        <div data-testid='form-history-race-replay'>
          <ButtonBlock
            color='secondary'
            data-testid='form-history-race-replay-button'
            onClick={() =>
              getEventAggregator().publish(
                `toggle-race-replay-for-${raceReplayDataRef.current.VideoKey}`
              )
            }
          >
            <Icon type={isVideoPlaying ? 'stop' : 'play'} size='inherit' /> Replay
          </ButtonBlock>

          <div ref={koElementRef}>
            {/* @ts-expect-error KNOCKOUT */}
            <race-replay params='id: model.id, raceReplayData: model.raceReplayData, source: "form"'></race-replay>
          </div>
        </div>
      )}
    </>
  )
}
