import React from 'react'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'
import { navigateToChangeTempPassword } from '@core/Areas/ChangePassword/ChangeTemporaryPassword'
import { attemptLogin } from '../helpers'
import { trackLoginSuccess } from '@classic/Foundation/Analytics/GoogleTagManagerService'

type PerformLoginSuccessActions = (accountNumber: number) => void

export const useNativeBioAuth = (
  performLoginSuccessActions: PerformLoginSuccessActions
): boolean => {
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (!isReactNativeApp) return undefined

    const { dispose } = subscribeToNative(
      'BIOMETRIC_AUTH_SUCCESS',
      ({ accountNumber, password }) => {
        setIsLoading(true)
        attemptLogin({
          accountNumber,
          password,
          rememberAccount: true,
          keepLoggedIn: true,
        }).then(res => {
          if (res.isSuccessful) {
            performLoginSuccessActions(accountNumber)
            trackLoginSuccess(accountNumber.toString(), true, true, true)
          } else {
            setIsLoading(false)
            if (res.mustChangePassword) {
              navigateToChangeTempPassword({ accountNumber: accountNumber.toString(), password })
            }
          }
        })
      }
    )
    sendToNative('BIOMETRIC_AUTH_REQUEST')

    return () => {
      dispose()
    }
  }, [performLoginSuccessActions])

  return isLoading
}
