import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { transparentize } from 'polished'
import { hexColors } from '@mobi/settings'

const rotate360 = keyframes({
  '0%': {
    transform: `rotate(0deg)`,
  },
  '100%': {
    transform: `rotate(360deg)`,
  },
})

export const SpinnerOverlayStyled = styled('div')({
  backgroundColor: transparentize(0.6, hexColors.black),
  height: '100%',
  left: '0',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: 202,
})

export const SpinnerHolderStyled = styled('div')({
  backgroundColor: hexColors.white,
  borderRadius: '0.6rem',
  boxSizing: 'content-box',
  height: '2.4rem',
  margin: '15rem auto 0',
  padding: '1.8rem',
  textAlign: 'center',
  width: '2.4rem',
})

type SpinnerProps = {
  height?: string
  width?: string
}

export const SpinnerStyled = styled('div')<SpinnerProps>(({ width = '1em', height = '1em' }) => ({
  bottom: '0',
  fontSize: '2.4rem',
  height,
  width,
  zIndex: 9999999,

  '&::before': {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    border: `0.25em solid ${hexColors.blueDiamond}66`, // 66 in hex = 102 in decimal = 40% opacity
    borderRadius: '50%',
    boxSizing: 'border-box',
    content: '""',
    animation: `${rotate360} 1.1s infinite linear`,
    borderLeftColor: hexColors.blueDiamond,
  },
}))

export const DesktopSpinnerOverlayStyled = styled(SpinnerOverlayStyled)({
  left: '0',
  top: '0',
  marginLeft: 0,
  marginTop: 0,
  width: '850px',
  position: 'absolute',
})

export interface SpinnerInline {
  /** Color of spinner */
  color?: 'light' | 'dark'
  /** Size in rem, interpolated as ${size}rem (default is inherit) */
  size?: number
}
export const SpinnerInlineStyled = styled('span')<SpinnerInline>(
  {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    position: 'relative',
    transform: 'translateZ(0)',
    animation: `${rotate360} 1s infinite linear`,
    margin: 0,
    borderWidth: '0.2em',
    borderStyle: 'solid',
  },
  ({ color = 'light', size }) => ({
    borderColor: color === 'light' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
    borderLeftColor: color === 'light' ? hexColors.white : hexColors.blueDiamond,
    fontSize: size ? `${size}rem` : 'inherit',
  })
)
