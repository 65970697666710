import React from 'react'
import { layering } from '@mobi/component-library/Theme/Common'
import { DepositLegacyLazy } from '@core/Areas/DepositLegacy'
import { OverlayOpen } from '@core/Components/Overlay'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { type FeatureFlagState, state$ } from '@core/State/LaunchDarklyFeatures/driver'
import { Deposit, type DepositProps } from './Deposit'
import { type DepositFlow } from '@mobi/account/Utils/types'

export const openDepositModal = (flow: DepositFlow) =>
  openDeposit({
    asModal: true,
    flow,
  })

export function openDeposit(props?: DepositProps) {
  state$.take(1).subscribe(state$ => {
    const features: FeatureFlagState['features'] = state$.asImmutable().features

    if (features.get('deposit-uplift')) {
      if (props?.asModal) {
        // `props` can no longer be undefined here as `asModal` must be `true`
        OverlayOpen({
          Component: () => <Deposit asModal={props.asModal} flow={props.flow} />,
          layer: layering.overlayHigh,
        })
      } else {
        if (window.location.hash !== '#deposit') {
          window.location.hash = '#deposit'
        }
      }
    } else {
      OverlayOpen({
        Component: DepositLegacyLazy,
        layer: layering.overlayHigh,
      })
    }
  })

  trackOptimoveEvent({
    eventName: 'set_page_visit',
    data: { customURL: '/', pageTtile: 'DEPOSIT' },
  })
}
