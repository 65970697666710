import { kambiLog } from '@core/Areas/Kambi/helpers'

export const waitForWapi = async (): Promise<Wapi> =>
  await new Promise(resolve => {
    const interval = setInterval(() => {
      if (!window.KambiWidget) return
      clearInterval(interval)

      window.KambiWidget.ready.then(wapi => {
        if (!window.wapi) window.wapi = wapi
        kambiLog('KambiWidget mounted', window.KambiWidget)
        resolve(wapi)
      })
    }, 50)
  })

export const getWapi = async (): Promise<Wapi> => {
  if (window.wapi) return window.wapi
  return waitForWapi()
}
