import React from 'react'

import {
  Button,
  ContainerStyled,
  HeaderStyled,
  ListItemStyled,
  ListStyled,
  TextStyled,
} from './TakeABreakInfo.styled'
import {
  Select,
  SelectContainer,
} from '@core/Areas/Account/ActivityStatements/Components/Select.styles'
import { trackTakeABreakConfirmation } from '@classic/Foundation/Analytics/GoogleTagManagerService'

interface TakeABreakFormProps {
  onPostAccountToTakeABreakHandler: (breakDuration: number) => void
  isPostingAccountToTakeABreakBusy: boolean
  accountNumber: number
}

type TakeABreakOptions = Readonly<{
  id: number
  name: string
  daysToBreak: number
  selectable: boolean
}>

const TakeABreakForm = ({
  isPostingAccountToTakeABreakBusy,
  onPostAccountToTakeABreakHandler,
  accountNumber,
}: TakeABreakFormProps) => {
  const [userSelectedBreakDuration, setUserSelectedBreakDuration] = React.useState(0)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    trackTakeABreakConfirmation({ accountNumber })
  }, [accountNumber])

  const TakeABreakSelections: Readonly<Array<TakeABreakOptions>> = React.useMemo(() => {
    return [
      {
        id: 0,
        name: 'Select duration',
        daysToBreak: 0,
        selectable: false,
      },
      {
        id: 1,
        name: '1 day',
        daysToBreak: 1,
        selectable: true,
      },
      {
        id: 2,
        name: '1 week (7 days)',
        daysToBreak: 7,
        selectable: true,
      },
      {
        id: 3,
        name: '1 month (30 days)',
        daysToBreak: 30,
        selectable: true,
      },
      {
        id: 4,
        name: '3 months (90 days)',
        daysToBreak: 90,
        selectable: true,
      },
      {
        id: 5,
        name: '6 months (180 days)',
        daysToBreak: 180,
        selectable: true,
      },
    ]
  }, [])

  return (
    <ContainerStyled data-testid='take-a-break-form'>
      <TextStyled>By taking a break you acknowledge that:</TextStyled>
      <ListStyled>
        <ListItemStyled>You will be unable to bet</ListItemStyled>
        <ListItemStyled>You will be unable to deposit into your account</ListItemStyled>
        <ListItemStyled>
          Pending bets will stand and your remaining account balance can be withdrawn
        </ListItemStyled>
        <ListItemStyled>
          You may continue to receive marketing material for up to 72 hours after your break has
          been confirmed
        </ListItemStyled>
      </ListStyled>
      <HeaderStyled>Take a Break Duration</HeaderStyled>

      <SelectContainer>
        <Select
          data-testid={'take-a-break-form-selection'}
          disabled={isPostingAccountToTakeABreakBusy}
          onChange={e => setUserSelectedBreakDuration(+e.target.value)}
          defaultValue={0}
        >
          {TakeABreakSelections.map(x => (
            <option
              key={x.id}
              value={x.daysToBreak}
              disabled={!x.selectable}
              hidden={!x.selectable}
            >
              {x.name}
            </option>
          ))}
        </Select>
      </SelectContainer>

      <Button
        data-testid={'take-a-break-form-confirm-button'}
        isBusy={isPostingAccountToTakeABreakBusy}
        disabled={userSelectedBreakDuration === 0}
        onClick={() => onPostAccountToTakeABreakHandler(userSelectedBreakDuration)}
      >
        Confirm
      </Button>
    </ContainerStyled>
  )
}

export default TakeABreakForm
