import { UpdateBetslipMaximized, UpdateUserData } from '@core/Areas/Kambi/driver'

interface ClientSubscriptions {
  [key: string]: (response: WapiSubscriptionResponse) => void
}

const clientSubscriptions: ClientSubscriptions = {
  BetslipMaximizedChange(response) {
    const isMaximized = response.data?.maximized
    if (isMaximized === undefined) return

    UpdateBetslipMaximized(isMaximized)
  },
  UserData(response) {
    const { sessionId } = response.data
    if (sessionId === undefined) return

    UpdateUserData(sessionId)
  },
}

export const handleClientSubscriptions = (response: WapiSubscriptionResponse): void => {
  clientSubscriptions[response.type]?.(response)
}
