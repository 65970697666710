import { creditCardExpirationPattern, parseExpirationDate } from '@mobi/utils'
import { z } from 'zod'

export const creditCardSchema = z.object({
  number: z
    .string()
    .regex(/^[\d\s]+$/, 'The given card number is not valid')
    .transform(value => value.replace(/\s/g, '')),
  expiry: z
    .string()
    .regex(creditCardExpirationPattern, 'The given expiration date is not valid or malformed')
    .transform<Date>((value, ctx) => {
      const parsedDate = parseExpirationDate(value)

      if (parsedDate === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${value} is not a valid expiration date`,
        })

        return z.NEVER
      }

      return parsedDate
    }),
  cvc: z.string().min(3).max(4).regex(/^\d+$/),
})

export type CreditCardData = z.infer<typeof creditCardSchema>
