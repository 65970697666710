import { AppRoutes } from '@core/AppRoutes'
import { KAMBI_HOME_HASH_ROUTE } from '@core/Areas/Kambi/constants'
import { getNewSportsSetting } from '@core/Areas/Settings/Store/selectors'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { store } from '@core/Store'
import { FeatureFlags } from '@mobi/settings'

export const navigateToSportsPath = () => {
  featureState$.take(1).subscribe(state => {
    const isNewSportsFeatureActive = state.features.get(
      FeatureFlags.FIXED_ODDS_SPORTS.key
    ) as boolean
    const isNewSportsFeatureSettingActive = getNewSportsSetting(store.getState())

    window.location.href =
      isNewSportsFeatureActive && isNewSportsFeatureSettingActive
        ? AppRoutes.Sports.replace('/', '#')
        : KAMBI_HOME_HASH_ROUTE
  })
}
