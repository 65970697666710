import React from 'react'
import { CreditCardDisplayStyled } from './CreditCardDisplay.styled'
import { makeAccessibleName } from './helpers'

type Props = {
  issuerName: string
  lastFour: string
}

export const CreditCardDisplay = ({ issuerName, lastFour }: Props) => {
  return (
    <CreditCardDisplayStyled aria-label={makeAccessibleName(issuerName, lastFour)}>
      <span>{issuerName}</span>
      <span>....</span>
      <span>{lastFour}</span>
    </CreditCardDisplayStyled>
  )
}
