import React from 'react'
import type { MysteryDetails, Race } from '@mobi/betslip/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import {
  MarketStyled,
  MarketPillStyled,
} from '@core/Areas/Quickbet/Components/Header/Components/Common/Header.styles'
import { getIconTypeFromMeetingCode } from '../../helpers/IconTypeHelper'
import { TitleStyled, CountdownPillStyled } from '../Common.styles'

interface MysteryInfoProps {
  race: Race
  selectionDetails: MysteryDetails
  isRaceClosed?: boolean
}

export function MysteryInfo({ race, selectionDetails, isRaceClosed = false }: MysteryInfoProps) {
  const { raceNumber, meetingName, raceTime } = race
  const iconType = getIconTypeFromMeetingCode(race.meetingCode)
  return (
    <Grid>
      <GridRow padding='0 0 0.5rem 0'>
        {iconType && (
          <GridCell width='3.5rem'>
            <Icon size='3rem' type={iconType} />
          </GridCell>
        )}
        <GridCell valign='middle'>
          <TitleStyled data-tid-quickbet-mystery-race=''>
            {meetingName}: R{raceNumber}
          </TitleStyled>
        </GridCell>
      </GridRow>
      <GridRow>
        <GridCell valign='middle'>
          <MarketStyled>
            <MarketPillStyled data-tid-quickbet-mystery-type=''>
              {selectionDetails.betTypeName}
            </MarketPillStyled>
          </MarketStyled>
        </GridCell>
        <GridCell minWidth='fit-content' flexGrow={0} valign='middle' align='right'>
          <CountdownPillStyled>
            {isRaceClosed ? (
              <span>Closed</span>
            ) : (
              <RaceCountdown displaySize='1.2rem' raceStartTime={new Date(raceTime)} />
            )}
          </CountdownPillStyled>
        </GridCell>
      </GridRow>
    </Grid>
  )
}
