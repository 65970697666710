import React from 'react'
import { SelectField } from '@mobi/component-library/Common/Select'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { FeedbackFormStyled } from './FeedbackForm.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { useFeedbackFormQuery } from './helpers/useFeedbackFormQuery'
import { handleFormSubmission } from './helpers/handleFormSubmission'
import { TextArea } from '@core/Components/Inputs'

export const FeedbackForm: React.FC<{ accountNumber: number }> = ({ accountNumber }) => {
  const [isBusy, setIsBusy] = React.useState(false)
  const [subject, setSubject] = React.useState<string>('')
  const [message, setMessage] = React.useState<string>('')

  const { hasApiErrored, shouldDisableSubmission, subjects, contactDetails } =
    useFeedbackFormQuery(accountNumber)

  if (hasApiErrored || (!shouldDisableSubmission && !subjects)) {
    return (
      <NoticeBox
        title='Feeback Form is Unavailable'
        subtitle="Sorry, we're unable to connect to the server. Please try again later."
        noticeBoxType={NoticeBoxTypes.Error}
      />
    )
  }

  const subjectValue = subject || (subjects ? subjects[0] : '')
  const errorMessage =
    message.length > 1 && message.length < 20 ? 'Message must contain at least 20 characters' : ''

  const handleSubmit = () => {
    if (!message || message.length < 20) return
    handleFormSubmission({
      message,
      subject: subjectValue,
      accountNumber,
      contactDetails,
      setMessage,
      setIsBusy,
    })
  }

  return (
    <FeedbackFormStyled data-testid='feedback-form'>
      <SelectField
        name='Subject'
        value={subjectValue}
        onChange={e => setSubject(e.target.value)}
        required
        options={subjects}
        data-testid='feedback-subjects'
      />

      <TextArea
        name='Message'
        type='text'
        errorMessage={errorMessage}
        onChange={e => setMessage(e.target.value)}
        value={message}
        required
        testId='feedback-message'
      />

      <ButtonBlock
        color='primary'
        onClick={handleSubmit}
        isBusy={isBusy || shouldDisableSubmission}
        disabled={isBusy || shouldDisableSubmission}
        data-testid='feedback-submit'
      >
        Send Message
      </ButtonBlock>
    </FeedbackFormStyled>
  )
}
