import React from 'react'
import styled from '@emotion/styled'
import { lessThan } from '@mobi/component-library/Utils/media'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { getImageUrlWithSizes } from '@core/Components/SilkImage/utils'

const SearchSilkContainer = styled('span')({
  width: '2.5rem',
  verticalAlign: 'middle',
  display: 'block',
  padding: '0 0.5rem 0 0',
  marginTop: '0.5rem',
  marginRight: '0.25rem',

  [lessThan('px350')]: {
    display: 'none',
  },
})

interface SearchSilkImageProps {
  meetingId: string
  meetingDate: string
  raceNo: number
  isDogsSilk: boolean
  isTrots: boolean
  imageSize?: string
  acceptorNumber: number
}

export function SearchSilkImage({
  meetingId,
  meetingDate,
  raceNo,
  isDogsSilk,
  isTrots,
  acceptorNumber,
}: SearchSilkImageProps): JSX.Element {
  let innerElement: JSX.Element

  const imageUrlWithSizes = getImageUrlWithSizes({ meetingId, meetingDate, isTrots, raceNo })

  const xOffset = acceptorNumber - 1

  innerElement = (
    <SilkImage
      url={imageUrlWithSizes.large.url}
      height={imageUrlWithSizes.large.size.height}
      width={imageUrlWithSizes.large.size.width}
      isDogsSilk={isDogsSilk}
      xOffset={xOffset}
    />
  )

  return <SearchSilkContainer>{innerElement}</SearchSilkContainer>
}
