import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

type InputBoxStyledProps = {
  error?: string | boolean
}

export const inputBoxBaseStyles = css({
  border: `1px solid ${hexColors.veryLightGrey}`,
  borderRadius: 0,
  appearance: 'none',
  boxSizing: 'border-box',
  fontSize: '1.4rem',
  margin: '0.5rem 0 0.6rem 0',
  padding: '1rem',
  width: '100%',
  outline: 'none',

  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0,
  },
})

export const InputBoxStyled = styled.input<InputBoxStyledProps>(
  {
    backgroundColor: hexColors.lightGreyCommon,
  },
  ({ error }) => ({
    outline: error ? '0.1rem solid red' : undefined,
  }),
  inputBoxBaseStyles
)

export const inputLabelBaseStyles = css({
  display: 'block',
  fontSize: '1.2rem',
})

export const InputLabelStyled = styled('label')(
  {
    color: hexColors.dimGrey,
  },
  inputLabelBaseStyles
)

export const ValidationErrorStyled = styled('label')({
  background: hexColors.lightRedCommonError,
  boxSizing: 'border-box',
  color: hexColors.freeSpeechRed,
  display: 'inline-block',
  lineHeight: '1.5rem',
  marginBottom: '0.5rem',
  padding: '0.5rem 1rem',
  position: 'relative',
  width: '100%',
  '&::before': {
    content: '""',
    bottom: '100%',
    border: 'solid transparent',
    position: 'absolute',
    pointerEvents: 'none',
    borderBottomColor: hexColors.lightRedCommonError,
    borderWidth: '0.5rem',
  },
})

export const InputPasswordVisibilityStyled = styled('div')({
  position: 'relative',

  'input[type="password"]': {
    paddingRight: '4rem',
  },

  button: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '4rem',
    padding: '0.5rem',
    background: 0,
    border: 0,
    color: hexColors.lola,
    cursor: 'pointer',
  },
})
