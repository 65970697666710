import Decimal from 'decimal.js'

export class FobAmount {
  public win: decimal.Decimal
  public place: decimal.Decimal

  constructor(win: decimal.Decimal, place: decimal.Decimal) {
    this.win = win ? new Decimal(win) : new Decimal(0)
    this.place = place ? new Decimal(place) : new Decimal(0)
  }
}

export interface IBetSpecialOfferElement {
  maxReward: decimal.Decimal | null
  profitMultiplier: decimal.Decimal | null
  stakeMultiplier: decimal.Decimal | null
  maxStake: decimal.Decimal | null
  priceIncrease: FobAmount | null
  isBoost: boolean
}

export class BetSpecialOfferElement implements IBetSpecialOfferElement {
  public specialProductCode: string | null = null
  public specialProductName: string | null = null
  public maxReward: decimal.Decimal | null = null
  public maxStake: decimal.Decimal | null = null
  public profitMultiplier: decimal.Decimal | null = null
  public stakeMultiplier: decimal.Decimal | null = null
  public priceIncrease!: FobAmount | null
  public rewardReturn: FobAmount | null = null
  public isBoost!: boolean

  private static _toDecimal(value: string | number | decimal.Decimal): decimal.Decimal {
    return value ? new Decimal(value) : new Decimal(0)
  }

  static normalise(offerElement: BetSpecialOfferElement): BetSpecialOfferElement {
    return {
      specialProductCode: offerElement.specialProductCode,
      specialProductName: offerElement.specialProductName,
      maxReward: BetSpecialOfferElement._toDecimal(offerElement.maxReward as decimal.Decimal),
      profitMultiplier: BetSpecialOfferElement._toDecimal(
        offerElement.profitMultiplier as decimal.Decimal
      ),
      stakeMultiplier: BetSpecialOfferElement._toDecimal(
        offerElement.stakeMultiplier as decimal.Decimal
      ),
      maxStake: BetSpecialOfferElement._toDecimal(offerElement.maxStake as decimal.Decimal),
      priceIncrease: offerElement.priceIncrease
        ? new FobAmount(offerElement.priceIncrease.win, offerElement.priceIncrease.place)
        : null,
      rewardReturn: offerElement.rewardReturn
        ? new FobAmount(offerElement.rewardReturn.win, offerElement.rewardReturn.place)
        : null,
      isBoost: offerElement.isBoost,
    }
  }
}

export interface IBetSpecialOffer {
  isExclusiveLegType: boolean | null
  legTypeCode: string | null
  elements: IBetSpecialOfferElement[] | null
}

export class BetSpecialOffer implements IBetSpecialOffer {
  public specialSeq: number | null = null
  public description: string | null = null
  public title: string | null = null
  public legTypeCode: string | null = null
  public shortDescription: string | null = null
  public specialType: string | null = null
  public isExclusiveLegType: boolean | null = null
  public isTemplatedSpecial: boolean | null = null
  public tokenId: number | null = null
  public specialTypeCode: string | null = null
  public isBoostOffer: boolean | null = null
  public isInsuranceOffer: boolean | null = null
  public nonBoostReward: number | null = null
  public elements: BetSpecialOfferElement[] | null = null
  public isSuperBoost: boolean | null = null

  static normalise(bso: BetSpecialOffer): BetSpecialOffer {
    const superBoostTitles: string[] = ['boost plus+', 'boostplus+']

    return {
      specialSeq: bso.specialSeq,
      description: bso.description,
      title: bso.title,
      legTypeCode: bso.legTypeCode,
      shortDescription: bso.shortDescription,
      specialType: bso.specialType,
      isExclusiveLegType: bso.isExclusiveLegType,
      isTemplatedSpecial: bso.isTemplatedSpecial,
      tokenId: bso.tokenId,
      specialTypeCode: bso.specialTypeCode,
      isBoostOffer: bso.isBoostOffer,
      isInsuranceOffer: bso.isInsuranceOffer,
      nonBoostReward: bso.nonBoostReward,
      elements: bso.elements
        ? bso.elements.map(offerElement => BetSpecialOfferElement.normalise(offerElement))
        : null,
      isSuperBoost: superBoostTitles.some(
        title => title.toLowerCase() === bso.title?.trim().toLowerCase()
      ),
    }
  }
}
