export function timed<TFunc extends (...args: unknown[]) => unknown>(
  operation: string,
  fn: TFunc
): ReturnType<TFunc> {
  if (PRODUCTION) {
    return fn() as ReturnType<TFunc>
  }

  console.time(operation) // eslint-disable-line no-console
  const result = fn() as ReturnType<TFunc>
  console.timeEnd(operation) // eslint-disable-line no-console

  return result
}
