import styled from '@emotion/styled'
import { newTheme as theme } from '@mobi/component-library/Theme'

export const AccountBalanceHeaderStyled = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  padding: '1.6rem',
  alignItems: 'center',
  ...theme.font.size.md,
  fontFamily: theme.font.family.primary,
  fontWeight: theme.font.weight.regular,
})

export const AccountBalanceTitleStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  '& > span': {
    padding: '0.8rem',
    background: theme.colors.surface['400'],
    borderRadius: '50%',
  },
})

export const AccountBalanceAmountStyled = styled.div({
  color: theme.colors.studio['500'],
})
