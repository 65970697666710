import React from 'react'

export const MultiReturn: React.FC<{
  price: number | null
}> = ({ price }) => {
  if (!price) return <span>N/A</span>

  return (
    <div>
      {price.toLocaleString('en-AU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
    </div>
  )
}
