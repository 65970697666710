import React from 'react'
import { OS } from '@mobi/web-native-comms/web'

export function UnbundlingStatusDot() {
  return OS === 'ios' && location.protocol === 'customscheme:' ? (
    <StatusDot color='#B3EBF2' radius={3.5} />
  ) : null
}

function StatusDot({ color, radius }: { color: string; radius: number }) {
  return (
    <svg width={radius * 2} height={radius * 2} style={{ marginBottom: '1px' }}>
      <circle cx={radius} cy={radius} r={radius} fill={color} />
    </svg>
  )
}
