import React from 'react'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ as launchDarklyState$, FeatureFlags } from '@core/State/LaunchDarklyFeatures/driver'
import type { FeatureFlagKeyName } from '@mobi/settings'

interface FeatureFlagProp {
  flag: FeatureFlagKeyName | ({} & string)
  showWhen?: string | boolean
}

export const FeatureFlag: React.ComponentClass<FeatureFlagProp> = observeImmutable(
  launchDarklyState$,
  ({ record: { features }, flag, showWhen, children }) => {
    return (
      <FeatureFlagComponent features={features.toJS()} flag={flag} showWhen={showWhen}>
        {children}
      </FeatureFlagComponent>
    )
  }
)

interface FeatureFlagComponentProp {
  flag: string
  features: FeatureFlags
  showWhen?: string | boolean
}

export const FeatureFlagComponent: React.FC<FeatureFlagComponentProp> = props => {
  const shouldRender = checkConditions(props)

  return <>{shouldRender ? props.children : null}</>
}

// Local Helpers

function checkConditions({ flag, features, showWhen = true }: FeatureFlagComponentProp): boolean {
  return flag ? features[flag] === showWhen : false
}

FeatureFlag.displayName = 'FeatureFlag'
