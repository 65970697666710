import braintree, { type Client, type BraintreeError } from 'braintree-web'

export async function getDeviceData(client: Client) {
  const dataCollector = await braintree.dataCollector.create({
    client,
    kount: true,
  })

  return dataCollector.getDeviceData()
}

export function isBraintreeError(error: unknown): error is BraintreeError {
  return ['code', 'message', 'type', 'details'].every(prop => prop in (error as BraintreeError))
}
