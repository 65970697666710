import { BettingInformation } from '../Model/BettingInformation'
import { IBetTypeValidator } from './IBetTypeValidator'
import Guard from '../../AppUtils/Framework/Guard'

export default class MultiLegValidator implements IBetTypeValidator {
  constructor(public legs: number) {}

  isValid(bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)

    let selections = bettingContext.selections

    if (!selections) return false

    return selections.allLegsHaveSelections(this.legs)
  }
}
