import React from 'react'

import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { Grid } from '@mobi/component-library/Common/Grid'

import {
  AmountLabelStyled,
  BonusBetLabelStyled,
  BonusBetListItemButtonStyled,
  ExpiryLabelStyled,
  MoneyStyled,
} from './BonusBetListItem.styles'
import dayjs from 'dayjs'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useFeature, useObservableImmutable } from '@core/Utils/hooks'
import { state$ } from '@core/State/UserAccount/userAccountDriver'

interface BonusBetListItemProps {
  id: number | string
  name: string
  expiry: Date
  amount: number
  selected?: boolean
  disabled?: boolean
  isBonusCash?: boolean
  handleClick: () => void
}

export function BonusBetListItem({
  id,
  name,
  expiry,
  amount,
  selected,
  disabled,
  isBonusCash = false,
  handleClick,
}: BonusBetListItemProps): JSX.Element {
  const {
    accountNumber = null,
    accountBalance = null,
    bonusBetBalance = null,
    bonusCashBalance = null,
  } = useObservableImmutable(state$, [
    'isLoggedIn',
    'accountBalance',
    'accountNumber',
    'bonusBetBalance',
    'bonusCashBalance',
  ])

  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')

  const trackClick = () => {
    let trackerKey = getTrackingKey(selected, isBonusCash, isBonusCashImprovementsActive)

    trackBonusBetEvent(trackerKey, {
      name,
      accountNumber: accountNumber ?? '',
      accountBalance,
      bonusBetBalance,
      bonusCashBalance,
    })
  }

  return (
    <BonusBetListItemButtonStyled
      data-testid={`bonus-bet-item-${id}`}
      selected={selected ?? false}
      disabled={disabled}
      onClick={() => {
        handleClick()
        trackClick()
      }}
      isBonusCash={isBonusCash}
    >
      <Grid margin='0' padding='0'>
        <BonusBetLabelStyled isBonusCash={isBonusCash} selected={selected}>
          {name}
        </BonusBetLabelStyled>
        <ExpiryLabelStyled isBonusCash={isBonusCash} selected={selected}>
          Expires: {dayjs(expiry).format('DD/MM/YY')}
        </ExpiryLabelStyled>
        <AmountLabelStyled isBonusCash={isBonusCash} selected={selected}>
          <MoneyStyled amount={amount} />
        </AmountLabelStyled>
      </Grid>
    </BonusBetListItemButtonStyled>
  )
}

function getTrackingKey(
  selected?: boolean,
  isBonusCash?: boolean,
  isBonusCashImprovementsActive?: boolean
) {
  if (!isBonusCashImprovementsActive)
    return selected ? keys.quickbetBonusBetUnselected : keys.quickbetBonusBetSelected

  return isBonusCash
    ? selected
      ? keys.quickbetBonusCashUnselected
      : keys.quickbetBonusCashSelected
    : selected
      ? keys.quickbetBonusBetUnselected
      : keys.quickbetBonusBetSelected
}
