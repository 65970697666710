import React from 'react'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import colors from '../../../../Settings/colors'
import {
  DueMessageContainerStyled,
  MessageStyled,
  DueMessageCountdownNumber,
  DueMessageCountdownDays,
} from './DueMessage.styles'
import { RadialCountdown } from '../../../../Components/RadialCountdown'

export function getDueMessageText({
  hasAttemptedReverification,
  numDaysRemaining,
  reverificationDue,
}: DueMessageProps) {
  if (reverificationDue && hasAttemptedReverification) {
    return 'Additional personal ID is required.'
  }

  if (reverificationDue) {
    return 'Please confirm your account details to re-activate your account.'
  }

  if (numDaysRemaining === 0) {
    return 'Reverification is due today!'
  }

  if (hasAttemptedReverification) {
    if (numDaysRemaining == null || numDaysRemaining < 0) {
      return 'Additional personal ID is required.'
    }
    return 'Re-verification required within:'
  }

  if (numDaysRemaining == null || numDaysRemaining < 0) {
    return 'Please confirm your account details below:'
  }

  return 'Please confirm your account details below within the next:'
}

interface DueMessageProps {
  hasAttemptedReverification: boolean
  numDaysRemaining: number
  reverificationDue: boolean
}

export function DueMessage({
  hasAttemptedReverification,
  numDaysRemaining,
  reverificationDue,
}: DueMessageProps) {
  const showCountdown = !reverificationDue && numDaysRemaining !== null && numDaysRemaining > 0

  return (
    <DueMessageContainerStyled>
      <Grid>
        <GridRow>
          <GridCell width='3.2rem' align='center' valign='middle'>
            <Icon type='infoCircled' size='3.2rem' color={colors.messages.warning.text} />
          </GridCell>
          <GridCell valign='middle'>
            <MessageStyled>
              {getDueMessageText({
                hasAttemptedReverification,
                numDaysRemaining,
                reverificationDue,
              })}
            </MessageStyled>
          </GridCell>
          <GridCell width={showCountdown ? '6rem' : '0'} valign='middle' align='right'>
            {showCountdown && <DueCountdown numDaysRemaining={numDaysRemaining} />}
          </GridCell>
        </GridRow>
      </Grid>
    </DueMessageContainerStyled>
  )
}

export function DueCountdown({ numDaysRemaining }: { numDaysRemaining: number }) {
  const progress = numDaysRemaining === 0 ? 100 : (100 * numDaysRemaining) / 14
  const urgency = numDaysRemaining < 3 ? 'high' : numDaysRemaining < 8 ? 'medium' : 'low'

  return (
    <RadialCountdown progress={progress} urgency={urgency}>
      <DueMessageCountdownNumber>{numDaysRemaining}</DueMessageCountdownNumber>
      <DueMessageCountdownDays>Day{numDaysRemaining === 1 ? '' : 's'}</DueMessageCountdownDays>
    </RadialCountdown>
  )
}
