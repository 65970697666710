import * as ko from 'knockout'
import template from './sameracemulti.tpl.html'
import { SameRaceMultiViewModel } from './SameRaceMultiViewModel'

export function register(): void {
  if (!ko.components.isRegistered('same-race-multi-bettype')) {
    ko.components.register('same-race-multi-bettype', {
      viewModel: SameRaceMultiViewModel,
      template,
    })
  }
}
