import styled, { CSSObject } from '@emotion/styled'
import { calculateRem } from '@core/Utils/calculateRem/calculateRem'
import { hexColors } from '@mobi/settings'
import { type BreakpointKey, greaterOrEqualThan } from '@mobi/component-library/Utils/media'

type Breakpoint = {
  breakpoint?: BreakpointKey
  baseWidth: number
}

export interface SilkImageProps {
  width: number
  height: number
  url: string
  xOffset: number
  isDogsSilk: boolean
  baseWidth?: number
  breakpoints?: Breakpoint[]
}

const BASE_WIDTH = 25

type Rem = ReturnType<typeof calculateRem>

type ImageSize = {
  imageWidth: Rem
  imageHeight: Rem
}

function calculateSize(baseWidth: number, width: number, height: number): ImageSize {
  const ratioToFitContainer = baseWidth / width
  const imageHeight = calculateRem(ratioToFitContainer * height, {
    roundSizeBeforeCalc: false,
  })
  const imageWidth = calculateRem(baseWidth, {
    roundSizeBeforeCalc: false,
  })

  return {
    imageHeight,
    imageWidth,
  }
}

function makeCSSObject(
  imageWidth: Rem,
  imageHeight: Rem,
  url: string,
  xOffset: number,
  isDogsSilk: boolean
): CSSObject {
  return {
    width: imageWidth,
    height: isDogsSilk ? imageWidth : imageHeight,
    boxSizing: 'border-box',
    background: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `auto ${imageHeight}`,
    backgroundPosition: `calc(${imageWidth} * ${-xOffset}) center`,
    border: (isDogsSilk && `1px solid ${hexColors.nero}`) || undefined,
  }
}

export const SilkImageStyled = styled('div')<SilkImageProps>(({
  xOffset,
  url,
  width,
  height,
  baseWidth = BASE_WIDTH,
  isDogsSilk,
  breakpoints,
}) => {
  if (breakpoints === undefined) {
    breakpoints = [{ baseWidth }]
  }

  return breakpoints.reduce<CSSObject>((css, { breakpoint, baseWidth }) => {
    const { imageWidth, imageHeight } = calculateSize(baseWidth, width, height)

    const cssObject = makeCSSObject(imageWidth, imageHeight, url, xOffset, isDogsSilk)

    if (breakpoint === undefined) {
      return {
        ...css,
        ...cssObject,
      }
    }

    return {
      ...css,
      [greaterOrEqualThan(breakpoint)]: cssObject,
    }
  }, {})
})
