import React from 'react'
import { useDispatch } from 'react-redux'
import { Currency } from '@mobi/utils/money'
import { setKeypadActiveValue } from '@mobi/betslip/Store/Workflow'

export const useCaptureValueOnActiveInput = ({ isActive, value }: Params) => {
  const dispatch = useDispatch()
  const hasCapturedValuedRef = React.useRef(false)

  React.useEffect(() => {
    if (!isActive || hasCapturedValuedRef.current) return
    hasCapturedValuedRef.current = true

    const stringValue = new Currency(value).toString()
    dispatch(
      setKeypadActiveValue({
        keypadInput: { value: stringValue, type: 'numeric' },
        isInitValue: true,
      })
    )
  }, [value, dispatch, isActive])

  React.useEffect(() => {
    if (!isActive) hasCapturedValuedRef.current = false
  }, [isActive])
}

// =====
// Types
// =====

type Params = {
  isActive: boolean
  value: number
}
