import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { NextRace as NextRaceData } from '@core/Data/Racing/nextRaces'
import {
  HomeNextEventRaceTextStyled,
  HomeNextEventNameEventStyled,
  HomeTimeToJump,
  HomeNextRacesListContainer,
  HomeNextSuperPicksListStyled,
} from './HomeNextSuperPicks.styles'
import { TimeToJumpComponent } from '@core/Areas/NextEvents/Components/TimeToJump/TimeToJump'
import { buildToteRaceUri } from '@core/Areas/Racing/helpers'

interface IconTypes {
  Races: keyof typeof iconPaths
  Trots: keyof typeof iconPaths
  Dogs: keyof typeof iconPaths
}

const IconTypes: IconTypes = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function HomeNextSuperPicksTile({
  race,
}: {
  race: NextRaceData
  keyValue: string
}): JSX.Element {
  const href = buildToteRaceUri({
    meetingId: race.MeetingID,
    raceNumber: race.RaceNumber,
    meetingDate: race.MeetingDate,
  })

  return (
    <HomeNextSuperPicksListStyled
      data-tid-home-next-events='home-next-events-item'
      href={href}
      data-is-boost={true}
    >
      <HomeNextRacesListContainer>
        <HomeNextEventNameEventStyled>{race.MeetingName}</HomeNextEventNameEventStyled>
        <span>R{race.RaceNumber}</span>
        <HomeTimeToJump>
          <HomeNextEventRaceTextStyled>
            <Icon size='2rem' type={IconTypes[race.RaceType as keyof IconTypes]} />
            <TimeToJumpComponent
              size='1.2rem'
              advertisedStartTime={new Date(race.AdvertisedStartTime)}
              showCountDown={false}
              showDay={false}
            />
          </HomeNextEventRaceTextStyled>
        </HomeTimeToJump>
      </HomeNextRacesListContainer>
    </HomeNextSuperPicksListStyled>
  )
}
