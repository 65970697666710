import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { BaseRaceCodeBuilder } from './BaseRaceCodeBuilder'
import { IObservableStarter } from '../Model/Observables/IObservableStarter'
import ObservableRaceStarter from '../Model/Observables/ObservableRaceStarter'

export default class RaceBuilder extends BaseRaceCodeBuilder {
  build(dto: dtos.StarterDataTransferObject): IObservableStarter {
    const model = super.addBaseInformation(
      new ObservableRaceStarter(),
      dto
    ) as ObservableRaceStarter
    model.template('race-starters-template')
    model.barrier(dto.Barrier)
    model.jockeyWeight(dto.JockeyWeight)
    model.tag('Race')

    return model
  }
}
