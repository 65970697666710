import { PushEvent, ToteRaceStatusChangedPushEvent, FobMarketSettlementPushEvent } from '.'

type EventWithRaceId = Pick<ToteRaceStatusChangedPushEvent, 'raceId' | keyof PushEvent>

type EventWithMarketId = Pick<FobMarketSettlementPushEvent, 'marketId' | keyof PushEvent>

export function hasRaceId(payload: PushEvent): payload is EventWithRaceId {
  const key: keyof EventWithRaceId = 'raceId'
  return payload && key in payload
}

export function hasMarketId(payload: PushEvent): payload is EventWithMarketId {
  const key: keyof EventWithMarketId = 'marketId'
  return payload && key in payload
}
