import React from 'react'
import ReactDOM from 'react-dom'
import * as QueryString from 'query-string'
import * as TabTouch from '@classic/Foundation/TabTouch'
import { SubApplicationHandler, RouteResult } from '../Navigation/SubAppRouter'
import { DepositLimits } from '@core/Areas/DepositLimits'
import { AccountHolders, AccountHoldersKey } from '@core/Data/Account/accountHolder'
import { showLogin, getIsLoggedOn } from '@core/Areas/Login/helpers'
import { State } from 'rwwa-data-access'

export class DepositLimitsHandler implements SubApplicationHandler {
  async enter(url: string): Promise<RouteResult> {
    // Ensure not leak if routed to twice in a row
    this.leave()

    const isLoggedIn = await getIsLoggedOn()
    if (isLoggedIn) {
      // Sets the back btn url and changes from i to < -- do this BEFORE setting ID below
      TabTouch.initCommon('#')
      document.body.id = 'deposit-limits-page' // For styling - ID is reset when you navigate away
      document.body.scrollIntoView()

      let saveSuccess: boolean = false
      let accountHolderNumber: number | undefined

      const queryValues = QueryString.parse(QueryString.extract(url))
      if (queryValues && queryValues.accountHolderNumber) {
        saveSuccess = queryValues.saveSuccess === 'true'
        accountHolderNumber = /^\d+$/.test(queryValues.accountHolderNumber as string)
          ? Number(queryValues.accountHolderNumber)
          : undefined
      }

      AccountHolders.hardInvalidate(null as unknown as State<AccountHolders>, AccountHoldersKey)
      ReactDOM.render(
        <DepositLimits
          saveSuccess={saveSuccess}
          selectedAccountHolderNumber={accountHolderNumber}
        />,
        document.getElementById('main-container')
      )
    } else {
      showLogin({
        onLoginSuccessRedirectUrl: window.location.hash,
        onLoginCancelledRedirectUrl: '/#',
      })
    }

    return RouteResult.IsMatchSuccess
  }

  leave(): void {
    // Clear body id as nav away does not guarantee it is cleared
    document.body.id = ''
    const container = document.getElementById('main-container')

    if (container) {
      ReactDOM.unmountComponentAtNode(container)
    }
  }
}
