import React from 'react'
import { v1 as uuid } from 'uuid'
import { ThemedToggleStyled, ToggleTypes } from './Toggle.styles'

interface ToggleProps {
  toggledOn: boolean
  toggleOnCharacter?: string
  toggleOffCharacter?: string
  title?: string
  isDisabled: boolean
  onToggle: () => void
  type?: keyof ToggleTypes
}

export function Toggle({
  toggledOn,
  onToggle,
  toggleOnCharacter,
  toggleOffCharacter,
  isDisabled,
  type = 'default',
  ...ToggleProps
}: ToggleProps) {
  const clipId = uuid()
  const cx = toggledOn ? '35' : '15'

  return (
    <ThemedToggleStyled
      {...ToggleProps}
      isDisabled={isDisabled}
      toggledOn={toggledOn}
      viewBox='0 -2 50 34'
      onClick={isDisabled ? undefined : onToggle}
      type={type}
    >
      <rect
        x='0'
        y='0'
        width='50'
        height='30'
        rx='15'
        ry='15'
        stroke={type}
        strokeWidth='1'
        strokeLinecap='butt'
        className='track'
      />
      <text x='15' y='20' textAnchor='middle'>
        {toggleOffCharacter}
      </text>
      <text x='35' y='20' textAnchor='middle'>
        {toggleOnCharacter}
      </text>
      <defs>
        <clipPath id={clipId}>
          <circle cx={cx} cy='15' r='12' />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipId})`}>
        <rect x='0' y='0' width='100' height='30' className='disc' />
        <text className='selected' x='15' y='20' textAnchor='middle'>
          {toggleOffCharacter}
        </text>
        <text className='selected' x='35' y='20' textAnchor='middle'>
          {toggleOnCharacter}
        </text>
      </g>
    </ThemedToggleStyled>
  )
}
