import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings/src/hexColors'
import { Money } from '@core/Components/Text/Money'

export const BalanceInfoBarContainerStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  height: '3rem',
  paddingBottom: '1rem',
  fontSize: '1.4rem',
})

export const BalanceInfoBarStyled = styled.span({
  color: hexColors.white,
})

export const BonusBalanceMoneyStyled = styled
  .div({
    textAlign: 'left',
    padding: '0 0.5rem',
    color: hexColors.orange,
    fontSize: '0.8em',
    margin: 'unset',
  })
  .withComponent(Money)
