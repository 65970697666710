import { RaceCardIcon } from '@core/Components/Icons'
import { Icon } from '@mobi/component-library/Common/Icon'
import React, { useEffect } from 'react'
import { CompactExpandablePanel } from '@core/Components/ExpandablePanel'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { RacePromotionTextStyled, ExternalLinkStyled } from './RacePromotion.styles'
import {
  trackRacePromotionClosed,
  trackRacePromotionLoaded,
  trackRacePromotionOpened,
  trackRacePromotionTermsAndConditionsClicked,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useOnce } from '@core/Utils/hooks/useOnce'
import type { Promotion } from '@core/Areas/Racing/Hooks/RacePromotion/types'

const RacePromotion = ({ text, title, termsAndConditionsUrl }: Promotion): JSX.Element | null => {
  const onLoaded = useOnce(trackRacePromotionLoaded, text, title, termsAndConditionsUrl)
  const onOpened = useOnce(trackRacePromotionOpened, text, title, termsAndConditionsUrl)
  const onClosed = useOnce(trackRacePromotionClosed, text, title, termsAndConditionsUrl)
  const onLinkClicked = useOnce(
    trackRacePromotionTermsAndConditionsClicked,
    text,
    title,
    termsAndConditionsUrl
  )

  function onExpansionChanged(expanded: boolean): void {
    const trackingFunction = expanded ? onOpened : onClosed
    trackingFunction()
  }

  useEffect(() => {
    onLoaded()
  }, [onLoaded])

  return (
    <CompactExpandablePanel
      headerIcon={<RaceCardIcon icon='specialsNew' />}
      headerText={title}
      themeName={ExpandablePanelThemes.yellow}
      onExpansionChanged={onExpansionChanged}
    >
      <RacePromotionTextStyled data-testid='race-promotion-text'>
        {text}
        <br />
        {termsAndConditionsUrl ? (
          <ExternalLinkStyled
            href={termsAndConditionsUrl}
            target='_blank'
            rel='external noreferrer'
            onClick={onLinkClicked}
            data-testid='race-promotion-tc-link'
          >
            Terms and Conditions <Icon type='shareWeb' size='1em' />
          </ExternalLinkStyled>
        ) : null}
      </RacePromotionTextStyled>
    </CompactExpandablePanel>
  )
}

export default RacePromotion
