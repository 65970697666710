import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { Icon } from '@mobi/component-library/Common/Icon'

export const DateNavigationContainerStyled = styled('div')({
  marginBottom: '1.25rem',
  padding: '0',
})

export const DatePickerStyled = styled('div')<{ isOpen?: boolean }>(
  {
    boxSizing: 'border-box',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    padding: '0.8rem 1rem',
    margin: '0 1rem',
    textAlign: 'center',
    position: 'relative',
    transition: 'background-color 0.25s ease-in-out',
    backgroundPosition: 'right 1rem top 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1rem',
  },
  ({ isOpen }) => ({
    backgroundColor: isOpen
      ? colors.meetingList.dateNavigation.activeBackground
      : colors.meetingList.dateNavigation.background,
    color: isOpen
      ? colors.meetingList.dateNavigation.activeText
      : colors.meetingList.dateNavigation.text,
    border: isOpen
      ? `1px solid ${colors.meetingList.dateNavigation.activeBorder}`
      : `1px solid ${colors.meetingList.dateNavigation.border}`,
  })
)

export const DatePickerIcon = styled(Icon)<{ isOpen?: boolean }>(
  {
    marginRight: '0.75rem',
  },
  ({ isOpen }) => ({
    color: isOpen
      ? colors.meetingList.dateNavigation.activeText
      : colors.meetingList.dateNavigation.text,
  })
)

export const CalendarDatePickerHolderStyled = styled('div')({
  backgroundColor: colors.meetingList.calendar.baseBackground,
  position: 'absolute',
  left: '0',
  right: '0',
  maxWidth: '42rem',
  margin: '0 auto',
  zIndex: 2,

  animationName: 'fadeIn',
  animationDuration: '0.2s',
  animationTimingFunction: 'ease-in-out',
  visibility: 'visible',
  boxShadow: `0 1.2rem 2.1rem rgba(0,0,0,0.2)`,
})
