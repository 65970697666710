import mitt from 'mitt'
import type { ToastItem } from '@mobi/component-library/Feedback/Toast/types'

export const globalToastEmitter = mitt<{
  addToast: ToastItem
  removeToast: Pick<ToastItem, 'id'>
}>()

export const addToast = (toast: ToastItem) => globalToastEmitter.emit('addToast', toast)
export const removeToast = (id: Pick<ToastItem, 'id'>) => globalToastEmitter.emit('removeToast', id)

/** Used to create new context for toasts, allowing render relative to parent */
export const createToastsEventEmitter = () =>
  mitt<{
    addToast: ToastItem
    removeToast: Pick<ToastItem, 'id'>
  }>()
