import * as ko from 'knockout'
import { FirstFourTopPanelViewModel } from './FirstFourTopPanelViewModel'
import template from './first4.top.panel.tpl.html'

if (!ko.components.isRegistered('first4-top-panel')) {
  ko.components.register('first4-top-panel', {
    viewModel: FirstFourTopPanelViewModel,
    template,
  })
}
