export function toTitleCase(s: string): string {
  // Replace multiple whitespaces with single, and split
  const split = s.replace(/\s+/g, ' ').split(' ')
  const upper = split
    .map(s => {
      if (!s) return s
      if (/^\(.+\)$/.test(s)) return s
      return s[0].toUpperCase() + s.slice(1).toLowerCase()
    })
    .join(' ')
  return upper.trim()
}

export function capitalize(s: string) {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`
}
