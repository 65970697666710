import React, { type PropsWithChildren } from 'react'
import { GridStyled } from './Grid.styled'

export type Props = PropsWithChildren<{
  gap?: string
  cols?: number
  rows?: number
}>

/**
 * A grid with evenly spaced rows and columns
 */
export const Grid = ({ children, ...props }: Props) => {
  return <GridStyled {...props}>{children}</GridStyled>
}
