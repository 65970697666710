import { Ticket } from '@core/Areas/AccountActivity/types'
import { isFobRacingWithIncorrectStarterNumbers } from './isFobRacingWithIncorrectStarterNumbers'

export function shouldUseFallbackIcon(leg: Ticket['legs'][0], planSeq: Ticket['planSeq']): boolean {
  const acceptorNumber = leg.selections[0].selectionDetails[0].acceptorDetails[0]?.number

  return (
    isFobRacingWithIncorrectStarterNumbers(planSeq, leg.betType) ||
    // Is missing data for silk retrieval
    leg.fixtureId === null ||
    leg.fixtureDate === null ||
    !Number.isInteger(acceptorNumber) ||
    !(leg.selections[0].raceNumber || leg.raceNumber)
  )
}
