import { ObservableFinisher } from './ObservableFinisher'
import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableFinisher } from './IObservableFinisher'

export default class ObservableDogFinisher extends ObservableFinisher {
  constructor() {
    super()

    // @ts-expect-error Type issue
    this.boxNumber = ko.observable()
    // @ts-expect-error Type issue
    this.stripeCount = ko.observable()
  }

  public merge(other: ObservableDogFinisher) {
    Guard.notNull(other)
    super.merge(other)

    this.boxNumber(other.boxNumber())
    this.stripeCount(other.stripeCount())
  }

  public untypedMerge(other: IObservableFinisher) {
    this.merge(other as ObservableDogFinisher)
  }

  public boxNumber: ko.Observable<number>
  public stripeCount: ko.Observable<number>
}
