import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'

export interface MysteryToggleButtonProps {
  isSelected: boolean
  disabled?: boolean
}

export const MysteryToggleButtonStyled = styled(BaseButtonStyled)<MysteryToggleButtonProps>(
  {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    height: '4.5rem',
    boxShadow: '0 0 1px rgba(0,0,0,0.1)',
    borderRadius: '0.3rem',
  },

  ({ isSelected, disabled }) => ({
    background: disabled
      ? hexColors.gainsboro
      : isSelected
        ? hexColors.black
        : `${hexColors.white} linear-gradient(to bottom,
          ${hexColors.white} 0%,
          ${hexColors.whiteSmoke} 100%)`,
    color: disabled ? hexColors.nightRider : isSelected ? hexColors.white : hexColors.black,
    border:
      isSelected && !disabled ? `1px solid transparent` : `1px solid ${hexColors.veryLightGrey}`,
  })
)

export const MysteryContainerStyled = styled.div({
  marginBottom: '0.5rem',
  boxShadow: `0 0.1rem 0.1rem rgba(0, 0, 0, 0.1)`,
  backgroundColor: hexColors.white,
})

export const MysteryQuickPicksStyled = styled.div(
  ({ optionsPerRow = 3 }: { optionsPerRow?: number }) => ({
    padding: optionsPerRow === 3 ? '0.8rem' : 0,
    display: 'grid',
    gridTemplateColumns: `repeat(${optionsPerRow}, 1fr)`,
    gap: '0.5rem',
    margin: '0 0 1em 0',
  })
)
