import { ISelectionProcessor } from './ISelectionProcessor'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import * as buttons from '../UIElements/ButtonsSelection'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import { ISelectionContext } from './ISelectionContext'
import { BettingInformation } from '../../../Model/BettingInformation'
import Guard from '../../../../AppUtils/Framework/Guard'
import WinPlaceProcessorFactory from './WinPlaceProcessorFactory'
import ko, { Observable } from 'knockout'
import LegSelectionContext from './LegSelectionContext'

export default class WinPlaceSelectionProcessor implements ISelectionProcessor {
  selectionsErrorMessage: Observable<string> = ko.observable('')

  public tag(): string {
    return 'WinPlaceSelectionProcessor'
  }

  public generateSelections(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    context: ISelectionContext
  ): Array<IObservableStarter> {
    const processor = new WinPlaceProcessorFactory(
      // context may be null in certain scenarios, e.g. ObservableRaceStarters.mergeStarterForRace() removing a scratched starter
      (context as LegSelectionContext)?.buttonSelectionType,
      bettingContext.isEnhancedBetslip()
    ).processor()
    if (processor) {
      return processor.process(starter, starters, bettingContext, context)
    }
    return []
  }

  public processFieldSelection(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    index: number,
    selected: boolean
  ) {
    Guard.notNull(bettingContext)
    Guard.greaterThanOrEqualZero(index)

    bettingContext.toggleFieldAt(index)
    bettingContext.isFixed(false)

    selected
      ? this.selectAllStarters(starters, selections, selected)
      : this.clearAllStarterSelections(starters, selections)
  }

  public processSameAsSelection() {
    //
  }

  private selectAllStarters(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    selected: boolean
  ) {
    selections([])

    const newSelections = starters()
      .filter(
        // @ts-expect-error Type or code issue
        (starter: IObservableStarter) => starter.selection && !starter.isScratchedToteAndFob()
      )
      .reduce<IObservableStarter[]>((starters, starter) => {
        const selection = starter.selection() as buttons.ButtonsSelection

        if (selected) {
          selection.value(ButtonSelectionType.Tote)
        } else {
          selection.value(ButtonSelectionType.None)
        }

        return starters.concat(starter)
      }, [])

    selections(newSelections)
  }

  private clearAllStarterSelections(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>
  ) {
    selections([])

    starters()
      .filter(
        // @ts-expect-error Type or code issue
        (starter: IObservableStarter) => starter.selection && !starter.isScratchedToteAndFob()
      )
      .forEach(starter => {
        const selection = starter.selection() as buttons.ButtonsSelection
        selection.value(ButtonSelectionType.None)
      })
  }

  public clearAllSelections(
    racesWithStarters: ko.ObservableArray<IObservableStarter>[],
    racesWithSelections: ko.ObservableArray<IObservableStarter>[]
  ) {
    racesWithSelections.forEach(raceSelections => raceSelections([]))

    for (const raceStarters of racesWithStarters) {
      raceStarters()
        .filter(
          // @ts-expect-error Type or code issue
          (starter: IObservableStarter) => starter.selection && !starter.isScratchedToteAndFob()
        )
        .forEach(starter => {
          const selection = starter.selection() as buttons.ButtonsSelection
          if (selection) {
            selection.value(ButtonSelectionType.None)
          }
        })
    }
  }

  getStarterSelections(): boolean[][] {
    return []
  }
}
