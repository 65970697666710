import React from 'react'
import { Flex } from '@mobi/component-library/Common/Flex'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const SkeletonLoader = () => {
  return (
    <div style={{ padding: '1rem', overflow: 'hidden' }}>
      <Flex flexDirection='column' justifyContent='space-between' alignItems='center'>
        <div style={{ width: '100%' }}>
          <Flex justifyContent='space-between' alignItems='center'>
            {/* Meeting Dropdown */}
            <LoadingPlaceholder width='10rem' height='3rem' borderRadius={radius.lgx3} />
            {/* Weather */}
            <LoadingPlaceholder width='6rem' height='2rem' borderRadius={radius.lgx5} />
          </Flex>
        </div>
        {/* Race Seletor */}
        <div style={{ width: '100%', padding: '1.5rem' }}>
          <Flex justifyContent='flex-start' alignItems='center' gap='1.5rem'>
            {Array.apply(0, new Array(5)).map((_, i: number) => (
              <LoadingPlaceholder
                key={i}
                width='3.5rem'
                height='2.5rem'
                borderRadius={radius.lgx3}
              />
            ))}
          </Flex>
        </div>
        <div style={{ width: '100%', padding: '0.5rem' }}>
          <Flex justifyContent='space-between' alignItems='center' gap='1.5rem'>
            {/* Race Info */}
            <Flex flexDirection='column' justifyContent='space-between' gap='0.5rem'>
              <LoadingPlaceholder width='15rem' height='1.5rem' borderRadius={radius.lgx5} />
              <LoadingPlaceholder width='20rem' height='1.5rem' borderRadius={radius.lgx5} />
            </Flex>

            {/* Sky & Time */}
            <Flex flexDirection='row' justifyContent='space-between' gap='0.5rem'>
              <LoadingPlaceholder width='6.5rem' height='3.5rem' borderRadius={radius.lg} />
              <LoadingPlaceholder width='6.5rem' height='3.5rem' borderRadius={radius.lg} />
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
