import { queryClient } from '@core/Data/ReactQuery'
import { serverConfigQuery } from '@core/Data/ServerConfig/query'
import * as gtm from './GoogleTagManagerService'
import * as msgBusService from '../Services/MessageBusService'
import * as loggingService from '../Services/LoggingService'
import * as dataLayer from './AnalyticsDataLayer'

let started = false

// TODO: This type is incomplete. It *seems* to be related to Bet.cs in the backend
type Bet = {
  ModelType: string
  ExpectedCost: number
  ProductCode: string
}

function cleanseNumber(number: string | number): number {
  if (typeof number === 'string') {
    number = number.replace('$', '')
  }
  return parseInt(number.toString())
}
function cleanseCurrency(amount: string | number): number {
  if (typeof amount === 'string') {
    amount = amount.replace('$', '')
  }
  return parseFloat(amount.toString())
}

function start() {
  if (!started) {
    dataLayer.loadTrackData()

    msgBusService.subscribe('nav.nav', (url: string) => {
      if (url) {
        // ti-1234 - filtering to reduce adobe licence consumption
        if (!/#vision\/home/.test(url)) {
          dataLayer.data.content.url = url
          dataLayer.saveTrackData()
          dataLayer.addToHistory('navigation', url)
          gtm.trackNav()
        }
      }
    })

    msgBusService.subscribe('nav.menu', function (direction) {
      dataLayer.addToHistory('menu', direction)
      if (direction === 'open') {
        gtm.trackMenu()
      }
    })

    msgBusService.subscribe('nav.exit', function (externalSite) {
      dataLayer.addToHistory('site exit', externalSite)
      gtm.trackExit(externalSite)
    })

    msgBusService.subscribe('account.processed', function (operationType) {
      dataLayer.data.user.accountOperationType = operationType
      dataLayer.saveTrackData()
      dataLayer.addToHistory('account', operationType)
      gtm.trackAccount()
    })

    msgBusService.subscribe('account.deposit', function (data) {
      //if (data.amount > 0) {
      dataLayer.data.deposit.amount = data.amount
      dataLayer.saveTrackData()
      //}
      dataLayer.addToHistory('deposit', JSON.stringify(dataLayer.keys.depositBaseKey + data.step))
      gtm.trackDeposit(data.step)
    })

    msgBusService.subscribe('lbs.checkin', function (checkInStep) {
      dataLayer.addToHistory('checkin', JSON.stringify(dataLayer.keys.checkInBaseKey + checkInStep))
      gtm.trackCheckIn(checkInStep)
    })

    msgBusService.subscribe('bet.single.selected', function (data) {
      dataLayer.resetBetData()
      dataLayer.data.bet.productType = 'single'
      if (data.type == 'toteracing') {
        dataLayer.data.bet.racing.tote.numberOfTickets = 1
      } else if (data.type == 'fobracing') {
        dataLayer.data.bet.racing.fixed.numberOfTickets = 1
      }

      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.single.selected', JSON.stringify(data))
      gtm.trackBetSingleSelected()
    })

    msgBusService.subscribe('bet.single.review', async function () {
      dataLayer.addToHistory('bet.single.review', JSON.stringify({ mode: 'single' }))
      dataLayer.data.bet.construction = 'single'
      gtm.trackBetSingleReview()
    })

    msgBusService.subscribe('bet.single.receipt', async function (data) {
      dataLayer.resetBetData()

      dataLayer.data.bet.id = cleanseNumber(data.ticket)
      dataLayer.data.bet.productType = 'single'
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'account'

      switch (data.betType) {
        case 'toteracing': {
          dataLayer.data.bet.racing.tote.numberOfTickets = 1
          dataLayer.data.bet.racing.tote.cost = cleanseCurrency(data.toteBetValue)
          break
        }
        case 'fobracing': {
          const winValue = data.fobWinValue ? cleanseCurrency(data.fobWinValue) : 0
          const placeValue = data.fobPlaceValue ? cleanseCurrency(data.fobPlaceValue) : 0
          dataLayer.data.bet.racing.fixed.numberOfTickets = 1
          dataLayer.data.bet.racing.fixed.cost =
            cleanseCurrency(winValue) + cleanseCurrency(placeValue)
          if (data.specialOffer) {
            dataLayer.data.bet.specialOffers = data.specialOffer.split(',')
          }
          break
        }
      }

      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.single.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackBetSingleReceipt()
    })

    msgBusService.subscribe('bet.betslip.review', function (bets: Bet[]) {
      dataLayer.resetBetData()
      let numToteRacingBets = 0
      let costOfToteRacingBets = 0
      let numFixedRacingBets = 0
      let costOfFixedRacingBets = 0

      for (const bet of bets) {
        const isToteBet = ['ToteRacing', 'ToteAllUpBet'].includes(bet.ModelType)

        if (isToteBet) {
          numToteRacingBets++
          costOfToteRacingBets += bet.ExpectedCost
        } else {
          numFixedRacingBets++
          costOfFixedRacingBets += bet.ExpectedCost
        }
      }

      dataLayer.data.bet.racing.tote.numberOfTickets = numToteRacingBets
      dataLayer.data.bet.racing.tote.cost = costOfToteRacingBets
      dataLayer.data.bet.racing.fixed.numberOfTickets = numFixedRacingBets
      dataLayer.data.bet.racing.fixed.cost = costOfFixedRacingBets

      dataLayer.data.bet.productType = 'single'
      dataLayer.data.bet.construction = 'betslip'
      dataLayer.data.bet.placementMethod = 'account'
      dataLayer.saveTrackData()

      dataLayer.addToHistory('bet.betslip.review', JSON.stringify({ productType: 'single' }))
      gtm.trackBetSlipReview()
    })

    msgBusService.subscribe('bet.betslip.receipt', function (receipt) {
      dataLayer.data.bet.id = cleanseNumber(receipt[0].TicketNumber)
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.betslip.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackBetSlipReceipt()
    })

    msgBusService.subscribe('bet.betslip.cash.selected', function (productType) {
      dataLayer.data.bet.productType = productType
      dataLayer.data.bet.construction = 'betslip'
      dataLayer.data.bet.placementMethod = 'cash'
      dataLayer.saveTrackData()

      dataLayer.addToHistory(
        'bet.betslip.cash.selected',
        JSON.stringify({ productType: productType })
      )
      gtm.trackBetSlipCashSelected()
    })

    msgBusService.subscribe('bet.betslip.remove', function () {
      dataLayer.addToHistory('bet.betslip.remove', '')
      gtm.trackBetSlipClear()
    })

    msgBusService.subscribe('bet.betslip.clear', function () {
      dataLayer.resetBetData()
      dataLayer.addToHistory('bet.betslip.clear', '')
      gtm.trackBetSlipClear()
    })

    msgBusService.subscribe('bet.mystery.selected', function () {
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.mystery.selected', JSON.stringify({ betType: 'toteracing' }))
      gtm.trackMysterySelected()
    })

    msgBusService.subscribe('bet.mystery.review', function () {
      dataLayer.data.bet.productType = 'single'
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'account'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.mystery.review', JSON.stringify({ productType: 'single' }))
      gtm.trackMysteryReview()
    })

    msgBusService.subscribe('bet.mystery.receipt', function (receipt) {
      dataLayer.resetBetData()
      dataLayer.data.bet.id = cleanseNumber(receipt.tickets[0].ticketNumber)
      dataLayer.data.bet.racing.tote.numberOfTickets = receipt.tickets.length
      dataLayer.data.bet.racing.tote.cost = cleanseCurrency(receipt.totalCost)
      dataLayer.data.bet.betType = 'Mystery'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.mystery.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackMysteryReceipt()
    })

    msgBusService.subscribe('bet.favnumbers.selected', function () {
      dataLayer.addToHistory('bet.favnumbers.selected', JSON.stringify({ betType: 'toteracing' }))
      gtm.trackFavNumbersSelected()
    })

    msgBusService.subscribe('bet.favnumbers.review', function () {
      dataLayer.data.bet.productType = 'single'
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'account'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.favnumbers.review', JSON.stringify({ productType: 'single' }))
      gtm.trackFavNumbersReview()
    })

    msgBusService.subscribe('bet.favnumbers.receipt', function (receipt) {
      dataLayer.resetBetData()
      dataLayer.data.bet.id = cleanseNumber(receipt.tickets[0].ticketNumber)
      dataLayer.data.bet.racing.tote.numberOfTickets = receipt.tickets.length
      dataLayer.data.bet.racing.tote.cost = cleanseCurrency(receipt.totalCost)
      dataLayer.data.bet.betType = 'Favourite Numbers'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.favnumbers.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackFavNumbersReceipt()
    })

    msgBusService.subscribe('bet.tipping.selected', function () {
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.tipping.selected', JSON.stringify({ betType: 'totesports' }))
      gtm.trackTippingSelected()
    })

    msgBusService.subscribe('bet.tipping.review', function () {
      dataLayer.data.bet.productType = 'single'
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'account'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.tipping.review', JSON.stringify({ productType: 'single' }))
      gtm.trackTippingReview()
    })

    msgBusService.subscribe('bet.tipping.receipt', function (receipt) {
      dataLayer.resetBetData()
      dataLayer.data.bet.id = cleanseNumber(receipt.tickets[0].ticketNumber)
      dataLayer.data.bet.sports.tote.numberOfTickets = receipt.tickets.length
      dataLayer.data.bet.sports.tote.cost = cleanseCurrency(receipt.totalCost)
      dataLayer.data.bet.betType = 'Tipping'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.tipping.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackTippingReceipt()
    })

    msgBusService.subscribe('bet.multi.add', function () {
      dataLayer.addToHistory('bet.multi.add', JSON.stringify({ betType: 'mixed' }))
      gtm.trackMultiAdd()
    })

    msgBusService.subscribe('bet.multi.review', function () {
      dataLayer.data.bet.productType = 'multi'
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'account'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.multi.review', JSON.stringify({ productType: 'multi' }))
      gtm.trackMultiReview()
    })

    msgBusService.subscribe('bet.multi.receipt', function (receipt) {
      dataLayer.resetBetData()
      dataLayer.data.bet.id = cleanseNumber(receipt.ticketNumber)
      dataLayer.data.bet.mixed.fixed.numberOfTickets = 1
      dataLayer.data.bet.mixed.fixed.cost = cleanseCurrency(receipt.totalCost)
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.multi.receipt', JSON.stringify(dataLayer.data.bet))
      gtm.trackMultiReceipt()
    })

    msgBusService.subscribe('bet.multi.remove', function () {
      dataLayer.addToHistory('bet.multi.remove', '')
      gtm.trackMultiRemove()
    })

    msgBusService.subscribe('bet.multi.clear', function () {
      dataLayer.resetBetData()
      dataLayer.addToHistory('bet.multi.clear', '')
      gtm.trackMultiClear()
    })

    msgBusService.subscribe('bet.cash.selected', function (productType) {
      dataLayer.data.bet.productType = productType
      dataLayer.data.bet.construction = 'single'
      dataLayer.data.bet.placementMethod = 'cash'
      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.cash.selected', JSON.stringify({ productType: productType }))
    })

    msgBusService.subscribe('bet.cash.receipt', function (receipt) {
      dataLayer.data.bet.id = cleanseNumber(receipt.ticket)

      if (dataLayer.data.bet.productType === 'multi') {
        dataLayer.resetBetData()
        dataLayer.data.bet.id = cleanseNumber(receipt.ticket)
        dataLayer.data.bet.mixed.fixed.numberOfTickets = 1
        dataLayer.data.bet.mixed.fixed.cost = cleanseCurrency(receipt.totalCost)
      }

      dataLayer.saveTrackData()
      dataLayer.addToHistory('bet.cash.receipt', JSON.stringify(receipt))
    })

    msgBusService.subscribe('bet.cash.remove', function (data) {
      dataLayer.data.bet.id = cleanseNumber(data.ticket)
      dataLayer.addToHistory('bet.cash.remove', JSON.stringify(data))
    })

    msgBusService.subscribe('bet.cash.clear', function () {
      dataLayer.resetBetData()
      dataLayer.addToHistory('bet.cash.clear', '')
    })

    msgBusService.subscribe('specialoffer.selected', function (data) {
      dataLayer.addToHistory('specialoffer.selected', JSON.stringify(data))
      dataLayer.data.specialOfferSelection = data.Title
      dataLayer.saveTrackData()
    })

    msgBusService.subscribe('specialoffer.cleared', function () {
      dataLayer.addToHistory('specialoffer.cleared', '')
      dataLayer.data.specialOfferSelection = null
      dataLayer.saveTrackData()
    })

    msgBusService.publish('analytics.started', null)
    loggingService.logDebug('AnalyticsService - started')
    started = true
  } else {
    loggingService.logDebug('AnalyticsService - already started')
  }
}

function stop() {
  if (started) {
    msgBusService.unsubscribe('nav.nav', gtm.trackNav)
    msgBusService.unsubscribe('nav.exit', gtm.trackExit)
    msgBusService.unsubscribe('nav.menu', gtm.trackMenu)

    loggingService.logDebug('AnalyticsService - stopped')
    started = false
  } else {
    loggingService.logDebug('AnalyticsService - already stopped')
  }
}

export async function init() {
  const data = await queryClient.fetchQuery(serverConfigQuery)
  data.enableAnalytics ? start() : stop()
}
