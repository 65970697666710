import * as React from 'react'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import type { SelectedBetType } from '../types'

type UseColumnSort = {
  sortOptions: SortOption[]
  sortSelected: SortOption
  sort: (newOption: SortOption) => void
}

export const useColumnSort = (
  selectedBetType: SelectedBetType,
  raceNumber: number,
  currentSortForRace: SortOption
): UseColumnSort => {
  const [sortSelected, setSortSelected] = React.useState<SortOption>(
    currentSortForRace ?? {
      property: 'Number',
      direction: 'Ascending',
    }
  )

  const eventAggregator = useEventAggregator()

  const sortOptions = React.useMemo(() => {
    return selectedBetType.sortOptions.map(sortProperty => ({
      property: sortProperty,
      direction: sortSelected?.property === sortProperty ? sortSelected.direction : 'Ascending',
    }))
  }, [selectedBetType.sortOptions, sortSelected.direction, sortSelected?.property])

  const sort = React.useCallback(
    (newOption: SortOption) => {
      if (sortSelected?.property === newOption.property) {
        newOption.direction = newOption.direction === 'Ascending' ? 'Descending' : 'Ascending'
      } else {
        sortOptions?.forEach(sortOption => {
          sortOption.direction = 'Ascending'
        })
      }
      setSortSelected(newOption)
      eventAggregator.publish('sort-starters', { raceNumber: raceNumber, option: newOption })
    },
    [eventAggregator, raceNumber, sortOptions, sortSelected?.property]
  )

  return { sortOptions, sortSelected, sort }
}
