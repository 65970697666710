import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import {
  ExpandablePanelStyled,
  ExpandablePanelHeaderStyled,
  ExpandablePanelTitleStyled,
  IconStyled,
  HiddenContentStyled,
  FormulaHeader,
} from './ExpandablePanel.styles'
import { ExpandablePanelContent } from './ExpandablePanelContent'
import { ExpandablePanelThemes, getExpandablePanelTheme } from './themes'

export interface ExpandablePanelProps {
  title: string
  themeName?: ExpandablePanelThemes
  leftIcon?: React.ReactChild | null
  rightIcon?: React.ReactChild | null
  expanded?: boolean
  headerClicked?: (() => void) | React.MouseEventHandler<HTMLElement>
  children?: React.ReactNode
  scrollIntoViewWhenOpened?: boolean
  deferRenderingContentOnExpand?: boolean
  hideHeaderTap?: boolean
  dataTidValue?: string
  headerComponent?: React.ReactElement
  useSmartScroll?: boolean
}

export function ExpandablePanel({
  title,
  leftIcon,
  rightIcon,
  expanded,
  headerClicked,
  themeName,
  children,
  scrollIntoViewWhenOpened = false,
  deferRenderingContentOnExpand = false,
  hideHeaderTap = false,
  dataTidValue = '',
  headerComponent: HeaderProp,
  useSmartScroll = false,
}: ExpandablePanelProps): JSX.Element {
  const theme = getExpandablePanelTheme(themeName)

  return (
    <ThemeProvider theme={theme}>
      <ExpandablePanelStyled data-tid-expandable={dataTidValue}>
        <ExpandablePanelHeaderStyled
          onClick={headerClicked}
          hideHeaderTap={hideHeaderTap}
          customHeader={!!HeaderProp}
        >
          {HeaderProp || (
            <Grid>
              <GridCell valign='middle'>
                {leftIcon && leftIcon}
                <ExpandablePanelTitleStyled>
                  {title || 'Please enter a title'}
                </ExpandablePanelTitleStyled>
              </GridCell>
              <GridCell width='4rem' valign='middle'>
                {rightIcon}
              </GridCell>
              <GridCell width='1.5rem' valign='middle'>
                <IconStyled type={expanded ? 'arrowup' : 'arrowdown'} size='1.2rem' />
              </GridCell>
            </Grid>
          )}
        </ExpandablePanelHeaderStyled>
        <ExpandablePanelContent
          expanded={!!expanded}
          scrollIntoViewWhenOpened={scrollIntoViewWhenOpened}
          useSmartScroll={useSmartScroll}
        >
          {deferRenderingContentOnExpand ? expanded ? children : <HiddenContentStyled /> : children}
        </ExpandablePanelContent>
      </ExpandablePanelStyled>
    </ThemeProvider>
  )
}

export function FormulaExpandablePanelHeaderComponent() {
  const theme = getExpandablePanelTheme(ExpandablePanelThemes.whiteNested)

  return (
    <ThemeProvider theme={theme}>
      <Grid>
        <GridCell valign='middle'>
          <FormulaHeader>Formula</FormulaHeader>
        </GridCell>
      </Grid>
    </ThemeProvider>
  )
}
