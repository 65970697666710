import { List } from 'immutable'
import { v4 as uuidv4 } from 'uuid'
import type {
  ToteSelection,
  AllUpSelection,
  BetLegType,
  BetSlipBetRequest,
} from '@mobi/betslip/types'
import {
  isAllUpSelection,
  isFobMatchedSelection,
  isFobPropositionSelection,
  isFobSelection,
  isFobSportsSelection,
  isStartingPriceSelection,
  isStartingPriceMatchedSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { getToteProductPlanSequence } from '@mobi/betslip/helpers/betting'
import { BetslipItem, MultiInvestment, defaultBetslipState, MultiBetError } from '../driver'
import { determineLegTypeFromInvestments } from '@core/Areas/Quickbet/helpers/calculator'
import {
  calculateBoosts,
  calculateBetCostSingleItem,
  calclulateCombinedMultiInvestment,
} from './calculator'
import { isValidMulti } from './state'

const createRacingDetails = (
  fixtureDate: string,
  fixtureId: string,
  raceNumber: number,
  starterNumber?: number
): BetSlipBetRequest['fixedOddsBets'][0]['legs'][0]['raceDetails'] => {
  return {
    fixtureDate,
    fixtureId,
    raceNumber,
    starterNumber,
  }
}

function buildMultiFixedOddsBet(
  spend: MultiInvestment,
  multis: List<BetslipItem>,
  position: number
): BetSlipBetRequest['fixedOddsBets'][0] {
  const legs = multis.map(item => buildFixedOddsBetLeg(item, true)).toArray()

  let campaign = { id: -1, rewardType: 'None' }

  if (spend.bonusBetId) {
    campaign.id = spend.bonusBetId
    campaign.rewardType = 'BonusBetReward'
  } else if (!spend.isBonusCash) {
    campaign.id = 0
    campaign.rewardType = 'BonusCashReward'
  }

  return {
    id: uuidv4(),
    investmentWin: spend.value || undefined,
    formulaInvestment: buildValidFormulas(spend, legs.length),
    legs,
    specials: [],
    position,
    tags: {},
    selectedCampaign: campaign.id != -1 ? campaign : undefined,
  }
}

// we need the number of valid legs to exclude invalid investments that might still be populated in state,
// for example, if "trebles" had an investment, but the multi was changed to be a 2-leg multi, then we won't send trebles
function buildValidFormulas(
  multiInvestment: MultiInvestment,
  numberOfValidLegs: number
): BetSlipBetRequest['fixedOddsBets'][0]['formulaInvestment'] {
  const formulaInvestment: BetSlipBetRequest['fixedOddsBets'][0]['formulaInvestment'] = {}
  if (numberOfValidLegs >= 2 && multiInvestment.f1) {
    formulaInvestment.singles = multiInvestment.f1
  }
  if (numberOfValidLegs >= 3 && multiInvestment.f2) {
    formulaInvestment.doubles = multiInvestment.f2
  }
  if (numberOfValidLegs >= 4 && multiInvestment.f3) {
    formulaInvestment.trebles = multiInvestment.f3
  }
  if (numberOfValidLegs >= 5 && multiInvestment.f4) {
    formulaInvestment.pick4 = multiInvestment.f4
  }
  if (numberOfValidLegs >= 6 && multiInvestment.f5) {
    formulaInvestment.pick5 = multiInvestment.f5
  }
  return formulaInvestment
}

function buildFixedOddsBet(
  item: BetslipItem,
  position: number,
  isRefreshing: boolean = false
): BetSlipBetRequest['fixedOddsBets'][0] {
  const [winBoost, placeBoost] = calculateBoosts(item.selectedSuperPickOffer || null)
  return {
    id: item.id || '',
    investmentWin: item.investment.win.value || undefined,
    investmentPlace: item.investment.place.value || undefined,
    legs: [buildFixedOddsBetLeg(item, false)],
    specials:
      item.selectedSuperPickOffer && item.selectedSuperPickOffer.specialSeq && !isRefreshing
        ? [
            {
              specialSeq: item.selectedSuperPickOffer.specialSeq,
              boostPrice: winBoost || placeBoost,
            },
          ]
        : [],
    position,
    tags: {},
    selectedCampaign: item.campaign
      ? { id: item.campaign.id, rewardType: item.campaign.rewardType }
      : undefined,
  }
}

function buildFixedOddsBetLeg(
  item: BetslipItem,
  isMultiBetLeg: boolean
): BetSlipBetRequest['fixedOddsBets'][0]['legs'][0] {
  let handicap = 0
  let priceWin
  let pricePlace
  let sportDetails
  let raceDetails

  const isStartingPrice = isStartingPriceSelection(item.selection)

  if (isFobPropositionSelection(item.selection)) {
    handicap = item.selection.handicap || 0
    sportDetails = {
      propositionSeq: item.selection.propositionSeq,
      variantSeq: item.selection.variantSeq,
    }
  }

  if (isFobMatchedSelection(item.selection) || isStartingPriceMatchedSelection(item.selection)) {
    raceDetails = createRacingDetails(
      item.selection.fixtureDate,
      item.selection.fixtureId,
      item.selection.raceNumber,
      item.selection.acceptorNumber
    )
  }

  let legType: BetLegType
  if (isStartingPrice) {
    legType = 'SP'
  } else if (isMultiBetLeg) {
    legType = item.multiLegBetType || 'W'
  } else if (item.isEachWay) {
    legType = 'EW'
  } else {
    legType = determineLegTypeFromInvestments(
      item.investment.win.value,
      item.investment.place.value
    )
  }

  if (isFobSelection(item.selection)) {
    priceWin = item.selection.winPrice || undefined
    pricePlace = item.selection.placePrice || undefined
  } else if (isStartingPrice) {
    priceWin = null
    pricePlace = null
  }

  return {
    id: item.id || '',
    legType,
    handicap,
    priceWin,
    pricePlace,
    sportDetails,
    raceDetails,
  }
}

function buildToteBet(
  item: BetslipItem,
  position: number,
  isRefreshing: boolean = false
): BetSlipBetRequest['toteBets'][0] {
  const selection = item.selection as ToteSelection
  const hasWinInvestment = !!item.investment.win.value && item.investment.win.value > 0
  const hasPlaceInvestment = !!item.investment.place.value && item.investment.place.value > 0
  const productPlanSeq = getToteProductPlanSequence(
    selection.betType,
    isRefreshing,
    selection.isRovingBanker,
    hasWinInvestment,
    hasPlaceInvestment
  )
  return {
    betTransactionId: item.id || '',
    productPlanSeq,
    fixtureId: selection.fixtureId,
    fixtureDate: selection.fixtureDate,
    raceNumber: selection.raceNumber,
    selections: selection.selectionString,
    investmentWin: item.investment.win.value === 0 ? undefined : item.investment.win.value,
    investmentPlace: item.investment.place.value === 0 ? undefined : item.investment.place.value,
    position,
    tags: {},
  }
}

function buildAllUpBet(item: BetslipItem, position: number): BetSlipBetRequest['allUpBets'][0] {
  const selection = item.selection as AllUpSelection
  const productPlanSeq = getToteProductPlanSequence(selection.betType, false)
  const raceDetails: AllUpSelection['details'] = []
  selection.details.forEach(selectionDetail => {
    if (selectionDetail.poolType === 'EW') {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'W',
        betSelections: selectionDetail.betSelections,
      })
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'P',
        betSelections: selectionDetail.betSelections,
      })
    } else {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: selectionDetail.poolType,
        betSelections: selectionDetail.betSelections,
      })
    }
  })
  return {
    betTransactionId: item.id || '',
    productPlanSeq,
    fixtureId: selection.fixtureId,
    fixtureDate: selection.fixtureDate,
    raceDetails,
    formulas: selection.formulas.filter(formula => formula.isSelected),
    investment: item.investment.win.value,
    position,
    tags: {},
  }
}

type RequestType = 'propose' | 'confirm' | 'refresh'

export function buildBetslipRequest(
  type: RequestType,
  items: List<BetslipItem>,
  multis?: List<BetslipItem>,
  multiInvestment: MultiInvestment = defaultBetslipState.multiInvestment,
  multiError: MultiBetError | null = null
): BetSlipBetRequest {
  const fobItems = new Array()
  const toteItems = new Array()
  const allUpItems = new Array()
  const isRefreshing = type === 'refresh'

  items.forEach((item, index) => {
    if (!item) {
      return
    }

    if (!isToteSelection(item.selection)) {
      if (isFobSportsSelection(item.selection)) {
        return
      }

      const request = buildFixedOddsBet(item, index, isRefreshing)
      if (!isRefreshing && request && request.tags) {
        request.tags.isMultiMate = !!item.tags && item.tags.some(tag => tag === 'multimate')
        request.tags.estimatedCost = calculateBetCostSingleItem(item)
        if (!!item.tags && item.tags.some(tag => tag.includes('featuredEvent'))) {
          request.tags.tags = `[${item.tags.map(t => t).join(', ')}]`
        }
      }
      fobItems.push(request)
    }

    if (isToteSelection(item.selection) && !isAllUpSelection(item.selection)) {
      const request = buildToteBet(item, index, isRefreshing)
      if (!isRefreshing && request && request.tags) {
        request.tags.estimatedCost = calculateBetCostSingleItem(item)
      }
      toteItems.push(request)
    }

    if (isAllUpSelection(item.selection)) {
      allUpItems.push(buildAllUpBet(item, index))
    }
  })

  if (
    multis &&
    multis.count() > 0 &&
    (isValidMulti(multiInvestment, multiError, multis) || isRefreshing)
  ) {
    const request = buildMultiFixedOddsBet(multiInvestment, multis, items.count())
    if (!isRefreshing && request && request.tags) {
      request.tags.isMulti = true
      request.tags.isMultiMate = multis.some(
        item => !!item.tags && item.tags.some(tag => tag === 'multimate')
      )
      request.tags.estimatedCost = calclulateCombinedMultiInvestment(multis, multiInvestment)
    }
    fobItems.push(request)
  }

  return {
    fixedOddsBets: fobItems,
    toteBets: toteItems,
    allUpBets: allUpItems,
    favouriteNumbersBets: [],
    toteSportsBets: [],
  }
}
