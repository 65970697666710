import { useQuery, UseQueryResult } from 'react-query'
import { RacePreview } from '@core/Areas/Racing/Hooks/RaceContent/types'
import { getRacePreviews, RaceContent } from './api'
import { queryKeys } from '@core/Data/ReactQuery/constants'

type UseRaceContent = Pick<UseQueryResult, 'isLoading' | 'isError'> & {
  content?: {
    title: string
    racePreview: RacePreview
    tipsterProfilePictureUrl: MaybeDefined<string>
  }
}

export function useRaceContent(
  meetingId: string,
  meetingDate: Date,
  raceNumber: number
): UseRaceContent {
  const queryKey = [queryKeys.meetingPreview, meetingDate, meetingId]
  const queryFn = () => getRacePreviews(meetingDate, meetingId)
  const { isLoading, isError, data } = useQuery<RaceContent, Error>(queryKey, queryFn, {
    staleTime: 1000 * 60 * 60, // 1hr
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false, // prevent retry of failed responses
  })
  const racePreview = data?.racePreviews.get(raceNumber)

  return {
    isLoading,
    isError,
    content: unpackData(data, racePreview),
  }
}

function unpackData(
  raceContentData: MaybeDefined<RaceContent>,
  racePreview: MaybeDefined<RacePreview>
): MaybeDefined<UseRaceContent['content']> {
  if (raceContentData === undefined || racePreview === undefined) {
    return undefined
  }

  return {
    title: raceContentData.title,
    racePreview,
    tipsterProfilePictureUrl: raceContentData.tipsterProfilePictureUrl,
  }
}
