import { state$ as kambiState$ } from '@core/Areas/Kambi/driver'
import { KambiPlaceBetResponse, KambiPlaceSingleBetRequest } from './types'
import { BetResponseCode } from '@core/Data/betting'

export function placeKambiBetSingle(
  request: KambiPlaceSingleBetRequest
): Promise<KambiPlaceBetResponse> {
  return getKambiAuthToken()
    .then(playerSessionId => {
      return fetch(
        `${window._kc.apiPlayerBaseUrl}coupon.json?lang=en_AU&market=AU&client_id=2&channel_id=3`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${playerSessionId}`,
          },
          body: JSON.stringify(request),
        }
      )
    })
    .then(response => response.json() as Promise<KambiPlaceBetResponse>)
    .catch(() => {
      throw {
        code: BetResponseCode.NetworkError,
        response: {
          message: 'Network Error',
        },
      }
    })
}

function getKambiAuthToken() {
  return new Promise<string | undefined>(resolve =>
    kambiState$
      .take(1)
      .toPromise()
      .then(kambiState => kambiState.get('playerSessionId'))
      .then(playerSessionId => resolve(playerSessionId))
  )
}
