import styled, { type StyledComponent } from '@emotion/styled'
import { Link, type LinkProps } from 'react-router-dom'

export const AppearanceInheritingLink: StyledComponent<LinkProps> = styled(Link)(
  {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  ({ className }) => {
    let cursor = className === 'disabled' ? 'default' : undefined
    return {
      cursor,
    }
  }
)
