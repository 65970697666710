import * as ko from 'knockout'
import TachoViewModel from './TachoViewModel'
import template from './tacho.tpl.html'

if (!ko.components.isRegistered('tacho-meter')) {
  ko.components.register('tacho-meter', {
    viewModel: {
      createViewModel: (params: { rating: ko.Observable<number> }) => new TachoViewModel(params),
    },
    template,
  })
}
