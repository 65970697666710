import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { RulesThemeSettings } from './InteractiveRules'
import { Icon } from '@mobi/component-library/Common/Icon'

interface SingleRuleStyledProps {
  isHighlighted?: boolean
}

interface Theme {
  theme?: RulesThemeSettings
}

export const InteractiveRulesListStyled = styled('ul')<Theme>(
  {
    paddingLeft: '0rem',
    listStyle: 'none',
    color: hexColors.dimGrey,
  },
  ({ theme }) => ({
    color: theme.text.color,
    backgroundColor: theme.background,
    margin: theme.margin,
  })
)

export const SingleRuleStyled = styled('li')<SingleRuleStyledProps & Theme>(
  {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  ({ theme }) => ({
    fontSize: theme.text.size,
  }),
  ({ isHighlighted }) => ({
    color: isHighlighted ? hexColors.sushi : undefined,
    fontWeight: isHighlighted ? 'bold' : undefined,
  })
)

export const TickedIconStyled = styled(Icon)<SingleRuleStyledProps & Theme>(
  {
    marginRight: '0.5rem',
  },
  ({ theme }) => ({
    fontSize: theme.tickIcon ? theme.tickIcon.size : '1.5rem',
  }),
  ({ isHighlighted }) => ({
    color: isHighlighted ? hexColors.sushi : hexColors.veryLightGrey,
  })
)
