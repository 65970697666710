import container from '../../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { registerComponentWithParams } from '@classic/AppUtils/Framework/Components/koComponentRegistration'
import { ProvDivsViewModel } from './ProvDivsViewModel'
import template from './prov-divs.tpl.html'

export function register(): void {
  registerComponentWithParams<ProvDivsViewModel>({
    componentName: 'prov-divs',
    template,
    container,
    resolver: (container: interfaces.Container) =>
      container.get<ProvDivsViewModel>('ProvDivsViewModel'),
  })
}
