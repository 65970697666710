import React from 'react'
import { InitialDisplay } from './SearchResultsContainers/InitialDisplay'
import { SearchLoading } from './SearchResultsContainers/SearchLoading'
import { SearchErrored } from './SearchResultsContainers/SearchErrored'
import { SearchResults } from './SearchResultsContainers/SearchResults'
import { SearchResult } from '@core/Data/Search/Search'

export function SearchResultsContainer({
  isLoading,
  isErrored,
  searchResults,
  searchPerformed,
}: SearchResultsContainerProps): JSX.Element {
  if (isLoading) {
    return <SearchLoading />
  }

  if (isErrored) {
    return <SearchErrored />
  }

  if (!searchPerformed) {
    return <InitialDisplay />
  }

  return <SearchResults results={searchResults} />
}

export interface SearchResultsContainerProps {
  isLoading: boolean
  isErrored: boolean
  searchResults: SearchResult[]
  searchPerformed: boolean
}
