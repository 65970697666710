import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type QuickDepositState = {
  lastDepositError?: string
  depositStatus: 'idle' | 'preparing' | 'depositing'
}

const initialState: QuickDepositState = {
  lastDepositError: undefined,
  depositStatus: 'idle',
}

const quickDepositSlice = createSlice({
  name: 'quick-deposit',
  initialState,
  reducers: {
    closeQuickDeposit() {
      return initialState
    },
    clearQuickDepositError(state) {
      state.lastDepositError = undefined
    },
    onQuickDepositError(state, action: PayloadAction<string>) {
      state.lastDepositError = action.payload
    },
    setQuickDepositStatus(
      state,
      action: PayloadAction<{ depositStatus: QuickDepositState['depositStatus'] }>
    ) {
      state.depositStatus = action.payload.depositStatus
    },
  },
})

export const {
  closeQuickDeposit,
  onQuickDepositError,
  clearQuickDepositError,
  setQuickDepositStatus,
} = quickDepositSlice.actions

export default quickDepositSlice.reducer
