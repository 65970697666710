import RaceFinisherBuilder from './RaceFinisherBuilder'
import DogFinisherBuilder from './DogFinisherBuilder'
import TrotFinisherBuilder from './TrotFinisherBuilder'
import { IFinisherCodeBuilder as FinisherCodeBuilder } from './IFinisherCodeBuilder'

export default class FinisherBuilder {
  static from(starterType: string): FinisherCodeBuilder {
    switch (starterType) {
      case 'Races':
        return new RaceFinisherBuilder()
      case 'Dogs':
        return new DogFinisherBuilder()
      case 'Trots':
        return new TrotFinisherBuilder()
      default:
        throw new Error('invalid source.Type')
    }
  }
}
