import styled from '@emotion/styled'
import { ButtonStyled } from '@mobi/component-library/Common/Buttons'
import { measurements } from '@mobi/component-library/Theme/Common'
import { colors } from '@mobi/settings'

export const KambiCenteredContainerStyled = styled.div({
  position: 'absolute',
  zIndex: 0,
  width: '100%',
  minHeight: `calc(100vh - ${measurements.mobi.headerHeight})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.kambi.container.background,
  fontSize: '4rem',
})

export const KambiUnsupportedBrowserTextStyled = styled.p({
  fontSize: '1.6rem',
})

export const LinkButton = styled(ButtonStyled)({
  textDecoration: 'none',
}).withComponent('a')
