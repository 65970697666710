import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Button } from '@mobi/component-library/Common/Buttons'

interface KeypadButtonProps {
  disabled?: boolean
}

export const KeypadButtonStyled = styled(Button)<KeypadButtonProps>(
  {
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 700,
    margin: 0,
    minHeight: '4.5rem',
    width: '100%',
  },

  (props: KeypadButtonProps) => ({
    color: props.disabled ? hexColors.darkGrey : hexColors.nero,
  })
)
