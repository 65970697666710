import React from 'react'
import { useSelector } from 'react-redux'
import type { Iterable } from 'immutable'
import { isFobMatchedSelection } from '@mobi/betslip/helpers/typeGuards'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { type BetslipItem, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { hasNotBeenPlaced } from '@core/Areas/Betslip/helpers/state'
import type { SmartBetslipState } from '@core/Areas/Blackbook/types'

/** Note: This is currently strictly for matched fixed odds */
export const useSmartBetslip = () => {
  const isSmartBetslipActive = useSelector(getEnhancedBetslipSetting)

  const [state, setState] = React.useState<SmartBetslipState>(() => {
    let newState: SmartBetslipState
    betslipState$
      .map(state => state.items.filter(hasNotBeenPlaced))
      .take(1)
      .subscribe(items => {
        newState = deriveReactStateFromBetslip(items)
      })
    // @ts-expect-error Rx is not a good state management solution for React
    return newState
  })

  React.useEffect(() => {
    if (!isSmartBetslipActive) {
      setState({ shouldAutoAddToBetslip: false })
      return
    }

    const subscription = betslipState$
      .map(state => state.items.filter(hasNotBeenPlaced))
      .skip(1)
      .subscribe(items => {
        setState(deriveReactStateFromBetslip(items))
      })
    return () => subscription.dispose()
  }, [isSmartBetslipActive])

  return state
}

// =============
// Local Helpers
// =============

function deriveReactStateFromBetslip(items: Iterable<number, BetslipItem>): SmartBetslipState {
  if (!items.count()) {
    return { shouldAutoAddToBetslip: false }
  }

  const propSeqs = items
    .filter(hasNotBeenPlaced)
    .flatMap(item =>
      item && isFobMatchedSelection(item.selection) ? [+item.selection.propositionSeq] : []
    )
    .toArray() as number[]

  return {
    shouldAutoAddToBetslip: true,
    betslipRunnerPropSeqs: propSeqs,
  }
}
