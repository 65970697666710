import { state$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableProperties } from './useObservable'

interface UseLogonResult {
  isLoggedIn: boolean | null
  accountNumber: number | null
}

export function useLogon(): UseLogonResult {
  return useObservableProperties(state$, ['isLoggedIn', 'accountNumber'], {
    isLoggedIn: null,
    accountNumber: null,
  })
}
