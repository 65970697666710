import React from 'react'
import {
  SearchResult,
  isSearchResultAcceptor,
  isSearchResultRace,
  isSearchResultSport,
  SearchResultSport,
  SearchResultAcceptor,
  SearchResultRace,
  MetaDataHighlight,
  isSearchResultFobEvent,
  isSearchResultFobProposition,
  SearchResultFobEvent,
  SearchResultFobProposition,
} from '@core/Data/Search/Search'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { LocationTime } from './LocationTime'
import {
  DriverStyled,
  SearchResultSecondaryInfoStyled,
  SearchResultPrimaryInfoStyled,
  RaceDetailsStyled,
  RaceDistanceStyled,
  RaceClassStyled,
  SportsStyled,
  SearchResultPricesStyled,
} from './SearchResults.styles'
import { HighlightDisplay } from './HighlightDisplay'
import { SearchSilkImage } from './SearchSilkImage'
import { RacingCode } from '@core/Data/StructuredSearch/StructuredSearchResult'
import { RaceProduct } from '@core/Areas/RaceCardFixedOnly'

interface SearchResultComponentProps {
  searchResult: SearchResult
  currentDateMilli: number
  highlights: MetaDataHighlight
}

export function SearchResultComponent({
  searchResult,
  currentDateMilli,
  highlights,
}: SearchResultComponentProps): JSX.Element | null {
  if (isSearchResultAcceptor(searchResult)) {
    return (
      <SearchResultAcceptorComponent
        searchResult={searchResult}
        currentDateMilli={currentDateMilli}
        highlights={highlights}
      />
    )
  }

  if (isSearchResultRace(searchResult)) {
    return (
      <SearchResultRaceComponent
        searchResult={searchResult}
        currentDateMilli={currentDateMilli}
        highlights={highlights}
      />
    )
  }

  if (isSearchResultSport(searchResult)) {
    return (
      <SearchResultSportComponent
        searchResult={searchResult}
        currentDateMilli={currentDateMilli}
        highlights={highlights}
      />
    )
  }

  if (isSearchResultFobEvent(searchResult)) {
    return (
      <SearchResultFobEventComponent
        searchResult={searchResult}
        currentDateMilli={currentDateMilli}
        highlights={highlights}
      />
    )
  }

  if (isSearchResultFobProposition(searchResult)) {
    return (
      <SearchResultFobPropositionComponent
        searchResult={searchResult}
        currentDateMilli={currentDateMilli}
        highlights={highlights}
      />
    )
  }

  return null
}

export function SearchResultAcceptorComponent({
  searchResult,
  currentDateMilli,
  highlights,
}: SearchResultComponentProps): JSX.Element {
  const { acceptor } = searchResult as SearchResultAcceptor
  let driver = null

  switch (acceptor.race.fixture.contestType) {
    case RacingCode.Races:
      if (acceptor.riderName && acceptor.riderName !== '') {
        driver = (
          <DriverStyled>
            <>J: </>
            <HighlightDisplay
              displayText={acceptor.riderName}
              highlightKey='acceptor.riderName'
              highlights={searchResult.metadata.highlights}
            />
          </DriverStyled>
        )
      }
      break
    case RacingCode.Trots:
      if (acceptor.driverName && acceptor.driverName !== '') {
        driver = (
          <DriverStyled>
            <>D: </>
            <HighlightDisplay
              displayText={acceptor.driverName}
              highlightKey='acceptor.driverName'
              highlights={searchResult.metadata.highlights}
            />
          </DriverStyled>
        )
      }
      break
    default:
      driver = null
  }

  let trainer = null

  if (acceptor.trainerName && acceptor.trainerName !== '') {
    trainer = (
      <DriverStyled>
        <>T: </>
        <HighlightDisplay
          displayText={acceptor.trainerName}
          highlightKey='acceptor.trainerName'
          highlights={searchResult.metadata.highlights}
        />
      </DriverStyled>
    )
  }

  let spacer = null

  if (driver && trainer) {
    spacer = <> </>
  }

  const advertisedStartTime = acceptor.race.isStartTimeRelevant
    ? acceptor.race.advertisedStartTime
    : undefined

  return (
    <div data-testid='SearchResultAcceptorComponent'>
      <Grid>
        <GridRow>
          <GridCell>
            <LocationTime
              isSport={false}
              locationName={acceptor.race.fixture.name}
              raceNumber={acceptor.race.number}
              advertisedStartTime={advertisedStartTime}
              currentDateMilli={currentDateMilli}
              code={acceptor.race.fixture.contestType}
              status={acceptor.race.status}
              highlightKey='acceptor.race.fixture.name'
              highlights={highlights}
              scratchedStatus={acceptor.scratchedStatus}
            />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell flexGrow={0} flexBasis='auto'>
            <SearchSilkImage
              meetingId={acceptor.race.fixture.id}
              meetingDate={acceptor.race.fixture.date}
              raceNo={acceptor.race.number}
              isDogsSilk={acceptor.race.fixture.contestType === RacingCode.Dogs}
              isTrots={acceptor.race.fixture.contestType === RacingCode.Trots}
              acceptorNumber={acceptor.numberOrBox}
            />
          </GridCell>
          <GridCell>
            <SearchResultPrimaryInfoStyled>
              {acceptor.numberOrBox}
              <> - </>
              <HighlightDisplay
                displayText={acceptor.name}
                highlightKey='acceptor.name'
                highlights={searchResult.metadata.highlights}
              />
            </SearchResultPrimaryInfoStyled>
            <SearchResultSecondaryInfoStyled>
              {driver}
              {spacer}
              {trainer}
            </SearchResultSecondaryInfoStyled>
          </GridCell>

          <GridCell width='11.5rem' align='right'>
            {!acceptor.fixedOddsPrices ? (
              '-'
            ) : (
              <SearchResultPricesStyled>
                <span data-tid-search-results-win-dividend=''>
                  {!acceptor.fixedOddsPrices.win.isValid
                    ? '-'
                    : acceptor.fixedOddsPrices.win?.dividend?.toFixed(2)}
                </span>
                <span data-tid-search-results-place-dividend=''>
                  {!acceptor.fixedOddsPrices.place.isValid
                    ? '-'
                    : acceptor.fixedOddsPrices.place?.dividend?.toFixed(2)}
                </span>
              </SearchResultPricesStyled>
            )}
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}

export function SearchResultRaceComponent({
  searchResult,
  currentDateMilli,
  highlights: tokens,
}: SearchResultComponentProps): JSX.Element {
  const { race } = searchResult as SearchResultRace

  const advertisedStartTime = race.isStartTimeRelevant ? race.advertisedStartTime : undefined

  return (
    <div data-testid='SearchResultRaceComponent'>
      <Grid>
        <GridRow>
          <GridCell>
            <LocationTime
              isSport={false}
              locationName={race.fixture.name}
              raceNumber={race.number}
              advertisedStartTime={advertisedStartTime}
              currentDateMilli={currentDateMilli}
              code={race.fixture.contestType}
              status={race.status}
              highlightKey='race.fixture.name'
              highlights={tokens}
            />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell>
            <RaceDetailsStyled>
              <HighlightDisplay
                displayText={race.name}
                highlightKey='race.name'
                highlights={searchResult.metadata.highlights}
              />
            </RaceDetailsStyled>
            <RaceDistanceStyled>{race.distance}m</RaceDistanceStyled>
            <RaceClassStyled>
              <HighlightDisplay
                displayText={race.class}
                highlightKey='race.class'
                highlights={searchResult.metadata.highlights}
              />
            </RaceClassStyled>
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}

export function SearchResultSportComponent({
  searchResult,
  currentDateMilli,
  highlights: tokens,
}: SearchResultComponentProps): JSX.Element {
  const { sport } = searchResult as SearchResultSport
  return (
    <div data-testid='SearchResultSportComponent'>
      <Grid>
        <GridCell>
          <SportsStyled>{sport.name}</SportsStyled>
        </GridCell>
        <GridCell width='11.5rem' align='right'>
          <LocationTime
            isSport={true}
            locationName={sport.locationName}
            advertisedStartTime={sport.advertisedStartTime}
            currentDateMilli={currentDateMilli}
            highlightKey='sport.locationName'
            highlights={tokens}
          />
        </GridCell>
      </Grid>
    </div>
  )
}

export function SearchResultFobEventComponent({
  searchResult,
  currentDateMilli,
  highlights,
}: SearchResultComponentProps): JSX.Element {
  const { fobEvent } = searchResult as SearchResultFobEvent

  const advertisedStartTime = fobEvent.isStartTimeRelevant ? fobEvent.startTime : undefined

  return (
    <div data-testid='SearchResultFobEventComponent'>
      <Grid>
        <GridRow>
          <GridCell>
            <LocationTime
              isSport={false}
              locationName={fobEvent.raceCourse}
              raceNumber={fobEvent.number}
              advertisedStartTime={advertisedStartTime}
              currentDateMilli={currentDateMilli}
              code={fobEvent.contestType}
              status={fobEvent.result ?? fobEvent.status}
              highlights={highlights}
              highlightKey='fobEvent.raceCourse'
            />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell>
            <RaceDetailsStyled>
              <HighlightDisplay
                displayText={fobEvent.name}
                highlightKey='fobEvent.name'
                highlights={searchResult.metadata.highlights}
              />
            </RaceDetailsStyled>
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}

export function SearchResultFobPropositionComponent({
  searchResult,
  currentDateMilli,
  highlights,
}: SearchResultComponentProps): JSX.Element {
  const { fobProposition } = searchResult as SearchResultFobProposition

  const isFooOrFff = [RaceProduct.FixedOddsOnly, RaceProduct.FutureFinalField].includes(
    fobProposition.fobEvent.productCode as RaceProduct
  )

  const bottomLeftDisplayContent = isFooOrFff ? null : (
    <SearchResultSecondaryInfoStyled>
      <HighlightDisplay
        displayText={fobProposition.fobEvent.name}
        highlightKey='fobProposition.fobEvent.name'
        highlights={searchResult.metadata.highlights}
      />
    </SearchResultSecondaryInfoStyled>
  )

  const advertisedStartTime = fobProposition.isStartTimeRelevant
    ? fobProposition.fobEvent.startTime
    : undefined

  return (
    <div data-testid='SearchResultFobPropositionComponent'>
      <Grid>
        <GridRow>
          <GridCell>
            <LocationTime
              isSport={false}
              locationName={fobProposition.fobEvent.raceCourse}
              raceNumber={fobProposition.fobEvent.number}
              advertisedStartTime={advertisedStartTime}
              currentDateMilli={currentDateMilli}
              code={fobProposition.fobEvent.contestType}
              status={fobProposition.result ?? fobProposition.status}
              highlightKey={'fobProposition.fobEvent.raceCourse'}
              highlights={highlights}
            />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell>
            <SearchResultPrimaryInfoStyled>
              {fobProposition.number}
              <> - </>
              <HighlightDisplay
                displayText={fobProposition.name}
                highlightKey='fobProposition.name'
                highlights={searchResult.metadata.highlights}
              />
            </SearchResultPrimaryInfoStyled>
            {bottomLeftDisplayContent}
          </GridCell>

          <GridCell width='11.5rem' align='right'>
            {
              <SearchResultPricesStyled>
                <span data-tid-search-results-win-dividend=''>
                  {fobProposition.price.winPrice?.toFixed(2) ?? '-'}
                </span>
                <span data-tid-search-results-place-dividend=''>
                  {fobProposition.price.placePrice?.toFixed(2) ?? '-'}
                </span>
              </SearchResultPricesStyled>
            }
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}
