import { Currency } from '@mobi/utils/money'
import type { KeypadInput } from '@mobi/betslip/Store/Workflow'

export function determineValueFromKeypad({ keypadInput, currentValue }: Args): string {
  const currValue = currentValue || '0'
  switch (keypadInput.type) {
    case 'action': {
      if (keypadInput.value === 'clear') return '0'
      if (keypadInput.value === 'del') return currValue.slice(0, -1).toString()
      if (keypadInput.value === '.') {
        return currValue.includes('.') ? currValue : `${currValue}.`
      }
      break
    }

    case 'numeric': {
      if (currValue.includes('.')) {
        const decimalValues = currValue.split('.')[1]
        return decimalValues.length >= 2 ? currValue : `${currValue}${keypadInput.value}`
      }
      return `${new Currency(currValue).value === 0 ? '' : currValue}${keypadInput.value}`
    }

    case 'denomation': {
      const [dollars, cents] = new Currency(currValue).add(keypadInput.value).toString().split('.')
      return `${dollars}${new Currency(cents).value > 0 ? '.' + cents : ''}`
    }
  }

  return '0'
}

// =====
// Types
// =====

type Args = { keypadInput: KeypadInput; currentValue: string | null }
