import { Day } from '@core/Components/Icons/Day'
import { Night } from '@core/Components/Icons/Night'
import React from 'react'
import { GreetingLabelStyled, GreetingContainer } from './Greeting.styles'

type GreetingProps = {
  currHour: number
}

export function GreetingComponent({ currHour }: GreetingProps): JSX.Element {
  return (
    <GreetingContainer>
      {currHour > 5 && currHour < 18 ? <Day /> : <Night />}
      <GreetingLabelStyled>
        {'Good ' + ((currHour < 12 && 'morning') || (currHour < 18 && 'afternoon') || 'evening')}
      </GreetingLabelStyled>
    </GreetingContainer>
  )
}

export const Greeting = () => {
  const currHour = new Date().getHours()
  return <GreetingComponent currHour={currHour} />
}
