import {
  fetchAccountBalanceAsync,
  fetchCampaignsAsync,
} from '@core/State/UserAccount/async-signals'
import {
  HasLoggedOut,
  state$ as userAccountState$,
  UserAccountStateRecord,
} from '@core/State/UserAccount/userAccountDriver'
import { state$ as kambiState$, RemoveKambiSpinner } from '@core/Areas/Kambi/driver'
import {
  determineUserRegulationId,
  createLogger,
  tryToLoginKambiPlayer,
  tryToLogoutKambiPlayer,
} from '@core/Areas/Kambi/helpers'
import { KAMBI_BETHISTORY_HASH_ROUTE, KAMBI_BETSLIP_DIV_CLASS } from '@core/Areas/Kambi/constants'
import { showLogin, getIsLoggedOn } from '@core/Areas/Login/helpers'
import { RegisterToast, RemoveToast } from '@core/Components/Toast/ToastDriver'
import { openDepositModal } from '@core/Areas/Deposit'

const SESSION_TOAST_ID = 'kambi-session-toast'

export default {
  enableNewFrameworkLayout: true,
  hideHeader: true,
  enableLandingPageFeed: false,
  enableLandingPageLiveRightNow: false,
  enableLandingPageLiveRightNowAggregate: false,
  attachBetslipToBody: false,
  enablePreviouslySearchedTerms: true,
  enableFootballVisualisation: false,
  enableQuickBrowse: true,
  enableLiveStats: true,
  routeRoot: 'tabtouch-sports',
  ignoredRoutes: [],
  enableBetslipDepositButton: true,
  enablePinnedBetslip: false,
  showFooterSettingsLink: false,

  betslipQuerySelectors: {
    // pinned: 'NOT SPECIFIED', // Handled by Kambi
    unpinned: `.${KAMBI_BETSLIP_DIV_CLASS}`,
  },

  /**
   * The URL location to redirect to when the user clicks on the
   * customers logo in the header. This URL can be an absolute URL or
   * a relative URL which is relative to the HTML file.
   *
   * @type String
   * @default undefined
   */
  homeUrl: '/#',
  /**
   * The URL location to redirect to when the user clicks on the
   * login button in the header. This URL can be an absolute URL or
   * a relative URL which is relative to the HTML file.
   *
   * @type String
   * @default undefined
   */
  loginUrl: 'notification',
  /**
   * The URL location to redirect to when the user clicks on the lobby
   * button in the header. This URL can be an absolute URL or
   * a relative URL which is relative to the HTML file.
   *
   * @type String
   * @default undefined
   */
  lobbyUrl: undefined,
  /**
   * Toggles the visibility of deposit button/links.
   *
   * @type Bool
   * @default undefined
   */
  enableDeposit: true,
  /**
   * The URL location to redirect to when the user wants to deposit
   * money into his/her account. This URL can be an absolute URL or
   * a relative URL which is relative to the HTML file.
   *
   * @type String
   * @default undefined
   */
  depositUrl: 'notification',
  /**
   * Toggles live betting. When set to false no live events or live
   * bet offers will be available in the client.
   *
   * @type Boolean
   * @default true
   */
  enableLiveBetting: false,
  liveBettingByPhoneOnlyEnabled: true,
  liveBettingPhoneNumber: '+611300238548',
  liveBettingHumanReadablePhoneNumber: '1300 238 548',

  /**
   * Toggles visibility of odds format selector in the client
   *
   * @type Boolean
   * @default false
   */
  enableOddsFormatSelector: false,
  /**
   * For tracking with Google Universal Analytics.
   * Google calls this the Web Property Id and
   * calls it UA-XXXX-Y in their documentation.
   *
   * @type String
   * @default ''
   */
  googleAnalyticsWebPropertyID: '',
  /**
   * Live betting by phone only:
   * A freetext string witch will added to the more generic message regarding Call to place bet.
   *
   * It tells which regulation applies
   * E.g 'Australian regulations'
   *
   * @type String
   * @default undefined
   */
  liveBettingRegulationString: undefined,
  /**
   * An object containing the different links that are available in the
   * account section in the client.
   *
   * Link information should be presented as an object where the name of
   * the object is the link 'type'. The 'type' is used within the app to
   * display the right icon next to the link, if applicable. The link
   * 'type' can be a pre-defined type that the client is aware of, or any
   * custom type defined by the customer.
   *
   * Each link is defined with the following properties:
   *      - url: The URL of the link
   *      - label: The label to display for the link
   *      - sortOrder: The sort order of the link. Should be a whole
   *          number. The lower the number, the higher up on the page
   *          the link should be. Sort order 1 is the first item
   *      - external: True if the link is opened in a new window or false
   *          in the same window [Optional]
   *      - skipAnimation: To skip the closing animation of the account
   *          menu when opening links
   *
   * @type Object
   * @default undefined
   */

  enableAllSportsInNavpanel: true,

  accountLinks: {
    // NOTE: This is only example code. Customer should replace with their
    // own implementation
    // support: {
    //   url: 'http://kambi.com/',
    //   label: 'Support',
    //   sortOrder: 1,
    //   external: true,
    // },
    // deposit: {
    //   url: 'http://kambi.com/',
    //   label: 'Deposit',
    //   sortOrder: 2,
    //   external: true,
    // },
    // faq: {
    //   url: 'http://kambi.com/',
    //   label: 'FAQ',
    //   sortOrder: 3,
    //   external: true,
    // },
  },
  /**
   * An object containing the different links that are available in the
   * footer in the client.
   *
   * Link information should be presented as an object where the name of
   * the object is the link 'type'. The 'type' is used within the app to
   * display the right icon next to the link, if applicable. The link
   * 'type' can be a pre-defined type that the client is aware of, or any
   * custom type defined by the customer.
   *
   * Each link is defined with the following properties:
   *      - url: The URL of the link
   *      - label: The label to display for the link
   *      - sortOrder: The sort order of the link. Should be a whole
   *          number. The lower the number, the higher up on the page
   *          the link should be. Sort order 1 is the first item
   *      - external: True if the link is opened in a new window or false
   *          in the same window [Optional]
   *      - featured: True if the link is to be highlighted on the start
   *          page below the normal content but above all other footer
   *          links. The link will be displayed as a full-width link with
   *          a pre-defined icon on the right. [Optional]
   *
   * If the link is an image link the following should be added:
   *      - imageHref: The url to the image that should be shown as a link
   *      - label: Note that the label will be used as the 'alt' property
   *          of the HTML img tag (shown if the image URL is wrong, image
   *          type is not supported and while the image is downloading).
   *
   * @type Object
   * @default undefined
   *
   * E.g.:
   */
  footerLinks: {
    // NOTE: This is only example code. Customer should replace with their
    // own implementation
  },
  /**
   * An object containing the optional links to be displayed at the bottom
   * of the betslip.
   *
   * The link is defined with the following properties:
   *      - url: The URL of the link
   *      - label: The label to display for the link
   *      - external: True if the link is opened in a new window or false in
   *          the same window [Optional]
   *
   * @type Object
   * @default undefined
   */
  betslipLinks: {
    // NOTE: This is only example code. Customer should replace with their
    // own implementation
    // link: {
    //   url: 'http://kambi.com/',
    //   label: 'Play It Safe',
    //   external: true,
    // },
  },
  /**
   * An object containing the optional links to be displayed at the bottom
   * of the bet history, below the 'More' bets link.
   *
   * The link is defined with the following properties:
   *      - url: The URL of the link
   *      - label: The label to display for the link
   *      - external: True if the link is opened in a new window or false in
   *          the same window [Optional]
   *
   * @type Object
   * @default undefined
   */
  bethistoryLinks: {
    // NOTE: This is only example code. Customer should replace with their
    // own implementation
    // link: {
    //   url: 'http://kambi.com/',
    //   label: 'German regulations',
    //   external: true,
    // },
  },
  /**
   * Toggles visibility of the navigation menu in the client (if browser-window is wide enough)
   *
   * @type Boolean
   * @default false
   */
  enableNavigationPanel: true,

  notification: (event: KambiEvent): void => {
    notificationLog(event.name)

    switch (event.name) {
      case 'pageRendered': {
        // timeout for smoother rendering
        setTimeout(RemoveKambiSpinner, 1000)
        break
      }

      case 'sessionTimedOut': {
        getIsLoggedOn().then(async isMobiSessionActive => {
          if (!isMobiSessionActive) {
            HasLoggedOut()
            return
          }

          const userAccountState = await userAccountState$.take(1).toPromise()
          if (!userAccountState.isLoggedIn || !userAccountState.accountNumber) return

          const kambiState = await kambiState$.take(1).toPromise()

          // Only display toast when user interface is impacted from session renewal
          if (
            kambiState.get('isBetslipMaximized') ||
            window.location.href.includes(KAMBI_BETHISTORY_HASH_ROUTE)
          ) {
            RegisterToast({
              message: 'Verifying Session',
              id: SESSION_TOAST_ID,
              timeout: 10,
              position: 'top',
              spinner: true,
            })
          }

          handleKambiSessionLogin(
            userAccountState.accountNumber,
            userAccountState.residentialDetail
          )
        })
        break
      }

      case 'loginRequested': {
        userAccountState$.take(1).subscribe(userAccountState => {
          if (userAccountState.isLoggedIn && userAccountState.accountNumber) {
            handleKambiSessionLogin(
              userAccountState.accountNumber,
              userAccountState.residentialDetail
            )
          } else {
            notificationLog('player requested to login to tab touch')
            showLogin()
          }
        })
        break
      }

      case 'loginRequestDone': {
        RemoveToast(SESSION_TOAST_ID)
        break
      }

      case 'depositRequested': {
        openDepositModal('account-deposit')
        break
      }

      case 'cashInEnd': {
        fetchAccountBalanceAsync()
        break
      }

      case 'placedBet': {
        fetchAccountBalanceAsync()
        fetchCampaignsAsync()
        break
      }
    }
  },

  logout: (): void => {
    kambiState$.take(1).subscribe(kambiAccountState => {
      const logoutToken = kambiAccountState.get('logoutToken')
      if (logoutToken) {
        notificationLog('trying to logout kambi player')
        tryToLogoutKambiPlayer(logoutToken)
      }
    })
  },

  getBalance: (): void => {
    // not implemented
  },

  heartbeat: (): void => {
    // not implemented
  },
}

const notificationLog = createLogger('deeppink', 'KAMBI: Notification')

// =============
// Local Helpers
// =============
function handleKambiSessionLogin(
  accountNumber: number,
  residentialDetail: UserAccountStateRecord['residentialDetail']
) {
  notificationLog('trying to login to Kambi to place bet')
  tryToLoginKambiPlayer(accountNumber, determineUserRegulationId(residentialDetail))
}
