import React from 'react'

const RADIUS = 40
const STARTX = 10
const STARTY = 50

const defaultPath = 'M0,0'

export const RatingTacho = ({ rating }: { rating: number }) => {
  if (!rating) {
    return null
  }

  return (
    <svg viewBox='0,0,100,50' width='4rem' height='2rem' style={{ marginTop: '0.2rem' }}>
      <g transform='translate(0, -5)'>
        <path d='M10,50A40,40 0 0 1 90,50' stroke='#e8e8e8' strokeWidth='10' fill='none' />
        <path d={blueArcPath(rating)} strokeWidth='10' fill='none' className='tachomove' />
        <path d={orangeArcPath(rating)} strokeWidth='10' fill='none' className='tachomove orange' />
        <text x='49' y='48' textAnchor='middle' color='#111' fontSize='24' data-tid-rating=''>
          {rating}
        </text>
      </g>
    </svg>
  )
}

export const blueArcPath = (rating: number) => {
  if (rating <= 0) {
    return defaultPath
  }
  if (rating <= 100) {
    return arcPath(rating)
  }
  if (rating <= 150) {
    return arcPath(100)
  }
  return defaultPath
}

export const orangeArcPath = (rating: number) => {
  if (rating <= 100) {
    return defaultPath
  }
  if (rating <= 150) {
    return arcPath(50)
  }
  return arcPath(100)
}

const arcPath = (clampedRating: number) => {
  const θ = Math.PI * 1e-2 * clampedRating
  const endX = RADIUS * (1.0 - Math.cos(θ))
  const endY = -RADIUS * Math.sin(θ)
  return `M${STARTX},${STARTY}a${RADIUS},${RADIUS} 0 0 1 ${endX},${endY}`
}
