import { createSignal } from 'rwwa-rx-state-machine'
export type Status =
  | 'default'
  | 'connecting'
  | 'connected'
  | 'reconnecting'
  | 'error'
  | 'not_supported'

export const OnStatusChanged = createSignal<Status>('PushDataStatusChanged')
