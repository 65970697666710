import React from 'react'
import { useParams } from 'react-router-dom'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import type { Tabs, Ticket as TicketType } from '@core/Areas/AccountActivity/types'
import { Header, Footer, Details, TICKET_HEIGHT_TRANSITION_MS } from './Components'
import { CardStyled as Card } from './Ticket.styles'
import { getTicketStatus } from './helpers/getTicketStatus'

export const Ticket: React.FC<{
  ticket: TicketType
}> = ({ ticket }) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    TICKET_HEIGHT_TRANSITION_MS
  )
  const [status] = React.useState(() => getTicketStatus(ticket, activeTab))

  return (
    <Card data-ticket-seq={ticket.ticketSequence}>
      <Header ticket={ticket} status={status} onClick={toggleExpanded} isExpanded={isExpanded} />

      {(isExpanded || shouldRender) && (
        <Details ticket={ticket} detailsWrapperRef={wrapperRef} detailsContentRef={contentRef} />
      )}

      <Footer ticket={ticket} status={status} />
    </Card>
  )
}
