import type { Draft } from '@reduxjs/toolkit'
import type { BetSlipErrorResponse } from '@mobi/betslip/types'
import { mapErrorResponseCodeToType } from '@mobi/betslip/Store/Bets/helpers/apiResponse/errors'
import type { BetSlipWorkflowState } from '..'

export function handleSettingServerCallRejectionState(
  state: Draft<BetSlipWorkflowState>,
  errorResponse: BetSlipErrorResponse
) {
  state.isBusy = false
  state.currentStatus = 'ready'

  if (mapErrorResponseCodeToType(errorResponse.code) === 'Unauthorized') {
    return
  }
  state.apiErrorMessage =
    errorResponse.response && errorResponse.response.message
      ? errorResponse.response.message
      : 'Unable to verify bet(s) - Please try again'
}
