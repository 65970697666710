import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mobi/component-library/Common/Buttons'
import { setBackUrl } from '@classic/Foundation/Navigation/Nav'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'
import { MeetingsAPIResponse } from '@core/Areas/Racing/Hooks/useMeetings/types'
import { getCleanMeetingName } from '../helpers/getCleanMeetingName'

export const DropdownLink: React.FC<{ meeting: MeetingsAPIResponse[0]['meetings'][0] }> = ({
  meeting,
}) => {
  const history = useHistory()

  const nextRaceUrl = getRaceLink({
    meetingId: meeting.id,
    raceNumber: meeting.nextRaceNumber || 1,
    meetingDate: meeting.date,
    code: meeting.code,
    meetingSeq: meeting.fixed?.competitionId,
    product: meeting.fixed?.racingProduct,
    raceSeq: meeting.fixed?.nextRaceEventId,
    isFOO: Number.isInteger(Number(meeting.id)),
  })
  const onLinkClick = () => {
    setBackUrl(makeBackUrl(meeting))
    history.push(nextRaceUrl)
  }

  return (
    <Button color='link' data-testid='meeting-dropdown-link' onClick={onLinkClick}>
      {getCleanMeetingName(meeting.name)}
    </Button>
  )
}

// =============
// Local Helpers
// =============

function makeBackUrl({ fixed, date, id }: MeetingsAPIResponse[0]['meetings'][0]): string {
  const cleanDate = dayjs(date).format('YYYY-MM-DD')
  return fixed
    ? `#tote/races?date=${cleanDate}&meetingId=-&product=${fixed.racingProduct.toLowerCase()}&compSeq=${
        fixed.competitionId
      }`
    : `#tote/races?date=${cleanDate}&meetingId=${id}&compSeq=0`
}
