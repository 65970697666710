import React from 'react'
import { ExpandablePanelContentStyled } from './ExpandablePanelContent.styles'
import { ExpandableSection } from './ExpandableSection'

interface ExpandablePanelContentProps {
  expanded: boolean
  children: React.ReactNode
  scrollIntoViewWhenOpened: boolean
  useSmartScroll?: boolean
  transitionTime?: number
  transitionCloseTime?: number
}

export function ExpandablePanelContent({
  expanded,
  children,
  scrollIntoViewWhenOpened,
  useSmartScroll,
  transitionTime = 200,
  transitionCloseTime = 100,
}: ExpandablePanelContentProps): JSX.Element {
  return (
    <ExpandableSection
      expanded={expanded}
      scrollIntoViewWhenOpened={scrollIntoViewWhenOpened}
      useSmartScroll={useSmartScroll}
      transitionTime={transitionTime}
      transitionCloseTime={transitionCloseTime}
    >
      <ExpandablePanelContentStyled>{children}</ExpandablePanelContentStyled>
    </ExpandableSection>
  )
}
