import React from 'react'
import styled from '@emotion/styled'
import { ReplayVideoPlayer } from '@core/Areas/Racing/Components/RaceReplay'
import { useAppSelector } from '@core/Store/hooks'

export function TicketReplayVideoPlayer({
  ticketId,
  ...props
}: {
  ticketId: number
} & React.ComponentProps<typeof ReplayVideoPlayer>) {
  const activeReplayTicketId = useAppSelector(state => state.accountActivity.activeReplayTicketId)

  if (activeReplayTicketId && ticketId != activeReplayTicketId) {
    const { onCloseTapped } = props
    onCloseTapped && onCloseTapped()
  }

  return (
    <TicketReplayVideoPlayerContainerStyled>
      <ReplayVideoPlayer {...props} />
    </TicketReplayVideoPlayerContainerStyled>
  )
}

const TicketReplayVideoPlayerContainerStyled = styled.div({
  padding: '4px',
})
