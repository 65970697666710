import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const DetailsSelectionInfoStyled = styled('div')({
  display: 'flex',
  flex: 1,

  '*': {
    wordBreak: 'break-word',
  },

  h4: {
    background: 'none',
    padding: 0,
    margin: 0,
    color: hexColors.nero,
    fontSize: '1.3rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    textTransform: 'none',
    textAlign: 'start',
  },

  span: {
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    color: hexColors.grey,
  },
})
