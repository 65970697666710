import type { SortOption } from './types'

export const persistedSortOption: SortOption = {
  property: 'Number',
  direction: 'Ascending',
} as const

export const setPersistedSortOption = ({ property, direction = 'Ascending' }: SortOption) => {
  persistedSortOption.property = property
  persistedSortOption.direction = direction
}
