import styled from '@emotion/styled'

export const ManualAddressEntryFormStyled = styled('form')({
  textAlign: 'left',
  marginBottom: '1rem',
})

export const ManualAddressEntryTitleStyled = styled('header')({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  lineHeight: 2.5,
  textAlign: 'left',
  height: 'auto', // desktop override
  backgroundColor: 'white', // desktop override
})
