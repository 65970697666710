import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

export function trackTopNavigationClicked(linkText: string) {
  trackEvent(keys.categoryNavigation, {
    'menu-position': 'top',
    cn_target: linkText,
  })
}

export function trackBottomNavigationClicked(linkText: string) {
  trackEvent(keys.categoryNavigation, {
    'menu-position': 'bottom',
    cn_target: linkText,
  })
}
