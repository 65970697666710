import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const FormulaStyled = styled('div')({
  background: hexColors.whiteSmoke,
  padding: '0 1.5rem 1rem 1.5rem',
})

export const FormulaListStyled = styled('ul')({
  borderColor: hexColors.veryLightGrey,
  borderStyle: 'solid',
  borderWidth: '1px 0 0 1px',
  display: 'flex',
  listStyle: 'none',
  margin: 0,
  padding: 0,
})
export const FormulaListItemStyled = styled('li')({
  flex: '1 1 0',
  textAlign: 'center',
  borderColor: hexColors.veryLightGrey,
  borderStyle: 'solid',
  borderWidth: '0 1px 1px 0',
  flexBasis: '100%',
})

interface FormulaButtonStyledProps {
  isSelected?: boolean
}

export const FormulaButtonStyled = styled.button<FormulaButtonStyledProps>(
  {
    border: 'none',
    fontSize: '1.2rem',
    fontFamily: 'inherit',
    height: '5rem',
    lineHeight: 1.5,
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    padding: 0,
    margin: 0,
  },

  ({ isSelected }) => ({
    background: isSelected ? hexColors.studio : hexColors.white,
    color: isSelected ? hexColors.white : hexColors.black,
  })
)

export const NameStyled = styled('span')({
  display: 'block',
})

export const CombinationsStyled = styled('span')({
  display: 'block',
})
