import React from 'react'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { Flex } from '@mobi/component-library/Common/Flex'
import { radius } from '@mobi/component-library/Theme/Common'

export function NextRaceLoading(): JSX.Element {
  return (
    <div style={{ padding: '1.5rem' }}>
      <Flex flexDirection='row'>
        <div style={{ width: '7rem' }}>
          <LoadingPlaceholder width='6.5rem' height='3.25rem' borderRadius={radius.lg} />
        </div>
        <div style={{ width: '5rem' }}>
          <LoadingPlaceholder width='4rem' height='3.25rem' borderRadius={radius.lg} />
        </div>
        <Flex flexDirection='column' justifyContent='space-between'>
          <LoadingPlaceholder width='135px' height='1.5rem' borderRadius={radius.lg} />
          <LoadingPlaceholder width='135px' height='1.5rem' borderRadius={radius.lg} />
        </Flex>
      </Flex>
    </div>
  )
}
