import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { BetAccountHolder, PostFeedbackDataToApiPayload } from '@mobi/api-types'
import { postFeedbackDataToApi } from './api'

export const handleFormSubmission = async ({
  message,
  subject,
  setMessage,
  setIsBusy,
  accountNumber,
  contactDetails,
}: {
  message: string
  subject: string
  setMessage: React.Dispatch<React.SetStateAction<string>>
  setIsBusy: React.Dispatch<React.SetStateAction<boolean>>
  accountNumber: number
  contactDetails?: BetAccountHolder
}) => {
  try {
    setIsBusy(true)
    if (!contactDetails) throw Error()

    await postFeedbackDataToApi(
      mapFormValuesToApiData({ message, subject, accountNumber, contactDetails })
    )

    setMessage('')
    setIsBusy(false)
    RegisterToast({
      type: 'default',
      position: 'bottom',
      message: 'Feedback Sent',
    })
  } catch {
    setIsBusy(false)
    RegisterToast({
      type: 'error',
      position: 'bottom',
      message: 'Sorry, Unable to Send',
    })
  }
}

// Helpers

function mapFormValuesToApiData({
  message,
  subject,
  accountNumber,
  contactDetails,
}: {
  message: string
  subject: string
  accountNumber: number
  contactDetails: BetAccountHolder
}): PostFeedbackDataToApiPayload {
  if (!contactDetails) throw Error()

  return {
    Account: accountNumber.toString(),
    Email: contactDetails.Contact?.Email,
    FirstName: contactDetails.NameDetails.FirstName,
    Message: message,
    Phone: contactDetails.Contact?.Mobile?.PhoneNumber,
    Subject: subject,
    Surname: contactDetails.NameDetails.Surname,
    ScreenHeight: 0,
    ScreenWidth: 0,
    PixelDepth: 0,
  }
}
