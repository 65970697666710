import React from 'react'
import { Observable } from 'rx'
import { isStartingPriceSelection } from '@mobi/betslip/helpers/typeGuards'
import { state$ as quickbetState$, QuickbetState } from '../../driver'
import { InvestmentState, state$ as investmentState$ } from '../BetInvestment/betInvestmentDriver'
import { state$ as superPickState$, SuperPickState } from '../../../../Components/SuperPick/driver'
import { ProjectedReturnMoneyStyled, ProjectedReturnStyled } from './ProjectedReturn.styles'
import { observeImmutable } from '../../../../Components/HOCs'
import { calculateProjectedReturn } from '../../helpers/calculator'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'

interface ProjectedReturnProps {
  value: number | null
}

const projectedReturn$ = Observable.combineLatest(
  quickbetState$,
  investmentState$,
  superPickState$,
  (quickbet, investment, superPick): ProjectedReturnProps => {
    const investmentJs = investment.toJS() as InvestmentState

    const {
      win: { value: investmentWin },
      place: { value: investmentPlace },
    } = investmentJs

    const bonusBetValue = investmentJs.bonusBet?.value

    let selectedSpecialOffer: BetSpecialOffer | null = null

    if (superPick.specialOffers && superPick.selectedSuperPickSeq) {
      selectedSpecialOffer =
        (superPick.toJS() as SuperPickState).specialOffers.find(
          offer => offer.specialSeq === superPick.selectedSuperPickSeq
        ) ?? null
    }
    const quickbetState: QuickbetState = quickbet.toJS()
    const hasNoReturn =
      !!quickbetState.selection && isStartingPriceSelection(quickbetState.selection)

    if (hasNoReturn) {
      return { value: null }
    }

    return {
      value:
        investmentWin + investmentPlace <= 0
          ? 0
          : calculateProjectedReturn(
              quickbet.bettingType,
              (quickbetState.selection as { winPrice: number }).winPrice,
              (quickbetState.selection as { placePrice: number }).placePrice,
              investmentWin,
              investmentPlace,
              quickbet.isEachWay,
              bonusBetValue,
              selectedSpecialOffer
            ),
    }
  }
)

export function ProjectedReturnComponent({ value }: ProjectedReturnProps): JSX.Element {
  return (
    <ProjectedReturnStyled data-testid='Quickbet.ProjectedReturn'>
      {value !== null && (
        <>
          Projected Return: <ProjectedReturnMoneyStyled amount={value} />
        </>
      )}
    </ProjectedReturnStyled>
  )
}

export const ProjectedReturn = observeImmutable<ProjectedReturnProps, {}>(
  projectedReturn$,
  ({ record }) => <ProjectedReturnComponent {...record} />
)
