import { BettingInformation } from '../Model/BettingInformation'
import { IBetTypeValidator } from './IBetTypeValidator'
import Guard from '../../AppUtils/Framework/Guard'
import { ButtonsSelection } from '../Components/Core/UIElements/ButtonsSelection'

export default class WinPlaceValidator implements IBetTypeValidator {
  isValid(bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)

    let selections = bettingContext.selections
    let starterSelections = selections.getStartersForRace(bettingContext.raceNumber)()

    // within the fixed/tote betting context confirm no selected starters are scratched, e.g. ignore tote selections in a fixed context
    // - unfortunately this is required because their exists a disconnect between the button's visual state and its view model
    if (bettingContext.isFixed()) {
      let fixedRaceSelections = starterSelections.filter(starter =>
        (starter.selection() as ButtonsSelection).hasFob()
      )
      if (fixedRaceSelections.some(starter => starter.isFixedScratchedOrSuspended())) {
        return false
      }
    } else {
      // field selections are always considered valid
      if (bettingContext.bettingOptionsSelected()) {
        return true
      }

      let toteRaceSelections = starterSelections.filter(starter =>
        (starter.selection() as ButtonsSelection).hasTote()
      )

      if (toteRaceSelections.some(starter => starter.isScratched())) {
        return false
      }
    }

    // no starters for a race
    // - this existing logic shouldn't be possible? perhaps this is trying to cater for a closed race?
    const count = selections.getTotalNumberOfStartersForRace(bettingContext.raceNumber)
    return count > 0
  }
}
