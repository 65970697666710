import { injectable } from 'inversify'
import { INoveltyDividendsViewModel } from './INoveltyDividendsViewModel'
import NoveltyDividend from '../../../Model/NoveltyDividend'

@injectable()
export class NoveltyDividendsViewModel implements INoveltyDividendsViewModel {
  public noveltyDividends!: ko.ObservableArray<NoveltyDividend>

  public init(params: { noveltyDividends: ko.ObservableArray<NoveltyDividend> }) {
    this.noveltyDividends = params.noveltyDividends
  }
}
