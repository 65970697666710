import React from 'react'
import styled from '@emotion/styled'
import {
  FixedOddsMarketCodes,
  MarketDataTransferObject,
  RacePageDataTransferObject,
} from '@mobi/api-types'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { getFobPriceChangeTopic, subscribe, unsubscribe } from '@core/State/PushData'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { MarketProposition } from '../MarketProposition'

export const Market: React.FC<{
  market: MarketDataTransferObject
  raceData: RacePageDataTransferObject
}> = ({ market, raceData }) => {
  const [isPanelExpanded, setPanelExpandedState] = React.useState(true)

  React.useEffect(() => {
    const topic = getFobPriceChangeTopic(market.MarketSequence.toString())
    subscribe([topic])
    return () => {
      unsubscribe([topic])
    }
  }, [market.MarketSequence])

  const marketDisplayName =
    FixedOddsMarketDisplayNames[market.MarketCode as keyof typeof FixedOddsMarketDisplayNames] ||
    market.MarketName

  const isMatchedWithStarters =
    market.MarketCode === FixedOddsMarketCodes.HeadToHead ||
    market.MarketCode === FixedOddsMarketCodes.RunnerVsField

  const meetingCode = isMatchedWithStarters && raceData.MeetingInformation.MeetingCode
  const selectedRaceStarters = isMatchedWithStarters
    ? raceData.RaceStarters.find(
        race => race.RaceKey.Key === raceData.MeetingInformation.SelectedRace.Key
      )
    : undefined

  if (
    (market.MarketCode === 'FAV' && market.Propositions.some(p => !p.Number)) ||
    (!(market.MarketCode === 'FAV') &&
      (market.Propositions.length !== 2 ||
        market.Propositions.some(p => p.IsScratched) ||
        market.Propositions.some(p => !p.Price)))
  ) {
    return null
  }

  return (
    <MarketContainerStyled>
      <ExpandablePanel
        title={marketDisplayName}
        themeName={ExpandablePanelThemes.light}
        deferRenderingContentOnExpand={true}
        headerClicked={() => setPanelExpandedState(curState => !curState)}
        expanded={isPanelExpanded}
        dataTidValue={marketDisplayName}
      >
        <MarketListStyled>
          {market.Propositions.map(proposition => (
            <MarketProposition
              key={proposition.PropositionSequence}
              proposition={proposition}
              matchedStarter={selectedRaceStarters?.Starters.find(
                starter => starter.Number === proposition.Number
              )}
              marketCode={market.MarketCode}
              meetingCode={meetingCode as RaceCodes}
            />
          ))}
        </MarketListStyled>
      </ExpandablePanel>
    </MarketContainerStyled>
  )
}

// Styles

const MarketContainerStyled = styled('div')({
  marginBottom: '0.5rem',
  boxShadow: `0 0.1rem 0.1rem rgba(0, 0, 0, 0.1)`,
})

const MarketListStyled = styled('ul')({
  padding: '0 0.8rem',
  margin: 0,
  background: 'white',
})
