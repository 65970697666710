/* istanbul ignore file */
const logger = (color: string, prefix: string, message: string, ...optionalParams: unknown[]) => {
  // eslint-disable-next-line no-console
  console.log(
    `%c${prefix !== '' ? `${prefix}: ` : ``}${message}`,
    `color:${color}`,
    ...optionalParams
  )
}

export const createLogger = (color: string = 'green', prefix: string = '') => {
  return (message: string, ...optionalParams: unknown[]): void =>
    logger(color, prefix, message, ...optionalParams)
}

export const kambiLog = createLogger('green', 'KAMBI')
