import styled from '@emotion/styled'

export const NoveltyListStyled = styled.dl({ margin: '1rem 0 0' })

export const NoveltyPlaceStyled = styled.dt({
  marginBottom: '0.5rem',
  marginLeft: 0,
  width: '7rem',
  clear: 'both',
  float: 'left',

  '&:last-of-type': {
    marginBottom: 0,
  },
})

export const NoveltyStarterStyled = styled(NoveltyPlaceStyled.withComponent('dd'))({
  width: 'calc(100% - 7rem)',
  clear: 'none',
  float: 'right',
  wordBreak: 'break-all',
  whiteSpace: 'normal',
})

const SingleSelectionStyled = styled.div({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  lineHeight: 'normal',
  padding: '1rem 0 0',
  wordBreak: 'break-all',
  wordWrap: 'normal',
})

export const SingleSameRaceMultiSelectionStyled = styled(SingleSelectionStyled)({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
})

export const AbbrStyled = styled.abbr({ textDecoration: 'none' })
