import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { dayjs } from '@mobi/utils/date'
import { toMoney } from '@mobi/utils/money'
import { GridCell, GridCellStyled } from '@mobi/component-library/Common/Grid'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { RunnerExpanded, RunnerNumber } from '@core/Areas/RaceCard/Components'
import type { RaceCodes, RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import {
  ButtonWrapStyled,
  ImageGridCellStyled,
  PillStyled,
  PropositionFooResultsStyled,
  PropositionOddsStyled,
  FavouriteIconWrapper,
  PropositionResultsStyled,
  SilkFOODivImageStyled,
  SilkFOODivImageResultsStyled,
  SilkImageStyled,
  SingleSilkDivImageResultsStyled,
} from '../RaceCardFixedOnly.styles'
import type { Proposition, SelectedFOOProposition } from '../Data/types'
import { RaceProduct, RaceStatus } from '../constants'
import { FieldsResults } from '@core/Areas/Racing/Components/FieldSummary/FieldsResults'
import { Fields } from '@core/Areas/Racing/Components/FieldSummary/Fields'
import type { FieldsResponse } from '@core/Areas/RaceCardFixedOnly/Hooks/useRaceFieldSummaryData/api'
import { useFeature } from '@core/Utils/hooks'
import { getSingleSilkImageUri } from '@core/Areas/RaceCardFixedOnly/Hooks/Silks/api'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { SingleSilkImageStyled } from '@core/Components/SilkImage/SingleSilkImage.styles'

const enum LocalConstants {
  runnerNameClassName = 'foo__runner-number',
  scratchedDeductionsClassName = 'foo__runner-scratched-deductions',
}

export const PropositionItem: React.FC<{
  fieldSummary?: FieldsResponse
  proposition: Proposition
  raceProduct: RaceProduct
  raceStatus: RaceStatus
  raceCode: RaceCodesLowerCase
  selectedItem: SelectedFOOProposition | null
  onItemSelected: (item: SelectedFOOProposition) => void
  isFieldSelected: boolean
  isStartingPriceAvailable: boolean
  payIndicator?: number
  racecourseSeq?: number | undefined
  meetingDate: string
  raceNumber: number
  raceLocation: string
}> = ({
  fieldSummary,
  proposition,
  raceProduct,
  raceStatus,
  raceCode,
  selectedItem,
  onItemSelected,
  isFieldSelected,
  isStartingPriceAvailable,
  payIndicator,
  racecourseSeq,
  meetingDate,
  raceNumber,
  raceLocation,
}) => {
  const [isRunnerExpanded, setIsRunnerExpanded] = React.useState(false)
  if (proposition.winReturn == null) {
    proposition.racingStatus = RaceStatus.Suspended
  }
  const isFormFeatureActive = useFeature('FOO_FORM_ENABLED')

  const boxNumber = getBoxNumber(proposition.name)

  const isWinner = proposition.resultPlace === 1
  const isScratched = proposition.racingStatus === RaceStatus.Scratched
  const isSuspended = proposition.racingStatus === RaceStatus.Suspended
  const isClosed =
    proposition.racingStatus === RaceStatus.Closed || raceStatus === RaceStatus.Closed
  const isAbandoned =
    proposition.racingStatus === RaceStatus.Abandoned || raceStatus === RaceStatus.Abandoned
  const isRaceFixedOddsOnly = raceProduct === RaceProduct.FixedOddsOnly
  const isFutureFinalField = raceProduct === RaceProduct.FutureFinalField
  const isBlackbookAvailable =
    isRaceFixedOddsOnly ||
    raceProduct === RaceProduct.FutureFinalField ||
    raceProduct === RaceProduct.FeatureRace
  const isRaceSettledOrResulted =
    raceStatus === RaceStatus.Settled || raceStatus === RaceStatus.Resulted
  const isBettingOpen =
    raceStatus === RaceStatus.Open && proposition.racingStatus === RaceStatus.Open

  const shouldShowDogSilks =
    raceCode == 'dogs' &&
    raceProduct == RaceProduct.Challenge &&
    boxNumber &&
    !proposition.name.includes('/')

  const shouldShowSpButton =
    isStartingPriceAvailable &&
    !isScratched &&
    (isBettingOpen || raceStatus === RaceStatus.Suspended)

  const isFieldSummaryPopulated = fieldSummary?.fields !== undefined

  function shouldShowFieldSummaryResults() {
    if (!isRaceFixedOddsOnly || !isFieldSummaryPopulated || isFieldSelected) return false
    return isRaceSettledOrResulted
  }
  function shouldShowFieldSummary() {
    if (!isRaceFixedOddsOnly || !isFieldSummaryPopulated) return false

    const isNotResultedWithStatus =
      !isRaceSettledOrResulted && (isScratched || isSuspended || isAbandoned || isClosed)

    const isNotResultedAndFieldSelectedWithStatus =
      !isRaceSettledOrResulted && isFieldSelected && isSuspended

    const isSettledOrResultedAndFieldSelected = isRaceSettledOrResulted && isFieldSelected

    const isRaceStatusMatching =
      isBettingOpen ||
      isNotResultedWithStatus ||
      isNotResultedAndFieldSelectedWithStatus ||
      isSettledOrResultedAndFieldSelected

    return isRaceStatusMatching
  }
  const isTrots = raceCode === 'trots'
  const isDogs = raceCode === 'dogs'
  const starterNumber = proposition.starterNumber
  const singleSilkImageUri = getSingleSilkImageUri({
    racecourseSeq,
    meetingDate,
    raceNumber,
    isTrots,
    isDogs,
    starterNumber,
    isFieldSummaryPopulated,
  })

  function shouldShowFieldSummaryToFFFRace() {
    if (!isFutureFinalField) {
      return false
    }
    if (!isFieldSummaryPopulated) {
      return false
    }
    const isNotResultedWithStatus =
      !isRaceSettledOrResulted && (isScratched || isSuspended || isAbandoned || isClosed)

    const isNotResultedAndFieldSelectedWithStatus =
      !isRaceSettledOrResulted && isFieldSelected && isSuspended

    const isSettledOrResultedAndFieldSelected = isRaceSettledOrResulted && isFieldSelected

    const isRaceStatusMatching =
      isBettingOpen ||
      isNotResultedWithStatus ||
      isNotResultedAndFieldSelectedWithStatus ||
      isSettledOrResultedAndFieldSelected

    return isRaceStatusMatching
  }

  const handleExpandProposition = () => {
    !isRunnerExpanded
      ? trackEvent(analyticsKeys.formOpened, {
          meetingName: raceLocation,
          meetingCode: raceCode,
        })
      : trackKey(analyticsKeys.formClosed)

    setIsRunnerExpanded(curr => !curr)
  }

  return (
    <>
      <PropositionWrapperStyled
        isWinnerHighlighted={isWinner && !isRaceFixedOddsOnly}
        isScratched={isScratched}
        onClick={handleExpandProposition}
      >
        {isRaceFixedOddsOnly && !isFieldSelected && isRaceSettledOrResulted && (
          <PropositionFooResultsStyled>
            {proposition.resultPlace && (
              <SilkFOODivImageResultsStyled>
                {isFieldSummaryPopulated && singleSilkImageUri && (
                  <SingleSilkDivImageResultsStyled>
                    <SingleSilkImageStyled
                      src={singleSilkImageUri.largest.url}
                      height={singleSilkImageUri.largest.size.height}
                      width={singleSilkImageUri.largest.size.width}
                      isDogsSilk={raceCode === 'dogs'}
                    />
                  </SingleSilkDivImageResultsStyled>
                )}
                <PillStyled color={hexColors.studio} toUpper={false}>
                  {positionToText(proposition.resultPlace)}
                </PillStyled>
              </SilkFOODivImageResultsStyled>
            )}
          </PropositionFooResultsStyled>
        )}

        {shouldShowDogSilks && (
          <ImageGridCellStyled
            valign='middle'
            align='left'
            padding='0'
            flexGrow={0}
            flexBasis='2.5em'
          >
            <SilkImageStyled offset={boxNumber - 1}>{boxNumber}</SilkImageStyled>
          </ImageGridCellStyled>
        )}

        <GridCell valign='middle' align='left' padding='0.4rem 1rem' flexGrow={1}>
          {proposition.name.split('/').map(x => (
            <div
              data-testid='proposition-name'
              key={x.trim()}
              className={LocalConstants.runnerNameClassName}
            >
              {proposition.starterNumber && isBlackbookAvailable && (
                <div>
                  <RunnerNumber
                    starterName={proposition.name.toUpperCase()}
                    starterType={converRaceCodeLowercaseToRaceCode(raceCode)}
                    starterNumber={proposition.starterNumber}
                  />
                </div>
              )}

              <span>{x.trim()}</span>

              {isScratched && (
                <strong>
                  Scratched{' '}
                  {proposition.scratchTime ? (
                    <span>{dayjs.tz(proposition.scratchTime).local().format('DD/MM, h:mma')}</span>
                  ) : (
                    ''
                  )}
                </strong>
              )}
            </div>
          ))}

          {!isScratched && (
            <SilkFOODivImageStyled>
              {(shouldShowFieldSummary() || shouldShowFieldSummaryToFFFRace()) &&
                singleSilkImageUri && (
                  <SingleSilkImageStyled
                    src={singleSilkImageUri.largest.url}
                    height={singleSilkImageUri.largest.size.height}
                    width={singleSilkImageUri.largest.size.width}
                    isDogsSilk={raceCode === 'dogs'}
                  />
                )}
              {shouldShowFieldSummaryResults() &&
                fieldSummary?.fields
                  .filter(itemField => itemField.starterNumber === proposition.starterNumber)
                  .map(raceField => {
                    return (
                      <FieldsResults
                        data-testid='field-results'
                        key={proposition.starterNumber}
                        summary={raceField}
                        raceCode={fieldSummary?.raceCode}
                      />
                    )
                  })}
              {(shouldShowFieldSummary() || shouldShowFieldSummaryToFFFRace()) &&
                fieldSummary?.fields
                  .filter(itemField => itemField.starterNumber === proposition.starterNumber)
                  .map(raceField => {
                    return (
                      <div data-testid='fields' key={proposition.starterNumber}>
                        <Fields summary={raceField} raceCode={fieldSummary?.raceCode} />
                      </div>
                    )
                  })}
            </SilkFOODivImageStyled>
          )}
        </GridCell>

        <GridCellStyled
          valign='middle'
          align='right'
          padding='0.8rem 1rem 0.8rem 0'
          flexBasis='10em'
          flexGrow={0}
        >
          <ButtonWrapStyled>
            {isBettingOpen && (
              <WinPlaceSelection
                tidAttribute='data-tid-dividend-fixed'
                isVisible={true}
                isDisabled={false}
                isFavourite={proposition.isFavourite}
                isSelected={Boolean(
                  selectedItem &&
                    !selectedItem.isStartingPrice &&
                    selectedItem.sequenceNumber === proposition.sequenceNumber
                )}
                onClick={e => {
                  e.stopPropagation()
                  onItemSelected({ ...proposition, isStartingPrice: false })
                }}
                priceWin={proposition.winReturn.toFixed(2)}
                pricePlace={proposition.placeReturn?.toFixed(2)}
                starterNumber={proposition.starterNumber}
                centerContent={!isRaceFixedOddsOnly}
              />
            )}

            {isSuspended &&
              (shouldShowSpButton ? (
                <PropositionOddsStyled aria-label='Suspended'>&ndash;</PropositionOddsStyled>
              ) : (
                <PillStyled color={hexColors.cinnabar}>Suspended</PillStyled>
              ))}

            {isScratched && (
              <div
                className={LocalConstants.scratchedDeductionsClassName}
                data-testid='ScratchedDeductions'
              >
                <strong>
                  {!proposition.winDeduction && !proposition.placeDeduction ? (
                    'No deductions'
                  ) : (
                    <>
                      {`W ${toMoney(proposition.winDeduction || 0)} P ${toMoney(
                        proposition.placeDeduction || 0
                      )}`}
                      <span>Deductions applied</span>
                    </>
                  )}
                </strong>
              </div>
            )}

            {raceStatus !== RaceStatus.Open && !isSuspended && !isScratched && (
              <PropositionResultsStyled highlightWinner={isWinner && !isRaceFixedOddsOnly}>
                {!isRaceFixedOddsOnly && isWinner && (
                  <PillStyled color={hexColors.studio}>Win</PillStyled>
                )}

                {isStartingPriceAvailable && isWinner && proposition.spWinReturn && (
                  <PropositionOddsStyled style={{ paddingRight: '1rem' }}>
                    {proposition.spWinReturn.toFixed(2)}
                  </PropositionOddsStyled>
                )}

                {!isFieldSelected && isRaceFixedOddsOnly && isRaceSettledOrResulted && (
                  <PropositionOddsStyled>
                    {isWinner && (
                      <div>{proposition.winReturn && proposition.winReturn.toFixed(2)}</div>
                    )}
                    {proposition.isFavourite && (
                      <FavouriteIconWrapper data-testid='favouriteIcon'>
                        <Icon name='SolidStar01' color={hexColors.orange} size='1.2rem' />
                      </FavouriteIconWrapper>
                    )}

                    {payIndicator &&
                      proposition.resultPlace <= payIndicator &&
                      proposition.placeReturn &&
                      proposition.placeReturn.toFixed(2)}
                  </PropositionOddsStyled>
                )}

                {(!isRaceFixedOddsOnly ||
                  (isRaceFixedOddsOnly && isFieldSelected) ||
                  (isRaceFixedOddsOnly && !isRaceSettledOrResulted)) && (
                  <PropositionOddsStyled>
                    {proposition.isFavourite && (
                      <FavouriteIconWrapper data-testid='favouriteIcon'>
                        <Icon name='SolidStar01' color={hexColors.orange} size='1.2rem' />
                      </FavouriteIconWrapper>
                    )}

                    {proposition.winReturn && proposition.winReturn.toFixed(2)}
                    <br />
                    {proposition.placeReturn && proposition.placeReturn.toFixed(2)}
                  </PropositionOddsStyled>
                )}
              </PropositionResultsStyled>
            )}
          </ButtonWrapStyled>

          {shouldShowSpButton && (
            <ButtonWrapStyled>
              <WinPlaceSelection
                isVisible
                isDisabled={false}
                isFavourite={false}
                isSelected={Boolean(
                  selectedItem?.isStartingPrice &&
                    selectedItem.sequenceNumber === proposition.sequenceNumber
                )}
                centerContent
                onClick={e => {
                  e.stopPropagation()
                  onItemSelected({ ...proposition, isStartingPrice: true })
                }}
                priceWin='SP'
                pricePlace=''
                starterNumber={proposition.starterNumber}
                tidAttribute='data-tid-dividend-starting-price'
              />
            </ButtonWrapStyled>
          )}
        </GridCellStyled>
      </PropositionWrapperStyled>

      {isBlackbookAvailable && (
        <RunnerExpanded
          isRunnerExpanded={isRunnerExpanded}
          starterName={proposition.name.toUpperCase()}
          starterType={converRaceCodeLowercaseToRaceCode(raceCode)}
          isFormAvailable={isFormFeatureActive}
          isFixedOddsOnly={true}
          racecourseSeq={racecourseSeq}
          selectionDate={meetingDate}
          raceNumber={raceNumber}
          starterNumber={proposition.starterNumber}
          source={
            isRaceFixedOddsOnly
              ? 'racecard-foo'
              : raceProduct === RaceProduct.FutureFinalField
                ? 'racecard-fff'
                : raceProduct === RaceProduct.FeatureRace
                  ? 'racecard-feature'
                  : undefined
          }
        />
      )}
    </>
  )
}

// ======
// Styles
// ======

const PropositionWrapperStyled = styled.div<{
  isWinnerHighlighted: boolean
  isScratched: boolean
}>(
  {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '4rem',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    borderBottom: '0.1rem solid ' + hexColors.gainsboro,
    '&:last-of-type': { border: 0 },

    [`.${LocalConstants.runnerNameClassName}`]: {
      '> div': {
        display: 'inline-flex',
        justifyContent: 'center',
        marginRight: '0.9rem',
        minWidth: '2.2rem',

        span: { flex: 1, textAlign: 'center' },
      },

      '> strong': {
        display: 'block',
        fontSize: '1.1rem',
        paddingLeft: 'calc(2.2rem + 0.9rem)',
        textDecoration: 'none',
        color: hexColors.darkGrey,

        span: { fontWeight: 'normal' },
      },
    },

    [`.${LocalConstants.scratchedDeductionsClassName}`]: {
      color: hexColors.grey,
      fontSize: '1.2rem',
      textTransform: 'none',

      span: { display: 'block', fontWeight: 'normal', fontSize: '1.1rem' },
    },
  },
  ({ isWinnerHighlighted, isScratched }) => ({
    backgroundColor: isWinnerHighlighted ? hexColors.blueDiamond : hexColors.white,
    color: isWinnerHighlighted ? hexColors.white : isScratched ? hexColors.grey : 'default',

    ['.' + LocalConstants.runnerNameClassName]: {
      '> span, > div': {
        textDecoration: isScratched ? 'line-through' : undefined,
      },
    },
  })
)

// =============
// Local Helpers
// =============

const getBoxNumber = (name: string): number | null => {
  for (let i = 10; i >= 1; i--) {
    if (name.toUpperCase().includes(`BOX ${i}`)) return i
  }
  return null
}

const positionToText = (position: number): string | null =>
  ['1st', '2nd', '3rd', '4th'][--position] || null

const converRaceCodeLowercaseToRaceCode = (code: RaceCodesLowerCase): RaceCodes => {
  return (code.slice(0, 1).toUpperCase() + code.slice(1)) as RaceCodes
}
