import Index from './Index'
export default class FieldUIElement {
  constructor(
    public index: number,
    public selected: ko.Observable<boolean>
  ) {
    this.text = Index.toString(this.index + 1)
  }

  public text: string
}
