import React from 'react'
import styled from '@emotion/styled'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import {
  HeaderSelectionIconBoxStyled,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import { LinkWrapper } from '@core/Areas/AccountActivity/Components/Ticket/Components'

export const FavouriteNumbers: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  return (
    <HeaderSelectionInfoStyled>
      <HeaderSelectionIconBoxStyled>
        <Icon type='favouriteNumbers' />
      </HeaderSelectionIconBoxStyled>

      <div>
        <h3 data-tid-bet-product-name>{ticket.productName}</h3>
        <LinkWrapper
          fixtureId={ticket.legs[0].fixtureId}
          fixtureDate={ticket.legs[0].fixtureDate}
          raceNumber={ticket.legs[0].raceNumber}
          label={ticket.legs[0].eventName}
        />

        {ticket.legs[0].selections.map(({ header, selectionDetails }) => (
          <GameStyled key={header} data-testid='favnum-games'>
            <span>{header}</span>
            <span>{selectionDetails[0].selectionString}</span>
          </GameStyled>
        ))}
      </div>
    </HeaderSelectionInfoStyled>
  )
}

const GameStyled = styled('div')({
  display: 'flex',

  'span:first-of-type': {
    flexShrink: 0,
    paddingRight: '1rem',
  },
})
