import { IObservableFinisher } from '../../../Model/Observables/IObservableFinisher'
import Guard from '../../../../AppUtils/Framework/Guard'
import { IFinishersListViewModel } from './IFinishersListViewModel'
import { injectable } from 'inversify'
import ObservableResultsPage from '../../../Model/Observables/ObservableResultsPage'

@injectable()
export class FinishersListViewModel implements IFinishersListViewModel {
  public raceInformation!: ObservableResultsPage
  public raceFinishers!: ko.ObservableArray<IObservableFinisher>
  public showField!: boolean

  init(params: {
    raceInformation: ObservableResultsPage
    raceFinishers: ko.ObservableArray<IObservableFinisher>
    showField: boolean
  }) {
    Guard.notNull(params.raceInformation)

    this.raceInformation = params.raceInformation
    this.raceFinishers = params.raceFinishers
    this.showField = params.showField
  }
}
