import type { Draft } from '@reduxjs/toolkit'
import { Currency } from '@mobi/utils/money'
import type { FobSelection, InvestmentType, MultiInvestmentKey } from '@mobi/betslip/types'
import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import { MAX_INVESTMENT_VALUE, MIN_INVESTMENT_VALUE } from '@mobi/betslip/helpers/constants'
import { calculateMultiReturn } from '@mobi/betslip/helpers/calculator/multis'

export function updateItemInvestment({
  investmentType,
  value,
  betId,
  state,
}: UpdateItemInvestmentArgs) {
  const newValue = getValidBetAmount(new Currency(value).value)

  if (investmentType === 'Multi') {
    state.multiInvestment.value = newValue
    return
  }

  if (investmentType === 'MultiReverseStake') {
    const newStake = new Currency(value).divide(
      calculateMultiReturn(state.items, { shouldRound: true })
    )
    state.multiInvestment.value = newStake.value < 0.5 ? 0.5 : newStake.value
    return
  }

  if (multiComboInvestmentTypes.includes(investmentType)) {
    state.multiInvestment[betId as MultiInvestmentKey] = newValue
  }

  const selectedItem = state.items.find(item => item.id === betId)
  if (!selectedItem) return

  if (investmentType === 'ReverseStake') {
    const newStake = new Currency(value).divide((selectedItem.selection as FobSelection).winPrice)
    selectedItem.investment.win.value = newStake.value < 0.5 ? 0.5 : newStake.value
    return
  }

  if (investmentType === 'Win') {
    selectedItem.investment.win.value = newValue
  }

  if (investmentType === 'Place') {
    selectedItem.investment.place.value = newValue
  }
}

// =============
// Local Helpers
// =============

const multiComboInvestmentTypes: InvestmentType[] = [
  'MultiComboDoubles',
  'MultiComboPick4',
  'MultiComboPick5',
  'MultiComboSingles',
  'MultiComboTrebles',
]

function getValidBetAmount(value: number): number {
  let newValue = new Currency(value).value

  if (newValue >= 0.01 && newValue < MIN_INVESTMENT_VALUE) {
    newValue = MIN_INVESTMENT_VALUE
  }
  if (newValue > MAX_INVESTMENT_VALUE) {
    newValue = MAX_INVESTMENT_VALUE
  }
  return newValue
}

// =====
// Types
// =====

type UpdateItemInvestmentArgs = {
  betId: string
  state: Draft<BetSlipBetsState>
  investmentType: InvestmentType
  value: string
}
