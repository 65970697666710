import * as ko from 'knockout'
import ObservableRaceKey from './ObservableRaceKey'
import { IObservableStarter } from './IObservableStarter'
import ObservableRacePage from './ObservableRacePage'
import { CheckBoxSelection } from '../../Components/Core/UIElements/CheckboxSelection'
import { PoolType } from './ObservableRaceKeyPoolInfo'
import { equal } from '../../../AppUtils/Utils/Algorithms'
import { range } from '@mobi/utils'

export default class ObservableSelectedRaceStarters {
  constructor() {
    this.selections = ko.observableArray<ObservableSelection>([])
  }

  public getAllSelectedStarters(): ko.ObservableArray<IObservableStarter>[] {
    return this.selections().map(selection => selection.starters)
  }

  public getStartersForRace(race: number): ko.ObservableArray<IObservableStarter> {
    const results = this.getSelectionsByRaceNumber(race)

    if (!results) {
      return ko.observableArray<IObservableStarter>([])
    }

    return results.starters
  }

  public getLegForRaceNumber(raceNumber: number): number {
    const results = this.getSelectionsByRaceNumber(raceNumber)

    if (!results) {
      return -1
    }

    return results.raceKey().leg()
  }

  public getRaceNumberForLeg(leg: number): number {
    const results = this.getSelectionsByLeg(leg)[0]

    if (!results) {
      return -1
    }

    return results.raceKey().raceNumber()
  }

  public assignSelectionForRace(race: number, updatedSelections: Array<IObservableStarter>) {
    const raceStarters = this.getSelectionsByRaceNumber(race)

    if (!raceStarters) {
      const key = new ObservableRaceKey()
      key.raceNumber(race)
      this.selections.push({
        raceKey: ko.observable(key),
        starters: ko.observableArray(updatedSelections),
      })
      return
    }

    raceStarters.starters(updatedSelections)
  }

  public updateSelectionForRace(race: number, updatedStarters: IObservableStarter[]) {
    const currentSelections = this.getSelectionsByRaceNumber(race)

    if (!currentSelections || currentSelections.starters().length <= 0) {
      return
    }

    const results = updatedStarters.filter(updatedStarter => {
      return currentSelections
        .starters()
        .some(
          originalStarter =>
            originalStarter.number() === updatedStarter.number() &&
            !updatedStarter.isScratched() &&
            !updatedStarter.isScratchedToteAndFob()
        )
    })

    if (results == null || results.length <= 0) {
      return
    }

    currentSelections.starters(results)
  }

  public getRaceNumbers(): Array<ObservableRaceKey> {
    let results = this.selections().map(x => x.raceKey())
    if (results === null) return []
    return results
  }

  public getTotalNumberOfStartersForRace(raceNumber: number): number {
    return this.getStartersForRace(raceNumber)().length
  }

  public anyRaceSelections(): boolean {
    return !!this.selections()[0]
  }

  public clearAll() {
    this.selections().forEach(selection => {
      selection.starters([])
    })
  }

  public setUpForLegs(data: ObservableRacePage, preserveSelections: boolean = false) {
    if (!preserveSelections) {
      this.selections([])
      data
        .getRaceNumbers()
        .map(race => {
          return {
            raceKey: ko.observable(race),
            starters: ko.observableArray<IObservableStarter>([]),
          }
        })
        .forEach(x => {
          this.selections.push(x)
        })
    } else {
      let preserved = this.selections()
      this.selections([])
      data
        .getRaceNumbers()
        .map(race => {
          return {
            raceKey: ko.observable(race),
            starters: ko.observableArray<IObservableStarter>(
              this.matchStartersForRace(race, data, preserved)
            ),
          }
        })
        .forEach(x => {
          this.selections.push(x)
        })
    }
  }

  private matchStartersForRace(
    race: ObservableRaceKey,
    data: ObservableRacePage,
    previous: Array<{
      raceKey: ko.Observable<ObservableRaceKey>
      starters: ko.ObservableArray<IObservableStarter>
    }>
  ): Array<IObservableStarter> {
    if (!previous || previous.length === 0) {
      return []
    }

    const hasPreviousRace = previous.some(
      observableRace => observableRace.raceKey().raceNumber() === race.raceNumber()
    )

    if (!hasPreviousRace) {
      return []
    }

    const raceStarters = data.getStartersForRace(race.raceNumber())

    if (!raceStarters || !raceStarters() || raceStarters().length === 0) {
      return []
    }

    const previousStarters = previous
      .filter(observableRace => observableRace.raceKey().raceNumber() === race.raceNumber())
      .map(observableRace => observableRace.starters())
      .flat()

    return raceStarters().filter(starter =>
      previousStarters.some(previousStarter => previousStarter.number() === starter.number())
    )
  }

  public legCount(): number {
    return this.selections().length
  }

  public allLegsHaveSelections(legs: Number): boolean {
    return this.selections().filter(selection => selection.starters().length > 0).length === legs
  }

  public betweenTwoAndSixLegsWithSelections(): boolean {
    if (!(this.selections && this.selections())) return false

    const count = this.selections().filter(selection => selection.starters().length > 0).length

    return count >= 2 && count <= 6
  }

  public onlyFirstLegHasSelections(raceNumber: number): boolean {
    return this.getStartersForRace(raceNumber)().every(starter => {
      return (starter.selection() as CheckBoxSelection).onlyFirstSelected()
    })
  }

  public isAlwaysBet(raceNumber: number, columnWidth: number): boolean {
    let checkBoxes = this.getStartersForRace(raceNumber)().map(
      x => x.selection() as CheckBoxSelection
    )

    const sets = range(1, columnWidth + 1).map(() => new Set<number>())

    for (let checkBox of checkBoxes) {
      for (let index = 1; index <= columnWidth; ++index) {
        if (checkBox.selectedAt(index - 1)) {
          sets[index - 1].add(checkBox.starter.number())
        }
      }
    }

    for (let index = 0; index < columnWidth - 1; ++index) {
      if (!equal(sets[index], sets[index + 1])) return false
    }

    return true
  }

  public quinellaLegsHaveNoneOrTwoSelectionsMinimum(): boolean {
    const legs = this.selections().filter(
      observableRace =>
        observableRace.raceKey().poolInfo.selectedPool().type() === PoolType.Quinella
    )

    if (legs.length === 0) {
      return true
    }

    return legs.every(selection => {
      const startersCount = selection.starters().length

      return startersCount === 0 || startersCount >= 2
    })
  }

  private getSelectionsByRaceNumber(raceNumber: number) {
    return this.selections().filter(
      observableRace => observableRace.raceKey().raceNumber() === raceNumber
    )[0]
  }

  private getSelectionsByLeg(leg: number) {
    return this.selections().filter(observableRace => observableRace.raceKey().leg() === leg)
  }

  public selections: ko.ObservableArray<ObservableSelection>
}

interface ObservableSelection {
  raceKey: ko.Observable<ObservableRaceKey>
  starters: ko.ObservableArray<IObservableStarter>
}
