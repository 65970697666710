import React from 'react'
import { DepositError } from '../../DepositError'
import { ApplePayButton } from '@mobi/component-library/Deposit/Buttons/ApplePay'
import { useBraintreeClient, useApplePayDeposit, useApplePayInterop } from '../../../../../Hooks'
import type { PanelProps } from '../../types'

export const ApplePayButtonNative = ({
  initialData: { transactionId, clientToken },
  depositAmount,
  isDepositAllowed,
  onStart,
  onSuccess,
  onDepositing,
  onFailure,
  onCancel,
}: PanelProps) => {
  const { isReady, client } = useBraintreeClient(clientToken)
  const { deposit } = useApplePayDeposit({ braintreeClient: client })
  const { tokenize, attachNativeSubscriptions, disposeNativeSubscriptions } = useApplePayInterop({
    onSuccess: async token => {
      try {
        onDepositing()

        const { isSuccess, ...errorDetails } = await deposit({
          amount: depositAmount,
          paymentMethodNonce: token,
          transactionId,
          depositSource: 'ApplePay',
        })

        if (isSuccess) {
          onSuccess(depositAmount)
        } else {
          onFailure(DepositError.fromErrorDetails(errorDetails))
        }
      } catch (error) {
        onFailure(DepositError.coerce(error, transactionId))
      } finally {
        disposeNativeSubscriptions()
      }
    },
    onFailure: async error => {
      onFailure(new DepositError('deposit_failed', transactionId, undefined, error))
      disposeNativeSubscriptions()
    },
    onCancel: () => {
      onCancel?.()
      disposeNativeSubscriptions()
    },
  })

  const onApplePayButtonClicked = async () => {
    // TODO validation

    onStart()

    const canDeposit = await isReady()

    if (canDeposit) {
      tokenize(clientToken, depositAmount)
      attachNativeSubscriptions()
    } else {
      onCancel?.()
      disposeNativeSubscriptions()
    }
  }

  return (
    <ApplePayButton
      disabled={!isDepositAllowed}
      aria-label='Deposit using Apple Pay'
      onClick={onApplePayButtonClicked}
    />
  )
}
