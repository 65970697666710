import React from 'react'
import * as apiService from '@classic/Foundation/Services/ApiService'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Heading } from '@core/Components/Text/Heading'
import { Paragraph } from '@core/Components/Text/Paragraph'
import { ContactDetailsFields } from '@core/Areas/ContactDetails/driver'
import { PopupNoticeBox } from '@core/Components/PopupNoticeBox'
import { CloseAccountContainerStyled, FieldSetStyled } from './CloseAccount.styles'
import { navigateToLogout } from '@classic/AppUtils/Framework/Intent/navigation'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

const hoursBeforeMarketingStops = 72
const closeAccountButtonText = 'Close account and log out'

interface CheckBoxStates {
  pendingBets: boolean
  remainingFunds: boolean
  marketingMaterial: boolean
  loggedOut: boolean
}
interface CloseAccountViewState {
  checkboxStates: CheckBoxStates
  confirmEnabled: boolean
  showError: boolean
}

type CloseAccountViewProps = Pick<ContactDetailsFields, 'isInternationalCustomer'> & {
  isDesktop: boolean
}

interface CloseBetAccountResponse {
  isSuccessful: boolean
}

export class CloseAccountView extends React.Component<
  CloseAccountViewProps,
  CloseAccountViewState
> {
  public static defaultProps = {
    isInternationalCustomer: false,
    isDesktop: false,
  }

  constructor(props: CloseAccountViewProps) {
    super(props)
    this.state = {
      checkboxStates: {
        pendingBets: false,
        remainingFunds: false,
        marketingMaterial: false,
        loggedOut: false,
      },
      confirmEnabled: false,
      showError: false,
    }
  }

  public componentDidUpdate(): void {
    if (this.state.showError) {
      window.scrollTo(0, 0)
    }
  }

  public render(): JSX.Element {
    const { isInternationalCustomer, isDesktop } = this.props

    return (
      <MainContainer data-tid-close-account-container=''>
        {!isDesktop && <ContentHeader title='Close Account' />}
        <CloseAccountContainerStyled>
          <Paragraph>
            Before you choose to close your account, there are a few things you should know that
            will happen after your account closure.
          </Paragraph>

          <Heading level={3}>Pending Bets</Heading>
          <Paragraph>
            Pending bets will remain active and any winnings will be credited to your account.
          </Paragraph>

          <Heading level={3}>Withdrawals</Heading>
          <Paragraph>
            If any funds remain in your account after closure, you can only withdraw them by
            contacting TABtouch on{' '}
            <CustomerServiceTelephone isInternational={isInternationalCustomer} />.
          </Paragraph>

          <Heading level={3}>Marketing Communications</Heading>
          <Paragraph>
            You may continue to receive marketing material for up to {hoursBeforeMarketingStops}{' '}
            hours after closing your account.
          </Paragraph>

          <Paragraph>Please tick the boxes below to proceed.</Paragraph>

          <Paragraph>
            <strong>I understand that once my account is closed,</strong>
          </Paragraph>

          <form action='' noValidate={true} onSubmit={this.onFormSubmit}>
            <FieldSetStyled>
              <CheckboxInput
                id='pending-bets-checkbox'
                label='any current pending bets will still remain active.'
                required={true}
                onChange={this.onCheckboxChange('pendingBets')}
              />
              <CheckboxInput
                id='remaining-funds-checkbox'
                label={
                  <React.Fragment>
                    remaining funds in my account can only be withdrawn by contacting TABtouch on{' '}
                    <CustomerServiceTelephone isInternational={isInternationalCustomer} />.
                  </React.Fragment>
                }
                required={true}
                onChange={this.onCheckboxChange('remainingFunds')}
              />
              <CheckboxInput
                id='marketing-material-checkbox'
                label={`I may continue to receive marketing material for up to ${hoursBeforeMarketingStops} hours.`}
                required={true}
                onChange={this.onCheckboxChange('marketingMaterial')}
              />
              <CheckboxInput
                id='logged-out-checkbox'
                label='I will immediately be logged out and no longer be able to log back into my account.'
                required={true}
                onChange={this.onCheckboxChange('loggedOut')}
              />
            </FieldSetStyled>
            <NoticeBox title='Warning!' noticeBoxType={NoticeBoxTypes.Warning} hasBorder={true}>
              Once the "{closeAccountButtonText}" button below is clicked, your account will be
              closed and you will be logged out immediately.
            </NoticeBox>
            <ButtonBlock
              onClick={this.onCloseAccountSubmit}
              type='submit'
              color='danger'
              disabled={!this.state.confirmEnabled}
              id='confirm-close-account-button'
            >
              {closeAccountButtonText}
            </ButtonBlock>
          </form>
        </CloseAccountContainerStyled>
        {this.state.showError && <this.ErrorPopupNoticeBox />}
      </MainContainer>
    )
  }

  private onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
  }

  private onCloseAccountSubmit = () => {
    // Disable the confirm button to stop people from 'double-tapping' it.
    this.setState({ confirmEnabled: false })

    apiService
      .post<CloseBetAccountResponse>({ url: '/$_/api/account/closebetaccount' })
      .then(response => {
        if (response.isSuccessful) {
          this.setState({ confirmEnabled: true })
          navigateToLogout({ isDesktop: this.props.isDesktop })
        } else {
          this.setState({ showError: true, confirmEnabled: true })
        }
      })
      .catch(() => {
        this.setState({ showError: true, confirmEnabled: true })
      })
  }

  private onCheckboxChange(field: keyof CheckBoxStates) {
    return (event: React.FormEvent<HTMLInputElement>) => {
      const checked = event.currentTarget.checked
      this.setState(state => {
        const checkboxStates = { ...state.checkboxStates, [field]: checked }
        const confirmEnabled =
          checkboxStates.pendingBets &&
          checkboxStates.remainingFunds &&
          checkboxStates.marketingMaterial &&
          checkboxStates.loggedOut
        return { checkboxStates, confirmEnabled }
      })
    }
  }

  private onPopupClose = () => {
    this.setState({ showError: false })
  }

  private ErrorPopupNoticeBox = () => {
    const { isInternationalCustomer } = this.props

    const subTitle = (
      <React.Fragment>
        <Paragraph>
          We are experiencing technical difficulties that are stopping you from being able to close
          your account.
        </Paragraph>
        <Paragraph>
          Please try again or call the Customer Management Centre on{' '}
          <CustomerServiceTelephone isInternational={isInternationalCustomer} />.
        </Paragraph>
      </React.Fragment>
    )

    return (
      <PopupNoticeBox
        title='Close Account'
        noticeBoxType={NoticeBoxTypes.Error}
        onClose={this.onPopupClose}
      >
        {subTitle}
      </PopupNoticeBox>
    )
  }
}
