export class ToteWinPlaceSelectionsViewModel {
  public title: string
  public raceName: string
  public selectionsString: string

  constructor(params: { raceNumber: number; selectionsString: string }) {
    this.title = 'Win & Place - TOTE'
    this.raceName = 'Race ' + params.raceNumber
    this.selectionsString = params.selectionsString.split('.').join('.\u200B') //\u200B is a zero-width space so the string will break over multiple lines
  }
}
