import React from 'react'
import dayjs from 'dayjs'
import {
  AccountActivityDateStyled as Date,
  AccountActivityHeaderDateStyled as HeaderDateContainer,
} from './HeaderDate.styles'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { hexColors } from '@mobi/settings'

interface DateHeaderProps {
  date: string
  isOrderByEvent: boolean
}

export const HeaderDate: React.FC<DateHeaderProps> = React.memo(({ date, isOrderByEvent }) => {
  const previousDisplayDateRef = React.useRef<string>('')

  React.useEffect(() => {
    previousDisplayDateRef.current = date
  }, [date])

  const prevDate = previousDisplayDateRef.current
  const shouldFindTimeDiff = date && prevDate && date !== prevDate
  const isNewDateBefore = shouldFindTimeDiff
    ? isOrderByEvent
      ? dayjs(date).isAfter(prevDate)
      : dayjs(date).isBefore(prevDate)
    : null

  return (
    <HeaderDateContainer data-tid-date-header>
      <Date
        key={date}
        direction={shouldFindTimeDiff ? (isNewDateBefore ? 'up' : 'down') : 'neutral'}
        previousDate={prevDate}
      >
        {date ? (
          <span data-testid='account-activity-header-date'>{date}</span>
        ) : (
          <div style={{ width: '4rem' }} data-testid='account-activity-header-loading'>
            <BusyIndicator isBusy color={hexColors.lightGrey} />
          </div>
        )}
      </Date>
    </HeaderDateContainer>
  )
})
