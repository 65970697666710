import * as ko from 'knockout'
import { IObservablePage } from './IObservablePage'
import { RacePageResponseType } from '../../Types/RacePageResponseType'
import ObservableMeetingInformation from './ObservableMeetingInformation'
import ObservableRace from './ObservableRace'
import Guard from '../../../AppUtils/Framework/Guard'
import ObservablePool from './ObservablePool'
import { SpecialOfferInfo } from '../SpecialOfferInfo'

export default class ObservablePage implements IObservablePage {
  public meetingInformation: ObservableMeetingInformation
  public availablePools: ko.ObservableArray<ObservablePool>
  public pageType!: RacePageResponseType
  public mergeDone: ko.Observable<boolean>
  public isMultiInProgress: boolean
  public numberOfLegsInMulti: number
  public specialOffersInfo: ko.Observable<SpecialOfferInfo[] | null>
  public hasDoubleProvDivs: ko.Observable<boolean>
  public hasQuaddieProvDivs: ko.Observable<boolean>
  public canShowInducements: ko.Observable<boolean>

  constructor() {
    this.meetingInformation = new ObservableMeetingInformation()
    this.availablePools = ko.observableArray<ObservablePool>()
    this.mergeDone = ko.observable(false).extend({ notify: 'always' })
    this.isMultiInProgress = false
    this.numberOfLegsInMulti = 0
    this.specialOffersInfo = ko.observable<SpecialOfferInfo[]>(null)
    this.hasDoubleProvDivs = ko.observable(false)
    this.hasQuaddieProvDivs = ko.observable(false)
    this.canShowInducements = ko.observable(false)
  }

  public addPoolTotal(poolTotal: ObservablePool) {
    Guard.notNull(poolTotal)
    this.availablePools.push(poolTotal)
  }

  public merge(other: IObservablePage) {
    this.pageType = other.pageType
    this.meetingInformation.merge(other.meetingInformation)
    this.availablePools(other.availablePools())
    this.isMultiInProgress = other.isMultiInProgress
    this.numberOfLegsInMulti = other.numberOfLegsInMulti
    this.specialOffersInfo(other.specialOffersInfo())
    this.hasDoubleProvDivs(other.hasDoubleProvDivs())
    this.hasQuaddieProvDivs(other.hasQuaddieProvDivs())
    this.canShowInducements(other.canShowInducements())
  }

  public addRace(race: ObservableRace) {
    Guard.notNull(race)
    this.meetingInformation.races.push(race)
  }
}
