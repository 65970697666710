import React from 'react'
import { useQuery } from 'react-query'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  FallBackBanner,
  LinkWrapper,
  BannerContainer as Container,
  CarouselWrapper,
  BannerPicture,
} from './Components'
import { filterBannersForArea, mapApiBanner } from './helpers'
import { BannerArea } from './types'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const Banner: React.FC<{ areaName: BannerArea }> = React.memo(({ areaName }) => {
  const { accountNumber } = useObservableImmutable(userAccountState$, ['accountNumber'])

  const { isLoading, error, data } = useQuery(
    [queryKeys.banner],
    () => fetch('/api/contentful/banners', { credentials: 'same-origin' }).then(res => res.json()),
    {
      refetchInterval: 1000 * 60 * 2, // 2min
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    }
  )

  if (isLoading || accountNumber === undefined) {
    return <Container areaName={areaName} />
  }

  const hasData = data && data.length > 0
  const banners = hasData
    ? filterBannersForArea({ areaName, accountNumber, data }).map(mapApiBanner)
    : []

  if (error || banners.length === 0)
    return (
      <Container areaName={areaName}>
        <FallBackBanner areaName={areaName} />
      </Container>
    )

  return (
    <Container areaName={areaName}>
      <CarouselWrapper key={accountNumber}>
        {banners.map((banner, index) => (
          <LinkWrapper
            key={`${banner.image}_${index}`}
            url={areaName === 'Desktop' ? banner.desktopUrl : banner.url}
            internalTrackingCode={banner.internalTrackingCode}
            abGroupingTokenPresent={banner.abGroupingTokenPresent}
            analyticsData={{
              bannerName: banner.name,
              internalTrackingCode: banner.internalTrackingCode,
              accountNumber: accountNumber,
              bannerLocation: areaName,
              bannerPosition: index,
            }}
          >
            <BannerPicture image={banner.image} imageWide={banner.imageWide} areaName={areaName} />
          </LinkWrapper>
        ))}
      </CarouselWrapper>
    </Container>
  )
})
