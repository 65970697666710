import React from 'react'
import { dayjs } from '@mobi/utils'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { dayNamesOrderedByWeekStart } from '../utils'
import { DaysOfTheWeekStyled } from '../DatePicker.styles'

interface AbbreviationsProps extends React.HTMLAttributes<HTMLTableRowElement> {
  weekStart: number
}

export const Abbreviations: React.FunctionComponent<AbbreviationsProps> = ({
  weekStart,
  ...props
}) => {
  return (
    <Grid {...props}>
      {dayNamesOrderedByWeekStart(dayjs.weekdaysShort(), weekStart).map((day: string) => (
        <GridCell key={day} width='auto' align='center'>
          <DaysOfTheWeekStyled>{day}</DaysOfTheWeekStyled>
        </GridCell>
      ))}
    </Grid>
  )
}
