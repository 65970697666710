import { keyframes } from '@emotion/react'

export const spin = keyframes({
  from: {
    transform: 'rotateZ(0deg)',
  },
  to: {
    transform: 'rotateZ(360deg)',
  },
})
