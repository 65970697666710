import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IResultsViewModel } from './IResultsViewModel'
import { IResultsFieldViewModel } from './IResultsFieldViewModel'
import resultsViewTemplate from './results.view.tpl.html'
import fieldViewTemplate from './results.field.view.tpl.html'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'

export function register(): void {
  if (!ko.components.isRegistered('results-view')) {
    ko.components.register('results-view', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableResultsPage
        }): IResultsViewModel => {
          const viewModel = container.get<IResultsViewModel>('IResultsViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: resultsViewTemplate,
    })
  }
  if (!ko.components.isRegistered('results-field-view')) {
    ko.components.register('results-field-view', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableResultsPage
        }): IResultsFieldViewModel => {
          const viewModel = container.get<IResultsFieldViewModel>('IResultsFieldViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: fieldViewTemplate,
    })
  }
}
