import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { fetchTakeABreakDetails, TakeABreakResponse } from '@core/Areas/Account/TakeABreak/api'
import { useLogon } from '@core/Utils/hooks'

export function useTakeABreak() {
  const { accountNumber } = useLogon()

  const query = useQuery<TakeABreakResponse, unknown>(
    [queryKeys.takeABreak, accountNumber],
    fetchTakeABreakDetails,
    {
      cacheTime: accountNumber ? 300000 : 0,
      enabled: !!accountNumber,
    }
  )

  return {
    data: query.data as TakeABreakResponse,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    isSuccess: query.isSuccess,
  }
}
