import { RacePromotions } from '@core/Areas/Racing/Hooks/RacePromotion/types'

export async function loadPromotions(): Promise<RacePromotions> {
  const response = await fetch('/api/contentful/race-card-promotion')

  if (response.ok) {
    return (await response.json()) as RacePromotions
  }

  throw new Error('Could not fetch race card promotions')
}
