import { store } from '@core/Store'
import { setIsMuted } from '@core/Areas/SkyVideoPlayer/Store'

export * from './useFullscreen'
export * from './findAndStartChildVideoElement'

export { useLogout } from './useLogout'

export const handleVolumeChange = ({ target }: Event) => {
  if (!target) {
    return
  }

  const videoEl = target as HTMLVideoElement
  if (videoEl.volume < 0.1 || videoEl.muted) {
    store.dispatch(setIsMuted(true))
  } else {
    store.dispatch(setIsMuted(false))
  }
}
