import React from 'react'
import { state$ as commandState$, BettingV2CommandStateRecord } from '../../Commands/driver'
import { observeImmutable } from '@core/Components/HOCs'
import {
  FixedFlucsContainer,
  FixedFlucsDividend,
  FixedFlucsDividendLabel,
  FixedFlucsSeperator,
  FixedFlucsChevron,
} from './FixedFlucs.styles'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { getDividendChange } from '@core/Components/Text/utils'
import { toOdds } from '@core/Utils/odds/odds'

interface FixedFlucsWrapperComponentProps {
  showFixedFlucs: boolean
  starter: FixedFlucsStarterProps
}

export interface FixedFlucsStarterProps {
  hasFixedOdds: boolean
  isScratched: boolean
  fixedOddsStarterInfo: FixedOddsStarterInfo
}

export interface FixedOddsStarterInfo {
  isScratched: boolean
  fixedFlucs: FixedFlucsDividendProps
}

export interface FixedFlucsDividendProps {
  winOpeningDividend: number
  winHighDividend: number
  winLowDividend: number
  winDividends: number[]
}

export const FixedFlucs = observeImmutable<BettingV2CommandStateRecord, FixedFlucsStarterProps>(
  commandState$,
  ({ record, ...props }) => {
    const { showFixedFlucs } = record

    return <FixedFlucsWrapperComponent showFixedFlucs={showFixedFlucs} starter={props} />
  }
)

export function FixedFlucsWrapperComponent({
  showFixedFlucs,
  starter,
}: FixedFlucsWrapperComponentProps): JSX.Element | null {
  const { hasFixedOdds, fixedOddsStarterInfo, isScratched } = starter

  if (
    !hasFixedOdds ||
    isScratched ||
    !fixedOddsStarterInfo ||
    !fixedOddsStarterInfo.fixedFlucs ||
    fixedOddsStarterInfo.isScratched ||
    !fixedOddsStarterInfo.fixedFlucs
  ) {
    return null
  }

  return showFixedFlucs ? <FixedFlucsComponent {...fixedOddsStarterInfo.fixedFlucs} /> : null
}

export function FixedFlucsPointDividend({
  dividendPoints: dividendPoints,
  dividendIndex: dividendIndex,
}: {
  dividendPoints: number[]
  dividendIndex: number
}): JSX.Element {
  const dividend = dividendPoints[dividendIndex]
  const previousDividend = dividendPoints[dividendIndex + 1]

  const dividendChange = getDividendChange(dividend, previousDividend)

  const displayData =
    dividendIndex === 0 || dividendIndex + 1 > dividendPoints.length ? '-' : toOdds(dividend)

  return (
    <FixedFlucsDividend
      dividendChange={dividendChange}
      data-tid-dividend-change={dividendChange}
      data-testid={`dividend-index-${dividendIndex}`}
      aria-label={dividendChange}
    >
      {displayData}
    </FixedFlucsDividend>
  )
}

export function FixedFlucsComponent({
  winOpeningDividend: openingDividend,
  winHighDividend: highDividend,
  winLowDividend: lowDividend,
  winDividends: dividendPoints,
}: FixedFlucsDividendProps) {
  const lowDividendDisplay = dividendPoints.length <= 1 ? '-' : toOdds(lowDividend)
  const highDividendDisplay = dividendPoints.length <= 1 ? '-' : toOdds(highDividend)

  return (
    <FixedFlucsContainer data-testid='fixed-flucs-container'>
      <Grid>
        <GridCell valign='bottom' align='left'>
          <FixedFlucsDividendLabel>Open</FixedFlucsDividendLabel>
          <FixedFlucsChevron />
          <FixedFlucsDividend data-testid='opening-dividend'>
            {toOdds(openingDividend)}
          </FixedFlucsDividend>
        </GridCell>

        <GridCell width='15%' valign='bottom' align='center'>
          <FixedFlucsPointDividend
            dividendPoints={dividendPoints}
            dividendIndex={3}
          ></FixedFlucsPointDividend>
        </GridCell>

        <GridCell width='15%' valign='bottom' align='center'>
          <FixedFlucsDividendLabel>Latest 3</FixedFlucsDividendLabel>
          <FixedFlucsPointDividend
            dividendPoints={dividendPoints}
            dividendIndex={2}
          ></FixedFlucsPointDividend>
        </GridCell>

        <GridCell width='15%' valign='bottom' align='center'>
          <FixedFlucsPointDividend
            dividendPoints={dividendPoints}
            dividendIndex={1}
          ></FixedFlucsPointDividend>
        </GridCell>

        <GridCell width='15%' valign='middle' align='right'>
          <FixedFlucsSeperator>&nbsp;</FixedFlucsSeperator>
        </GridCell>

        <GridCell width='10%' align='right' valign='bottom'>
          <FixedFlucsDividendLabel>Low</FixedFlucsDividendLabel>
          <FixedFlucsDividend data-testid='low-dividend'>{lowDividendDisplay}</FixedFlucsDividend>
        </GridCell>

        <GridCell width='15%' align='right' valign='bottom'>
          <FixedFlucsDividendLabel>High</FixedFlucsDividendLabel>
          <FixedFlucsDividend data-testid='high-dividend'>{highDividendDisplay}</FixedFlucsDividend>
        </GridCell>
      </Grid>
    </FixedFlucsContainer>
  )
}
