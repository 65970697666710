import {
  FixedOddsMarketCodes,
  MarketDataTransferObject,
  RacePageDataTransferObject,
} from '@mobi/api-types'
import { store } from '@core/Store'
import { createBetSelection } from './createBetSelection'
import { RaceCardState, setSelectedProposition } from '@core/Areas/RaceCard/Store'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

export const betWithSelection = (
  type: 'quickbet' | 'betslip',
  markets: MarketDataTransferObject[],
  selection: RaceCardState['selectedProposition'],
  race: RacePageDataTransferObject
): void => {
  if (!selection) {
    return
  }

  const [selectedMarket, selectedProposition] = selection
  const market = markets.find(({ MarketCode }) => MarketCode === selectedMarket)

  if (!market) {
    clearFobMarketSelection()
    return
  }

  const proposition = market.Propositions.find(
    ({ PropositionSequence }) => PropositionSequence === selectedProposition
  )

  if (!proposition) {
    clearFobMarketSelection()
    return
  }

  const betSelection = createBetSelection({ market, proposition, raceData: race })

  if (type === 'quickbet') {
    handleBetSelection({
      location: 'Quickbet',
      selection: betSelection,
      options: {
        quickbetProps: {
          shouldShowSuperPicks: market.MarketCode === FixedOddsMarketCodes.Concession,
        },
      },
    })

    return
  }

  if (type === 'betslip') {
    handleBetSelection({ location: 'Betslip', selection: betSelection })
    clearFobMarketSelection()
  }
}

// Local helpers

const clearFobMarketSelection = () => {
  store.dispatch(setSelectedProposition(null))
}
