import React from 'react'
import { state$, SetAccountDetails, GetAccountDetailsRes } from '../driver'
import { get } from '@classic/Foundation/Services/ApiService'
import { useObservableImmutable } from '@core/Utils/hooks'
import {
  UpdateAccountBalance,
  HasLoggedOut,
  state$ as userAccountState$,
} from '@core/State/UserAccount/userAccountDriver'
import { StateMap } from 'typings/immutable'

const enum LocalConstants {
  TTL_ACCOUNT_DETAILS = 2_000,
}

export const useGetAccountDetails = (
  shouldFetch: boolean
): StateMap<GetAccountDetailsRes> | undefined => {
  const { accountDetails } = useObservableImmutable(state$, ['accountDetails'])
  const { isLoggedIn } = useObservableImmutable(userAccountState$, ['isLoggedIn'])
  const currentlyLoadingAccountRef = React.useRef<boolean>(false)

  React.useEffect(() => {
    if (
      !isLoggedIn ||
      !shouldFetch ||
      currentlyLoadingAccountRef.current ||
      (accountDetails &&
        Date.now() - accountDetails.get('_lastUpdate') < LocalConstants.TTL_ACCOUNT_DETAILS)
    ) {
      return
    }
    currentlyLoadingAccountRef.current = true

    get<GetAccountDetailsRes>({ url: 'api/account/detailsformenu' })
      .then(accountDetailsRes => {
        if (accountDetailsRes.accountNumber === 0) {
          HasLoggedOut()
          return
        }

        const balanceCleaned = (accountDetailsRes.balance || '').replace('$', '').replace(/,/g, '')
        UpdateAccountBalance({ balance: balanceCleaned })
        SetAccountDetails({ ...accountDetailsRes })
      })
      .finally(() => {
        currentlyLoadingAccountRef.current = false
      })
  }, [shouldFetch, accountDetails, isLoggedIn])

  return accountDetails
}
