import { type PaymentMethods } from '@mobi/component-library/Deposit/types'
import { getFromLocalStorage, removeFromLocalStorage, setInLocalStorage } from '@mobi/utils'

const TOOL_TIP_LOCAL_STORAGE_KEY = 'tooltips_track_seen'

type LegacyLastUsedPaymentMethod = [
  paymentMethod: PaymentMethods | 'NoneOrUnknown',
  remove: VoidFunction,
]

/**
 * Get and parse Last Payment Method written to local storage by legacy Deposit
 * Note that ExistingPaymentMethod and NewPaymentMethod are converted to CreditCard
 * @deprecate
 */
export const tryGetLegacyLastUsedPaymentMethod = (
  accountNumber: number
): LegacyLastUsedPaymentMethod => {
  const LegacyPaymentMethods = [
    'ExistingPaymentMethod',
    'NewCreditCard',
    'PayPal',
    'ApplePay',
    'GooglePay',
  ]
  const key = `last-deposit-method/${accountNumber}`
  const remove = () => removeFromLocalStorage(key)
  const lastPayment = getFromLocalStorage(key)
  const paymentMethod = LegacyPaymentMethods.find(paymentMethod => lastPayment === paymentMethod)

  if (!paymentMethod) {
    return ['NoneOrUnknown', remove]
  }
  if (paymentMethod === 'ExistingPaymentMethod' || paymentMethod === 'NewCreditCard') {
    return ['CreditCard', remove]
  }
  return [paymentMethod as PaymentMethods, remove]
}

export function hasTooltipBeenSeen(contextKey: string): boolean {
  const trackedData = getTooltipTrackedData()
  if (!trackedData) return false
  return trackedData[contextKey] || false
}

export function setTooltipSeen(contextKey: string): void {
  const trackedData = getTooltipTrackedData() || {}
  trackedData[contextKey] = true
  setInLocalStorage(TOOL_TIP_LOCAL_STORAGE_KEY, JSON.stringify(trackedData))
}

// =============
// Local Helpers
// =============

function getTooltipTrackedData(): Record<string, boolean> | null {
  const store = getFromLocalStorage(TOOL_TIP_LOCAL_STORAGE_KEY)
  if (!store) return null

  const trackedData = JSON.parse(store)
  if (typeof trackedData !== 'object') return null

  return trackedData
}
