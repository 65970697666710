import React from 'react'

import {
  MysteryProposeAcceptor,
  MysteryProposeBet,
  MysteryProposeBetLine,
} from '@core/Data/betting'
import { ProductCode } from '@core/Data/Betting/selections'

import { BetContainerStyled, BetLineContainerStyled } from '../MysteryBetPage.styles'

interface ProposedMysteryBetProps {
  bet: MysteryProposeBet
}

export const ProposedMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => {
  switch (bet.productCode) {
    case ProductCode[ProductCode.Win]:
    case ProductCode[ProductCode.Place]:
    case ProductCode[ProductCode.WinPlace]:
      return (
        <BetContainerStyled>
          <ProposedWinPlaceMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.Quinella]:
      return (
        <BetContainerStyled>
          <ProposedQuinellaMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.Exacta]:
      return (
        <BetContainerStyled>
          <ProposedExactaMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.Trifecta]:
      return (
        <BetContainerStyled>
          <ProposedTrifectaMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.Combo]:
      return (
        <BetContainerStyled>
          <ProposedComboMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.First4]:
      return (
        <BetContainerStyled>
          <ProposedFirst4MysteryBet bet={bet} />
        </BetContainerStyled>
      )
    case ProductCode[ProductCode.Quaddie]:
      return (
        <BetContainerStyled>
          <ProposedQuaddieMysteryBet bet={bet} />
        </BetContainerStyled>
      )
    default:
      return <></>
  }
}

const ProposedWinPlaceMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => {
  // Win/place bets will have 2 bet lines with 1 leg each, but the acceptors in each bet line will always be the same.
  // So we can just output the acceptors from the first leg that we find. As with the other components here, if there
  // are more than 3 acceptors then we just display the numbers, rather than the names.
  const acceptors = bet.betLines[0].legs[0].acceptors
  return (
    <tbody data-testid='proposed-winplace-mystery'>
      {acceptors.length <= 3 ? (
        acceptors.map(acceptor => (
          <BetLineContainerStyled key={acceptor.name}>
            <td></td>
            <td style={{ width: '100%' }}>{getAcceptorText([acceptor], true)}</td>
          </BetLineContainerStyled>
        ))
      ) : (
        <BetLineContainerStyled>
          <td></td>
          <td style={{ width: '100%' }}>{bet.betLines[0].selections}</td>
        </BetLineContainerStyled>
      )}
    </tbody>
  )
}

const ProposedQuinellaMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => {
  const betLine = bet.betLines[0]
  const firstLeg = betLine.legs[0]
  return (
    <tbody data-testid='proposed-quinella-mystery'>
      {betLine.legs.length === 1 &&
        firstLeg.acceptors.length <= 3 &&
        firstLeg.acceptors.map(acceptor => (
          <BetLineContainerStyled key={acceptor.name}>
            <td></td>
            <td style={{ width: '100%' }}>{getAcceptorText([acceptor], true)}</td>
          </BetLineContainerStyled>
        ))}
      {betLine.legs.length === 1 && firstLeg.acceptors.length >= 4 && (
        <BetLineContainerStyled>
          <td></td>
          <td style={{ width: '100%' }}>{betLine.selections}</td>
        </BetLineContainerStyled>
      )}
      {betLine.legs.length === 2 &&
        firstLeg.acceptors.length === 1 &&
        betLine.legs[1].acceptors.length === 2 &&
        betLine.legs.map((leg, legIndex) => (
          <BetLineContainerStyled key={leg.legNumber}>
            <td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
              {legIndex === 0 ? 'Leg-In' : 'With'}
            </td>
            <td style={{ width: '100%' }}>
              {leg.acceptors.length === 1 ? (
                getAcceptorText(leg.acceptors, true)
              ) : (
                <>
                  {getAcceptorText([leg.acceptors[0]], true)}
                  <br />
                  {getAcceptorText([leg.acceptors[1]], true)}
                </>
              )}
            </td>
          </BetLineContainerStyled>
        ))}
    </tbody>
  )
}

const ProposedExactaMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => {
  const betLine = bet.betLines[0]
  return (
    <tbody data-testid='proposed-exacta-mystery'>
      {betLine.legs.length === 2 &&
        !betLine.isAllways &&
        betLine.legs.map(leg => (
          <BetLineContainerStyled key={leg.legNumber}>
            <td>{toNth(leg.legNumber)}</td>
            <td style={{ width: '100%' }}>{getAcceptorText(leg.acceptors, true)}</td>
          </BetLineContainerStyled>
        ))}
      {betLine.legs.length === 1 &&
        betLine.isAllways &&
        betLine.legs[0].acceptors.length <= 3 &&
        betLine.legs[0].acceptors.map(acceptor => (
          <BetLineContainerStyled key={acceptor.name}>
            <td></td>
            <td style={{ width: '100%' }}>{getAcceptorText([acceptor], true)}</td>
          </BetLineContainerStyled>
        ))}
      {betLine.legs.length === 1 && betLine.isAllways && betLine.legs[0].acceptors.length >= 4 && (
        <BetLineContainerStyled>
          <td></td>
          <td style={{ width: '100%' }}>{betLine.selections}</td>
        </BetLineContainerStyled>
      )}
    </tbody>
  )
}

const ProposedComboMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => {
  return (
    <tbody data-testid='proposed-combo-mystery'>
      {bet.betLines.map((betLine, betLineIndex) => {
        const previousAcceptors = bet.betLines
          .slice(0, betLineIndex)
          .flatMap(x => x.legs[0].acceptors.map(y => y.name))
        const selections = betLine.selections
        const firstLeg = betLine.legs[0]
        const runnerName = firstLeg.acceptors
          .map(x => x.name)
          .filter(x => !previousAcceptors.includes(x))[0]
        return (
          <BetLineContainerStyled key={betLineIndex}>
            <td>
              {betLine.poolCode === ProductCode[ProductCode.Win]
                ? betLine.poolCode
                : `${betLine.poolCode} - ${isAllways(betLine) ? 'Allways' : 'In Order'}`}
            </td>
            <td>
              {betLine.legs.length === 1 && `${selections}${runnerName ? ' - ' + runnerName : ''}`}
              {betLine.legs.length > 1 &&
                `${selections} - ${betLine.legs[betLine.legs.length - 1].acceptors[0].name}`}
            </td>
            <td>
              {betLine.poolCode === ProductCode[ProductCode.Win] && `Div x${betLine.dividend}`}
            </td>
          </BetLineContainerStyled>
        )
      })}
    </tbody>
  )
}

const ProposedTrifectaMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element | null => {
  const betLine = bet.betLines[0]
  const firstLeg = betLine.legs[0]
  if (isAllways(betLine)) {
    return (
      <tbody data-testid='proposed-trifecta-mystery'>
        {firstLeg.acceptors.length <= 3 &&
          betLine.legs[0].acceptors.map(acceptor => (
            <BetLineContainerStyled key={acceptor.name}>
              <td></td>
              <td style={{ width: '100%' }}>{`${acceptor.number}${
                acceptor.name ? ' - ' + acceptor.name : ''
              }`}</td>
            </BetLineContainerStyled>
          ))}
        {firstLeg.acceptors.length >= 4 && (
          <BetLineContainerStyled>
            <td></td>
            <td style={{ width: '100%' }}>{betLine.selections}</td>
          </BetLineContainerStyled>
        )}
      </tbody>
    )
  }

  return (
    <tbody data-testid='proposed-trifecta-mystery'>
      {betLine.legs.map(leg => (
        <BetLineContainerStyled key={leg.legNumber}>
          <td>{toNth(leg.legNumber)}</td>
          <td style={{ width: '90%' }}>{getAcceptorText(leg.acceptors, true)}</td>
        </BetLineContainerStyled>
      ))}
    </tbody>
  )
}

const ProposedFirst4MysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => (
  <tbody data-testid='proposed-first4-mystery'>
    {bet.betLines[0].legs.map(leg => (
      <BetLineContainerStyled key={leg.legNumber}>
        <td>{!bet.betLines[0].isAllways && toNth(leg.legNumber)}</td>
        <td style={{ width: '100%' }}>
          {getAcceptorText(leg.acceptors, true)}
          {bet.betLines[0].isAllways && ' (Allways)'}
        </td>
      </BetLineContainerStyled>
    ))}
  </tbody>
)

const ProposedQuaddieMysteryBet = ({ bet }: ProposedMysteryBetProps): JSX.Element => (
  <tbody data-testid='proposed-quaddie-mystery'>
    {bet.betLines[0].legs.map(leg => (
      <BetLineContainerStyled key={leg.legNumber}>
        <td>Race {leg.raceNumber}</td>
        <td style={{ width: '80%' }}>{getAcceptorText(leg.acceptors, true)}</td>
      </BetLineContainerStyled>
    ))}
  </tbody>
)

function getAcceptorText(
  acceptors: MysteryProposeAcceptor[],
  hasDotsInsteadOfSlashes: boolean
): string {
  const text =
    acceptors.length === 1 && acceptors[0].name
      ? `${acceptors[0].number} - ${acceptors[0].name}`
      : acceptors.map(x => x.number).join(hasDotsInsteadOfSlashes ? '.' : '/')
  return text
}

export function toNth(n: number): string {
  if (n > 20 || n < 0) {
    throw new Error('Unexpected number supplied. Choose a number between 0 and 20.')
  }

  if (n === 1) {
    return '1st'
  }

  if (n === 2) {
    return '2nd'
  }

  if (n === 3) {
    return '3rd'
  }

  return `${n}th`
}

function isAllways(betLine: MysteryProposeBetLine) {
  const isAllways = betLine.isAllways || betLine.poolCode === ProductCode[ProductCode.Quinella]
  return isAllways
}
