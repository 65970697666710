import { get } from '@classic/Foundation/Services/ApiService'
import type { RaceListResponse } from './types'

export const getMeetingRaces = (data: RaceUrlParams) =>
  get<RaceListResponse>({ url: buildRacesUri(data) })

// Local Helpers

function buildRacesUri({ date, meetingId, compSeq, product }: RaceUrlParams) {
  let url = `/api/races?date=${date}`
  if (meetingId) {
    url += '&meetingId=' + meetingId.toUpperCase()
  }
  if (compSeq !== undefined) {
    url += `&compSeq=${compSeq}`
  }
  if (product !== undefined) {
    url += `&product=${product}`
  }
  return url
}

// Types

type RaceUrlParams = {
  date: string
  meetingId?: string
  compSeq?: string
  product?: string
}
