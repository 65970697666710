import container from '../../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { registerComponentWithParams } from '@classic/AppUtils/Framework/Components/koComponentRegistration'
import { StartersListViewModel } from './StartersListViewModel'
import template from './starters.list.tpl.html'
export function register(): void {
  registerComponentWithParams<StartersListViewModel>({
    componentName: 'starters-list',
    template,
    container: container,
    resolver: (container: interfaces.Container) =>
      container.get<StartersListViewModel>('StartersListViewModel'),
  })
}
