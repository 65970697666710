import React from 'react'
import ReactDOM from 'react-dom'

import { layering } from '@mobi/component-library/Theme/Common'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { BettingDrawerStyled, MAX_DRAWER_CONTENT_WIDTH } from './BettingDrawer.styles'

interface ReviewSelectionsDrawerProps {
  open: boolean
  canReviewSelections: boolean
  onEditBet: () => void
  onReviewSelections: () => void
}

export function ReviewSelectionsDrawer({
  open,
  canReviewSelections,
  onEditBet,
  onReviewSelections,
}: ReviewSelectionsDrawerProps): JSX.Element {
  return ReactDOM.createPortal(
    <BettingDrawerStyled open={open} zIndexOverride={layering.overlayLow}>
      <Grid margin='auto' maxWidth={MAX_DRAWER_CONTENT_WIDTH}>
        <GridRow>
          <GridCell padding='1rem 0.5rem 1rem 1rem'>
            <ButtonBlock type='button' color='secondary' onClick={onEditBet}>
              Edit Bet
            </ButtonBlock>
          </GridCell>
          <GridCell padding='1rem 1rem 1rem 0.5rem'>
            <ButtonBlock
              type='button'
              color='primary'
              disabled={!canReviewSelections}
              onClick={onReviewSelections}
            >
              Review Selections
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
    </BettingDrawerStyled>,
    document.body
  )
}
