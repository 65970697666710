import React from 'react'
import Decimal from 'decimal.js'
import type { ToteSelection } from '@mobi/betslip/types'
import {
  isAllUpSelection,
  isNoveltyBetType,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { GridCell, Grid, GridRow } from '@mobi/component-library/Common/Grid'
import {
  BetInvestmentGroupStyled,
  InvestmentTextStyled,
  InvestmentAmountStyled,
} from './BetInvestment.styles'
import { BetslipItem } from '../../driver'
import { InvestmentType } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import { EditBetslipItemArgs } from './BetInvestment'

interface ToteInvestmentProps {
  item: BetslipItem
  isEditable: boolean
  editBetslipItem: ({ item, investmentType, isEditable }: EditBetslipItemArgs) => void
}

export function ToteInvestment({ item, isEditable, editBetslipItem }: ToteInvestmentProps) {
  const { investment, selection, shouldAllowPlaceInvestment } = item
  const shouldDisplayFlexi = isToteSelection(selection) && isNoveltyBetType(selection.betType)
  const shouldDisplayNumberOfCombinations = isAllUpSelection(selection)

  const toteBetType = (item.selection as ToteSelection).betType
  const hasSecondaryDisplay =
    shouldAllowPlaceInvestment || shouldDisplayFlexi || shouldDisplayNumberOfCombinations

  let flexi
  if (shouldDisplayFlexi) {
    flexi = item.numberOfCombinations
      ? Math.floor(((investment.win.value * 100) / item.numberOfCombinations) * 100) / 100
      : 0
  }

  const handleEditBetslipWin = () =>
    editBetslipItem({
      item,
      investmentType: InvestmentType.Win,
      isEditable,
      items: null,
    })
  const handleEditBetslipPlace = () =>
    editBetslipItem({
      item,
      investmentType: InvestmentType.Place,
      isEditable,
      items: null,
    })

  return (
    <GridRow>
      <GridCell width={hasSecondaryDisplay ? '49%' : 'auto'}>
        <BetInvestmentGroupStyled
          isEditable={isEditable}
          onClick={handleEditBetslipWin}
          data-tid-investment-w=''
        >
          <Grid>
            <GridCell>
              <InvestmentTextStyled isEditable={isEditable}>
                {toteBetType === 'Win & Place' ? 'W' : 'Spend'}
              </InvestmentTextStyled>
            </GridCell>
            <GridCell align='right'>
              {investment !== undefined && (
                <InvestmentAmountStyled
                  amount={new Decimal(investment.win.value || 0).toNumber()}
                  isEditable={isEditable}
                />
              )}
            </GridCell>
          </Grid>
        </BetInvestmentGroupStyled>
      </GridCell>

      {hasSecondaryDisplay && <GridCell width='2%' />}

      {shouldAllowPlaceInvestment && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled
            isEditable={isEditable}
            onClick={handleEditBetslipPlace}
            data-tid-investment-p=''
          >
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>P</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {investment !== undefined && (
                  <InvestmentAmountStyled
                    amount={new Decimal(investment.place.value || 0).toNumber()}
                    isEditable={isEditable}
                  />
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}

      {shouldDisplayFlexi && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled
            isEditable={isEditable}
            onClick={handleEditBetslipWin}
            data-tid-investment-flexi=''
          >
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>Flexi</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {flexi !== undefined && (
                  <InvestmentTextStyled isEditable={isEditable}>{`${flexi}%`}</InvestmentTextStyled>
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}

      {shouldDisplayNumberOfCombinations && (
        <GridCell width='49%'>
          <BetInvestmentGroupStyled
            isEditable={isEditable}
            onClick={handleEditBetslipWin}
            data-tid-investment-combos=''
          >
            <Grid>
              <GridCell>
                <InvestmentTextStyled isEditable={isEditable}>Combos</InvestmentTextStyled>
              </GridCell>
              <GridCell align='right'>
                {item.numberOfCombinations !== undefined && (
                  <InvestmentTextStyled isEditable={isEditable}>
                    {`${item.numberOfCombinations}`}
                  </InvestmentTextStyled>
                )}
              </GridCell>
            </Grid>
          </BetInvestmentGroupStyled>
        </GridCell>
      )}
    </GridRow>
  )
}
