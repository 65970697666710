import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { hexColors } from '@mobi/settings'

export const BetslipButtonBadgeStyled = styled.div({
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  right: 0,
  height: '2.4rem',
  width: '2.4rem',
  overflow: 'hidden',
  transform: 'translate(-5%, 15%)',
  background: hexColors.freeSpeechRed,
  color: hexColors.white,
  border: `0.2rem ${hexColors.blueDiamond} solid`,
  borderRadius: '50%',
})

export type BadgeAnimation = 'up' | 'down' | null

export const BetslipButtonBadgeNumberStyled = styled.div<{
  animation: BadgeAnimation
}>(
  {
    position: 'relative',
    width: '2rem',
    height: '2rem',
    fontSize: '1.2rem',
    fontWeight: 600,
    letterSpacing: '-0.1rem',

    span: {
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '2rem',
      paddingRight: '0.1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  ({ animation }) => {
    if (!animation) return undefined

    const isUp = animation === 'up'
    return {
      '& span:first-of-type': {
        animation: `${isUp ? animateCountInDown : animateCountInUp} 0.4s ease-in forwards`,
      },
      '& span:last-of-type': {
        animation: `${isUp ? animatePrevOutDown : animatePrevOutUp} 0.4s ease-in forwards`,
      },
    }
  }
)

// Animations

const animateCountInUp = keyframes({
  from: {
    transform: 'translateY(-100%)',
  },
  to: {
    transform: 'translateY(0)',
  },
})
const animateCountInDown = keyframes({
  from: {
    transform: 'translateY(100%)',
  },
  to: {
    transform: 'translateY(0)',
  },
})

const animatePrevOutUp = keyframes({
  to: {
    transform: 'translateY(100%)',
  },
})
const animatePrevOutDown = keyframes({
  to: {
    transform: 'translateY(-100%)',
  },
})
