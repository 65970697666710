import React from 'react'
import type { FieldSummary } from '@core/Areas/RaceCardFixedOnly/Types/FieldSummary'
import {
  PropositionStrongFieldsStyled,
  PropositionSummaryResultsStyled,
  PropositionTrainerNameStyled,
  SelectionFormTableStyled,
} from '@core/Areas/RaceCardFixedOnly/RaceCardFixedOnly.styles'

type Props = {
  summary: FieldSummary
}
export const DogFieldSummaryResults: React.FC<Props> = ({ summary }) => {
  if (!summary) return null

  return (
    <PropositionSummaryResultsStyled>
      <SelectionFormTableStyled>
        <PropositionTrainerNameStyled>
          <PropositionStrongFieldsStyled>T:</PropositionStrongFieldsStyled>
          <span>{summary.trainerName?.toUpperCase()}</span>
        </PropositionTrainerNameStyled>
      </SelectionFormTableStyled>
    </PropositionSummaryResultsStyled>
  )
}
