import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Text } from '@core/Components/Text'
import { tap } from '@mobi/utils'
import { GapX } from '@core/Components/Gap'

type Props = {
  backgroundColor: string
  textColor: string
}

export const CompactPanelContainerStyled = styled.div<Props>(({ backgroundColor, textColor }) => ({
  backgroundColor,
  color: textColor,
  padding: '0 1.2rem',
}))

export const CompactPanelHeaderStyled = tap(
  styled(GapX)({
    alignItems: 'center',
    color: hexColors.black,
    display: 'flex',
    padding: '0.3rem 0',
  }),
  component => {
    component.defaultProps = {
      gutterSize: '0.9rem',
    }
  }
)

export const CompactPanelHeaderContentStyled = tap(
  styled(Text)({
    flex: '1 1 0%',
  }),
  component => {
    component.defaultProps = {
      as: 'div',
    }
  }
)

export const CompactPanelContent = tap(
  styled(Text)({
    lineHeight: 1.1818,
    padding: '0.2rem 0 0.9rem',
  }),
  component => {
    component.defaultProps = {
      as: 'div',
      size: 'sm',
    }
  }
)
