import type { BetSlipBetRequest, BetSlipResponse } from '@mobi/betslip/types/betting'

export function isBetslipBetRequest(value: unknown): value is BetSlipBetRequest {
  if ((value as BetSlipBetRequest).toteBets !== undefined) return true
  if ((value as BetSlipBetRequest).toteSportsBets !== undefined) return true
  return false
}

export function isBetslipResponses(value: unknown): value is BetSlipResponse[] {
  return Array.isArray(value) && (value[0] as BetSlipResponse).id !== undefined
}
