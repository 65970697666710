import { RaceStatus } from '../Hooks/useRaceList/types'

export const getPrettyRaceStatus = (raceStatus: RaceStatus, resultString: string): string => {
  switch (raceStatus) {
    case 'Open':
    case 'Pending': // tote
      return ''
    case 'Released':
    case 'Results':
      return `Results (${resultString})`
    case 'Interim':
      return `Interim (${resultString})`
    case 'Closed':
      return 'Closed'
    default:
      return raceStatus
  }
}
