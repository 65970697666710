import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const ChangePasswordContainerStyled = styled.section({
  padding: '0 2rem',
  margin: '1rem 0',
})

export const ChangePasswordTitleLabelStyled = styled.div({
  fontSize: '1.2rem',
  backgroundColor: hexColors.gainsboro,
  color: hexColors.dimGrey,
  margin: '1rem 0',
  padding: '0.75rem',
  display: 'block',
  textAlign: 'center',
})
