import React, { ButtonHTMLAttributes, type FC } from 'react'
import { ButtonWithIconStyled } from './Buttons.styles'

type Props = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  icon: JSX.Element
  color?: 'dark' | 'light'
}

export const ButtonWithIcon: FC<Props> = ({ onClick, icon, color = 'dark' }) => (
  <ButtonWithIconStyled onClick={onClick} color={color}>
    {icon}
  </ButtonWithIconStyled>
)
