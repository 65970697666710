import React from 'react'
import dayjs from 'dayjs'
import type { BetSlipItem, FobSportsSelection, SportsDetails } from '@mobi/betslip/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard, type LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiItem/Components/MultiLegCheckbox'

export const SportBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
}> = ({ item, isMultiItem }) => {
  const { tournamentName, eventName, marketName, advertisedStartTime, marketId, urlPath } =
    item.selectionDetails as SportsDetails
  const { propositionName, winPrice } = item.selection as FobSportsSelection
  const linkInfo: LinkInfo | undefined =
    !isMultiItem && marketId && urlPath ? { urlPath, market: marketId.toString() } : undefined

  return (
    <>
      <BetCard.EventDetails
        heading={tournamentName}
        subHeading={eventName && eventName !== marketName ? eventName : ''}
        statusComponent={
          <CountdownBadge
            shouldUseExtendedCountdown
            advertisedStartTime={dayjs(advertisedStartTime).toDate()}
          />
        }
        iconComponent={<Icon size='2rem' type='tab' />}
        linkInfo={linkInfo}
      />

      <BetCard.SelectionInfo
        heading={propositionName}
        subHeading={marketName}
        price={winPrice}
        // TODO: Use real sports/team logo
        iconComponent={
          <div
            style={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%', background: 'red' }}
          />
        }
        linkInfo={linkInfo}
        rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
      />
    </>
  )
}
