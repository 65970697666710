import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ContactDetailsLink } from './ContactDetailsLink'

interface Props {
  accountNumber?: number
}

export const NoEmailAddress = ({ accountNumber }: Props): JSX.Element => {
  return (
    <NoticeBox
      role='alert'
      testId='no-email-addresses'
      noticeBoxType={NoticeBoxTypes.Error}
      title='No email addresses'
      hasBorder
    >
      <p>
        There are no email addresses on your account,{' '}
        <ContactDetailsLink accountNumber={accountNumber}>click here to add one</ContactDetailsLink>
      </p>
    </NoticeBox>
  )
}
