import Decimal from 'decimal.js'
import type { BetLegType, BetSpecialOffer } from '@mobi/betslip/types'

export function determineLegTypeFromInvestments(
  winInvestment: number,
  placeInvestment: number
): BetLegType {
  if (winInvestment > 0 && placeInvestment === 0) return 'W'
  if (winInvestment === 0 && placeInvestment > 0) return 'P'
  if (winInvestment > 0 && placeInvestment > 0) return 'WP'
  return 'W'
}

export function calculateBoosts(selectedSuperPickOffer: BetSpecialOffer | null) {
  let winBoost = 0
  let placeBoost = 0
  if (selectedSuperPickOffer) {
    const elementWithPriceIncrease = selectedSuperPickOffer.elements?.find(
      element => element.priceIncrease !== null
    )
    if (elementWithPriceIncrease) {
      const priceIncrease = elementWithPriceIncrease.priceIncrease
      winBoost = priceIncrease?.win ? new Decimal(priceIncrease.win).toNumber() : 0
      placeBoost = priceIncrease?.place ? new Decimal(priceIncrease.place).toNumber() : 0
    }
  }
  return [winBoost, placeBoost]
}
