import { BettingInformation } from '../Model/BettingInformation'
import { IBetTypeValidator } from './IBetTypeValidator'
import { ICheckBoxValidatorProcessorPipeline } from './ICheckBoxValidatorProcessorPipeline'
import CheckBoxValidatorProcessorPipeline from './CheckBoxValidatorProcessorPipeline'

export default class ExactaValidator implements IBetTypeValidator {
  constructor() {
    this.pipeline = new CheckBoxValidatorProcessorPipeline()
  }

  isValid(bettingContext: BettingInformation): boolean {
    return this.pipeline.validate(2, bettingContext)
  }

  private pipeline: ICheckBoxValidatorProcessorPipeline
}
