import styled from '@emotion/styled'
import colors from '@core/Settings/colors'

export const MultiItemStyled = styled('li')<{ hasFatalError: boolean }>(
  {
    marginBottom: '0.5rem',
    padding: '0.5rem 0',
    borderBottom: `0.1rem solid ${colors.betslip.item.border}`,
    color: colors.betslip.item.text,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  ({ hasFatalError }) =>
    hasFatalError && {
      backgroundColor: colors.betslip.item.disabled.background,
      borderBottom: 'none',
      paddingRight: '0.5rem',
      color: colors.betslip.item.disabled.text,
    }
)

export const ItemErrorTextStyled = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: colors.betslip.errors.betLeg.color,
})
