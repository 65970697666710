import { CSSProperties } from 'react'
import styled from '@emotion/styled'
import { calculateRem } from '@core/Utils/calculateRem/calculateRem'

/**
 * Silk sizes in pixels:
 *   Races: 35 x 42 -> Ratio: 5:6
 *   Trots: 36 x 36 -> Ratio: 1:1
 *   Dogs:  35 x 42 -> Ratio: 5:6
 *
 * We use the `large` size of the sprite and scale it down in order for them to look OK on retina devices.
 * The silks are displayed within a fixed size container span `size`.
 */

interface SilkImageStyledProps {
  sprite: {
    url: string
    width: number
    height: number
    xOffset: number
  }
  size: number
  padding?: number
  backgroundColor?: string
}

export const SilkImageFlexibleStyled = styled('span')<SilkImageStyledProps>(
  {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',

    '&::before, &::after': {
      content: '""',
      display: 'block',
    },
  },

  ({ sprite, size, padding = 0, backgroundColor = 'transparent' }) => {
    const { url, width, height, xOffset } = sprite

    const {
      width: ratioWidth,
      height: ratioHeight,
      backgroundPosition: ratioBackgroundPosition,
    } = calculateAspectRatioFit({
      srcWidth: width,
      srcHeight: height,
      maxWidth: size,
      maxHeight: size,
      xOffset,
      padding,
    })

    return {
      width: calculateRem(size),
      height: calculateRem(size),

      // The silk image sprite in the foreground
      '&::before': {
        position: 'relative',
        zIndex: 1,
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: ratioWidth,
        height: ratioHeight,
        backgroundPosition: ratioBackgroundPosition,
      },

      // The circle in the background
      '&::after': {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
        width: calculateRem(size),
        height: calculateRem(size),
        borderRadius: '50%',
        backgroundColor,
      },
    }
  }
)

// =============
// Local Helpers
// =============

interface CalculateAspectRatioFitArgs {
  srcWidth: number
  srcHeight: number
  maxWidth: number
  maxHeight: number
  xOffset: number
  padding?: number
}
function calculateAspectRatioFit({
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
  xOffset,
  padding = 0,
}: CalculateAspectRatioFitArgs): Pick<CSSProperties, 'width' | 'height' | 'backgroundPosition'> {
  const ratio = Math.min((maxWidth - padding) / srcWidth, (maxHeight - padding) / srcHeight)
  const newWidth = srcWidth * ratio
  const newHeight = srcHeight * ratio
  return {
    width: calculateRem(newWidth),
    height: calculateRem(newHeight),
    backgroundPosition: `${calculateRem(-newWidth * xOffset)} center`,
  }
}
