import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { hexColors } from '@mobi/settings'

export interface SpecialIndicatorProps {
  height?: string
  width?: string
  className?: string
  viewBox?: string
  themeName?: string
}

export const SpecialIndicatorStyled = styled('svg')<SpecialIndicatorProps>(
  ({ height, width, themeName }: SpecialIndicatorProps) => ({
    display: 'inline-block',
    fill: themeName === 'purple' ? hexColors.white : colors.specialIndicator.foreground,
    width: width || '2rem',
    height: height || '2rem',
    verticalAlign: 'middle',
  })
)
