import React, { Suspense } from 'react'
import { SvgComponents } from './Components'
import type { SvgLazyProps } from './types'

export const SvgLazy: React.FC<
  {
    name: keyof typeof SvgComponents
  } & SvgLazyProps
> = ({ name, color, ...dimensions }) => {
  const SvgComponent = SvgComponents[name]
  if (!SvgComponent) return null

  return (
    <Suspense fallback={null}>
      <SvgComponent color={color} {...dimensions} />
    </Suspense>
  )
}
