import styled from '@emotion/styled'
import { ButtonSelectionStyled } from '@mobi/component-library/Common/Buttons'
import { WinPlaceSelection } from '..'

export const WinPlaceSelectionButtonStyled = styled(ButtonSelectionStyled)({
  width: '5rem',
  minWidth: '5rem',
  height: '5rem',
})

export const WinPlaceSelectionButtonTitleStyled = styled('div')<{ isContentCentered: boolean }>(
  {
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    marginTop: '0.3rem',
    marginBottom: '0.4rem',
    fontWeight: 'normal',
  },
  ({ isContentCentered: isContentCentered = false }) => ({
    textAlign: isContentCentered ? 'center' : 'right',
  })
)

type WinPlaceSelectionFavouriteStyledProps = Pick<WinPlaceSelection, 'favouritePosition'>
export const WinPlaceSelectionFavouriteStyled = styled.span<WinPlaceSelectionFavouriteStyledProps>(
  {
    position: 'absolute',
    top: 0,
  },
  ({ favouritePosition = 'left' }) => ({
    [favouritePosition]: 0,
    transform: `translate(${favouritePosition === 'left' ? '-' : ''}40%,-40%)`,
  })
)
