import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFeature } from '@core/Utils/hooks/useFeature'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { useHistory } from 'react-router-dom'
import { KAMBI_ROOT_ROUTE } from '@core/Areas/Kambi/constants'
import { getNewSportsSetting } from '../Settings/Store/selectors'

const SportsLazyRoute = React.lazy(() => import('./Components/Main'))

/** NEW in-house FOB Sports */
export const SportsPage = () => {
  const isNewSportsFeatureSettingActive = useSelector(getNewSportsSetting)
  const isNewSportsFeatureActive = useFeature('FIXED_ODDS_SPORTS')
  const history = useHistory()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isNewSportsFeatureActive || !isNewSportsFeatureSettingActive) {
        history.push(`/${KAMBI_ROOT_ROUTE}/home`)
      }
    }, 6000)

    return () => clearTimeout(timeoutId)
  }, [isNewSportsFeatureActive, isNewSportsFeatureSettingActive])

  useBackNav()

  return (
    <React.Suspense fallback={null}>
      <SportsLazyRoute />
    </React.Suspense>
  )
}
