import React from 'react'
import styled from '@emotion/styled'

export const LoadingSpinner: React.FC<{ size?: string }> = ({ size = '4rem' }) => {
  return (
    <SvgStyled
      role='progressbar'
      aria-label='Loading'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height='auto'
      viewBox='0 0 64 64'
      fill='none'
    >
      <path
        d='M57 32C57 45.8071 45.8071 57 32 57C18.1929 57 7 45.8071 7 32C7 18.1929 18.1929 7 32 7C45.8071 7 57 18.1929 57 32Z'
        stroke='#ECDFF8'
        strokeWidth='14'
      />
      <path
        d='M9.30781 42.4912C7.5467 38.6819 6.77286 34.4906 7.05762 30.3036C7.34238 26.1166 8.67659 22.0687 10.9372 18.533C13.1979 14.9973 16.3123 12.0875 19.9932 10.072C23.6742 8.05646 27.8033 7 32 7'
        stroke='url(#paint0_linear_2095_41031)'
        strokeWidth='14'
        strokeLinecap='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2095_41031'
          x1='50.2526'
          y1='9.65403'
          x2='22.599'
          y2='62.9858'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#824DB1' />
          <stop offset='0.755208' stopColor='#B580E4' stopOpacity='0' />
          <stop offset='1' stopColor='#DABFF1' stopOpacity='0' />
        </linearGradient>
      </defs>
    </SvgStyled>
  )
}

// ======
// Styles
// ======

const SvgStyled = styled.svg({
  flexShrink: 0,
  aspectRatio: '1/1',
  animation: 'spin 1.2s cubic-bezier(0.45, 0.14, 0.6, 0.86) infinite',

  '@keyframes spin': { to: { transform: 'rotate(360deg)' } },
})
