import type { BettingType } from '@mobi/betslip/types'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'

export function isCampaignRedeemableWithBettingType(
  campaign: Campaign,
  bettingType: BettingType | null
): boolean {
  if (campaign.redeemableBy == null) {
    return false
  }

  switch (bettingType) {
    case 'fixed-odds-racing':
      return campaign.redeemableBy.includes('FobRacingBet')
    case 'fixed-odds-sports':
      return campaign.redeemableBy.includes('FobSportsBet')
    case 'tote-racing':
      return campaign.redeemableBy.includes('ToteRacingBet')
    case 'tote-sports-tipping':
      return campaign.redeemableBy.includes('ToteSportsBet')
    default:
      return false
  }
}
