import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { MarketCodesType } from '@mobi/api-types'
import type { BetType } from '@classic/Betting-v2/Model/Betting/BetType'

export const raceCardInitialState: RaceCardState = {
  currentBetType: null,
  currentRacePageQueryKey: null,
  toteSwapState: [[], null],
  selectedProposition: null,
}

const raceCardSlice = createSlice({
  name: 'race-card',
  initialState: raceCardInitialState,
  reducers: {
    /** Used exclusively to FixedOddsMarkets at present */
    setSelectedProposition(
      state,
      { payload }: PayloadAction<RaceCardState['selectedProposition']>
    ) {
      if (payload === null) {
        state.selectedProposition = null
        return
      }
      if (state.selectedProposition === null) {
        state.selectedProposition = payload
        return
      }

      const shouldToggleSelection =
        payload[0] === state.selectedProposition[0] && payload[1] === state.selectedProposition[1]

      state.selectedProposition = shouldToggleSelection ? null : payload
    },

    /** NOT primary source - tracked from BetTypeSelector -> StartersPageVM */
    setCurrentBetType(state, { payload }: PayloadAction<AvailableBetType['betType']>) {
      state.currentBetType = payload
    },

    /** Tote swapping via BetModifier for Insurance and Starting Price */
    setToteSwapOptions(state, { payload }: PayloadAction<ToteSwapOptions[]>) {
      const activeToteSwap = state.toteSwapState[1]
      state.toteSwapState = [
        payload,
        payload.some(opt => opt === activeToteSwap) ? activeToteSwap : null,
      ]
    },
    setActiveToteSwap(state, { payload }: PayloadAction<ToteSwapOptions | null>) {
      state.toteSwapState[1] = payload
      state.selectedProposition = null
    },

    /** Captured race page react query key for deeply nested knockout */
    setRacePageReactQueryKey(state, { payload }: PayloadAction<(string | number)[] | null>) {
      state.currentRacePageQueryKey = payload
    },
  },
})

export const {
  setSelectedProposition,
  setCurrentBetType,
  setActiveToteSwap,
  setToteSwapOptions,
  setRacePageReactQueryKey,
} = raceCardSlice.actions

export default raceCardSlice.reducer

// =====
// Types
// =====

export interface RaceCardState {
  currentBetType: AvailableBetType['betType'] | null
  currentRacePageQueryKey: (string | number)[] | null
  toteSwapState: [availableOptions: ToteSwapOptions[], activeOption: ToteSwapOptions | null]
  selectedProposition: [marketCode: MarketCodesType, propositionSeq: number] | null
}

type AvailableBetType = { betType: BetType; name: string; hasJackpot: boolean }

type ToteSwapOptions = Extract<MarketCodesType, 'CON' | 'SP'>
