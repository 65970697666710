import React from 'react'

import { DetailsTextWrapper, DetailsWrapper } from '../TakeABreak.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { dayjs } from '@mobi/utils'
import { trackTakeABreakAlreadyActive } from '@classic/Foundation/Analytics/GoogleTagManagerService'

const TakeABreakActive = ({
  accountNumber,
  endDate,
  justPostedAccountToTakeABreak,
}: {
  accountNumber: Number
  endDate: Date
  justPostedAccountToTakeABreak: Boolean
}): JSX.Element => {
  React.useEffect(() => {
    if (!justPostedAccountToTakeABreak) {
      // trigger event if active and NOT after a post to set tab
      trackTakeABreakAlreadyActive({ accountNumber, endDate })
    }
  }, [accountNumber, endDate, justPostedAccountToTakeABreak])

  return (
    <DetailsWrapper>
      <NoticeBox
        title='You have opted to Take a Break.'
        noticeBoxType={NoticeBoxTypes.Info}
        hasBorder={true}
      />
      <DetailsTextWrapper data-testid='end-date-message'>
        Your Take a Break will end at {dayjs.utc(endDate).local().format('hh:mm a MMM Do, YYYY')}
      </DetailsTextWrapper>
    </DetailsWrapper>
  )
}

export default TakeABreakActive
