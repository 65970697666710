import React from 'react'
import { useAppDispatch } from '@core/Store/hooks'
import { toggleIsAppHeaderSticky } from '../Store'

/** Only to be used on top level page routes - otherwise changes required */
export const useDisableAppHeaderSticky = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(toggleIsAppHeaderSticky(false))
    return () => {
      dispatch(toggleIsAppHeaderSticky(true))
    }
  }, [dispatch])
}
