import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import {
  HeaderSelectionIconBoxStyled,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'

export const Tipping: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  return (
    <HeaderSelectionInfoStyled>
      <HeaderSelectionIconBoxStyled>
        <Icon type='TIPPING' />
      </HeaderSelectionIconBoxStyled>

      <div>
        <h3 data-tid-bet-product-name>{ticket.shortProductName}</h3>
        <span data-testid='tipping-detail-header'>{ticket.detailHeader}</span>
      </div>
    </HeaderSelectionInfoStyled>
  )
}
