import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const VisibilityButtonStyled = styled('div')({
  float: 'left',
})

export const BalancesTableStyled = styled('table')({
  width: '100%',
  '& th': {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '1.4rem',
    verticalAlign: 'top',
    color: hexColors.grey,
  },
  '& td': {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '1.4rem',
    verticalAlign: 'top',
  },
})

export const CashHeaderStyled = styled('th')({
  width: '20%',
  paddingRight: '0.5rem',
})

export const BonusBetHeaderStyled = styled('th')({
  width: '40%',
})

export const BonusCashHeaderStyled = styled('th')({
  width: '40%',
})

export const CashDataStyled = styled('td')({
  color: hexColors.charCoal,
})

export const BonusDataStyled = styled('td')(() => ({
  color: hexColors.studio,
}))
