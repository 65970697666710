import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'
import ObservableFixedFlucs from './ObservableFixedFlucs'
import ObservableSameRaceMultiPrices from './ObservableSameRaceMultiPrices'

export default class ObservableFixedOddsStarterInfo {
  constructor() {
    // @ts-expect-error Type issue
    this.isScratched = ko.observable()
    // @ts-expect-error Type issue
    this.isSuspended = ko.observable()
    // @ts-expect-error Type issue
    this.isFavourite = ko.observable()
    // @ts-expect-error Type issue
    this.scratchedTime = ko.observable()
    // @ts-expect-error Type issue
    this.hasWinDividend = ko.observable()
    // @ts-expect-error Type issue
    this.hasPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.displayWinDividend = ko.observable()
    // @ts-expect-error Type issue
    this.displayPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.winDividendDeduction = ko.observable()
    // @ts-expect-error Type issue
    this.placeDividendDeduction = ko.observable()
    // @ts-expect-error Type issue
    this.playerNo = ko.observable()
    // @ts-expect-error Type issue
    this.hasPlacePool = ko.observable()
    this.fixedFlucs = new ObservableFixedFlucs()
    // @ts-expect-error Type issue
    this.propositionSequence = ko.observable()
    this.sameRaceMultiPrices = new ObservableSameRaceMultiPrices()
  }

  public merge(other: ObservableFixedOddsStarterInfo) {
    Guard.notNull(other)

    this.isScratched(other.isScratched())
    this.isSuspended(other.isSuspended())
    this.isFavourite(other.isFavourite())
    this.scratchedTime(other.scratchedTime())
    this.hasWinDividend(other.hasWinDividend())
    this.hasPlaceDividend(other.hasPlaceDividend())
    this.displayWinDividend(other.displayWinDividend())
    this.displayPlaceDividend(other.displayPlaceDividend())
    this.winDividendDeduction(other.winDividendDeduction())
    this.placeDividendDeduction(other.placeDividendDeduction())
    this.playerNo(other.playerNo())
    this.hasPlacePool(other.hasPlacePool())

    // is this intentional?  re-assigning the KO object won't cause a binding update.. should be a merge instead?
    this.fixedFlucs = other.fixedFlucs

    this.sameRaceMultiPrices.merge(other.sameRaceMultiPrices)
  }

  public isScratched: ko.Observable<boolean>
  public isSuspended: ko.Observable<boolean>
  public isFavourite: ko.Observable<boolean>
  public scratchedTime: ko.Observable<string | null>
  public hasWinDividend: ko.Observable<boolean>
  public hasPlaceDividend: ko.Observable<boolean>
  public displayWinDividend: ko.Observable<string>
  public displayPlaceDividend: ko.Observable<string>
  public winDividendDeduction: ko.Observable<number | null>
  public placeDividendDeduction: ko.Observable<number | null>
  public playerNo: ko.Observable<number>
  public hasPlacePool: ko.Observable<boolean>
  public fixedFlucs: ObservableFixedFlucs
  public propositionSequence: ko.Observable<number>
  public sameRaceMultiPrices: ObservableSameRaceMultiPrices
}
