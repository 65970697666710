import * as ko from 'knockout'

export default class SameAs {
  constructor(
    public count: number,
    public row: number,
    public column: number
  ) {
    this.selected = ko.observable<boolean>(false)
  }

  public enable() {
    this.selected(true)
  }

  public disable() {
    this.selected(false)
  }

  public toggle() {
    this.selected(!this.selected())
  }

  public selected: ko.Observable<boolean>
}
