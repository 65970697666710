import React, { ComponentProps } from 'react'
import { SvgRaceCardIcon } from './Icons.styles'

type Props = ComponentProps<typeof SvgRaceCardIcon>

export const ChevronUp = ({ size, ...other }: Props) => (
  <SvgRaceCardIcon size={size} {...other}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12.1513L3.22669 14L10 8.79192L16.7733 14L18 12.1513L10 6L2 12.1513Z'
      fill='black'
    />
  </SvgRaceCardIcon>
)
