import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import type { BetError, BetErrorType, BetSlipResponse } from '@mobi/betslip/types'

export function mapErrorResponseCodeToType(code: number | string | BetErrorType): BetErrorType {
  if (typeof code === 'number' || Number.isInteger(+code)) {
    return betErrorTypes[`${code}`] || 'Unspecified'
  }
  return isValidBetErrorType(code) ? code : 'Unspecified'
}

export function getBetErrorType(response?: { error: BetError | null }): BetErrorType | undefined {
  if (!response || !response.error) return undefined
  return mapErrorResponseCodeToType(response.error.type)
}

export function getMultibetErrorDescription(
  multiBetResponse: BetSlipResponse | undefined
): BetSlipBetsState['multiBetError'] {
  if (!multiBetResponse || multiBetResponse.success) return null

  const betErrorType = getBetErrorType(multiBetResponse) || 'Unspecified'
  const errorMessage =
    MultiErrorMapping[betErrorType] || multiBetResponse.error?.message || 'An error has occurred'
  return { betErrorType, errorMessage }
}

// =============
// Local Helpers
// =============

const betErrorTypes: Readonly<Record<string, BetErrorType>> = {
  '0': 'Unspecified',
  '1': 'Unauthorized',
  '2': 'InvalidBet',
  '3': 'IncorrectNumberOfLegs',
  '4': 'InsufficientFunds',
  '5': 'PricesChanged',
  '6': 'HandicapChanged',
  '7': 'SpecialsError',
  '8': 'Closed',
  '10': 'BetPlacementFault',
  '11': 'DuplicateBonusBet',
  '12': 'InvalidBonusBet',
}

const MultiErrorMapping: Partial<Record<BetErrorType, string>> = {
  InvalidBonusBet: 'Error using Bonus Bet',
  DuplicateBonusBet: 'Bonus Bet can only be used once',
}

function isValidBetErrorType(errorType: string | BetErrorType): errorType is BetErrorType {
  return Object.values(betErrorTypes).includes(errorType as BetErrorType)
}
