import { createSignal } from 'rwwa-rx-state-machine'
import type { BetSlipResponse, BetSlipErrorResponse } from '@mobi/betslip/types'
import { BetslipExpandableTypes, BetslipItem, BetslipScrollPosition } from './driver'
import { FormulaFields } from './Components/MultiFormula/MultiFormula'
import { PriceSources } from '@core/Data/Betting/selections'

// general
export const BetslipDepositModalClosed = createSignal<void>('BetslipDepositModalClosed')
export const OnOpen = createSignal('OnOpen')
export const OnClose = createSignal('OnClose')
export const ClearBetslip = createSignal<void>('ClearBetslip')
export const EditBets = createSignal('EditBets')
export const ToggleBetslipExpandable =
  createSignal<BetslipExpandableTypes>('ToggleBetslipExpandable')
export const CloseBetslipExpandable = createSignal<BetslipExpandableTypes>('CloseBetslipExpandable')

/**
 * @todo Rename to KeepSelections
 */
export const KeepBets = createSignal('KeepBets')
export const ScrollTo = createSignal<BetslipScrollPosition>('ScrollTo')

// singles
export const AddSingleToBetslip = createSignal<BetslipItem>('AddSingleToBetslip')
export const AddSinglesToBetslip = createSignal<BetslipItem[]>('AddSinglesToBetslip')
export const RemoveSingleBet = createSignal<BetslipItem>('RemoveSingleBet')
export const UpdateItemInBetslip = createSignal<BetslipItem>('UpdateItemInBetslip')
export const DeselectSuperPick = createSignal<BetslipItem>('DeselectSuperPick')

export interface UpdateBetslipItemPriceData {
  propositionId: string
  winPrice: number
  placePrice: number | null
  priceSource: PriceSources
}

export interface UpdateMultiBetSpendData {
  field: FormulaFields | 'value'
  value: number
  bonusBetId?: number
  isBonusCash?: boolean
}

export const UpdateBetslipItemPrice =
  createSignal<UpdateBetslipItemPriceData>('UpdateBetslipItemPrice')

// multi
export const UpdateMultiBetSpend = createSignal<UpdateMultiBetSpendData>('UpdateMultiBetSpend')
export const ToggleMultiBet = createSignal<string | null>('ToggleMultiBet')
export const ToggleMultiLegBetType = createSignal<string | null>('ToggleMultiLegBetType')
export const ToggleMultiFormulaExpanded = createSignal('ToggleMultiFormulaExpanded')

// requests
export const RefreshBetslip = createSignal<{ betslipItemIds?: string[] }>('RefreshBetslip')
export const RemoveBonusBetsFromBetslip = createSignal('RemoveBonusBetsFromBetslip')

export interface RefreshBetslipSuccessfulData {
  response: BetSlipResponse[]
  ignorePriceChanges: boolean
}
export const RefreshBetslipSuccessful = createSignal<RefreshBetslipSuccessfulData>(
  'RefreshBetslipSuccessful'
)

export const RefreshBetslipFailed = createSignal<BetSlipErrorResponse>('RefreshBetslipFailed')
export const ProposeAllBets = createSignal('ProposeAllBets')
export const ProposeAllBetsSuccessful = createSignal<BetSlipResponse[]>('ProposeAllBetsSuccessful')
export const ProposeAllBetsFailed = createSignal<BetSlipErrorResponse>('ProposeAllBetsFailed')
export const ConfirmAllBets = createSignal('ConfirmAllBets')
export const ConfirmAllBetsSuccessful = createSignal<BetSlipResponse[]>('ConfirmAllBetsSuccessful')
export const ConfirmAllBetsFailed = createSignal<BetSlipErrorResponse>('ConfirmAllBetsFailed')
