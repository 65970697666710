import React from 'react'
import { isFobDetails, isFobSportsDetails, isRaceDetails } from '@mobi/betslip/helpers/typeGuards'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import { RemoveSingleBet } from '@core/Areas/Betslip/signals'
import { FobBetInfo, ErroredBetInfo, RaceBetInfo, SportBetInfo } from './Components'

interface BetInfoProps {
  item: BetslipItem
  isSimpleView: boolean
  hasFatalError?: boolean
  hasProposed?: boolean
  isBusy?: boolean
}

export function BetInfo({
  item,
  isSimpleView,
  hasFatalError = false,
  hasProposed = false,
  isBusy = false,
}: BetInfoProps): JSX.Element {
  const removeHandler = () => RemoveSingleBet(item)

  if (isFobDetails(item.selectionDetails)) {
    return (
      <FobBetInfo
        selectionDetails={item.selectionDetails}
        removeHandler={removeHandler}
        isSimpleView={isSimpleView}
        hasProposed={hasProposed}
        isBusy={isBusy}
        hasFatalError={hasFatalError}
      />
    )
  }

  if (isRaceDetails(item.selectionDetails)) {
    return (
      <RaceBetInfo
        item={item}
        removeHandler={removeHandler}
        isSimpleView={isSimpleView}
        hasProposed={hasProposed}
        isBusy={isBusy}
        hasFatalError={hasFatalError}
      />
    )
  }

  if (isFobSportsDetails(item.selectionDetails)) {
    return (
      <SportBetInfo
        item={item}
        removeHandler={removeHandler}
        isSimpleView={isSimpleView}
        hasProposed={hasProposed}
        isBusy={isBusy}
        hasFatalError={hasFatalError}
      />
    )
  }

  // should never happen, but at least we can handle it gracefully
  return <ErroredBetInfo removeHandler={removeHandler} />
}
