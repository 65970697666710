import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import { IEventAggregator } from './IEventAggregator'
import Guard from '../Guard'
import { injectable } from 'inversify'

@injectable()
export default class EventAggregator implements IEventAggregator {
  subscribe(topic: string, func: Function, context?: unknown): void {
    Guard.stringNotNullOrEmpty(topic)
    Guard.notNull(func)

    if (context) amplify.subscribe(topic, context, func)
    else amplify.subscribe(topic, func)
  }

  publish(topic: string, ...args: Array<unknown>): void {
    Guard.stringNotNullOrEmpty(topic)

    amplify.publish(topic, ...args)
  }

  unsubscribe(topic: string, func: Function): void {
    amplify.unsubscribe(topic, func)
  }
}
