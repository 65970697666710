import React from 'react'
import { store } from '@core/Store'
import { updateUserSettingsOnDataLayer } from '../helpers'

/** After redux persist has updated store, init data layer with values */
export const useInitAnalyticsDataLayer = () => {
  React.useEffect(() => {
    updateAnalyticsDataLayer()
  }, [])
}

// =============
// Local Helpers
// =============

async function updateAnalyticsDataLayer() {
  const appState = store.getState()
  updateUserSettingsOnDataLayer(appState)
}
