import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { ButtonWithIconStyled } from '@mobi/component-library/Common/Buttons'

interface SearchLinkStyledProps {
  padding?: string | number | (string | number)[]
}

export const SearchLinkStyled = styled(ButtonWithIconStyled)<SearchLinkStyledProps>(
  ({ padding }) => ({
    padding: padding ? padding : 0,
    color: colors.search.launchicon.icon,
    WebkitTapHighlightColor: 'transparent',
  })
)
