import React from 'react'
import { Autocomplete } from '@core/Components/Autocomplete'
import { searchStreetTypes } from '@core/Data/Address'

const StreetTypeAutosuggest = Autocomplete as { new (): Autocomplete<string> }

const getSuggestionValue = (suggestion: string) => suggestion

export function fetchSuggestions(inputValue: string) {
  if (!inputValue.length) {
    return []
  }

  return searchStreetTypes(inputValue).then(resp => resp.map(({ value }) => value))
}

interface StreetTypeAutocompleteProps {
  id: string
  inputId?: string
  value?: string | null
  autoFocus?: boolean
  errorMessage: string | boolean
  onSelection(suggestion: string | null): void
  onBlur?(): void
}

export function StreetTypeAutocomplete({
  id,
  inputId,
  value = null,
  errorMessage,
  onSelection,
  autoFocus,
  onBlur,
}: StreetTypeAutocompleteProps) {
  return (
    <StreetTypeAutosuggest
      id={id}
      selection={value}
      inputProps={{ id: inputId, onBlur }}
      errorMessage={errorMessage}
      autoFocus={autoFocus}
      onSelection={onSelection}
      getSuggestionValue={getSuggestionValue}
      getSuggestionsForInputValue={fetchSuggestions}
    />
  )
}
