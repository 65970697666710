import Rx from 'rx'
import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import type { Acceptor } from '@mobi/betslip/types'
import { filterObject } from '../helpers'
import { get } from '@classic/Foundation/Services/ApiService'
import { type TypedRecord, recordify } from 'typed-immutable-record'

export interface ImageSize {
  width: number
  height: number
  y: number
}
export interface ImageUrlWithSize {
  url: string
  size: ImageSize
}

export interface ImageUrlWithSizes {
  small: ImageUrlWithSize
  large: ImageUrlWithSize
  larger: ImageUrlWithSize
  largest: ImageUrlWithSize
}

export interface MysteryAcceptor {
  name: string
  number: number
}

interface AcceptorMap {
  acceptors: IKeyMap<Acceptor>
}

export interface AcceptorRecord extends TypedRecord<AcceptorRecord>, Acceptor {}

export const Acceptors = declareResourceType<AcceptorRecord, Acceptor>({
  typeKey: 'acceptors',
  // @ts-expect-error Legacy - use better data management solution
  fetchMultiple,
  map: x => recordify<Acceptor, AcceptorRecord>(x),
  maxToKeep: 100,
  hardTimeToLive: 60000,
})

// ti-1180: Maximum number of keys to request at a time so as not to exceed the 260 character URL limit in IIS.
// - base url: 54 (e.g. http://tabtouch-mobi.local.rwwaq.com.au/api/acceptors/)
// - each key: 11 (6 digit race key, +1 to allow for growth to 7 digits, +3 for acceptor, +1 for delimiter)
// - max keys: (260 - 54) / 11 = 18.72
// (go with 10 just in case)

const MAX_KEYS_TO_REQUEST = 10

function fetchMultiple(keys: string[]) {
  return (
    Rx.Observable.fromArray(keys)
      .bufferWithCount(MAX_KEYS_TO_REQUEST)
      .select(acceptorKeys => get<AcceptorMap>({ url: `/api/acceptors/${acceptorKeys.join(',')}` }))
      .select(promise => Rx.Observable.fromPromise(promise))
      .mergeAll() as unknown as Rx.Observable<AcceptorMap>
  ).selectMany(acceptorMap => {
    const success = filterObject<Acceptor>(
      acceptorMap.acceptors,
      acceptor => acceptor.name !== '-- not found --'
    ) as IKeyMap<Acceptor>

    const success$ = Rx.Observable.just({
      keys: Object.keys(success),
      result: Promise.resolve({ acceptors: success }),
    })

    const failed = filterObject<Acceptor>(
      acceptorMap.acceptors,
      acceptor => acceptor.name === '-- not found --'
    ) as IKeyMap<Acceptor>
    const failed$ = Rx.Observable.just({
      keys: Object.keys(failed),
      result: Promise.reject('Could not find acceptors'),
    })

    // @ts-expect-error Legacy - use better data management solution
    return Rx.Observable.concat(success$, failed$)
  })
}
