import React from 'react'
import { Observable } from 'rx'
import { List } from 'immutable'
import { useWillUnmount } from 'rooks'
import type { BettingType, EventDetails } from '@mobi/betslip/types'
import { isFobDetails, isFobSportsDetails, isRaceDetails } from '@mobi/betslip/helpers/typeGuards'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { useFeature } from '@core/Utils/hooks'
import { useHapticFeedbackOnBetSuccess } from '@core/Utils/hooks/useHapticFeedbackOnBetSuccess'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ as superPickState$ } from '@core/Components/SuperPick/driver'
import { Campaign, state$ as userState$ } from '@core/State/UserAccount/userAccountDriver'
import { QuickbetState, state$ as quickbetState$ } from './driver'
import { BetInvestment } from './Components/BetInvestment/BetInvestment'
import { QuickbetKeypad } from './Components/Keypad/Keypad'
import { Receipt } from './Components/Receipt/Receipt'
import { QuickbetBodyStyled, QuickbetActionsStyled } from './Quickbet.styles'
import { ButtonsContainer } from './Components/Buttons/ButtonsContainer'
import { QuickbetSuperPick } from './Components/SuperPick/QuickbetSuperPick'
import { QuickbetFormula } from './Components/Formula/QuickbetFormula'
import { Notifications } from './Components/Notifications/Notifications'
import { QuickbetCampaigns } from './Components/Campaigns/QuickbetCampaigns'
import { register as registerAnalytics } from './analytics'
import { register as registerQuickbetLogging } from './logging'
import {
  isFavouriteNumbersDetails,
  isMysteryDetails,
  isToteSportsDetails,
} from '@core/Data/Betting/selectionDetails'
import { BalanceInfoBar } from './Components/BalanceBar/BalanceBar'
import { HeaderStyled } from './Components/Header/Components/Common/Header.styles'
import {
  FavouriteNumbersHeader,
  FobHeader,
  FobSportsHeader,
  MysteryHeader,
  RacingHeader,
  ToteSportsHeader,
} from './Components/Header/Components'
import { BonusBetList } from './Components/BonusBetList/BonusBetList'
import { SetBonusBet, ToggleBonusBetUsage, ToggleBonusCashUsage } from './signals'
import { BonusBetListItem } from './Components/BonusBetList/BonusBetListItem'
import { isCampaignRedeemableWithBettingType } from './helpers/campaignHelper'
import { useAppDispatch } from '@core/Store/hooks'
import { closeQuickDeposit } from '@core/Areas/QuickDeposit/Store'
import { MysteryQuickPickBonusToggleButton } from './Components/MysteryQuickPickBonusToggleButton/MysteryQuickPickBonusToggleButton'
import { QuickDeposit } from '../QuickDeposit'
import { useServerConfig } from '@core/Data/ServerConfig/useServerConfig'

export interface QuickbetExternalProps {
  shouldShowSuperPicks?: boolean
}

interface QuickbetStateProps {
  accountBalance: number
  accountNumber: number
  activeCampaigns: List<Campaign> | null
  bettingType: BettingType | null
  bonusBetBalance: number
  bonusCashBalance: number
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  notificationVisible: boolean
  selectionDetails: EventDetails | null
  superPickEnabled: boolean
  isSameRaceMulti?: boolean
  betPlaced?: boolean
  canConfirmBet: boolean
  insufficientFundsForBet: boolean
}

type QuickbetProps = QuickbetExternalProps & QuickbetStateProps

const state$ = Observable.combineLatest(
  quickbetState$,
  superPickState$,
  userState$,
  (quickbetStateRecord, superPickState, userState): QuickbetStateProps => {
    const quickbetState = quickbetStateRecord.toJS() as QuickbetState
    const canBet = quickbetState.canBet
    const canConfirmBet = quickbetState.canConfirmBet
    const isFixedOddsRace = quickbetState.bettingType === 'fixed-odds-racing'
    const selectedSuperPickReq = superPickState.selectedSuperPickSeq
    const isVisible = !selectedSuperPickReq && canConfirmBet ? false : canBet
    const specialOffers = List(superPickState.specialOffers)
    const isSpecialOffersAvailable = !specialOffers || !specialOffers.size || !isVisible

    return {
      bettingType: quickbetState.bettingType,
      isUsingBonusCash: quickbetState.isUsingBonusCash,
      isUsingBonusBet: quickbetState.isUsingBonusBet,
      accountNumber: userState.accountNumber || 0,
      accountBalance: userState.accountBalance || 0,
      bonusBetBalance: userState.bonusBetBalance || 0,
      bonusCashBalance: userState.bonusCashBalance || 0,
      activeCampaigns: userState.activeCampaigns,
      isSameRaceMulti: quickbetState.isSameRaceMulti,
      selectionDetails: quickbetState.selectionDetails,
      superPickEnabled: isFixedOddsRace && !isSpecialOffersAvailable,
      notificationVisible: !!quickbetState.notificationType,
      betPlaced: quickbetState.betPlaced,
      canConfirmBet: quickbetState.canConfirmBet,
      insufficientFundsForBet: quickbetState.insufficientFundsForBet,
    }
  }
)

const quickDepositEnabledBettingTypes: (BettingType | null)[] = [
  'tote-racing',
  'tote-sports-tipping',
  'fixed-odds-racing',
]

export const QuickbetComponent = ({
  accountBalance,
  accountNumber,
  activeCampaigns,
  betPlaced,
  bettingType,
  bonusBetBalance,
  bonusCashBalance,
  isSameRaceMulti,
  isUsingBonusBet,
  isUsingBonusCash,
  notificationVisible,
  selectionDetails,
  shouldShowSuperPicks = true,
  superPickEnabled,
  insufficientFundsForBet,
  canConfirmBet,
}: QuickbetProps) => {
  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')
  const serverConfig = useServerConfig()
  useHapticFeedbackOnBetSuccess('quickbet')

  React.useEffect(() => {
    if (!serverConfig?.enableAnalytics) return
    const { dispose } = registerAnalytics()
    return dispose
  }, [serverConfig?.enableAnalytics])

  React.useEffect(() => {
    const { dispose } = registerQuickbetLogging()
    return dispose
  }, [])

  const isMysteryQuickPick = isBonusCashImprovementsActive && bettingType === 'mystery-quick-pick'

  const hasBonusCash = activeCampaigns?.some(
    campaign =>
      campaign.rewardType === 'BonusCashReward' &&
      (isCampaignRedeemableWithBettingType(campaign, 'fixed-odds-racing') ||
        isCampaignRedeemableWithBettingType(campaign, 'tote-racing') ||
        isMysteryQuickPick)
  )

  React.useEffect(() => {
    if (isBonusCashImprovementsActive && hasBonusCash) {
      ToggleBonusCashUsage(false)
    }
  }, [isBonusCashImprovementsActive, hasBonusCash])

  const isRacing = isRaceDetails(selectionDetails)
  const isMystery = isMysteryDetails(selectionDetails)
  const isFob = !isRacing && !isMystery && isFobDetails(selectionDetails)
  const isFavouriteNumbers = isFavouriteNumbersDetails(selectionDetails)
  const isToteSports = isToteSportsDetails(selectionDetails)
  const isFobSports = isFobSportsDetails(selectionDetails)

  const shouldShowSuperPicksOverride = shouldShowSuperPicks && !isSameRaceMulti
  const mustUseBonusCash =
    bettingType !== 'fixed-odds-racing' && bettingType !== 'tote-racing' && !isMysteryQuickPick

  const headerType = isMystery ? 'mystery' : isFob ? 'sports' : 'racing'

  const headerTid = { [`data-tid-quickbet-${headerType}-header`]: '' }

  const dispatch = useAppDispatch()

  useWillUnmount(() => dispatch(closeQuickDeposit()))

  const activeBonusCashCampaign = activeCampaigns
    ?.sort(x => x.rewardExpiry.valueOf())
    ?.find(x => x.rewardType === 'BonusCashReward')

  const showAvailableBonuses =
    (isUsingBonusBet && !notificationVisible) || (isMysteryQuickPick && isUsingBonusBet)

  const displayQuickDeposit =
    insufficientFundsForBet &&
    canConfirmBet &&
    quickDepositEnabledBettingTypes.includes(bettingType) &&
    !isUsingBonusBet

  return (
    <section>
      <HeaderStyled {...headerTid}>
        <BalanceInfoBar />
        {isRacing && <RacingHeader />}
        {isFob && <FobHeader />}
        {isMystery && <MysteryHeader />}
        {isFavouriteNumbers && <FavouriteNumbersHeader />}
        {isToteSports && <ToteSportsHeader />}
        {isFobSports && <FobSportsHeader />}
      </HeaderStyled>

      <BetInvestment />

      {isMysteryQuickPick && !betPlaced && (
        <MysteryQuickPickBonusToggleButton
          handleClick={() => {
            ToggleBonusBetUsage()
            trackEvent(
              isUsingBonusBet
                ? keys.quickbetBonusCashButtonDeactivated
                : keys.quickbetBonusCashButtonActivated,
              {
                accountNumber: accountNumber ?? '',
                accountBalance: accountBalance,
                bonusBetBalance: bonusBetBalance,
                bonusCashBalance: bonusCashBalance,
              }
            )
          }}
        />
      )}

      <QuickbetFormula />

      {(isBonusCashImprovementsActive || !isSameRaceMulti) &&
        (isUsingBonusCash || mustUseBonusCash) && <QuickbetCampaigns />}

      {displayQuickDeposit ? (
        <QuickDeposit />
      ) : (
        <>
          <QuickbetBodyStyled
            id='quickbet-body'
            superPickEnabled={superPickEnabled && shouldShowSuperPicksOverride}
          >
            {showAvailableBonuses && (
              <>
                {activeBonusCashCampaign && (
                  <BonusBetListItem
                    id='bonus-cash'
                    name='Bonus Cash'
                    expiry={activeBonusCashCampaign.rewardExpiry}
                    amount={bonusCashBalance}
                    isBonusCash={true}
                    selected={isUsingBonusCash}
                    handleClick={() => {
                      if (isMysteryQuickPick) {
                        // select this single bonus cash item that's shown here
                        ToggleBonusCashUsage(true)
                      } else {
                        if (!isUsingBonusCash) {
                          // We're turning bonus cash on, so clear any selected bonus bet.
                          ToggleBonusBetUsage(false)
                          SetBonusBet(null)
                        }
                        ToggleBonusCashUsage()
                      }
                    }}
                  />
                )}
                {!isMysteryQuickPick && (
                  <BonusBetList
                    superPickEnabled={superPickEnabled && shouldShowSuperPicksOverride}
                    setBonusBet={bonusBet => {
                      SetBonusBet(bonusBet)
                      ToggleBonusCashUsage(false)
                    }}
                    bettingType={bettingType}
                  />
                )}
              </>
            )}
            {!isUsingBonusBet && shouldShowSuperPicksOverride && <QuickbetSuperPick />}
            {!isUsingBonusBet && <QuickbetKeypad />}
            {<Notifications />}
            {<Receipt />}
          </QuickbetBodyStyled>
          <QuickbetActionsStyled>
            <ButtonsContainer />
          </QuickbetActionsStyled>
        </>
      )}
    </section>
  )
}

export const Quickbet = observeImmutable<QuickbetStateProps, QuickbetExternalProps>(
  state$,
  ({ record, shouldShowSuperPicks }) => (
    <QuickbetComponent shouldShowSuperPicks={shouldShowSuperPicks} {...record} />
  )
)
