import * as ko from 'knockout'
import { IFieldViewModel } from './IFieldViewModel'
import container from '../../../IOC/inversify.config'
import { IMultiFieldViewModel } from './IMultiFieldViewModel'
import fieldTemplate from './field.tpl.html'
import multiFieldTemplate from './multi.field.tpl.html'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'

interface Params {
  raceKey: ObservableRaceKey
  bettingContext: BettingInformation
}

export function register(): void {
  if (!ko.components.isRegistered('single-field')) {
    ko.components.register('single-field', {
      viewModel: {
        createViewModel: (params: Params): IFieldViewModel => {
          const viewModel = container.get<IFieldViewModel>('IFieldViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: fieldTemplate,
    })
  }

  if (!ko.components.isRegistered('multi-field')) {
    ko.components.register('multi-field', {
      viewModel: {
        createViewModel: (params: Params): IMultiFieldViewModel => {
          const viewModel = container.get<IMultiFieldViewModel>('IMultiFieldViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: multiFieldTemplate,
    })
  }
}
