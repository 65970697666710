import { FeatureFlags as LaunchDarklyFeatureFlags } from '@core/State/LaunchDarklyFeatures/driver'
import type { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { NextRace } from '@core/Data/Racing/nextRaces'
import { FeatureFlags } from '@mobi/settings'
import { UpdateSuperBoostRaceList } from './HomeNextSuperPickDriver'

export interface SuperBoostMeeting {
  FixtureId: string
  RaceCourse: string
  AdvertisedStartTime: string
  RaceNumber: string
  RaceType: string
}

interface SuperBoostSourceData {
  SuperBoostMeetings: SuperBoostMeeting[]
}

export const addRawSuperBoostMeetings = (
  serverEnvironment: string,
  features: LaunchDarklyFeatureFlags
) => {
  try {
    let rawDataFeatureFlagName = FeatureFlags.HOMEPAGE_SUPERPICKS_DATA.key
    if (serverEnvironment.toLowerCase() !== 'dev') {
      rawDataFeatureFlagName += '-' + serverEnvironment.toLowerCase()
    }
    const rawData = features[rawDataFeatureFlagName]

    if (!rawData || !rawData.SuperBoostMeetings) {
      UpdateSuperBoostRaceList([])
      return
    }

    const races = ConvertSuperBoostMeetings(rawData)
    UpdateSuperBoostRaceList(races)
  } catch (error) {
    UpdateSuperBoostRaceList([])
  }
}

export const ConvertSuperBoostMeetings = (data: SuperBoostSourceData): NextRace[] => {
  try {
    if (data === null || data.SuperBoostMeetings === null) {
      return []
    }

    const races = data.SuperBoostMeetings.filter(data => {
      if (isNaN(Date.parse(data.AdvertisedStartTime))) {
        return false
      }

      if (isNaN(Number.parseInt(data.RaceNumber))) {
        return false
      }
      return new Date(data.AdvertisedStartTime) > new Date()
    })
      .map(superBoostRace => {
        const nextRace: NextRace = {
          AdvertisedStartTime: superBoostRace.AdvertisedStartTime,
          MeetingDate: superBoostRace.AdvertisedStartTime,
          MeetingName: superBoostRace.RaceCourse,
          RaceNumber: Number.parseInt(superBoostRace.RaceNumber),
          RaceType: superBoostRace.RaceType as RaceCodes,
          MeetingID: superBoostRace.FixtureId,
          Distance: 0,
          HasJackpot: false,
          GeoLocationCode: 'WA',
        }
        return nextRace
      })
      .sort((a: NextRace, b: NextRace) => {
        return +new Date(a.AdvertisedStartTime) - +new Date(b.AdvertisedStartTime)
      })

    return races
  } catch (error) {
    return []
  }
}
