import React from 'react'
import type { Contract } from '@mobi/web-native-comms'
import { trackOptimoveEvent } from '../optimove'

export function useTrackOptimoveEvent(event: Contract['OPTIMOVE_TRACK']) {
  const capturedEventRef = React.useRef<Contract['OPTIMOVE_TRACK']>(event)
  React.useEffect(() => {
    trackOptimoveEvent(capturedEventRef.current)
  }, [])
}
