import React from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Icon } from '@mobi/component-library/Common/Icon'
import {
  getPendingSortOrder,
  getResultsSortOrder,
} from '@core/Areas/AccountActivity/Store/selectors'
import type { SortOrder, Tabs } from '@core/Areas/AccountActivity/types'
import { setPendingSortOrder, setResultsSortOrder } from '@core/Areas/AccountActivity/Store'
import { ACCOUNT_ACTIVITY_ROUTE } from '@core/Areas/AccountActivity/constants'
import { useRebetBetCount } from './hooks/useRebetBetCount'
import { useHeaderScrollEvents } from './hooks/useScrollHeaderEvents'
import { HeaderDate } from './Components/HeaderDate'
import {
  ACC_ACT_FLEX_1,
  ACC_ACT_NAV_ACTIVE_CLASS,
  AccountActivityDateLabelStyled as DateLabel,
  AccountActivityHeaderContainerStyled as HeaderContainer,
  AccountActivityNavStyled as Nav,
  AccountActivityPendingSortContainerStyled as PendingSortContainer,
  AccountActivitySecondaryHeaderStyled as SecondaryHeader,
  RebetCountBadgeStyled as Badge,
  RefreshButtonStyled as Refresh,
  SecondaryDateSelectStyled as DateSelect,
  SecondayHeaderIconStyled as SecondayHeaderIcon,
  DateWrapperStyled as DateWrapper,
} from './Header.styles'

const EVENT_START: SortOrder = 'eventStart'
const BET_PLACED: SortOrder = 'betPlaced'

const MONTHS_FOR_HISTORY_SELECT = [...Array(24).keys()]

export const Header: React.FC<{
  firstDate: string
  setSelectedDate: React.Dispatch<string>
  selectedDate: string
  onRefresh: () => void
}> = ({ firstDate, setSelectedDate, selectedDate, onRefresh }) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()

  const dispatch = useDispatch()
  const pendingSortOrder = useSelector(getPendingSortOrder)
  const resultsSortOrder = useSelector(getResultsSortOrder)

  const rebetInsights = useRebetBetCount(activeTab)

  const { fixedHeaderWrapperElRef, fixedHeaderElRef, displayDate } = useHeaderScrollEvents({
    firstDate,
  })

  const isPendingTab = activeTab === 'pending'
  const isResultsTab = activeTab === 'results'
  const isRebetTab = activeTab === 'rebet'
  const isTransactionsTab = activeTab === 'transactions'
  const isOrderByEvent =
    (isPendingTab && pendingSortOrder === 'eventStart') ||
    (isResultsTab && resultsSortOrder === 'eventStart') ||
    isRebetTab

  const handlePendingSortOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setPendingSortOrder(e.target.value as SortOrder))
  }

  const handleResultsSortOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setResultsSortOrder(e.target.value as SortOrder))
  }

  const handlSelectedDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.currentTarget.value)
  }

  const handleRefresh = () => {
    onRefresh()
    rebetInsights.fetchRebetCount()
  }

  return (
    <div ref={fixedHeaderWrapperElRef}>
      <HeaderContainer ref={fixedHeaderElRef}>
        {!isTransactionsTab && (
          <Nav>
            <Link
              data-testid='account-activity-tab-pending'
              to={`/${ACCOUNT_ACTIVITY_ROUTE}/pending`}
            >
              <span className={activeTab === 'pending' ? ACC_ACT_NAV_ACTIVE_CLASS : ''}>
                Pending
              </span>
            </Link>

            <Link
              data-testid='account-activity-tab-results'
              to={`/${ACCOUNT_ACTIVITY_ROUTE}/results`}
            >
              <span className={activeTab === 'results' ? ACC_ACT_NAV_ACTIVE_CLASS : ''}>
                Resulted
              </span>
            </Link>

            {rebetInsights.isReBetFeatureActive && (
              <Link to={`/${ACCOUNT_ACTIVITY_ROUTE}/rebet`} data-tid-tab-rebet>
                <span className={activeTab === 'rebet' ? ACC_ACT_NAV_ACTIVE_CLASS : ''}>
                  Re-Bet
                  {rebetInsights.hasRebetBadge && (
                    <Badge isMaxCount={rebetInsights.isRebetCountMax}>
                      {rebetInsights.rebetTicketsCount}
                    </Badge>
                  )}
                </span>
              </Link>
            )}
          </Nav>
        )}

        <SecondaryHeader>
          <div className={ACC_ACT_FLEX_1}>
            {isPendingTab ? (
              <PendingSortContainer>
                <select
                  data-testid='pending-sort-select'
                  onChange={handlePendingSortOrderChange}
                  value={pendingSortOrder}
                >
                  <option value={EVENT_START}>Event Start</option>
                  <option value={BET_PLACED}>Bet Placed</option>
                </select>

                <Icon type='arrowdown' size='1.2rem' />
              </PendingSortContainer>
            ) : isResultsTab ? (
              <PendingSortContainer>
                <select
                  data-testid='results-sort-select'
                  onChange={handleResultsSortOrderChange}
                  value={resultsSortOrder}
                >
                  <option value={EVENT_START}>Event Start</option>
                  <option value={BET_PLACED}>Bet Placed</option>
                </select>
                <Icon type='arrowdown' size='1.2rem' />
              </PendingSortContainer>
            ) : (
              <DateLabel>
                {activeTab === 'transactions' && 'Date'}
                {activeTab === 'rebet' && 'Event Start'}
              </DateLabel>
            )}

            <DateWrapper>
              <HeaderDate date={displayDate} isOrderByEvent={isOrderByEvent} />

              {(isResultsTab || isTransactionsTab) && (
                <>
                  <SecondayHeaderIcon>
                    <Icon type='calendar' />
                  </SecondayHeaderIcon>

                  <DateSelect
                    data-testid='account-activity-date-picker'
                    value={selectedDate}
                    onChange={handlSelectedDateChange}
                  >
                    {MONTHS_FOR_HISTORY_SELECT.map(monthNumber => {
                      const date = dayjs().subtract(monthNumber, 'months').date(1)
                      return (
                        <option key={monthNumber} value={date.format('YYYY-MM-DD')}>
                          {date.format('MMMM YYYY')}
                        </option>
                      )
                    })}
                  </DateSelect>
                </>
              )}
            </DateWrapper>
          </div>

          <Refresh data-testid='account-activity-refresh' onClick={handleRefresh}>
            <Icon type='refresh' />
          </Refresh>
        </SecondaryHeader>
      </HeaderContainer>
    </div>
  )
}
