import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { DeliveryMethod } from './types'
import { getDeliveryMethodName } from './helpers'

export const DeliveryMethodSent: React.FC<{
  deliveryMethods: DeliveryMethod[]
  selectedDeliveryMethod?: DeliveryMethod['id']
  isValidationCode?: boolean
}> = ({ deliveryMethods, selectedDeliveryMethod, isValidationCode = false }) => {
  const method = deliveryMethods.find(method => method.id === selectedDeliveryMethod)

  if (!method) return null

  const sendType = isValidationCode ? 'A validation code' : 'Your account number'
  const methodName = getDeliveryMethodName(method.id)

  return (
    <DeliveryMethodSentNoticeStyled data-testid='delivery-method-sent'>
      <span>{sendType} has been sent to</span>
      <span>your {methodName === 'Email' ? 'email account' : 'phone by SMS'}</span>
      <Icon type={methodName === 'Email' ? 'envelopeOpen' : 'phone'} />
      <strong>{method.redactedValue}</strong>
    </DeliveryMethodSentNoticeStyled>
  )
}

// ======
// Styles
// ======

const DeliveryMethodSentNoticeStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  color: hexColors.white,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',

  strong: {
    fontSize: '2rem',
    fontWeight: 'normal',
  },

  svg: {
    margin: '0.5rem 0',
  },
})
