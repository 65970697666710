import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { Money, MoneyProps } from '@core/Components/Text/Money'
import { lessOrEqualThan } from '@mobi/component-library/Utils/media'
import { calculateInvestmentFontSizeFactor } from '../../helpers/calculator'
import { hexColors } from '@mobi/settings'

export const BetInvestmentStyled = styled.div({
  backgroundColor: colors.quickbet.header.background,
  color: colors.quickbet.header.text,
  padding: '0 1.5rem 1.5rem',
  position: 'relative',
})

export interface BetInvestmentGroupStyledProps {
  isActive: boolean
}

export const BetInvestmentGroupStyled = styled.div<BetInvestmentGroupStyledProps>(
  {
    alignContent: 'stretch',
    alignItems: 'center',
    backgroundColor: colors.quickbet.investment.background,
    color: colors.quickbet.investment.text,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontSize: '1.4rem',
    justifyContent: 'flex-start',
    padding: '0.8rem',
    height: '3.5rem',
  },

  ({ isActive }) => ({
    backgroundColor: isActive
      ? colors.quickbet.investment.active.background
      : colors.quickbet.investment.background,
    color: isActive ? colors.quickbet.investment.active.text : colors.quickbet.investment.text,
  })
)

interface InvestmentContainerStyledProps {
  isBonusBet: boolean
  isActive: boolean
}

export const InvestmentContainerStyled = styled.div<InvestmentContainerStyledProps>(
  {
    textAlign: 'right',
    padding: '0.3em 0.8em 0.3em 0.8em',
    borderRadius: '0.4em',
    display: 'flex',
    marginLeft: 'auto',
  },
  props => ({
    backgroundColor: props.isBonusBet ? hexColors.orange : 'unset',
    color: props.isActive
      ? colors.quickbet.investment.active.text
      : props.isBonusBet
        ? colors.quickbet.investment.active.text
        : colors.quickbet.investment.text,
  })
)

interface InvestmentStyledProps extends MoneyProps {
  shouldFontResize?: boolean
}

const defaultValueFontSize = 2.6

export const InvestmentStyled = styled(Money)<InvestmentStyledProps>(
  {
    flex: '1 0',
    fontSize: `${defaultValueFontSize}rem`,
    fontWeight: 'bold',
    textAlign: 'right',
    // Increase the lineHeight to match the fontSize, to prevent artifacts from text above and below the amount of space
    // for the line, as given by the lineHeight (iOS 11 only; see MOBILE-865)
    lineHeight: 'normal',
  },

  ({ amount, shouldFontResize, decimalPlaces = 0 }) => {
    if (shouldFontResize) {
      return {
        [lessOrEqualThan('px450')]: {
          fontSize: `${(
            defaultValueFontSize *
            calculateInvestmentFontSizeFactor({ amount, decimalPlaces }) *
            1.2
          ).toFixed(2)}rem`,
        },
        [lessOrEqualThan('px400')]: {
          fontSize: `${(
            defaultValueFontSize *
            calculateInvestmentFontSizeFactor({ amount, decimalPlaces }) *
            1.08
          ).toFixed(2)}rem`,
        },
        [lessOrEqualThan('px350')]: {
          fontSize: `${(
            defaultValueFontSize * calculateInvestmentFontSizeFactor({ amount, decimalPlaces })
          ).toFixed(2)}rem`,
        },
      }
    }
    return null
  }
)

export const TotalCostSectionStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 0 0',
  height: '2.7rem',
  fontSize: '1.1rem',
  marginBottom: '-0.5rem',
})

export const TotalCostMoneyStyled = styled('span')({
  fontSize: '1.6rem',
  fontWeight: 'bold',
})
