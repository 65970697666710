import React from 'react'
import dayjs from 'dayjs'
import {
  CancellationState,
  Transaction as TransactionType,
} from '@core/Areas/AccountActivity/types'
import { TransactionCardStyled, PendingStatusStyled, TransactionStyled } from './Transaction.styles'
import { Money } from '@core/Components/Text/Money'
import { TransactionRow } from './TransactionRow/TransactionRow'
import { PendingWithdrawalDetails } from './PendingWithdrawalDetails/PendingWithdrawalDetails'
import { CompletedWithdrawalDetails } from './CompletedWithdrawalDetails/CompletedWithdrawalDetails'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'

export const Transaction: React.FC<{ transaction: TransactionType }> = ({ transaction }) => {
  const [cancellationState, setCancellationState] = React.useState<CancellationState>('initial')

  return (
    <TransactionCardStyled data-tid-transaction>
      <TransactionStyled bonusType={transaction.bonusType}>
        <Grid>
          <GridCell width='15%'>
            <span data-testid='transaction-timestamp'>
              {dayjs(transaction.timestamp).format('HH:mm')}
            </span>
          </GridCell>

          <GridCell>
            <strong data-testid='transaction-name'>{transaction.name}</strong>
          </GridCell>

          <GridCell>
            <span data-testid='transaction-description'>
              {transaction.description}
              {transaction.withdrawalCancelable && cancellationState != 'completed' && (
                <PendingStatusStyled>Pending</PendingStatusStyled>
              )}
            </span>
          </GridCell>

          <GridCell align='right'>
            <span className='text-right' data-testid='transaction-value'>
              <Money amount={transaction.value} />
            </span>
          </GridCell>
        </Grid>

        <TransactionRow name='Location' value={transaction.location} />
        <TransactionRow name='Card No.' value={transaction.maskedCcNumber} />
        <TransactionRow name='BSB - Acc No.' value={transaction.bankAccountNumber} />
        <TransactionRow name='Reference Number' value={transaction.bpayReferenceNumber} />
        <TransactionRow name='Transaction Id' value={transaction.transactionSequence.toString()} />

        {transaction.withdrawalCancelable &&
          (cancellationState === 'completed' || cancellationState === 'error' ? (
            <CompletedWithdrawalDetails cancellationState={cancellationState} />
          ) : (
            <PendingWithdrawalDetails
              cancellationState={cancellationState}
              setCancellationState={setCancellationState}
              transactionNumber={transaction.tranTransSeq}
              transactionTimestamp={transaction.timestamp}
            />
          ))}
      </TransactionStyled>
    </TransactionCardStyled>
  )
}
