import React from 'react'
import { isLoading, isErrored } from 'rwwa-data-access'
import dayjs from 'dayjs'
import type { Race } from '@mobi/betslip/types'
import { hexColors } from '@mobi/settings'

import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { ListBadge, ListTime } from '@core/Components/List'
import { ErrorMessage } from '@core/Components/Messages'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Money } from '@core/Components/Text/Money'
import { ListLink } from '@core/Components/List/ListLink'
import { connect } from '@core/Components/HOCs/connect'
import { JackpotTitleStyled, JackpotTextStyled } from './Jackpot.styles'
import { JackpotLoading } from './JackpotLoading' // separate import here to fix tests TODO: dont do this
import {
  Races,
  Pools,
  FavouriteNumberPool,
  RacingPool,
  TippingPool,
  isFavouriteNumbersPool,
  isRacingPool,
  isTippingPool,
  FixtureType,
} from '@core/Data/Racing'

function ErroredJackpot() {
  return <ErrorMessage>Error loading jackpot</ErrorMessage>
}

export const Jackpot = connect({ pool: Pools })(({ pool }) => {
  if (isLoading(pool)) {
    return <JackpotLoading />
  }

  if (isErrored(pool)) {
    return <ErroredJackpot />
  }

  if (isRacingPool(pool)) {
    return <JackpotWithRace loadedPool={pool} race={pool.raceKeys[0]} />
  }

  return <LoadedJackpot pool={pool} />
})

const JackpotWithRace = connect({ race: Races })<{ loadedPool: RacingPool }>(({
  loadedPool,
  race,
}) => {
  if (isLoading(race)) {
    return <JackpotLoading />
  }

  if (isErrored(race)) {
    return <ErroredJackpot />
  }

  return <LoadedJackpot pool={loadedPool} race={race} />
})

export function LoadedJackpot({
  pool,
  race,
}: {
  pool: TippingPool | RacingPool | FavouriteNumberPool
  race?: Race
}): JSX.Element {
  if (isRacingPool(pool) && !!race) {
    let codeIcon

    switch (pool.fixtureType) {
      case FixtureType.Races:
        codeIcon = <Icon type='races' />
        break
      case FixtureType.Dogs:
        codeIcon = <Icon type='dog' />
        break
      case FixtureType.Trots:
        codeIcon = <Icon type='trot' />
        break
      default:
        return <ErroredJackpot />
    }

    return (
      <JackpotContent
        title={race.meetingName}
        subTitle={pool.name}
        endTime={new Date(race.raceTime)}
        prefix={race.meetingCode}
        badgeText={race.raceNumber.toString()}
        href={`/#tote/meetings/${race.meetingCode.toLowerCase()}/${race.raceNumber}?date=${dayjs(
          race.meetingDate
        ).format('YYYY-MM-DD')}`}
        icon={codeIcon}
        netJackpotAmount={pool.netJackpotAmount}
      />
    )
  }

  if (isFavouriteNumbersPool(pool)) {
    return (
      <JackpotContent
        title={'Favourite Numbers'}
        subTitle={pool.name}
        endTime={pool.scheduledCloseDateTime}
        badgeText='FN'
        href={'/#favourite-numbers'}
        icon={<Icon type='favouriteNumbers' />}
        netJackpotAmount={pool.netJackpotAmount}
      />
    )
  }

  if (isTippingPool(pool)) {
    return (
      <JackpotContent
        title={pool.sportDescription}
        subTitle={pool.name}
        endTime={pool.gameStartDateTime}
        badgeText='TIP'
        href={`/#totesports/${pool.sportCode.toLowerCase()}/${pool.key}`}
        icon={<Icon type={pool.sportCode} />}
        netJackpotAmount={pool.netJackpotAmount}
      />
    )
  }

  return <ErroredJackpot />
}

function getDisplayEndTime(endTime: Date) {
  const dayjsEndTime = dayjs(endTime)
  return (
    <ListTime>
      {dayjsEndTime.isSame(Date.now(), 'd')
        ? dayjsEndTime.format('HH:mm')
        : dayjsEndTime.format('D MMM')}
    </ListTime>
  )
}

interface JackpotContentProps {
  title: string
  subTitle: string
  endTime?: Date
  prefix?: string
  badgeText: string
  href: string
  icon: JSX.Element
  netJackpotAmount: number
}

function JackpotContent({
  title,
  subTitle,
  endTime,
  prefix,
  badgeText,
  href,
  icon,
  netJackpotAmount,
}: JackpotContentProps): JSX.Element {
  return (
    <ListLink href={href}>
      <Grid>
        <GridCell width='3em' align='center'>
          <ListBadge prefix={prefix} color={hexColors.sushi}>
            {badgeText}
          </ListBadge>
          {endTime ? getDisplayEndTime(endTime) : ''}
        </GridCell>
        <GridCell width='3em' align='center'>
          {icon}
        </GridCell>
        <GridCell>
          <JackpotTitleStyled>{title}</JackpotTitleStyled>
          <JackpotTextStyled>{subTitle}</JackpotTextStyled>
          <JackpotTextStyled>
            <Money amount={netJackpotAmount} />
          </JackpotTextStyled>
        </GridCell>
      </Grid>
    </ListLink>
  )
}
