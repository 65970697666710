import React from 'react'
import { SearchMessageDisplayStyled } from './InitialDisplay.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { SearchTextLabel } from '../SearchTextLabel/SearchTextLabel'
import {
  SearchTextLabelFontSize,
  SearchTextLabelLinkStyled,
} from '../SearchTextLabel/SearchTextLabelStyled.styles'
import { colors } from '@core/Settings'
import { StructuredSearchRoute } from '@core/Areas/StructuredSearch/constants'
import { OverlayClose } from '@core/Components/Overlay'

export function InitialDisplay() {
  return (
    <SearchMessageDisplayStyled data-tid-search-upcoming-races>
      <Icon size='6rem' type='allraces' color={colors.search.initialText} />
      <SearchTextLabel size={SearchTextLabelFontSize.Medium}>
        Search for upcoming races...
      </SearchTextLabel>
      <SearchTextLabelLinkStyled
        id='advanced-search-link'
        size={SearchTextLabelFontSize.Small}
        href={`/#${StructuredSearchRoute}`}
        onClick={() => OverlayClose()}
      >
        Advanced search
      </SearchTextLabelLinkStyled>
    </SearchMessageDisplayStyled>
  )
}
