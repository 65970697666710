import container from '../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { registerComponentWithParams } from '@classic/AppUtils/Framework/Components/koComponentRegistration'
import { PyosSelectViewModel } from './PyosSelectViewModel'
import template from './superpick.tpl.html'

registerComponentWithParams<PyosSelectViewModel>({
  componentName: 'superpick',
  template,
  container: container,
  resolver: (container: interfaces.Container) =>
    container.get<PyosSelectViewModel>('PyosSelectViewModel'),
})
