import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import React from 'react'
import { ActivityStatementContainer } from '../ActivityStatement.styles'

const CannotFetchData = (): JSX.Element => {
  return (
    <MainContainer>
      <ContentHeader title='Activity Statement Request' />

      <ActivityStatementContainer>
        <NoticeBox
          role='alert'
          testId='unable-to-fetch-account-details'
          noticeBoxType={NoticeBoxTypes.Error}
          title='Could not fetch account details'
          subtitle='We were unable to fetch your account details'
          hasBorder
        />
      </ActivityStatementContainer>
    </MainContainer>
  )
}

export default CannotFetchData
