import React from 'react'
import { RemoveButtonStyled } from './BettingHeader.styles'
import { Icon } from '@mobi/component-library/Common/Icon'

interface RemoveButtonProps {
  onClick: () => void
}

export function RemoveButton({ onClick }: RemoveButtonProps) {
  return (
    <RemoveButtonStyled data-tid-remove-button='' onClick={onClick}>
      <Icon type='trash' size='1em' />
    </RemoveButtonStyled>
  )
}
