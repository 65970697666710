import React from 'react'
import { FormulaButtonStyled, NameStyled, CombinationsStyled } from './Formula.styles'
import { FormulaCombination } from './types'

interface FormulaButtonProps {
  formulaCombination: FormulaCombination
  onClick(formula: number, wasSelected: boolean): void
}

export function FormulaButton({
  formulaCombination: { formula, name, numberOfCombinations, isSelected },
  onClick,
}: FormulaButtonProps) {
  return (
    <FormulaButtonStyled isSelected={isSelected} onClick={() => onClick(formula, isSelected)}>
      <NameStyled>{name}</NameStyled>
      <CombinationsStyled>x{numberOfCombinations}</CombinationsStyled>
    </FormulaButtonStyled>
  )
}
