import React from 'react'
import { BannerArea } from '../types'
import { BannerPicture } from './BannerPicture'

import fallbackImage from './images/normal.v1.jpg'
import fallbackWideImage from './images/wide.v1.jpg'

export const FallBackBanner = ({ areaName }: { areaName: BannerArea }) => (
  <BannerPicture image={fallbackImage} imageWide={fallbackWideImage} areaName={areaName} />
)
