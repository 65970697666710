import React from 'react'
import { setTemporaryWidthOfPreviewElement } from '../helpers'
import { createImageUrlFromHtml } from '../helpers/createImageUrlFromHtml'

export const useImageOutputDevTool = (
  htmlEl: React.RefObject<HTMLDivElement>,
  shouldHideCost: boolean
): [clickHandler: () => void, outputImage: React.ReactElement | null] => {
  const clickCountRef = React.useRef<number>(0)
  const timerIdRef = React.useRef<number>()
  const [isDevModeEnabled, setIsDevModeEnabled] = React.useState(false)
  const [outputImage, setOutputImage] = React.useState<React.ReactElement | null>(null)

  React.useEffect(() => {
    if (!isDevModeEnabled || !htmlEl.current) {
      setOutputImage(null)
      return
    }
    const el = htmlEl.current
    const resetTempWidth = setTemporaryWidthOfPreviewElement(el)
    createImageUrlFromHtml(el).then(imageUrl => {
      setOutputImage(
        React.createElement('figure', { style: { margin: '1rem' } }, [
          React.createElement(
            'figcaption',
            null,
            React.createElement(
              'pre',
              { style: { color: 'white', marginBottom: 0 } },
              'DEV ONLY - Preview of output image:'
            )
          ),
          React.createElement('img', { src: imageUrl, style: { maxWidth: '100%' } }),
        ])
      )
      resetTempWidth()
    })
  }, [isDevModeEnabled, htmlEl, shouldHideCost])

  const clickHandler = React.useCallback(() => {
    clickCountRef.current = ++clickCountRef.current
    clearTimeout(timerIdRef.current)
    if (clickCountRef.current === 7) setIsDevModeEnabled(curr => !curr)
    timerIdRef.current = window.setTimeout(() => (clickCountRef.current = 0), 800)
  }, [])

  return [clickHandler, outputImage]
}
