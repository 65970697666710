import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  AppHeaderContainerStyled as Container,
  AppHeaderInnerStyled,
  AppHeaderRightAlignedContainerStyled as RightAlign,
  AppHeaderStyled,
  HamburgerStyled,
} from './AppHeader.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import Toast from '@core/Components/Toast'
import {
  AppHeaderBackButton as BackButton,
  AppHeaderJoinButton as JoinButton,
  AppHeaderLoginButton as LoginButton,
  AppHeaderLogo as Logo,
} from './Components/Buttons/Buttons'
import { useKambiVisible, useStickyOnScroll } from './hooks'
import { BetslipButton } from './Components/BetslipButton'
import { SearchButton } from '../Search/Components/Buttons/SearchButton'
import { useLogon, usePrevious } from '@core/Utils/hooks'
import {
  AccountDropDownBGStyled,
  BG_ANIMATION_TIMEOUT_MS,
  Wallet,
} from './Components/AccountDropDown'
import { refetch as refetchSpecialTokens } from './Components/AccountDropDown/Components/helpers/useGetSpecialTokens'
import { usePendingTicketsCount } from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/usePendingTicketsCount'
import { HamburgerMenu } from './Components/HamburgerMenu/HamburgerMenu'
import { isReactNativeApp, subscribeToNative } from '@mobi/web-native-comms/web'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { BalancePill } from './Components/Balance/BalancePill'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackHamburgerMenuEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import {
  getIsAppHeaderStickyEnabled,
  getIsAppHeaderVisible,
  getIsDropDownOpen,
  getIsHamburgerMenuOpen,
  getShouldDisableAppHeaderRender,
} from './Store/selectors'
import { toggleIsDropDownOpen, toggleIsHamburgerMenuOpen } from './Store'

export const AppHeader: React.FC<{}> = () => {
  const appHeaderRef = React.useRef<HTMLDivElement>(null)
  const betslipButtonRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const isDropDownOpen = useSelector(getIsDropDownOpen)
  const isAppHeaderVisible = useSelector(getIsAppHeaderVisible)
  const isHamburgerMenuOpen = useSelector(getIsHamburgerMenuOpen)
  const isStickyEnabled = useSelector(getIsAppHeaderStickyEnabled)
  const shouldDisableAppHeaderRender = useSelector(getShouldDisableAppHeaderRender)

  let { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)

  const { isLoggedIn } = useLogon()

  const shouldRenderBG = useRenderTimeoutControl({
    shouldRender: isDropDownOpen,
    timeoutMs: BG_ANIMATION_TIMEOUT_MS,
  })
  const shouldLoadPendingBetsForAccountDropdown = shouldRenderBG && !!isDropDownOpen
  const shouldLoadPendingBets =
    !!isLoggedIn && (shouldLoadPendingBetsForAccountDropdown || !!isHamburgerMenuOpen)

  const { doPendingBetsExceedMaxCount, pendingBetsCount } = usePendingTicketsCount({
    enabled: shouldLoadPendingBets,
  })

  const [isKambiVisible, isKambiBetslipMaximized] = useKambiVisible()

  useStickyOnScroll(appHeaderRef, isAppHeaderVisible)

  React.useEffect(() => {
    if ((isDropDownOpen && !isAppHeaderVisible) || pathname !== prevPathname) {
      dispatch(toggleIsDropDownOpen(false))
    }
  }, [isAppHeaderVisible, isDropDownOpen, pathname, prevPathname, dispatch])

  React.useEffect(() => {
    if (isDropDownOpen) refetchSpecialTokens()
  }, [isDropDownOpen])

  // TODO: Move into NativeServices folder, use specific hook in <App />
  React.useEffect(() => {
    if (!isReactNativeApp) return
    const { dispose } = subscribeToNative('PROMPT_LOGIN', showLogin)
    return () => dispose()
  }, [])

  if (shouldDisableAppHeaderRender) return null

  const shouldRenderBackButton =
    (pathname !== '/' && !pathname.startsWith('/deposit')) || isKambiVisible

  const shouldLowerZIndex =
    (isKambiVisible && isKambiBetslipMaximized && !shouldRenderBG) ||
    (!isStickyEnabled && !shouldRenderBG)

  return (
    <>
      <HamburgerMenu
        isOpen={isHamburgerMenuOpen}
        onClose={() => dispatch(toggleIsHamburgerMenuOpen(false))}
        pendingBetsCount={pendingBetsCount}
        doPendingBetsExceedMaxCount={doPendingBetsExceedMaxCount}
      />
      <Container id='header-container'>
        <AppHeaderStyled
          ref={appHeaderRef}
          isStickyEnabled={isStickyEnabled}
          shouldLowerZIndex={shouldLowerZIndex}
        >
          <AppHeaderInnerStyled>
            <HamburgerStyled
              data-testid='header-menu-btn'
              role='button'
              aria-label='Open menu'
              onClick={() => {
                dispatch(toggleIsHamburgerMenuOpen(true))
                trackHamburgerMenuEvent(keys.hamburgerMenuClicked, {
                  item: 'hamburger-open-button',
                })
              }}
            >
              <Icon type='hamburger' title='Menu' />
            </HamburgerStyled>

            {shouldRenderBackButton && <BackButton />}

            <Logo compactBreakpoint='px375' />

            <RightAlign>
              {!isKambiVisible && <SearchButton />}

              {isLoggedIn ? (
                <BalancePill renderContext='app-header' />
              ) : (
                <>
                  <JoinButton />
                  <LoginButton />
                </>
              )}

              {!isKambiVisible && (
                <div ref={betslipButtonRef}>
                  <BetslipButton />
                </div>
              )}
            </RightAlign>
          </AppHeaderInnerStyled>

          {isLoggedIn && (
            <Wallet
              doPendingBetsExceedMaxCount={doPendingBetsExceedMaxCount}
              pendingBetsCount={pendingBetsCount}
            />
          )}
        </AppHeaderStyled>
      </Container>

      {shouldRenderBG && (
        <AccountDropDownBGStyled
          onClick={() => dispatch(toggleIsDropDownOpen(false))}
          isVisible={isDropDownOpen}
        />
      )}

      <Toast isHeaderVisible={isAppHeaderVisible} />
    </>
  )
}
