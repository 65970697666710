import React from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { useActiveTab } from '../../Hooks/useActiveTab'
import { WebOnlyNav } from './Components/WebOnlyNav/WebOnlyNav'

export const TabNavigation = () => {
  const activeTab = useActiveTab()

  // See Native App for React Native Bottom Tab Navigation
  if (isReactNativeApp) return null

  return <WebOnlyNav activeTab={activeTab} />
}
