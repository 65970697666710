import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'

export const ToggleFixedFlucs = createSignal('ToggleFixedFlucs')
export const ToggleShowAllForm = createSignal('ToggleShowAllForm')
export const ToggleMarketMovers = createSignal('ToggleMarketMovers')
export const ToggleDoubleProvDiv = createSignal('ToggleDoubleProvDiv')
export const ToggleQuaddieProvDiv = createSignal('ToggleQuaddieProvDiv')
export const ToggleInfoToolbarVisible = createSignal('ToggleInfoToolbarVisible')
export const ToggleStewardsCommentsVisible = createSignal('ToggleStewardsCommentsVisible')
export const ToggleTipsVisible = createSignal('ToggleTipsVisible')
export const ToggleResultsVisible = createSignal('ToggleResultsVisible')
export const ToggleFieldVisible = createSignal('ToggleFieldVisible')
export const ToggleRaceReplayVisible = createSignal('ToggleRaceReplayVisible')
export const ToggleRacePreviewVisible = createSignal('ToggleRacePreviewVisible')

export interface BettingV2CommandState {
  showFixedFlucs: boolean
  showAllForm: boolean
  showMarketMovers: boolean
  showDoubleProvDiv: boolean
  showQuaddieProvDiv: boolean
  infoToolBarVisible: boolean
  commentsVisible: boolean
  tipsVisible: boolean
  resultsVisible: boolean
  fieldVisible: boolean
  replayVisible: boolean
  previewVisible: boolean
}

export const bettingV2CommandStateDefaultState: BettingV2CommandState = {
  showFixedFlucs: false,
  showAllForm: false,
  showMarketMovers: false,
  showDoubleProvDiv: false,
  showQuaddieProvDiv: false,
  infoToolBarVisible: false,
  commentsVisible: false,
  tipsVisible: false,
  resultsVisible: true,
  fieldVisible: false,
  replayVisible: false,
  previewVisible: false,
}

export interface BettingV2CommandStateRecord
  extends TypedRecord<BettingV2CommandStateRecord>,
    BettingV2CommandState {}
export const BettingV2CommandStateFactory = makeTypedFactory<
  BettingV2CommandState,
  BettingV2CommandStateRecord
>(bettingV2CommandStateDefaultState)

export function bettingV2CommandDriver(
  state = BettingV2CommandStateFactory(),
  signal: Signal
): BettingV2CommandStateRecord {
  switch (signal.tag) {
    case ToggleFixedFlucs: {
      return state.merge({
        showFixedFlucs: !state.showFixedFlucs,
      })
    }

    case ToggleShowAllForm: {
      return state.merge({
        showAllForm: !state.showAllForm,
      })
    }

    case ToggleMarketMovers: {
      return state.merge({
        showMarketMovers: !state.showMarketMovers,
      })
    }

    case ToggleDoubleProvDiv: {
      return state.merge({
        showDoubleProvDiv: !state.showDoubleProvDiv,
      })
    }

    case ToggleQuaddieProvDiv: {
      return state.merge({
        showQuaddieProvDiv: !state.showQuaddieProvDiv,
      })
    }

    case ToggleInfoToolbarVisible: {
      return state.merge({
        infoToolBarVisible: !state.infoToolBarVisible,
      })
    }

    case ToggleStewardsCommentsVisible: {
      return state.merge({
        commentsVisible: !state.commentsVisible,
      })
    }

    case ToggleTipsVisible: {
      return state.merge({
        tipsVisible: !state.tipsVisible,
      })
    }

    case ToggleResultsVisible: {
      return state.merge({
        resultsVisible: true,
        fieldVisible: false,
      })
    }

    case ToggleFieldVisible: {
      return state.merge({
        resultsVisible: false,
        fieldVisible: true,
      })
    }

    case ToggleRaceReplayVisible: {
      return state.merge({
        replayVisible: !state.replayVisible,
      })
    }

    case ToggleRacePreviewVisible: {
      return state.merge({
        previewVisible: !state.previewVisible,
      })
    }

    default: {
      return state
    }
  }
}

export const state$ = attachDriver<BettingV2CommandStateRecord>({
  path: 'betting-v2-commands',
  driver: bettingV2CommandDriver,
})
