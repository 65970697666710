import { IEntitlementService } from './IEntitlementService'
import { EntitlementsDataTransferObject } from '../DataTransferObjects/EntitlementsDataTransferObject'
import type { IUrlProvider } from '../Utils/IUrlProvider'
import type { IApiService } from '../../Foundation/Services/ApiServiceInjectable'
import { injectable, inject } from 'inversify'

@injectable()
export class EntitlementService implements IEntitlementService {
  private apiService: IApiService

  constructor(
    @inject('IUrlProvider') urlProvider: IUrlProvider,
    @inject('IApiService') apiService: IApiService
  ) {
    this.urlProvider = urlProvider
    this.apiService = apiService
  }

  public isEntitled(serviceName: string): Promise<EntitlementsDataTransferObject> {
    switch (serviceName) {
      case 'giddyUp':
        return this.isGiddyUpEntitled()
      default:
        return Promise.resolve(new EntitlementsDataTransferObject())
    }
  }

  private isGiddyUpEntitled(): Promise<EntitlementsDataTransferObject> {
    const url = this.urlProvider.getGiddyUpEntitlement
    const promise = this.apiService.get<EntitlementsDataTransferObject>({ url })
    return promise.then()
  }

  private urlProvider: IUrlProvider
}
