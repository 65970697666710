import React from 'react'
import styled from '@emotion/styled'
import { PillButton } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { spacing, radius, shadow, font, colors } from '@mobi/component-library/Theme/Common'
import type { ToastItem } from '@mobi/component-library/Feedback/Toast/types'
import type { globalToastEmitter } from '../../helpers/events'

const enum LocalConstants {
  LightThemeClass = 'js-toast--light-theme',
  DarkThemeClass = 'js-toast--dark-theme',
  DefaultTimeoutMs = 3_000, // 3s,
  FullWidth = 'js-toast--full-width',
}

export const Toast: React.FC<{
  toast: ToastItem
  onMount: OnMountFn
  toastEmitter: typeof globalToastEmitter
}> = React.memo(({ toast, onMount, toastEmitter }) => {
  const toastRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    onMount({ id: toast.id, ref: toastRef })
  }, [onMount, toast])

  React.useEffect(() => {
    if (toast.timeoutMs === Infinity) return
    const timerId = setTimeout(() => {
      toastEmitter.emit('removeToast', { id: toast.id })
    }, toast.timeoutMs || LocalConstants.DefaultTimeoutMs)
    return () => {
      clearTimeout(timerId)
    }
  }, [toast, toastEmitter])

  return (
    <ToastStyled
      ref={toastRef}
      className={`${toast.isFullWidth ? LocalConstants.FullWidth : ''} ${
        toast.theme === 'dark'
          ? (LocalConstants.DarkThemeClass as string)
          : (LocalConstants.LightThemeClass as string)
      } `}
    >
      <div>{toast.content}</div>

      <div>
        {toast.isDismissable && (
          <PillButton
            size='xs'
            type='button'
            isIconOnlyButton
            color='tertiary_grey'
            aria-label='Dismiss toast'
            onClick={() => toastEmitter.emit('removeToast', { id: toast.id })}
          >
            <Icon name='SolidXClose' size='1.6rem' color='inherit' />
          </PillButton>
        )}
      </div>
    </ToastStyled>
  )
})

// ======
// Styles
// ======

const ToastStyled = styled.div({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
  padding: `${spacing.sm} ${spacing.md}`,

  borderRadius: radius.lgx2,
  boxShadow: shadow.md,
  pointerEvents: 'all',

  fontFamily: font.family.primary,
  fontWeight: font.weight.medium,
  fontSize: font.size.lg.fontSize,
  letterSpacing: font.size.lg.letterSpacing,
  lineHeight: font.size.lg.lineHeight,

  animationName: 'toast-enter',
  animationFillMode: 'forwards',
  animationDuration: '300ms',
  animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',

  ['&.' + LocalConstants.FullWidth]: {
    alignSelf: 'stretch',
  },

  ['&.' + LocalConstants.DarkThemeClass]: {
    border: `1px solid ${colors.studio[800]}`,
    backgroundColor: colors.studio[900],
    color: colors.white,

    '> div:last-of-type > button:not(:hover, :active)': { color: 'inherit' },
  },

  ['&.' + LocalConstants.LightThemeClass]: {
    border: `1px solid ${colors.neutral[200]}`,
    backgroundColor: colors.white,
    color: colors.black,
  },

  '@keyframes toast-enter': {
    '0%': { opacity: 0, transform: 'translateY(150%)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },

  '> div:first-of-type': { flex: '1 0 auto' },

  '> div:last-of-type': {
    display: 'flex',

    '&:empty': { display: 'none' },
  },
})

// =====
// Types
// =====

type OnMountFn = (arg: { id: ToastItem['id']; ref: React.RefObject<HTMLDivElement> }) => void
