import React, { useRef, useState } from 'react'
import {
  ButtonsContainerStyled,
  ButtonStyled,
  ButtonWrapperStyled,
  DescriptionStyled,
  IconBackground,
  IconContainer,
  IconStyled,
  PrimaryButtonStyled,
  SpecialTileContainer,
  TermsAndConditionsHeaderStyled,
  TermsAndConditionsStyled,
  TermsAndConditionsTextStyled,
  TileTitle,
  TitleContainer,
} from './SpecialsPage.styles'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { Link, useRouteMatch } from 'react-router-dom'
import { build } from './routeBuilder'

export interface SpecialTileProp {
  title: string
  description: string
  iconCode: keyof typeof iconPaths
  isCurrent?: boolean
  isPowerPlay?: boolean
  maxRewardAmount?: number
  sequence?: number
  termsAndConditions: string
  betNowNavigator: BetNowNavigator
  competitionSeqs?: null | number
  eventSeqs?: null | number
  marketSeqs?: null | number
  productGroupCode?: null | number
  racingProductOptions?: null | number
  rewardTypeCode?: null | number
  rewardTypeCodeName?: null | number
  statusCode?: null | number
  sportSeqs?: null | number
  statusCodeName?: null | number
  tournamentSeqs?: null | number
}

interface BetNowNavigator {
  competitionName: string
  countryName: string
  eventName: string
  hasCountries: boolean
  marketName: string
  multiCompetition: boolean
  multiEvent: boolean
  multiMarket: boolean
  multiTournament: boolean
  sportName: string
  tournamentName?: string
  categoryCode: string
  competitionSequence: number
  eventDate: string
  eventSequence: number
  groupCode: string
  marketSequence: number
  sportSequence: number
  tournamentSequence?: number
}

export function SpecialTile({ special }: { special: SpecialTileProp }): JSX.Element {
  const isSuperPick = special.title.toLocaleLowerCase() === 'superpick' ? true : false
  const [isExpanded, setIsExpanded] = useState(false)
  const marketElementToScrollTo = useRef<HTMLDivElement>(null)

  const handleButtonToggle = () => {
    if (isExpanded) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
      window.setTimeout(() => {
        if (!!marketElementToScrollTo && marketElementToScrollTo.current) {
          window.scrollTo({
            top: marketElementToScrollTo.current.offsetTop,
            behavior: 'smooth',
          })
        }
      }, 10)
    }
  }

  const isUnexpected = special.title === 'Unexpected Race Special'

  return (
    <div ref={marketElementToScrollTo}>
      <SpecialTileContainer isSuperPick={isSuperPick} data-tid-special-tile-container=''>
        <TitleContainer>
          <IconContainer isSuperPick={isSuperPick}>
            <IconStyled
              type={
                isSuperPick
                  ? 'specials'
                  : isUnexpected
                    ? 'tab'
                    : special.iconCode === 'FB'
                      ? 'FBL'
                      : special.iconCode
              }
              size='4rem'
              color={isSuperPick ? '#f2a900' : '#fff'}
            />
            {isSuperPick && <IconBackground />}
          </IconContainer>
          <TileTitle data-tid-special-tile-title=''>{special.title}</TileTitle>
        </TitleContainer>
        <DescriptionStyled data-tid-special-tile-description=''>
          {special.description}
        </DescriptionStyled>
        <ButtonsContainerStyled isSuperPick={isSuperPick}>
          <Buttons
            isSuperPick={isSuperPick}
            special={special}
            handleButtonToggle={handleButtonToggle}
          />
        </ButtonsContainerStyled>
      </SpecialTileContainer>
      {!isSuperPick && (
        <TermsAndConditions
          termsAndConditions={special.termsAndConditions}
          isExpanded={isExpanded}
        />
      )}
    </div>
  )
}

interface ButtonsProps {
  isSuperPick: boolean
  special: SpecialTileProp
  handleButtonToggle: () => void
}

export function Buttons({ isSuperPick, special, handleButtonToggle }: ButtonsProps): JSX.Element {
  const { url } = useRouteMatch()
  const buildUrl = build(special)
  return (
    <>
      {isSuperPick && (
        <Link style={{ textDecoration: 'none' }} to={`${url}/terms`}>
          <PrimaryButtonStyled data-tid-special-tile-primary-button='' isSuperPick={isSuperPick}>
            Learn More
          </PrimaryButtonStyled>
        </Link>
      )}
      {!isSuperPick && (
        <ButtonWrapperStyled>
          {buildUrl && (
            <a style={{ textDecoration: 'none', flexGrow: 1 }} href={buildUrl}>
              <PrimaryButtonStyled>Bet Now</PrimaryButtonStyled>
            </a>
          )}
          <ButtonStyled
            onClick={handleButtonToggle}
            data-tid-special-tile-termsandconditions-button=''
          >
            T&amp;Cs
          </ButtonStyled>
        </ButtonWrapperStyled>
      )}
    </>
  )
}

function TermsAndConditions({
  termsAndConditions,
  isExpanded,
}: {
  termsAndConditions: string
  isExpanded: boolean
}): JSX.Element {
  return (
    <TermsAndConditionsStyled
      isExpanded={isExpanded}
      data-tid-special-tile-terms-conditions-dropdown=''
    >
      <TermsAndConditionsHeaderStyled>TERMS &amp; CONDITIONS</TermsAndConditionsHeaderStyled>
      <TermsAndConditionsTextStyled>{termsAndConditions}</TermsAndConditionsTextStyled>
    </TermsAndConditionsStyled>
  )
}
