import * as immutable from 'immutable'
import { v4 as uuid } from 'uuid'
import { attachDriver, Signal, createSignal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'

export interface ToastItem {
  id: string
  message: string
  position?: 'top' | 'bottom'
  type?: 'default' | 'error'
  /** Number of seconds eg. 0.2, 3 or 0 for infinite */
  timeout?: number
  spinner?: boolean
}

// Signals
export const RegisterToast = createSignal<Partial<ToastItem> & Pick<ToastItem, 'message'>>(
  'RegisterToast'
)
export const RemoveToast = createSignal<string>('RemoveToast')

// Driver State
export interface ToastDriverState {
  items: immutable.List<ToastItem>
}
export const defaultState: ToastDriverState = { items: immutable.List() }

export interface ToastStateRecord extends TypedRecord<ToastStateRecord>, ToastDriverState {}
export const ToastStateFactory = makeTypedFactory<ToastDriverState, ToastStateRecord>(defaultState)

export const driver = (state = ToastStateFactory(), signal: Signal): ToastStateRecord => {
  switch (signal.tag) {
    case RegisterToast: {
      if (state.items.some(item => item.id === signal.data.id)) {
        return state
      }
      return state.merge({
        items: state.items.push({ id: uuid(), position: 'bottom', ...signal.data }),
      })
    }

    case RemoveToast: {
      return state.merge({
        items: state.items.filter(item => item.id !== signal.data),
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver({ driver, path: `Toast` })
