import styled from '@emotion/styled'
import { ExpandablePanelComponentTheme } from '@core/Components/ExpandablePanel/themes'
import { isEmpty } from '@core/Utils/object/object'
import { colors } from '@core/Settings'

interface Theme {
  theme?: ExpandablePanelComponentTheme
}

export const BetslipExpandablePanelStyled = styled('div')<Theme>(
  {
    position: 'relative',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          color: theme.header.text.color,
        }
)

interface BetslipExpandablePanelHeaderStyled extends Theme {
  isExpanded: boolean
}

export const BetslipExpandablePanelHeaderStyled = styled(
  'header'
)<BetslipExpandablePanelHeaderStyled>(
  {
    cursor: 'pointer',
    padding: '1.4rem 1.5rem',
  },
  ({ theme, isExpanded }) =>
    isEmpty(theme)
      ? {}
      : {
          background: theme.header.background,
          color: theme.header.text.color,
          borderBottom: isExpanded
            ? `0.1rem solid ${theme.header.background}`
            : `0.1rem solid #666`,
        }
)

export const BetslipExpandablePanelTitleStyled = styled('div')({
  fontWeight: 'bold',
  marginRight: '1rem',
  fontSize: '1.4rem',
})

export const BetslipExpandablePanelToggleStyled = styled('div')({
  position: 'relative',
})

export const BetslipExpandablePanelBadgeStyled = styled(BetslipExpandablePanelTitleStyled)({
  width: '2rem',
  height: '2rem',
  background: colors.betslip.expandable.badge.background,
  color: colors.betslip.expandable.badge.text,
  borderRadius: '50%',
  textAlign: 'center',
})
