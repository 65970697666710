import type { Tabs, Ticket, TicketStatus, TicketLeg } from '@core/Areas/AccountActivity/types'
import { isSameRaceMulti } from './legTypes'

export function getStatusText(status: TicketStatus) {
  switch (status) {
    case 'NoPayout':
      return 'No Payout'
    case 'Abandoned':
      return 'Abandoned - Refunded'
    case 'Scratched':
      return 'Scratched - Refunded'
    case 'Cancelled':
      return 'Cancelled - Refunded'
    default:
      return status
  }
}

export const getTicketStatus = (ticket: Ticket, activeTab: Tabs | null): TicketStatus => {
  const isCancelled = ticket.status === 'Cancelled'
  const isWin = ticket.status === 'Resolved' && ticket.payValue > 0
  const isPending = activeTab === 'pending' || activeTab === 'rebet'
  const noPayout = ticket.payValue === 0
  const isAbandoned = ticket.legs?.every((leg: TicketLeg) => leg.result === 'ABD')
  const isScratched = ticket.legs?.every((leg: TicketLeg) => leg.result === 'SCR')

  const isRefund =
    ticket.legs?.every(isScratchedOrAbandoned) ||
    (isSameRaceMulti(ticket) && ticket.legs?.some(isScratchedOrAbandoned))

  const isPartiallyRefunded =
    !isSameRaceMulti(ticket) && // partial refunds never apply to SRM
    ticket.payValue > 0 &&
    ticket.legs?.some(isScratchedOrAbandoned) && // at least 1 leg is scratched OR abandoned
    ticket.legs?.some(leg => !isScratchedOrAbandoned(leg)) // at least 1 leg is NOT scratched AND NOT abandoned

  return (
    (isPartiallyRefunded && 'Partially Refunded') ||
    (isAbandoned && 'Abandoned') ||
    (isScratched && 'Scratched') ||
    (isRefund && 'Refunded') ||
    (isWin && 'Won') ||
    (isCancelled && 'Cancelled') ||
    (isPending && 'Pending') ||
    (noPayout && 'NoPayout') ||
    null
  )
}

function isScratchedOrAbandoned(leg: TicketLeg) {
  return leg.result === 'SCR' || leg.result === 'ABD'
}

// in addition to the standard 'Refunded' state, this method includes the 'psuedo refunded' states (abandoned, scratched, and cancelled) which are derived separately elsewhere, e.g. footer.tsx
const refundedTicketStatuses: TicketStatus[] = ['Refunded', 'Abandoned', 'Scratched', 'Cancelled']

export function isRefundedStatus(ticket: Ticket): boolean {
  return refundedTicketStatuses.includes(getTicketStatus(ticket, null))
}
