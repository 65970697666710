import React, { memo, type InputHTMLAttributes } from 'react'
import { CheckboxInputStyled, CheckboxInputLabelStyled } from './CheckboxInput.styles'

export type CheckboxInputProps = Partial<InputHTMLAttributes<HTMLInputElement>> & {
  label: React.ReactNode
  id: string
  boxPosition?: 'center'
}

export const CheckboxInput = memo<CheckboxInputProps>(
  ({ label, id, boxPosition, ...inputProps }) => {
    const checkboxTestId = { [`data-tid-${id}`]: '' }
    return (
      <div>
        <CheckboxInputStyled type='checkbox' id={id} name={id} {...inputProps} />
        <CheckboxInputLabelStyled htmlFor={id} boxPosition={boxPosition} {...checkboxTestId}>
          <span>{label}</span>
        </CheckboxInputLabelStyled>
      </div>
    )
  }
)

CheckboxInput.displayName = 'CheckboxInput'
