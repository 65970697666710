import type {
  BetError,
  BetErrorType,
  BetPrices,
  BetSlipItem,
  BetSlipResponse,
  BetSpecialOffer,
  FobSelection,
  Selection,
} from '@mobi/betslip/types'
import {
  isFobBetReceiptResponse,
  isFobSelection,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { getBetErrorType, mapErrorResponseCodeToType } from './errors'

// TODO: Straight copy from web, see if can be improved

export function mapResponse(
  currentItem: BetSlipItem,
  responses: BetSlipResponse[],
  multiBetResponse: BetSlipResponse | undefined,
  isRefreshing: boolean = false,
  ignorePriceChanges: boolean = false
): BetSlipItem {
  const modifiedItem = { ...currentItem }
  const hasMultiBetResponse = !!multiBetResponse && !!multiBetResponse.legs

  if (hasMultiBetResponse) {
    const matchedLegInMultiBetResponse = ((multiBetResponse && multiBetResponse.legs) || []).find(
      leg => leg.id === modifiedItem.id
    )

    if (matchedLegInMultiBetResponse) {
      const matchedSingleInResponse = responses.find(
        res => res.id === matchedLegInMultiBetResponse.id
      )
      const matchedSingleInResponseError = getBetErrorType(matchedSingleInResponse)
      const matchedLegInMultiBetErrorType = getBetErrorType(matchedLegInMultiBetResponse)

      const newSelection = buildSelection(
        modifiedItem,
        ignorePriceChanges,
        matchedLegInMultiBetResponse.prices
      )

      const newMultiBetLegError = mapMultiBetError(matchedLegInMultiBetErrorType)
      const hasFatalError: boolean =
        isFatalErrorType(matchedLegInMultiBetErrorType) ||
        isFatalErrorType(matchedSingleInResponseError) ||
        isFatalErrorType(newMultiBetLegError?.betErrorType)

      modifiedItem.selection = newSelection
      modifiedItem.multiBetLegError = newMultiBetLegError || null
      modifiedItem.isInMulti = hasFatalError ? false : modifiedItem.isInMulti

      if (hasFatalError) {
        modifiedItem.betErrorType = matchedLegInMultiBetErrorType
        modifiedItem.errorMessage = matchedLegInMultiBetResponse?.error?.message || ''
      }
    }
  }

  const response = responses.find(res => res.id === modifiedItem.id)
  if (!response) {
    return modifiedItem
  }

  const responseLeg = response && response.legs && response.legs[0]

  modifiedItem.selection = buildSelection(
    modifiedItem,
    ignorePriceChanges,
    responseLeg && responseLeg.prices
  )

  modifiedItem.isSuperPickAvailable =
    modifiedItem.specialOffers && modifiedItem.specialOffers.length > 0

  modifiedItem.specialOffers = updateSpecialOffersFromResponse(
    modifiedItem,
    response,
    isRefreshing
  ) as BetSpecialOffer[]

  modifiedItem.selectedSuperPickOffer =
    (modifiedItem.selectedSuperPickOffer &&
      modifiedItem.specialOffers &&
      modifiedItem.specialOffers.find(
        s =>
          s.specialSeq ===
          (modifiedItem.selectedSuperPickOffer && modifiedItem.selectedSuperPickOffer.specialSeq)
      )) ||
    null

  if (!response.success) {
    const betErrorType = getBetErrorType(response)
    const shouldClearSpecials = betErrorType && betErrorType === 'SpecialsError'

    modifiedItem.betErrorType = betErrorType
    modifiedItem.errorMessage = getErrorMessage(betErrorType, response.error)
    modifiedItem.investment = getNewInvestmentAfterResponse(modifiedItem, betErrorType)
    modifiedItem.selectedSuperPickOffer = shouldClearSpecials
      ? null
      : modifiedItem.selectedSuperPickOffer
    modifiedItem.isSuperPickAvailable = shouldClearSpecials ? false : true

    return modifiedItem
  }

  // Clear existing errors on successful response
  modifiedItem.errorMessage = ''
  modifiedItem.betErrorType = undefined

  // Check for receipt
  const receiptItem = response.receipt

  if (receiptItem) {
    const specialOffers =
      !isToteSelection(modifiedItem.selection) && isFobBetReceiptResponse(receiptItem)
        ? receiptItem.specialOffers
        : null

    modifiedItem.investment = getNewInvestmentAfterResponse(modifiedItem)
    modifiedItem.receipt = specialOffers ? { ...receiptItem, specialOffers } : receiptItem
  }

  return modifiedItem
}

// =============
// Local Helpers
// =============

function updateSpecialOffersFromResponse(
  modifiedItem: BetSlipItem,
  response: BetSlipResponse,
  isRefreshing?: boolean
) {
  // Refresh will update all specials
  if (isRefreshing) {
    return response.specialOffers || []
  }

  // Propose/Commit will only update selected specials
  const hasSelectedSpecialOfferOnResponse =
    modifiedItem.selectedSuperPickOffer && response.specialOffers && response.specialOffers[0]

  if (hasSelectedSpecialOfferOnResponse) {
    return modifiedItem.specialOffers.map(specialOffer =>
      specialOffer.specialSeq === (response.specialOffers && response.specialOffers[0].specialSeq)
        ? response.specialOffers && response.specialOffers[0]
        : specialOffer
    )
  }
  return modifiedItem.specialOffers
}

function buildSelection(
  item: BetSlipItem,
  ignorePriceChanges: boolean,
  responsePrices?: BetPrices
): Selection {
  const selection = item.selection

  if (isFobSelection(selection) && !isStartingPriceSelection(selection)) {
    const newSelection: FobSelection = { ...selection }

    // * ignorePriceChanges: push prices are disgarded during bet confirmation/placement, so
    //   take price changes on propose/confirm actions (but not refresh)
    // * or, if we haven't received a push price (eg. no price change since subscription)
    //   then take this price
    const canOverwritePrices = !ignorePriceChanges || selection.priceSource !== 'push'

    if (canOverwritePrices && responsePrices) {
      if (responsePrices.placePrice) {
        newSelection.placePrice = responsePrices.placePrice
      }
      if (responsePrices.winPrice) {
        newSelection.winPrice = responsePrices.winPrice
      }
      newSelection.priceSource = 'api'
    }
    return newSelection
  } else {
    return item.selection
  }
}

function getErrorMessage(betErrorType?: BetErrorType | null, resError?: BetError | null): string {
  const SingleErrorMapping: Partial<Record<BetErrorType, string>> = {
    HandicapChanged: 'Handicap changed. Bet is no longer valid.',
    InvalidBonusBet: 'Error using Bonus Bet',
    DuplicateBonusBet: 'Bonus Bet can only be used once',
  }

  if (!betErrorType) return ''
  return SingleErrorMapping[betErrorType] || resError?.message || 'Unknown error occurred'
}

function getNewInvestmentAfterResponse(
  item: BetSlipItem,
  errorType?: BetErrorType
): BetSlipBetsState['items'][0]['investment'] {
  const shouldResetInvestment =
    !!item.receipt || isFatalErrorType(errorType) || errorType == 'InvalidBonusBet'
  return {
    win: {
      value: shouldResetInvestment || !item.investment.win ? 0 : item.investment.win.value,
      isBonusBet: shouldResetInvestment ? false : item.investment.win.isBonusBet,
    },
    place: {
      value: shouldResetInvestment || !item.investment.place ? 0 : item.investment.place.value,
      isBonusBet: shouldResetInvestment ? false : item.investment.place.isBonusBet,
    },
    bonusBet: shouldResetInvestment ? undefined : item.investment.bonusBet,
  }
}

function mapMultiBetError(
  betErrorType: BetErrorType | string | undefined
): { betErrorType: BetErrorType; errorMessage: string } | undefined {
  if (betErrorType == undefined) return undefined
  const betError = mapErrorResponseCodeToType(betErrorType)

  if (isFatalErrorType(betError)) {
    return { betErrorType: betError, errorMessage: betError }
  }

  if (betError === 'PricesChanged') {
    return { betErrorType: betError, errorMessage: '' }
  }

  return { betErrorType: 'Unspecified', errorMessage: 'Invalid Leg' }
}
