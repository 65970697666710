import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { changeBlackbookAlertPreference } from '@core/Areas/Blackbook/helpers/changeBlackbookAlertPreference'
import type { BlackbookPreferencesRequestBody } from '@core/Areas/Blackbook/types'
import { trackBlackbookAlertPreferencesUpdated } from '@core/Areas/Blackbook/analytics'
import { radius } from '@mobi/component-library/Theme/Common'
import { useTakeABreak } from '@core/Areas/Account/TakeABreak/Hooks'

export const RaceDayAlertPreference: React.FC<{
  isEmailAlertActive: boolean
  isSmsAlertActive: boolean
  isLoading: boolean
  shouldDisable: boolean
}> = ({ isEmailAlertActive, isSmsAlertActive, isLoading, shouldDisable }) => {
  const { data, isLoading: isTakeABreakLoading } = useTakeABreak()

  const isTakeABreakActive = isTakeABreakLoading || !data || data.inProgress

  const [localStateOverride, setLocalStateOverride] =
    React.useState<BlackbookPreferencesRequestBody>()
  const [isBusy, setIsBusy] = React.useState(false)

  /** Uses optimistic UI updates with rollback */
  const handlePreferenceToggle = (changedAlertType: AlertTypes) => () => {
    setIsBusy(true)

    const currAlertPref = localStateOverride
      ? { ...localStateOverride }
      : {
          SendEmail: isEmailAlertActive,
          SendSms: isSmsAlertActive,
        }
    const newAlertPref = { ...currAlertPref }

    newAlertPref[changedAlertType] = !newAlertPref[changedAlertType]
    setLocalStateOverride(newAlertPref)

    changeBlackbookAlertPreference(newAlertPref, false)
      .catch(() => {
        RegisterToast({
          id: 'blackbook-preference-error',
          message: 'Oops, Unable to Change Alert',
          type: 'error',
          position: 'top',
          timeout: 0,
        })
        setLocalStateOverride(currAlertPref)
      })
      .finally(() => {
        setIsBusy(false)
        trackBlackbookAlertPreferencesUpdated(newAlertPref['SendEmail'], newAlertPref['SendSms'])
      })
  }

  return (
    <WrapperStyled data-testid='blackbook-raceday-alert-preference'>
      <span>Race Day Alerts:</span>

      {isLoading ? (
        <LoadingPlaceholder width='15rem' height='3rem' borderRadius={radius.lg} />
      ) : (
        <>
          <CheckboxInput
            id='notify-sms'
            label='SMS'
            checked={localStateOverride ? localStateOverride.SendSms : isSmsAlertActive}
            onChange={handlePreferenceToggle('SendSms')}
            disabled={isBusy || isTakeABreakActive || shouldDisable}
            data-testid='blackbook-raceday-alert-preference-sms-checkbox'
          />
          <CheckboxInput
            id='notify-email'
            label='Email'
            checked={localStateOverride ? localStateOverride.SendEmail : isEmailAlertActive}
            onChange={handlePreferenceToggle('SendEmail')}
            disabled={isBusy || isTakeABreakActive || shouldDisable}
            data-testid='blackbook-raceday-alert-preference-email-checkbox'
          />
        </>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '5rem',
  padding: '0 1rem',
  background: hexColors.white,
  borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,

  '& > span': {
    marginRight: '1rem',
    fontSize: '1.2rem',
  },

  'input[type="checkbox"] + label': {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '0 1.5rem 0 0',
  },
})

// =====
// Types
// =====

type AlertTypes = {
  [K in keyof BlackbookPreferencesRequestBody]: K
}[keyof BlackbookPreferencesRequestBody]
