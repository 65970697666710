import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IStandardTopPanel } from './IStandardTopPanel'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'
import { BettingInformation } from '../../../Model/BettingInformation'
import template from './standard.top.panel.tpl.html'
import { IObservablePage } from '../../../Model/Observables/IObservablePage'

export function register(): void {
  if (!ko.components.isRegistered('standard-top-panel')) {
    ko.components.register('standard-top-panel', {
      viewModel: {
        createViewModel: (params: {
          raceInfo: IObservablePage
          bettingContext: BettingInformation
          raceNumber: ObservableRaceKey
        }): IStandardTopPanel => {
          const viewModel = container.get<IStandardTopPanel>('IStandardTopPanel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
