import React from 'react'

export const ChevronRight = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_1017_109'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1017_109)'>
        <path d='M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z' fill='#1C1B1F' />
      </g>
    </svg>
  )
}
