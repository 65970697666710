import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'

export const PayPalButtonCaption = styled.div(
  {
    fontFamily: font.family.primary,
    fontWeight: font.weight.medium,
    textAlign: 'center',
    color: colors.neutral['700'],
    width: '100%',
    marginTop: '0.4rem',
  },
  font.size.sm
)
