import React from 'react'
import { PaymentMethod } from '@mobi/api-types'
import { CreditCardAlertLabel } from './CreditCardAlertLabel'
import { CreditCardDisplay } from './CreditCardDisplay'
import { CreditCardListItemStyled, CreditCardListStyled } from './CreditCardList.styled'
import { isCardValid, isSameCard } from '../helpers'

type Props = {
  /**
   * The user's cards.
   */
  cards: PaymentMethod[]
  /**
   * An optional function to render after the credit card list entry
   */
  renderSuffix?: (card: PaymentMethod) => React.ReactNode
  /**
   * An optional function to render the icon
   */
  renderIcon?: (card: PaymentMethod) => React.ReactNode
  /**
   * Whether the items are disabled
   */
  disabled?: boolean
  /**
   * Card that is currently selected
   */
  selectedCard?: PaymentMethod
  /**
   * The handler that fires when a card has been clicked
   */
  onClick?: (card: PaymentMethod) => void
}

export const CreditCardList = ({
  cards,
  renderSuffix,
  renderIcon,
  disabled,
  selectedCard,
  onClick,
}: Props) => {
  return (
    <CreditCardListStyled>
      {cards.map((card, i) => {
        const isHighlightedCard = selectedCard !== undefined && isSameCard(card, selectedCard)

        return (
          <li key={i}>
            <CreditCardListItemStyled
              size='md'
              color={isHighlightedCard ? 'secondary_color' : 'secondary_outline'}
              disabled={!isCardValid(card)}
              isFullWidth
              onClick={e => {
                e.preventDefault()

                if (!disabled && isCardValid(card)) {
                  onClick?.(card)
                }
              }}
            >
              <CreditCardDisplay
                issuerName={card.issuerName}
                lastFour={card.lastFour}
                formattedExpiryDate={card.formattedExpiryDate}
                isSelected={isHighlightedCard}
                alertLabel={
                  !isCardValid(card) ? (
                    <CreditCardAlertLabel
                      isDebitCard={card.isDebitCard}
                      isExpired={card.isExpired}
                    />
                  ) : null
                }
                icon={renderIcon?.(card) ?? null}
              />
            </CreditCardListItemStyled>

            {renderSuffix?.(card)}
          </li>
        )
      })}
    </CreditCardListStyled>
  )
}

CreditCardList.displayName = 'CreditCardList'
