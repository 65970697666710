import { last } from '@mobi/utils'
import * as ko from 'knockout'
import AllUpFormulasDataTransferObject from '../DataTransferObjects/AllUpFormulasDataTransferObject'

export class Formula {
  constructor(
    public name: ko.Observable<string>,
    public number: ko.Observable<number>,
    public numberOfCombinations: ko.Observable<number>,
    public isSelected: ko.Observable<boolean>
  ) {}

  public toggleSelection() {
    this.isSelected(!this.isSelected())
  }
}

export class AllUpFormulas {
  public formulas: ko.ObservableArray<Formula>

  constructor() {
    this.formulas = ko.observableArray<Formula>([])
  }

  public assignFormulas(allUpFormulasDto?: AllUpFormulasDataTransferObject): void {
    if (allUpFormulasDto === undefined) return

    let newFormulas = this.getFormulas(allUpFormulasDto)

    //update existing
    this.updateFormulas(newFormulas)

    if (newFormulas.length > this.formulas().length) {
      //deselect last (defualt) formula from old list if only last (default) is selected
      this.deselectDefaultFormula(this.formulas())
      //add new formulas
      this.addNewFormulas(newFormulas)
      //select last (default) formula if none selected
      this.selectDefaultFormula(this.formulas())
    } else if (newFormulas.length < this.formulas().length) {
      this.removeOldFormulas(newFormulas)
      //select last (default) formula if none selected
      this.selectDefaultFormula(this.formulas())
    }
  }

  private selectDefaultFormula(formulas: Formula[]): void {
    if (formulas != null) {
      //select last (default) formula if not selected
      let lastFormula = last(formulas) || null
      if (lastFormula != null && !lastFormula.isSelected()) {
        lastFormula.isSelected(true)
      }
    }
  }

  private deselectDefaultFormula(formulas: Formula[]): void {
    if (formulas != null) {
      //deselect last (defualt) formula from list if it is selected
      let lastFormula = last(formulas) || null
      if (lastFormula != null && lastFormula.isSelected()) {
        lastFormula.isSelected(false)
      }
    }
  }

  private getFormulas(allUpFormulasDto: AllUpFormulasDataTransferObject): Formula[] {
    let formulas: Formula[] = []
    if (allUpFormulasDto.SingleCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Singles'),
          ko.observable(1),
          ko.observable(allUpFormulasDto.SingleCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }
    if (allUpFormulasDto.DoubleCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Doubles'),
          ko.observable(2),
          ko.observable(allUpFormulasDto.DoubleCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }
    if (allUpFormulasDto.TrebleCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Trebles'),
          ko.observable(3),
          ko.observable(allUpFormulasDto.TrebleCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }
    if (allUpFormulasDto.FoursCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Pick 4'),
          ko.observable(4),
          ko.observable(allUpFormulasDto.FoursCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }
    if (allUpFormulasDto.FivesCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Pick 5'),
          ko.observable(5),
          ko.observable(allUpFormulasDto.FivesCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }
    if (allUpFormulasDto.SixCombinations > 0) {
      formulas.push(
        new Formula(
          ko.observable('Pick 6'),
          ko.observable(6),
          ko.observable(allUpFormulasDto.SixCombinations),
          ko.observable(false) as ko.Observable<boolean>
        )
      )
    }

    return formulas
  }

  private updateFormulas(newFormulas: Formula[]): void {
    newFormulas.forEach(newFormula => {
      this.formulas().forEach(oldFormula => {
        if (newFormula.number() === oldFormula.number()) {
          oldFormula.numberOfCombinations(newFormula.numberOfCombinations())
        }
      })
    })
  }

  private addNewFormulas(newFormulas: Formula[]): void {
    newFormulas.forEach(newFormula => {
      let alreadyExists = this.formulas().some(
        (oldFormula: Formula) => oldFormula.number() === newFormula.number()
      )
      if (!alreadyExists) {
        this.formulas.push(newFormula)
      }
    })
  }

  private removeOldFormulas(newFormulas: Formula[]): void {
    let needsRemovingFormulaNumbers: number[] = []
    this.formulas().forEach(oldFormula => {
      let alreadyExists = newFormulas.some(
        (newFormula: Formula) => newFormula.number() === oldFormula.number()
      )
      if (!alreadyExists) {
        needsRemovingFormulaNumbers.push(oldFormula.number())
      }
    })
    needsRemovingFormulaNumbers.forEach(oldFormulaNumber => {
      this.formulas.remove(f => {
        return f.number() === oldFormulaNumber
      })
    })
  }
}
