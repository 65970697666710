import styled, { CSSObject } from '@emotion/styled'

type Size = 'base' | 'sm' | 'lg' | 'xl'

const styles: Record<Size, CSSObject> = {
  lg: {
    fontSize: '1.4rem',
    lineHeight: 1.2,
  },
  xl: {
    fontSize: '1.8rem',
    lineHeight: 1.2,
  },
  base: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
  },
  sm: {
    fontSize: '1.1rem',
    lineHeight: 1.2,
  },
}

type Props = {
  size?: keyof typeof styles
}

export const Text = styled.span<Props>(({ size = 'base' }) => styles[size])
