import { ISelection } from './ISelection'
import * as ko from 'knockout'
import { ButtonSelectionType } from './ButtonSelectionType'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'

export class ButtonsSelection implements ISelection {
  constructor(selection: ButtonSelectionType, raceNumber: ObservableRaceKey, legCount: number = 1) {
    this.value = ko.observable(selection)
    this.raceNumber = raceNumber

    this.values = ko.observableArray()
    for (var leg = 0; leg < legCount; leg++) {
      this.values.push(ko.observable<ButtonSelectionType>(ButtonSelectionType.None))
    }
  }

  // in theory, usage of the singular 'value' could be replaced with the 'values' collection
  public value: ko.Observable<ButtonSelectionType>
  public values: ko.ObservableArray<ko.Observable<ButtonSelectionType>>

  public raceNumber: ObservableRaceKey

  public dispose() {
    //Do nothing
  }

  public hasFob = () =>
    [ButtonSelectionType.Fob, ButtonSelectionType.ToteAndFob].includes(this.value())

  public hasTote = () =>
    [ButtonSelectionType.Tote, ButtonSelectionType.ToteAndFob].includes(this.value())

  public hasAny = () => ButtonSelectionType.None !== this.value()
}
