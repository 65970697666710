import styled from '@emotion/styled'
import { fontFamiliesBold } from '@core/Settings'

export const FeaturedItemsHeaderStyled = styled('div')({
  fontFamily: fontFamiliesBold.brand,
  fontSize: '1.4rem',
  padding: '0.45rem 1rem 0',
  display: 'flex',
  justifyContent: 'space-between',
  color: '#FFF',
  alignItems: 'center',
})

export const FeaturedItemsListStyled = styled('ol')<{ count: number }>(
  {
    listStyle: 'none',
    margin: 0,
    overflowX: 'hidden',
    textAlign: 'left',
  },
  ({ count }) => ({
    padding: count > 0 ? '1rem' : 0,
    width: `${count * 12}rem`,
  })
)
