import { fromJS } from 'immutable'
import { StateMap } from 'typings/immutable'
import { attachDriver, createSignal, type Signal } from 'rwwa-rx-state-machine'
import { HasLoggedOut } from '@core/State/UserAccount/userAccountDriver'

export const SetAccountDetails = createSignal<GetAccountDetailsRes>('SetAccountDetails')
export const InvalidateAccountDetails = createSignal('InvalidateAccountDetails')

export type GetAccountDetailsRes = Omit<AccountDetails, '_lastUpdate'>

interface AccountDetails {
  accountName: string
  accountNumber: number
  balance: string
  blackBookAlertCount: number
  activeCampaignsCount: number
  _lastUpdate: number
}

interface AccountState {
  accountDetails?: AccountDetails
}

export type AccountStateMap = StateMap<AccountState>

export const defaultState: AccountState = {
  accountDetails: undefined,
}

export function driver(state = fromJS(defaultState), signal: Signal): AccountStateMap {
  switch (signal.tag) {
    case SetAccountDetails: {
      return state.merge({ accountDetails: { ...signal.data, _lastUpdate: Date.now() } })
    }

    case InvalidateAccountDetails: {
      return state.merge({ accountDetails: undefined })
    }

    case HasLoggedOut: {
      return state.merge({ accountDetails: undefined })
    }

    default:
      return state
  }
}

export const state$ = attachDriver<AccountStateMap>({ path: 'account-area', driver })
