import styled from '@emotion/styled'
import type { IssuerName } from '@mobi/api-types'
import type { DepositSource } from '../types'
import { font, colors } from '@mobi/component-library/Theme/Common'
import { ProviderMap, themes } from '../Common/PaymentMethodIconThemes'
import { gapY } from '../../Utils/gap'

export const PanelStyled = styled.div(
  {
    boxSizing: 'border-box',
    fontFamily: font.family.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'max-content',
  },
  gapY('3rem')
)

export const PanelIconStyled = styled.span<{ backgroundColor?: string }>(
  {
    display: 'inline-flex',
    borderRadius: '50%',
    height: '6.4rem',
    width: '6.4rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ backgroundColor = colors.neutral[100] }) => ({
    backgroundColor,
  })
)

export const PanelBankCardIconStyled = styled(PanelIconStyled)<{
  depositSource: DepositSource
  cardIssuer?: IssuerName
}>(({ depositSource, cardIssuer }) => {
  const provider = cardIssuer ? ProviderMap[cardIssuer] : ProviderMap[depositSource]
  const { textColor, svgWidth, backgroundColor, svgHeight } = themes[provider]

  return {
    backgroundColor,
    color: textColor,
    cursor: 'disabled',

    '& svg': {
      height: svgHeight,
      width: svgWidth,
    },
  }
})

export const PanelHeaderStyled = styled.div({
  fontSize: font.size.xl2.fontSize,
  fontWeight: font.weight.semibold,
})

export const PanelSubHeaderStyled = styled.div({
  fontSize: font.size.lg.fontSize,
  fontWeight: font.weight.regular,
  color: colors.neutral[800],
  textAlign: 'center',
})

export const PanelButtonsStyled = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  gapY('0.8rem')
)
