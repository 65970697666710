import { injectable } from 'inversify'
import { BetSpecialOffer } from '../Model/BetSpecialOffer'
import { LegTypeCode } from '../Model/LegTypeCode'

@injectable()
export class PyosExtensions {
  getLegTypeForDisplay(specialOffer: BetSpecialOffer): string | null {
    if (specialOffer) {
      if (specialOffer.isExclusiveLegType) {
        switch (specialOffer.legTypeCode) {
          case LegTypeCode.Win:
            return 'win only'
          case LegTypeCode.Place:
            return 'place only'
          case LegTypeCode.WinPlace:
            return 'win or place only'
        }
      } else {
        switch (specialOffer.legTypeCode) {
          case LegTypeCode.Win:
            return 'win'
          case LegTypeCode.Place:
            return 'place'
          case LegTypeCode.WinPlace:
            return 'win + place'
        }
      }
    }
    return null
  }

  getLegTypeIneligibilityDisplay(specialOffer: BetSpecialOffer): string | null {
    if (specialOffer) {
      if (specialOffer.isExclusiveLegType) {
        switch (specialOffer.legTypeCode) {
          case LegTypeCode.Win:
            return 'Only a Win amount can be entered for the selected SuperPick. Please amend your bet'
          case LegTypeCode.Place:
            return 'Only a Place amount can be entered for the selected SuperPick. Please amend your bet'
          case LegTypeCode.WinPlace:
            return null /* invalid option */
        }
      } else {
        switch (specialOffer.legTypeCode) {
          case LegTypeCode.Win:
            return 'A Win amount must be entered for the selected SuperPick. Please amend your bet'
          case LegTypeCode.Place:
            return 'A Place amount must be entered for the selected SuperPick. Please amend your bet'
          case LegTypeCode.WinPlace:
            return null
        }
      }
    }
    return null
  }
}
