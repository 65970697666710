import React from 'react'
import type { FieldSummary } from '@core/Areas/RaceCardFixedOnly/Types/FieldSummary'
import { RaceFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/Races/RaceFieldSummary'
import { DogFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/Dogs/DogFieldSummary'
import { TrotFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/Trots/TrotFieldSummary'

type Props = {
  summary: FieldSummary
  raceCode: string
}

const raceCodeComponents: Record<string, React.FC<{ summary: FieldSummary }>> = {
  Races: RaceFieldSummary,
  Dogs: DogFieldSummary,
  Trots: TrotFieldSummary,
}

export const Fields: React.FC<Props> = ({ summary, raceCode }) => {
  if (!summary) return null

  const FieldSummaryComponent = raceCodeComponents[raceCode] ?? null

  if (FieldSummaryComponent) {
    return <FieldSummaryComponent summary={summary} />
  }
  return null
}
