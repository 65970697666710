import * as ko from 'knockout'
import ObservablePage from './ObservablePage'
import ObservableRaceFinishers from './ObservableRaceFinishers'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableFinisher as ObservableFinisher } from './IObservableFinisher'
import ObservableSubstitutePool from './ObservableSubstitutePool'
import ObservableScratching from './ObservableScratching'
import NoveltyDividend from '../NoveltyDividend'
import ObservableRaceReplay from './ObservableRaceReplay'

export default class ObservableResultsPage extends ObservablePage {
  public raceNumber: ko.Observable<number | undefined>
  public raceFinishers: ObservableRaceFinishers
  public simplePlacings: ko.Observable<string | undefined>
  public raceStatus: ko.Observable<string | undefined>
  public protestStatus: ko.Observable<string | undefined>
  public noveltyDividends: ko.ObservableArray<NoveltyDividend>
  public availableSubstitutePools: ko.ObservableArray<ObservableSubstitutePool>
  public availableScratchings: ko.ObservableArray<ObservableScratching>
  public raceReplay: ObservableRaceReplay
  public giddyUpRaceKey: ko.Observable<string | undefined>
  public giddyUpFixtureKey: ko.Observable<string | undefined>

  constructor() {
    super()
    this.raceNumber = ko.observable()
    this.raceFinishers = new ObservableRaceFinishers()
    this.simplePlacings = ko.observable()
    this.raceStatus = ko.observable()
    this.protestStatus = ko.observable<string>()
    this.noveltyDividends = ko.observableArray<NoveltyDividend>([])
    this.availableSubstitutePools = ko.observableArray<ObservableSubstitutePool>([])
    this.availableScratchings = ko.observableArray<ObservableScratching>([])
    this.raceReplay = new ObservableRaceReplay()
    this.giddyUpRaceKey = ko.observable()
    this.giddyUpFixtureKey = ko.observable()
  }

  public addFinisher(finisher: ObservableFinisher) {
    Guard.notNull(finisher)

    this.raceFinishers.addFinishersForRace(finisher)
  }

  public addNoveltyDividends(noveltyDividends: Array<NoveltyDividend>) {
    Guard.notNull(noveltyDividends)
    this.noveltyDividends(noveltyDividends)
  }

  public addSubstitutePool(subPool: ObservableSubstitutePool) {
    this.availableSubstitutePools.push(subPool)
  }

  public addScratching(scratching: ObservableScratching) {
    this.availableScratchings.push(scratching)
  }

  public merge(other: ObservableResultsPage, forceClear?: boolean) {
    Guard.notNull(other)
    this.mergeDone(false)

    super.merge(other)
    this.simplePlacings(other.simplePlacings())
    this.raceStatus(other.raceStatus())
    this.protestStatus(other.protestStatus())
    this.raceFinishers.merge(other.raceFinishers, !!forceClear)
    this.noveltyDividends(other.noveltyDividends())
    this.availableSubstitutePools(other.availableSubstitutePools())
    this.availableScratchings(other.availableScratchings())
    this.raceReplay.merge(other.raceReplay)
    this.giddyUpRaceKey(other.giddyUpRaceKey())
    this.giddyUpFixtureKey(other.giddyUpFixtureKey())
    this.mergeDone(true)
  }
}
