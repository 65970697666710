import React from 'react'
import { SpinnerHolderStyled, SpinnerStyled, DesktopSpinnerOverlayStyled } from './Spinner.styles'

/**
 * Busy indicator for desktop browsers
 */
export function DesktopSpinner() {
  return (
    <DesktopSpinnerOverlayStyled>
      <SpinnerHolderStyled>
        <SpinnerStyled title='Loading...' />
      </SpinnerHolderStyled>
    </DesktopSpinnerOverlayStyled>
  )
}
