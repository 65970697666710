import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors, font } from '@mobi/component-library/Theme/Common'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'

const enum LocalConstants {
  ActiveSortClassName = 'js-race-col-header__sort--active',
}

export type FinisherSortOptions = `${SortLabels}.${SortDirection}`

export const FinishersColumnHeader: React.FC<{ shouldDisplayFixed: boolean }> = ({
  shouldDisplayFixed,
}) => {
  const eventAggregator = useEventAggregator()

  const [currSort, setCurrSort] = React.useState<FinisherSortOptions>('Number.Asc')

  const [currLabel, currDirection] = currSort.split('.') as [SortLabels, SortDirection]

  const createSortClick = (label: SortLabels) => () => {
    const direction: SortDirection = currLabel === label && currDirection === 'Asc' ? 'Desc' : 'Asc'
    const newSort: FinisherSortOptions = `${label}.${direction}`
    eventAggregator.publish('sort-finishers', { newSort })
    setCurrSort(newSort)
  }

  return (
    <div className='flat-display-list-header bg-gray-lightest pr0'>
      <ColumnHeaderItemStyled className='pt1em pb1em number-col'>
        <div
          onClick={createSortClick('Number')}
          className={currLabel === 'Number' ? LocalConstants.ActiveSortClassName : ''}
        >
          <span>#</span>
          <Icon
            size='1.4rem'
            name={currSort === 'Number.Desc' ? 'SolidChevronDown' : 'SolidChevronUp'}
            color={colors.neutral[500]}
          />
        </div>
      </ColumnHeaderItemStyled>

      {shouldDisplayFixed && (
        <ColumnHeaderItemStyled className='pt1em pb1em fixed-col'>
          <div
            onClick={createSortClick('Fixed')}
            className={currLabel === 'Fixed' ? LocalConstants.ActiveSortClassName : ''}
          >
            <span>Fixed</span>
            <Icon
              size='1.4rem'
              name={currSort === 'Fixed.Desc' ? 'SolidChevronDown' : 'SolidChevronUp'}
              color={colors.neutral[500]}
            />
          </div>
        </ColumnHeaderItemStyled>
      )}

      <ColumnHeaderItemStyled className='pt1em pb1em tote-col'>
        <div
          onClick={createSortClick('Tote')}
          className={currLabel === 'Tote' ? LocalConstants.ActiveSortClassName : ''}
        >
          <span>Tote</span>
          <Icon
            size='1.4rem'
            name={currSort === 'Tote.Desc' ? 'SolidChevronDown' : 'SolidChevronUp'}
            color={colors.neutral[500]}
          />
        </div>
      </ColumnHeaderItemStyled>
    </div>
  )
}

// ======
// Styles
// ======

const ColumnHeaderItemStyled = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: '2rem',
  justifyContent: 'center',

  '> div': {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '0.2rem',
    fontFamily: font.family.primary,
    fontWeight: font.weight.regular,
    fontSize: font.size.sm.fontSize,
    letterSpacing: font.size.sm.letterSpacing,
    lineHeight: font.size.sm.lineHeight,
    color: colors.black,

    [`&.${LocalConstants.ActiveSortClassName}`]: {
      fontWeight: font.weight.semibold,
      svg: { color: colors.black },
    },
  },
})

// =====
// Types
// =====

export type SortLabels = 'Fixed' | 'Tote' | 'Number'
export type SortDirection = 'Asc' | 'Desc'
