import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'

interface ErrorMessageProps {
  errorMessage: string
}

export function ErrorMessage({ errorMessage }: ErrorMessageProps) {
  return <NoticeBox title={errorMessage} noticeBoxType={NoticeBoxTypes.Error} hasBorder={false} />
}
