import React from 'react'
import {
  FixedOddsMarketCodes,
  type MarketDataTransferObject,
  type RacePageDataTransferObject,
} from '@mobi/api-types'
import { FixedOddsMarketsInMoreBettingOptions } from '@core/Areas/RaceCard/constants'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import { Market } from './Components/Market'
import { betWithSelection, betWithStartingPrice } from './Utils'
import { useFixedOddsMarkets } from './Hooks'

export const FixedOddsMarkets: React.FC<{ raceData: RacePageDataTransferObject }> = ({
  raceData,
}) => {
  const { selectedProposition, isInsuranceAvailable, isStartingPriceAvailable } =
    useFixedOddsMarkets(raceData)

  const fixedOddsMarkets = filterMarketsForMoreBettingOptions(raceData.FixedOddsMarkets)

  if (!(fixedOddsMarkets.length > 0) && !isInsuranceAvailable && !isStartingPriceAvailable)
    return null

  const handleAddToBetslipClick = () => {
    if (!selectedProposition) return
    return selectedProposition[0] === FixedOddsMarketCodes.StartingPrice
      ? betWithStartingPrice('betslip', selectedProposition[1], raceData)
      : betWithSelection('betslip', raceData.FixedOddsMarkets, selectedProposition, raceData)
  }

  const handleQuickbetClick = () => {
    if (!selectedProposition) return
    return selectedProposition[0] === FixedOddsMarketCodes.StartingPrice
      ? betWithStartingPrice('quickbet', selectedProposition[1], raceData)
      : betWithSelection('quickbet', raceData.FixedOddsMarkets, selectedProposition, raceData)
  }

  return (
    <>
      {fixedOddsMarkets.map(market => (
        <Market key={market.MarketSequence} market={market} raceData={raceData} />
      ))}

      <BettingDrawer
        open={!!selectedProposition}
        dataTId='more-markets'
        handleAddToBetslipClick={handleAddToBetslipClick}
        handleQuickbetClick={handleQuickbetClick}
      />
    </>
  )
}

// Local Helpers

function filterMarketsForMoreBettingOptions(markets: MarketDataTransferObject[]) {
  return markets
    .filter(market => FixedOddsMarketsInMoreBettingOptions.includes(market.MarketCode))
    .sort(sortMarkets)
}

function sortMarkets(a: MarketDataTransferObject, b: MarketDataTransferObject) {
  return FixedOddsMarketsInMoreBettingOptions.indexOf(a.MarketCode) ===
    FixedOddsMarketsInMoreBettingOptions.indexOf(b.MarketCode)
    ? 0
    : FixedOddsMarketsInMoreBettingOptions.indexOf(a.MarketCode) >
        FixedOddsMarketsInMoreBettingOptions.indexOf(b.MarketCode)
      ? 1
      : -1
}
