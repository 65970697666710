import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { colors } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { BetCard, FooterButtons } from '@mobi/betslip/Components/Common/BetCard'
import type { BetSlipItem } from '@mobi/betslip/types'
import { removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { AllUpSelection, BetInvestment, ItemMessage, ReceiptDetail } from './Components'

export const SingleItem: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  const hasFatalError = isFatalErrorType(item.betErrorType) || false
  const isEditable = workflowStatus === 'ready'

  const handleRemove = () => dispatch(removeItemFromBetSlipById(item.id))

  return (
    <BetCard.Container key={item.id} topComponent={<ItemMessage item={item} />}>
      <BetInfo item={item} />

      {!hasFatalError && <BetInvestment item={item} />}

      <AllUpSelection item={item} />

      {item.receipt ? (
        <ReceiptDetail receipt={item.receipt} />
      ) : (
        isEditable && (
          <BetCard.Footer
            rightSection={
              <>
                {!hasFatalError && (
                  <FooterButtons.ShareButton disabled={isBusy} onClick={() => null} />
                )}

                <Button
                  size='xs'
                  color='tertiary_grey'
                  isIconOnlyButton
                  onClick={handleRemove}
                  disabled={isBusy}
                >
                  <Icon name='LineTrash01' size='1.6rem' color={colors.black} />
                </Button>
              </>
            }
          />
        )
      )}
    </BetCard.Container>
  )
}
