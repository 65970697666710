import styled from '@emotion/styled'

export const DetailsWrapper = styled('div')({
  padding: '1rem',
})

export const DetailsTextWrapper = styled('div')({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  margin: '1rem',
})
