import {
  StructuredSearchResult,
  RacingCode,
  RawStructuredSearchResults,
  StructuredSearchAggregations,
} from '@core/Data/StructuredSearch/StructuredSearchResult'
import { StructuredSearchFields, StructuredSearchResults } from '../Driver/StructuredSearchDriver'
import { logError } from '@classic/Foundation/Services/LoggingService'
import { assignStructuredSearch } from '@classic/Foundation/Analytics/Analytics'
import { StructuredSearchData } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { getTimezoneOffsetHours } from '@classic/Foundation/DateTimeProvider'
import { RangeBoundaries } from '../RangeBoundaries'

export type StructuredSearchReturnType = StructuredSearchResult[]

export const CONTEST_TYPES = 'contestTypes'
export const EVENT_DATES = 'eventDates'
export const BARRIER_NUMBERS = 'barrierNumbers'
export const BOX_NUMBERS = 'boxNumbers'
export const ONE_TO_FOUR = 'oneToFour'
export const ONE_TO_FIVE = 'oneToFive'
export const FIVE_PLUS = 'fivePlus'
export const SIX_PLUS = 'sixPlus'
export const WEIGHT_CHANGE = 'weightChange'
export const DISTANCE_CHANGE = 'distanceChange'
export const UP = 'up'
export const DOWN = 'down'
export const SAME = 'same'
export const TODAY = 'today'
export const TOMORROW = 'tomorrow'
export const BEYOND = 'beyond'
export const TIMEZONE_OFFSET = 'timeZoneOffset'
export const WIN_TRACK_CONDITIONS = 'winTrackConditions'
export const WIN_TRACK_FIRM = 'firm'
export const WIN_TRACK_GOOD = 'good'
export const WIN_TRACK_HEAVY = 'heavy'
export const WIN_TRACK_SOFT = 'soft'
export const WIN_TRACK_SYNTHETIC = 'synthetic'
export const LOCATION_GROUPS = 'locationGroups'
export const LOCATION_GROUP_AUSTRALIA = 'AUS'
export const LOCATION_GROUP_NEW_ZEALAND = 'NZ'
export const LOCATION_GROUP_INTERNATIONAL = 'INT'
export const TRACK_ID = 'trackIds'

export function buildQueryToken(fieldName: string, value: string | number | boolean): string {
  return `${fieldName}=${encodeURIComponent(value)}`
}

export function buildUrl(parameters: StructuredSearchFields): string {
  let queryTokens: string[] = []
  let urlString = ''

  // If the structure of th  criteria node changes, you will need to ensure that Google Ananlytics handles the change
  let structuredSearchData: StructuredSearchData = {
    criteria: null,
  }

  // Declaring it this way stops lint warnings from typescript
  structuredSearchData.criteria = {}

  const offset: number = getTimezoneOffsetHours()

  queryTokens.push(buildQueryToken(TIMEZONE_OFFSET, offset))

  if (parameters) {
    if (!(parameters.contestTypeRace && parameters.contestTypeTrot && parameters.contestTypeDogs)) {
      let contestTypeArray = []

      if (parameters.contestTypeRace) {
        queryTokens.push(buildQueryToken(CONTEST_TYPES, RacingCode.Races.toLocaleLowerCase()))
        contestTypeArray.push(RacingCode.Races.toLocaleLowerCase())
      }

      if (parameters.contestTypeTrot) {
        queryTokens.push(buildQueryToken(CONTEST_TYPES, RacingCode.Trots.toLocaleLowerCase()))
        contestTypeArray.push(RacingCode.Trots.toLocaleLowerCase())
      }

      if (parameters.contestTypeDogs) {
        queryTokens.push(buildQueryToken(CONTEST_TYPES, RacingCode.Dogs.toLocaleLowerCase()))
        contestTypeArray.push(RacingCode.Dogs.toLocaleLowerCase())
      }

      if (contestTypeArray.length > 0) {
        structuredSearchData.criteria.contestType = contestTypeArray
      }
    }

    if (!(parameters.dateToday && parameters.dateTomorrow && parameters.dateBeyondTomorrow)) {
      let dateArray = []
      if (parameters.dateToday) {
        queryTokens.push(buildQueryToken(EVENT_DATES, TODAY))
        dateArray.push(TODAY)
      }
      if (parameters.dateTomorrow) {
        queryTokens.push(buildQueryToken(EVENT_DATES, TOMORROW))
        dateArray.push(TOMORROW)
      }
      if (parameters.dateBeyondTomorrow) {
        queryTokens.push(buildQueryToken(EVENT_DATES, BEYOND))
        dateArray.push(BEYOND)
      }

      if (dateArray.length > 0) {
        structuredSearchData.criteria.date = dateArray
      }
    }

    if (parameters.firstUp) {
      queryTokens.push(buildQueryToken('spellFirstUp', true))
      structuredSearchData.criteria.firstUp = true
    }

    if (parameters.secondUp) {
      queryTokens.push(buildQueryToken('spellSecondUp', true))
      structuredSearchData.criteria.secondUp = true
    }

    if (parameters.gearChange) {
      queryTokens.push(buildQueryToken('gearChange', true))
      structuredSearchData.criteria.gearChange = true
    }

    if (parameters.wonLastStart) {
      queryTokens.push(buildQueryToken('lastStartWin', true))
      structuredSearchData.criteria.wonLastStart = true
    }

    if (parameters.placedLastStart) {
      queryTokens.push(buildQueryToken('lastStartPlace', true))
      structuredSearchData.criteria.placedLastStart = true
    }

    if (parameters.careerWinPercentage) {
      queryTokens.push(buildQueryToken('winPercentageCareer', parameters.careerWinPercentage))
      structuredSearchData.criteria.careerWinPercentage = parameters.careerWinPercentage
    }

    if (parameters.trackWinPercentage) {
      queryTokens.push(buildQueryToken('winPercentageTrack', parameters.trackWinPercentage))
      structuredSearchData.criteria.trackWinPercentage = parameters.trackWinPercentage
    }

    if (parameters.distanceWinPercentage) {
      queryTokens.push(buildQueryToken('winPercentageDistance', parameters.distanceWinPercentage))
      structuredSearchData.criteria.distanceWinPercentage = parameters.distanceWinPercentage
    }

    if (parameters.trackdistanceWinPercentage) {
      queryTokens.push(
        buildQueryToken('winPercentageDistanceTrack', parameters.trackdistanceWinPercentage)
      )
      structuredSearchData.criteria.trackdistanceWinPercentage =
        parameters.trackdistanceWinPercentage
    }

    if (!(parameters.barrierNumberOneToFive && parameters.barrierNumberSixPlus)) {
      let barrierArray = []
      if (parameters.barrierNumberOneToFive) {
        queryTokens.push(buildQueryToken(BARRIER_NUMBERS, ONE_TO_FIVE))
        barrierArray.push(ONE_TO_FIVE)
      }

      if (parameters.barrierNumberSixPlus) {
        queryTokens.push(buildQueryToken(BARRIER_NUMBERS, SIX_PLUS))
        barrierArray.push(SIX_PLUS)
      }
      if (barrierArray.length > 0) {
        structuredSearchData.criteria.barrierNumber = barrierArray
      }
    }

    if (!(parameters.boxNumberOneToFour && parameters.boxNumberFivePlus)) {
      let boxArray = []
      if (parameters.boxNumberOneToFour) {
        queryTokens.push(buildQueryToken(BOX_NUMBERS, ONE_TO_FOUR))
        boxArray.push(ONE_TO_FOUR)
      }

      if (parameters.boxNumberFivePlus) {
        queryTokens.push(buildQueryToken(BOX_NUMBERS, FIVE_PLUS))
        boxArray.push(FIVE_PLUS)
      }
      if (boxArray.length > 0) {
        structuredSearchData.criteria.boxNumber = boxArray
      }
    }

    if (parameters.apprentice) {
      queryTokens.push(buildQueryToken('apprentice', true))
      structuredSearchData.criteria.apprentice = true
    }

    if (parameters.favouriteLastStart) {
      queryTokens.push(buildQueryToken('lastStartFavourite', true))
      structuredSearchData.criteria.favouriteLastStart = true
    }

    if (
      !(parameters.weightChangedUp && parameters.weightChangedDown && parameters.weightNotChanged)
    ) {
      let weightChangedArray = []
      if (parameters.weightChangedUp) {
        queryTokens.push(buildQueryToken(WEIGHT_CHANGE, UP))
        weightChangedArray.push(UP)
      }

      if (parameters.weightChangedDown) {
        queryTokens.push(buildQueryToken(WEIGHT_CHANGE, DOWN))
        weightChangedArray.push(DOWN)
      }

      if (parameters.weightNotChanged) {
        queryTokens.push(buildQueryToken(WEIGHT_CHANGE, SAME))
        weightChangedArray.push(SAME)
      }
      if (weightChangedArray.length > 0) {
        structuredSearchData.criteria.weightChanged = weightChangedArray
      }
    }

    if (
      !(
        parameters.distanceChangedUp &&
        parameters.distanceChangedDown &&
        parameters.distanceNotChanged
      )
    ) {
      let distanceChangedArray = []
      if (parameters.distanceChangedUp) {
        queryTokens.push(buildQueryToken(DISTANCE_CHANGE, UP))
        distanceChangedArray.push(UP)
      }

      if (parameters.distanceChangedDown) {
        queryTokens.push(buildQueryToken(DISTANCE_CHANGE, DOWN))
        distanceChangedArray.push(DOWN)
      }

      if (parameters.distanceNotChanged) {
        queryTokens.push(buildQueryToken(DISTANCE_CHANGE, SAME))
        distanceChangedArray.push(SAME)
      }

      if (distanceChangedArray.length > 0) {
        structuredSearchData.criteria.distanceChanged = distanceChangedArray
      }
    }

    if (
      parameters.minDaysSinceLastWin > RangeBoundaries.minDaysSinceLastWin ||
      parameters.maxDaysSinceLastWin < RangeBoundaries.maxDaysSinceLastWin
    ) {
      structuredSearchData.criteria.daysSinceLastWin = {}
      if (parameters.minDaysSinceLastWin > RangeBoundaries.minDaysSinceLastWin) {
        queryTokens.push(buildQueryToken('daysSinceLastWinMin', parameters.minDaysSinceLastWin))
        structuredSearchData.criteria.daysSinceLastWin.min = parameters.minDaysSinceLastWin
      }

      if (parameters.maxDaysSinceLastWin < RangeBoundaries.maxDaysSinceLastWin) {
        queryTokens.push(buildQueryToken('daysSinceLastWinMax', parameters.maxDaysSinceLastWin))
        structuredSearchData.criteria.daysSinceLastWin.max = parameters.maxDaysSinceLastWin
      }
    }

    if (
      parameters.minDaysSinceLastStart > RangeBoundaries.minDaysSinceLastStart ||
      parameters.maxDaysSinceLastStart < RangeBoundaries.maxDaysSinceLastStart
    ) {
      structuredSearchData.criteria.daysSinceLastStart = {}
      if (parameters.minDaysSinceLastStart > RangeBoundaries.minDaysSinceLastStart) {
        queryTokens.push(buildQueryToken('daysSinceLastStartMin', parameters.minDaysSinceLastStart))
        structuredSearchData.criteria.daysSinceLastStart.min = parameters.minDaysSinceLastStart
      }

      if (parameters.maxDaysSinceLastStart < RangeBoundaries.maxDaysSinceLastStart) {
        queryTokens.push(buildQueryToken('daysSinceLastStartMax', parameters.maxDaysSinceLastStart))
        structuredSearchData.criteria.daysSinceLastStart.max = parameters.maxDaysSinceLastStart
      }
    }

    if (
      parameters.minStartsSinceLastSpell > RangeBoundaries.minStartsSinceLastSpell ||
      parameters.maxStartsSinceLastSpell < RangeBoundaries.maxStartsSinceLastSpell
    ) {
      structuredSearchData.criteria.startsSinceLastSpell = {}
      if (parameters.minStartsSinceLastSpell > RangeBoundaries.minStartsSinceLastSpell) {
        queryTokens.push(
          buildQueryToken('startsSinceLastSpellMin', parameters.minStartsSinceLastSpell)
        )
        structuredSearchData.criteria.startsSinceLastSpell.min = parameters.minStartsSinceLastSpell
      }

      if (parameters.maxStartsSinceLastSpell < RangeBoundaries.maxStartsSinceLastSpell) {
        queryTokens.push(
          buildQueryToken('startsSinceLastSpellMax', parameters.maxStartsSinceLastSpell)
        )
        structuredSearchData.criteria.startsSinceLastSpell.max = parameters.maxStartsSinceLastSpell
      }
    }

    if (
      parameters.minFixedOddsPrice > RangeBoundaries.minFixedOddsPrice ||
      parameters.maxFixedOddsPrice < RangeBoundaries.maxFixedOddsPrice
    ) {
      structuredSearchData.criteria.fixedOddsPrice = {}
      if (parameters.minFixedOddsPrice > RangeBoundaries.minFixedOddsPrice) {
        queryTokens.push(buildQueryToken('fixedOddsPriceMin', parameters.minFixedOddsPrice))
        structuredSearchData.criteria.fixedOddsPrice.min = parameters.minFixedOddsPrice
      }

      if (parameters.maxFixedOddsPrice < RangeBoundaries.maxFixedOddsPrice) {
        queryTokens.push(buildQueryToken('fixedOddsPriceMax', parameters.maxFixedOddsPrice))
        structuredSearchData.criteria.fixedOddsPrice.max = parameters.maxFixedOddsPrice
      }
    }

    if (
      !(
        parameters.winTrackConditionFirm &&
        parameters.winTrackConditionGood &&
        parameters.winTrackConditionHeavy &&
        parameters.winTrackConditionSoft &&
        parameters.winTrackConditionSynthetic
      )
    ) {
      let winTrackConditionsArray = []

      if (parameters.winTrackConditionFirm) {
        queryTokens.push(buildQueryToken(WIN_TRACK_CONDITIONS, WIN_TRACK_FIRM))
        winTrackConditionsArray.push(WIN_TRACK_FIRM)
      }

      if (parameters.winTrackConditionGood) {
        queryTokens.push(buildQueryToken(WIN_TRACK_CONDITIONS, WIN_TRACK_GOOD))
        winTrackConditionsArray.push(WIN_TRACK_GOOD)
      }

      if (parameters.winTrackConditionSoft) {
        queryTokens.push(buildQueryToken(WIN_TRACK_CONDITIONS, WIN_TRACK_SOFT))
        winTrackConditionsArray.push(WIN_TRACK_SOFT)
      }

      if (parameters.winTrackConditionHeavy) {
        queryTokens.push(buildQueryToken(WIN_TRACK_CONDITIONS, WIN_TRACK_HEAVY))
        winTrackConditionsArray.push(WIN_TRACK_HEAVY)
      }

      if (parameters.winTrackConditionSynthetic) {
        queryTokens.push(buildQueryToken(WIN_TRACK_CONDITIONS, WIN_TRACK_SYNTHETIC))
        winTrackConditionsArray.push(WIN_TRACK_SYNTHETIC)
      }

      if (winTrackConditionsArray.length > 0) {
        structuredSearchData.criteria.winTrackConditions = winTrackConditionsArray
      }
    }

    if (
      !(
        parameters.locationCountryAustralia &&
        parameters.locationCountryInternational &&
        parameters.locationCountryNewZealand
      )
    ) {
      let locationGroup = []
      if (parameters.locationCountryAustralia) {
        queryTokens.push(buildQueryToken(LOCATION_GROUPS, LOCATION_GROUP_AUSTRALIA))
        locationGroup.push(LOCATION_GROUP_AUSTRALIA)
      }
      if (parameters.locationCountryNewZealand) {
        queryTokens.push(buildQueryToken(LOCATION_GROUPS, LOCATION_GROUP_NEW_ZEALAND))
        locationGroup.push(LOCATION_GROUP_NEW_ZEALAND)
      }
      if (parameters.locationCountryInternational) {
        queryTokens.push(buildQueryToken(LOCATION_GROUPS, LOCATION_GROUP_INTERNATIONAL))
        locationGroup.push(LOCATION_GROUP_INTERNATIONAL)
      }

      if (locationGroup.length > 0) {
        structuredSearchData.criteria.location = { locationGroup, trackIds: null }
      }
    }

    if (parameters.selectedTracks && parameters.selectedTracks.length > 0) {
      parameters.selectedTracks.forEach(trackId =>
        queryTokens.push(buildQueryToken(TRACK_ID, trackId))
      )

      if (structuredSearchData.criteria.location) {
        structuredSearchData.criteria.location.trackIds = parameters.selectedTracks
      } else {
        structuredSearchData.criteria.location = {
          locationGroup: null,
          trackIds: parameters.selectedTracks,
        }
      }
    }
  }

  if (
    Object.keys(structuredSearchData.criteria).length === 0 &&
    structuredSearchData.criteria.constructor === Object
  ) {
    structuredSearchData.criteria = null
  }

  if (queryTokens.length > 0) {
    urlString = queryTokens.join('&')
  }

  assignStructuredSearch(structuredSearchData)

  const url = `/api/search/structured${urlString ? `?${urlString}` : ''}`

  return url
}

export async function performStructuredSearch(parameters: StructuredSearchFields): Promise<void> {
  let data: RawStructuredSearchResults
  const url = buildUrl(parameters)

  try {
    const response: Response = await fetch(url, {
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error(`Did not receive a valid response: ${response.status}`)
    }

    data = await response.json()

    const results: StructuredSearchResult[] = data.results
    const totalResultCount: number = data.statistics.hitCount
    const aggregations: StructuredSearchAggregations = data.aggregations

    StructuredSearchResults({ results, totalResultCount, aggregations })
  } catch (e) {
    logError(`Error retrieving data: ${e}`)
  }
}
