import { SpecialTileProp } from './SpecialTile'

export const build = (special: SpecialTileProp): string | null => {
  let url = null
  if (special && special.betNowNavigator) {
    const navigator = special.betNowNavigator
    if (navigator.multiEvent && navigator.multiEvent === true) {
      url = `#fob/event?sseq=${navigator.sportSequence}&cseq=${navigator.competitionSequence}`
      if (navigator.tournamentSequence) {
        url += `&tseq=${navigator.tournamentSequence}`
      }
    } else {
      url = `#fob/event/mkt?sseq=${navigator.sportSequence}&cseq=${navigator.competitionSequence}&eseq=${navigator.eventSequence}&mseq=${navigator.marketSequence}&pyol=false`
      if (navigator.tournamentSequence) {
        url += `&tseq=${navigator.tournamentSequence}`
      }
    }
  }
  return url
}
