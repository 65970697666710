import React from 'react'
import type { Race, Acceptor, AllUpSelection, ToteSelection } from '@mobi/betslip/types'
import {
  NoveltyListStyled,
  NoveltyPlaceStyled as Place,
  NoveltyStarterStyled as Starter,
} from './Selection.styles'
import { parseMultiLegSelectionString, parseAllUpSelections } from './helpers'

export const MultiRaceSelection: React.FC<{
  selection: ToteSelection | AllUpSelection
  races: Race[]
  acceptors: Acceptor[]
}> = ({ selection, races, acceptors }) => {
  const parsed =
    selection.betType === 'All Up'
      ? parseAllUpSelections(selection as AllUpSelection, races, acceptors)
      : parseMultiLegSelectionString(selection as ToteSelection, races, acceptors)
  return (
    <NoveltyListStyled>
      {Object.keys(parsed).map(key => [
        <Place key={`__${key}`}>
          <span>{key}</span>
        </Place>,
        <Starter key={key}>{parsed[key]}</Starter>,
      ])}
    </NoveltyListStyled>
  )
}
