import { dayjs } from '@mobi/utils/date'
import type { BlackbookEntryRacingToday } from '@core/Areas/Blackbook/types'
import { isBettingAvailable } from '@core/Areas/Blackbook/helpers'

export function sortByStartTime(a: BlackbookEntryRacingToday, b: BlackbookEntryRacingToday) {
  if (!isBettingAvailable(a.RacingTodayDetails)) return 1
  if (!isBettingAvailable(b.RacingTodayDetails)) return -1
  return a.RacingTodayDetails.AdvertisedStartTime === b.RacingTodayDetails.AdvertisedStartTime
    ? 0
    : dayjs(a.RacingTodayDetails.AdvertisedStartTime).isBefore(
          b.RacingTodayDetails.AdvertisedStartTime
        )
      ? -1
      : 1
}
