import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const MarketPropositionStyled = styled('li')({
  display: 'flex',
  padding: '1rem 0',
  borderBottom: `1px solid ${hexColors.whiteSmoke}`,

  '&:last-of-type': {
    borderBottom: 'none',
  },
})

export const MarketPropositionIdSectionStyled = styled('div')({
  width: '2.5rem',
  paddingRight: '0.8rem',

  '& span': {
    display: 'block',
    marginBottom: '0.2rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: '1.6rem',
  },
})

export const MarketPropositionInfoSectionStyled = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
})

export const MarketPropositionInfoStyled = styled('span')({
  fontSize: '1.1rem',
  lineHeight: '1.5rem',

  '& strong': {
    display: 'inline-block',
    minWidth: '1.5rem',
  },
})

export const MarketPropositionInfoSubSectionStyled = styled('div')({
  display: 'flex',

  '& div': {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1rem',
  },
})

export const MarketPropositionHeaderStyled = styled('span')<{ isMatchedStarter: boolean }>(
  {
    marginBottom: '0.2rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '1.6rem',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  ({ isMatchedStarter }) =>
    isMatchedStarter && {
      textTransform: 'uppercase',
    }
)

export const MarketPropositionActionSectionStyled = styled('div')({
  display: 'flex',
})

export const MarketPropositionSratchedStyled = styled('span')({
  display: 'flex',
  alignItems: 'center',
  color: '#fe0000', // SASS Only Color
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '4rem',
  textTransform: 'uppercase',
  textAlign: 'center',
})
