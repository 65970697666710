import styled from '@emotion/styled'

interface TimetoJumpProps {
  size: string
}

export const TimeToJumpAbsoluteTimeStyled = styled('span')<TimetoJumpProps>(
  {
    display: 'block',
    fontWeight: 'bold',
    width: 'auto',
    paddingLeft: '1.3rem',
  },
  ({ size }) => {
    return {
      fontSize: `${size}` || '1.4rem',
    }
  }
)

export const TimeToJumpDayFlagStyled = styled('div')(({ size }: { size?: string }) => ({
  textAlign: 'center',
  fontSize: size || '1.0rem',
}))
