import type { BetAccountRecord } from '@core/Data/Account/betAccount'
import {
  HasLoggedIn,
  HasLoggedOut,
  state$ as userAccountState$,
} from '@core/State/UserAccount/userAccountDriver'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'

export function startUserLoginListeners(): void {
  HasLoggedOut.signal$.subscribe(() => {
    if (isReactNativeApp) {
      sendToNative('USER_DID_LOGOUT')
      return
    }
  })

  HasLoggedIn.signal$.subscribe(() => {
    userAccountState$.take(1).subscribe(userAccountState => {
      const accountNumber = userAccountState.accountNumber as number
      if (isReactNativeApp) {
        sendToNative('USER_DID_LOGIN', { accountNumber })
        return
      }
    })
  })
}

export function syncLoginStateWithNativeApp(betAccount: BetAccountRecord): void {
  const data = {
    accountNumber: betAccount.BetAccountNumber,
    accountNumberHash: betAccount.BetAccountNumberHash,
    accountBalance: betAccount.Balance,
  }

  if (isReactNativeApp) {
    sendToNative('HAS_LOGGED_IN', data)
    return
  }
}
