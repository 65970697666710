import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableFixedOddsFinisherInfo {
  constructor() {
    // @ts-expect-error Type issue
    this.isScratched = ko.observable()
    // @ts-expect-error Type issue
    this.isSuspended = ko.observable()
    // @ts-expect-error Type issue
    this.isFavourite = ko.observable()
    // @ts-expect-error Type issue
    this.scratchedTime = ko.observable()
    // @ts-expect-error Type issue
    this.winDiv = ko.observable()
    // @ts-expect-error Type issue
    this.placeDiv = ko.observable()
    // @ts-expect-error Type issue
    this.winDividendDeduction = ko.observable()
    // @ts-expect-error Type issue
    this.placeDividendDeduction = ko.observable()
    // @ts-expect-error Type issue
    this.displayWinDividend = ko.observable()
    // @ts-expect-error Type issue
    this.displayPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.shouldDisplayPlaceDividend = ko.observable()
  }

  public merge(other: ObservableFixedOddsFinisherInfo) {
    Guard.notNull(other)

    this.isScratched(other.isScratched())
    this.isSuspended(other.isSuspended())
    this.isFavourite(other.isFavourite())
    this.scratchedTime(other.scratchedTime())
    this.winDiv(other.winDiv())
    this.placeDiv(other.placeDiv())
    this.winDividendDeduction(other.winDividendDeduction())
    this.placeDividendDeduction(other.placeDividendDeduction())
    this.displayWinDividend(other.displayWinDividend())
    this.displayPlaceDividend(other.displayPlaceDividend())
    this.shouldDisplayPlaceDividend(other.shouldDisplayPlaceDividend())
  }

  public isScratched: ko.Observable<boolean>
  public isSuspended: ko.Observable<boolean>
  public isFavourite: ko.Observable<boolean>
  public scratchedTime: ko.Observable<string | null>
  public winDiv: ko.Observable<number>
  public placeDiv: ko.Observable<number>
  public winDividendDeduction: ko.Observable<number | null>
  public placeDividendDeduction: ko.Observable<number | null>
  public displayWinDividend: ko.Observable<string>
  public displayPlaceDividend: ko.Observable<string>
  public shouldDisplayPlaceDividend: ko.Observable<boolean>
}
