import React from 'react'
import type { ToteSelection } from '@mobi/betslip/types'
import { isAllUpSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Money } from '@core/Components/Text/Money'
import { networkErrorMessage } from '@core/Data/betting'
import { QuickbetState } from '../../driver'
import { UserAccountState } from '@core/State/UserAccount/userAccountDriver'
import { toMoney } from '@mobi/utils/money'
import { calculateBetCost } from '../../helpers/calculator'
import { isFavouriteNumbersSelection, isToteSportsSelection } from '@core/Data/Betting/selections'
import { FormulaState } from '../Formula/driver'
import { calculateCombinations as favouriteNumbersCombinations } from '@core/Areas/FavouriteNumbers/calculator'
import { calculateCombinations as tippingCombinations } from '@core/Areas/Tipping/helpers/calculator'
import { BonusDetailsNotification } from './BonusDetailsNotification'
import { DisclaimerStyled } from './NotificationTypes.styles'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

export enum NotificationType {
  Unauthorized = 'UNAUTHORIZED',
  InvalidSuperPickSelection = 'INVALID_SUPERPICK_SELECTION',
  InsufficientFunds = 'INSUFFCIENT_FUNDS',
  NonHandledError = 'NON_HANDLED_ERROR',
  ProposeSucceeded = 'PROPOSE_SUCCEEDED',
  PriceChange = 'PRICE_CHANGE',
  HandicapChanged = 'HANDICAP_CHANGED',
  InsufficientInvestmentForCombinations = 'INSUFFICIENT_FUNDS_FOR_COMBINATIONS',
  RaceClosed = 'RACE_CLOSED',
  MysteryQuickPickFixedInvestment = 'MYSTERY_QP_FIXED_INVESTMENT',
  InvalidBonusBet = 'INVALID_BONUS_BET',
}

interface NotificationData {
  [key: string]: {
    icon: NoticeBoxTypes
    title: string
    subtitle?: string
    children?: () => JSX.Element[] | JSX.Element | undefined
  }
}

type NotificationDataArgs = Pick<
  QuickbetState,
  | 'canBet'
  | 'notificationTitle'
  | 'notificationSubtitle'
  | 'bettingType'
  | 'isEachWay'
  | 'selection'
  | 'isUsingBonusBet'
  | 'isUsingBonusCash'
> &
  Pick<UserAccountState, 'accountBalance' | 'bonusCashBalance'> &
  Pick<FormulaState, 'numberOfCombinationsSelected'> & {
    winInvestment: number
    placeInvestment: number
  }

const {
  Unauthorized,
  InsufficientFunds,
  InvalidSuperPickSelection,
  NonHandledError,
  ProposeSucceeded,
  PriceChange,
  HandicapChanged,
  InsufficientInvestmentForCombinations,
  RaceClosed,
  MysteryQuickPickFixedInvestment,
  InvalidBonusBet,
} = NotificationType

const { Error: ErrorIcon, Warning: WarningIcon, Info: InformationIcon } = NoticeBoxTypes

export const notificationData = ({
  notificationTitle,
  notificationSubtitle,
  accountBalance,
  canBet,
  bettingType,
  winInvestment,
  placeInvestment,
  isEachWay,
  selection,
  numberOfCombinationsSelected,
  isUsingBonusBet,
  isUsingBonusCash,
  bonusCashBalance,
}: NotificationDataArgs): NotificationData => {
  const numberOfCombinations = isAllUpSelection(selection)
    ? numberOfCombinationsSelected
    : isToteSelection(selection)
      ? selection.numberOfCombinations
      : isFavouriteNumbersSelection(selection)
        ? favouriteNumbersCombinations(selection.bets)
        : isToteSportsSelection(selection)
          ? tippingCombinations(selection.betSelections)
          : 1

  const betType = bettingType === 'tote-racing' ? (selection as ToteSelection).betType : undefined
  const betCost = calculateBetCost(
    bettingType,
    winInvestment,
    placeInvestment,
    isEachWay,
    betType,
    numberOfCombinations
  )

  return {
    [Unauthorized]: {
      icon: ErrorIcon,
      title: 'Please log in to place bet',
    },
    [InvalidSuperPickSelection]: {
      icon: WarningIcon,
      title: 'Invalid SuperPick selection',
      subtitle: notificationSubtitle == null ? undefined : notificationSubtitle,
    },
    [InsufficientFunds]: {
      icon: ErrorIcon,
      title: 'Your account has insufficient funds',
      children: () =>
        accountBalance ? (
          <p key='insufficientFunds'>
            Current account balance is{' '}
            <strong>
              <Money amount={accountBalance} />
            </strong>
          </p>
        ) : undefined,
    },
    [NonHandledError]: {
      icon: ErrorIcon,
      title: notificationTitle as string,
      subtitle:
        notificationTitle === networkErrorMessage
          ? canBet
            ? 'Please check your internet connection and try again.'
            : 'Please check your internet connection.'
          : undefined,
      children: () =>
        notificationTitle === networkErrorMessage && !canBet ? (
          <p key='unHandledError'>
            <strong>IMPORTANT </strong>Please check your Transactions or contact our Customer
            Service Centre on <CustomerServiceTelephone /> to confirm whether the operation
            succeeded.
          </p>
        ) : undefined,
    },
    [ProposeSucceeded]: {
      icon: WarningIcon,
      title: `This bet will cost ${toMoney(betCost)}`,
      children: () => (
        <>
          <BonusDetailsNotification
            betCost={betCost}
            isUsingBonusBet={isUsingBonusBet}
            isUsingBonusCash={isUsingBonusCash}
            bonusCashBalance={bonusCashBalance}
            accountBalance={accountBalance}
          />
          <DisclaimerStyled>Bets cannot be cancelled once placed.</DisclaimerStyled>
        </>
      ),
    },
    [PriceChange]: {
      icon: WarningIcon,
      title: 'Prices have changed',
      subtitle: `This bet will cost ${toMoney(betCost)}`,
    },
    [HandicapChanged]: {
      icon: ErrorIcon,
      title: 'Handicap changed. Bet is no longer valid',
    },
    [InsufficientInvestmentForCombinations]: {
      icon: WarningIcon,
      title: `Insufficient investment for ${numberOfCombinations} combinations.`,
    },
    [RaceClosed]: {
      icon: ErrorIcon,
      title: 'Betting Closed',
    },
    [MysteryQuickPickFixedInvestment]: {
      icon: InformationIcon,
      title: 'Investment is pre-set for this bet type',
    },
    [InvalidBonusBet]: {
      icon: ErrorIcon,
      title: 'Bonus Bet has already been used',
    },
  }
}
