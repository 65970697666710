import React from 'react'
import type { FieldSummary } from '@core/Areas/RaceCardFixedOnly/Types/FieldSummary'
import {
  Proposition3FieldsStyled,
  PropositionJockeyNameStyled,
  PropositionStrongFieldsStyled,
  PropositionSummaryStyled,
  PropositionTrainerNameStyled,
  SelectionFormTableStyled,
} from '@core/Areas/RaceCardFixedOnly/RaceCardFixedOnly.styles'
import { JockeyName } from '@core/Areas/RaceCard/Components/JockeyName'

type Props = {
  summary: FieldSummary
}
export const RaceFieldSummary: React.FC<Props> = ({ summary }) => {
  if (!summary) return null

  return (
    <PropositionSummaryStyled>
      <SelectionFormTableStyled>
        <Proposition3FieldsStyled>
          <div>
            <PropositionStrongFieldsStyled>F:</PropositionStrongFieldsStyled>
            <span>{summary.lastFour}</span>
          </div>
          <div>
            <PropositionStrongFieldsStyled>Br:</PropositionStrongFieldsStyled>
            <span>{summary.barrier}</span>
          </div>
          <div>
            <PropositionStrongFieldsStyled>W:</PropositionStrongFieldsStyled>
            <span>{summary.jockeyWeight}</span>
          </div>
        </Proposition3FieldsStyled>
        <PropositionJockeyNameStyled>
          <JockeyName
            jockeyName={summary.jockeyName ?? ''}
            isRiderChanged={summary.isRiderChanged}
          />
        </PropositionJockeyNameStyled>
        <PropositionTrainerNameStyled>
          <PropositionStrongFieldsStyled>T:</PropositionStrongFieldsStyled>
          <span>{summary.trainerName}</span>
        </PropositionTrainerNameStyled>
      </SelectionFormTableStyled>
    </PropositionSummaryStyled>
  )
}
