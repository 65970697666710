import { store } from '@core/Store'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { showLogin as showLoginHelper } from '@core/Areas/Login/helpers'

export const dismissBetSlip = () => {
  store.dispatch(setBetSlipNewIsOpen(false))
}

export const showLogin = () => {
  showLoginHelper({
    onLoginSuccessCallback: () => {
      store.dispatch(setBetSlipNewIsOpen(true))
    },
  })
}

export { postAllBetsToServer } from './postAllBetsToServer'
