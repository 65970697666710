/**
 * Payment Processor decline codes as reported by Braintree
 *
 * These codes are the ones that require the customer to contact their bank and we have seen in Sentry
 *
 * @link https://developer.paypal.com/braintree/articles/control-panel/transactions/declines#authorization-decline-codes
 */
const paymentProcessorErrorCodes = [
  '2000', // Do Not Honor
  '2001', // Insufficient Funds
  '2015', // Transaction Not Allowed
  '2074', // Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment
] as const

export type PaymentProcessorErrorCode = (typeof paymentProcessorErrorCodes)[number]

export const isPaymentProcessorErrorCode = (
  errorCode: string
): errorCode is PaymentProcessorErrorCode => {
  return paymentProcessorErrorCodes.includes(errorCode as PaymentProcessorErrorCode)
}
