import type { Ticket } from '@core/Areas/AccountActivity/types'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'

export function getMatchedSkyRaceForTicketLeg(
  leg: Leg,
  nextSkyRaces: NextSkyRace[]
): NextSkyRace | undefined {
  if (!leg.fixtureDate || !leg.meetingName || !leg.raceNumber) return undefined

  const ticketString = createTicketMatchingString(leg)

  const matchedSkyRace = nextSkyRaces.find(
    skyRace => createSkyMatchingString(skyRace) === ticketString
  )

  return matchedSkyRace
}

// =============
// Local Helpers
// =============

const createSkyMatchingString = (skyRace: NextSkyRace): MatchingString =>
  `${skyRace.MeetingDate}-${(skyRace.MeetingName || '').toLowerCase()}-${skyRace.RaceNumber}`

const createTicketMatchingString = (leg: Leg): MatchingString =>
  `${leg.fixtureDate}-${leg.meetingName.toLowerCase()}-${leg.raceNumber}`

// =====
// Types
// =====

type Leg = Ticket['legs'][0]

type MatchingString = `${string}-${string}-${string}`
