import type { PayPalCheckout } from 'braintree-web'

type PayPal = typeof window.paypal

let loadedPayPalSdk: PayPal

export function getPayPal(): NonNullable<PayPal> {
  if (!loadedPayPalSdk) {
    throw new Error('PayPal has not been loaded yet, ensure the initialize call has been made')
  }

  return loadedPayPalSdk
}

export async function loadSdk(sdkLoader: () => Promise<PayPalCheckout>): Promise<void> {
  if (!loadedPayPalSdk) {
    // First we store the current implementation of the PayPal SDK, so
    // we can later restore it after we're done loading the version
    // Braintree needs to properly operate
    const prevPayPalSdk = window.paypal

    // Next, we clear window.paypal so Braintree to make room for Braintree
    // to call PayPal's initialization scripts, which will then populate
    // window.paypal. This needs to be clear to avoid a loading error
    Object.assign(window, {
      paypal: undefined,
    })

    // Now we can actually load PayPal's SDK. After which we cache
    // the resulting window.paypal object in memory. After that
    // we can restore the previously loaded PayPal SDK
    await sdkLoader()
    loadedPayPalSdk = window.paypal
    Object.assign(window, {
      paypal: prevPayPalSdk,
    })
  }
}
