// The distanceRule, trackNameRule, and trackDistanceRule are all valid if we have a value
// other than hyphen (which typically denotes no form data available)
export const distanceRule = (distance?: string) => (distance ?? '').length > 0 && distance !== '-'
export const trackNameRule = (trackName?: string) =>
  (trackName ?? '').length > 0 && trackName !== '-'
export const trackDistanceRule = (trackDistance?: string) =>
  (trackDistance ?? '').length > 0 && trackDistance !== '-'

// If this is the runner's first start following a spell (displayed by an x in last starts)
// AND the runner has statistics in the 1st Up field (i.e. not hyphen), THEN return true
export const firstUpRule = (lastTenStarts: string, firstUp?: string) =>
  lastTenStarts?.endsWith('x') && (firstUp || '').length > 0 && firstUp != '-'

// If this is the runner's second start following a spell (one number following the x in last starts)
// AND the runner has statistics in the 2nd Up field (i.e. not hyphen), THEN return true
export const secondUpRule = (lastTenStarts: string, secondUp?: string) =>
  lastTenStarts.length > 1 &&
  lastTenStarts.charAt(lastTenStarts.length - 2) === 'x' &&
  (secondUp || '').length > 0 &&
  secondUp !== '-'

export const courseConditionRule = (
  courseCondition: string | undefined,
  name: string,
  stats: string | null
) => {
  // For this rule to be true, we want the current course condition to be equal to that of the name
  // of the form data key we are given (name), as well have some form data (stats) defined
  return (
    (courseCondition || '').toLowerCase().startsWith(name.toLowerCase()) && (stats ?? '').length > 0
  )
}
