export const KAMBI_ROOT_ROUTE = 'tabtouch-sports'
export const KAMBI_HOME_HASH_ROUTE = `#${KAMBI_ROOT_ROUTE}/home` as const

export const KAMBI_BETHISTORY_HASH_ROUTE = `#${KAMBI_ROOT_ROUTE}/bethistory` as const
export const KAMBI_PENDING_HASH_ROUTE = `${KAMBI_BETHISTORY_HASH_ROUTE}/open` as const
export const KAMBI_RESULTED_HASH_ROUTE = `${KAMBI_BETHISTORY_HASH_ROUTE}/settled` as const

export const KAMBI_CSS_HIDDEN_CLASS = 'mobi__kambi-hide-element'
export const KAMBI_BETSLIP_DIV_CLASS = 'mobi__kambi-betslip-unpinned'
export const KAMBI_DIV_ID = 'KambiBC'

export const KAMBI_WRAPPER_DIV_ID = 'mobi_kambi-wrapper'
