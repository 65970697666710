export enum BetType {
  WinPlace,
  Quinella,
  Exacta,
  Trifecta,
  First4,
  SameRaceMulti,
  Double,
  Quaddie,
  Mystery,
  AllUp,
}
