import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { BetSlipItem } from '@mobi/betslip/types'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo/BetInfo'
import { isNonHandledError, isFatalMultiBetLegError } from '@mobi/betslip/helpers/state'

export const MultiItem: React.FC<{
  item: BetSlipItem
  isReceipt?: boolean
}> = ({ item }) => {
  const hasMultiLegError =
    !!item.multiBetLegError && isNonHandledError(item.multiBetLegError.betErrorType)

  const isFatalErrorType = isFatalMultiBetLegError(item)

  return (
    <WrapperStyled>
      {(hasMultiLegError || isFatalErrorType) && <div>{item.multiBetLegError?.errorMessage}</div>}
      <BetInfo item={item} isMultiItem />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: spacing.smx1,
  border: `1px solid ${colors.neutral[200]}`,
  borderRadius: radius.lgx1,
})
