import React from 'react'

export const TABtouchBrandLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1500 960'>
      <path
        d='M769 799.4h-25v88c0 9.8 1 12.5 9.2 12.5 5.3.7 22.1-3.2 21.8 6.2v31.2c0 3.6-3 5.5-6.9 6.5C655 966.7 685 876 681 799.8c-5.7-2.5-28.8-2.9-28.5-12.2 1.9-34.4-9.7-35.4 28.5-34.4v-26.4c0-3.6 3.3-6.2 6.9-6.9l48.9-8.5c5.9-1 7.2 2.6 7.2 6.2v35.8h24.6a6.5 6.5 0 0 1 6.4 6.6v33.2a6.6 6.6 0 0 1-6.5 6.6m106.2 146.9C811.2 949 783 900 788.7 839c0-57.6 31.4-88.7 86-88.7 63.5-2.3 91.3 46.8 85.7 107.7 0 57.6-31 88.7-85.7 88.7M898 839c3.2-49-50-49.1-47 0v19c-3 49.1 50.2 49.7 47 0Zm243 104.4h-45.2c-7.5.1-6.7-8.9-6.6-14-52.2 37.8-118 12.5-112.2-58.1V760.6c0-3.6 2.6-7.2 6.6-7.2h49.2a7.5 7.5 0 0 1 7.2 7.2v110.7c-3.2 29.7 26.6 25.8 45 16V760.6c0-3.6 2.6-7.2 6.6-7.2h49.2a7.5 7.5 0 0 1 7.2 7.2V937a6.5 6.5 0 0 1-6.5 6.5m148.9-2.1c-81.2 20.2-130.8-14.1-123-105-3-77.4 64.2-98 123-80.8 4.3 1 6.6 2.6 6.6 6.9v37c0 4-2 6.9-6.9 6.2-24.3-.4-61.8-15.4-59.9 30.8-2.6 62.8 7.4 60.2 60 55.3 4.8-.7 6.8 2 6.8 6.2v36.6c0 4.3-2.3 6-6.6 6.9m189.6 1.9h-49.5a6.6 6.6 0 0 1-6.5-6.5V824c.9-29.1-27.2-23.7-45-14.4v126.6c0 3.6-2.6 7.2-6.6 7.2h-49.2a7.5 7.5 0 0 1-7.2-7.2V697a6.5 6.5 0 0 1 6.5-6.5h50a6.6 6.6 0 0 1 6.5 6.5v66.1c54.8-30.7 112.7-7.4 108.3 61.2v112.9a6.6 6.6 0 0 1-6.5 6.5M404 711.2c-8.5-26.6-8.4-73.1-47.3-73H258l-74 306.2h65.7c9.8 0 19.7-7.4 21.9-16.5l10.2-42.5h80.3ZM297.6 820.4c28.6-117 20.7-116.8 48.6 0Zm300.5-44.3a71.3 71.3 0 0 0 28.1-56.5c3.7-96-119.8-79.4-188.2-79.8l-40.4 167.3C399.8 966 370.2 943 533 943.7c97 7.2 145.5-120.3 65-167.6m-125-68.7h52.5c14.8 0 23 7.3 23 20.5 0 15-8.4 22.5-25 22.5H473v-43Zm54 168h-54v-62h53.6c39.4-3.9 52.4 60.3 0 62M168 944.1l13-53.6v-177h43l17.7-73H23c-11 0-15.7 4.3-15.7 14.7C6.2 734 1.7 709.4 96 713.4v215.8c0 9.7 6 14.9 17.5 14.9H168Z'
        fill='#fff'
      />
      <path d='m1038.6 441.1-25.1 103.4 444-144.3' fill='#72498b' />
      <path
        d='m1006.9 551.6 27.1-111.7 9.1 2.3-23.1 95.3 436-141.7 2.9 8.9-452 146.9z'
        fill='#72498b'
      />
      <g>
        <path
          fill='#f48022'
          d='M480.2 142.6 1207.8 11l-27.6 204.8-9.8 1.4C1032 237.5 629.6 296 486.7 317l-1.5 8.7-36.5 5.2Z'
        />
        <path
          d='m443.2 336.2 33-197.5c2.2-.5 734.3-132.7 737-133.3l-29 214.4-13.1 2-680.5 99-1.4 8.8Zm41-189.7-30 179 27-4 1.5-8.7 45.5-6.6 648-94.4 26.3-195.2Z'
          fill='#72498b'
        />
        <path
          fill='#e0dfde'
          d='m1180.2 215.8 306.7-44.5-27.9 231.1L454.8 509.1l30.4-183.5 1.5-8.8 683.7-99.6Z'
        />
        <path
          d='m449.4 514.3 33.3-201.5 3.3-.5c212.7-30.9 801.8-116.9 1006.2-146.3L1463 406.6c-2.4.4-1010.8 107.2-1013.7 107.7Zm41.2-193.4L460.3 504l994.6-105.7 26.8-221.6c-200.7 29-782.3 113.8-991 144.2Z'
          fill='#72498b'
        />
        <path
          d='M603.2 165c6.7 16-1 35.1-14 45.7 17.8 13.8 10.5 43.7-4.8 58-14 16.5-55.2 16-74.4 20.3-2.6.7-3.9-1.7-3.2-4l20-117.6c.3-1.8 1-3.7 2.7-4.6a6.2 6.2 0 0 1 2-.6l43.8-7.7c10.3-2 23.5-.4 27.9 10.5Zm-37 72.9c.2-10.5-14.1-4.7-19.9-4.3l-3.3 20.3c10.5-1.4 23.5-2 23.2-16m5.3-46.2c.7-9.7-12.4-3.7-17.5-3.5l-2.7 15.8c7.5-1.2 20.7-1.7 20.2-12.3'
          fill='#fff'
        />
        <path
          d='M629.4 330.3c3.2-.8 3 2.7 1.5 4.6l-53.4 88.5-6.4 38.8c-.3 2.5-1.7 5.2-4.5 5.2l-24.7 2.8a6.5 6.5 0 0 1-1.1 0c-6 2 5.2-41.2 4.3-43-.2-1.4-22.6-76.2-22.8-77.6a4 4 0 0 1 3.4-5c3.7.4 26.4-6 27.7-1 4 12 9.1 35.2 12.1 47.7l1-.1c8.3-15 20.7-40.5 30.3-54.4 2.4-4.4 28.4-5 32.6-6.5Z'
          fill='#512d6d'
        />
        <path
          d='M704.4 132c2.5-.7 3.9 1.7 3.3 4l-3.6 22.6a5.8 5.8 0 0 1-5 5l-41.4 7-2.5 15.6 35.7-5.9c2.5-.6 4 1.8 3.3 4-1.3 3.6-2 25.4-6.3 27a6.6 6.6 0 0 1-2 .7l-35.7 5.7-3.3 20 43-6.7c2.5-.7 3.9 1.8 3.3 4-1 3-2.7 25-5.6 26.7a6 6 0 0 1-2.9 1.2L613 273.6c-2.7.8-4-1.7-3.4-4 1-2.6 19-120.7 20.8-122.7-2.2-3.8 72.7-13.8 74-14.9Zm101.8-17.8c2.6-.7 4.1 1.6 3.5 4l-3.5 23a6 6 0 0 1-5.2 5.1l-24.5 4.2C774 153.6 764.4 255 756.7 252c-1 0-29.2 5-29 3.6-1-1-1.1-2.5-.9-3.8l15.2-95.5c-33.1 4.5-28.5 12.1-23.4-22.4.3-2.8 2-5.2 5-5.4Z'
          fill='#fff'
        />
        <path
          d='M1166.8 273.8c9.4 17.8.3 40.5-15.3 51.7 25.7 17.3 13.9 58.2-11.3 72-15.5 11.5-66.4 11.6-84.8 15-1.6.3-3.6 0-4-1.8-1.3-2.5 19.2-133.3 19-136a6.3 6.3 0 0 1 5.7-5.9c22.7-.1 76.8-21.2 90.7 5Zm-43 82c-.6-12.2-18.2-5.5-25.4-5.5l-3.1 23.1c11.7-1.3 29.6-1 28.5-17.6m4.3-52.5c.2-11.2-16-4.4-22.3-4.5l-2.6 18c8.6-1.3 25.8-.6 25-13.5m165.3-64.6c2.9-.7 5 1.9 4.4 4.7l-3.4 25.7a6.4 6.4 0 0 1-6 5.6l-52.2 7-2.3 17.7 45-5.8a3.8 3.8 0 0 1 4.4 4.6c-1.4 4-1.4 29.2-6.6 30.8a9.3 9.3 0 0 1-2.6.6l-45.1 5.6-3.2 22.7 54.3-6.4a3.8 3.8 0 0 1 4.5 4.8c-1 3.4-2.1 28.5-5.7 30.3-1 1.1-97 13.3-98.2 10.3a5 5 0 0 1-.6-3.6c.9-2.7 18.2-136 19.6-138.4-4-6.2 92-14.5 93.7-16.2ZM730.3 348c3.8 31.1-.3 68-22.5 91.7a67 67 0 0 1-51.9 18.5c-26.4-.4-33-29.3-30.6-50.8 1.8-26.3 8-55.1 29.2-72.8 21.9-19.1 70.4-23.1 75.8 13.4Zm-33.6 22.3c2.4-17-14.2-21.2-24.6-10-9 13.1-10.4 30.7-11.8 46.4-3.1 24.1 23.1 21.2 29 2.7 4.7-12 6.8-26 7.4-39'
          fill='#512d6d'
        />
        <path
          d='M908 96.3c2.6-.7 4.3 1.7 3.6 4.1l-3.5 23.5a6 6 0 0 1-5.3 5.3l-25.5 4.3c-.1.6-15 98-15.2 98.6-.5 2.4-1.9 4.7-4.6 4.9-1.8-.3-30 6-30.6 3.2a4.9 4.9 0 0 1-.4-3.3l15-97.3c-33.7 4.2-30 13.4-24.5-22.6.2-3 2-5.3 5.1-5.5Z'
          fill='#fff'
        />
        <path
          d='M853.8 299.5c3-.8 4.2 1.8 3.5 4.3-3.8 17.9-11.3 88.5-18.4 104.2-10.8 31.2-54.7 48.2-82.3 29-11.6-9.6-13.3-26-10.8-40.2 2.4-3 9.5-89 17.7-84.8 1.4 0 29.2-5 29.4-3a5.6 5.6 0 0 1 .4 3.6l-12.4 78.7c-5.9 30.9 28.1 20 28.5-3.5l12.2-79a7.5 7.5 0 0 1 2.2-4.4c.7-1.7 28.1-4.3 30-5Z'
          fill='#512d6d'
        />
        <path
          d='M1009.6 78.6c2.7-.8 4.5 1.6 3.9 4.1l-3.5 23.9a6.3 6.3 0 0 1-5.5 5.4l-46.5 8-2.4 16.5 40-6.7c2.8-.7 4.5 1.7 4 4.2-1.4 3.8-1.9 27-6.5 28.6a8 8 0 0 1-2.4.7l-40 6.4-3.3 21.2 48.3-7.5c2.7-.8 4.5 1.7 3.9 4.3-1.4 3.7-1.8 27.2-6.5 28.7a8 8 0 0 1-2.4.7l-80.6 12.1c-1.5.3-3.3 0-3.8-1.6-1.1-2.4 19.3-126.9 19.2-129.5a8.3 8.3 0 0 1 1-2.8c-3.5-3.5 82.3-15.8 83.1-16.6Z'
          fill='#fff'
        />
        <path
          d='M971 303c9.9 22.8-1.6 50.3-21.8 63.6l14.6 50.1c1.1 3.2-.1 6.4-3.8 6.5l-25.9 3c-3.5.5-7.5-.5-8.2-4.6l-10.3-43.5-10.1 1.3-7 45.8c-.3 2.6-1.7 5.4-4.8 5.5l-28 3.1c-3 .7-4.6-1.8-4-4.5l19.8-128.8c.3-2 1.2-4 3-5a7.2 7.2 0 0 1 2.3-.6l49.8-6.9c13.2-2.2 28.7 1.7 34.5 15Zm-35.7 26.6c.2-11.4-15-5.7-21.5-5.5l-3.2 22.5c11-.9 25.4-2.7 24.7-17'
          fill='#512d6d'
        />
        <path
          d='M1126 77.8c10.8 23-1.9 50.9-22.1 64.5l15.8 49.3c1.3 3.3-.2 6.4-3.8 6.7l-27 4c-3.6.7-7.9 0-8.7-4.1l-11.2-43-10.6 1.8-6.6 46c-.4 2.6-2 5.5-5 5.6l-29.4 4.4c-2.8.8-4.8-1.5-4-4.3l19-129.3c.4-2.4 1.8-4.8 4.3-5.5 1-.4 52-9 53.2-9.4 13.3-2.7 29.7 0 36.1 13.3Zm-37 28.1c-.1-11.5-16-4.8-22.5-4.4l-3.1 22.5c11-1.3 26.5-3.6 25.6-18'
          fill='#fff'
        />
        <path
          d='M1422.5 221a3.8 3.8 0 0 1 4.6 4.7l-3.3 26.2a6.7 6.7 0 0 1-6.3 5.8l-31.1 4.1-14.3 109.8c-.4 3-2.2 6-5.6 6-2.4-.4-36.5 5.7-37.4 2.6a5.6 5.6 0 0 1-.7-3.7l14.4-109c-40.1 3-36.5 16.2-31-26a6.2 6.2 0 0 1 6.1-6.1Z'
          fill='#512d6d'
        />
      </g>
    </svg>
  )
}
