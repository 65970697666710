import React, { type FC } from 'react'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import type { PanelProps } from '../types'
import { CreditCardPanel } from './CreditCard'
import { ApplePayButtonWeb, ApplePayButtonNative } from './ApplePay'
import { GooglePayButtonWeb, GooglePayButtonNative } from './GooglePay'
import { PayPalButtonWeb, PayPalButtonNative } from './PayPal'

type Props = PanelProps & {
  paymentMethod: PaymentMethods
  isNative: boolean
}

export const PaymentButton: FC<Props> = ({ paymentMethod, isNative, ...panelProps }) => {
  const Component = determinePaymentMethodPanel(paymentMethod, isNative)

  return <Component {...panelProps} />
}

PaymentButton.displayName = 'PaymentButton'

function determinePaymentMethodPanel(
  paymentMethod: PaymentMethods,
  isNative: boolean
): FC<PanelProps> {
  switch (paymentMethod) {
    case 'CreditCard':
      return CreditCardPanel
    case 'ApplePay':
      return isNative ? ApplePayButtonNative : ApplePayButtonWeb
    case 'PayPal':
      return isNative ? PayPalButtonNative : PayPalButtonWeb
    case 'GooglePay':
      return isNative ? GooglePayButtonNative : GooglePayButtonWeb
  }
}
