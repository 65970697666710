import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BettingHeader } from '@core/Components/BettingHeader/BettingHeader'
import { betslipBettingHeader } from '@core/Components/BettingHeader/themes'

interface ErroredBetInfoProps {
  message?: string
  details?: string
  removeHandler(): void
}

export function ErroredBetInfo({
  removeHandler,
  message = 'There is a problem with your bet',
  details = 'We were unable to retrieve some details',
}: ErroredBetInfoProps): JSX.Element {
  return (
    <BettingHeader
      icon={<Icon size={'3rem'} type={'errorCircled'} color='red' />}
      heading={<span>{message}</span>}
      subheading={<span>{details}</span>}
      pilled={null}
      timeInfo={null}
      selection={null}
      theme={betslipBettingHeader}
      removeHandler={removeHandler}
      displayPill={false}
      isEditable={true}
    />
  )
}
