import React from 'react'
import { useQueryClient } from 'react-query'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableImmutable } from './useObservableImmutable'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { usePrevious } from './usePrevious'

export const useIsAuthenticated = (): boolean => {
  const { isLoggedIn = null } = useObservableImmutable(userAccountState$, ['isLoggedIn'])
  const prevIsLoggedIn = usePrevious(isLoggedIn)
  const queryClient = useQueryClient()

  // initialize the logged in state by synchronously reading from Rx
  // - this is required to avoid interpreting the temporary value of isLoggedIn=null (which is not retrieved synchronously) as false
  // - without this change, it will cause unusual (and unexplained) effects with useFeature() which fails to retrieve the current state synchronously
  const [isLoggedInInitialValue] = React.useState(() => {
    let value: boolean | null = null
    userAccountState$.take(1).subscribe(record => {
      value = record.get('isLoggedIn')
    })
    return value
  })

  React.useEffect(() => {
    if (isLoggedIn === false && !prevIsLoggedIn) {
      // Timeout fixes first nav where routing code resets actions
      window.setTimeout(() => {
        queryClient.invalidateQueries()
        showLogin({
          onLoginCancelledRedirectUrl: '/#',
        })
      }, 200)
    }
  }, [isLoggedIn, prevIsLoggedIn, queryClient])

  return isLoggedIn !== null ? isLoggedIn : isLoggedInInitialValue || false
}
