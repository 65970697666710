import React from 'react'
import { GridRow, GridCell } from '@mobi/component-library/Common/Grid'

import { ActiveCampaignsComponent } from './ActiveCampaign'
import { MysteryBonusButtonStyled } from '../Buttons/BonusBetButton.styles'
import { useFeature } from '@core/Utils/hooks'

type BonusCashCampaignProps = {
  defaultValue: boolean
  toggleCampaign: (isApplied: boolean) => void
}

export function BonusCashCampaign({ defaultValue, toggleCampaign }: BonusCashCampaignProps) {
  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')
  const [useBonusCash, setUseBonusCash] = React.useState(defaultValue)

  if (!isBonusCashImprovementsActive) {
    return null
  }

  const handleBonusHandler = () => {
    const newState = !useBonusCash
    setUseBonusCash(newState)
    toggleCampaign(newState)
  }

  return (
    <GridRow padding='0.5rem 0.5rem 0 0.5rem'>
      <GridCell>
        {!useBonusCash ? (
          <MysteryBonusButtonStyled onClick={handleBonusHandler}>
            Use My Bonus
          </MysteryBonusButtonStyled>
        ) : (
          <ActiveCampaignsComponent
            handleRemoveBonusCashButtonClick={handleBonusHandler}
          ></ActiveCampaignsComponent>
        )}
      </GridCell>
    </GridRow>
  )
}
