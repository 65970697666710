import React from 'react'
import { dayjs } from '@mobi/utils'
import type { CancellationState, Transaction } from '@core/Areas/AccountActivity/types'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { post } from '@classic/Foundation/Services/ApiService'
import { trackWithdrawalCancel } from '@core/Areas/Withdraw/analytics'
import { UpdateAccountBalance } from '@core/State/UserAccount/userAccountDriver'
import { PendingWithdrawalDetailsStyled } from './PendingWithdrawalDetails.styles'

export const PendingWithdrawalDetails: React.FC<{
  cancellationState: CancellationState
  setCancellationState: React.Dispatch<React.SetStateAction<CancellationState>>
  transactionNumber: Transaction['tranTransSeq']
  transactionTimestamp: Transaction['timestamp']
}> = ({ cancellationState, setCancellationState, transactionNumber, transactionTimestamp }) => {
  return (
    <PendingWithdrawalDetailsStyled data-testid='transaction-pending-withdrawal'>
      <ButtonBlock
        data-testid='transaction-cancel-withdrawal'
        onClick={() =>
          cancelWithdrawal(setCancellationState, transactionNumber, transactionTimestamp)
        }
        disabled={cancellationState === 'waiting'}
        isBusy={cancellationState === 'waiting'}
      >
        {cancellationState === 'initial' && <span>Cancel Withdrawal</span>}
      </ButtonBlock>
    </PendingWithdrawalDetailsStyled>
  )
}

// =============
// Local Helpers
// =============

export interface WithdrawalCancellationResponse {
  success: boolean
  failureReason: string | null
  cancelTransactionNumber: number | null
  cancelTransactionTimestamp: Date | null
  balance: number | null
}

const cancelWithdrawal = async (
  setState: React.Dispatch<React.SetStateAction<CancellationState>>,
  transactionNumber: Transaction['tranTransSeq'],
  transactionTimestamp: Transaction['timestamp']
) => {
  setState('waiting')

  const response = await post<WithdrawalCancellationResponse>({
    url: '/$_/accountActivity/tickets/transactions/withdrawal-cancel',
    body: {
      TransactionNumber: transactionNumber,
    },
  }).catch(() => {
    setState('error')
  })

  if (!response || !response.success) {
    setState('error')
  } else {
    setState('completed')

    trackWithdrawalCancel(
      dayjs(response.cancelTransactionTimestamp).diff(transactionTimestamp, 'hours')
    )

    UpdateAccountBalance !== undefined &&
      response.balance !== null &&
      UpdateAccountBalance({ balance: response.balance })
  }
}
