import React from 'react'
import { Observable } from 'rx'
import { RefreshIcon } from '@core/Components/Icons'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { RefreshBetslip } from '@core/Areas/Betslip/signals'
import { state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { RefreshButtonStyled } from './Buttons.styles'

interface RefreshBetslipButtonComponentProps {
  isLoggedIn: boolean
  isBusy: boolean
}

export function RefreshBetslipButtonComponent({
  isLoggedIn,
  isBusy,
}: RefreshBetslipButtonComponentProps): JSX.Element | null {
  const isDisabledRef = React.useRef(false)

  const refreshBetslip = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (!isDisabledRef.current && !isBusy) {
      isDisabledRef.current = true
      RefreshBetslip()

      setTimeout(() => {
        isDisabledRef.current = false
      }, 30000)
    }
  }

  return isLoggedIn ? (
    <RefreshButtonStyled onClick={refreshBetslip} data-testid='betslip-refresh-btn'>
      <RefreshIcon />
    </RefreshButtonStyled>
  ) : null
}

const state$ = Observable.combineLatest(
  userAccountState$,
  betslipState$,
  (userAccountState, betslipState) => ({
    isLoggedIn: userAccountState.isLoggedIn,
    isBusy: betslipState.isBusy,
  })
)

export const RefreshBetslipButton = observeImmutable(state$, ({ record }) => (
  <RefreshBetslipButtonComponent isLoggedIn={record.isLoggedIn ?? false} isBusy={record.isBusy} />
))
