export enum RaceProduct {
  Challenge = 'CLG',
  FeatureRace = 'FR',
  FutureFinalField = 'FFF',
  FixedOddsOnly = 'FOO',
}

export enum RaceStatus {
  Open,
  Suspended,
  Scratched,
  Closed,
  Resulted,
  Settled,
  Abandoned,
}
