import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { hexColors } from '@mobi/settings'

interface BettingDrawerStyledProps {
  show: boolean
  changed: boolean
}

export const MultiToastContainerStyled = styled('div')<BettingDrawerStyledProps>(
  {
    position: 'fixed',
    bottom: '6rem',
    right: '10px',
    height: '50px',
    minWidth: '170px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    zIndex: 8,
    backgroundColor: transparentize(0.05, hexColors.charCoal),
    display: 'flex',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  ({ show, changed }) => ({
    // show: show/hide via opacity for a 'disolve' effect.. immediate to show and slow to hide
    // changed: slide in/out.. quick to slide in and slow to slide out
    transition: `transform ${changed ? '0.3s ease-out' : '1.5s ease-in'},
                 opacity ${show ? '0s' : '0.5s ease-out'}`,

    transform: `translateX(${changed ? '0px' : '250px'})`,

    // conditionally apply the transform only if it should be shown
    // - this stops the slide right transform happening at the same time as the opacity to zero
    // - https://stackoverflow.com/a/73518427/227110
    // ...(show ? { transform: `translateX(${changed ? '0px' : '160px'})` } : {}),

    opacity: `${show ? '1' : '0'}`,
  })
)
