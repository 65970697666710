import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { trackLoginFailed } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { refreshLogonState } from './refreshLogonState'
import { postLogon } from './api'

export const attemptLogin = (data: Parameters<typeof postLogon>[0]): Promise<AttemptLoginReturn> =>
  postLogon(data)
    .then(result => {
      if (result.isSuccessful) {
        refreshLogonState()
        return { isSuccessful: true }
      }

      if (!result.mustChangePassword) trackLoginFailed(data.accountNumber)

      // Only the React Native app needs to be notified. It hooks into this message and removes
      // credentials from the phone's KeyChain as they're no longer valid.
      if (isReactNativeApp && !!data.loginViaBioAuth) {
        sendToNative('INCORRECT_PASSWORD_OR_ACCOUNT', {
          accountNumber: data.accountNumber,
          requiresReset: result.mustChangePassword,
        })
      }

      return {
        isSuccessful: false,
        mustChangePassword: result.mustChangePassword,
        errorMessage: 'The account or password details you entered are invalid. Please try again.',
      }
    })
    .catch(() => ({
      isSuccessful: false,
      errorMessage: 'Sorry, something went wrong. Please try again.',
    }))

// =====
// Types
// =====

interface AttemptLoginReturn {
  isSuccessful: boolean
  mustChangePassword?: boolean
  errorMessage?: string
}
