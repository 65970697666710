import React from 'react'
import { Observable } from 'rx'
import type { BettingType } from '@mobi/betslip/types'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { NotificationType } from '../Notifications/NotificationTypes'
import { QuickbetState, state$ as quickbetState$ } from '../../driver'
import { observeImmutable } from '../../../../Components/HOCs'
import { Campaigns } from './Campaigns'
import { ToggleBonusCashUsage } from '../../signals'
import { state$ as userState$ } from '@core/State/UserAccount/userAccountDriver'
import { useFeature } from '@core/Utils/hooks'

interface CampaignsStateProps {
  canBet: boolean
  bettingType: BettingType | null
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  canConfirmBet: boolean
  accountNumber: number | null
  accountBalance: number | null
  bonusCashBalance: number | null
  bonusBetBalance: number | null
  notification: NotificationType | null
}

const state$ = Observable.combineLatest(
  quickbetState$,
  userState$,
  (quickbetState, userState): CampaignsStateProps => {
    const quickbetStateValue = quickbetState.toJS() as QuickbetState
    {
      return {
        canBet: quickbetStateValue.canBet,
        bettingType: quickbetStateValue.bettingType,
        isUsingBonusBet: quickbetStateValue.isUsingBonusBet,
        isUsingBonusCash: quickbetStateValue.isUsingBonusCash,
        canConfirmBet: quickbetStateValue.canConfirmBet,
        accountNumber: userState.accountNumber,
        accountBalance: userState.accountBalance,
        bonusCashBalance: userState.bonusCashBalance,
        bonusBetBalance: userState.bonusBetBalance,
        notification: quickbetStateValue.notificationType,
      }
    }
  }
)

export const QuickbetCampaigns: React.ComponentClass = observeImmutable(state$, ({ record }) => {
  const hasSuperPickError = record.notification === NotificationType.InvalidSuperPickSelection
  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')
  return (
    <div data-testid='QuickbetCampaigns'>
      <Campaigns
        shouldDisplay={record.canBet && !record.isUsingBonusBet}
        bettingType={record.bettingType}
        handleRemoveBonusCashButtonClick={() => {
          ToggleBonusCashUsage(false)
          trackBonusBetEvent(keys.quickbetBonusCashRemoved, {
            accountBalance: record.accountBalance,
            accountNumber: record.accountNumber || '',
            bonusBetBalance: record.bonusBetBalance,
            bonusCashBalance: record.bonusCashBalance,
          })
        }}
        showRemoveButton={
          !hasSuperPickError &&
          !record.canConfirmBet &&
          (record.bettingType === 'fixed-odds-racing' ||
            (isBonusCashImprovementsActive && record.bettingType === 'tote-racing') ||
            (isBonusCashImprovementsActive && record.bettingType === 'mystery-quick-pick'))
        }
      />
    </div>
  )
})
QuickbetCampaigns.displayName = 'QuickbetCampaigns'
