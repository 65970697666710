import { CSSObject } from '@emotion/styled'

export function columnGap(gap: string): CSSObject {
  return {
    columnGap: gap,

    [`@supports not (column-gap: ${gap})`]: {
      '> *:not(:last-child)': {
        marginRight: gap,
      },
    },
  }
}
