import React from 'react'
import { PopupContainerStyled, PopupStyled } from '@core/Components/Popup/Popup.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'

interface PopupNoticeBoxProps {
  title: string
  subtitle?: string
  noticeBoxType?: NoticeBoxTypes
  onClose?: () => void
  closeText?: string
  children?: React.ReactNode
}

export function PopupNoticeBox({
  title,
  subtitle,
  noticeBoxType,
  onClose,
  closeText = 'Close',
  children,
}: PopupNoticeBoxProps) {
  return (
    <PopupContainerStyled>
      <PopupStyled>
        <NoticeBox title={title} subtitle={subtitle} noticeBoxType={noticeBoxType}>
          {children}
        </NoticeBox>
        <ButtonBlock
          onClick={() => onClose?.()}
          color='primary'
          uppercaseText={true}
          testId='notice-box-close'
        >
          {closeText}
        </ButtonBlock>
      </PopupStyled>
    </PopupContainerStyled>
  )
}
