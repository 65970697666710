import * as ko from 'knockout'
import { RovingBankerViewModel } from './RovingBankerViewModel'
import template from './rovingbanker.tpl.html'

if (!ko.components.isRegistered('roving-banker')) {
  ko.components.register('roving-banker', {
    viewModel: RovingBankerViewModel,
    template,
  })
}
