import React from 'react'
import { useSelector } from 'react-redux'
import { selectBetSlipFobSelectionsIds } from '@mobi/betslip/Store/Bets/selectors'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import { updateBetSlipFobItemPrice } from '@mobi/betslip/Store/Bets'
import { subscribe, unsubscribe } from '@core/State/PushData/awsIot'
import { store } from '@core/Store'
import {
  event$,
  type FobPriceChangedPushEvent,
  getFobPriceChangeByPropositionTopic,
  isFobPriceChangedPushEvent,
} from '@core/State/PushData'

export const useRealtimeFobPriceChanges = (shouldEnable: boolean) => {
  const fobIds = useSelector(selectBetSlipFobSelectionsIds)
  const workflowStatus = useSelector(selectWorkflowStatus)

  const currentSubscriptionsRef = React.useRef(new Set<string>())
  const workflowStatusRef = React.useRef(workflowStatus)

  workflowStatusRef.current = workflowStatus

  // ==================================
  // Handle fob selection subscriptions
  // ==================================
  React.useEffect(() => {
    if (!shouldEnable) return

    fobIds.forEach(id => {
      if (currentSubscriptionsRef.current.has(id)) return
      subscribe([getFobPriceChangeByPropositionTopic(id)])
      currentSubscriptionsRef.current.add(id)
    })
    Array.from(currentSubscriptionsRef.current)
      .filter(subscribedId => !fobIds.includes(subscribedId))
      .forEach(id => {
        unsubscribe([getFobPriceChangeByPropositionTopic(id)])
        currentSubscriptionsRef.current.delete(id)
      })
  }, [fobIds, shouldEnable])

  // ===========================================
  // Listen for price changes, update selections
  // ===========================================
  React.useEffect(() => {
    if (!shouldEnable) return

    const fobPriceChangedSubscription = event$
      .filter(e => isFobPriceChangedPushEvent(e.payload))
      .map(e => e.payload as FobPriceChangedPushEvent)
      .groupBy(e => e.propositionId)
      .flatMap(group => group.debounce(2500))
      .subscribe(({ propositionId, winPrice, placePrice }) => {
        if (workflowStatusRef.current !== 'ready' || !winPrice) return
        if (!currentSubscriptionsRef.current.has(`${propositionId}`)) return

        store.dispatch(
          updateBetSlipFobItemPrice({ propositionId: `${propositionId}`, winPrice, placePrice })
        )
      })

    return () => {
      fobPriceChangedSubscription.dispose()
    }
  }, [shouldEnable])
}
