import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { CreditCardButton } from './CreditCardButton'
import { CreditCardSelector } from '@mobi/component-library/Deposit/CreditCard'
import { isCardValid } from '@mobi/component-library/Deposit/helpers'
import { PaymentMethod } from '@mobi/api-types'
import { routes } from '../../../Utils'
import type { PanelProps } from '../../types'
import { selectDepositFlow } from '../../../../../Store'
import { track } from '../../../../../Utils/analytics'

export const CreditCardPanel = ({
  initialData,
  accountNumber,
  depositAmount,
  isDepositAllowed,
  onStart,
  onCancel,
  onDepositing,
  onSuccess,
  onFailure,
}: PanelProps) => {
  const history = useHistory()
  const flow = useSelector(selectDepositFlow)

  // TODO: Pass this as a prop after MOBI-2697 has been merged
  // See https://rwwa.atlassian.net/browse/MOBI-2697
  const primaryCard = initialData?.paymentMethods.find(card => card.isDefault)

  const [selectedCard, setSelectedCard] = useState<PaymentMethod | undefined>(() => primaryCard)

  const selectedCardIsDebitCard = selectedCard?.isDebitCard ?? false
  const selectedCardHasExpired = selectedCard?.isExpired ?? false
  const canDeposit = isDepositAllowed && selectedCardIsDebitCard && !selectedCardHasExpired

  const onCardSelected = (card: PaymentMethod) => {
    setSelectedCard(card)
    track('deposit_change_card', {
      accountNumber,
      depositVariant: flow,
    })
  }

  const navigateToManageCards = () => {
    history.push(routes.manageCards)
    track('deposit_manage_cards', {
      accountNumber,
      paymentMethod: 'CreditCard',
      depositVariant: flow,
    })
  }

  if (!primaryCard || !selectedCard) {
    return (
      <Button
        aria-label='Add a new card'
        color='secondary_grey'
        size='md'
        isFullWidth={true}
        onClick={navigateToManageCards}
      >
        <Icon name='LinePlusCircle' />
        <span>Add a new card</span>
      </Button>
    )
  }

  // Expired cards should not show up in the list
  const selectableCards = initialData?.paymentMethods.filter(isCardValid) ?? []

  /** Has existing cards */
  return (
    <>
      <CreditCardButton
        initialData={initialData}
        accountNumber={accountNumber}
        depositAmount={depositAmount}
        isDepositAllowed={canDeposit}
        creditCard={selectedCard}
        showIssuer={false}
        onStart={onStart}
        onCancel={onCancel}
        onDepositing={onDepositing}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />

      <CreditCardSelector
        cards={selectableCards}
        selectedCard={selectedCard}
        primaryCard={primaryCard}
        isOpenByDefault={false}
        onSelect={onCardSelected}
      />

      <Button color='secondary_grey' size='md' isFullWidth onClick={navigateToManageCards}>
        <Icon name='LinePencil01' />
        <span>Manage Cards</span>
      </Button>
    </>
  )
}
