import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { BETSLIP_ITEM_PADDING } from '../SingleItemList/SingleItemList.styles'
import { Money } from '@core/Components/Text/Money'

export const ReceiptHeaderStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: colors.betslip.receipt.header.background,
  color: colors.betslip.receipt.header.color,
  padding: BETSLIP_ITEM_PADDING,
  margin: `-${BETSLIP_ITEM_PADDING} -${BETSLIP_ITEM_PADDING} 1rem -${BETSLIP_ITEM_PADDING}`,
})

export const ReceiptHeaderTextStyled = styled('span')({
  fontWeight: 'bold',
  fontSize: '1.4rem',
  paddingLeft: '1rem',
})

export const ReceiptMultiItemContainerStyled = styled('div')({
  marginBottom: '1rem',
  paddingBottom: '1rem',
  borderBottom: `1px solid ${colors.betslip.multiDetails.border}`,
})

export const ReceiptListItemFixedMoneyStyled = styled(Money)({
  marginRight: '0.5rem',
  textAlign: 'right',
  width: '4rem',
})
