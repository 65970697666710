import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { lessOrEqualThan } from '@mobi/component-library/Utils/media'

export const AccountListWrapperStyled = styled.div({
  padding: '0 0.5rem',
  marginBottom: '1.5rem',
})

export const AccountListStyled = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  margin: 0,
  padding: 0,
  listStyleType: 'none',
})

export const AccountListItemStyled = styled('li')({
  width: 'calc(50% - 0.5px)',
  marginBottom: '1px',
})

export const AccountLinkStyled = styled('a')({
  display: 'flex',
  alignItems: 'center',
  height: '5rem',
  margin: 0,
  padding: 0,

  fontWeight: 'bold',
  fontSize: '1.3rem',
  textDecoration: 'none',
  lineHeight: 1,
  color: hexColors.nightRider,
  background: hexColors.white,
  boxShadow: '0 1px 1px rgba(0,0,0,0.1)',

  '& > svg': {
    fontSize: '2rem',
    margin: '0 0.5rem',
    color: hexColors.lola,
  },

  [lessOrEqualThan('px350')]: {
    fontSize: '1.2rem',
  },
})

export const AccountButtonStyled = styled(AccountLinkStyled)({
  width: '100%',
  border: 0,
}).withComponent('button')

export const AccountBalanceStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: '2rem 0 2rem 0.6rem',
  fontSize: '3rem',
  fontWeight: 'bold',

  '& > button': {
    padding: '0.5rem',
    marginLeft: '1rem',
  },
})

export const BonusBalanceStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '2rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '7em',
})

export const BonusItemStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1',
})

export const BonusBalanceTitleStyled = styled.div({
  textTransform: 'uppercase',
  fontSize: '1.3rem',
  fontWeight: 'bold',
  lineHeight: 1.2,
  color: hexColors.grey,
})

export const BonusBalanceValueStyled = styled.div({
  color: hexColors.black,
  fontSize: '2rem',
  fontWeight: 'bold',
  paddingTop: '0.2em',
})

export const AccountNumberBadgeStyled = styled('span')<{ isMaxCount?: boolean }>(
  {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0.5rem',
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    borderRadius: '50%',
    lineHeight: '2rem',
    textAlign: 'center',
    backgroundColor: hexColors.freeSpeechRed,
    color: hexColors.white,
  },
  ({ isMaxCount = false }) => ({
    fontSize: isMaxCount ? '1rem' : '1.2rem',
    ...(isMaxCount && {
      '&::after': {
        content: '"+"',
        letterSpacing: '-2px',
      },
    }),
  })
)

export const AccountSubHeading = styled.div({
  color: hexColors.nero,
  fontSize: '1.3rem',
  fontWeight: 'bold',
  paddingBottom: '0.5rem',
})
