import Rx from 'rx'
import { mapObject } from '../helpers'
import { get } from '@classic/Foundation/Services/ApiService'

export interface SuccessfulMapResult<Resource> {
  keys: string[]
  result: {
    [typeKey: string]: {
      [key: string]: Resource[]
    }
  }
}

export interface FailedMapResult {
  keys: string[]
  error: Error
}

export function fetchThenMap<WireResource, Resource>(
  requestedKey: string,
  resourceType: string,
  typeKey: string,
  iteratee: (wire: WireResource) => Resource,
  createKeysAndGroup: (resources: WireResource[]) => { [newKey: string]: WireResource[] }
): Rx.Observable<SuccessfulMapResult<Resource> | FailedMapResult> {
  return Rx.Observable.fromPromise(
    get<WireResource[]>({ url: `/api/contentful/${resourceType}` })
      // Components want to filter results (eg. with or without inducements) so we create new keys
      .then(mappedItems => createKeysAndGroup(mappedItems))
      // Map each field to our interface
      .then(keysWithWireResources =>
        mapObject<typeof keysWithWireResources, { [key: string]: Resource[] }>(
          keysWithWireResources,
          // @ts-expect-error Typing issues
          (value: WireResource[]) => value.map(iteratee)
        )
      )
      // Build the result that data access expects
      .then(keysWithResources => ({
        keys: [requestedKey],
        result: {
          [typeKey]: keysWithResources,
        },
      }))
      .catch(err => ({
        keys: [requestedKey],
        error: err,
      }))
  )
}
