import * as dtos from '../DataTransferObjects/DataTransferObjects'
import ObservablePool from '../Model/Observables/ObservablePool'
import ObservableRaceKey from '../Model/Observables/ObservableRaceKey'
import ObservablePage from '../Model/Observables/ObservablePage'

export default class PoolMapper {
  public static map(dto: dtos.RacePageDataTransferObject, model: ObservablePage) {
    for (let pool of dto.AvailablePools) {
      let poolTotal = this.createObservablePool(pool)
      model.addPoolTotal(poolTotal)
    }
  }

  public static mapAllUpPools(dto: dtos.RaceKeyDataTransferObject, model: ObservableRaceKey) {
    for (let pool of dto.AllUpPools) {
      let poolTotal = this.createObservablePool(pool)
      model.addAllUpPool(poolTotal)
    }
  }

  private static createObservablePool(poolDto: dtos.PoolDataTransferObject): ObservablePool {
    let poolTotal = new ObservablePool()
    poolTotal.hasJackpot(poolDto.HasJackpot)
    poolTotal.isAbandoned(poolDto.IsAbandoned)
    poolTotal.jackpotTotal(poolDto.JackpotTotal)
    poolTotal.name(poolDto.Name)
    poolTotal.total(poolDto.Total)
    poolTotal.poolingIcon(poolDto.PoolingIcon)
    poolTotal.jurisdictionDescription(poolDto.JurisdictionDescription)

    poolDto.RaceNumbers.forEach(raceNumber => {
      poolTotal.raceNumbers.push(raceNumber)
    })

    poolTotal.hasPoolingIcon(
      poolTotal.poolingIcon() != undefined && poolTotal.poolingIcon().length > 0
    )
    poolTotal.isInternationalPool(
      poolTotal.poolingIcon() != undefined && poolTotal.poolingIcon() === 'INT'
    )

    return poolTotal
  }
}
