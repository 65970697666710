import React from 'react'
import { state$ as commandState$, BettingV2CommandStateRecord } from '../../Commands/driver'
import { observeImmutable } from '@core/Components/HOCs'
import {
  MarketMoverContainer as MarketMoversContainer,
  MarketMoversDividend,
  MarketMoversHeader,
} from './MarketMovers.styles'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { toOdds } from '@core/Utils/odds/odds'

export interface MarketMoversDividendProps {
  MinutesToAst: number
  Dividend: number | null
}

interface MarketMoversWrapperComponentProps {
  showMarketMovers: boolean
  starter: MarketMoversStarterProps
}

interface MarketMoversStarterProps {
  isScratched: boolean
  marketMovers: MarketMoversDividendProps[]
}

export const MarketMovers = observeImmutable<BettingV2CommandStateRecord, MarketMoversStarterProps>(
  commandState$,
  ({ record, ...props }) => {
    const { showMarketMovers } = record
    return <MarketMoversWrapperComponent showMarketMovers={showMarketMovers} starter={props} />
  }
)

export function MarketMoversWrapperComponent({
  showMarketMovers,
  starter,
}: MarketMoversWrapperComponentProps): JSX.Element | null {
  const { isScratched, marketMovers } = starter

  if (!showMarketMovers) return null

  if (isScratched || marketMovers.length === 0 || !marketMovers.some(m => m.Dividend != null)) {
    return null
  }

  return <MarketMoversComponent marketMovers={marketMovers} />
}

function MarketMoversComponent({
  marketMovers,
}: {
  marketMovers: MarketMoversDividendProps[]
}): JSX.Element {
  const listItems = marketMovers.map(marketMover => (
    <GridCell width='15%' valign='bottom' align='center' key={marketMover.MinutesToAst}>
      <MarketMoverHeader
        dividendIndex={marketMover.MinutesToAst}
        header={marketMover.MinutesToAst}
      ></MarketMoverHeader>
      <MarketMoverPointDividend
        dividendIndex={marketMover.MinutesToAst}
        dividend={marketMover.Dividend}
      ></MarketMoverPointDividend>
    </GridCell>
  ))

  return (
    <MarketMoversContainer data-testid='market-movers-container'>
      <Grid>
        <GridCell width='15%'></GridCell>
        {listItems}
      </Grid>
    </MarketMoversContainer>
  )
}

function MarketMoverPointDividend({
  dividendIndex,
  dividend,
}: {
  dividendIndex: number
  dividend: number | null
}): JSX.Element {
  const displayDividend: string = dividend === null ? '-' : toOdds(dividend)
  return (
    <MarketMoversDividend data-tid-dividend-index={dividendIndex}>
      {displayDividend}
    </MarketMoversDividend>
  )
}

function MarketMoverHeader({
  dividendIndex,
  header,
}: {
  dividendIndex: number
  header: number
}): JSX.Element {
  return <MarketMoversHeader data-tid-dividend-index={dividendIndex}>{header}m</MarketMoversHeader>
}
