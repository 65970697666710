import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const MessageStyled = styled('div')({
  marginLeft: '1rem',
  fontSize: '1.2rem',
  lineHeight: '1.5',
})

export const DueMessageContainerStyled = styled('div')({
  backgroundColor: hexColors.whiteSmoke,
  margin: '1rem -1rem',
  padding: '1rem',
  position: 'relative',
})

export const DueMessageCountdownNumber = styled('div')({
  fontSize: '2rem',
})

export const DueMessageCountdownDays = styled('div')({
  fontSize: '1rem',
})
