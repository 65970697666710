import React from 'react'
import type { KeypadInput } from '@mobi/betslip/Store/Workflow'

export const usePhysicalKeyboardEntry = (
  keypadClickHandler: (keypadInput: KeypadInput) => void
) => {
  React.useEffect(() => {
    if (window.ontouchstart) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (Number.isInteger(+e.key)) {
        keypadClickHandler({ type: 'numeric', value: e.key as `${number}` })
        return
      }
      if (e.key === 'Backspace') {
        keypadClickHandler({ type: 'action', value: 'del' })
        return
      }
      if (e.key === '.') {
        keypadClickHandler({ type: 'action', value: '.' })
        return
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [keypadClickHandler])
}
