import React from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { trackOptimoveEvent } from '../optimove'

export const useOptimoveTrackingEvents = () => {
  useTrackAppOpen()
}

// Local Hooks

const useTrackAppOpen = () => {
  React.useEffect(() => {
    if (!isReactNativeApp) return
    trackOptimoveEvent({ eventName: 'session_betting_view_loaded' })
  }, [])
}
