import dayjs from 'dayjs'
import { subscribeToNative, sendToNative, isReactNativeApp } from '@mobi/web-native-comms/web'
import { AuthStatus, updatePushNotificationsAuthStatus } from '@core/Data/Account/pushNotifications'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'

const AuthStatusLocalStorageKey = 'push-notification-auth-status'
const AuthStatusUpdatePeriodHours = 24

export function startPushNotificationsAuthStatus(): void {
  if (isReactNativeApp) {
    userAccountState$
      .where(state => !!state.isLoggedIn)
      .take(1)
      .subscribe(state => {
        const { dispose } = subscribeToNative('RESPONSE_PUSH_AUTH_STATUS', payload => {
          try {
            const persistedAuthStatus = getFromLocalStorage(AuthStatusLocalStorageKey)
            const oldStatus = persistedAuthStatus
              ? (JSON.parse(persistedAuthStatus) as AuthStatus)
              : null

            const newStatus: AuthStatus = {
              deviceId: payload.deviceId,
              status: payload.status,
              accountNumber: state.accountNumber,
              lastUpdated: new Date(),
            }

            if (
              !oldStatus ||
              newStatus.deviceId !== oldStatus.deviceId ||
              newStatus.status !== oldStatus.status ||
              newStatus.accountNumber !== oldStatus.accountNumber ||
              dayjs(newStatus.lastUpdated).diff(oldStatus.lastUpdated, 'hours', true) >=
                AuthStatusUpdatePeriodHours
            ) {
              // eslint-disable-next-line no-console
              console.log('syncing push notification auth status: ', newStatus)
              setInLocalStorage(AuthStatusLocalStorageKey, JSON.stringify(newStatus))
              updatePushNotificationsAuthStatus(newStatus)
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('error updating push notification auth status:', e)
          }
          dispose()
        })

        sendToNative('REQUEST_PUSH_AUTH_STATUS')
      })
  }
}
