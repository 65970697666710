import React from 'react'
import { state$ as navState$ } from '@core/State/Navigation'
import { KAMBI_BETHISTORY_HASH_ROUTE } from '../constants'

export const useShowTransactionTabs = (): boolean => {
  const [shouldShowTransactionTabs, setShouldShowTransactionTabs] = React.useState(false)

  // Shows racing/sports transaction tabs on bethistory route
  React.useEffect(() => {
    const subscription = navState$.subscribe(state => {
      const isBetHistory = state.get('currentUrl').includes(KAMBI_BETHISTORY_HASH_ROUTE)
      if (isBetHistory === shouldShowTransactionTabs) return
      setShouldShowTransactionTabs(isBetHistory)
    })
    return () => {
      subscription.dispose()
    }
  }, [shouldShowTransactionTabs])

  return shouldShowTransactionTabs
}
