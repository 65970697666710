import type { BetSlipItem, MultiInvestment } from '../types'
import type { BetSlipBetsState } from '../Store/Bets'
import {
  isToteSelection,
  isFobDetails,
  isRaceDetails,
  isStartingPriceSelection,
  isSameRaceMultiSelection,
} from './typeGuards'
import {
  MIN_LEGS_IN_MULTI,
  MAX_LEGS_IN_RACE_MULTI,
  MAX_LEGS_IN_SPORT_MULTI,
} from '../helpers/constants'

export const isSingleAllowedInMulti = (item: BetSlipItem): boolean => {
  return (
    !isToteSelection(item.selection) &&
    !isStartingPriceSelection(item.selection) &&
    !isSameRaceMultiSelection(item.selection)
  )
}

export const isFatalErrorType = (betErrorType: BetSlipItem['betErrorType']): boolean => {
  if (!betErrorType) return false
  return betErrorType === 'Closed' || betErrorType === 'HandicapChanged'
}

export const isNonHandledError = (betErrorType: BetSlipItem['betErrorType']): boolean =>
  betErrorType !== undefined && betErrorType !== 'PricesChanged'

export const isFatalMultiBetLegError = (item: BetSlipItem): boolean =>
  isFatalErrorType(item.multiBetLegError?.betErrorType)

export const hasErrors = (item: BetSlipItem): boolean => !!item.betErrorType

export const isValidMultiInvestmentForLegs = (
  multiInvestment: MultiInvestment,
  multiItemsCount: number,
  { shouldCheckOnlyFormulas }: { shouldCheckOnlyFormulas?: boolean } = {}
): boolean =>
  Array.from(Array(Math.min(multiItemsCount, 6)).keys()).some(cur => {
    if (cur === 0 && !shouldCheckOnlyFormulas) return !!multiInvestment.value
    if (cur === 1 && !shouldCheckOnlyFormulas) return false
    return !!multiInvestment[`f${cur}` as keyof MultiInvestment]
  })

export const isValidMulti = (
  multiInvestment: MultiInvestment,
  multiError: BetSlipBetsState['multiBetError'] | null,
  multiItems: BetSlipItem[]
): boolean => {
  const hasValidInvestment = isValidMultiInvestmentForLegs(multiInvestment, multiItems.length)

  return (
    hasValidInvestment &&
    !hasTooFewMultiLegs(multiItems) &&
    !hasTooManyMultiLegs(multiItems) &&
    !hasInvalidLegsOnMulti(multiItems) &&
    !multiError?.betErrorType
  )
}

export const hasNoFatalErrors = (item: BetSlipItem): boolean => !isFatalErrorType(item.betErrorType)

export const hasBeenPlaced = (item: BetSlipItem): boolean => !!item.receipt

export const hasWinBoostedSuperPick = (item: BetSlipItem): boolean =>
  (item &&
    !!item.selectedSuperPickOffer &&
    item.selectedSuperPickOffer.isBoostOffer &&
    item.selectedSuperPickOffer.legTypeCode === 'W') as boolean

export const hasPlaceBoostedSuperPick = (item: BetSlipItem): boolean =>
  (item &&
    !!item.selectedSuperPickOffer &&
    item.selectedSuperPickOffer.isBoostOffer &&
    item.selectedSuperPickOffer.legTypeCode === 'P') as boolean

export const hasInvestment = (item: BetSlipItem): boolean =>
  (!!item.investment.win && item.investment.win.value > 0) ||
  (!!item.investment.place && item.investment.place.value > 0)

export const hasTooFewMultiLegs = (multiItems: BetSlipItem[]): boolean =>
  multiItems.filter(item => item.isInMulti).length < MIN_LEGS_IN_MULTI

export const hasTooManyMultiLegs = (multiItems: BetSlipItem[]): boolean => {
  const isRaceMulti = multiItems.some(
    item => item.isInMulti && isRaceDetails(item.selectionDetails)
  )
  return isRaceMulti
    ? multiItems.filter(item => item.isInMulti).length > MAX_LEGS_IN_RACE_MULTI
    : multiItems.length > MAX_LEGS_IN_SPORT_MULTI
}

// TODO: Not used - remove if not needed
export const clearNonFatalErrors = (item: BetSlipItem): BetSlipItem =>
  !isFatalErrorType(item.betErrorType)
    ? { ...item, errorMessage: '', betErrorType: undefined }
    : item

// TODO: Not used - remove if not needed
export const clearNonFatalMultiBetLegError = (item: BetSlipItem): BetSlipItem =>
  isFatalMultiBetLegError(item) ? item : { ...item, multiBetLegError: null }

export const getBetsToPlace = (items: BetSlipItem[]): BetSlipItem[] =>
  items.flatMap(item => {
    if (!hasNoUnspecifiedErrors(item)) return []
    if (item.betErrorType === 'DuplicateBonusBet') return []
    if (item.betErrorType === 'BetPlacementFault') return []
    if (!hasNoFatalErrors(item)) return []
    if (hasBeenPlaced(item)) return []
    if (!hasInvestment(item)) return []

    return [item]
  })

export const getBetsInMulti = (items: BetSlipItem[]): BetSlipItem[] =>
  items.filter(item => !!item && item.isInMulti && !isToteSelection(item.selection))

export function isSpecialUsed(item: BetSlipItem): boolean {
  // TODO: Use eligible items from state
  const items: BetSlipItem[] = []
  if (Array.isArray(item.specialOffers) && item.specialOffers.length > 0) {
    return items.some(
      bet =>
        !!bet.selectedSuperPickOffer &&
        bet.selectedSuperPickOffer.tokenId === item.specialOffers[0].tokenId &&
        bet.id !== item.id
    )
  }
  return false
}

// =============
// Local Helpers
// =============

function hasInvalidLegsOnMulti(items: BetSlipItem[]): boolean {
  const uniqueKeys: string[] = []
  const isDuplicateKey = (key: string) => uniqueKeys.includes(key)

  return getBetsInMulti(items).some((item): boolean => {
    const { selectionDetails } = item

    if (isRaceDetails(selectionDetails)) {
      const { key } = selectionDetails.races[0]
      if (isDuplicateKey(key)) {
        return true
      }
      uniqueKeys.push(key)
    }
    if (isFobDetails(selectionDetails)) {
      const key = selectionDetails.event
      if (isDuplicateKey(key)) {
        return true
      }
      uniqueKeys.push(key)
    }
    return false
  })
}

const hasNoUnspecifiedErrors = (item: BetSlipItem): boolean => item.betErrorType !== 'Unspecified'
