import styled from '@emotion/styled'
import { fontFamilies } from '@core/Settings'
import { HOME_CSS_SHARED_VALUES } from '../../Home.styles'
import { hexColors } from '@mobi/settings'

export const HomeNextRacesListItemStyled = styled.a({
  background: hexColors.white,
  boxShadow: HOME_CSS_SHARED_VALUES.boxShadow,
  color: hexColors.black,
  display: 'inline-block',
  borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
  alignItems: 'center',
  fontFamily: fontFamilies.default,
  height: '5.5rem',
  fontSize: '1.1rem',
  margin: '0',
  marginRight: '0.75rem',
  padding: '0.5rem',
  position: 'relative',
  textAlign: 'left',
  textDecoration: 'none',
  verticalAlign: 'middle',
  width: '10rem',
  minWidth: '10rem',

  '&:hover': {
    backgroundColor: 'none',
  },
})
