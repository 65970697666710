import React from 'react'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'

import { setRadioPlaying } from '@core/Areas/Navigation/Store'
import { selectIsRadioPlaying } from '@core/Areas/Navigation/Store/selectors'
import { setCloseSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/Store'

const enum LocalConstants {
  TABtouchRadio = 'https://racingradio.rwwa.com.au/live',
}

// Audio Element is module scoped to allow play while using app
let globalAudioElement: HTMLAudioElement | null = null

export const useRadio = (
  isVisionPlaying: boolean
): {
  handleRadioToggle(): void
  isRadioPlaying: boolean
  isRadioBusy: boolean
} => {
  const isRadioPlaying = useAppSelector(selectIsRadioPlaying)
  const dispatch = useAppDispatch()
  const [isRadioBusy, setIsRadioBusy] = React.useState(false)
  const [audioElement, setAudioElement] = React.useState(globalAudioElement)

  const pause = React.useCallback(
    (isReactNativeApp: boolean) => {
      if (isReactNativeApp) {
        sendToNative('REQUEST_RADIO_STATE', { isPlaying: false })
      } else {
        audioElement?.pause()
      }

      dispatch(setRadioPlaying(false))
    },
    [audioElement, dispatch]
  )

  React.useEffect(() => {
    if (!isReactNativeApp) {
      return undefined
    }

    const { dispose: disposeNativeRadioStateListener } = subscribeToNative(
      'NOTIFY_RADIO_STATE',
      data => {
        dispatch(setRadioPlaying(data.isPlaying))
        if (data.isPlaying) {
          dispatch(setCloseSkyVideoPlayer())
        }

        setIsRadioBusy(false)
      }
    )

    return disposeNativeRadioStateListener
  }, [dispatch])

  // Stop Radio if Vision is playing
  React.useEffect(() => {
    if (!isVisionPlaying) return
    pause(isReactNativeApp)
  }, [isVisionPlaying, dispatch, pause])

  // Synchronize Radio state with UI on mount
  React.useEffect(() => {
    if (isReactNativeApp) {
      sendToNative('REQUEST_RADIO_STATE')
      return undefined
    }

    if (!audioElement) {
      dispatch(setRadioPlaying(false))
      return undefined
    }

    dispatch(setRadioPlaying(!audioElement.paused && !!audioElement.currentTime))

    const onPauseEvent = () => dispatch(setRadioPlaying(false))
    audioElement.addEventListener('pause', onPauseEvent)
    const onPlayEvent = () => dispatch(setRadioPlaying(true))
    audioElement.addEventListener('play', onPlayEvent)

    return () => {
      audioElement?.removeEventListener('pause', onPauseEvent)
      audioElement?.removeEventListener('play', onPlayEvent)
    }
  }, [audioElement, dispatch])

  const handleRadioToggle = React.useCallback(() => {
    if (isRadioPlaying) {
      pause(isReactNativeApp)
      return
    }

    // Handle Play
    setIsRadioBusy(true)
    if (isReactNativeApp) {
      sendToNative('REQUEST_RADIO_STATE', { isPlaying: true })
    } else {
      if (!globalAudioElement) globalAudioElement = new Audio(LocalConstants.TABtouchRadio)
      setAudioElement(globalAudioElement)
      globalAudioElement.setAttribute('src', LocalConstants.TABtouchRadio)
      globalAudioElement.load()
      globalAudioElement
        .play()
        .then(() => dispatch(setRadioPlaying(true)))
        .catch(() => pause(isReactNativeApp))
        .finally(() => setIsRadioBusy(false))
    }

    dispatch(setCloseSkyVideoPlayer())
  }, [dispatch, pause, isRadioPlaying])

  return {
    handleRadioToggle,
    isRadioPlaying,
    isRadioBusy,
  }
}
