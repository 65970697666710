import React from 'react'
import { useParams } from 'react-router-dom'
import { useFeature } from '@core/Utils/hooks'
import { Icon, type IconProps } from '@mobi/component-library/Common/Icon'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { isReactNativeAndroid, isReactNativeIos } from '@mobi/web-native-comms/web'
import { SharePreview } from './Components/SharePreview/SharePreview'
import { OverlayOpen } from '@core/Components/Overlay'
import { TicketFooterButtonStyled as FooterButton } from '../../Footer.styles'
import { doesClientSupportShare } from './helpers/doesClientSupportShare'

export const ShareBet: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const isShareBetFeatureActive = useFeature('SHARE_BET')
  let { tab: activeTab } = useParams<{ tab: Tabs }>()

  if (!isShareBetFeatureActive || !doesClientSupportShare()) return null

  const shareIconPath: IconProps['type'] = isReactNativeIos
    ? 'shareIos'
    : isReactNativeAndroid
      ? 'shareAndroid'
      : 'shareWeb'

  return (
    <FooterButton
      data-testid='share-bet-button'
      color='link'
      onClick={() => launchSharePreview(ticket, shareIconPath, activeTab)}
    >
      <Icon data-testid='share-bet-icon' type={shareIconPath} size='2rem' /> Share
    </FooterButton>
  )
}

// Local Helpers

function launchSharePreview(ticket: Ticket, shareIconPath: IconProps['type'], activeTab: Tabs) {
  OverlayOpen(() => (
    <SharePreview ticket={ticket} shareIconPath={shareIconPath} activeTab={activeTab} />
  ))
}
