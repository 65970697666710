import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'

interface IconTypes {
  Races: 'races'
  Trots: 'trot'
  Dogs: 'dog'
}

const iconTypes: IconTypes = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function getIconTypeFromMeetingCode(
  meetingCode: string | undefined
): keyof typeof iconPaths | undefined {
  if (meetingCode) {
    const loweredMeetingCode = meetingCode.toLowerCase()

    switch (loweredMeetingCode) {
      case 'race':
      case 'races':
        return iconTypes.Races
      case 'trot':
      case 'trots':
        return iconTypes.Trots
      case 'dog':
      case 'dogs':
        return iconTypes.Dogs
      default:
        return undefined
    }
  }
  return undefined
}
