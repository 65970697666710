import React from 'react'
import { BannerAnalyticsData, trackBannerClicked } from '../analytics'

interface LinkWrapperProps {
  url?: string
  internalTrackingCode?: string
  abGroupingTokenPresent?: boolean
  analyticsData?: BannerAnalyticsData
}

export const LinkWrapper: React.FC<LinkWrapperProps> = ({
  url,
  internalTrackingCode,
  abGroupingTokenPresent,
  analyticsData,
  children,
}) => {
  const anchorRef = React.useRef<HTMLAnchorElement>(null)
  const mouseMoveRef = React.useRef<number>(0)

  const handleMouseMove = React.useCallback(({ movementX }: MouseEvent) => {
    mouseMoveRef.current = Math.max(mouseMoveRef.current, Math.abs(movementX))
  }, [])

  const removeMouseMoveEventListener = React.useCallback(() => {
    const anchorEl = anchorRef.current
    anchorEl?.removeEventListener('mousemove', handleMouseMove)
  }, [handleMouseMove])

  React.useEffect(() => {
    return removeMouseMoveEventListener
  }, [removeMouseMoveEventListener])

  return url ? (
    <a
      ref={anchorRef}
      onMouseDown={() => {
        anchorRef.current?.addEventListener('mousemove', handleMouseMove)
      }}
      onMouseUp={removeMouseMoveEventListener}
      onMouseLeave={() => {
        removeMouseMoveEventListener()
        mouseMoveRef.current = 0
      }}
      onClick={ev => {
        if (mouseMoveRef.current > 5) ev.preventDefault()
        else trackBannerClicked(analyticsData)
        mouseMoveRef.current = 0
      }}
      style={{ display: 'block' }}
      draggable='false'
      href={url}
      data-internaltrackingcode={internalTrackingCode}
      data-abtestbanner={abGroupingTokenPresent}
      data-nav=''
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  )
}
