import React from 'react'
import { SpinnerOverlayStyled, SpinnerHolderStyled, SpinnerStyled } from './Spinner.styles'

export { SpinnerStyled as SpinnerStandalone }

/**
 * Busy indicator for mobile devices
 */
export function Spinner() {
  return (
    <SpinnerOverlayStyled>
      <SpinnerHolderStyled>
        <SpinnerStyled
          width='auto'
          height='auto'
          title='Loading...'
          role='progressbar'
          aria-label='Loading'
        />
      </SpinnerHolderStyled>
    </SpinnerOverlayStyled>
  )
}
