import type { BetSelection, EventDetails } from '@mobi/betslip/types'
import type { QuickbetSelection } from '@core/Areas/Quickbet/signals'

export function convertToBetSelection(selection: QuickbetSelection): BetSelection {
  return {
    bettingType: selection.bettingType,
    selection: selection.selection,
    selectionDetails: selection.selectionDetails as EventDetails,
    isEachWayAvailable: selection.isEachWayAvailable,
    shouldAllowPlaceInvestment: selection.shouldAllowPlaceInvestment,
    tags: selection.tags,
    betSource: selection.betSource,
  }
}
