import React from 'react'
import { Observable } from 'rx'
import Decimal from 'decimal.js'
import { Transition } from 'react-transition-group'
import type { Selection, FobSelection, EventDetails } from '@mobi/betslip/types'
import {
  isFobSelection,
  isNoveltyBetType,
  isRaceDetails,
  isSameRaceMultiSelection,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ as quickbetState$, QuickbetState } from '../../driver'
import { state$ as superPickState$, SuperPickState } from '@core/Components/SuperPick/driver'
import { InvestmentType } from '../BetInvestment/betInvestmentDriver'
import { InvestmentComponentProps } from '../BetInvestment/BetInvestment'
import {
  LegInfoWrapperStyled,
  SingleLegTypeStyled,
  SingleLegPriceStyled,
  EachWayTypeStyled,
  EachWayPriceStyled,
  SingleLegMoneyGroupedStyled,
  LegInfoPriceTextStyled,
  LegInfoPriceFinalDigitStyled,
  LegInfoPriceChangeStyled,
  LegInfoPriceFinalDigitInnerStyled,
  LegInfoPriceFinalDigitInnerDigitStyled,
  LegInfoPriceFinalDigitWrapStyled,
  LegInfoPriceScreenReaderFriendlyStyled,
} from './LegInfo.styles'
import { getPriceChange, PriceChange } from '@core/Components/Text/utils'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { Odometer } from '@core/Components/Odometer'

interface LegInfoState {
  selection: Selection
  selectionDetails: EventDetails
  winPricePreviousAndCurrent: number[]
  placePricePreviousAndCurrent: number[]
  selectedSuperPickOffer?: BetSpecialOffer | null
  isEachWay: boolean
}

type LegInfoProps = Pick<InvestmentComponentProps, 'investmentType' | 'isActive'>

export type LegInfoCombinedProps = LegInfoState & LegInfoProps

export class LegInfoComponent extends React.PureComponent<LegInfoCombinedProps> {
  public render(): JSX.Element | null {
    let winBoost = 0
    let placeBoost = 0

    if (this.props.selectedSuperPickOffer) {
      const elementWithPriceIncrease = this.props.selectedSuperPickOffer.elements?.find(
        element => element.priceIncrease !== null
      )
      if (elementWithPriceIncrease) {
        winBoost = elementWithPriceIncrease.priceIncrease?.win.toNumber() as number
        placeBoost = elementWithPriceIncrease.priceIncrease?.place.toNumber() as number
      }
    }

    if (isStartingPriceSelection(this.props.selection)) {
      return <LegInfoStartingPriceComponent legType='Win' isActive={this.props.isActive} />
    }

    if (isFobSelection(this.props.selection)) {
      const winPriceChange = this.props.winPricePreviousAndCurrent
        ? getPriceChange(
            this.props.winPricePreviousAndCurrent[0],
            this.props.winPricePreviousAndCurrent[1]
          )
        : PriceChange.None
      const placePriceChange = this.props.placePricePreviousAndCurrent
        ? getPriceChange(
            this.props.placePricePreviousAndCurrent[0],
            this.props.placePricePreviousAndCurrent[1]
          )
        : PriceChange.None

      if (isRaceDetails(this.props.selectionDetails)) {
        if (this.props.investmentType === InvestmentType.Win) {
          const legType = isSameRaceMultiSelection(this.props.selection) ? 'Same Race Multi' : 'Win'
          return (
            <LegInfoWithPriceComponent
              legType={legType}
              price={this.props.selection.winPrice}
              isActive={this.props.isActive}
              boostAmount={winBoost}
              priceChange={winPriceChange}
            />
          )
        }
        if (this.props.investmentType === InvestmentType.Place) {
          return (
            <LegInfoWithPriceComponent
              legType='Place'
              price={this.props.selection.placePrice as number}
              isActive={this.props.isActive}
              boostAmount={placeBoost}
              priceChange={placePriceChange}
            />
          )
        }
      }

      if (this.props.isEachWay) {
        return (
          <LegInfoWrapperStyled data-testid='LegInfo.EachWay'>
            <EachWayTypeStyled isActive={this.props.isActive}>
              Win <LegInfoPriceTextStyled>price:</LegInfoPriceTextStyled>
            </EachWayTypeStyled>
            <EachWayPriceStyled>
              <SingleLegMoneyGroupedStyled
                data-tid='qb-win-price'
                price={this.props.selection.winPrice}
                priceChange={winPriceChange}
                isActive={this.props.isActive}
                shouldHighlight={false}
              />
            </EachWayPriceStyled>
            <EachWayTypeStyled isActive={this.props.isActive}>
              Place <LegInfoPriceTextStyled>price:</LegInfoPriceTextStyled>
            </EachWayTypeStyled>
            <EachWayPriceStyled>
              <SingleLegMoneyGroupedStyled
                data-tid='qb-place-price'
                price={this.props.selection.placePrice as number}
                priceChange={placePriceChange}
                isActive={this.props.isActive}
                shouldHighlight={false}
              />
            </EachWayPriceStyled>
          </LegInfoWrapperStyled>
        )
      }
      if (this.props.investmentType === InvestmentType.Win) {
        return (
          <LegInfoWithPriceComponent
            legType='Win'
            price={this.props.selection.winPrice}
            isActive={this.props.isActive}
            boostAmount={winBoost}
            priceChange={winPriceChange}
          />
        )
      }
      if (this.props.investmentType === InvestmentType.Place) {
        return (
          <LegInfoWithPriceComponent
            legType='Place'
            price={this.props.selection.placePrice as number}
            isActive={this.props.isActive}
            boostAmount={placeBoost}
            priceChange={placePriceChange}
          />
        )
      }
    }

    if (isToteSelection(this.props.selection)) {
      let label = this.props.investmentType === InvestmentType.Win ? 'Win' : 'Place'
      if (
        isNoveltyBetType(this.props.selection.betType) ||
        this.props.selection.betType === 'All Up'
      ) {
        label = 'Spend'
      }
      return (
        <LegInfoWrapperStyled>
          <SingleLegTypeStyled data-testid='LegInfo.SingleLeg' isActive={this.props.isActive}>
            {label}
          </SingleLegTypeStyled>
        </LegInfoWrapperStyled>
      )
    }
    return null
  }
}

interface GenericSingleLegTypeProps {
  legType: string
  price: number
  isActive: boolean
  boostAmount: number
  priceChange: PriceChange
}

function LegInfoWithPriceComponent({
  legType,
  price,
  isActive = true,
  boostAmount = 0,
  priceChange = PriceChange.None,
}: GenericSingleLegTypeProps) {
  const isBoosted = boostAmount > 0
  const finalPrice = Decimal(price || 0)
    .plus(boostAmount)
    .toNumber()
  const finalPriceString = '$' + finalPrice.toFixed(2)
  return (
    <LegInfoWrapperStyled>
      <SingleLegTypeStyled data-testid='LegInfo.SingleLeg' isActive={isActive}>
        {legType}{' '}
        <LegInfoPriceTextStyled>price{isBoosted ? ' boosted' : null}:</LegInfoPriceTextStyled>
      </SingleLegTypeStyled>
      <Transition in={isBoosted} timeout={900}>
        {status => {
          return (
            <SingleLegPriceStyled
              shouldHighlight={isBoosted}
              isActive={isActive}
              priceChange={priceChange}
            >
              <LegInfoPriceScreenReaderFriendlyStyled
                data-tid={`qb-${legType.toLowerCase()}-price`}
                aria-label={finalPriceString}
              >
                {finalPriceString}
              </LegInfoPriceScreenReaderFriendlyStyled>

              <LegInfoPriceFinalDigit price={finalPrice} />

              <LegInfoPriceChangeStyled aria-hidden='true' shouldDisplay={status === 'entering'}>
                <Odometer
                  start={price}
                  end={finalPrice}
                  duration={700}
                  startAnim={isBoosted}
                  prefix='$'
                />
              </LegInfoPriceChangeStyled>
            </SingleLegPriceStyled>
          )
        }}
      </Transition>
    </LegInfoWrapperStyled>
  )
}

function LegInfoStartingPriceComponent({
  legType,
  isActive = true,
}: {
  legType: string
  isActive: boolean
}) {
  const finalPriceString = 'Starting Price'

  return (
    <LegInfoWrapperStyled data-testid='leg-info-sp'>
      <SingleLegTypeStyled isActive={isActive}>{legType} </SingleLegTypeStyled>

      <SingleLegPriceStyled
        shouldHighlight={false}
        isActive={isActive}
        priceChange={PriceChange.None}
      >
        <LegInfoPriceScreenReaderFriendlyStyled
          data-tid={`qb-${legType.toLowerCase()}-price`}
          aria-label={finalPriceString}
        >
          {finalPriceString}
        </LegInfoPriceScreenReaderFriendlyStyled>

        <LegInfoPriceFinalDigitStyled aria-hidden='true'>SP</LegInfoPriceFinalDigitStyled>
      </SingleLegPriceStyled>
    </LegInfoWrapperStyled>
  )
}

interface LegInfoPriceFinalDigit {
  price: number
}

export const LegInfoPriceFinalDigit = ({ price }: LegInfoPriceFinalDigit): JSX.Element => {
  let rows: JSX.Element[] = []

  const priceString = `$${price}`
  // checks if string has decimal once place from the end eg. 56.5
  const shouldAddExtra0: boolean = priceString.slice(-2, -1) === '.'
  const priceString0Check = shouldAddExtra0 ? priceString + '0' : priceString
  // if no decimal exists, add in '.00'
  const priceStringDecimalCheck =
    priceString.indexOf('.') === -1 ? priceString0Check + '.00' : priceString0Check
  const priceArray: string[] = priceStringDecimalCheck.split('')

  rows = priceArray.map((digit, index) => (
    <LegInfoPriceFinalDigitInnerStyled isDecimal={digit === '.'} key={index}>
      <LegInfoPriceFinalDigitInnerDigitStyled>{digit}</LegInfoPriceFinalDigitInnerDigitStyled>
    </LegInfoPriceFinalDigitInnerStyled>
  ))

  return (
    <LegInfoPriceFinalDigitStyled aria-hidden='true'>
      <LegInfoPriceFinalDigitWrapStyled>{rows}</LegInfoPriceFinalDigitWrapStyled>
    </LegInfoPriceFinalDigitStyled>
  )
}

const isPriceUpdated = (pair: (number | null)[]): boolean => {
  return pair[0] !== pair[1]
}

const state$ = Observable.combineLatest(
  quickbetState$,
  quickbetState$
    .map(record => ((record.toJS() as QuickbetState).selection as FobSelection).winPrice)
    .pairwise()
    .filter(isPriceUpdated)
    .startWith([0, 0]),
  quickbetState$
    .map(record => ((record.toJS() as QuickbetState).selection as FobSelection).placePrice)
    .pairwise()
    .filter(isPriceUpdated)
    .startWith([0, 0]),
  superPickState$,
  (quickbetStateRecord, winPriceHistory, placePriceHistory, superPickStateRecord): LegInfoState => {
    const superPick: SuperPickState = superPickStateRecord.toJS()
    const specialOffers = superPick.specialOffers
    const selectedSuperPickOffer = specialOffers
      ? specialOffers.find(offer => offer.specialSeq === superPick.selectedSuperPickSeq)
      : null
    const quickbet: QuickbetState = quickbetStateRecord.toJS()
    return {
      selection: quickbet.selection as Selection,
      selectionDetails: quickbet.selectionDetails as EventDetails,
      isEachWay: quickbet.isEachWay,
      winPricePreviousAndCurrent: winPriceHistory,
      placePricePreviousAndCurrent: placePriceHistory as number[],
      selectedSuperPickOffer,
    }
  }
)

export const LegInfo: React.ComponentClass<LegInfoProps> = observeImmutable<
  LegInfoState,
  LegInfoProps
>(state$, ({ record, investmentType, isActive }) => (
  <LegInfoComponent {...record} investmentType={investmentType} isActive={isActive} />
))
LegInfo.displayName = 'LegInfo'
