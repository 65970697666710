import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const ReceiptNumber: React.FC<{ ticketNumber: number }> = ({ ticketNumber }) => {
  return (
    <WrapperStyled>
      <span>Receipt: </span> <span>{ticketNumber}</span>
    </WrapperStyled>
  )
}

const WrapperStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.smx2,
  fontSize: font.size.sm.fontSize,
  letterSpacing: font.size.sm.letterSpacing,
  lineHeight: font.size.sm.lineHeight,
  color: colors.neutral[800],
  fontWeight: font.weight.regular,

  '> span': {
    fontWeight: font.weight.medium,
  },
})
