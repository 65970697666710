import React from 'react'
import styled from '@emotion/styled'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { colors, layering, radius } from '@mobi/component-library/Theme/Common'
import { PendingBetsDrawer } from './Components/PendingBetsDrawer'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import {
  useFullscreenNativeInterop,
  useCanRenderPendingBetsCheck,
  usePendingBetsOpenToggle,
  useSwipeActions,
} from './Hooks'

const enum LocalConstants {
  DrawerWidthRem = 25,
}

export const FullscreenPlayer: React.FC = () => {
  const fullscreenContainerRef = React.useRef<HTMLDivElement>(null)

  useCanRenderPendingBetsCheck(LocalConstants.DrawerWidthRem)
  const pendingBetsContainerRef = usePendingBetsOpenToggle(LocalConstants.DrawerWidthRem)
  const pendingBetsDrawerRef = useBodyScrollLock()

  useFullscreenNativeInterop()
  useSwipeActions(fullscreenContainerRef, pendingBetsContainerRef)

  return (
    <FullscreenPlayerWrapperStyled ref={fullscreenContainerRef}>
      <div>
        <VideoPlayer />
      </div>

      <div ref={pendingBetsContainerRef}>
        <PendingBetsDrawer ref={pendingBetsDrawerRef} />
      </div>
    </FullscreenPlayerWrapperStyled>
  )
}

// ======
// Styles
// ======

const FullscreenPlayerWrapperStyled = styled.div({
  position: 'fixed',
  zIndex: layering.skyVideoPlayerFloating,
  display: 'flex',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: colors.black,

  // Video Player Container
  '> div:first-of-type': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  // Pending Bets Drawer Container
  '> div:last-of-type': {
    flexShrink: 0,
    position: 'relative',
    zIndex: layering.overlayHigh,
    overflow: 'hidden',
    display: 'flex',

    transition: 'width 400ms ease-in-out',
    background: colors.surface[300],
    borderTopLeftRadius: radius.lgx3,
    borderBottomLeftRadius: radius.lgx3,
  },
})
