import dayjs from 'dayjs'
import { MeetingPreview, RacePreview, VideoPreviewResponse } from './types'

export type RaceContent = Omit<MeetingPreview, 'meetingId' | 'meetingDate' | 'racePreviews'> & {
  racePreviews: Map<number, RacePreview>
}

export async function getRacePreviews(meetingDate: Date, meetingId: string): Promise<RaceContent> {
  const response = await fetch(
    `/api/contentful/meeting-preview/${dayjs(meetingDate).format('YYYY-MM-DD')}/${meetingId}`
  )
  if (response.ok) {
    const meetingPreview: MeetingPreview = await response.json()
    return {
      title: meetingPreview.title,
      racePreviews: new Map<number, RacePreview>(
        meetingPreview.racePreviews?.map(preview => [preview.raceNumber, preview])
      ),
      tipsterProfilePictureUrl: meetingPreview.tipsterProfilePictureUrl,
    }
  }
  throw new Error()
}

export async function getVideoPreviews(
  meetingDate: Date,
  meetingId: string
): Promise<VideoPreviewResponse> {
  const response = await fetch(
    `/api/video-streams/${dayjs(meetingDate).format('YYYY-MM-DD')}/${meetingId}.json`
  )
  if (response.ok) {
    return (await response.json()) as VideoPreviewResponse
  }
  throw new Error()
}
