import styled from '@emotion/styled'
import { TabList, Tab } from 'react-tabs'
import { hexColors } from '@mobi/settings'

const tabPadding = 1 // rem
const selectedTabBorderWidth = 0.4 // rem

export interface TabListProps {
  colorscheme?: 'light' | 'dark'
  uppercaseText?: boolean
  fontWeight?: React.CSSProperties['fontWeight']
}

export const TabListStyled = styled(TabList)<TabListProps>(
  {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0rem',
    padding: 0,
  },
  ({ colorscheme, uppercaseText, fontWeight }: TabListProps) => ({
    fontWeight: fontWeight,
    textTransform: uppercaseText ? 'uppercase' : undefined,
    ['[role="tab"]']: {
      background: colorscheme === 'dark' ? hexColors.nero : hexColors.gainsboro,
      color: colorscheme === 'dark' ? hexColors.lightGrey : hexColors.dimGrey,
      margin: colorscheme === 'dark' ? 0 : '0 0.5rem',
      fontSize: colorscheme === 'dark' ? '1.4rem' : '1.2rem',
      '&.selected': {
        paddingBottom: colorscheme === 'dark' ? 0 : `${tabPadding - selectedTabBorderWidth}rem`,
        borderBottom:
          colorscheme === 'dark' ? 0 : `${selectedTabBorderWidth}rem solid ${hexColors.chocolate}`,
        color: colorscheme === 'dark' ? hexColors.white : hexColors.chocolate,
        background: colorscheme === 'dark' ? hexColors.charCoal : hexColors.gainsboro,
      },
    },
  })
)
// Unfortunately typings for react-tabs are out of date, but `tabsRole` is required to use
// react-tabs in combination with emotion (styled components).
// See: https://github.com/reactjs/react-tabs#custom-components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(TabListStyled as any).tabsRole = 'TabList'

export const TabStyled = styled(Tab)({
  cursor: 'pointer',
  flex: '1 0',
  listStyle: 'none',
  padding: `${tabPadding}rem`,
  textAlign: 'center',
  width: '50%',
})
// Adding a custom class name for the `select` state in order to style it above.
TabStyled.defaultProps = { selectedClassName: 'selected' }

// Unfortunately typings for react-tabs are out of date, but `tabsRole` is required to use
// react-tabs in combination with emotion (styled components).
// See: https://github.com/reactjs/react-tabs#custom-components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(TabStyled as any).tabsRole = 'Tab'
