import React from 'react'
import {
  NumberOfCombinationsDisplayStyled,
  CombinationsStyled,
} from './NumberOfCombinationsDisplay.styles'

interface NumberOfCombinationsDisplayProps {
  numberOfCombinations: number
}

export function NumberOfCombinationsDisplay({
  numberOfCombinations = 0,
}: NumberOfCombinationsDisplayProps): JSX.Element {
  return (
    <NumberOfCombinationsDisplayStyled isActive={false}>
      <span>Combos</span>{' '}
      <CombinationsStyled combinations={numberOfCombinations}>
        {numberOfCombinations}
      </CombinationsStyled>
    </NumberOfCombinationsDisplayStyled>
  )
}
