import { ButtonStyled } from '@mobi/component-library/Common/Buttons'
import { greaterThan } from '@mobi/component-library/Utils/media'
import styled from '@emotion/styled'

export const TicketFooterStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minHeight: '4rem',
  marginTop: '-1rem',
  paddingRight: '1rem',

  '&:empty': {
    display: 'none',
  },
})

export const TicketFooterButtonStyled = styled(ButtonStyled)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '4rem',
  marginRight: '0.2rem',
  lineHeight: 1,

  '&[disabled]': {
    backgroundColor: 'transparent',
  },

  svg: {
    display: 'none',
    paddingRight: '0.3rem',
  },

  [`${greaterThan('px350')}`]: {
    svg: {
      display: 'block',
    },
  },
})
