import React from 'react'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

// Types
interface ClickEvent extends MouseEvent {
  target: Element
}
type ClickEventHandler = (event: MouseEvent) => void

// Handler
export function clickTrackHandler(event: ClickEvent): void {
  if (
    !event.target ||
    !event.target.closest('[data-tid-account-dropdown]') ||
    event.target.closest('[data-testid="account-dropdown-superpicks-list"]')
  )
    return

  const clickableElement = event.target.closest('button') || (event.target as Element).closest('a')
  if (!clickableElement) return

  const buttonLabel = (clickableElement.innerText ?? clickableElement.textContent)
    .replace(/\d/g, '')
    .trim()

  if (!buttonLabel) return

  trackEvent(keys.accountDropDownClick, { buttonLabel })
}

// =================
// useAnalytics Hook
// =================

export const useAnalytics = (shouldTrack: boolean): void => {
  React.useEffect(() => {
    if (shouldTrack) {
      document.body.addEventListener('click', clickTrackHandler as ClickEventHandler, true)
    } else {
      document.body.removeEventListener('click', clickTrackHandler as ClickEventHandler, true)
    }
  }, [shouldTrack])
}
