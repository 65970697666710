import ko from 'knockout'
import { inject, injectable } from 'inversify'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import type { IObservablePage } from '@classic/Betting-v2/Model/Observables/IObservablePage'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import type { IStandardTopPanel } from './IStandardTopPanel'
import type { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'

@injectable()
export class StandardTopPanel extends Disposable implements IStandardTopPanel {
  public bettingContext!: BettingInformation
  public raceNumber!: ObservableRaceKey
  public raceInfo!: IObservablePage
  public starters!: ko.ObservableArray<IObservableStarter>
  public useGiddyUp?: IUseGiddyUp

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: {
    raceInfo: IObservablePage
    bettingContext: BettingInformation
    raceNumber: ObservableRaceKey
    starters: ko.ObservableArray<IObservableStarter>
    useGiddyUp: IUseGiddyUp
  }): void {
    this.bettingContext = params.bettingContext
    this.starters = params.starters
    this.raceNumber = params.raceNumber
    this.raceInfo = params.raceInfo
    this.useGiddyUp = params.useGiddyUp
  }

  public hasGiddyUp() {
    return this.useGiddyUp?.showGiddyUpInTopPanel(this.raceNumber.raceNumber())
  }

  public openGiddyUp() {
    this.useGiddyUp?.openGiddyUp()
  }
}
