import React from 'react'
import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { LinkWrapperStyled } from './LinkWrapper.styles'

export const LinkWrapper: React.FC<{
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  label: string
}> = ({ fixtureId, raceNumber, fixtureDate, label }) => {
  const ticketDisplayContext = React.useContext(TicketDisplayContext)

  if (
    ticketDisplayContext.type === 'race-card' ||
    (ticketDisplayContext.type === 'pending-bets-drawer' &&
      ticketDisplayContext.data.isSkyRaceMatch)
  )
    return null

  if (ticketDisplayContext.type === 'share-preview' || fixtureId == null || !raceNumber) {
    return <span data-testid='event-name'>{label}</span>
  }

  return (
    <LinkWrapperStyled
      to={getMeetingUrl(fixtureId, raceNumber, fixtureDate)}
      data-testid='event-name'
    >
      {label}
    </LinkWrapperStyled>
  )
}

function getMeetingUrl(fixtureId: string, raceNumber: number, fixtureDate: string) {
  return `/tote/meetings/${fixtureId}/${raceNumber}?date=${formatDateStringWithoutChangingTimezone(
    fixtureDate,
    'YYYY-MM-DD'
  )}`
}
