import html2canvas from 'html2canvas'
import { colors } from '@mobi/settings'

export async function createImageUrlFromHtml(el: HTMLElement): Promise<string> {
  const { width } = el.getBoundingClientRect()

  const canvas = await html2canvas(el, {
    scale: 2,
    backgroundColor: colors.brand.primary,
    width,
    /**
     * MOBI-2301: Additional config below for PROD bug causing browser to freeze
     * https://github.com/niklasvh/html2canvas/issues/3053
     */
    useCORS: true,
    allowTaint: true,
    ignoreElements: e =>
      // @ts-expect-error Type issue, host is available on certain els
      (e.tagName === 'A' && e.host !== window.location.host) ||
      e.getAttribute('loading') === 'lazy',
  })
  return canvas.toDataURL('image/png')
}
