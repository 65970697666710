import React from 'react'
import { state$ as kambiState$ } from '../driver'

let hasPageRendered = false

export const useShouldShowSpinner = (): boolean => {
  const [shouldShowSpinner, setShouldShowSpinner] = React.useState(false)

  // Only show spinner before "pageRendered" event & route is visible
  React.useEffect(() => {
    if (hasPageRendered) return undefined

    const subscription = kambiState$
      .map(state => state.get('isKambiRendered'))
      .distinctUntilChanged()
      .subscribe(isKambiRendered => {
        setShouldShowSpinner(!isKambiRendered)

        if (isKambiRendered) {
          hasPageRendered = true
          subscription?.dispose?.()
        }
      })
    return () => {
      subscription?.dispose?.()
    }
  }, [])

  return shouldShowSpinner
}
