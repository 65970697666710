import React from 'react'
import { Banner, BannerArea } from '../types'

export const BannerPicture: React.FC<
  Pick<Banner, 'image' | 'imageWide'> & { areaName: BannerArea }
> = ({ image, imageWide, areaName }) => {
  const isDesktop = areaName === 'Desktop'
  const sharedParams = `?fm=jpg&fl=progressive&q=90`

  return (
    <picture draggable='false' style={{ pointerEvents: 'none' }}>
      {!isDesktop && (
        <source
          srcSet={`
          ${imageWide + sharedParams} 2x,
          ${imageWide + sharedParams}&fit=fill&w=1280
        `}
          media='(min-width: 768px)'
        />
      )}

      <source
        srcSet={`
        ${image + sharedParams} 2x,
        ${image + sharedParams}&fit=fill&w=767
        `}
        media='(min-width: 383px)'
      />

      <img
        srcSet={`
          ${image + sharedParams}&fit=fill&w=764 2x
        `}
        src={image + sharedParams + '&fit=fill&w=382'}
      />
    </picture>
  )
}
