import React from 'react'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { getImageUrlWithSizes } from '@core/Components/SilkImage/utils'
import { SilkImage as SilkImageHolder } from '@core/Components/SilkImage/SilkImage'

export const SilkImageContained: React.FC<{
  fixtureId: string
  fixtureDate: string
  raceNumber: number
  acceptorNumber: number
  isSmall?: boolean
}> = ({ fixtureId, fixtureDate, raceNumber, acceptorNumber, isSmall = false }) => {
  const contestType = meetingIdToContestType(fixtureId)

  const isDogsSilk = contestType === 'dogs'
  const silkUrl = getImageUrlWithSizes({
    meetingId: fixtureId,
    meetingDate: fixtureDate,
    isTrots: contestType === 'trots',
    raceNo: raceNumber,
  })

  return (
    <SilkImageHolder
      url={silkUrl.large.url}
      height={silkUrl.large.size.height}
      width={silkUrl.large.size.width}
      isDogsSilk={isDogsSilk}
      xOffset={acceptorNumber - 1}
      baseWidth={isSmall ? 15 : undefined}
    />
  )
}
