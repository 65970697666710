import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'
import { fetchFromApi } from '@classic/Foundation/Services/ApiService'

export const queryDefaults = {
  retry: false,
  cacheTime: 0,
  refetchOnWindowFocus: false,
}

const API_PREFIX = '/api/mysterybet'

export const getMysteryBetDetails = async (
  meetingId?: string,
  raceNumber?: number,
  date?: Date | string
): Promise<MysteryDataTransferObject> => {
  const url =
    meetingId && (raceNumber || 0) > 0 && date
      ? `${API_PREFIX}/${meetingId}/${raceNumber}/${date}`
      : API_PREFIX
  const response = await fetchFromApi(url)
  return await response.json()
}
