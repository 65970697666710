import styled from '@emotion/styled'

export const CompletedWithdrawalDetailsStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
  justifyContent: 'center',
  textAlign: 'center',

  '& > div': {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',

    '& > svg:first-of-type': {
      marginRight: '1rem',
    },

    span: {
      display: 'flex',
      alignItems: 'center',
    },
  },
})
