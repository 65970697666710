import { searchAddresses } from '@core/Data/Address'
import React from 'react'
import { Autocomplete } from '@core/Components/Autocomplete'

const AddressAutosuggest = Autocomplete as { new (): Autocomplete<string> }

const getSuggestionValue = (suggestion: string) => suggestion

const fetchSuggestions = (inputValue: string) => {
  if (!inputValue) {
    return []
  }

  return searchAddresses(inputValue).then(resp => resp.map(({ value }) => value))
}

interface AddressAutocompleteProps {
  id: string
  inputId?: string
  value?: string | null
  autoFocus?: boolean
  errorMessage: string | boolean
  backgroundColor?: string
  fontFamily?: string
  disabled?: boolean
  onSelection(suggestion: string | null): void
}

export function AddressAutocomplete({
  id,
  inputId,
  value = null,
  errorMessage,
  onSelection,
  autoFocus,
  backgroundColor,
  fontFamily,
  disabled,
}: AddressAutocompleteProps): JSX.Element {
  return (
    <AddressAutosuggest
      id={id}
      selection={value}
      inputProps={{ id: inputId }}
      errorMessage={errorMessage}
      autoFocus={autoFocus}
      backgroundColor={backgroundColor}
      fontFamily={fontFamily}
      disabled={disabled}
      onSelection={onSelection}
      getSuggestionValue={getSuggestionValue}
      getSuggestionsForInputValue={fetchSuggestions}
    />
  )
}
