import styled from '@emotion/styled'
import { SpecialIndicator } from '@core/Components/SpecialIndicator'
import { hexColors } from '@mobi/settings/src/hexColors'

export const ReceiptListStyled = styled('dl')({
  margin: 0,
  fontSize: '1.2rem',
  lineHeight: 1.4,
})

export const ReceiptListRowStyled = styled('div')({
  marginBottom: '0.5rem',
  display: 'flex',
  flexWrap: 'wrap',

  '&:last-of-type': {
    marginBottom: 0,
  },
})

export const ReceiptListItemStyled = styled('dt')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  margin: 0,

  '&::after': {
    content: '""',
    flexGrow: 1,
    height: '0.2rem',
    marginLeft: '0.4rem',
    marginRight: '0.4rem',
    borderBottom: '0.2rem dotted rgba(0,0,0,0.1)',
  },
})

export const ReceiptListItemTextStyled = styled('dd')({
  margin: 0,
  textAlign: 'right',
})

export const ReceiptSpecialIndicator = styled(SpecialIndicator)({
  width: '1.6rem',
  height: '1.6rem',
})

export const ReceiptCampaignTextStyled = styled.span<{ isBonusBet: boolean }>(({ isBonusBet }) => ({
  color: isBonusBet ? hexColors.orange : hexColors.cobalt,
}))
