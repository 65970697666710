import type { PaymentMethodsAll } from '@mobi/component-library/Deposit/types'

export const paymentMethodTypeNameMap: Record<PaymentMethodsAll, string> = {
  ApplePay: 'Apple Pay',
  CreditCard: 'Debit Card',
  GooglePay: 'Google Pay',
  PayPal: 'PayPal',
  BPay: 'BPay',
  Cash: 'Cash',
  ScanMyBet: 'Scan My Bet',
  PayID: 'PayID',
}
