import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const Select = styled.select({
  appearance: 'none',
  backgroundColor: hexColors.white,
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  minWidth: '100px',
  paddingBottom: '6px',
  paddingTop: '6px',
})

export const SelectContainer = styled.div({
  flex: '1 1 0%',
  position: 'relative',

  '&::after': {
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    color: hexColors.darkGrey,
    content: `'\\76'`,
    display: 'inline-block',
    flex: '1 1 0%',
    fontFamily: `'tticons'`,
    fontSize: '11px',
    pointerEvents: 'none',
    position: 'absolute',
    right: '3em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '3',
  },

  '& select': {
    paddingLeft: '3em',
    paddingRight: '3em',
    width: '100%',
  },
})
