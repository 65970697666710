import { hexColors } from './hexColors'

export const colors = {
  brand: {
    primary: hexColors.blueDiamond,
  },
  action: {
    primary: hexColors.sushi,
  },
  text: {
    dark: hexColors.nero,
    darkMuted: hexColors.grey,
    light: hexColors.white,
  },
  kambi: {
    container: {
      background: hexColors.athensGrey,
    },
  },
}
