import React from 'react'
import { Tabs, TabPanel } from 'react-tabs'
import { ThemeProvider } from '@emotion/react'
import { NameDetails } from '@mobi/api-types'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { Paragraph } from '@core/Components/Text/Paragraph'
import {
  DepositLimitsContainerStyled as DepositLimitsContainer,
  AccountHolderTabList as TabList,
  AccountHolderTab as Tab,
  TabLabel,
} from './DepositLimits.styles'
import { AccountHolders, AccountHolder } from '@core/Data/Account/accountHolder'
import { DepositLimitsViewAccountHolder } from './DepositLimitsViewAccountHolder'
import { getDepositLimitTheme, DepositLimitThemes } from './themes'

interface DepositLimitsViewProps {
  accountHolders: AccountHolders
  selectedAccountHolderNumber?: number
  isDesktop?: boolean
  saveSuccess?: boolean
}

export class DepositLimitsView extends React.Component<DepositLimitsViewProps, {}> {
  constructor(props: Readonly<DepositLimitsViewProps>) {
    super(props)
  }

  public render(): JSX.Element {
    const {
      accountHolders: { primaryAccountHolder, secondaryAccountHolder },
      selectedAccountHolderNumber,
      isDesktop,
      saveSuccess,
    } = this.props

    return (
      <ThemeProvider theme={getDepositLimitTheme(DepositLimitThemes.default)}>
        <MainContainer data-tid-deposit-limits-container=''>
          {!isDesktop && <ContentHeader title='Deposit Limits' />}
          <DepositLimitsContainer>
            <Paragraph>
              TABtouch supports responsible wagering. Manage the amount you can deposit by setting
              yourself weekly, fortnightly or even monthly limits to help you manage your betting.
            </Paragraph>
            {!secondaryAccountHolder ? (
              <DepositLimitsViewAccountHolder
                isDesktop={isDesktop}
                accountHolder={primaryAccountHolder}
                saveSuccess={saveSuccess}
              />
            ) : (
              <DepositLimitsViewAccountHolderTabs
                isDesktop={isDesktop}
                primaryAccountHolder={primaryAccountHolder}
                secondaryAccountHolder={secondaryAccountHolder}
                selectedAccountHolderNumber={selectedAccountHolderNumber}
                saveSuccess={saveSuccess}
              />
            )}
          </DepositLimitsContainer>
        </MainContainer>
      </ThemeProvider>
    )
  }
}

interface DepositLimitsViewAccountHolderTabsProps {
  primaryAccountHolder: AccountHolder
  secondaryAccountHolder: AccountHolder
  isDesktop?: boolean
  selectedAccountHolderNumber?: number
  saveSuccess?: boolean
}

export function DepositLimitsViewAccountHolderTabs(
  props: DepositLimitsViewAccountHolderTabsProps
): JSX.Element {
  const {
    primaryAccountHolder,
    secondaryAccountHolder,
    selectedAccountHolderNumber,
    saveSuccess,
    isDesktop,
  } = props

  const defaultTabIndex =
    secondaryAccountHolder.BetAccountHolderNumber === selectedAccountHolderNumber ? 1 : 0

  return (
    <Tabs defaultIndex={defaultTabIndex}>
      <TabList>
        {[primaryAccountHolder, secondaryAccountHolder].map((accountHolder, index) => (
          <Tab key={index} data-tid-tab={index}>
            <TabLabel>{buildName(accountHolder.NameDetails)}</TabLabel>
          </Tab>
        ))}
      </TabList>
      {[primaryAccountHolder, secondaryAccountHolder].map((accountHolder, index) => (
        <TabPanel key={index} data-tid-tab-panel={index}>
          <DepositLimitsViewAccountHolder
            isDesktop={isDesktop}
            accountHolder={accountHolder}
            saveSuccess={
              saveSuccess && selectedAccountHolderNumber === accountHolder.BetAccountHolderNumber
            }
          />
        </TabPanel>
      ))}
    </Tabs>
  )
}

function buildName(nameDetails: NameDetails) {
  return [nameDetails.FirstName, nameDetails.Surname].filter(n => !!n).join(' ')
}
