import React from 'react'
import styled from '@emotion/styled'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { hexColors } from '@mobi/settings'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { Button } from '@mobi/component-library/Common/Buttons'
import { useLogon } from '@core/Utils/hooks'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { getPreferencesFromApi, postPreferencesToApi, type OptInPreferences } from './api'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'

export const MyPreferences: React.FC<{}> = () => {
  const mutateDataRef = React.useRef<OptInPreferences>()

  useBackNav()
  const history = useHistory()
  const { accountNumber } = useLogon()

  const { data, isLoading, isError } = useQuery(
    queryKeys.accountPreferences(accountNumber as number),
    getPreferencesFromApi,
    { refetchOnMount: true, refetchOnWindowFocus: false }
  )

  const {
    mutate,
    isError: isMutateError,
    isLoading: isLoadingMutate,
    data: mutateData,
  } = useMutation<OptInPreferences, void, OptInPreferences>(options =>
    postPreferencesToApi(options)
  )

  React.useEffect(() => {
    if (!mutateData) return
    mutateDataRef.current = mutateData
  }, [mutateData])

  const isEmailChecked = mutateData
    ? mutateData.Email
    : mutateDataRef.current
      ? mutateDataRef.current.Email
      : !!data?.OptInPreferences.Email
  const isSmsChecked = mutateData
    ? mutateData.Sms
    : mutateDataRef.current
      ? mutateDataRef.current.Sms
      : !!data?.OptInPreferences.Sms
  const isPhoneChecked = mutateData
    ? mutateData.Phone
    : mutateDataRef.current
      ? mutateDataRef.current.Phone
      : !!data?.OptInPreferences.Phone
  const isMailChecked = mutateData
    ? mutateData.Mail
    : mutateDataRef.current
      ? mutateDataRef.current.Mail
      : !!data?.OptInPreferences.Mail

  const isAnyCommsChecked = isEmailChecked || isSmsChecked || isPhoneChecked || isMailChecked

  const isBusy = isLoading || isLoadingMutate

  return (
    <MainContainer background='grey'>
      <ContentHeader title='Subscription' shouldShowHome={false} />
      <WrapperStyled>
        {(isError || isMutateError) && (
          <NoticeBox
            hasBorder
            title={isMutateError ? 'Unable to make change' : 'Unable to get data'}
            subtitle='Please try again later'
            noticeBoxType={NoticeBoxTypes.Error}
          />
        )}

        <ul>
          <li>
            <span>I would like to receive bonus bets and offers</span>
            <CheckboxInput
              id='main-opt-in-check'
              label=''
              checked={isAnyCommsChecked}
              disabled={isBusy}
              onChange={() =>
                mutate({
                  Email: !isAnyCommsChecked,
                  Sms: !isAnyCommsChecked,
                  Phone: !isAnyCommsChecked,
                  Mail: !isAnyCommsChecked,
                  Marketing: !isAnyCommsChecked,
                })
              }
            />
          </li>
        </ul>

        <div>
          <span>Contact me via:</span>
        </div>

        <ul>
          <li>
            <strong>
              <span>Email</span> <label htmlFor='email-checkbox'>{data?.ContactInfo.Email}</label>
            </strong>
            <CheckboxInput
              id='email-checkbox'
              label=''
              checked={isEmailChecked}
              disabled={isBusy}
              onChange={() =>
                mutate({
                  ...(data?.OptInPreferences as OptInPreferences),
                  ...mutateData,
                  Email: !isEmailChecked,
                })
              }
            />
          </li>

          <li>
            <strong>
              <span>SMS</span> <label htmlFor='sms-checkbox'>{data?.ContactInfo.Mobile}</label>
            </strong>
            <CheckboxInput
              id='sms-checkbox'
              label=''
              checked={isSmsChecked}
              disabled={isBusy}
              onChange={() =>
                mutate({
                  ...(data?.OptInPreferences as OptInPreferences),
                  ...mutateData,
                  Sms: !isSmsChecked,
                })
              }
            />
          </li>

          <li>
            <strong>
              <span>Phone</span> <label htmlFor='phone-checkbox'>{data?.ContactInfo.Phone}</label>
            </strong>
            <CheckboxInput
              id='phone-checkbox'
              label=''
              checked={isPhoneChecked}
              disabled={isBusy}
              onChange={() =>
                mutate({
                  ...(data?.OptInPreferences as OptInPreferences),
                  ...mutateData,
                  Phone: !isPhoneChecked,
                })
              }
            />
          </li>

          <li>
            <strong>
              <span>Mail</span>{' '}
              <label htmlFor='mail-checkbox'>{data?.ContactInfo.MailingAddress}</label>
            </strong>
            <CheckboxInput
              id='mail-checkbox'
              label=''
              checked={isMailChecked}
              disabled={isBusy}
              onChange={() =>
                mutate({
                  ...(data?.OptInPreferences as OptInPreferences),
                  ...mutateData,
                  Mail: !isMailChecked,
                })
              }
            />
          </li>
        </ul>

        <div>
          <Button color='secondary' onClick={history.goBack}>
            {isBusy ? <SpinnerInlineStyled color='dark' size={2.5} /> : 'Cancel'}
          </Button>
        </div>
      </WrapperStyled>
    </MainContainer>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  fontSize: '1.4rem',

  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    borderTop: '0.1rem solid ' + hexColors.veryLightGrey,

    li: {
      margin: 0,
      fontSize: '1.4rem',
      backgroundColor: hexColors.white,
      borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,

      label: { marginBottom: 0 },
      '> div': { display: 'flex' },

      '> strong': {
        display: 'flex',

        span: { minWidth: '6.5rem' },

        label: {
          fontWeight: 'normal',
          color: hexColors.dimGrey,
        },
      },
    },
  },

  '> div, ul > li': {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '5rem',
    padding: '0.5rem 1rem',
  },

  '> div > button': {
    display: 'block',
    width: '100%',
    minHeight: '5rem',
    marginTop: '2rem',
  },
})
