import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { MultiTicket } from './Components/MultiTicket'
import { GeneralTicket } from './Components/GeneralTicket'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'

export const SkyPendingTicket: React.FC<{ ticket: Ticket; nextSkyRaces: NextSkyRace[] }> = ({
  ticket,
  nextSkyRaces,
}) => {
  const ticketType = getTicketLayoutType(ticket)
  const isMulti = ticketType === 'multi'

  return (
    <TicketDisplayContext.Provider
      value={{ type: 'pending-bets-drawer', data: { isSkyRaceMatch: false } }}
    >
      <WrapperStyled data-testid='SkyPendingTicket'>
        {isMulti ? (
          <MultiTicket ticket={ticket} nextSkyRaces={nextSkyRaces} />
        ) : (
          <GeneralTicket ticket={ticket} nextSkyRaces={nextSkyRaces} ticketType={ticketType} />
        )}
      </WrapperStyled>
    </TicketDisplayContext.Provider>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  marginBottom: spacing.smx1,
  borderRadius: radius.md,
  border: '0.5px solid ' + colors.neutral[200],
  background: colors.white,
  boxShadow: shadow.xs,

  fontFamily: font.family.primary,
  fontSize: font.size.sm.fontSize,
  letterSpacing: font.size.sm.letterSpacing,
  lineHeight: font.size.sm.lineHeight,
  fontWeight: font.weight.regular,
})
