import React, { type FC, memo } from 'react'
import { colors } from '@mobi/component-library/Theme/Common'
import { PillButton, Icon } from '@mobi/component-library/Common/V2'
import {
  PanelStyled as Panel,
  PanelIconStyled,
  PanelHeaderStyled as Header,
  PanelSubHeaderStyled as Subheader,
  PanelButtonsStyled as Buttons,
} from './Workflow.styled'

type Props = {
  wasDepositDeclined?: boolean
  message?: string
  onClose: VoidFunction
}

const Component: FC<Props> = ({
  wasDepositDeclined = false,
  message = 'Please update your payment method.',
  onClose,
}) => {
  return (
    <Panel>
      <PanelIconStyled backgroundColor={colors.error[50]}>
        <Icon name='LineAlertCircle' size='32px' color='#D92D20' />
      </PanelIconStyled>
      <Header>Payment Failed</Header>
      <Subheader>
        {!wasDepositDeclined && (
          <>
            Your deposit could not be completed.
            <br />
          </>
        )}
        {message}
      </Subheader>
      <Buttons>
        <PillButton color='primary' size='lg' isFullWidth={true} onClick={onClose}>
          Return to deposit
        </PillButton>
      </Buttons>
    </Panel>
  )
}

Component.displayName = 'FailPanel'

export const FailPanel = memo(Component)
