import React from 'react'
import { OverlayOpen } from '@core/Components/Overlay'
import { Betslip } from '@core/Areas/Betslip/Betslip'
import { AppHeaderButtonStyled as Button } from '../../../Buttons/Buttons.styles'
import {
  BetslipButtonBadgeStyled as Badge,
  BetslipButtonBadgeNumberStyled as Number,
  BadgeAnimation,
} from './BetslipButton.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { useBetslipCount } from './hooks/useBetslipCount'
import { store } from '@core/Store'
import { toggleIsAppHeaderVisible, toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'

export const BetslipButton = React.memo(() => {
  const [count, prevCount] = useBetslipCount()

  React.useEffect(() => {
    if (prevCount === null) return

    const hasCountIncreased = count > prevCount
    const toastText = hasCountIncreased ? 'Added to' : 'Removed from'

    RegisterToast({
      message: `${toastText} Betslip`,
      position: 'top',
      id: `Betslip Button Event - ${toastText} Betslip`,
    })

    if (hasCountIncreased && window.pageYOffset > 0) {
      store.dispatch(toggleIsAppHeaderVisible(true))
    }
  }, [count, prevCount])

  const animation = getBadgeAnimation(count, prevCount)

  return (
    <Button id='betslipButton' onClick={handleBetslipButtonClick} data-testid='header-betslip-btn'>
      {!!count && (
        <Badge>
          <Number key={count} animation={animation}>
            <span aria-label='Betslip Item Count' data-testid='betslip-item-count'>
              {count}
            </span>
            {animation && <span aria-hidden>{count + (animation === 'up' ? -1 : 1)}</span>}
          </Number>
        </Badge>
      )}
      <Icon type='betslip' title='Betslip' size='inherit' />
    </Button>
  )
})

// Local Helpers
function handleBetslipButtonClick() {
  store.dispatch(toggleIsDropDownOpen(false))
  OverlayOpen(Betslip)
}

function getBadgeAnimation(count: number, prevCount: number | null): BadgeAnimation {
  if (prevCount === null) return null
  return count > prevCount ? 'up' : 'down'
}
