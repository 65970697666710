import styled from '@emotion/styled'

/**
 * A video container with aspect ratio fallback support
 *
 * To check if the fallback is required, @supports is used. This query is widely supported
 * Fallback uses a padding that results from (9 / 16) * 100
 *
 * @link https://caniuse.com/?search=supports
 * @link https://usefulangle.com/post/142/css-video-aspect-ratio
 */
export const VideoContainerStyled = styled.div({
  width: '100%',

  '@supports not (aspect-ratio: 16/9)': {
    height: '0px',
    paddingTop: '56.25%',
    position: 'relative',

    '& > video': {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
    },
  },
})

export const VideoStyled = styled.video({
  aspectRatio: '16 / 9',
  width: '100%',
})
