import { RaceStatus } from '@mobi/api-types'
import { useMemo } from 'react'

export function useRaceStatus(status: MaybeDefined<RaceStatus>) {
  const isOpenMemo = useMemo(() => isOpen(status), [status])
  const hasResultedMemo = useMemo(() => hasResulted(status), [status])
  const hasResultsMemo = useMemo(() => hasResults(status), [status])
  return {
    isOpen: isOpenMemo,
    hasResulted: hasResultedMemo,
    hasResults: hasResultsMemo,
  }
}

const isOpen = (status: MaybeDefined<RaceStatus>): boolean => status === RaceStatus.Open

export const hasResulted = (status: MaybeDefined<RaceStatus>): boolean =>
  status !== undefined &&
  [RaceStatus.Released, RaceStatus.Interim, RaceStatus.Abandoned].includes(status)

export const hasResults = (status: MaybeDefined<RaceStatus>): boolean =>
  status !== undefined && [RaceStatus.Released, RaceStatus.Interim].includes(status)
