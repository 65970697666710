import styled from '@emotion/styled'
import { Money } from '@core/Components/Text/Money'
import { colors } from '@core/Settings'

export const ProjectedReturnStyled = styled('div')({
  fontSize: '1.2rem',
  lineHeight: 'normal',
  padding: '0.5em 0 0.5rem 0',
  color: colors.betslip.multiDetails.label.color,
})

export const ProjectedReturnMoneyStyled = styled(Money)({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  color: colors.betslip.item.text,
})
