import React from 'react'
import { range } from '@mobi/utils'
import { PaginationStyled, PaginationDotStyled } from './Pagination.styles'
import { ErrorMessage } from '../Messages'

interface PaginationProps {
  dotCount: number
  index?: number
}

export function Pagination({ dotCount, index = 0 }: PaginationProps) {
  if (dotCount < 0) {
    return PRODUCTION ? null : <ErrorMessage>dotCount must not be negative.</ErrorMessage>
  }
  if (dotCount <= 1) {
    return null
  }
  if (index < 0 || index >= dotCount) {
    return PRODUCTION ? null : (
      <ErrorMessage>
        Index must be in range for [0..{dotCount - 1}] for {dotCount} dots.
      </ErrorMessage>
    )
  }

  return (
    <PaginationStyled role='group'>
      {range(0, dotCount).map(i => (
        <PaginationDotStyled key={i} active={i === index} />
      ))}
    </PaginationStyled>
  )
}
