import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import Guard from '@classic/AppUtils/Framework/Guard'
import { state$ as bettingV2CommandDriver$ } from '../../Commands/driver'

@injectable()
export class StartersListViewModel extends Disposable {
  public starters: ko.ObservableArray<IObservableStarter>
  public raceNumber: ObservableRaceKey
  public bettingContext: BettingInformation
  public isInfoVisible: ko.Observable<boolean>
  public numberOfStartersInRace: ko.PureComputed<number>
  private hasMarketMovers: ko.PureComputed<boolean>
  private hasFixedOdds: ko.PureComputed<boolean>
  private subInfoButton: Rx.Disposable
  public doubleProvDivAllowed: ko.Observable<boolean>
  public quaddieProvDivAllowed: ko.Observable<boolean>
  public isRaceClosed: ko.Observable<boolean> = ko.observable(false)

  constructor(
    @inject('$params') params: StartersListViewModelParams,
    @inject('IEventAggregator') evtAggregator: IEventAggregator
  ) {
    super(evtAggregator)

    Guard.notNull(params.raceStarters)
    Guard.notNull(params.bettingContext)

    this.evtAggregator = evtAggregator
    this.bettingContext = params.bettingContext
    this.raceNumber = params.raceStarters.Key
    this.starters = params.raceStarters.Value

    this.hasMarketMovers = ko.pureComputed(() =>
      this.starters().some(starter => starter.hasMarketMovers())
    )

    this.numberOfStartersInRace = ko.pureComputed(() => this.starters().length)

    this.hasFixedOdds = ko.pureComputed(() =>
      this.starters().some(starter => starter.hasFixedOdds())
    )

    this.isInfoVisible = ko.observable(false)

    this.isRaceClosed(params.isRaceClosed || false)

    this.doubleProvDivAllowed = !this.isRaceClosed()
      ? params.doubleProvDivAllowed
      : ko.observable(false)
    this.quaddieProvDivAllowed = !this.isRaceClosed()
      ? params.quaddieProvDivAllowed
      : ko.observable(false)

    this.subInfoButton = bettingV2CommandDriver$
      .map(record => {
        return {
          showAllForm: record.showAllForm,
          showFixedFlucs: record.showFixedFlucs,
          showMarketMovers: record.showMarketMovers,
          showDoubleProvDiv: record.showDoubleProvDiv,
          showQuaddieProvDiv: record.showQuaddieProvDiv,
        }
      })
      .distinctUntilChanged()
      .subscribe(record => {
        //  This is where the subscription to the driver state is resolved. Put logic here, not in toggleFixedFlucs.
        if (!this.isRaceClosed()) {
          this.isInfoVisible(
            record.showAllForm ||
              (record.showFixedFlucs && this.hasFixedOdds()) ||
              (record.showMarketMovers && this.hasMarketMovers()) ||
              (record.showDoubleProvDiv && this.doubleProvDivAllowed()) ||
              (record.showQuaddieProvDiv && this.quaddieProvDivAllowed())
          )
        }
      })

    this.registerDisposals(() => {
      this.subInfoButton.dispose()
    })
  }
}

class StartersListViewModelParams {
  raceStarters!: { Key: ObservableRaceKey; Value: ko.ObservableArray<IObservableStarter> }
  bettingContext!: BettingInformation
  hasFixedOdds!: ko.Observable<boolean>
  doubleProvDivAllowed!: ko.Observable<boolean>
  quaddieProvDivAllowed!: ko.Observable<boolean>
  isRaceClosed?: boolean
}
