import * as ko from 'knockout'
import { IMultiLegSelectionsViewModel } from './IMultiLegSelectionsViewModel'
import { BettingInformation } from '../../../Model/BettingInformation'
import container from '../../../IOC/inversify.config'
import template from './multileg-selections.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('multileg-selections')) {
    ko.components.register('multileg-selections', {
      viewModel: {
        createViewModel: (params: {
          bettingContext: BettingInformation
        }): IMultiLegSelectionsViewModel => {
          const viewModel = container.get<IMultiLegSelectionsViewModel>(
            'IMultiLegSelectionsViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
