export const shareIos =
  'm167.4 48.59-14.34 14.57L137 46.84v81.67h-20V46.84l-16.06 16.32L86.6 48.59 127 7.53ZM209 72v112.47A20.59 20.59 0 0 1 188.47 205H65.5A20.51 20.51 0 0 1 45 184.47V71.53A20.53 20.53 0 0 1 65.53 51L66 72v112h123V72l-.4-21c11.22 0 20.4 9.35 20.4 21Z'

export const shareAndroid =
  'M196 175.2a33 33 0 0 0-22.21 8.72L93 136.9a37 37 0 0 0 1-7.9 36.89 36.89 0 0 0-1-8l79.9-46.57A33.92 33.92 0 1 0 162 49.64a36.89 36.89 0 0 0 1 7.93l-79.9 46.58a34 34 0 1 0 0 49.63l80.69 47.14a31.85 31.85 0 0 0-.91 7.37A33.09 33.09 0 1 0 196 175.2Z'

export const shareWeb =
  'M201 182H55V75h33.12s8.12-11.35 24.59-22H43.29A11.3 11.3 0 0 0 32 64.29v128.45A11.31 11.31 0 0 0 43.32 204h169.39A11.3 11.3 0 0 0 224 192.71v-62.48l-23 18.08Zm-35-77v37.58l75.28-56.17L166 29.76v35.11c-91 0-94.1 96.63-94.1 96.63C97.66 119.45 116.6 105 166 105Z'

export const twitter =
  'm225.9 80.5.1 6.5c0 66.7-50.8 143.7-143.7 143.7A143 143 0 0 1 5 208a102.7 102.7 0 0 0 12 .7 101.5 101.5 0 0 0 62.8-21.6A50.5 50.5 0 0 1 32.5 152a50.3 50.3 0 0 0 22.8-.9 50.5 50.5 0 0 1-40.5-49.5v-.6a50 50 0 0 0 22.9 6.3A50.6 50.6 0 0 1 22 40a143.3 143.3 0 0 0 104 52.8 50.5 50.5 0 0 1 86.1-46.1 101.5 101.5 0 0 0 32-12.3 50.8 50.8 0 0 1-22.1 28 100.6 100.6 0 0 0 29-8 102.8 102.8 0 0 1-25.2 26.2Z'

export const facebook =
  'M236.8 124.8A109.8 109.8 0 0 0 129.6 17.5C68.8 16.6 19.2 66.5 19.2 128A110 110 0 0 0 111 237.3V160H83v-32h28v-24c0-27.7 16.4-42.9 41.4-42.9a167.5 167.5 0 0 1 24.6 2.1v28h-14c-13.8 0-18 8.5-18 17.3v19.8h30.2l-4.8 32H145v77.3c53-8.5 93.4-56 91.8-112.7Z'

export const instagram =
  'M128 20.6c-40.2 1-44-2.4-70.8 5.6C33 36 21.3 57.4 20.5 84.3c-1 9-.8 80 0 89 .8 26.9 12.6 48.4 36.7 58 27 8 30.5 4.7 70.8 5.6 40.2-1 44 2.4 70.8-5.7 24.1-9.6 35.9-31 36.7-57.8 1-9.1.8-80 0-89.2-.8-26.8-12.6-48.3-36.7-57.8-27-8-30.5-4.8-70.8-5.7Zm0 19.4c48.9 1 84.3-7 88 45 1 9 .8 78.4 0 87.4-1.6 17.4-2.8 22-11.9 32.5-20.6 17.4-38.3 10.8-76.3 12.5-38.1-1.7-55.8 4.8-76.4-12.6-9.2-10.5-10.2-15.2-12-32.6-.7-9.1-.6-78.3 0-87.4 4-52.2 39.2-44 88.2-45Zm0 33.1c-73 1.3-73 109.9 0 111 73-1.1 73-109.7 0-111Zm0 91.6c-47.1-.2-47.1-71.9 0-72 47.1.2 47.1 71.8 0 72ZM198.7 71c0 17-25.9 17-26 0 .1-17 26-17 26 0Z'
