import * as ko from 'knockout'
import { WinPlaceViewModel } from './WinPlaceViewModel'
import template from './winplace.tpl.html'

if (!ko.components.isRegistered('win-place-bettype')) {
  ko.components.register('win-place-bettype', {
    viewModel: WinPlaceViewModel,
    template,
  })
}
