import type {
  RaceCodesLowerCase as CodesLower,
  RaceCodes,
} from '@core/Areas/Racing/Types/MeetingInformation'

export function meetingIdToContestType(meetingId: string, isUpper: true): RaceCodes | undefined
export function meetingIdToContestType(meetingId: string, isUpper?: false): CodesLower | undefined
export function meetingIdToContestType(
  meetingId: string,
  isUpper: boolean = false
): RaceCodes | CodesLower | undefined {
  if (!meetingId || meetingId.length <= 1) return undefined

  switch (meetingId[1].toLowerCase()) {
    case 'r':
      return isUpper ? 'Races' : 'races'
    case 'd':
      return isUpper ? 'Dogs' : 'dogs'
    case 't':
      return isUpper ? 'Trots' : 'trots'
    default:
      return undefined
  }
}
