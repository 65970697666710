import { PyosStore } from '../../Store/PyosStore'
import { injectable, inject } from 'inversify'

@injectable()
export class PyosMessageViewModel {
  /* properties */
  private _store: PyosStore

  constructor(@inject('PyosStore') store: PyosStore) {
    this._store = store
  }

  /* computed state */
  isVisible() {
    return !!this._store.errorMessage()
  }

  messageText(): string {
    return this._store.errorMessage()
  }
}
