import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

const enum LocalConstants {
  TextExpandedClassName = 'js-race-info--expanded-text',
  TitleSwitchClassName = 'js-race-info--switch-titles',
  SkyRaceCardClassName = 'js-race-info--sky-race',
}

export const RaceInfoWrapperWithTextExpand: React.FC<{
  titlePrimary: string
  titleSecondary: string
  subTitle: ReactNode
  shouldSwitchTitles: boolean
  children: React.ReactChild
  isOnSkyRacePage?: boolean
}> = ({
  children,
  titlePrimary,
  titleSecondary,
  subTitle,
  shouldSwitchTitles,
  isOnSkyRacePage,
}) => {
  const textualElRef = React.useRef<HTMLDivElement>(null)

  const [shouldEnableTextExpand, setShouldEnableTextExpand] = React.useState(false)
  const [isTextExpanded, setIsTextExpanded] = React.useState(false)

  // Only allow text expand on click when overflowed
  React.useEffect(() => {
    if (!textualElRef.current || !window.ResizeObserver) return
    const el = textualElRef.current
    const observer = new ResizeObserver(() => {
      for (const child of el.children) {
        const spanEls = child.querySelectorAll<HTMLSpanElement>('span')
        for (const spanEl of spanEls) {
          if (spanEl.offsetWidth < spanEl.scrollWidth) setShouldEnableTextExpand(true)
        }
      }
    })
    observer.observe(el)
    return () => observer.disconnect()
  }, [])

  const classNames = []
  if (isTextExpanded) classNames.push(LocalConstants.TextExpandedClassName)
  if (isOnSkyRacePage) classNames.push(LocalConstants.SkyRaceCardClassName)

  return (
    <WrapperStyled className={classNames.join(' ')}>
      <div onClick={shouldEnableTextExpand ? () => setIsTextExpanded(curr => !curr) : undefined}>
        <div
          ref={textualElRef}
          className={shouldSwitchTitles ? LocalConstants.TitleSwitchClassName : ''}
        >
          <h2 data-testid='race-info__name'>
            <span>{titlePrimary}</span>
            <span>{titleSecondary}</span>
          </h2>

          <div data-testid='race-info__extra'>
            <span>{subTitle}</span>
          </div>
        </div>

        {isTextExpanded && (
          <div>
            <Icon name='SolidXClose' size='1.6rem' color={colors.neutral[900]} />
          </div>
        )}
      </div>

      <div>{children}</div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',

  '> div': { transition: 'all 0.3s' },

  // Race textual info
  '> div:first-of-type': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing.md,

    fontWeight: font.weight.regular,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,
    letterSpacing: font.size.md.letterSpacing,
    overflow: 'hidden',

    '> div:nth-of-type(1)': {
      overflow: 'hidden',

      '> h2 > span, > div > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },

      '> h2': {
        margin: 0,
        padding: 0,
        textShadow: 'none',
        textAlign: 'left',
        color: colors.black,

        fontFamily: font.family.primary,
        fontWeight: font.weight.semibold,
        fontSize: font.size.md.fontSize,
        lineHeight: font.size.md.lineHeight,
        letterSpacing: font.size.md.letterSpacing,
        textTransform: 'unset',

        // Default to show race name, hide race # + meeting
        '> span:nth-of-type(1)': { display: 'none' },
        '> span:nth-of-type(2)': { display: 'block' },
      },

      '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },

      // Switch titles when pinned (standard race) or button out of view (sky)
      [`&.${LocalConstants.TitleSwitchClassName} > h2`]: {
        '> span:nth-of-type(1)': { display: 'block', animation: 'ease-in 0.2s fadeIn forwards' },
        '> span:nth-of-type(2)': { display: 'none' },
      },

      '> div': { display: 'flex' },
    },

    // Close icon
    '> div:nth-of-type(2)': {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      paddingLeft: '0.2rem',
      opacity: 0,
      animation: 'ease-in 0.3s fadeIn 0.1s forwards',

      '> span': {
        padding: spacing.smx1,
        background: colors.surface[50],
        borderRadius: radius.md,
      },
    },
  },

  // Race actions / status / extra info
  '> div:last-of-type': {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    gap: spacing.smx2,
    width: '100%',
    maxWidth: 'fit-content',
    opacity: 1,
    transform: 'translateX(0)',
    paddingRight: spacing.md,
    paddingLeft: '0.5rem',
    marginLeft: 'auto',

    '> *': {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
  },

  [`&.${LocalConstants.SkyRaceCardClassName}`]: {
    '> div:first-of-type': { paddingLeft: spacing.smx1 },
    '> div:last-of-type': { paddingRight: spacing.smx1 },
  },

  // Text expanded animation of status/btn section
  [`&.${LocalConstants.TextExpandedClassName}`]: {
    overflowX: 'hidden',

    '> div:last-of-type': {
      width: 0,
      opacity: 0,
      visibility: 'hidden',
      pointerEvents: 'none',
      transform: `translateX(calc(100% + ${spacing.md}))`,
    },
  },
})
