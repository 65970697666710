import * as ko from 'knockout'
import { MySelectionsViewModel } from './MySelectionsViewModel'
import template from './myselections.tpl.html'

if (!ko.components.isRegistered('my-selections')) {
  ko.components.register('my-selections', {
    viewModel: MySelectionsViewModel,
    template,
  })
}
