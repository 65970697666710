import produce from 'immer'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { FobPriceChangedPushEvent } from '@core/State/PushData'
import { toDisplayPrice } from '@mobi/utils/money'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'

interface PushFobPriceChanged {
  event: FobPriceChangedPushEvent
  meetingId: string
  meetingDate: Date
  raceInformation: ObservableRacePage
  raceNumber: number
  resetFobFavourite: (starters: IObservableStarter[]) => void
}

export function pushFobPriceChanged({
  event,
  raceInformation,
  meetingId,
  meetingDate,
  raceNumber,
  resetFobFavourite,
}: PushFobPriceChanged) {
  const { winPrice, placePrice, marketId, propositionId } = event

  const queryKey = queryKeys.racePageComplete(meetingId, meetingDate, raceNumber)

  // Update ReactQuery state
  queryClient.setQueryData<RacePageDTO | undefined>(queryKey, current => {
    if (!current) return undefined // key not found
    if (!isRacePageDto(current)) return undefined // only update open races
    return produce(current, draft => {
      // Extra Fixed Odds Markets
      const fixedOddsMarketMatch = draft.FixedOddsMarkets?.find(
        ({ MarketSequence }) => MarketSequence === marketId
      )
      if (fixedOddsMarketMatch) {
        const fixedPropositionWithChange = fixedOddsMarketMatch.Propositions.find(
          ({ PropositionSequence }) => PropositionSequence === propositionId
        )
        if (!fixedPropositionWithChange) return
        fixedPropositionWithChange.Price = winPrice
        fixedPropositionWithChange.PriceToDisplay = toDisplayPrice(winPrice)
        return
      }

      // Win / Place Fixed
      const starters = draft.RaceStarters.flatMap(rs =>
        rs.RaceKey.RaceNumber === raceNumber ? rs.Starters : []
      )
      for (const starter of starters) {
        if (starter.FixedOddsStarterInfo?.PropositionSequence === propositionId) {
          starter.FixedOddsStarterInfo.DisplayWinDividend = toDisplayPrice(winPrice)
          starter.FixedOddsStarterInfo.DisplayPlaceDividend = toDisplayPrice(placePrice)
          starter.FixedOddsStarterInfo.IsSuspended = !winPrice
          break
        }
      }
    })
  })

  // Update KO View Model state
  const starter = raceInformation.raceStarters.getStartersByPropSeq(propositionId)

  if (starter?.fixedOddsStarterInfo && !starter.fixedOddsStarterInfo.isScratched()) {
    if (placePrice !== undefined) {
      starter.fixedOddsStarterInfo.displayPlaceDividend(toDisplayPrice(placePrice))
    }

    if (winPrice !== undefined) {
      const starters = raceInformation.getStartersForRace(raceNumber)()

      const { isSuspended, displayWinDividend, hasWinDividend } = starter.fixedOddsStarterInfo

      if (isSuspended() && !hasWinDividend()) {
        isSuspended(false)
      }

      displayWinDividend(toDisplayPrice(winPrice))
      hasWinDividend(winPrice !== null)
      resetFobFavourite(starters)
    }
  }
}
