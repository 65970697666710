type ScriptAttributes = Partial<Omit<HTMLScriptElement, 'src' | 'async'>>

export function scriptLoader(
  url: string,
  attributes?: ScriptAttributes
): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const script = window.document.createElement('script')
    script.src = url
    script.async = true

    Object.assign(script, attributes)

    script.addEventListener('load', () => {
      resolve(script)
    })

    script.addEventListener('error', reject, false)

    window.document.body.appendChild(script)
  })
}
