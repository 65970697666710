export const getPriceChange = (
  oldPrice: number | undefined | null,
  newPrice: number
): PriceChange => {
  if (!oldPrice) {
    return PriceChange.None
  }

  if (oldPrice > newPrice) {
    return PriceChange.Decrease
  } else if (oldPrice < newPrice) {
    return PriceChange.Increase
  }
  return PriceChange.None
}

// string values coming from API response
export enum PriceChange {
  Increase = 'Up',
  Decrease = 'Down',
  None = 'NoChange',
}

export const getDividendChange = (
  newDividend: number,
  oldDividend: number | undefined | null
): DividendChange => {
  if (!oldDividend || !newDividend) {
    return DividendChange.Undefined
  }

  if (newDividend < oldDividend) {
    return DividendChange.Decrease
  } else if (newDividend > oldDividend) {
    return DividendChange.Increase
  }
  return DividendChange.None
}

// string values coming from API response
export enum DividendChange {
  Increase = 'Up',
  Decrease = 'Down',
  None = 'No Change',
  Undefined = '',
}
