import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const SummaryLine: React.FC<{ left: string; right?: string }> = ({ left, right }) => {
  return (
    <WrapperStyled>
      <span>{left}</span>
      {right && <span>{right}</span>}
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: spacing.smx2,
  paddingBottom: spacing.smx2,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  fontWeight: font.weight.regular,
  color: colors.black,

  '> span:nth-of-type(2)': {
    fontWeight: font.weight.medium,
  },
})
