export enum PlanSeq {
  Unknown = 0,
  Win = 1,
  Place = 7,
  WinAndPlace = 8,

  Exacta = 27,
  ExactaWithRovingBanker = 122,

  Quinella = 19,

  Trifecta = 11,
  TrifectaWithRovingBanker = 123,

  First4 = 35,
  First4WithRovingBanker = 124,

  Double = 43,
  MysteryDouble1 = 44,
  MysteryDouble2 = 45,
  MysteryDouble3 = 46,
  MysteryDouble4 = 47,
  MysteryDouble5 = 48,
  MysteryDouble6 = 49,
  MysteryDouble7 = 50,

  Quaddie = 51,
  MysteryQuaddie1 = 52,
  MysteryQuaddie2 = 53,
  MysteryQuaddie3 = 54,
  MysteryQuaddie4 = 55,
  MysteryQuaddie5 = 56,
  MysteryQuaddie6 = 57,
  MysteryQuaddie7 = 58,

  Mystery$3Combo = 60,
  Mystery$10Combo = 61,

  AllUp = 91,

  FavouriteNumbers = 64,

  Footo = 89,
  FootyTipping = 92,
  SportsTipping = 93,

  FOBRacing = 94,

  FOBSports = 110,
}
