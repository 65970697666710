import { createSignal } from 'rwwa-rx-state-machine'

export enum KeypadModes {
  Denomination = 'Denomination',
  Numeric = 'Numeric',
  Percentage = 'Percentage',
}

export enum SpecialKeys {
  Clear = 'clr',
  Backspace = 'bksp',
  Blank = '',
  Decimal = '.',
}

export interface KeyPressed {
  mode: KeypadModes
  value: number | SpecialKeys
}

export const Keypress = createSignal<KeyPressed>('Keypress')
