/**
 * Detects if the Apple Pay JS API is supported by the browser (Safari only)
 */
export function isApplePayWebSupported(): boolean {
  try {
    return (
      !!window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    )
  } catch {
    return false
  }
}
