import { Events, Contract } from '.'

export class CommService {
  private subscribers: Partial<{
    [key in Events]: CommsCallback[]
  }>

  constructor() {
    this.subscribers = {}
  }

  removeCallback<K extends keyof Contract>(
    topic: K,
    callbackRef: (data: Contract[K]) => void
  ): void {
    if (!this.subscribers[topic]) return
    const indexToRemove = this.subscribers[topic]?.findIndex(
      currListener => currListener === callbackRef
    )
    if (indexToRemove === -1 || indexToRemove === undefined) return
    this.subscribers[topic]?.splice(indexToRemove, 1)
  }

  notifySubscribers<K extends keyof Contract>(topic: K, data: Contract[K]): void {
    if (!this.subscribers[topic]) return
    this.subscribers[topic]?.forEach(callback => {
      callback(data)
    })
  }

  addCallback<K extends keyof Contract>(topic: K, callbackRef: (data: Contract[K]) => void): void {
    if (!this.subscribers[topic]) {
      this.subscribers[topic] = []
    }
    this.subscribers[topic]?.push(callbackRef as CommsCallback)
  }
}

type CommsCallback = (data: Contract[Events]) => void
