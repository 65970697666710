import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'

import { WithdrawContainerStyled } from './Withdraw.styles'
import { navigateToAccount } from './helpers/navigaton'
import { trackWithdrawalError } from './analytics'

export const WithdrawFailure = (): JSX.Element => {
  React.useEffect(() => {
    trackWithdrawalError('Withdraw Error')
  }, [])

  return (
    <MainContainer data-tid-withdraw-failure='' forceSizing={false}>
      <ContentHeader title='Withdrawal' />
      <WithdrawContainerStyled>
        <NoticeBoxSingle
          title='The withdrawal could not be completed'
          noticeBoxType={NoticeBoxTypes.Error}
        />
        <ButtonBlock onClick={navigateToAccount} color='primary' testId='back-to-account-btn'>
          Back to Account
        </ButtonBlock>
      </WithdrawContainerStyled>
    </MainContainer>
  )
}
