import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const ContainerStyled = styled.div({
  color: hexColors.black,
  fontSize: '1.2em',
  padding: '1.5em 1.25em',
})

export const HeaderImageStyled = styled.img({
  borderRadius: '0.75em',
  marginBottom: '1em',
  maxWidth: '100%',
  width: '100%',
})

export const HeaderStyled = styled.h3({
  background: 'none',
  color: 'inherit',
  fontWeight: 'bold',
  fontSize: '1.1em',
  margin: '1.25em 0 0.25em',
  padding: 0,
  textTransform: 'none',
})

export const TextStyled = styled.p({
  fontSize: '1em',
  lineHeight: 1.4,
  textAlign: 'left',
  margin: 0,
})

export const ListStyled = styled.ul({
  margin: 0,
})

export const LinkListStyled = styled(ListStyled)({
  listStyle: 'none',
  marginTop: '0.5em',
  padding: 0,
})

export const ListItemStyled = styled.li({
  fontSize: '1em',
  lineHeight: 1.4,
})

export const LinkStyled = styled.a({
  color: hexColors.studio,
  fontWeight: 'bold',
  fontSize: '1.1em',
  textDecoration: 'underline',
})

export const ExternalLinkStyled = styled(LinkStyled)({
  alignItems: 'center',
  display: 'inline-flex',

  '> svg': {
    marginLeft: '0.25em',
  },
})

export const Button = styled(ButtonBlock)({
  marginTop: '2em',
})
