import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { TrotsFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { HistoryExpandable } from '../Common/HistoryExpandable'

export const TrotsHistorySingle: React.FC<{
  history: TrotsFormRaceResponse['Forms'][0]['TrotHistories'][0]
}> = ({
  history: {
    Place,
    FieldSize,
    RaceNumber,
    DriverName,
    TrackName,
    FixtureDate,
    Distance,
    StewardsComments,
    RaceClass,
    Handicap,
    MileRate,
    StartType,
    StartPrice,
    Margin,
    Placings,
    RaceReplay,
  },
}) => {
  return (
    <HistoryExpandable
      Place={Place}
      FieldSize={FieldSize}
      RaceNumber={RaceNumber}
      TrackName={TrackName}
      FixtureDate={FixtureDate}
      Distance={Distance}
      RaceReplay={RaceReplay}
    >
      <>
        {StewardsComments && (
          <div data-testid='form-history-stewards-comments'>
            <strong>Stewards Comments</strong>
            <br />
            {StewardsComments}
          </div>
        )}

        <Grid>
          <GridCell width='50%'>
            <div data-testid='form-history-class'>
              <strong>Class</strong> {RaceClass}
            </div>
            <div data-testid='form-history-starttype'>
              <strong>Start Type</strong> {StartType}
            </div>
            <div data-testid='form-history-milerate'>
              <strong>Mile Rate</strong> {MileRate}
            </div>
            <div data-testid='form-history-startprice'>
              <strong>Start Price</strong> {StartPrice}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='form-history-driver'>
              <strong>Driver</strong> {DriverName}
            </div>
            <div data-testid='form-history-handicap'>
              <strong>Handicap</strong> {Handicap}
            </div>
            <div data-testid='form-history-margin'>
              <strong>Margin</strong> {Margin}
            </div>
          </GridCell>
        </Grid>

        {Placings.length && (
          <div>
            <ol>
              {Placings.map(({ Place, StarterName, Handicap, Margin }) => (
                <li key={Place}>
                  <strong>{Place}</strong>
                  <span>{StarterName + ' (' + Handicap + ')'}</span>
                  {Margin && <div>{Margin}</div>}
                </li>
              ))}
            </ol>
          </div>
        )}
      </>
    </HistoryExpandable>
  )
}
