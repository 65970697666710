import { ObservableFinisher } from './ObservableFinisher'
import Guard from '../../../AppUtils/Framework/Guard'
import * as ko from 'knockout'
import { IObservableFinisher } from './IObservableFinisher'

export default class ObservableRaceFinisher extends ObservableFinisher {
  constructor() {
    super()

    this.jockeyWeight = ko.observable<string>()
    this.barrier = ko.observable<number>()
  }

  public merge(other: ObservableRaceFinisher) {
    Guard.notNull(other)
    super.merge(other)

    this.jockeyWeight(other.jockeyWeight())
    this.barrier(other.barrier())
  }

  public untypedMerge(other: IObservableFinisher) {
    this.merge(other as ObservableRaceFinisher)
  }

  public jockeyWeight: ko.Observable<string | undefined>
  public barrier: ko.Observable<number | undefined>
}
