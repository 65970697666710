import * as dataLayer from './AnalyticsDataLayer'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { trackAccountNumber } from './Analytics'
import { isReactNativeIos, sendToNative } from '@mobi/web-native-comms/web'
import { getFeatureValue } from '@core/Utils/hooks/useFeature'
import { fullClientVersion } from '@core/Utils/clientVersion'

export const trackNav = (): void => trackKey(dataLayer.keys.nav)
export const trackBet = (): void => trackKey(dataLayer.keys.betSelected)
export const trackBetSingleSelected = (): void => trackKey(dataLayer.keys.betSelected)
export const trackBetSingleReview = (): void => {
  trackKey(dataLayer.keys.betAccountSelected)
  trackKey(dataLayer.keys.betAccountReview)
}
export const trackBetSingleReceipt = (): void => trackKey(dataLayer.keys.betAccountProcessed)

export const trackBetSlipAdd = (data: object): void =>
  trackEvent(dataLayer.keys.betslipBetAdded, { data })
export const trackBetSlipReview = (): void => {
  trackKey(dataLayer.keys.betslipAccountSelected)
  trackKey(dataLayer.keys.betslipAccountReview)
}
export const trackBetSlipReceipt = (): void => trackKey(dataLayer.keys.betslipAccountProcessed)
export const trackBetSlipCashSelected = (): void => trackKey(dataLayer.keys.betslipCashSelected)
export const trackBetSlipClear = (): void => trackKey(dataLayer.keys.betslipCleared)
export const trackBetSlipRemove = (): void => trackKey(dataLayer.keys.betslipBetRemoved)

export const trackMysterySelected = (): void => trackKey(dataLayer.keys.mysterySelected)
export const trackMysteryReview = (): void => {
  trackKey(dataLayer.keys.mysteryAccountSelected)
  trackKey(dataLayer.keys.mysteryReview)
}
export const trackMysteryReceipt = (): void => trackKey(dataLayer.keys.mysteryReceipt)

export const trackFavNumbersSelected = (): void => trackKey(dataLayer.keys.favNumbersSelected)
export const trackFavNumbersReview = (): void => {
  trackKey(dataLayer.keys.favNumbersAccountSelected)
  trackKey(dataLayer.keys.favNumbersAccountReview)
}
export const trackFavNumbersReceipt = (): void =>
  trackKey(dataLayer.keys.favNumbersAccountProcessed)

export const trackTippingSelected = (): void => trackKey(dataLayer.keys.tippingSelected)
export const trackTippingReview = (): void => {
  trackKey(dataLayer.keys.tippingAccountSelected)
  trackKey(dataLayer.keys.tippingAccountReview)
}
export const trackTippingReceipt = (): void => trackKey(dataLayer.keys.tippingAccountProcessed)

export const trackMultiAdd = (): void => trackKey(dataLayer.keys.multiBetAdded)
export const trackMultiReview = (): void => {
  trackKey(dataLayer.keys.multiAccountSelected)
  trackKey(dataLayer.keys.multiAccountReview)
}
export const trackMultiReceipt = (): void => trackKey(dataLayer.keys.multiAccountProcessed)
export const trackMultiClear = (): void => trackKey(dataLayer.keys.multiCleared)
export const trackMultiRemove = (): void => trackKey(dataLayer.keys.multiBetRemoved)

export const trackExit = (externalSite: string): void =>
  trackKey((dataLayer.keys.exitToBaseKey + externalSite) as EventKey)
export const trackMenu = (): void => trackKey(dataLayer.keys.menu)
export const trackAccount = (): void => trackKey(dataLayer.keys.accountProcessed)
export const trackDeposit = (key: string): void =>
  trackKey((dataLayer.keys.depositBaseKey + key) as EventKey)
export const trackCheckIn = (key: string): void =>
  trackKey((dataLayer.keys.checkInBaseKey + key) as EventKey)
export const trackActivity = (key: string): void =>
  trackKey((dataLayer.keys.activityBaseKey + key) as EventKey)

export const trackSearchQuery = (searchQueryText: string): void => {
  trackEvent(dataLayer.keys.searchQuery, { searchQueryText })
}
export const trackTrendingBetSelected = (data: object): void =>
  trackEvent(dataLayer.keys.trendingBetSelected, { data })
export const trackTrendingBetSortingChanged = (data: object): void =>
  trackEvent(dataLayer.keys.trendingBetSortingChanged, { data })

export const trackShareBet = (data: object): void => trackEvent(dataLayer.keys.shareBet, { data })
export const trackRebet = (data: object): void => trackEvent(dataLayer.keys.rebet, { data })
export const trackLoadedBetBetslip = (data: object): void =>
  trackEvent(dataLayer.keys.loadedBetBetslip, { data })

export const trackTakeABreakInformation = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakInformation, { data })
export const trackTakeABreakConfirmation = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakConfirmation, { data })
export const trackTakeABreakAlreadyActive = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakAlreadyActive, { data })
export const trackTakeABreakCancel = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakCancel, { data })
export const trackTakeABreakSubmitSelection = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakSubmitSelection, { data })
export const trackTakeABreakSubmitSucceeded = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakSubmitSucceeded, { data })
export const trackTakeABreakSubmitFailed = (data: object): void =>
  trackEvent(dataLayer.keys.takeABreakSubmitFailed, { data })

export const trackActivityStatementsSelected = (accountNumber: number) => {
  trackEvent(dataLayer.keys.activityStatementsSelected, {
    accountNumber,
  })
}

export const trackActivityStatementRequestSubmitted = (
  accountNumber: number,
  month: number,
  year: number,
  emailAddresses: string[]
) => {
  trackEvent(dataLayer.keys.activityStatementRequestSubmitted, {
    accountNumber,
    month,
    year,
    emailAddresses,
  })
}

export const trackActivityStatementRequestSuccess = (
  accountNumber: number,
  month: number,
  year: number,
  emailAddresses: string[]
) => {
  trackEvent(dataLayer.keys.activityStatementRequestSuccess, {
    accountNumber,
    month,
    year,
    emailAddresses,
  })
}

export const trackActivityStatementRequestFailure = (
  accountNumber: number,
  month: number,
  year: number,
  emailAddresses: string[]
) => {
  trackEvent(dataLayer.keys.activityStatementRequestFailure, {
    accountNumber,
    month,
    year,
    emailAddresses,
  })
}

export const trackActivityStatementsUpdateEmail = (accountNumber: number) => {
  trackEvent(dataLayer.keys.activityStatementsUpdateEmail, {
    accountNumber,
  })
}

export const trackAccountActivityTab = (data: object): void =>
  trackEvent(dataLayer.keys.accountActivityTabChange, { data })

export const trackTooltipDismissal = (tooltipKey: string) =>
  trackEvent(dataLayer.keys.tooltipDismiss, {
    tooltipKey,
  })

export interface BonusBetTrackingMetadata {
  accountNumber: string | number
  accountBalance: number | null
  bonusBetBalance: number | null
  bonusCashBalance: number | null
  [key: string]: unknown
}

export const trackBonusBetEvent = (eventName: EventKey, metadata: BonusBetTrackingMetadata) => {
  trackEvent(eventName, metadata)
}

export const trackRacePromotionLoaded = () => trackEvent(dataLayer.keys.racePromotionLoaded, {})
export const trackRacePromotionOpened = () => trackEvent(dataLayer.keys.racePromotionOpened, {})
export const trackRacePromotionClosed = () => trackEvent(dataLayer.keys.racePromotionClosed, {})
export const trackRacePromotionTermsAndConditionsClicked = () =>
  trackEvent(dataLayer.keys.racePromotionTermsAndConditionsClicked, {})

export interface HamburgerMenuTrackingMetadata {
  item: string
  [key: string]: unknown
}

export type BottomNavigationTrackingMetadata = HamburgerMenuTrackingMetadata

export const trackHamburgerMenuEvent = (
  eventName: EventKey,
  metadata: HamburgerMenuTrackingMetadata
) => {
  trackEvent(eventName, metadata)
}

/**
 * This can be co-located when we remove the bottom navigation radio toggle
 */
export const trackOnRadioToggle = (
  isRadioPlaying: boolean,
  sourceComponent: string,
  location: string
): void => {
  const eventKey = !isRadioPlaying ? dataLayer.keys.radioOn : dataLayer.keys.radioOff
  trackEvent(eventKey, { source: sourceComponent, page: location })
}
/**
 * This can be co-located when we remove the bottom navigation vision toggle
 */
export const trackOnVisionToggle = (
  isVisionPlaying: boolean,
  sourceComponent: string,
  location: string
): void => {
  const eventKey = !isVisionPlaying ? dataLayer.keys.visionOpened : dataLayer.keys.visionClosed
  trackEvent(eventKey, { source: sourceComponent, page: location })
}
export const trackRacePreviewLoaded = () => trackEvent(dataLayer.keys.racePreviewLoaded, {})
export const trackRacePreviewOpened = () => trackEvent(dataLayer.keys.racePreviewOpened, {})
export const trackRacePreviewClosed = () => trackEvent(dataLayer.keys.racePreviewClosed, {})

export const trackLoginSuccess = (
  accountNumber: string,
  keepLoggedIn: boolean,
  rememberAccount: boolean,
  isBioAuthUsed: boolean
) => {
  trackEvent(dataLayer.keys.loginSuccess, {
    accountNumber,
    keepLoggedIn,
    rememberAccount,
    isBioAuthUsed,
  })
}
export const trackLoginFailed = (accountNumber: number) => {
  trackEvent(dataLayer.keys.loginFailed, { accountNumber: accountNumber.toString() })
}
export const trackLogout = () => {
  trackEvent(dataLayer.keys.logout, { accountNumber: dataLayer.data.user.accountNumber })
  trackAccountNumber()
}
export const trackLoginForgotPassword = (accountNumber: string) =>
  trackEvent(dataLayer.keys.loginForgotPassword, { accountNumber })

export const trackLoginForgotAccountNumber = () => trackKey(dataLayer.keys.loginForgotAccountNumber)
export const trackLoginForgotAccountNumberSent = (deliveryMethod: string) =>
  trackEvent(dataLayer.keys.loginForgotAccountNumberSent, { deliveryMethod })
export const trackLoginResetPasswordSuccess = (accountNumber: string, deliveryMethod: string) =>
  trackEvent(dataLayer.keys.loginResetPasswordSucces, { accountNumber, deliveryMethod })

export const trackVideoPreviewLoaded = () => trackEvent(dataLayer.keys.videoPreviewLoaded, {})
export const trackVideoPreviewOpened = () => trackEvent(dataLayer.keys.videoPreviewOpened, {})
export const trackVideoPreviewClosed = () => trackEvent(dataLayer.keys.videoPreviewClosed, {})
export const trackVideoPreviewPlayed = () => trackEvent(dataLayer.keys.videoPreviewPlayed, {})
export const trackVideoPreviewNotSupported = (streamUrl: string, contentType: string) =>
  trackEvent(dataLayer.keys.videoPreviewNotSupported, {
    streamUrl,
    contentType,
  })
export interface BiometricsToggleMetadata {
  isEnabled: boolean
  [key: string]: unknown
}

export const trackBiometricsToggle = (eventName: EventKey, metadata: BiometricsToggleMetadata) => {
  trackEvent(eventName, metadata)
}

// ==============
// Core Functions
// ==============

export type EventKey = (typeof dataLayer.keys)[keyof typeof dataLayer.keys]

export const trackKey = (key: EventKey): void => {
  userAccountState$?.take(1).subscribe(state => {
    const accountNumber = state?.accountNumber
    window.dataLayer?.push({ event: key, accountNumber, appVersion: fullClientVersion })

    if (isReactNativeIos) {
      logFirebaseAnalyticsEvent(key, {
        accountNumber: accountNumber?.toString(),
        appVersion: fullClientVersion,
      })
    }
  })
}

export const trackEvent = (event: EventKey, data: Record<string | number, unknown>): void => {
  userAccountState$?.take(1).subscribe(state => {
    const accountNumber = state?.accountNumber
    window.dataLayer?.push({
      event,
      ...data,
      accountNumber: data?.accountNumber ?? accountNumber,
      appVersion: fullClientVersion,
    })

    if (isReactNativeIos) {
      logFirebaseAnalyticsEvent(event, {
        ...data,
        accountNumber: (data?.accountNumber ?? accountNumber)?.toString(),
        appVersion: fullClientVersion,
      })
    }
  })
}

function logFirebaseAnalyticsEvent(eventKey: string, data: Record<string | number, unknown> = {}) {
  // blacklist of events we want to drop for now, because they are too noisy
  if (eventKey === 'optimove_track') {
    return
  }

  getFeatureValue('SEND_GA_EVENTS_TO_FIREBASE').then(isEnabled => {
    if (isEnabled) {
      const flatData = flattenObjectForFirebase(data)
      sendToNative('GOOGLE_ANALYTICS_WEBVIEW_EVENT', {
        eventKey,
        data: flatData,
      })
    }
  })
}

// Firebase has a simpler parameter model than universal analytics
// it can only take simple key/value pairs, not nest objects. This
// function takes an object and flattens it as much as possible.
// Some keys still end up getting dropped because they are not supported
//
// Converts a structure that looks like this:
// {
//   accountNumber: '6347611',
//   quickbet: {
//     closed: { betPlaced: false, timestamp: '2024-07-02T06:55:25.258Z' },
//   },
// }
//
// To this:
// {
//   accountNumber: '6347611',
//   quickbet_closed_betPlaced: false,
//   quickbet_closed_timestamp: '2024-07-02T06:55:25.258Z'
// }
//
function flattenObjectForFirebase(
  data: Record<string | number, unknown>
): Record<string, string | number | boolean> {
  return flattenObjectRecursive(data, '', {})
}

function flattenObjectRecursive(
  obj: Record<string | number, unknown>,
  prefix: string,
  result: Record<string, string | number | boolean>
) {
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj[key]
    const keyWithPrefix = prefix ? `${prefix}_${key}` : key

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      if (typeof value === 'string' && (value.length > 64 || value === '')) {
        // empty strings, or strings that are too long shouldn't be logged
        continue
      }
      result[keyWithPrefix] = value
    } else if (typeof value === 'object' && typeof key == 'string' && !!value) {
      flattenObjectRecursive(value as Record<string, unknown>, keyWithPrefix, result)
    }
  }

  return result
}
