import React from 'react'
import { useParams } from 'react-router-dom'
import { PlanSeq } from '@mobi/api-types'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import {
  formatDateTime,
  getTicketLayoutType,
  isTippingTicket,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import {
  DetailsEventStartNoticeStyled as EventStartNotice,
  DetailsSecondaryInfoStyled as SecondaryInfo,
  DetailsStyled,
} from './Details.styles'
import { TicketInvestment } from './Components/Investment/Investment'
import { Specials } from './Components/Specials/Specials'
import { SelectionInfo } from './Components/SelectionInfo/SelectionInfo'
import { BonusBet } from './Components/BonusBet/BonusBet'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import {
  BettingInformationOverrides,
  FixedOddsMarketDisplayNames,
} from '@core/Areas/RaceCard/constants'

export const Details: React.FC<DetailsProps> = ({
  ticket,
  detailsWrapperRef,
  detailsContentRef,
}) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()
  const ticketDisplayContext = React.useContext(TicketDisplayContext)

  const isPreviewMode = ticketDisplayContext.type === 'share-preview'
  const shouldHideCost = isPreviewMode && ticketDisplayContext.data.shouldHideCost

  const selection = ticket.legs[0].selections[0]

  if (!selection || selection.selectionDetails.length === 0) return null

  const ticketLayoutType = getTicketLayoutType(ticket)
  const isFavouriteNumbers = ticket.planSeq === PlanSeq.FavouriteNumbers
  const isSameRaceMulti = ticketLayoutType === 'same-race-multi'

  const shouldHideStartTime =
    isFavouriteNumbers || isTippingTicket(ticket) || (ticket.legs.length > 1 && !isSameRaceMulti)
  const shouldDisplayNewStartTime: boolean =
    activeTab === 'pending' &&
    !!ticket.updatedEventStartDateTime &&
    ticket.eventStartDateTime !== ticket.updatedEventStartDateTime

  return (
    <DetailsStyled
      ref={detailsWrapperRef}
      isPreviewMode={isPreviewMode}
      data-testid='ticket-detail'
    >
      <div ref={detailsContentRef}>
        <SelectionInfo ticket={ticket} />

        {!isPreviewMode && (
          <SecondaryInfo data-testid='ticket-detail-secondary-info'>
            <dl>
              {!shouldHideStartTime && (
                <div data-testid='ticket-detail-start-time'>
                  <dt>Start Time:</dt>
                  <dd>
                    {formatDateTime(ticket.updatedEventStartDateTime)}
                    {shouldDisplayNewStartTime && <EventStartNotice> New</EventStartNotice>}
                  </dd>
                </div>
              )}
              <div data-testid='ticket-detail-bet-placed'>
                <dt>Bet Placed:</dt>
                <dd>{formatDateTime(ticket.timestamp)}</dd>
              </div>
              <div data-testid='ticket-detail-ticket-id'>
                <dt>Ticket ID:</dt>
                <dd>{ticket.ticketSequence}</dd>
              </div>
            </dl>

            {ticket.bettingInformation && !isSameRaceMulti && (
              <div data-testid='ticket-detail-betting-information'>
                {getBettingInformationWithOverrides(ticket)?.map(b => (
                  <p key={b.title}>
                    {b.title}: {b.detail.join('. ')}
                  </p>
                ))}
                {ticket.specialInformation?.map(s => (
                  <p key={s.title}>
                    {s.title} : {s.description}
                  </p>
                ))}
              </div>
            )}
          </SecondaryInfo>
        )}

        {ticket.specialInformation && (
          <Specials specials={ticket.specialInformation} shouldHideCost={shouldHideCost} />
        )}

        {!shouldHideCost && (
          <>
            <TicketInvestment ticket={ticket} />
            <BonusBet investment={ticket.investment} />
          </>
        )}
      </div>
    </DetailsStyled>
  )
}

// =============
// Local Helpers
// =============

function getBettingInformationWithOverrides(ticket: Ticket) {
  if (ticket.productName === FixedOddsMarketDisplayNames.SP) {
    return BettingInformationOverrides.StartingPrice
  } else {
    return ticket.bettingInformation
  }
}

// =====
// Types
// =====

type DetailsProps =
  | {
      ticket: Ticket
      detailsWrapperRef: React.RefObject<HTMLDivElement>
      detailsContentRef: React.RefObject<HTMLDivElement>
      isPreviewMode?: never
    }
  // Preview
  | {
      ticket: Ticket
      detailsWrapperRef?: never
      detailsContentRef?: never
    }
