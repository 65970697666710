import { RootState } from '@core/Store'

export const getSelectedProposition = ({ raceCard }: RootState) => raceCard.selectedProposition

export const getCurrentBetType = ({ raceCard }: RootState) => raceCard.currentBetType

export const getToteSwapOptions = ({ raceCard }: RootState) => raceCard.toteSwapState[0]
export const getActiveToteSwap = ({ raceCard }: RootState) => raceCard.toteSwapState[1] || null

export const getCurrentRacePageQueryKey = ({ raceCard }: RootState) =>
  raceCard.currentRacePageQueryKey
