import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { ObservableStarter } from '../../../Model/Observables/ObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { IMultiSelectionPriceViewModel } from './IMultiSelectionPriceViewModel'
import template from './multselection.pricing.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('multiselection-pricing')) {
    ko.components.register('multiselection-pricing', {
      viewModel: {
        createViewModel: (params: {
          starter: ObservableStarter
          bettingInformation: BettingInformation
        }): IMultiSelectionPriceViewModel => {
          const viewModel = container.get<IMultiSelectionPriceViewModel>(
            'IMultiSelectionPriceViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
