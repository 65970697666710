import React from 'react'
import dayjs from 'dayjs'

import { ToteSportsGame } from '../../data/toteSports'

import {
  AbandonedPillStyled,
  DrawPillStyled,
  GameGroupStyled,
  GameHeaderStyled,
  TeamNameContainerStyled,
  TeamNameStyled,
} from './Games.styles'

interface ClosedGameProps {
  game: ToteSportsGame
  gameIndex: number
}

export const ClosedGame = ({ game, gameIndex }: ClosedGameProps): JSX.Element => {
  const isAbandoned = game.result === 'Abandoned'
  return (
    <GameGroupStyled>
      <GameHeaderStyled data-tid-tote-sport-game-header>
        <strong>Game {gameIndex + 1} </strong>
        {dayjs(game.startTime).format('ddd, DD MMM YYYY HH:mm')}{' '}
        {!game.isDrawSupported && game.result === 'Draw' && <DrawPillStyled>Draw</DrawPillStyled>}{' '}
        {isAbandoned && <AbandonedPillStyled>Abandoned</AbandonedPillStyled>}
      </GameHeaderStyled>

      <TeamNameContainerStyled>
        <TeamNameStyled isSelected={game.result === 'HomeWin'} isAbandoned={isAbandoned}>
          {game.homeTeamName}
        </TeamNameStyled>
        {game.isDrawSupported && (
          <TeamNameStyled
            isSelected={game.result === 'Draw'}
            maxWidth='6.5rem'
            isAbandoned={isAbandoned}
          >
            Draw
          </TeamNameStyled>
        )}
        <TeamNameStyled isSelected={game.result === 'AwayWin'} isAbandoned={isAbandoned}>
          {game.awayTeamName}
        </TeamNameStyled>
      </TeamNameContainerStyled>
    </GameGroupStyled>
  )
}
