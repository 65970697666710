import produce from 'immer'
import ObservableRacePage from '../../../../Model/Observables/ObservableRacePage'
import { TotePriceChangedPushEvent } from '@core/State/PushData'
import { toDisplayPrice } from '@mobi/utils/money'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import { queryKeys } from '@core/Data/ReactQuery/constants'

interface PushTotePriceChanged {
  event: TotePriceChangedPushEvent
  meetingId: string
  meetingDate: Date
  raceNumber: number
  raceInformation: ObservableRacePage
}

export function pushTotePriceChanged({
  event,
  meetingId,
  meetingDate,
  raceNumber,
  raceInformation,
}: PushTotePriceChanged) {
  const queryKey = queryKeys.racePageComplete(meetingId, meetingDate, raceNumber)

  // Update ReactQuery state
  queryClient.setQueryData<RacePageDTO | undefined>(queryKey, current => {
    if (!current) return undefined // key not found
    if (!isRacePageDto(current)) return undefined // only update open races
    return produce(current, draft => {
      const acceptorLookup = new Map(event.acceptorTotePools.map(p => [p.acceptorNumber, p]))
      draft.RaceStarters.flatMap(rs =>
        rs.RaceKey.RaceNumber === raceNumber ? rs.Starters : []
      ).forEach(starter => {
        const update = acceptorLookup.get(starter.Number)
        if (update) {
          starter.DisplayWinDividend = toDisplayPrice(update.winPrice)
          starter.DisplayPlaceDividend = toDisplayPrice(update.placePrice)
        }
        starter.IsFavourite = event.raceFavourite === starter.Number
      })
    })
  })

  // Update KO View Model state
  const starters = raceInformation.getStartersForRace(raceNumber)()

  event.acceptorTotePools.forEach(({ acceptorNumber, winPrice, placePrice }) => {
    const starter = starters.find(starter => starter.number() === acceptorNumber)
    if (starter) {
      if (winPrice !== null && winPrice !== undefined) {
        starter.displayWinDividend(toDisplayPrice(winPrice))
      }
      if (placePrice !== null && placePrice != undefined) {
        starter.displayPlaceDividend(toDisplayPrice(placePrice))
      }
      starter.isFavourite(starter.number() === event.raceFavourite)
    }
  })
}
