import React from 'react'
import { KeypadButtonStyled } from './KeypadButton.styles'
import { Icon } from '@mobi/component-library/Common/Icon'

interface KeypadButtonProps {
  clickHandler: () => void
  disabled: boolean
  label: number | string
  ariaLabel?: string
}

export function KeypadButton({
  clickHandler,
  disabled,
  label,
  ariaLabel,
}: KeypadButtonProps): JSX.Element {
  const icon = label === '\u232B' ? <Icon type='backspace' /> : label
  const testId = { 'data-tid-keypad-key': label === '\u232B' ? 'backspace' : label }

  return (
    <KeypadButtonStyled
      onClick={clickHandler}
      disabled={disabled}
      color='secondary'
      aria-label={ariaLabel}
      {...testId}
    >
      {icon}
    </KeypadButtonStyled>
  )
}
