import { ProvDivPoolDataTransferObject } from '@mobi/api-types'
import { ObservableStarter } from '../Model/Observables/ObservableStarter'
import ObservableProvDivPool from '../Model/Observables/ObservableProvDivPool'

export default class ProvDivPoolMapper {
  public static map(dtos: ProvDivPoolDataTransferObject[], model: ObservableStarter) {
    var pools: ObservableProvDivPool[] = []

    dtos.forEach(poolDto => {
      var pool = new ObservableProvDivPool()
      pool.name = poolDto.Name
      pool.quaddieProvDivLegNums = poolDto.QuaddieProvDivLegNums

      poolDto.ProvDivs.forEach(provDiv => {
        //Add prov div (trust server to send correct dividend or string value - eg. C/O)
        pool.addProvDiv(provDiv.Div, provDiv.Outcome)
      })

      pools.push(pool)
    })

    //Add all at end to avoid updating UI constantly
    model.provDivPools(pools)
  }
}
