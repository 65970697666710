import React from 'react'
import Autosuggest, { type ChangeEvent } from 'react-autosuggest'
import { Signal, createSignal, attachDriver } from 'rwwa-rx-state-machine'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { SuggestionStyled, SuggestionsContainerStyled } from './Autocomplete.styles'
import { InputBoxStyled, ValidationErrorStyled } from '@mobi/component-library/Common/Input'

export interface AutocompleteProps<TSuggestion> {
  id: string
  inputProps: Partial<Autosuggest.InputProps<TSuggestion>>
  selection: TSuggestion | null
  errorMessage: string | boolean
  autoFocus?: boolean
  backgroundColor?: string
  placeholder?: string
  fontFamily?: string
  disabled?: boolean
  getSuggestionValue: Autosuggest.GetSuggestionValue<TSuggestion>
  getSuggestionsForInputValue: (inputValue: string) => TSuggestion[] | Promise<TSuggestion[]>
  onSelection: (suggestion: TSuggestion | null) => void
}

interface AutocompleteState<TSuggestion> {
  inputValue: string
  selection: TSuggestion | null
  suggestions: TSuggestion[]
}

interface AutocompleteRecord<TSuggestion>
  extends TypedRecord<AutocompleteRecord<TSuggestion>>,
    AutocompleteState<TSuggestion> {}

export class Autocomplete<T> extends React.Component<AutocompleteProps<T>, AutocompleteState<T>> {
  private defaultState: AutocompleteState<T> = {
    inputValue: '',
    selection: null,
    suggestions: [],
  }

  private autocomplete$: Rx.Observable<AutocompleteRecord<T>> | undefined
  private sub: Rx.IDisposable | undefined

  private ChangeInput = createSignal<string>(`ChangeInput-${this.props.id}`)
  private ClearSuggestions = createSignal(`ClearSuggestions-${this.props.id}`)
  private GetSuggestions = createSignal<string>(`GetSuggestions-${this.props.id}`)
  private SetSuggestions = createSignal<T[]>(`SetSuggestions-${this.props.id}`)
  private ChangeSelection = createSignal<T | null>(`ChangeSelection-${this.props.id}`)
  private BlurInput = createSignal<T | null>(`BlurInput-${this.props.id}`)
  private Initialize = createSignal<T | null>(`Initialize-${this.props.id}`)
  private Update = createSignal<T | null>(`Update-${this.props.id}`)

  constructor(props: AutocompleteProps<T>) {
    super(props)
    this.state = this.defaultState
  }

  public componentDidMount(): void {
    this.autocomplete$ = attachDriver({
      driver: this.driver,
      path: `autocomplete-${this.props.id}`,
    })
    this.sub = this.autocomplete$.subscribe(x => {
      this.setState({
        inputValue: x.inputValue,
        suggestions: x.suggestions,
        selection: (x.toJS() as AutocompleteState<T>).selection, // toJS unwraps the `selection: Immutable.Map<T | null>` into just `selection: T | null`
      })
    })

    this.Initialize(this.props.selection)
  }

  public componentDidUpdate(prevProps: AutocompleteProps<T>): void {
    if (this.props.selection !== prevProps.selection) {
      this.Update(this.props.selection)
    }
  }

  public componentWillUnmount(): void {
    if (this.sub) {
      this.sub.dispose()
    }
  }

  public render(): JSX.Element {
    const Auto = Autosuggest as unknown as React.JSXElementConstructor<Record<string, unknown>>

    const { inputValue, suggestions } = this.state
    const {
      autoFocus,
      errorMessage,
      backgroundColor,
      placeholder,
      fontFamily,
      disabled,
      ...autosuggestProps
    } = this.props

    const mergedProps = {
      ...autosuggestProps,
      focusInputOnSuggestionClick: false, // don't restore focus as it would show keyboard on mobile
      suggestions,
      inputProps: {
        ...this.props.inputProps,
        value: inputValue || '', // can't be null, complains about changing from controlled to uncontrolled component
        type: 'search',
        onChange: (event: ChangeEvent, changeEvent: ChangeEvent) => {
          this.ChangeInput(changeEvent.newValue)
          if (this.props.inputProps.onChange) {
            // @ts-expect-error Library type issue
            this.props.inputProps.onChange(event, changeEvent)
          }
        },
        // @ts-expect-error Library type issue
        onBlur: (event: React.FocusEvent<unknown, Element>, { highlightedSuggestion }) => {
          this.BlurInput(highlightedSuggestion)
          if (this.props.inputProps.onBlur) {
            this.props.inputProps.onBlur(event)
          }
        },
      },
      onSuggestionsFetchRequested: ({ value }: Record<string, string>) =>
        this.GetSuggestions(value),
      onSuggestionsClearRequested: () => this.ClearSuggestions(),
      // @ts-expect-error Library type issue
      onSuggestionSelected: (_, { suggestion }) => this.ChangeSelection(suggestion),
      // @ts-expect-error Library type issue
      renderSuggestion: (suggestion, { isHighlighted }) => (
        <SuggestionStyled isHighlighted={isHighlighted}>
          {this.props.getSuggestionValue(suggestion)}
        </SuggestionStyled>
      ),
      renderInputComponent: (propsForInput: Record<string, unknown>) => (
        <InputBoxStyled
          {...propsForInput}
          error={errorMessage}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          style={{ backgroundColor, fontFamily }}
        />
      ),
      // @ts-expect-error Library type issue
      renderSuggestionsContainer: ({ containerProps, children }) => (
        <SuggestionsContainerStyled {...containerProps}>{children}</SuggestionsContainerStyled>
      ),
    }

    return (
      <div>
        <Auto {...mergedProps} />
        {suggestions.length === 0 && errorMessage && (
          <ValidationErrorStyled>{errorMessage}</ValidationErrorStyled>
        )}
      </div>
    )
  }

  private driver: (state: AutocompleteRecord<T>, signal: Signal) => AutocompleteRecord<T> = (
    state = recordify<AutocompleteState<T>, AutocompleteRecord<T>>(this.defaultState),
    signal: Signal
  ) => {
    switch (signal.tag) {
      case this.ClearSuggestions: {
        return state.set('suggestions', [])
      }

      case this.SetSuggestions: {
        return state.set('suggestions', signal.data)
      }

      case this.GetSuggestions: {
        const suggestions = this.props.getSuggestionsForInputValue(state.inputValue)
        if (suggestions instanceof Promise) {
          suggestions
            .then(results => this.SetSuggestions(results))
            .catch(() => {
              /* leave suggestions as they are */
            })
          return state
        }

        return state.set('suggestions', suggestions)
      }

      case this.ChangeInput: {
        // set the input value and remove any existing selection
        if (state.get('selection') !== null) {
          this.props.onSelection(null)
          const newInputValue = getInputDifference(state.get('inputValue'), signal.data)
          return state.merge({
            inputValue: newInputValue,
            selection: null,
          })
        }
        return state.merge({
          inputValue: signal.data,
        })
      }

      case this.BlurInput: {
        const hasSelection = state.get('selection') !== null
        const highlightedSuggestion = signal.data

        if (hasSelection && !highlightedSuggestion) {
          return state
        }

        if (highlightedSuggestion) {
          this.props.onSelection(highlightedSuggestion)
          return state.set('selection', highlightedSuggestion)
        }

        this.props.onSelection(null)
        return state.merge({
          inputValue: '',
          selection: null,
        })
      }

      case this.ChangeSelection: {
        this.props.onSelection(signal.data)
        return state.set('selection', signal.data)
      }

      case this.Update: {
        const selection: T | null = signal.data

        if (!selection) {
          return state
        }

        return state.merge({
          selection,
          inputValue: selection ? this.props.getSuggestionValue(selection) : '',
        })
      }

      case this.Initialize: {
        const selection: T | null = signal.data

        return state.merge({
          selection,
          inputValue: selection ? this.props.getSuggestionValue(selection) : '',
        })
      }

      default:
        return state
    }
  }
}

function getInputDifference(inputValuePrevious: string, inputValue: string) {
  const previousInput: string[] = inputValuePrevious.split('')
  const currentInput: string[] = inputValue.split('')

  if (currentInput.length >= previousInput.length) {
    return currentInput.reduce(
      (acc, val, idx) => (val !== previousInput[idx] && acc === '' ? val : acc),
      ''
    )
  }
  return ''
}
