import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { BetInvestmentGroupStyled } from '@core/Areas/Betslip/Components/BetInvestment/BetInvestment.styles'
import {
  MultiDetailsStyled,
  MultiDetailsAmountStyled,
} from '@core/Areas/Betslip/Components/MultiItemList/MultiItemList.styles'

export const MultiFormulaExpandablePanelStyled = styled('div')({
  borderTop: `0.1rem solid ${colors.betslip.formulas.border}`,
  marginTop: '0.25rem',
})

export const FormulaTitleTextStyled = styled('strong')({
  fontSize: '1.2rem',
  display: 'block',
  color: colors.betslip.formulas.text,
})

export const FormulaContainerStyled = styled('div')({
  marginTop: '0.5rem',
})

export const FormulaItemStyled = styled('div')({
  marginBottom: '0.5rem',
  padding: '0.5rem 0',
  borderBottom: `0.1rem solid ${colors.betslip.item.border}`,

  '&:last-child': {
    borderBottom: 'none',
    marginBottom: 0,
  },
})

export const FormulaComboTextStyled = styled(MultiDetailsStyled)({
  padding: 0,
})

export const FormulaTextStyled = MultiDetailsAmountStyled

export const FormulaInvestmentStyled = styled(BetInvestmentGroupStyled)({
  justifyContent: 'flex-end',
})

export const FormulaCostContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const FormulaCostChildStyled = styled('div')<{ isQuickEdit?: boolean }>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    lineHeight: 1,
    fontSize: '1rem',
    height: '1.5rem',
    width: '33%',
    marginTop: '-0.2rem',
  },
  ({ isQuickEdit }) => ({
    color: isQuickEdit ? colors.quickbet.header.text : colors.betslip.multiDetails.label.color,
  })
)

export const FormulaCostAmountStyled = styled('span')<{ isQuickEdit?: boolean }>(
  {
    fontSize: '1.2rem',
  },
  ({ isQuickEdit }) => ({
    color: isQuickEdit ? colors.quickbet.header.text : colors.betslip.formulas.text,
  })
)
