import React from 'react'
import { SvgIconStyled } from '@mobi/component-library/Common/Icon/Icon.styles'

export function TabRadioIcon() {
  return (
    <SvgIconStyled viewBox='0 0 20 20' size='1em'>
      <g fill='currentColor'>
        <path d='M2.4142 13.9053C2.4142 13.8214 2.38687 13.7002 2.2138 13.7002H1.22091C1.06605 13.7002 1.02051 13.7841 1.02051 13.9053V18.0988C1.02051 18.22 1.06605 18.3038 1.22091 18.3038H2.22291C2.38687 18.3038 2.4142 18.192 2.4142 18.0988V15.4615C2.85144 15.3031 3.37066 15.1633 3.9081 15.0608C4.18137 15.0049 4.18137 14.949 4.16315 14.7812L4.05384 13.868C4.02651 13.635 3.94453 13.6257 3.75324 13.6536C3.37066 13.7189 2.76035 14.0264 2.43242 14.3712L2.4142 14.3526V13.9053Z' />
        <path d='M6.76957 16.9898C6.51451 17.1483 6.09549 17.316 5.81311 17.316C5.5034 17.316 5.4032 17.2228 5.4032 16.9339C5.4032 16.4959 5.61271 16.3655 5.94975 16.3655H6.76957V16.9898ZM6.92442 17.754C7.04284 18.2479 7.41631 18.3597 7.86266 18.3597C8.05395 18.3597 8.24524 18.3411 8.42742 18.2945C8.6005 18.2572 8.69159 18.192 8.69159 18.0522V17.4558C8.69159 17.344 8.65515 17.3067 8.5003 17.2974C8.21791 17.2601 8.16326 17.2415 8.16326 16.8501V15.3777C8.16326 14.2687 7.92642 13.6164 6.21391 13.6164C5.79489 13.6164 5.05705 13.6816 4.64714 13.84C4.36476 13.9519 4.32832 14.045 4.35565 14.2035L4.45585 14.6881C4.5014 14.8744 4.54694 14.8744 4.802 14.8465C5.15725 14.8092 5.73113 14.7626 5.99529 14.7626C6.66937 14.7626 6.79689 14.921 6.79689 15.3124V15.4615H5.74935C4.67447 15.4615 3.98218 15.8622 4.0004 17.0085C4.0004 17.7167 4.20991 18.3784 5.27567 18.3784C5.73113 18.3784 6.43253 18.2013 6.9062 17.7447L6.92442 17.754Z' />
        <path d='M12.9022 18.1081V11.9389C12.9022 11.8458 12.884 11.7246 12.7109 11.7246H11.718C11.5632 11.7246 11.5267 11.8178 11.5267 11.9389V13.7468C11.5176 13.7468 11.5176 13.7562 11.5176 13.7562C11.2443 13.6723 10.9437 13.607 10.5338 13.607C8.97618 13.607 8.68469 14.5855 8.68469 16.0673C8.68469 17.3999 8.96707 18.3784 10.2059 18.3784C10.634 18.3784 11.0986 18.1734 11.4994 17.8658C11.5085 17.8658 11.5176 17.8751 11.5267 17.8751V18.1174C11.5267 18.2386 11.5632 18.3038 11.7089 18.3038H12.7109C12.8749 18.3038 12.9022 18.2013 12.9022 18.1081ZM11.5176 16.8128C11.3081 16.9153 10.9255 17.0924 10.6431 17.0924C10.2697 17.0924 10.0966 16.9339 10.0966 16.0207C10.0966 15.0608 10.2879 14.8278 10.8071 14.8278C10.9984 14.8278 11.1988 14.8465 11.5176 14.9303V16.8128Z' />
        <path d='M14.7945 12.4888C14.7945 12.0881 14.6669 11.7992 14.0749 11.7992C13.4919 11.7992 13.3461 12.1067 13.3461 12.4888C13.3461 12.8243 13.4372 13.1877 14.0749 13.1877C14.6852 13.1877 14.7945 12.8429 14.7945 12.4888ZM14.7489 13.9053C14.7489 13.8214 14.7216 13.7002 14.5667 13.7002H13.5647C13.4099 13.7002 13.3643 13.7748 13.3643 13.9053V18.0988C13.3643 18.22 13.4099 18.3038 13.5647 18.3038H14.5667C14.7216 18.3038 14.7489 18.192 14.7489 18.0988V13.9053Z' />
        <path d='M19.4159 15.9368C19.4159 14.8558 19.1335 13.6164 17.2571 13.6164C15.3897 13.6164 15.1711 15.0888 15.1711 15.9368C15.1711 16.9526 15.2986 18.3784 17.2571 18.3784C19.2611 18.3784 19.4159 16.9526 19.4159 15.9368ZM17.9949 15.9927C17.9949 16.7848 17.8674 17.1669 17.2571 17.1669C16.7105 17.1669 16.5739 16.8035 16.5739 15.9927C16.5739 15.2379 16.7196 14.8278 17.2753 14.8278C17.8856 14.8278 17.9949 15.2099 17.9949 15.9927Z' />
        <path d='M12.5555 3.97517L12.1745 2.52295C12.0578 2.07436 11.5601 1.72461 11.0703 1.72461H7.97539L5.65035 11.1374H7.71101C8.02205 11.1374 8.33309 10.9093 8.3953 10.628L8.71412 9.32026H11.2336L12.5555 3.97517ZM9.21956 7.3206L9.8883 4.62145H10.106L10.7592 7.3206H9.21956Z' />
        <path d='M18.7375 5.95202C19.2896 5.54905 19.6239 4.88756 19.6239 4.20327C19.6239 2.6294 18.5197 1.73221 16.5913 1.73221H13.6597L12.3844 6.91003V10.1642C12.3844 10.6736 12.5011 11.145 13.372 11.145H16.6768C18.613 11.145 20.0205 9.91331 20.0205 8.21018C20.0205 7.20655 19.5462 6.3702 18.7375 5.95202ZM14.7794 3.82311H16.4435C16.9101 3.82311 17.1745 4.05121 17.1745 4.45418C17.1745 4.91798 16.9101 5.15368 16.3813 5.15368H14.7872V3.82311H14.7794ZM16.4824 9.03133H14.7794V7.10771H16.4824C17.1589 7.10771 17.5633 7.47267 17.5633 8.07332C17.5633 8.30142 17.4855 9.03133 16.4824 9.03133Z' />
        <path d='M5.09827 11.1374L5.5104 9.47232V3.99038H6.86343L7.42331 1.72461H0.518175C0.168253 1.72461 0.0205078 1.86147 0.0205078 2.1808V3.53418C0.0205078 3.82311 0.199357 3.98277 0.518175 3.98277H2.81989V10.666C2.81989 10.9702 3.01429 11.1298 3.37199 11.1298H5.09827V11.1374Z' />
      </g>
    </SvgIconStyled>
  )
}
