import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import {
  changePasswordSubmit,
  changeTemporaryPasswordDesktopSubmit,
} from '../../Data/Account/changePassword'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'
import {
  UpdateNewPassword,
  UpdateConfirmPassword,
  isPasswordValid,
  CreatePassword,
  Reset as ResetPassword,
} from '../Password/driver'

export interface ChangePasswordState {
  accountNumber: string | null
  new: string | null
  confirm: string | null
  existing: string | null
  validationError: string | false
  isChangePasswordSuccess: boolean
  isChangeTemporaryPasswordSuccess: boolean
  isCurrentPasswordVisible: boolean
  isChangePasswordSubmitEnabled: boolean
}

export const defaultChangePasswordState: ChangePasswordState = {
  accountNumber: null,
  new: null,
  confirm: null,
  existing: null,
  validationError: false,
  isChangePasswordSuccess: false,
  isChangeTemporaryPasswordSuccess: false,
  isCurrentPasswordVisible: false,
  isChangePasswordSubmitEnabled: false,
}

export const ChangePasswordSubmit = createSignal<{
  accountNumber: string
  form: HTMLFormElement
  isDesktop?: boolean
  isChangeTemporaryPassword?: boolean
}>('ChangePasswordSubmit')
export const ChangePasswordSucceeded = createSignal<HTMLFormElement>('ChangePasswordSucceeded')
export const ChangeTemporaryPasswordSucceeded = createSignal<{
  form: HTMLFormElement
  isDesktop?: boolean
}>('ChangeTemporaryPasswordSucceeded')
export const ChangePasswordFailed = createSignal<string>('ChangePasswordFailed')
export const UpdateCurrentPassword = createSignal('UpdateCurrentPassword')
export const IsCurrentPasswordVisible = createSignal('IsCurrentPasswordVisible')
export const Reset = createSignal('Reset')
export const ResetForm = createSignal<HTMLFormElement>('ResetForm')

export interface ChangePasswordStateRecord
  extends TypedRecord<ChangePasswordStateRecord>,
    ChangePasswordState {}
export const ChangePasswordStateFactory = makeTypedFactory<
  ChangePasswordState,
  ChangePasswordStateRecord
>(defaultChangePasswordState)

export function changePasswordDriver(
  state = ChangePasswordStateFactory(),
  signal: Signal
): ChangePasswordStateRecord {
  switch (signal.tag) {
    case UpdateNewPassword: {
      const newPassword = signal.data
      const newState = state.merge({
        new: newPassword,
      })

      return newState.merge({
        isChangePasswordSubmitEnabled: isChangePasswordSubmitEnabled(newState),
      })
    }

    case UpdateConfirmPassword: {
      const confirmPassword = signal.data
      const newState = state.merge({
        confirm: confirmPassword,
      })

      return newState.merge({
        isChangePasswordSubmitEnabled: isChangePasswordSubmitEnabled(newState),
      })
    }

    case UpdateCurrentPassword: {
      const existingPassword = signal.data
      const newState = state.merge({
        existing: existingPassword,
      })

      return newState.merge({
        isChangePasswordSubmitEnabled: isChangePasswordSubmitEnabled(newState),
      })
    }

    case IsCurrentPasswordVisible: {
      return state.merge({
        isCurrentPasswordVisible: !state.get('isCurrentPasswordVisible'),
      })
    }

    case ChangePasswordSubmit: {
      const { accountNumber, form, isDesktop, isChangeTemporaryPassword } = signal.data
      const newPassword = state.get('new')
      const confirmPassword = state.get('confirm')
      const existingPassword = state.get('existing')
      const isNewPasswordValid = isPasswordValid(newPassword)
      const isNewAndConfirmPasswordSame = newPassword === confirmPassword

      CreatePassword()

      if (isNewPasswordValid && isNewAndConfirmPasswordSame) {
        if (isChangeTemporaryPassword && isDesktop) {
          changeTemporaryPasswordDesktop({ accountNumber, existingPassword, newPassword, form })
        } else {
          changePassword({
            accountNumber,
            existingPassword,
            newPassword,
            confirmPassword,
            form,
            isDesktop,
            isChangeTemporaryPassword,
          })
        }
      }

      return state
    }

    case ChangePasswordSucceeded: {
      const form = signal.data

      ResetForm(form)

      scrollToTop()

      return state.merge({
        isChangePasswordSuccess: true,
      })
    }

    case ChangeTemporaryPasswordSucceeded: {
      const { form, isDesktop } = signal.data

      ResetForm(form)

      scrollToTop()

      if (isDesktop) {
        navigateHome()
      }

      return state.merge({
        isChangeTemporaryPasswordSuccess: true,
      })
    }

    case ChangePasswordFailed: {
      const validationError = signal.data

      scrollToTop()

      return state.merge({
        isChangePasswordSuccess: false,
        validationError,
      })
    }

    case Reset: {
      // On leaving the page, reset both change passsword and new password component states
      ResetPassword()

      return state.merge({
        ...defaultChangePasswordState,
      })
    }

    case ResetForm: {
      const form: HTMLFormElement = signal.data

      // Reset the form and UI state
      if (form) {
        form.reset()

        ResetPassword() // reset the new password component state

        return state.merge({
          isChangePasswordSubmitEnabled: false,
          isCurrentPasswordVisible: false, // reset current password visible toggle
          new: null,
          confirm: null,
          existing: null,
        })
      }

      return state
    }

    default: {
      return state
    }
  }
}

interface ChangePassword {
  accountNumber: string
  existingPassword: string
  newPassword: string
  confirmPassword: string
  form: HTMLFormElement
  isDesktop: boolean
  isChangeTemporaryPassword: boolean
}

export function changePassword({
  accountNumber,
  existingPassword,
  newPassword,
  confirmPassword,
  form,
  isDesktop,
  isChangeTemporaryPassword,
}: ChangePassword): void {
  changePasswordSubmit({ accountNumber, existingPassword, newPassword, confirmPassword }, isDesktop)
    .then(resp => {
      if (resp.isChangePasswordSuccess) {
        isChangeTemporaryPassword
          ? ChangeTemporaryPasswordSucceeded({ form })
          : ChangePasswordSucceeded(form)
      } else {
        ChangePasswordFailed(resp.error)
      }
    })
    .catch(() => {
      ChangePasswordFailed('Sorry, something went wrong. Please try again.')
    })
}

interface ChangeTemporaryPasswordDesktop {
  accountNumber: string
  existingPassword: string
  newPassword: string
  form: HTMLFormElement
}

export function changeTemporaryPasswordDesktop({
  accountNumber,
  existingPassword,
  newPassword,
  form,
}: ChangeTemporaryPasswordDesktop): void {
  changeTemporaryPasswordDesktopSubmit({
    betAccountId: accountNumber,
    existingPassword,
    newPassword,
  })
    .then(resp => {
      if (resp.isChangePasswordSuccess) {
        ChangeTemporaryPasswordSucceeded({ form, isDesktop: true })
      } else {
        ChangePasswordFailed(resp.error)
      }
    })
    .catch(() => {
      ChangePasswordFailed('Sorry, something went wrong. Please try again.')
    })
}

function isChangePasswordSubmitEnabled(state: ChangePasswordStateRecord) {
  const hasNewPassword = !!state.get('new')
  const hasConfirmPassword = !!state.get('confirm')
  const hasCurrentPassword = !!state.get('existing')

  return hasNewPassword && hasConfirmPassword && hasCurrentPassword
}

function scrollToTop() {
  if (window && window.scrollTo) {
    window.scrollTo(0, 0)
  }
}

export const state$ = attachDriver<ChangePasswordStateRecord>({
  path: 'changePassword',
  driver: changePasswordDriver,
})
