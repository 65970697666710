import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'

export const getRaceLink = ({
  meetingId,
  raceNumber,
  meetingDate,
  code,
  meetingSeq,
  product,
  raceSeq,
  isFOO,
}: {
  meetingDate: string
  meetingId?: string
  raceNumber?: string | number
  code?: string
  meetingSeq?: string | number
  product?: string
  raceSeq?: string | number
  isFOO?: boolean
}): string => {
  const date = formatDateStringWithoutChangingTimezone(meetingDate, 'YYYY-MM-DD')
  return isFOO
    ? `/fobracing/propositions/${product}/${code}/${raceSeq}?selectionDate=${date}&meetingSeq=${meetingSeq}`
    : `/tote/meetings/${meetingId}/${raceNumber}?date=${date}`
}
