import React from 'react'
import { OverlayClose } from '@core/Components/Overlay'
import {
  SearchOverlayStyled,
  SearchOverlayHeaderStyled,
  CloseButtonStyled,
  SearchResultsContainerStyled,
} from './Search.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'
import { SearchField } from './Components/Input/SearchField'
import { SearchService, isResultAnError } from './Service/SearchService'
import { SearchResult } from '@core/Data/Search/Search'
import { SearchResultsContainer } from './Components/SearchResultsContainer'
import { deregisterBodyScrollLock, registerBodyScrollLock } from '@mobi/utils/functions'
import { useFeature } from '@core/Utils/hooks'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'

const SEARCH_PREVIOUS_TERM = 'search_previous_terms'
const MAX_PREVIOUS_SEARCH_TERMS = 10

export function Search(): JSX.Element {
  const containerElementRef = React.useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isErrored, setIsErrored] = React.useState(false)
  const [searchPerformed, setSearchPerformed] = React.useState(false)
  const [performSearch, setPerformSearch] = React.useState(false)
  const [searchResults, setSearchResults] = React.useState<SearchResult[]>([])
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const searchUnmatchedRacing = useFeature('SEARCH_UNMATCHED_RACING')
  const [previousSearches, setPreviousSearches] = React.useState(
    getPreviousSearchesFromLocalStorage()
  )

  React.useEffect(() => {
    const subscription = navigationState$
      .map(navState => navState.toJS().currentUrl)
      .skip(1)
      .distinctUntilChanged()
      .subscribe(handleCloseButtonClick)

    return () => subscription?.dispose()
  }, [])

  React.useEffect(() => {
    async function getResults(term: string) {
      const results = await SearchService.performSearch(term, searchUnmatchedRacing)
      return results
    }

    async function doSearch(term: string) {
      setPerformSearch(false)
      setIsLoading(true)

      const results = await getResults(term)

      try {
        if (isResultAnError(results)) {
          setIsErrored(true)
        } else {
          setIsErrored(false)
          setSearchResults(results as SearchResult[])
        }
      } catch (e) {
        setIsErrored(true)
      } finally {
        setIsLoading(false)
        setSearchPerformed(true)
      }
    }

    if (performSearch) {
      if (searchTerm.length >= 2) {
        doSearch(searchTerm)
      }
    }
  }, [performSearch, searchTerm, searchUnmatchedRacing])

  React.useEffect(() => {
    const element = containerElementRef.current
    element && registerBodyScrollLock(element)

    return () => {
      element && deregisterBodyScrollLock(element)
    }
  }, [])

  const updatePreviousSearchTerms = (term: string) => {
    const local = [term, ...previousSearches.filter(item => item !== term)].slice(
      0,
      MAX_PREVIOUS_SEARCH_TERMS
    )
    setInLocalStorage(SEARCH_PREVIOUS_TERM, JSON.stringify(local))
  }

  const callPerformSearch = (term: string) => {
    updatePreviousSearchTerms(term)
    setSearchTerm(term)
    setPerformSearch(true)
    setPreviousSearches([])
  }

  const resultsCleared = () => {
    setSearchTerm('')
    // force display of previous searches
    setPreviousSearches(getPreviousSearchesFromLocalStorage())
  }

  return (
    <SearchOverlayStyled data-tid-search-overlay=''>
      <SearchOverlayHeaderStyled>
        <SearchField
          performSearch={callPerformSearch}
          clearResults={resultsCleared}
          previousSearches={previousSearches}
          searchFocused={() => setPreviousSearches(getPreviousSearchesFromLocalStorage())}
        />
        <CloseButtonStyled onClick={handleCloseButtonClick} data-tid-search-button-close>
          <Icon type='cross' title='Close' />
        </CloseButtonStyled>
      </SearchOverlayHeaderStyled>

      <SearchResultsContainerStyled
        ref={containerElementRef}
        onPointerDown={event => {
          // hide previous searches when clicking outside them but still in the results area
          let clickedOnResults = hasClickedOnElement(
            event.target as HTMLElement,
            containerElementRef
          )
          setPreviousSearches(clickedOnResults ? [] : getPreviousSearchesFromLocalStorage())
        }}
      >
        <SearchResultsContainer
          searchResults={searchResults}
          searchPerformed={searchPerformed}
          isLoading={isLoading}
          isErrored={isErrored}
        />
      </SearchResultsContainerStyled>
    </SearchOverlayStyled>
  )
}

function getPreviousSearchesFromLocalStorage() {
  return JSON.parse(getFromLocalStorage(SEARCH_PREVIOUS_TERM) ?? '[]') as string[]
}

function hasClickedOnElement(
  target: HTMLElement,
  containerElementRef: React.RefObject<HTMLDivElement>
): boolean {
  return (
    target === containerElementRef.current ||
    (target.parentElement !== null &&
      hasClickedOnElement(target.parentElement, containerElementRef))
  )
}

export function handleCloseButtonClick(): void {
  OverlayClose()
  //Do any other logic here required to end a search modal session
}
