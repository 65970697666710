import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

export interface BannerAnalyticsData {
  bannerName: string
  internalTrackingCode?: string
  accountNumber: number | null
  bannerLocation: string
  bannerPosition: number
}

export const trackBannerClicked = (data?: BannerAnalyticsData) => {
  trackEvent(analyticsKeys.featureBannerClicked, { ...data })
}
