import React from 'react'
import { useAppSelector } from '@core/Store/hooks'
import { selectDockedPlayerHeight } from '@core/Areas/SkyVideoPlayer/Store/selectors'

export const useHeaderPinnedState = () => {
  const wrapperElRef = React.useRef<HTMLDivElement>(null)

  const [isHeaderPinned, setIsHeaderPinned] = React.useState(false)

  const skyVideoDockedPlayerHeight = useAppSelector(selectDockedPlayerHeight)

  // Determine when pinned - player NOT docked
  React.useEffect(() => {
    if (!wrapperElRef.current || !!skyVideoDockedPlayerHeight) return
    const el = wrapperElRef.current
    const observer = new IntersectionObserver(([e]) => setIsHeaderPinned(e.intersectionRatio < 1), {
      threshold: [1],
    })
    observer.observe(el)
    return () => observer.disconnect()
  }, [skyVideoDockedPlayerHeight])

  // Determine when pinned - player is docked
  React.useEffect(() => {
    if (!wrapperElRef.current || skyVideoDockedPlayerHeight === null) return
    const el = wrapperElRef.current
    let prevValue: boolean
    const handleScrollChange = () => {
      const shouldPin = el.getBoundingClientRect().top < skyVideoDockedPlayerHeight
      if (prevValue !== shouldPin) {
        setIsHeaderPinned(shouldPin)
        prevValue = shouldPin
      }
    }
    window.addEventListener('scroll', handleScrollChange)
    return () => {
      window.removeEventListener('scroll', handleScrollChange)
    }
  }, [skyVideoDockedPlayerHeight])

  return { isHeaderPinned, top: (skyVideoDockedPlayerHeight || 0) - 1, wrapperElRef }
}
