import React from 'react'
import { QuickbetReceiptDetails } from '@core/Areas/Quickbet/Components/Receipt/QuickbetReceipt'
import { BetslipItem } from '../../driver'

type BetslipReceiptProps = Pick<BetslipItem, 'receipt'> & {
  isTote: boolean
  isMystery: boolean
  isMysteryCombo: boolean
  isNoveltyBetType: boolean
  itemSelectionTag?: string
}

export function BetslipReceipt({
  receipt,
  isTote,
  isMystery,
  isMysteryCombo,
  isNoveltyBetType,
  itemSelectionTag,
}: BetslipReceiptProps): JSX.Element | null {
  if (!receipt) {
    return null
  }

  const {
    accountBalance,
    ticketNumber,
    winInvestment,
    placeInvestment,
    betCost,
    projectedWinPayout,
    projectedPlacePayout,
    specialOffers,
    bonusBet,
    campaignActivatedInd,
    campaignType,
    amountPaid,
    divXAnnotations,
  } = receipt

  return (
    <QuickbetReceiptDetails
      amountPaid={amountPaid}
      bonusBet={bonusBet}
      campaignActivatedInd={campaignActivatedInd}
      campaignType={campaignType}
      isToteBet={isTote}
      isMysteryBet={isMystery}
      isMysteryCombo={isMysteryCombo}
      isNoveltyBet={isNoveltyBetType}
      betCost={betCost}
      accountBalance={accountBalance}
      ticketNumber={ticketNumber}
      winInvestment={winInvestment}
      placeInvestment={placeInvestment}
      projectedWinPayout={projectedWinPayout}
      projectedPlacePayout={projectedPlacePayout}
      specialOffers={specialOffers}
      divXAnnotations={divXAnnotations}
      showBalance={false}
      itemSelectionTag={itemSelectionTag}
    />
  )
}
