import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { Lock } from '../../AppUtils/Framework/Lock'
import type { IAppWindow } from '../../AppUtils/Framework/WindowManagement/IAppWindow'
import type { IProgressIndicator } from '../../AppUtils/Framework/ProgressIndicator/IProgressIndicator'
import type { IEventAggregator } from '../../AppUtils/Framework/Messaging/IEventAggregator'
import { BetSpecialOffer } from '../Model/BetSpecialOffer'
import { Stake } from '../Model/Stake'
import { PriceDetails } from '../Model/PriceDetails'
import type { IPyosRewardCalculator } from './PyosRewardCalculator'
import { PyosExtensions } from './PyosExtensions'
import type { IPyosService } from '../Services/IPyosService'
import {
  FobBetPreEnquiryRequestDto,
  EnquirySelectionDto,
  isRacingEnquirySelectionDto,
  isSportsEnquirySelectionExDto,
} from '../Dtos/PyosServiceRequestDto'
import { PreEnquireSingleResultDto } from '../Dtos/PyosServiceResponseDto'
import type { ILoginHelper } from '@classic/AppUtils/Framework/Utils/ILoginHelper'

enum PyosStoreState {
  Ok,
  Loading,
  LoadFailed,
}

@injectable()
export class PyosStore {
  /* dependencies */
  private _appWindow: IAppWindow
  private _progressIndicator: IProgressIndicator
  private _eventAggregator: IEventAggregator
  private _pyosService: IPyosService
  private _rewardCalculator: IPyosRewardCalculator
  private _extensions: PyosExtensions
  private _loginHelper: ILoginHelper

  /* state */
  private _lock = new Lock()
  private _status = ko.observable<PyosStoreState>(PyosStoreState.Ok)
  private _specialOffers = ko.observable<BetSpecialOffer[]>([])
  private _selectedSpecialSeq = ko.observable<number>(null)
  private _betSelection = ko.observable<EnquirySelectionDto>(null)
  private _isExpanded = ko.observable(false)
  private _specialsErrorMessage = ko.observable<string>()
  private _disposables: ko.Subscription[]
  private _canShowInducements = ko.observable(false)

  public priceDetails = ko.observable<PriceDetails>(null)
  public stake = ko.observable<Stake>(null)

  private loginSubscription: Rx.IDisposable

  constructor(
    @inject('IAppWindow') appWindow: IAppWindow,
    @inject('IProgressIndicator') progressIndicator: IProgressIndicator,
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('IPyosService') pyosService: IPyosService,
    @inject('IPyosRewardCalculator') rewardCalculator: IPyosRewardCalculator,
    @inject('PyosExtensions') extensions: PyosExtensions,
    @inject('ILoginHelper') loginHelper: ILoginHelper
  ) {
    this._appWindow = appWindow
    this._progressIndicator = progressIndicator
    this._eventAggregator = eventAggregator
    this._pyosService = pyosService
    this._rewardCalculator = rewardCalculator
    this._extensions = extensions
    this._disposables = []
    this._loginHelper = loginHelper

    this.loginSubscription = this._loginHelper.setupHasLoggedInSubscription(() => {
      if (this._appWindow.domElementExists('pyos-select')) {
        this.enquireSpecialOffers()
      }
    })
  }

  /* clean up */
  dispose() {
    this.loginSubscription?.dispose()
    this._disposables.forEach(obj => obj.dispose())
    this._disposables = []
  }

  /* computed state */
  loading() {
    return this._status() === PyosStoreState.Loading
  }

  specialOffers() {
    return this._specialOffers()
  }

  selectedSpecialSeq() {
    return this._selectedSpecialSeq()
  }

  isExpanded(): boolean {
    return this._isExpanded()
  }

  hasOffers(): boolean {
    return !!(this._specialOffers() && this._specialOffers().length)
  }

  selectedSpecial() {
    const specialSeq = this._selectedSpecialSeq()
    if (this._specialOffers()) {
      for (let so of this._specialOffers()) {
        if (so.specialSeq === specialSeq) {
          return so
        }
      }
    }
    return null
  }

  hasWinStake(): boolean {
    const stake = this.stake()
    return !!(stake && !!Number(stake.Win))
  }

  hasPlaceStake(): boolean {
    const stake = this.stake()
    return !!(stake && !!Number(stake.Place))
  }

  projectedReward(specialOffer: BetSpecialOffer): decimal.Decimal | null {
    if (specialOffer && this.stake() && this.priceDetails()) {
      return this._rewardCalculator.calculateProjectedReward(
        specialOffer,
        this.stake() as Stake,
        this.priceDetails() as PriceDetails
      )
    }
    return null
  }

  projectedBoostReward(specialOffer: BetSpecialOffer): decimal.Decimal | null {
    if (specialOffer && this.stake()) {
      return this._rewardCalculator.calculateProjectedBoostReward(
        specialOffer,
        this.stake() as Stake
      )
    }
    return null
  }

  isOfferEligible(specialOffer: BetSpecialOffer) {
    return specialOffer
      ? this._rewardCalculator.checkEligible(specialOffer, this.stake() as Stake)
      : false
  }

  selectedOfferIsEligible(): boolean {
    const specialOffer = this.selectedSpecial()
    return specialOffer ? this.isOfferEligible(specialOffer) : false
  }

  errorMessage(): string {
    switch (this._status()) {
      case PyosStoreState.Ok:
        return this._specialsErrorMessage() || ''
      case PyosStoreState.Loading:
        return ''
      case PyosStoreState.LoadFailed:
        return 'Sorry, there was a problem loading the SuperPick details for this bet.'
      default:
        return ''
    }
  }

  /* actions */
  primaryAction() {
    if (this._selectedSpecialSeq()) {
      this.clearSelectedSpecial()
    } else {
      this._isExpanded(!this._isExpanded())
    }
  }

  setSelectedSpecial(specialOffer: BetSpecialOffer) {
    if (this.selectedSpecialSeq() === specialOffer.specialSeq) {
      this.clearSelectedSpecial()
    } else {
      this.clearSelectedSpecial()
      this._selectedSpecialSeq(specialOffer.specialSeq)
      this._eventAggregator.publish('specialoffer.selected', specialOffer)
    }
  }

  setStake(stake: Stake) {
    this.stake(Stake.normalise(stake))
  }

  setPriceDetails(priceDetails: PriceDetails) {
    this.priceDetails(PriceDetails.normalise(priceDetails))
  }

  setSpecialOffers(specialOffers: BetSpecialOffer[] | null) {
    if (specialOffers) {
      this._specialOffers(specialOffers.map(so => BetSpecialOffer.normalise(so)))
    } else {
      this._specialOffers([])
    }

    /* if the current selection is no longer valid */
    if (this._selectedSpecialSeq() && !this.selectedSpecial()) {
      this.clearSelectedSpecial()
    }
  }

  clearSelectedSpecial() {
    this._selectedSpecialSeq(null)
    this._isExpanded(false)
    this._eventAggregator.publish('specialoffer.cleared')
  }

  clear() {
    this.setSpecialOffers(null)
    this.clearSelectedSpecial()
    this._betSelection(null)
    this.priceDetails(null)
    this._specialsErrorMessage('')
    this._status(PyosStoreState.Ok)
  }

  clearErrorMessage() {
    this._selectedSpecialSeq(null)
    this._isExpanded(false)
    this._specialsErrorMessage('')
  }

  validate() {
    const specialOffer = this.selectedSpecial()
    if (specialOffer) {
      if (this.isOfferEligible(specialOffer) === false) {
        this._specialsErrorMessage(
          this._extensions.getLegTypeIneligibilityDisplay(specialOffer) || ''
        )
        return false
      }
    }

    this._specialsErrorMessage('')
    return true
  }

  setBetSelection(betSelection: EnquirySelectionDto) {
    this._betSelection(betSelection)
  }

  enquireSpecialOffers(): Promise<void> {
    if (this._betSelection() && this.priceDetails()) {
      if (this._lock.trylock()) {
        this._status(PyosStoreState.Loading)

        const betSelection = this._betSelection()
        const request = {
          SelectedSpecial: this.selectedSpecial() as BetSpecialOffer,
          Racing: isRacingEnquirySelectionDto(betSelection as EnquirySelectionDto)
            ? betSelection
            : null,
          SportsEx: isSportsEnquirySelectionExDto(betSelection as EnquirySelectionDto)
            ? betSelection
            : null,
        } as FobBetPreEnquiryRequestDto

        let operation = this._pyosService
          .fobBetPreEnquiry(request)
          .then((response: PreEnquireSingleResultDto) => {
            if (response) {
              this._canShowInducements(response.CanShowInducements)
              if (response.CanShowInducements && response.SpecialTokenOffers) {
                this.setSpecialOffers(response.SpecialTokenOffers.SpecialOffers)
              } else {
                this.setSpecialOffers(null)
              }
              if (response.SpecialOffersFault) {
                this._specialsErrorMessage(response.SpecialOffersFault.Message)
              } else {
                this._specialsErrorMessage('')
              }
            } else {
              this.setSpecialOffers(null)
              this._specialsErrorMessage('')
              this._canShowInducements(false)
            }

            this._status(PyosStoreState.Ok)
            this._lock.unlock()
          })
          .catch(() => {
            this.setSpecialOffers(null)
            this._canShowInducements(false)
            this._status(PyosStoreState.LoadFailed)
            this._lock.unlock()
          })

        return this._progressIndicator.displayFor(operation)
      }
    }
    return Promise.reject('No bet selection, or price details')
  }

  canShowInducements(): boolean {
    return this._canShowInducements()
  }
}
