import React, { memo, useCallback, useEffect, useRef } from 'react'
import { CompactExpandablePanel } from '@core/Components/ExpandablePanel'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { RaceCardIcon } from '@core/Components/Icons'
import { useVideoPreview } from '@core/Areas/Racing/Hooks/RaceContent/useVideoPreview'
import { useOnce } from '@core/Utils/hooks/useOnce'
import {
  trackVideoPreviewClosed,
  trackVideoPreviewLoaded,
  trackVideoPreviewNotSupported,
  trackVideoPreviewOpened,
  trackVideoPreviewPlayed,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Text } from '@core/Components/Text'
import { toISODate } from '@mobi/utils'
import { VideoContainerStyled, VideoStyled } from './Preview.styles'
import { useVideoStream } from '@core/Utils/hooks/Video/useVideoStream'
import Hls from 'hls.js'

type PreviewProps = {
  meetingId: string
  meetingDate: Date
  raceNumber: number
}

const Preview = memo<PreviewProps>(({ meetingId, meetingDate, raceNumber }): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const { isLoading, isError, content } = useVideoPreview(meetingId, meetingDate, raceNumber)

  const onVideoPanelLoaded = useOnce(trackVideoPreviewLoaded, meetingId, meetingDate, raceNumber)
  const onVideoPanelOpened = useOnce(trackVideoPreviewOpened, meetingId, meetingDate, raceNumber)
  const onVideoPanelClosed = useOnce(trackVideoPreviewClosed, meetingId, meetingDate, raceNumber)
  const onVideoPlayed = useOnce(trackVideoPreviewPlayed, meetingId, meetingDate, raceNumber)

  // TODO: Refactor useOnce to allow support for arguments
  const trackPreviewNotSupported = useCallback(() => {
    if (content) {
      trackVideoPreviewNotSupported(content.streamUrl, content.contentType)
    }
  }, [content])
  const onNotSupported = useOnce(trackPreviewNotSupported, content)

  const { playbackSupportStatus } = useVideoStream({
    videoRef,
    streamUrl: content?.streamUrl,
    onError: useCallback(
      ({ type }) => {
        if (type === 'unsupported' || !Hls.isSupported()) {
          onNotSupported()
        }
      },
      [onNotSupported]
    ),
  })

  const canPlay = !isLoading && !isError && !!content

  function onExpansionChanged(expanded: boolean): void {
    if (videoRef.current) {
      const player = videoRef.current

      if (expanded) {
        onVideoPanelOpened()

        player.setAttribute('autoplay', '')
        player.play().then(onVideoPlayed).catch(onNotSupported)
      } else if (!expanded) {
        player.removeAttribute('autoplay')
        onVideoPanelClosed()

        player.pause()
      }
    }
  }

  // When the component is unmounted, exit fullscreen
  // TODO: Refactor to use existing useFullscreen hook (which needs refactoring to be more generic)
  useEffect(
    () => () => {
      ;(async () => {
        if (document.fullscreenElement) {
          await document.exitFullscreen()
        }
      })()
    },
    [videoRef]
  )

  useEffect(() => {
    if (videoRef.current && canPlay) {
      onVideoPanelLoaded()
    }
  }, [videoRef, canPlay, onVideoPanelLoaded])

  if (!canPlay) {
    return null
  }

  return (
    <CompactExpandablePanel
      key={`${meetingId}-${toISODate(meetingDate)}-${raceNumber}`}
      themeName={ExpandablePanelThemes.lavender}
      headerText='Watch race preview'
      headerIcon={<RaceCardIcon icon='video' />}
      data-testid='race-preview'
      onExpansionChanged={onExpansionChanged}
    >
      {playbackSupportStatus === 'unsupported' ? (
        <Text data-testid='preview-video-not-supported'>
          Video playback is currently not supported on this device
        </Text>
      ) : (
        <VideoContainerStyled>
          <VideoStyled
            ref={videoRef}
            preload='metadata'
            autoPlay={false}
            playsInline={true}
            controls
            data-testid='preview-video'
          />
        </VideoContainerStyled>
      )}
    </CompactExpandablePanel>
  )
})

export default Preview
