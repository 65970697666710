import React from 'react'
import styled from '@emotion/styled'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { HeaderWrapper } from '../HeaderWrapper'
import {
  StarterAcceptorStyled,
  StarterDisplayStyled,
} from '../SingleMultiple/SingleMultiple.styles'

export const FavouriteNumbers: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  return (
    <StarterDisplayStyled data-testid='details-selection-info'>
      {ticket.legs[0].selections.map(({ header, selectionDetails, raceNumber }) => (
        <HeaderWrapper key={header} header={header} leg={ticket.legs[0]} raceNumber={raceNumber}>
          <StarterAcceptorStyled data-testid='details-selection-info-starter'>
            <Numbers>{selectionDetails[0].selectionString}</Numbers>
          </StarterAcceptorStyled>
        </HeaderWrapper>
      ))}
    </StarterDisplayStyled>
  )
}

const Numbers = styled('span')({
  textAlign: 'left',
  fontWeight: 'bold',
  wordBreak: 'break-word',
})
