import { useQuery, UseQueryOptions } from 'react-query'

import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { get } from '@classic/Foundation/Services/ApiService'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { RaceStatus } from '@mobi/api-types'
import { NextRace } from '@core/Data/Racing/nextRaces'
import { store } from '@core/Store'
import { bool } from 'aws-sdk/clients/signer'
import { useAppSelector } from '@core/Store/hooks'
import { getSkyRaceLimit } from '../Store/selectors'
import { setSkyRaceLimit } from '../Store'

const MaxRefetchInterval = 120_000

export const SkyPageLimit = 50

export interface NextSkyRace extends NextRace {
  RaceStatus: RaceStatus
  IsoCountryCode: string
  SimplePlacings: string
}

export const useNextSkyRaces = (
  props?: UseQueryOptions<{ nextRaces: NextSkyRace[] }>
): {
  nextRaces: NextSkyRace[]
  isLoading: bool
  isError: bool
  isMoreToFetch: bool
  refetch: () => void
  fetchMoreRaces: () => void
  resetLimit: () => void
} => {
  const limit = useAppSelector(getSkyRaceLimit)

  const fetchSkyRaces = async () => {
    return await get<{ nextRaces: NextSkyRace[] }>({
      url: `/webapi/race/GetNextSkyRaces${limit ? `?limit=${limit}` : ''}`,
    })
  }

  const { isLoading, isError, data, refetch } = useQuery<{
    nextRaces: NextSkyRace[]
  }>([queryKeys.nextSkyRaces, limit], fetchSkyRaces, {
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchIntervalInBackground: false,
    refetchInterval: MaxRefetchInterval,
    keepPreviousData: true,
    ...props,
  })

  const fetchMoreRaces = () => {
    store.dispatch(setSkyRaceLimit(limit + SkyPageLimit))
    trackEvent(analyticsKeys.moreSkyRacesLoaded, {
      clickCount: limit / SkyPageLimit,
    })
  }

  const resetLimit = () => {
    store.dispatch(setSkyRaceLimit(SkyPageLimit))
  }

  const nextRaces = data?.nextRaces || []

  return {
    nextRaces,
    isLoading,
    isError,
    isMoreToFetch: nextRaces.length === limit,
    refetch: refetch,
    fetchMoreRaces,
    resetLimit,
  }
}
