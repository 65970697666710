import type { Button, ToggleButton } from '../Types'
import {
  ToggleShowAllForm,
  ToggleFixedFlucs,
  ToggleMarketMovers,
  ToggleDoubleProvDiv,
  ToggleQuaddieProvDiv,
  ToggleStewardsCommentsVisible,
  ToggleTipsVisible,
  ToggleResultsVisible,
  ToggleFieldVisible,
  ToggleRaceReplayVisible,
  ToggleRacePreviewVisible,
} from '@classic/Betting-v2/Components/Commands/driver'
import { useCommandState } from './useCommandState'
import { useCallback } from 'react'

type UseBetInformers = {
  buttons: Record<Button, boolean>
  toggleButton: ToggleButton
}

const actions: Record<Button, () => void> = {
  showDoubleProvDiv: ToggleDoubleProvDiv,
  showFixedFlucs: ToggleFixedFlucs,
  showAllForm: ToggleShowAllForm,
  showMarketMovers: ToggleMarketMovers,
  showQuaddieProvDiv: ToggleQuaddieProvDiv,
  commentsVisible: ToggleStewardsCommentsVisible,
  tipsVisible: ToggleTipsVisible,
  resultsVisible: ToggleResultsVisible,
  fieldVisible: ToggleFieldVisible,
  replayVisible: ToggleRaceReplayVisible,
  previewVisible: ToggleRacePreviewVisible,
}

export function useBetInformers(): UseBetInformers {
  const toggleButton = useCallback((button: Button): void => {
    actions[button as Button]()
  }, [])

  const commandState = useCommandState()

  return {
    buttons: commandState,
    toggleButton,
  }
}
