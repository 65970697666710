import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { DetailsSecondaryInfoStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Details/Details.styles'

export const SingleSingle: React.FC<{
  leg: Ticket['legs'][0]
}> = ({ leg }) => {
  const { sportName, eventNameLine2 } = leg

  if (!sportName && !eventNameLine2) return null

  return (
    <DetailsSecondaryInfoStyled data-testid='single-details-selection-info'>
      <dl>
        {eventNameLine2 && (
          <div data-tid-start-time>
            <dt>Comp:</dt>
            <dd>{eventNameLine2}</dd>
          </div>
        )}
        {sportName && (
          <div data-tid-start-time>
            <dt>Sport:</dt>
            <dd>{sportName}</dd>
          </div>
        )}
      </dl>
    </DetailsSecondaryInfoStyled>
  )
}
