import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const StarterMultiStyled = styled('div')({
  display: 'flex',
})

export const MultiSilkStyled = styled('div')({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3rem',
  minHeight: '3rem',
  borderRadius: '0.5rem',
  marginRight: '1rem',
  padding: '0.5rem',
  color: hexColors.dimGrey,
  backgroundColor: hexColors.gainsboro,
})

export const StatusInfoStyled = styled('div')({
  paddingLeft: '0.5rem',
  textAlign: 'right',
  fontSize: '1.2rem',
  lineHeight: 1.3,
  color: hexColors.grey,
})
