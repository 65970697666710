import React from 'react'

import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

import { HomeNextRacesListItemStyled } from './HomeNextRaces.styles'
import { radius } from '@mobi/component-library/Theme/Common'

export const HomeNextRacesLoading: React.FC = () => {
  return (
    <>
      <HomeNextRacesListItemStyled>
        <LoadingPlaceholder
          width='8rem'
          height='1.4rem'
          borderRadius={radius.lg}
          margin='0.25rem'
        />
        <LoadingPlaceholder width='4rem' height='1rem' borderRadius={radius.lg} margin='0.25rem' />
      </HomeNextRacesListItemStyled>
      <HomeNextRacesListItemStyled>
        <LoadingPlaceholder
          width='8rem'
          height='1.4rem'
          borderRadius={radius.lg}
          margin='0.25rem'
        />
        <LoadingPlaceholder width='4rem' height='1rem' borderRadius={radius.lg} margin='0.25rem' />
      </HomeNextRacesListItemStyled>
      <HomeNextRacesListItemStyled>
        <LoadingPlaceholder
          width='8rem'
          height='1.4rem'
          borderRadius={radius.lg}
          margin='0.25rem'
        />
        <LoadingPlaceholder width='4rem' height='1rem' borderRadius={radius.lg} margin='0.25rem' />
      </HomeNextRacesListItemStyled>
    </>
  )
}
