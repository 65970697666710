import * as dtos from '../DataTransferObjects/DataTransferObjects'
import NoveltyDividend from '../Model/NoveltyDividend'
import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import { Dividend } from '../Model/NoveltyDividend'

export default class NoveltyDividendsMapper {
  public static map(dto: dtos.ResultsPageDataTransferObject, model: ObservableResultsPage) {
    let noveltyDividends = []
    for (let noveltyDividend of dto.NoveltyDividends) {
      let noveltyDividendModel = this.noveltyDividendModel(noveltyDividend)
      noveltyDividends.push(noveltyDividendModel)
    }
    model.addNoveltyDividends(noveltyDividends)
  }

  private static noveltyDividendModel(
    noveltyDividendDto: dtos.NoveltyDividendDataTransferObject
  ): NoveltyDividend {
    let noveltyDividend = new NoveltyDividend()
    noveltyDividend.poolName = noveltyDividendDto.PoolName

    noveltyDividendDto.RaceNumbers.forEach(raceNumber => {
      noveltyDividend.raceNumbers.push(raceNumber)
    })

    let raceNumbers = noveltyDividend.raceNumbers
    if (raceNumbers != undefined && raceNumbers.length > 0) {
      noveltyDividend.hasRaceNumbers = true
      let numbersString = '(' + raceNumbers.join(', ') + ')'
      noveltyDividend.raceNumbersString = numbersString
    }

    noveltyDividendDto.Dividends.forEach(dividendDto => {
      noveltyDividend.dividends.push(new Dividend(dividendDto.Name, dividendDto.Dividend))
    })

    return noveltyDividend
  }
}
