import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const DateHeaderLabelStyled = styled('div')({
  padding: '0.5rem 0',
  fontSize: '1.2rem',

  '& > span:nth-of-type(1)': {
    color: hexColors.grey,
    paddingRight: '1rem',
  },

  '& > span:nth-of-type(2)': {
    color: hexColors.black,
  },
})
