import { ISelectionProcessor } from '../../../../../classic/Betting-v2/Components/Core/Processors/ISelectionProcessor'
import { IObservableStarter } from '../../../../../classic/Betting-v2/Model/Observables/IObservableStarter'
import { ButtonSelectionType } from '../../../../../classic/Betting-v2/Components/Core/UIElements/ButtonSelectionType'
import { BettingInformation } from '../../../../../classic/Betting-v2/Model/BettingInformation'
import Guard from '../../../../../classic/AppUtils/Framework/Guard'
import { ButtonsSelection } from '../../../../../classic/Betting-v2/Components/Core/UIElements/ButtonsSelection'
import SameRaceMultiValidator from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiValidator'
import LegSelectionContext from '../../../../../classic/Betting-v2/Components/Core/Processors/LegSelectionContext'
import ko, { Observable } from 'knockout'

export default class SameRaceMultiSelectionProcessor implements ISelectionProcessor {
  selectionsErrorMessage: Observable<string> = ko.observable('')
  errorTimeout!: NodeJS.Timeout

  public tag(): string {
    return 'SameRaceMultiSelectionProcessor'
  }

  public generateSelections(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    legSelectionContext: LegSelectionContext
  ): Array<IObservableStarter> {
    return this.processSingleSelection(starter, starters, bettingContext, legSelectionContext)
  }

  private processSingleSelection(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    legSelectionContext: LegSelectionContext | null
  ): Array<IObservableStarter> {
    Guard.notNull(starter)
    Guard.notNull(starters)
    Guard.notNull(bettingContext)

    bettingContext.isFixed(true)

    // validate whether the acceptor should be allowed
    // - use 'starters' which represents the current UI selections, i.e. not the betting context selection (which is assigned from the output of this method)
    // - re-using the validator to avoid logic duplication (note, the validator is also directly invoked via KO computed property StartersPageViewModel.isValid)
    const selections = SameRaceMultiValidator.getSelectionsFromStarters(starters())
    const starterIndex = starters().findIndex(s => s.number() === starter.number())

    // VERY important to cater for legSelectionContext==null, since unfortunately ObservableXxxStarter uses this to trigger 'psuedo selections' on scratched acceptors
    // - also, null references in this method are caught higher up and thus NOT captured in the console!

    // remove selection is there's no context (e.g. a scratched/suspended starter) AND an applicable fixed odds scratching status exists
    if (starter.isFixedScratchedOrSuspended() || starter.isSameRaceMultiSuspended()) {
      this.removeOldSelection(starter, legSelectionContext)
    }

    let { isValid, sumLegSelectionsCount, hasSingleStarterWithTwoSelections } =
      SameRaceMultiValidator.validate(selections, starterIndex, legSelectionContext?.legNumber)

    if (!isValid) {
      if (sumLegSelectionsCount > 1) {
        // remove the new invalid leg selection
        this.removeNewSelection(starter, legSelectionContext)
        // and inform the user
        this.showError()
      } else if (hasSingleStarterWithTwoSelections) {
        this.removeOldSelection(starter, legSelectionContext)
      }
    } else {
      if (hasSingleStarterWithTwoSelections) {
        this.removeOldSelection(starter, legSelectionContext)
      }
      this.hideError()
    }

    let selectedStarters = starters().filter(x =>
      (x.selection() as ButtonsSelection).values().some(value => value() == ButtonSelectionType.Fob)
    )

    return selectedStarters
  }

  private removeOldSelection(
    starter: IObservableStarter,
    legSelectionContext: LegSelectionContext | null
  ) {
    ;(starter.selection() as ButtonsSelection).values().forEach((value, index) => {
      if (!legSelectionContext || index != legSelectionContext.legNumber)
        value(ButtonSelectionType.None)
    })
  }

  private removeNewSelection(
    starter: IObservableStarter,
    legSelectionContext: LegSelectionContext | null
  ) {
    ;(starter.selection() as ButtonsSelection).values().forEach((value, index) => {
      if (index == legSelectionContext?.legNumber) value(ButtonSelectionType.None)
    })
  }

  private showError() {
    this.hideError()

    this.selectionsErrorMessage('starter selection invalid: no reason specified')
    this.errorTimeout = setTimeout(() => this.hideError(), 2_000)
  }

  private hideError() {
    if (this.errorTimeout) clearTimeout(this.errorTimeout)
    this.selectionsErrorMessage('')
  }

  public getStarterSelections(starters: IObservableStarter[]): boolean[][] {
    // starters parameter is not guaranteed to be ordered by starter number, e.g. ordered by win price
    let orderedStarters = [...starters].sort((a, b) => a.number() - b.number())

    let selections = SameRaceMultiValidator.getSelectionsFromStarters(orderedStarters)
    return selections.map(s => s.map(x => x == 1))
  }

  // this method is NOT required for SRM.. arguably it shouldn't exist on ISelectionProcessor
  public processFieldSelection(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _starters: ko.ObservableArray<IObservableStarter>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selections: ko.ObservableArray<IObservableStarter>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _bettingContext: BettingInformation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _index: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selected: boolean
  ) {
    // this method is not required.. potentially shouldn't belong to the interface?
  }

  // this method is NOT required for SRM.. arguably it shouldn't exist on ISelectionProcessor
  public processSameAsSelection(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _starters: ko.ObservableArray<IObservableStarter>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selections: ko.ObservableArray<IObservableStarter>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _bettingContext: BettingInformation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _row: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _column: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selected: boolean
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) {
    // this method is not required.. potentially shouldn't belong to the interface?
  }

  public clearAllSelections(
    starters: ko.ObservableArray<IObservableStarter>[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selections: ko.ObservableArray<IObservableStarter>[]
  ) {
    // although SRM is a single race product, we still need to iterate over ALL the different races becauae the 'common' i/f caters for multi race products (e.g. allup)
    starters.forEach(starterArray =>
      starterArray().forEach(starter => this.removeOldSelection(starter, null))
    )
    // unless there's some edge case scenario we're trying to accommodate here.. we shouldn't need to explicitly clear the selections here as this is done automatically based on the starter selections
    // selections.forEach(selectionArray =>
    //   selectionArray().forEach(selection => this.removeOldSelection(selection, null))
    // )
  }
}
