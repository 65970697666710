import { isErrored, type Repository } from 'rwwa-data-access'

export async function getFromRepo<T>(repository: Repository<T>, key: string): Promise<T> {
  const result = await repository.getPromise(key)

  if (isErrored(result)) {
    throw new Error(`Unable to fetch key '${key}' from repository`)
  }

  return result
}
