import React from 'react'
import styled from '@emotion/styled'
import { colors, font, layering } from '@mobi/component-library/Theme/Common'
import { useAppSelector } from '@core/Store/hooks'
import { sortMeetingOnName, sortMeetings } from '@core/Areas/Meetings/helpers/sortMeetings'
import { useClickOutside } from '@core/Utils/hooks/useClickOutside'
import { useMeetings } from '@core/Areas/Racing/Hooks/useMeetings'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { DropdownLink } from './Components/DropdownLink'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { getCleanMeetingName } from './helpers/getCleanMeetingName'

export const MeetingsDropdown: React.FC<{
  meetingName: string
  meetingCode: RaceCodesLowerCase
  meetingDate: string
}> = ({ meetingName, meetingCode, meetingDate }) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = React.useState(false)

  useClickOutside(dropdownRef, () => setOpen(false))

  const meetings = useMeetings(meetingCode, meetingDate)
  const favourites = useAppSelector(state => state.meetings.favourites)

  React.useEffect(() => setOpen(false), [meetingName])

  const favouriteMeetings = meetings
    .filter(meeting => meeting.status === 'Open' && favourites.includes(meeting.name))
    .sort(sortMeetingOnName)

  const { AustralianMeetings, InternationalMeetings } = sortMeetings(
    meetings.filter(meeting => meeting.status === 'Open' && !favourites.includes(meeting.name))
  )

  const shouldShowDropdown = !!(
    favouriteMeetings.length ||
    AustralianMeetings.length ||
    InternationalMeetings.length
  )

  const meetingNameClean = getCleanMeetingName(meetingName)

  return (
    <MeetingsDropdownStyled ref={dropdownRef} data-testid='dropdown'>
      {shouldShowDropdown ? (
        <button onClick={() => setOpen(curr => !curr)}>
          {meetingNameClean}

          <Icon name={isOpen ? 'SolidChevronUp' : 'SolidChevronDown'} size='2.4rem' />
        </button>
      ) : (
        <strong>{meetingNameClean}</strong>
      )}

      {shouldShowDropdown && isOpen && (
        <div>
          <ul data-testid='dropdown-menu'>
            {favouriteMeetings.length > 0 && (
              <>
                <li role='heading'>
                  <strong>Favourites</strong>
                </li>
                {favouriteMeetings.map(meeting => (
                  <li key={meeting.id}>
                    <DropdownLink meeting={meeting} />
                  </li>
                ))}
              </>
            )}

            {AustralianMeetings.length > 0 && (
              <>
                <li role='heading'>
                  <strong>Australia</strong>
                </li>
                {AustralianMeetings.map(meeting => (
                  <li key={meeting.id}>
                    <DropdownLink meeting={meeting} />
                  </li>
                ))}
              </>
            )}

            {InternationalMeetings.length > 0 && (
              <>
                <li role='heading'>
                  <strong>International</strong>
                </li>
                {InternationalMeetings.map(meeting => (
                  <li key={meeting.id}>
                    <DropdownLink meeting={meeting} />
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </MeetingsDropdownStyled>
  )
}

// ======
// Styles
// ======

const MeetingsDropdownStyled = styled.div({
  position: 'relative',
  fontFamily: font.family.primary,

  // Current Meeting
  '> button, > strong': {
    fontWeight: font.weight.semibold,
    fontSize: font.size.xl.fontSize,
    lineHeight: font.size.xl.lineHeight,
    letterSpacing: font.size.xl.letterSpacing,
    color: colors.black,
  },

  // Toggle
  '> button': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    minHeight: '4rem',
    cursor: 'pointer',
    paddingLeft: 0,
    background: 0,
    border: 0,

    svg: { pointerEvents: 'none' },

    '&:active': { background: 'transparent' },
  },

  // No Toggle
  '> strong': {
    display: 'inline-flex',
    alignItems: 'center',
    minHeight: '3rem',
  },

  '@keyframes animateIn': {
    ['0%']: { transform: 'translateY(1rem)', opacity: 0 },
    ['70%']: { opacity: 1 },
    ['100%']: { transform: 'translateY(0)' },
  },

  // Dropdown
  '& > div': {
    backgroundColor: colors.surface[200],
    borderRadius: '0.5rem',
    boxShadow: '0 0.4rem 0.2rem rgba(0, 0, 0, 0.2)',
    color: 'black',
    overflow: 'hidden',
    position: 'absolute',
    top: '100%',
    animation: 'ease animateIn .2s',
    transform: 'translateY(0)',
    zIndex: layering.pagePopoverElements,

    ul: {
      listStyle: 'none',
      margin: 0,
      maxHeight: '60vh',
      overflow: 'hidden auto',
      padding: '0.8rem 1.6rem',

      li: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '1.2rem',
        lineHeight: 1,
        height: '3rem',
        whiteSpace: 'nowrap',

        strong: {
          color: colors.black,
        },

        button: {
          flex: 1,
          paddingLeft: 0,
          textAlign: 'left',
          fontWeight: 'normal',
        },
      },
    },
  },
})
