import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { Keypress, KeyPressed, KeypadModes } from '@core/Components/Keypad/KeyPress'
import { calculateInvestment } from '../../helpers/calculator'
import {
  SetActiveInvestment,
  EditBetslipItem,
  QuickbetLoadSelection,
  SetMysteryQuickPickPresetInvestment,
  SetBonusBet,
} from '../../signals'
import { BetslipItem } from '../../../Betslip/driver'

export enum InvestmentType {
  Win = 'win',
  Place = 'place',
}

export interface BonusBet {
  campaignId: number
  value: number
}

export interface SingleInvestment {
  value: number
  isBonusBet?: boolean
  isBonusCash?: boolean
  lastKeyPressed: KeyPressed
  secondLastKeyPressed: KeyPressed
}

export interface InvestmentState {
  bonusBet: BonusBet | undefined
  win: SingleInvestment
  place: SingleInvestment
  activeInvestment: InvestmentType
}

export const defaultInvestmentState: InvestmentState = {
  bonusBet: undefined,
  win: {
    value: 0,
    isBonusBet: false,
    lastKeyPressed: {
      mode: KeypadModes.Denomination,
      value: 0,
    },
    secondLastKeyPressed: {
      mode: KeypadModes.Denomination,
      value: 0,
    },
  },
  place: {
    value: 0,
    isBonusBet: false,
    lastKeyPressed: {
      mode: KeypadModes.Denomination,
      value: 0,
    },
    secondLastKeyPressed: {
      mode: KeypadModes.Denomination,
      value: 0,
    },
  },
  activeInvestment: InvestmentType.Win,
}

export interface InvestmentStateRecord
  extends TypedRecord<InvestmentStateRecord>,
    InvestmentState {}
export const InvestmentStateFactory = makeTypedFactory<InvestmentState, InvestmentStateRecord>(
  defaultInvestmentState
)

export function betInvestmentDriver(
  state = InvestmentStateFactory(),
  signal: Signal
): InvestmentStateRecord {
  switch (signal.tag) {
    case QuickbetLoadSelection: {
      return state.merge({
        ...defaultInvestmentState,
      })
    }

    case Keypress: {
      const currentState: InvestmentStateRecord = { ...state.toJS() }
      const lastKeyPressed = currentState[currentState.activeInvestment].lastKeyPressed
      const secondLastKeyPressed = currentState[currentState.activeInvestment].secondLastKeyPressed
      const currentValue = currentState[currentState.activeInvestment].value
      const newValue = calculateInvestment(
        signal.data,
        currentValue,
        lastKeyPressed,
        secondLastKeyPressed
      )

      const newState: InvestmentStateRecord = {
        ...currentState,
        [currentState.activeInvestment]: {
          value: newValue,
          secondLastKeyPressed: lastKeyPressed,
          lastKeyPressed: signal.data,
        },
      }

      return state.merge(newState)
    }

    case SetActiveInvestment: {
      return state.merge({
        activeInvestment: signal.data,
      })
    }

    case SetMysteryQuickPickPresetInvestment: {
      const investmentWin: number = signal.data

      return state.merge({
        ...defaultInvestmentState,
        win: {
          value: investmentWin,
          lastKeyPressed: {
            mode: KeypadModes.Denomination,
            value: 0,
          },
        },
      })
    }

    case EditBetslipItem: {
      const {
        investment: { win, place },
      }: BetslipItem = signal.data
      return state.merge({
        ...defaultInvestmentState,
        win,
        place,
        activeInvestment: state.activeInvestment,
      })
    }

    case SetBonusBet: {
      const bonusBet = signal.data as BonusBet
      const currentState: InvestmentStateRecord = { ...state.toJS() }
      let win: SingleInvestment = currentState.win
      let place: SingleInvestment = currentState.place

      win = {
        ...win,
        value: 0,
        isBonusBet: false,
      }
      place = {
        ...place,
        value: 0,
        isBonusBet: false,
      }

      if (bonusBet && currentState.activeInvestment == InvestmentType.Win) {
        win = {
          ...win,
          value: bonusBet.value,
          isBonusBet: true,
        }
      }

      if (bonusBet && currentState.activeInvestment == InvestmentType.Place) {
        place = {
          ...place,
          value: bonusBet.value,
          isBonusBet: true,
        }
      }

      return state.merge({
        bonusBet: bonusBet,
        win: win,
        place: place,
      })
    }

    default: {
      return state
    }
  }
}

export const state$ = attachDriver<InvestmentStateRecord>({
  path: 'betInvestment',
  driver: betInvestmentDriver,
})
