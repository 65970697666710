import React, { type FC } from 'react'
import type { PaymentMethodsSelfServe } from '@mobi/component-library/Deposit/types'
import type { PanelProps } from './types'
import { BPayPanel, CashPanel, PayIDPanel, ScanMyBetPanel } from './Payments'
import { PanelStyled } from './DepositBody.styles'
import { InitialData } from '@mobi/api-types'
import { type PayIdDetails } from '@mobi/api-types/src/account/betAccount'

type Props = {
  accountNumber: number
  paymentMethod: PaymentMethodsSelfServe
  initialData: InitialData
  isPreCommitmentLimitSet: boolean
  payIdDetailList: PayIdDetails[]
}

export const SelfServeDepositPanels: FC<Props> = ({
  accountNumber,
  paymentMethod,
  initialData,
  isPreCommitmentLimitSet,
  payIdDetailList,
}) => {
  const Panel = externalPaymentPanels[paymentMethod]
  return (
    <PanelStyled>
      <Panel
        accountNumber={accountNumber}
        initialData={initialData}
        isPreCommitmentLimitSet={isPreCommitmentLimitSet}
        payIdDetailList={payIdDetailList}
      />
    </PanelStyled>
  )
}

const externalPaymentPanels: Record<
  PaymentMethodsSelfServe,
  React.FC<
    Pick<PanelProps, 'accountNumber' | 'initialData'> & {
      isPreCommitmentLimitSet: boolean
      payIdDetailList: PayIdDetails[]
    }
  >
> = {
  BPay: BPayPanel,
  Cash: CashPanel,
  ScanMyBet: ScanMyBetPanel,
  PayID: PayIDPanel,
} as const
