import { navChange } from '@classic/Foundation/Navigation/Nav'
import { get, post } from '@classic/Foundation/Services/ApiService'
import { refreshLogonState } from './refreshLogonState'

const AUTH_ROOT_API = '/api/authentication'

// MOBI requires capitalised root - work is needed to fix
export const postLogon = (body: LogonRequestData) =>
  post<LogonResult>({ url: `/api/Authentication/Logon`, body })

export const postLogout = (): Promise<void> => {
  return post({ url: `${AUTH_ROOT_API}/logoff` }).then(() => {
    refreshLogonState()
    navChange('/#')
  })
}

/** Salted cookie value is sent to server for unsalting */
export const getRememberAccountNumber = (): Promise<number | undefined> =>
  get<{ accountNumber: number }>({
    url: `${AUTH_ROOT_API}/GetAccountNumber`,
  }).then(data =>
    !data || !data.accountNumber || !Number.isInteger(data.accountNumber)
      ? undefined
      : data.accountNumber
  )

export const getIsAuthenticated = () =>
  get<IsAuthenticatedResponse>({ url: `${AUTH_ROOT_API}/IsAuthenticated` })

export const getIsLoggedOn = (): Promise<boolean> =>
  getIsAuthenticated().then(data => !!data.accountNumber)

/////////
// Types
/////////
export type LogonResult = {
  isSuccessful: boolean
  mustChangePassword: boolean
}

export type LogonRequestData = {
  accountNumber: number
  password: string
  rememberAccount: boolean
  keepLoggedIn: boolean
  loginViaBioAuth?: boolean
}

type LoggedOut = {
  accountNumber: null
  loggedIn: false
}

type LoggedIn = {
  accountNumber: number
  loggedIn: true
}

type TokenResponse = {
  // HTML string
  token: string
}

export type IsAuthenticatedResponse = (LoggedIn | LoggedOut) & TokenResponse
