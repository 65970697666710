import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const NextRaceFilterContainer = styled('div')({
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  fontSize: '1.2rem',
  backgroundColor: colors.nextEvents.toggle.background,
})

export const NextEventMeetingNameStyled = styled('div')({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  lineHeight: '2.1rem',
})

export const NextEventRaceTextStyled = styled('div')({
  fontSize: '1.4rem',
  lineHeight: '2.1rem',
})

export const NextEventRaceDistanceStyled = styled('div')({
  color: colors.nextEvents.distance.text,
  display: 'inline-block',
  marginLeft: '0.5rem',
})

interface NextEventCodeFilterButton {
  selected?: boolean
}

export const NextEventCodeFilterButtonStyled = styled('button')<NextEventCodeFilterButton>(
  {
    fontSize: '1.2rem',
    fontFamily: 'inherit',
    height: '4rem',
    lineHeight: 1.5,
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    padding: 0,
    margin: 0,
  },

  ({ selected }) => ({
    background: selected
      ? colors.nextEvents.toggle.selected.background
      : colors.nextEvents.toggle.unselected.background,
    color: selected
      ? colors.nextEvents.toggle.selected.text
      : colors.nextEvents.toggle.unselected.text,
    border: selected ? 'none' : `1px solid ${colors.nextEvents.toggle.unselected.border}`,
  })
)
