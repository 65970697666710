import type { RacingBetType } from '@mobi/betslip/types'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

const trackTipsBetClickedEvent = (
  key: typeof keys.tipsBetClicked | typeof keys.racePreviewBetClicked,
  meetingCode: string,
  meetingName: string,
  buttonPosition: number,
  betType: RacingBetType
) =>
  trackEvent(key, {
    meetingCode,
    meetingName,
    buttonPosition,
    betType,
  })

export const trackTipsBetClicked = (
  meetingCode: string,
  meetingName: string,
  buttonPosition: number,
  betType: RacingBetType
) =>
  trackTipsBetClickedEvent(keys.tipsBetClicked, meetingCode, meetingName, buttonPosition, betType)

export const trackRacePreviewBetClicked = (
  meetingCode: string,
  meetingName: string,
  buttonPosition: number,
  betType: RacingBetType
) =>
  trackTipsBetClickedEvent(
    keys.racePreviewBetClicked,
    meetingCode,
    meetingName,
    buttonPosition,
    betType
  )
