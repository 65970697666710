import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'

export const HistoryHeader: React.FC<
  { onClick: () => void; isExpanded: boolean } & Pick<
    RacesFormRaceResponse['Forms'][0]['RaceHistories'][0],
    'Place' | 'RaceNumber' | 'TrackName' | 'FixtureDate' | 'Distance'
  > &
    Partial<Pick<RacesFormRaceResponse['Forms'][0]['RaceHistories'][0], 'FieldSize'>>
> = ({ onClick, isExpanded, Place, FieldSize, RaceNumber, TrackName, FixtureDate, Distance }) => (
  <HistoryHeaderStyled onClick={onClick} data-testid='runner-form-history-header'>
    <span>
      {Place}
      {FieldSize ? `-${FieldSize}` : ''}
    </span>
    <span>{RaceNumber}</span>
    <span className='trackName'>{TrackName}</span>
    <span>{FixtureDate}</span>
    <span>{Distance}</span>
    <Icon size='1.5rem' type={isExpanded ? 'arrowup' : 'arrowdown'} />
  </HistoryHeaderStyled>
)

const HistoryHeaderStyled = styled.h6({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '4rem',
  background: hexColors.gainsboro,
  color: hexColors.nero,
  margin: 0,
  padding: '0 1rem',
  fontSize: '1.2rem',
  lineHeight: 1,
  border: '0.1rem solid ' + hexColors.grey3,
  borderTop: 0,

  span: {
    minWidth: '10%',
    textAlign: 'left',
    '&.trackName': {
      minWidth: '37%',
      textOverflow: 'ellipsis',
      maxWidth: '37%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  '&:first-of-type': {
    borderTop: '0.1rem solid ' + hexColors.grey3,
  },
})
