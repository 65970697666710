import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const SearchResultsStyled = styled('div')({
  fontSize: '1.4rem',
})

export const SearchResultPrimaryInfoStyled = styled('span')({
  fontSize: '1.2rem',
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const SearchResultSecondaryInfoStyled = styled('span')({
  color: colors.search.result.secondary,
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '1.0rem',
})

export const DriverStyled = styled(SearchResultSecondaryInfoStyled)({
  marginRight: '3px',
  display: 'inline',
})

export const RaceDetailsStyled = styled(SearchResultPrimaryInfoStyled)({})

export const RaceClassStyled = styled(SearchResultSecondaryInfoStyled)({
  display: 'inline',
})
export const RaceDistanceStyled = styled(RaceClassStyled)({
  marginRight: '3px',
})

export const SportsStyled = styled(SearchResultPrimaryInfoStyled)({})

export const SearchResultPricesStyled = styled('div')({
  display: 'flex',
  fontWeight: 'bold',
  flexDirection: 'column',
})

export const SearchResultInfoHeaderStyled = styled('div')({
  backgroundColor: colors.search.background,
  borderBottom: `1px solid ${colors.search.result.border}`,
  textAlign: 'right',
  padding: '0.25rem 1rem',
})

export const SearchFixedOddsLabelStyled = styled('span')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: colors.structuredSearch.resultDetails.fixed,
})
