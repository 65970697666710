import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const Loading: React.FC<{}> = () => {
  return (
    <LoadingWrapperStyled data-testid='meetings-data-loading'>
      <div>
        <LoadingPlaceholder width='60%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='50%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='65%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='70%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='55%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='60%' height='1.6rem' borderRadius={radius.lg} />
      </div>
      <div>
        <LoadingPlaceholder width='65%' height='1.6rem' borderRadius={radius.lg} />
      </div>
    </LoadingWrapperStyled>
  )
}

// ======
// Styles
// ======

const LoadingWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2.5rem',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '5rem',
    background: hexColors.white,
    borderBottom: `0.1rem solid ${hexColors.gainsboro}`,

    paddingLeft: '5rem',
  },
})
