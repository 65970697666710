import React from 'react'
import { navigateHistoryBack } from '@classic/AppUtils/Framework/Intent/navigation'
import { observeImmutable } from '@core/Components/HOCs/observe'
import { PopupNoticeBox } from '@core/Components/PopupNoticeBox'
import { NoticeBoxTypes } from '@core/Components/NoticeBox'
import { state$ as reverificationState$, ReverificationCompleted } from './../../driver'
import {
  state$ as contactDetailsState$,
  ContactDetailsStateRecord,
} from '@core/Areas/ContactDetails/driver'

interface ReverificationCompleteMessageProps {
  isReverificationSuccess: boolean
  isReverificationFailed?: boolean
  isReverificationCompleted: boolean
  navigateToHistoryBack?: boolean
}

export const ReverificationComplete = observeImmutable(reverificationState$, ({ record }) => {
  const { isReverificationSuccess, isReverificationFailed, isReverificationCompleted } = record
  return (
    <ReverificationCompleteMessage
      isReverificationSuccess={isReverificationSuccess}
      isReverificationFailed={isReverificationFailed}
      isReverificationCompleted={isReverificationCompleted}
    />
  )
})

export const ContactDetailsReverificationSuccess = observeImmutable<
  ContactDetailsStateRecord,
  { navigateToHistoryBack?: boolean }
>(contactDetailsState$, ({ record, navigateToHistoryBack }) => {
  const { isReverificationSuccess } = record
  return (
    <ReverificationCompleteMessage
      isReverificationSuccess={!!isReverificationSuccess}
      isReverificationCompleted={false}
      navigateToHistoryBack={navigateToHistoryBack}
    />
  )
})

export class ReverificationCompleteMessage extends React.Component<ReverificationCompleteMessageProps> {
  private reverificationCompleteRef: HTMLElement | null = null

  public onClose = () => {
    ReverificationCompleted()
    if (this.props.navigateToHistoryBack) {
      navigateHistoryBack()
    }
  }

  public componentDidMount() {
    // on mount scroll to message
    if (this.reverificationCompleteRef) {
      window.scrollTo(0, -this.reverificationCompleteRef.offsetTop)
    }
  }

  public render() {
    if (!this.props.isReverificationSuccess && !this.props.isReverificationFailed) {
      return null
    }

    if (this.props.isReverificationCompleted) {
      return null
    }

    const message = this.props.isReverificationSuccess
      ? 'Re-verification was successful'
      : 'Re-verification was unsuccessful please try again later.'
    const noticeBoxType = this.props.isReverificationSuccess
      ? NoticeBoxTypes.Success
      : NoticeBoxTypes.Error
    const subtitle = this.props.isReverificationSuccess ? 'Thank you!' : ''

    return (
      <div
        ref={node => {
          this.reverificationCompleteRef = node
        }}
      >
        <PopupNoticeBox
          noticeBoxType={noticeBoxType}
          title={message}
          subtitle={subtitle}
          closeText='continue'
          onClose={this.onClose}
        />
      </div>
    )
  }
}
