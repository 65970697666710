import {
  SearchResult,
  isSearchResultAcceptor,
  isSearchResultRace,
  isSearchResultSport,
  SearchResultAcceptor,
  SearchResultRace,
  isSearchResultFobEvent,
  isSearchResultFobProposition,
  SearchResultFobEvent,
  SearchResultFobProposition,
} from '@core/Data/Search/Search'
import dayjs from 'dayjs'

export const ONE_HOUR = 3598_000 // Fudge it less than an hour so it only shows 59m, not 1hr
export const ONE_DAY = 86_400_000

interface GetAstDetailsInterface {
  advertisedStartTime: Date
  showCountDown: boolean
  showDay: boolean
}

export function getAstDetails(date: string, currentDateMilli: number): GetAstDetailsInterface {
  const advertisedStartTime = new Date(date)
  const milliToAst = advertisedStartTime.getTime() - currentDateMilli
  const showCountDown: boolean = milliToAst < ONE_HOUR
  const showDay: boolean = milliToAst > ONE_DAY

  return {
    advertisedStartTime,
    showCountDown,
    showDay,
  }
}

export function getRedirectUrl(searchResult: SearchResult): string {
  if (isSearchResultAcceptor(searchResult)) {
    const acceptorResult = searchResult as SearchResultAcceptor
    return `#tote/meetings/${acceptorResult.acceptor.race.fixture.id}/${acceptorResult.acceptor.race.number}?date=${acceptorResult.acceptor.race.fixture.date}`
  }

  if (isSearchResultRace(searchResult)) {
    const raceResult = searchResult as SearchResultRace
    return `#tote/meetings/${raceResult.race.fixture.id}/${raceResult.race.number}?date=${raceResult.race.fixture.date}`
  }

  if (isSearchResultSport(searchResult)) {
    return '#sports'
  }

  if (isSearchResultFobEvent(searchResult)) {
    const { fobEvent } = searchResult as SearchResultFobEvent
    if (!fobEvent) return '#'

    const { startTime, productCode, contestType, sequence, competitionSeq } = fobEvent
    const date = dayjs(startTime).format('YYYY-MM-DD')

    if (productCode === 'FR')
      return `#fobracing/propositions/FR/${contestType}/${sequence}?selectiondate=${date}`
    if (productCode === 'CLG')
      return `#fobracing/propositions/CLG/${contestType}/${sequence}?selectiondate=${date}`
    if (productCode === 'FOO')
      return `#fobracing/propositions/FOO/${contestType}/${sequence}?selectiondate=${date}&meetingSeq=${competitionSeq}`
    if (productCode === 'FFF')
      return `#fobracing/propositions/FFF/${contestType}/${sequence}?selectiondate=${date}&meetingSeq=${competitionSeq}`

    return `#`
  }

  if (isSearchResultFobProposition(searchResult)) {
    const { fobProposition } = searchResult as SearchResultFobProposition
    const { contestType, productCode, sequence, startTime, competitionSeq } =
      fobProposition.fobEvent
    const date = dayjs(startTime).format('YYYY-MM-DD')

    return `#fobracing/propositions/${productCode}/${contestType}/${sequence}?selectiondate=${date}&meetingSeq=${competitionSeq}`
  }

  return ''
}
