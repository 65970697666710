import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import { fetchThenMap } from './utils'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { Observable } from 'rx'

export interface FeaturedItem {
  text: string
  url: string
  iconPathName: keyof typeof iconPaths
  internalTrackingCode: string | null
}

interface FeaturedItemWireResource {
  Text: string
  StartDate: string
  EndDate: string
  Link: string
  IconName: string
  IsInducement: boolean
  InternalTrackingCode: string | null
}

export enum FeaturedItemsKeys {
  WithInducements,
  WithoutInducements,
}

/* istanbul ignore next */
function getPathNameFromIconName(iconName: string): keyof typeof iconPaths {
  switch (iconName) {
    case 'Alpine Sports':
      return 'alpinesports'
    case 'Athletics':
      return 'athletics'
    case 'Australian Football':
      return 'footballau'
    case 'Badminton':
      return 'badminton'
    case 'Bandy':
      return 'bandy'
    case 'Baseball':
      return 'baseball'
    case 'Basketball':
      return 'basketball'
    case 'Boxing':
      return 'boxing'
    case 'Cricket':
      return 'cricket'
    case 'Cycling':
      return 'cycling'
    case 'Darts':
      return 'darts'
    case 'Dogs':
      return 'dog'
    case 'Election':
      return 'elections'
    case 'Entertainment':
      return 'entertainment'
    case 'E-sports':
      return 'esports'
    case 'Financial':
      return 'financial'
    case 'Futsal':
      return 'fustal'
    case 'Golf':
      return 'golf'
    case 'Gridiron':
      return 'footballus'
    case 'Handball':
      return 'handball'
    case 'Hockey':
      return 'hockey'
    case 'House':
      return 'house'
    case 'Ice Hockey':
      return 'hockeyice'
    case 'Info':
      return 'info'
    case 'Ironman':
      return 'surfironman'
    case 'Jackpot':
      return 'jackpot'
    case 'Lawn Bowls':
      return 'lawnbowls'
    case 'Martial Arts':
      return 'martialarts'
    case 'Medal':
      return 'commonwealthgames'
    case 'Motorsports':
      return 'motorsport'
    case 'Multimate':
      return 'accumulator'
    case 'Mystery':
      return 'mystery'
    case 'Netball':
      return 'netball'
    case 'Pencil':
      return 'pencil'
    case 'Person':
      return 'person'
    case 'Pesapallo':
      return 'pesapallo'
    case 'Races':
      return 'races'
    case 'Rugby League':
      return 'rugby'
    case 'Rugby Union':
      return 'rugbyunion'
    case 'Sailing':
      return 'yachting'
    case 'Special':
      return 'specials'
    case 'Snooker/Pool':
      return 'snooker'
    case 'Soccer':
      return 'footballlight'
    case 'Squash':
      return 'squash'
    case 'Surfing':
      return 'surfing'
    case 'Swimming':
      return 'swimming'
    case 'Table Tennis':
      return 'tabletennis'
    case 'Tennis':
      return 'tennis'
    case 'Trots':
      return 'trot'
    case 'Trophy':
      return 'featureraces'
    case 'Volleyball':
      return 'volleyball'
    case 'Winter Sports':
      return 'wintersports'
    default:
      return 'tab' //  TAB will default to this so doesn't need own case statement
  }
}

export function map(resource: FeaturedItemWireResource): FeaturedItem {
  return {
    text: resource.Text,
    url: resource.Link,
    iconPathName: getPathNameFromIconName(resource.IconName),
    internalTrackingCode: resource.InternalTrackingCode,
  }
}

function keyCreator(mappedItems: FeaturedItemWireResource[]) {
  return {
    WithInducements: mappedItems.filter(item => item.IsInducement),
    WithoutInducements: mappedItems.filter(item => !item.IsInducement),
  }
}

const typeKey = 'featuredItems'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FeaturedItemsRepo = declareResourceType<FeaturedItem[], any>({
  typeKey,
  fetchMultipleNoPromise: keys =>
    fetchThenMap<FeaturedItemWireResource, FeaturedItem>(
      keys[0],
      'featured-items',
      typeKey,
      map,
      keyCreator
    ) as Observable<{
      keys: string[]
      result?: IKeyMap<IKeyMap<Function>> | undefined
      error?: Error | undefined
    }>,
  softTimeToLive: 60 * 1000,
})
