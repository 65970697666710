import React from 'react'
import { Button } from '@mobi/component-library/Common/V2'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import {
  DepositHeaderStyled,
  DepositHeaderGridCellStyled,
  DepositHeaderTitle,
} from './DepositHeader.styles'

/**
 * Display a header for the Deposit screen, with an optional close
 * button
 */
export const DepositHeader = ({ title, onClose }: { title: string; onClose?: VoidFunction }) => {
  return (
    <DepositHeaderStyled>
      {onClose && (
        <DepositHeaderGridCellStyled area='close'>
          <Button
            aria-label='Close deposit'
            color='secondary_grey'
            isIconOnlyButton={true}
            onClick={onClose}
            size='xs'
          >
            <Icon name='SolidChevronLeft' />
          </Button>
        </DepositHeaderGridCellStyled>
      )}
      <DepositHeaderGridCellStyled area='title'>
        <DepositHeaderTitle>{title}</DepositHeaderTitle>
      </DepositHeaderGridCellStyled>
    </DepositHeaderStyled>
  )
}
