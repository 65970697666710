import { useQuery } from 'react-query'
import { getContactDetails } from './api'
import { BetAccountHolder } from '@mobi/api-types'
import { queryKeys } from '../ReactQuery/constants'

export const useContactDetails = (accountNumber: number | null) =>
  useQuery<BetAccountHolder>(
    queryKeys.accountContactDetails(accountNumber || 0),
    getContactDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60, // 1hr
      enabled: !!accountNumber,
    }
  )
