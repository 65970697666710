import styled from '@emotion/styled'
import { LinkStyled } from '@core/Components/Links/Links.styles'
import { hexColors } from '@mobi/settings'
import { Text } from '@core/Components/Text'
import { tap } from '@mobi/utils'

export const RacePromotionTextStyled = tap(
  styled(Text)({
    lineHeight: 1.1818,
    margin: 0,
  }),
  component => {
    component.defaultProps = {
      as: 'p',
      size: 'sm',
    }
  }
)

export const ExternalLinkStyled = styled(LinkStyled)({
  alignItems: 'center',
  color: hexColors.studio,
  display: 'inline-flex',
  marginTop: '0.5em',

  '> svg': {
    marginLeft: '0.25em',
  },
})
