import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import Guard from '@classic/AppUtils/Framework/Guard'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IMultiSelectionPriceViewModel } from './IMultiSelectionPriceViewModel'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { ObservableStarter } from '@classic/Betting-v2/Model/Observables/ObservableStarter'
import { PriceChangeDisplay } from '@core/Components/PriceChangeDisplay'

@injectable()
export class MultiSelectionPriceViewModel
  extends Disposable
  implements IMultiSelectionPriceViewModel
{
  public bettingInformation!: BettingInformation
  public starter!: ObservableStarter
  public isFixedOddsOn!: ko.Observable<boolean>
  public shouldDisplayScratchedFixedOdds!: ko.PureComputed<boolean>
  public shouldDisplaySuspendedFixedOdds!: ko.PureComputed<boolean>
  public shouldDisplayWinFixedOdds!: ko.PureComputed<boolean>
  public shouldDisplayPlaceFixedOdds!: ko.PureComputed<boolean>
  public shouldDisplayStackedPrices!: ko.PureComputed<boolean>
  public PriceChangeDisplay: React.ReactNode

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)

    this.PriceChangeDisplay = PriceChangeDisplay
  }

  public init(params: { starter: ObservableStarter; bettingInformation: BettingInformation }) {
    Guard.notNull(params.starter)
    Guard.notNull(params.bettingInformation)

    this.starter = params.starter
    this.bettingInformation = params.bettingInformation
    this.isFixedOddsOn = ko.observable<boolean>(false)

    this.shouldDisplayScratchedFixedOdds = ko.pureComputed<boolean>(
      () => this.starter.hasFixedOdds() && this.starter.fixedOddsStarterInfo.isScratched()
    )
    this.shouldDisplaySuspendedFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.starter.hasFixedOdds() &&
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        !this.starter.fixedOddsStarterInfo.hasWinDividend()
    )
    this.shouldDisplayWinFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.starter.hasFixedOdds() &&
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        this.starter.fixedOddsStarterInfo.hasWinDividend()
    )
    this.shouldDisplayPlaceFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.starter.hasFixedOdds() &&
        !this.starter.fixedOddsStarterInfo.isScratched() &&
        this.starter.fixedOddsStarterInfo.hasWinDividend() &&
        this.starter.fixedOddsStarterInfo.hasPlacePool()
    )
    this.shouldDisplayStackedPrices = ko.pureComputed<boolean>(
      () =>
        this.bettingInformation.selectedBetType().isExacta() ||
        this.bettingInformation.selectedBetType().isQuinella() ||
        this.bettingInformation.rovingBanker() ||
        this.bettingInformation.isBoxed() ||
        this.bettingInformation.selectedBetType().isMultiLegType() ||
        this.bettingInformation.selectedBetType().isAllUp()
    )

    this.registerEventHandlers()
  }

  private registerEventHandlers() {
    this.safeSubscribe('fixed-odds-display-state-changed', (isOn: boolean) => {
      this.isFixedOddsOn(isOn)
    })
  }
}
