import React from 'react'
import dayjs from 'dayjs'
import { Ticket, Transaction } from '@core/Areas/AccountActivity/types'
import { DateHeaderLabelStyled } from './DateHeader.styles'

export const DATE_HEADER_DATA_SELECTOR = 'data-js-account-activity-date-header'

type DateHeaderProps = {
  latestDateRef: React.MutableRefObject<string | null>
  isFirstDate: boolean
} & (
  | {
      timestamp: Transaction['timestamp']
      isOrderByEvent?: never
      eventStartDateTime?: never
    }
  | {
      timestamp: Ticket['timestamp']
      eventStartDateTime: Ticket['eventStartDateTime']
      isOrderByEvent: boolean
    }
)

export const DateHeader: React.FC<DateHeaderProps> = ({
  latestDateRef,
  isOrderByEvent,
  timestamp,
  eventStartDateTime,
  isFirstDate,
}) => {
  const ticketDate = isOrderByEvent ? eventStartDateTime : timestamp
  const ticketDisplayDate = dayjs(ticketDate).isValid()
    ? dayjs(ticketDate).startOf('day').format('ddd, DD MMM YYYY')
    : ''

  const shouldHideDate = isFirstDate || latestDateRef.current === ticketDisplayDate

  if (!latestDateRef.current || latestDateRef.current !== ticketDisplayDate) {
    latestDateRef.current = ticketDisplayDate
  }

  if (shouldHideDate) return null

  const dateDataSelectorWithValue = { [DATE_HEADER_DATA_SELECTOR]: '' }

  return (
    <div data-tid-date-header>
      <DateHeaderLabelStyled data-tid-order-by-event-date={isOrderByEvent}>
        <span data-testid='label-value'>
          {isOrderByEvent === undefined ? 'Date' : isOrderByEvent ? 'Event Start' : 'Bet Placed'}
        </span>{' '}
        <span data-testid='date-value' {...dateDataSelectorWithValue}>
          {ticketDisplayDate}
        </span>
      </DateHeaderLabelStyled>
    </div>
  )
}
