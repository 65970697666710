import styled from '@emotion/styled'

export const AccountActivityContainerStyled = styled.div({
  position: 'relative',
  margin: '0.5rem',
})

export const CenterContentStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.2rem',
  padding: '2rem',
})
