import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const StructuredSearchResultPrimaryInfoStyled = styled('span')({
  fontSize: '1.2rem',
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const StructuredSearchResultSecondaryInfoStyled = styled('span')({
  color: colors.search.result.secondary,
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '1.0rem',
})

export const StructuredSearchResultInfoHeaderStyled = styled('div')({
  borderBottom: `1px solid ${colors.search.result.border}`,
  textAlign: 'right',
  padding: '0.25rem 1rem',
})

export const StructuredSearchFixedOddsLabelStyled = styled('span')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: colors.structuredSearch.resultDetails.fixed,
})

export const DriverStyled = styled(StructuredSearchResultSecondaryInfoStyled)({
  marginRight: '3px',
  display: 'inline',
})

export const StructuredSearchResultStyled = styled('div')({
  fontSize: '1.2rem',
  backgroundColor: colors.search.result.background,
  padding: '0.5rem 0.75rem',
  borderBottom: `1px solid ${colors.search.result.border}`,
})

export const NoResultsStyled = styled('div')({
  fontSize: '1.8rem',
  transform: 'translate(0%, 90%)',
  textAlign: 'center',
})
