import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const BankAccountContainerStyled = styled('section')({
  fontSize: '1.2rem',
  padding: '2rem',
})

export const BankNameStyled = styled('p')({
  fontSize: '2.2rem',
  margin: 0,
})

export const BankBranchStyled = styled('p')({
  fontSize: '1.6rem',
  margin: '0.5rem 0',
})

export const BankBranchEmptyStyled = styled('p')({
  fontSize: '1.4rem',
  margin: '0.5rem 1',
})

export const BankDetailDescriptionTermStyled = styled('dt')({
  borderTop: `1px solid ${hexColors.veryLightGrey}`,
  padding: '1rem 0 0 0',
  color: hexColors.dimGrey,
  width: '100%',
  float: 'none',
})

export const BankDetailDescriptionDetailsStyled = styled('dd')({
  fontSize: '1.4rem',
  margin: 0,
  padding: '0.5rem 0 0.75rem 0',
  width: '100%',
  float: 'none',
})
