export const listen =
  'M85 157.4c3.7 28.3 44.5 17.8 63.4 19.6 11.1 0 21.4-8.8 22.7-19.6l5.4-44.8c3.5-23.2-2.8-60.7-5.4-84C167.3.3 126.6 10.8 107.7 9A23.2 23.2 0 0 0 85 28.6l-5.4 44.7c-3.5 23.3 2.8 60.8 5.4 84ZM207.4 88h-20c1.2 18.3-4.7 52.6-6.5 70.6a33 33 0 0 1-32.5 28.4c-25.6-.7-68.7 7.7-73.4-28.4-1.7-18.1-7.6-52-6.4-70.6h-20c-1.1 19.7 4.5 53.9 6.6 73.1 3.4 30.4 33.3 49.4 62.8 45.9v20H88v20h80v-20h-30v-20c29.6 3.5 59.5-15.5 62.8-45.9 2-19.4 7.6-53.3 6.6-73.1Z'

export const watch =
  'M20,24H236c6.65,0,12,5.89,12,13.15v137.7c0,7.26-5.38,13.15-12,13.15H146v17h24c6.64,0,12,5.83,12,13v13H74V218c0-7.2,5.38-13,12-13h24V188H20c-6.65,0-12-5.89-12-13.15V37.15C8,29.89,13.38,24,20,24Zm6,20V168H230V44Z'

export const blog =
  'M92 9.3v29.9C160 39 216.7 95.7 216.1 164h30C247 80.8 176.3 8.4 92 9.3ZM94.4 62H92v29.3a73.7 73.7 0 0 1 72.3 72.2c1.7 1 27.6.5 29.4.3 1.5-53.7-46-102.4-99.3-101.8ZM29 63.3c-11.1.3-19.5 8.7-19 21.5V174c-5 61.1 70.8 98.9 111.4 51.6 32.8-31.7 21.1-93.4-23.6-107-11.6-2.8-28.7-7.8-37.7 3.4-5.2 7-6 14.3-2.4 22 3.3 7 9.3 10.6 18 10.4 18-.9 29.5 14.8 25 31.4-6.8 30.1-50.5 25.8-51.5-4.8-.3-4.2-.2-80-.2-98.1.3-12-9-19.7-19.9-19.6Z'

export const play =
  'M207.86 117.05L65.57 21.21c-10-6.85-22.76 0-22.76 10.95v191.68c0 10.95 12.8 17.8 22.76 10.95L207.86 139a13.91 13.91 0 000-21.9z'

export const stop =
  'M237.51 229.69a7.34 7.34 0 01-6.88 7.82H26.31a7.34 7.34 0 01-7.82-6.88V26.31a7.34 7.34 0 016.88-7.82h204.32a7.34 7.34 0 017.82 6.88v204.32z'
