import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { IRaceReplayViewModel } from './IRaceReplayViewModel'
import ObservableRaceReplay from '@classic/Betting-v2/Model/Observables/ObservableRaceReplay'
import { LogInHelper } from '@classic/AppUtils/Framework/Utils/LogInHelper'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IApiService } from '@classic/Foundation/Services/ApiServiceInjectable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import {
  trackRaceReplayVideoClosed,
  trackRaceReplayVideoLaunched,
  trackRaceReplayVideoMute,
} from '@core/Areas/Racing/Components/RaceReplay/analytics'

@injectable()
export class RaceReplayViewModel extends Disposable implements IRaceReplayViewModel {
  public id!: string
  public videoSource!: ko.Observable<string>
  public message!: ko.Observable<string>
  public raceReplayData!: ObservableRaceReplay

  private apiService: IApiService
  private isMuted = false
  private source: string = ''

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('IApiService') apiService: IApiService
  ) {
    super(eventAggregator)
    this.apiService = apiService
  }

  public init = (params: {
    id: string
    raceReplayData: ObservableRaceReplay
    source: string
  }): void => {
    this.id = params.id
    this.raceReplayData = params.raceReplayData
    this.source = params.source
    this.videoSource = ko.observable('')
    this.message = ko.observable('')

    this.safeSubscribe(`toggle-race-replay-for-${this.id}`, () => {
      this.toggleVideo()
    })
    this.safeSubscribe(`stop-race-replay-for-${this.id}`, () => {
      if (this.isVideoOn()) {
        this.closeVideo()
      }
    })
    this.safeSubscribe(`stop-all-race-replay`, () => {
      if (this.isVideoOn()) {
        this.closeVideo()
      }
    })
  }

  public visible = (): boolean => {
    return this.isVideoOn() || this.shouldDisplayMessage()
  }

  public isVideoOn = (): boolean => {
    return this.videoSource() != null && this.videoSource().length > 0
  }

  public shouldDisplayMessage = (): boolean => {
    return this.message() != null && this.message().length > 0
  }

  public closeVideo = (): void => {
    this.message('')
    this.videoSource('')
    this.evtAggregator.publish(`race-replay-stopped-for-${this.id}`)
    trackRaceReplayVideoClosed()
  }

  public trackVideoPlay = (): void => {
    const replayLocation = this.source
    trackRaceReplayVideoLaunched(replayLocation, true)
  }

  public trackVolumeChange = (_: unknown, event: Event): void => {
    const target = event.currentTarget as HTMLVideoElement

    const isMutedNew = target.volume <= 0.1 || target.muted
    if (this.isMuted != isMutedNew) trackRaceReplayVideoMute(isMutedNew)
    this.isMuted = isMutedNew
  }

  public toggleVideo = (): void => {
    if (this.isVideoOn()) {
      this.closeVideo()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let promise = this.apiService.post<any>({
        url: '/api/videoentitlement',
        body: { encrpytedVideoKey: this.raceReplayData.videoKey() },
      })

      promise
        .then(model => {
          if (model.IsEntitled) {
            this.evtAggregator.publish(`stop-all-race-replay`)
            this.message('')
            this.videoSource(model.VideoKey)
            this.evtAggregator.publish(`race-replay-started-for-${this.id}`)
          } else {
            if (model.CallToActionLoginRequired) {
              new LogInHelper().presentLogIn()
            } else {
              this.message(model.ReasonText)
            }
          }
        })
        .catch(() => {
          this.videoSource('')
          this.message('Error checking entitlement to replay.')
        })
    }
  }
}
