/** Sets hard-coded width to fix iOS issue on output image */
export function setTemporaryWidthOfPreviewElement(el: HTMLDivElement): ResetTemporaryWidthFn {
  if (!el?.style) return () => undefined
  el.style.width = el.getBoundingClientRect().width + 'px'
  const reset = () => {
    if (!el?.style) return
    el.style.width = 'auto'
  }
  return reset
}

type ResetTemporaryWidthFn = () => void
