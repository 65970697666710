import React from 'react'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'

const enum LocalConstants {
  PriceChangeUpClass = 'js-price--up',
  PriceChangeDownClass = 'js-price--down',
  PriceChangeActiveClass = 'js-price--changed',
}

export const Price: React.FC<{ value: number }> = ({ value }) => {
  const prevPriceRef = React.useRef(value)
  const priceChangeDirectionRef = React.useRef<PriceChangeDirection>(null)

  const [isChanging, setIsChanging] = React.useState<boolean>(false)

  const shouldRenderChange = useRenderTimeoutControl({ timeoutMs: 400, shouldRender: isChanging })

  React.useEffect(() => {
    const prevPrice = prevPriceRef.current
    prevPriceRef.current = value
    if (prevPrice === value) return
    priceChangeDirectionRef.current = prevPrice < value ? 'UP' : 'DOWN'
    setIsChanging(true)
  }, [value])

  React.useEffect(() => {
    if (!isChanging) return
    const timerId = setTimeout(() => setIsChanging(false), 6_000)
    return () => clearTimeout(timerId)
  }, [isChanging])

  const className = isChanging ? getPriceChangeClass(priceChangeDirectionRef) : ''

  return (
    <PriceStyled className={className}>
      <span>{value}</span>

      <span>
        <span>
          {shouldRenderChange && priceChangeDirectionRef.current === 'UP' && (
            <Icon name='SolidTriangle' size='0.8rem' color={colors.success[500]} />
          )}
        </span>
        <span>
          {shouldRenderChange && priceChangeDirectionRef.current === 'DOWN' && (
            <Icon name='SolidTriangle' size='0.8rem' color={colors.error[600]} />
          )}
        </span>
      </span>
    </PriceStyled>
  )
}

// =============
// Local Helpers
// =============

function getPriceChangeClass(directionRef: React.MutableRefObject<PriceChangeDirection>): string {
  if (directionRef.current === 'UP') return LocalConstants.PriceChangeUpClass
  if (directionRef.current === 'DOWN') return LocalConstants.PriceChangeDownClass
  return ''
}

// ======
// Styles
// ======

const PriceStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  fontSize: font.size.lg.fontSize,
  fontWeight: font.weight.medium,
  letterSpacing: font.size.lg.letterSpacing,
  lineHeight: 1,
  color: colors.black,

  // Price Value
  '> span:nth-of-type(1)': {
    transition: 'all ease 400ms',
  },

  // Change Indicators
  '> span:nth-of-type(2)': {
    position: 'absolute',
    right: 0,
    transition: 'opacity ease 400ms',
    opacity: 0,

    '> span': { display: 'flex', alignItems: 'center' },
    '> span:last-of-type': { transform: 'rotate(180deg)' },
  },

  [`&.${LocalConstants.PriceChangeUpClass}`]: {
    '> span:nth-of-type(1)': { color: colors.success[500], transform: 'translateX(-1.2rem)' },
    '> span:nth-of-type(2)': { opacity: 1 },
  },

  [`&.${LocalConstants.PriceChangeDownClass}`]: {
    '> span:nth-of-type(1)': { color: colors.error[600], transform: 'translateX(-1.2rem)' },
    '> span:nth-of-type(2)': { opacity: 1 },
  },
})

// =====
// Types
// =====

type PriceChangeDirection = 'UP' | 'DOWN' | null
