import React, { useMemo } from 'react'
import { useRacePromotions } from '@core/Areas/Racing/Hooks/RacePromotion'
import RacePromotion from '@core/Areas/Racing/Components/RacePromotion/RacePromotion'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { filterRacePromotionsByEntitlement } from '@core/Utils/race-promotions'
import type { IdentifiableRace } from '@core/Areas/Racing/Types'

export const RacePromotions = (props: IdentifiableRace): Nullable<JSX.Element> => {
  const { promotions, isLoading, isError } = useRacePromotions(props)
  const isEntitled = useInducementService()

  const entitledPromotions = useMemo(
    () => filterRacePromotionsByEntitlement(promotions, isEntitled),
    [promotions, isEntitled]
  )

  const shouldRender = !isLoading && !isError && entitledPromotions.length > 0

  if (!shouldRender) {
    return null
  }

  return (
    <>
      {entitledPromotions.map((promotion, i) => {
        return <RacePromotion key={i} {...promotion} />
      })}
    </>
  )
}
