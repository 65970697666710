import React from 'react'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { SelectionInfo } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Components'
import { FooterInvestment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/Components'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { SkyRaceHeader } from '../SkyRaceHeader'
import { getMatchedSkyRaceForTicketLeg } from '../../helpers/getMatchedSkyRaceForTicketLeg'

export const GeneralTicket: React.FC<{
  ticket: Ticket
  nextSkyRaces: NextSkyRace[]
  ticketType: ReturnType<typeof getTicketLayoutType>
}> = ({ ticket, nextSkyRaces, ticketType }) => {
  const isSingleRaceTicket =
    ticketType === 'single-single' ||
    ticketType === 'single-multiple' ||
    ticketType === 'same-race-multi'

  const matchedSkyRace =
    isSingleRaceTicket && getMatchedSkyRaceForTicketLeg(ticket.legs[0], nextSkyRaces)

  const isSkyRaceMatch = !!matchedSkyRace

  return (
    <TicketDisplayContext.Provider
      value={{ type: 'pending-bets-drawer', data: { isSkyRaceMatch } }}
    >
      <WrapperStyled data-testid='SkyPendingTicket'>
        {isSkyRaceMatch && isSingleRaceTicket && <SkyRaceHeader nextSkyRace={matchedSkyRace} />}

        <section>
          <SelectionInfo ticket={ticket} />
        </section>

        <footer>
          <FooterInvestment ticket={ticket} status='Pending' />
        </footer>
      </WrapperStyled>
    </TicketDisplayContext.Provider>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  '> section': {
    display: 'flex',
    padding: '1rem',
    userSelect: 'none',

    h3: {
      fontFamily: font.family.primary,
      fontSize: font.size.sm.fontSize,
      letterSpacing: font.size.sm.letterSpacing,
      lineHeight: font.size.sm.lineHeight,
      fontWeight: font.weight.semibold,
    },
  },

  '> footer': {
    borderTop: '0.5px solid ' + colors.neutral[200],
  },
})
