import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const ListStyled = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: '0.5rem',
})

interface ItemStyledProps {
  hasFatalError: boolean
}

export const BETSLIP_ITEM_PADDING = '1rem'
export const BETSLIP_ITEM_BOX_SHADOW = `0 0.1rem 0.2rem ${colors.betslip.item.boxShadow}`

export const ItemStyled = styled('li')<ItemStyledProps>(
  {
    marginBottom: '0.5rem',
    padding: BETSLIP_ITEM_PADDING,
    boxShadow: BETSLIP_ITEM_BOX_SHADOW,
    backgroundColor: colors.betslip.item.background,
    color: colors.betslip.item.text,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  ({ hasFatalError = false }) =>
    hasFatalError && {
      backgroundColor: colors.betslip.item.disabled.background,
      color: colors.betslip.item.disabled.text,

      '& [data-tid-notice-box]': {
        marginBottom: 0,
      },

      '& [data-tid-betting-header]': {
        paddingBottom: '0.5rem',
      },

      '& [data-tid-remove-button]': {
        color: colors.betslip.removeButton.disabled.color,
      },
    }
)

export const SingleItemNoticeWrapper = styled('div')({
  backgroundColor: colors.betslip.errors.noticeBox.background,
  color: colors.betslip.errors.noticeBox.text,
})

export const TotalCostSectionStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '2.7rem',
  fontSize: '1.2rem',
  color: colors.betslip.multiDetails.label.color,
})

export const TotalCostMoneyStyled = styled('span')({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: colors.betslip.item.text,
})
