import styled, { type StyledComponent } from '@emotion/styled'
import { Link, type LinkProps } from 'react-router-dom'
import { MainContainerStyled } from '@core/Components/Containers/MainContainer.styles'
import { fontFamilies, fontFamiliesBold } from '@core/Settings'
import { colors, hexColors } from '@mobi/settings'

export const HOME_CSS_SHARED_VALUES = {
  spacingY: '1rem',
  spacingX: '0.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0 0.1rem 0.1rem rgba(0,0,0,0.1)',
}

export const HomeMainContainerStyled = styled(MainContainerStyled)({
  display: 'flex',
  flexDirection: 'column',
})

export const BannerCardStyled = styled('div')({
  overflow: 'hidden',
  boxSizing: 'border-box',
  margin: `${HOME_CSS_SHARED_VALUES.spacingY} ${HOME_CSS_SHARED_VALUES.spacingX}`,
  borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
  border: `0.2rem solid ${hexColors.blueDiamond}`,
  boxShadow: 'rgb(0 0 0 / 20%) 0 0.1rem 0.3rem 0, rgb(0 0 0 / 40%) 0 0.1rem 0.2rem 0',
  background: hexColors.blueDiamond,
})

// Horizontal Scroll

export const HomeScrollWrapperStyled = styled('div')({
  background: hexColors.gainsboro,
  paddingBottom: HOME_CSS_SHARED_VALUES.spacingY,

  h3: {
    color: colors.brand.primary,
  },
})

export const HomeScrollHeaderStyled = styled('h3')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '3rem',
  margin: 0,
  padding: 0,
  paddingLeft: HOME_CSS_SHARED_VALUES.spacingX,
  fontFamily: fontFamiliesBold.brand,
  fontSize: '1.4rem',
  textTransform: 'none',
  lineHeight: 1,
  color: 'inherit',
  background: 'none',
})

export const HomeScrollableContainerStyled = styled('div')({
  boxSizing: 'border-box',
  overflowX: 'auto',
  width: '100%',
  paddingLeft: HOME_CSS_SHARED_VALUES.spacingX,
  paddingBottom: HOME_CSS_SHARED_VALUES.spacingY,
  scrollBehavior: 'smooth',
  WebkitOverflowScrolling: 'touch',
  WebkitTapHighlightColor: 'transparent',
})

export const HomeScrollListItemsStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  margin: 0,
  padding: 0,
  listStyle: 'none',

  '& > *:last-child': {
    marginRight: 0,
  },

  '&::after': {
    content: '""',
    display: 'block',
    minWidth: HOME_CSS_SHARED_VALUES.spacingX,
  },
})

export const HomeScrollHeaderLinkStyled: StyledComponent<LinkProps> = styled(Link)({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: HOME_CSS_SHARED_VALUES.spacingX,
  paddingRight: HOME_CSS_SHARED_VALUES.spacingX,
  fontFamily: fontFamilies.brand,
  fontSize: '1.4rem',
  color: 'inherit',
})
