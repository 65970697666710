import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { Keypress, KeyPressed, KeypadModes } from '@core/Components/Keypad/KeyPress'
import { calculateInvestment } from '@core/Areas/Quickbet/helpers/calculator'

export interface QuickEditState {
  value: number
  lastKeyPressed: KeyPressed
  secondLastKeyPressed: KeyPressed
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  bonusBet: BonusBet | null
}

export interface BonusBet {
  campaignId: number
  value: number
}

export const defaultQuickEditState: QuickEditState = {
  value: 0,
  lastKeyPressed: {
    mode: KeypadModes.Denomination,
    value: 0,
  },
  secondLastKeyPressed: {
    mode: KeypadModes.Denomination,
    value: 0,
  },
  isUsingBonusCash: true,
  isUsingBonusBet: false,
  bonusBet: null,
}

export const InitialiseQuickEditState = createSignal<QuickEditState>('InitialiseQuickEditState')
export const SetQuickEditInvestment = createSignal<number>('InitMultiFormulaInvestment')
export const ToggleBonusBet = createSignal<boolean | undefined>('ToggleBonusBet')
export const ToggleBonusCash = createSignal<boolean | undefined>('ToggleBonusCash')
export const SetBonusBet = createSignal<BonusBet | null>('SetBonusBet')
export const SetQuickEditValue = createSignal<number>('SetQuickEditValue')

export interface QuickEditStateRecord extends TypedRecord<QuickEditStateRecord>, QuickEditState {}
export const QuickEditStateFactory = makeTypedFactory<QuickEditState, QuickEditStateRecord>(
  defaultQuickEditState
)

export function quickEditDriver(
  state = QuickEditStateFactory(),
  signal: Signal
): QuickEditStateRecord {
  switch (signal.tag) {
    case InitialiseQuickEditState: {
      return state.merge({
        ...defaultQuickEditState,
      })
    }

    case Keypress: {
      const currentState: QuickEditState = { ...state.toJS() }
      const lastKeyPressed = currentState.lastKeyPressed
      const secondLastKeyPressed = currentState.secondLastKeyPressed
      const currentValue = currentState.value
      const newValue = calculateInvestment(
        signal.data,
        currentValue,
        lastKeyPressed,
        secondLastKeyPressed
      )
      const newState: QuickEditState = {
        ...currentState,
        value: newValue,
        secondLastKeyPressed: lastKeyPressed,
        lastKeyPressed: signal.data,
      }
      return state.merge(newState)
    }

    case SetQuickEditInvestment: {
      return state.merge({
        ...defaultQuickEditState,
        value: signal.data,
        lastKeyPressed: {
          mode: KeypadModes.Denomination,
          value: 0,
        },
      })
    }

    case ToggleBonusBet: {
      var forcedValue = signal.data
      return state.merge({
        isUsingBonusBet: forcedValue != undefined ? forcedValue : !state.get('isUsingBonusBet'),
        value: 0,
      })
    }

    case ToggleBonusCash: {
      var forcedValue = signal.data
      return state.merge({
        isUsingBonusCash: forcedValue != undefined ? forcedValue : !state.get('isUsingBonusCash'),
        value: 0,
      })
    }

    case SetBonusBet: {
      const bonusBet = signal.data as BonusBet
      return state.merge({
        bonusBet: bonusBet,
        isUsingBonusBet: bonusBet ? true : state.isUsingBonusBet,
        value: bonusBet?.value ?? 0,
      })
    }

    case SetQuickEditValue: {
      return state.merge({
        value: signal.data,
      })
    }

    default: {
      return state
    }
  }
}

export const state$ = attachDriver<QuickEditStateRecord>({
  path: 'betslipQuickEdit',
  driver: quickEditDriver,
})
