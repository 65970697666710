import { store } from '@core/Store'
import { setMeetingsRaceCode } from '@core/Areas/Meetings/Store'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { closeModal } from '@core/Components/Modal'
import { state$ as navState$ } from '../driver'

const enum LocalConstants {
  MeetingsPageHash = '#tote',
}

export const navigateToLastRacesPath = () => {
  navState$.take(1).subscribe(navState => {
    store.dispatch(closeModal({ id: QUICKBET_MODAL_ID }))

    const lastRacesPath = navState.get('lastRacesPath')
    const currentHashPath = navState.get('currentUrl')

    const newRacesPath =
      currentHashPath === lastRacesPath ? LocalConstants.MeetingsPageHash : lastRacesPath

    if (currentHashPath.split('?')[0] === LocalConstants.MeetingsPageHash) {
      store.dispatch(setMeetingsRaceCode('races'))
    }

    window.location.href = newRacesPath
  })
}
