import styled from '@emotion/styled'

export const WinPlaceButtonsContainerStyled = styled.div({
  float: 'right',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2rem',

  '& > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '5rem',
    height: '5rem',
    marginLeft: '0.3rem',
    textAlign: 'center',
  },

  '& > button': {
    marginLeft: '0.3rem',
  },
})

export const WinPlaceSpacerSpanStyled = styled.span({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '5rem',
  height: '5rem',
})

export const WinPlaceScratchedStyled = styled(WinPlaceSpacerSpanStyled)({
  color: '#fe0000',
  fontWeight: 'bold',
})

const ToteSwapOptionBaseStyled = styled.span<{
  isVisible?: boolean
}>({
  display: 'block',
  position: 'absolute',
  top: 0,
  right: 0,
  width: '5rem',
  height: '5rem',
  transition: 'transform cubic-bezier(0.76, 0, 0.24, 1) 300ms',
})

export const WinPlaceToteStyled = styled(ToteSwapOptionBaseStyled)(
  { transform: 'translateX(0) scale(0)' },
  ({ isVisible }) =>
    isVisible !== undefined && {
      transform: isVisible ? 'translateX(0)' : 'translateX(150%) scale(0.8)',
    }
)

export const WinPlaceToteSwapOptionsStyled = styled(ToteSwapOptionBaseStyled)<{
  isVisible?: boolean
}>(
  { transform: 'translateX(150%) scale(0)' },
  ({ isVisible }) =>
    isVisible !== undefined && {
      transform: isVisible ? 'translateX(0)' : 'translateX(150%) scale(0.8)',
    }
)
