/**
 * Pattern to validate a deposit amount
 *
 * This pattern can check for up to 8 digits and 2 decimal places if they are provided
 * Decimal places are optional unless a cent separator is given, from which the
 * decimal places become mandatory
 *
 * @example
 *
 * ```ts
 * depositAmountInputValidationRegex.test('1') // true
 * depositAmountInputValidationRegex.test('12') // true
 * depositAmountInputValidationRegex.test('123') // true
 * depositAmountInputValidationRegex.test('1.23') // true
 * depositAmountInputValidationRegex.test('12.34') // true
 * depositAmountInputValidationRegex.test('123.45') // true
 * depositAmountInputValidationRegex.test('123.') // false
 * depositAmountInputValidationRegex.test('123456789') // false
 * depositAmountInputValidationRegex.test('123.1') // false
 * depositAmountInputValidationRegex.test('$123') // false
 * depositAmountInputValidationRegex.test('🦎') // false
 * depositAmountInputValidationRegex.test('🍺') // false
 * ```
 */
export const depositAmountInputValidationRegex = /^\d{1,8}(?:\.\d{2})?$/
