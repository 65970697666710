import { get, post } from '@classic/Foundation/Services/ApiService'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { UseQueryResult, useQuery } from 'react-query'

export interface BankAccountResponse {
  bankName: string
  branchName: string
  bsb: string
  accountNumber: string
  accountName: string
  accountHolderSurname: string
  isPendingWithdrawals: boolean
}

export const useBankDetailsQuery = (): UseQueryResult<BankAccountResponse> => {
  return useQuery(
    queryKeys.getBankAccount,
    () => get<BankAccountResponse>({ url: 'api/account/bankaccount' }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      cacheTime: 0,
      staleTime: 2 * 1000,
    }
  )
}

export const isBankAccountRegistered = (
  data: BankAccountResponse | undefined
): data is BankAccountResponse => {
  return data !== undefined && data?.bankName?.trim().length > 0
}

export const useBankDetailsFromBsbQuery = (
  bsb: string
): UseQueryResult<BankDetailsFromBsbResponse> => {
  return useQuery<BankDetailsFromBsbResponse>(
    `getBankAccountFromBsb-${bsb}`,
    () => get<BankDetailsFromBsbResponse>({ url: `/api/account/getbankdetailsfrombsb/${bsb}` }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      cacheTime: 0,
      staleTime: 2 * 1000,
    }
  )
}

export const isBankDetailsFromBsbFound = (
  data: BankDetailsFromBsbResponse | undefined
): data is BankDetailsFromBsbResponse => {
  return data !== undefined && data.isSuccessful && data.bankName.trim().length > 0
}

interface UpdateBankAccountRequest {
  bankName: string
  branchName: string
  bsb: string
  accountNumber: string
  accountName: string
}

interface UpdateBankAccountResponse {
  isSuccessful: boolean
}

export const postUpdateBankAccount = async (
  bankAccount: UpdateBankAccountRequest
): Promise<UpdateBankAccountResponse> => {
  return await post<UpdateBankAccountResponse>({
    url: '/api/account/updatebankaccount',
    body: bankAccount,
  })
}

export interface BankDetailsFromBsbResponse {
  isSuccessful: boolean
  bsb: string
  bankName: string
  branchName: string
}
