import React, { type SVGAttributes, Suspense } from 'react'
import styled from '@emotion/styled'
import { Icons } from './library'

type Props = {
  name: keyof typeof Icons
  color?: string
  size?: string
} & SVGAttributes<SVGSVGElement>

export const Icon: React.FC<Props> = ({
  name,
  color = 'inherit',
  size = '2rem',
  ...svgAttributes
}) => {
  const IconComponent = Icons[name]
  if (!IconComponent) return null

  return (
    <IconWrapper style={{ fontSize: size, width: size, height: size, color }}>
      <Suspense fallback={null}>
        <IconComponent {...svgAttributes} />
      </Suspense>
    </IconWrapper>
  )
}

const IconWrapper = styled.span({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,

  svg: { maxWidth: '100%', height: 'auto' },
})
