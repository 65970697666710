import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const ContactDetailsContainer = styled('section')({
  fontSize: '1.2rem',
  padding: '2rem',
})

export const ContactDetailsPreferredNameStyled = styled('p')({
  fontSize: '2.2rem',
  margin: 0,
})

export const ContactDetailsItemStyled = styled('p')({
  fontSize: '1.6rem',
  margin: '0.5rem 0',
})

export const ContactDetailsViewLabel = styled('dt')({
  borderTop: `1px solid ${hexColors.veryLightGrey}`,
  padding: '1rem 0 0 0',
  color: hexColors.dimGrey,
  width: '100%',
  float: 'none', // reset for desktop
})

export const ContactDetailsViewItem = styled('dd')({
  fontSize: '1.4rem',
  margin: 0,
  padding: '0.5rem 0 0.75rem 0',
  width: '100%',
  float: 'none', // reset for desktop
})

export const ContactDetailsTitleLabelStyled = styled('p')({
  fontSize: '1.2rem',
  color: hexColors.dimGrey,
  margin: '1rem 0 0.25rem',
})

export const ContactDetailsEditLabelStyled = styled('label')({
  display: 'block',
  color: hexColors.dimGrey,
  fontSize: '1.2rem',
})

export const ContactDetailsManualAddressButton = styled('button')({
  float: 'right',
  alignItems: 'normal',
  backgroundColor: 'rgba(0,0,0,0)',
  borderStyle: 'none',
  color: hexColors.sushi,
  font: 'inherit',
  padding: 0,
  textDecoration: 'underline',
  MozAppearance: 'none',
})

export const NoticeBoxInternationalStyled = styled('div')({
  boxSizing: 'border-box',
  padding: '0.2rem 1.5rem',
  border: `2px solid ${hexColors.black}`,
  lineHeight: 2,
})

export const VerifyTextContainerStyled = styled.div({
  margin: '1rem 0',
  textAlign: 'center',
})
