import * as DateTimeProvider from '@classic/Foundation/DateTimeProvider'

const enum LocalConstants {
  OneHour = 3_598_000, // Fudge it less than an hour so it only shows 59m, not 1hr
  OneDay = 86_400_000,
}

export const getNextRaceTimeDisplayOptions: GetNextRaceTimeDisplayOptions = dateString => {
  const advertisedStartTime = new Date(dateString)
  const milliToAst = advertisedStartTime.getTime() - DateTimeProvider.now()
  const showCountDown = milliToAst < LocalConstants.OneHour
  const showDay = milliToAst > LocalConstants.OneDay

  return {
    advertisedStartTime,
    showCountDown,
    showDay,
  }
}

type GetNextRaceTimeDisplayOptions = (dateString: string) => {
  advertisedStartTime: Date
  showCountDown: boolean
  showDay: boolean
}
