import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const SuggestionsContainerStyled = styled.div({
  backgroundColor: hexColors.white,
  border: `1px solid ${hexColors.veryLightGrey}`,
  borderTop: 0,
  boxSizing: 'border-box',
  margin: '-1rem 0 1rem 0',
  width: '100%',

  '& ul': {
    // override user-agent styles for the <ul>
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  '&:empty': {
    border: 0,
  },
})

export const SuggestionStyled = styled.div<{ isHighlighted: boolean }>(({ isHighlighted }) => ({
  backgroundColor: isHighlighted ? hexColors.gainsboro : undefined,
  padding: '1rem',
  fontSize: '1.4rem',
}))
