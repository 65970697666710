import React from 'react'

export const Mastercard = () => (
  <svg
    width='24'
    height='14'
    viewBox='0 0 24 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    role='img'
    aria-label='Mastercard'
  >
    <path d='M15.0564 1.50781H8.94385V12.4923H15.0564V1.50781Z' fill='#FF5F00' />
    <path
      d='M9.33193 6.99949C9.33107 5.94158 9.57076 4.89743 10.0331 3.94579C10.4953 2.99428 11.168 2.16038 12.0001 1.50725C10.9696 0.697204 9.73195 0.193458 8.42862 0.0535745C7.12531 -0.0863213 5.80892 0.143296 4.62994 0.716197C3.45095 1.28908 2.45693 2.18212 1.76149 3.29324C1.06605 4.40441 0.697266 5.68874 0.697266 6.99949C0.697266 8.31036 1.06605 9.59469 1.76149 10.7058C2.45693 11.8169 3.45095 12.7099 4.62994 13.2828C5.80892 13.8557 7.12531 14.0853 8.42862 13.9455C9.73195 13.8056 10.9696 13.3019 12.0001 12.4918C11.168 11.8387 10.4953 11.0047 10.0331 10.0532C9.57076 9.10167 9.33107 8.0574 9.33193 6.99949Z'
      fill='#EB001B'
    />
    <path
      d='M23.3026 6.9995C23.3027 8.31036 22.9339 9.5947 22.2385 10.7058C21.5431 11.8169 20.5492 12.7099 19.3701 13.2828C18.1912 13.8557 16.8748 14.0853 15.5715 13.9455C14.2683 13.8056 13.0306 13.3019 12 12.4918C12.8315 11.838 13.5036 11.004 13.9659 10.0526C14.4281 9.10119 14.6682 8.05728 14.6682 6.9995C14.6682 5.94183 14.4281 4.89793 13.9659 3.94653C13.5036 2.99509 12.8315 2.16105 12 1.50726C13.0306 0.697209 14.2683 0.193451 15.5715 0.0535675C16.8748 -0.0863159 18.1912 0.143314 19.3701 0.716214C20.5492 1.2891 21.5431 2.18215 22.2385 3.29327C22.9339 4.40441 23.3027 5.68875 23.3026 6.9995Z'
      fill='#F79E1B'
    />
  </svg>
)
