import React from 'react'
import { ProcessingMessageStyled } from './ProcessingMessage.styles'
import type { DepositSource } from '@mobi/component-library/Deposit/types'

type Props = {
  isDepositing: boolean
  depositSource: DepositSource
}

export const ProcessingMessage = ({ isDepositing, depositSource }: Props) => {
  return (
    <ProcessingMessageStyled role='status'>
      {getProcessingMessageForPaymentMethod(depositSource, isDepositing)}
    </ProcessingMessageStyled>
  )
}

function getProcessingMessageForPaymentMethod(depositSource: DepositSource, isDepositing: boolean) {
  if (isDepositing) {
    return 'Processing...'
  }

  switch (depositSource) {
    case 'PayPal':
      return 'Redirecting you to PayPal.'
    case 'CreditCard':
      return 'Verifying with your bank.'
    default:
      return 'Processing...'
  }
}
