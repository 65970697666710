import React from 'react'
import {
  BalanceInfoBarContainerStyled,
  BalanceInfoBarStyled,
  BonusBalanceMoneyStyled,
} from './BalanceBar.styles'
import { Money } from '@core/Components/Text/Money'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { hasValue } from '@mobi/utils'

export const BalanceInfoBar = (): JSX.Element | null => {
  const {
    accountBalance: balance,
    bonusBetBalance,
    bonusCashBalance,
  } = useObservableImmutable(userAccountState$, [
    'accountBalance',
    'bonusBetBalance',
    'bonusCashBalance',
  ])

  const bonusBalance = (bonusBetBalance ?? 0) + (bonusCashBalance ?? 0)

  return hasValue(balance) ? (
    <BalanceInfoBarContainerStyled>
      <BalanceInfoBarStyled>
        <Money amount={balance} />
        {!!bonusBalance && <BonusBalanceMoneyStyled prefix={'Bonus: '} amount={bonusBalance} />}
      </BalanceInfoBarStyled>
    </BalanceInfoBarContainerStyled>
  ) : null
}
