import * as ko from 'knockout'
import { ObservableStarter } from './ObservableStarter'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableStarter } from './IObservableStarter'

export default class ObservableTrotStarter extends ObservableStarter {
  constructor() {
    super()
    // @ts-expect-error Type issue
    this.handicap = ko.observable()
  }

  merge(other: ObservableTrotStarter) {
    Guard.notNull(other)
    super.merge(other)

    this.handicap(other.handicap())
  }

  public untypedMerge(other: IObservableStarter) {
    this.merge(other as ObservableTrotStarter)
  }

  public handicap: ko.Observable<string>
}
