import { SIGNUP_ROOT_ROUTE } from '@core/Areas/Account/SignUp/constants'
import { BannerApiResource, BannerArea } from '../types'

interface FilterBannersForArea {
  areaName: BannerArea
  accountNumber: number | null
  data: BannerApiResource[]
}

export function filterBannersForArea({
  areaName,
  accountNumber,
  data,
}: FilterBannersForArea): BannerApiResource[] {
  const filteredBanners = data.filter(
    item =>
      // Ensure Images are Available
      !!item.Image &&
      !!item.Image.File &&
      (areaName === 'Desktop' || (!!item.ImageWide && !!item.ImageWide.File)) &&
      //
      // When Location Includes Area, Keep in Array
      item.Location.some(location => location.includes(areaName)) &&
      //
      // When Item is Flagged as Inducement, Keep if Account Number is Present
      (item.IsInducement ? accountNumber !== null : true) &&
      //
      // Remove Sign Up if Logged In
      (item.Link?.toLowerCase().includes(`/#${SIGNUP_ROOT_ROUTE.toLowerCase()}`) ||
      item.DesktopLink?.toLowerCase().includes(`/${SIGNUP_ROOT_ROUTE.toLowerCase()}`)
        ? accountNumber === null
        : true) &&
      //
      // When A/B Targeting, Keep Correct Segment Based on Account Number
      filterABSegments(item, accountNumber)
  )
  return filteredBanners.some(item => item.IsInducement)
    ? filteredBanners.filter(item => item.IsInducement)
    : filteredBanners
}

// =============
// Local Helpers
// =============

function filterABSegments(
  item: BannerApiResource,
  accountNumber: FilterBannersForArea['accountNumber']
): boolean {
  // Don't Filter if No ABGrouping is Present
  if (!item.AbGroupingSegment) return true

  // Only use A/B Testing when Logged In
  if (accountNumber === null) return false

  const targetSegment = accountNumber % 2 === 0 ? 'a' : 'b'

  return item.AbGroupingSegment.toLowerCase() === targetSegment
}
