import React from 'react'
import { store } from '@core/Store'
import { useAppDispatch } from '@core/Store/hooks'
import { setIsControlShowing, type SkyVideoPlayerState } from '@core/Areas/SkyVideoPlayer/Store'

const enum LocalConstants {
  TimeToHideControlsSec = 5000,
}

export const useControlToggle = ({
  isPlaying,
  isMuted,
  isFullscreen,
  isControlShowing,
  selectedChannel,
  topBarElement,
  bottomBarElement,
}: {
  isPlaying: boolean
  isMuted: boolean
  isFullscreen: boolean
  isControlShowing: boolean
  selectedChannel: SkyVideoPlayerState['selectedChannel']
  topBarElement: HTMLDivElement | null
  bottomBarElement: HTMLDivElement | null
}) => {
  const timerIdRef = React.useRef<number>()
  const dispatch = useAppDispatch()

  // On unmount, clear timeout
  React.useEffect(() => () => clearTimeout(timerIdRef.current), [])

  // Show Controls on Player State Change
  React.useEffect(() => {
    dispatch(setIsControlShowing(true))
    timerIdRef.current = hideControlsAfterTimeout(hideControls, timerIdRef.current)
  }, [isPlaying, isMuted, isFullscreen, selectedChannel, dispatch])

  // Hide Controls After Set Time
  React.useEffect(() => {
    if (!isControlShowing) return
    timerIdRef.current = hideControlsAfterTimeout(hideControls, timerIdRef.current)
  }, [isControlShowing, dispatch])

  // Handle Showing Controls
  React.useEffect(() => {
    if (!topBarElement || !bottomBarElement) return
    topBarElement.style.transform = `translateY(${isControlShowing ? '0' : '-100%'})`
    bottomBarElement.style.transform = `translateY(${isControlShowing ? '0' : '100%'})`
  }, [isControlShowing, isPlaying, topBarElement, bottomBarElement])
}

// =============
// Local Helpers
// =============

function hideControlsAfterTimeout(callback: () => void, timerId?: number) {
  clearTimeout(timerId)

  const newTimerId = window.setTimeout(() => {
    callback()
  }, LocalConstants.TimeToHideControlsSec)

  return newTimerId
}

function hideControls() {
  store.dispatch(setIsControlShowing(false))
}
