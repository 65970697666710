import { BettingInformation } from './BettingInformation'
import { ILegacyMultiLegModel } from './ILegacyMultiLegModel'
import { FobSelectionResult } from '../Components/Core/SelectionResults/FobSelectionResult'
import moment from 'moment'

export class LegacyMultiLegModel implements ILegacyMultiLegModel {
  constructor(bettingContext: BettingInformation, legType: string) {
    this.MeetingDate = moment(bettingContext.meetingDate).format('YYYY-MM-DD')
    this.MeetingId = bettingContext.meetingId
    this.RaceNumber = bettingContext.raceNumber
    this.LegType = legType

    var results = bettingContext.results()
    if (bettingContext.isFixed()) {
      this.PropositionNumber = (results as FobSelectionResult).propositionNumber
      this.SelectedStarterNumber = (results as FobSelectionResult).starterNumber
    }
  }

  public MeetingDate: string
  public MeetingId: string
  public RaceNumber: number
  public LegType: string
  public PropositionNumber!: number
  public FobPropositionType!: string
  public SelectedStarterNumber!: number
}
