import { type RefObject, useEffect, useRef, useState } from 'react'
import Hls, { type HlsConfig } from 'hls.js'
import { isReactNativeIos } from '@mobi/web-native-comms/web'
import { isInIosBrowser } from '@classic/Foundation/Services/Device/DeviceIdentificationService'
import type { ErrorCallbackArgs } from '@core/Utils/hooks/Video/errors'

const defaultFallbackOptions: Partial<HlsConfig> = {
  liveSyncDurationCount: 6,
  liveMaxLatencyDurationCount: 10,
  liveBackBufferLength: 1,
  startFragPrefetch: true,
}

type ErrorCallback = (error: ErrorCallbackArgs, hlsInstance?: Hls) => void

type Props = {
  videoRef: RefObject<HTMLVideoElement>
  streamUrl: MaybeDefined<string>
  fallbackOptions?: Partial<HlsConfig>
  onError?: ErrorCallback
}

type PlaybackSupportStatus =
  | 'pending' // Playback support status is yet to be determined
  | 'supported' // Playback is supported, be it natively or through hls
  | 'unsupported' // Playback is not supported, be it natively or through hls

const initUsingHLS = (
  videoElement: HTMLVideoElement,
  options: Partial<HlsConfig> = {},
  onError?: ErrorCallback
): Maybe<Hls> => {
  if (!Hls.isSupported()) {
    return null
  }

  const videoSource = videoElement.src

  const hls = new Hls({
    ...defaultFallbackOptions,
    ...options,
  })

  hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    hls.loadSource(videoSource)
  })

  hls.on(Hls.Events.ERROR, (event, error) => {
    if (error.fatal && error.type === Hls.ErrorTypes.OTHER_ERROR) {
      hls.recoverMediaError()
    }

    onError?.(
      {
        type: 'hlsError',
        error,
      },
      hls
    )
  })

  hls.attachMedia(videoElement)

  return hls
}

export function useVideoStream({
  videoRef,
  streamUrl,
  fallbackOptions,
  onError: onErrorCallback,
}: Props) {
  const hlsRef = useRef<Hls>()
  const [playbackSupportStatus, setPlaybackSupportStatus] =
    useState<PlaybackSupportStatus>('pending')

  useEffect(() => {
    const { current: videoEl } = videoRef

    if (!videoEl || !streamUrl) {
      return undefined
    }

    setPlaybackSupportStatus('pending')

    const initHls = (videoElement: HTMLVideoElement, onError?: ErrorCallback): boolean => {
      const hlsInstance = initUsingHLS(videoElement, fallbackOptions, onError)

      if (hlsInstance) {
        setPlaybackSupportStatus('supported')
        hlsRef.current = hlsInstance

        return true
      }

      setPlaybackSupportStatus('unsupported')

      return false
    }

    const onError = (error: ErrorEvent) => {
      const videoPlayer = error.target as HTMLVideoElement
      const mediaError = videoPlayer.error

      // If the media isn't supported and HLS cannot be initialized (such as on iPhones)
      // then we report the error back to the developer
      if (
        mediaError?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED &&
        !initHls(videoPlayer, onErrorCallback)
      ) {
        onErrorCallback?.({
          type: 'unsupported',
          error: mediaError,
        })
      }
    }

    const onCanPlay = () => {
      setPlaybackSupportStatus('supported')
    }

    // Set up the streaming source, so it's ready for the native load or Hls to pick it up
    videoEl.src = streamUrl
    videoEl.setAttribute('src', streamUrl)
    videoEl.addEventListener('canplay', onCanPlay)

    const isIOS = isReactNativeIos || isInIosBrowser()

    /**
     * Android Native has an issue that causes videos to have extremely poor performance
     * As a workaround we only attempt to use the native implementation on iOS
     * and load the video as is, falling back as needed
     *
     * @link https://rwwa.atlassian.net/browse/MOBI-1580
     */
    if (isIOS) {
      videoEl.addEventListener('error', onError)
      videoEl.load()
    } else if (!initHls(videoEl, onErrorCallback)) {
      onErrorCallback?.({
        type: 'unsupported',
      })
    }

    return () => {
      videoEl.removeEventListener('error', onError)
      videoEl.removeEventListener('canplay', onCanPlay)
      hlsRef.current?.destroy()
    }
  }, [videoRef, fallbackOptions, streamUrl, onErrorCallback])

  return {
    playbackSupportStatus,
    hlsRef,
  }
}

export { Hls }
