import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Money } from '@core/Components/Text/Money'
import { toMoney } from '@mobi/utils/money'
import { SpecialsStyled } from './Specials.styles'

export const Specials: React.FC<{
  specials: Ticket['specialInformation']
  shouldHideCost: boolean
}> = ({ specials, shouldHideCost }) => {
  if (!specials?.length) return null
  const powerPlaySpecials = specials.filter(s => s.isPowerPlay)
  const otherSpecials = specials.filter(s => !s.isPowerPlay)

  return (
    <SpecialsStyled>
      {powerPlaySpecials.length > 0 && (
        <table data-testid='ticket-details-specials-powerplay'>
          <tbody>
            {powerPlaySpecials.map((special, i) => (
              <tr key={i}>
                {
                  <td>
                    <Icon type='specials' color='orange' />
                    <strong>SuperPick: </strong>
                    <span>
                      {special.title}
                      {!shouldHideCost &&
                        special.specialTypeCode === 'WBI' &&
                        ` (${toMoney(special.insuranceReward ?? 0)})`}
                    </span>
                  </td>
                }
                {!shouldHideCost &&
                  special.specialTypeCode !== 'BST' &&
                  special.specialTypeCode !== 'WBI' && (
                    <td className='text-right'>
                      <Money amount={special.projectedReward} />
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {otherSpecials.length > 0 && (
        <table data-tid-other-specials>
          <tbody>
            {otherSpecials.map((special, i) => (
              <React.Fragment key={`otherSpecials-${i}`}>
                <tr>
                  <td style={{ width: '1%' }}>
                    <Icon type='specials' color='orange' />
                  </td>
                  <td>{special.title}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{special.description}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </SpecialsStyled>
  )
}
