import styled from '@emotion/styled'
import { BetInvestmentGroupStyled } from '../BetInvestment/BetInvestment.styles'
import { lessOrEqualThan, greaterThan } from '@mobi/component-library/Utils/media'

export const NumberOfCombinationsDisplayStyled = BetInvestmentGroupStyled.withComponent('section')

const maxNumberOfDigits = 10

const fontSizeFactorDict = {
  // This scale accounts for string lengths from 1 to 10
  x7: 0.7,
  x8: 0.6,
  x9: 0.6,
  [`x${maxNumberOfDigits}`]: 0.5,
  xDefault: 0.75,
} as const

const defaultValueFontSize = 2.6

const isValidFactor = (key: string): key is keyof typeof fontSizeFactorDict => {
  return Object.hasOwn(fontSizeFactorDict, key)
}

const getFontSizeFactor = (digitCount: number) => {
  const sizeFactorKey = `x${digitCount}`

  if (isValidFactor(sizeFactorKey)) {
    return fontSizeFactorDict[sizeFactorKey]
  }

  return fontSizeFactorDict.xDefault
}

export const CombinationsStyled = styled('span')<{ combinations: number }>(
  {
    flex: '1 0',
    fontSize: '2.6rem',
    fontWeight: 'bold',
    textAlign: 'right',
    // Increase the lineHeight to match the fontSize, to prevent artifacts from text above and below the amount of space
    // for the line, as given by the lineHeight (iOS 11 only; see MOBILE-865)
    lineHeight: 'normal',
  },
  ({ combinations }) => {
    const numberOfDigits = combinations.toString().length

    const fontSizeFactor = getFontSizeFactor(numberOfDigits)

    return {
      [lessOrEqualThan('px450')]: {
        fontSize: `${(defaultValueFontSize * fontSizeFactor * 1.25).toFixed(1)}rem`,
      },
      [lessOrEqualThan('px400')]: {
        fontSize: `${(defaultValueFontSize * fontSizeFactor * 1.1).toFixed(1)}rem`,
      },
      [lessOrEqualThan('px350')]: {
        fontSize: `${(defaultValueFontSize * fontSizeFactor).toFixed(1)}rem`,
      },
      [greaterThan('px450')]: {
        // Lower the default font-size to display 10 digits for any viewport that's larger than `px450`.
        fontSize:
          (numberOfDigits === maxNumberOfDigits &&
            `${(defaultValueFontSize * fontSizeFactorDict['xDefault']).toFixed(1)}rem`) ||
          undefined,
      },
    }
  }
)
