import React from 'react'
import { MessageStyled } from './ProvideExtraPersonalIdMessage.styles'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

export function ProvideExtraPersonalIdMessage() {
  return (
    <MessageStyled>
      To increase the success of re-verification please provide additional information such as{' '}
      <strong>Driver's Licence</strong>, <strong>Passport</strong>, <strong>Medicare Card</strong>{' '}
      or call <CustomerServiceTelephone /> for assistance.
    </MessageStyled>
  )
}
