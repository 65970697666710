import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { ISubstitutesViewModel } from './ISubstitutesViewModel'
import template from './substitutes.tpl.html'
import ObservableSubstitutePool from '@classic/Betting-v2/Model/Observables/ObservableSubstitutePool'

export function register(): void {
  if (!ko.components.isRegistered('substitutes-panel')) {
    ko.components.register('substitutes-panel', {
      viewModel: {
        createViewModel: (params: {
          availableSubstitutePools: ko.ObservableArray<ObservableSubstitutePool>
        }): ISubstitutesViewModel => {
          const viewModel = container.get<ISubstitutesViewModel>('ISubstitutesViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
