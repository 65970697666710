import { MutableRefObject } from 'react'
import { Client } from 'braintree-web'
import type { DepositRequest } from '@mobi/api-types'
import { tokenize } from './initApplePayWeb'
import { useDeposit } from '../useDeposit'

type UseApplePayDepositParams = {
  braintreeClient: MutableRefObject<Client | undefined>
}

export function useApplePayDeposit({ braintreeClient }: UseApplePayDepositParams) {
  const { depositMutation } = useDeposit<DepositRequest>({ braintreeClient })
  return {
    tokenize,
    deposit: depositMutation.mutateAsync,
  }
}
