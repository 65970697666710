import type {
  Selection,
  AllUpSelection,
  MysterySelection,
  RacingBetType,
  BetSlipBetRequest,
  BetLegType,
} from '@mobi/betslip/types'
import {
  isMysterySelection,
  isToteSelection,
  isAllUpSelection,
  isFobPropositionSelection,
  isStartingPriceSelection,
  isStartingPriceMatchedSelection,
  isFobSportsSelection,
  isFobMatchedSelection,
  isSameRaceMultiSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { getToteProductPlanSequence } from '@mobi/betslip/helpers/betting'
import {
  ToteRequest,
  AllUpRequest,
  FobSingleRacingRequest,
  RacingSingleRequest,
  MysteryProposeRequest,
  MysteryCommitRequest,
  SameRaceMultiRequest,
  FobSingleSportsRequest,
  FobSingleRequest,
} from '@core/Data/betting'
import { isFavouriteNumbersSelection, isToteSportsSelection } from '@core/Data/Betting/selections'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { determineLegTypeFromInvestments } from './calculator'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'

// Create Betting API Request Object
export function createBetRequest(
  selection: Selection | null,
  id: string,
  investmentWin: number,
  investmentPlace: number,
  isEachWay: boolean,
  selectedSuperPickOffer: BetSpecialOffer,
  campaign: Campaign | undefined,
  formulas: AllUpSelection['formulas'],
  useBonusCash?: boolean,
  isBonusCashImprovementsActive?: boolean
):
  | ToteRequest
  | AllUpRequest
  | FobSingleRequest
  | RacingSingleRequest
  | MysteryProposeRequest
  | MysteryCommitRequest
  | BetSlipBetRequest
  | SameRaceMultiRequest
  | null {
  if (isFavouriteNumbersSelection(selection)) {
    if (selection.bets) {
      return {
        favouriteNumbersBets: selection.bets.map((bet, index) => {
          return {
            fixtureDate: bet.fixtureDate,
            selectionsGameOne: bet.game1Selections.join('.'),
            selectionsGameTwo: bet.game2Selections?.join('.'),
            betTransactionId: id,
            position: index + 1,
          }
        }),
        toteBets: [],
        fixedOddsBets: [],
        allUpBets: [],
        toteSportsBets: [],
      }
    }
  } else if (isToteSportsSelection(selection)) {
    return {
      toteSportsBets: [
        {
          poolSeq: selection.poolSequence,
          selections: selection.betSelections,
          investment: investmentWin,
          betTransactionId: id,
          position: 1,
        },
      ],
      toteBets: [],
      fixedOddsBets: [],
      allUpBets: [],
      favouriteNumbersBets: [],
    }
  } else if (isMysterySelection(selection)) {
    // TODO replace actuallyUseBonusCash with useBonusCash when removing BONUS_CASH_IMPROVEMENTS/bonus-cash-improvements flag
    const actuallyUseBonusCash = isBonusCashImprovementsActive ? useBonusCash : undefined
    if (selection.bets) {
      // If we have bets, then we are confirming, otherwise we are proposing.
      return createMysteryCommitRequest(selection, actuallyUseBonusCash)
    }

    const mysteryInvestWin = selection.mysteryType === 'Combo' ? 1 : investmentWin
    const mysteryInvestWPlace = 0

    return createMysteryProposeRequest(
      selection.fixtureId,
      selection.fixtureDate,
      selection.raceNumber,
      selection.productCode,
      selection.mysteryOption,
      1,
      mysteryInvestWin,
      mysteryInvestWPlace,
      actuallyUseBonusCash
    )
  } else if (isToteSelection(selection)) {
    if (isAllUpSelection(selection)) {
      return createAllUpRequest(
        id,
        selection.fixtureId,
        selection.fixtureDate,
        selection.betType,
        selection,
        investmentWin,
        formulas
      )
    } else {
      return createToteRequest(
        id,
        selection.fixtureId,
        selection.fixtureDate,
        selection.raceNumber,
        selection.selectionString,
        selection.isRovingBanker,
        selection.betType,
        investmentWin,
        investmentPlace,
        useBonusCash
      )
    }
  }

  if (isFobPropositionSelection(selection)) {
    return createFobRequest(
      id,
      selection.propositionSeq.toString(),
      selection.variantSeq as string,
      isEachWay,
      selection.winPrice,
      selection.placePrice as number,
      selection.handicap as number,
      investmentWin,
      investmentPlace,
      selectedSuperPickOffer,
      campaign,
      isStartingPriceSelection(selection)
    )
  }

  if (isFobSportsSelection(selection) && selection.externalBetId) {
    return createFobSportsRequest(
      selection.externalBetId,
      id,
      selection.winPrice,
      investmentWin,
      selectedSuperPickOffer,
      campaign
    )
  }

  if (isStartingPriceMatchedSelection(selection)) {
    const request = createRacingRequest(
      id,
      selection.fixtureId,
      selection.fixtureDate,
      selection.raceNumber,
      null,
      null,
      investmentWin,
      investmentPlace,
      selection.acceptorNumber,
      selectedSuperPickOffer,
      campaign
    )
    request.legType = 'SP'
    return request
  }

  if (isFobMatchedSelection(selection)) {
    return createRacingRequest(
      id,
      selection.fixtureId,
      selection.fixtureDate,
      selection.raceNumber,
      selection.winPrice,
      selection.placePrice as number,
      investmentWin,
      investmentPlace,
      selection.acceptorNumber,
      selectedSuperPickOffer,
      campaign
    )
  }

  if (isSameRaceMultiSelection(selection)) {
    return {
      responseVersion: 2,
      betTransactionId: id,
      fixtureDate: selection.fixtureDate,
      fixtureId: selection.fixtureId,
      raceNumber: selection.raceNumber,

      selectionPrice: selection.winPrice,
      selections: selection.acceptors.map(acceptor => ({
        starter: acceptor.acceptorNumber,
        position: acceptor.legNumber + 1,
      })),

      investment: investmentWin,
      specialSeq: selectedSuperPickOffer ? selectedSuperPickOffer.specialSeq : null,
      selectedCampaign: campaign,
    } as SameRaceMultiRequest
  }

  return null
}

// ==========================
// FOB Racing Matched Request
// ==========================
function createRacingRequest(
  betTransactionId: string,
  fixtureId: string,
  fixtureDate: string,
  raceNumber: number,
  winPrice: number | null,
  placePrice: number | null,
  investmentWin: number,
  investmentPlace: number,
  starterNumber?: number,
  selectedSuperPickOffer?: BetSpecialOffer,
  campaign?: Campaign
): RacingSingleRequest {
  return {
    responseVersion: 2,
    betTransactionId,
    fixtureDate,
    fixtureId,
    raceNumber,
    legType: determineLegTypeFromInvestments(investmentWin, investmentPlace),
    priceWin: winPrice,
    pricePlace: placePrice,
    investmentWin: (investmentWin === 0 ? null : investmentWin) as number,
    investmentPlace: (investmentPlace === 0 ? null : investmentPlace) as number,
    starterNumber,
    specialSeq: selectedSuperPickOffer ? selectedSuperPickOffer.specialSeq : null,
    selectedCampaign: campaign,
  }
}

// =========================
// FOB Sports/Racing Request
// =========================
function createFobRequest(
  betTransactionId: string,
  propositionSeq: string,
  variantSeq: string,
  isEachWay: boolean,
  priceWin: number,
  pricePlace: number,
  handicap: number,
  winInvestment: number,
  placeInvestment: number,
  selectedSuperPickOffer: BetSpecialOffer | undefined,
  selectedCampaign: Campaign | undefined,
  isStartingPrice: boolean
): FobSingleRacingRequest {
  const legType: BetLegType = isStartingPrice
    ? 'SP'
    : isEachWay
      ? 'EW'
      : determineLegTypeFromInvestments(winInvestment, placeInvestment)
  const investmentPlace = isEachWay ? winInvestment : placeInvestment || null
  const investmentWin = winInvestment === 0 ? null : winInvestment

  return {
    responseVersion: 2,
    betTransactionId,
    propositionSeq,
    variantSeq,
    legType,
    priceWin,
    pricePlace,
    handicap,
    investmentWin,
    investmentPlace,
    specialSeq: selectedSuperPickOffer ? selectedSuperPickOffer.specialSeq : null,
    selectedCampaign,
  }
}

export function createFobSportsRequest(
  externalBetId: string,
  betTransactionId: string,
  priceWin: number,
  winInvestment: number,
  selectedSuperPickOffer: BetSpecialOffer | undefined,
  selectedCampaign: Campaign | undefined
): FobSingleSportsRequest {
  const investmentPlace = null
  const investmentWin = winInvestment === 0 ? null : winInvestment

  return {
    responseVersion: 2,
    betTransactionId,
    priceWin,
    investmentWin,
    investmentPlace,
    specialSeq: selectedSuperPickOffer ? selectedSuperPickOffer.specialSeq : null,
    selectedCampaign,
    externalBetId,
  }
}

// ==========================
// Tote Racing All-up Request
// ==========================
export function createAllUpRequest(
  betTransactionId: string,
  fixtureId: string,
  fixtureDate: string,
  betType: RacingBetType,
  selection: AllUpSelection,
  investmentWin: number,
  formulas: AllUpSelection['formulas']
): AllUpRequest {
  const productPlanSeq = getToteProductPlanSequence(betType, false)
  const raceDetails: AllUpSelection['details'] = []
  selection.details.forEach(selectionDetail => {
    if (selectionDetail.poolType === 'EW') {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'W',
        betSelections: selectionDetail.betSelections,
      })
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: 'P',
        betSelections: selectionDetail.betSelections,
      })
    } else {
      raceDetails.push({
        raceNum: selectionDetail.raceNum,
        poolType: selectionDetail.poolType,
        betSelections: selectionDetail.betSelections,
      })
    }
  })
  return {
    betTransactionId,
    productPlanSeq,
    fixtureId,
    fixtureDate,
    raceDetails,
    formulas: formulas.filter(formula => formula.isSelected),
    investment: (investmentWin === 0 ? null : investmentWin) as number,
  }
}

// ===================
// Tote Racing Request
// ===================
function createToteRequest(
  betTransactionId: string,
  fixtureId: string,
  fixtureDate: string,
  raceNumber: number,
  selections: string,
  isRovingBanker: boolean,
  betType: RacingBetType,
  investmentWin?: number,
  investmentPlace?: number,
  useBonusCash?: boolean
): ToteRequest {
  const hasWinInvestment = !!(investmentWin && investmentWin > 0)
  const hasPlaceInvestment = !!(investmentPlace && investmentPlace > 0)
  const productPlanSeq = getToteProductPlanSequence(
    betType,
    false,
    isRovingBanker,
    hasWinInvestment,
    hasPlaceInvestment
  )

  return {
    betTransactionId,
    productPlanSeq,
    fixtureId,
    fixtureDate,
    raceNumber,
    selections,
    investmentWin: (investmentWin === 0 ? null : investmentWin) as number,
    investmentPlace: (investmentPlace === 0 ? null : investmentPlace) as number,
    useBonusCash,
  }
}

function createMysteryProposeRequest(
  fixtureId: string,
  fixtureDate: string,
  raceNumber: number,
  productCode: string,
  option: number,
  numberOfBets: number,
  investmentWin?: number,
  investmentPlace?: number,
  useBonusCash?: boolean
): MysteryProposeRequest {
  return {
    fixtureId,
    fixtureDate,
    raceNumber,
    productCode,
    option,
    numberOfBets,
    investmentWin: investmentWin === 0 ? undefined : investmentWin,
    investmentPlace: investmentPlace === 0 ? undefined : investmentPlace,
    useBonusCash,
  }
}

function createMysteryCommitRequest(
  selection: MysterySelection,
  useBonusCash?: boolean
): MysteryCommitRequest {
  let proposalIds: number[] = []

  selection.bets?.forEach(bet => {
    proposalIds.push(bet.id)
  })

  return {
    proposalIds,
    metaData: selection.metaData as string,
    useBonusCash,
  }
}
