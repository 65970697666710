import Rx from 'rx'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { declareResourceType } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'

export interface ReverificationDetail {
  requiresReverification: boolean
  reverificationDue: boolean
  numDaysRemaining: number | null
  hasAttemptedReverification: boolean
  betAccountHolderNumber: number | null
}

const defaultDetails: ReverificationDetail = {
  requiresReverification: false,
  reverificationDue: false,
  numDaysRemaining: null,
  hasAttemptedReverification: false,
  betAccountHolderNumber: null,
}

export interface ReverificationDetailRecord
  extends TypedRecord<ReverificationDetailRecord>,
    ReverificationDetail {}

export const ReverificationDetailKey = 'me'
const ReverificationDetailsTypeKey = 'reverificationDetails'

export const ReverificationDetails = declareResourceType<ReverificationDetailRecord, unknown>({
  typeKey: ReverificationDetailsTypeKey,
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 200,
  map: x => recordify<ReverificationDetail, ReverificationDetailRecord>(x as ReverificationDetail),
})

function fetchMultipleNoPromise(keys: string[]) {
  return Rx.Observable.fromPromise(
    fetchReverificationDetails()
      .then(response => ({
        [ReverificationDetailsTypeKey]: {
          [ReverificationDetailKey]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(() => ({
        keys,
        result: { [ReverificationDetailsTypeKey]: { [ReverificationDetailKey]: defaultDetails } },
      }))
  )
}

function fetchReverificationDetails() {
  return apiService.get<ReverificationDetail>({ url: '/$_/api/account/reverificationDetails' })
}

interface ConfirmReverificationResponse {
  isReverificationSuccess: boolean
  error: string
}

export function confirmAllReverificationDetails(model: {
  betAccountHolderNumber: number
}): Promise<ConfirmReverificationResponse> {
  return apiService.post<ConfirmReverificationResponse>({
    url: '/$_/api/contactDetails/confirmAllReverificationDetailsAsync',
    body: model,
  })
}
