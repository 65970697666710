import React from 'react'
import {
  BetslipState,
  BetslipStateRecord,
  state$ as betslipState$,
} from '@core/Areas/Betslip/driver'
import {
  FormulaContainerStyled,
  FormulaTextStyled,
  FormulaComboTextStyled,
  FormulaInvestmentStyled,
  FormulaItemStyled,
  FormulaTitleTextStyled,
  FormulaCostChildStyled,
  FormulaCostAmountStyled,
  FormulaCostContainerStyled,
  MultiFormulaExpandablePanelStyled,
} from '@core/Areas/Betslip/Components/MultiFormula/MultiFormula.styles'
import { GridCell, Grid, GridRow } from '@mobi/component-library/Common/Grid'
import { InvestmentAmountStyled } from '@core/Areas/Betslip/Components/BetInvestment/BetInvestment.styles'
import { observeImmutable } from '@core/Components/HOCs'
import {
  QuickEdit,
  QUICK_EDIT_MODAL,
  QuickEditContentTypes,
} from '@core/Areas/Betslip/Components/QuickEdit/QuickEdit'
import { SetQuickEditInvestment } from '@core/Areas/Betslip/Components/QuickEdit/driver'
import {
  MIN_LEGS_IN_MULTI,
  MAX_LEGS_FOR_MULTI_FORMULA,
  MULTI_FORMULA_COMBINATIONS,
} from '@core/Areas/Betslip/constants'
import { getBetsInMulti, isValidMultiInvestmentForLegs } from '@core/Areas/Betslip/helpers/state'
import { store } from '@core/Store'
import { openModal } from '@core/Components/Modal'
import { FormulaExpandablePanelHeaderComponent } from '@core/Components/ExpandablePanel'

enum FormulaTitles {
  f1 = 'Singles',
  f2 = 'Doubles',
  f3 = 'Trebles',
  f4 = 'Pick 4',
  f5 = 'Pick 5',
}

type EnumLiteralsOf<T extends object> = T[keyof T]
export type FormulaFields = EnumLiteralsOf<typeof FormulaFields>
export const FormulaFields = {
  f1: 'f1',
  f2: 'f2',
  f3: 'f3',
  f4: 'f4',
  f5: 'f5',
} as const

interface InvestmentClickArgs {
  investment: number
  subTitle: string
  combos: number
  field: string
  isFieldEditable: boolean
}

const handleInvestmentClick = ({
  investment = 0,
  subTitle,
  combos,
  field,
  isFieldEditable,
}: InvestmentClickArgs): void => {
  if (!isFieldEditable) {
    return
  }
  SetQuickEditInvestment(investment)
  store.dispatch(
    openModal({
      id: QUICK_EDIT_MODAL,
      modalComponent: QuickEdit,
      modalComponentProps: {
        title: 'Formula',
        subTitle,
        combos,
        contentType: QuickEditContentTypes.MultiFormula,
        formulaField: field,
      },
      color: 'dark',
    })
  )
}

type MultiFormulaProps = Pick<BetslipStateRecord, 'multiInvestment'> & {
  isEditable: boolean
  multiItemsCount: number
}

type MultiFormulaComboKeys =
  keyof (typeof MULTI_FORMULA_COMBINATIONS)[keyof typeof MULTI_FORMULA_COMBINATIONS]

export class MultiFormulaComponent extends React.PureComponent<MultiFormulaProps> {
  public render() {
    const { multiItemsCount: count, isEditable, multiInvestment } = this.props

    const shouldEnableSingles = isValidMultiInvestmentForLegs(multiInvestment, count)

    if (multiInvestment.bonusBetId) {
      return `Formulas can't be used with Bonus Bet`
    }

    const formulaFields: JSX.Element[] = []
    for (let i = 1; i <= count - 1; i++) {
      const current: FormulaFields = FormulaFields[`f${i}` as keyof typeof FormulaFields]
      const formulaCombosForLegs =
        MULTI_FORMULA_COMBINATIONS[current][`legs${count}` as MultiFormulaComboKeys]
      const formulaTitle = FormulaTitles[current]
      const formulaInvestment = multiInvestment[current]

      const isFieldEditable = i === 1 ? shouldEnableSingles && isEditable : isEditable

      formulaFields.push(
        <FormulaItemStyled key={formulaTitle} data-testid='formula-item'>
          <Grid>
            <GridRow>
              <GridCell width='33%' valign='middle'>
                <FormulaTitleTextStyled data-tid-formula-title=''>
                  {formulaTitle}
                </FormulaTitleTextStyled>
              </GridCell>
              <GridCell align='center' valign='middle'>
                <FormulaComboTextStyled>
                  Combos{' '}
                  <FormulaTextStyled data-tid-formula-combos=''>
                    x {formulaCombosForLegs}
                  </FormulaTextStyled>
                </FormulaComboTextStyled>
              </GridCell>
              <GridCell width='33%'>
                <FormulaInvestmentStyled
                  data-tid-formula-investment-value=''
                  isEditable={isFieldEditable}
                  onClick={() =>
                    handleInvestmentClick({
                      investment: formulaInvestment,
                      subTitle: formulaTitle,
                      combos: formulaCombosForLegs,
                      field: current,
                      isFieldEditable,
                    })
                  }
                >
                  <InvestmentAmountStyled isEditable={isFieldEditable} amount={formulaInvestment} />
                </FormulaInvestmentStyled>
              </GridCell>
            </GridRow>
          </Grid>
          {formulaInvestment > 0 && (
            <FormulaCostContainerStyled>
              <FormulaCostChildStyled data-tid-formula-cost=''>
                Cost{' '}
                <FormulaCostAmountStyled>
                  ${(formulaInvestment * formulaCombosForLegs).toFixed(2)}
                </FormulaCostAmountStyled>
              </FormulaCostChildStyled>
            </FormulaCostContainerStyled>
          )}
        </FormulaItemStyled>
      )
    }

    return (
      <>
        <FormulaContainerStyled data-testid='formula-container'>
          {formulaFields}
        </FormulaContainerStyled>
      </>
    )
  }
}

export const MultiFormula = observeImmutable<BetslipStateRecord, {}>(
  betslipState$,
  ({ record }) => {
    const betsInMulti = getBetsInMulti(record.items)
    const count = betsInMulti.count()

    if (!(count >= MIN_LEGS_IN_MULTI && count <= MAX_LEGS_FOR_MULTI_FORMULA)) {
      return null
    }

    return (
      <MultiFormulaExpandablePanelStyled>
        <FormulaExpandablePanelHeaderComponent />
        <MultiFormulaComponent
          multiItemsCount={count}
          isEditable={!record.hasProposed && !record.isBusy}
          multiInvestment={(record.toJS() as BetslipState).multiInvestment}
        />
      </MultiFormulaExpandablePanelStyled>
    )
  }
)
