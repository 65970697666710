import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { hexColors } from '@mobi/settings'

const urgencyColors: Record<RadialCountdownArcFilledStyledProps['urgency'], string> = {
  high: hexColors.freeSpeechRed,
  medium: hexColors.orange,
  low: hexColors.sushi,
}

export const RadialCountdownContainerStyled = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1.5em',
  justifyContent: 'center',
  lineHeight: 1,
  userSelect: 'none',
})

export const RadialCountdownWrapStyled = styled('div')({
  backgroundColor: hexColors.white,
  borderRadius: '50%',
  display: 'inline-block',
  position: 'relative',
})

export const RadialCountdownTextStyled = styled('div')({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  fontSize: '1.2rem',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  left: 0,
  margin: 'auto',
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  top: 0,
})

export const RadialCountdownArcStyled = styled('svg')({
  fill: 'none',
  strokeLinecap: 'round',
  transform: 'rotate(-90deg)',
  width: '6rem',
  height: '6rem',
})

export const RadialCountdownArcBackgroundStyled = styled('circle')({
  stroke: hexColors.veryLightGrey,
})

interface RadialCountdownArcFilledStyledProps {
  offset: number
  urgency: 'high' | 'medium' | 'low'
}

const fillArc = (offset: number) =>
  keyframes({
    '0%': {
      strokeDashoffset: '0',
    },
    '100%': {
      strokeDashoffset: `${offset}`,
    },
  })

export const RadialCountdownArcFillStyled = styled('circle')<RadialCountdownArcFilledStyledProps>(
  {
    transition: 'stroke-dashoffset 9000ms ease-in-out',
  },

  ({ offset, urgency }) => ({
    animation: `${fillArc(offset)} 0.5s ease-out forwards`,
    stroke: urgencyColors[urgency],
  })
)
