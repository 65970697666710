import React from 'react'
import { Button } from '@mobi/component-library/Common/Buttons'
import { BetAmountButtonsContainerStyled } from '../MysteryBetPage.styles'

interface BetAmountButtonsProps {
  isDisabled: boolean
  onAmountSelected: (amount: number) => void
  onClear: () => void
}

export const BetAmountButtons = ({
  isDisabled,
  onAmountSelected,
  onClear,
}: BetAmountButtonsProps): JSX.Element => {
  return (
    <BetAmountButtonsContainerStyled>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => {
          onAmountSelected(0.5)
        }}
        data-tid-numpad-50c
        data-tid-amt-50c
      >
        + 50c
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(1)}
        data-tid-amt-1
      >
        + $1
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(2)}
        data-tid-amt-2
      >
        + $2
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(5)}
        data-tid-amt-5
      >
        + $5
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(10)}
        data-tid-amt-10
      >
        + $10
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(20)}
        data-tid-amt-20
      >
        + $20
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={() => onAmountSelected(50)}
        data-tid-amt-50
      >
        + $50
      </Button>
      <Button
        type='button'
        color='secondary'
        disabled={isDisabled}
        onClick={onClear}
        data-tid-amt-clear
      >
        Clear
      </Button>
    </BetAmountButtonsContainerStyled>
  )
}
