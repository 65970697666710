import React, { useEffect } from 'react'
import type { RacePageDTO } from '@mobi/api-types'
import { CompactExpandablePanel } from '@core/Components/ExpandablePanel'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { RaceCardIcon } from '@core/Components/Icons'
import {
  RaceContentBadgeStyled,
  RaceContentContainerStyled,
  RaceContentTextStyled,
} from './RaceContentPanel.styles'
import {
  trackRacePreviewClosed,
  trackRacePreviewLoaded,
  trackRacePreviewOpened,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useOnce } from '@core/Utils/hooks/useOnce'
import { Provider } from 'react-redux'
import { store } from '@core/Store'
import { useRaceContent } from '@core/Areas/Racing/Hooks/RaceContent'
import type { IdentifiableRace } from '@core/Areas/Racing/Types'
import { TipsterImage } from '@core/Components/TipsterImage'
import { BettableTips } from '@core/Areas/Racing/Components/BettableTips/BettableTips'

type Props = IdentifiableRace & {
  raceKey: string
  isRaceOpen: boolean
  race?: RacePageDTO
}

type SuggestedBetProps = {
  text?: string
  badge?: string
}

export const RaceContentPanel = (props: Props): Nullable<JSX.Element> => {
  const onLoaded = useOnce(trackRacePreviewLoaded, props)
  const onOpened = useOnce(trackRacePreviewOpened, props)
  const onClosed = useOnce(trackRacePreviewClosed, props)
  const { isRaceOpen, raceKey, meetingId, meetingDate, raceNumber, race } = props
  const { isLoading, isError, content } = useRaceContent(meetingId, meetingDate, raceNumber)

  useEffect(() => {
    if (content?.racePreview) {
      onLoaded()
    }
  }, [content?.racePreview, onLoaded])

  if (isLoading || isError || !content?.racePreview) {
    return null
  }

  const onExpansionChanged = (expanded: boolean): void => {
    const trackingFunction = expanded ? onOpened : onClosed
    trackingFunction()
  }

  const expertTips = (content.racePreview.numbers ?? []).length > 0

  return (
    <Provider store={store}>
      <CompactExpandablePanel
        themeName={ExpandablePanelThemes.lavender}
        headerText={content.title}
        headerIcon={<RaceCardIcon icon='chat' />}
        data-testid={`race-preview`}
        onExpansionChanged={onExpansionChanged}
      >
        <RaceContentContainerStyled>
          {!!content.tipsterProfilePictureUrl && (
            <TipsterImage
              src={content.tipsterProfilePictureUrl}
              alt={"Picture of this meeting's expert"}
            />
          )}

          <RaceContentTextStyled>{content.racePreview.previewText}</RaceContentTextStyled>
        </RaceContentContainerStyled>

        {expertTips && (
          <>
            <br />
            <BettableTips
              tips={content.racePreview.numbers ?? []}
              isRaceOpen={isRaceOpen}
              raceKey={raceKey}
              race={race}
              tipSource='race-preview'
            />
          </>
        )}

        <SuggestedBet text={content.racePreview.suggestedBet} badge={content.racePreview.badge} />
      </CompactExpandablePanel>
    </Provider>
  )
}

const SuggestedBet = ({ text, badge }: SuggestedBetProps): Nullable<JSX.Element> => {
  if (!text) {
    return null
  }

  return (
    <>
      <br />
      <RaceContentTextStyled>
        <strong>Suggested Bet:</strong> {text}
        {badge ? <RaceContentBadgeStyled>{badge}</RaceContentBadgeStyled> : ''}
      </RaceContentTextStyled>
    </>
  )
}
