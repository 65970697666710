import React from 'react'
import { state$ as kambiState$ } from '../driver'

export const useShowBrowserUnsupported = (): boolean => {
  const [shouldShowBrowserUnsupported, setShouldShowBrowserUnsupported] = React.useState(false)

  React.useEffect(() => {
    const subscription = kambiState$
      .map(state => state.get('isBrowserSupported'))
      .distinctUntilChanged()
      .subscribe(isBrowserSupported => {
        setShouldShowBrowserUnsupported(!isBrowserSupported)
      })
    return () => {
      subscription.dispose()
    }
  })

  return shouldShowBrowserUnsupported
}
