import React from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { colors, spacing } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  ExpandedButtonClass = 'js-expandable-btn--expanded',
}

// ****
// ONLY add buttons here that are used in BOTH BetSlip + Transacted
// ****

export const ShareButton: React.FC<ButtonProps> = ({ onClick, disabled }) => (
  <Button size='xs' color='tertiary_grey' isIconOnlyButton onClick={onClick} disabled={disabled}>
    <Icon name='LineShare02' size='1.6rem' color={colors.black} />
  </Button>
)

export const ExpandableContentButton: React.FC<ButtonProps & { isExpanded: boolean }> = ({
  onClick,
  disabled,
  isExpanded,
  children,
}) => (
  <ExpandableButtonStyled>
    <Button
      size='xs'
      color='tertiary_grey'
      onClick={onClick}
      isIconOnlyButton
      disabled={disabled}
      className={isExpanded ? LocalConstants.ExpandedButtonClass : ''}
    >
      <Icon size='1.6rem' name='SolidChevronDown' /> {children}
    </Button>
  </ExpandableButtonStyled>
)

// ======
// Styles
// ======

const ExpandableButtonStyled = styled.span({
  '> button': {
    paddingLeft: spacing.smx2,
    paddingRight: spacing.smx2,

    svg: { transition: 'transform ease 0.3s' },
    [`&.${LocalConstants.ExpandedButtonClass} svg`]: { transform: 'rotate(-180deg)' },
  },
})

// =====
// Types
// =====

type ButtonProps = Pick<React.ComponentProps<typeof Button>, 'onClick' | 'disabled'>
