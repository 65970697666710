import React from 'react'
import { useRenderTimeoutControl } from '../useRenderTimeoutControl'

export const useExpandToggle = (
  timeoutMs: number,
  shouldStartExpanded?: boolean
): {
  wrapperRef: React.RefObject<HTMLDivElement>
  contentRef: React.RefObject<HTMLDivElement>
  shouldRender: boolean
  isExpanded: boolean
  toggleExpanded: (shouldForceExpandValue?: boolean) => void
} => {
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const contentRef = React.useRef<HTMLDivElement>(null)
  const timeoutMsRef = React.useRef<number>(timeoutMs)

  const [isExpanded, setIsExpanded] = React.useState(!!shouldStartExpanded)

  const shouldRender = useRenderTimeoutControl({ shouldRender: isExpanded, timeoutMs })

  React.useEffect(() => {
    if (!contentRef.current || !wrapperRef.current) return undefined

    const wrapperEl = wrapperRef.current
    const contentEl = contentRef.current

    if (!isExpanded) {
      wrapperEl.style.height = contentEl.offsetHeight + 'px'
    }

    const animationTimeoutId = setTimeout(() => {
      requestAnimationFrame(() => {
        wrapperEl.style.height = `${isExpanded ? contentEl.offsetHeight : 0}px`
      })
    }, 50)

    let autoHeightTimeoutId: ReturnType<typeof setTimeout>
    if (isExpanded) {
      autoHeightTimeoutId = setTimeout(() => {
        wrapperEl.style.height = 'auto'
      }, timeoutMsRef.current + 50)
    }

    return () => {
      autoHeightTimeoutId && clearTimeout(autoHeightTimeoutId)
      clearTimeout(animationTimeoutId)
    }
  }, [isExpanded])

  const toggleExpanded = React.useCallback((shouldForceExpandValue: boolean | null = null) => {
    typeof shouldForceExpandValue === 'boolean'
      ? setIsExpanded(shouldForceExpandValue)
      : setIsExpanded(curr => !curr)
  }, [])

  return {
    wrapperRef,
    contentRef,
    shouldRender,
    isExpanded,
    toggleExpanded,
  }
}
