import React, { type FormEventHandler } from 'react'
import Rx from 'rx'
import { observeImmutable } from '../../../../Components/HOCs/observe'
import { InputField } from '@mobi/component-library/Common/Input'
import { SelectField } from '@mobi/component-library/Common/Select'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { state$ as reverificationState$ } from '../../../Reverification/driver'
import {
  state$ as contactDetailsState$,
  ContactDetailsState,
  ValidationErrors,
  ChangeValue,
  ContactDetailsFields,
  ValidateField,
} from '../../../ContactDetails/driver'
import { CountrySelect } from '../CountrySelect/CountrySelect'
import { HorizontalDivider, SectionTitle, CheckboxContainer } from './AdditionalDetails.styles'
import { Medicare } from './Medicare'
import { PastReverificationMessage } from '../../../Reverification/Components/PastReverificationMessage'
import { statesInAustralia } from '@mobi/settings/src/statesInAustralia'

interface AdditionalDetailsFields {
  driversLicenceNumber: string
  driversLicenceState: string
  driversLicenceCardNumber: string
  passportNumber: string
  passportCountry: string
  isAdditionalDetailsChanged: boolean
  agreeToVerifyAdditionalDetails: boolean
  medicareBlueYellowExpiryDate: Date | null
  medicareGreenExpiry: string
  medicareCardColour: string
  medicareMiddleNameOnCard: string
  medicareNumber: string
  medicareReference: string
  validationErrors: ValidationErrors
  isReverificationSuccess: boolean
}

export type AdditionalDetailsProps = Pick<ContactDetailsState, keyof AdditionalDetailsFields> // this is for type safety, when ContactDetailsState changes it forces to update AdditionalDetailsFields

const state$ = Rx.Observable.combineLatest(
  contactDetailsState$,
  reverificationState$,
  (contactDetails, reverfication) => ({ contactDetails, reverfication })
)

export const AdditionalDetails = observeImmutable(state$, ({ record }) => {
  const { requiresReverification, hasAttemptedReverification } = record.reverfication

  if (requiresReverification && hasAttemptedReverification) {
    return <AdditionalDetailsComponent {...record.contactDetails.toJS()} />
  }

  return null
})

export function validateFields() {
  const validationFields: Array<keyof ContactDetailsFields> = [
    'passportCountry',
    'passportNumber',
    'driversLicenceState',
    'driversLicenceNumber',
    'driversLicenceCardNumber',
  ]
  validationFields.forEach(field => ValidateField({ field }))
}

export function AdditionalDetailsComponent({
  driversLicenceNumber,
  driversLicenceState,
  driversLicenceCardNumber,
  passportNumber,
  passportCountry,
  isAdditionalDetailsChanged,
  agreeToVerifyAdditionalDetails,
  medicareBlueYellowExpiryDate,
  medicareGreenExpiry,
  medicareCardColour,
  medicareNumber,
  medicareReference,
  medicareMiddleNameOnCard,
  validationErrors,
  isReverificationSuccess,
}: AdditionalDetailsProps) {
  const changeDriversLicenceState: FormEventHandler<HTMLSelectElement> = event =>
    ChangeValue({ field: 'driversLicenceState', value: event.currentTarget.value })
  const changeDriversLicenceNumber: FormEventHandler<HTMLInputElement> = event =>
    ChangeValue({ field: 'driversLicenceNumber', value: event.currentTarget.value })
  const changeDriversLicenceCardNumber: FormEventHandler<HTMLInputElement> = event =>
    ChangeValue({ field: 'driversLicenceCardNumber', value: event.currentTarget.value })

  const changePassportCountry: FormEventHandler<HTMLSelectElement> = event =>
    ChangeValue({ field: 'passportCountry', value: event.currentTarget.value })
  const changePassportNumber: FormEventHandler<HTMLInputElement> = event =>
    ChangeValue({ field: 'passportNumber', value: event.currentTarget.value })

  return (
    <div>
      <PastReverificationMessage isReverificationSuccess={!!isReverificationSuccess} />

      <fieldset>
        <SectionTitle>
          <strong>Driver's licence</strong>
        </SectionTitle>
        <SelectField
          name='State of Issue'
          options={statesInAustralia}
          value={driversLicenceState}
          placeholder='Select state'
          onChange={changeDriversLicenceState}
          onBlur={validateFields}
          errorMessage={validationErrors.driversLicenceState}
        />
        <InputField
          type='text'
          name='Licence Number'
          value={driversLicenceNumber || ''}
          onChange={changeDriversLicenceNumber}
          onBlur={validateFields}
          errorMessage={validationErrors.driversLicenceNumber}
        />
        <InputField
          type='text'
          name='Card Number'
          value={driversLicenceCardNumber || ''}
          onChange={changeDriversLicenceCardNumber}
          onBlur={validateFields}
          errorMessage={validationErrors.driversLicenceCardNumber}
        />
      </fieldset>

      <HorizontalDivider />

      <fieldset>
        <SectionTitle>
          <strong>Passport</strong>
        </SectionTitle>
        <CountrySelect
          name='Country of Issue'
          value={passportCountry}
          onChange={changePassportCountry}
          onBlur={validateFields}
          errorMessage={validationErrors.passportCountry}
        />
        <InputField
          name='Passport Number'
          value={passportNumber || ''}
          onChange={changePassportNumber}
          onBlur={validateFields}
          errorMessage={validationErrors.passportNumber}
        />
      </fieldset>

      <HorizontalDivider />

      <fieldset>
        <SectionTitle>
          <strong>Medicare</strong>
        </SectionTitle>
        <Medicare
          medicareBlueYellowExpiryDate={medicareBlueYellowExpiryDate}
          medicareGreenExpiry={medicareGreenExpiry}
          medicareCardColour={medicareCardColour}
          medicareNumber={medicareNumber}
          medicareReference={medicareReference}
          medicareMiddleNameOnCard={medicareMiddleNameOnCard}
          validationErrors={validationErrors}
        />
      </fieldset>

      <HorizontalDivider />

      <AgreeToVerifyAdditionalDetails
        isAdditionalDetailsChanged={isAdditionalDetailsChanged}
        agreeToVerifyAdditionalDetails={agreeToVerifyAdditionalDetails ?? false}
      />
    </div>
  )
}

function AgreeToVerifyAdditionalDetails({
  isAdditionalDetailsChanged,
  agreeToVerifyAdditionalDetails,
}: {
  isAdditionalDetailsChanged: boolean
  agreeToVerifyAdditionalDetails: boolean
}) {
  if (!isAdditionalDetailsChanged) {
    return null
  }

  const changeAgreeToVerifyAdditionalDetails = (event: React.FormEvent<HTMLInputElement>) =>
    ChangeValue({ field: 'agreeToVerifyAdditionalDetails', value: event.currentTarget.checked })

  return (
    <CheckboxContainer>
      <CheckboxInput
        id='agree-to-verify-checkbox'
        checked={agreeToVerifyAdditionalDetails}
        onChange={changeAgreeToVerifyAdditionalDetails}
        label='I agree to allow the details I provided to be verified by the issuer.'
      />
    </CheckboxContainer>
  )
}
