import React from 'react'

import { toMoney } from '@mobi/utils/money'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Money } from '@core/Components/Text/Money'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'

import {
  WithdrawContainerStyled,
  WithdrawDetailStyled,
  SuccessMessageStyled,
} from './Withdraw.styles'
import { WithdrawResponse } from './WithdrawApi'
import { navigateToAccount } from './helpers/navigaton'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { useFeature } from '@core/Utils/hooks'
import { trackWithdrawalSuccess } from './analytics'

interface WithdrawSuccessProps {
  success: WithdrawResponse | undefined
}

export const WithdrawSuccess: React.FC<WithdrawSuccessProps> = ({ success }) => {
  const isRateMyAppEnabled = useFeature('RATE_MY_APP')

  React.useEffect(() => {
    document.body.scrollIntoView()
  }, [])

  React.useEffect(() => {
    if (isReactNativeApp && isRateMyAppEnabled) {
      sendToNative('RATE_MY_APP')
    }
  }, [isRateMyAppEnabled])

  React.useEffect(() => {
    if (success?.isSuccessful) {
      trackWithdrawalSuccess(success.withdrawalAmount)
    }
  }, [success])

  if (!success?.isSuccessful) {
    return (
      <MainContainer data-tid-withdraw-success='' forceSizing>
        <ContentHeader title='Withdrawal' />
        <WithdrawContainerStyled>
          <NoticeBoxSingle
            title={<SuccessMessageStyled>Your withdrawal was successful</SuccessMessageStyled>}
            noticeBoxType={NoticeBoxTypes.Success}
            testId='withdrawal-success-message'
          />
          <ButtonBlock onClick={navigateToAccount} color='primary' data-tid-back-to-account-btn=''>
            Back to Account
          </ButtonBlock>
        </WithdrawContainerStyled>
      </MainContainer>
    )
  }

  return (
    <MainContainer data-tid-withdraw-success='' forceSizing>
      <ContentHeader title='Withdrawal' />
      <WithdrawContainerStyled>
        <NoticeBoxSingle
          title={
            <SuccessMessageStyled>
              Your withdrawal of {toMoney(success.withdrawalAmount)} was successful
            </SuccessMessageStyled>
          }
          noticeBoxType={NoticeBoxTypes.Success}
          testId='withdrawal-success-message'
        />
        <WithdrawDetailStyled>
          <span>Account Number</span>
          <span>{success.accountNumber}</span>
        </WithdrawDetailStyled>
        <WithdrawDetailStyled>
          <span>Account Balance</span>
          <span>
            <Money amount={success.balance} />
          </span>
        </WithdrawDetailStyled>
        <WithdrawDetailStyled>
          <span>Transaction Reference</span>
          <span>{success.transactionNumber}</span>
        </WithdrawDetailStyled>
        <ButtonBlock onClick={navigateToAccount} color='primary' data-tid-back-to-account-btn=''>
          Back to Account
        </ButtonBlock>
      </WithdrawContainerStyled>
    </MainContainer>
  )
}
