import React, { useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import * as QueryString from 'query-string'
import { MainContainer } from '@core/Components/Containers'
import { RaceEventsHeader } from './Components/RaceEventsHeader'
import { RaceEventsItem } from './Components/RaceEventsItem'
import {
  RaceEvent,
  RaceEventGrouping,
  getRaceEventData,
  getRaceEventTitle,
  getTitleUrl,
} from './RaceEventsApis'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { RaceEventsContainerStyled } from './RaceEvents.styles'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  RaceEventGroupSeperatorStyled,
  RaceItemWrapperStyled,
} from './Components/RaceEventsItem/RaceEventsItem.styles'
import { setBackUrl } from '@classic/Foundation/Navigation/Nav'
import { AppearanceInheritingLink } from '@core/Components/Links'
import { GridCell } from '@mobi/component-library/Common/Grid'

/*
In the end this will handle
Futures
  #fobracing/FR/Races/2021-01-25?selectiondate=2021-01-25
  #fobracing/FR/Trots/2021-01-25?selectiondate=2021-01-25
  #fobracing/FR/Dogs/2021-01-25?selectiondate=2021-01-25
Jockey Challenge
  #fobracing/CLG/Races/2021-01-25?selectiondate=2021-01-25
Future Final Field
  #fobracing/fff/races/1332828/2021-01-20?selectiondate=2021-01-25
  #fobracing/fff/trots/1042893/2021-01-23?selectiondate=2021-01-25
Greys Box Challenge
  #fobracing/CLG/Dogs/2021-01-25?selectiondate=2021-01-25
Racing Specials
  #fobracing/SR/Races/2021-01-25?selectiondate=2021-01-25
  #fobracing/SR/Trots/2021-01-25?selectiondate=2021-01-25
  #fobracing/SR/Dogs/2021-01-25?selectiondate=2021-01-25
*/

const productsAndCodes = 'FR/Races|FR/Trots|FR/Dogs|SR/Races|SR/Trots|SR/Dogs|CLG/Races|CLG/Dogs'
const propositionsProductsAndCodes =
  `${productsAndCodes}|FFF/Races|FFF/Trots|FFF/Dogs|FOO/Races|FOO/Trots|FOO/Dogs` as const

export const RaceEventsRegex =
  `^fobracing/(${productsAndCodes})(/[^\\?]+)?\\?selectiondate=(.*)$` as const
export const RaceEventsPropositionsRegex =
  `^fobracing/propositions/(${propositionsProductsAndCodes})(/[^\\?]+)?\\?selectiondate=(.*)$` as const

const noDataFoundDateTimeFormat = 'ddd, DD MMM YYYY'

interface RaceEventsParams {
  code: string
  date: string
  product: string
}

export const RaceEvents = (): JSX.Element | null => {
  const [data, setData] = useState<RaceEventGrouping[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { product, code, date } = useParams<RaceEventsParams>()

  /* Remove once race event item proposition page is converted to react
   use query date for link as the useParam date
   is not properly filled in from the url when u hit back from race events item proposition. */
  const { selectiondate } = QueryString.parse(useLocation().search)
  const searchDate = date ?? selectiondate
  const pageTitle = getRaceEventTitle(product, code)
  const titleUrl = getTitleUrl(code, selectiondate)

  React.useEffect(() => {
    getRaceEventData(product, code, searchDate)
      .then(data => {
        setData(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [product, code, searchDate])

  React.useEffect(() => {
    // construct back page url for 'back tick' button from current url
    setTimeout(() => setBackUrl(`/#tote?code=${code.toLowerCase()}&date=${searchDate}`), 500)
  }, [code, searchDate])

  return (
    <MainContainer>
      <RaceEventsHeader title={pageTitle} backUrl={titleUrl} />
      <RaceEventsContainerStyled>
        {getContent(isLoading, product, code, searchDate, data)}
      </RaceEventsContainerStyled>
    </MainContainer>
  )
}

function getContent(
  isLoading: boolean,
  product: string,
  code: string,
  date: string,
  data: RaceEventGrouping[]
): JSX.Element[] | JSX.Element {
  if (isLoading) {
    return getShimmerElements()
  }
  if (!data || data.length === 0) {
    return getNoDataFound(code, date)
  }

  return data.map((grouping: RaceEventGrouping) => (
    <React.Fragment key={grouping.title}>
      <RaceEventGroupSeperatorStyled data-tid-date-separator>
        {grouping.title}
      </RaceEventGroupSeperatorStyled>
      {grouping.events.map((item: RaceEvent) => createRaceEventItem(item, product, date))}
    </React.Fragment>
  ))
}

function createRaceEventItem(item: RaceEvent, product: string, date: string): JSX.Element {
  return (
    <RaceItemWrapperStyled key={item.id}>
      <AppearanceInheritingLink
        to={`/fobracing/propositions/${product}/${item.code}/${item.id}?selectiondate=${date}`}
      >
        <RaceEventsItem key={item.id} {...item} />
      </AppearanceInheritingLink>
    </RaceItemWrapperStyled>
  )
}

function getShimmerElements(): JSX.Element[] {
  return Array(3)
    .fill(0)
    .map((_, i: number) => (
      <GridCell padding='0 1rem' key={i}>
        <LoadingPlaceholder width='100%' height='2rem' />
        <LoadingPlaceholder width='100%' height='3.5rem' />
        <LoadingPlaceholder width='100%' height='3.5rem' />
        <br />
      </GridCell>
    ))
}

function getNoDataFound(code: string, date: string): JSX.Element {
  const dateText = dayjs(Date.parse(date)).format(noDataFoundDateTimeFormat)
  return (
    <GridCell padding='1rem'>
      <NoticeBoxSingle
        data-tid-notice-box='nodatafound'
        hasBorder={true}
        noticeBoxType={NoticeBoxTypes.Warning}
        title={`No data found for ${code} on ${dateText}.`}
      />
    </GridCell>
  )
}
