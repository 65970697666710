import { PlanSeq } from '@mobi/api-types'
import type { Ticket } from '@core/Areas/AccountActivity/types'

const enum FixedRacingBetTypesWithStarters {
  WinPlace = 'Win & Place - Fixed',
  Win = 'Win - Fixed',
  Place = 'Place - Fixed',
  Insurance2nd3rd = 'Insurance 2nd & 3rd',
  StartingPrice = 'SP - Fixed',
}

/** Is fixed racing derivative market with incorrect starter number
 * ie. "Head to Head", "Odds vs Evens"
 */
export function isFobRacingWithIncorrectStarterNumbers(
  planSeq: Ticket['planSeq'],
  betType: Ticket['legs'][0]['betType']
) {
  return (
    planSeq === PlanSeq.FOBRacing &&
    !(
      [
        FixedRacingBetTypesWithStarters.Win,
        FixedRacingBetTypesWithStarters.WinPlace,
        FixedRacingBetTypesWithStarters.Place,
        FixedRacingBetTypesWithStarters.Insurance2nd3rd,
        FixedRacingBetTypesWithStarters.StartingPrice,
      ] as string[]
    ).includes(betType || '')
  )
}
