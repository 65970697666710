import React from 'react'

type Options = {
  timeoutMs: number
  shouldRender?: boolean
}

export function useRenderTimeoutControl({ timeoutMs, shouldRender = false }: Options): boolean {
  const [shouldRenderControlState, setShouldRenderControlState] = React.useState(shouldRender)
  const prevShouldRenderControlState = React.useRef<boolean>()

  React.useEffect(() => {
    let timerId: number

    if (shouldRender && !prevShouldRenderControlState.current) {
      setShouldRenderControlState(true)
    }

    if (!shouldRender && prevShouldRenderControlState.current) {
      timerId = window.setTimeout(() => {
        setShouldRenderControlState(false)
      }, timeoutMs)
    }

    prevShouldRenderControlState.current = shouldRender

    return () => {
      clearTimeout(timerId)
    }
  }, [shouldRender, timeoutMs])

  return shouldRenderControlState
}
