import { ApiServiceError, post } from '@classic/Foundation/Services/ApiService'
import { isUserAccountStatePending } from '@core/Areas/Kambi/helpers/regulation'
import { getLastKnownLocation, getLastKnownVenue } from './Location/LocationService'

import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { getCookieValue } from '@classic/Foundation/Services/CookieService'

interface IMetadata {
  latitude?: number
  longitude?: number
  deviceIdentifier?: string
  pulseLocationId?: number
}

export function startMetadataStatusUpdate(): void {
  setInterval(updateMetadata, 300000)
}

export const updateMetadata = async (): Promise<void> => {
  const { isLoggedIn } = await userAccountState$
    .skipWhile(isUserAccountStatePending)
    .take(1)
    .toPromise()

  if (isLoggedIn) {
    var gpsPosition = getLastKnownLocation()
    var pulseLocation = getLastKnownVenue()

    var metadata: IMetadata = {
      latitude: Number(gpsPosition?.latitude),
      longitude: Number(gpsPosition?.longitude),
      deviceIdentifier: getCookieValue('tt-dv'),
      pulseLocationId: Number(pulseLocation?.venueId),
    }

    post({
      url: '/api/metadata/update-metadata',
      body: metadata,
    }).catch(e => {
      if (e instanceof ApiServiceError && e.response.status === 403) {
        /*  this can happen when auth cookie expires but state machine
        thinks user is still logged in. Handle silently so global error handler
        doesn't cause a sentry error event */
        return Promise.resolve()
      } else {
        return Promise.reject(e)
      }
    })
  }
}
