interface IEntitlementsData {
  isGiddyUpEntitled: boolean
  reasonText: string
}

export class EntitlementsDataTransferObject {
  public success!: boolean
  public errorCode!: number
  public errorMessage!: string
  public data!: IEntitlementsData
}
