import MultiLegSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/MultiLegSelectionResultProcessor'
import MultiLegValidator from '@classic/Betting-v2/Validation/MultiLegValidator'
import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import type { IConfigureBetType } from './IConfigureBetType'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class MysteryConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.Mystery,
      name: 'Mystery',
      supportsFlexi: true,
      sortOptions: ['Number', 'Price', 'FixedPrice'],
      template: 'mystery-template',
      betSelectionTemplate: 'quad-checkbox-selection-template',
      multiBet: false,
      multiSelections: true,
      fieldCheckBoxCount: 0,
      checkBoxCount: 0,
      sameAsCheckBoxCount: 0,
      processor: new CheckboxSelectionProcessor(1),
      validator: new MultiLegValidator(4),
      selectionResultProcessor: new MultiLegSelectionResultProcessor(),
      legs: 1,
    })
  }
}
