import React from 'react'
import { Flex } from '@mobi/component-library/Common/Flex'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const SkeletonLoader = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <Flex justifyContent='space-between'>
        <Flex flexDirection='column' gap='0.25rem'>
          <LoadingPlaceholder width='20rem' height='1.5rem' borderRadius={radius.lg} />
          <LoadingPlaceholder width='20rem' height='1.5rem' borderRadius={radius.lg} />
        </Flex>
        <Flex alignItems='center' justifyContent='flex-end'>
          <LoadingPlaceholder
            height='1.5rem'
            width='5rem'
            borderRadius={radius.lg}
            margin='0 0.5rem'
          />
          <LoadingPlaceholder
            height='1.5rem'
            width='5rem'
            borderRadius={radius.lg}
            margin='0 0.5rem'
          />
          <LoadingPlaceholder height='1.5rem' width='1.5rem' borderRadius='50%' margin='0 0.5rem' />
        </Flex>
      </Flex>
    </div>
  )
}
