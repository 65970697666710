import React, { useCallback } from 'react'
import { sendToNative } from '@mobi/web-native-comms/web'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

import { Footer } from './Components/Footer'
import { useHistory } from 'react-router'
import { useLogon } from '@core/Utils/hooks'
import { fullClientVersion } from '@core/Utils/clientVersion'
import { useServerConfig } from '@core/Data/ServerConfig/useServerConfig'

const version = CI_PIPELINE_ID ? `${fullClientVersion} (${CI_PIPELINE_ID})` : fullClientVersion

export const HomeFooter: React.FC = () => {
  const history = useHistory()
  const { isLoggedIn } = useLogon()
  const serverConfig = useServerConfig()
  const [showDeveloperOptions, setShowDeveloperOptions] = React.useState(0)

  const onSetDepositLimitClick = useCallback(() => {
    if (isLoggedIn) {
      history.push(`/#account/deposit-limits`)
    } else {
      window.open('https://help.tabtouch.com.au/knowledge-base/setting-up-your-deposit-limit/')
    }
  }, [history, isLoggedIn])

  const onVersionNumberClick = useCallback(() => {
    if (!isReactNativeApp) {
      return
    }

    if (showDeveloperOptions >= 4) {
      sendToNative('REQUEST_NATIVE_NAVIGATION', { location: 'Developer Options' })
      setShowDeveloperOptions(0)
    } else {
      setShowDeveloperOptions(curr => ++curr)
    }
  }, [showDeveloperOptions])

  return (
    <Footer
      version={version}
      trackInternalNavigation={destination => trackEvent('nav.nav', { destination })}
      trackExitNavigation={destination => trackEvent('nav.exit', { destination })}
      showToolbox={serverConfig?.isInternalRequest ?? false}
      onSetDepositLimitClick={onSetDepositLimitClick}
      onVersionNumberClick={onVersionNumberClick}
    />
  )
}
