import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import React from 'react'
import { SameRaceMultiQuickbetStyled } from './SameRaceMultiQuickbet.styles'

export interface SameRaceMultiQuickbetProps {
  isValid: boolean
  handleQuickbetSelected(): void
}

export function SameRaceMultiQuickbet({
  isValid,
  handleQuickbetSelected,
}: SameRaceMultiQuickbetProps) {
  return (
    <SameRaceMultiQuickbetStyled>
      <ButtonBlock
        disabled={!isValid}
        onClick={handleQuickbetSelected}
        data-tid-place-single-btn={'bet-forward-buttons'}
      >
        Quickbet
      </ButtonBlock>
    </SameRaceMultiQuickbetStyled>
  )
}
