import React from 'react'
import type { FobDetails } from '@mobi/betslip/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BettingHeader, SimpleBettingHeader } from '@core/Components/BettingHeader/BettingHeader'
import { betslipBettingHeader } from '@core/Components/BettingHeader/themes'

type SelectionDetails = Pick<
  FobDetails,
  | 'eventName'
  | 'competitionName'
  | 'sportIconCode'
  | 'tournamentName'
  | 'propositionName'
  | 'marketName'
  | 'handicapName'
>

export interface FobBetInfoProps {
  selectionDetails: SelectionDetails
  isSimpleView: boolean
  removeHandler: () => void
  hasFatalError: boolean
  hasProposed: boolean
  isBusy: boolean
}

export function FobBetInfo({
  selectionDetails,
  isSimpleView,
  removeHandler,
  hasFatalError,
  hasProposed,
  isBusy,
}: FobBetInfoProps): JSX.Element {
  const {
    competitionName,
    eventName,
    handicapName,
    marketName,
    propositionName,
    sportIconCode,
    tournamentName,
  } = selectionDetails

  const selection = hasFatalError ? null : (
    <span data-tid-proposition=''>
      {propositionName}
      {handicapName ? ` ${handicapName}` : ''}
    </span>
  )

  return isSimpleView ? (
    <SimpleBettingHeader heading={eventName} selection={selection} />
  ) : (
    <BettingHeader
      icon={<Icon size='3rem' type={sportIconCode || 'tab'} />}
      theme={betslipBettingHeader}
      removeHandler={removeHandler}
      displayPill={false}
      pilled={marketName ? <span data-tid-market=''>{marketName}</span> : null}
      isEditable={!hasProposed && !isBusy}
      selection={selection}
      heading={
        tournamentName ? (
          <span data-tid-competition=''>
            {competitionName} {tournamentName}
          </span>
        ) : (
          <span>{competitionName}</span>
        )
      }
      subheading={eventName !== marketName ? <span data-tid-event=''>{eventName}</span> : null}
    />
  )
}
