import React from 'react'
import { SvgIconStyled } from '@mobi/component-library/Common/Icon/Icon.styles'

export function PlayIcon() {
  return (
    <SvgIconStyled width='1em' height='1em' viewBox='0 0 10 10' size='1.7rem'>
      <path
        d='M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z'
        fill='#9E56FF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z'
        fill='white'
      />
      <path d='M7.1543 5.002L3.92353 6.86729L3.92353 3.13672L7.1543 5.002Z' fill='white' />
    </SvgIconStyled>
  )
}
