import React from 'react'
import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import { RacePageDataTransferObject } from '@mobi/api-types'
import { useAppDispatch } from '@core/Store/hooks'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { useObservableImmutable } from '@core/Utils/hooks'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import {
  mysteryRaceBettingPageWrapperState$,
  RaceBettingPageMysterySelection,
  ClearRaceBettingPageMysterySelection,
} from './MysteryRaceBettingPageWrapperDriver'
import { MysteryContainerStyled } from './Mystery.styles'
import { MysteryQuickPicks } from './Components/MysteryQuickPick'
import { betFromRaceBettingPageQuickPick } from './utils'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import {
  ExpandablePanelTitleStyled,
  IconStyled,
  ExpandablePanelHeaderStyled,
} from '@core/Components/ExpandablePanel/ExpandablePanel.styles'
import { createRaceObjectFromResponse } from '@core/Data/Racing'

export const MysteryQuickPicksRaceBettingPageWrapper: React.FC<{
  meetingInformation: RacePageDataTransferObject['MeetingInformation']
}> = ({ meetingInformation }) => {
  const [isPanelExpanded, setPanelExpanded] = React.useState(true)
  const dispatch = useAppDispatch()

  const { selectedKey } = useObservableImmutable(mysteryRaceBettingPageWrapperState$, [
    'selectedKey',
  ])

  React.useEffect(() => {
    if (selectedKey) {
      amplify.publish('clear-all-selections-command')
      dispatch(setSelectedProposition(null))
    }
  }, [selectedKey, dispatch])

  React.useEffect(() => {
    amplify.subscribe('selection-made-command', ClearRaceBettingPageMysterySelection)
    return () => {
      amplify.unsubscribe('selection-made-command', ClearRaceBettingPageMysterySelection)
    }
  }, [])

  const selectionCallback = (key: string) => {
    RaceBettingPageMysterySelection(key)
  }

  const HeaderComponent = customHeader(isPanelExpanded)

  return (
    <>
      <MysteryContainerStyled>
        <ExpandablePanel
          title='Mystery Bet'
          themeName={ExpandablePanelThemes.light}
          deferRenderingContentOnExpand={true}
          headerClicked={() => setPanelExpanded(!isPanelExpanded)}
          expanded={isPanelExpanded}
          dataTidValue='mystery-bet'
          headerComponent={HeaderComponent}
        >
          <MysteryQuickPicks selectedKey={selectedKey} selectionCallback={selectionCallback} />
        </ExpandablePanel>
      </MysteryContainerStyled>

      <BettingDrawer
        open={!!selectedKey}
        handleQuickbetClick={() =>
          betFromRaceBettingPageQuickPick(
            selectedKey,
            createRaceObjectFromResponse(meetingInformation)
          )
        }
        dataTId='mystery-buttons'
      />
    </>
  )
}

const customHeader = (isExpanded: boolean) => {
  return (
    <ExpandablePanelHeaderStyled>
      <Grid>
        <GridCell valign='middle' align='left'>
          <ExpandablePanelTitleStyled>Mystery Bet</ExpandablePanelTitleStyled>
        </GridCell>
        <GridCell width='1.5rem' valign='middle'>
          <IconStyled type={isExpanded ? 'arrowup' : 'arrowdown'} size='1.2rem' />
        </GridCell>
      </Grid>
    </ExpandablePanelHeaderStyled>
  )
}
