import { store } from '@core/Store'
import { setIsLoginShown, LoginState } from '../Store'

export const showLogin = ({
  onLoginSuccessCallback,
  onLoginSuccessRedirectUrl,
  onLoginCancelledRedirectUrl,
}: ShowLoginOptions = {}) => {
  store.dispatch(
    setIsLoginShown({
      shouldShow: true,
      onLoginSuccessCallback,
      onLoginSuccessRedirectUrl,
      onLoginCancelledRedirectUrl,
    })
  )
}

export const hideLogin = () => {
  store.dispatch(setIsLoginShown({ shouldShow: false }))
}

// =====
// Types
// =====

type ShowLoginOptions = Partial<
  Pick<
    LoginState,
    'onLoginSuccessCallback' | 'onLoginSuccessRedirectUrl' | 'onLoginCancelledRedirectUrl'
  >
>
