import styled from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '../../../Theme/Common'
import { gapX } from '../../../Utils/gap'

export const CopyFieldStyled = styled.div({
  alignItems: 'center',
  boxShadow: shadow.xs,
  boxSizing: 'border-box',
  backgroundColor: colors.white,
  border: `1px solid ${colors.neutral[300]}`,
  borderRadius: radius.md,
  color: colors.neutral[900],
  display: 'flex',
  fontFamily: font.family.primary,
  height: '4rem',
  justifyContent: 'space-between',
  padding: `0 ${spacing.smx1}`,
  ...font.size.lg,
})

export const CopyButtonStyled = styled.button(
  {
    alignItems: 'center',
    backgroundColor: colors.lavender[50],
    border: 'none',
    borderRadius: radius.smx1,
    color: colors.lavender[700],
    display: 'inline-flex',
    fontWeight: 500,
    height: '2.4rem',
    padding: `0 ${spacing.smx1}`,
    ...font.size.sm,

    '&:active': {
      backgroundColor: colors.lavender[700],
      color: colors.lavender[50],
    },
  },
  gapX(spacing.smx2)
)
