import Decimal from 'decimal.js'

export class PriceDetails {
  public Win!: {
    DollarReturn: decimal.Decimal
  }
  public Place!: {
    DollarReturn: decimal.Decimal
  }

  private static _toDecimal(value: string | number | decimal.Decimal): decimal.Decimal {
    return value ? new Decimal(value) : new Decimal(0)
  }

  static normalise(priceDetails: PriceDetails): PriceDetails {
    return priceDetails
      ? {
          Win: { DollarReturn: PriceDetails._toDecimal(priceDetails.Win.DollarReturn) },
          Place: { DollarReturn: PriceDetails._toDecimal(priceDetails.Place.DollarReturn) },
        }
      : {
          Win: { DollarReturn: new Decimal(0) },
          Place: { DollarReturn: new Decimal(0) },
        }
  }
}
