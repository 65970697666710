import styled from '@emotion/styled'
import { fontFamiliesBold } from '@core/Settings'
import { HOME_CSS_SHARED_VALUES } from '@core/Areas/Home/Home.styles'
import { colors, hexColors } from '@mobi/settings'

export const FeaturedEventsItemStyled = styled('a')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '11rem',
  minWidth: '11rem',
  height: '6.5rem',

  margin: '0',
  padding: '0.5rem',
  marginRight: '0.75rem',

  color: hexColors.white,
  background: colors.brand.primary,
  border: 0,
  boxShadow: HOME_CSS_SHARED_VALUES.boxShadow,
  borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: 'none',
  },
})

export const FeaturedEventsItemTitleStyled = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '0.5rem',
  lineHeight: 1.1,
  fontSize: '1.1rem',
  fontFamily: fontFamiliesBold.brand,
  textDecoration: 'none',
  textTransform: 'uppercase',
  textAlign: 'center',
  whiteSpace: 'normal',
  color: 'inherit',
})
