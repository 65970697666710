import styled from '@emotion/styled'

export const NumberResultsTabStyled = styled('div')({
  marginTop: '1rem',
  background: '#fff',
  height: '77rem',
})

export const PrevButtonStyled = styled('div')<{ isPreviousAvailable: boolean }>(
  {
    backgroundColor: '#689f38',
    width: '8.5rem',
    left: '1rem',
    margin: '0 0.5rem',
  },
  ({ isPreviousAvailable }) => ({
    backgroundColor: isPreviousAvailable ? '#689f38' : '#ccc',
    color: isPreviousAvailable ? '#fff' : '#eee',
  })
)

export const NextButtonStyled = styled('div')<{ isNextAvailable: boolean }>(
  {
    backgroundColor: '#689f38',
    width: '8.5rem',
    margin: '0 0.5rem',
  },
  ({ isNextAvailable }) => ({
    backgroundColor: isNextAvailable ? '#689f38' : '#ccc',
    color: isNextAvailable ? '#fff' : '#eee',
  })
)

export const DateComponentStyled = styled('div')({
  color: '#000',
  fontWeight: 'bold',
  width: '13rem',
  backgroundColor: '#ccc',
})

export const NavComponentStyled = styled('div')({
  display: 'flex',
  width: '100%',
  height: '4rem',
  fontSize: '1.4rem',
  color: '#fff',
  lineHeight: '4rem',
  textAlign: 'center',
  justifyContent: 'center',
  marginBottom: '2rem',
})

export const PoolInfoComponentStyled = styled('div')({
  margin: '1rem 0',
  fontSize: '1.4rem',
  lineHeight: '3rem',
})

export const PoolInfoLineStyled = styled('div')({
  borderTop: '1px solid #eee',
  display: 'flex',
})

export const PoolInfoLineLeftStyled = styled('div')({
  marginLeft: '2rem',
  width: '40%',
})

export const PoolInfoLineRightStyled = styled('div')({
  display: 'flex',
  width: '60%',
})
export const JackpotStyled = styled('span')({
  marginLeft: '1rem',
  display: 'flex',
  alignItems: 'center',
})

export const DetailtextStyled = styled('div')({
  margin: '0',
  textAlign: 'center',
  fontSize: '1.4rem',
})

interface ResultButtonStyledProps {
  isLive: boolean
  isWinner: boolean
}

export const ResultButtonStyled = styled('li')<ResultButtonStyledProps>(
  {
    margin: '0.6rem',
    textAlign: 'center',
    border: '1px solid #cecece',
    width: '5rem',
    minHeight: '5rem',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: '1.4rem',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  ({ isLive, isWinner }) => ({
    backgroundColor: isLive ? '#e9e9e9' : isWinner ? '#689f38' : '#cc0404',
    border: isWinner ? '0.5rem solid #680202' : '1px solid #cecece',
    color: isLive ? '#000' : '#fff',
  })
)

export const OrderStyled = styled('div')({
  margin: '0',
})

export const RaceStyled = styled('div')({
  margin: '0',
})

export const SubStyled = styled('div')({
  margin: '0',
})

export const ResultLegendComponentStyled = styled('div')({
  width: '31rem',
  margin: '1rem 0rem',
  border: '1px solid #cecece',
  boxSizing: 'border-box',
  display: 'flex',
  padding: '1rem',
  backgroundColor: '#F4F4F4',
})

export const ResultTitleStyled = styled('div')({
  fontSize: '1.6rem',
  margin: '2rem',
  fontWeight: 'bold',
})

export const ResultLiStyled = styled('div')({
  textAlign: 'center',
  fontWeight: 'bold',
})

export const FavnumberResultListStyled = styled('div')({
  width: '31rem',
  margin: '1rem 0rem',
  padding: '0rem',
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
})

export const ResultsStyled = styled('div')({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
})
