import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { SuperPickRemovedButtonStyled } from './SuperPick.styles'

interface RemoveButtonProps {
  onClick: () => void
}

export function RemoveButton({ onClick }: RemoveButtonProps) {
  return (
    <SuperPickRemovedButtonStyled onClick={onClick}>
      <Icon type='cross' size='1rem' />
    </SuperPickRemovedButtonStyled>
  )
}
