import React from 'react'
import { setInvestmentAmount } from '@mobi/betslip/Store/Bets'
import { selectKeypadActiveValue } from '@mobi/betslip/Store/Workflow/selectors'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { BetSlipWorkflowState, setKeypadActiveValue } from '@mobi/betslip/Store/Workflow'

export const useSyncActiveValueWithSelection = (
  activeInvestment: BetSlipWorkflowState['activeInvestment']
) => {
  React.useEffect(() => {
    if (!activeInvestment) return
    const hostHelpers = getHostHelpers()

    const { itemId, investmentType } = activeInvestment

    const unsubscribe = hostHelpers.redux.startListening({
      actionCreator: setKeypadActiveValue,
      effect: (_, listenerApi) => {
        const value = selectKeypadActiveValue(listenerApi.getState())
        if (value === null) return
        listenerApi.dispatch(setInvestmentAmount({ itemId, investmentType, value }))
      },
    })

    return () => unsubscribe()
  }, [activeInvestment])
}
