import * as ko from 'knockout'
import { ResultsColumnHeaderViewModel } from './ResultsColumnHeaderViewModel'
import template from './results.column.header.tpl.html'

if (!ko.components.isRegistered('results-column-header')) {
  ko.components.register('results-column-header', {
    viewModel: ResultsColumnHeaderViewModel,
    template,
  })
}
