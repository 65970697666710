import React from 'react'
import * as immutable from 'immutable'
import {
  ReceiptHeaderStyled,
  ReceiptHeaderTextStyled,
  ReceiptMultiItemContainerStyled,
  ReceiptListItemFixedMoneyStyled,
} from './BetsliptReceipt.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { MultiItem } from '@core/Areas/Betslip/Components/MultiItem/MultiItem'
import { ReceiptItem, BetslipItem } from '../../driver'
import {
  ReceiptListStyled,
  ReceiptListRowStyled,
  ReceiptListItemTextStyled,
  ReceiptListItemStyled,
  ReceiptCampaignTextStyled,
  ReceiptSpecialIndicator,
} from '@core/Areas/Quickbet/Components/Receipt/Receipt.styles'
import { Money } from '@core/Components/Text/Money'
import { calculateMultiReturn } from '../../helpers/calculator'

interface BetslipReceiptMultiProps {
  items: immutable.List<BetslipItem>
  receipt: ReceiptItem
}

export class BetslipReceiptMulti extends React.Component<BetslipReceiptMultiProps> {
  public shouldComponentUpdate() {
    return false
  }

  public render() {
    const {
      items,
      receipt: {
        numberOfCombinations,
        projectedWinPayout,
        formulaResponse,
        winInvestment,
        campaignActivatedInd,
        bonusBet: bonusValue,
        amountPaid,
        specialOffers,
        campaignType,
        ticketNumber,
      },
    } = this.props

    const isMultiOnly = numberOfCombinations === 1
    const isMultiFormulaOnly =
      numberOfCombinations && numberOfCombinations > 1 && formulaResponse && !formulaResponse.multi
    const isMultiWithFormula = !isMultiOnly && !isMultiFormulaOnly

    const multiReturn: number | null = !isMultiFormulaOnly
      ? calculateMultiReturn(items, { shouldRound: true })
      : 0
    const multiCost: number =
      (isMultiOnly
        ? winInvestment
        : isMultiWithFormula
          ? formulaResponse?.multi?.combinedStake
          : 0) ?? 0
    const isBonusBet = campaignType == 'BonusBetReward'

    return (
      <>
        <ReceiptHeaderStyled data-tid-receipt-header=''>
          <Icon type='tickCircled' size='2.5rem' />{' '}
          <ReceiptHeaderTextStyled>Your multi bet has been placed</ReceiptHeaderTextStyled>
        </ReceiptHeaderStyled>
        {items
          .filter(x => x.isInMulti)
          .toArray()
          .map((item, i) => (
            <ReceiptMultiItemContainerStyled key={item.id || i} data-testid='betslip-receipt-item'>
              <MultiItem item={item} disabled={false} showBetTypeToggle={false} isReceipt={true} />
            </ReceiptMultiItemContainerStyled>
          ))}

        <ReceiptListStyled>
          <ReceiptListRowStyled data-tid-receipt-projpay=''>
            <ReceiptListItemStyled>Projected Pay</ReceiptListItemStyled>
            <ReceiptListItemTextStyled>
              <Money amount={projectedWinPayout ?? 0} />
            </ReceiptListItemTextStyled>
          </ReceiptListRowStyled>

          {!isMultiFormulaOnly && (
            <>
              <ReceiptListRowStyled data-tid-receipt-multirtn=''>
                <ReceiptListItemStyled>Multi Return</ReceiptListItemStyled>
                <ReceiptListItemTextStyled>
                  <Money amount={multiReturn} />
                </ReceiptListItemTextStyled>
              </ReceiptListRowStyled>

              <ReceiptListRowStyled data-tid-receipt-multicost=''>
                <ReceiptListItemStyled>Multi Cost</ReceiptListItemStyled>
                <ReceiptListItemTextStyled>
                  <Money amount={multiCost} />
                </ReceiptListItemTextStyled>
              </ReceiptListRowStyled>
            </>
          )}

          {!isMultiOnly && formulaResponse && (
            <>
              <ReceiptListRowStyled>Formulas</ReceiptListRowStyled>

              {formulaResponse.singles && (
                <ReceiptListRowStyled data-tid-receipt-formula-1=''>
                  <ReceiptListItemStyled>
                    Singles{' '}
                    <ReceiptListItemFixedMoneyStyled
                      amount={formulaResponse.singles.stake}
                      decimalPlaces={2}
                    />
                    (Combo x {formulaResponse.singles.numberOfCombinations})
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled>
                    <Money amount={formulaResponse.singles.combinedStake} />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              )}

              {formulaResponse.doubles && (
                <ReceiptListRowStyled data-tid-receipt-formula-2=''>
                  <ReceiptListItemStyled>
                    Doubles{' '}
                    <ReceiptListItemFixedMoneyStyled
                      amount={formulaResponse.doubles.stake}
                      decimalPlaces={2}
                    />
                    (Combo x {formulaResponse.doubles.numberOfCombinations})
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled>
                    <Money amount={formulaResponse.doubles.combinedStake} />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              )}

              {formulaResponse.trebles && (
                <ReceiptListRowStyled data-tid-receipt-formula-3=''>
                  <ReceiptListItemStyled>
                    Trebles{' '}
                    <ReceiptListItemFixedMoneyStyled
                      amount={formulaResponse.trebles.stake}
                      decimalPlaces={2}
                    />
                    (Combo x {formulaResponse.trebles.numberOfCombinations})
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled>
                    <Money amount={formulaResponse.trebles.combinedStake} />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              )}

              {formulaResponse.pick4 && (
                <ReceiptListRowStyled data-tid-receipt-formula-4=''>
                  <ReceiptListItemStyled>
                    Pick 4{' '}
                    <ReceiptListItemFixedMoneyStyled
                      amount={formulaResponse.pick4.stake}
                      decimalPlaces={2}
                    />
                    (Combo x {formulaResponse.pick4.numberOfCombinations})
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled>
                    <Money amount={formulaResponse.pick4.combinedStake} />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              )}

              {formulaResponse.pick5 && (
                <ReceiptListRowStyled data-tid-receipt-formula-5=''>
                  <ReceiptListItemStyled>
                    Pick 5{' '}
                    <ReceiptListItemFixedMoneyStyled
                      amount={formulaResponse.pick5.stake}
                      decimalPlaces={2}
                    />
                    (Combo x {formulaResponse.pick5.numberOfCombinations})
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled>
                    <Money amount={formulaResponse.pick5.combinedStake} />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              )}

              <ReceiptListRowStyled data-tid-receipt-totalcost=''>
                <ReceiptListItemStyled>Total Cost</ReceiptListItemStyled>
                <ReceiptListItemTextStyled>
                  <Money amount={winInvestment ?? 0} />
                </ReceiptListItemTextStyled>
              </ReceiptListRowStyled>
            </>
          )}

          {campaignActivatedInd && bonusValue && bonusValue > 0 && (
            <ReceiptListRowStyled data-tid-receipt-bonusbet=''>
              <ReceiptListItemStyled>
                <ReceiptCampaignTextStyled isBonusBet={isBonusBet}>
                  {isBonusBet ? 'Bonus Bet' : 'Bonus Cash'}
                </ReceiptCampaignTextStyled>
              </ReceiptListItemStyled>
              <ReceiptListItemTextStyled>
                <ReceiptCampaignTextStyled isBonusBet={isBonusBet}>
                  <Money amount={-bonusValue} />
                </ReceiptCampaignTextStyled>
              </ReceiptListItemTextStyled>
            </ReceiptListRowStyled>
          )}

          {campaignActivatedInd && bonusValue && bonusValue > 0 && (
            <ReceiptListRowStyled data-tid-receipt-amountpaid=''>
              <ReceiptListItemStyled>Amount Paid</ReceiptListItemStyled>
              <ReceiptListItemTextStyled>
                <Money amount={amountPaid ?? 0} />
              </ReceiptListItemTextStyled>
            </ReceiptListRowStyled>
          )}

          {specialOffers
            ? specialOffers.map((special, i) => (
                <ReceiptListRowStyled key={i} data-tid-receipt-special=''>
                  <ReceiptListItemStyled key={`desc-${special.specialSeq}`}>
                    {special.title}
                  </ReceiptListItemStyled>
                  <ReceiptListItemTextStyled key={`val-${special.specialSeq}`}>
                    <ReceiptSpecialIndicator />
                  </ReceiptListItemTextStyled>
                </ReceiptListRowStyled>
              ))
            : null}

          <ReceiptListRowStyled data-tid-receipt-projpay=''>
            <ReceiptListItemStyled>Ticket</ReceiptListItemStyled>
            <ReceiptListItemTextStyled>{ticketNumber}</ReceiptListItemTextStyled>
          </ReceiptListRowStyled>
        </ReceiptListStyled>
      </>
    )
  }
}
