import styled from '@emotion/styled'

export const SameRaceMultiButtonsContainerStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2rem',
  marginLeft: '25px',
  gap: '0.5rem',

  '& > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5rem',
    marginLeft: '0.3rem',
    textAlign: 'center',
  },

  '& > button': {
    marginLeft: '0.3rem',
  },
})

export const SameRaceMultiScratchedStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  color: '#fe0000',
  fontWeight: 'bold',
  height: 'auto',
  width: 'auto',
  justifyContent: 'center',
  marginBottom: '-0.5rem',
})
