import React from 'react'
import { ConfirmAllBetsSuccessful } from '@core/Areas/Betslip/signals'
import { ConfirmBetSucceeded } from '@core/Areas/Quickbet/signals'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'

export const useHapticFeedbackOnBetSuccess = (location: 'betslip' | 'quickbet') => {
  React.useEffect(() => {
    const listener = { betslip: ConfirmAllBetsSuccessful, quickbet: ConfirmBetSucceeded }[location]
    if (!listener) return undefined

    const subscription = listener.signal$.subscribe(() => {
      triggerHapticFeedback('success')
    })
    return () => subscription.dispose()
  }, [location])
}
