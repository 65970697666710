import React from 'react'
import { Flex } from '@mobi/component-library/Common/Flex'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const SkeletonLoader = () => {
  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex>
        <LoadingPlaceholder width='3rem' height='3rem' borderRadius={radius.lgx5} margin='0.5rem' />
        <LoadingPlaceholder width='3rem' height='3rem' borderRadius={radius.lgx5} margin='0.5rem' />
        <LoadingPlaceholder width='3rem' height='3rem' borderRadius={radius.lgx5} margin='0.5rem' />
      </Flex>
      <Flex justifyContent='flex-end' alignItems='center'>
        <LoadingPlaceholder width='3rem' height='3rem' borderRadius={radius.lgx5} margin='0.5rem' />
        <LoadingPlaceholder width='4rem' height='3rem' borderRadius={radius.lgx3} margin='0.5rem' />
      </Flex>
    </Flex>
  )
}
