import { RaceCardIcon } from '@core/Components/Icons'
import React, { useCallback, useState } from 'react'
import {
  CompactPanelHeaderStyled,
  CompactPanelHeaderContentStyled,
  CompactPanelContainerStyled,
  CompactPanelContent,
} from '@core/Components/Panel'
import { Props as BaseProps } from '@core/Components/Panel/CompactPanel'
import { getExpandablePanelTheme } from '../ExpandablePanel/themes'
import { ThemeProvider } from '@emotion/react'
import {
  CompactExpandablePanelHeaderStyled,
  CompactExpandablePanelStyled,
} from './CompactExpandablePanel.styles'

export type Props = BaseProps & {
  defaultExpanded?: boolean
  onExpansionChanged?: (expanded: boolean) => void
}

export const CompactExpandablePanel = ({
  headerText,
  headerIcon,
  children,
  defaultExpanded = false,
  themeName,
  onExpansionChanged,
  ...containerProps
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const toggleExpanded = useCallback(() => {
    const nextExpanded = !expanded
    setExpanded(nextExpanded)
    onExpansionChanged?.(nextExpanded)
  }, [expanded, onExpansionChanged])

  const theme = getExpandablePanelTheme(themeName)

  /// Setting background to undefined - header UI to be defined by headerContent
  theme.header.background = undefined

  return (
    <CompactPanelContainerStyled
      backgroundColor={theme.content.background ?? ''}
      textColor={theme.content.text.color ?? ''}
      aria-expanded={expanded}
      data-expandable
      {...containerProps}
    >
      <ThemeProvider theme={theme}>
        <CompactExpandablePanelStyled>
          <CompactExpandablePanelHeaderStyled onClick={toggleExpanded} role='button'>
            <CompactPanelHeaderStyled>
              {headerIcon}

              <CompactPanelHeaderContentStyled>
                <strong>{headerText}</strong>
              </CompactPanelHeaderContentStyled>

              {<RaceCardIcon icon={expanded ? 'chevronUp' : 'chevronDown'} />}
            </CompactPanelHeaderStyled>
          </CompactExpandablePanelHeaderStyled>
          <CompactPanelContent
            aria-expanded={expanded}
            data-testid='banner-content'
            style={{
              display: expanded ? 'block' : 'none',
            }}
          >
            {children}
          </CompactPanelContent>
        </CompactExpandablePanelStyled>
      </ThemeProvider>
    </CompactPanelContainerStyled>
  )
}
