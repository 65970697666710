import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { AppRoutes } from '@core/AppRoutes'
import { PlayerButton, ResizeIcon } from './Components/Buttons'
import { PendingBetsDrawerButton } from './Components/PendingBetsDrawerButton'
import { SkyChannelSelector } from './Components/SkyChannelSelector'
import {
  setIsFullscreen,
  setIsMuted,
  setIsPlaying,
  setCloseSkyVideoPlayer,
  setSkyVideoPlayerStatus,
  setIsControlShowing,
} from '@core/Areas/SkyVideoPlayer/Store'
import { useControlToggle } from './helpers/useControlToggle'
import { useFeature } from '@core/Utils/hooks'
import { findAndStartChildVideoElement } from '../../helpers'

export const VISION_DRAG_HANDLE_CLASSNAME = 'js-vision-floating-draggable'

export const VideoPlayerControls: React.FC<{
  parentContainer?: React.RefObject<HTMLDivElement>
}> = ({ parentContainer }) => {
  const dispatch = useAppDispatch()
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)
  const isPlaying = useAppSelector(state => state.skyVideoPlayer.isPlaying)
  const isMuted = useAppSelector(state => state.skyVideoPlayer.isMuted)
  const isFullscreen = useAppSelector(state => state.skyVideoPlayer.isFullscreen)
  const isControlShowing = useAppSelector(state => state.skyVideoPlayer.isControlShowing)
  const selectedChannel = useAppSelector(state => state.skyVideoPlayer.selectedChannel)
  const history = useHistory()
  const { pathname } = useLocation()

  const topBarRef = React.useRef(null)
  const bottomBarRef = React.useRef(null)

  const isFullscreenUpdateEnabled = useFeature('VISION_FULL_SCREEN_UPDATE')

  useControlToggle({
    isPlaying,
    isMuted,
    isFullscreen,
    isControlShowing,
    selectedChannel,
    topBarElement: topBarRef.current,
    bottomBarElement: bottomBarRef.current,
  })

  const handleFullscreenClick = (isFullscreen: boolean) => {
    isFullscreenUpdateEnabled
      ? dispatch(setSkyVideoPlayerStatus({ playerStatus: isFullscreen ? 'fullscreen' : undefined }))
      : dispatch(setIsFullscreen(isFullscreen))
  }

  const handleCloseSkyPlayer = () => {
    dispatch(setCloseSkyVideoPlayer())
  }

  const handleSkyRacePageClose = () => {
    handleCloseSkyPlayer()
    history.push(AppRoutes.Home)
  }

  const handleToggleControls = () => {
    if (playerStatus !== 'floating') {
      dispatch(setIsControlShowing(!isControlShowing))
      findAndStartChildVideoElement(parentContainer?.current ?? undefined)
    }
  }

  const isOnSkyRacePage = pathname === AppRoutes.RaceCardSky
  const isPlayerFullscreen = playerStatus === 'fullscreen'
  const isPlayerFloating = playerStatus === 'floating'
  const isPlayerDocked = playerStatus === 'docked'

  const shouldRenderResizeButton = isPlayerFloating && !isFullscreen

  return (
    <WrapperStyled onClick={handleToggleControls}>
      <BigPlayButtonWrapperStyled>
        {!isPlaying && <PlayerButton type='play' onClick={() => dispatch(setIsPlaying(true))} />}
        <PlayerDragHandleStyled className={VISION_DRAG_HANDLE_CLASSNAME} />
      </BigPlayButtonWrapperStyled>

      {isPlaying && (
        <>
          <TopBarStyled ref={topBarRef}>
            <div>
              {shouldRenderResizeButton && <ResizeIcon />}

              {(isPlayerDocked || isPlayerFullscreen) && (
                <PlayerButton
                  onClick={isOnSkyRacePage ? handleSkyRacePageClose : handleCloseSkyPlayer}
                  type='close'
                />
              )}
            </div>

            <div>
              <SkyChannelSelector selectedChannel={selectedChannel} />
            </div>

            <div>
              {isPlayerFloating &&
                (isOnSkyRacePage ? (
                  <PlayerButton
                    type='docked'
                    onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'docked' }))}
                  />
                ) : (
                  <PlayerButton type='close' onClick={handleCloseSkyPlayer} />
                ))}

              {isPlayerDocked && (
                <PlayerButton
                  type='floating'
                  onClick={() => dispatch(setSkyVideoPlayerStatus({ playerStatus: 'floating' }))}
                />
              )}

              {isPlayerFullscreen && <PendingBetsDrawerButton />}
            </div>
          </TopBarStyled>

          <BotttomBarStyled ref={bottomBarRef}>
            {isMuted ? (
              <PlayerButton onClick={() => dispatch(setIsMuted(false))} type='mute' />
            ) : (
              <PlayerButton onClick={() => dispatch(setIsMuted(true))} type='volume' />
            )}

            {!isPlayerFullscreen && !isFullscreen ? (
              <PlayerButton onClick={() => handleFullscreenClick(true)} type='fullscreen' />
            ) : (
              <PlayerButton onClick={() => handleFullscreenClick(false)} type='exitfullscreen' />
            )}
          </BotttomBarStyled>
        </>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  overflow: 'hidden',
})

const BigPlayButtonWrapperStyled = styled.div({
  zIndex: 1,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',

  '> button': {
    flex: 1,
    width: '100%',
    background: 0,
    fontSize: '4rem',
  },
})

const ControlBarStyled = styled.div({
  zIndex: 2,
  position: 'absolute',
  left: 0,
  right: 0,
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transform: 'translateY(0)',
  willChange: 'transform',
  color: hexColors.white,
  fontSize: '2.4rem',
  pointerEvents: 'none',
  transition: 'transform ease-in-out 0.2s',
})

const TopBarStyled = styled(ControlBarStyled)({
  top: 0,
  padding: '0.5rem 0.5rem 0',

  '& > div': {
    minWidth: '3.6rem',
  },

  '> div:nth-of-type(1), > div:nth-of-type(3)': {
    flex: 1,
  },

  '> div:nth-of-type(3)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const BotttomBarStyled = styled(ControlBarStyled)({
  bottom: 0,
  padding: '0 0.5rem 0.5rem',
})

const PlayerDragHandleStyled = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
})
