import * as apiService from '@classic/Foundation/Services/ApiService'
import { TAKE_A_BREAK_API_PATH_PREFIX } from './constants'

export interface TakeABreakResponse {
  inProgress: boolean
  endDate: Date
}
interface SaveTakeABreakRequest {
  accountNumber: number
  endDate: Date
}

export const saveTakeABreak = async (
  SaveTakeABreakRequest: SaveTakeABreakRequest
): Promise<TakeABreakResponse> => {
  return await apiService.post({
    url: `${TAKE_A_BREAK_API_PATH_PREFIX}/commence`,
    body: {
      accountNumber: SaveTakeABreakRequest.accountNumber,
      endDate: SaveTakeABreakRequest.endDate,
    },
  })
}

export const fetchTakeABreakDetails = async (): Promise<TakeABreakResponse> => {
  return await apiService.get<TakeABreakResponse>({
    url: `${TAKE_A_BREAK_API_PATH_PREFIX}/status`,
  })
}
