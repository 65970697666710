import React from 'react'
import { useQuery } from 'react-query'
import { RacePageDataTransferObject } from '@mobi/api-types'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import {
  getCurrentRacePageQueryKey,
  getSelectedProposition,
} from '@core/Areas/RaceCard/Store/selectors'
import { WinPlaceScratchedStyled as SCR } from '../WinPlaceButtons.styles'

export const StartingPriceButton: React.FC<{
  starterNumber: number
  isFixedOddsScratched: boolean
}> = ({ starterNumber, isFixedOddsScratched }) => {
  const racePageQueryKey = useAppSelector(getCurrentRacePageQueryKey)
  const { data } = useQuery<RacePageDataTransferObject>({
    queryKey: racePageQueryKey || [],
    enabled: !!racePageQueryKey,
    staleTime: Infinity,
  })

  if (!data || !data.RaceStarters || !Array.isArray(data.RaceStarters)) return null

  const selectedRaceKey = data.MeetingInformation.SelectedRace.Key
  const matchedRace = data.RaceStarters.find(race => race.RaceKey.Key === selectedRaceKey)
  const matchedStarter = matchedRace?.Starters.find(starter => starter.Number === starterNumber)

  if (!matchedStarter) return null
  if (isFixedOddsScratched) return <SCR data-tid-dividend-starting-price={starterNumber}>SCR</SCR>

  return <StartingButtonComponent matchedStarter={matchedStarter} />
}

// ===============
// Local Component
// ===============

const StartingButtonComponent: React.FC<{
  matchedStarter: RacePageDataTransferObject['RaceStarters'][0]['Starters'][0]
}> = ({ matchedStarter }) => {
  const dispatch = useAppDispatch()
  const isSelected = useAppSelector(getSelectedProposition)?.[1] === matchedStarter.Number

  const onClick = () => {
    dispatch(setSelectedProposition(['SP', matchedStarter.Number]))
  }

  return (
    <WinPlaceSelection
      isVisible
      isDisabled={false}
      isFavourite={false}
      isSelected={isSelected}
      centerContent
      onClick={onClick}
      priceWin='SP'
      pricePlace=''
      starterNumber={matchedStarter.Number}
      tidAttribute='data-tid-dividend-starting-price'
    />
  )
}
