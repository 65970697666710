import type {
  EventDetails,
  MysteryDetails,
  FavouriteNumbersDetails,
  ToteSportsDetails,
} from '@mobi/betslip/types'

// ========================
// Type Narrowing Functions
// ========================

export function isMysteryDetails(details: EventDetails | null): details is MysteryDetails {
  if (!details || typeof details !== 'object') return false
  return !!(details as MysteryDetails).mysteryOption
}

export function isFavouriteNumbersDetails(
  details: EventDetails | null
): details is FavouriteNumbersDetails {
  if (!details || typeof details !== 'object') return false
  return !!(details as FavouriteNumbersDetails).pools
}

export function isToteSportsDetails(details: EventDetails | null): details is ToteSportsDetails {
  if (!details || typeof details !== 'object') return false
  return Boolean((details as ToteSportsDetails).poolName && (details as ToteSportsDetails).games)
}
