import { StewardsComment } from '@core/Areas/Racing/Components/StewardsComments/types'
import { FormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import {
  isDogsRunnerForm,
  isRacesRunnerForm,
  isTrotsRunnerForm,
} from '@core/Areas/Racing/Hooks/useRaceFormData/helpers'

export function formatStewardsComments(
  formData: FormRaceResponse['Forms'],
  names: string[]
): StewardsComment[] {
  if (isDogsRunnerForm(formData))
    return formData.flatMap((form, index) => {
      const lastRace = form.Histories[0]
      const acceptorName = names[index] || undefined

      if (!lastRace?.StewardsComments || !acceptorName) return []

      return {
        acceptorName,
        comment: lastRace.StewardsComments,
        timestamp: lastRace.FixtureDate,
      }
    })

  if (isRacesRunnerForm(formData))
    return formData.flatMap((form, index) => {
      const lastRace = form.RaceHistories[0]
      const acceptorName = names[index] || undefined

      if (!lastRace?.StewardsComments || !acceptorName) return []

      return {
        acceptorName,
        comment: lastRace.StewardsComments,
        timestamp: lastRace.FixtureDate,
      }
    })

  if (isTrotsRunnerForm(formData))
    return formData.flatMap((form, index) => {
      const lastRace = form.TrotHistories[0]
      const acceptorName = names[index] || undefined

      if (!lastRace?.StewardsComments || !acceptorName) return []

      return {
        acceptorName,
        comment: lastRace.StewardsComments,
        timestamp: lastRace.FixtureDate,
      }
    })

  return []
}
