import React, { type AnchorHTMLAttributes, type FC } from 'react'

type Props = AnchorHTMLAttributes<HTMLAnchorElement>

export const ExternalLink: FC<Omit<Props, 'rel' | 'target'>> = ({ children, ...props }) => {
  return (
    <a target='_blank' rel='noopener noreferrer' {...props}>
      {children}
    </a>
  )
}
