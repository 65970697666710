export const radius = {
  none: '0',
  smx2: '0.2rem',
  smx1: '0.4rem',
  sm: '0.6rem',
  md: '0.8rem',
  lg: '1rem',
  lgx1: '1.2rem',
  lgx2: '1.6rem',
  lgx3: '2rem',
  lgx4: '2.4rem',
  lgx5: '2.8rem',
  lgx6: '3.2rem',
  full: '50%',
  pill: '999.9rem',
} as const
