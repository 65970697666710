import React from 'react'
import { TipButton } from './TipButton'
import type { Acceptor } from '@core/Areas/Racing/Hooks/useAcceptors'
import type { MeetingInformationDataTransferObject } from '@mobi/api-types'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { TipPriceAndScratchingsProps } from '@core/Areas/Racing/Components/BettableTips/helpers'
import {
  type TipSource,
  useBettableTipQuickBetting,
} from '@core/Areas/Racing/Components/BettableTips/Hooks/useBettableTipQuickBetting'

type Props = TipPriceAndScratchingsProps & {
  meeting: MeetingInformationDataTransferObject
  acceptor: Acceptor
  buttonPosition: number
  isRaceOpen: boolean
  tipSource: TipSource
}

export const BettableTip = ({
  meeting,
  acceptor,
  isFixedOdds,
  price,
  priceSource,
  isScratched,
  toteScratchType,
  isSuspended,
  buttonPosition,
  isRaceOpen,
  tipSource,
}: Props) => {
  const { loadQuickBet } = useBettableTipQuickBetting({
    meeting,
    acceptor,
    isFixedOdds,
    priceSource,
    buttonPosition,
    tipSource,
  })

  return (
    <TipButton
      number={acceptor.number}
      imageUrl={acceptor.imageUrlWithSizes.large.url}
      imageSize={acceptor.imageUrlWithSizes.large.size}
      price={price}
      isScratched={isScratched}
      toteScratchType={toteScratchType}
      isSuspended={isSuspended}
      meetingCode={meeting.MeetingCode.toLowerCase() as RaceCodesLowerCase}
      isRaceOpen={isRaceOpen}
      onClick={loadQuickBet}
    />
  )
}
