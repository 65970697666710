import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import {
  HeaderSelectionIconBoxStyled,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'

export const Sports: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const leg = ticket.legs[0]
  const selection = leg.selections[0].selectionDetails[0]

  return (
    <HeaderSelectionInfoStyled data-testid='SelectionInfo.Sports'>
      <HeaderSelectionIconBoxStyled>
        <Icon type='tab' />
      </HeaderSelectionIconBoxStyled>

      <div>
        <h3>{selection.acceptorDetails[0].name}</h3>
        <span>{ticket.productName}</span>
        <span>{leg.eventName}</span>
        <span>{leg.sportName}</span>
      </div>
    </HeaderSelectionInfoStyled>
  )
}
