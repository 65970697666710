import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'

interface MysteryQuickPickBonusToggleProps {
  selected: boolean
  disabled?: boolean
}

export const MysteryQuickPickBonusToggleStyled = styled(
  BaseButtonStyled
)<MysteryQuickPickBonusToggleProps>(
  {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '1rem',
  },

  ({ selected, disabled }) => ({
    background: selected ? hexColors.white : hexColors.orange,
    color: disabled ? 'default' : hexColors.black,
  })
)

export const ContainerStyled = styled.div({
  backgroundColor: hexColors.blueDiamond,
  color: hexColors.white,
  padding: '0 1.5rem 1.5rem',
  position: 'relative',
})
