import Index from './Index'
import SameAs from '../../../Model/SameAs'

export default class SameAsUIElement {
  constructor(row: number, columns: Array<SameAs>) {
    this.text = 'Same as ' + Index.toString(row)
    this.row = row
    this.columns = columns.slice(0).reverse()
  }

  public text: string
  public row: number
  public columns: Array<SameAs>
}
