import { useEffect } from 'react'
import { configureScope } from '@sentry/react'
import type { WithRequired } from '../types/utils'

type AreaTag = 'quickbet-deposit' | 'account-deposit' | 'self-verification'

type UseSentryScopeParams = {
  areaTag: AreaTag
  userId: number | null
  useUserIdAsFingerprint?: boolean
}

export const useSentryScope = ({
  areaTag,
  userId,
  useUserIdAsFingerprint = false,
}: UseSentryScopeParams) => {
  useEffect(() => {
    configureSentryScope({ areaTag, userId, useUserIdAsFingerprint })
    return () => {
      resetSentryScope(!!userId)
    }
  }, [areaTag, useUserIdAsFingerprint, userId])
}

const configureSentryScope = ({
  areaTag,
  userId,
  useUserIdAsFingerprint,
}: WithRequired<UseSentryScopeParams, 'useUserIdAsFingerprint'>) => {
  configureScope(scope => {
    scope.setTag('area', areaTag)

    if (userId) {
      scope.setUser({ id: userId })
      if (useUserIdAsFingerprint) {
        scope.setFingerprint([userId.toString()])
      }
    }
  })
}

const resetSentryScope = (clearUser: boolean) => {
  configureScope(scope => {
    scope.setTag('area', undefined)
    if (clearUser) {
      scope.setUser(null)
    }
    scope.setFingerprint(['{{ default }}'])
  })
}
