import { ComponentProps } from 'react'
import { store } from '@core/Store'
import { closeModal, openModal } from '@core/Components/Modal'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { AddCommentModal } from '@core/Areas/Blackbook/Components/AddCommentModal'
import type { BlackbookEntry } from '@core/Areas/Blackbook/types'
import {
  trackBlackbookAddCommentCancel,
  trackBlackbookAddCommentSave,
} from '@core/Areas/Blackbook/analytics'

const enum LocalConstants {
  AddEditCommentToBlackbookModal = 'add-edit-blackbook-comment',
}

export const addOrEditBlackbookComment = ({
  id,
  name,
  type,
  commentID,
  newComment,
}: {
  id: BlackbookEntry['Id']
  name: BlackbookEntry['StarterName']
  type: BlackbookEntry['Code']
  commentID?: number
  newComment?: string
}) => {
  const closeAddCommentModal = () =>
    store.dispatch(closeModal({ id: LocalConstants.AddEditCommentToBlackbookModal }))

  const reloadData = () => {
    closeAddCommentModal()
    queryClient.invalidateQueries({ queryKey: queryKeys.blackbookRunnersBase, refetchActive: true })
    queryClient.invalidateQueries({ queryKey: queryKeys.blackbookDetails, refetchActive: true })
    queryClient.invalidateQueries({
      queryKey: [queryKeys.blackbookComments, id],
      refetchActive: true,
    })
  }

  store.dispatch(
    openModal({
      id: LocalConstants.AddEditCommentToBlackbookModal,
      modalComponent: AddCommentModal,
      modalComponentProps: {
        onCommentSave: () => {
          reloadData()
          trackBlackbookAddCommentSave({ code: type, name })
        },
        onCancel: () => {
          closeAddCommentModal()
          trackBlackbookAddCommentCancel()
        },
        id,
        name,
        type,
        commentID,
        newComment,
      } as ComponentProps<typeof AddCommentModal>,
      color: 'light',
      isDismissable: false,
      position: 'middle',
      cornerStyle: 'round',
    })
  )
}
