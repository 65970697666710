import React from 'react'
import { Button } from '@mobi/component-library/Common/Buttons'
import {
  isReactNativeApp,
  isReactNativeIos,
  AppVersion,
  sendToNative,
} from '@mobi/web-native-comms/web'
import { store } from '@core/Store'
import { useFeature } from '@core/Utils/hooks'
import { closeModal, openModal } from '@core/Components/Modal'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { ShareLoading } from './Components/ShareLoading'
import { getFirebaseDynamicLinkFromNative } from '@core/NativeServices/DeepLinking'
import type { BlackbookEntry } from '@core/Areas/Blackbook/types'

const enum LocalConstants {
  ModalId = 'blackbook-share-loading',
}

export const ShareButton: React.FC<ShareButtonProps> = ({ name, type }) => {
  const isFeatureActive = useFeature('SHARE_BLACKBOOK_RUNNER')

  if (
    !isReactNativeApp ||
    !isFeatureActive ||
    !AppVersion ||
    +AppVersion.split('.')[0] < 208 ||
    !(name && type)
  )
    return null

  const handleClick = () => {
    trackBlackbookShare({ name, type })
    handleBlackbookShareClick({ name, type })
  }

  return (
    <Button onClick={handleClick} color='link' data-testid='blackbook-runner-share-button'>
      <Icon type={isReactNativeIos ? 'shareIos' : 'shareAndroid'} size='1.3rem' /> Share
    </Button>
  )
}

// =============
// Local Helpers
// =============

const handleBlackbookShareClick = async ({ name, type }: ShareButtonProps) => {
  try {
    store.dispatch(
      openModal({
        id: LocalConstants.ModalId,
        modalComponent: ShareLoading,
        isDismissable: false,
        position: 'middle',
        cornerStyle: 'round',
      })
    )

    const response = await buildLinkForShare({ name, type })

    sendToNative('SHARE_SHEET_REQUEST', {
      message: `${name} is one of my favourites. You should add it to your TABtouch Blackbook here:`,
      url: response.link,
    })
  } catch {
    RegisterToast({
      id: 'blackbook-share-cancel',
      message: 'Unable to Create Share',
      type: 'error',
      position: 'top',
      timeout: 0,
    })
  } finally {
    store.dispatch(closeModal({ id: LocalConstants.ModalId }))
  }
}

const buildLinkForShare = async ({ name, type }: ShareButtonProps): Promise<{ link: string }> => {
  let rawLink = `${window.location.origin}/?share-blackbook=${type},${name}`
  if (isReactNativeIos) {
    rawLink = rawLink.replace('customscheme://', 'https://')
  }
  const link = await getFirebaseDynamicLinkFromNative(encodeURI(rawLink))
  if (!link) throw new Error()
  return { link }
}

const trackBlackbookShare = (data: ShareButtonProps) => {
  trackEvent('share-blackbook', data)
}

// =====
// Types
// =====

type ShareButtonProps = { name: string; type: BlackbookEntry['Code'] }
