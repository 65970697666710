import * as Bowser from 'bowser'

export function isKambiSupportedBrowser(): boolean {
  /**
   * iOS 10 and above
   * Chrome 62 and above
   */
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isInvalidBrowser = !!browser.satisfies({
    chrome: '<=61',
    safari: '<=10',
  })
  return !isInvalidBrowser
}
