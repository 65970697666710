import React from 'react'
import { MysteryQuickPicksStyled, MysteryToggleButtonStyled } from '../Mystery.styles'
import { MysteryQuickPickOptions, MysteryQuickPickOption } from '../MysteryTypes'

interface MysteryQuickPicksProps {
  selectedKey: string | null
  disabledQuickPicks?: string[]
  optionsPerRow?: number
  selectionCallback: (key: string) => void
}

export function sortQuickPicks(options: MysteryQuickPickOption[]): MysteryQuickPickOption[] {
  return options.sort((a, b) =>
    a.displayOrder > b.displayOrder ? 1 : a.displayOrder === b.displayOrder ? 0 : -1
  )
}

export function MysteryQuickPicks({
  selectedKey,
  disabledQuickPicks,
  optionsPerRow,
  selectionCallback,
}: MysteryQuickPicksProps): JSX.Element {
  const quickPickOptions = sortQuickPicks(MysteryQuickPickOptions)

  let quickPicks = quickPickOptions.map(quickPick => {
    return (
      <MysteryQuickPick
        key={quickPick.uniqueKey}
        uniqueKey={quickPick.uniqueKey}
        displayPrice={quickPick.displayPrice}
        label={quickPick.label}
        selectedKey={selectedKey}
        disabledQuickPicks={disabledQuickPicks}
        selectionCallback={selectionCallback}
      />
    )
  })

  return (
    <MysteryQuickPicksStyled optionsPerRow={optionsPerRow}>{quickPicks}</MysteryQuickPicksStyled>
  )
}

type MysteryQuickPickProps = Pick<MysteryQuickPickOption, 'displayPrice' | 'label' | 'uniqueKey'> &
  MysteryQuickPicksProps

function MysteryQuickPick({
  displayPrice,
  label,
  uniqueKey,
  selectedKey,
  disabledQuickPicks,
  selectionCallback,
}: MysteryQuickPickProps): JSX.Element {
  disabledQuickPicks = disabledQuickPicks || []
  return (
    <MysteryToggleButtonStyled
      isSelected={uniqueKey === selectedKey}
      disabled={disabledQuickPicks.some(disabledQuickPick => uniqueKey === disabledQuickPick)}
      onClick={() => selectionCallback(uniqueKey)}
      data-tid-mystery-quickpick={uniqueKey}
      data-tid-mystery-qp-type={`$${displayPrice}${label.toUpperCase()}`}
    >
      <strong>${displayPrice}</strong>&nbsp;{label}
    </MysteryToggleButtonStyled>
  )
}
