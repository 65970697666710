import React, { type ButtonHTMLAttributes, type FC } from 'react'
import { ButtonCloseStyled } from './Buttons.styles'

type Props = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  icon: JSX.Element
}

export const CloseButton: FC<Props> = ({ onClick, icon }) => (
  <ButtonCloseStyled onClick={onClick}>{icon}</ButtonCloseStyled>
)
