import {
  UpdateAccountBalance,
  Campaign,
  UpdateCampaigns,
  UpdateBonusBalance,
} from './userAccountDriver'
import { post } from '@classic/Foundation/Services/ApiService'
import { sendToNative } from '@mobi/web-native-comms/web'

export async function fetchAccountBalanceAsync(): Promise<void> {
  const { balance } = await getAccountBalance()

  UpdateAccountBalance({ balance })
  sendToNative('ACCOUNT_BALANCE', { balance })
}

export async function fetchCampaignsAsync(): Promise<void> {
  const response = await getCampaigns()
  UpdateCampaigns(response.campaigns)
  UpdateBonusBalance({
    bonusBetBalance: response.bonusBetBalance,
    bonusCashBalance: response.bonusCashBalance,
  })
}

export interface GetAccountBalanceResponse {
  balance: number
  withdrawableBalance: number
}

export interface GetCampaignsResponse {
  bonusBetBalance: number
  bonusCashBalance: number
  campaigns: Campaign[]
}

export function getAccountBalance(): Promise<GetAccountBalanceResponse> {
  return post<GetAccountBalanceResponse>({ url: '/api/account/balance' })
}

export function getCampaigns(): Promise<GetCampaignsResponse> {
  return post<GetCampaignsResponse>({ url: '/api/account/campaigns' })
}
