import styled from '@emotion/styled'
import { ExpandablePanelComponentTheme } from './themes'
import { isEmpty } from '@core/Utils/object/object'

interface Theme {
  theme?: ExpandablePanelComponentTheme
}

export const CompactExpandablePanelStyled = styled('div')<Theme>(
  {
    position: 'relative',
    width: '100%',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          color: theme.header.text.color,
        }
)

interface CompactExpandablePanelHeaderStyledProps {
  theme?: ExpandablePanelComponentTheme
  hideHeaderTap?: boolean
}

export const CompactExpandablePanelHeaderStyled = styled(
  'header'
)<CompactExpandablePanelHeaderStyledProps>(
  {
    cursor: 'pointer',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          background: theme.header.background,
          color: theme.header.text.color,
        }
)
