import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import type { IConfigureBetType } from '@classic/Betting-v2/Model/Configuration/IConfigureBetType'
import BetTypeInformation from '@classic/Betting-v2/Model/Betting/BetTypeInformation'
import SameRaceMultiSelectionProcessor from './SameRaceMultiSelectionProcessor'
import SameRaceMultiValidator from './SameRaceMultiValidator'
import SameRaceMultiSelectionResultProcessor from './SameRaceMultiSelectionResultProcessor'

export default class SameRaceMultiConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.SameRaceMulti,
      name: 'Same Race Multi',

      legs: 4,
      sortOptions: ['Number', 'SameRaceMultiWinPrice'],

      template: 'same-race-multi-template',

      // rendering buttons: refer selection.tpl.. SameRaceMultiButtons.tsx
      betSelectionTemplate: 'same-race-multi-buttons-selection-template',

      // process button selection.. invoking validator
      processor: new SameRaceMultiSelectionProcessor(),

      // validate button selection.. invoked by Processor (during button press) and SelectionViewModel (to show/hide bet drawer)
      validator: new SameRaceMultiValidator(),

      // retrieve selection details required for betting, e.g. quickbet
      selectionResultProcessor: new SameRaceMultiSelectionResultProcessor(),

      // n/a 'tote style' product properties
      supportsFlexi: false,
      multiBet: false,
      multiSelections: false,
      checkBoxCount: 0,
      fieldCheckBoxCount: 0,
      sameAsCheckBoxCount: 0,
    })
  }
}
