import {
  state$,
  bettingV2CommandStateDefaultState,
  BettingV2CommandStateRecord,
} from '@classic/Betting-v2/Components/Commands/driver'
import { useObservable } from '@core/Utils/hooks'

export function useCommandState(): BettingV2CommandStateRecord {
  return useObservable(state$, bettingV2CommandStateDefaultState)
}
