import React from 'react'
import { hexColors } from '@mobi/settings'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { openDeposit } from '@core/Areas/Deposit'
import type { usePendingTicketsCount } from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/usePendingTicketsCount'
import { Icon } from '@mobi/component-library/Common/Icon'
import { usePrevious } from '@core/Utils/hooks'
import { postLogout } from '@core/Areas/Login/helpers/api'
import { useGetAccountDetails } from '@core/Areas/Account/helpers/useGetAccountDetails'
import type { BetAccount } from '@mobi/api-types'
import { BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'
import { ReverificationAlert } from '@core/Areas/Reverification/Alert'
import { deregisterBodyScrollLock, registerBodyScrollLock } from '@mobi/utils/functions'
import { SecondaryButtonStyled } from '@mobi/component-library/Common/Buttons'
import {
  AccountDropDownWrapStyled as DropDownWrap,
  AccountInfoWrapStyled as InfoWrap,
  AccountNameLabelStyled,
  AccountNumberLabelStyled,
  DepositWithdrawSectionStyled,
  ItemContainerStyled,
  LinkStyled,
  LogoutLinkStyled,
  SeparatorStyled,
} from './Wallet.styles'
import { useAnalytics } from './helpers/useAnalytics'
import { OffersPanel } from './Components/OffersPanel'
import { Badge } from '@core/Components/Badge/Badge'
import { BalanceSection } from '@core/Areas/AppHeader/Components/HamburgerMenu/Components/BalanceSection/BalanceSection'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { getIsDropDownOpen } from '../../Store/selectors'

const LOADING_TEXT = 'Loading...'

export const Wallet: React.FC<ReturnType<typeof usePendingTicketsCount>> = ({
  pendingBetsCount,
  doPendingBetsExceedMaxCount,
}) => {
  const dispatch = useAppDispatch()

  const [accountName, setAccountName] = React.useState<[string, string]>()
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight)
  const [dragDistance, setDragDistance] = React.useState<number | null>(null)

  const isDropDownOpen = useAppSelector(getIsDropDownOpen)

  const accountDropDownRef = React.useRef<HTMLDivElement>(null)
  const prevIsOpen = usePrevious(isDropDownOpen)

  const {
    accountNumber = LOADING_TEXT,
    activeCampaignsCount = 0,
    blackBookAlertCount = 0,
  } = useGetAccountDetails(isDropDownOpen)?.toJS() || {}

  useAnalytics(isDropDownOpen)

  React.useEffect(() => {
    if (!isDropDownOpen) {
      if (accountDropDownRef.current) {
        deregisterBodyScrollLock(accountDropDownRef.current)
      }
      return undefined
    }

    let touchStartY: number | undefined = undefined

    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') dispatch(toggleIsDropDownOpen())
    }

    const touchStartHandler = (e: TouchEvent) => {
      if (accountDropDownRef.current) {
        const isAtBottom =
          accountDropDownRef.current.scrollTop + accountDropDownRef.current.offsetHeight >=
          accountDropDownRef.current.scrollHeight
        if (isAtBottom) {
          touchStartY = e?.touches?.[0].clientY
        } else {
          touchStartY = undefined
        }
        registerBodyScrollLock(accountDropDownRef.current)
      }
    }

    const touchMoveHandler = (e: TouchEvent) => {
      // Slide the menu around as the user drags it.
      if (touchStartY !== undefined) {
        const yDiff =
          (e.changedTouches[0]?.clientY === undefined ? touchStartY : e.changedTouches[0].clientY) -
          touchStartY

        setDragDistance(Math.min(yDiff, 0))
      }
    }

    const touchEndHandler = (e: TouchEvent) => {
      if (touchStartY !== undefined) {
        const yDiff =
          touchStartY -
          (e.changedTouches[0]?.clientY === undefined ? touchStartY : e.changedTouches[0].clientY)

        if (yDiff > 100) {
          // It was a significant swipe
          dispatch(toggleIsDropDownOpen())
        }

        setDragDistance(null)
      }

      if (accountDropDownRef.current) {
        deregisterBodyScrollLock(accountDropDownRef.current)
      }
    }

    const parent = accountDropDownRef.current?.closest('html')
    parent?.addEventListener('keydown', keyPressHandler)
    parent?.addEventListener('touchstart', touchStartHandler)
    parent?.addEventListener('touchmove', touchMoveHandler)
    parent?.addEventListener('touchend', touchEndHandler)

    return function cleanUp() {
      parent?.removeEventListener('keydown', keyPressHandler)
      parent?.removeEventListener('touchstart', touchStartHandler)
      parent?.removeEventListener('touchmove', touchMoveHandler)
      parent?.removeEventListener('touchend', touchEndHandler)
    }
  }, [isDropDownOpen, dispatch])

  React.useEffect(() => {
    BetAccountRepo.getPromise(BetAccountKey)
      .then(({ PreferredName, PrimaryAcctHolderName }: BetAccount) => {
        setAccountName([PreferredName, PrimaryAcctHolderName])
      })
      .catch(() => {
        setAccountName(undefined)
      })
  }, [])

  React.useEffect(() => {
    const updateInnerHeight = () => {
      setTimeout(() => {
        setInnerHeight(window.innerHeight)
      }, 200)
    }
    window.addEventListener('resize', updateInnerHeight)
    return () => {
      window.removeEventListener('resize', updateInnerHeight)
    }
  }, [])

  React.useEffect(() => {
    if (!accountDropDownRef.current || isDropDownOpen === prevIsOpen) return

    const dropDownEl = accountDropDownRef.current

    if (isDropDownOpen) {
      registerBodyScrollLock(dropDownEl)
      setTimeout(() => {
        setInnerHeight(window.innerHeight)
      }, 200)
      dropDownEl.scrollTop = 0
    } else {
      deregisterBodyScrollLock(dropDownEl)
    }
  }, [isDropDownOpen, prevIsOpen, accountDropDownRef])

  React.useEffect(() => {
    const dropDownEl = accountDropDownRef.current
    return () => {
      dropDownEl && deregisterBodyScrollLock(dropDownEl)
    }
  }, [])

  return (
    <DropDownWrap
      ref={accountDropDownRef}
      innerHeight={innerHeight}
      isVisible={isDropDownOpen}
      aria-hidden={!isDropDownOpen}
      style={{
        transform:
          !isDropDownOpen || (dragDistance || 0) > -30
            ? undefined
            : `translateY(${dragDistance}px)`,
      }}
      data-tid-account-dropdown
    >
      <ReverificationAlert />

      <InfoWrap>
        <AccountNameLabelStyled data-tid-account-dropdown-account-name>
          {accountName ? accountName[0] || accountName[1] : LOADING_TEXT}
        </AccountNameLabelStyled>
        <AccountNumberLabelStyled data-tid-account-dropdown-account-number>
          Account # {accountNumber}
        </AccountNumberLabelStyled>
      </InfoWrap>

      {isDropDownOpen && (
        <OffersPanel bonusBetCount={activeCampaignsCount} isOpen={isDropDownOpen} />
      )}

      <SeparatorStyled />

      <BalanceSection />

      <SeparatorStyled />

      <DepositWithdrawSectionStyled>
        <SecondaryButtonStyled
          role='button'
          data-tid-account-dropdown-deposit
          onClick={() => {
            dispatch(toggleIsDropDownOpen(false))
            openDeposit()
          }}
          aria-label='Deposit'
        >
          <Icon type='arrowdownwithtail' size='1.5rem' color={hexColors.studio} />
          Deposit
        </SecondaryButtonStyled>

        <SecondaryButtonStyled
          data-nav=''
          data-tid-account-dropdown-withdrawal
          href='/#account/withdrawal'
        >
          <Icon type='arrowupwithtail' size='1.5rem' color={hexColors.studio} />
          Withdraw
        </SecondaryButtonStyled>
      </DepositWithdrawSectionStyled>

      <SeparatorStyled />

      <ItemContainerStyled>
        <LinkStyled
          href='/#account/activity/pending'
          data-tid-account-dropdown-pending-racing
          data-testid='pending-bets-link'
          data-nav=''
          aria-label={`You have ${pendingBetsCount} pending bet(s)`}
        >
          Pending bets
          {pendingBetsCount > 0 && (
            <Badge count={pendingBetsCount} showPlusIcon={doPendingBetsExceedMaxCount} />
          )}
        </LinkStyled>
      </ItemContainerStyled>
      <ItemContainerStyled>
        <LinkStyled href='/#account/activity/results' data-tid-my-account-resulted data-nav=''>
          Resulted bets
        </LinkStyled>
      </ItemContainerStyled>
      <ItemContainerStyled>
        <LinkStyled
          href='/#account/blackbook'
          data-nav=''
          data-tid-account-dropdown-blackbook
          data-testid='blackbook-link'
        >
          Blackbook
          {blackBookAlertCount > 0 && (
            <Badge count={blackBookAlertCount} data-testid='wallet-blackbook-badge' />
          )}
        </LinkStyled>
      </ItemContainerStyled>
      <SeparatorStyled />

      <ItemContainerStyled>
        <LinkStyled href='/#account' data-nav='' data-tid-account-dropdown-account>
          My account
        </LinkStyled>
      </ItemContainerStyled>
      <ItemContainerStyled>
        <LogoutLinkStyled
          onClick={() => {
            postLogout()
            dispatch(toggleIsDropDownOpen(false))
          }}
          data-tid-account-dropdown-logout
        >
          <Icon type='logout' size='1.7rem' color={hexColors.crimson} />
          Log out
        </LogoutLinkStyled>
      </ItemContainerStyled>
    </DropDownWrap>
  )
}
