import * as nav from '@classic/Foundation/Navigation/Nav'
import { DepositLimitsHandler } from './DepositLimits'
import { DepositLimitsEditHandler } from './DepositLimitsEdit'
import { ReactRouterHandler } from './ReactRouter'
import { APP_ROUTES_REGEX } from '@core/App'

export function registerSubApplicationHandlerRoutes(): void {
  nav.subAppRouting.defineRoute(APP_ROUTES_REGEX, new ReactRouterHandler())

  nav.subAppRouting.defineRoute('account/deposit-limits(\\?)*', new DepositLimitsHandler())
  nav.subAppRouting.defineRoute(
    'account/deposit-limits/edit/([0-9]+)$',
    new DepositLimitsEditHandler()
  )
}
