import styled from '@emotion/styled'
import { Money } from '@core/Components/Text/Money'

export const ProjectedReturnStyled = styled('div')({
  fontSize: '1.2rem',
  textAlign: 'right',
  lineHeight: 'normal',
  padding: '1.6rem 0',
  whiteSpace: 'nowrap',
})

export const ProjectedReturnMoneyStyled = styled(Money)({
  fontSize: '1.4rem',
  fontWeight: 'bold',
})
