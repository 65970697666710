import * as ko from 'knockout'
import { TrifectaViewModel } from './TrifectaViewModel'
import template from './trifecta.tpl.html'

if (!ko.components.isRegistered('trifecta-bettype')) {
  ko.components.register('trifecta-bettype', {
    viewModel: TrifectaViewModel,
    template,
  })
}
