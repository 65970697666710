import React from 'react'
import { CompletedWithdrawalDetailsStyled } from './CompletedWithdrawalDetails.styles'
import { CancellationState } from '@core/Areas/AccountActivity/types'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { queryClient } from '@core/Data/ReactQuery'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const CompletedWithdrawalDetails: React.FC<{
  cancellationState: CancellationState
}> = ({ cancellationState }) => {
  const isSuccessfullyCompleted = cancellationState === 'completed'
  const message = isSuccessfullyCompleted
    ? 'Cancellation was successful. Your balance has been updated.'
    : 'Your transaction could not be cancelled.'

  const clearTransactionsCache = () => {
    queryClient.invalidateQueries(queryKeys.accountActivityData('transactions', false))
  }

  return (
    <CompletedWithdrawalDetailsStyled data-testid='transaction-completed-withdrawal'>
      <div>
        <NoticeBox
          title={message}
          noticeBoxType={isSuccessfullyCompleted ? NoticeBoxTypes.Success : NoticeBoxTypes.Error}
        />
      </div>
      <div>
        <ButtonBlock data-tid-close-button onClick={clearTransactionsCache}>
          Close
        </ButtonBlock>
      </div>
    </CompletedWithdrawalDetailsStyled>
  )
}
