import React, { type FC } from 'react'
import { ButtonStyled } from './Buttons.styles'
import type { ButtonProps } from './types'

export const Button: FC<ButtonProps> = ({
  onClick,
  children,
  color = 'primary',
  selected = false,
  uppercaseText = false,
  disabled = false,
  ...buttonProps
}) => (
  <ButtonStyled
    {...buttonProps}
    onClick={onClick}
    color={color}
    selected={selected}
    uppercaseText={uppercaseText}
    disabled={disabled}
  >
    {children}
  </ButtonStyled>
)
