import React from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { createImageUrlFromHtml } from '../helpers/createImageUrlFromHtml'
import { convertImageUrlToBlob } from '../helpers/convertImageUrlToBlob'
import { setTemporaryWidthOfPreviewElement } from '../helpers'

export const useCreateImageForBrowser = (
  shouldHideCost: boolean,
  setIsBusy: React.Dispatch<React.SetStateAction<boolean>>,
  previewImageElRef: React.RefObject<HTMLDivElement>
): Blob | undefined => {
  const previewImageDataRef = React.useRef<Blob>()

  React.useEffect(() => {
    if (isReactNativeApp || !previewImageElRef.current) return

    setIsBusy(true)
    const previewEl = previewImageElRef.current
    const resetTempWidth = setTemporaryWidthOfPreviewElement(previewEl)

    setImageBlobOnRef(previewEl, previewImageDataRef).finally(() => {
      setIsBusy(false)
      resetTempWidth()
    })
  }, [shouldHideCost, setIsBusy, previewImageElRef])

  return previewImageDataRef.current
}

// Helpers
async function setImageBlobOnRef(el: HTMLElement, ref: React.MutableRefObject<Blob | undefined>) {
  const imageUrl = await createImageUrlFromHtml(el)
  const blob = await convertImageUrlToBlob(imageUrl)
  ref.current = blob
}
