import React from 'react'
import { TabButton, Tabs } from '@mobi/component-library/Navigation/Tabs'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { hasInvestment, isSingleAllowedInMulti, isValidMulti } from '@mobi/betslip/helpers/state'
import { MIN_LEGS_IN_MULTI } from '@mobi/betslip/helpers/constants'

export const TabButtons: React.FC = () => {
  const items = useAppSelector(selectBetSlipItems)
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)

  const multiItems = items.filter(isSingleAllowedInMulti)
  const activeMultiItems = multiItems.filter(item => item.isInMulti)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, activeMultiItems)

  const isEditable = workflowStatus === 'ready'

  const shouldRenderSinglesTab = isEditable || (!isEditable && items.some(hasInvestment))

  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI

  return (
    <Tabs>
      {shouldRenderSinglesTab && (
        <TabButton
          name={`Singles (${items.length})`}
          onClick={() => null}
          isSelected
          shouldFillSpace={false}
        />
      )}

      {((isEditable && hasEnoughMultiItems) || (!isEditable && isMultiValid)) && (
        <TabButton
          name='Multi (1)'
          onClick={() => null}
          isSelected={false}
          shouldFillSpace={false}
        />
      )}
    </Tabs>
  )
}
