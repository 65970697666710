import { deleteAsync, post } from '@classic/Foundation/Services/ApiService'
import { defaultStartupParameters } from '@core/Areas/Kambi/config/startupParameters'
import {
  LoggedInKambiPlayer as SignalLoggedInKambiPlayer,
  LoggedOutKambiPlayer as SignalLoggedOutKambiPlayer,
} from '@core/Areas/Kambi/driver'
import { getWapi, kambiLog, KambiRegulationId } from '@core/Areas/Kambi/helpers'
import { getFromLocalStorage, removeFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'
import { createLogger } from './logging'

const LOCAL_STORAGE_LOGOUT_TOKEN = 'KambiLogoutToken'

export const tryToLoginKambiPlayer = async (
  accountNumber: number,
  regulationId: KambiRegulationId
): Promise<void> => {
  await retry(
    async () => {
      kambiLog(`user ${accountNumber} getting temp ticket`)
      const data = await post<{
        ticket: string
      }>({
        url: '/api/kambi/issue-temp-ticket',
        body: {
          regulationId,
        },
      })

      if (!data.ticket) {
        kambiLog('issue-temp-ticket responded with ticket:' + data.ticket)
        throw Error
      }

      kambiLog(`got temp ticket ${data.ticket}, logging Kambi in`)

      const wapiRequest = {
        punterId: accountNumber?.toString(),
        ticket: data.ticket,
        currency: defaultStartupParameters.currency,
        locale: defaultStartupParameters.locale,
        market: defaultStartupParameters.market,
      }
      const wapi = await getWapi()
      wapi.request(wapi.LOGIN, wapiRequest)

      const currentLogoutToken = data.ticket
      const previousLogoutToken = getFromLocalStorage(LOCAL_STORAGE_LOGOUT_TOKEN)

      // Clean up previous session if exists
      if (previousLogoutToken && previousLogoutToken !== currentLogoutToken) {
        kambiLog(
          'logout token is not the same, delete previous session for logout token',
          previousLogoutToken
        )
        await deletePlayerSession(previousLogoutToken)
      }

      setInLocalStorage(LOCAL_STORAGE_LOGOUT_TOKEN, currentLogoutToken)
      SignalLoggedInKambiPlayer(currentLogoutToken)
      kambiLog(`user ${accountNumber} logged into kambi`)
    },
    750,
    3
  )
}

export const tryToLogoutKambiPlayer = async (logoutToken: string): Promise<void> => {
  await retry(
    async () => {
      await deletePlayerSession(logoutToken)
      const wapi = await getWapi()
      wapi.request(wapi.LOGOUT)
      SignalLoggedOutKambiPlayer()
      kambiLog('kambi player logged out')
    },
    750,
    3
  )
}

// =============
// Local Helpers
// =============

async function deletePlayerSession(logoutToken: string): Promise<void> {
  kambiLog('trying to delete session for logout token', logoutToken)
  await deleteAsync({
    url: '/api/kambi/logout',
    body: {
      logoutToken,
    },
  })
  removeFromLocalStorage(LOCAL_STORAGE_LOGOUT_TOKEN)
  kambiLog('deleted session for logout token', logoutToken)
}

async function retry<T>(operation: () => Promise<T>, delay: number, times: number): Promise<T> {
  const log = createLogger('greenyellow', 'Retry')
  try {
    return await operation()
  } catch (ex) {
    log('Operation failed - retries left', times)
    if (times > 1) {
      await new Promise(resolve => setTimeout(resolve, delay))
      return retry(operation, delay, times - 1)
    } else {
      log('Operation failed - done, no more retries')
      throw ex
    }
  }
}
