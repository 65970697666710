import * as ko from 'knockout'
import jQuery from 'jquery'
import EventAggregator from '@classic/AppUtils/Framework/Messaging/EventAggregator'
import { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'

export class ExpandPanelViewModel {
  constructor(
    params: {
      data: {}
      panelName: string
      shouldScrollTo: boolean
      initialPanelState: ko.Observable<boolean>
      containerClass: string
      panelClass: string
    },
    panelElement: HTMLElement,
    templateNodes: HTMLElement[]
  ) {
    this.panelElement = panelElement
    this.panelName = params.panelName
    this.shouldScrollTo = params.shouldScrollTo
    let initialState = params.initialPanelState != undefined ? params.initialPanelState() : false
    this.isExpanded = ko.observable(initialState)
    this.context = params.data

    this.headerContent = (templateNodes.find(x => x.nodeName == 'HEADER') as HTMLElement).childNodes
    this.panelContent = (
      templateNodes.find(x => x.nodeName == 'EXPAND-PANEL-CONTENT') as HTMLElement
    ).childNodes

    this.eventAggregator = this.getEventAggregator()

    this.panelContainerCssClasses = ko.pureComputed<string>(() => {
      let open = this.isExpanded() ? ' open' : ''
      let container = params.containerClass || 'black-flat-container '
      return container + open
    })

    this.panelCssClasses = ko.pureComputed<string>(() => {
      let container = params.panelClass || 'black-flat '
      return container
    })
  }

  private publishPanelStateChanged(): void {
    if (this.panelName != undefined && this.panelName.length > 0) {
      this.eventAggregator.publish(this.panelName + '-panel-state-changed', this.isExpanded())
    }
  }

  protected getEventAggregator(): IEventAggregator {
    return new EventAggregator()
  }

  public context: unknown
  public panelElement: HTMLElement
  public headerContent: NodeList
  public panelContent: NodeList

  public isExpanded: ko.Observable<boolean>
  public panelContainerCssClasses: ko.Computed<string>
  public panelCssClasses: ko.Computed<string>

  private eventAggregator: IEventAggregator
  private panelName: string
  public shouldScrollTo: boolean

  public toggle(): void {
    this.isExpanded(!this.isExpanded())

    if (this.shouldScrollTo) {
      if (this.panelElement) {
        var loc = jQuery(this.panelElement).length ? jQuery(this.panelElement).position().top : 0
        jQuery('html:not(:animated),body:not(:animated)').animate({ scrollTop: loc }, 500)
      }
    }

    this.publishPanelStateChanged()
  }
}
