import React, { HTMLAttributes, memo, type ReactNode } from 'react'
import { BannerStyled, type ThemeName } from './Banner.styles'
import BannerIcon from './BannerIcon'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { colors } from '@mobi/component-library/Theme/Common'

type Props = {
  type?: ThemeName
  text: string
  button?: ReactNode
  hideIcon?: boolean
  onClickDismiss?: () => void
} & HTMLAttributes<HTMLDivElement>

export const Banner = memo<Props>(
  ({ text, type = 'neutral', button, hideIcon = false, onClickDismiss, ...props }) => {
    return (
      <BannerStyled type={type} {...props}>
        {!hideIcon && <BannerIcon theme={type} />}

        <p>{text}</p>

        {button}

        {onClickDismiss && (
          <Button
            onClick={() => onClickDismiss()}
            color='tertiary_grey'
            size='sm'
            isIconOnlyButton
            aria-label='Dismiss banner'
          >
            <Icon name='SolidXClose' color={colors.white} />
          </Button>
        )}
      </BannerStyled>
    )
  }
)

Banner.displayName = 'Banner'
