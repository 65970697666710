import type { Updater } from 'react-query/types/core/utils'
import { toTitleCase } from '@mobi/utils/string'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { RegisterToast, RemoveToast } from '@core/Components/Toast/ToastDriver'
import { promptUserAndReturnDecision } from '@core/Components/Modal/Components/PromptForUserDecision/helpers'
import type { BlackbookDetailsResponse, BlackbookEntry } from '@core/Areas/Blackbook/types'
import { trackBlackbookEntryDeleteCanceled, trackBlackbookEntryDeleted } from '../analytics'
import { deleteBlackbookEntry } from './api'

const enum LocalConstants {
  RemoveRunnerModalId = 'remove-blackbook-runner',
}

export const deleteRunnerFromBlackbook = async ({ id, name, type }: DeleteRunnerArgs) => {
  let subtitle = ''

  if (type === 'Jockeys') {
    subtitle = `Are you sure you want to remove the jockey "${toTitleCase(
      name
    )}" from your Blackbook?`
  } else {
    subtitle = `Are you sure you want to remove "${toTitleCase(name)}" from your Blackbook?`
  }

  const decision = await promptUserAndReturnDecision({
    id: LocalConstants.RemoveRunnerModalId,
    testId: 'blackboook-remove-runner',
    title: 'Remove from Blackbook',
    subtitle,
    buttons: [
      { label: 'Cancel', color: 'secondary' },
      { label: 'Yes, delete', color: 'danger' },
    ],
  })

  if (decision === 'Yes, delete') {
    const toastBusyId = 'blackbook-delete-busy'
    let toastBusytimeoutId: number | null = null

    try {
      toastBusytimeoutId = window.setTimeout(() => {
        RegisterToast({
          id: toastBusyId,
          message: 'Deleting Runner',
          timeout: 0,
          spinner: true,
        })
      }, 1000)

      await deleteBlackbookEntry(id)

      invalidateQueriesOnSuccessfulDelete(id)

      trackBlackbookEntryDeleted(type)
    } catch {
      RegisterToast({
        id: 'blackbook-runner-delete-error',
        message: 'Unable to Delete Runner',
        type: 'error',
        timeout: 0,
      })
    }

    toastBusytimeoutId && clearTimeout(toastBusytimeoutId)
    RemoveToast(toastBusyId)
  } else {
    trackBlackbookEntryDeleteCanceled()
  }
}

// =============
// Local Helpers
// =============

function invalidateQueriesOnSuccessfulDelete(id: BlackbookEntry['Id']) {
  queryClient.invalidateQueries({ queryKey: queryKeys.blackbookDetails, refetchActive: false })
  queryClient.setQueryData<BlackbookCachedQuery>(
    queryKeys.blackbookDetails,
    removeRunnerFromBlackbookCache(id)
  )
  queryClient.invalidateQueries({
    queryKey: queryKeys.blackbookRunnersBase,
    refetchInactive: true,
  })
}

const removeRunnerFromBlackbookCache: (
  id: number
) => Updater<BlackbookCachedQuery, BlackbookCachedQuery> = id => oldData => {
  if (!oldData) return undefined
  const newEntries = oldData?.Entries?.filter(entry => entry.Id !== id) || null
  return { ...oldData, Entries: newEntries }
}

// =====
// Types
// =====

type BlackbookCachedQuery = BlackbookDetailsResponse | undefined

type DeleteRunnerArgs = {
  id: BlackbookEntry['Id']
  name: string
  type: BlackbookEntry['Code']
}
