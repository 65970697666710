import React from 'react'
import { useQuery } from 'react-query'
import { isToteRacePushEvent, ToteRaceEventPushEvent } from 'tabtouch-push-contract'
import { dayjs } from '@mobi/utils'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { event$ } from '@core/State/PushData'
import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'
import type { RaceListResponse } from './types'
import { getMeetingRaces } from './api'

export const useRaceList = (
  meetingDate: string,
  meetingId?: string,
  compSeq?: string,
  product?: string
) => {
  const date = formatDateStringWithoutChangingTimezone(meetingDate, 'YYYY-MM-DD')
  const queryKey = queryKeys.raceList(meetingId || compSeq || product || '', date)

  // invalidate meetings cache when tote race status updated
  React.useEffect(() => {
    const iotSubscription = event$
      .filter(x => isToteRacePushEvent(x.payload)) // Race update events
      .filter(x => isToteRaceChangePushEvent((x.payload as ToteRaceEventPushEvent).status)) // Filter events
      .delay(5_000) // a  delay to cater for potential race condition between mobi push and api
      .subscribe(x => {
        const raceDetails = (x.payload as ToteRaceEventPushEvent).race
        if (raceDetails) {
          queryClient.invalidateQueries({
            queryKey: queryKeys.raceList(
              raceDetails.meetingCode,
              dayjs(raceDetails.meetingDate).format('YYYY-MM-DD')
            ),
            refetchActive: true,
            refetchInactive: false,
          })
        }
      })

    return () => iotSubscription.dispose()
  }, [])

  // Subscribe to race closed events and update cache immediately to show race 'closed'
  // We need this because we have a delay on the invalidation on the query due to the potential for
  // race conditions between the push events and the Mobi API updating
  React.useEffect(() => {
    const iotSubscription = event$
      .filter(x => isToteRacePushEvent(x.payload))
      // Only update race closed events
      .filter(x => (x.payload as ToteRaceEventPushEvent).status == 'raceclosed')
      .subscribe(x => {
        const updatedRace = (x.payload as ToteRaceEventPushEvent).race
        if (!updatedRace) return
        const queryKey = queryKeys.raceList(
          updatedRace.meetingCode,
          dayjs(updatedRace.meetingDate).format('YYYY-MM-DD')
        )
        queryClient.setQueryData<RaceListResponse | undefined>(
          queryKey,
          (current?: RaceListResponse) => {
            const raceIndex = current?.races.findIndex(
              race => race.raceNumber === updatedRace.raceNumber
            )

            if (!current || raceIndex == undefined) return current

            current.races[raceIndex].raceStatus = 'Closed'
            return current
          }
        )
      })

    return () => iotSubscription.dispose()
  }, [])

  // Invalidate old tote data on mount
  React.useEffect(() => {
    if (meetingId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.raceList(meetingId, dayjs(date).format('YYYY-MM-DD')),
        refetchActive: true,
        refetchInactive: false,
      })
    }
  }, [meetingId, date])

  const { data, isError, isLoading } = useQuery({
    queryFn: () => getMeetingRaces({ date, meetingId, compSeq, product }),
    queryKey,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    // We invalidate Tote data base on push events, we dont have this for FOO
    staleTime: meetingId ? Infinity : 10 * 1000,
  })

  return { meeting: data, isLoading, isError }
}

// Local helpers

function isToteRaceChangePushEvent(status: string): boolean {
  return [
    'raceopened',
    'raceclosed',
    'raceabandon',
    'raceunabandon',
    'raceenterresults',
    'racecancelresults',
    'racerelease',
    'racecancelrelease',
  ].includes(status)
}
