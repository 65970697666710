import * as ko from 'knockout'

export default class ObservableSameRaceMultiPrices {
  constructor() {
    this.win = ko.observable<number>()
    this.top2 = ko.observable<number>()
    this.top3 = ko.observable<number>()
    this.top4 = ko.observable<number>()
    this.isFavourite = ko.observable<boolean>()

    // for consistency with FixedOddsStarterInfo.isSuspended, SRM.isSuspended is deliberately undefined until a valid SRM price object is processed (refer BaseRaceCodeBuilder)
    this.isSuspended = ko.observable<boolean>()
  }

  public merge(other: ObservableSameRaceMultiPrices) {
    this.win(other.win())
    this.top2(other.top2())
    this.top3(other.top3())
    this.top4(other.top4())
    this.isFavourite(other.isFavourite())
    this.isSuspended(other.isSuspended())
  }

  public win: ko.Observable<number | undefined>
  public top2: ko.Observable<number | undefined>
  public top3: ko.Observable<number | undefined>
  public top4: ko.Observable<number | undefined>
  public isFavourite: ko.Observable<boolean | undefined>
  public isSuspended: ko.Observable<boolean | undefined>
}
