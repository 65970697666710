import React from 'react'
import { Race } from '@core/Areas/Racing/Types'
import { DateTime } from '@core/Components/DateTime'
import { SeparatorBullet } from '@core/Components/Separator'

type Props = {
  race: Pick<Race, 'raceTime' | 'provDivUpdateTime'>
}

const RaceTime = ({ race }: Props): JSX.Element => {
  const { raceTime, provDivUpdateTime } = race

  return (
    <span data-testid='race-time'>
      <DateTime date={raceTime} format='HH:mm ddd D MMM' />

      {provDivUpdateTime && (
        <span data-testid='prov-div-update-time'>
          {' '}
          <strong>
            <SeparatorBullet />
          </strong>{' '}
          <DateTime date={provDivUpdateTime} format='[Updated at] HH:mm, D MMM YYYY' />
        </span>
      )}
    </span>
  )
}

export default RaceTime
