import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IScratchingsViewModel } from './IScratchingsViewModel'
import template from './scratchings.tpl.html'
import ObservableScratching from '@classic/Betting-v2/Model/Observables/ObservableScratching'

interface Params {
  availableScratchings: ko.ObservableArray<ObservableScratching>
}

export function register(): void {
  if (!ko.components.isRegistered('scratchings-panel')) {
    ko.components.register('scratchings-panel', {
      viewModel: {
        createViewModel: (params: Params): IScratchingsViewModel => {
          const viewModel = container.get<IScratchingsViewModel>('IScratchingsViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
