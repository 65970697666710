import React from 'react'
import dayjs from 'dayjs'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import {
  StructuredSearchResultASTTimerSTyled,
  StructuredLocationTimeStyled,
  StructuredSearchLocationNameStyled,
  StructuredRaceNumberStyled,
  StructuredSearchResultIconStyled,
  StructuredSearchLocationNameContainerStyled,
} from './StructuredLocationTime.styles'
import { getAstDetails } from '../../Search/Components/SearchResultsContainers/utils'

export interface StructuredLocationTimeProps {
  locationName: string
  raceNumber?: number
  code?: RaceCodes
  advertisedStartTime: string
  currentDateMilli: number
  status?: string
  scratchedStatus?: string
}

export function StructuredLocationTime({
  locationName,
  raceNumber,
  code,
  advertisedStartTime,
  currentDateMilli,
}: StructuredLocationTimeProps) {
  const astDetails = getAstDetails(advertisedStartTime, currentDateMilli)

  return (
    <StructuredLocationTimeStyled>
      <StructuredSearchResultASTTimerSTyled>
        {astDetails.showDay ? (
          <span>{dayjs(advertisedStartTime).format('DD MMM hh:mm A')}</span>
        ) : (
          <RaceCountdown raceStartTime={astDetails.advertisedStartTime} displaySize={'1rem'} />
        )}
      </StructuredSearchResultASTTimerSTyled>
      <StructuredSearchLocationNameContainerStyled>
        <StructuredSearchLocationNameStyled data-tid-structured-search-location={locationName}>
          {locationName}
        </StructuredSearchLocationNameStyled>
        <RaceLocation raceNumber={raceNumber} code={code} />
      </StructuredSearchLocationNameContainerStyled>
    </StructuredLocationTimeStyled>
  )
}

type RaceLocationProps = Pick<StructuredLocationTimeProps, 'raceNumber' | 'code'>

function RaceLocation({ raceNumber, code }: RaceLocationProps) {
  let codeIcon

  switch (code) {
    case 'Races':
      codeIcon = <Icon type='races' />
      break
    case 'Dogs':
      codeIcon = <Icon type='dog' />
      break
    case 'Trots':
      codeIcon = <Icon type='trot' />
      break
    default:
      codeIcon = null
  }

  const raceNumberString: string = `R${raceNumber}`

  return (
    <>
      <StructuredRaceNumberStyled>{raceNumberString}</StructuredRaceNumberStyled>{' '}
      <StructuredSearchResultIconStyled>{codeIcon}</StructuredSearchResultIconStyled>
    </>
  )
}
