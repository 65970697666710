import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NavigationState {
  isRadioPlaying: boolean
}

const commonInitialState: NavigationState = {
  isRadioPlaying: false,
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: commonInitialState,
  reducers: {
    setRadioPlaying(state, action: PayloadAction<boolean>) {
      state.isRadioPlaying = action.payload
    },
  },
})

export const { setRadioPlaying } = navigationSlice.actions

export default navigationSlice.reducer
