import WinPlaceSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/WinPlaceSelectionProcessor'
import WinPlaceValidator from '@classic/Betting-v2/Validation/WinPlaceValidator'
import WinPlaceSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/WinPlaceSelectionResultProcessor'
import type { IConfigureBetType } from './IConfigureBetType'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class WinPlaceConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.WinPlace,
      name: 'Win & Place',
      supportsFlexi: false,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'winplace-template',
      betSelectionTemplate: 'buttons-selection-template',
      multiBet: false,
      multiSelections: false,
      fieldCheckBoxCount: 1,
      checkBoxCount: 0,
      sameAsCheckBoxCount: 0,
      processor: new WinPlaceSelectionProcessor(),
      validator: new WinPlaceValidator(),
      selectionResultProcessor: new WinPlaceSelectionResultProcessor(),
      legs: 1,
    })
  }
}
