import { getImageUrlWithSizes } from '@core/Areas/RaceCardFixedOnly/Hooks/Silks/silkImage'

export function getSingleSilkImageUri({
  racecourseSeq,
  meetingDate,
  raceNumber,
  isTrots,
  isDogs,
  starterNumber,
  isFieldSummaryPopulated,
  meetingCode,
}: {
  racecourseSeq?: number | null
  meetingDate: string
  raceNumber: number | null
  isTrots: boolean
  isDogs: boolean
  starterNumber: number
  isFieldSummaryPopulated: boolean
  meetingCode?: string
}) {
  if (!isFieldSummaryPopulated) {
    return null
  }
  if (!racecourseSeq || !raceNumber) {
    return null
  }
  return getImageUrlWithSizes({
    racecourseSeq,
    meetingDate,
    raceNumber,
    isTrots,
    isDogs,
    starterNumber,
    meetingCode,
  })
}
