import React from 'react'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'

const GoodThingsSliceLazy = React.lazy(() => import('@rwwa/racing-form/WebClient/dist/goodThings'))

const Loading = () => null

export const GoodThingsSlice: React.FC<{}> = () => (
  <MainContainer background='grey'>
    <ContentHeader title='Good Things' />

    <React.Suspense fallback={<Loading />}>
      <GoodThingsSliceLazy />
    </React.Suspense>
  </MainContainer>
)
