import dayjs from 'dayjs'
import React from 'react'
import { NotificationButtonMain } from './Components/NotificationButton'
import type { RaceStatus } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { isReactNativeApp } from '@mobi/web-native-comms/web'

type Props = {
  raceStatus: RaceStatus
  raceKey: string
  raceTime: Date
  meetingId: string
  raceNumber: number
  meetingName: string
  meetingDate: string
  isHeaderPinned?: boolean
}

/** Race alert button - includes RN app check */
export const RaceNotificationButton: React.FC<Props> = ({
  raceStatus,
  raceKey,
  raceTime,
  meetingId,
  raceNumber,
  meetingDate,
  meetingName,
  isHeaderPinned,
}) => {
  if (!isReactNativeApp) return null

  return (
    <NotificationButton
      raceStatus={raceStatus}
      raceKey={raceKey}
      raceTime={raceTime}
      meetingId={meetingId}
      raceNumber={raceNumber}
      meetingDate={meetingDate}
      meetingName={meetingName}
      isHeaderPinned={isHeaderPinned}
    />
  )
}

// ===============
// Local Component
// ===============

const NotificationButton: React.FC<Props> = ({
  raceStatus,
  raceKey,
  raceTime,
  meetingId,
  raceNumber,
  meetingDate,
  meetingName,
  isHeaderPinned,
}) => {
  const expiryTime = React.useMemo(() => dayjs(raceTime).subtract(1, 'm'), [raceTime])
  const checkIfTimerExpired = React.useCallback(() => expiryTime.isBefore(Date.now()), [expiryTime])

  const [hasTimerExpired, setHasTimerExpired] = React.useState(checkIfTimerExpired)

  React.useEffect(() => {
    const hasExpired = checkIfTimerExpired()
    setHasTimerExpired(hasExpired)

    if (hasExpired) return undefined

    const timerId = setInterval(() => {
      if (!checkIfTimerExpired()) return
      setHasTimerExpired(true)
      clearInterval(timerId)
    }, 1000)
    return () => clearInterval(timerId)
  }, [checkIfTimerExpired])

  if (raceStatus === 'Released' || raceStatus === 'Abandoned' || hasTimerExpired) return null

  return (
    <NotificationButtonMain
      raceKey={raceKey}
      ast={raceTime.toISOString()}
      meetingCode={meetingId}
      raceNumber={raceNumber}
      meetingName={meetingName}
      meetingDate={dayjs(meetingDate).format('YYYY-MM-DD')}
      isHeaderPinned={isHeaderPinned}
    />
  )
}
