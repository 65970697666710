import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { MainContainer } from '@core/Components/Containers'
import { deregisterBodyScrollLock, registerBodyScrollLock } from '@mobi/utils/functions'
import {
  OverlayCloseButtonStyled,
  OverlayHeaderStyled,
  OverlayHeadingStyled,
} from '@core/Components/Overlay/Overlay.styles'
import { OverlayClose } from '@core/Components/Overlay'
import { Loading } from './Components/Common/Loading'

const DepositLegacy = React.lazy(() => import('@core/Areas/DepositLegacy/DepositLegacy'))

export const DepositLegacyLazy: React.FC<{}> = () => {
  const containerElementRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!containerElementRef.current) return

    const containerElement = containerElementRef.current
    registerBodyScrollLock(containerElement)

    return () => deregisterBodyScrollLock(containerElement)
  }, [])

  return (
    <div ref={containerElementRef} style={{ overflowY: 'auto' }}>
      <OverlayHeaderStyled>
        <OverlayHeadingStyled>Deposit</OverlayHeadingStyled>
        <OverlayCloseButtonStyled data-testid='close-button' onClick={OverlayClose}>
          <Icon type='cross' title='Close' />
        </OverlayCloseButtonStyled>
      </OverlayHeaderStyled>

      <MainContainer background='grey' padding='0.5rem'>
        <React.Suspense fallback={<Loading />}>
          <DepositLegacy />
        </React.Suspense>
      </MainContainer>
    </div>
  )
}
