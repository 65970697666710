import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export interface ToggleTypes {
  default: ToggleType
  grey: ToggleType
  inverted: ToggleType
  allgreen: ToggleType
}

const toggleColors: ToggleTypes = {
  default: {
    trackFill: hexColors.gainsboro,
    trackFillActive: hexColors.gainsboro,
    leftCircle: hexColors.darkGrey,
    rightCircle: hexColors.sushi,
    text: hexColors.dimGrey,
    selectedText: hexColors.white,
    border: hexColors.darkGrey,
  },
  grey: {
    trackFill: hexColors.gainsboro,
    trackFillActive: hexColors.gainsboro,
    leftCircle: hexColors.darkGrey,
    rightCircle: hexColors.darkGrey,
    text: hexColors.dimGrey,
    selectedText: hexColors.white,
    border: hexColors.darkGrey,
  },
  inverted: {
    trackFill: hexColors.veryLightGrey,
    trackFillActive: hexColors.charCoal,
    leftCircle: hexColors.white,
    rightCircle: hexColors.white,
    text: hexColors.charCoal,
    selectedText: hexColors.white,
    border: 'transparent',
  },
  allgreen: {
    trackFill: hexColors.gainsboro,
    trackFillActive: hexColors.gainsboro,
    leftCircle: hexColors.sushi,
    rightCircle: hexColors.sushi,
    text: hexColors.dimGrey,
    selectedText: hexColors.white,
    border: hexColors.darkGrey,
  },
}

interface ToggleStyledProps {
  isDisabled?: boolean
  toggledOn?: boolean
  type?: keyof ToggleTypes
}

export const ToggleStyled = styled('svg')<ToggleStyledProps>(
  {
    WebkitTapHighlightColor: 'transparent',
    padding: '0.3rem',
    verticalAlign: 'middle',
    display: 'inline-block',
    width: '5rem',
    height: '3rem',
    fontSize: '0.9rem',
    '& text': {
      fontWeight: 'bold',
      '@media screen and (max-width: 767px)': {
        fontSize: '1.2rem',
      },
    },
    '& circle': {
      transitionProperty: 'cx',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-in-out',
    },
  },
  props => {
    return {
      opacity: props.isDisabled ? 0.4 : 1,
    }
  }
)

export const ThemedToggleStyled = styled(ToggleStyled)<ToggleStyledProps>(
  ({ toggledOn, type = 'default' }) => ({
    '& rect.track': {
      stroke: toggleColors[type].border,
      fill: toggledOn ? toggleColors[type].trackFillActive : toggleColors[type].trackFill,
    },
    '& rect.disc': {
      textTransform: 'uppercase',
      fill: toggledOn ? toggleColors[type].rightCircle : toggleColors[type].leftCircle,
    },
    '& text': {
      fill: toggleColors[type].text,
    },
    '& text.selected': {
      fill: toggleColors[type].selectedText,
    },
  })
)

interface ToggleType {
  trackFill: string
  trackFillActive: string
  leftCircle: string
  rightCircle: string
  text: string
  selectedText: string
  border: string
}
