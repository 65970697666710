export function intersection<T extends Number | String>(a: Set<T>, b: Set<T>): Set<T> {
  return new Set<T>([...a.values()].filter(x => b.has(x)))
}

export function difference<T extends Number | String>(a: Set<T>, b: Set<T>): Set<T> {
  return new Set<T>([...a.values()].filter(x => !b.has(x)))
}

export function equal<T extends Number | String>(a: Set<T>, b: Set<T>): boolean {
  return a.size === b.size && intersection(a, b).size === a.size
}
