import React from 'react'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { MysteryQuickPicksRaceBettingPageWrapper } from '@core/Areas/Racing/Components/Mystery/MysteryRaceBettingPageWrapper'
import { useAppSelector } from '@core/Store/hooks'
import { SecondaryHeader } from '../Common/SecondaryHeader'
import { getCurrentBetType } from '../../Store/selectors'
import { MORE_BETTING_OPTIONS_ID } from '../../constants'
import { FixedOddsMarkets, useResetActiveToteSwap } from './FixedOddsMarkets'

export const MoreBettingOptions: React.FC<{
  raceData?: RacePageDTO
}> = ({ raceData }) => {
  const [isExpanded, setIsExpanded] = React.useState(true)
  const currentBetType = useAppSelector(getCurrentBetType)

  useResetActiveToteSwap()

  if (
    !raceData ||
    !isRacePageDto(raceData) ||
    currentBetType !== BetType.WinPlace ||
    raceData.MeetingInformation.SelectedRace.RaceStatus === 'Closed'
  ) {
    return null
  }

  return (
    <div id={MORE_BETTING_OPTIONS_ID} data-testid='more-betting-options'>
      <ExpandablePanel
        title=''
        headerComponent={<SecondaryHeader title='More Betting Options' isExpanded={isExpanded} />}
        expanded={isExpanded}
        headerClicked={() => setIsExpanded(val => !val)}
      >
        <MysteryQuickPicksRaceBettingPageWrapper meetingInformation={raceData.MeetingInformation} />

        <FixedOddsMarkets raceData={raceData} />
      </ExpandablePanel>
    </div>
  )
}
