import jQuery from 'jquery'
import * as nav from './Navigation/Nav'
import * as dateTimeProvider from './DateTimeProvider'
import * as nativeLocationService from '@core/NativeServices/Location/LocationService'
import { isReactNativeIos, isReactNativeApp, MajorVersion } from '@mobi/web-native-comms/web'
import { getServerVersion } from './Services/ServerConfig'

export function initCommon(url: string) {
  nav.setBackUrl(url)
}

export function init() {
  //set up common ajax options
  jQuery.ajaxSetup({
    cache: false,
    timeout: 30000,
    beforeSend: function (xhr, req) {
      if (
        isReactNativeIos &&
        MajorVersion &&
        MajorVersion <= 12 &&
        // @ts-expect-error Type issue
        req.type.toUpperCase() === 'POST' &&
        req.data
      ) {
        // Required to support the react native iOS 11 and 12 app.
        // A bug in the iOS scheme handler means the body of a http post via XHR is not passed through.
        // We put the body into a header ('X-Post-Data') and copy it back into the body within the scheme handler.
        // See: MOBILE-4028
        // See: https://bugs.webkit.org/show_bug.cgi?id=191362
        xhr.setRequestHeader('X-Post-Data', req.data)
      }

      if (req.crossDomain) return
      const xClientTimezone = dateTimeProvider.getTimezoneOffset() / 60
      const serverVersion = getServerVersion()
      if (serverVersion && !isReactNativeApp) {
        xhr.setRequestHeader('X-TabTouch-Version', serverVersion)
      }
      xhr.setRequestHeader('ClientTimeZone', '' + xClientTimezone)
      xhr.setRequestHeader('ClientScreenWidth', '' + window.innerWidth)
      xhr.setRequestHeader('X-Location', nativeLocationService.getLastKnownLocationAsString())
      xhr.setRequestHeader('X-Venue', nativeLocationService.getLastKnownVenueAsString())
    },
  })

  window.scrollTo(0, 1)
}
