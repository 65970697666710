import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { RaceStatus } from '@core/Areas/Racing/Types'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'

const mappedSortTypes: Record<SortOption['property'], string> = {
  Name: 'runner',
  Number: 'runner',
  Price: 'tote',
  FixedPrice: 'fixed',
  SameRaceMultiWinPrice: 'srm',
}

export const trackRaceCardSorting = (sortOption: SortOption, updatedRaceStatus: RaceStatus) => {
  const sortItem = mappedSortTypes[sortOption.property]
  const sortOrder = sortOption.direction === 'Ascending' ? 'ascending' : 'descending'
  const raceStatus = updatedRaceStatus.toLowerCase()
  trackEvent('race_card_sorting', {
    sortItem,
    sortOrder,
    raceStatus,
  })
}
