import * as ko from 'knockout'
import { IProgressIndicator } from './IProgressIndicator'
import { injectable } from 'inversify'

@injectable()
export default class ProgressIndicatorBase implements IProgressIndicator {
  constructor() {
    this.serviceCallCompleted = ko.observable(false)
  }

  public beginning() {
    this.setServiceCallCompleted(false)
  }

  public finished() {
    this.setServiceCallCompleted(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  displayFor<T>(_: Promise<T>): Promise<T> {
    throw new Error('must override this operation')
  }

  protected setServiceCallCompleted(isRunning: boolean) {
    this.serviceCallCompleted(isRunning)
    this.serviceCallCompleted.valueHasMutated()
  }

  public serviceCallCompleted: ko.Observable<boolean>
}
