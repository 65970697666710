import type { BraintreeError } from 'braintree-web'

type ErrorCode = 'failed_to_initialize' | 'user_canceled' | 'card_verification_failed' | 'no_data'

export class ThreeDSecureError extends Error {
  public constructor(
    message: string,
    public readonly code: ErrorCode,
    public readonly previousError?: Error | BraintreeError
  ) {
    super(message)
  }

  public static failedToInitialize(previousError: Error) {
    return new ThreeDSecureError(
      'Failed to initialize 3DS client',
      'failed_to_initialize',
      previousError
    )
  }

  public static canceled() {
    return new ThreeDSecureError('User canceled the 3DS flow', 'user_canceled')
  }

  public static verificationFailed(previousError: BraintreeError) {
    return new ThreeDSecureError(
      'Card verification failed',
      'card_verification_failed',
      previousError
    )
  }

  public static noData() {
    return new ThreeDSecureError('3DS verification returned no data', 'no_data')
  }
}
