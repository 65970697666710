import React from 'react'
import {
  StructuredSearchTextLabelStyled,
  StructuredSearchTextLabelFontSize,
} from './StructuredSearchTextLabelStyled.styles'

interface StructuredSearchTextLabelProps {
  children: React.ReactNode
  size?: StructuredSearchTextLabelFontSize
}

export function StructuredSearchTextLabel({ children, size }: StructuredSearchTextLabelProps) {
  return <StructuredSearchTextLabelStyled size={size}>{children}</StructuredSearchTextLabelStyled>
}
