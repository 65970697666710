import React from 'react'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { useRacePageData } from '@core/Areas/Racing/Hooks/useRacePageData'
import { BlackbookMenuButton } from './BlackbookMenuButton'

export const BlackbookJockey: React.FC<BlackbookJockeyProps> = ({
  fixtureId,
  fixtureDate,
  raceNumber,
  starterName: runnerName,
  source,
}) => {
  const { isLoading, race, isError } = useRacePageData({
    meetingId: fixtureId,
    meetingDate: fixtureDate,
    raceNumber,
  })

  const jockeyName = getJockeyNameFromRace({ runnerName, race })

  if (isError || (!isLoading && !jockeyName)) return null

  return (
    <BlackbookMenuButton
      starterName={jockeyName}
      starterType='Jockeys'
      source={source}
      entryType='Jockey'
      isReady={!isLoading}
    />
  )
}

// =============
// Local Helpers
// =============

function getJockeyNameFromRace({
  runnerName,
  race,
}: {
  runnerName: string
} & Pick<ReturnType<typeof useRacePageData>, 'race'>): string {
  if (!race) return ''

  if (race.Tag === 'Starters') {
    const selectedRace = race.RaceStarters.find(
      ({ RaceKey }) => RaceKey.Key === race.MeetingInformation.SelectedRace.Key
    )
    if (!selectedRace) return ''

    const runner = selectedRace.Starters.find(
      starter => starter.Name.toLowerCase() === runnerName.toLowerCase()
    )
    if (!runner) return ''

    return runner.StarterPersonName || ''
  }

  if (race.Tag === 'Results') {
    const runner = race.Finishers.find(finisher => finisher.Name === runnerName)
    if (!runner) return ''

    return runner.StarterPersonName
  }

  return ''
}

// =====
// Types
// =====

type BlackbookParams = Parameters<typeof useAddToBlackbook>[0] & { isReady: boolean }

type BlackbookJockeyProps = Pick<Ticket['legs'][0], 'fixtureId' | 'fixtureDate' | 'raceNumber'> &
  Pick<BlackbookParams, 'starterName' | 'source'>
