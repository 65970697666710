import React from 'react'
import { matchPath, RouteProps, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { useRadio } from '@core/Areas/Navigation/Components/TabNavigation/Components/WebOnlyNav/hooks/useRadio'
import { selectIsSkyVideoPlayerOpen } from '@core/Areas/SkyVideoPlayer/Store/selectors'
import { PlayIcon } from '@core/Components/Icons/PlayIcon'
import { TabRadioIcon } from '@core/Components/Icons/TabRadioIcon'
import { useAppSelector } from '@core/Store/hooks'
import { trackOnRadioToggle } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { selectIsRadioPlaying } from './Store/selectors'
import { isReactNativeApp } from '@mobi/web-native-comms/web'

export const FloatingTabRadioButton: React.FC<{ visibleRoutes: RouteProps[] }> = ({
  visibleRoutes,
}) => {
  const isRadioPlayingSelector = useAppSelector(selectIsRadioPlaying)
  const isSkyVideoPlayerOpen = useAppSelector(selectIsSkyVideoPlayerOpen)

  const { isRadioPlaying, handleRadioToggle } = useRadio(isSkyVideoPlayerOpen)

  const toggleRadio = () => {
    handleRadioToggle()
    trackOnRadioToggle(
      isRadioPlayingSelector,
      'floating-nav',
      `${location.pathname}${location.search}`
    )
  }

  const location = useLocation()
  const isVisible = visibleRoutes.some(route => matchPath(location.pathname, route))

  return (
    <FloatingTabRadioButtonContainerStyled
      id='floatingRadioPlayer'
      style={{ display: isVisible ? undefined : 'none' }}
    >
      <button
        role='button'
        name='tab-radio-button'
        aria-label='TAB Radio Button'
        onClick={toggleRadio}
      >
        <TabRadioIcon />
      </button>

      {isRadioPlaying && (
        <div data-testid='tab-radio-playing'>
          <PlayIcon />
        </div>
      )}
    </FloatingTabRadioButtonContainerStyled>
  )
}

// ======
// Styles
// ======

const FloatingTabRadioButtonContainerStyled = styled.div({
  width: '5rem',
  height: '5rem',
  background: 'linear-gradient(180deg, #8246AF 24.48%, #6A3A8F 48.96%, #512D6D 74.48%);',
  borderRadius: '1rem',
  zIndex: '3',
  position: 'fixed',
  display: 'flex',
  right: 'calc(max(1rem, 50vw - 40rem + 1rem))',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  alignItems: 'center',
  bottom: isReactNativeApp ? '2rem' : 'calc(7rem + env(safe-area-inset-bottom, 0px))',

  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    color: hexColors.white,
    width: '100%',
    fontSize: '3em',
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '0.2rem',
    top: '0.2rem',
    color: hexColors.white,
    pointerEvents: 'none',
  },
})
