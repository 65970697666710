import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IRaceFinisherViewModel } from './IRaceFinisherViewModel'
import template from './race.finisher.tpl.html'
import ObservableRaceFinisher from '@classic/Betting-v2/Model/Observables/ObservableRaceFinisher'

export function register(): void {
  if (!ko.components.isRegistered('race-finisher')) {
    ko.components.register('race-finisher', {
      viewModel: {
        createViewModel: (params: {
          finisher: ObservableRaceFinisher
          showField: boolean
        }): IRaceFinisherViewModel => {
          const viewModel = container.get<IRaceFinisherViewModel>('IRaceFinisherViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
