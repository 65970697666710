import { ISelectionResultProcessor } from '../../../../../classic/Betting-v2/Components/Core/SelectionResults/ISelectionResultProcessor'
import { ISelectionResult } from '../../../../../classic/Betting-v2/Components/Core/SelectionResults/ISelectionResult'
import ObservableSelectedRaceStarters from '../../../../../classic/Betting-v2/Model/Observables/ObservableSelectedRaceStarters'
import { BettingInformation } from '../../../../../classic/Betting-v2/Model/BettingInformation'
import { IObservableStarter } from '../../../../../classic/Betting-v2/Model/Observables/IObservableStarter'
import * as buttons from '../../../../../classic/Betting-v2/Components/Core/UIElements/ButtonsSelection'
import { ButtonSelectionType } from '../../../../../classic/Betting-v2/Components/Core/UIElements/ButtonSelectionType'

export default class SameRaceMultiSelectionResultProcessor implements ISelectionResultProcessor {
  selections(
    _bettingContext: BettingInformation,
    raceNumber: number,
    selectedRaceStarters: ObservableSelectedRaceStarters
  ): ISelectionResult {
    // return an object that implements ISelectionResult for compatibility with the contract (specifically Tote) for consumption by BetSelectionBuilder
    const selectedStarters = selectedRaceStarters.getStartersForRace(raceNumber)()
    const fobSelectionResults = selectedStarters.map(this.toSameRaceMultiSelectionResultLeg)

    return {
      fobSelectionResults,
      selectionStrings: [], // n/a for SRM
    } as SameRaceMultiSelectionResult
  }

  getSelectionsResult(bettingContext: BettingInformation, raceNumber: number): ISelectionResult {
    return this.selections(bettingContext, raceNumber, bettingContext.selections)
  }

  private toSameRaceMultiSelectionResultLeg(starter: IObservableStarter) {
    return {
      starterNumber: starter.number(),
      legNumber: (starter.selection() as buttons.ButtonsSelection)
        .values()
        .map(e => e())
        .indexOf(ButtonSelectionType.Fob),
    } as SameRaceMultiSelectionResultLeg
  }
}

export interface SameRaceMultiSelectionResult extends ISelectionResult {
  fobSelectionResults: SameRaceMultiSelectionResultLeg[]
  selectionStrings: string[]
}

interface SameRaceMultiSelectionResultLeg {
  starterNumber: number
  legNumber: number // 0 - 3
}
