import React from 'react'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { useAppDispatch } from '@core/Store/hooks'
import { setSkyVideoPlayerStatus } from '@core/Areas/SkyVideoPlayer/Store'

/** Native App Only - Force landscape orientation, exit fullscreen on portrait orientation */
export const useFullscreenNativeInterop = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('LOCK_LANDSCAPE_ORIENTATION')
    return () => sendToNative('UNLOCK_ORIENTATION_LOCKS')
  }, [])

  // Exit fullscreen on orientation change back to portrait
  React.useEffect(() => {
    if (!isReactNativeApp) return
    const portrait = window.matchMedia('(orientation: portrait)')
    const handleOrientationChange = (e: MediaQueryListEvent) => {
      if (e.matches) dispatch(setSkyVideoPlayerStatus({}))
    }
    portrait.addEventListener('change', handleOrientationChange)
    return () => portrait.removeEventListener('change', handleOrientationChange)
  }, [dispatch])
}
