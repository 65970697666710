import Rx from 'rx'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { declareResourceType } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'
import {
  BetAccountHolder,
  FullSingleBetAccountHolder,
  DepositLimitFrequency,
} from '@mobi/api-types'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'

// store the account's contact details under this key
export const ContactDetailsKey: string = 'me'
const ContactDetailsTypeKey = 'contactDetails'

interface ContactDetailsRecord extends TypedRecord<ContactDetailsRecord>, BetAccountHolder {}

/** @deprecated Use react query -> useContactDetails */
export const ContactDetails = declareResourceType<BetAccountHolder, unknown>({
  typeKey: 'contactDetails',
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 200,
  map: x => recordify<BetAccountHolder, ContactDetailsRecord>(x as BetAccountHolder),
})

function fetchMultipleNoPromise(keys: string[]) {
  const betAccountHolderNumber = keys[0]
  return Rx.Observable.fromPromise(
    fetchContactDetails(betAccountHolderNumber)
      .then(response => ({
        [ContactDetailsTypeKey]: {
          [betAccountHolderNumber]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(error => ({ keys, error }))
  )
}

export function fetchContactDetails(
  betAccountHolderNumber: string | null
): Promise<BetAccountHolder> {
  const isBetAccountHolderNumber = /^\d+$/.test(
    (betAccountHolderNumber && betAccountHolderNumber.trim()) || ''
  )
  const url = isBetAccountHolderNumber
    ? `/$_/api/getContactDetailsForContact/${betAccountHolderNumber}`
    : '/$_/api/contactDetails'

  return apiService.get<BetAccountHolder>({ url })
}

export interface ContactDetailsUpdateRequest {
  VerifyResidentialAddress: boolean
  VerifyPostalAddress: boolean
  ResidentialAddressMoniker: string
  PostalAddressMoniker: string
  BetAccountHolder: FullSingleBetAccountHolder
}

export function saveContactDetails(model: ContactDetailsUpdateRequest) {
  return apiService.post<{
    isReverificationSuccess: boolean
    isContactDetailsUpdateSuccess: string
  }>({
    url: '/$_/api/contactDetails/updatedetails',
    body: model,
  })
}

export async function savePrecommitments(model: {
  BetAccountNumber: number
  OptInIndicator: boolean
  MaxBetAmount: string
  MaxWeeklyDepositLimit: string
  OptInMarketingIndicator: boolean
  UserCreditCardBlockedIndicator: boolean
  BlockPayPalIndicator: boolean
  AccountHolderId: number
  PreCommitmentMaxDepositAmount: number | null
  PreCommitmentMaxDepositFrequency: DepositLimitFrequency
}) {
  const body = { PreCommitmentDetails: model }
  await apiService.post<{}>({
    url: '/$_/api/account/updatePrecommitments',
    body: body,
  })
  queryClient.removeQueries(queryKeys.depositInitialData)
  BetAccountRepo.invalidate(BetAccountKey)
}
