import React, { VoidFunctionComponent } from 'react'
import { useWillUnmount } from 'rooks'
import { useDispatch, useSelector } from 'react-redux'
import { addCrumb } from '@mobi/utils'
import { allowDismissal, preventDismissal } from '@core/Components/Modal/store'
import {
  ApplePayButtonNative,
  ApplePayButtonWeb,
} from '@mobi/account/Areas/Deposit/Components/Payments/ApplePay'
import { ClearInsufficientFundsForBet } from '@core/Areas/Quickbet/signals'
import { CreditCardButton } from '@mobi/account/Areas/Deposit/Components/Payments/CreditCard'
import { fetchAccountBalanceAsync } from '@core/State/UserAccount/async-signals'
import { incrementSuccessCount } from '@mobi/account/Utils/depositCount'
import { isReactNativeApp as isNative } from '@mobi/web-native-comms/web'
import { messages } from '@mobi/account/Areas/Deposit/Components'
import { Money } from '@core/Components/Text/Money'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { paymentMethodTypeNameMap } from '@mobi/account/Utils/schema'
import {
  PayPalButtonNative,
  PayPalButtonWeb,
} from '@mobi/account/Areas/Deposit/Components/Payments/PayPal'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { selectLastDepositError } from '@core/Areas/QuickDeposit/Store/selectors'
import { SpinnerStandalone } from '@mobi/component-library/Common/Spinner'
import { Text } from '@core/Components/Text'
import { toMoney } from '@mobi/utils/money'
import { useBetAccount } from '@mobi/account/Hooks'
import { getFromRepo } from '@core/Utils/repository'
import { BetAccountRecord, BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'
import {
  trackQuickDepositCancelled,
  trackQuickDepositFailed,
  trackQuickDepositStarted,
  trackQuickDepositSucceeded,
} from '@core/Areas/QuickDeposit/analytics'
import {
  LoadingContainerStyled,
  QuickDepositBodyContainerStyled,
  QuickDepositBodySectionStyled,
  QuickDepositLabelStyled,
} from '@core/Areas/QuickDeposit/Components/QuickDeposit.styles'
import {
  clearQuickDepositError,
  onQuickDepositError,
  setQuickDepositStatus,
} from '@core/Areas/QuickDeposit/Store'
import type { InitialData, PaymentMethod } from '@mobi/api-types'
import type { OnFailureParams, PanelProps } from '@mobi/account/Areas/Deposit/Components/types'
import {
  GooglePayButtonWeb,
  GooglePayButtonNative,
} from '@mobi/account/Areas/Deposit/Components/Payments/GooglePay'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { PaymentAvailabilities } from '@core/Areas/Deposit/helper'

type Props = {
  lastUsedPaymentMethod: PaymentMethods
  creditCard?: PaymentMethod
  willDepositMinimumAmount: boolean
  minimumDepositAmount: number
  depositAmount: number
  initialData: InitialData
  isBusy: boolean
  accountNumber: number
}

export const QuickDepositBody = ({
  lastUsedPaymentMethod,
  creditCard,
  willDepositMinimumAmount,
  minimumDepositAmount,
  depositAmount,
  initialData,
  isBusy,
  accountNumber,
}: Props) => {
  const dispatch = useDispatch()
  const lastQuickDepositError = useSelector(selectLastDepositError)

  const {
    isLoading,
    isError,
    data: betAccount,
  } = useBetAccount<BetAccountRecord>({
    enabled: accountNumber !== null,
    queryFn: () => getFromRepo(BetAccountRepo, BetAccountKey),
  })

  const paymentAvailabilities = PaymentAvailabilities(betAccount)

  const onStart = () => {
    trackQuickDepositStarted(lastUsedPaymentMethod)
    addCrumb(
      'deposit',
      `start deposit with ${
        paymentMethodTypeNameMap[lastUsedPaymentMethod]
      } for ${depositAmount.toFixed(2)}`
    )

    dispatch(preventDismissal(QUICKBET_MODAL_ID))
    dispatch(clearQuickDepositError())
    dispatch(
      setQuickDepositStatus({
        depositStatus: 'preparing',
      })
    )
  }

  const onDepositing = () => {
    dispatch(
      setQuickDepositStatus({
        depositStatus: 'depositing',
      })
    )
  }

  const onSuccess = () => {
    const successfulDepositCount = incrementSuccessCount(accountNumber)

    trackQuickDepositSucceeded({
      depositAmount: depositAmount.toFixed(2),
      paymentMethod: lastUsedPaymentMethod,
      accountNumber,
      isFirstDeposit: successfulDepositCount === 1,
      isSecondDeposit: successfulDepositCount === 2,
    })
    addCrumb('deposit', `successful deposit`)

    return fetchAccountBalanceAsync().then(() => {
      ClearInsufficientFundsForBet() // close Quick Deposit
    })
  }

  const onFailure = (args: OnFailureParams) => {
    onSettled()

    trackQuickDepositFailed(lastUsedPaymentMethod, args.reason)

    dispatch(onQuickDepositError(messages[args.reason]))
  }

  const onCancel = () => {
    trackQuickDepositCancelled(lastUsedPaymentMethod)
    addCrumb('deposit', `cancel deposit`)
    onSettled()
  }

  const onSettled = () => {
    dispatch(allowDismissal(QUICKBET_MODAL_ID))
    dispatch(
      setQuickDepositStatus({
        depositStatus: 'idle',
      })
    )
  }

  useWillUnmount(onSettled)

  const renderPaymentMethod = () => {
    const panelProps = {
      initialData,
      depositAmount,
      isDepositAllowed: !isBusy,
      onStart,
      onSuccess,
      onDepositing,
      onFailure,
      onCancel,
      accountNumber,
    }

    if (lastUsedPaymentMethod === 'CreditCard') {
      if (creditCard) {
        return <CreditCardButton {...panelProps} creditCard={creditCard} showIssuer={true} />
      }
      // User attempts to do a credit card deposit without a credit card
      // While this isn't theoretically possible, we do need to handle it
      return <></>
    }

    const Component = determineComponent(lastUsedPaymentMethod, isNative)

    return <Component {...panelProps} />
  }

  return (
    <QuickDepositBodyContainerStyled hidden={isBusy}>
      {lastQuickDepositError && (
        <NoticeBox
          title='Deposit failed'
          noticeBoxType={NoticeBoxTypes.Error}
          hasBorder
          role='alert'
          aria-label='Deposit failed'
        >
          <Text size='lg'>{lastQuickDepositError}</Text>
        </NoticeBox>
      )}

      {(function () {
        if (accountNumber === null || isLoading) {
          return (
            <LoadingContainerStyled>
              <SpinnerStandalone />
            </LoadingContainerStyled>
          )
        }
        if (isError) {
          return (
            <p>Unable to deposit funds. No previous payment method has been used on this device.</p>
          )
        }
        if (paymentAvailabilities[lastUsedPaymentMethod].blocked) {
          return (
            <p>
              Deposits are not enabled for {paymentMethodTypeNameMap[lastUsedPaymentMethod]} on this
              account.
            </p>
          )
        }
        return (
          <QuickDepositBodySectionStyled>
            <header>
              <QuickDepositLabelStyled size='lg'>
                <strong>Would you like to make a quick deposit now?</strong>
              </QuickDepositLabelStyled>
            </header>
            <div>
              <QuickDepositLabelStyled size='lg'>
                <strong>
                  Deposit <Money amount={depositAmount} /> using
                </strong>
              </QuickDepositLabelStyled>

              {isBusy && (
                <LoadingContainerStyled>
                  <SpinnerStandalone />
                </LoadingContainerStyled>
              )}

              {renderPaymentMethod()}

              {willDepositMinimumAmount && (
                <QuickDepositLabelStyled
                  role='status'
                  aria-label={`Minimum deposit is ${toMoney(minimumDepositAmount)}`}
                >
                  Minimum deposit is {toMoney(minimumDepositAmount, { decimalPlaces: 0 })}
                </QuickDepositLabelStyled>
              )}
            </div>
          </QuickDepositBodySectionStyled>
        )
      })()}
    </QuickDepositBodyContainerStyled>
  )
}

function determineComponent(
  paymentMethod: Exclude<PaymentMethods, 'CreditCard'>,
  isNative: boolean
): VoidFunctionComponent<PanelProps> {
  switch (paymentMethod) {
    case 'ApplePay':
      return isNative ? ApplePayButtonNative : ApplePayButtonWeb
    case 'GooglePay':
      return isNative ? GooglePayButtonNative : GooglePayButtonWeb
    case 'PayPal':
      return isNative ? PayPalButtonNative : PayPalButtonWeb
  }
}
