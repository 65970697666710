import * as dtos from '../DataTransferObjects/DataTransferObjects'
import ObservableRacePage from '../Model/Observables/ObservableRacePage'

export default class BetTypesMapper {
  static map(dto: dtos.RacePageDataTransferObject, model: ObservableRacePage) {
    for (let element of dto.AvailableBetTypes) {
      model.addBetType(element)
    }
  }
}
