import styled from '@emotion/styled'
import React from 'react'
import { PaymentMethodButtonStyled } from '../PaymentMethodButton.styled'
import { PaymentMethodButton } from '../../Buttons/types'

/**
 * ApplePayButton component
 * @description Renders a button according to Apple Pay style guidelines. Safari only.
 */
export const ApplePayButton: PaymentMethodButton = ({ disabled, onClick, ...buttonAttributes }) => {
  return (
    <ApplePayButtonStyled
      provider='applepay'
      disabled={disabled}
      onClick={onClick}
      {...buttonAttributes}
    />
  )
}

const ApplePayButtonStyled = styled(PaymentMethodButtonStyled)({
  WebkitAppearance: '-apple-pay-button',
  ApplePayButtonType: 'plain',
  ApplePayButtonStyle: 'black',
})
