import { z } from 'zod'

export const addressSuggestionsSchema = z.array(
  z.object({
    id: z.string(),
    value: z.string(),
  })
)

export type AddressSuggestions = z.infer<typeof addressSuggestionsSchema>

export const streetTypeSuggestionsSchema = z.array(
  z.object({
    value: z.string(),
  })
)

export type StreetTypeSuggestions = z.infer<typeof streetTypeSuggestionsSchema>

export const suburbStateSuggestionsSchema = z.array(
  z.object({
    value: z.string(),
  })
)

export type SuburbStateSuggestions = z.infer<typeof suburbStateSuggestionsSchema>

export const countryListSchema = z.array(
  z.object({
    code: z.string(),
    description: z.string(),
  })
)

export type CountryList = z.infer<typeof countryListSchema>

export type AddressMonikerId = string

export const findByMonikerSchema = z.object({
  UnitNumber: z.string().optional(),
  StreetNumber: z.string(),
  StreetName: z.string(),
  StreetType: z.string(),
  Suburb: z.string(),
  Postcode: z.string(),
  State: z.string(),
  Country: z.string(),
})

export type FindByMoniker = z.infer<typeof findByMonikerSchema>
