import React, { type FC } from 'react'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { ButtonBlockStyled } from './Buttons.styles'
import type { ButtonBlockProps } from './types'

export const ButtonBlock: FC<ButtonBlockProps> = ({
  onClick,
  children,
  color = 'primary',
  selected = false,
  uppercaseText = false,
  disabled = false,
  isDesktop = false,
  isBusy = false,
  busyIndicatorColour,
  ...buttonProps
}) => (
  <ButtonBlockStyled
    {...buttonProps}
    onClick={onClick}
    color={color}
    selected={selected}
    uppercaseText={uppercaseText}
    disabled={disabled}
    {...(buttonProps.testId && {
      [`data-tid-${buttonProps.testId}`]: '',
      'data-testid': buttonProps.testId,
    })}
    isDesktop={isDesktop}
  >
    {isBusy ? <BusyIndicator isBusy={isBusy} color={busyIndicatorColour} /> : children}
  </ButtonBlockStyled>
)
