import React from 'react'
import { openDeposit } from '@core/Areas/Deposit'
import { GridButtonStyled } from '../Buttons/Buttons.styles'
import { TotalStake } from '../TotalStake/TotalStake'
import {
  ProposeAllBets,
  ConfirmAllBets,
  EditBets,
  KeepBets,
  RefreshBetslip,
  OnClose,
} from '../../signals'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { OverlayClose } from '@core/Components/Overlay'
import { navigateToPendingBets } from '@classic/AppUtils/Framework/Intent/navigation'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'

// Place Bets
export const PlaceBetsButton = ({ disabled, isBusy }: { disabled: boolean; isBusy: boolean }) => (
  <GridButtonStyled
    data-tid-betslip-placebets=''
    disabled={disabled}
    onClick={ProposeAllBets}
    color='primary'
    type='button'
  >
    {isBusy ? (
      <BusyIndicator isBusy={isBusy} />
    ) : (
      <>
        Place Bets <TotalStake />
      </>
    )}
  </GridButtonStyled>
)

// Confirm
export const ConfirmBetsButton = ({
  disabled = false,
  isBusy,
}: {
  disabled?: boolean
  isBusy: boolean
}) => (
  <GridButtonStyled
    data-tid-betslip-confirm=''
    disabled={disabled}
    onClick={ConfirmAllBets}
    color='primary'
    type='button'
  >
    {isBusy ? (
      <BusyIndicator isBusy={isBusy} />
    ) : (
      <>
        Confirm <TotalStake />
      </>
    )}
  </GridButtonStyled>
)

// Pending Bets
const handlePendingBetsClick = () => {
  OverlayClose()
  OnClose()
  navigateToPendingBets()
}
export const PendingBetsButton = () => (
  <GridButtonStyled
    data-tid-pending-bets=''
    onClick={handlePendingBetsClick}
    color='secondary'
    type='button'
  >
    Pending Bets
  </GridButtonStyled>
)

// Close Betslip
const handleCloseBetslip = () => {
  OverlayClose()
  OnClose()
}
export const CloseButton = () => (
  <GridButtonStyled
    data-tid-close-summary=''
    onClick={handleCloseBetslip}
    color='primary'
    type='button'
  >
    Close
  </GridButtonStyled>
)

// Login
export const LoginButton = ({ isBusy }: { isBusy: boolean }) => (
  <GridButtonStyled type='button' onClick={() => showLogin()}>
    {isBusy ? <BusyIndicator isBusy={isBusy} /> : 'Log in to place bet'}
  </GridButtonStyled>
)

// Edit Bets
const handleEditBetsClick = () => {
  EditBets()
  RefreshBetslip()
}
export const EditBetsButton = ({ disabled }: { disabled: boolean }) => (
  <GridButtonStyled
    disabled={disabled}
    onClick={handleEditBetsClick}
    color='secondary'
    type='button'
  >
    Edit
  </GridButtonStyled>
)

// Deposit
const handleDepositClick = () => {
  OverlayClose()
  OnClose()
  openDeposit()
}
export const DepositButton = () => (
  <GridButtonStyled data-tid-footer-deposit='' type='button' onClick={handleDepositClick}>
    Deposit
  </GridButtonStyled>
)

// Keep Bets
const handleKeepBetsClick = () => {
  KeepBets()
  RefreshBetslip()
}
export const KeepBetsButton = () => (
  <GridButtonStyled color='secondary' type='button' onClick={handleKeepBetsClick}>
    Keep Bets
  </GridButtonStyled>
)
