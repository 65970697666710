import React from 'react'

import { Money } from '@core/Components/Text/Money'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { store } from '@core/Store'
import { toggleIsBalanceVisible } from '@core/Areas/AppHeader/Store'
import { useAppSelector } from '@core/Store/hooks'
import { Icon } from '@mobi/component-library/Common/Icon'

import {
  BalancesTableStyled,
  VisibilityButtonStyled,
  BonusBetHeaderStyled,
  BonusCashHeaderStyled,
  CashDataStyled,
  CashHeaderStyled,
  BonusDataStyled,
} from './BalanceSection.styles'
import { hexColors } from '@mobi/settings'

import { trackHamburgerMenuEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

type BalanceSectionProps = {
  balance: number
  bonusBetBalance: number
  bonusCashBalance: number
  isBalanceVisible: boolean
}

export function BalanceSectionComponent({
  balance,
  bonusBetBalance,
  bonusCashBalance,
  isBalanceVisible,
}: BalanceSectionProps): JSX.Element {
  return (
    <>
      <BalancesTableStyled>
        <thead>
          <tr>
            <th>
              <VisibilityButtonStyled
                id='hamburger-balance-visibility-toggle'
                onClick={handleToggleBalanceVisibleClick}
                className='text-right'
              >
                <Icon
                  type={isBalanceVisible ? 'visibilityoff' : 'visibilityon'}
                  color={hexColors.grey}
                  size='2rem'
                ></Icon>
              </VisibilityButtonStyled>
            </th>
            <CashHeaderStyled>BALANCE</CashHeaderStyled>
            <BonusBetHeaderStyled>
              {!!bonusBetBalance && 'BONUS BETS'}
              {!bonusBetBalance && !!bonusCashBalance && 'BONUS CASH'}
            </BonusBetHeaderStyled>
            <BonusCashHeaderStyled>
              {!!bonusBetBalance && !!bonusCashBalance && 'BONUS CASH'}
            </BonusCashHeaderStyled>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <CashDataStyled>
              <Money amount={balance} decimalPlaces={!balance ? 0 : 2} />
            </CashDataStyled>
            <BonusDataStyled>
              {!!bonusBetBalance && (
                <Money amount={bonusBetBalance} decimalPlaces={!bonusBetBalance ? 0 : 2} />
              )}
              {!bonusBetBalance && !!bonusCashBalance && (
                <Money amount={bonusCashBalance} decimalPlaces={!bonusCashBalance ? 0 : 2} />
              )}
            </BonusDataStyled>
            <BonusDataStyled>
              {!!bonusBetBalance && !!bonusCashBalance && (
                <Money amount={bonusCashBalance} decimalPlaces={!bonusCashBalance ? 0 : 2} />
              )}
            </BonusDataStyled>
          </tr>
        </tbody>
      </BalancesTableStyled>
    </>
  )
}

export const BalanceSection = () => {
  const isBalanceVisible = useAppSelector(state => state.appHeader.isBalanceVisible)
  const { accountBalance, bonusBetBalance, bonusCashBalance } = useObservableImmutable(
    userAccountState$,
    ['accountBalance', 'accountBalanceUpdatedTime', 'bonusBetBalance', 'bonusCashBalance']
  )
  return (
    <BalanceSectionComponent
      balance={accountBalance ?? 0}
      bonusBetBalance={bonusBetBalance ?? 0}
      bonusCashBalance={bonusCashBalance ?? 0}
      isBalanceVisible={isBalanceVisible}
    />
  )
}

function handleToggleBalanceVisibleClick() {
  store.dispatch(toggleIsBalanceVisible())
  trackHamburgerMenuEvent(keys.hamburgerMenuClicked, {
    item: 'hamburger-balance-visibility-toggle',
  })
}
