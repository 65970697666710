import * as ko from 'knockout'
import container from '@classic/Betting-v2/IOC/inversify.config'
import { ITrotStarterViewModel } from './ITrotStarterViewModel'
import template from './trot.starter.tpl.html'

if (!ko.components.isRegistered('trot-starter')) {
  ko.components.register('trot-starter', {
    viewModel: {
      createViewModel: (
        params: Parameters<ITrotStarterViewModel['init']>[0]
      ): ITrotStarterViewModel => {
        const viewModel = container.get<ITrotStarterViewModel>('ITrotStarterViewModel')
        viewModel.init(params)
        return viewModel
      },
    },
    template,
  })
}
