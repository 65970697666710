import Rx from 'rx'
import { setInLocalStorage, getFromLocalStorage } from '@mobi/utils/storage'

export function setStateToLocalStorage<T>(key: string, state$: Rx.Observable<T>): void {
  state$.map(state => JSON.stringify(state)).subscribe(json => setInLocalStorage(key, json))
}

export function getStateFromLocalStorage<T>(key: string, defaultState?: T): T | undefined {
  try {
    const fromLocal = getFromLocalStorage(key)
    if (!fromLocal) {
      return defaultState
    }

    const obj = JSON.parse(fromLocal)

    // potential TODO: check that obj _is_ T
    return obj as T
  } catch {
    return defaultState
  }
}
