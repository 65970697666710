import { z } from 'zod'

/**
 * Schema for endpoint:
 * GET /api/bet-account/primary-account-holder-details/:betAccountNumber
 */

export const addressSchema = z.object({
  unitNumber: z.string().optional(),
  streetNumber: z.string(),
  streetName: z.string(),
  streetType: z.string(), // Road, Avenue, etc
  suburb: z.string(),
  postcode: z.string(),
  state: z.string(),
  country: z.union([z.literal('Australia'), z.string()]),
})

export type AccountHolderAddress = z.infer<typeof addressSchema>

const phoneNumberSchema = z.object({
  countryCode: z.string(),
  areaCode: z.string(),
  phoneNumber: z.string(),
})

const accountHolderSchema = z.object({
  betAccountHolderNumber: z.number(),
  nameDateOfBirthChanged: z.boolean(),
  nameDetails: z.object({
    title: z.string(),
    firstName: z.string(),
    middleName: z.string(),
    surname: z.string(),
    preferredName: z.string(),
  }),
  dateOfBirth: z.string(),
  contact: z.object({
    homePhone: phoneNumberSchema,
    workPhone: phoneNumberSchema,
    mobile: z.object({
      phoneNumber: z.string().optional(),
    }),
    email: z.string().email().or(z.literal('')),
  }),
  residentialAddress: addressSchema,
  postalAddress: addressSchema,
  postalAddressSameAsResidential: z.boolean(),
  primaryContact: z.boolean(),
  initialIDVerificationDetail: z.object({
    isIDVerified: z.boolean(),
    isNameVerified: z.boolean(),
    isDOBVerified: z.boolean(),
    isAddressVerified: z.boolean(),
    remainingAttempts: z.number(),
  }),
  canNameOrDOBChange: z.boolean(),
  canNameOrDOBChangeSpecified: z.boolean(),
  numberOfAddressChanges: z.number().gte(0),
  numberOfAddressChangesSpecified: z.boolean(),
})

export const primaryAccountHolderDetailsSchema = z.object({
  primaryAccountHolderDetails: accountHolderSchema,
})

export type AccountHolderDetails = z.infer<typeof accountHolderSchema>

export type PrimaryAccountHolderDetails = z.infer<typeof primaryAccountHolderDetailsSchema>
