import InvariantFailureException from './Exceptions/InvariantFailureException'

export default class Guard {
  static stringNotNullOrEmpty(value: string) {
    if (value == null || value.length === 0)
      throw new InvariantFailureException('Invariant: String not empty not satisfied.')
  }

  static notNull<T>(value: T) {
    if (value == null)
      throw new InvariantFailureException('Invariant: Value not null not satisfied.')
  }

  static arrayNotNullOrEmpty<T>(value: Array<T>) {
    if (value == null || value.length == 0)
      throw new InvariantFailureException('Invariant: Array not null or empty not satisfied.')
  }

  static greaterThanZero(value: number) {
    if (value <= 0) throw new InvariantFailureException('Invariant: Value > 0 not satisfied.')
  }

  static greaterThanOrEqualZero(value: number) {
    if (value < 0) throw new InvariantFailureException('Invariant: Value >= 0 not satisfied.')
  }

  static greaterOrEqual(min: number, value: number): void {
    if (value < min) throw new InvariantFailureException('Invariant: Value < Min not satisfied.')
  }
}
