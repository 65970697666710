import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  BetSlipItem,
  BetErrorType,
  MultiInvestment,
  InvestmentType,
  FobBetReceiptResponse,
} from '@mobi/betslip/types'
import {
  confirmAllBets,
  proposeAllBets,
  refreshAllBets,
} from '@mobi/betslip/Store/Workflow/asyncActions'
import { isFobSelection } from '@mobi/betslip/helpers/typeGuards'
import { mapResponse } from './helpers/apiResponse/mapItemsWithResponse'
import { updateItemInvestment } from './helpers/investment/updateItem'
import { getMultiBetResponse } from './helpers/apiResponse/multi'
import { getMultibetErrorDescription } from './helpers/apiResponse/errors'
import { clearBonusBetFromItemInvestment } from './helpers/investment/clearBonusBet'
import {
  hasNoFatalErrors,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
} from '@mobi/betslip/helpers/state'

export const defaultBetSlipBetsState: BetSlipBetsState = {
  items: [],
  isSingleExpanded: true,
  isMultiExpanded: true,
  multiBetError: null,
  multiInvestment: {
    value: 0,
    f1: 0,
    f2: 0,
    f3: 0,
    f4: 0,
    f5: 0,
    bonusBetId: null,
    isBonusBet: false,
  },
  multiReceipt: undefined,
}

const betsSlice = createSlice({
  name: 'betslip/bets',
  initialState: defaultBetSlipBetsState,
  reducers: {
    addItemsToBetSlip(state, { payload: items }: PayloadAction<BetSlipItem[]>) {
      state.items.push(...items)
    },

    clearAllBetSlipItems(state) {
      state.items = []
      state.multiBetError = null
      state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
    },

    removeItemFromBetSlipById(state, { payload: id }: PayloadAction<BetSlipItem['id']>) {
      state.items = state.items.filter(item => item.id !== id)
      if (hasTooFewMultiLegs(state.items) || hasTooManyMultiLegs(state.items)) {
        state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
      }
    },

    setInvestmentAmount(state, { payload }: SetInvestmentAmountArgs) {
      const { investmentType, itemId, value } = payload
      updateItemInvestment({ investmentType, value, state, betId: itemId })
    },

    clearAllReceiptsFromItems(state) {
      state.items.forEach(item => {
        item.receipt = undefined
        item.investment = clearBonusBetFromItemInvestment(item.investment)
      })
      state.multiBetError = null
      state.multiReceipt = undefined
      state.multiInvestment.value = state.multiInvestment.isBonusBet
        ? 0
        : state.multiInvestment.value
      state.multiInvestment.bonusBetId = null
      state.multiInvestment.isBonusBet = false
    },

    removeItemsWithReceipt(state) {
      const hasMultiBeenPlaced = !!state.multiReceipt
      state.items = state.items.filter(
        item => !item.receipt || (hasMultiBeenPlaced && !item.isInMulti)
      )
      state.multiReceipt = undefined
      state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
    },

    removeItemsWithFatalError(state) {
      state.items = state.items.filter(hasNoFatalErrors)
    },

    updateBetSlipFobItemPrice(
      state,
      action: PayloadAction<{ propositionId: string; winPrice: number; placePrice: number | null }>
    ) {
      const { propositionId, winPrice: newWinPrice, placePrice: newPlacePrice } = action.payload
      state.items.map(item => {
        if (isFobSelection(item.selection) && item.selection.propositionSeq === propositionId) {
          item.selection.winPrice = newWinPrice
          item.selection.placePrice = newPlacePrice
        }
      })
    },

    toggleIsItemInMulti(state, { payload }: PayloadAction<Pick<BetSlipItem, 'id'>>) {
      const item = state.items.find(item => item.id === payload.id)
      if (!item) return
      item.isInMulti = !item.isInMulti
      if (hasTooFewMultiLegs(state.items) || hasTooManyMultiLegs(state.items)) {
        state.multiInvestment = defaultBetSlipBetsState.multiInvestment
      }
    },
  },
  extraReducers: builder => {
    // =======
    // Refresh
    // =======
    builder.addCase(refreshAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => {
        return mapResponse(item, data, multiBetResponse, true, true)
      })

      const multiBetError = getMultibetErrorDescription(multiBetResponse)
      state.items = newItems
      state.multiBetError = multiBetError
    })

    // =======
    // Propose
    // =======
    builder.addCase(proposeAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => mapResponse(item, data, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)

      state.items = newItems
      state.multiBetError = multiBetError
      state.multiInvestment =
        multiBetError && multiBetError.betErrorType === 'Unspecified'
          ? defaultBetSlipBetsState.multiInvestment
          : state.multiInvestment
    })

    // =======
    // Confirm
    // =======
    builder.addCase(confirmAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => mapResponse(item, data, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)

      state.items = newItems
      state.multiBetError = multiBetError
      state.multiInvestment =
        multiBetError && multiBetError.betErrorType === 'Unspecified'
          ? defaultBetSlipBetsState.multiInvestment
          : state.multiInvestment

      let newMultiReceipt: FobBetReceiptResponse | undefined = undefined
      if (multiBetResponse && multiBetResponse.success) {
        newMultiReceipt = multiBetResponse.receipt as FobBetReceiptResponse
      }

      const singleBetCampaignActivatedInd = newItems.some(x =>
        x.receipt ? !!x.receipt.campaignActivatedInd : false
      )
      const multiBetCampaignActivatedInd = newMultiReceipt && newMultiReceipt.campaignActivatedInd
      if (singleBetCampaignActivatedInd || multiBetCampaignActivatedInd) {
        // fetchCampaignsAsync()
      }

      state.multiReceipt = newMultiReceipt
    })
  },
})

// =============
// Slice Exports
// =============

export const {
  addItemsToBetSlip,
  clearAllBetSlipItems,
  removeItemFromBetSlipById,
  setInvestmentAmount,
  clearAllReceiptsFromItems,
  removeItemsWithReceipt,
  removeItemsWithFatalError,
  updateBetSlipFobItemPrice,
  toggleIsItemInMulti,
} = betsSlice.actions

const allowPersistForKeys: (keyof BetSlipBetsState)[] = ['items']

export default persistReducer(
  {
    version: 1,
    key: 'betslip/bets',
    storage,
    debug: !PRODUCTION,
    whitelist: allowPersistForKeys,
  },
  betsSlice.reducer
)

// =====
// Types
// =====

export interface BetSlipBetsState {
  items: BetSlipItem[]

  isMultiExpanded: boolean
  isSingleExpanded: boolean
  multiBetError: { betErrorType: BetErrorType; errorMessage: string } | null
  multiInvestment: MultiInvestment
  multiReceipt?: FobBetReceiptResponse
}

type SetInvestmentAmountArgs = PayloadAction<{
  itemId: BetSlipItem['id']
  investmentType: InvestmentType
  value: string
}>
