import React, { ReactNode, useMemo } from 'react'
import dayjs from 'dayjs'

type Props = {
  date: Date
  format: string
  children?: (formattedDate: string) => ReactNode
}

const DateTime = ({ date, format, children }: Props): JSX.Element => {
  const formattedDate = useMemo(() => dayjs(date).format(format), [date, format])

  return <time dateTime={date.toISOString()}>{children?.(formattedDate) ?? formattedDate}</time>
}

export default DateTime
