import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const H1Styled = styled.h1({
  color: colors.black,
  fontFamily: font.family.primary,
  fontStyle: 'normal',
  fontWeight: font.weight.bold,
  margin: 0,
  padding: `${spacing.sm} ${spacing.smx1}`,
  ...font.size.xl,
  textTransform: 'unset',
  textAlign: 'center',
  borderBottom: `0.1rem solid ${colors.neutral[200]}`,
})

export const SubHeadingStyled = styled.h2({
  margin: 0,
  padding: `${spacing.smx1} ${spacing.md}`,
  fontStyle: 'normal',
  fontFamily: font.family.primary,
  fontSize: font.size.xl.fontSize,
  letterSpacing: font.size.xl.letterSpacing,
  lineHeight: font.size.xl.lineHeight,
  fontWeight: font.weight.semibold,
  textAlign: 'left',
  textShadow: 'none',
  textTransform: 'unset',
  background: 0,
  color: colors.lavender[900],

  '> span': {
    display: 'block',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.neutral[800],
  },
})
