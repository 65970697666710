import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { HistoryHeader } from '../Common/HistoryHeader'
import { FormDataStyled } from '../Common/Common.styles'
import { RaceReplayKnockout } from '../Common/RaceReplayKnockout'

const enum LocalConstants {
  animationTiming = 400,
}

export const HistoryExpandable: React.FC<
  React.ComponentProps<typeof RaceReplayKnockout> &
    Omit<React.ComponentProps<typeof HistoryHeader>, 'isExpanded' | 'onClick'> & {
      children: React.ReactChild
    }
> = ({ Place, FixtureDate, FieldSize, RaceNumber, TrackName, Distance, RaceReplay, children }) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    LocalConstants.animationTiming
  )

  return (
    <>
      <HistoryHeader
        onClick={toggleExpanded}
        isExpanded={isExpanded}
        Place={Place}
        FieldSize={FieldSize}
        RaceNumber={RaceNumber}
        TrackName={TrackName}
        FixtureDate={FixtureDate}
        Distance={Distance}
      />

      {(isExpanded || shouldRender) && (
        <WrapperStyled ref={wrapperRef}>
          <FormDataStyled ref={contentRef}>{children}</FormDataStyled>

          {RaceReplay.HasVideo && <RaceReplayKnockout RaceReplay={RaceReplay} />}
        </WrapperStyled>
      )}
    </>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxShadow: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  height: 0,
  transition: 'height ' + LocalConstants.animationTiming + 'ms ease',
  willChange: 'height',
  border: '0.1rem solid ' + hexColors.grey3,
  borderTop: 0,

  '& > div': {
    padding: '1rem 0.5rem',
  },

  // Placings
  ol: {
    listStyle: 'none',
    padding: 0,
    margin: 0,

    li: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '3rem',
      border: 0,
      fontSize: '1.2rem',
      borderBottom: '0.1rem solid ' + hexColors.gainsboro,

      '&:last-of-type': {
        border: 0,
      },

      strong: {
        margin: 0,
        minWidth: '4rem',
      },

      div: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '3rem',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        padding: '0.2rem 0.5rem',
        background: hexColors.whiteSmoke,
      },
    },
  },
})
