import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const RaceItemWrapperStyled = styled.div({
  borderBottom: '1px solid #eee',
  ':last-child': {
    borderBottom: 'none',
  },
})

export const RaceEventItemContainerStyled = styled.div({
  display: 'flex',
  padding: '0.75rem 1rem',
})

export const RaceItemStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const RaceEventItemDateStyled = styled.div({
  fontSize: '1.2rem',
  color: hexColors.dimGrey,
})

export const RaceItemTimerStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  minWidth: '7rem',
  marginRight: '1rem',
})

export const RaceEventDetailStyled = styled.div({
  display: 'flex',
  fontSize: '1.4rem',
  flexDirection: 'column',
})

export const RaceEventGroupSeperatorStyled = styled.div({
  background: hexColors.gainsboro,
  color: hexColors.black,
  fontSize: '1.2rem',
  margin: '0',
  padding: '0.75rem 1rem',
})

export const RaceEventResultStatusStyled = styled.div({
  color: hexColors.cinnabar,
})
