import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackKey, trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

// Calendar Actions
export const trackMeetingsCalendarNextMonth = () => trackKey(keys.meetingsCalendarNextMonth)
export const trackMeetingsCalendarPrevMonth = () => trackKey(keys.meetingsCalendarPrevMonth)
export const trackMeetingsCalendarOpened = () => trackKey(keys.meetingsCalendarOpened)
export const trackMeetingsCalendarSelectMonth = ({ selectedMonth }: { selectedMonth: string }) =>
  trackEvent(keys.meetingsCalendarSelectMonth, { selectedMonth })
export const trackMeetingsCalendarSelectedDay = () => trackKey(keys.meetingsCalendarSelectedDay)

// Favourite Actions
export const trackMeetingsFavouriteAdded = ({
  meetingName,
  meetingRaceCode,
}: {
  meetingName: string
  meetingRaceCode: string
}) => trackEvent(keys.meetingsFavouriteAdded, { meetingName, meetingRaceCode })
export const trackMeetingsFavouriteRemoved = () => trackKey(keys.meetingsFavouriteRemoved)
export const trackMeetingsFavouriteUsed = () => trackKey(keys.meetingsFavouriteUsed)

// Meetings Nav Actions
export const trackMeetingsRaceCodeToggle = ({ raceCode }: { raceCode: string }) =>
  trackEvent(keys.meetingsRaceCodeToggle, { raceCode })
export const trackMeetingsDayQuickSelect = ({ label }: { label: string }) =>
  trackEvent(keys.meetingsDayQuickSelect, { label })
export const trackMeetingsDayPrevArrow = () => trackKey(keys.meetingsDayPrevArrow)
export const trackMeetingsDayNextArrow = () => trackKey(keys.meetingsDayNextArrow)
