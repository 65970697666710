import Decimal from 'decimal.js'

export class Stake {
  public Win!: decimal.Decimal
  public Place!: decimal.Decimal

  private static _toDecimal(value: string | number | decimal.Decimal): decimal.Decimal {
    return value ? new Decimal(value) : new Decimal(0)
  }

  static normalise(stake: Stake): Stake {
    return stake
      ? {
          Win: Stake._toDecimal(stake.Win),
          Place: Stake._toDecimal(stake.Place),
        }
      : {
          Win: Stake._toDecimal(0),
          Place: Stake._toDecimal(0),
        }
  }
}
