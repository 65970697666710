import styled from '@emotion/styled'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { colors } from '@core/Settings'

interface SingleDayStyledProps {
  isInThisMonth?: boolean
  isDayToday?: boolean
  isSelected?: boolean
}

export const CalendarDatePickerContainerStyled = styled('div')({
  textAlign: 'center',
  margin: '0 auto',

  '&:focus': {
    outline: 'none',
  },
})

export const CalendarDatePickerArrow = styled('a')({
  color: colors.meetingList.calendar.directionalArrow,
})

export const DaysOfTheWeekStyled = styled('span')({
  fontSize: '1.2rem',
  color: colors.meetingList.calendar.headerText,
  lineHeight: '4rem',
})

export const SingleDayStyled = styled('span')<SingleDayStyledProps>(
  {
    display: 'flex',
    height: '4rem',
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: colors.meetingList.calendar.days.hoverBackground,
      color: colors.meetingList.calendar.days.hoverText,
    },
  },

  ({ isInThisMonth }) => ({
    color: isInThisMonth
      ? colors.meetingList.calendar.days.inThisMonth.inText
      : colors.meetingList.calendar.days.inThisMonth.outText,
    backgroundColor: isInThisMonth
      ? colors.meetingList.calendar.days.inThisMonth.inBackground
      : colors.meetingList.calendar.days.inThisMonth.outBackground,
    boxShadow: isInThisMonth
      ? `0 0 0 1px ${colors.meetingList.calendar.days.inThisMonth.boxShadow}`
      : `0 0 0 1px ${colors.meetingList.calendar.days.inThisMonth.boxShadow}`,
  }),

  ({ isDayToday, isSelected }) => ({
    backgroundColor:
      isDayToday && isSelected ? colors.meetingList.calendar.days.isDayToday.background : undefined,
    color:
      isDayToday && isSelected
        ? colors.meetingList.calendar.days.isDayToday.text
        : colors.meetingList.calendar.days.isDayToday.isNotText,
    fontWeight: isDayToday && isSelected ? 800 : 0,
  }),

  ({ isSelected }) => ({
    backgroundColor: isSelected
      ? colors.meetingList.calendar.days.isSelected.background
      : undefined,
    color: isSelected ? colors.meetingList.calendar.days.isSelected.text : undefined,
  }),

  ({ isDayToday, isSelected }) => ({
    fontWeight: isDayToday && !isSelected ? 800 : 0,
  })
)

export const MatrixStyled = styled(GridCell)({
  backgroundColor: colors.meetingList.calendar.matrix.background,
})

export const SelectStyled = styled('select')({
  display: 'inline-block',
  fontSize: '1.4rem',
  padding: '0.5rem 2rem 0.5rem 0.5rem',
  margin: '0.5rem 0.1rem',
  border: `0px solid ${colors.meetingList.calendar.select.border}`,
  borderRadius: '0',
  backgroundColor: colors.meetingList.calendar.select.background,
  backgroundImage: `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjU2cHgiIGhlaWdodD0iMjU2cHgiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTYgMjU2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHRpdGxlPmFycm93ZG93bjwvdGl0bGU+PGcgaWQ9ImFycm93ZG93biI+PHBhdGggZD0iTTI1Niw4MGwtMzItMzJsLTk2LDk2TDMyLDQ4TDAsODBsMTI4LDEyOEwyNTYsODB6Ii8+PC9nPjwvc3ZnPg==),
   linear-gradient(to bottom, #FFF 0%,#FFF 100%)`,
  backgroundRepeat: 'no-repeat, repeat',
  backgroundPosition: 'right 0.7rem top 50%, 0 0',
  backgroundSize: '0.85rem auto, 100%',
  appearance: 'none',
})

export const YearStyled = styled('div')({
  display: 'inline-block',
  fontSize: '1.4rem',
  padding: '0.5rem 2rem 0.5rem 0.5rem',
  margin: '0.5rem 0.1rem',
})
