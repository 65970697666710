import React from 'react'
import { SearchMessageDisplayStyled } from './InitialDisplay.styles'
import { colors } from '@core/Settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { SearchTextLabel } from '../SearchTextLabel/SearchTextLabel'
import { SearchTextLabelFontSize } from '../SearchTextLabel/SearchTextLabelStyled.styles'

export function SearchErrored() {
  return (
    <SearchMessageDisplayStyled>
      <Icon type='warningCircled' size='6rem' color={colors.search.initialText} />
      <SearchTextLabel size={SearchTextLabelFontSize.Large}>An Error has Occurred</SearchTextLabel>
      <SearchTextLabel size={SearchTextLabelFontSize.Small}>Please try again.</SearchTextLabel>
    </SearchMessageDisplayStyled>
  )
}
