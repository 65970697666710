import React from 'react'
import { Observable } from 'rx'
import type { BettingType } from '@mobi/betslip/types'
import { state$ as quickbetState$, QuickbetState } from '../../driver'
import { state$ as investmentState$ } from '../BetInvestment/betInvestmentDriver'
import { state$ as superPickState$, SuperPickState } from '@core/Components/SuperPick/driver'
import { observeImmutable } from '@core/Components/HOCs'
import { SuperPickWithInducementCheck } from '@core/Components/SuperPick'

export function shouldRenderSuperPick(bettingType: BettingType | null) {
  return bettingType === 'fixed-odds-racing'
}

export interface QuickbetSuperPickProps {
  winPrice: number
  placePrice: number
  investmentWin: number
  investmentPlace: number
  selectedSuperPickSeq?: number
  canBet: boolean
  canConfirmBet: boolean
  isBusy: boolean
  shouldRender: boolean
}

const state$ = Observable.combineLatest(
  quickbetState$,
  investmentState$,
  superPickState$,
  (quickbetState, investmentState, superpickState) => {
    const quickbet: QuickbetState = quickbetState.toJS()
    const {
      win: { value: investmentWin },
      place: { value: investmentPlace },
    } = investmentState.toJS()
    const superPick: SuperPickState = superpickState.toJS()
    const winPrice = quickbet.selection ? (quickbet.selection as { winPrice: number }).winPrice : 0
    const placePrice = quickbet.selection
      ? (quickbet.selection as { placePrice: number }).placePrice
      : 0
    return {
      bettingType: quickbet.bettingType,
      winPrice,
      placePrice,
      investmentWin,
      investmentPlace,
      selectedSuperPickSeq: superPick.selectedSuperPickSeq,
      canBet: quickbet.canBet,
      canConfirmBet: quickbet.canConfirmBet,
      isBusy: quickbet.isBusy,
      shouldRender: shouldRenderSuperPick(quickbet.bettingType),
    }
  }
)

export function QuickbetSuperPickComponent({
  shouldRender,
  winPrice,
  placePrice,
  investmentWin,
  investmentPlace,
  selectedSuperPickSeq,
  canBet,
  canConfirmBet,
  isBusy,
}: QuickbetSuperPickProps) {
  if (!shouldRender) {
    return null
  }
  const isVisible = !selectedSuperPickSeq && canConfirmBet ? false : canBet
  return (
    <SuperPickWithInducementCheck
      investmentWin={investmentWin}
      investmentPlace={investmentPlace}
      winPrice={winPrice}
      placePrice={placePrice}
      isDisabled={isBusy}
      isVisible={isVisible}
    />
  )
}

export const QuickbetSuperPick: React.ComponentClass = observeImmutable(state$, ({ record }) => {
  return (
    <div data-testid='QuickbetSuperPick'>
      <QuickbetSuperPickComponent {...(record as QuickbetSuperPickProps)} />
    </div>
  )
})
QuickbetSuperPick.displayName = 'QuickbetSuperPick'
