import React from 'react'
import * as iconPaths from './iconPaths'
import { SvgIconStyled } from './Icon.styles'

export type IconType = keyof typeof iconPaths

type ViewBox = '0 0 256 256' | '0 0 20 20' | (string & {})

export type IconProps = {
  type?: IconType
  size?: string
  title?: string
  color?: string
  character?: string
  className?: string
  viewBox?: ViewBox
  width?: string | number
  height?: string | number
}

export function Icon({
  type,
  size = '2.2rem',
  title,
  color,
  className,
  viewBox = '0 0 256 256',
  width = '1em',
  height = '1em',
  ...inputProps
}: IconProps): JSX.Element | null {
  if (!type) {
    return null
  }

  const resolvedPath = iconPaths[type]
  if (!resolvedPath) {
    return null
  }

  // README: Accessible SVG markup -> https://gist.github.com/davidhund/564331193e1085208d7e
  return (
    <SvgIconStyled
      width={width} // `width` REQUIRED for older browsers
      height={height} // `height` REQUIRED for older browsers
      viewBox={viewBox}
      role={title ? 'img' : 'presentation'}
      aria-hidden={!title}
      color={color}
      size={size}
      className={className}
      data-tid-icon-type={type}
      {...inputProps}
    >
      {title ? <title>{title}</title> : null}
      <path d={resolvedPath} />
    </SvgIconStyled>
  )
}
