import React from 'react'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { toTitleCase } from '@mobi/utils/string'
import { store } from '@core/Store'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { closeModal } from '@core/Components/Modal'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'

export const ShareBlackbookIncoming: React.FC<ShareBlackbookProps> = ({ type, name, id }) => {
  const { isLoading, isLoadingRunners, clickHandler, matchedRunnerInBlackbook } = useAddToBlackbook(
    { starterName: name, starterType: type, source: 'share' }
  )

  // Automatically navigate to Blackbook and highlight runner
  React.useEffect(() => {
    if (!matchedRunnerInBlackbook) return
    store.dispatch(closeModal({ id }))
    clickHandler()
  }, [matchedRunnerInBlackbook, clickHandler, id])

  const isBusy = isLoading || isLoadingRunners

  return (
    <div data-testid='share-blackbook-incoming'>
      <NoticeBox title='Blackbook Share Received' noticeBoxType={NoticeBoxTypes.Info}>
        <p>
          Add <strong>{toTitleCase(name)}</strong> to your Blackbook?
        </p>
      </NoticeBox>

      <Grid>
        <GridRow>
          <GridCell padding='1rem'>
            <ButtonBlock
              onClick={() => store.dispatch(closeModal({ id }))}
              color='secondary'
              disabled={isBusy}
            >
              Cancel
            </ButtonBlock>
          </GridCell>

          <GridCell padding='1rem'>
            <ButtonBlock
              onClick={clickHandler}
              color='primary'
              disabled={isBusy}
              testId='add-shared-blackbook'
            >
              {isBusy ? <SpinnerInlineStyled /> : 'Add to Blackbook'}
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}

// =====
// Types
// =====

type ShareBlackbookProps = { type: RaceCodes; name: string; id: string }
