import * as ko from 'knockout'
import { AllUpPoolSelectionViewModel } from './AllUpPoolSelectionViewModel'
import template from './allup.pool.selection.tpl.html'

if (!ko.components.isRegistered('allup-pool-selection2')) {
  ko.components.register('allup-pool-selection2', {
    viewModel: AllUpPoolSelectionViewModel,
    template,
  })
}
