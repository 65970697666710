/**
 * Standard spacing units
 */
export const spacing = {
  none: 0,
  smx2: '0.4rem',
  smx1: '0.8rem',
  sm: '1.2rem',
  md: '1.6rem',
  big: '2rem',
  bigx1: '2.4rem',
  bigx2: '2.8rem',
  bigx3: '3.2rem',
  lg: '4rem',
  lgx1: '4.8rem',
  lgx2: '6.4rem',
  lgx3: '8rem',
  huge: '9.6rem',
  hugex1: '12.8rem',
  hugex2: '16rem',
  hugex3: '19.2rem',
} as const
