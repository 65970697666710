import { injectable } from 'inversify'
import { IObservablePage } from '../Model/Observables/IObservablePage'
import Mapper from '../Mapping/Mappers'
import { IStartersService } from './IStartersService'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import * as useStartersData from '@core/Areas/Racing/Hooks/useStartersData'
import { getRacePageDataFromApi } from '@core/Areas/Racing/Hooks/useRacePageData'
import { queryKeys } from '@core/Data/ReactQuery/constants'

@injectable()
export class StartersReactQueryService implements IStartersService {
  public async retrieveCompleteRace(
    meetingId: string,
    raceNumber: number,
    meetingDate: Date
  ): Promise<IObservablePage> {
    const pageDto = await queryClient.fetchQuery(
      queryKeys.racePageComplete(meetingId, meetingDate, raceNumber),
      { queryFn: () => getRacePageDataFromApi(meetingId, meetingDate, raceNumber) }
    )
    const page = Mapper.map(pageDto)
    return page
  }
  public async retrieveStartersForRaces(
    meetingId: string,
    meetingDate: Date,
    raceNumbers: number[]
  ): Promise<IObservablePage> {
    const { queryKey, queryFn } = useStartersData
    const pageDto = await queryFn(meetingId, meetingDate, raceNumbers)
    queryClient.setQueryData(queryKey(meetingId, meetingDate, raceNumbers), pageDto)
    return Mapper.map(pageDto)
  }
}
