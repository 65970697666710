import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import type { ReactNode } from 'react'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IObservablePage } from '@classic/Betting-v2/Model/Observables/IObservablePage'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import BetTypeInformation from '@classic/Betting-v2/Model/Betting/BetTypeInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { BetInformers } from '@core/Areas/Racing/Components/BetInformers/BetInformers'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { StartersColumnHeader } from '@core/Areas/Racing/Components/RunnersColumnHeader'
import { RacePanels } from '@core/Areas/Racing/Components/Panels'
import { RaceStatus } from '@core/Areas/Racing/Types'
import type { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'
import { state$ as bettingV2CommandDriver$ } from '../driver'
import type { IInfoToolbarViewModel, IInfoToolbarViewModelParams } from './IInfoToolbarViewModel'

type Params = IInfoToolbarViewModelParams & {
  starters: ko.ObservableArray<IObservableStarter>
  raceKey: ObservableRaceKey
  useGiddyUp: IUseGiddyUp
  bettingContext: BettingInformation
}

type StartersColumnHeaderParams = Omit<
  React.ComponentProps<typeof StartersColumnHeader>,
  'children' | 'bettingContext'
>

@injectable()
export class InfoToolbarViewModel extends Disposable implements IInfoToolbarViewModel {
  public raceInfo!: IObservablePage
  public betType!: ko.Observable<BetTypeInformation>
  public isDoubleSelected!: ko.Observable<boolean>
  public isQuaddieSelected!: ko.Observable<boolean>
  public starters!: ko.ObservableArray<IObservableStarter>
  public raceKey!: ObservableRaceKey
  private subFormButton!: Rx.Disposable

  public BetInformers: ReactNode = BetInformers
  public StarterPageHeader: ReactNode = StartersColumnHeader
  public RacePanels: ReactNode = RacePanels
  public useGiddyUp!: IUseGiddyUp
  public isGiddyUpDisabled!: boolean
  public toggleGiddyUp!: () => void

  public bettingContext!: BettingInformation
  public starterPageHeaderProps!: ko.PureComputed<StartersColumnHeaderParams>
  public showBestPrice!: ko.PureComputed<boolean>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.starterPageHeaderProps = ko.pureComputed(this.createStarterPageHeaderProps, this)
  }

  public init(params: Params): void {
    this.bettingContext = params.bettingContext
    this.raceInfo = params.raceInfo
    this.betType = params.betType
    this.starters = params.starters
    this.raceKey = params.raceKey

    const raceNumber = this.raceKey.raceNumber()

    this.useGiddyUp = params.useGiddyUp
    this.isGiddyUpDisabled = !this.useGiddyUp.showGiddyUpInInformationPanel(raceNumber)
    this.toggleGiddyUp = this.useGiddyUp.openGiddyUp

    this.subFormButton = bettingV2CommandDriver$
      .map(record => {
        return {
          selected: record.showAllForm,
        }
      })
      .distinctUntilChanged()
      .subscribe(result => {
        this.PublishFormMessages(result.selected)
      })

    this.registerDisposals(() => {
      this.subFormButton.dispose()
    })
  }

  private PublishFormMessages(selected: boolean) {
    this.evtAggregator.publish('all-form-selected-command', {
      raceNumber: this.raceKey.raceNumber(),
      selected: selected,
    })
  }

  private createStarterPageHeaderProps(): StartersColumnHeaderParams {
    const selectedBetType = this.bettingContext.selectedBetType()
    const raceNumber = this.raceKey.raceNumber()
    const currentSortForRace = this.bettingContext.sortOption
    const raceInformation = this.raceInfo as unknown as ObservableRacePage
    const raceStatus = this.raceInfo.meetingInformation.selectedRace.raceStatus() as RaceStatus
    return {
      currentSortForRace: {
        property: currentSortForRace?.property || 'Number',
        direction: currentSortForRace?.direction || 'Ascending',
      },
      startersForRace: raceInformation
        .getStartersForRace(raceNumber)()
        .map(x => ({ hasFixedOdds: x.hasFixedOdds() })),
      selectedBetType: {
        betType: selectedBetType.betType(),
        isSameRaceMulti: selectedBetType.isSameRaceMulti(),
        isWinPlace: selectedBetType.isWinPlace(),
        sortOptions: selectedBetType.sortOptions(),
      },
      raceNumber,
      raceStatus,
    }
  }
}
