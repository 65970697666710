import React, { type ComponentPropsWithoutRef, forwardRef } from 'react'
import { PillButton } from '../Buttons'
import { Icon } from '../Icon'
import { ToastStyled } from './Toast.styled'
import type { ToastType } from './types'

type Props = {
  /**
   * The type of the toast
   */
  type: ToastType
  /**
   * The text to display in the toast
   */
  text: string
  /**
   * Callback when the toast is dismissed
   */
  onDismiss: VoidFunction
}

const icons: Record<ToastType, ComponentPropsWithoutRef<typeof Icon>['name']> = {
  success: 'LineCheckCircle',
  warning: 'LineAlertTriangle',
  error: 'LineAlertTriangle',
}

export const Toast = forwardRef<HTMLDivElement, Props>(({ type, text, onDismiss }, ref) => {
  return (
    <ToastStyled
      type={type}
      ref={ref}
      role={type === 'error' ? 'alert' : 'status'}
      aria-label={text}
    >
      <div>
        <Icon name={icons[type]} size='1.6rem' />
      </div>

      <div>{text}</div>

      <div>
        <PillButton
          type='button'
          color='tertiary_grey'
          onClick={onDismiss}
          size='xs'
          isIconOnlyButton
          aria-label='Dismiss toast'
        >
          <Icon name='SolidXClose' size='1.6rem' />
        </PillButton>
      </div>
    </ToastStyled>
  )
})

Toast.displayName = 'Toast'
