import React from 'react'

interface PriceDisplayState {
  win: string
  place: string | undefined
}

interface WpChangeTypeState {
  [s: string]: PriceChangeType
}

interface UsePriceChangeAnimationReturnState {
  wpChangeTypeState: WpChangeTypeState
  priceDisplayState: PriceDisplayState
}

interface UsePriceChangeAnimationReturnApi {
  setWpChangeTypeState: React.Dispatch<React.SetStateAction<WpChangeTypeState>>
  setPriceDisplayState: React.Dispatch<React.SetStateAction<PriceDisplayState>>
}

export type PriceChangeType = 'UP' | 'DOWN' | null

export function usePriceChangeAnimation(
  priceWin: string,
  pricePlace?: string,
  suppressAnimation = false
): [UsePriceChangeAnimationReturnState, UsePriceChangeAnimationReturnApi] {
  const [wpChangeTypeState, setWpChangeTypeState] = React.useState<WpChangeTypeState>({
    win: null,
    place: null,
  })

  const [priceDisplayState, setPriceDisplayState] = React.useState<PriceDisplayState>({
    win: priceWin,
    place: pricePlace,
  })

  const timer1IdRef = React.useRef<number>()
  const timer2IdRef = React.useRef<number>()

  const prevWinPriceRef = React.useRef<string>()
  const prevPlacePriceRef = React.useRef<string>()

  React.useEffect(() => {
    if (suppressAnimation) {
      setPriceDisplayState({ win: priceWin, place: pricePlace })
    } else {
      const winChangeType = getPriceChangeType(priceWin, prevWinPriceRef.current)
      const placeChangeType = pricePlace
        ? getPriceChangeType(pricePlace, prevPlacePriceRef.current)
        : null

      if (!!winChangeType || !!placeChangeType) {
        setWpChangeTypeState({ win: winChangeType, place: placeChangeType })

        timer1IdRef.current = window.setTimeout(() => {
          setPriceDisplayState({ win: priceWin, place: pricePlace })
        }, 750)

        timer2IdRef.current = window.setTimeout(() => {
          setWpChangeTypeState({ win: null, place: null })
        }, 2000)
      } else if (
        (!prevWinPriceRef.current && !!priceWin) ||
        (!prevPlacePriceRef.current && !!pricePlace) ||
        priceWin === '-' ||
        pricePlace === '-'
      ) {
        setPriceDisplayState({ win: priceWin, place: pricePlace })
      }
    }

    return function cleanUp() {
      if (timer1IdRef.current) {
        clearTimeout(timer1IdRef.current)
        timer1IdRef.current = undefined
      }
      if (timer2IdRef.current) {
        clearTimeout(timer2IdRef.current)
        timer2IdRef.current = undefined
      }
    }
  }, [priceWin, pricePlace, suppressAnimation])

  // Update previous values AFTER effect above
  React.useEffect(() => {
    prevWinPriceRef.current = priceWin
    prevPlacePriceRef.current = pricePlace
  }, [pricePlace, priceWin])

  const state = { wpChangeTypeState, priceDisplayState }
  const api = { setWpChangeTypeState, setPriceDisplayState }

  return [state, api]
}

// =======
// Helpers
// =======
function getPriceChangeType(curr: string, prev?: string): PriceChangeType {
  if (!prev || curr === '-') {
    return null
  }

  const currNum = Number.parseFloat(curr) || 0
  const prevNum = Number.parseFloat(prev) || 0

  if (currNum > prevNum) {
    return 'UP'
  }
  if (currNum < prevNum) {
    return 'DOWN'
  }

  return null
}
