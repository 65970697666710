import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { hexColors } from '@mobi/settings'

interface BettingDrawerStyledProps {
  open: boolean
}

export const SameRaceMultiBettingDrawerStyled = styled('div')({
  transition: 'transform 0.2s',
  backgroundColor: transparentize(0, hexColors.white),
  boxSizing: 'border-box',
  borderTop: `0.1rem solid ${hexColors.veryLightGrey}`,
  padding: '6px',
})

export const SameRaceMultiBettingDrawerContainerStyled = styled('div')<BettingDrawerStyledProps>(
  {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'transform 0.2s',
    boxSizing: 'border-box',
    zIndex: 8,
  },
  ({ open }) => ({
    transform: `translateY(${open ? 0 : '100%'})`,
  })
)

export const SameRaceMultiBettingDrawerNoticeBoxPositionerStyled = styled('div')({
  margin: '10px',
  boxShadow: '0px 0px 5px #aaaa',
})
