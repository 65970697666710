// ====================
// ! HEADS UP !
// ====================
// Several values in this file have an explicit any cast, this is for a reason.
// PayPal's SDK doesn't support ES6 import syntax (https://github.com/paypal/paypal-checkout-components/issues/1109).
// Also, sadly, the community typings for these are incorrect. Some of these types have been re-exported correctly
// in paypal.d.ts, but that only goes so far. This will require fixing in the typings, or we have to export our own.

import { type MutableRefObject } from 'react'
import {
  paypalCheckout as braintreePayPalCheckout,
  type Client,
  type PayPalCheckout,
} from 'braintree-web'
import type { CancellationData, AuthorizationData } from 'paypal-checkout-components'
import { getPayPal, loadSdk } from '../../Hooks/PayPal/paypalSdk'
import type { DepositRequestWithoutDeviceData } from '../useDeposit'
import { PayPalDepositRequest } from '@mobi/api-types'

type UnwrapRefObject<T> = T extends MutableRefObject<infer TValue> ? NonNullable<TValue> : never

type TransactionDetails = { depositAmount: number; transactionId: string }

type InitParams = {
  braintreeClient: MutableRefObject<Client | undefined>
  buttonContainer: MutableRefObject<HTMLDivElement | null>
  getTransactionDetails: () => TransactionDetails
  onInit?: (data: AuthorizationData, actions: object) => void
  onSuccess: (data: DepositRequestWithoutDeviceData<PayPalDepositRequest>) => Promise<void>
  onError?: (error: string | Error) => void
  onCancel?: (data: CancellationData, actions: object) => void
  onClick?: VoidFunction
}

export async function init({
  buttonContainer,
  braintreeClient,
  getTransactionDetails,
  onInit,
  onCancel,
  onError,
  onSuccess,
  onClick,
}: InitParams) {
  const { current: container } = buttonContainer
  const { current: client } = braintreeClient

  if (!client || !container) {
    const message = !client
      ? 'Attempted to initialize PayPal without a Braintree client'
      : 'Attempted to initialize PayPal without a button container'

    return Promise.reject(new Error(message))
  }

  const paypalCheckout = await braintreePayPalCheckout.create({
    client,
  })

  await loadSdk(() =>
    paypalCheckout.loadPayPalSDK({
      currency: 'AUD',
      intent: 'capture',
      commit: true,
    })
  )

  return renderButtons({
    paypalCheckout,
    braintreeClient: client,
    getTransactionDetails,
    onInit,
    onCancel,
    onSuccess,
    onError,
    onClick,
    buttonContainer: container,
  })
}

type RenderParams = Omit<InitParams, 'braintreeClient' | 'buttonContainer'> & {
  paypalCheckout: PayPalCheckout
  braintreeClient: UnwrapRefObject<InitParams['braintreeClient']>
  buttonContainer: UnwrapRefObject<InitParams['buttonContainer']>
}

async function renderButtons({
  buttonContainer,
  braintreeClient,
  getTransactionDetails,
  onInit,
  onClick,
  onSuccess,
  onError,
  onCancel,
  paypalCheckout,
}: RenderParams) {
  const buttonRenderer = getPayPal().Buttons({
    fundingSource: 'paypal',
    style: {
      height: 40, // In line with the other buttons
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      size: 'responsive' as any,
    },
    onApprove: async data => {
      const response = await paypalCheckout.tokenizePayment(data)

      const {
        nonce,
        details: { firstName, lastName, email, payerId, billingAddress },
      } = response

      const { depositAmount: amount, transactionId } = getTransactionDetails()

      await onSuccess({
        amount,
        paymentMethodToken: braintreeClient.authorization,
        paymentMethodNonce: nonce,
        firstName,
        lastName,
        payPalAddress: billingAddress && {
          street: `${billingAddress.line1}${billingAddress.line2}`,
          city: billingAddress.city,
          postCode: billingAddress.postalCode,
          state: billingAddress.state,
          countryCode: billingAddress.countryCode,
        },
        payPalEmail: email,
        payPalPayerId: payerId,
        depositSource: 'PayPal',
        transactionId,
      })

      return response
    },
    onCancel,
    onError,
    onInit,
    onClick,
    createOrder: () => {
      const { depositAmount: amount } = getTransactionDetails()
      return paypalCheckout.createPayment({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        flow: 'checkout' as any,
        currency: 'AUD',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        intent: 'capture' as any,
        amount,
      })
    },
  })

  // Last chance to check if the button container exists
  // It's entirely possible that the user will have closed the PayPal panel at this point
  // This can happen if the user's internet connection is a tad slow
  const id = `#${buttonContainer.id}`
  if (document.querySelector(id)) {
    buttonRenderer.render(id)
  }
}
