import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { colors, hexColors } from '@mobi/settings'
import { measurements, layering } from '@mobi/component-library/Theme/Common'

const animateInUp = keyframes({
  from: {
    transform: 'translateY(3rem)',
    opacity: 0,
  },
  to: {
    transform: 'translateY(0)',
    opacity: 1,
  },
})

export const LoginContainerStyled = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  zIndex: layering.login,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',
  backgroundColor: colors.brand.primary,
  animation: `${animateInUp} 0.15s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards`,

  h1: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    paddingLeft: '2rem',
    paddingRight: '5rem',
    textTransform: 'uppercase',
    fontSize: '1.8rem',
    color: hexColors.white,
    height: measurements.mobi.headerHeight,
    minHeight: measurements.mobi.headerHeight,

    '& > svg': {
      marginRight: '1rem',
    },

    button: {
      position: 'absolute',
      right: 0,
      height: measurements.mobi.headerHeight,
      width: 'auto',
      minWidth: '5rem',
      background: 'none',
      border: 0,
      color: hexColors.white,
    },
  },

  'input[type="tel"], input[type="password"], input[type="text"]': {
    fontSize: '2.4rem',
  },

  '& > div': {
    fontSize: '1.4rem',
    padding: '1rem 2rem',

    'input:focus': {
      backgroundColor: hexColors.white,
    },

    label: {
      fontSize: '1.3rem',
      color: hexColors.white,
    },

    fieldset: {
      marginBottom: '2rem',
    },

    '& > div': {
      marginBottom: '1.5rem',
    },
  },
})

export const LoginFieldOptionsStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '3rem',

  '& > *': {
    height: '3rem',
    lineHeight: 1,
  },

  label: {
    display: 'flex',
    alignItems: 'center',
  },

  button: {
    border: 0,
    background: 'none',
    color: hexColors.lavenderBlue,
    padding: 0,
    textDecoration: 'underline',
  },
})

export const LoginCoverLoadingStyled = styled.div({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
