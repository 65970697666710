import React, { lazy, Suspense } from 'react'
import { Spinner } from '@mobi/component-library/Common/Spinner'

const SignUpCompletePage = lazy(() => import('@core/Areas/Account/SignUp/Old/SignUpCompletePage'))

export const SignUpComplete = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <SignUpCompletePage />
    </Suspense>
  )
}
