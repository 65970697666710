import React from 'react'
import type { PaymentMethodButton } from '../types'
import { PaymentMethodButtonStyled } from '../PaymentMethodButton.styled'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'

/**
 * Best-effort implementation of Google Pay's button
 *
 * While not *exactly* in line with Google's brand guidelines, this button is styled
 * to look as closely to the real button as we reasonably could. Normally we load
 * the button through Google's scripts, but those are not allowed in web views
 */
export const GooglePayButton: PaymentMethodButton = ({ disabled, onClick }) => {
  return (
    <PaymentMethodButtonStyled
      aria-label='Pay with GPay'
      provider='googlepay'
      onClick={onClick}
      disabled={disabled}
    >
      <SvgLazy name='GooglePay' color='white' height='2rem' />
    </PaymentMethodButtonStyled>
  )
}
