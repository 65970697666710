import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import type { LinkInfo } from '../../../types'

export const LinkWrapper: React.FC<{ linkInfo?: LinkInfo; shouldProvideMarket?: boolean }> = ({
  linkInfo,
  shouldProvideMarket,
  children,
}) => {
  if (!linkInfo) return <>{children}</>

  return (
    <LinkStyled
      to={{
        pathname: linkInfo.urlPath,
        state: { market: shouldProvideMarket ? linkInfo.market : null },
      }}
    >
      {children}
    </LinkStyled>
  )
}

// ======
// Styles
// ======

const LinkStyled = styled(Link)({
  padding: 0,
  margin: 0,
  color: 'unset',
  textDecoration: 'unset',
  transition: 'opacity 300ms ease',

  ':hover': { opacity: 0.6 },
})
