import ObservableFixedOddsRaceInformation from './ObservableFixedOddsRaceInformation'
import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableRace {
  constructor() {
    this.key = ko.observable('')
    // @ts-expect-error Type issue
    this.meetingId = ko.observable()
    // @ts-expect-error Type issue
    this.distance = ko.observable()
    // @ts-expect-error Type issue
    this.tips = ko.observable()
    // @ts-expect-error Type issue
    this.tipster = ko.observable()
    // @ts-expect-error Type issue
    this.raceNumber = ko.observable()
    // @ts-expect-error Type issue
    this.raceName = ko.observable()
    // @ts-expect-error Type issue
    this.raceTime = ko.observable()
    // @ts-expect-error Type issue
    this.raceType = ko.observable()
    // @ts-expect-error Type issue
    this.payoutInfomation = ko.observable()
    // @ts-expect-error Type issue
    this.provDivUpdateTime = ko.observable()
    // @ts-expect-error Type issue
    this.fixedPlacesPaying = ko.observable()
    this.isFixedOddsRace = ko.observable(false)
    this.raceCode = ko.pureComputed<string>(() => (this.meetingId() as string) + this.raceNumber())
    this.fixedOddsInfo = new ObservableFixedOddsRaceInformation()
    this.hasSpecials = ko.observable(false)
    this.hasJackpots = ko.observable(false)
    this.jackpotPoolNames = ko.observable('')
    this.hasPlacePool = ko.observable(false)
    this.raceStatus = ko.observable('')
  }

  public merge(other: ObservableRace): void {
    Guard.notNull(other)

    this.key(other.key())
    this.meetingId(other.meetingId())
    this.distance(other.distance())
    this.tips(other.tips())
    this.tipster(other.tipster())
    this.raceNumber(other.raceNumber())
    this.raceName(other.raceName())
    this.raceTime(other.raceTime())
    this.raceType(other.raceType())
    this.isFixedOddsRace(other.isFixedOddsRace())
    this.payoutInfomation(other.payoutInfomation())
    this.provDivUpdateTime(other.provDivUpdateTime())
    this.fixedPlacesPaying(other.fixedPlacesPaying())
    this.fixedOddsInfo.merge(other.fixedOddsInfo)
    this.hasSpecials(other.hasSpecials())
    this.hasJackpots(other.hasJackpots())
    this.jackpotPoolNames(other.jackpotPoolNames())
    this.hasPlacePool(other.hasPlacePool())
    this.raceStatus(other.raceStatus())
  }

  public key: ko.Observable<string>
  public meetingId: ko.Observable<string>
  public distance: ko.Observable<number>
  public tips: ko.Observable<string>
  public tipster: ko.Observable<string>
  public raceNumber: ko.Observable<number>
  public raceName: ko.Observable<string>
  public raceTime: ko.Observable<Date>
  public raceType: ko.Observable<string>
  public raceCode: ko.PureComputed<string>
  public isFixedOddsRace: ko.Observable<boolean>
  public payoutInfomation: ko.Observable<string>
  public provDivUpdateTime: ko.Observable<Date>
  public fixedPlacesPaying: ko.Observable<number>
  public fixedOddsInfo: ObservableFixedOddsRaceInformation
  public hasSpecials: ko.Observable<boolean>
  public hasJackpots: ko.Observable<boolean>
  public jackpotPoolNames: ko.Observable<string>
  public hasPlacePool: ko.Observable<boolean>
  public raceStatus: ko.Observable<string>
}
