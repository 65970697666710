import styled from '@emotion/styled'
import { measurements } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { lessThan } from '@mobi/component-library/Utils/media'

export const AccountDropDownWrapStyled = styled.div<{ isVisible: boolean; innerHeight: number }>(
  {
    position: 'absolute',
    top: measurements.mobi.headerHeight,
    boxSizing: 'border-box',
    background: hexColors.white,
    width: '100%',
    zIndex: -1,
    transition: 'transform 0.2s cubic-bezier(0.16, 1, 0.3, 1)',
    padding: '1.3rem 1.7rem',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    fontFamily: '"PF Square Sans Pro", Arial, sans-serif',

    [lessThan('px350')]: {
      padding: '1rem 0.5rem',
    },
  },
  ({ isVisible = false, innerHeight }) => ({
    transform: `translateY(${isVisible ? '0' : '-100%'})`,
    maxHeight: `calc(${innerHeight}px - ${measurements.mobi.headerHeight})`,
  })
)

export const AccountInfoWrapStyled = styled.div({
  marginBottom: '1.6rem',

  '& > div': {
    minWidth: '50%',
  },
})

export const AccountNameLabelStyled = styled.div({
  fontSize: '2rem',
  fontWeight: '700',
  color: hexColors.studio,
})

export const AccountNumberLabelStyled = styled.div({
  fontSize: '1.2rem',
  fontWeight: '300',
  color: hexColors.nero,
})

export const SeparatorStyled = styled.hr({
  margin: '1rem 0',
  border: 0,
  height: 0,
  borderBottom: '1px solid ' + hexColors.grey3,
})

export const DepositWithdrawSectionStyled = styled.div({
  display: 'flex',
  '*:not(:last-child)': {
    marginRight: '1.7rem',
  },
})

export const ItemContainerStyled = styled.div({
  alignItems: 'center',
  color: hexColors.jacarta,
  display: 'flex',
  fontSize: '1.4rem',
  fontWeight: '500',
  justifyContent: 'space-between',
  marginBottom: '0.25rem',
})

export const LinkStyled = styled.a({
  alignItems: 'center',
  color: hexColors.jacarta,
  display: 'inline-flex',
  fontSize: '1.4rem',
  fontWeight: '500',
  justifyContent: 'space-between',
  padding: '0.5rem 0',
  textDecoration: 'none',
  width: '100%',
})

export const LogoutLinkStyled = styled(LinkStyled)({
  color: hexColors.crimson,

  // The logout link has no href associated but does a POST request instead
  // So we add a pointer as the cursor to indicate to the user it's still clickable
  cursor: 'pointer',
  justifyContent: 'flex-start',

  '& svg': {
    verticalAlign: 'middle',
    marginRight: '0.8rem',
    marginTop: '-0.2rem',
  },
})
