import type { IconType } from '@mobi/component-library/Common/Icon'
import type { NextSkyRace } from '../types'

export function getLatestRace(races: NextSkyRace[]): NextSkyRace {
  return races.find(race => race.RaceStatus === 'Open') || races[0]
}

export const mapCodeToIcon: Record<NextSkyRace['RaceType'], IconType> = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function sortRaceStatusOpenToTheRight(races: NextSkyRace[]): NextSkyRace[] {
  return races.sort((a, b) => {
    if (a.RaceStatus === 'Open') return 1
    if (b.RaceStatus === 'Open') return -1
    return 0
  })
}

export function createUniqueSkyRaceId({ MeetingID, MeetingDate, RaceNumber }: NextSkyRace): string {
  return `${MeetingID}:${MeetingDate}:${RaceNumber}`
}
