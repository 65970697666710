import { Link } from '@core/Components/Links'
import { ListUnstyled } from '@core/Components/List'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const DateContainer = styled.div({
  display: 'flex',
  marginBottom: '4em',
  width: '100%',

  '& > *:not(:first-of-type)': {
    marginLeft: '5px',
  },

  '& > *:not(:last-of-type)': {
    marginRight: '5px',
  },
})

export const Heading = styled.h3({
  background: 'none',
  color: hexColors.black,
  fontWeight: 'bold',
  margin: 0,
  padding: 0,
  textTransform: 'none',
})

export const ActivityStatementContainer = styled.div({
  padding: '0.75rem 0.5rem',
})

export const ActivityStatementText = styled.p({
  fontSize: '1.2em',
  textAlign: 'left',
})

export const ActivityStatementContactDetailsLink = styled(Link)({
  color: hexColors.pacificBlue,
})

export const ActivityEmailList = styled(ListUnstyled)({
  fontSize: '1.2em',

  '& > li:not(:last-of-type)': {
    marginBottom: '1rem',
  },
})
