import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Ticket } from '@core/Areas/AccountActivity/types'
import {
  HeaderSelectionIconBoxStyled,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import { LinkWrapper } from '@core/Areas/AccountActivity/Components/Ticket/Components/Common/LinkWrapper/LinkWrapper'
import {
  sameRaceMultiPositionToText,
  sortSameRaceMultiTicketLegs,
} from '@core/Areas/AccountActivity/helpers'

export const SameRaceMulti: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const header = `${ticket.productName} @ ${ticket.combinedDividend?.toFixed(2) || ''}`

  const sortedLegs = sortSameRaceMultiTicketLegs(ticket)

  return (
    <HeaderSelectionInfoStyled data-testid='ticket-header-same-race-multi'>
      <HeaderSelectionIconBoxStyled>
        <Icon type='betslip' />
      </HeaderSelectionIconBoxStyled>
      <div>
        <h3 data-tid-bet-product-name>{header}</h3>

        <ul data-test='ticket-header-same-race-multi-acceptors'>
          {sortedLegs.map((leg, index) => {
            const { srmPosition, number, name } =
              leg.selections[0].selectionDetails[0].acceptorDetails[0]
            const label = `${number} - ${name}`

            return (
              <li key={index} className='same-race-multi-acceptor'>
                <span>{sameRaceMultiPositionToText(srmPosition)}</span>
                <span>{label}</span>
              </li>
            )
          })}
        </ul>

        <LinkWrapper
          fixtureId={ticket.legs[0].fixtureId}
          fixtureDate={ticket.legs[0].fixtureDate}
          raceNumber={ticket.legs[0].raceNumber}
          label={ticket.legs[0].eventName}
        />
      </div>
    </HeaderSelectionInfoStyled>
  )
}
