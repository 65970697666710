import React from 'react'
import { useParams } from 'react-router'
import { navChange } from '@classic/Foundation/Navigation/Nav'
import { ChangePasswordViewObserve } from './ChangePassword'
import { Reset, state$, UpdateCurrentPassword } from './driver'
import { useObservableImmutable } from '@core/Utils/hooks'
import { hideLogin, refreshLogonState } from '@core/Areas/Login/helpers'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { CHANGE_TEMP_PASSWORD_ROUTE } from './constants'

export const navigateToChangeTempPassword = ({
  accountNumber,
  password,
}: NavigateToChangeTempPassword) => {
  UpdateCurrentPassword(password)
  navChange(`/#${CHANGE_TEMP_PASSWORD_ROUTE}/${accountNumber}`)
  hideLogin()
}

export const ChangeTemporaryPassword = () => {
  const { accountNumber } = useParams<{ accountNumber?: string }>()
  const { isChangeTemporaryPasswordSuccess } = useObservableImmutable(state$, [
    'isChangeTemporaryPasswordSuccess',
  ])

  React.useEffect(() => Reset, [])

  React.useEffect(() => {
    !accountNumber && navChange('/#')
  }, [accountNumber])

  React.useEffect(() => {
    if (isChangeTemporaryPasswordSuccess) {
      refreshLogonState()
      navChange('/#')
      RegisterToast({ message: 'Password Changed', type: 'default' })
    }
  }, [isChangeTemporaryPasswordSuccess])

  if (!accountNumber) {
    return null
  }

  return (
    <ChangePasswordViewObserve
      accountNumber={accountNumber}
      isChangeTemporaryPassword
      isDesktop={false}
    />
  )
}

// Types
interface NavigateToChangeTempPassword {
  accountNumber: string
  password: string
}
