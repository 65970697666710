import React from 'react'
import { HeadingLevels, HeadingStyled } from './Heading.styles'

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
  level?: HeadingLevels
}

export function Heading({ level = 1, children, ...props }: HeadingProps) {
  return React.createElement(HeadingStyled(level) as React.ElementType, props, children)
}
