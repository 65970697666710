import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { RaceListResponse } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { RaceButton } from './RaceButton'

const enum LocalConstants {
  RaceButtonWidth = '3.6rem',
}

export const RaceSelection: React.FC<{
  meetingId: string
  meetingDate: string
  races: RaceListResponse['races']
  selectedRace: number
  onRaceSelected: (raceNumber: number) => void
  firstLegsOfQuaddies: Set<number>
}> = ({ meetingId, meetingDate, races, selectedRace, firstLegsOfQuaddies, onRaceSelected }) => {
  const scrollContainerRef = React.useRef<HTMLOListElement>(null)
  const activeIndicatorRef = React.useRef<HTMLSpanElement>(null)

  return (
    <RacesListStyled data-testid='races' ref={scrollContainerRef}>
      {races.map(race => (
        <li key={race.raceNumber}>
          <RaceButton
            meetingId={meetingId}
            meetingDate={meetingDate}
            race={race}
            isSelected={race.raceNumber === selectedRace}
            firstLegsOfQuaddies={firstLegsOfQuaddies}
            onClick={onRaceSelected}
            activeIndicatorRef={activeIndicatorRef}
            scrollContainerRef={scrollContainerRef}
          />
        </li>
      ))}

      <span data-testid='RaceSelection.ActiveIndicator' ref={activeIndicatorRef} />
    </RacesListStyled>
  )
}

// ======
// Styles
// ======

const RacesListStyled = styled.ol({
  position: 'relative',
  display: 'flex',
  padding: '1rem 0',
  paddingLeft: spacing.md,
  margin: 0,
  listStyle: 'none',
  overflowX: 'auto',
  overscrollBehavior: 'contain',

  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  scrollbarColor: colors.neutral[300] + ' ' + colors.surface[50],

  '@media (pointer: coarse)': {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': { display: 'none' },
  },

  '> li': {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: spacing.sm,
    minWidth: LocalConstants.RaceButtonWidth,
    maxWidth: LocalConstants.RaceButtonWidth,
    height: '2.4rem',
  },

  '> li:last-of-type': { paddingRight: spacing.md },

  // Active Indicator
  '> span': {
    position: 'absolute',
    left: 0,
    zIndex: 0,
    width: LocalConstants.RaceButtonWidth,
    height: '2.4rem',
    transform: `translateX(${spacing.md})`,
    transition: 'transform 0.2s ease',
    borderRadius: radius.lgx1,
    backgroundColor: colors.studio[500],
  },
})
