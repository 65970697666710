import { AppState } from '..'

export const selectActiveInvestment = (state: AppState) => state.betslip.workflow.activeInvestment

export const selectKeypadActiveValue = (state: AppState) => state.betslip.workflow.keypadActiveValue

export const selectIsBettingDisabled = (state: AppState) => state.betslip.workflow.isBettingDisabled

export const selectWorkflowStatus = (state: AppState) => state.betslip.workflow.currentStatus

export const selectIsHostUserLoggedIn = (state: AppState) =>
  state.betslip.workflow.isHostUserLoggedIn
