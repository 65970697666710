import * as ko from 'knockout'
import { injectable } from 'inversify'
import ObservableScratching from '../../../Model/Observables/ObservableScratching'
import { IScratchingsViewModel } from './IScratchingsViewModel'

@injectable()
export class ScratchingsViewModel implements IScratchingsViewModel {
  public availableScratchings!: ko.ObservableArray<ObservableScratching>
  public isExpanded!: ko.Observable<boolean>

  public init(params: { availableScratchings: ko.ObservableArray<ObservableScratching> }) {
    this.availableScratchings = params.availableScratchings
    this.isExpanded = ko.observable(false)
  }

  public toggleExpanded() {
    this.isExpanded(!this.isExpanded())
  }
}
