import * as immutable from 'immutable'
import { KeypadModes } from '@core/Components/Keypad/KeyPress'
import {
  BetslipState,
  BetslipItem,
  defaultBetslipState,
  BetslipStateRecord,
} from '@core/Areas/Betslip/driver'
import {
  hasNotBeenPlaced,
  isFatalErrorType,
  clearNonFatalErrors,
  clearNonFatalMultiBetLegError,
  setFobSelectionPriceSource,
} from './state'
import { startIotSubscription } from './iotSubscriptions'

const CURRENT_SCHEMA_VERSION = 2

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type PersistedBetslipState = Omit<
  BetslipState,
  | 'apiErrorMessage'
  | 'hasProposed'
  | 'isBusy'
  | 'multiReceipt'
  | 'isMultiExpanded'
  | 'isSingleExpanded'
  | 'items'
  | 'isOpen'
  | 'isMultiFormulaExpanded'
> & { version: number } & { items: BetslipItem[] }

export const defaultBetslipItem: Readonly<BetslipItem> = {
  betErrorType: undefined,
  selection: null,
  isInMulti: false,
  bettingType: 'fixed-odds-racing',
  errorMessage: '',
  id: '',
  investment: {
    win: {
      value: 0,
      lastKeyPressed: {
        mode: KeypadModes.Denomination,
        value: 0,
      },
      secondLastKeyPressed: {
        mode: KeypadModes.Denomination,
        value: 0,
      },
    },
    place: {
      value: 0,
      lastKeyPressed: {
        mode: KeypadModes.Denomination,
        value: 0,
      },
      secondLastKeyPressed: {
        mode: KeypadModes.Denomination,
        value: 0,
      },
    },
    bonusBet: undefined,
  },
  isEachWay: false,
  isEachWayAvailable: false,
  isSuperPickAvailable: false,
  multiBetLegError: null,
  selectedSuperPickOffer: null,
  selectionDetails: null,
  shouldAllowPlaceInvestment: false,
  specialOffers: [],
  hasIotSubscription: false,
}

function hydrateItem(item: Partial<BetslipItem>): BetslipItem {
  if (!item || typeof item !== 'object') {
    return { ...defaultBetslipItem }
  }

  return startIotSubscription({
    ...defaultBetslipItem,
    ...item,
    // forget receipt
    receipt: defaultBetslipItem.receipt,
  })
}

export function hydrateBetslip(
  state: Partial<PersistedBetslipState> | undefined
): BetslipStateRecord {
  if (!state || typeof state !== 'object') {
    return immutable.fromJS({ ...defaultBetslipState })
  }

  upgradeSchemaIfRequired(state)

  const {
    isOpen,
    apiErrorMessage,
    hasProposed: hasProposed,
    isBusy,
    multiReceipt,
    isSingleExpanded,
    isMultiExpanded,
    isMultiFormulaExpanded,
  } = defaultBetslipState

  const { multiBetError = defaultBetslipState.multiBetError, multiInvestment } = state

  const newMultiInvestment =
    multiInvestment !== undefined
      ? { ...defaultBetslipState.multiInvestment, ...multiInvestment }
      : defaultBetslipState.multiInvestment

  const items = immutable
    .List(Array.isArray(state.items) ? state.items : [])
    .map(hydrateItem)
    .toList()

  const stateToHydrate: BetslipState = {
    // default values
    isOpen,
    apiErrorMessage,
    hasProposed,
    isBusy,
    multiReceipt,
    isSingleExpanded,
    isMultiExpanded,
    // from local storage
    items,
    multiBetError,
    multiInvestment: newMultiInvestment,
    scrollPosition: 'top',
    isMultiFormulaExpanded,
  }
  return immutable.fromJS(stateToHydrate)
}

const upgradeSchemaIfRequired = (state: Partial<PersistedBetslipState>) => {
  const currentVersion = state.version || 1
  if (currentVersion < 2) {
    if (state.items) {
      for (const item of state.items) {
        // upgrade old betslip item FOB selection to the current version
        if (item.selection) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const selection = item.selection as any
          if (selection.propositionSeq) {
            selection.type = 'fob-proposition'
          } else if (selection.acceptorNumber) {
            selection.type = 'fob-matched'
            selection.propositionSeq = '0' // unknown
          } else if (selection.market) {
            selection.type = 'fob-proposition'
            selection.propositionSeq = '0' // unknown
          }
        }
      }
    }
  }
}

export function betslipStateToPersisted(state: BetslipState): PersistedBetslipState {
  const { items, multiBetError, multiInvestment, multiReceipt } = state
  // exclude items that have been placed, and clear non-fatal errors for singles and multi legs
  const persistableItems = items
    .filter(hasNotBeenPlaced)
    .map(clearNonFatalErrors)
    .map(clearNonFatalMultiBetLegError)
    .map(item => ({ ...item, hasIotSubscription: false }))
    .map(setFobSelectionPriceSource)
    .toArray()
  return {
    version: CURRENT_SCHEMA_VERSION,
    items: persistableItems,
    // if there's a receipt, reset the multi investment and error. clear non-fatal multi errors
    multiBetError:
      !multiReceipt && multiBetError && isFatalErrorType(multiBetError.betErrorType)
        ? multiBetError
        : defaultBetslipState.multiBetError,
    multiInvestment: !multiReceipt ? multiInvestment : defaultBetslipState.multiInvestment,
    scrollPosition: 'top',
  }
}
