export const font = {
  family: {
    primary: "'Inter', sans-serif",
    secondary: "'PF Square Sans Pro', sans-serif",
  },
  size: {
    xs: { fontSize: '0.9rem', lineHeight: '1.2rem', letterSpacing: 0 },
    base: { fontSize: '1rem', lineHeight: '1.2rem', letterSpacing: 0 },
    sm: { fontSize: '1.1rem', lineHeight: '1.2rem', letterSpacing: 0 },
    md: { fontSize: '1.3rem', lineHeight: '1.6rem', letterSpacing: 0 },
    lg: { fontSize: '1.4rem', lineHeight: '2rem', letterSpacing: 0 },
    xl: { fontSize: '1.6rem', lineHeight: '2.4rem', letterSpacing: 0 },
    xl2: { fontSize: '1.8rem', lineHeight: '2.8rem', letterSpacing: '-0.018rem' },
    xl3: { fontSize: '2rem', lineHeight: '3.2rem', letterSpacing: '-0.02rem' },
    xl4: { fontSize: '2.3rem', lineHeight: '3.6rem', letterSpacing: '-0.023rem' },
    xl5: { fontSize: '2.6rem', lineHeight: '4rem', letterSpacing: '-0.052rem' },
    xl6: { fontSize: '2.9rem', lineHeight: '4.4rem', letterSpacing: '-0.052rem' },
    xl7: { fontSize: '3.2rem', lineHeight: '4.8rem', letterSpacing: '-0.072rem' },
    xl8: { fontSize: '3.7rem', lineHeight: '4.8rem', letterSpacing: '-0.072rem' },
  },
  baseSize: {
    mobile: '10px',
    desktop: '16px',
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
} as const
