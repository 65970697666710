import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRaceFieldFormDataFromApi, GetRaceFieldFormDataParams } from './api'

export const useRaceFieldFOOFormData = (raceFieldFOOFormData: GetRaceFieldFormDataParams) =>
  useQuery(
    [
      queryKeys.racePageFieldFOOForm(
        raceFieldFOOFormData.racecourseSeq,
        raceFieldFOOFormData.meetingDate,
        raceFieldFOOFormData.raceNumber
      ),
    ],
    () => getRaceFieldFormDataFromApi(raceFieldFOOFormData),
    {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!raceFieldFOOFormData.racecourseSeq,
    }
  )
