import React from 'react'
import dayjs from 'dayjs'

import { sameDays } from '../utils'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { SingleDayStyled } from '../DatePicker.styles'

interface DayProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  day: Date
  value: Date
  screen: Date
  onClick: () => void
  isInThisMonth?: boolean
  isDayToday?: boolean
  isSelected?: boolean
}

export const Day: React.FunctionComponent<DayProps> = ({
  day,
  value,
  screen,
  onClick,
  ...props
}) => {
  const isPrevMonth =
    screen.getMonth() === 0 ? day.getMonth() === 11 : day.getMonth() === screen.getMonth() - 1
  const isNextMonth =
    screen.getMonth() === 11 ? day.getMonth() === 0 : day.getMonth() === screen.getMonth() + 1
  const isInThisMonth = !isPrevMonth && !isNextMonth

  const isSelected = sameDays(day, value)
  const isDayToday = sameDays(day, new Date())

  const tomorrow = dayjs().add(1, 'days').toDate()
  const isDayTomorrow = sameDays(day, tomorrow)

  return (
    <GridCell>
      <SingleDayStyled
        {...props}
        onClick={onClick}
        isInThisMonth={isInThisMonth}
        isDayToday={isDayToday}
        isSelected={isSelected}
        data-tid-is-day-today={isDayToday}
        data-tid-is-day-nextday={isDayTomorrow}
      >
        {day.getDate()}
      </SingleDayStyled>
    </GridCell>
  )
}
