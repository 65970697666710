import * as ko from 'knockout'
import { VerticalArrowViewModel } from './VerticalArrowViewModel'
import template from './verticalArrow.tpl.html'

if (!ko.components.isRegistered('vertical-arrow')) {
  ko.components.register('vertical-arrow', {
    viewModel: VerticalArrowViewModel,
    template,
  })
}
