import * as ko from 'knockout'
import { ScratchedViewModel } from './ScratchedViewModel'
import template from './scratched.tpl.html'

if (!ko.components.isRegistered('display-scratched')) {
  ko.components.register('display-scratched', {
    viewModel: ScratchedViewModel,
    template,
  })
}
