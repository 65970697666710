import React from 'react'
import { SettingItem } from '../Common/SettingItem'
import { Tray } from '@mobi/component-library/Overlays/Tray'
import { EditPresetTrayContent } from './Components/EditPresetTrayContent'
import { Button } from '@mobi/component-library/Common/V2/Buttons'

export const KeypadDenominationsSetting: React.FC = () => {
  const [isTrayOpen, setIsTrayOpen] = React.useState(false)

  return (
    <SettingItem
      label='Betslip keyboard presets'
      desciption='Save up to 3 custom amounts for one tap selection when placing a bet'
      rightComponent={
        <Button onClick={() => setIsTrayOpen(true)} color='tertiary_grey'>
          Edit
        </Button>
      }
      bottomComponent={
        <Tray
          isOpen={isTrayOpen}
          headerTitle='Edit Betslip Keyboard'
          dismissStatus='allowed'
          onDismiss={() => setIsTrayOpen(false)}
        >
          <EditPresetTrayContent hideTray={() => setIsTrayOpen(false)} />
        </Tray>
      }
    />
  )
}
