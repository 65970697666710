import React from 'react'
import { toMoney } from '@mobi/utils/money'

export interface MoneyProps extends ToMoneyOptions {
  amount: number
  className?: string
  testId?: string
  prefix?: string
}

export function Money({ amount, className, testId, prefix, ...options }: MoneyProps): JSX.Element {
  const testElement = testId ? { [`data-tid-${testId}`]: '' } : { ['data-tid-money']: '' }

  return (
    <span className={className} {...testElement}>
      {`${prefix ? prefix : ''}${toMoney(amount, options)}`}
    </span>
  )
}

// =====
// Types
// =====

type ToMoneyOptions = NonNullable<Parameters<typeof toMoney>[1]>
