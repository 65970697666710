import { ImageUrlWithSizes, ImageUrlWithSize, ImageSize } from '@core/Data/Racing'
import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'

type Sizes = 'small' | 'large' | 'larger' | 'largest'

export interface BuildFOOImageUrlWithSizesProps {
  meetingDate: string
  racecourseSeq?: number | null
  raceNumber: number | null
  isTrots: boolean
  isDogs: boolean
  starterNumber: number
  meetingCode?: string
}

export interface BuildFOOImageUrlWithSizeProps extends BuildFOOImageUrlWithSizesProps {
  imageSize: Sizes
}

export const RaceSmall: ImageSize = { width: 25, height: 30, y: 0 }
export const RaceLarge: ImageSize = { width: 35, height: 42, y: 0 }
export const RaceLarger: ImageSize = { width: 45, height: 54, y: 0 }
export const RaceLargest: ImageSize = { width: 55, height: 77, y: 0 }
export const DogSmall: ImageSize = { width: 25, height: 25, y: 0 }
export const DogLarge: ImageSize = { width: 35, height: 35, y: 0 }
export const DogLarger: ImageSize = { width: 45, height: 45, y: 0 }
export const DogLargest: ImageSize = { width: 55, height: 55, y: 0 }
export const TrotSmall: ImageSize = { width: 24, height: 18, y: -1 }
export const TrotLarge: ImageSize = { width: 36, height: 36, y: -1 }
export const TrotLarger: ImageSize = { width: 48, height: 48, y: -1 }
export const TrotLargest: ImageSize = { width: 60, height: 60, y: -1 }

export function getSize(isTrots: boolean, isDogs: boolean, imageSize: Sizes): ImageSize {
  const sizes: { [key: string]: ImageSize } = {
    RaceSmall,
    RaceLarge,
    RaceLarger,
    RaceLargest,
    DogSmall,
    DogLarge,
    DogLarger,
    DogLargest,
    TrotSmall,
    TrotLarge,
    TrotLarger,
    TrotLargest,
  }

  return (
    sizes[
      `${isTrots ? 'Trot' : 'Race'}${imageSize.charAt(0).toUpperCase() + imageSize.slice(1)}` ||
        `${isDogs ? 'Dog' : 'Race'}${imageSize.charAt(0).toUpperCase() + imageSize.slice(1)}`
    ] || RaceSmall
  )
}

export function getImageUrlWithSize({
  meetingDate,
  racecourseSeq,
  raceNumber,
  isTrots,
  isDogs,
  imageSize,
  starterNumber,
  meetingCode,
}: BuildFOOImageUrlWithSizeProps): ImageUrlWithSize {
  return {
    url: getImageSizeUrl({
      racecourseSeq,
      meetingDate,
      raceNumber,
      isTrots,
      isDogs,
      imageSize,
      starterNumber,
      meetingCode,
    }),
    size: getSize(isTrots, isDogs, imageSize),
  }
}

export function getImageUrlWithSizes({
  racecourseSeq,
  meetingDate,
  raceNumber,
  isTrots,
  isDogs,
  starterNumber,
  meetingCode,
}: BuildFOOImageUrlWithSizesProps): ImageUrlWithSizes {
  return {
    small: getImageUrlWithSize({
      racecourseSeq,
      meetingDate,
      raceNumber,
      isTrots,
      isDogs,
      imageSize: 'small',
      starterNumber,
      meetingCode,
    }),
    large: getImageUrlWithSize({
      racecourseSeq,
      meetingDate,
      raceNumber,
      isTrots,
      isDogs,
      imageSize: 'large',
      starterNumber,
      meetingCode,
    }),
    larger: getImageUrlWithSize({
      racecourseSeq,
      meetingDate,
      raceNumber,
      isTrots,
      isDogs,
      imageSize: 'larger',
      starterNumber,
      meetingCode,
    }),
    largest: getImageUrlWithSize({
      racecourseSeq,
      meetingDate,
      raceNumber,
      isTrots,
      isDogs,
      imageSize: 'largest',
      starterNumber,
      meetingCode,
    }),
  }
}

export function getImageSizeUrl({
  racecourseSeq,
  meetingDate,
  raceNumber,
  isTrots,
  isDogs,
  imageSize,
  starterNumber,
  meetingCode,
}: BuildFOOImageUrlWithSizeProps): string {
  const urlDate = encodeURIComponent(
    formatDateStringWithoutChangingTimezone(meetingDate, 'MM/DD/YYYY')
  )
  return `/Image/GetSingleSilkWithSizes?racecourseSeq=${racecourseSeq}&meetingDate=${urlDate}&raceNo=${raceNumber}&isTrots=${isTrots}&isDogs=${isDogs}&size=${imageSize}&starterNumber=${starterNumber}&meetingCode=${meetingCode}`
}
