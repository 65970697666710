import { useQuery } from 'react-query'
import type { CountryList } from '@mobi/api-types'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getCountryList } from '.'

export const useCountryList = () => {
  return useQuery<CountryList>({
    queryKey: queryKeys.countryList,
    queryFn: getCountryList,
    staleTime: 360000,
    refetchOnWindowFocus: false,
  })
}
