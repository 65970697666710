import * as ko from 'knockout'
import { injectable } from 'inversify'
import ObservableSubstitutePool from '../../../Model/Observables/ObservableSubstitutePool'
import { ISubstitutesViewModel } from './ISubstitutesViewModel'

@injectable()
export class SubstitutesViewModel implements ISubstitutesViewModel {
  public availableSubstitutePools!: ko.ObservableArray<ObservableSubstitutePool>
  public isExpanded!: ko.Observable<boolean>

  public init(params: {
    availableSubstitutePools: ko.ObservableArray<ObservableSubstitutePool>
  }): void {
    this.availableSubstitutePools = params.availableSubstitutePools
    this.isExpanded = ko.observable(false)
  }

  public toggleExpanded(): void {
    this.isExpanded(!this.isExpanded())
  }
}
