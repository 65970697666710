import { AcceptorDetail, RaceDetail, RacingCode } from '../StructuredSearch/StructuredSearchResult'

export type SearchResult =
  | SearchResultRace
  | SearchResultAcceptor
  | SearchResultSport
  | SearchResultFobEvent
  | SearchResultFobProposition

interface Error {
  message: string
}

export type SearchResultType = SearchResult[] | Error

export interface RawSearchResults {
  statistics: {
    count: number
    raceCount: number
    acceptorCount: number
    sportsCount: number
    hitCount: number
    minScore: number
    maxScore: number
  }
  results: SearchResult[]
}

export enum ResultType {
  Acceptor = 'AcceptorResult',
  Race = 'RaceResult',
  Sport = 'SportResult',
  Bet = 'BetResult',
  FobEvent = 'FobEvent',
  FobProposition = 'FobProposition',
}

export interface HighlightToken {
  value: string
  isMatch: boolean
}

type HighlightResult = HighlightToken[]

interface MetaDataDictionary<T> {
  [index: string]: T
}

export type MetaDataHighlight = MetaDataDictionary<HighlightResult>

interface Metadata {
  score?: number
  highlights: MetaDataHighlight
}

interface SportDetail {
  name: string
  locationName: string
  advertisedStartTime: string
}

interface FobEvent {
  sequence: number
  raceCourseSequence?: number
  raceCourse: string
  contestType?: RacingCode
  country: string
  name: string
  number?: number
  competition: string
  competitionSeq: number
  startTime: string
  productCode: string
  isBettable: boolean
  isSelling: boolean
  isSettled: boolean
  isAbandoned: boolean
  isLateStart: boolean
  isSuspended: boolean
  isStartTimeRelevant: boolean
  status: string
  result: string
}

interface FobMarket {
  sequence: number
  name: string
  openedDateTime?: string
  isSelling: boolean
  isSettled: boolean
  isLateStart: boolean
  isEwAvail: boolean
  isSuspended: boolean
  isResulted: boolean
  isDelayedStart: boolean
}

interface PropositionPrice {
  changeTimestamp: string
  providerTimestamp?: string
  winPrice?: number
  placePrice?: number
  handicapValue?: number
}

interface FobProposition {
  sequence: number
  name: string
  number: number
  isBettable?: boolean
  isSettled: boolean
  isAbandoned: boolean
  isSuspended: boolean
  isResulted: boolean
  isScratched: boolean
  isVoid: boolean
  isStartTimeRelevant: boolean
  scratchingTime?: string
  status: string
  result: string
  price: PropositionPrice
  fobMarket: FobMarket
  fobEvent: FobEvent
}

interface SearchResultCommon {
  id: string
  metadata: Metadata
  kind: ResultType
}

export interface SearchResultRace extends SearchResultCommon {
  race: RaceDetail
}

export interface SearchResultAcceptor extends SearchResultCommon {
  acceptor: AcceptorDetail
}

export interface SearchResultSport extends SearchResultCommon {
  sport: SportDetail
}

export interface SearchResultFobEvent extends SearchResultCommon {
  fobEvent: FobEvent
}

export interface SearchResultFobProposition extends SearchResultCommon {
  fobProposition: FobProposition
}

export function isSearchResultRace(result: SearchResult): result is SearchResultRace {
  return (result as SearchResultRace).race !== undefined
}

export function isSearchResultAcceptor(result: SearchResult): result is SearchResultAcceptor {
  return (result as SearchResultAcceptor).acceptor !== undefined
}

export function isSearchResultSport(result: SearchResult): result is SearchResultSport {
  return (result as SearchResultSport).sport !== undefined
}

export function isSearchResultFobEvent(result: SearchResult): result is SearchResultFobEvent {
  return (result as SearchResultFobEvent).fobEvent !== undefined
}

export function isSearchResultFobProposition(
  result: SearchResult
): result is SearchResultFobProposition {
  return (result as SearchResultFobProposition).fobProposition !== undefined
}
