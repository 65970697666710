import type { TrackableButton } from './Types'

export const buttonText: Readonly<Record<TrackableButton, string>> = {
  showDoubleProvDiv: 'Double Prov Div',
  showFixedFlucs: 'Fixed Flucs',
  showAllForm: 'Form',
  showMarketMovers: 'Market Movers',
  showQuaddieProvDiv: 'Quaddie Prov Div',
  commentsVisible: 'Stewards Comments',
  tipsVisible: 'Tips',
  resultsVisible: 'Results',
  fieldVisible: 'Field',
  replayVisible: 'Replay',
  previewVisible: 'Preview',
  giddyUp: 'Giddy Up',
}
