import { post } from '@classic/Foundation/Services/ApiService'
import type { DeliveryMethod } from '../..'

const enum LocalConstants {
  ApiRoot = '/$_/accountrecovery/RecoverPassword',
  GenericError = 'Sorry, something went wrong. Please try again.',
}

/** Used to get the available delivery methods to send validation code */
export const postDeliveryMethods = (body: {
  accountNumber: string
}): Promise<PostDeliveryMethodsResponse | string> =>
  post<PostDeliveryMethodsResponse>({
    url: `${LocalConstants.ApiRoot}/deliveryMethods`,
    body,
  })
    .then(data => {
      if (data.deliveryMethods.length === 0) {
        return "Sorry, we don't have a registered email or mobile number for this account. Please call to reset your password."
      }
      return data
    })
    .catch(err =>
      err.response?.status === 403
        ? 'Sorry password recovery for this account is currently disabled'
        : err.response?.status === 404
          ? 'Sorry the account number cannot be found'
          : LocalConstants.GenericError
    )

/** Used to trigger sending of validation code if delivery method chosen */
export const postGenerateValidationCode = (
  body: PostGenerateValidationCodeBody
): Promise<PostGenerateValidationCodeReturn> =>
  post<PostGenerateValidationCodeResponse>({
    url: `${LocalConstants.ApiRoot}/generateVerificationCode`,
    body,
  })
    .then(res => {
      if (!!res.expires && !!res.key) {
        return { expires: res.expires, key: res.key }
      }
      return LocalConstants.GenericError
    })
    .catch(() => {
      return LocalConstants.GenericError
    })

/** Used to validate code sent to delivery method */
export const postValidateCode = async (body: PostValidateCodeBody): Promise<ApiReturn> => {
  try {
    await post({ url: `${LocalConstants.ApiRoot}/ValidateCode`, body })
    return { isSuccessful: true }
  } catch (err: unknown) {
    if (isErrorResponse(err)) {
      if (err.response.status !== 403) return LocalConstants.GenericError
      const errorRes = await err.response.json?.()
      if (errorRes.codeIncorrect) return 'Sorry the code you have provided is incorrect'
      if (errorRes.codeExpired) return 'Sorry this code has expired'
    }
    return LocalConstants.GenericError
  }
}

/** Used to change the password */
export const postChangePassword = async (body: PostChangePasswordBody): Promise<ApiReturn> => {
  try {
    const res = await post<PostChangePasswordResponse>({
      url: `${LocalConstants.ApiRoot}/ChangePassword`,
      body,
    })
    if (isPasswordChangeSuccessResponse(res)) return { isSuccessful: true }
    if (res.errorCode && res.errorCode === 257) return 'Verification code provided has expired'
    return LocalConstants.GenericError
  } catch (err: unknown) {
    if (isErrorResponse(err)) {
      if (err.response.status === 400) {
        const errorRes = await err.response.json?.()
        if (errorRes.errorMessage) return errorRes.errorMessage
      }
    }
    return LocalConstants.GenericError
  }
}

// =============
// Local Helpers
// =============

function isErrorResponse(err: unknown): err is ErrorResponse {
  return !!(err && typeof err === 'object' && 'response' in err)
}

function isPasswordChangeSuccessResponse(
  res: PostChangePasswordResponse
): res is PostChangePasswordResponseSuccess {
  return 'id' in res
}

// =====
// Types
// =====

type ApiReturn = string | { isSuccessful: true }

// Step 1
interface PostDeliveryMethodsResponse {
  accountNumber: string
  deliveryMethods: DeliveryMethod[]
}

// Step 2
interface PostGenerateValidationCodeBody {
  accountNumber: string
  deliveryMethod: DeliveryMethod
}
interface PostGenerateValidationCodeResponse {
  accountNumber: string
  deliveryMethod: DeliveryMethod
  expires: string
  key: string
}
type PostGenerateValidationCodeReturn =
  | {
      expires: string
      key: string
    }
  | string

// Step 3
interface PostValidateCodeBody {
  accountNumber: string
  code: string
  expiries: string[]
  keys: string[]
  providedCodes: string[]
}
type ErrorResponse = { response: Response }

// Step 4
interface PostChangePasswordBody {
  accountNumber: string
  key: string
  plaintextPassword: string
  expires: string
  code: string
}
type PostChangePasswordResponse = PostChangePasswordResponseFail | PostChangePasswordResponseSuccess
interface PostChangePasswordResponseFail {
  errorMessage: string | null
  errorCode: number | null
  accountNumber: string
  key: string
  code: string
  expires: string
}
interface PostChangePasswordResponseSuccess {
  id: string
}
