import styled from '@emotion/styled'

export const ListUnstyled = styled('ul')({
  listStyle: 'none',
  padding: 0,
  fontSize: '1.0rem',
  lineHeight: '1.2',
  marginBlockEnd: 0,
})

export const ListStyled = styled(ListUnstyled)({
  margin: '1rem 0',
})
