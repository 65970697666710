import { ISelection } from './ISelection'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import CheckedValue from './CheckedValue'
import NumberToPosition from './NumberToPosition'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'
import { IEventAggregator } from '../../../../AppUtils/Framework/Messaging/IEventAggregator'
import EventAggregator from '../../../../AppUtils/Framework/Messaging/EventAggregator'
import CheckBoxSelectionContext from '../Processors/CheckBoxSelectionContext'
import Guard from '../../../../AppUtils/Framework/Guard'
import * as ko from 'knockout'
import { range } from '@mobi/utils'

export { CheckedValue }

export class CheckBoxSelection implements ISelection {
  static readonly MAX_COUNT = 4

  public values: ko.Computed<CheckedValue[]>
  public count: ko.PureComputed<number>

  private _checked: ko.Observable<boolean>[]
  private _lastChecked: boolean[]
  private _eventAggregator: IEventAggregator
  private _subscriptions: ko.Subscription[]
  private _modifierStateWatcher: ko.Computed<void>
  private _lastModifierState: { isBoxed: boolean; isLegIn: boolean; rovingBanker: boolean }

  constructor(
    public starter: IObservableStarter,
    public raceNumber: ObservableRaceKey,
    public bettingContext: BettingInformation,
    eventAggregator = new EventAggregator()
  ) {
    Guard.notNull(bettingContext)
    this._checked = range(CheckBoxSelection.MAX_COUNT).map(() => ko.observable(false))
    this._lastChecked = this._checked.map(v => v())
    this._lastModifierState = this.betModifierState()

    this._eventAggregator = eventAggregator

    this.values = ko.pureComputed(() => {
      const checkBoxCount = this.checkBoxCount(bettingContext)
      return range(checkBoxCount).map(
        index =>
          new CheckedValue(
            index + 1,
            this._checked[index],
            NumberToPosition.labelFor(
              this.bettingContext.selectedBetType,
              this.bettingContext,
              index + 1
            )
          )
      )
    })

    this.count = ko.pureComputed(() => this.checkBoxCount(bettingContext))

    this._subscriptions = this._checked.map((checked, k) =>
      checked.subscribe(state => {
        if (this._lastChecked[k] !== state) {
          this._lastChecked[k] = state
          this.checkBoxChanged(k + 1, state)
        }
      })
    )

    // clear all selections by side-effect if bet modifiers are toggled,
    // unless regular -> allways transition
    this._modifierStateWatcher = ko.computed(() => {
      const newState = this.betModifierState()

      if (
        this._lastModifierState.isBoxed !== newState.isBoxed ||
        this._lastModifierState.isLegIn !== newState.isLegIn ||
        this._lastModifierState.rovingBanker !== newState.rovingBanker
      ) {
        if (
          !this._lastModifierState.isBoxed &&
          !this._lastModifierState.isLegIn &&
          !this._lastModifierState.rovingBanker &&
          newState.isBoxed
        ) {
          this.transferAllwaysSelection()
        } else {
          this.clearAll()
        }
      }

      this._lastModifierState = newState
    })
  }

  public dispose() {
    this._subscriptions.forEach(s => s.dispose())
    this._modifierStateWatcher.dispose()
  }

  public setValue(value: number, checked: boolean) {
    const index = value - 1
    this._lastChecked[index] = checked
    this._checked[index](checked)
  }

  public setValues(values: number[], checked = true) {
    values
      .filter(v => v > 0 && v <= CheckBoxSelection.MAX_COUNT)
      .forEach(v => this.setValue(v, checked))
  }

  public clearAt(number: number) {
    this.setValue(number, false)
  }

  public enableAt(number: number) {
    this.setValue(number, true)
  }

  public clearAll() {
    range(1, CheckBoxSelection.MAX_COUNT + 1).forEach(number => this.clearAt(number))
  }

  private checkBoxChanged(index: number, checked: boolean) {
    if (index > 0 && index <= this.count()) {
      // Can not select roving banker if at capacity undo our change
      if (this.rovingBankerSelectedAndMaxReached(index, checked)) {
        this.clearAt(1)
        return // about nothing to see here
      }

      // Need remove the with box
      if (this.rovingBankerSelectedAndNotAtMax(checked, index)) {
        this.clearAt(2)
      }

      // Move a roving banker to a with and clear the roving banker bit. - Always a safe move.
      if (this.withSelected(checked, index)) {
        this.clearAt(1)
      }

      this._eventAggregator.publish('selection-made-command', {
        raceNumber: this.raceNumber.raceNumber(),
        starter: this.starter,
        context: new CheckBoxSelectionContext(index),
      })
    }
  }

  private checkBoxCount(bettingContext: BettingInformation) {
    if (bettingContext.isBoxed()) {
      return 1
    }
    if (bettingContext.rovingBanker()) {
      return 2
    }
    if (bettingContext.isLegIn()) {
      return 2
    }
    return bettingContext.selectedBetType().checkBoxCount()
  }

  private betModifierState() {
    return {
      isBoxed: this.bettingContext.isBoxed(),
      isLegIn: this.bettingContext.isLegIn(),
      rovingBanker: this.bettingContext.rovingBanker(),
    }
  }

  private withSelected(checked: boolean, index: number) {
    return (
      checked &&
      index === 2 &&
      (this.bettingContext.rovingBanker() || this.bettingContext.isLegIn())
    )
  }

  private rovingBankerSelectedAndNotAtMax(checked: boolean, index: number) {
    return (
      checked &&
      index === 1 &&
      (this.bettingContext.rovingBanker() || this.bettingContext.isLegIn())
    )
  }

  private rovingBankerSelectedAndMaxReached(index: number, checked: boolean) {
    return (
      this.bettingContext.rovingBanker() &&
      this.bettingContext.rovingBankersSelected() >=
        this.bettingContext.selectedBetType().checkBoxCount() - 1 &&
      index == 1 &&
      checked
    )
  }

  private transferAllwaysSelection() {
    const regularCount = this.bettingContext.selectedBetType().checkBoxCount()
    const anySelected = range(regularCount).some(i => this._checked[i]())
    this.clearAll()
    if (anySelected) {
      this.enableAt(1)
    }
  }

  public enableSiblingIfSelected(row: number, column: number) {
    if (this.selectedAt(row - 1)) {
      this.enableAt(column)
    }
  }

  public disableSiblingIfSelected(row: number, column: number) {
    if (this.selectedAt(row - 1)) {
      this.clearAt(column)
    }
  }

  public copyToSibling(row: number, column: number) {
    if (this.selectedAt(row - 1)) {
      this.enableAt(column)
    } else {
      this.clearAt(column)
    }
  }

  public anySelectedAtIndicies(indices: Array<number>): boolean {
    for (let index of indices) {
      if (this.selectedAt(index)) return true
    }
    return false
  }

  public selectedAt(index: number): boolean {
    if (index < this.count()) {
      return this.values()[index].checked()
    }
    return false
  }

  public anySelected(): boolean {
    return range(CheckBoxSelection.MAX_COUNT).some(i => this.selectedAt(i))
  }

  public onlyFirstSelected(): boolean {
    const [head, ...rest] = range(CheckBoxSelection.MAX_COUNT).map(i => this.selectedAt(i))
    return head && rest.every(r => !r)
  }
}
