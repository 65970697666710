import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { isReactNativeApp, AppVersion } from '@mobi/web-native-comms/web'
import { displayVisionNotEntitledMessage } from '../Components/VideoPlayer/helpers/useEntitlement'
import {
  trackFullScreenOff,
  trackFullScreenOn,
  trackSelectedChannel,
  trackVisionClosed,
  trackVisionMute,
  trackVisionUnMute,
} from '../analytics'

const skyVideoPlayerInitialState: SkyVideoPlayerState = {
  selectedChannel: 'Sky1',
  playerStatus: 'floating',
  previousPlayerStatus: 'floating',
  isPlayerOpen: false,
  isPlaying: false,
  isMuted: true,
  isFullscreen: false,
  isControlShowing: false,
  isEntitled: null,
  dockedPlayerHeight: null,

  isPendingBetsDrawerOpen: false,
  canRenderPendingBetsDrawer: false,
}

const skyVideoPlayerSlice = createSlice({
  name: 'sky-video-player',
  initialState: skyVideoPlayerInitialState,
  reducers: {
    setIsFullscreen(state, { payload: isFullscreen }: PayloadAction<boolean>) {
      isFullscreen ? trackFullScreenOn() : trackFullScreenOff()
      state.isFullscreen = isFullscreen
    },

    setIsPendingBetsDrawerOpen(
      state,
      { payload: isPendingBetsDrawerOpen }: PayloadAction<boolean>
    ) {
      state.isPendingBetsDrawerOpen = isPendingBetsDrawerOpen
    },

    setCanRenderPendingBetsDrawer(
      state,
      { payload: isEnoughRoomToRender }: PayloadAction<boolean>
    ) {
      state.canRenderPendingBetsDrawer = isEnoughRoomToRender
    },

    setIsPlaying(state, { payload: isPlaying }: PayloadAction<boolean>) {
      state.isPlaying = isPlaying
      if (!isPlaying) {
        state.isFullscreen = false
      }
    },

    setIsMuted(state, { payload: isMuted }: PayloadAction<boolean>) {
      isMuted ? trackVisionMute() : trackVisionUnMute()
      state.isMuted = isMuted
    },

    setSelectedChannel(
      state,
      { payload: selectedChannel }: PayloadAction<SkyVideoPlayerState['selectedChannel']>
    ) {
      if (state.selectedChannel === selectedChannel) return
      trackSelectedChannel(selectedChannel)

      state.selectedChannel = selectedChannel
      state.isPlaying = true
    },

    setIsControlShowing(state, { payload: isControlShowing }: PayloadAction<boolean>) {
      state.isControlShowing = isControlShowing
    },

    setEntitlementStatus(
      state,
      { payload }: PayloadAction<{ isEntitled: boolean; notEntitledMessage?: string }>
    ) {
      state.isEntitled = payload.isEntitled
      if (!payload.isEntitled) {
        state.notEntitledMessage = payload.notEntitledMessage
      }
    },

    setSkyVideoPlayerStatus(state, { payload }: PayloadAction<SetSkyVideoPlayerStatusOptions>) {
      if (state.isEntitled === false) {
        displayVisionNotEntitledMessage(state.notEntitledMessage || '')
        return
      }

      const newPreviousPlayerStatus = state.playerStatus
      state.playerStatus = payload.playerStatus || state.previousPlayerStatus
      state.previousPlayerStatus = newPreviousPlayerStatus

      state.isPlayerOpen = true
      state.isPlaying = true
      state.isMuted = payload.isMuted || state.isMuted
      state.selectedChannel = payload.selectedChannel || state.selectedChannel
      state.isFullscreen = false
      state.isPendingBetsDrawerOpen = false
    },

    setCloseSkyVideoPlayer(
      state,
      { payload = { shouldTrack: true } }: PayloadAction<{ shouldTrack: boolean } | undefined>
    ) {
      if (state.isPlayerOpen && payload.shouldTrack) trackVisionClosed()
      state.isPlayerOpen = false
    },

    setSkyDockedPlayerHeight(state, { payload }: PayloadAction<number | null>) {
      state.dockedPlayerHeight = payload
    },

    resetSkyVideoPlayerPersistedState(state) {
      state.isPlayerOpen = skyVideoPlayerInitialState.isPlayerOpen
      state.selectedChannel = skyVideoPlayerInitialState.selectedChannel
      state.isPlaying = skyVideoPlayerInitialState.isPlaying
      state.isMuted = skyVideoPlayerInitialState.isMuted
    },
  },
})

export const {
  resetSkyVideoPlayerPersistedState,
  setIsFullscreen,
  setIsPlaying,
  setSkyVideoPlayerStatus,
  setCloseSkyVideoPlayer,
  setIsPendingBetsDrawerOpen,
  setCanRenderPendingBetsDrawer,
  setIsMuted,
  setSelectedChannel,
  setIsControlShowing,
  setEntitlementStatus,
  setSkyDockedPlayerHeight,
} = skyVideoPlayerSlice.actions

const persistKeys: StateKeys[] =
  isReactNativeApp && AppVersion && Number(AppVersion.split('.')[0]) >= 221
    ? ['isPlayerOpen', 'selectedChannel', 'isPlaying', 'isMuted']
    : []

export default persistReducer(
  {
    key: 'sky-player',
    whitelist: persistKeys,
    storage,
    debug: !PRODUCTION,
  },
  skyVideoPlayerSlice.reducer
)

// =====
// Types
// =====

export interface SkyVideoPlayerState {
  selectedChannel: 'Sky1' | 'Sky2' | 'RacingWA'
  playerStatus: 'floating' | 'docked' | 'fullscreen'
  previousPlayerStatus: 'floating' | 'docked' | 'fullscreen'
  isPlayerOpen: boolean
  isPlaying: boolean
  isMuted: boolean
  isFullscreen: boolean
  isControlShowing: boolean
  isEntitled: null | boolean
  dockedPlayerHeight: null | number
  notEntitledMessage?: string
  isPendingBetsDrawerOpen: boolean
  canRenderPendingBetsDrawer: boolean
}

type SetSkyVideoPlayerStatusOptions = Partial<
  Pick<SkyVideoPlayerState, 'playerStatus' | 'selectedChannel' | 'isMuted'>
>

type StateKeys = keyof SkyVideoPlayerState
