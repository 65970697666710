import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

export const CheckCircleAnimated = () => (
  <Animation>
    <Icon name='LineCheckCircleBroken' />
  </Animation>
)

const draw = keyframes({
  '0%': {
    strokeDashoffset: '102.75810241699219px',
    strokeDasharray: '102.75810241699219px',
  },
  '100%': {
    strokeDashoffset: '205.51620483398438px',
    strokeDasharray: '102.75810241699219px',
  },
})

export const Animation = styled.div({
  animation: `${draw} 1.3s ease-in-out 0s both`,
})
