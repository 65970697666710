import Rx from 'rx'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { declareResourceType } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'

export interface AccountDetails {
  isAuthorized: boolean
  accountNumber: string | null
}

const defaultAccountDetails: AccountDetails = {
  isAuthorized: false,
  accountNumber: null,
}

export interface AccountDetailsRecord extends TypedRecord<AccountDetailsRecord>, AccountDetails {}

export const ChangePasswordKey = 'me'
const ChangePasswordTypeKey = 'changePassword'

export const ChangePasswordRepo = declareResourceType<AccountDetailsRecord, AccountDetails>({
  typeKey: ChangePasswordTypeKey,
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 200,
  map: x => recordify<AccountDetails, AccountDetailsRecord>(x),
})

function fetchMultipleNoPromise(keys: string[]) {
  return Rx.Observable.fromPromise(
    fetchAccountDetails()
      .then(response => ({
        [ChangePasswordTypeKey]: {
          [ChangePasswordKey]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(() => ({
        keys,
        result: { [ChangePasswordTypeKey]: { [ChangePasswordTypeKey]: defaultAccountDetails } },
      }))
  )
}

export function fetchAccountDetails(): Promise<AccountDetails> {
  return apiService.get<AccountDetails>({ url: '/$_/api/Authentication/getAccountDetails' })
}

interface ChangeTemporaryPasswordDesktopRequest {
  betAccountId: string
  existingPassword: string
  newPassword: string
}

interface ChangePasswordRequest {
  accountNumber: string
  existingPassword: string
  newPassword: string
  confirmPassword: string
}

export interface ChangePasswordResponse {
  isChangePasswordSuccess: boolean
  error: string
}

export function changeTemporaryPasswordDesktopSubmit(
  model: ChangeTemporaryPasswordDesktopRequest
): Promise<ChangePasswordResponse> {
  return apiService.post<ChangePasswordResponse>({
    url: '/api/password/changeTemporary',
    body: model,
  })
}

export function changePasswordSubmit(
  model: ChangePasswordRequest,
  isDesktop: boolean
): Promise<ChangePasswordResponse> {
  return isDesktop
    ? apiService.post<ChangePasswordResponse>({ url: '/api/password/change', body: model })
    : apiService.post<ChangePasswordResponse>({
        url: '/$_/api/Authentication/ChangePassword',
        body: model,
      })
}
