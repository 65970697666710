import React from 'react'

import {
  RadialCountdownContainerStyled,
  RadialCountdownWrapStyled,
  RadialCountdownTextStyled,
  RadialCountdownArcStyled,
  RadialCountdownArcBackgroundStyled,
  RadialCountdownArcFillStyled,
} from './RadialCountdown.styles'

interface RadialCountdownProps {
  progress: number
  urgency: 'high' | 'medium' | 'low'
  children?: React.ReactNode
  sizeInPx?: number
}

export function RadialCountdown({
  progress,
  children,
  urgency,
  sizeInPx = 80,
}: RadialCountdownProps) {
  return (
    <RadialCountdownContainerStyled role='timer'>
      <Progress progress={progress} urgency={urgency} sizeInPx={sizeInPx}>
        {children}
      </Progress>
    </RadialCountdownContainerStyled>
  )
}

type ProgressProps = Required<Omit<RadialCountdownProps, 'children'>> &
  Pick<RadialCountdownProps, 'children'>

function Progress({ progress, urgency, children, sizeInPx }: ProgressProps) {
  const center = sizeInPx / 2
  const strokeWidth = sizeInPx * 0.1
  const radius = sizeInPx / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  const offset = ((100 - progress) / 100) * circumference

  return (
    <RadialCountdownWrapStyled>
      <RadialCountdownArcStyled
        width={sizeInPx}
        height={sizeInPx}
        viewBox={`0 0 ${sizeInPx} ${sizeInPx}`}
      >
        <RadialCountdownArcBackgroundStyled
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <RadialCountdownArcFillStyled
          urgency={urgency}
          offset={offset}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
        />
      </RadialCountdownArcStyled>
      <RadialCountdownTextStyled>{children}</RadialCountdownTextStyled>
    </RadialCountdownWrapStyled>
  )
}
