import React from 'react'

import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Money } from '@core/Components/Text/Money'

import {
  CloseTimeStyled,
  PoolNameStyled,
  PoolTotalStyled,
  SinglePoolStyled,
} from '../TippingPage.styles'
import { getDisplayTime } from '../helpers/getDisplayTime'

interface SingleOpenPoolProps {
  name: string
  nameSuffix: string
  numberOfGames: number
  scheduledCloseTime: Date
  grossJackpot?: number
  total: number
}

export function SingleOpenPool({
  name,
  nameSuffix,
  numberOfGames,
  scheduledCloseTime,
  grossJackpot,
  total,
}: SingleOpenPoolProps): JSX.Element {
  return (
    <SinglePoolStyled>
      <Grid>
        <GridRow>
          <GridCell width='40%' align='left' valign='middle'>
            <PoolNameStyled data-tid-tote-sport-pool-name=''>{`${name} ${nameSuffix} ${numberOfGames}`}</PoolNameStyled>
          </GridCell>
          <GridCell width='30%' align='left' valign='middle'>
            <CloseTimeStyled data-tid-tote-sport-pool-closetime=''>
              {getDisplayTime(scheduledCloseTime)}
            </CloseTimeStyled>
          </GridCell>
          <GridCell width='10%' align='center' valign='middle'>
            {!!grossJackpot && (
              <Icon type='jackpot' color='#f2a900' data-testid='tote-sport-pool-jackpot-icon' />
            )}
          </GridCell>
          <GridCell width='20%' align='right' valign='middle'>
            <PoolTotalStyled data-tid-tote-sport-pool-total=''>
              <Money amount={total} decimalPlaces={0} />
            </PoolTotalStyled>
          </GridCell>
        </GridRow>
      </Grid>
    </SinglePoolStyled>
  )
}
