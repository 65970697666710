import { BettingInformation } from '../../../Model/BettingInformation'
import * as ko from 'knockout'
import Guard from '../../../../AppUtils/Framework/Guard'

export class BoxedViewModel {
  constructor(params: { context: { bettingContext: BettingInformation } }) {
    Guard.notNull(params.context.bettingContext)
    this.bettingContext = params.context.bettingContext
    this.cssClass = ko.pureComputed<string>(() => {
      if (this.bettingContext.isBoxed()) {
        return 'orange'
      } else {
        return ''
      }
    })
  }

  public toggle() {
    this.bettingContext.toggleBoxed()
  }

  public bettingContext: BettingInformation
  public cssClass: ko.PureComputed<string>
}
