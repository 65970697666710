import React from 'react'
import dayjs from 'dayjs'
import {
  LocationTimeStyled,
  RaceNumberStyled,
  ScratchedAcceptorStyled,
  SearchResultIconStyled,
  SearchResultRaceStatusStyled,
  SearchResultASTTimerSTyled,
  SearchLocationNameStyled,
} from './LocationTime.styles'
import { getAstDetails } from './utils'
import { MetaDataHighlight } from '@core/Data/Search/Search'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import { HighlightDisplay } from './HighlightDisplay'
import { RacingCode } from '@core/Data/StructuredSearch/StructuredSearchResult'

export interface LocationTimeProps {
  isSport: boolean
  locationName: string
  raceNumber?: number
  code?: RacingCode
  advertisedStartTime?: string
  currentDateMilli: number
  status?: string
  highlightKey: string
  highlights: MetaDataHighlight
  scratchedStatus?: string
}

export function LocationTime({
  isSport,
  locationName,
  raceNumber,
  code,
  advertisedStartTime,
  currentDateMilli,
  status,
  highlightKey,
  highlights,
  scratchedStatus,
}: LocationTimeProps) {
  const statusJsx = scratchedStatus ? (
    <ScratchedAcceptorStyled>{scratchedStatus}</ScratchedAcceptorStyled>
  ) : status ? (
    <SearchResultRaceStatusStyled>{status}</SearchResultRaceStatusStyled>
  ) : null

  let astJsx: JSX.Element | null = null
  if (advertisedStartTime) {
    const astDetails = getAstDetails(advertisedStartTime, currentDateMilli)
    const formattedDate = dayjs(advertisedStartTime).format('DD MMM hh:mm A')
    astJsx = (
      <SearchResultASTTimerSTyled>
        {astDetails.showDay ? (
          <span>{formattedDate}</span>
        ) : (
          <RaceCountdown raceStartTime={astDetails.advertisedStartTime} displaySize='1rem' />
        )}
      </SearchResultASTTimerSTyled>
    )
  }

  const bottomRow = (
    <>
      {statusJsx}
      {astJsx}
    </>
  )

  return (
    <LocationTimeStyled>
      {bottomRow}
      <SearchLocationNameStyled data-tid-search-location={locationName}>
        <HighlightDisplay
          displayText={locationName}
          highlightKey={highlightKey}
          highlights={highlights}
        />
        {!isSport ? <RaceLocation raceNumber={raceNumber} code={code} /> : null}
      </SearchLocationNameStyled>
    </LocationTimeStyled>
  )
}

type RaceLocationProps = Pick<LocationTimeProps, 'raceNumber' | 'code'>

export function RaceLocation({ raceNumber, code }: RaceLocationProps) {
  let codeIcon

  switch (code) {
    case RacingCode.Races:
      codeIcon = <Icon type='races' />
      break
    case RacingCode.Dogs:
      codeIcon = <Icon type='dog' />
      break
    case RacingCode.Trots:
      codeIcon = <Icon type='trot' />
      break
    default:
      codeIcon = null
  }

  const raceNumberString: string = raceNumber ? `R${raceNumber}` : ''

  return (
    <>
      <RaceNumberStyled>{raceNumberString}</RaceNumberStyled>{' '}
      <SearchResultIconStyled>{codeIcon}</SearchResultIconStyled>
    </>
  )
}
