import * as ko from 'knockout'
import { BettingClosedViewModel } from './BettingClosedViewModel'
import template from './betting.closed.tpl.html'

if (!ko.components.isRegistered('betting-closed')) {
  ko.components.register('betting-closed', {
    viewModel: BettingClosedViewModel,
    template,
  })
}
