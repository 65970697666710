import React from 'react'
import * as immutable from 'immutable'
import { Observable } from 'rx'
import { isFobPropositionSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { Grid } from '@mobi/component-library/Common/Grid'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ as betslipState$, BetslipItem } from '../../driver'
import {
  ToggleBonusCashUsage,
  EditBetslipItem,
  QuickbetClosed,
  SetActiveInvestment,
  SetBonusBet,
  ToggleBonusBetUsage,
} from '@core/Areas/Quickbet/signals'
import { Quickbet } from '@core/Areas/Quickbet/Quickbet'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { InvestmentType } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { FobInvestment } from './FobInvestment'
import { ToteInvestment } from './ToteInvestment'
import { isFatalErrorType, isSpecialUsed } from '../../helpers/state'
import { store } from '@core/Store'
import { openModal } from '@core/Components/Modal'

interface BetInvestmentState {
  items: immutable.List<BetslipItem>
  isEditable: boolean
  isLoggedIn: boolean
}

export interface EditBetslipItemArgs {
  item: BetslipItem
  investmentType: InvestmentType
  isEditable: boolean
  items: immutable.List<BetslipItem> | null
}

// This function is run to edit a betslip item
const editBetslipItem = ({
  item,
  investmentType = InvestmentType.Win,
  isEditable,
  items,
}: EditBetslipItemArgs) => {
  if (!isFatalErrorType(item.betErrorType) && isEditable) {
    if (item.investment.win.isBonusBet) {
      SetActiveInvestment(InvestmentType.Win)
    } else if (item.investment.place.isBonusBet) {
      SetActiveInvestment(InvestmentType.Place)
    } else {
      SetActiveInvestment(investmentType)
    }

    EditBetslipItem(item)
    let shouldShowSuperPicks = !(
      isFobPropositionSelection(item.selection) && item.selection.marketCode === 'CON'
    )
    if (items !== null) {
      shouldShowSuperPicks = !isSpecialUsed(item, items)
    }
    if (item.investment.bonusBet) {
      ToggleBonusBetUsage()
      SetBonusBet(item.investment.bonusBet)
    }

    ToggleBonusCashUsage(item.isUsingBonusCash ?? true)

    store.dispatch(
      openModal({
        id: QUICKBET_MODAL_ID,
        modalComponent: Quickbet,
        modalComponentProps: {
          shouldShowSuperPicks,
        },
        onCloseCallback: QuickbetClosed,
        color: 'dark',
      })
    )
  }
}

interface BetInvestmentComponentProps {
  item: BetslipItem
  isEditable: boolean
  isLoggedIn: boolean
  items: immutable.List<BetslipItem>
}

export function BetInvestmentComponent({
  item,
  isEditable,
  isLoggedIn,
  items,
}: BetInvestmentComponentProps) {
  if (!item.selection || isFatalErrorType(item.betErrorType)) {
    return null
  }

  return (
    <section>
      {!item.receipt && (
        <Grid data-tid-investment-container=''>
          {isToteSelection(item.selection) ? (
            <ToteInvestment item={item} isEditable={isEditable} editBetslipItem={editBetslipItem} />
          ) : (
            <FobInvestment
              item={item}
              isLoggedIn={isLoggedIn}
              isEditable={isEditable}
              editBetslipItem={editBetslipItem}
              items={items}
            />
          )}
        </Grid>
      )}
    </section>
  )
}

const state$ = Observable.combineLatest(
  betslipState$,
  userAccountState$,
  (betslipStateRecord, userAccountRecord): BetInvestmentState => ({
    items: betslipStateRecord.items,
    isEditable: !betslipStateRecord.hasProposed && !betslipStateRecord.isBusy,
    isLoggedIn: !!userAccountRecord.isLoggedIn,
  })
)

type BetInvestmentProps = Pick<BetslipItem, 'id'>

export const BetInvestment = observeImmutable<BetInvestmentState, BetInvestmentProps>(
  state$,
  ({ record: { items, isEditable, isLoggedIn }, id }) => {
    const item = items.find(betInvestmentItem => betInvestmentItem.id === id)
    if (!item) {
      return null
    }
    return (
      <BetInvestmentComponent
        item={item}
        isEditable={isEditable}
        isLoggedIn={isLoggedIn}
        items={items}
      />
    )
  }
)
