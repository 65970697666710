import type { PaymentMethod } from '@mobi/api-types'
import { isCardValid } from '@mobi/component-library/Deposit/helpers'

/**
 * Sort cards in the following order:
 *
 * - Default card
 * - Valid cards
 * - Invalid cards
 */
export function sortCardsByDefaultThenValidity(card1: PaymentMethod, card2: PaymentMethod) {
  const card1Score = Number(card1.isDefault) + Number(isCardValid(card1))
  const card2Score = Number(card2.isDefault) + Number(isCardValid(card2))

  return card2Score - card1Score
}
