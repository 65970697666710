import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

export function JackpotLoading() {
  return (
    <Grid>
      <GridCell width='3em' align='center'>
        <LoadingPlaceholder width='3em' height='3em' />
        <LoadingPlaceholder width='100%' height='auto' />
      </GridCell>
      <GridCell width='3em' align='center'>
        <LoadingPlaceholder width='2rem' height='2rem' />
      </GridCell>
      <GridCell>
        <LoadingPlaceholder width='150px' height='auto' />
        <LoadingPlaceholder width='150px' height='auto' />
        <LoadingPlaceholder width='150px' height='auto' />
      </GridCell>
    </Grid>
  )
}
