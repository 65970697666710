import { RouteStack } from '@core/State/Navigation'
import { registerStackHandler } from '@core/State/Navigation/helpers/stackHandler'
import { KAMBI_BETHISTORY_HASH_ROUTE, KAMBI_HOME_HASH_ROUTE, KAMBI_ROOT_ROUTE } from './constants'

export { KambiClient, KambiRouteEffects } from './KambiClient'
export { useKambiClient } from './hooks/useKambiClient'

// Init Route Stack Handler
registerStackHandler(`#${KAMBI_ROOT_ROUTE}`, {
  topLevelRoute: KAMBI_HOME_HASH_ROUTE,
  handler(newHashRoute, currentStack) {
    // Fix back button on bethistory bug (KAM-587)
    if (newHashRoute.includes(KAMBI_BETHISTORY_HASH_ROUTE)) {
      const stackWithNoBethistory = currentStack.filter(
        route => !route.includes(KAMBI_BETHISTORY_HASH_ROUTE)
      ) as RouteStack
      stackWithNoBethistory.push(newHashRoute)
      return stackWithNoBethistory
    }

    return false
  },
})
