import { useQuery, UseQueryResult } from 'react-query'
import { VideoPreview, VideoPreviewResponse } from '@core/Areas/Racing/Hooks/RaceContent/types'
import { getVideoPreviews } from './api'
import { queryKeys } from '@core/Data/ReactQuery/constants'

type UseRaceContent = Pick<UseQueryResult, 'isLoading' | 'isError'> & {
  content?: VideoPreview
}

export function useVideoPreview(
  meetingId: string,
  meetingDate: Date,
  raceNumber: number
): UseRaceContent {
  const queryKey = [queryKeys.videoPreview, meetingDate, meetingId]
  const queryFn = () => getVideoPreviews(meetingDate, meetingId)
  const { isLoading, isError, data } = useQuery<VideoPreviewResponse, Error>(queryKey, queryFn, {
    staleTime: 1000 * 60 * 60, // 1hr
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false, // prevent retry of failed responses
  })

  const content = data && new Map<string, VideoPreview>(Object.entries(data))

  return {
    isLoading,
    isError,
    content: content?.get(raceNumber.toString()),
  }
}
