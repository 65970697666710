import React from 'react'
import { useDispatch } from 'react-redux'
import * as QueryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { dayjs } from '@mobi/utils'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { useAppSelector } from '@core/Store/hooks'
import { setMeetingsRaceCode } from '@core/Areas/Meetings/Store'
import { useMeetingsQuery } from '@core/Areas/Racing/Hooks/useMeetings/useMeetingsQuery'

export const useMeetingsData = () => {
  const dispatch = useDispatch()
  const { date: entryDate, code: entryCode } = QueryString.parse(useLocation().search) as {
    date: string
    code: RaceCodesLowerCase
  }
  const raceCode = useAppSelector(rootState => rootState.meetings.currentCodeTab)

  const [currentDate, setCurrentDate] = React.useState(() => cleanDate(entryDate))

  // Capture search params with code
  React.useEffect(() => {
    if (!entryCode) return
    dispatch(setMeetingsRaceCode(cleanRaceCode(entryCode)))
    window.history.replaceState('', document.title, '/#tote')
  }, [entryCode, dispatch])

  const { isLoading, isError, data, refetch } = useMeetingsQuery(currentDate)

  const meetingsData = data ? data[currentDate] : undefined

  const setDate = React.useCallback((date: Date | string) => setCurrentDate(cleanDate(date)), [])

  return {
    data: {
      isLoading,
      isError,
      refetch,
      dates: meetingsData?.dates,
      meetings: meetingsData?.meetings,
      fixedProducts: meetingsData?.fixedProducts,
    },
    handlers: {
      setDate,
    },
    state: {
      raceCode,
      currentDate,
    },
  }
}

// =============
// Local Helpers
// =============

const cleanDate = (date: string | Date): string =>
  !date || !dayjs(date).isValid()
    ? dayjs(Date.now()).utcOffset('+0800').format('YYYY-MM-DD')
    : dayjs(date).format('YYYY-MM-DD')

const cleanRaceCode = (code: RaceCodesLowerCase): RaceCodesLowerCase => {
  const validCodes: RaceCodesLowerCase[] = ['races', 'trots', 'dogs']
  return validCodes.includes(code?.toLowerCase() as unknown as RaceCodesLowerCase) ? code : 'races'
}
