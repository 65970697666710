import { HOME_CSS_SHARED_VALUES } from '@core/Areas/Home/Home.styles'
import { SilkImageStyled } from '@core/Components/SilkImage/SilkImage.styles'
import { fontFamilies } from '@core/Settings'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const silkHeight = 13

export const TrendingBetCard = styled('div')({
  background: hexColors.white,
  boxShadow: HOME_CSS_SHARED_VALUES.boxShadow,
  color: hexColors.black,
  borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
  alignItems: 'center',
  fontFamily: fontFamilies.default,
  fontSize: '1.2rem',
  fontWeight: 'normal',
  lineHeight: '1.3rem',
  margin: '0',
  marginRight: '1rem',
  padding: '0',
  position: 'relative',
  textAlign: 'left',
  textDecoration: 'none',
  verticalAlign: 'middle',
  width: '19.7rem',
  height: '16.8rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

// HEADER
export const TrendingBetCardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '.7rem .7rem 0 0',
  backgroundColor: '#eee',
  width: '19.7rem',
  height: '2.8rem',
  padding: '.6rem .96rem .6rem .4rem',
  boxSizing: 'border-box',
})

export const TrendingBetCardHeaderLink = styled('a')({
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#512D6D',
  lineHeight: '1.7rem',
  marginLeft: '.7rem',
  width: '10.9rem',
  maxWidth: '10.9rem',
  textDecoration: 'none',
  flex: '1',
})

// BODY
export const TrendingBetCardBody = styled('div')({
  height: '11rem',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '1.3rem',
  padding: '10px 10px 0 10px',
})

export const TrendingBetCardAcceptor = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: 'white',
  height: '1.8rem',
  width: '17.7rem',
  color: '#888A84',
})

export const TrendingBetCardSilkAndName = styled('div')({
  display: 'flex',
})

export const TrendingBetCardSilkImage = styled(SilkImageStyled)({
  display: 'inline-block',
})

export const TrendingBetCardGreyhoundsSilkImage = styled(TrendingBetCardSilkImage)({
  color: 'red',
})

export const TrendingBetCardSameRaceMultiAcceptorName = styled('span')({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  marginLeft: '10px',
  maxWidth: '12rem',
})

export const TrendingBetCardSameRaceMultiAcceptorPosition = styled('span')({
  display: 'inline-block',
})

// FOOTER
export const TrendingBetCardFooter = styled('div')({
  height: '5rem',
  fontWeight: '700',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  textAlign: 'center',
  width: '17.7rem',
  padding: '1rem',
})

export const TrendingBetCardQuickbetButton = styled('a')({
  backgroundColor: '#689F38',
  color: 'white',
  width: '16rem',
  display: 'inline-block',
  borderRadius: '.5rem',
  padding: '1rem',
  textDecoration: 'none',
})
