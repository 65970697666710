import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { toTitleCase } from '@mobi/utils/string'
import { SkyTvSvg } from '@mobi/component-library/Common/Svg'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import { isThoroughbredNextRaceInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import { useFeature } from '@core/Utils/hooks'

export const SkyRaceHeader: React.FC<{ nextSkyRace: NextSkyRace }> = ({ nextSkyRace }) => {
  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')

  const { RaceNumber, MeetingName, SkyTvChannelId, AdvertisedStartTime } = nextSkyRace

  const shouldDisplayRacingWA = isRacingWAFeatureActive && isThoroughbredNextRaceInWA(nextSkyRace)

  return (
    <HeaderStyled>
      <div>
        <strong data-testid='SkyRaceHeader.RaceNumber'>R{RaceNumber}</strong>

        <span data-testid='SkyRaceHeader.MeetingName'>{toTitleCase(MeetingName)}</span>
      </div>

      <div>
        <StatusBadge>
          <SkyTvSvg
            channel={getVisionChannelForDisplay(SkyTvChannelId as string, shouldDisplayRacingWA)}
            width='3rem'
          />
        </StatusBadge>

        <span data-testid='SkyRaceHeader.StartTime'>
          <CountdownBadge advertisedStartTime={new Date(AdvertisedStartTime)} />
        </span>
      </div>
    </HeaderStyled>
  )
}

// ======
// Styles
// ======

const HeaderStyled = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 0',
  margin: '0 1rem',
  borderBottom: '0.5px solid ' + colors.neutral[200],

  '> div': {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
  },

  '> div:first-of-type': {
    paddingRight: spacing.smx2,
    fontFamily: font.family.primary,
    fontSize: font.size.sm.fontSize,
    letterSpacing: font.size.sm.letterSpacing,
    lineHeight: font.size.sm.lineHeight,
    fontWeight: font.weight.semibold,

    overflow: 'hidden',

    '> span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },

  '> div:last-of-type': {
    '> span': {
      alignSelf: 'stretch',
    },
  },
})
