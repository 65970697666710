import styled from '@emotion/styled'
import { hideVisually } from 'polished'
import { hexColors } from '@mobi/settings'
import { Button } from '@mobi/component-library/Common/Buttons'

const rewardTextHeight = '2.4rem'

export const SuperPickStyled = styled('div')({
  background: hexColors.whiteSmoke,
})

export const HeaderStyled = styled('strong')({
  textAlign: 'center',
  fontSize: '1.2rem',
  lineHeight: 1.6,
  position: 'relative',
  paddingBottom: '0.7rem',
  display: 'block',

  '&::after': {
    borderTop: `1px solid ${hexColors.veryLightGrey}`,
    content: '" "',
    display: 'block',
    position: 'relative',
    top: '-0.8em',
    width: '100%',
    zIndex: 0,
  },
})

export const HeaderBg = styled('span')({
  background: hexColors.whiteSmoke,
  position: 'relative',
  zIndex: 1,
  padding: '0 1rem',
})

export const HeaderIconStyled = styled('span')({
  marginRight: '0.5rem',
  color: hexColors.orange,
})

export const SuperPickButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '-0.2rem',
  flexWrap: 'wrap',

  '& > :first-of-type': {
    width: '100%',
  },

  '& > :not(:first-of-type)': {
    flex: '1',
  },
})

export const SuperButtonCellStyled = styled('div')({
  textAlign: 'left',
  padding: '0.2rem',
})

interface SuperPickButtonStyledProps {
  isSelected?: boolean
  shouldDisplayReward?: boolean
  isInsuranceOffer?: boolean
}

export const SuperPickButtonStyled = styled(Button)<SuperPickButtonStyledProps>(
  {
    backgroundColor: hexColors.white,
    height: '4.5rem',
    padding: '0 0.5rem',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    textAlign: 'center',

    '&:focus': {
      outline: 'none',
    },

    '&::before': {
      background: hexColors.orange,
      content: '" "',
      height: '100%',
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      zIndex: 0,
      transform: 'translateY(100%)',
    },
  },

  ({ isSelected, shouldDisplayReward, isInsuranceOffer }) => ({
    marginBottom: shouldDisplayReward ? 0 : rewardTextHeight,
    borderColor: isInsuranceOffer ? hexColors.orange : undefined,

    '&::before': {
      transform: isSelected ? 'translateY(0%)' : 'translateY(100%)',
      transitionDuration: isSelected ? '800ms' : '0s',
    },
  })
)

const gradientColorStop1 = hexColors.orange
const gradientColorStop2 = hexColors.alizarin

export const SuperBoostButtonStyled = styled(Button)<SuperPickButtonStyledProps>(
  {
    backgroundColor: hexColors.white,
    height: '4.5rem',
    padding: '0 0.5rem',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    border: `1px solid ${gradientColorStop1}`,

    '&:focus': {
      outline: 'none',
    },

    '&::before': {
      backgroundImage: `linear-gradient(to right,
        ${gradientColorStop1},
        ${gradientColorStop1},
        ${gradientColorStop2})`,
      content: '" "',
      height: '100%',
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      zIndex: 0,
      transform: 'translateY(100%)',
    },
  },

  ({ isSelected, shouldDisplayReward, isInsuranceOffer }) => ({
    marginBottom: shouldDisplayReward ? 0 : rewardTextHeight,
    borderColor: isInsuranceOffer ? hexColors.orange : undefined,

    '&::before': {
      transform: isSelected ? 'translateY(0%)' : 'translateY(100%)',
      transitionDuration: isSelected ? '800ms' : '0s',
    },
  })
)

export const SuperPickButtonTextStyled = styled('strong')({
  display: 'block',
  position: 'relative',
  zIndex: 1,
})

export const SuperPickButtonSubTextStyled = SuperPickButtonTextStyled.withComponent('span')

export const SuperPickButtonSelectedTextStyled = styled('em')({
  ...hideVisually(),
})

interface RewardStyledProps {
  isSelected?: boolean
}

export const RewardStyled = styled.div<RewardStyledProps>(
  {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    alignItems: 'center',
    display: 'flex',
    height: rewardTextHeight,
  },

  ({ isSelected }) => ({
    color: isSelected ? hexColors.black : hexColors.grey,
  })
)

export const RewardInnerStyled = styled.div({
  margin: '0 auto',
  textAlign: 'center',
})
