import styled from '@emotion/styled'

export interface BaseButtonProps {
  isDesktop?: boolean
}

export const BaseButtonStyled = styled('button')<BaseButtonProps>(({ isDesktop }) => ({
  border: '0.1rem solid transparent',
  boxSizing: 'border-box',
  display: 'inline-block',
  fontFamily: 'inherit',
  lineHeight: 1,
  fontSize: isDesktop ? '1.4rem' : '1.2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  verticalAlign: 'middle',
  padding: '0.8rem 1rem',
  transition: 'background-color 200ms',
  touchAction: 'manipulation',
}))
