import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import type { DepositLimitFrequency } from '@mobi/api-types'
import type { IdentityVerificationMethod } from './Old/IdentityConfirmation/SignUpVerificationOptions'

export const trackSignUpClick = (location: 'header' | 'login-modal' | 'hamburger') =>
  trackEvent(keys.signUpClicked, {
    location,
  })

export const trackSignUpStart = () => trackKey(keys.signUpStart)

export const trackSignUpJoin = () => trackKey(keys.signUpJoin)

export const trackSignUpVerifyAddress = (addressMethod: 'auto' | 'manual') =>
  trackEvent(keys.signUpVerifyAddress, { addressMethod })

export const trackSignUpCreatePassword = (
  previousStep: 'verified-address' | 'verified-identification'
) =>
  trackEvent(keys.signUpCreatePassword, {
    previousStep,
  })

export const trackSignUpDepositLimits = (
  depositFrequency: DepositLimitFrequency,
  limitAmount: string,
  skipped: boolean
) =>
  trackEvent(keys.signUpDepositLimits, {
    depositFrequency: depositFrequency ?? 'None',
    limitAmount,
    skipped,
  })

export const trackSignUpComplete = () => trackKey(keys.signUpComplete)

export const trackSignUpDepositClick = () => trackKey(keys.signUpDepositClick)

export const trackVerificationSelectOption = (
  optionSelected: IdentityVerificationMethod,
  repeatAttempt: number
) =>
  trackEvent(keys.verificationSelectOption, {
    optionSelected: optionSelected ?? 'None',
    repeatAttempt,
  })

export const trackVerificationEnterIdentification = (
  optionSelected: IdentityVerificationMethod,
  verifyResult: 'success' | 'fail'
) =>
  trackEvent(keys.signUpVerificationEnterIdentification, {
    optionSelected,
    verifyResult,
  })

export const trackVerificationAccountPending = () => trackKey(keys.verificationAccountPending)

export const trackSignUpAlreadyHaveAccount = () => trackKey(keys.signUpAlreadyHaveAccount)

export const trackSignUpGoBackHomePage = (location: string) =>
  trackEvent(keys.signUpGoBackHomePage, {
    location,
  })

export const trackSignUpNeedAssistance = () => trackKey(keys.signUpNeedAssistance)

export const trackSignUpError = (location: string, message: string) =>
  trackEvent(keys.signUpError, {
    location,
    message,
  })

export const trackSignUpUnsuccessful = () => trackKey(keys.signUpUnsuccessful)

export const trackSignUpUnsuccessfulCallUs = () => trackKey(keys.signUpUnsuccessfulCallUs)
