import { get, post } from '@classic/Foundation/Services/ApiService'
import { LoadBetDetails } from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/helpers/loadBet/types'

export const postShareBetDataAndGenerateLink = (
  bets: LoadBetDetails[]
): Promise<{ message: string; link: string }> => {
  return post({
    url: '/api/sharebet/share-bet',
    body: {
      data: bets,
    },
  })
}

export const getShareBetDataWithKey = (
  key: string
): Promise<{ message: string; key: string; data: LoadBetDetails[]; accountNumber: string }> => {
  return get({
    url: `/api/sharebet/share-bet?key=${key}`,
  })
}
