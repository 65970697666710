import * as ko from 'knockout'
import { IResultsPageViewModel } from './IResultsPageViewModel'
import container from '../../../IOC/inversify.config'
import ObservableResultsPage from '../../../Model/Observables/ObservableResultsPage'
import template from './results.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('race-results')) {
    ko.components.register('race-results', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableResultsPage
        }): IResultsPageViewModel => {
          const viewModel = container.get<IResultsPageViewModel>('IResultsPageViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
