import React, { type HTMLAttributes, type VFC } from 'react'
import { BadgeStyled } from '@core/Components/Badge/Badge.styles'

type Props = {
  count: number
  showPlusIcon?: boolean
} & HTMLAttributes<HTMLSpanElement>

export const Badge: VFC<Props> = ({ count, showPlusIcon = false, ...props }) => {
  return (
    <BadgeStyled {...props} showPlusIcon={showPlusIcon}>
      {count}
      {showPlusIcon && '+'}
    </BadgeStyled>
  )
}
