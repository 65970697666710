import { IAppWindow } from '../../../AppUtils/Framework/WindowManagement/IAppWindow'
import { IObservableFinisher } from './IObservableFinisher'
import ObservableFixedOddsFinisherInfo from './ObservableFixedOddsFinisherInfo'
import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'
import { RaceTypes } from '../../Model/RaceTypes'
import { SilkImages, SilkImage } from '../SilkImages'

export abstract class ObservableFinisher implements IObservableFinisher {
  constructor() {
    // @ts-expect-error Type issue
    this.tag = ko.observable()
    // @ts-expect-error Type issue
    this.template = ko.observable()
    // @ts-expect-error Type issue
    this.number = ko.observable()
    // @ts-expect-error Type issue
    this.type = ko.observable()
    // @ts-expect-error Type issue
    this.name = ko.observable()
    // @ts-expect-error Type issue
    this.silkImages = ko.observable<SilkImages>()
    // @ts-expect-error Type issue
    this.placing = ko.observable()
    // @ts-expect-error Type issue
    this.isFormAvailable = ko.observable()
    // @ts-expect-error Type issue
    this.isScratched = ko.observable()
    // @ts-expect-error Type issue
    this.hasFixedOdds = ko.observable()
    // @ts-expect-error Type issue
    this.isScratchedToteAndFob = ko.observable()
    // @ts-expect-error Type issue
    this.isFixedOddsStarter = ko.observable()
    // @ts-expect-error Type issue
    this.isFavourite = ko.observable()
    // @ts-expect-error Type issue
    this.starterPersonName = ko.observable()
    // @ts-expect-error Type issue
    this.trainerName = ko.observable()
    // @ts-expect-error Type issue
    this.isEmergency = ko.observable()
    // @ts-expect-error Type issue
    this.emergencyNum = ko.observable()
    // @ts-expect-error Type issue
    this.emres = ko.observable()
    // @ts-expect-error Type issue
    this.hasPlacePool = ko.observable()
    // @ts-expect-error Type issue
    this.scratchType = ko.observable()
    // @ts-expect-error Type issue
    this.isCountBack = ko.observable()
    // @ts-expect-error Type issue
    this.toteWinDiv = ko.observable()
    // @ts-expect-error Type issue
    this.totePlaceDiv = ko.observable()
    // @ts-expect-error Type issue
    this.toteWinProvDiv = ko.observable()
    // @ts-expect-error Type issue
    this.totePlaceProvDiv = ko.observable()
    // @ts-expect-error Type issue
    this.displayWinDividend = ko.observable()
    // @ts-expect-error Type issue
    this.displayPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.shouldDisplayPlaceDividend = ko.observable()
    // @ts-expect-error Type issue
    this.lastFour = ko.observable()
    this.fixedOddsFinisherInfo = new ObservableFixedOddsFinisherInfo()
    // @ts-expect-error Type issue
    this.rating = ko.observable()
    // @ts-expect-error Type issue
    this.isRiderChanged = ko.observable()
    // @ts-expect-error Type issue
    this.spWinPrice = ko.observable()
  }

  merge(other: ObservableFinisher) {
    Guard.notNull(other)

    this.tag(other.tag())
    this.template(other.template())
    this.number(other.number())
    this.type(other.type())
    this.name(other.name())
    this.silkImages(other.silkImages())
    this.placing(other.placing())
    this.isFormAvailable(other.isFormAvailable())
    this.isScratched(other.isScratched())
    this.hasFixedOdds(other.hasFixedOdds())
    this.isScratchedToteAndFob(other.isScratchedToteAndFob())
    this.isFixedOddsStarter(other.isFixedOddsStarter())
    this.isFavourite(other.isFavourite())
    this.starterPersonName(other.starterPersonName())
    this.trainerName(other.trainerName())
    this.isEmergency(other.isEmergency())
    this.emergencyNum(other.emergencyNum())
    this.hasPlacePool(other.hasPlacePool())
    this.scratchType(other.scratchType())
    this.isCountBack(other.isCountBack())
    this.toteWinDiv(other.toteWinDiv())
    this.totePlaceDiv(other.totePlaceDiv())
    this.toteWinProvDiv(other.toteWinProvDiv())
    this.totePlaceProvDiv(other.totePlaceProvDiv())
    this.displayWinDividend(other.displayWinDividend())
    this.displayPlaceDividend(other.displayPlaceDividend())
    this.shouldDisplayPlaceDividend(other.shouldDisplayPlaceDividend())
    this.lastFour(other.lastFour())
    this.fixedOddsFinisherInfo.merge(other.fixedOddsFinisherInfo)
    this.isRiderChanged(other.isRiderChanged())
    this.rating(other.rating())
    this.spWinPrice(other.spWinPrice())

    this.computeEmRes()
  }

  public untypedMerge(other: IObservableFinisher) {
    this.merge(other as ObservableFinisher)
  }

  public getSilkImage(appWindow: IAppWindow): SilkImage {
    if (this.silkImages()) {
      return this.silkImages().getSilkImageFor(appWindow.innerWidth())
    }
    return new SilkImage()
  }

  public computeEmRes() {
    var emres = ''

    if (this.emergencyNum()) {
      switch (this.type()) {
        case RaceTypes[RaceTypes.Races]:
        case RaceTypes[RaceTypes.Trots]:
          emres = '(EM' + this.emergencyNum() + ')'
          break

        case RaceTypes[RaceTypes.Dogs]:
          emres = '(RES' + this.emergencyNum() + ')'
          break

        default:
          emres = '(UNKNOWN)'
      }
    }

    this.emres(emres)
  }

  public tag: ko.Observable<string>
  public template: ko.Observable<string>
  public number: ko.Observable<number>
  public type: ko.Observable<string>
  public name: ko.Observable<string>
  public silkImages: ko.Observable<SilkImages>
  public placing: ko.Observable<number>
  public isFormAvailable: ko.Observable<boolean>
  public isScratched: ko.Observable<boolean>
  public hasFixedOdds: ko.Observable<boolean>
  public isScratchedToteAndFob: ko.Observable<boolean>
  public isFixedOddsStarter: ko.Observable<boolean>
  public isFavourite: ko.Observable<boolean>
  public starterPersonName: ko.Observable<string>
  public trainerName: ko.Observable<string>
  public isEmergency: ko.Observable<boolean>
  public emergencyNum: ko.Observable<number>
  public emres: ko.Observable<string>
  public hasPlacePool: ko.Observable<boolean>
  public scratchType: ko.Observable<string>
  public isCountBack: ko.Observable<boolean>
  public toteWinDiv: ko.Observable<number>
  public totePlaceDiv: ko.Observable<number>
  public toteWinProvDiv: ko.Observable<number>
  public totePlaceProvDiv: ko.Observable<number>
  public displayWinDividend: ko.Observable<string>
  public displayPlaceDividend: ko.Observable<string>
  public shouldDisplayPlaceDividend: ko.Observable<boolean>
  public lastFour: ko.Observable<string>
  public fixedOddsFinisherInfo: ObservableFixedOddsFinisherInfo
  public rating: ko.Observable<number>
  public isRiderChanged: ko.Observable<boolean>
  public spWinPrice: ko.Observable<number>
}
