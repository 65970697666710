import { Repository, Loading, Errored, LoadingInstance } from 'rwwa-data-access'
import { useState, useEffect } from 'react'

interface DataState<T> {
  data: T | Loading | Errored
}

export function useDataAccess<T>(repository: Repository<T>, key: string): DataState<T> {
  const [state, setState] = useState<DataState<T>>({ data: LoadingInstance })
  useEffect(() => {
    const subscription = repository.data$.distinctUntilChanged().subscribe(state => {
      const newState = repository.getFrom(state, key)
      setState({ data: newState })
    })
    return () => {
      subscription.dispose()
    }
  }, [repository, key])
  return state
}
