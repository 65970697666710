import React from 'react'
import { ConfirmAllBetsSuccessful } from '@core/Areas/Betslip/signals'
import { ConfirmBetSucceeded } from '@core/Areas/Quickbet/signals'
import { queryClient } from '@core/Data/ReactQuery'
import { queryKeys } from '@core/Data/ReactQuery/constants'

/** Used primarily for ReBet, as Quickbet and Betslip can be opened over account activity */
export const useRefreshOnBetPlaced = () => {
  React.useEffect(() => {
    const clearAccountActivityCache = () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.accountBase, queryKeys.accountActivityBase],
      })

    const betslipBetPlacedSupscription =
      ConfirmAllBetsSuccessful.signal$.subscribe(clearAccountActivityCache)
    const quickbetBetPlacedSubscription =
      ConfirmBetSucceeded.signal$.subscribe(clearAccountActivityCache)

    return () => {
      quickbetBetPlacedSubscription.dispose()
      betslipBetPlacedSupscription.dispose()
    }
  }, [])
}
