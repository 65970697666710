import { fontFamiliesBold } from '@core/Settings/fonts'
import styled from '@emotion/styled'
import { colors, hexColors } from '@mobi/settings'

export const TransactionTabsStyled = styled('div')({
  height: '4.8rem',
  display: 'flex',
  background: colors.brand.primary,
})

export const TransactionTabStyled = styled('a')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 0.1rem',
  fontSize: '1.4rem',
  textDecoration: 'none',
  color: colors.text.light,
  background: hexColors.vividViolet,

  '&:first-of-type, &:last-of-type': {
    margin: 0,
  },
})

export const TransactionTabActiveStyled = styled(TransactionTabStyled)({
  pointerEvents: 'none',
  color: colors.text.dark,
  background: colors.kambi.container.background,
})

export const RacingTransactionTitleStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '4.8rem',
  padding: '0.8rem 0',
  background: colors.kambi.container.background,

  '& > h1': {
    margin: 0,
    paddingLeft: '1.5rem',
    fontFamily: fontFamiliesBold.brand,
    fontSize: '2rem',
    color: colors.brand.primary,
  },
})
