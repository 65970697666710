import styled from '@emotion/styled'
import { ButtonStyled } from '../../Common/V2/Buttons/Button'
import { colors, font, spacing, radius } from '../../Theme/Common'

export const PaymentMethodSelectionButtonStyled = styled(ButtonStyled)({
  position: 'relative',
  minHeight: '4.8rem',
  padding: `0 ${spacing.smx1}`,
  ...font.size.sm,
})

export const PaymentMethodSelectionIconStyled = styled.span({
  justifyContent: 'center',
  display: 'inline-flex',
  minWidth: '4.8rem',
})

export const PaymentMethodSelectionTextStyled = styled.span({
  display: 'inline-flex',
  flex: '1 1 auto',
  textAlign: 'left',
})

export const PaymentMethodSelectionChevronStyled = styled.span({
  display: 'inline-flex',
  marginLeft: 'auto',
})

export const NewPaymentMethodBadgeStyled = styled.span({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  right: 0,
  borderRadius: radius.smx2,
  backgroundColor: colors.error[500],
  color: colors.white,
  fontSize: '.7rem',
  fontWeight: font.weight.semibold,
  width: '3.1rem',
  height: '1.3rem',
  textTransform: 'uppercase',
})
