import { fetchFromApi, getPostParameters } from '@classic/Foundation/Services/ApiService'
import { CREATE_ACTIVITY_STATEMENT_PATH } from './constants'

export type ActivityStatementData = {
  accountNumber: number
  month: number
  year: number
  emailAddresses: string[]
}

type CreateActivityStatementResponse = void

export async function createActivityStatement(
  body: ActivityStatementData
): Promise<CreateActivityStatementResponse> {
  const response = await fetchFromApi(CREATE_ACTIVITY_STATEMENT_PATH, getPostParameters(body))
  if (response.status === 204) {
    throw new Error('No transactions')
  }
}
