import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2'
import { store } from '@core/Store'
import { colors, shadow, radius, spacing, layering } from '@mobi/component-library/Theme/Common'
import { DOCKED_EXPAND_ANIMATION_MS } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer/constants'
import { useHeaderPinnedState } from '@core/Areas/SkyVideoPlayer/Components/DockedPlayer/Hooks/useHeaderPinnedState'
import { SharedRaceSelection } from '../Common/SharedRaceSelection'
import { setIsSelectedRaceScrolledOutOfView } from '../../Store'
import type { NextSkyRace } from '../../types'
import { useNextToJumpReset } from './Hooks/useNextToJumpReset'
import { ShowMoreSkyRacesButton } from '../Common/ShowMoreSkyRacesButton'
import { createUniqueSkyRaceId } from '../../helpers'

const enum LocalConstants {
  RaceSelectedClass = 'js-sky-race-button--selected',
  ResetButtonRightClass = 'js-sky-race-reset--right',
  PinnedClassName = 'js-sky-race-select--pinned',
}

export const RaceScrollView: React.FC<{
  races: NextSkyRace[]
  currentRace: NextSkyRace
  setCurrentRace: React.Dispatch<React.SetStateAction<NextSkyRace>>
}> = ({ races, currentRace, setCurrentRace }) => {
  const { isHeaderPinned, wrapperElRef, top } = useHeaderPinnedState()
  const {
    horizontalScrollerRef,
    nextToJumpPosition,
    nextToJumpRaceId,
    onResetButtonClick,
    setNextToJumpEl,
    shouldRenderResetButtons,
  } = useNextToJumpReset({ races, currentRace, setCurrentRace })

  return (
    <WrapperStyled
      ref={wrapperElRef}
      style={{ top }}
      className={isHeaderPinned ? LocalConstants.PinnedClassName : ''}
    >
      <ul ref={horizontalScrollerRef}>
        {races.map(skyRace => {
          const isSelected =
            currentRace.AdvertisedStartTime === skyRace.AdvertisedStartTime &&
            currentRace.MeetingID === skyRace.MeetingID

          const raceId = createUniqueSkyRaceId(skyRace)

          return (
            <RaceSelectionButton
              key={raceId}
              isSelected={isSelected}
              isNextToJump={nextToJumpRaceId === raceId}
              setNextToJumpEl={setNextToJumpEl}
              scrollerRef={horizontalScrollerRef}
            >
              <SharedRaceSelection
                skyRace={skyRace}
                onClick={() => setCurrentRace(skyRace)}
                isSelected={isSelected}
              />
            </RaceSelectionButton>
          )
        })}

        {nextToJumpPosition !== 'visible' && shouldRenderResetButtons && (
          <Button
            color='secondary_outline'
            onClick={onResetButtonClick}
            data-testid='RaceScrollView.ResetButton'
            className={nextToJumpPosition === 'right' ? LocalConstants.ResetButtonRightClass : ''}
          >
            <Icon size='2rem' name='SolidChevronRightDouble' color={colors.studio[500]} />
          </Button>
        )}
        <li>
          <ShowMoreSkyRacesButton />
        </li>
      </ul>
    </WrapperStyled>
  )
}

// ===============
// Local Component
// ===============

const RaceSelectionButton: React.FC<{
  isSelected: boolean
  children: React.ReactNode
  isNextToJump: boolean
  scrollerRef: React.RefObject<HTMLUListElement>
  setNextToJumpEl: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLLIElement | null> | undefined>
  >
}> = ({ isSelected, children, isNextToJump, scrollerRef, setNextToJumpEl }) => {
  const buttonRef = React.useRef<HTMLLIElement>(null)

  React.useEffect(() => {
    if (isNextToJump) setNextToJumpEl(buttonRef)
  }, [isNextToJump, setNextToJumpEl])

  React.useEffect(() => {
    if (!isSelected || !buttonRef.current) return
    const observer = new IntersectionObserver(handleIntersectionObserver, { threshold: 0.2 })
    observer.observe(buttonRef.current)
    return () => observer.disconnect()
  }, [isSelected])

  const scrollButtonIntoView = React.useCallback(() => {
    if (!scrollerRef.current || !buttonRef.current) return
    const buttonCenter = buttonRef.current.offsetLeft + buttonRef.current.offsetWidth / 2
    const containerCenter = scrollerRef.current.offsetWidth / 2
    scrollerRef.current.scrollLeft = buttonCenter - containerCenter
  }, [scrollerRef])

  React.useEffect(() => {
    if (isSelected) setTimeout(scrollButtonIntoView, 500)
  }, [isSelected, scrollButtonIntoView])

  return (
    <li ref={buttonRef} className={isSelected ? LocalConstants.RaceSelectedClass : ''}>
      {children}
    </li>
  )
}

// =============
// Local Helpers
// =============

function handleIntersectionObserver([entry]: IntersectionObserverEntry[]) {
  if (entry.isIntersecting) {
    store.dispatch(setIsSelectedRaceScrolledOutOfView(false))
    return
  }
  return store.dispatch(setIsSelectedRaceScrolledOutOfView(true))
}

// ======
// Styles
// ======

const WrapperStyled = styled.nav({
  position: 'sticky',
  zIndex: layering.stickyPageElements,
  top: '-1px',
  transition: `top ${DOCKED_EXPAND_ANIMATION_MS}ms ease`,

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    boxShadow: shadow.md,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.4s ease',
  },

  '*': { flexShrink: 0 },

  [`&.${LocalConstants.PinnedClassName}`]: {
    background: colors.surface[50],

    '&::after': { opacity: 1 },
  },

  '> ul': {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    gap: spacing.smx1,
    padding: '0 ' + spacing.md,
    margin: 0,

    scrollbarWidth: 'thin',
    scrollBehavior: 'smooth',
    scrollbarColor: colors.neutral[300] + ' ' + colors.surface[50],

    '@media (pointer: coarse)': {
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': { display: 'none' },
    },

    // Reset button
    '> button': {
      boxSizing: 'border-box',
      position: 'absolute',
      top: '0.4rem',
      bottom: '0.4rem',
      left: 0,
      width: '4.4rem',
      borderRadius: `0 ${radius.lgx2} ${radius.lgx2} 0`,
      boxShadow: shadow.md,

      ':active, :focus': {
        backgroundColor: colors.neutral[100],
        borderColor: colors.neutral[100],
      },

      [`&.${LocalConstants.ResetButtonRightClass}`]: {
        left: 'unset',
        right: 0,
        transform: 'rotateY(180deg)',
      },
    },

    li: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 'fit-content',
      minHeight: '5.2rem',

      button: {
        boxSizing: 'border-box',
        height: '3.6rem',
        padding: '0 ' + spacing.smx1,
        borderRadius: radius.lgx1,

        ':active, :focus': {
          backgroundColor: colors.neutral[100],
          borderColor: colors.neutral[100],
        },
      },

      [`&.${LocalConstants.RaceSelectedClass} > button`]: {
        borderWidth: '0.2rem',
        borderColor: colors.studio[500],
        backgroundColor: colors.lavender[50],
      },
    },
  },
})
