import React from 'react'

import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'

import { NextEventCodeFilterButtonStyled, NextRaceFilterContainer } from '../../NextRaces.styles'
import { NextEventsFilter } from '../../utils'
import { IconTypes } from '../NextRace/NextRace'

export const NextRacesFilter: React.FC<{
  filter: NextEventsFilter
  setFilter: (filter: NextEventsFilter) => void
}> = ({ filter, setFilter }) => {
  return (
    <NextRaceFilterContainer>
      <Grid padding='0.25rem'>
        <GridRow>
          <GridCell padding='0 0.1rem 0 0' width='40%' valign='middle'>
            Filter by:
          </GridCell>
          <GridCell>
            <NextEventCodeFilterButtonStyled
              color='toggle'
              onClick={() =>
                setFilter({
                  ...filter,
                  Races: !filter.Races,
                })
              }
              selected={filter.Races}
              aria-label='races'
            >
              <Icon size='2.8rem' type={IconTypes.Races} />
            </NextEventCodeFilterButtonStyled>
          </GridCell>

          <GridCell>
            <NextEventCodeFilterButtonStyled
              color='toggle'
              onClick={() =>
                setFilter({
                  ...filter,
                  Trots: !filter.Trots,
                })
              }
              selected={filter.Trots}
              aria-label='trots'
            >
              <Icon size='2.8rem' type={IconTypes.Trots} />
            </NextEventCodeFilterButtonStyled>
          </GridCell>

          <GridCell>
            <NextEventCodeFilterButtonStyled
              color='toggle'
              onClick={() =>
                setFilter({
                  ...filter,
                  Dogs: !filter.Dogs,
                })
              }
              selected={filter.Dogs}
              aria-label='dogs'
            >
              <Icon size='2.8rem' type={IconTypes.Dogs} />
            </NextEventCodeFilterButtonStyled>
          </GridCell>
        </GridRow>
      </Grid>
    </NextRaceFilterContainer>
  )
}
