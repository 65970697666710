import React from 'react'
import type { Map } from 'immutable'
import type { Selection, EventDetails } from '@mobi/betslip/types'
import { isFobSportsDetails, isFobSportsSelection } from '@mobi/betslip/helpers/typeGuards'
import { useObservableImmutable } from '@core/Utils/hooks'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ErrorMessage } from '@core/Components/Messages'
import { state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { CountdownPillStyled } from '@core/Areas/Quickbet/Components/Common.styles'
import {
  MarketStyled,
  MarketPillStyled,
  MarketNameStyled,
  CompetitionAndTournamentStyled,
  EventStyled,
  SingleSelectionStyled,
} from '../Common/Header.styles'
import { RaceCountdown } from '@core/Components/RaceCountdown'

export const FobSportsHeader: React.FC<{}> = () => {
  const { selectionDetails, selection } = useObservableImmutable(quickbetState$, [
    'selectionDetails',
    'selection',
  ])

  const sportsSelectionDetails = (
    selectionDetails as unknown as Map<string, string> | undefined
  )?.toJS() as EventDetails

  const sportsSelection = (
    selection as unknown as Map<string, string> | undefined
  )?.toJS() as Selection

  if (!isFobSportsDetails(sportsSelectionDetails) || !isFobSportsSelection(sportsSelection)) {
    return !PRODUCTION ? (
      <ErrorMessage>selectionDetails incompatible with FobSportsHeader</ErrorMessage>
    ) : null
  }

  const { sportName, tournamentName, eventName, marketName, advertisedStartTime } =
    sportsSelectionDetails
  const { propositionName } = sportsSelection

  return (
    <>
      <Grid>
        <GridRow padding='0 0 1.2rem 0'>
          <GridCell valign='middle'>
            <CompetitionAndTournamentStyled>
              {sportName} - {tournamentName}
            </CompetitionAndTournamentStyled>

            <EventStyled>{eventName}</EventStyled>
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell valign='middle'>
            <MarketStyled>
              <MarketPillStyled>
                <MarketNameStyled>{marketName}</MarketNameStyled>
              </MarketPillStyled>
            </MarketStyled>
          </GridCell>

          <GridCell minWidth='fit-content' flexGrow={0} valign='middle' align='right'>
            <CountdownPillStyled>
              <RaceCountdown displaySize='1.2rem' raceStartTime={new Date(advertisedStartTime)} />
            </CountdownPillStyled>
          </GridCell>
        </GridRow>
      </Grid>

      <SingleSelectionStyled>{propositionName}</SingleSelectionStyled>
    </>
  )
}
