import styled from '@emotion/styled'
import { colors } from '@core/Settings'

// All new messages should extend this
const Message = styled('div')({
  padding: '1rem',
  fontSize: '1.2rem',
  border: '1px solid',
  margin: '1rem',
  width: 'calc(100% - 2rem)',
  boxSizing: 'border-box',
})

export const MessageStyled = styled(Message)({
  color: colors.messages.info.text,
  borderColor: colors.messages.info.border,
  backgroundColor: colors.messages.info.background,
})

export const ErrorMessageStyled = styled(Message)({
  color: colors.messages.error.text,
  borderColor: colors.messages.error.border,
  backgroundColor: colors.messages.error.background,
})

export const WarningMessageStyled = styled(Message)({
  color: colors.messages.warning.text,
  borderColor: colors.messages.warning.border,
  backgroundColor: colors.messages.warning.background,
})

export const SuccessMessageStyled = styled(Message)({
  color: colors.messages.success.text,
  borderColor: colors.messages.success.border,
  backgroundColor: colors.messages.success.background,
})
