import ObservableRacePage from '../../../../Model/Observables/ObservableRacePage'
import { SrmGridPriceChangedPushEvent } from 'tabtouch-push-contract'
import dayjs from 'dayjs'

interface PushSameRaceMultiPriceChanged {
  event: SrmGridPriceChangedPushEvent
  raceInformation: ObservableRacePage
}

export function pushSameRaceMultiPriceChanged({
  event,
  raceInformation,
}: PushSameRaceMultiPriceChanged) {
  const { runners } = event

  const eventDate = dayjs(event.fixtureDate).format('YYYY-MM-DD')

  const raceMatchedToEvent = raceInformation.meetingInformation
    .races()
    .filter(
      race =>
        event.raceNumber === race.raceNumber() &&
        event.fixtureId === race.meetingId() &&
        eventDate === dayjs(raceInformation.meetingInformation.meetingDate()).format('YYYY-MM-DD')
    )

  if (!event.raceNumber || !event.runners || !raceMatchedToEvent.length) {
    return
  }

  const starters = raceInformation.getStartersForRace(raceMatchedToEvent[0].raceNumber())()

  runners.forEach(({ runnerNumber, winPrice, top2Price, top3Price, top4Price, isFavourite }) => {
    const starter = starters.find(starter => starter.number() === runnerNumber)
    if (!starter) return
    const {
      fixedOddsStarterInfo: { sameRaceMultiPrices },
    } = starter
    sameRaceMultiPrices.win(winPrice)
    sameRaceMultiPrices.top2(top2Price)
    sameRaceMultiPrices.top3(top3Price)
    sameRaceMultiPrices.top4(top4Price)
    sameRaceMultiPrices.isFavourite(isFavourite)
    sameRaceMultiPrices.isSuspended(!winPrice)
  })
}
