import React from 'react'
import dayjs from 'dayjs'

import { MysteryDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/MysteryDataTransferObject'

import { TimeToJumpComponent } from '@core/Areas/NextEvents/Components/TimeToJump/TimeToJump'
import { GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { PillStyled } from '@core/Components/Pill'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export const MysteryRaceDetailsHeader: React.FC<{
  mysteryData: MysteryDataTransferObject
  betTypeText: string
  isRaceClosed: boolean
}> = ({ mysteryData, betTypeText, isRaceClosed }) => (
  <>
    <GridRow>
      <GridCell padding='0 1rem' flexGrow={0} flexBasis='auto'>
        <Icon
          size='3rem'
          type={mysteryData.Meeting.MeetingCodeText.toLowerCase() as RaceCodesLowerCase}
        />
      </GridCell>
      <GridCell valign='middle'>
        <span id='mystery-meeting-name-and-race-number'>
          {mysteryData.Meeting.MeetingName}: R{mysteryData.Race.RaceNumber}
        </span>
      </GridCell>
    </GridRow>
    <GridRow>
      <GridCell align='left' flexGrow={1} flexBasis='auto'>
        <PillStyled id='mystery-bet-type'>{betTypeText}</PillStyled>
      </GridCell>
      <GridCell align='right' flexGrow={0} flexBasis='auto'>
        <PillStyled id='mystery-bet-countdown'>
          {!isRaceClosed && (
            <TimeToJumpComponent
              showDay={false}
              showCountDown={true}
              advertisedStartTime={dayjs(mysteryData.Race.RaceTime).toDate()}
            />
          )}
          {isRaceClosed && <span id='mystery-bet-betting-closed'>Betting closed</span>}
        </PillStyled>
      </GridCell>
    </GridRow>
  </>
)
