import React from 'react'
import { LoadingInstance, ErroredInstance, isLoading, isErrored } from 'rwwa-data-access'
import { BetAccountHolder } from '@mobi/api-types'
import {
  ContactDetails as ContactDetailsRepo,
  ContactDetailsKey,
} from '@core/Data/Account/contactDetails'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { getContactDetailsFields } from '@core/Areas/ContactDetails/data-transforms'
import { connect } from '@core/Components/HOCs/connect'
import { ErrorMessage } from '@core/Components/Messages'
import { DesktopSpinner, Spinner } from '@mobi/component-library/Common/Spinner'
import { CloseAccountView } from './CloseAccountView'

export function CloseAccount({ isDesktop }: { isDesktop?: boolean }) {
  return <ConnectContactDetails contactDetails={ContactDetailsKey} isDesktop={isDesktop} />
}

const ConnectContactDetails: React.ComponentClass<{
  contactDetails: string
  isDesktop?: boolean
}> = connect({ contactDetails: ContactDetailsRepo })<{ isDesktop?: boolean }>(CloseAccountLoader)

interface CloseAccountLoaderProps {
  contactDetails: typeof LoadingInstance | typeof ErroredInstance | BetAccountHolder
  isDesktop?: boolean
}

export function CloseAccountLoader({ contactDetails, isDesktop }: CloseAccountLoaderProps) {
  useBackNav()

  if (isLoading(contactDetails)) {
    return isDesktop ? <DesktopSpinner /> : <Spinner />
  }

  if (isErrored(contactDetails)) {
    return <ErrorMessage>An error occurred while loading the "Close Account" page.</ErrorMessage>
  }

  const { isInternationalCustomer } = getContactDetailsFields(contactDetails)
  return (
    <CloseAccountView isInternationalCustomer={isInternationalCustomer} isDesktop={isDesktop} />
  )
}
