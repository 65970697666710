import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRaceFormDataFromApi } from './api'

export const useRaceFormData = ({
  meetingId,
  meetingDate,
  raceNumber,
}: {
  meetingId: string
  meetingDate: Date
  raceNumber: number
}) =>
  useQuery(
    queryKeys.racePageForm(meetingId, meetingDate, raceNumber),
    () => getRaceFormDataFromApi(meetingId, meetingDate, raceNumber),
    {
      staleTime: Infinity,
    }
  )
