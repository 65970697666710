import React from 'react'
import ReactDOM from 'react-dom'

import { layering } from '@mobi/component-library/Theme/Common'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'

import { BettingDrawerStyled, MAX_DRAWER_CONTENT_WIDTH } from './BettingDrawer.styles'

interface RaceClosedDrawerProps {
  open: boolean
  noticeBox?: JSX.Element
  onNextRace: () => void
  onGoToMeeting: () => void
}

export function NextRaceDrawer({
  open,
  noticeBox,
  onNextRace,
  onGoToMeeting,
}: RaceClosedDrawerProps): JSX.Element {
  return ReactDOM.createPortal(
    <BettingDrawerStyled
      open={open}
      zIndexOverride={layering.modalOverlay}
      data-testid='NextRaceDrawer'
    >
      <Grid margin='auto' maxWidth={MAX_DRAWER_CONTENT_WIDTH}>
        <GridRow>
          <GridCell padding='1rem 0.5rem 0.5rem 0.5rem'>
            {noticeBox || (
              <NoticeBox
                title='Betting closed'
                noticeBoxType={NoticeBoxTypes.Error}
                hasBorder={true}
              />
            )}
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell padding='0 0.5rem 1rem 0.5rem'>
            <ButtonBlock
              type='button'
              color='secondary'
              onClick={onGoToMeeting}
              data-tid-back-to-meeting-button
            >
              Back To Meeting
            </ButtonBlock>
          </GridCell>
          <GridCell padding='0 0.5rem 1rem 0.5rem'>
            <ButtonBlock
              type='button'
              color='primary'
              onClick={onNextRace}
              data-tid-next-race-to-jump-button
            >
              Next Race To Jump
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
    </BettingDrawerStyled>,
    document.body
  )
}
