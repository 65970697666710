import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2'
import { Popover } from '@mobi/component-library/Overlays/Popover'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export type NotificationTime = 1 | 5 | 10 | 15
const notificationTimeOptions: NotificationTime[] = [1, 5, 10, 15]

export const TimePickerPopover: React.FC<{
  selectedTimes: Set<NotificationTime>
  setSelectedTimes: (setter: (prev: Set<NotificationTime>) => Set<NotificationTime>) => void
  registerAlert: (time: Set<NotificationTime>) => void
  deregisterAlert: (time: Set<NotificationTime>) => void
  ast: string
  popoverRef: React.RefObject<HTMLDivElement>
  analyticsDataRef: React.RefObject<RaceAlertAnalyticsData>
}> = ({
  selectedTimes,
  setSelectedTimes,
  registerAlert,
  deregisterAlert,
  ast,
  popoverRef,
  analyticsDataRef,
}) => {
  const initialSelectedTimesRef = React.useRef(selectedTimes)

  const validNotificationOptions = getValidNotificationOptions(ast)

  React.useEffect(() => {
    const data = analyticsDataRef
    const initialSelectedTimes = initialSelectedTimesRef.current
    return () => {
      if (!data.current || areSelectedTimesEqual(initialSelectedTimes, data.current.selectedTimes))
        return
      trackAnalytics({ ...data.current })
    }
  }, [analyticsDataRef])

  return (
    <Popover isOpen referenceElRef={popoverRef} placement='left-start'>
      <PopoverWrapperStyled>
        <h4>Set your reminder(s)</h4>

        {validNotificationOptions.map(time => (
          <Button
            key={time}
            size='xs'
            color={selectedTimes.has(time) ? 'primary' : 'secondary_outline'}
            onClick={e => {
              e.stopPropagation()
              setSelectedTimes(prev => {
                const updatedTimes = new Set(prev)

                if (updatedTimes.has(time)) {
                  updatedTimes.delete(time)
                } else {
                  updatedTimes.add(time)
                }

                if (updatedTimes.size === 0) {
                  deregisterAlert(updatedTimes)
                } else {
                  registerAlert(updatedTimes)
                }

                return updatedTimes
              })
            }}
          >
            {time} min
          </Button>
        ))}
      </PopoverWrapperStyled>
    </Popover>
  )
}

// =============
// Local Helpers
// =============

const getValidNotificationOptions = (raceTimeStart: string): NotificationTime[] => {
  const now = dayjs()
  const raceTime = dayjs(raceTimeStart)
  const timeToJump = raceTime.diff(now, 'minute')

  return notificationTimeOptions.filter(option => option <= timeToJump)
}

// Analytics tracking

const serialisedSet = (set: Set<unknown>) => [...set].join('')

function areSelectedTimesEqual(
  initialSelectedTimes: Set<NotificationTime>,
  newSelectedTimes: Set<NotificationTime>
) {
  return serialisedSet(initialSelectedTimes) === serialisedSet(newSelectedTimes)
}

const trackAnalytics = ({
  selectedTimes,
  ast,
  meetingCode,
  raceNumber,
  isHeaderPinned,
}: RaceAlertAnalyticsData) => {
  const timeToJump = dayjs(ast).diff(dayjs(), 'second')

  if (selectedTimes.size === 0)
    trackRaceNotificationDisabled({
      meetingCode,
      raceNumber,
      timeToJump,
    })
  else
    trackRaceNotificationEnabled({
      meetingCode,
      raceNumber,
      timeToJump,
      headerState: isHeaderPinned ? 'sticky' : 'default',
      ...selectedTimesToDictionary(selectedTimes ?? new Set()),
    })
}

function selectedTimesToDictionary(selectedTimes: Set<NotificationTime>) {
  const allTimes: NotificationTime[] = [1, 5, 10, 15]
  return allTimes.reduce(
    (result, n) => {
      result[`alert${n}`] = selectedTimes.has(n)
      return result
    },
    {} as Record<`alert${NotificationTime}`, boolean>
  )
}

const trackRaceNotificationEnabled = ({ headerState, ...data }: AnalyticsDataEnabled): void => {
  trackEvent('race-notification-enabled', { data, headerState })
}

const trackRaceNotificationDisabled = (data: AnalyticsData): void => {
  trackEvent('race-notification-disabled', { data })
}

// ======
// Styles
// ======

const PopoverWrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx2,

  '> h4': {
    padding: 0,
    margin: 0,
    marginBottom: spacing.smx2,
    color: colors.black,
    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.semibold,
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
})

// =====
// Types
// =====

type AnalyticsData = {
  meetingCode: string
  raceNumber: number
  timeToJump: number
}

type AnalyticsDataEnabled = AnalyticsData & {
  headerState?: 'sticky' | 'default'
} & Record<`alert${NotificationTime}`, boolean>

export type RaceAlertAnalyticsData = {
  selectedTimes: Set<NotificationTime>
  ast: string
  meetingCode: string
  raceNumber: number
  isHeaderPinned?: boolean
}
