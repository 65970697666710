import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IMessageBoxViewModel } from './IMessageBoxViewModel'
import template from './messagebox.tpl.html'

interface Params {
  iconClass: string
  title: string
  description: string
  link: {
    text: string
    url: string
  }
}

export function register(): void {
  if (!ko.components.isRegistered('message-box')) {
    ko.components.register('message-box', {
      viewModel: {
        createViewModel: (params: Params): IMessageBoxViewModel => {
          const viewModel = container.get<IMessageBoxViewModel>('IMessageBoxViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
