import styled from '@emotion/styled'

export const HeaderStyled = styled.div({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  textAlign: 'left',
  margin: '-1rem 0 1rem',
})

export const SubheaderStyled = styled.div({
  fontSize: '1.2rem',
  textAlign: 'left',
  marginBottom: '1.5rem',
})

export const TextContainerStyled = styled.div({
  width: '40%',
})

export const SubheaderTextStyled = styled.span({
  backgroundColor: '#281636',
  borderRadius: '10rem',
  fontWeight: 'bold',
  padding: '0.5rem',
})

export const LineContainerStyled = styled.div({
  display: 'flex',
  margin: '1rem 0',
  wordBreak: 'break-word',
})

export const TotalContainerStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const TotalBetsStyled = styled.div({
  fontWeight: 'bold',
  fontSize: '1.6rem',
  marginRight: '1rem',
})

export const LineInfoStyled = styled.div({
  width: '80%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '1rem',
})

export const ConfirmInfoStyled = styled.div({
  backgroundColor: '#66477F',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 1rem',
  height: '5rem',
  margin: '1rem 0',
})

export const ConfirmInfoTextStyled = styled.div({
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
})

export const ConfirmInfoBetStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: '2.5rem',
  overflowWrap: 'anywhere',
})

export const PoolDateContainerStyled = styled.div({
  maxWidth: '18rem',
})

export const PoolDateStyled = styled.div({
  display: 'inline-block',
  marginRight: '1rem',
})
