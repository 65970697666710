import { Ticket } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import { BonusBetRowStyled } from './BonusBet.styles'
import React from 'react'

export const BonusBet: React.FC<Pick<Ticket, 'investment'>> = ({
  investment: { bonusBet, total, bonusType },
}) => {
  return bonusBet && bonusBet > 0 && bonusType ? (
    <table>
      <tbody>
        <BonusBetRowStyled data-testid='bonus-bet' bonusAmount={bonusBet} bonusType={bonusType}>
          <td>{bonusType == 'BonusBet' ? 'Bonus Bet' : 'Bonus Cash'}</td>
          <td className='text-right'>
            <Money amount={-bonusBet} />
          </td>
        </BonusBetRowStyled>
      </tbody>
      <tfoot>
        <tr data-testid='amount-paid'>
          <th className='text-left'>Amount Paid</th>
          <td className='text-right'>
            <Money amount={total - bonusBet} />
          </td>
        </tr>
      </tfoot>
    </table>
  ) : null
}
