import * as ko from 'knockout'
import EventAggregator from '@classic/AppUtils/Framework/Messaging/EventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'

export class VerticalArrowViewModel extends Disposable {
  constructor(params: { down: ko.Observable<boolean> }) {
    super(new EventAggregator())
    this.arrowCss = ko.pureComputed<string>(() => {
      if (params.down()) {
        return 'tticon-arrowup'
      } else {
        return 'tticon-arrowdown'
      }
    })
    this.registerDisposals(() => this.arrowCss.dispose())
  }

  public arrowCss: ko.PureComputed<string>
}
