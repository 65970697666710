import React from 'react'
import { PanelsContainerStyled } from '.'
import { RacePromotions } from '@core/Areas/Racing/Components/RacePromotion'
import { useBetInformers } from '@core/Areas/Racing/Components/BetInformers/Hooks/useBetInformers'
import StewardsComments from '@core/Areas/Racing/Components/StewardsComments/StewardsComments'
import { RaceContentPanel } from '@core/Areas/Racing/Components/RaceContent/RaceContentPanel'
import Preview from '@core/Areas/Racing/Components/Preview/Preview'
import { FeatureFlag } from '@core/State/LaunchDarklyFeatures/Components/FeatureFlag'
import { useRaceStatus } from '@core/Areas/Racing/Hooks/useRaceStatus'
import { useRacePageData } from '@core/Areas/Racing/Hooks'
import { BettableTipsPanel, hasTips } from '@core/Areas/Racing/Components/BettableTips'
import { type IdentifiableRace, RaceStatus } from '@core/Areas/Racing/Types'

export const RacePanels = (props: IdentifiableRace): JSX.Element | null => {
  const { meetingDate, meetingId, raceNumber } = props
  const { buttons } = useBetInformers()
  const { isLoading, isError, race } = useRacePageData({ meetingId, meetingDate, raceNumber })

  const raceStatus = race?.MeetingInformation.SelectedRace.RaceStatus as MaybeDefined<RaceStatus>

  const { hasResults, isOpen: isRaceOpen } = useRaceStatus(raceStatus)

  if (isLoading || isError || !race) return null

  const {
    SelectedRace: { Tips: tips, Tipster: tipster, Key: raceKey },
  } = race.MeetingInformation

  return (
    <PanelsContainerStyled data-testid='race-panels'>
      {!hasResults && (
        <RacePromotions meetingDate={meetingDate} meetingId={meetingId} raceNumber={raceNumber} />
      )}

      <FeatureFlag flag='race-page-video-content'>
        <Preview {...props} />
      </FeatureFlag>

      <RaceContentPanel
        meetingId={meetingId}
        meetingDate={meetingDate}
        raceNumber={raceNumber}
        raceKey={raceKey}
        isRaceOpen={isRaceOpen}
        race={race}
      />

      {buttons.tipsVisible && hasTips(tips, tipster) && (
        <BettableTipsPanel
          tips={tips}
          tipster={tipster}
          raceKey={raceKey}
          isRaceOpen={isRaceOpen}
          race={race}
          tipSource='tips'
        />
      )}

      {buttons.commentsVisible && <StewardsComments {...props} />}
    </PanelsContainerStyled>
  )
}
