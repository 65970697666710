import { RacePageDTO } from '@mobi/api-types'
import { getCompleteRaceApiUrl } from '@classic/Betting-v2/Utils/UrlProvider'
import { get } from '@classic/Foundation/Services/ApiService'

export const getRacePageDataFromApi = async (
  meetingId: string,
  meetingDate: Date | string,
  raceNumber: number
): Promise<RacePageDTO> => {
  const url = getCompleteRaceApiUrl(meetingId.toLowerCase(), meetingDate, raceNumber)
  const response = await get<RacePageDTO>({
    url,
  })
  return response
}
