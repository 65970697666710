import * as ko from 'knockout'
import { injectable } from 'inversify'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import Guard from '@classic/AppUtils/Framework/Guard'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { IAllUpViewModel } from './IAllUpViewModel'
import { IDisposable } from '@classic/AppUtils/Framework/Disposable/IDisposable'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import StartersMapper from '@classic/Betting-v2/Mapping/StartersMapper'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { isRacePageDto } from '@mobi/api-types'
import { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRacePageDataFromApi } from '@core/Areas/Racing/Hooks/useRacePageData'

@injectable()
export class AllUpViewModel implements IAllUpViewModel, IDisposable {
  private legVisibleSubscriptions!: ko.Subscription

  public data!: ObservableRacePage
  public bettingContext!: BettingInformation
  public selections!: ko.ObservableArray<IObservableStarter>
  public legVisible!: ko.Observable<number | null>
  public doubleProvDivAllowed!: ko.PureComputed<boolean>
  public quaddieProvDivAllowed!: ko.PureComputed<boolean>
  public useGiddyUp!: IUseGiddyUp

  public init(params: {
    data: ObservableRacePage
    selections: ko.ObservableArray<IObservableStarter>
    context: { bettingContext: BettingInformation }
    useGiddyUp: IUseGiddyUp
  }) {
    Guard.notNull(params.data)
    Guard.notNull(params.context.bettingContext)

    this.data = params.data
    this.bettingContext = params.context.bettingContext
    this.selections = params.selections

    this.legVisible = ko.observable(null)
    this.useGiddyUp = params.useGiddyUp

    this.doubleProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.data.hasDoubleProvDivs()
      )
    })

    this.quaddieProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.data.hasQuaddieProvDivs()
      )
    })

    this.registerHandlers()
  }

  public dispose() {
    this.legVisibleSubscriptions.dispose()
  }

  private registerHandlers() {
    this.legVisibleSubscriptions = this.legVisible.subscribe(() => {
      this.loadStartersForLeg()
    })
  }

  private loadStartersForLeg(): void {
    const legVisible = this.legVisible()
    if (legVisible && legVisible >= 0) {
      const raceKeyForLeg = this.data.getRaceKeyForLeg(legVisible)
      if (raceKeyForLeg) {
        this.loadStartersForRace(raceKeyForLeg)
      }
    }
  }

  private async loadStartersForRace(raceKey: ObservableRaceKey) {
    if (raceKey == null) return

    const raceNumber = raceKey.raceNumber()
    const { meetingId, meetingDate } = this.bettingContext

    try {
      const pageDto = await getRacePageDataFromApi(meetingId, meetingDate, raceNumber)

      queryClient.setQueryData(
        queryKeys.racePageComplete(meetingId, meetingDate, raceNumber),
        pageDto
      )

      if (isRacePageDto(pageDto)) {
        const raceStarters = pageDto.RaceStarters.find(s => s.RaceKey.RaceNumber === raceNumber)
        const starters = raceStarters?.Starters ?? []
        this.data.mergeStartersForRaceKey(raceKey, StartersMapper.mapStarters(starters))
      }
    } catch {
      this.data.addStartersForRace(raceKey, [])
    }
  }
}
