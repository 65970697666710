import { store } from '.'

export function subscribeToStore<T extends FunctionType>(
  select: T,
  onChange: (newValue: ReturnType<T>, previousValue: ReturnType<T>) => void
) {
  let currentState: ReturnType<T>

  function handleChange() {
    const nextState = select(store.getState())
    if (nextState !== currentState) {
      const previousState = currentState
      currentState = nextState
      onChange(currentState, previousState)
    }
  }

  return store.subscribe(handleChange)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionType = (...args: any) => any
