import React from 'react'
import Decimal from 'decimal.js'
import {
  SuperPickStyled,
  HeaderStyled,
  HeaderBg,
  HeaderIconStyled,
  SuperPickButtonsContainer,
  SuperButtonCellStyled,
} from './SuperPick.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { SuperPickButton } from './SuperPickButton'
import { observeImmutable } from '../HOCs'
import { BetSpecialOffer, IBetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { Stake } from '@classic/Specials/Model/Stake'
import { PriceDetails } from '@classic/Specials/Model/PriceDetails'
import { state$ as superPickState$, SuperPickState, SuperPickStateRecord } from './driver'
import { LoadSuperPicks } from './signals'
import { calculateProjectedReward } from '@classic/Specials/Store/PyosRewardCalculator'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { useFeature } from '@core/Utils/hooks'
import { useInducementService } from '@core/Utils/hooks/useInducementService'

interface SuperPickProps {
  investmentWin: number
  investmentPlace: number
  winPrice: number
  placePrice: number
  isDisabled: boolean
  isVisible: boolean
}

export function SuperPickComponent({
  specialOffers,
  investmentWin,
  investmentPlace,
  winPrice,
  placePrice,
  isVisible,
  isDisabled,
}: Pick<SuperPickState, 'specialOffers'> & SuperPickProps): JSX.Element | null {
  if (!specialOffers || !specialOffers.length || !isVisible) {
    return null
  }

  const stakeObj: Stake = Stake.normalise({
    Win: new Decimal(investmentWin || 0),
    Place: new Decimal(investmentPlace || 0),
  })
  const priceDetails: PriceDetails = PriceDetails.normalise({
    Win: { DollarReturn: new Decimal(winPrice || 0) },
    Place: { DollarReturn: new Decimal(placePrice || 0) },
  })

  return (
    <SuperPickStyled>
      <HeaderStyled>
        <HeaderBg>
          <HeaderIconStyled>
            <Icon type='specials' />
          </HeaderIconStyled>
          SuperPick
        </HeaderBg>
      </HeaderStyled>
      <Grid padding='0.2rem'>
        {specialOffers.map(specialOffer => {
          const newSpecialOffer = BetSpecialOffer.normalise(specialOffer)
          const projectedReward = calculateProjectedReward(
            newSpecialOffer as IBetSpecialOffer,
            stakeObj,
            priceDetails
          ).toNumber()
          return (
            <GridCell key={newSpecialOffer.specialSeq}>
              <SuperPickButton
                specialOffer={newSpecialOffer}
                projectedReward={projectedReward}
                isDisabled={isDisabled}
              />
            </GridCell>
          )
        })}
      </Grid>
    </SuperPickStyled>
  )
}

function SuperPickWithSuperBoostsComponent({
  specialOffers,
  investmentWin,
  investmentPlace,
  winPrice,
  placePrice,
  isVisible,
  isDisabled,
}: Pick<SuperPickState, 'specialOffers'> & SuperPickProps): JSX.Element | null {
  if (!specialOffers || !specialOffers.length || !isVisible) {
    return null
  }

  const stakeObj: Stake = Stake.normalise({
    Win: new Decimal(investmentWin || 0),
    Place: new Decimal(investmentPlace || 0),
  })
  const priceDetails: PriceDetails = PriceDetails.normalise({
    Win: { DollarReturn: new Decimal(winPrice || 0) },
    Place: { DollarReturn: new Decimal(placePrice || 0) },
  })

  return (
    <SuperPickStyled>
      <HeaderStyled>
        <HeaderBg>
          <HeaderIconStyled>
            <Icon type='specials' />
          </HeaderIconStyled>
          SuperPick
        </HeaderBg>
      </HeaderStyled>
      <SuperPickButtonsContainer>
        {specialOffers.sort().map(specialOffer => {
          const newSpecialOffer = BetSpecialOffer.normalise(specialOffer)
          const projectedReward = calculateProjectedReward(
            newSpecialOffer as IBetSpecialOffer,
            stakeObj,
            priceDetails
          ).toNumber()
          return (
            <SuperButtonCellStyled key={newSpecialOffer.specialSeq}>
              <SuperPickButton
                specialOffer={newSpecialOffer}
                projectedReward={projectedReward}
                isDisabled={isDisabled}
              />
            </SuperButtonCellStyled>
          )
        })}
      </SuperPickButtonsContainer>
    </SuperPickStyled>
  )
}

const SuperPickObserver = observeImmutable<SuperPickStateRecord, SuperPickProps>(
  superPickState$,
  ({ record, investmentWin, investmentPlace, winPrice, placePrice, isVisible, isDisabled }) => {
    React.useEffect(() => {
      LoadSuperPicks()
    }, [])

    const specialOffers = (record.toJS() as SuperPickState).specialOffers
    const isSuperBoostFeatureActive = !!useFeature('HOMEPAGE_SUPERPICKS')
    const isSuperBoostIncluded = specialOffers.some(offer => offer.isSuperBoost)

    return isSuperBoostFeatureActive && isSuperBoostIncluded ? (
      <SuperPickWithSuperBoostsComponent
        specialOffers={specialOffers}
        investmentWin={investmentWin}
        investmentPlace={investmentPlace}
        winPrice={winPrice}
        placePrice={placePrice}
        isDisabled={isDisabled}
        isVisible={isVisible}
      />
    ) : (
      <SuperPickComponent
        specialOffers={specialOffers}
        investmentWin={investmentWin}
        investmentPlace={investmentPlace}
        winPrice={winPrice}
        placePrice={placePrice}
        isDisabled={isDisabled}
        isVisible={isVisible}
      />
    )
  }
)

export const SuperPickWithInducementCheck: React.FC<SuperPickProps> = ({
  investmentWin,
  investmentPlace,
  winPrice,
  placePrice,
  isDisabled,
  isVisible,
}) => {
  const canShowInducements = useInducementService()
  return canShowInducements ? (
    <SuperPickObserver
      investmentWin={investmentWin}
      investmentPlace={investmentPlace}
      winPrice={winPrice}
      placePrice={placePrice}
      isDisabled={isDisabled}
      isVisible={isVisible}
    />
  ) : null
}
