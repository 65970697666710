import React from 'react'
import { useAppSelector } from '@core/Store/hooks'
import {
  selectCanRenderPendingBetsDrawer,
  selectIsPendingBetsDrawerOpen,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'

export const usePendingBetsOpenToggle = (drawerWidth: number) => {
  const pendingBetsDrawerRef = React.useRef<HTMLDivElement>(null)

  const isPendingBetsOpen = useAppSelector(selectIsPendingBetsDrawerOpen)
  const canRenderBetsDrawer = useAppSelector(selectCanRenderPendingBetsDrawer)

  React.useEffect(() => {
    if (!pendingBetsDrawerRef.current) return

    if (!canRenderBetsDrawer || !isPendingBetsOpen) {
      pendingBetsDrawerRef.current.style.width = '0'
      return
    }

    pendingBetsDrawerRef.current.style.width = drawerWidth + 'rem'
  }, [isPendingBetsOpen, canRenderBetsDrawer, pendingBetsDrawerRef, drawerWidth])

  return pendingBetsDrawerRef
}
