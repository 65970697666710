import React from 'react'
import styled from '@emotion/styled'
import { useMutation } from 'react-query'
import { toTitleCase } from '@mobi/utils/string'
import { TextArea } from '@core/Components/Inputs'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { postBlackbookComment } from '@core/Areas/Blackbook/helpers/api'
import type { BlackbookEntry } from '@core/Areas/Blackbook/types'
import { getIconName } from '@core/Areas/Blackbook/helpers'

const MAX_CHARACTERS = 1000

export const AddCommentModal: React.FC<{
  id: number
  name: string
  type: BlackbookEntry['Code']

  // for editing
  commentID?: number
  newComment?: string

  onCommentSave: () => void
  onCancel: () => void
}> = ({ id, name, onCancel, onCommentSave, type, newComment, commentID }) => {
  const [comment, setComment] = React.useState<string>(newComment || '')

  const { mutate, isError, isLoading } = useMutation<void, void, { comment: string; id: number }>(
    ({ id, comment }) => postBlackbookComment({ id, comment, noteID: commentID }),
    { onSuccess: onCommentSave }
  )

  const handleAddComment = () => mutate({ id, comment })
  const isJockey = type === 'Jockeys'

  return (
    <WrapperStyled data-testid='blackboook-add-comment-modal'>
      <h4>{isJockey ? name : toTitleCase(name)}</h4>

      {isError && (
        <div data-testid='blackbook-add-comment-failure'>
          <NoticeBox
            title='Unable to save your comment. Please try again later.'
            noticeBoxType={NoticeBoxTypes.Error}
            hasBorder
          />
        </div>
      )}

      <p>
        <Icon type={getIconName(type)} size='2rem' /> {!commentID ? 'Add a ' : 'Edit '}comment
      </p>

      <TextArea
        type='text'
        onChange={e => setComment(e.target.value)}
        value={comment}
        maxCharacters={MAX_CHARACTERS}
        testId='blackbook-comment-textarea'
      />

      <div>
        {comment.length}/{MAX_CHARACTERS}
      </div>

      <Grid margin='2rem 0 0 0'>
        <GridRow>
          <GridCell padding='0 0.5rem 0 0'>
            <ButtonBlock
              id='cancel'
              testId='cancel'
              color='secondary'
              type='button'
              onClick={onCancel}
            >
              Cancel
            </ButtonBlock>
          </GridCell>

          <GridCell padding='0 0 0 0.5rem'>
            <ButtonBlock
              testId='save'
              color='primary'
              type='button'
              isBusy={isLoading}
              disabled={isLoading || comment.length == 0}
              onClick={handleAddComment}
            >
              Save
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: '1rem',

  h4: {
    margin: 0,
    textAlign: 'center',
  },

  p: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    fontSize: '1.2rem',
    textAlign: 'left',
    margin: 0,

    svg: { marginRight: '0.5rem ' },
  },

  textarea: {
    display: 'block',
    width: '100%',
    borderRadius: '1rem',
  },

  div: {
    textAlign: 'right',
  },
})
