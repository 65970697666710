import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const CardStyled = styled.div<{ isPreview?: boolean }>(
  {
    marginBottom: '1rem',
    fontSize: '1.2rem',
    background: hexColors.white,
    borderRadius: '0.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  },
  ({ isPreview = false }) =>
    isPreview && {
      overflow: 'hidden',
      marginBottom: 0,
    }
)
