import * as ko from 'knockout'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IBaseBetType } from './IBaseBetType'
import { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'

export class BaseBetType implements IBaseBetType {
  public doubleProvDivAllowed: ko.PureComputed<boolean>
  public quaddieProvDivAllowed: ko.PureComputed<boolean>
  public raceInfo: ObservableRacePage
  public raceStarters: {
    Key: ObservableRaceKey
    Value: ko.ObservableArray<IObservableStarter>
  }
  public bettingContext: BettingInformation
  public selections: ko.ObservableArray<IObservableStarter>
  public useGiddyUp: IUseGiddyUp

  constructor(
    raceInfo: ObservableRacePage,
    raceStarters: ko.Observable<{
      Key: ObservableRaceKey
      Value: ko.ObservableArray<IObservableStarter>
    }>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    useGiddyUp: IUseGiddyUp
  ) {
    Guard.notNull(raceInfo)

    this.raceInfo = raceInfo
    this.raceStarters = raceStarters()
    this.bettingContext = bettingContext
    this.selections = selections
    this.useGiddyUp = useGiddyUp

    this.doubleProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.raceInfo.hasDoubleProvDivs()
      )
    })

    this.quaddieProvDivAllowed = ko.pureComputed(() => {
      return (
        this.bettingContext.selectedBetType().betType() === BetType.WinPlace &&
        this.raceInfo.hasQuaddieProvDivs()
      )
    })
  }
}
