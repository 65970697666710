import { Icon } from '@mobi/component-library/Common/Icon'
import React from 'react'
import { HeaderStyled } from './FavouriteNumbers.styles'
import {
  DateComponentStyled,
  DetailtextStyled,
  FavnumberResultListStyled,
  JackpotStyled,
  NavComponentStyled,
  NextButtonStyled,
  NumberResultsTabStyled,
  OrderStyled,
  PoolInfoComponentStyled,
  PoolInfoLineLeftStyled,
  PoolInfoLineRightStyled,
  PoolInfoLineStyled,
  PrevButtonStyled,
  RaceStyled,
  ResultButtonStyled,
  ResultLegendComponentStyled,
  ResultLiStyled,
  ResultsStyled,
  ResultTitleStyled,
  SubStyled,
} from './NumberResultsTab.styles'
import { Leg } from './NumberResultsPools'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { useDataAccess } from '@core/Utils/hooks/useDataAccess'
import { FavouriteNumberPoolResults } from '@core/Data/favouriteNumbersResults'
import { isErrored, isLoading } from 'rwwa-data-access/dist/data-access'
import { ErrorMessage } from '@core/Components/Messages'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { SetResultKey } from './driver'
import { state$ } from './driver'
import { useObservableProperties } from '@core/Utils/hooks/useObservable'

export const NumberResultsTab = () => {
  const { resultKey } = useObservableProperties(state$, ['resultKey'], {
    resultKey: 'current',
  })
  const state = useDataAccess(FavouriteNumberPoolResults, resultKey)

  if (isErrored(state.data)) {
    return <ErrorMessage>Unable to retrieve results</ErrorMessage>
  }

  if (isLoading(state.data)) {
    return (
      <div style={{ margin: '1em' }}>
        <LoadingPlaceholder width='100%' height='4em' />
      </div>
    )
  }

  const currentPool = state.data
  const isNextAvailable = currentPool.isNextAvailable
  const isPreviousAvailable = currentPool.isPreviousAvailable

  const handletPreviousButtonClick = () => {
    if (currentPool.isPreviousAvailable) {
      SetResultKey(`prev|${currentPool.poolDate}`)
    }
  }

  const handleNextButtonClick = () => {
    if (currentPool.isNextAvailable) {
      SetResultKey(`next|${currentPool.poolDate}`)
    }
  }

  if (!currentPool) {
    return null
  }
  const isNumberAllocated = !!currentPool.legs

  return (
    <NumberResultsTabStyled data-tid-number-results-content=''>
      <NavComponent
        isPreviousAvailable={isPreviousAvailable}
        isNextAvailable={isNextAvailable}
        poolDate={currentPool.poolDate}
        handletPreviousButtonClick={handletPreviousButtonClick}
        handleNextButtonClick={handleNextButtonClick}
      />
      <HeaderStyled data-tid-pool-info-header='' style={{ margin: '1rem 0' }}>
        POOL INFO
      </HeaderStyled>
      <PoolInfoComponent
        poolTotal={currentPool.poolTotal}
        poolResultDisplayDateTitle={currentPool.poolResultDisplayDateTitle}
        poolResultDisplayDate={currentPool.poolResultDisplayDate}
        jackpotAmount={currentPool.jackpotAmount}
      />
      <HeaderStyled data-tid-numbers-results-header='' style={{ margin: '1rem 0' }}>
        NUMBERS AND RESULTS
      </HeaderStyled>
      {isNumberAllocated && (
        <>
          <ResultDetails
            requiredNumber={currentPool.numbersRequired}
            dividendPayable={currentPool.dividendPayable}
            carryover={currentPool.carryover}
            winningEntries={currentPool.winningEntries}
            data-tid-result-details=''
          />
          <ResultsStyled>
            <FavnumberResultList data-tid-favnumber-result-list legs={currentPool.legs} />
            <ResultLegendComponent data-tid-favnumber-result-legend />
          </ResultsStyled>
        </>
      )}
      {!isNumberAllocated && (
        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center' }}>
          <NoticeBox
            title='Favourite Numbers have not yet been allocated.'
            noticeBoxType={NoticeBoxTypes.Warning}
            hasBorder={true}
          />
        </div>
      )}
    </NumberResultsTabStyled>
  )
}

interface ResultDetailsProps {
  requiredNumber: string
  dividendPayable: string
  carryover: string
  winningEntries: number
}

function ResultDetails({
  requiredNumber,
  dividendPayable,
  carryover,
  winningEntries,
}: ResultDetailsProps) {
  let winningEntriesText = 'There are no winning entries.'

  if (winningEntries == 1) {
    winningEntriesText = 'There is 1 winning entry.'
  } else if (winningEntries > 1) {
    winningEntriesText = `There are ${winningEntries.toLocaleString()} winning entries.`
  }

  const showDividendPayable = dividendPayable && dividendPayable.length > 0 && requiredNumber

  return (
    <>
      {showDividendPayable && (
        <DetailtextStyled id='dividendPayable' data-tid-number-results-dividend-payable=''>
          {requiredNumber} numbers required for a dividend of {dividendPayable}.
        </DetailtextStyled>
      )}
      {carryover && (
        <DetailtextStyled id='carryover' data-tid-number-results-carryover=''>
          There is a carryover of {carryover}.
        </DetailtextStyled>
      )}
      {showDividendPayable && (
        <DetailtextStyled id='winningEntries' data-tid-number-winning-entries=''>
          {winningEntriesText}
        </DetailtextStyled>
      )}{' '}
    </>
  )
}

function FavnumberResultList({ legs }: { legs: Leg[] }) {
  const resultBoxList = legs.map((data, index) => {
    return (
      <ResultButton
        key={index}
        order={index + 1}
        race={data.race}
        isLive={data.isLive}
        isWinner={data.isWinner}
        isSub={data.isSub}
      />
    )
  })
  return <FavnumberResultListStyled>{resultBoxList}</FavnumberResultListStyled>
}

interface ResultButtonProps {
  order: number
  race: string
  isLive: boolean
  isWinner: boolean
  isSub: boolean
}

function ResultLegendComponent() {
  return (
    <ResultLegendComponentStyled data-tid-number-result-legend=''>
      <ResultTitleStyled>Legend</ResultTitleStyled>
      <ul style={{ listStyle: 'none', margin: '0', padding: '0', display: 'flex' }}>
        <ResultLiStyled>
          <ResultButton order={21} race={'AD2'} isLive={false} isWinner={true} isSub={false} />
          Won
        </ResultLiStyled>
        <ResultLiStyled>
          <ResultButton order={17} race={'BD1'} isLive={false} isWinner={false} isSub={false} />
          Lost
        </ResultLiStyled>
        <ResultLiStyled>
          <ResultButton order={11} race={'PR6'} isLive={true} isWinner={false} isSub={false} />
          Live
        </ResultLiStyled>
      </ul>
    </ResultLegendComponentStyled>
  )
}

function ResultButton({ order, race, isLive, isWinner, isSub }: ResultButtonProps): JSX.Element {
  return (
    <ResultButtonStyled
      isLive={isLive}
      isWinner={isWinner}
      data-tid-number-results-result-button=''
    >
      <OrderStyled>{order}</OrderStyled>
      <RaceStyled>{race}</RaceStyled>
      {isSub && <SubStyled>(SUB)</SubStyled>}
    </ResultButtonStyled>
  )
}

function NavComponent({
  isPreviousAvailable,
  isNextAvailable,
  poolDate,
  handletPreviousButtonClick,
  handleNextButtonClick,
}: {
  isPreviousAvailable: boolean
  isNextAvailable: boolean
  poolDate: string
  handletPreviousButtonClick: () => void
  handleNextButtonClick: () => void
}) {
  return (
    <NavComponentStyled>
      <PrevButtonStyled
        onClick={handletPreviousButtonClick}
        isPreviousAvailable={isPreviousAvailable}
        data-tid-number-results-prev-button=''
      >
        {'< '}PREV
      </PrevButtonStyled>
      <DateComponentStyled data-tid-number-results-date-button=''>{poolDate}</DateComponentStyled>
      <NextButtonStyled
        onClick={handleNextButtonClick}
        isNextAvailable={isNextAvailable}
        data-tid-number-results-next-button=''
      >
        NEXT{' >'}
      </NextButtonStyled>
    </NavComponentStyled>
  )
}

interface PoolInfoComponentProps {
  poolTotal: string
  poolResultDisplayDateTitle: string
  poolResultDisplayDate: string
  jackpotAmount: string
}

function PoolInfoComponent({
  poolTotal,
  poolResultDisplayDateTitle,
  poolResultDisplayDate,
  jackpotAmount,
}: PoolInfoComponentProps) {
  return (
    <PoolInfoComponentStyled>
      <PoolInfoLineStyled>
        <PoolInfoLineLeftStyled data-tid-number-pool-total-title=''>
          Pool Total
        </PoolInfoLineLeftStyled>
        <PoolInfoLineRightStyled data-tid-number-pool-total=''>
          {poolTotal}
          {jackpotAmount && (
            <JackpotStyled>
              <Icon size='2.1rem' type='jackpot' color='#f2a900' />
              {jackpotAmount}
            </JackpotStyled>
          )}
        </PoolInfoLineRightStyled>
      </PoolInfoLineStyled>
      <PoolInfoLineStyled>
        <PoolInfoLineLeftStyled data-tid-number-results-display-date-title=''>
          {poolResultDisplayDateTitle}
        </PoolInfoLineLeftStyled>
        <PoolInfoLineRightStyled data-tid-number-results-display-date=''>
          {poolResultDisplayDate}
        </PoolInfoLineRightStyled>
      </PoolInfoLineStyled>
    </PoolInfoComponentStyled>
  )
}
