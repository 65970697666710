import React, { type FC, memo } from 'react'
import type { IssuerName } from '@mobi/api-types'
import { toMoney } from '@mobi/utils/money'
import { PillButton } from '../../Common/V2'
import { depositSourceMap, cardIssuerNameMap } from '../helpers'
import {
  PanelStyled as Panel,
  PanelBankCardIconStyled,
  PanelHeaderStyled as Header,
  PanelSubHeaderStyled as Subheader,
  PanelButtonsStyled as Buttons,
} from './Workflow.styled'
import { DepositSource } from '../types'

type Props = {
  /** The payment method being used, e.g. Credit Card, Paypal */
  depositSource: DepositSource
  /** The bank card issuer (Credit Card only) */
  cardIssuer?: IssuerName
  /** The amount being deposited */
  amount: number
  onConfirmed: VoidFunction
  onCanceled: VoidFunction
}

const Component: FC<Props> = ({ depositSource, cardIssuer, amount, onConfirmed, onCanceled }) => {
  const Icon = cardIssuer ? cardIssuerNameMap[cardIssuer] : depositSourceMap[depositSource]
  return (
    <Panel>
      <PanelBankCardIconStyled depositSource={depositSource} cardIssuer={cardIssuer}>
        <Icon />
      </PanelBankCardIconStyled>
      <Header>Confirm {toMoney(amount)} Deposit</Header>
      <Subheader>
        Are you sure you want to deposit <br />
        <strong>{toMoney(amount)}</strong> into your account?
      </Subheader>
      <Buttons>
        <PillButton color={'primary'} isFullWidth={true} onClick={onConfirmed}>
          Deposit
        </PillButton>
        <PillButton color={'secondary_color'} isFullWidth={true} onClick={onCanceled}>
          Cancel
        </PillButton>
      </Buttons>
    </Panel>
  )
}

Component.displayName = 'ConfirmPanel'

export const ConfirmPanel = memo(Component)
