import React from 'react'
import type { Acceptor, ToteSelection } from '@mobi/betslip/types'
import {
  NoveltyListStyled,
  NoveltyPlaceStyled,
  NoveltyStarterStyled,
  AbbrStyled,
} from './Selection.styles'
import { parseNoveltySelectionString } from './helpers'

export const NoveltySelection: React.FC<{ selection: ToteSelection; acceptors: Acceptor[] }> = ({
  selection,
  acceptors,
}) => {
  const parsed = parseNoveltySelectionString(selection, acceptors)
  return (
    <NoveltyListStyled>
      {parsed['1st'] && [
        <NoveltyPlaceStyled key='__1st'>
          <span>1st</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='1st'>{parsed['1st']}</NoveltyStarterStyled>,
      ]}

      {parsed['2nd'] && [
        <NoveltyPlaceStyled key='__2nd'>
          <span>2nd</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='2nd'>{parsed['2nd']}</NoveltyStarterStyled>,
      ]}

      {parsed['3rd'] && [
        <NoveltyPlaceStyled key='__3rd'>
          <span>3rd</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='3rd'>{parsed['3rd']}</NoveltyStarterStyled>,
      ]}

      {parsed['4th'] && [
        <NoveltyPlaceStyled key='__4ths'>
          <span>4th</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='4th'>{parsed['4th']}</NoveltyStarterStyled>,
      ]}

      {parsed.ALLWAYS && [
        <NoveltyPlaceStyled key='__ALLWAYS'>
          <span>Allways</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='ALLWAYS'>{parsed.ALLWAYS}</NoveltyStarterStyled>,
      ]}

      {parsed['LEG-IN'] && [
        <NoveltyPlaceStyled key='__LEG-IN'>
          <span>Leg-In</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='LEG-IN'>{parsed['LEG-IN']}</NoveltyStarterStyled>,
      ]}

      {parsed.RB && [
        <NoveltyPlaceStyled key='__RB'>
          <AbbrStyled title='Roving Banker'>RB</AbbrStyled>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='RB'>{parsed.RB}</NoveltyStarterStyled>,
      ]}

      {parsed.WITH && [
        <NoveltyPlaceStyled key='__WITH'>
          <span>With</span>
        </NoveltyPlaceStyled>,
        <NoveltyStarterStyled key='WITH'>{parsed.WITH}</NoveltyStarterStyled>,
      ]}
    </NoveltyListStyled>
  )
}
