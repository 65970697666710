import styled from '@emotion/styled'
import { colors, spacing, font } from '@mobi/component-library/Theme/Common'
import { gapX } from '../../../Utils/gap'

const themes = {
  neutral: {
    '--background-color': colors.greyDark,
    '--color': colors.white,
  },
  primary: { '--background-color': colors.mariner[500], '--color': colors.white },
  success: {
    '--background-color': colors.sushi[500],
    '--color': colors.white,
  },
  warning: {
    '--background-color': colors.warning[500],
    '--color': colors.black,
  },
  error: {
    '--background-color': colors.error[600],
    '--color': colors.white,
  },
} as const

export type ThemeName = keyof typeof themes

type Props = {
  type: ThemeName
}

export const BannerStyled = styled.div<Props>(
  {
    alignItems: 'center',
    backgroundColor: 'var(--background-color)',
    boxSizing: 'border-box',
    color: 'var(--color)',
    display: 'flex',
    fontSize: font.size.lg.fontSize,
    lineHeight: font.size.lg.lineHeight,
    padding: `${spacing.sm} ${spacing.md}`,

    '> svg': {
      flex: '0 0 auto',
      fill: 'none', // Required because Mobi sets `fill: currentColor`
    },

    '> p': {
      flex: '1 1 auto',
      fontSize: 'inherit',
      margin: 0,
      textAlign: 'left',
    },

    '> button, > a': {
      flexShrink: 0,
    },
    'button[name="close_button"]': {
      marginRight: `-${spacing.smx1}`,
      marginLeft: `-${spacing.smx1}`,
    },
  },
  ({ type }) => themes[type],
  gapX(spacing.md)
)
