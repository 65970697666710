import React from 'react'
import Rx from 'rx'
import type { AllUpSelection } from '@mobi/betslip/types'
import { observeImmutable } from '@core/Components/HOCs'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import {
  ExpandablePanelFactory,
  type ExpandablePanelProps,
  ExpandablePanel,
} from '@core/Components/ExpandablePanel'
import { FormulaCombination } from '@core/Components/Formula/types'
import { Formula } from '@core/Components/Formula'
import { state$ as quickbetState$ } from '../../driver'
import { state$ as formulaState$, ToggleFormula, FormulaState } from './driver'

const formulaNames = {
  1: 'Singles',
  2: 'Doubles',
  3: 'Trebles',
}

const QuickbetPanel = ExpandablePanelFactory<ExpandablePanelProps>(ExpandablePanel)
QuickbetPanel.displayName = 'QuickbetPanel'

interface QuickbetFormulaComponentProps {
  betPlaced: boolean
  formulas: AllUpSelection['formulas']
}

export function QuickbetFormulaComponent({ betPlaced, formulas }: QuickbetFormulaComponentProps) {
  if (betPlaced || !formulas.length) {
    return null
  }

  const combinations: FormulaCombination[] = formulas.map(form => ({
    name: formulaNames[form.formula as keyof typeof formulaNames] || `Pick ${form.formula}`,
    formula: form.formula,
    numberOfCombinations: form.numberOfCombinations,
    isSelected: form.isSelected,
  }))

  return (
    <QuickbetPanel title='Formula' themeName={ExpandablePanelThemes.light}>
      <Formula
        formulaCombinations={combinations}
        onClickFormula={(formula, wasSelected) => ToggleFormula({ formula, wasSelected })}
      />
    </QuickbetPanel>
  )
}

const state$ = Rx.Observable.combineLatest(
  quickbetState$,
  formulaState$,
  (quickbetStateRecord, formulaStateRecord): QuickbetFormulaComponentProps => {
    const { betPlaced } = quickbetStateRecord
    const { formulas } = formulaStateRecord.toJS() as FormulaState

    return {
      betPlaced,
      formulas,
    }
  }
)

export const QuickbetFormula: React.ComponentClass = observeImmutable(state$, ({ record }) => {
  const { betPlaced, formulas } = record
  return <QuickbetFormulaComponent betPlaced={betPlaced} formulas={formulas} />
})
QuickbetFormula.displayName = 'QuickbetFormula'
