import { TypedRecord, recordify } from 'typed-immutable-record'
import { List } from 'immutable'
import { declareResourceType, IKeyMap } from 'rwwa-data-access'
import { fetchNullableResourcesWithKeys } from '../fetchResources'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'

export interface PoolKeyCollection {
  items: string[]
}

interface PoolKeyMap {
  poolKeys: IKeyMap<PoolKeyCollection>
}

export type PoolKeysRecord = List<string>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PoolKeys = declareResourceType<PoolKeysRecord, any>({
  fetchMultipleNoPromise: keys =>
    fetchNullableResourcesWithKeys<PoolKeyMap, PoolKeyCollection>(keys, 'poolKeys'),
  typeKey: 'poolKeys',
  map: (x: PoolKeyCollection) => x && List(x.items),
  maxToKeep: 100,
  hardTimeToLive: 60000,
})

export class PoolType {
  public static Racing = 'Racing'
  public static FavouriteNumbers = 'FavouriteNumbers'
  public static Tipping = 'Tipping'
}

export class FixtureType {
  public static Races = 'Races'
  public static Trots = 'Trots'
  public static Dogs = 'Dogs'
}

interface Pool {
  key: string
  type: PoolType
  name: string
  status: string
  fixtureKey: string
  raceKeys: string[]
  grossAmount: number
  netJackpotAmount: number
}

export interface RacingPool extends Pool {
  fixtureType: FixtureType
}
export interface RacingPoolRecord extends TypedRecord<RacingPoolRecord>, RacingPool {}

export interface FavouriteNumberPool extends Pool {
  scheduledCloseDateTime: Date
}
export interface FavouriteNumberPoolRecord
  extends TypedRecord<FavouriteNumberPoolRecord>,
    FavouriteNumberPool {}

export interface TippingPool extends Pool {
  sportName: string
  sportDescription: string
  sportType: string
  sportCode: keyof typeof iconPaths
  gameStartDateTime: Date
  numGames: number
}
export interface TippingPoolRecord extends TypedRecord<TippingPoolRecord>, TippingPool {}

interface PoolMap {
  pool: IKeyMap<TippingPool | RacingPool | FavouriteNumberPool>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Pools = declareResourceType<TippingPool | RacingPool | FavouriteNumberPool, any>({
  fetchMultipleNoPromise: keys => fetchNullableResourcesWithKeys<PoolMap, Pool>(keys, 'pool'),
  typeKey: 'pool',
  map: x => {
    switch (x.type) {
      case PoolType.FavouriteNumbers:
        return recordify<FavouriteNumberPool, FavouriteNumberPoolRecord>(x)
      case PoolType.Tipping:
        return recordify<TippingPool, TippingPoolRecord>(x)
      default:
        return recordify<RacingPool, RacingPoolRecord>(x)
    }
  },
  maxToKeep: 100,
  hardTimeToLive: 60000,
})

export function isRacingPool(
  pool: TippingPool | RacingPool | FavouriteNumberPool
): pool is RacingPool {
  return pool.type === PoolType.Racing
}

export function isFavouriteNumbersPool(
  pool: TippingPool | RacingPool | FavouriteNumberPool
): pool is FavouriteNumberPool {
  return pool.type === PoolType.FavouriteNumbers
}

export function isTippingPool(
  pool: TippingPool | RacingPool | FavouriteNumberPool
): pool is TippingPool {
  return pool.type === PoolType.Tipping
}
