import { state$ as BettingV2CommandState$ } from './driver'
import { trackActionInvoked } from '../../../Foundation/Analytics/Analytics'

const segmentPrefix: string = 'RaceBettingPageCommand-'

export interface RaceBettingPageCommandSideEffectsSubscriptions {
  showFormSubscription: Rx.IDisposable | null
  showFixedFlucsSubscription: Rx.IDisposable | null
  showMarketMoversSubscription: Rx.Disposable | null
  showDoubleProvDivSubscription: Rx.Disposable | null
  showQuaddieProvDivSubScription: Rx.Disposable | null
}

export function registerRaceBettingPageCommandSideEffects(): RaceBettingPageCommandSideEffectsSubscriptions {
  const returnSubscriptions: RaceBettingPageCommandSideEffectsSubscriptions = {
    showFormSubscription: null,
    showFixedFlucsSubscription: null,
    showMarketMoversSubscription: null,
    showDoubleProvDivSubscription: null,
    showQuaddieProvDivSubScription: null,
  }

  returnSubscriptions.showFormSubscription = BettingV2CommandState$.map(
    record => record.showAllForm
  )
    .distinctUntilChanged()
    .skip(1) //  Ignore initial state from subscribing
    .subscribe(selected => {
      trackActionInvoked(
        `${segmentPrefix}Show-All-Form-button: ${selected ? 'selected' : 'deselected'}`
      )
    })

  returnSubscriptions.showFixedFlucsSubscription = BettingV2CommandState$.map(
    record => record.showFixedFlucs
  )
    .distinctUntilChanged()
    .skip(1) //  Ignore initial state from subscribing
    .subscribe(selected => {
      trackActionInvoked(
        `${segmentPrefix}Fixed-Flucs-button: ${selected ? 'selected' : 'deselected'}`
      )
    })

  returnSubscriptions.showMarketMoversSubscription = BettingV2CommandState$.map(
    record => record.showMarketMovers
  )
    .distinctUntilChanged()
    .skip(1) //  Ignore initial state from subscribing
    .subscribe(selected => {
      trackActionInvoked(
        `${segmentPrefix}Market-Movers-button: ${selected ? 'selected' : 'deselected'}`
      )
    })

  returnSubscriptions.showDoubleProvDivSubscription = BettingV2CommandState$.map(
    record => record.showDoubleProvDiv
  )
    .distinctUntilChanged()
    .skip(1) //  Ignore initial state from subscribing
    .subscribe(selected => {
      trackActionInvoked(
        `${segmentPrefix}Double-Prov-Div-button: ${selected ? 'selected' : 'deselected'}`
      )
    })

  returnSubscriptions.showQuaddieProvDivSubScription = BettingV2CommandState$.map(
    record => record.showQuaddieProvDiv
  )
    .distinctUntilChanged()
    .skip(1) //  Ignore initial state from subscribing
    .subscribe(selected => {
      trackActionInvoked(
        `${segmentPrefix}Quaddie-Prov-Div-button: ${selected ? 'selected' : 'deselected'}`
      )
    })

  return returnSubscriptions
}
