import styled from '@emotion/styled'
import { colors } from '../../Settings'
import { GridRow } from '@mobi/component-library/Common/Grid'

export const RaceListHeaderStyled = styled('header')({
  alignItems: 'center',
  background: colors.subContentHeader.background,
  boxSizing: 'border-box',
  color: colors.subContentHeader.text,
  display: 'flex',
  justifyContent: 'center',
  lineHeight: 2,
  padding: '0.5em 1.2em 1em 1.2em',
  textAlign: 'center',
  width: '100%',
})

export const RaceListHeaderDetails = styled(GridRow)({
  color: colors.subContentHeader.detailsText,
  fontSize: '1.1rem',
  lineHeight: '3rem',
  textTransform: 'uppercase',
})

export const RaceListCenteredHeaderStyled = styled('div')({
  maxWidth: '42rem',
  width: '100%',
})
