import React, { Suspense, lazy } from 'react'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { useDisableAppHeaderRender } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderRender'

const VerificationPage = lazy(() => import('./Components/VerificationPage'))

export const Verification = () => {
  useDisableAppHeaderRender()

  return (
    <Suspense fallback={<Spinner />}>
      <VerificationPage />
    </Suspense>
  )
}

Verification.displayName = 'Verification'
