import React from 'react'
import { Button } from '@mobi/component-library/Common/Buttons'
import { removeFromLocalStorage } from '@mobi/utils/storage'

export const LocalStorageManager: React.FC<{}> = () => {
  const [localStorageState, setLocalStorageState] = React.useState<Record<string, string>>()

  React.useEffect(() => {
    setLocalStorageState(getLocalStorageForState())
  }, [])

  if (!localStorageState) return null

  const handleLocalStorageDelete = (key: string) => {
    removeFromLocalStorage(key)
    setLocalStorageState(getLocalStorageForState())
  }

  return (
    <>
      <h3>Local Storage Manager</h3>
      <div style={{ fontSize: '1.4rem', padding: '1rem', wordBreak: 'break-all' }}>
        {Object.keys(localStorageState).map(key => (
          <details key={key} style={{ marginBottom: '1rem' }}>
            <summary>
              {key}{' '}
              <Button type='button' color='link' onClick={() => handleLocalStorageDelete(key)}>
                Delete
              </Button>
            </summary>
            {localStorageState[key].startsWith('{') ? (
              <pre>{JSON.stringify(JSON.parse(localStorageState[key]), null, 2)}</pre>
            ) : (
              localStorageState[key]
            )}
          </details>
        ))}
      </div>
    </>
  )
}

// Local Helpers

function getLocalStorageForState() {
  const storageKeys: string[] = []
  for (var i = 0; i < localStorage.length; i++) {
    storageKeys.push(localStorage.key(i) as string)
  }
  const state = storageKeys.reduce<Record<string, string>>((acc, curr) => {
    acc[curr] = localStorage.getItem(curr) as string
    return acc
  }, {})
  return state
}
