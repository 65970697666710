import styled from '@emotion/styled'
import { measurements, layering } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { AppHeaderButtonStyled } from '@core/Areas/AppHeader/Components/Buttons/Buttons.styles'

type Props = {
  layer: typeof layering.overlayLow | typeof layering.overlayHigh
}

export const OverlayStyled = styled.div<Props>(({ layer }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: layer ?? layering.overlayLow,
  width: '100%',
  maxWidth: '80em',
  margin: '0 auto',
  background: hexColors.white,
  display: 'flex',
  flexDirection: 'column',
}))

export const OverlayHeaderStyled = styled.div({
  width: '100%',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: measurements.mobi.headerHeight,
  minHeight: measurements.mobi.headerHeight,
  background: hexColors.blueDiamond,
})

export const OverlayHeadingStyled = styled.h1({
  marginLeft: '1rem',
  fontSize: '1.8rem',
  textTransform: 'uppercase',
  color: hexColors.white,
})

export const OverlayCloseButtonStyled = styled(AppHeaderButtonStyled)({
  marginLeft: 'auto',
})
