import { BettingInformation } from '../Model/BettingInformation'
import { ICheckBoxValidatorProcessorPipeline } from './ICheckBoxValidatorProcessorPipeline'
import { ICheckBoxValidatorProcessor } from './ICheckBoxValidatorProcessor'
import RovingBankerCheckBoxValidatorProcessor from './RovingBankerCheckBoxValidatorProcessor'
import AllwaysCheckBoxValidatorProcessor from './AllwaysCheckBoxValidatorProcessor'
import RegularCheckBoxValidatorProcessor from './RegularCheckBoxValidatorProcessor'

export default class CheckBoxValidatorProcessorPipeline
  implements ICheckBoxValidatorProcessorPipeline
{
  constructor() {
    this.head = new RegularCheckBoxValidatorProcessor(
      new RovingBankerCheckBoxValidatorProcessor(
        new AllwaysCheckBoxValidatorProcessor(null as unknown as ICheckBoxValidatorProcessor)
      )
    )
  }

  validate(count: number, bettingContext: BettingInformation): boolean {
    return this.head.validate(count, bettingContext)
  }

  private head: ICheckBoxValidatorProcessor
}
