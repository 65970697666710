import * as ko from 'knockout'
import { CookieAttributes } from 'js-cookie'
import atob from 'atob'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { getLastKnownLocationAsString } from '@core/NativeServices/Location/LocationService'
import * as apiService from '@classic/Foundation/Services/ApiService'
import {
  getCookieValue,
  setCookieValue,
  clearCookieValue,
} from '@classic/Foundation/Services/CookieService'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'
import {
  checkNotificationStatusAsync,
  ShowNotificationAuthPopup,
} from '@core/Areas/Settings/Components/PayoutNotification/PayoutNotificationPopup'

export enum PresetCookieExpiry {
  Session,
  Custom,
  OneMinute,
  OneHour,
  OneDay,
  OneWeek,
  OneMonth,
  SixMonths,
  OneYear,
}

interface PresetCookieExpiryOption {
  value: string
  label: string
}

interface VenueLocation {
  Venue: string
  Location: number
  GpsVenue: string
  GpsLocation: number
  SecondaryProviderCode: string
  IsGpsVenueLocationSet: boolean
}

type NullableDate = Date | null

let userAccountStateSubscription$: Rx.IDisposable
export class ToolboxViewModel {
  now: Date
  selectedCookieName: ko.Observable<string>
  customCookieName: ko.Observable<string>
  cookieName: ko.PureComputed<string>
  cookieValue: ko.Observable<string>
  cookieResultMessage: ko.Observable<string>
  selectedExpiryPreset: ko.Observable<PresetCookieExpiry>
  cookieExpiryOptions: ko.ObservableArray<PresetCookieExpiryOption>
  customExpirySelected: ko.PureComputed<boolean>
  customCookieExpiry: ko.Observable<string>
  cookieExpiry: ko.PureComputed<NullableDate>

  lastKnownLocation: ko.Observable<string>
  lastKnownLocationText: ko.PureComputed<string>
  dateLastKnownLocationUpdated: ko.Observable<string>

  currentVenueLocation: ko.Observable<string>
  currentVenueLocationUpdatedAt: ko.Observable<string>

  ldFeatures: ko.Observable<[string, unknown][]>
  appCapability: ko.Observable<string>

  deviceId: ko.Observable<string | undefined>
  firstLoad: ko.Observable<string | undefined>
  segment: ko.Observable<string | undefined>

  fcmToken: ko.Observable<string>
  authStatus: ko.Observable<string>

  constructor(now?: string) {
    this.now = now ? new Date(now) : new Date()
    this.selectedCookieName = ko.observable<string>('')
    this.customCookieName = ko.observable<string>('')
    this.cookieValue = ko.observable<string>('')
    this.cookieName = ko.pureComputed<string>(() => {
      return this.selectedCookieName() ? this.selectedCookieName() : this.customCookieName()
    })
    this.cookieResultMessage = ko.observable<string>('')
    this.selectedExpiryPreset = ko.observable<PresetCookieExpiry>(PresetCookieExpiry.OneMonth)
    this.cookieExpiryOptions = ko.observableArray<PresetCookieExpiryOption>(this.getPresetOptions())
    this.customExpirySelected = ko.pureComputed<boolean>(() => {
      return this.selectedExpiryPreset() === PresetCookieExpiry.Custom
    })
    this.customCookieExpiry = ko.observable<string>('')
    this.cookieExpiry = ko.pureComputed<NullableDate>(() => {
      let date: Date

      switch (this.selectedExpiryPreset()) {
        case PresetCookieExpiry.Custom:
          date = new Date(this.customCookieExpiry())
          break
        case PresetCookieExpiry.OneMinute:
          date = new Date(this.now)
          date.setMinutes(new Date(this.now).getMinutes() + 1)
          break
        case PresetCookieExpiry.OneHour:
          date = new Date(this.now)
          date.setHours(new Date(this.now).getHours() + 1)
          break
        case PresetCookieExpiry.OneDay:
          date = new Date(this.now)
          date.setDate(new Date(this.now).getDate() + 1)
          break
        case PresetCookieExpiry.OneWeek:
          date = new Date(this.now)
          date.setDate(new Date(this.now).getDate() + 7)
          break
        case PresetCookieExpiry.OneMonth:
          date = new Date(this.now)
          date.setMonth(new Date(this.now).getMonth() + 1)
          break
        case PresetCookieExpiry.SixMonths:
          date = new Date(this.now)
          date.setMonth(new Date(this.now).getMonth() + 6)
          break
        case PresetCookieExpiry.OneYear:
          date = new Date(this.now)
          date.setFullYear(new Date(this.now).getFullYear() + 1)
          break
        default:
          return null
      }

      if (!isNaN(date.getTime())) {
        return date
      } else {
        return null
      }
    })

    this.lastKnownLocation = ko.observable<string>(getLastKnownLocationAsString())
    this.lastKnownLocationText = ko.pureComputed<string>(() => {
      const location = this.lastKnownLocation()

      return location ? location : 'Unknown'
    })
    this.dateLastKnownLocationUpdated = ko.observable<string>('')
    this.currentVenueLocation = ko.observable<string>('Unknown')
    this.currentVenueLocationUpdatedAt = ko.observable<string>('')

    const appCapabilityCookie = getCookieValue('AppCapability')
    this.appCapability = ko.observable<string>(
      atob(appCapabilityCookie != undefined ? appCapabilityCookie : '') || '-'
    )

    this.deviceId = ko.observable(getCookieValue('tt-dv'))
    this.firstLoad = ko.observable(getCookieValue('tt-fl'))
    this.segment = ko.observable(getCookieValue('tt-sg'))

    this.ldFeatures = ko.observable<[string, unknown][]>([])
    this.fcmToken = ko.observable<string>('unknown')
    this.authStatus = ko.observable<string>('unknown')

    userAccountStateSubscription$ = userAccountState$
      .map(record => record.isLoggedIn)
      .distinctUntilChanged()
      .subscribe(this.updateFeatures)
    this.updateFeatures()
    this.getPushAuthStatus()
  }

  setCookie() {
    const name = this.cookieName()
    if (!name) {
      this.cookieResultMessage('Need a cookie name')
      return
    }

    let options: CookieAttributes | undefined = undefined

    if (this.cookieExpiry()) {
      options = {
        expires: this.cookieExpiry() as Date,
      }
    }

    setCookieValue(name, this.cookieValue(), options)

    if (name === 'tt-sg') {
      this.updateSegmentDetails()
    }

    this.cookieResultMessage(this.getSuccessfulResultMessage())
  }

  clearCookie() {
    const name = this.cookieName()

    if (!name) {
      this.cookieResultMessage('Need a cookie name')
      return
    }

    clearCookieValue(name)

    if (name === 'tt-sg') {
      this.updateSegmentDetails()
    }

    this.cookieResultMessage(`Cleared cookie: ${name}`)
  }

  getPresetOptions() {
    let options: PresetCookieExpiryOption[] = []

    for (var preset in PresetCookieExpiry) {
      if (typeof PresetCookieExpiry[preset] === 'number') {
        options.push({
          value: PresetCookieExpiry[preset],
          label: preset,
        })
      }
    }

    return options
  }

  getSuccessfulResultMessage() {
    const expiry = this.cookieExpiry()
    const expiryAsString = expiry ? expiry.toUTCString() : 'session'
    return `Cookie "${this.cookieName()}" set to "${this.cookieValue()}" with expiry "${expiryAsString}"`
  }

  updateLastKnownLocation() {
    this.lastKnownLocation(getLastKnownLocationAsString())
    this.dateLastKnownLocationUpdated(`Updated ${new Date().toLocaleTimeString()}`)
  }

  getCurrentVenueLocation() {
    const url = 'webapi/device/GetCurrentVenueLocation'
    apiService.get<VenueLocation>({ url }).then(data => {
      let currentVenueLocation = data.Venue + '/' + data.Location + ' -> GPS: '
      currentVenueLocation += data.IsGpsVenueLocationSet
        ? data.GpsVenue + '/' + data.GpsLocation + '/'
        : 'none'
      this.currentVenueLocation(currentVenueLocation)
      this.currentVenueLocationUpdatedAt(`Updated ${new Date().toLocaleTimeString()}`)
    })
  }

  getPushAuthStatus() {
    if (isReactNativeApp) {
      checkNotificationStatusAsync().then(response => {
        if (response.status !== 'Authorized') {
          ShowNotificationAuthPopup({
            reason: 'Notifications must be enabled to set race alerts',
            notificationAuthStatus: response.status,
          })
        }

        this.authStatus(response.status as string)
        this.fcmToken(response.fcmRegistrationToken as string)
      })
    }
  }

  private updateFeatures = () => {
    launchDarkState$.subscribe(record => {
      this.ldFeatures(
        Object.entries(record.features.toJS()).sort((a, b) =>
          a[0].toUpperCase().localeCompare(b[0].toUpperCase())
        )
      )
    })
  }

  private updateSegmentDetails() {
    this.updateFeatures() // Reload the features
    const segmentValue = getCookieValue('tt-sg') as string
    this.segment(segmentValue)
  }

  cleanup() {
    if (userAccountStateSubscription$) {
      userAccountStateSubscription$.dispose()
    }
  }
}
