import React from 'react'
import { LinkProps } from 'react-router-dom'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackHamburgerMenuEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { store } from '@core/Store'
import { toggleIsHamburgerMenuOpen } from '../../Store'
import { ExternalLinkIconStyled, ExternalLinkStyled, LinkStyled } from './HamburgerMenu.styles'

export const HamburgerMenuLink = (attribs: LinkProps) => (
  <LinkStyled
    onClick={() => {
      store.dispatch(toggleIsHamburgerMenuOpen(false))
      trackHamburgerMenuEvent(keys.hamburgerMenuClicked, { item: attribs.id ?? '' })
    }}
    {...attribs}
  >
    {attribs.children}
  </LinkStyled>
)

export const HamburgerMenuExternalLink = (
  attribs: React.AnchorHTMLAttributes<HTMLAnchorElement>
) => (
  <ExternalLinkStyled
    href={attribs.href}
    target='_blank'
    onClick={() => trackHamburgerMenuEvent(keys.hamburgerMenuClicked, { item: attribs.id ?? '' })}
    {...attribs}
  >
    {attribs.children} <ExternalLinkIconStyled size='1em' type='externalLink' />
  </ExternalLinkStyled>
)
