import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const TipsterImageStyled = styled.img({
  height: '5rem',
  width: '5rem',

  backgroundColor: hexColors.amethyst,
  borderRadius: '50%',
})
