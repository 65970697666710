import React from 'react'
import styled from '@emotion/styled'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isAllUpSelection } from '@mobi/betslip/helpers/typeGuards'

const FORMULA_NAME_MAP = {
  F1: 'Singles',
  F2: 'Doubles',
  F3: 'Trebles',
  F4: 'Pick 4',
  F5: 'Pick 5',
  F6: 'Pick 6',
}

export const AllUpSelection: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  if (!isAllUpSelection(item.selection)) return null

  const isNotDefaultAllUp = item.selection.formulas.slice(0, -1).some(formula => formula.isSelected)
  const allUpTotalCost = item.investment.win.value * (item.numberOfCombinations || 0)

  return (
    <>
      {isNotDefaultAllUp && (
        <SingleAllUpFormulaContainerStyled>
          {item.selection.formulas.map(
            ({ formula, isSelected, numberOfCombinations }) =>
              isSelected && (
                <li key={formula}>
                  <span>{FORMULA_NAME_MAP[`F${formula}` as keyof typeof FORMULA_NAME_MAP]}</span> x
                  {numberOfCombinations}
                </li>
              )
          )}
        </SingleAllUpFormulaContainerStyled>
      )}

      {allUpTotalCost > 0 && !item.receipt && (
        <TotalCostStyled>
          Total Cost
          <span>${allUpTotalCost.toFixed(2)}</span>
        </TotalCostStyled>
      )}
    </>
  )
}

// ======
// Styles
// ======

const SingleAllUpFormulaContainerStyled = styled.ul({
  listStyle: 'none',
  margin: '0 0 0.5rem',
  padding: '0.5rem 0 0.5rem 0',
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1.2,
  borderBottom: '0.1rem solid #eee',

  '> li': {
    width: `calc(100% / 6)`,
    fontSize: '1.1rem',
    textAlign: 'center',
    color: '#222',
    boxSizing: 'border-box',
    borderRight: '0.1rem solid #eee',

    '> span': {
      display: 'block',
    },

    '&:last-of-type': { borderRight: 'none' },
  },
})

const TotalCostStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '2.7rem',
  fontSize: '1.2rem',
  color: 'black',

  '> span': {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: 'black',
  },
})
