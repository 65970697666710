import type { RaceIdentifierString } from './types'
import { formatDate } from '@mobi/utils'
import type { IdentifiableRace } from '@core/Areas/Racing/Types'

export function makeRaceIdentifier({
  meetingDate,
  meetingId,
  raceNumber,
}: IdentifiableRace): RaceIdentifierString {
  return `${formatDate(meetingDate, 'YYYYMMDD')}/${meetingId}/${raceNumber}`
}
