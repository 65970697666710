import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const SpecialsStyled = styled('div')({
  color: hexColors.nero,

  '& > table svg:first-of-type': {
    margin: 'auto',
    marginRight: '1rem',
  },
})
