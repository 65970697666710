import React from 'react'
import { useHistory } from 'react-router-dom'
import { isReactNativeAndroid, subscribeToNative } from '@mobi/web-native-comms/web'

export const useAndroidBackButton = (): void => {
  const history = useHistory()

  React.useEffect(() => {
    if (!isReactNativeAndroid) return undefined

    const subscription = subscribeToNative('ANDROID_NAVIGATE_BACK', () => {
      history.goBack()
    })
    return () => {
      subscription?.dispose()
    }
  }, [history])
}
