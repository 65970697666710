import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs } from '@core/Areas/AccountActivity/types'
import {
  RacingTransactionTitleStyled,
  TransactionTabActiveStyled as TabActive,
  TransactionTabStyled as Tab,
  TransactionTabsStyled as TransactionTabs,
} from './RacingSportsTabs.styles'
import { KAMBI_PENDING_HASH_ROUTE } from '@core/Areas/Kambi/constants'

type ActiveTabs = Tabs | 'sports'

const racingTabs: ActiveTabs[] = ['pending', 'results', 'rebet']

export const RacingSportsTabs: React.FC<{ activeTab: ActiveTabs; isRacing?: boolean }> = ({
  isRacing,
  activeTab,
}) => {
  return (
    <>
      <TransactionTabs>
        <Link
          data-testid='account-activity-tab-racing'
          to='/account/activity/pending'
          component={racingTabs.includes(activeTab) ? TabActive : Tab}
        >
          Racing Bets
        </Link>

        <Link
          data-testid='account-activity-tab-sports'
          to={`/${KAMBI_PENDING_HASH_ROUTE.replace('#', '')}`}
          component={activeTab === 'sports' ? TabActive : Tab}
        >
          Sports Bets
        </Link>

        <Link
          data-testid='account-activity-tab-transactions'
          to='/account/activity/transactions'
          component={activeTab === 'transactions' ? TabActive : Tab}
        >
          Debit / Credit
        </Link>
      </TransactionTabs>

      {isRacing && (
        <RacingTransactionTitleStyled>
          <h1 data-testid='account-activity-transactions-title'>
            My {activeTab === 'transactions' ? 'Debit / Credit' : 'Racing Bets'}
          </h1>
        </RacingTransactionTitleStyled>
      )}
    </>
  )
}
