import * as cookies from 'js-cookie'

export function isCookieSet(cookieName: string) {
  return getCookieValue(cookieName) !== undefined
}

export function getCookieValue(cookieName: string): string | undefined {
  return cookies.get(cookieName)
}

export function setCookieValue(
  cookieName: string,
  value: string,
  options?: cookies.CookieAttributes
) {
  cookies.set(cookieName, value, options)
}

export function clearCookieValue(cookieName: string) {
  cookies.remove(cookieName)
}
