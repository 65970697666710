import React from 'react'
import dayjs from 'dayjs'
import { useAppSelector } from '@core/Store/hooks'
import { useFeature } from '@core/Utils/hooks'
import { RaceListEntry, type RaceStatus } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import { SkyTvButton } from '@mobi/component-library/Racing/SkyTvIndicator'
import { toggleSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/helpers/toggleSkyVideoPlayer'
import {
  selectIsDockedSkyVideoPlayerOpen,
  selectIsSkyVideoPlayerOpen,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'

const RACE_NOT_FINISHED_STATUSES: RaceStatus[] = ['Open', 'Closed', 'Pending']

export const SkyVideoButton: React.FC<{
  currentRace: RaceListEntry
  isHeaderPinned: boolean
  isRacingWA?: boolean
}> = ({ currentRace, isHeaderPinned, isRacingWA }) => {
  const [showLiveIcon, setShowLiveIcon] = React.useState(false)

  const isDockedPlayerOpen = useAppSelector(selectIsDockedSkyVideoPlayerOpen)
  const isSkyVideoPlayerOpen = useAppSelector(selectIsSkyVideoPlayerOpen)

  const isSkyRacePageFeatureActive = useFeature('SKY_LIVE_RACE_PAGE')
  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')

  React.useEffect(() => {
    if (!currentRace) return

    const checkRaceStartTime = () => {
      const ast = currentRace.advertisedStartTime
      const lessThanMinute = dayjs(ast).isBefore(dayjs().add(1, 'minute'))
      // Check to remove live button if race isnt settled after race completed
      // Only really an edge case in the Test Envs
      const isPastAstBuffer = dayjs(currentRace?.advertisedStartTime).isBefore(
        dayjs().subtract(1, 'hour')
      )
      setShowLiveIcon(lessThanMinute && !isPastAstBuffer)
    }
    checkRaceStartTime()
    const timerId = setInterval(checkRaceStartTime, 1000)
    return () => clearInterval(timerId)
  }, [currentRace])

  const shouldDisplayRacingWA = isRacingWA && isRacingWAFeatureActive

  const channel = getVisionChannelForDisplay(
    currentRace.skyTvChannelId as string,
    !!shouldDisplayRacingWA
  )

  const handleButtonClick = () => {
    const headerState = isHeaderPinned ? 'sticky' : 'default'

    if (isSkyRacePageFeatureActive) {
      toggleSkyVideoPlayer(
        isDockedPlayerOpen
          ? { isPlayerOpen: false }
          : {
              isPlayerOpen: true,
              playerStatus: 'docked',
              selectedChannel: channel,
              analyticsData: { openLocation: 'race-card', headerState, channel },
            }
      )
    } else {
      toggleSkyVideoPlayer({
        selectedChannel: channel,
        analyticsData: { openLocation: 'race-card', headerState, channel },
      })
    }
  }

  const isButtonActive = isSkyRacePageFeatureActive ? isDockedPlayerOpen : isSkyVideoPlayerOpen
  const shouldShowLive = showLiveIcon && RACE_NOT_FINISHED_STATUSES.includes(currentRace.raceStatus)

  return (
    <SkyTvButton
      channel={channel}
      isLive={shouldShowLive}
      isPlaying={isButtonActive}
      onClick={handleButtonClick}
    />
  )
}
