import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { TicketStatus } from '@core/Areas/AccountActivity/types'

type PillSize = 'small' | 'large'
type LegStatus = TicketStatus | 'Lost'

export const StatusPillStyled = styled('div')<{ status: LegStatus; size: PillSize }>(
  {
    boxSizing: 'border-box',
    display: 'inline-block',
    marginLeft: 'auto',
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'white',
    borderRadius: '1rem',
  },
  ({ status, size }) => ({
    backgroundColor:
      (status === 'Pending' && hexColors.tangerine) ||
      (status === 'Won' && hexColors.citrus) ||
      hexColors.grey,

    ...(size === 'small'
      ? {
          padding: '0 1rem',
          fontSize: '1.1rem',
          fontWeight: 'normal',
          height: '2rem',
          lineHeight: '2rem',
        }
      : {
          padding: '0 1rem',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          height: '2rem',
          lineHeight: '2rem',
          // Gives visual balance to right alignment, due to rounded edge
          transform: 'translateX(0.5rem)',
        }),
  })
)
