import { UserAccountStateRecord } from '@core/State/UserAccount/userAccountDriver'

const WESTERN_AUSTRALIA = 'AU_WESTERN_AUSTRALIA'
const SOUTH_AUSTRALIA = 'AU_SOUTH_AUSTRALIA'

export type KambiRegulationId = typeof WESTERN_AUSTRALIA | typeof SOUTH_AUSTRALIA

const AU_COUNTRY_CODE = 'AU'
const SA_STATE_CODE = 'SA'

export function isUserResidentOfSA(
  residentialDetail: UserAccountStateRecord['residentialDetail']
): boolean {
  if (!residentialDetail) return false

  const [country, state] = residentialDetail.toArray()
  return country === AU_COUNTRY_CODE && state === SA_STATE_CODE
}

export function isUserAccountStatePending(userAccountRecord: UserAccountStateRecord): boolean {
  return (
    // Login state not ready
    userAccountRecord.isLoggedIn === null ||
    // Login state is true but residential detail not ready
    (userAccountRecord.isLoggedIn && !userAccountRecord.residentialDetail)
  )
}

export function determineUserRegulationId(
  residentialDetail: UserAccountStateRecord['residentialDetail']
): typeof WESTERN_AUSTRALIA | typeof SOUTH_AUSTRALIA {
  return isUserResidentOfSA(residentialDetail) ? SOUTH_AUSTRALIA : WESTERN_AUSTRALIA
}
