import React, { CSSProperties, useState } from 'react'
import styled from '@emotion/styled'
import { ButtonStyled } from '../../Common/V2/Buttons/Button'
import { colors, font, radius, spacing } from '../../Theme/Common'
import { CreditCardList } from '../../Deposit/CreditCard/CreditCardList'
import { CreditCardName } from './CreditCardName'
import { gapX } from '../../Utils/gap'
import { Icon } from '../../Common/V2/Icon'
import type { PaymentMethod } from '@mobi/api-types'
import { isSameCard, nameCardIssuerMap } from '../helpers'
import { CreditCardAlertLabel } from './CreditCardAlertLabel'
import { determineIndefiniteArticle } from '@mobi/utils'

type Props = {
  /**
   * The cards to display
   */
  cards: PaymentMethod[]
  /**
   * The user's primary card
   */
  primaryCard: PaymentMethod
  /**
   * The selected card
   */
  selectedCard: PaymentMethod
  /**
   * Whether the card selection is expanded by default or not
   */
  isOpenByDefault?: boolean
  /**
   * Callback when a card is selected
   */
  onSelect: (card: PaymentMethod) => void
}

export const CreditCardSelector = ({
  cards,
  selectedCard,
  primaryCard,
  isOpenByDefault = false,
  onSelect,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)
  const isPrimaryCardValid = !primaryCard.isExpired && primaryCard.isDebitCard

  const shouldRenderDropdown =
    cards.length > 1 ||
    (cards.length === 1 && isSameCard(primaryCard, selectedCard) && !isPrimaryCardValid)

  return (
    <CreditCardSelectorStyled
      open={isOpen}
      onClick={event => {
        event.preventDefault()
        setIsOpen(!isOpen)
      }}
      aria-label='Your cards'
    >
      <CreditCardSelectorToggleStyled
        as='summary'
        color='secondary_grey'
        isFullWidth
        size='md'
        aria-label={makeSelectedCardLabel(selectedCard)}
        role='button'
        tabIndex={0}
      >
        {selectedCard && (
          <>
            <CreditCardName issuerName={selectedCard.issuerName} lastFour={selectedCard.lastFour} />
            <CreditCardAlertLabel
              isDebitCard={selectedCard.isDebitCard}
              isExpired={selectedCard.isExpired}
            />
            {shouldRenderDropdown && (
              <Icon
                role='img'
                aria-label={isOpen ? 'Close card list' : 'Open card list'}
                name={isOpen ? 'SolidChevronUp' : 'SolidChevronDown'}
              />
            )}
          </>
        )}
      </CreditCardSelectorToggleStyled>

      {shouldRenderDropdown && (
        <CreditCardList
          cards={cards}
          selectedCard={selectedCard}
          onClick={card => {
            onSelect(card)
            setIsOpen(false)
          }}
          renderIcon={card => {
            const style: CSSProperties = {
              display: 'inline-flex',
              color: colors.neutral[300],
            }

            const isCardSelected = isSameCard(card, selectedCard)

            if (card.isExpired) {
              style.color = colors.neutral[200]
              style.fill = colors.neutral[300]
            } else if (isCardSelected) {
              style.color = colors.lavender[500]
            }

            return (
              <span style={style}>
                <Icon
                  name={isCardSelected && !card.isExpired ? 'SolidCheckCircle' : 'LinePlaceholder'}
                />
              </span>
            )
          }}
        />
      )}
    </CreditCardSelectorStyled>
  )
}

CreditCardSelector.displayName = 'CreditCardSelector'

function makeSelectedCardLabel(card: PaymentMethod) {
  const issuer = nameCardIssuerMap[card.issuerName]

  const aOrAn = determineIndefiniteArticle(issuer)

  return `The current selected card is ${aOrAn} ${issuer} ending in ${card.lastFour}`
}

// ======
// Styles
// ======

const CreditCardSelectorStyled = styled.details({
  color: colors.neutral['900'],
  fontFamily: font.family.primary,
  fontWeight: font.weight.medium,
  ...font.size.lg,

  ul: {
    display: 'grid',
    listStyle: 'none',
    margin: `${spacing.sm} 0 ${spacing.smx2}`,
    padding: 0,
  },
})

const CreditCardSelectorToggleStyled = styled(ButtonStyled)(
  {
    borderRadius: radius.lgx1,
    userSelect: 'none',

    '&:focus': {
      borderColor: colors.surface['300'],
    },

    '&::marker, &::-webkit-details-marker': {
      display: 'none',
    },
  },
  gapX(spacing.sm)
).withComponent('summary')
