import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { hexColors } from '@mobi/settings'

export const CampaignStyled = styled('div')({
  background: colors.campaigns.panel.background,
  color: colors.campaigns.panel.text,
  padding: '0.5rem 1.5rem',
})

export const CampaignTitleStyled = styled('p')({
  fontSize: '1.2rem',
  margin: 0,
})

export const RemoveCampaignButtonStyled = styled('button')({
  width: '2rem',
  height: '2rem',
  margin: 0,
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',
  fontSize: 0,
  color: hexColors.white,
})
