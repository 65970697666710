import React from 'react'
import { FlexiDisplayStyled, AmountStyled } from './FlexiDisplay.styles'
import { calculateFlexiAmount } from '../../helpers/calculator'

interface FlexiDisplayProps {
  winInvestment: number
  numberCombinations: number
  isUserInputAllowed?: boolean
}

export function FlexiDisplay({
  winInvestment,
  numberCombinations,
  isUserInputAllowed,
}: FlexiDisplayProps): JSX.Element {
  const calculatedFlexi = calculateFlexiAmount(winInvestment, numberCombinations)
  return (
    <FlexiDisplayStyled isActive={!!isUserInputAllowed}>
      <span>Flexi</span>{' '}
      <AmountStyled amount={calculatedFlexi}>{`${calculatedFlexi}%`}</AmountStyled>
    </FlexiDisplayStyled>
  )
}

interface MysteryFlexDisplayProps {
  divXAnnotation: string
}

export function MysteryFlexDisplay({ divXAnnotation }: MysteryFlexDisplayProps) {
  return (
    <FlexiDisplayStyled isActive={false}>
      <span>Flexi</span> <AmountStyled amount={1}>{divXAnnotation}</AmountStyled>
    </FlexiDisplayStyled>
  )
}
