import React from 'react'
import type { BetSlipResponse } from '@mobi/betslip/types'
import { Money } from '@core/Components/Text/Money'
import {
  ReceiptListItemTextStyled,
  ReceiptListItemStyled,
  ReceiptListStyled,
  ReceiptListRowStyled,
} from './Receipt.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'

export type BetslipReceiptProps = {
  responses: Immutable<BetSlipResponse[]>
}

export function BetslipReceipt({ responses }: BetslipReceiptProps): JSX.Element {
  const successfulBets = responses.filter(bet => bet.success)
  const unsuccessfulBets = responses.filter(bet => !bet.success)
  const allSuccess = successfulBets.length > 0 && unsuccessfulBets.length == 0

  const pluralization = successfulBets.length > 1 ? 'bets have been' : 'bet has been'

  return (
    <NoticeBox
      noticeBoxType={allSuccess ? NoticeBoxTypes.Success : NoticeBoxTypes.Warning}
      title={`Your ${pluralization} placed.`}
      hasBorder={true}
    >
      <BetslipReceiptDetails responses={responses} />
    </NoticeBox>
  )
}

function BetslipReceiptDetails({ responses }: BetslipReceiptProps): JSX.Element {
  const successfulBets = responses.filter(bet => bet.success)
  const unsuccessfulBets = responses.filter(bet => !bet.success)
  const totalCost = successfulBets.reduce(
    (total, bet) => (total += bet?.receipt?.amountPaid ?? 0),
    0
  )
  const ticketNumber =
    responses.length == 1 && successfulBets.length == 1
      ? successfulBets[0].receipt?.ticketNumber
      : false

  return (
    <ReceiptListStyled>
      <ReceiptListRowStyled data-tid-receipt-successful-bets=''>
        <ReceiptListItemStyled>Bets Placed</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>{successfulBets.length}</ReceiptListItemTextStyled>
      </ReceiptListRowStyled>
      {unsuccessfulBets.length > 0 && (
        <ReceiptListRowStyled data-tid-receipt-unsuccessful-bets=''>
          <ReceiptListItemStyled>Failed Bets</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>{unsuccessfulBets.length}</ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}
      <ReceiptListRowStyled data-tid-receipt-betcost=''>
        <ReceiptListItemStyled>Bet Cost</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>
          <Money amount={totalCost} />
        </ReceiptListItemTextStyled>
      </ReceiptListRowStyled>
      {ticketNumber && (
        <ReceiptListRowStyled data-tid-receipt-ticket=''>
          <ReceiptListItemStyled>Ticket</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>
            {successfulBets[0].receipt?.ticketNumber}
          </ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}
      {!ticketNumber && (
        <ReceiptListRowStyled>Please check Pending Bets for details</ReceiptListRowStyled>
      )}
    </ReceiptListStyled>
  )
}
