import { PoolDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/DataTransferObjects'
import { tap } from '@mobi/utils'
import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservablePool {
  constructor() {
    this.name = ko.observable<string>('')
    this.total = ko.observable<string>('')
    this.hasJackpot = ko.observable<boolean>(false)
    this.jackpotTotal = ko.observable<string>('')
    this.isAbandoned = ko.observable<boolean>(false)
    this.poolingIcon = ko.observable<string>('')
    this.raceNumbers = ko.observableArray<number>([])

    this.hasPoolingIcon = ko.observable<boolean>(false)
    this.isInternationalPool = ko.observable<boolean>(false)
    this.jurisdictionDescription = ko.observable<string>('')
  }

  public static fromDto(dto: PoolDataTransferObject): ObservablePool {
    return tap(new ObservablePool(), pool => {
      pool.name(dto.Name)
      pool.total(dto.Total)
      pool.hasJackpot(dto.HasJackpot)
      pool.jackpotTotal(dto.JackpotTotal)
      pool.isAbandoned(dto.IsAbandoned)
      pool.poolingIcon(dto.PoolingIcon)
      pool.raceNumbers(dto.RaceNumbers)
      pool.jurisdictionDescription(dto.JurisdictionDescription)
    })
  }

  hasRaceNumbers(): boolean {
    return this.raceNumbers && this.raceNumbers() && this.raceNumbers().length > 0
  }

  public merge(other: ObservablePool) {
    Guard.notNull(other)

    this.name(other.name())
    this.total(other.total())
    this.hasJackpot(other.hasJackpot())
    this.jackpotTotal(other.jackpotTotal())
    this.isAbandoned(other.isAbandoned())
    this.poolingIcon(other.poolingIcon())
    this.raceNumbers(other.raceNumbers())
    this.hasPoolingIcon(other.hasPoolingIcon())
    this.isInternationalPool(other.isInternationalPool())
    this.jurisdictionDescription(other.jurisdictionDescription())
  }

  public jurisdictionDescription: ko.Observable<string>
  public name: ko.Observable<string>
  public total: ko.Observable<string>
  public hasJackpot: ko.Observable<boolean>
  public jackpotTotal: ko.Observable<string>
  public isAbandoned: ko.Observable<boolean>
  public poolingIcon: ko.Observable<string>
  public raceNumbers: ko.ObservableArray<number>
  public hasPoolingIcon: ko.Observable<boolean>
  public isInternationalPool: ko.Observable<boolean>
}
