import React from 'react'

interface FlexProps {
  children?: React.ReactNode
  testId?: string
  flexDirection?: 'row' | 'column'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end'
  gap?: string
}

export const Flex = (props: FlexProps) => (
  <div
    data-testid={props.testId}
    style={{
      display: 'flex',
      justifyContent: props.justifyContent,
      flexDirection: props.flexDirection,
      flexWrap: props.flexWrap,
      gap: props.gap,
      alignItems: props.alignItems,
    }}
  >
    {props.children}
  </div>
)
