import React, { type ReactFragment } from 'react'
import { Text } from '@core/Components/Text'
import { ScratchedTextStyled, TipButtonStyled, TipInformationStyled } from './TipButton.styles'
import type { ScratchType } from '@mobi/api-types'
import { useFeature, usePriceChangeAnimation } from '@core/Utils/hooks'
import {
  PriceChangeIndicatorInsideStyled,
  PriceInnerStyled,
  PriceWrapperStyled,
} from '@core/Components/PriceChangeDisplay/PriceChangeDisplay.styles'
import { MatchedSilkImage } from '@core/Areas/Racing/Components/SilkImage/MatchedSilkImage'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import type { ImageSize } from '@core/Data/Racing'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export type Props = {
  number: number
  price: string
  isScratched: boolean
  toteScratchType: ScratchType | string
  isSuspended: boolean
  meetingCode: RaceCodesLowerCase
  imageUrl: string
  imageSize: ImageSize
  isRaceOpen: boolean
  onClick: () => void
}

export const TipButton = ({
  number,
  price,
  isScratched,
  toteScratchType,
  isSuspended,
  meetingCode,
  imageUrl,
  imageSize,
  isRaceOpen,
  onClick,
}: Props): JSX.Element => {
  const [state] = usePriceChangeAnimation(price)
  const disabled = !isRaceOpen || isScratched || isSuspended
  const isFieldSummaryV2Active = useFeature('FIELD_SUMMARY_V2')

  return (
    <TipButtonStyled
      aria-disabled={disabled}
      disabled={disabled}
      isDisabled={disabled}
      isSelected={false}
      role='button'
      aria-label={`Tip button ${number}`}
      data-testid={`tip-button-${number}`}
      data-scratched={isScratched}
      data-suspended={isSuspended}
      onClick={disabled ? undefined : onClick}
    >
      <div>
        {isFieldSummaryV2Active ? (
          <MatchedSilkImage starterNumber={number} />
        ) : (
          <SilkImage
            width={imageSize.width}
            height={imageSize.height}
            url={imageUrl}
            xOffset={number - 1}
            isDogsSilk={meetingCode === 'dogs'}
            breakpoints={[{ baseWidth: 16 }, { breakpoint: 'px400', baseWidth: 25 }]}
          />
        )}
      </div>

      <TipInformationStyled>
        <Text size='sm'>{number}</Text>

        <strong>
          {isScratched ? (
            <ScratchedTextStyled size='lg'>{getScratchedText(toteScratchType)}</ScratchedTextStyled>
          ) : (
            <PriceWrapperStyled
              isInline={true}
              isWidthDynamic={false}
              isSelected={false}
              isPriceChanging={isRaceOpen && !!state.wpChangeTypeState.win}
            >
              {isRaceOpen && (
                <PriceChangeIndicatorInsideStyled
                  arrowDirection={state.wpChangeTypeState.win}
                  light={false}
                  isWidthDynamic={false}
                />
              )}
              <PriceInnerStyled shouldForceColor={false} centerContent={true}>
                {price}
              </PriceInnerStyled>
            </PriceWrapperStyled>
          )}
        </strong>
      </TipInformationStyled>
    </TipButtonStyled>
  )
}

function getScratchedText(toteScratchType: ScratchType | string): ReactFragment | string {
  return toteScratchType === 'Late' ? 'LATE' : 'SCR'
}
