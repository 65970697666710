import { postLogout } from '@core/Areas/Login/helpers'
import React from 'react'

export const Logout = () => {
  React.useEffect(() => {
    postLogout()
  }, [])

  return null
}
