import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { ObservableFinisher } from '../Model/Observables/ObservableFinisher'
import { IFinisherCodeBuilder } from './IFinisherCodeBuilder'
import MissingStarterException from '../Exceptions/MissingStarterException'
import { IObservableFinisher } from '../Model/Observables/IObservableFinisher'
import SilkImageMapper from '../Mapping/SilkImageMapper'

export abstract class BaseFinisherCodeBuilder implements IFinisherCodeBuilder {
  addBaseInformation(
    model: ObservableFinisher,
    dto: dtos.FinisherDataTransferObject
  ): ObservableFinisher {
    if (!dto) throw new MissingStarterException('Missing data for an individual starter')

    model.type(dto.Type)
    model.name(dto.Name)
    model.number(dto.Number)
    model.placing(dto.Placing)
    model.isFixedOddsStarter(dto.IsFixedOddsStarter)
    model.starterPersonName(dto.StarterPersonName)
    model.trainerName(dto.TrainerName || '')
    model.isCountBack(dto.IsCountBack)
    model.toteWinDiv(dto.ToteWinDiv)
    model.totePlaceDiv(dto.TotePlaceDiv)
    model.toteWinProvDiv(dto.ToteWinProvDiv)
    model.totePlaceProvDiv(dto.TotePlaceProvDiv)
    model.displayPlaceDividend(dto.DisplayPlaceDividend)
    model.shouldDisplayPlaceDividend(dto.ShouldDisplayPlaceDividend)
    model.displayWinDividend(dto.DisplayWinDividend)
    model.hasPlacePool(dto.HasPlacePool)
    model.isScratched(dto.IsScratched)
    model.isScratchedToteAndFob(dto.IsScratchedToteAndFob)
    model.isFavourite(dto.IsFavourite)
    model.isEmergency(dto.IsEmergency)
    model.scratchType(dto.ScratchType)
    model.lastFour(dto.LastFour)
    model.isEmergency(dto.IsEmergency)
    model.emergencyNum(dto.EmergencyNumber)
    model.hasFixedOdds(dto.HasFixedOdds)
    model.rating(dto.Rating)
    model.isRiderChanged(dto.IsRiderChanged)
    model.spWinPrice(dto.SpWinPrice || 0)

    model.computeEmRes()

    model.isFormAvailable(!dto.IsFormDataEmpty)

    if (dto.HasFixedOdds && model.fixedOddsFinisherInfo) {
      model.fixedOddsFinisherInfo.winDiv(dto.FixedOddsFinisherInfo.WinDiv)
      model.fixedOddsFinisherInfo.placeDiv(dto.FixedOddsFinisherInfo.PlaceDiv)
      model.fixedOddsFinisherInfo.displayPlaceDividend(
        dto.FixedOddsFinisherInfo.DisplayPlaceDividend
      )
      model.fixedOddsFinisherInfo.displayWinDividend(dto.FixedOddsFinisherInfo.DisplayWinDividend)
      model.fixedOddsFinisherInfo.shouldDisplayPlaceDividend(
        dto.FixedOddsFinisherInfo.ShouldDisplayPlaceDividend
      )
      model.fixedOddsFinisherInfo.isScratched(dto.FixedOddsFinisherInfo.IsScratched)
      model.fixedOddsFinisherInfo.isSuspended(dto.FixedOddsFinisherInfo.IsSuspended)
      model.fixedOddsFinisherInfo.isFavourite(dto.FixedOddsFinisherInfo.IsFavourite)
      model.fixedOddsFinisherInfo.scratchedTime(dto.FixedOddsFinisherInfo.ScratchedTime)
      model.fixedOddsFinisherInfo.winDividendDeduction(
        dto.FixedOddsFinisherInfo.WinDividendDeduction
      )
      model.fixedOddsFinisherInfo.placeDividendDeduction(
        dto.FixedOddsFinisherInfo.PlaceDividendDeduction
      )
    } else {
      model.hasFixedOdds(false)
      if (dto.IsScratched) {
        model.isScratchedToteAndFob(dto.IsScratched)
      }
    }

    if (dto.SilkImages) {
      SilkImageMapper.map(dto.SilkImages, model)
    }

    return model
  }

  abstract build(dto: dtos.FinisherDataTransferObject): IObservableFinisher
}
