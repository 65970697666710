import React, { ReactNode } from 'react'
import { ExpandablePanelThemes, getExpandablePanelTheme } from '../ExpandablePanel/themes'
import {
  CompactPanelContainerStyled,
  CompactPanelContent,
  CompactPanelHeaderContentStyled,
  CompactPanelHeaderStyled,
} from './CompactPanel.styles'

export type Props = {
  themeName: ExpandablePanelThemes
  headerText: ReactNode
  headerIcon: ReactNode
  children?: ReactNode
}

export const CompactPanel = ({
  children,
  headerIcon,
  headerText,
  themeName,
}: Props): JSX.Element => {
  const theme = getExpandablePanelTheme(themeName)

  return (
    <CompactPanelContainerStyled
      backgroundColor={theme.content.background ?? ''}
      textColor={theme.content.text.color ?? ''}
    >
      <CompactPanelHeaderStyled>
        {headerIcon}

        <CompactPanelHeaderContentStyled>
          <strong>{headerText}</strong>
        </CompactPanelHeaderContentStyled>
      </CompactPanelHeaderStyled>
      {children && <CompactPanelContent>{children}</CompactPanelContent>}
    </CompactPanelContainerStyled>
  )
}
