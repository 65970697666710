import React from 'react'
import { HeaderStyled } from './FavouriteNumbers.styles'
import { NumberSelection } from './NumberSelection'
import { PoolSelection } from './PoolSelection'
import { FavouriteNumbersBetSummary } from './FavouriteNumbersBetSummary'
import { ClearSelections } from './driver'
import { HeaderWithLoadingIndicator } from './HeaderWithLoader'

export const EasyBetTab = (): JSX.Element => {
  React.useEffect(() => {
    return ClearSelections
  })

  return (
    <div data-testid='easy-bet' style={{ marginTop: '1em' }}>
      <HeaderWithLoadingIndicator title={'SELECT NUMBERS'}></HeaderWithLoadingIndicator>
      <NumberSelection />
      <HeaderStyled>SELECT POOL DATES</HeaderStyled>
      <PoolSelection />
      <HeaderStyled>BET SUMMARY</HeaderStyled>
      <FavouriteNumbersBetSummary mode={'easyBet'} />
    </div>
  )
}
