import * as ko from 'knockout'
import { IAllUpViewModel } from './IAllUpViewModel'
import { BettingInformation } from '../../../Model/BettingInformation'
import container from '../../../IOC/inversify.config'
import ObservableRacePage from '../../../Model/Observables/ObservableRacePage'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import template from './allup.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('allup-bettype')) {
    ko.components.register('allup-bettype', {
      viewModel: {
        createViewModel: (params: {
          data: ObservableRacePage
          selections: ko.ObservableArray<IObservableStarter>
          context: { bettingContext: BettingInformation }
        }): IAllUpViewModel => {
          const viewModel = container.get<IAllUpViewModel>('IAllUpViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
