import React from 'react'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { ToteSelection, BetSlipItem } from '@mobi/betslip/types'
import {
  isToteSelection,
  isNoveltyBetType,
  isAllUpSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { Investment, InvestmentsWrapperStyled } from '@mobi/betslip/Components/Common/Investment'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'

export const ToteInvestment: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const { itemId, investmentType } = useAppSelector(selectActiveInvestment) || {}
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  const { investment, selection, shouldAllowPlaceInvestment } = item
  const shouldDisplayFlexi = isToteSelection(selection) && isNoveltyBetType(selection.betType)
  const shouldDisplayNumberOfCombinations = isAllUpSelection(selection)

  const isItemInputActive = itemId === item.id
  const isEditable = !item.receipt && workflowStatus === 'ready' && !isBusy

  const toteBetType = (item.selection as ToteSelection).betType
  // const hasSecondaryDisplay =
  //   shouldAllowPlaceInvestment || shouldDisplayFlexi || shouldDisplayNumberOfCombinations

  let flexi
  if (shouldDisplayFlexi) {
    flexi = item.numberOfCombinations
      ? Math.floor(((investment.win.value * 100) / item.numberOfCombinations) * 100) / 100
      : 0
  }

  return (
    <InvestmentsWrapperStyled isStacked>
      <Investment
        value={investment.win.value || 0}
        itemId={item.id}
        label={toteBetType === 'Win & Place' ? 'Win' : 'Spend'}
        investmentType='Win'
        display='stacked'
        isEditable={isEditable}
        isActive={isItemInputActive && investmentType === 'Win'}
      />

      {shouldAllowPlaceInvestment && (
        <Investment
          value={investment.place.value || 0}
          itemId={item.id}
          label='Place'
          investmentType='Place'
          display='stacked'
          isEditable={isEditable}
          isActive={isItemInputActive && investmentType === 'Place'}
        />
      )}

      {shouldDisplayFlexi && (
        <Investment
          value={flexi || 0}
          itemId={item.id}
          label='Flexi'
          investmentType='Flexi'
          display='stacked'
          valueType='percentage'
          isEditable={isEditable}
          isActive={isItemInputActive && investmentType === 'Flexi'}
        />
      )}

      {shouldDisplayNumberOfCombinations && (
        <Investment
          value={item.numberOfCombinations || 0}
          itemId={item.id}
          label='Combos'
          investmentType='Combos'
          display='stacked'
          valueType='number'
          isEditable={isEditable}
          isActive={isItemInputActive && investmentType === 'Combos'}
        />
      )}
    </InvestmentsWrapperStyled>
  )
}

// =============
// Local Helpers
// =============

// const editBetslipItem = ({
//   item,
//   investmentType = 'win',
//   isEditable,
// }: {
//   item: BetSlipItem
//   investmentType: Extract<keyof BetSlipItem['investment'], 'win' | 'place'>
//   isEditable: boolean
// }) => {

//   if (!isFatalErrorType(item.betErrorType) && isEditable) {
//     if (item.investment.win.isBonusBet) {
//       SetActiveInvestment(InvestmentType.Win)
//     } else if (item.investment.place.isBonusBet) {
//       SetActiveInvestment(InvestmentType.Place)
//     } else {
//       SetActiveInvestment(investmentType)
//     }

//     EditBetslipItem(item)
//     let shouldShowSuperPicks = !(isFobPropositionSelection(item.selection) && item.selection.marketCode === FixedOddsMarketCodes.Concession)

//     // TODO: Checks all items if special is used already
//     shouldShowSuperPicks = !isSpecialUsed(item, items)

//     if (item.investment.bonusBet) {
//       ToggleBonusBetUsage()
//       SetBonusBet(item.investment.bonusBet)
//     }

//     ToggleBonusCashUsage(item.isUsingBonusCash ?? true)
//   }
// }
