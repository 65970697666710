import React from 'react'

type TicketDisplayContext =
  | {
      type: 'account-activity' | 'race-card'
      data?: never
    }
  | {
      type: 'share-preview'
      data: { shouldHideCost: boolean }
    }
  | {
      type: 'pending-bets-drawer'
      data: { isSkyRaceMatch: boolean }
    }

/** Handles different display variations for Ticket child components
 *  @default type: 'account-activity' */
export const TicketDisplayContext = React.createContext<TicketDisplayContext>({
  type: 'account-activity',
})
