import { GridCell } from '@mobi/component-library/Common/Grid'
import { ErrorMessageStyled } from '@core/Components/Messages/Messages.styles'
import styled from '@emotion/styled'

export const SameRaceMultiPriceDetailContainerStyled = styled(GridCell)({
  fontSize: '1.4rem', // 1.4 * 10 = 14px
  display: 'flex',
  paddingBottom: '3px', // 'quickbet' button below also has padding/margin
})

export const SameRaceMultiPriceTitleStyled = styled('div')({
  flex: 1, // siblings equal width
})

export const SameRaceMultiPriceDetailStyled = styled('div')({
  display: 'flex',
  flex: 1, // siblings equal width
  justifyContent: 'space-between', // children left/right justified
})

export const SameRaceMultiKeyStyled = styled('span')({
  fontWeight: 'normal',
})

export const SameRaceMultiValueStyled = styled('span')({
  fontWeight: 'bold',
  marginLeft: '3px',
})

export const SameRaceMultiErrorMessageStyled = styled(ErrorMessageStyled)({
  width: 'unset',
  textAlign: 'center',
})
