import React from 'react'
import { BetslipButton as BetslipButtonLegacy } from './Components/BetslipButton/BetslipButton'
import { BetslipButtonNew } from './Components/BetslipButtonNew'
import { useFeature } from '@core/Utils/hooks/useFeature'

export const BetslipButton: React.FC = () => {
  const isNewBetSlipDesignActive = useFeature('NEW_BET_SLIP_DESIGN')

  return isNewBetSlipDesignActive ? <BetslipButtonNew /> : <BetslipButtonLegacy />
}
