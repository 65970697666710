import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import {
  getSingleTicketTitle,
  getTitleOdds,
  shouldUseFallbackIcon,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { HeaderSelectionInfoStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import { LinkWrapper, SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { RunnerNumber } from '@core/Areas/RaceCard/Components'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { SingleSilkStyled } from './SingleSingle.styles'
import { TitleWithSuperPickIcon } from '../Common/TitleWithSuperPickIcon'

export const SingleSingle: React.FC<{
  ticket: Ticket
}> = ({ ticket }) => {
  const ticketContextType = React.useContext(TicketDisplayContext).type
  const isPreviewMode = ticketContextType === 'share-preview'

  const leg = ticket.legs[0]
  const contestType = meetingIdToContestType(leg.fixtureId)
  const isDogsSilk = contestType === 'dogs'
  const isStartingPrice = leg.betType === FixedOddsMarketDisplayNames.SP
  const shouldHideSilk = shouldUseFallbackIcon(leg, ticket.planSeq)
  const shouldRenderSubHeader = ticket.productName !== leg.eventName

  const productName =
    (leg.betType === FixedOddsMarketDisplayNames.H2H && `${leg.betType} - ${leg.runners}`) ||
    (isStartingPrice && 'Starting Price') ||
    ticket.productName

  const runnerCode = meetingIdToContestType(leg.fixtureId, true)

  const { title, runnerNumber } = getSingleTicketTitle(
    leg,
    ticket.planSeq,
    !isPreviewMode && !!runnerCode
  )

  const ticketReturn =
    ticket.return !== null && (!isStartingPrice || (isStartingPrice && ticket.return > 0))
      ? ` @ ${getTitleOdds(ticket.investment.lines)}`
      : ''

  const runnerNameForBlackbook =
    runnerNumber && runnerCode && leg.selections[0]?.selectionDetails[0]?.acceptorDetails[0]?.name

  return (
    <HeaderSelectionInfoStyled>
      <SingleSilkStyled isDogs={!shouldHideSilk && isDogsSilk} data-tid-ticket-header-silk>
        {shouldHideSilk ? (
          <SilkFallback contestType={contestType} />
        ) : (
          <SilkImageContained
            fixtureDate={leg.fixtureDate}
            fixtureId={leg.fixtureId}
            raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
            acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
          />
        )}
      </SingleSilkStyled>

      <div>
        <TitleWithSuperPickIcon isSpecial={ticket.isSpecial} specials={ticket.specialInformation}>
          {runnerNumber && runnerCode && runnerNameForBlackbook && (
            <>
              <RunnerNumber
                starterType={runnerCode}
                starterName={runnerNameForBlackbook}
                starterNumber={runnerNumber}
              />
              {' - '}
            </>
          )}
          {title + ticketReturn}
        </TitleWithSuperPickIcon>

        <span data-testid='bet-product-name'>{productName}</span>

        {shouldRenderSubHeader && (
          <LinkWrapper
            fixtureId={ticket.legs[0].fixtureId}
            fixtureDate={ticket.legs[0].fixtureDate}
            raceNumber={ticket.legs[0].raceNumber}
            label={ticket.legs[0].eventName}
          />
        )}
      </div>
    </HeaderSelectionInfoStyled>
  )
}
