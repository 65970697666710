import React from 'react'
import ReactDOM from 'react-dom'
import { NoticeBox } from '@core/Components/NoticeBox'
import { SameRaceMultiPrice, SelectionPriceProps } from './SameRaceMultiPrice'
import {
  SameRaceMultiBettingDrawerContainerStyled,
  SameRaceMultiBettingDrawerNoticeBoxPositionerStyled,
} from './SameRaceMultiBettingDrawer.styles'
import { QueryClient, QueryClientProvider } from 'react-query'

export interface BettingDrawerProps extends SelectionPriceProps {
  selectionsErrorMessage: string
}

export function SameRaceMultiBettingDrawer({
  isValid,
  handleQuickbetSelected,
  meetingDate,
  meetingId,
  raceNumber,
  starterSelections,
  selectionsErrorMessage,
}: BettingDrawerProps): JSX.Element {
  const queryClient = new QueryClient()

  return ReactDOM.createPortal(
    <SameRaceMultiBettingDrawerContainerStyled open={isValid || !!selectionsErrorMessage}>
      {/* display selections error irrespective of whether valid selections exist */}
      {selectionsErrorMessage && (
        <SameRaceMultiBettingDrawerNoticeBoxPositionerStyled>
          <NoticeBox title='This combination is not possible' hasBorder={true} />
        </SameRaceMultiBettingDrawerNoticeBoxPositionerStyled>
      )}

      {/* only render selection price is valid selections exist */}
      {isValid && (
        <QueryClientProvider client={queryClient}>
          <SameRaceMultiPrice
            isValid={isValid}
            meetingDate={meetingDate}
            meetingId={meetingId}
            raceNumber={raceNumber}
            starterSelections={starterSelections}
            handleQuickbetSelected={handleQuickbetSelected}
          />
        </QueryClientProvider>
      )}
    </SameRaceMultiBettingDrawerContainerStyled>,
    document.body
  )
}
