import React from 'react'
import { FormulaStyled, FormulaListStyled, FormulaListItemStyled } from './Formula.styles'
import { FormulaButton } from './FormulaButton'
import { FormulaCombination } from './types'

interface FormulaProps {
  formulaCombinations: FormulaCombination[]
  onClickFormula(formula: number, wasSelected: boolean): void
}

export function Formula({ formulaCombinations, onClickFormula }: FormulaProps) {
  return (
    <FormulaStyled>
      <FormulaListStyled>
        {formulaCombinations.map(comb => (
          <FormulaListItemStyled key={comb.formula}>
            <FormulaButton formulaCombination={comb} onClick={onClickFormula} />
          </FormulaListItemStyled>
        ))}
      </FormulaListStyled>
    </FormulaStyled>
  )
}
