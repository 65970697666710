import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export enum NoticeBoxTypes {
  Info,
  Error,
  Success,
  Warning,
}

interface NoticeBoxStyledProps {
  noticeBoxType?: NoticeBoxTypes
  hasBorder?: boolean
  isDesktop?: boolean
}

export const NoticeBoxStyled = styled('div')<NoticeBoxStyledProps>(
  {
    lineHeight: 1.2,
    margin: '0 0 1rem',
    padding: '1rem',
  },

  ({ hasBorder, isDesktop }) => ({
    border: hasBorder ? `0.1rem solid ${hexColors.veryLightGrey}` : undefined,
    backgroundColor: hasBorder ? hexColors.white : undefined,
    fontSize: isDesktop ? '1.4rem' : '1.2rem',
  })
)

export const NoticeBoxSingleStyled = styled(NoticeBoxStyled)<NoticeBoxStyledProps>({
  padding: '0.5rem',
})

export const NoticeBoxIconStyled = styled('div')({
  display: 'flex',
  width: '4.2rem',
})

export const NoticeBoxTitleStyled = styled('p')({
  fontSize: '1.4rem', // overriding mobi styles
  margin: '0 0 1rem',

  // NoticeBoxTitle can be rendered by itself (no subtitle or children)
  '&:last-child': {
    marginBottom: 0,
  },
})

export const NoticeBoxChildContentWrapperStyled = styled('div')({
  fontSize: '1.2rem',

  // As we allow children to be passed into the <NoticeBox> component,
  // we need to override mobi styles at least for paragraphs. Any other kind of content
  // or component used as a child of NoticeBox will require its own styling.
  // Source of the problem: `scss/base/_legacy-globals.scss`
  '& p': {
    fontSize: 'inherit',
    textAlign: 'inherit',
    margin: '1rem 0',

    '&:last-child': {
      marginBottom: 0,
    },
  },
})
