import React, { type FC } from 'react'
import { PaymentMethod } from '@mobi/api-types'
import { InfoBox } from '@mobi/component-library/Common/V2'

type ExpiredCard = Omit<PaymentMethod, 'isExpired'> & {
  isExpired: true
}

type DisabledCard = Omit<PaymentMethod, 'isDebitCard'> & {
  isDebitCard: false
}

type InvalidCard = ExpiredCard | DisabledCard

type Props = {
  card: InvalidCard
}

export const InvalidCardAlert: FC<Props> = ({ card }) => {
  return (
    <InfoBox color='orange' size='md' aria-labelledby='invalid-card-text' aria-live='polite'>
      <span id='invalid-card-text'>{getAlertText(card)}</span>
    </InfoBox>
  )
}

InvalidCardAlert.displayName = 'InvalidCardAlert'

function getAlertText(card: InvalidCard) {
  if (card.isExpired) {
    return "Your primary card has expired. Please set a new primary card in 'Manage Cards'."
  }

  if (!card.isDebitCard) {
    return "Your primary card is disabled as it is a credit card. Please set a new primary card in 'Manage Cards'."
  }

  // card is `never` at this point
  throw new Error('Given card is valid')
}
