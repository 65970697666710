import React from 'react'
import { Observable } from 'rx'
import { useDispatch } from 'react-redux'
import { List } from 'immutable'

import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import {
  QuickEditTitleStyled,
  QuickEditComboTextStyled,
  QuickEditComboTitleStyled,
  QuickEditSubTitleStyled,
  QuickEditFormulaInvestmentStyled,
  QuickEditFormulaCostStyled,
} from './QuickEdit.styles'
import {
  state$ as quickEditState$,
  ToggleBonusBet,
  SetBonusBet,
  BonusBet,
  QuickEditState,
  ToggleBonusCash,
} from './driver'
import { BonusBetButton } from './BonusBet/BonusBetButton'
import { BonusBetList } from './BonusBet/BonusBetList'
import { FormulaCostAmountStyled } from '../MultiFormula/MultiFormula.styles'
import { UpdateMultiBetSpend, UpdateMultiBetSpendData } from '../../signals'
import { HeaderStyled } from '@core/Areas/Quickbet/Components/Header/Components/Common/Header.styles'
import { Swiper } from '@core/Components/Swiper'
import { Keypad } from '@core/Components/Keypad'
import { KeypadModes } from '@core/Components/Keypad/KeyPress'
import { QuickbetBodyStyled, QuickbetActionsStyled } from '@core/Areas/Quickbet/Quickbet.styles'
import { observeImmutable } from '@core/Components/HOCs'
import { Campaign, state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  BetInvestmentStyled,
  BetInvestmentGroupStyled,
  InvestmentStyled,
  InvestmentContainerStyled,
} from '@core/Areas/Quickbet/Components/BetInvestment/BetInvestment.styles'
import {
  LegInfoWrapperStyled,
  SingleLegTypeStyled,
} from '@core/Areas/Quickbet/Components/LegInfo/LegInfo.styles'
import { QuickbetButtonStyled } from '@core/Areas/Quickbet/Components/Buttons/QuickbetButton.styles'
import { FormulaFields } from '@core/Areas/Betslip/Components/MultiFormula/MultiFormula'
import { BalanceInfoBar } from '@core/Areas/Quickbet/Components/BalanceBar/BalanceBar'
import { closeModal } from '@core/Components/Modal'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'
import { isCampaignRedeemableWithBettingType } from '@core/Areas/Quickbet/helpers/campaignHelper'
import { Campaigns } from '@core/Areas/Quickbet/Components/Campaigns/Campaigns'
import { BonusBetListItem } from '@core/Areas/Quickbet/Components/BonusBetList/BonusBetListItem'
import { BetslipState, state$ as betslipDriverState$ } from '@core/Areas/Betslip/driver'
import { useFeature } from '@core/Utils/hooks/useFeature'

export enum QuickEditContentTypes {
  Multi = 'Multi',
  MultiFormula = 'MultiFormula',
}

interface QuickEditProps {
  contentType: QuickEditContentTypes
  title: string
  subTitle: string
  combos?: number
  formulaField?: FormulaFields
}

type QuickEditStateProps = {
  value: number
  isUsingBonusBet: boolean
  isUsingBonusCash: boolean
  bonusBet: BonusBet | null
  loggedIn: boolean
  accountNumber?: number | null
  accountBalance: number | null
  bonusBetBalance: number | null
  bonusCashBalance: number | null
  activeCampaigns: List<Campaign> | null
  hasFormula: boolean
  isEligibleForBonusBet: boolean
}

type QuickEditComponentProps = QuickEditProps & QuickEditStateProps

const quickEdiState$ = Observable.combineLatest(
  quickEditState$,
  userAccountState$,
  betslipDriverState$,
  (quickEditRecord, userAccountRecord, betslipRecord): QuickEditStateProps => {
    const quickEditState = quickEditRecord.toJS() as QuickEditState
    const betslipState = betslipRecord.toJS() as BetslipState
    const multi = betslipState.multiInvestment

    return {
      activeCampaigns: userAccountRecord.activeCampaigns,
      value: quickEditState.value,
      isUsingBonusBet: quickEditState.isUsingBonusBet,
      isUsingBonusCash: quickEditState.isUsingBonusCash,
      bonusBet: quickEditState.bonusBet,
      loggedIn: userAccountRecord.isLoggedIn ?? false,
      accountNumber: userAccountRecord.accountNumber,
      accountBalance: userAccountRecord.accountBalance,
      bonusBetBalance: userAccountRecord.bonusBetBalance,
      bonusCashBalance: userAccountRecord.bonusCashBalance,
      isEligibleForBonusBet: !!(
        userAccountRecord.activeCampaigns?.count &&
        !userAccountRecord.activeCampaigns?.find(
          x =>
            x.rewardType != 'BonusBetReward' &&
            isCampaignRedeemableWithBettingType(x, 'fixed-odds-racing')
        )
      ),
      hasFormula: 0 < multi.f1 + multi.f2 + multi.f3 + multi.f4 + multi.f5,
    }
  }
)

export const QUICK_EDIT_MODAL = 'QUICK_EDIT'

export function QuickEditComponent({
  value,
  loggedIn,
  accountNumber,
  accountBalance,
  bonusBetBalance,
  bonusCashBalance,
  isUsingBonusBet,
  isUsingBonusCash,
  bonusBet,
  title,
  subTitle,
  combos = 1,
  formulaField = FormulaFields.f1,
  contentType,
  activeCampaigns,
  hasFormula,
}: QuickEditComponentProps) {
  const dispatch = useDispatch()
  const mustUseBonusCash = contentType !== QuickEditContentTypes.Multi

  const activeBonusCashCampaign = activeCampaigns
    ?.sort(x => x.rewardExpiry.valueOf())
    ?.find(x => x.rewardType === 'BonusCashReward')

  const hasBonusBet = activeCampaigns?.some(campaign => campaign.rewardType === 'BonusBetReward')
  const hasBonusCash = activeCampaigns?.some(
    campaign =>
      campaign.rewardType === 'BonusCashReward' &&
      (isCampaignRedeemableWithBettingType(campaign, 'fixed-odds-racing') ||
        isCampaignRedeemableWithBettingType(campaign, 'tote-racing'))
  )

  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')

  React.useEffect(() => {
    if (isBonusCashImprovementsActive && hasBonusCash) {
      ToggleBonusCash(false)
    }
  }, [isBonusCashImprovementsActive, hasBonusCash])

  React.useEffect(() => {
    if (loggedIn) {
      fetchCampaignsAsync()
    }
  }, [loggedIn])

  const handleDoneClick = () => {
    const data: UpdateMultiBetSpendData = {
      field: contentType === QuickEditContentTypes.Multi ? 'value' : formulaField,
      value,
      bonusBetId: bonusBet?.campaignId,
      isBonusCash: mustUseBonusCash ? true : isUsingBonusCash,
    }
    UpdateMultiBetSpend(data)
    dispatch(
      closeModal({
        id: QUICK_EDIT_MODAL,
      })
    )
  }

  const isCentsIncrementActive = useFeature('CENTS_INCREMENTS_BET')

  const shouldAllowDecimals =
    contentType === QuickEditContentTypes.Multi ||
    contentType === QuickEditContentTypes.MultiFormula

  const toggleBonusBet = () => {
    ToggleBonusBet()
    SetBonusBet(null)
  }

  const trackBonusBetClick = () => {
    const isActivated = isUsingBonusBet
    trackBonusBetEvent(
      isActivated ? keys.betslipBonusBetButtonDeactivated : keys.quickbetBonusBetButtonActivated,
      {
        accountNumber: accountNumber ?? '',
        accountBalance: accountBalance,
        bonusBetBalance: bonusBetBalance,
        bonusCashBalance: bonusCashBalance,
      }
    )
  }

  let shouldDisplayBonusButton = false
  if (mustUseBonusCash) {
    shouldDisplayBonusButton = false
  } else if (!!hasBonusBet || !!hasBonusCash) {
    if (isUsingBonusCash) {
      if (hasBonusCash) {
        shouldDisplayBonusButton = false
      } else {
        shouldDisplayBonusButton = true
      }
    } else {
      shouldDisplayBonusButton = true
    }
  }

  return (
    <>
      <HeaderStyled>
        <BalanceInfoBar />
        <Grid>
          <GridRow padding='0 0 0.5rem 0'>
            <GridCell valign='middle'>
              <QuickEditTitleStyled>{title}</QuickEditTitleStyled>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell valign='middle'>
              <QuickEditSubTitleStyled>{subTitle}</QuickEditSubTitleStyled>
            </GridCell>
          </GridRow>
        </Grid>
      </HeaderStyled>

      {contentType === QuickEditContentTypes.Multi && (
        <BetInvestmentStyled>
          <Grid padding='0.4rem'>
            <GridRow>
              <GridCell width='auto'>
                <BetInvestmentGroupStyled isActive={true}>
                  <LegInfoWrapperStyled>
                    <SingleLegTypeStyled isActive={true}>Spend</SingleLegTypeStyled>
                  </LegInfoWrapperStyled>
                  <InvestmentContainerStyled isBonusBet={!!bonusBet} isActive={true}>
                    <InvestmentStyled shouldFontResize={false} amount={value} />
                  </InvestmentContainerStyled>
                </BetInvestmentGroupStyled>
              </GridCell>
            </GridRow>
            {shouldDisplayBonusButton && (
              <GridRow>
                <GridCell>
                  <BonusBetButton
                    toggleBonusBet={toggleBonusBet}
                    trackBonusBetClick={trackBonusBetClick}
                  />
                </GridCell>
              </GridRow>
            )}
          </Grid>
        </BetInvestmentStyled>
      )}

      {contentType === QuickEditContentTypes.MultiFormula && (
        <QuickEditFormulaInvestmentStyled>
          <Grid padding='0.4rem'>
            <GridCell width='50%' valign='middle'>
              <Grid>
                <GridCell width='50%'>
                  <QuickEditComboTitleStyled>Combos</QuickEditComboTitleStyled>
                </GridCell>
                <GridCell width='50%' align='center'>
                  <QuickEditComboTextStyled>x {combos}</QuickEditComboTextStyled>
                </GridCell>
              </Grid>
            </GridCell>
            <GridCell width='50%'>
              <div style={{ position: 'relative' }}>
                <BetInvestmentGroupStyled isActive={true}>
                  <InvestmentStyled shouldFontResize={false} amount={value} />
                </BetInvestmentGroupStyled>
                <QuickEditFormulaCostStyled isQuickEdit={true}>
                  Cost{' '}
                  <FormulaCostAmountStyled isQuickEdit={true}>
                    ${(value * combos).toFixed(2)}
                  </FormulaCostAmountStyled>
                </QuickEditFormulaCostStyled>
              </div>
            </GridCell>
          </Grid>
        </QuickEditFormulaInvestmentStyled>
      )}

      <Campaigns
        shouldDisplay={mustUseBonusCash || (isUsingBonusCash && !isUsingBonusBet)}
        bettingType='fixed-odds-racing'
        handleRemoveBonusCashButtonClick={() => {
          ToggleBonusCash(false)
          trackBonusBetEvent(keys.betslipBonusCashRemoved, {
            accountBalance,
            accountNumber: accountNumber || '',
            bonusBetBalance,
            bonusCashBalance,
          })
        }}
        showRemoveButton={!mustUseBonusCash && !hasFormula}
      />

      <QuickbetBodyStyled>
        {!isUsingBonusBet && (
          <Swiper usePagination={true}>
            <Keypad disabled={false} allowDecimals={shouldAllowDecimals} />
            <Keypad
              disabled={false}
              allowDecimals={shouldAllowDecimals}
              keypadMode={KeypadModes.Numeric}
              isIncrementAvailable={isCentsIncrementActive}
            />
          </Swiper>
        )}
        {isUsingBonusBet && (
          <GridCell>
            {activeBonusCashCampaign && (
              <BonusBetListItem
                id='bonus-cash'
                name='Bonus Cash'
                expiry={activeBonusCashCampaign.rewardExpiry}
                amount={bonusCashBalance || 0}
                isBonusCash={true}
                selected={isUsingBonusCash}
                handleClick={() => {
                  if (!isUsingBonusCash) {
                    // We're turning bonus cash on, so clear any selected bonus bet.
                    ToggleBonusBet(false)
                    SetBonusBet(null)
                  }

                  ToggleBonusCash()
                }}
              />
            )}
            <BonusBetList setBonusBet={SetBonusBet} />
          </GridCell>
        )}
      </QuickbetBodyStyled>

      <QuickbetActionsStyled>
        <QuickbetButtonStyled type='button' onClick={handleDoneClick} disabled={false}>
          Done
        </QuickbetButtonStyled>
      </QuickbetActionsStyled>
    </>
  )
}

export const QuickEdit = observeImmutable<QuickEditStateProps, QuickEditProps>(
  quickEdiState$,
  function ({ record, ...props }) {
    return <QuickEditComponent {...record} {...props} />
  }
)
