import React from 'react'
import { FeatureFlags } from '@mobi/settings'
import { useHostContext } from '../Areas/Deposit/HostContext'

type Features = keyof typeof FeatureFlags

type ExtractPartialType<TKey extends string> = TKey extends Features
  ? (typeof FeatureFlags)[TKey]['defaultValue'] extends boolean
    ? boolean
    : (typeof FeatureFlags)[TKey]['defaultValue']
  : never

type FeatureValueType<
  TKey extends Features,
  _TValue = ExtractPartialType<TKey>,
> = _TValue extends boolean
  ? boolean
  : _TValue extends number
    ? number
    : _TValue extends string
      ? string
      : _TValue

export function useHostContextFeature<T extends Features>(feature: T): FeatureValueType<T> {
  const { key } = FeatureFlags[feature]
  const { features } = useHostContext()

  const [featureFlagValue, setFeatureFlagValue] = React.useState(() => {
    return FeatureFlags[feature].defaultValue
  })

  React.useEffect(() => {
    const fetchData = async () => {
      const featureFlagValue = await features?.hasFeature(FeatureFlags[feature].key)
      if (featureFlagValue !== undefined) {
        setFeatureFlagValue(featureFlagValue)
      }
    }
    fetchData()
  }, [feature, features, key])

  return featureFlagValue as FeatureValueType<T>
}
