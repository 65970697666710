import React from 'react'
import { Notification } from './Components/Notification/Notification'
import { Banner } from '@core/Components/Banner'
import { HomeNavigation } from './Components/HomeNavigation'
import { HomeNextRaces } from './Components/HomeNextRaces'
import { FeaturedItems } from './Components/FeaturedItems'
import { resetMatchedRaceBetTypePersist } from '@classic/Betting-v2/Components/Pages/Workflow/BettingStateManagerViewModel'
import { ActivitySummary } from '@core/Areas/ResponsibleWagering/ActivitySummary'
import { HomeNextSuperPicks } from './Components/HomeNextSuperPicks'
import { HomeFooter } from './Components/Footer/HomeFooter'
import {
  HomeMainContainerStyled,
  BannerCardStyled,
  HomeScrollWrapperStyled as ScrollerWrapper,
} from './Home.styles'
import { TrendingBetsToggled } from './Components/TrendingBets/TrendingBetsToggled'
import { useShareIncomingListener } from '@core/Services/ShareIncoming/useShareIncomingListener'
import { useDisableEdgeBackNav } from '@core/Utils/useDisableEdgeBackNav'

export const Home = (): JSX.Element => {
  useShareIncomingListener()
  useDisableEdgeBackNav()

  React.useEffect(() => {
    resetMatchedRaceBetTypePersist()
  }, [])

  return (
    <HomeMainContainerStyled background='grey'>
      <Notification isDesktop={false} />

      <BannerCardStyled>
        <Banner areaName='Home' />
      </BannerCardStyled>

      <HomeNavigation />

      <ScrollerWrapper>
        <HomeNextRaces />
        <HomeNextSuperPicks />
        <FeaturedItems />
        <TrendingBetsToggled flagKey='POPULAR_BETS_DISPLAY' type='Single' />{' '}
        <TrendingBetsToggled flagKey='TRENDING_SAME_RACE_MULTI' type='SameRaceMulti' />
      </ScrollerWrapper>

      <ActivitySummary />

      <HomeFooter />
    </HomeMainContainerStyled>
  )
}
