import React from 'react'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { recordify, TypedRecord } from 'typed-immutable-record'
import { observeImmutable } from '@core/Components/HOCs/observe'

interface PanelState {
  expanded: boolean
}

interface StateRecord extends TypedRecord<StateRecord>, PanelState {}

export function ExpandablePanelFactory<TProps>(
  ExpandablePanelComponent: React.StatelessComponent<TProps> | React.ComponentClass<TProps>,
  expanded: boolean = false
): React.ComponentClass<TProps> {
  const ID = btoa(Math.random().toString())
  const defaultState = recordify<PanelState, StateRecord>({
    expanded,
  })
  const toggleExpanded = createSignal(`ToggleExpanded-${ID}`)
  const driver = (state = defaultState, signal: Signal) => {
    switch (signal.tag) {
      case toggleExpanded: {
        return state.set('expanded', !state.expanded)
      }
      default:
        return state
    }
  }
  const state$ = attachDriver({ driver, path: `ExpandPanel-${ID}` })

  return observeImmutable<StateRecord, TProps>(state$, props => {
    return (
      <ExpandablePanelComponent
        {...props}
        headerClicked={toggleExpanded}
        expanded={props.record.expanded}
      >
        {props.children}
      </ExpandablePanelComponent>
    )
  })
}
