import React from 'react'
import type { FieldSummary } from '@core/Areas/RaceCardFixedOnly/Types/FieldSummary'
import { RaceFieldSummaryResults } from '@core/Areas/Racing/Components/FieldSummary/Races/RaceFieldSummaryResults'
import { DogFieldSummaryResults } from '@core/Areas/Racing/Components/FieldSummary/Dogs/DogFieldSummaryResults'
import { TrotFieldSummaryResults } from '@core/Areas/Racing/Components/FieldSummary/Trots/TrotFieldSummaryResults'

type Props = {
  summary: FieldSummary
  raceCode: string
}

const raceCodeComponents: Record<string, React.FC<{ summary: FieldSummary }>> = {
  Races: RaceFieldSummaryResults,
  Dogs: DogFieldSummaryResults,
  Trots: TrotFieldSummaryResults,
}

export const FieldsResults: React.FC<Props> = ({ summary, raceCode }) => {
  if (!summary) return null

  const FieldSummaryResultsComponent = raceCodeComponents[raceCode] ?? null

  if (FieldSummaryResultsComponent) {
    return <FieldSummaryResultsComponent summary={summary} />
  }
  return null
}
