import ko from 'knockout'
import Guard from '@classic/AppUtils/Framework/Guard'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { toMoney } from '@mobi/utils/money'

export class ScratchedDeductionsViewModel {
  public shouldDisplayDeductions: ko.PureComputed<boolean>
  public hasDeductions: ko.PureComputed<boolean>

  private winDeduction: ko.Observable<number | null>
  private placeDeduction: ko.Observable<number | null>

  constructor(params: {
    winDeduction: ko.Observable<number | null>
    placeDeduction: ko.Observable<number | null>
    bettingContext?: BettingInformation
  }) {
    Guard.notNull(params.winDeduction)
    Guard.notNull(params.placeDeduction)

    this.winDeduction = params.winDeduction
    this.placeDeduction = params.placeDeduction

    this.shouldDisplayDeductions = ko.pureComputed(() =>
      params.bettingContext ? params.bettingContext.selectedBetType().isWinPlace() : true
    )

    this.hasDeductions = ko.pureComputed(() =>
      Boolean(this.winDeduction() || this.placeDeduction())
    )
  }

  public winDeductionDisplay() {
    return this.winDeduction() !== null ? toMoney(this.winDeduction() || 0) : ''
  }

  public placeDeductionDisplay() {
    return this.placeDeduction() !== null ? toMoney(this.placeDeduction() || 0) : ''
  }
}
