import { Banner, BannerApiResource } from '../types'

export function mapApiBanner(resource: BannerApiResource): Banner {
  return {
    name: resource.Name,
    image: resource.Image.File.Url,
    imageWide: resource.ImageWide.File.Url,
    imageText: resource.Image.Title,
    url: resource.Link,
    desktopUrl: resource.DesktopLink,
    internalTrackingCode: resource.InternalTrackingCode || undefined,
    abGroupingTokenPresent: !!resource.AbGroupingToken,
  }
}
