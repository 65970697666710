import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'

export const SettingItem: React.FC<{
  label: string
  desciption?: string
  isLoading?: boolean
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}> = ({ label, desciption = '', isLoading = false, rightComponent, bottomComponent, onClick }) => {
  const settingNameKebabCase = label.toLowerCase().replace(/ /g, '-')

  return (
    <WrapperStyled onClick={onClick} data-testid={`setting-item-${settingNameKebabCase}`}>
      <div>
        <h3>
          {label} {isLoading && <SpinnerInlineStyled color='dark' />}
        </h3>
        {desciption}

        <div>{bottomComponent}</div>
      </div>

      <div>{rightComponent}</div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,
  padding: '1.5rem 0',
  margin: '0 1rem',
  fontSize: '1.2rem',
  color: hexColors.dimGrey,

  '&:last-of-type': { borderBottom: 0 },

  '> div:first-of-type': {
    flex: 1,

    '> h3': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '2.4rem',
      padding: 0,
      margin: 0,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      textAlign: 'left',
      textTransform: 'capitalize',
      background: 'none',
      color: hexColors.nero,

      span: { marginLeft: '0.5rem' },
    },

    // Bottom Component
    '> div': {
      marginTop: '0.5rem',
    },
  },

  // Right Component
  '> div:last-of-type': {
    paddingLeft: '0.5rem',

    ':empty': { display: 'none' },
  },
})
