import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIconStyled } from '@mobi/component-library/Common/Icon/Icon.styles'

const MoonAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '50%': {
    transform: 'rotate(15deg)',
  },
  '100%': {
    transform: 'rotate(0deg)',
  },
})

const StarAnimation = keyframes({
  '0%': {
    opacity: '0',
  },
  '100%': {
    opacity: '1',
  },
})

const Moon = styled('g')({
  animationName: MoonAnimation,
  animationDuration: '6s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  transformOrigin: '12.5px 15.15px 0',
})

const Star1 = styled('g')({
  animationName: StarAnimation,
  animationDelay: '3s',
  animationDuration: '5s',
  animationTimingFunction: 'linear',
  animationIterationCount: '1',
})

const Star2 = styled('g')({
  animationName: StarAnimation,
  animationDelay: '5s',
  animationDuration: '4s',
  animationTimingFunction: 'linear',
  animationIterationCount: '1',
})

export const Night = () => {
  return (
    <SvgIconStyled
      width='40'
      height='40'
      viewBox='0 0 64 64'
      fill='none'
      size='3rem'
      data-testid='night'
    >
      <defs>
        <filter id='blur' width='200%' height='200%'>
          <feGaussianBlur in='SourceAlpha' stdDeviation='3' />
          <feOffset dx='0' dy='4' result='offsetblur' />
          <feComponentTransfer>
            <feFuncA type='linear' slope='0.05' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g filter='url(#blur)' id='night'>
        <g transform='translate(20,20)'>
          <Star1>
            <polygon
              fill='orange'
              points='3.3,1.5 4,2.7 5.2,3.3 4,4 3.3,5.2 2.7,4 1.5,3.3 2.7,2.7'
              stroke='none'
              strokeMiterlimit='10'
            />
          </Star1>
          <Star2>
            <polygon
              fill='orange'
              points='3.3,1.5 4,2.7 5.2,3.3 4,4 3.3,5.2 2.7,4 1.5,3.3 2.7,2.7'
              stroke='none'
              strokeMiterlimit='10'
              transform='translate(20,10)'
            />
          </Star2>
          <Moon>
            <path
              d='M14.5,13.2c0-3.7,2-6.9,5-8.7   c-1.5-0.9-3.2-1.3-5-1.3c-5.5,0-10,4.5-10,10s4.5,10,10,10c1.8,0,3.5-0.5,5-1.3C16.5,20.2,14.5,16.9,14.5,13.2z'
              fill='orange'
              stroke='orange'
              strokeLinejoin='round'
              strokeWidth='2'
            />
          </Moon>
        </g>
      </g>
    </SvgIconStyled>
  )
}
