import React from 'react'
import { SelectField, SelectOption } from '@mobi/component-library/Common/Select'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ValidationErrorStyled } from '@mobi/component-library/Common/Input'
import { DateSelectionLabelStyled } from './DateSelection.styles'

const months: SelectOption[] = [
  { value: '', label: 'MMMM' },
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
]

interface DateSelectionProps {
  name?: string
  day?: string | null // if null is passed date field is disabled
  month?: string
  year?: string
  errorMessage?: string | boolean
  onChange: (selectedDate: Date | null) => void
  onBlur?: () => void
}

interface DateSelectionState {
  day: string
  month: string
  year: string
}

export class DateSelection extends React.Component<DateSelectionProps, DateSelectionState> {
  constructor(props: DateSelectionProps) {
    super(props)

    this.state = {
      day: this.props.day || '',
      month: this.props.month || '',
      year: this.props.year || '',
    }
  }

  public onChangeDay = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState(
      {
        day: event.currentTarget.value,
      },
      () =>
        callOnChange({
          onChange: this.props.onChange,
          state: this.state,
          isDayDisabled: this.props.day === null,
        })
    )
  }

  public onChangeMonth = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState(
      {
        month: event.currentTarget.value,
      },
      () =>
        callOnChange({
          onChange: this.props.onChange,
          state: this.state,
          isDayDisabled: this.props.day === null,
        })
    )
  }

  public onChangeYear = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState(
      {
        year: event.currentTarget.value,
      },
      () =>
        callOnChange({
          onChange: this.props.onChange,
          state: this.state,
          isDayDisabled: this.props.day === null,
        })
    )
  }

  public render() {
    return (
      <div>
        {!!this.props.name && (
          <DateSelectionLabelStyled>{this.props.name}</DateSelectionLabelStyled>
        )}
        <Grid padding='0.5rem'>
          <GridRow>
            <GridCell width='25%'>
              <SelectField
                name='day'
                hideLabel={true}
                disabled={this.props.day === null}
                options={getDaySelectOptions(this.state.month, this.state.year)}
                value={this.state.day || ''}
                onChange={this.onChangeDay}
                onBlur={this.props.onBlur}
              />
            </GridCell>
            <GridCell>
              <SelectField
                name='month'
                hideLabel={true}
                options={months}
                value={this.state.month || ''}
                onChange={this.onChangeMonth}
                onBlur={this.props.onBlur}
              />
            </GridCell>
            <GridCell width='30%'>
              <SelectField
                name='year'
                hideLabel={true}
                options={getYearSelectOptions()}
                value={this.state.year || ''}
                onChange={this.onChangeYear}
                onBlur={this.props.onBlur}
              />
            </GridCell>
          </GridRow>
        </Grid>
        {this.props.errorMessage && (
          <ValidationErrorStyled>{this.props.errorMessage}</ValidationErrorStyled>
        )}
      </div>
    )
  }
}

type CallOnChangeOpts = {
  state: DateSelectionState
  onChange: (selectedDate: Date | null) => void
  isDayDisabled: boolean
}

export function callOnChange({ onChange, state, isDayDisabled }: CallOnChangeOpts): void {
  let selectedDate: Date | null = null

  if (state.day && state.month && state.year) {
    selectedDate = new Date(`${state.year}-${state.month}-${state.day}`)
  }

  if (isDayDisabled && state.month && state.year) {
    selectedDate = new Date(`${state.year}-${state.month}-1`) // pass day as 1
  }
  onChange(selectedDate)
}

export function getDaySelectOptions(month: string, year: string) {
  // Based on whether month and year is provided populate days
  const currentYear = new Date().getFullYear().toString()
  if (!month) {
    return getDayDefaultSelectOptions()
  }
  if (!!month && !year) {
    return getUpdatedDaySelectOptions(month, currentYear)
  }
  return getUpdatedDaySelectOptions(month, year)
}

export function getDayDefaultSelectOptions(): SelectOption[] {
  const defaultSelectOptions = [{ value: '', label: 'DD' }]
  // Add 31 days as default selection options
  Array.apply(null, Array(31)).map((x, i) => {
    const day = (i + 1).toString()
    defaultSelectOptions.push({
      value: day,
      label: day,
    })
  })
  return defaultSelectOptions
}

export function getUpdatedDaySelectOptions(month: string, year: string): SelectOption[] {
  const selectOptions = [{ value: '', label: 'DD' }]
  const numOfDays = new Date(parseInt(year), parseInt(month), 0).getDate() // Find days in the given month and year
  // Add days of the given month and year to the selection options
  Array.apply(null, Array(numOfDays)).map((x, i) => {
    const monthVal = (i + 1).toString()
    selectOptions.push({
      value: monthVal,
      label: monthVal,
    })
  })
  return selectOptions
}

export function getYearSelectOptions(): SelectOption[] {
  const selectOptions = [{ value: '', label: 'YYYY' }]
  const currentYear = new Date().getFullYear()
  // Add current year to the selection options
  selectOptions.push({
    value: currentYear.toString(),
    label: currentYear.toString(),
  })
  // Add next 10 years to the selection options
  Array.apply(null, Array(10)).map((x, i) => {
    const futureYear = (currentYear + (i + 1)).toString()
    selectOptions.push({
      value: futureYear,
      label: futureYear,
    })
  })

  return selectOptions
}
