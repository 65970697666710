import dayjs from 'dayjs'
import type { Selection, ToteSelection, RaceDetails } from '@mobi/betslip/types'
import {
  isRaceDetails,
  isFobDetails,
  isFobMatchedSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { ISelectionMadeCommand } from '../../../Core/Processors/ISelectionMadeContext'
import { ButtonsSelection } from '../../../Core/UIElements/ButtonsSelection'
import LegSelectionContext from '../../../Core/Processors/LegSelectionContext'
import { ButtonSelectionType } from '../../../Core/UIElements/ButtonSelectionType'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import { RemoveSingleBet } from '@core/Areas/Betslip/signals'
import {
  isStarterScratched,
  processEnhancedBetSelection,
} from '../../../Selection/SelectionViewModel'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { groupBy } from '@mobi/utils/functions'
import { MAX_LEGS_IN_RACE_MULTI, MIN_LEGS_IN_MULTI } from '@core/Areas/Betslip/constants'

type AddSelectionCallback = (isQuickbet: boolean, winPlaceStarterOverride: number) => void

type FilteredBetslipItem = BetslipItem & { starterNumber: number }

export const synchronizeSelections = (
  isEnhancedBetslip: boolean,
  raceInformation: ObservableRacePage,
  betslipItems: BetslipItem[]
) => {
  if (!isEnhancedBetslip) return

  // extract relevant betslip items
  let raceBetslipItems = getRaceBetslipItems(raceInformation, betslipItems)

  // iterate through the race starters updating the selection state based on any relevant betslip items
  let raceStarters = raceInformation.getStartersForRace(
    raceInformation.meetingInformation.selectedRace.raceNumber()
  )()
  raceStarters.forEach(starter => {
    const starterNumber = starter.number()
    const starterSelection = starter.selection?.() as ButtonsSelection | undefined
    if (!starterSelection) return // guard against undefined selection, which can happen if race hasn't been fully initialized (despite having starters!)

    // iterate through the betslip items looking for matching starters
    // - unfortunately we need to use acceptorNumber (in the context of the selected race) because acceptor.key isn't unique
    //   e.g. re-bet acceptorKey=raceNumber-acceptorNumber, instead of the usual acceptorKey=raceKey-acceptorNumber
    // - for a given starter, there can be multiple betslip items, e.g. fixed and tote both added
    const starterBetslipItems = raceBetslipItems
      .filter(item => item.starterNumber === starterNumber)
      .map(item => item)

    // handle add
    starterBetslipItems.forEach(item => {
      // don't process starter if it's scratched or already selected
      // - e.g. if betslip has the starter selection twice, we don't want to select it twice on the starter as this will remove the selection
      const isBetslipItemFixed = item.bettingType == 'fixed-odds-racing'
      if (
        isStarterScratched(starter, isBetslipItemFixed) ||
        (isBetslipItemFixed && starterSelection.hasFob()) ||
        (!isBetslipItemFixed && starterSelection.hasTote())
      ) {
        return
      }

      // reuse the existing SelectionViewModel to process the selection, i.e. treat this just like a button click
      processEnhancedBetSelection(starterSelection, isBetslipItemFixed)
    })

    // handle remove, either of two scenarios...
    // - starter no longer has any betslip item(s), i.e. previously tote OR fixed
    // - starter has betslip item(s) that don't match the current selection, i.e. previously tote AND fixed
    if (
      starterBetslipItems.every(item => item.bettingType == 'fixed-odds-racing') &&
      starterSelection.hasTote()
    ) {
      processEnhancedBetSelection(starterSelection, false)
    }
    if (
      starterBetslipItems.every(item => item.bettingType == 'tote-racing') &&
      starterSelection.hasFob()
    ) {
      processEnhancedBetSelection(starterSelection, true)
    }
  })
}

export const processSelection = (
  isEnhancedBetslip: boolean,
  command: ISelectionMadeCommand,
  raceInformation: ObservableRacePage,
  betslipItems: BetslipItem[],
  addSelection: AddSelectionCallback
) => {
  try {
    if (!isEnhancedBetslip) return

    const selection = command.starter.selection() as ButtonsSelection
    const clickedButton = (command.context as LegSelectionContext)?.buttonSelectionType

    // determine whether the user is adding or removing a selection
    // - determined by comparing the button click type with the button selection state... the latter of which has ALREADY been updated (elsewhere) based on the click
    let isAdd =
      (clickedButton === ButtonSelectionType.Fob && selection.hasFob()) ||
      (clickedButton === ButtonSelectionType.Tote && selection.hasTote())

    const starterNumber = command.starter.number()

    if (isAdd) addSelection(betslipItems.length == 0, starterNumber)
    else removeSelection(raceInformation, starterNumber, clickedButton, betslipItems)
  } catch (e) {
    // deliberately swallowed
    // eslint-disable-next-line no-console
    console.log('processStarterSelection failed', e)
  }
}

export function getMultiBetslipItems(isEnhancedBetslip: boolean, betslipItems: BetslipItem[]) {
  if (!isEnhancedBetslip) return { isValidMulti: false, multiBetslipItems: [] }

  // item must be in multi, i.e. fixed odds and checked
  let multiBetslipItems = betslipItems.filter(item => item.isInMulti)

  // every betslip item must be on a unique race (matched) or event (unmatched, challenges, etc)
  const betslipItemsGroupedByRaceOrEvent = groupBy(multiBetslipItems, item => {
    if (isRaceDetails(item.selectionDetails)) return item.selectionDetails.races[0].key
    else if (isFobDetails(item.selectionDetails)) return item.selectionDetails.event

    // should not be possible
    return ''
  })

  let groupedRaces = Object.entries(betslipItemsGroupedByRaceOrEvent)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let hasNoSameRaceBetslipItems = groupedRaces.every(([_, races]) => races.length == 1)

  // min 2 and max 10 legs allowed
  let hasSufficientBetslipItems =
    multiBetslipItems.length >= MIN_LEGS_IN_MULTI &&
    multiBetslipItems.length <= MAX_LEGS_IN_RACE_MULTI

  let isEveryRaceOpen = multiBetslipItems.every(item => {
    if (isRaceDetails(item.selectionDetails)) {
      // matched race
      return isRaceOpen(item.selectionDetails.races[0].raceTime)
    } else if (isFobDetails(item.selectionDetails)) {
      // unmatched race
      return isRaceOpen(item.selectionDetails.eventStartTime)
    }
    return false
  })

  return {
    isValidMulti: hasSufficientBetslipItems && hasNoSameRaceBetslipItems && isEveryRaceOpen,
    multiBetslipItems,
  }
}

function isRaceOpen(raceTime: string | Date): boolean {
  // an open race is considered to be any which has an AST that is NOT more than 15minutes in the past
  // - ideally we would use the race status (open, close, abandoned, etc), but unfortunately the betslip race info
  //   isn't automatically updated (e.g. via tabtouch-push)
  let raceTimeDayjs = dayjs(raceTime)
  return raceTimeDayjs.diff(dayjs(), 'minute') >= -15
}

function removeSelection(
  raceInformation: ObservableRacePage,
  starterNumber: number,
  selectionType: ButtonSelectionType,
  betslipItems: BetslipItem[]
) {
  // extract relevant betslip items relevant to the selected race
  let raceBetslipItems = getRaceBetslipItems(raceInformation, betslipItems)

  // identify betslip item(s) via acceptorNumber (in the context of the selected race) to be removed
  // - unfortunately we need to use acceptorNumber (in the context of the selected race) because acceptor.key isn't unique
  //   e.g. re-bet acceptorKey=raceNumber-acceptorNumber, instead of the usual acceptorKey=raceKey-acceptorNumber
  const betslipItemsToRemove = raceBetslipItems.filter(
    item =>
      item.starterNumber === starterNumber &&
      (selectionType == ButtonSelectionType.Fob
        ? isFobMatchedSelection(item.selection)
        : isWinPlaceToteSelection(item.selection))
  )

  // remove relevant betslip item(s) via their quickbet id, i.e. guid
  betslipItemsToRemove.forEach(matchedItem => RemoveSingleBet(matchedItem))
}

function getRaceBetslipItems(
  raceInformation: ObservableRacePage,
  betslipItems: BetslipItem[]
): FilteredBetslipItem[] {
  const raceKey = raceInformation.meetingInformation.selectedRace.key()

  const result: FilteredBetslipItem[] = []
  betslipItems.forEach(item => {
    // ignore multi-race items
    if ((item.selectionDetails as RaceDetails).races?.length != 1) return
    // ignore unrelated race
    if ((item.selectionDetails as RaceDetails).races[0].key != raceKey) return

    // ignore any non-W&P items, unmatched, or multiple selections
    // - FOO/FR/etc. are automatically stripped as they are not considered 'fob-matched'
    if (isFobMatchedSelection(item.selection))
      result.push({ ...item, starterNumber: item.selection.acceptorNumber })
    else if (isWinPlaceToteSelection(item.selection)) {
      if ((item.selection as ToteSelection).numberOfCombinations == 1)
        result.push({
          ...item,
          starterNumber: (item.selectionDetails as RaceDetails).acceptors[0].number,
        })
    }
  })

  return result
}

// =============
// Local Helpers
// =============

function isWinPlaceToteSelection(selection: Selection | null): boolean {
  if (!isToteSelection(selection)) return false
  return selection.betType === 'Win & Place'
}
