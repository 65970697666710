import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Button } from '@mobi/component-library/Common/Buttons'
import { deleteRunnerFromBlackbook } from '@core/Areas/Blackbook/helpers/deleteRunnerFromBlackbook'
import type { BlackbookEntryRacingToday } from '@core/Areas/Blackbook/types'
import {
  trackBlackbookAddCommentClicked,
  trackBlackbookEntryDeleteClicked,
} from '@core/Areas/Blackbook/analytics'
import { addOrEditBlackbookComment } from '@core/Areas/Blackbook/helpers/addOrEditBlackbookComment'
import { Comments } from '@core/Areas/Blackbook/Components/Comments'
import { ShareButton } from './Components/ShareButton'

const enum LocalConstants {
  actionBarClassName = 'blackbook__details_actions',
}

export const BlackbookEntryDetails: React.FC<BlackbookEntryDetailsProps> = ({
  id,
  type,
  name,
  hasComments,
  wrapperRef,
  contentRef,
  children,
}) => {
  const handleDelete = () => {
    deleteRunnerFromBlackbook({ id, name, type })
    trackBlackbookEntryDeleteClicked()
  }

  const handleAddComment = () => {
    addOrEditBlackbookComment({ id, name, type })
    trackBlackbookAddCommentClicked()
  }

  return (
    <WrapperStyled ref={wrapperRef} data-testid='blackbook-entry-details'>
      <div ref={contentRef}>
        {children}

        {hasComments && type === 'Jockeys' && <hr />}
        {hasComments && <Comments runnerId={id} type={type} name={name} />}

        <div className={LocalConstants.actionBarClassName}>
          <Button
            onClick={handleAddComment}
            color='link'
            data-testid='blackbook-runner-add-comment-button'
          >
            <Icon type='comment' size='1.3rem' /> Add Comment
          </Button>

          <Button onClick={handleDelete} color='link' data-testid='blackbook-runner-remove-button'>
            <Icon type='trash' size='1.3rem' /> Delete
          </Button>

          <ShareButton name={name} type={type} />
        </div>
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: hexColors.white,
  overflow: 'hidden',
  transition: 'height 400ms ease',
  willChange: 'height',
  height: 0,

  // Spacer for Jockeys
  '> div > hr': {
    border: 0,
    margin: 0,
    padding: 0,
    minHeight: '0.5rem',
  },

  // Button action bar
  ['.' + LocalConstants.actionBarClassName]: {
    display: 'flex',
    minHeight: '4rem',
  },
})

// =====
// Types
// =====

type BlackbookEntryDetailsProps = {
  isJockey: boolean
  id: BlackbookEntryRacingToday['Id']
  name: BlackbookEntryRacingToday['StarterName']
  type: BlackbookEntryRacingToday['Code']
  hasComments: boolean
  wrapperRef: React.RefObject<HTMLDivElement>
  contentRef: React.RefObject<HTMLDivElement>
  children?: React.ReactChild
}
