import React from 'react'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { toMoney } from '@mobi/utils/money'
import {
  AccountBalanceHeaderStyled,
  AccountBalanceTitleStyled,
  AccountBalanceAmountStyled,
} from './AccountBalanceHeader.styles'

export const AccountBalanceHeader = ({ amount }: { amount: number }) => {
  return (
    <AccountBalanceHeaderStyled>
      <AccountBalanceTitleStyled>
        <Icon name='DuocolorBank' size={'1.6rem'} />
        Account Balance
      </AccountBalanceTitleStyled>
      <AccountBalanceAmountStyled>{toMoney(amount)}</AccountBalanceAmountStyled>
    </AccountBalanceHeaderStyled>
  )
}
