import React from 'react'

import { observeImmutable } from '../../../../../Components/HOCs'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { QuickEditState, state$ as quickEditState$ } from '../driver'
import { Observable } from 'rx'
import {
  BonusBetListComponent,
  BonusBetListExternalProps,
  BonusBetListStateProps,
} from '@core/Areas/Quickbet/Components/BonusBetList/BonusBetList'

const state$ = Observable.combineLatest(
  userAccountState$,
  quickEditState$,
  (userAccountState, quickEditState): BonusBetListStateProps => {
    const quickEdit = quickEditState.toJS() as QuickEditState
    return {
      isBusy: false,
      canBet: true,
      campaigns: userAccountState.activeCampaigns
        ?.filter(x => x.rewardType == 'BonusBetReward')
        .toArray(),
      selectedBonusBetCampaignId: quickEdit.bonusBet?.campaignId,
      hasSelectedSuperPick: false,
      isEachWay: false,
    }
  }
)

export const BonusBetList = observeImmutable<BonusBetListStateProps, BonusBetListExternalProps>(
  state$,
  ({ record: state, ...externalProps }) => {
    return state.canBet ? <BonusBetListComponent {...externalProps} {...state} /> : null
  }
)
