import styled from '@emotion/styled'
import { ButtonStyled } from '@mobi/component-library/Common/Buttons'

export const GridButtonStyled = styled(ButtonStyled)({
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  whiteSpace: 'pre-line',
  height: '4rem',
})

export const RefreshButtonStyled = styled('button')({
  margin: '-1.4rem',
  padding: '1.4rem',
  background: 'none',
  border: 'none',
})
