import React from 'react'
import anime from 'animejs'
import { layering } from '@mobi/component-library/Theme/Common'
import Transition, { type TransitionStatus } from 'react-transition-group/Transition'
import { observeImmutable } from '../HOCs'
import { type OverlayStateRecord, state$, type OverlayState } from './OverlayDriver'
import { OverlayReset } from './signals'
import { OverlayStyled } from './Overlay.styles'

const ANIMATION_DURATION = 150
const ANIMATION_OPACITY = { entering: [0, 1], exiting: [1, 0] }
const ANIMATION_TRANSLATEY = { entering: ['3rem', 0], exiting: [0, '3rem'] }

type ChildProps = {
  status: TransitionStatus
  layer: typeof layering.overlayLow | typeof layering.overlayHigh
}

class OverlayChild extends React.Component<ChildProps> {
  private overlayRef = React.createRef<HTMLDivElement>()

  public componentDidUpdate() {
    const currentStatus = this.props.status

    if (currentStatus === 'entering' || currentStatus === 'exiting') {
      anime({
        targets: this.overlayRef.current,
        opacity: {
          value: ANIMATION_OPACITY[currentStatus],
          duration: ANIMATION_DURATION / 2,
        },
        translateY: {
          value: ANIMATION_TRANSLATEY[currentStatus],
        },
        easing: 'easeOutQuart',
        duration: ANIMATION_DURATION,
        delay: 20,
      })
    }
  }

  public componentWillUnmount() {
    OverlayReset()
  }

  public render() {
    return (
      <OverlayStyled layer={this.props.layer} ref={this.overlayRef}>
        {this.props.children}
      </OverlayStyled>
    )
  }
}

export const OverlayComponent = ({ isOpen, Component, layer }: OverlayState): JSX.Element => (
  <Transition in={isOpen} timeout={ANIMATION_DURATION} unmountOnExit={true}>
    {status => {
      return (
        <OverlayChild layer={layer} status={status}>
          {Component && <Component />}
        </OverlayChild>
      )
    }}
  </Transition>
)

/** Used by Betslip, Search */
export const Overlay = observeImmutable<OverlayStateRecord, {}>(
  state$,
  ({ record: { Component, isOpen, layer } }) => (
    <OverlayComponent Component={Component} isOpen={isOpen} layer={layer} />
  )
)
