import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import FirstFourValidator from '@classic/Betting-v2/Validation/FirstFourValidator'
import CheckBoxSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/CheckBoxSelectionResultProcessor'
import { BetType } from '../Betting/BetType'
import type { IConfigureBetType } from './IConfigureBetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class FirstFourConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.First4,
      name: 'First 4',
      supportsFlexi: true,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'first4-template',
      betSelectionTemplate: 'quad-checkbox-selection-template',
      multiBet: false,
      multiSelections: true,
      checkBoxCount: 4,
      fieldCheckBoxCount: 4,
      sameAsCheckBoxCount: 3,
      processor: new CheckboxSelectionProcessor(4),
      validator: new FirstFourValidator(),
      selectionResultProcessor: new CheckBoxSelectionResultProcessor(4),
      legs: 1,
    })
  }
}
