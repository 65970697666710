import React from 'react'
import { InputLabelStyled, ValidationErrorStyled } from '../Input'
import { OptionStyled, SelectBoxStyled } from './SelectField.styles'

export type SelectOption = string | { value: string; label: string }

export interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  hideLabel?: boolean
  options?: SelectOption[]
  placeholder?: string
  disablePlaceholder?: boolean
  errorMessage?: string | boolean
  childrenLast?: boolean
}

function createOption(option: SelectOption) {
  const { value, label } = typeof option === 'string' ? { value: option, label: option } : option
  return (
    <OptionStyled key={value} value={value}>
      {label}
    </OptionStyled>
  )
}

export function SelectField({
  name,
  hideLabel,
  options = [],
  required,
  onChange,
  errorMessage,
  children,
  placeholder,
  disablePlaceholder,
  childrenLast = false,
  ...selectProps
}: SelectFieldProps): JSX.Element {
  return (
    <div>
      {!hideLabel && (
        <InputLabelStyled htmlFor={name}>
          {name}
          <span>{required ? '*' : null}</span>
        </InputLabelStyled>
      )}
      <SelectBoxStyled
        {...selectProps}
        id={name}
        onChange={onChange}
        required={required}
        error={errorMessage}
      >
        {!childrenLast && children}
        {placeholder && (
          <option value='' disabled={disablePlaceholder}>
            {placeholder}
          </option>
        )}
        {options.map(createOption)}
        {childrenLast && children}
      </SelectBoxStyled>

      {errorMessage && <ValidationErrorStyled>{errorMessage}</ValidationErrorStyled>}
    </div>
  )
}
