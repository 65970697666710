import React from 'react'
import { dayjs } from '@mobi/utils'
import { toTitleCase } from '@mobi/utils/string'
import { Icon } from '@mobi/component-library/Common/Icon'
import { TimeToJumpComponent } from '@core/Areas/NextEvents/Components/TimeToJump/TimeToJump'
import { BuildImageUrlWithSizesProps, getImageUrlWithSizes } from '@core/Components/SilkImage/utils'
import { RaceDetail, RacingCode } from '@core/Data/StructuredSearch/StructuredSearchResult'
import {
  buildSameRaceMultiRaceUri,
  getNextRaceTimeDisplayOptions,
} from '@core/Areas/Racing/helpers'
import { trackTrendingBetSelected } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { TrendingBetFilterEnum } from './TrendingBetsFilter'
import { IconTypes } from './TrendingBetsList'
import {
  silkHeight,
  TrendingBetCard,
  TrendingBetCardHeader,
  TrendingBetCardHeaderLink,
  TrendingBetCardBody,
  TrendingBetCardAcceptor,
  TrendingBetCardGreyhoundsSilkImage,
  TrendingBetCardSilkImage,
  TrendingBetCardSameRaceMultiAcceptorName,
  TrendingBetCardSameRaceMultiAcceptorPosition,
  TrendingBetCardFooter,
  TrendingBetCardQuickbetButton,
  TrendingBetCardSilkAndName,
} from './TrendingSameRaceMultiBetTile.styles'
import { AcceptorResult, TrendingBet } from '../types'

export function TrendingSameRaceMultiBetTile({
  bet,
  index,
  sorting,
  acceptors,
}: {
  bet: TrendingBet
  index: number
  sorting: TrendingBetFilterEnum
  acceptors: AcceptorResult
}): JSX.Element | null {
  const betAcceptorLegIds = bet.acceptorLegIds

  // all acceptors are on the Same Race, so just pick first
  const race = acceptors[betAcceptorLegIds[0].acceptorId].acceptor.race

  const nextEvent = getNextRaceTimeDisplayOptions(race.advertisedStartTime)

  const [shouldShowDay, setShouldShowDay] = React.useState<boolean>(() => nextEvent.showDay)

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const { showDay } = getNextRaceTimeDisplayOptions(race.advertisedStartTime)
      setShouldShowDay(current => (showDay !== current ? showDay : current))
    }, 1_000)

    return () => window.clearInterval(interval)
  }, [race.advertisedStartTime])

  try {
    const someParams = {
      meetingDate: race.fixture.date,
      meetingId: race.fixture.id,
      raceNumber: race.number,
      raceNo: race.number,
      isTrots: race.fixture.contestType === RacingCode.Trots,
    }
    const acceptorsForUrl = betAcceptorLegIds
      .map(
        acceptor =>
          `${acceptors[acceptor.acceptorId].acceptor.numberOrBox}-${parseInt(acceptor.legType)}`
      )
      .join(',')

    const linkToRace = buildSameRaceMultiRaceUri({ ...someParams })
    const linkToRaceWithSelection = buildSameRaceMultiRaceUri({
      ...someParams,
      starters: acceptorsForUrl,
      source: 'trending-bets-srm',
    })

    const startTime = new Date(race.advertisedStartTime)

    const acceptorData = betAcceptorLegIds
      .map(acceptorLegId => {
        const acceptorData = acceptors[acceptorLegId.acceptorId].acceptor
        return {
          silkProps: computeSilkProps(
            someParams,
            acceptorData.numberOrBox,
            race.fixture.contestType
          ),
          numberOrBox: acceptorData.numberOrBox,
          name: acceptorData.name,
          sameRaceMultiPosition: Number(acceptorLegId.legType),
        }
      })
      .sort(sortSameRaceMultiAcceptors)

    const raceName = `R${race.number} - ${race.fixture.name}`

    return (
      <TrendingBetCard
        data-tid-home-trending-bets='home-trending-bet-item'
        data-testid='trending-bet-item'
      >
        <TrendingBetCardHeader>
          <Icon
            size='2rem'
            type={IconTypes[race.fixture.contestType as keyof IconTypes]}
            color='#512D6D'
          />
          <TrendingBetCardHeaderLink
            href={linkToRace}
            onClick={() => handleClick(bet, acceptors, index, sorting, race, 'race name')}
          >
            {raceName}
          </TrendingBetCardHeaderLink>
          <TimeToJumpComponent
            size='1rem'
            advertisedStartTime={startTime}
            showCountDown={!shouldShowDay}
            showDay={shouldShowDay}
            showDayBeforeTime={true}
          />
        </TrendingBetCardHeader>

        <TrendingBetCardBody>
          {acceptorData.map(({ silkProps, numberOrBox, name, sameRaceMultiPosition }) => {
            const acceptorName = `${numberOrBox} - ${toTitleCase(name)}`
            return (
              <TrendingBetCardAcceptor key={name}>
                <TrendingBetCardSilkAndName>
                  {silkProps.isDogsSilk ? (
                    <TrendingBetCardGreyhoundsSilkImage {...silkProps} />
                  ) : (
                    <TrendingBetCardSilkImage {...silkProps} />
                  )}
                  <TrendingBetCardSameRaceMultiAcceptorName>
                    {acceptorName}
                  </TrendingBetCardSameRaceMultiAcceptorName>
                </TrendingBetCardSilkAndName>
                <TrendingBetCardSameRaceMultiAcceptorPosition>
                  {sameRaceMultiPositionToString(sameRaceMultiPosition)}
                </TrendingBetCardSameRaceMultiAcceptorPosition>
              </TrendingBetCardAcceptor>
            )
          })}
        </TrendingBetCardBody>

        <TrendingBetCardFooter>
          <TrendingBetCardQuickbetButton
            onClick={() => handleClick(bet, acceptors, index, sorting, race, 'quickbet button')}
            href={linkToRaceWithSelection}
          >
            Quickbet{bet?.price?.dividend && ` @ $${bet?.price?.dividend}`}
          </TrendingBetCardQuickbetButton>
        </TrendingBetCardFooter>
      </TrendingBetCard>
    )
  } catch {
    // it shouldn't happen, but we don't want any code/data issues causing exceptions to bubble up back to the parent (e.g. home page)
    return null
  }
}

const sameRaceMultiPositionToString = (sameRaceMultiPosition: number) =>
  sameRaceMultiPosition === 1 ? 'WIN' : `TOP${sameRaceMultiPosition}`

const handleClick = (
  bet: TrendingBet,
  allAcceptors: AcceptorResult,
  index: number,
  sorting: TrendingBetFilterEnum,
  race: RaceDetail,
  element: string
) => {
  const timeToJump = dayjs(race.advertisedStartTime).diff(dayjs(), 'second')
  const acceptors = getAcceptorsForBet(bet, allAcceptors)

  trackTrendingBetSelected({
    bet,
    acceptors,
    timeToJump,
    index,
    sorting,
    element,
    trendingType: 'same race multi',
  })
}

export const getAcceptorsForBet = (bet: TrendingBet, acceptors: AcceptorResult) => {
  const result: AcceptorResult = {}

  bet.acceptorLegIds.forEach(legId => {
    result[legId.acceptorId] = acceptors[legId.acceptorId]
  })

  return result
}

export const computeSilkProps = (
  someParams: BuildImageUrlWithSizesProps,
  numberOrBox: number,
  contestType: RacingCode
) => {
  const imageUrl = getImageUrlWithSizes(someParams).larger
  const url = imageUrl.url
  let { height, width } = imageUrl.size
  const ratio = silkHeight / height
  width *= ratio
  height *= ratio

  const baseWidth = width
  const isDogsSilk = contestType === RacingCode.Dogs
  const xOffset = numberOrBox - 1
  return { url, height, width, isDogsSilk, xOffset, baseWidth }
}

function sortSameRaceMultiAcceptors(
  a: { numberOrBox: number; sameRaceMultiPosition: number },
  b: { numberOrBox: number; sameRaceMultiPosition: number }
) {
  let diff = a.sameRaceMultiPosition - b.sameRaceMultiPosition
  if (diff != 0) return diff
  return a.numberOrBox - b.numberOrBox
}
