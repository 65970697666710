import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { hexColors } from '@mobi/settings'

import { ReceiptListRowStyled } from '@core/Areas/Quickbet/Components/Receipt/Receipt.styles'
import { isReactNativeApp } from '@mobi/web-native-comms/web'

interface BettingDrawerStyledProps {
  open: boolean
  zIndexOverride?: number
}

export const MAX_DRAWER_CONTENT_WIDTH = '40rem'

export const BettingDrawerStyled = styled('div')<BettingDrawerStyledProps>(
  {
    backgroundColor: transparentize(0.1, hexColors.white),
    bottom: 0,
    boxSizing: 'border-box',
    left: 0,
    position: 'fixed',
    right: 0,
    transition: 'transform 0.2s',
  },

  ({ open, zIndexOverride }) => ({
    transform: `translateY(${open ? 0 : '100%'})`,
    zIndex: zIndexOverride == null ? 8 : zIndexOverride,
    paddingBottom: isReactNativeApp ? '0' : 'env(safe-area-inset-bottom, 0)',
  })
)

export const BonusBetReceiptRowStyled = styled(ReceiptListRowStyled)({
  color: hexColors.mariner,
  fontWeight: 'normal',
})
