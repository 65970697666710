import React from 'react'
import type { BetError } from '@mobi/betslip/types'
import {
  ReceiptListItemStyled,
  ReceiptListItemTextStyled,
  ReceiptListRowStyled,
} from '@core/Areas/Quickbet/Components/Receipt/Receipt.styles'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Money } from '@core/Components/Text/Money'
import { useIsAuthenticated } from '@core/Utils/hooks/useIsAuthenticated'
import { toMoney } from '@mobi/utils/money'
import {
  BettingDrawerStyled,
  BonusBetReceiptRowStyled,
  MAX_DRAWER_CONTENT_WIDTH,
} from './BettingDrawer.styles'
import { BonusCashCampaign } from './Campaigns/BonusCashCampaign'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useFeature, useObservableImmutable } from '@core/Utils/hooks'

interface ConfirmBetDrawerProps {
  open: boolean
  hideNoticeBox?: boolean
  totalCost: number
  accountBalance: number
  editTId: string
  confirmTId: string
  isConfirmDisabled?: boolean
  bonusBetBalance: number
  betError?: BetError
  onEditBet: () => void
  onConfirmBet: (useBonusCash: boolean) => void
  onDeposit: () => void
}

export function ConfirmBetDrawer({
  open,
  hideNoticeBox,
  totalCost,
  accountBalance,
  editTId,
  confirmTId,
  isConfirmDisabled,
  bonusBetBalance,
  betError,
  onEditBet,
  onConfirmBet,
  onDeposit,
}: ConfirmBetDrawerProps): JSX.Element {
  const isAuthenticated = useIsAuthenticated()
  const hasBonusBet = bonusBetBalance > 0
  const bonusBetToUse = Math.min(bonusBetBalance, totalCost)
  const isInsufficientFunds = betError && betError.type === 'InsufficientFunds'
  const totalBalance = accountBalance + bonusBetBalance
  const isPartialPlacement = totalCost > totalBalance
  const warningText = isPartialPlacement
    ? 'Your account has insufficient funds, all bets cannot be placed.'
    : 'Bets cannot be cancelled once placed.'

  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')

  const [useBonusCash, setUseBonusCash] = React.useState(!isBonusCashImprovementsActive)

  const { accountNumber, bonusCashBalance } = useObservableImmutable(userAccountState$, [
    'accountNumber',
    'bonusCashBalance',
  ])

  const calculateTotalCost = (): number => (useBonusCash ? totalCost - bonusBetToUse : totalCost)

  const handleBonusCashToggle = (isApplied: boolean) => {
    setUseBonusCash(isApplied)
    trackEvent(
      isApplied ? keys.mysteryBonusCashButtonDeactivated : keys.mysteryBonusCashButtonActivated,
      {
        accountNumber: accountNumber ?? '',
        accountBalance: accountBalance,
        bonusBetBalance: bonusBetBalance,
        bonusCashBalance: bonusCashBalance,
      }
    )
  }

  return (
    <BettingDrawerStyled open={open}>
      <Grid margin='auto' maxWidth={MAX_DRAWER_CONTENT_WIDTH}>
        {!hideNoticeBox && (
          <>
            {hasBonusBet && (
              <BonusCashCampaign
                defaultValue={useBonusCash}
                toggleCampaign={handleBonusCashToggle}
              />
            )}
            <GridRow>
              <GridCell padding='1rem 0.5rem 0 0.5rem'>
                <NoticeBox title='' noticeBoxType={NoticeBoxTypes.Warning} hasBorder={true}>
                  {hasBonusBet && (
                    <>
                      <ReceiptListRowStyled data-tid-notice-total-bet-cost>
                        <ReceiptListItemStyled>Total Bet Cost</ReceiptListItemStyled>
                        <ReceiptListItemTextStyled>
                          <Money amount={totalCost} />
                        </ReceiptListItemTextStyled>
                      </ReceiptListRowStyled>
                      {useBonusCash && (
                        <BonusBetReceiptRowStyled data-tid-notice-bonus-bet-amount-to-use>
                          <ReceiptListItemStyled>Bonus Cash</ReceiptListItemStyled>
                          <ReceiptListItemTextStyled>
                            <Money amount={-bonusBetToUse} />
                          </ReceiptListItemTextStyled>
                        </BonusBetReceiptRowStyled>
                      )}
                      <ReceiptListRowStyled data-tid-notice-amount-paying>
                        <ReceiptListItemStyled>Amount Paying</ReceiptListItemStyled>
                        <ReceiptListItemTextStyled>
                          <Money amount={calculateTotalCost()} />
                        </ReceiptListItemTextStyled>
                      </ReceiptListRowStyled>
                    </>
                  )}
                  {!hasBonusBet && (
                    <strong>The total bet cost will be {toMoney(totalCost)}.</strong>
                  )}
                  <br />
                  <span>{warningText}</span>
                </NoticeBox>
              </GridCell>
            </GridRow>
          </>
        )}
        <GridRow padding='0.5rem'>
          <GridCell padding='0 0.5rem 0 1rem'>
            <ButtonBlock type='button' color='secondary' onClick={onEditBet} {...{ [editTId]: '' }}>
              Edit your Bet
            </ButtonBlock>
          </GridCell>
          <GridCell padding='0 1rem 1rem 0.5rem'>
            {isInsufficientFunds && (
              <ButtonBlock type='button' color='primary' onClick={onDeposit}>
                Deposit
              </ButtonBlock>
            )}
            {!isInsufficientFunds && (
              <ButtonBlock
                type='button'
                color='primary'
                disabled={!isAuthenticated || !!isConfirmDisabled}
                onClick={() => {
                  onConfirmBet(useBonusCash)
                }}
                {...{ [confirmTId]: '' }}
              >
                Confirm Bet
              </ButtonBlock>
            )}
          </GridCell>
        </GridRow>
      </Grid>
    </BettingDrawerStyled>
  )
}
