import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { ITrotFinisherViewModel } from './ITrotFinisherViewModel'
import template from './trot.finisher.tpl.html'
import ObservableTrotFinisher from '@classic/Betting-v2/Model/Observables/ObservableTrotFinisher'

export function register(): void {
  if (!ko.components.isRegistered('trot-finisher')) {
    ko.components.register('trot-finisher', {
      viewModel: {
        createViewModel: (params: {
          finisher: ObservableTrotFinisher
          showField: boolean
        }): ITrotFinisherViewModel => {
          const viewModel = container.get<ITrotFinisherViewModel>('ITrotFinisherViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
