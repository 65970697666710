import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useOnce } from '@core/Utils/hooks/useOnce'
import type { TrackableButton } from '@core/Areas/Racing/Components/BetInformers/Types'
import { buttonText } from '../constants'

export const useBetInformerAnalytics: UseBetInformerAnalytics = (
  meetingId,
  meetingDate,
  raceNumber
) => ({
  trackShowFixedFlucs: useOnce(track('showFixedFlucs'), meetingId, meetingDate, raceNumber),
  trackShowAllForm: useOnce(track('showAllForm'), meetingId, meetingDate, raceNumber),
  trackShowMarketMovers: useOnce(track('showMarketMovers'), meetingId, meetingDate, raceNumber),
  trackShowDoubleProvDiv: useOnce(track('showDoubleProvDiv'), meetingId, meetingDate, raceNumber),
  trackShowQuaddieProvDiv: useOnce(track('showQuaddieProvDiv'), meetingId, meetingDate, raceNumber),
  trackCommentsVisible: useOnce(track('commentsVisible'), meetingId, meetingDate, raceNumber),
  trackTipsVisible: useOnce(track('tipsVisible'), meetingId, meetingDate, raceNumber),
  trackResultsVisible: useOnce(track('resultsVisible'), meetingId, meetingDate, raceNumber),
  trackFieldVisible: useOnce(track('fieldVisible'), meetingId, meetingDate, raceNumber),
  trackReplayVisible: useOnce(track('replayVisible'), meetingId, meetingDate, raceNumber),
  trackPreviewVisible: useOnce(track('previewVisible'), meetingId, meetingDate, raceNumber),
  trackGiddyUp: useOnce(track('giddyUp'), meetingId, meetingDate, raceNumber),
})

// Local Helpers
const track = (key: TrackableButton) => () =>
  trackEvent('bet-informer-button-pressed', { buttonText: buttonText[key] })

// Types
type UseBetInformerAnalytics = (
  meetingId: string,
  meetingDate: Date,
  raceNumber: number
) => Record<`track${Capitalize<TrackableButton>}`, () => void>
