import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { Icon } from '@mobi/component-library/Common/Icon'

export const SecondaryHeader: React.FC<{
  title: string
  isExpanded?: boolean
  icon?: keyof typeof iconPaths
}> = ({ isExpanded = false, title, icon }) => {
  return (
    <HeaderStyled>
      <span>{title}</span>
      <span>
        {icon && <Icon type={icon} />}
        <Icon type={isExpanded ? 'arrowup' : 'arrowdown'} size='1.5rem' />
      </span>
    </HeaderStyled>
  )
}

// Styles

const HeaderStyled = styled.h3({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4rem',
  marginTop: '0.5rem',
  marginBottom: 0,
  paddingLeft: '1rem',
  fontWeight: 'bold',
  textTransform: 'none',
  lineHeight: 1,
  color: hexColors.white,
  backgroundColor: hexColors.nightRider,

  'span:first-of-type': {
    flex: 1,
  },

  'span:last-of-type': {
    display: 'flex',
    alignItems: 'center',

    'svg:last-of-type': {
      marginLeft: '1rem',
    },
  },
})
