import { getBlackbookDetails } from '@core/Areas/Blackbook/helpers/api'
import { useQuery } from 'react-query'
import { NextSkyRace } from './useNextSkyRaces'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const useIsBlackbookInRace = (skyRace: NextSkyRace) => {
  const { data: blackbookRunningSet } = useQuery({
    queryKey: queryKeys.blackbookDetails,
    queryFn: getBlackbookDetails,
    retry: false,
    refetchOnWindowFocus: false,
    select: data => transformToRunningSet(data),
    staleTime: 1_000 * 60 * 30, // 30 mins,

    // temp. disabled due to perf concerns, see MVPS-625
    enabled: false,
  })

  if (!blackbookRunningSet) return false

  const raceDetails = `${skyRace.MeetingDate}-${skyRace.MeetingID}-${skyRace.RaceNumber}`

  return blackbookRunningSet.has(raceDetails)
}

function transformToRunningSet(data: Awaited<ReturnType<typeof getBlackbookDetails>>) {
  if (!data.Entries) {
    return new Set<string>()
  }

  const filteredEntriesSet = new Set(
    data.Entries.filter(entry => entry.RacingTodayDetails).map(
      entry =>
        `${entry.RacingTodayDetails?.FixtureDate}-${entry.RacingTodayDetails?.FixtureId}-${entry.RacingTodayDetails?.ContestNumber}`
    )
  )

  return filteredEntriesSet
}
