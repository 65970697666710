import { inject, injectable } from 'inversify'
import * as ko from 'knockout'
import Rx from 'rx'
import type { Unsubscribe } from '@reduxjs/toolkit'
import type { ToteSelection, RaceDetails, Acceptor, Race } from '@mobi/betslip/types'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import Guard from '@classic/AppUtils/Framework/Guard'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { PyosStore } from '@classic/Specials/Store/PyosStore'
import { FormGiddyUpRaceInformation } from '@classic/Betting-v2/Components/Form/FormGiddyUpRaceInformation'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import type { ILegacyBetAdapter } from '@classic/Betting-v2/Model/ILegacyBetAdapter'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { BetSelectionBuilder } from '@classic/Betting-v2/Model/BetSelectionBuilder'
import type { IErrorHandler } from '@classic/Betting-v2/Utils/IErrorHandler'
import SelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/Processor'
import { CheckBoxSelection } from '@classic/Betting-v2/Components/Core/UIElements/CheckboxSelection'
import { ButtonSelectionType } from '@classic/Betting-v2/Components/Core/UIElements/ButtonSelectionType'
import * as buttons from '@classic/Betting-v2/Components/Core/UIElements/ButtonsSelection'
import { giddyUpViewed } from '@classic/Foundation/Analytics/Analytics'
import type { IProgressIndicator } from '@classic/AppUtils/Framework/ProgressIndicator/IProgressIndicator'
import ObservableFixedOddsStarterInfo from '@classic/Betting-v2/Model/Observables/ObservableFixedOddsStarterInfo'
import { getRacePushDataTopics } from './helpers/getRacePushDataTopics'
import { BettingDrawer } from '@core/Components/BettingDrawer/BettingDrawer'
import { SameRaceMultiBettingDrawer } from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiBettingDrawer/SameRaceMultiBettingDrawer'
import { MultiToast } from '@core/Areas/Racing/Components/MultiToast/MultiToast'
import { NotificationType } from '@core/Areas/Quickbet/Components/Notifications/NotificationTypes'
import { QuickbetState, state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'
import { IUseGiddyUp } from './IUseGiddyUp'
import { IStartersPageViewModel } from './IStartersPageViewModel'
import {
  AddingToBetslip,
  QuickbetClosed,
  RaceClosedEventReceived,
} from '@core/Areas/Quickbet/signals'
import { FeatureFlags } from '@mobi/settings'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { AddSingleToBetslip, OnClose } from '@core/Areas/Betslip/signals'
import {
  subscribe as subscribeToRaceEvents,
  unsubscribe as unsubscribeFromRaceEvents,
  EventData,
  FobPriceChangedPushEvent,
  TotePriceChangedPushEvent,
  ToteAcceptorScratchedPushEvent,
  ToteAcceptorUnscratchedPushEvent,
  ToteRaceStatusChangedPushEvent,
} from '@core/State/PushData'
import { RemoveToast } from '@core/Components/Toast/ToastDriver'
import { debounceFn } from '@mobi/utils'
import { CHANGE_BET_TYPE_EVENT } from '@core/Areas/RaceCard/constants'
import { setCurrentBetType, setSelectedProposition } from '@core/Areas/RaceCard/Store'
import {
  ClearRaceBettingPageMysterySelection,
  mysteryRaceBettingPageWrapperState$,
} from '@core/Areas/Racing/Components/Mystery/MysteryRaceBettingPageWrapperDriver'
import { getTypeAndStartersFromRaceUri, RaceHashParameters } from '@core/Areas/Racing/helpers'
import { store } from '@core/Store'
import { subscribeToStore } from '@core/Store/helpers'
import {
  getSelectionPrice,
  SameRaceMultiPriceResponse,
} from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiBettingDrawer/SameRaceMultiPrice'
import RaceTime from '@core/Areas/Racing/Components/RaceTime'
import { SrmGridPriceChangedPushEvent } from 'tabtouch-push-contract'
import { StartersKeyValue } from '@classic/Betting-v2/Model/Observables/ObservableRaceStarters'
import {
  pushTotePriceChanged,
  pushFobPriceChanged,
  pushSameRaceMultiPriceChanged,
  pushToteAcceptorScratched,
  pushToteAcceptorUnscratched,
  pushToteRaceStatusChanged,
} from './helpers'
import LegSelectionContext from '../../Core/Processors/LegSelectionContext'
import { ISelectionMadeCommand } from '../../Core/Processors/ISelectionMadeContext'
import { getCurrentBetType, getSelectedProposition } from '@core/Areas/RaceCard/Store/selectors'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import {
  getMultiBetslipItems,
  processSelection,
  synchronizeSelections,
} from './helpers/EnhancedBetslip'
import { BetslipItem, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { hasNotBeenPlaced } from '@core/Areas/Betslip/helpers/state'
import { AppRoutes } from '@core/AppRoutes'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

const GiddyUpModalTitle = 'Giddy-Up'

@injectable()
export class StartersPageViewModel extends Disposable implements IStartersPageViewModel {
  private giddyUp: FormGiddyUpRaceInformation
  private selectionProcessor!: SelectionProcessor
  private legacyBetAdapter: ILegacyBetAdapter
  private pyosStore: PyosStore
  private selectedRaceNumberChangedSubscription!: ko.Subscription
  private selectedBetTypeSubscription!: ko.Subscription
  public isValid!: ko.PureComputed<boolean>
  private isValidSubscriptionGiddyUp: ko.Subscription | undefined
  public raceInformation!: ObservableRacePage
  public bettingInformation!: BettingInformation
  public selectedRaceStarters!: ko.Observable<StartersKeyValue | undefined>
  // all race starters with their selections (if any)
  public starterSelections!: ko.PureComputed<boolean[][]>
  public mergeDone!: ko.PureComputed<boolean>
  public isAllUp!: ko.PureComputed<boolean>
  public hasGiddyUpRaceKey!: ko.PureComputed<boolean>
  public raceStatus: ko.Observable<string> = ko.observable('')
  private errorHandler: IErrorHandler
  private quickbetCloseSubscription!: Rx.Disposable
  private quickbetAddingToBetslipSubscription!: Rx.Disposable
  private isSelectingQuaddieStarters: boolean = false
  public BettingDrawer: React.ReactNode
  public SameRaceMultiBettingDrawer: React.ReactNode
  private progressIndicator: IProgressIndicator
  public PLACE_QUICKBET: string
  public PLACE_BETSLIP: string
  private isRaceCloseFeatureActive: boolean = false
  private isPriceChangeFeatureActive: boolean = false
  private raceEventSubscription!: Rx.IDisposable | null
  private ldSubscription!: Rx.IDisposable
  private currentSubscribedRaceNumber!: number | null
  private currentSubscribedTopics!: string[]
  private RACE_CLOSED_TOAST_ID: string | null
  public STARTER_ID_PREFIX: string
  public useGiddyUp?: IUseGiddyUp
  public RaceTime = RaceTime
  private isQuickbetKeepSelectionsFeatureActive: boolean = false
  private doKeepSelections: boolean = false

  private enhancedBetslipCloseSubscription!: Rx.Disposable
  private enhancedBetslipAddSingleSubscription!: Rx.Disposable
  private enhancedBetslipMysteryRaceBettingSubscription!: Rx.Disposable
  private enhancedBetslipItemsSubscription!: Rx.IDisposable
  private enhancedBetslipSettingSubscriber!: Unsubscribe
  private enhancedBetslipCurrentBetTypeSubscriber!: Unsubscribe
  private enhancedBetslipSelectedPropositionSubscriber!: Unsubscribe

  private isEnhancedBetslipFeatureActive: boolean = false
  private betslipItems: BetslipItem[] = []

  public showMultiToast: ko.Observable<boolean> = ko.observable(false)
  public multiBetslipItems: ko.ObservableArray<BetslipItem> = ko.observableArray<BetslipItem>([])
  public MultiToast: React.ReactNode

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('ILegacyBetAdapter') legacyBetAdapter: ILegacyBetAdapter,
    @inject('PyosStore') pyosStore: PyosStore,
    @inject('FormGiddyUpRaceInformation') giddyUp: FormGiddyUpRaceInformation,
    @inject('IErrorHandler') errorHandler: IErrorHandler,
    @inject('IProgressIndicator') progressIndicator: IProgressIndicator
  ) {
    super(eventAggregator)
    this.legacyBetAdapter = legacyBetAdapter
    this.pyosStore = pyosStore
    this.giddyUp = giddyUp
    this.errorHandler = errorHandler
    this.progressIndicator = progressIndicator
    this.BettingDrawer = BettingDrawer
    this.SameRaceMultiBettingDrawer = SameRaceMultiBettingDrawer
    this.MultiToast = MultiToast
    this.PLACE_QUICKBET = 'QUICKBET'
    this.PLACE_BETSLIP = 'BETSLIP'
    this.RACE_CLOSED_TOAST_ID = null
    this.STARTER_ID_PREFIX = 'starter_'
  }

  public init(params: {
    raceInformation: ObservableRacePage
    bettingInformation: BettingInformation
  }): void {
    Guard.notNull(params)
    Guard.notNull(params.raceInformation)
    Guard.notNull(params.bettingInformation)

    this.bettingInformation = params.bettingInformation

    // Sync Initial Value w/ RaceCard Redux Store
    store.dispatch(setCurrentBetType(this.bettingInformation.selectedBetType().betType()))

    this.raceInformation = params.raceInformation
    this.raceStatus = this.raceInformation.meetingInformation.selectedRace.raceStatus
    this.bettingInformation.meetingInformation = this.raceInformation.meetingInformation

    this.selectedRaceStarters = ko.observable()
    this.updateSelectedRaceStarters()
    this.mergeDone = ko.pureComputed<boolean>(
      () => this.bettingInformation.mergeDone() && this.raceInformation.mergeDone()
    )

    this.selectionProcessor = new SelectionProcessor(
      this.evtAggregator,
      this.bettingInformation,
      this.raceInformation
    )
    this.mergeDisposables(this.selectionProcessor.callBacksForDisposal())
    this.isValid = ko.pureComputed<boolean>(
      () =>
        this.validateSelections() &&
        this.raceStatus() !== 'Closed' &&
        // enhanced betslip only uses the betting drawer for W&P field betting (aka bettingOptionsSelected)
        (!this.bettingInformation.isEnhancedBetslip() ||
          this.bettingInformation.bettingOptionsSelected())
    )
    this.starterSelections = ko.pureComputed<boolean[][]>(() => this.getStarterSelections())

    this.configureDisposal()
    this.monitorLaunchDarkly()
    this.registerHandlers()

    this.pyosStore.clear()
    this.isAllUp = ko.pureComputed<boolean>(() =>
      this.bettingInformation.selectedBetType().isAllUp()
    )

    this.hasGiddyUpRaceKey = ko.pureComputed<boolean>(() => !!this.raceInformation.giddyUpRaceKey())

    this.useGiddyUp = {
      openGiddyUp: this.openGiddyUp.bind(this),
      showGiddyUpInTopPanel: this.showGiddyUpInTopPanel.bind(this),
      showGiddyUpInInformationPanel: this.showGiddyUpInfoPanel.bind(this),
    }

    this.evtAggregator.subscribe('last-starter-initialised', this.quickbetOnHighlightedStarter)

    this.updateIsEnhancedBetslipActive()
  }

  monitorLaunchDarkly() {
    this.ldSubscription = launchDarkState$.subscribe(record => {
      const isRaceCloseFeatureActive = record.features.get(
        FeatureFlags.PUSHDATA_RACECLOSE.key
      ) as boolean
      const isPriceChangeFeatureActive = record.features.get(
        FeatureFlags.PUSHDATA_PRICECHANGE.key
      ) as boolean
      const isQuickbetKeepSelectionsFeatureActive = record.features.get(
        FeatureFlags.QUICKBET_KEEP_SELECTIONS.key
      ) as boolean

      const isEnhancedBetslipFeatureActive = record.features.get(
        FeatureFlags.ENHANCED_BETSLIP.key
      ) as boolean

      let isSubscriptionChanged = false

      if (this.isRaceCloseFeatureActive != isRaceCloseFeatureActive) {
        this.isRaceCloseFeatureActive = isRaceCloseFeatureActive
        isSubscriptionChanged = true
      }

      if (this.isPriceChangeFeatureActive != isPriceChangeFeatureActive) {
        this.isPriceChangeFeatureActive = isPriceChangeFeatureActive
        isSubscriptionChanged = true
      }

      if (this.isQuickbetKeepSelectionsFeatureActive != isQuickbetKeepSelectionsFeatureActive) {
        this.isQuickbetKeepSelectionsFeatureActive = isQuickbetKeepSelectionsFeatureActive
        isSubscriptionChanged = true
      }

      if (this.isEnhancedBetslipFeatureActive != isEnhancedBetslipFeatureActive) {
        this.isEnhancedBetslipFeatureActive = isEnhancedBetslipFeatureActive
        this.updateIsEnhancedBetslipActive()
        this.synchronizeSelections()
      }

      if (isSubscriptionChanged) {
        if (this.raceEventSubscription) {
          this.unsubscribeFromRaceEvent()
        }
        this.subscribeToRaceEvent()
      }
    })
  }

  private showGiddyUpInfoPanel = (raceNumber: number): boolean => {
    return this.showGiddyUpInTopPanel(raceNumber)
  }

  private showGiddyUpInTopPanel = (raceNumber: number) => {
    if (
      !this.raceInformation ||
      !this.raceInformation.meetingInformation ||
      !this.raceInformation.meetingInformation.races()
    )
      return false

    const foundRace = this.raceInformation.meetingInformation
      .races()
      .find(item => raceNumber === item.raceNumber())
    if (!foundRace) return false

    return foundRace.key() === this.raceInformation.giddyUpRaceKey()
  }

  private registerHandlers() {
    this.selectedRaceNumberChangedSubscription = this.raceInformation.mergeDone.subscribe(
      finished => {
        if (finished) {
          let updatedStarters = this.raceInformation.getStartersForRace(
            this.bettingInformation.raceNumber
          )
          this.bettingInformation.updateSelectionsForCurrentRace(updatedStarters())
          this.updateSelectedRaceStarters()
        }
      }
    )

    this.selectedBetTypeSubscription = this.bettingInformation.selectedBetType.subscribe(betType =>
      store.dispatch(setCurrentBetType(betType.betType()))
    )

    this.evtAggregator.subscribe('nav.nav', this.navigationTracking, this)

    // On Quickbet Close - Refresh Starters if Bet Complete or Error
    this.quickbetCloseSubscription = QuickbetClosed.signal$
      .withLatestFrom(quickbetState$, (_: unknown, quickbetState) => ({ quickbetState }))
      .subscribe(({ quickbetState }) => {
        const quickbetStateJs: QuickbetState = quickbetState.toJS()
        const hasAnErrorOccurred =
          quickbetStateJs.notificationType === NotificationType.NonHandledError ||
          quickbetStateJs.notificationType === NotificationType.RaceClosed ||
          quickbetStateJs.notificationType === NotificationType.PriceChange ||
          quickbetStateJs.notificationType === NotificationType.HandicapChanged ||
          quickbetStateJs.notificationType === NotificationType.Unauthorized
        const isBetComplete = quickbetStateJs.betPlaced

        if (!this.isPriceChangeFeatureActive && hasAnErrorOccurred) {
          // only refresh if push data isn't available
          this.refreshSelectionPage(isBetComplete)
        } else if (
          // clear selections if they're no longer required
          (this.isPriceChangeFeatureActive && !this.doKeepSelections && isBetComplete) ||
          (!quickbetStateJs.isAddingToBetslip && this.bettingInformation.isEnhancedBetslip())
        ) {
          this.clearAllSelections()
        }
        this.doKeepSelections = false
      })

    this.quickbetAddingToBetslipSubscription = AddingToBetslip.signal$.subscribe(() => {
      if (
        this.isPriceChangeFeatureActive &&
        !this.doKeepSelections &&
        !this.bettingInformation.isEnhancedBetslip()
      ) {
        this.clearAllSelections()
        this.doKeepSelections = false
      }
    })

    this.subscribeEnhancedBetslip()
  }

  private configureDisposal() {
    this.registerDisposals(() => {
      this.handleRemovingRaceClosedToast()
      this.selectedRaceNumberChangedSubscription.dispose()
      this.selectedBetTypeSubscription.dispose()
      this.quickbetCloseSubscription.dispose()
      this.quickbetAddingToBetslipSubscription.dispose()
      this.unsubscribeFromRaceEvent()
      this.ldSubscription && this.ldSubscription.dispose()
      this.evtAggregator.unsubscribe('nav.nav', this.navigationTracking)
      this.subscribeToRaceEventDebounced.cancel()
      this.unsubscribeEnhancedBetslip()
    })
  }

  private updateSelectedRaceStarters() {
    const currentRaceStatus =
      this.raceInformation.meetingInformation
        .races()
        .find(x => x.key() === this.raceInformation.meetingInformation.selectedRace.key())
        ?.raceStatus() || null

    if (currentRaceStatus && currentRaceStatus !== 'Closed') {
      this.raceStatus(currentRaceStatus)
      this.handleRemovingRaceClosedToast()
    }

    const raceStartersLocal = this.raceInformation.raceStarters
      .starters()
      .find(
        raceStarter =>
          raceStarter.Key.raceNumber() ===
          this.raceInformation.meetingInformation.selectedRace.raceNumber()
      )
    if (raceStartersLocal) this.selectedRaceStarters(raceStartersLocal)

    if (
      (this.isPriceChangeFeatureActive || this.isRaceCloseFeatureActive) &&
      this.bettingInformation.raceNumber !== this.currentSubscribedRaceNumber
    ) {
      if (this.raceEventSubscription) {
        this.unsubscribeFromRaceEvent()
      }
      this.subscribeToRaceEventDebounced()
    }
  }

  public validateSelections(): boolean {
    // guard against race/meeting that is undergoing a merge
    if (!this.mergeDone()) {
      return false
    }

    return this.bettingInformation.selectedBetType().validator.isValid(this.bettingInformation)
  }

  public getStarterSelections(): boolean[][] {
    // don't attempt to retrieve starter selections if it's not valid, e.g. starters not fully initialised, insufficient starters, etc.
    if (!this.validateSelections()) return []

    return this.bettingInformation
      .selectedBetType()
      .processor.getStarterSelections(
        this.raceInformation.getStartersForRace(this.bettingInformation.raceNumber)()
      )
  }

  public openGiddyUp = () => {
    // subscribe isValid changes to automatically open quickbet if/when any valid selection
    // combinations are made (corresponds to when toast is normally displayed)
    this.subscribeIsValidGiddyUp()
    // unsubscribe isValid changes when giddyup modal is closed
    const modalClosed = (data: { title: string }) => {
      if (data.title == GiddyUpModalTitle) {
        this.evtAggregator.unsubscribe('modal.did_close', modalClosed)
        this.unsubscribeIsValidGiddyUp()
        this.synchronizeSelections()
      }
    }
    this.evtAggregator.subscribe('modal.did_close', modalClosed, this)
    // Set Giddy Up in the data layer
    giddyUpViewed()
    // show giddyup modal
    this.giddyUp.openGiddyUp(
      this.raceInformation.giddyUpRaceKey(),
      this.raceInformation.giddyUpFixtureKey(),
      starterNumber =>
        this.raceInformation.raceStarters.getStarter(
          this.bettingInformation.raceNumber,
          starterNumber
        ),
      () =>
        this.raceInformation.meetingInformation.getRaceForGiddyUp(
          this.bettingInformation.raceNumber
        ),
      (starterNumber: number, isFixed: boolean) =>
        this.selectBetType(BetType.WinPlace, () =>
          this.selectWinAndPlaceStarters(starterNumber, isFixed)
        ),
      () =>
        !this.bettingInformation.isEnhancedBetslip() &&
        this.evtAggregator.publish('clear-all-selections-command'),
      (raceNumbers, acceptorNumbers) => {
        this.progressIndicator.beginning()
        this.selectBetType(BetType.Quaddie, () => {
          this.progressIndicator.beginning()
          this.selectQuaddieStarters(raceNumbers, acceptorNumbers)
        })
      }
    )
  }

  private selectBetType(betType: BetType, callback: Function): void {
    if (this.bettingInformation.selectedBetType().betType() != betType) {
      // change bet type and invoke the callback, e.g. make selections
      const betTypeChangedCallback = () => {
        this.evtAggregator.unsubscribe('process-bet-type-completed', betTypeChangedCallback)
        callback()
      }
      this.evtAggregator.subscribe('process-bet-type-completed', betTypeChangedCallback)
      this.bettingInformation.selectedBetType().betType(betType)
      this.evtAggregator.publish(CHANGE_BET_TYPE_EVENT, { betType })
    } else {
      this.evtAggregator.publish('clear-all-selections-command')
      callback()
    }
  }

  private selectSameRaceMultiStarters(params: RaceHashParameters): void {
    if (!params || !params.starters) return

    let selection: string[] = []

    params.starters.forEach(starterParam => {
      let starter = this.raceInformation.raceStarters.getStarter(
        this.bettingInformation.raceNumber,
        starterParam.starterNumber
      )

      if (
        starter === null ||
        starter.isFixedScratchedOrSuspended() ||
        (!starter.fixedOddsStarterInfo.isScratched() &&
          starter.fixedOddsStarterInfo.isSuspended()) ||
        starter.fixedOddsStarterInfo.sameRaceMultiPrices.isSuspended() != false
      )
        return

      // adapted from makeLegSelection in SelectionViewModel
      const starterSelection = starter.selection() as buttons.ButtonsSelection
      let legNumber = starterParam.legNumber // 1 to 4
      if (legNumber === undefined) return

      // starterSelection uses 0-based indexing, so subtract 1
      const selectedValue = starterSelection.values()[legNumber - 1]

      if (!selectedValue) {
        return
      }

      selectedValue(
        selectedValue() == ButtonSelectionType.None
          ? ButtonSelectionType.Fob
          : ButtonSelectionType.None
      )

      selection.push(`${starterParam.starterNumber}-${starterParam.legNumber}`)

      this.evtAggregator.publish('selection-made-command', {
        raceNumber: this.bettingInformation.raceNumber,
        starter: starter,
        context: new LegSelectionContext(legNumber, ButtonSelectionType.Fob),
      } as ISelectionMadeCommand)
    })

    if (selection.length === params.starters.length) {
      // potential improvement; reuse call by SameRaceMultiPrice's useQuery, instead of separate one
      getSelectionPrice(
        this.raceInformation.meetingInformation.meetingDate(),
        this.raceInformation.meetingInformation.meetingId(),
        this.bettingInformation.raceNumber,
        selection.map(item => `selection=${item}`).join('&')
      ).then(price => this.placeSelection(this.PLACE_QUICKBET, price, params.source))
    }
  }

  private selectWinAndPlaceStarters(
    starterNumber: number,
    isFixed: boolean,
    source?: string
  ): void {
    // find acceptor
    let starter = this.raceInformation.raceStarters.getStarter(
      this.bettingInformation.raceNumber,
      starterNumber
    )

    if (starter === null) return

    // scroll to starter
    const elementId = `${this.STARTER_ID_PREFIX}${starterNumber}`
    const element = document.getElementById(elementId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }

    let selection = starter.selection() as buttons.ButtonsSelection

    // select acceptor
    selection.value(isFixed ? ButtonSelectionType.Fob : ButtonSelectionType.Tote)

    this.evtAggregator.publish('selection-made-command', {
      raceNumber: this.bettingInformation.raceNumber,
      starter: starter,
      context: new LegSelectionContext(0, selection.value()),
      source,
    } as ISelectionMadeCommand)

    // show quickbet - not required for enhanced betslip as this will trigger automatically after the publishing 'selection-made-command'
    if (!this.bettingInformation.isEnhancedBetslip())
      this.placeSelection(this.PLACE_QUICKBET, undefined, source)
  }

  private selectQuaddieStarters(raceNumbers: number[], acceptorNumbers: number[][]) {
    // guard against multiple invocations
    if (this.isSelectingQuaddieStarters) return
    this.isSelectingQuaddieStarters = true

    let raceIndex = 0
    let scratchedAcceptorCount = 0

    const startersInitialisedCallback = () => {
      // select starters
      acceptorNumbers[raceIndex].forEach(acceptor => {
        let starter = this.raceInformation.raceStarters.getStarter(raceNumbers[raceIndex], acceptor)

        if ((starter as IObservableStarter).isScratched()) {
          scratchedAcceptorCount++
        } else {
          let selection = (starter as IObservableStarter).selection() as CheckBoxSelection
          selection.values()[0].checked(true)
        }
      })

      if (++raceIndex == raceNumbers.length) {
        // all selections have been made so we can bail out
        this.evtAggregator.unsubscribe('last-starter-initialised', startersInitialisedCallback)
        this.bettingInformation.legVisible(-1)

        if (scratchedAcceptorCount > 0) {
          // disable auto quickbet until after the user has acknowledged the scratched modal
          this.unsubscribeIsValidGiddyUp()

          this.errorHandler.showWarningMessage(
            `Acceptor${scratchedAcceptorCount > 1 ? 's' : ''} Scratched`,
            'Please check your selections before proceeding.',
            () => {
              // re-enable auto quickbet & explicitly invoke quickbet as we would miss it whilst waiting for the scratched modal to close
              this.subscribeIsValidGiddyUp()
              this.placeSelection(this.PLACE_QUICKBET)
            }
          )
        }
        // clear the guard now that all the quaddie selections have been made
        this.isSelectingQuaddieStarters = false
      } else {
        // process next quaddie leg
        this.bettingInformation.legVisible(this.getLegNumber(raceNumbers[raceIndex]))
      }
    }
    this.evtAggregator.subscribe('last-starter-initialised', startersInitialisedCallback)

    // select leg (race) - this will result in last-starter-initialised firing
    const targetLeg = this.getLegNumber(raceNumbers[raceIndex])
    if (this.bettingInformation.legVisible() === targetLeg) {
      // this view is already rendered/initialised by ko, therefore we can immediately trigger the selection callback
      this.evtAggregator.publish('last-starter-initialised')
    } else {
      this.bettingInformation.legVisible(targetLeg)
    }
  }

  private canKeepSelections(type: string) {
    if (!this.isQuickbetKeepSelectionsFeatureActive) {
      return false
    }

    if (type !== this.PLACE_QUICKBET) {
      return false
    }

    return this.bettingInformation.isFixed()
      ? this.bettingInformation.selectedBetType().betType() === BetType.SameRaceMulti
      : this.bettingInformation.selectedBetType().canKeepSelections()
  }

  public placeSelection(
    type: string,
    priceResponse?: SameRaceMultiPriceResponse,
    betSource?: string,
    winPlaceStarterOverride?: number
  ): void {
    this.evtAggregator.publish('stop-all-race-replay')
    if (!this.validateSelections()) return

    if (!betSource) betSource = isOnSkyPage() ? 'sky-page' : 'race-card'

    const bettingInfo = this.bettingInformation

    // for EB, we don't want to refresh the page because...
    // - speed up the UX by avoiding unnecessary backend calls
    // - avoid unwanted side effects where the page refresh also clears selections
    const refreshPage = !this.bettingInformation.isEnhancedBetslip()

    bettingInfo.assignMeetingInfo(this.raceInformation.meetingInformation)

    const keepSelections = (doKeep?: boolean): boolean | undefined => {
      if (doKeep === undefined) return this.canKeepSelections(type)

      this.doKeepSelections = doKeep
      return doKeep
    }

    if (bettingInfo.isFixed()) {
      const selectionDetails = this.getRaceSelectionDetails(
        bettingInfo.isFixed(),
        undefined,
        winPlaceStarterOverride
      )

      const selection = BetSelectionBuilder.build({
        bettingInformation: bettingInfo,
        numberOfCombinations: 1,
        allUpFormulas: undefined,
        priceResponse,
        winPlaceStarterOverride,
      })

      if (type === this.PLACE_BETSLIP) {
        handleBetSelection({
          location: 'Betslip',
          selection: { ...selection, selectionDetails, betSource },
        })
        this.clearAllSelectionsAndRefresh(refreshPage)
      }
      if (type === this.PLACE_QUICKBET) {
        handleBetSelection({
          location: 'Quickbet',
          selection: { ...selection, selectionDetails, betSource, keepSelections },
        })
      }
    } else {
      // potential future optimisation would be to remove the bet enquiry when using enhancedBetslip since it's n/a for W&P bets
      this.legacyBetAdapter.doBetEnquiry(bettingInfo, result => {
        const selection = BetSelectionBuilder.build({
          bettingInformation: bettingInfo,
          numberOfCombinations: result.TotalCombinations,
          allUpFormulas: result.AllUpFormulas,
          winPlaceStarterOverride,
        })

        const toteSelection = selection.selection as ToteSelection

        // override the win & place selection if a starter is provided, e.g. when enhancedBetslip is active where multiple starter selections can be present
        if (winPlaceStarterOverride) {
          toteSelection.numberOfCombinations = 1
          toteSelection.selectionString = winPlaceStarterOverride.toString()
        }

        const selectionString = toteSelection.selectionString // OK if it's undefined because of a bad cast
        const selectionDetails = this.getRaceSelectionDetails(
          bettingInfo.isFixed(),
          selectionString,
          winPlaceStarterOverride
        )

        if (type === this.PLACE_BETSLIP) {
          handleBetSelection({
            location: 'Betslip',
            selection: { ...selection, selectionDetails, betSource },
          })
          this.clearAllSelectionsAndRefresh(refreshPage)
        }
        if (type === this.PLACE_QUICKBET) {
          handleBetSelection({
            location: 'Quickbet',
            selection: { ...selection, selectionDetails, betSource, keepSelections },
          })
        }
      })
    }
  }

  private clearAllSelectionsAndRefresh(refreshPage: boolean) {
    // always clear selections with an optional refresh page which acts as an optimisation to avoid unnecessary backend api calls
    if (refreshPage) this.refreshSelectionPage(true)
    else this.clearAllSelections()
  }

  private refreshSelectionPage(clearSelections: boolean): void {
    let raceNumbersToRefresh = [this.raceInformation.meetingInformation.selectedRace.raceNumber()]
    if (this.bettingInformation != null) {
      if (this.bettingInformation.selectedBetType().isDouble()) {
        raceNumbersToRefresh = this.raceInformation.getDoubleRaceNumbers()
      }
      if (this.bettingInformation.selectedBetType().isQuaddie()) {
        raceNumbersToRefresh = this.raceInformation.getQuaddieRaceNumbers()
      }
      if (this.bettingInformation.selectedBetType().isAllUp()) {
        raceNumbersToRefresh = [] //don't refresh for allUp bettype for performance reason as we don't in other parts of page
      }
    }
    this.evtAggregator.publish('race-refresh-command', raceNumbersToRefresh, clearSelections)
  }

  private clearAllSelections() {
    store.dispatch(setSelectedProposition(null))
    ClearRaceBettingPageMysterySelection()
    this.evtAggregator.publish('clear-all-selections-command')

    this.synchronizeSelections()
  }

  private navigationTracking(): void {
    this.giddyUp.close()
  }

  private subscribeIsValidGiddyUp() {
    this.unsubscribeIsValidGiddyUp()

    this.isValidSubscriptionGiddyUp = this.isValid.subscribe(isValid => {
      if (isValid) this.placeSelection(this.PLACE_QUICKBET)
    })
  }

  private unsubscribeIsValidGiddyUp(): void {
    if (this.isValidSubscriptionGiddyUp != undefined) {
      this.isValidSubscriptionGiddyUp.dispose()
      this.isValidSubscriptionGiddyUp = undefined
    }
  }

  private createAcceptor(
    raceKey: string,
    observableStarter: IObservableStarter,
    isFixed: boolean
  ): Acceptor {
    return {
      key: `${raceKey}-${observableStarter.number()}`,
      name: observableStarter.name(),
      number: observableStarter.number(),
      type: observableStarter.type(),
      imageUrl: observableStarter.silkImages().large.url,
      imageUrlWithSizes: observableStarter.silkImages(),
      meetingName: this.raceInformation.meetingInformation.meetingName(),
      fixedOdds: {
        win: '',
        place: '',
        isSuspended: observableStarter.fixedOddsStarterInfo.isSuspended(),
      },
      toteOdds: { win: '', place: '' },
      isScratched: isFixed
        ? observableStarter.isFixedScratchedOrSuspended()
        : observableStarter.isScratched(),
      scratchType: isFixed ? null : observableStarter.scratchType(),
    } as Acceptor
  }

  private getRaceSelectionDetails(
    isFixed: boolean,
    selectionString: string = '',
    preferredStarter?: number
  ): RaceDetails {
    const raceDetails: RaceDetails = { races: [], acceptors: [] }

    if (this.bettingInformation.selectedBetType().isNoveltyBet()) {
      raceDetails.races = [
        {
          key: this.raceInformation.meetingInformation.selectedRace.key(),
          raceNumber: this.raceInformation.meetingInformation.selectedRace.raceNumber(),
          meetingName: this.raceInformation.meetingInformation.meetingName(),
          meetingCode: this.raceInformation.meetingInformation.meetingCode(),
          meetingId: this.raceInformation.meetingInformation.meetingId(),
          meetingDate: this.raceInformation.meetingInformation.meetingDate().toString(),
          distance: this.raceInformation.meetingInformation.selectedRace.distance(),
          raceTime: this.raceInformation.meetingInformation.selectedRace.raceTime().toString(),
          type: this.raceInformation.meetingInformation.selectedRace.raceType(),
          acceptorKeys: [],
        },
      ]

      // novelty bet types have multiple selections for the same leg,
      // so look at the selection string to figure out which ones we need
      const singleStarterKeys = selectionString.split('/')
      const raceKey = this.raceInformation.meetingInformation.selectedRace.key()
      raceDetails.acceptors = this.raceInformation.raceStarters
        .starters()
        .flatMap(rs => rs.Value())
        .filter(rs => singleStarterKeys.includes(rs.number().toString()))
        .map(rs => this.createAcceptor(raceKey, rs, isFixed))
      raceDetails.races[0].acceptorKeys = raceDetails.acceptors.map(a => a.key)

      return raceDetails
    }

    this.bettingInformation.getLegsForProcessing().forEach(leg => {
      const thisRaceKey = this.getRaceKeyByRaceNumber(leg.raceKey().raceNumber()) || ''
      const race: Race = {
        key: thisRaceKey,
        raceNumber: leg.raceKey().raceNumber(),
        meetingName: this.raceInformation.meetingInformation.meetingName(),
        meetingCode: this.raceInformation.meetingInformation.meetingCode(),
        meetingId: this.raceInformation.meetingInformation.meetingId(),
        meetingDate: this.raceInformation.meetingInformation.meetingDate().toString(),
        distance: parseInt(leg.raceKey().distance()),
        raceTime: leg.raceKey().startTime().toString(),
        type: this.raceInformation.meetingInformation.selectedRace.raceType(),
        acceptorKeys: [],
      }

      // if novelty, race.acceptorKeys will not contain all keys in selection
      // - only one acceptor is needed for name display/icon type (i.e. Trots, Dogs etc.)
      // - use the supplied starter number if available, else default to the first selection
      // - n/a for W&P fixed because WinPlaceFobProcessor.process() only maintains the last single starter selection, i.e. no need to pick through the collection of starters
      let starterOffset = 0
      if (preferredStarter) {
        let index = leg.starters().findIndex(starter => starter.number() == preferredStarter)
        if (index != -1) starterOffset = index
      }

      const acceptors: Acceptor[] =
        BetType.SameRaceMulti !== this.bettingInformation.selectedBetType().betType()
          ? [this.createAcceptor(thisRaceKey, leg.starters()[starterOffset], isFixed)]
          : leg.starters().map(starter => this.createAcceptor(thisRaceKey, starter, isFixed))

      raceDetails.acceptors = raceDetails.acceptors.concat(acceptors)

      const raceAcceptors = raceDetails.acceptors
        .filter(a => a.key.indexOf(race.key + '-') === 0)
        .map(a => a.key)

      race.acceptorKeys = raceAcceptors

      raceDetails.races.push(race)
    })
    return raceDetails
  }

  private getRaceKey(): string | null {
    return this.raceInformation?.meetingInformation?.selectedRace?.key?.() || null
  }

  private getRaceKeyByRaceNumber(raceNumber: number): string | null {
    if (
      this.raceInformation &&
      this.raceInformation.meetingInformation &&
      this.raceInformation.meetingInformation.races
    ) {
      const race = this.raceInformation.meetingInformation
        .races()
        .find(x => x.raceNumber() === raceNumber)
      return race ? race.key() : null
    }
    return null
  }

  private getLegNumber(raceNumber: number) {
    const startersKeyValue = this.raceInformation.raceStarters
      .starters()
      .find(raceStarter => raceStarter.Key.raceNumber() === raceNumber)
    return startersKeyValue != undefined ? startersKeyValue.Key.leg() : 0
  }

  private subscribeToRaceEvent() {
    if (this.raceEventSubscription) {
      this.unsubscribeFromRaceEvent()
    }
    const hasSameRaceMulti = this.raceInformation
      .availableBetTypes()
      .some(bt => bt.betType() === BetType.SameRaceMulti)

    this.currentSubscribedTopics = this.isPriceChangeFeatureActive
      ? getRacePushDataTopics({
          raceId: this.getRaceKey(),
          raceInformation: this.raceInformation,
          hasSameRaceMulti,
        })
      : []
    this.currentSubscribedRaceNumber = this.bettingInformation.raceNumber

    const pushEventSubscriptions = subscribeToRaceEvents(this.currentSubscribedTopics)
    this.raceEventSubscription = pushEventSubscriptions.subscribe(this.pushEventReceived.bind(this))
  }

  private subscribeToRaceEventDebounced = debounceFn(this.subscribeToRaceEvent, 3000)

  private unsubscribeFromRaceEvent() {
    if (this.raceEventSubscription) {
      this.raceEventSubscription.dispose()
      this.raceEventSubscription = null
      this.currentSubscribedRaceNumber = null

      if (this.currentSubscribedTopics) {
        unsubscribeFromRaceEvents(this.currentSubscribedTopics)
        this.currentSubscribedTopics.length = 0
      }

      ClearRaceBettingPageMysterySelection()
    }
  }

  public static resetFobFavourite(starters: IObservableStarter[]) {
    const lowestPriceStarters = starters.reduce(
      (accu: ObservableFixedOddsStarterInfo[], starter) => {
        let fobInfo = starter.fixedOddsStarterInfo

        if (!fobInfo) {
          return accu
        }

        if (fobInfo.isFavourite()) {
          fobInfo.isFavourite(false)
        }

        if (fobInfo.isScratched() || fobInfo.isSuspended()) {
          return accu
        }

        if (accu.length === 0) {
          return [fobInfo]
        }

        const currentLowestPrice = Number.parseFloat(accu[0].displayWinDividend())
        const currentPrice = Number.parseFloat(fobInfo.displayWinDividend())

        if (currentPrice < currentLowestPrice) {
          return [fobInfo]
        } else if (currentPrice === currentLowestPrice) {
          accu.push(fobInfo)
        }
        return accu
      },
      []
    )

    if (lowestPriceStarters.length === 1) {
      lowestPriceStarters[0].isFavourite(true)
    }
  }

  private pushToteRaceStatusChanged(event: ToteRaceStatusChangedPushEvent) {
    if (event.status !== 'closed') return

    this.RACE_CLOSED_TOAST_ID = `race-closed-${this.currentSubscribedRaceNumber}`

    RaceClosedEventReceived({ event, toastId: this.RACE_CLOSED_TOAST_ID })

    const { meetingId, meetingDate, raceNumber } = this.bettingInformation

    pushToteRaceStatusChanged({
      event,
      meetingId,
      meetingDate,
      raceNumber,
    })

    this.selectBetType(BetType.WinPlace, () => {
      setTimeout(() => {
        this.raceInformation.meetingInformation.selectedRace.raceStatus('Closed')

        // Giddyup doesn't read value from selectedRace, update the race directly
        const race = this.raceInformation.meetingInformation
          .races()
          .find(x => x.key() === this.raceInformation.meetingInformation.selectedRace.key())
        if (race) race.raceStatus('Closed')
      }, 500)
    })
  }

  private pushEventReceived(event: EventData) {
    if (!event || !event.payload) {
      return
    }

    switch (event.payload.eventType) {
      case 'ToteRaceStatusChanged':
        this.pushToteRaceStatusChanged(event.payload as ToteRaceStatusChangedPushEvent)
        break
      case 'FobPriceChanged': {
        const { meetingId, meetingDate, raceNumber } = this.bettingInformation
        pushFobPriceChanged({
          event: event.payload as FobPriceChangedPushEvent,
          raceInformation: this.raceInformation,
          meetingId,
          meetingDate,
          raceNumber,
          resetFobFavourite: StartersPageViewModel.resetFobFavourite,
        })
        break
      }
      case 'TotePriceChanged': {
        const { meetingId, meetingDate, raceNumber } = this.bettingInformation
        pushTotePriceChanged({
          event: event.payload as TotePriceChangedPushEvent,
          meetingId,
          meetingDate,
          raceNumber,
          raceInformation: this.raceInformation,
        })
        break
      }
      case 'ToteAcceptorScratched': {
        const { meetingId, meetingDate, raceNumber } = this.bettingInformation
        pushToteAcceptorScratched(
          event.payload as ToteAcceptorScratchedPushEvent,
          meetingId,
          meetingDate,
          raceNumber,
          this.getRaceKey(),
          this.raceInformation
        )
        break
      }
      case 'ToteAcceptorUnscratched': {
        const { meetingId, meetingDate, raceNumber } = this.bettingInformation
        pushToteAcceptorUnscratched(
          event.payload as ToteAcceptorUnscratchedPushEvent,
          meetingId,
          meetingDate,
          raceNumber,
          this.getRaceKey(),
          this.raceInformation
        )
        break
      }
      case 'SrmGridPriceChanged':
        pushSameRaceMultiPriceChanged({
          event: event.payload as SrmGridPriceChangedPushEvent,
          raceInformation: this.raceInformation,
        })
        break
    }
  }

  private handleRemovingRaceClosedToast() {
    if (this.RACE_CLOSED_TOAST_ID) {
      RemoveToast(this.RACE_CLOSED_TOAST_ID)
      this.RACE_CLOSED_TOAST_ID = null
    }
  }

  public quickbetOnHighlightedStarter = () => {
    this.evtAggregator.unsubscribe('last-starter-initialised', this.quickbetOnHighlightedStarter)

    const params = getTypeAndStartersFromRaceUri(window.location.hash)
    if (!params || !params.isValidUri) return

    this.selectBetType(params.betType, () => {
      setTimeout(() => {
        if (params.betType === BetType.SameRaceMulti) {
          this.selectSameRaceMultiStarters(params)
        } else if (params.starters.length === 1) {
          this.selectWinAndPlaceStarters(params.starters[0].starterNumber, true, params.source)
        }
      }, 0)
    })
  }

  // contains ALL the enhancedBetslip entry points
  private subscribeEnhancedBetslip() {
    // monitor redux for user configuration.. EB only applies if user has opted in
    this.enhancedBetslipSettingSubscriber = subscribeToStore(getEnhancedBetslipSetting, () =>
      this.updateIsEnhancedBetslipActive()
    )

    // monitor redux for bet type change.. EB only applies for W&P
    this.enhancedBetslipCurrentBetTypeSubscriber = subscribeToStore(getCurrentBetType, () =>
      this.updateIsEnhancedBetslipActive()
    )

    // monitor redux for derivative market change (e.g. odds/events).. if it is deselected then we force a starter selection sync
    this.enhancedBetslipSelectedPropositionSubscriber = subscribeToStore(
      getSelectedProposition,
      propositionInfo => {
        if (propositionInfo == null) this.synchronizeSelections()
      }
    )

    // monitor betslip items
    this.enhancedBetslipItemsSubscription = betslipState$
      .map(state => state.items.filter(hasNotBeenPlaced))
      .distinctUntilChanged()
      .subscribe(unplacedBetslipItems => (this.betslipItems = unplacedBetslipItems.toArray()))

    // monitor betslip addition that can occur outside betslip, e.g. via tips
    this.enhancedBetslipAddSingleSubscription = AddSingleToBetslip.signal$.subscribe(() =>
      this.synchronizeSelections()
    )

    // monitor betslip addition/removal that occurs within betslip, e.g. add/remove item
    this.enhancedBetslipCloseSubscription = OnClose.signal$.subscribe(() => {
      // closing betslip is a special scenario as we want to explicitly clear all selections before performing the selection sync
      // - e.g. clear the field selection checkbox
      if (this.bettingInformation.isEnhancedBetslip()) this.clearAllSelections()
    })

    // monitor mystery button selection
    this.enhancedBetslipMysteryRaceBettingSubscription = mysteryRaceBettingPageWrapperState$
      .distinctUntilChanged()
      .subscribe(state => {
        // trigger sync when mystery button is deselected
        const selectedKey = state.get('selectedKey')
        if (selectedKey == null) this.synchronizeSelections()
      })

    // monitor the active race page, e.g. during race navigation
    this.evtAggregator.subscribe('last-starter-initialised', this.lastStarterInitSubscription, this)

    // monitor tote/fixed button selection
    this.evtAggregator.subscribe('selection-made-command', this.selectionMadeSubscription, this)

    // monitor W&P field button selection
    this.evtAggregator.subscribe('field-selected-command', this.fieldSelectedSubscription, this)
  }

  private unsubscribeEnhancedBetslip() {
    this.enhancedBetslipItemsSubscription.dispose()
    this.enhancedBetslipAddSingleSubscription.dispose()
    this.enhancedBetslipCloseSubscription.dispose()
    this.enhancedBetslipMysteryRaceBettingSubscription.dispose()

    this.enhancedBetslipSettingSubscriber()
    this.enhancedBetslipCurrentBetTypeSubscriber()
    this.enhancedBetslipSelectedPropositionSubscriber()

    this.evtAggregator.unsubscribe('last-starter-initialised', this.lastStarterInitSubscription)
    this.evtAggregator.unsubscribe('selection-made-command', this.selectionMadeSubscription)
    this.evtAggregator.unsubscribe('field-selected-command', this.fieldSelectedSubscription)
  }

  private lastStarterInitSubscription() {
    this.synchronizeSelections()
  }

  private fieldSelectedSubscription(cmd: { selected: boolean }) {
    if (!cmd.selected) this.synchronizeSelections()
  }

  private selectionMadeSubscription(command: ISelectionMadeCommand) {
    // guard against unnecessary 'phantom' selections generated by ObservableRaceStarters.mergeStartersForRace
    if (!command.context) return

    processSelection(
      this.bettingInformation.isEnhancedBetslip(),
      command,
      this.raceInformation,
      this.betslipItems,
      (isQuickbet, winPlaceStarterOverride) => {
        this.placeSelection(
          isQuickbet ? this.PLACE_QUICKBET : this.PLACE_BETSLIP,
          undefined,
          command.source,
          winPlaceStarterOverride
        )
      }
    )

    this.updateShowMultiToast()

    if (this.bettingInformation.isEnhancedBetslip())
      this.evtAggregator.publish('betslip-items-changed', this.betslipItems.length)
  }

  private synchronizeSelections() {
    synchronizeSelections(
      this.bettingInformation.isEnhancedBetslip(),
      this.raceInformation,
      this.betslipItems
    )
    this.updateShowMultiToast()
  }

  private updateIsEnhancedBetslipActive() {
    if (this.bettingInformation)
      this.bettingInformation.updateEnhancedBetslip(this.isEnhancedBetslipFeatureActive)
  }

  public updateShowMultiToast() {
    let { isValidMulti, multiBetslipItems } = getMultiBetslipItems(
      this.bettingInformation.isEnhancedBetslip(),
      this.betslipItems
    )

    // unfortunately we need to *manually* check for equality to avoid unnecessary KO updates (and thus redundant react rendering)
    let hasBetslipChanged = !this.areEqual(multiBetslipItems, this.multiBetslipItems())

    // show the toast
    if (hasBetslipChanged && isValidMulti && this.multiBetslipItems().length > 0) {
      // previous value is n/a since we want to trigger a render
      this.showMultiToast(true)
    } else if (!isValidMulti && this.showMultiToast()) {
      // compare previous value as an optimisation to avoid triggering a redundant render
      this.showMultiToast(false)
    }

    // update betslip items
    if (hasBetslipChanged) this.multiBetslipItems(multiBetslipItems)
  }

  // a quick and dirty object comparison
  // - this doesn't cater for differing property orders which fortunately we don't care about, refer https://stackoverflow.com/a/55256318/227110
  private areEqual<T>(first: T, second: T): boolean {
    return JSON.stringify(first) === JSON.stringify(second)
  }
}

function isOnSkyPage() {
  const skyPageRoute = AppRoutes.RaceCardSky.replace('/', '')
  const hashRoute = window.location.hash.replace('#', '')

  return hashRoute === skyPageRoute
}
