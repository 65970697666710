import React, { useRef, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useLogon, useFeature } from '@core/Utils/hooks'
import { months } from '@mobi/utils'
import {
  ActivityEmailList,
  ActivityStatementContainer,
  ActivityStatementText,
  DateContainer,
  Heading,
} from './ActivityStatement.styles'
import { Select, SelectContainer } from './Components/Select.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import type { Month } from '@mobi/utils'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { dayjs } from '@mobi/utils'
import { ActivityStatementData, createActivityStatement } from './api'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  trackActivityStatementRequestFailure as trackFailure,
  trackActivityStatementRequestSubmitted as trackSubmitted,
  trackActivityStatementRequestSuccess as trackSuccess,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useBackNav } from '@core/Utils/hooks/useBackNav'
import { NoEmailAddress } from './Components/NoEmailAddress'
import CannotFetchData from './Components/CannotFetchData'
import { useActivityStatementQueries as useQueries } from './Hooks/useActivityStatementQueries'
import { AccountHolders } from '@core/Data/Account/accountHolder'
import { ContactDetailsLink } from './Components/ContactDetailsLink'

export const ActivityStatements = (): JSX.Element => {
  useBackNav()

  function onStatementSubmit(): void {
    const currentMonth = +(month.current?.value ?? 0)

    const canSubmit =
      !!accountNumber && isDateValid(year, currentMonth) && selectedEmailAddresses.length > 0

    if (canSubmit) {
      trackSubmitted(accountNumber, currentMonth, year, selectedEmailAddresses)

      submit.mutate({
        accountNumber,
        emailAddresses: selectedEmailAddresses,
        month: currentMonth,
        year,
      })
    }
  }

  function onUpdateSelectedEmailAddresses(email: string, selected: boolean): void {
    const newSelections = new Set<string>(selectedEmailAddresses)
    selected ? newSelections.add(email) : newSelections.delete(email)
    setSelectedEmailAddresses(Array.from(newSelections))
    submit.reset()
  }

  const { accountNumber } = useLogon()

  const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<string[]>([])
  const month = useRef<HTMLSelectElement>(null)
  const today = dayjs()
  const [year, setYear] = useState<number>(today.year())

  const isFeatureActive = useFeature('NCPF_ACTIVITY_STATEMENTS') || false

  const [accountHoldersQuery, betAccountQuery] = useQueries(accountNumber, isFeatureActive)

  const validMonths = useMemo<Month[]>(() => {
    if (!betAccountQuery.data) return months

    const betAccountDetails = betAccountQuery.data
    const accountCreationDate = dayjs(betAccountDetails.AccountCreationDate)

    const filters: Array<(month: Month) => boolean> = []

    // When the selected year is the year the account was created in
    // then we need to filter out every month that comes before it
    if (year === accountCreationDate.year()) {
      filters.push(({ number }) => number >= accountCreationDate.month() + 1)
    }

    // When the selected year is the current year then we need
    // to remove every month that comes after the current
    if (year === today.year()) {
      filters.push(({ number }) => number <= today.month() + 1)
    }

    return filters
      .reduce<Month[]>((months, filter) => {
        return months.filter(filter)
      }, months.slice())
      .reverse()
  }, [betAccountQuery.data, today, year])

  const years = useMemo<number[]>(() => {
    const betAccountDetails = betAccountQuery.data
    return betAccountDetails
      ? Array.from(
          range(dayjs(betAccountDetails.AccountCreationDate).year(), today.year())
        ).reverse()
      : []
  }, [betAccountQuery.data, today])

  const submit = useMutation<unknown, Error, ActivityStatementData>(createActivityStatement, {
    onSuccess: (_, data) => {
      trackSuccess(data.accountNumber, data.month, data.year, data.emailAddresses)
    },
    onError: (error, data) => {
      if (error.message !== 'No transactions') {
        trackFailure(data.accountNumber, data.month, data.year, data.emailAddresses)
      }
    },
  })

  if (!accountNumber || accountHoldersQuery.isLoading || betAccountQuery.isLoading) {
    return <Spinner />
  }

  if (accountHoldersQuery.isError || betAccountQuery.isError) {
    return <CannotFetchData />
  }

  const availableEmailAddresses = getEmailAddresses(accountHoldersQuery.data)

  return (
    <MainContainer>
      <ContentHeader title='Activity Statement Request' />

      <ActivityStatementContainer>
        {submit.isSuccess && !submit.isLoading && (
          <NoticeBox
            role='alert'
            testId='activity-statement-created'
            noticeBoxType={NoticeBoxTypes.Success}
            title='Activity statement will be sent'
            hasBorder
          >
            <p data-testid='activity-statement-created-body'>
              Your activity statement will be sent to <strong>{selectedEmailAddresses[0]}</strong>
              {selectedEmailAddresses.length > 1 && (
                <>
                  {' '}
                  and <strong>{selectedEmailAddresses[1]}</strong>
                </>
              )}
            </p>
          </NoticeBox>
        )}

        {submit.isError && !submit.isLoading && submit.error?.message === 'No transactions' && (
          <NoticeBox
            role='alert'
            testId='activity-statement-request-noop'
            noticeBoxType={NoticeBoxTypes.Info}
            title='Activity statement can not be sent'
            subtitle='There are no transactions on your account for the selected month'
            hasBorder
          />
        )}

        {submit.isError && !submit.isLoading && submit.error?.message !== 'No transactions' && (
          <NoticeBox
            role='alert'
            testId='activity-statement-request-failed'
            noticeBoxType={NoticeBoxTypes.Error}
            title='Activity statement can not be sent'
            subtitle='An error occured while requesting your activity statement'
            hasBorder
          />
        )}

        <ActivityStatementText>
          You can view a detailed report of your TABtouch account activity by sending an Activity
          Statement to your registered email address (listed below).
        </ActivityStatementText>

        <ActivityStatementText>
          Activity statements can be provided for any month you have used your TABtouch account.
          Select the month and year you are interested in and an email will be sent once you have
          confirmed your dates.
        </ActivityStatementText>

        <ActivityStatementText>
          If you wish to add or update your email address please do so <ContactDetailsLink />
        </ActivityStatementText>

        <ActivityStatementText>
          Activity statements provide you with information on your wagering activity, transactions,
          account balances and win/loss outcome.
        </ActivityStatementText>

        <ActivityStatementText>
          Your statement will be sent once details confirmed.
        </ActivityStatementText>

        {availableEmailAddresses.length === 0 && <NoEmailAddress accountNumber={accountNumber} />}

        {availableEmailAddresses.length > 0 && (
          <>
            <Heading>Send email to:</Heading>

            <ActivityEmailList>
              {availableEmailAddresses.map(email => (
                <li key={email}>
                  <label>
                    <input
                      type='checkbox'
                      role='checkbox'
                      disabled={submit.isLoading}
                      value={email}
                      defaultChecked={selectedEmailAddresses.includes(email)}
                      onInput={e =>
                        onUpdateSelectedEmailAddresses(
                          email,
                          (e.target as HTMLInputElement).checked
                        )
                      }
                    />
                    {` ${email}`}
                  </label>
                </li>
              ))}
            </ActivityEmailList>

            <Heading>Select Statement Period</Heading>

            <DateContainer>
              <SelectContainer>
                <Select
                  ref={month}
                  data-testid='month'
                  disabled={submit.isLoading}
                  onChange={() => submit.reset()}
                >
                  {validMonths.map(({ number, name }) => (
                    <option key={number} value={number}>
                      {name}
                    </option>
                  ))}
                </Select>
              </SelectContainer>

              <SelectContainer>
                <Select
                  value={year}
                  onChange={e => {
                    setYear(parseInt(e.target.value))
                    submit.reset()
                  }}
                  data-testid='year'
                  disabled={submit.isLoading}
                >
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </SelectContainer>
            </DateContainer>

            <ButtonBlock
              onClick={onStatementSubmit}
              disabled={selectedEmailAddresses.length === 0 || submit.isLoading}
              isBusy={submit.isLoading}
              testId='send-activity-statement'
            >
              Send Activity Statement
            </ButtonBlock>
          </>
        )}
      </ActivityStatementContainer>
    </MainContainer>
  )
}

function isDateValid(year: number, month: number): boolean {
  const selected = dayjs(new Date(year, month - 1)) // Date month is 0-indexed
  return selected.isSameOrBefore(dayjs(), 'month')
}

function getEmailAddresses(accountHolders?: AccountHolders): string[] {
  const emails = []
  if (accountHolders?.primaryAccountHolder?.Contact?.Email) {
    emails.push(accountHolders.primaryAccountHolder.Contact.Email)
  }
  if (accountHolders?.secondaryAccountHolder?.Contact?.Email) {
    emails.push(accountHolders.secondaryAccountHolder.Contact.Email)
  }
  return emails
}

function* range(from: number, to: number): Generator<number> {
  for (var year = from; year <= to; year++) yield year
}
