import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IFinishersListViewModel } from './IFinishersListViewModel'
import template from './finishers.list.tpl.html'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'

export function register(): void {
  if (!ko.components.isRegistered('finishers-list')) {
    ko.components.register('finishers-list', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableResultsPage
          raceFinishers: ko.ObservableArray<IObservableFinisher>
        }): IFinishersListViewModel => {
          const viewModel = container.get<IFinishersListViewModel>('IFinishersListViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
