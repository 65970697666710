import { useRef, useState } from 'react'
import { useDidMount, useWillUnmount } from 'rooks'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

type ResponseApplePayAvailiability = {
  isAvailable: boolean
}

type Availability = 'unknown' | 'available' | 'unavailable'

type DisposeFunction = VoidFunction

export const useApplePayAvailabilityInterop = () => {
  const [availability, setAvailability] = useState<Availability>('unknown')
  const disposals = useRef<DisposeFunction[]>([])

  useDidMount(() => {
    if (isReactNativeApp) {
      const { dispose } = subscribeToNative(
        'RESPONSE_APPLE_PAY_AVAILABLE',
        (payload: ResponseApplePayAvailiability) => {
          setAvailability(payload.isAvailable ? 'available' : 'unavailable')
        }
      )
      disposals.current.push(dispose)
    }
  })

  useDidMount(() => {
    if (availability === 'unknown') {
      if (!isReactNativeApp) {
        setAvailability('unavailable')
      } else {
        sendToNative('REQUEST_APPLE_PAY_AVAILABLE')
      }
    }
  })

  useWillUnmount(() => {
    disposals.current.forEach(dispose => dispose())
    disposals.current.length = 0
  })

  return availability
}
