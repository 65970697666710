import { useMemo } from 'react'
import {
  RacePageDataTransferObject,
  ResultsPageDataTransferObject,
  isRacePageDto,
} from '@mobi/api-types'
import { StewardsComment } from '@core/Areas/Racing/Components/StewardsComments/types'
import { useRacePageData } from '../useRacePageData'
import { formatStewardsComments } from './helpers/formatStewardsComments'

export const useStewardsComments = ({
  meetingId,
  meetingDate,
  raceNumber,
}: {
  meetingId: string
  meetingDate: Date
  raceNumber: number
}): Return => {
  const { isLoading, isError, race, form } = useRacePageData({
    meetingId,
    meetingDate,
    raceNumber,
    withForm: true,
  })

  const names = useMemo(
    () => raceNumber && race && getRaceStarterNames(raceNumber, race),
    [raceNumber, race]
  )

  if (isLoading || isError || !race || !form || !names) {
    return {
      hasValidStewardsComments: false,
      stewardsComments: [],
    }
  }

  const stewardsComments = formatStewardsComments(form.Forms, names)

  return {
    hasValidStewardsComments: stewardsComments?.length > 0,
    stewardsComments,
  }
}

// Local Helpers

const getRaceStarterNames = (
  raceNumber: number,
  race: RacePageDataTransferObject | ResultsPageDataTransferObject
): string[] => {
  const names: string[] = []
  if (isRacePageDto(race)) {
    const starters = race.RaceStarters
    starters
      .find(s => s.RaceKey.RaceNumber === raceNumber)
      ?.Starters.forEach(s => names.push(s.Name))
  } else {
    race.Finishers.forEach(s => names.push(s.Name))
  }
  return names
}

// Types

type Return = {
  hasValidStewardsComments: boolean
  stewardsComments: StewardsComment[]
}
