import styled from '@emotion/styled'

export const SummaryPopupStyled = styled('div')<{ isDesktop?: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ({ isDesktop }) => ({
    height: isDesktop ? '25rem' : undefined,
    minHeight: isDesktop ? undefined : '20rem',
  })
)

export const ButtonBlockStyled = styled('div')<{ isDesktop?: boolean }>(({ isDesktop }) => ({
  display: isDesktop ? 'flex' : undefined,
}))

export const NoticeboxStyled = styled('div')({
  margin: '2rem 0 -1rem',
})

export const ParagraphStyled = styled('div')({
  color: '#000',
  textAlign: 'left',
  margin: '2rem 0',
})
