import React, { useContext } from 'react'
import { BetSlipContext } from '@mobi/betslip/context'
import type { InvestmentType } from '@mobi/betslip/types'

/** Used for keypad investment input cycling */
export const useRegisterInvestmentInput = ({ itemId, investmentType }: Params) => {
  const registeredInvestmentInputsRef = useContext(BetSlipContext)?.registeredInvestmentInputsRef

  React.useEffect(() => {
    if (!registeredInvestmentInputsRef?.current) return
    registeredInvestmentInputsRef.current.push({ itemId, investmentType })

    return () => {
      registeredInvestmentInputsRef.current = registeredInvestmentInputsRef.current.filter(
        input => !(input.itemId === itemId && input.investmentType === investmentType)
      )
    }
  }, [registeredInvestmentInputsRef, itemId, investmentType])
}

// =====
// Types
// =====

type Params = { itemId: string; investmentType: InvestmentType }
