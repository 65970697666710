import { debounceFn } from '@mobi/utils'
import { FeatureFlags } from '@mobi/settings'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { toggleIsHamburgerMenuOpen } from '@core/Areas/AppHeader/Store'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { store } from '@core/Store'
import { navigateToSkyRacePage } from '@core/Areas/RaceCardSky/helpers/navigateToSkyRacePage'
import { setCloseSkyVideoPlayer, setSkyVideoPlayerStatus, SkyVideoPlayerState } from '../Store'
import { selectIsSkyVideoPlayerOpen } from '../Store/selectors'
import { trackVisionOpened } from '../analytics'

export function toggleSkyVideoPlayer({
  selectedChannel,
  playerStatus,
  isPlayerOpen,
  analyticsData,
}: StartSkyVideoPlayerOptions = {}): void {
  if (isPlayerOpen !== undefined ? !isPlayerOpen : selectIsSkyVideoPlayerOpen(store.getState())) {
    store.dispatch(setCloseSkyVideoPlayer())
    return
  }

  store.dispatch(toggleIsHamburgerMenuOpen(false))

  userAccountState$.take(1).subscribe(({ isLoggedIn }) => {
    const openSkyVideoPlayer = () =>
      store.dispatch(
        setSkyVideoPlayerStatus({ playerStatus: playerStatus || 'floating', selectedChannel })
      )

    if (isLoggedIn) {
      openSkyVideoPlayer()
      analyticsData && trackVisionOpened(analyticsData)
    } else {
      const handleOnLoginSuccess = () => {
        featureState$
          .skipWhile(state => state.isUserAnonymous)
          .take(1)
          .subscribe(state => {
            const isSkyRacePageActive = state.features.get(
              FeatureFlags.SKY_LIVE_RACE_PAGE.key
            ) as boolean
            if (analyticsData?.openLocation === 'bottom-navigation' && isSkyRacePageActive) {
              navigateToSkyRacePage()
            } else openSkyVideoPlayer()
          })
      }
      showLogin({ onLoginSuccessCallback: debounceFn(handleOnLoginSuccess, 500) })
    }
  })
}

// =====
// Types
// =====

type StartSkyVideoPlayerOptions = {
  analyticsData?: Parameters<typeof trackVisionOpened>[0]
} & Partial<Pick<SkyVideoPlayerState, 'selectedChannel' | 'playerStatus' | 'isPlayerOpen'>>
