import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import {
  calculateMultiProjectedPay,
  calculateMultiReturn,
} from '@mobi/betslip/helpers/calculator/multis'
import {
  getBetsInMulti,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isSingleAllowedInMulti,
  isValidMulti,
  isValidMultiInvestmentForLegs,
} from '@mobi/betslip/helpers/state'
import { MAX_LEGS_FOR_MULTI_FORMULA, MIN_LEGS_IN_MULTI } from '@mobi/betslip/helpers/constants'
import { Investment, InvestmentsWrapperStyled } from '../Common/Investment'
import { InlineAlert } from '../Common/InlineAlert'
import { ReceiptNumber } from '../Common/ReceiptNumber'
import { BetCard, FooterButtons } from '@mobi/betslip/Components/Common/BetCard'
import { MultiItem, MultiReturn, ComboMultis, InvestmentSummary } from './Components'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'

export const Multi: React.FC = () => {
  const items = useAppSelector(selectBetSlipItems)
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const activeInvestment = useAppSelector(selectActiveInvestment)
  const receipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const [isComboMultisExapanded, setIsComboMultisExpanded] = React.useState(false)

  const multiItems = items.filter(isSingleAllowedInMulti)
  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI
  const selectedMultiItems = getBetsInMulti(items)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, selectedMultiItems)
  const isMultiInvestmentValid = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.length
  )

  const isEditable = workflowStatus === 'ready'

  if (!hasEnoughMultiItems || (!isEditable && !isMultiValid && !multiBetError)) return null

  const isDisabled = !isEditable || isBusy || receipt != null
  const hasEnoughSelections = selectedMultiItems.length >= MIN_LEGS_IN_MULTI
  const hasTooManyLegs = hasTooManyMultiLegs(selectedMultiItems)
  const multiReturn = calculateMultiReturn(items, { shouldRound: true })
  const isValidNumberOfLegs = !hasTooFewMultiLegs(selectedMultiItems) && !hasTooManyLegs

  const hasAllowableMultiCountForCombos =
    selectedMultiItems.length >= MIN_LEGS_IN_MULTI &&
    selectedMultiItems.length <= MAX_LEGS_FOR_MULTI_FORMULA

  const shouldRenderMultiSummary = !isEditable && isMultiInvestmentValid
  const shouldRenderMultiCombosInputs =
    isEditable && hasAllowableMultiCountForCombos && !multiInvestment.bonusBetId

  return (
    <Accordion
      title='Multi (1)'
      onToggle={() => null}
      shouldStartExpanded
      shouldUseDefaultTheme={false}
    >
      <BetCard.Container
        topComponent={receipt && <InlineAlert type='success' text='Your bet has been placed' />}
      >
        <MultiContentStyled data-testid='Multi'>
          <div data-testid='Multi.Info'>
            <div>
              <span>{selectedMultiItems.length} Leg Multi</span>
              <span>
                {/*  TODO: Handle price change for multi - use reactive method on component */}
                <MultiReturn price={multiReturn} />
              </span>
            </div>

            <div data-testid='Multi.Info.Error'>
              {!hasEnoughSelections && (
                <div>Error: {`${MIN_LEGS_IN_MULTI} legs minimum for Multi`}</div>
              )}

              {!multiBetError?.errorMessage && hasTooManyLegs && (
                <div>
                  Error: Exceeded number of allowed legs for Multi bet. Please amend your bet.
                </div>
              )}

              {multiBetError?.errorMessage && (
                <div>
                  Error:{' '}
                  {multiBetError.errorMessage.indexOf('Multibet') > -1 &&
                  multiBetError.errorMessage.indexOf('must contain no more than') > -1
                    ? 'Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                    : multiBetError.errorMessage}
                </div>
              )}

              {multiInvestment.f1 > 0 && !isMultiInvestmentValid && (
                <div>
                  Warning: Invalid formula investment, &quot;Singles only&quot; is not a valid bet
                  type.
                </div>
              )}
            </div>
          </div>

          <div data-testid='Multi.Selections'>
            {items.map(item => {
              if (workflowStatus !== 'ready' && !item.isInMulti) return null
              return <MultiItem key={item.id || ''} item={item} />
            })}
          </div>

          {isEditable && (
            <InvestmentsWrapperStyled data-testid='Multi.Investment'>
              <Investment
                isActive={activeInvestment?.investmentType === 'Multi'}
                itemId='Multi'
                investmentType='Multi'
                label='Stake'
                isEditable={isValidNumberOfLegs}
                isDisabled={isDisabled}
                value={multiInvestment.value}
                // isBonusBet={multiInvestment.isBonusBet}
              />

              <Investment
                isActive={activeInvestment?.investmentType === 'MultiReverseStake'}
                itemId='MultiReverseStake'
                investmentType='MultiReverseStake'
                label='Potential Payout'
                isEditable={isValidNumberOfLegs}
                isDisabled={isDisabled}
                value={calculateMultiProjectedPay(selectedMultiItems, multiInvestment, true)}
              />
            </InvestmentsWrapperStyled>
          )}

          {shouldRenderMultiSummary && (
            <InvestmentSummary selectedMultiItems={selectedMultiItems} />
          )}
        </MultiContentStyled>

        {workflowStatus !== 'proposed' && (
          <div>
            <BetCard.Footer
              leftSection={
                <>
                  {receipt && <ReceiptNumber ticketNumber={receipt.ticketNumber} />}

                  {shouldRenderMultiCombosInputs && (
                    <FooterButtons.ExpandableContentButton
                      isExpanded={isComboMultisExapanded}
                      onClick={() => setIsComboMultisExpanded(curr => !curr)}
                    >
                      {`${isComboMultisExapanded ? 'Hide' : 'View'} Combo Multis`}
                    </FooterButtons.ExpandableContentButton>
                  )}
                </>
              }
              rightSection={<FooterButtons.ShareButton disabled={isBusy} onClick={() => null} />}
            />

            {shouldRenderMultiCombosInputs && (
              <ComboMultis
                isDisabled={isDisabled}
                shouldExpand={isComboMultisExapanded}
                selectedMultiItems={selectedMultiItems}
              />
            )}
          </div>
        )}
      </BetCard.Container>
    </Accordion>
  )
}

// ======
// Styles
// ======

const MultiContentStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: font.family.primary,

  // Multi Info
  '> div:nth-of-type(1)': {
    '> div:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: spacing.smx1,
      borderBottom: `0.5px solid ${colors.neutral[200]}`,
      fontSize: font.size.lg.fontSize,
      letterSpacing: font.size.lg.letterSpacing,
      lineHeight: font.size.lg.lineHeight,
      fontWeight: font.weight.medium,
      color: colors.black,
    },
  },

  // Selections
  '> div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
    paddingTop: spacing.sm,
    paddingBottom: spacing.sm,
  },
})
