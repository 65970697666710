export const issuerNames = ['VISA', 'MASTER_CARD'] as const
export type IssuerName = (typeof issuerNames)[number]

export type DepositSource =
  | 'ExistingPaymentMethod'
  | 'NewCreditCard'
  | 'PayPal'
  | 'ApplePay'
  | 'GooglePay'

export type PayPalAddress = {
  street: string
  city: string
  postCode: string
  state: string
  countryCode: string
}

export type DepositRequest = {
  amount: number
  depositSource: DepositSource
  paymentMethodNonce: string
  transactionId: string
  deviceData: string | object
}

export type PayPalDepositRequest = DepositRequest & {
  paymentMethodToken: string
  payPalEmail?: string
  payPalPayerId?: string
  firstName?: string
  lastName?: string
  payPalAddress?: PayPalAddress
}

export type CreditCardDepositRequest = DepositRequest & {
  paymentMethodToken: string
  makeDefault: boolean
  forceThreeDSecureClient: boolean
}

export type GooglePayDepositRequest = DepositRequest & {
  forceThreeDSecureClient: boolean
}
