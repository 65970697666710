import styled from '@emotion/styled'

export const StarterDisplayStyled = styled('div')({
  marginBottom: '1.5rem',
})

export const StarterAcceptorStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.3rem',
  minHeight: '2.5rem',
  lineHeight: 1,

  '& > div:first-of-type': {
    paddingRight: '0.5rem',
  },

  '& > strong': {
    minWidth: '2rem',
    textAlign: 'right',
  },

  '& > span:last-of-type': {
    paddingLeft: '0.5rem',
  },
})
