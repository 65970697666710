import React from 'react'
import { getWapi } from '../helpers'

// Ensure Kambi displays the home page. Workaround for an open issue (2021/07/09) whereby the Kambi client
// navigates back to the Bet History page, when we explicitly link to the home page
export const useRouteEnterRedirect = (isNewSportsFeatureActive: boolean): void => {
  const enterHashRef = React.useRef(window.location.hash)

  React.useEffect(() => {
    if (isNewSportsFeatureActive) return
    getWapi().then(wapi => {
      wapi.navigate(enterHashRef.current)
    })
  }, [])
}
