import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { AccountSignUp } from '../Data/AccountSignUp'
import { InternetProspectResponse } from '../Data/InternetProspectResponse'
import { VerificationResponse } from '../Data/VerificationResponse'

export interface SignUpState {
  signUp?: AccountSignUp
  internetProspect?: InternetProspectResponse
  verification?: VerificationResponse
  // This state field is used to communicate the partial/fully verified
  // status of the signup process to the completed signup page.
  // Pseudonym: isCompletedSignupFullyVerified
  rwwaRanking?: number
}

const signUpInitialState: SignUpState = {}

const signUpSlice = createSlice({
  name: 'sign-up',
  initialState: signUpInitialState,
  reducers: {
    setAccountSignUp(state, action: PayloadAction<AccountSignUp | undefined>) {
      state.signUp = action.payload
    },

    setInternetProspect(state, action: PayloadAction<InternetProspectResponse | undefined>) {
      state.internetProspect = action.payload
    },

    setVerification(state, action: PayloadAction<VerificationResponse | undefined>) {
      state.verification = action.payload
    },

    setRwwaRanking(state, action: PayloadAction<number | undefined>) {
      state.rwwaRanking = action.payload
    },
  },
})

export const { setAccountSignUp, setInternetProspect, setVerification, setRwwaRanking } =
  signUpSlice.actions

export default persistReducer(
  {
    key: 'sign-up',
    whitelist: ['signUp', 'internetProspect'],
    storage,
    debug: !PRODUCTION,
  },
  signUpSlice.reducer
)
