export default class Index {
  public static toString(index: number): string {
    if (index === 1) return '1st'

    if (index === 2) return '2nd'

    if (index === 3) return '3rd'

    if (index === 4) return '4th'

    return ''
  }
}
