import React from 'react'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import type { ThemeName } from './Banner.styles'

type Props = { theme: ThemeName }

const IconSize = '2.4rem'

export default ({ theme }: Props): JSX.Element | null => {
  switch (theme) {
    case 'neutral':
      return <Icon name='BannerNeutral' size={IconSize} />
    case 'primary':
      return <Icon name='BannerPrimary' size={IconSize} />
    case 'success':
      return <Icon name='BannerSuccess' size={IconSize} />
    case 'warning':
      return <Icon name='BannerWarning' size={IconSize} />
    case 'error':
      return <Icon name='BannerError' size={IconSize} />
    default:
      return null
  }
}
