import { IWinPlaceProcessor } from './IWinPlaceProcessor'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ButtonSelectionType } from '../UIElements/ButtonSelectionType'
import { ButtonsSelection } from '../UIElements/ButtonsSelection'
import Guard from '../../../../AppUtils/Framework/Guard'

export default class WinPlaceToteProcessor implements IWinPlaceProcessor {
  public process(
    starter: IObservableStarter,
    starters: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation
  ): Array<IObservableStarter> {
    Guard.notNull(starter)
    Guard.notNull(starters)
    Guard.notNull(bettingContext)

    bettingContext.turnOffFieldAt(starter.selection().raceNumber.leg() - 1)
    bettingContext.isFixed(false)

    const starterSelection = starter.selection() as ButtonsSelection

    const results = starters()
      .filter(s => s.number() !== starter.number() && !s.isScratchedToteAndFob())
      .reduce<IObservableStarter[]>((newStarters, starter) => {
        if (!starter.selection) {
          return newStarters
        }

        const selection = starter.selection() as ButtonsSelection

        if (!selection) {
          return newStarters
        }

        if (selection.value() === ButtonSelectionType.Fob) {
          // remove any fixed selections when a tote selection is made
          selection.value(ButtonSelectionType.None)
        } else if (selection.value() === ButtonSelectionType.Tote) {
          // add the new tote selection
          newStarters.push(starter)
        }

        return newStarters
      }, [])

    if (starter.isScratchedToteAndFob() || starter.isScratched()) {
      if (starterSelection) {
        starterSelection.value(ButtonSelectionType.None)
      }
    } else {
      if (starterSelection.value() === ButtonSelectionType.Tote) {
        results.push(starter)
      }
    }

    // contains non-scratched selected starter, AND any other starters with Tote selection
    return results
  }
}
