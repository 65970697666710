import React, { type FC } from 'react'
import styled from '@emotion/styled'
import { colors, radius, shadow, spacing, font } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'

const enum LocalConstants {
  AccordionExpandedClass = 'js-accordion--expanded',
  DefaultThemeClass = 'js-accordion--default-theme',
  ChevronClass = 'js-accordion__chevron',
}

export const Accordion: FC<{
  title: React.ReactNode
  children: React.ReactNode
  /** Triggered when the accordion is toggled */
  onToggle?: (isExpanded: boolean) => void
  /** Determines if the accordion starts off already expanded */
  shouldStartExpanded?: boolean
  /** Controls if main theme should used */
  shouldUseDefaultTheme?: boolean
}> = ({ title, children, onToggle, shouldStartExpanded, shouldUseDefaultTheme = true }) => {
  const isFirstRenderRef = React.useRef(true)

  const [buttonId] = React.useState<string>(() => `${title}.button`)
  const [regionId] = React.useState<string>(() => `${title}.region`)

  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    400,
    shouldStartExpanded
  )

  // If shouldStartExpanded, prevent animation on mount
  React.useLayoutEffect(() => {
    if (!isFirstRenderRef.current || !shouldStartExpanded || !wrapperRef.current) return
    wrapperRef.current.style.height = 'auto'
    wrapperRef.current.style.opacity = '1'
    isFirstRenderRef.current = false
  }, [shouldStartExpanded, wrapperRef])

  // Handle content opacity toggling
  React.useEffect(() => {
    if (!wrapperRef.current) return
    wrapperRef.current.style.opacity = isExpanded ? '1' : '0'
  }, [wrapperRef, isExpanded])

  const classNames = []
  if (isExpanded) classNames.push(LocalConstants.AccordionExpandedClass)
  if (shouldUseDefaultTheme) classNames.push(LocalConstants.DefaultThemeClass)

  return (
    <ContainerStyled className={classNames.join(' ')}>
      <h2>
        <button
          onClick={() => {
            onToggle?.(isExpanded)
            toggleExpanded()
          }}
          aria-expanded={isExpanded}
          aria-controls={regionId}
          id={buttonId}
        >
          <span>{title}</span>

          <span className={LocalConstants.ChevronClass}>
            <Icon
              size={shouldUseDefaultTheme ? '1.6rem' : '2.4rem'}
              name='SolidChevronDown'
              color='inherit'
            />
          </span>
        </button>
      </h2>

      {(isExpanded || shouldRender) && (
        <div ref={wrapperRef} aria-labelledby={buttonId} id={regionId} role='region'>
          <div ref={contentRef}>{children}</div>
        </div>
      )}
    </ContainerStyled>
  )
}

// ======
// Styles
// ======

const ContainerStyled = styled.div({
  // Header
  '> h2': {
    margin: 0,

    '> button': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${spacing.smx1} ${spacing.sm} ${spacing.smx1} ${spacing.md}`,
      background: 'unset',
      border: 0,

      '&:hover': { cursor: 'pointer' },

      // Title Text
      '> span:first-of-type': {
        flex: 1,
        fontFamily: font.family.primary,
        fontSize: font.size.lg.fontSize,
        letterSpacing: font.size.lg.letterSpacing,
        lineHeight: font.size.lg.lineHeight,
        color: colors.black,
        fontWeight: font.weight.medium,
        textAlign: 'left',
      },

      // Chevron Icon
      ['> .' + LocalConstants.ChevronClass]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: spacing.bigx3,
        width: spacing.bigx3,
        borderRadius: '50%',
        transform: 'rotate(0deg)',
        transition: `all 400ms ease`,
        color: colors.neutral[900],
      },
    },
  },

  // Wrapper
  '> div': {
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    transition: 'height 400ms ease, opacity 600ms ease',
    willChange: 'height',

    // Content
    '> div': {
      paddingLeft: spacing.md,
      paddingRight: spacing.md,
    },
  },

  ['&.' + LocalConstants.AccordionExpandedClass]: {
    ['> h2 > button > .' + LocalConstants.ChevronClass]: {
      transform: 'rotate(-180deg)',
    },
  },

  ['&.' + LocalConstants.DefaultThemeClass]: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral[200]}`,
    borderRadius: radius.md,
    boxShadow: shadow.xs,

    // Wrapper
    '> div': {
      borderTop: `0.05rem solid ${colors.neutral[200]}`,

      // Content
      '> div': {
        paddingTop: spacing.sm,
        paddingBottom: spacing.sm,
      },
    },

    ['> h2 > button > .' + LocalConstants.ChevronClass]: {
      backgroundColor: colors.surface[50],
    },

    ['&.' + LocalConstants.AccordionExpandedClass]: {
      ['> h2 > button > .' + LocalConstants.ChevronClass]: {
        backgroundColor: colors.studio[500],
        color: colors.white,
      },
    },
  },
})
