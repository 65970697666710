import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const PaginationStyled = styled('div')({
  margin: 0,
  textAlign: 'center',
})

interface PaginationDotProps {
  active: boolean
}

const dotSize = '0.8rem'

export const PaginationDotStyled = styled('span')<PaginationDotProps>(
  {
    border: `0.1rem solid ${hexColors.veryLightGrey}`,
    borderRadius: '100%',
    display: 'inline-block',
    height: dotSize,
    margin: '0.5rem 0.2rem',
    width: dotSize,
  },

  ({ active }) => ({
    backgroundColor: active ? hexColors.veryLightGrey : 'transparent',
  })
)
