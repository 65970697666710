import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { createSignal } from 'rwwa-rx-state-machine'
import { observe } from '../HOCs'
import { Pagination } from '../Pagination'

export interface SwipedProps {
  index: number
}

export const Swiped = createSignal<SwipedProps>('Swiped')

const KeypadSwitch$ = Swiped.signal$
  .filter(t => t.tag === Swiped)
  .map(t => ({ index: t.data.index }))
  .startWith({ index: 0 })

const changeIndex = (index: number) => Swiped({ index })

interface SwiperProps {
  children: JSX.Element[]
  usePagination?: boolean
}

export const Swiper = observe<SwipedProps, SwiperProps>(
  KeypadSwitch$,
  ({ children, index, usePagination = true }) => {
    return (
      <div>
        {
          // Overwriting the `containerStyle` to temporarily reset the `willChange` property as it causes
          // issues on iOS 10.3.2 in combination with the current mobi layout.
          // It's also considered bad practice to prematurely add the property, but that's a different issue with the 3rd party pkg.
          // https://dev.opera.com/articles/css-will-change-property/
        }
        <SwipeableViews
          threshold={10}
          enableMouseEvents={true}
          index={index}
          onChangeIndex={changeIndex}
          containerStyle={{ willChange: 'auto' }}
        >
          {children}
        </SwipeableViews>
        {usePagination && <Pagination dotCount={children.length} index={index} />}
      </div>
    )
  }
)
