import React from 'react'
import {
  useRaceFormData,
  isRacesFormData,
  isTrotsFormData,
  isDogsFormData,
} from '@core/Areas/Racing/Hooks/useRaceFormData'
import { Races, Trots, Dogs } from './Components'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

export const Form = React.memo<{
  meetingId: string
  meetingDate: Date
  raceNumber: number
  starterNumber: number
  courseCondition?: string
}>(({ meetingId, meetingDate, raceNumber, starterNumber, courseCondition }) => {
  const { isLoading, isError, data } = useRaceFormData({ meetingDate, meetingId, raceNumber })
  if (isLoading)
    return <LoadingPlaceholder testId='form-loading-placeholder' width='100%' height='20rem' />

  if (!data || isError) return null

  if (isRacesFormData(data)) {
    return (
      <Races
        form={data.Forms.find(form => form.StarterNumber === starterNumber)}
        courseCondition={courseCondition}
      />
    )
  }

  if (isTrotsFormData(data)) {
    return <Trots form={data.Forms.find(form => form.StarterNumber === starterNumber)} />
  }

  if (isDogsFormData(data)) {
    return <Dogs form={data.Forms.find(form => form.StarterNumber === starterNumber)} />
  }

  return null
})
