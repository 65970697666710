import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import { TableStyled } from './Formulas.styles'

export const Formulas: React.FC<{ formulaDetail: Ticket['formula'] }> = ({ formulaDetail }) => {
  if (!formulaDetail || formulaDetail?.lines.length === 0) return null
  return (
    <div>
      <TableStyled>
        <thead>
          <tr data-testid='betformula-header-row'>
            <th data-tid-betformula-formulastring=''>
              {formulaDetail?.formulaString.replace(/\./g, '.\u200B')}
            </th>
            <th className='text-right'>Bet Amount</th>
            <th className='text-right'>Cost</th>
          </tr>
        </thead>

        <tbody data-testid='betformula-body'>
          {formulaDetail?.lines.map(line => (
            <tr key={line.key}>
              <td>
                {line.key}
                {line.combos > 0 ? ` \u00D7 ${line.combos}` : null}
              </td>
              <td className='text-right'>
                {line.stake > 0 ? <Money amount={line.stake} /> : null}
              </td>
              <td className='text-right'>
                <Money amount={line.combinedStake} />
              </td>
            </tr>
          ))}
        </tbody>
      </TableStyled>
    </div>
  )
}
