import React from 'react'
import { PriceChange } from '@core/Components/Text/utils'
import { MoneyGrouped } from '@core/Components/Text/MoneyGrouped'

interface MultiReturnComponentProps {
  price: number | null
  priceChange: PriceChange
}

export const MultiReturn = ({ price, priceChange }: MultiReturnComponentProps) => {
  if (!price) {
    return <span>N/A</span>
  }
  return <MoneyGrouped price={price} priceChange={priceChange} />
}
