//Note: other modules (eg. TabLocatorViewModel) show/hide the loading overlay themselves (which is bad)

var shownFlag = false /* standard show/hide flag */
var counter = 0 /* stack counter for pop/push */
var overlayElem: HTMLElement

const createOverlay = () => {
  let elem = document.createElement('div') as HTMLElement
  elem.innerHTML =
    '<div id="loading-image">\
<div class="spinner spinner-purple fz-24"></div>\
</div>'
  elem.id = 'loading-overlay'
  elem.setAttribute('data-tid-loading-overlay', '')
  return elem
}

const insertOverlay = (elem: HTMLElement) => {
  const container = document.querySelector('.page-container')

  if (container) {
    container.appendChild(elem)
  } else {
    throw new Error('Page container not found')
  }
}

const render = () => {
  if (!overlayElem) {
    overlayElem = createOverlay()
    insertOverlay(overlayElem)
  }

  if (shownFlag || counter) {
    overlayElem.style.display = 'block'
  } else {
    overlayElem.style.display = 'none'
  }
}

export const showLoading = () => {
  shownFlag = true
  counter = 0
  render()
}

export const hideLoading = () => {
  shownFlag = false
  render()
}

/* allow nested show/hides, operates in addition to show/hide */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushLoading = (action: any) => {
  counter++
  render()

  return action(() => {
    if (counter > 0) {
      counter--
    }
    render()
  })
}
