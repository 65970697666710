import { inject, injectable } from 'inversify'
import ObservableRaceStarter from '@classic/Betting-v2/Model/Observables/ObservableRaceStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IRaceStarterViewModel } from './IRaceStarterViewModel'
import BaseStarterViewModel from './../BaseStarterViewModel'
import { MatchedFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummary'
import { JockeyName } from '@core/Areas/RaceCard/Components/JockeyName'

@injectable()
export class RaceStarterViewModel extends BaseStarterViewModel implements IRaceStarterViewModel {
  public MatchedFieldSummary: React.ReactNode
  public JockeyName: React.ReactNode

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.JockeyName = JockeyName
    this.MatchedFieldSummary = MatchedFieldSummary
  }

  init(params: {
    data: ObservableRaceStarter
    raceNumber: ObservableRaceKey
    context: { bettingContext: BettingInformation }
    numberOfStartersInRace: ko.Computed<number>
    doubleProvDivAllowed: ko.Observable<boolean>
    quaddieProvDivAllowed: ko.Observable<boolean>
    isRaceClosed: ko.Observable<boolean>
  }) {
    super.initBase(
      params.data,
      params.raceNumber,
      params.context.bettingContext,
      'Races',
      params.numberOfStartersInRace,
      params.doubleProvDivAllowed,
      params.quaddieProvDivAllowed,
      params.isRaceClosed
    )
  }
}
