import { useFeature } from '@core/Utils/hooks'
import { useSelector } from 'react-redux'
import { getNewSportsSetting } from '../../Areas/Settings/Store/selectors'

export const useIsNewSportsFeatureEnabled = () => {
  const isNewSportsFeatureActive = useFeature('FIXED_ODDS_SPORTS')
  const isNewSportsFeatureSettingActive = useSelector(getNewSportsSetting)

  return isNewSportsFeatureActive && isNewSportsFeatureSettingActive
}
