import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import {
  BETSLIP_ITEM_PADDING,
  BETSLIP_ITEM_BOX_SHADOW,
  ListStyled,
} from '@core/Areas/Betslip/Components/SingleItemList/SingleItemList.styles'
import { BetInvestmentGroupStyled } from '@core/Areas/Betslip/Components/BetInvestment/BetInvestment.styles'

export const EmptyItemStyled = styled('div')({
  marginTop: '1.4rem',
  fontSize: '1.4rem',
  textAlign: 'center',
  color: colors.betslip.item.empty.text,
})

export const MultiSectionStyled = styled('div')({
  margin: '0.5rem',
  padding: BETSLIP_ITEM_PADDING,
  boxShadow: BETSLIP_ITEM_BOX_SHADOW,
  backgroundColor: colors.betslip.item.background,
})

export const MultiItemListStyled = styled(ListStyled)({
  padding: 0,
  marginBottom: '0.5rem',
})

export const MultiSpendStyled = styled(BetInvestmentGroupStyled)({
  width: '100%',
})

export const MultiDetailsStyled = styled('div')<{ isCenter?: boolean }>(
  {
    position: 'relative',
    fontSize: '1.2rem',
    lineHeight: 'normal',
    padding: '1rem 0 0.5rem 0',
    color: colors.betslip.multiDetails.label.color,
  },
  ({ isCenter = false }) => {
    if (!isCenter) {
      return undefined
    }

    return {
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '0.1rem',
        height: '2.6rem',
        left: '-1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: colors.betslip.multiDetails.border,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.1rem',
        height: '2.6rem',
        right: '-1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: colors.betslip.multiDetails.border,
      },
    }
  }
)

export const MultiDetailsAmountStyled = styled('strong')({
  fontSize: '1.4rem',
  display: 'block',
  color: colors.betslip.multiDetails.amount.color,
})

export const MultiTotalContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  lineHeight: 1,
  padding: '1rem 0',
  fontSize: '1.2rem',
  color: colors.betslip.multiDetails.label.color,
  height: '2rem',
  borderTop: `0.1rem solid ${colors.betslip.item.border}`,
})

export const MultiTotalAmountStyled = styled('span')({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: colors.betslip.formulas.text,
})
