import styled from '@emotion/styled'
import { ellipsis } from 'polished'
import { colors } from '@core/Settings'
import { PillStyled } from '@core/Components/Pill'
import { modalXPadding } from '@core/Areas/Quickbet/Quickbet.styles'

export const HeaderStyled = styled.header({
  padding: `1rem ${modalXPadding}`,
  backgroundColor: colors.quickbet.header.background,
  color: colors.quickbet.header.text,
  fontSize: '1.2rem',
})

export const HeaderPillStyled = styled(PillStyled)({
  backgroundColor: colors.quickbet.header.pill.background,
  lineHeight: 1.2,
  padding: '0.3rem 0.7rem',
  boxSizing: 'border-box',
})

export const MarketPillStyled = styled(HeaderPillStyled)({
  fontWeight: 'bold',
  marginLeft: '-0.7rem',
  maxWidth: 'calc(100% + 0.7rem)',
  display: 'flex',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  marginTop: '0.1rem',
})

export const MarketNameStyled = styled.span({
  ...ellipsis('100%'),
})

export const MarketTypeStyled = styled.span({
  paddingLeft: '0.3rem',
  minWidth: '3.5rem',
  flexGrow: 1,
  textAlign: 'right',
  textTransform: 'uppercase',
})

export const MarketStyled = styled.div({ display: 'flex' })

export const CompetitionAndTournamentStyled = styled.div({
  ...ellipsis('100%'),
  width: 'calc(100% - 2.5rem)',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  lineHeight: 1.3,
  paddingBottom: '0.2rem',
})

export const SingleSelectionStyled = styled.div({
  fontSize: '1.8rem',
  fontWeight: 'bold',
  lineHeight: 'normal',
  padding: '1rem 0 0',
})

export const EventStyled = styled.div({
  ...ellipsis('100%'),
  width: 'calc(100% - 2.5rem)',
  fontSize: '1.2rem',
  lineHeight: 1,
})
