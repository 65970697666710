import { z } from 'zod'

const errorCodes = [
  'invalid_body',
  'unknown_account',
  'no_primary_contact',
  'already_verified',
  'exhausted_verification_attempts',
  'name_or_dob_change_disallowed',
  'no_data_from_upstream',
  'duplicate_account_holder',
] as const

export const verifyErrorMessageSchema = z.object({
  error: z.string({
    description: 'The error message returned from the backend',
  }),
  code: z.enum(errorCodes, {
    description: 'The error code that describes why the error happened',
  }),
})

export type VerifyErrorMessage = z.infer<typeof verifyErrorMessageSchema>
