import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import type { Direction } from './types'

// Animations
const swipeBackward = keyframes({
  '33%': { transform: 'translateX(100%)', opacity: 0 }, // Completely to the right
  '50%': { transform: 'translateX(-200%)', opacity: 0 }, // Completely to the left out of view
  '100%': { transform: 'translateX(0%)', opacity: 1 }, // Back to original position
})

const swipeForward = keyframes({
  '33%': { transform: 'translateX(-100%)', opacity: 0 }, // Completely to the left
  '50%': { transform: 'translateX(200%)', opacity: 0 }, // Completely to the right out of view
  '100%': { transform: 'translateX(0%)', opacity: 1 }, // Back to original position
})

export const AnimatedSwipeContainerStyled = styled.div<{
  animateDirection?: Direction
}>({}, ({ animateDirection }) => {
  if (!animateDirection) return null
  const animationName = animateDirection === 'FORWARD' ? swipeForward : swipeBackward
  return {
    animation: `${animationName} 0.65s ease-in-out forwards`,
    opacity: 0.5,
  }
})
