import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const SelectBoxStyled = styled('select')<{ error?: string | boolean }>(
  {
    appearance: 'none',
    backgroundImage: `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjU2cHgiIGhlaWdodD0iMjU2cHgiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTYgMjU2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHRpdGxlPmFycm93ZG93bjwvdGl0bGU+PGcgaWQ9ImFycm93ZG93biI+PHBhdGggZD0iTTI1Niw4MGwtMzItMzJsLTk2LDk2TDMyLDQ4TDAsODBsMTI4LDEyOEwyNTYsODB6Ii8+PC9nPjwvc3ZnPg==)`,
    backgroundColor: hexColors.lightGreyCommon,
    backgroundPosition: 'right 1rem top 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.2rem',
    border: `1px solid ${hexColors.veryLightGrey}`,
    borderRadius: 0,
    boxSizing: 'border-box',
    fontSize: '1.4rem',
    margin: '0.5rem 0 0.6rem 0',
    padding: '1rem',
    width: '100%',
  },

  ({ error }) => ({
    outline: error ? '0.1rem solid red' : undefined,
  })
)

export const OptionStyled = styled('option')({
  appearance: 'none',
  background: hexColors.white,
  fontSize: '1.4rem',
  padding: '0.3rem 1rem',
  height: '2rem',
})
