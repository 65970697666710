import React from 'react'
import styled from '@emotion/styled'
import {
  ComplexPasswordObserve,
  CreatePassword,
  Reset,
  state$ as passwordState$,
} from '@core/Areas/Password'
import { PasswordThemes } from '@core/Areas/Password/themes'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { trackLoginResetPasswordSuccess } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { NoticeBox, NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import type { usePasswordReset } from '../hooks/usePasswordReset'
import { getDeliveryMethodName } from '../../Shared/DeliveryMethods'
import { postChangePassword } from '../helpers/api'

/** Step 4 is the final step, user should not be allowed to go back */
export const Step4SetNewPassword: React.FC<Step4SetNewPasswordProps> = ({
  handleReturnToLogin,
  validationCode,
  accountNumber,
  selectedDeliveryMethod,
  currentValidationCodeRef,
}) => {
  const plainPasswordRef = React.useRef<string | null>(null)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)
  const [isBusy, setIsBusy] = React.useState(false)

  React.useEffect(() => {
    if (!isPasswordChangeSuccessful) return
    trackLoginResetPasswordSuccess(
      accountNumber,
      getDeliveryMethodName(selectedDeliveryMethod as number)
    )
  }, [isPasswordChangeSuccessful, accountNumber, selectedDeliveryMethod])

  React.useEffect(() => {
    const passwordSubscription = passwordState$.skip(1).subscribe(state => {
      plainPasswordRef.current = state.new
      setIsValid(state.isValidationSuccess)
      setErrorMessage(undefined)
    })

    return () => {
      passwordSubscription.dispose()
      Reset()
    }
  }, [])

  if (isPasswordChangeSuccessful) {
    return (
      <>
        <NoticeBox
          title='Password Successfully Reset'
          subtitle='Your new password has been saved, and is now ready to use.'
          noticeBoxType={NoticeBoxTypes.Success}
          hasBorder
        />

        <ButtonBlock
          tabIndex={101}
          data-testid='password-reset-login'
          color='primary'
          onClick={handleReturnToLogin}
        >
          Log in Now
        </ButtonBlock>
      </>
    )
  }

  const handleChangePasswordClick = async () => {
    CreatePassword()
    setIsBusy(true)
    const { key = '', expires = '' } = currentValidationCodeRef.current || {}
    const res = await postChangePassword({
      accountNumber,
      key,
      expires,
      plaintextPassword: plainPasswordRef.current as string,
      code: validationCode,
    })
    setIsBusy(false)
    if (typeof res === 'string') return setErrorMessage(res)
    setIsPasswordChangeSuccessful(true)
  }

  return (
    <>
      {errorMessage && (
        <NoticeBoxSingle title={errorMessage} noticeBoxType={NoticeBoxTypes.Error} hasBorder />
      )}

      <WrappedComponentStyled>
        <ComplexPasswordObserve themeName={PasswordThemes.dark} />
      </WrappedComponentStyled>

      <ButtonBlock
        id='submit-next'
        data-testid='password-reset-next'
        color='primary'
        onClick={handleChangePasswordClick}
        disabled={!isValid}
      >
        {isBusy ? <SpinnerInlineStyled size={2} /> : 'Submit New Password'}
      </ButtonBlock>
    </>
  )
}

// ======
// Styles
// ======

const WrappedComponentStyled = styled('div')({
  ul: {
    marginTop: 0,
  },

  'ul li': {
    fontSize: '1.2rem',
  },

  // Shared InputField component needs to be made more flexible, use important for now
  'input[type="password"], input[type="text"]': {
    fontSize: '1.4rem !important',
  },
})

// =====
// Types
// =====

type Step4SetNewPasswordProps = {
  handleReturnToLogin(): void
  currentValidationCodeRef: React.MutableRefObject<
    | {
        expires: string
        key: string
      }
    | undefined
  >
} & Pick<
  ReturnType<typeof usePasswordReset>['state'],
  'accountNumber' | 'validationCode' | 'selectedDeliveryMethod'
>
