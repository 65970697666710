type KnownWeatherClasses =
  | 'weathersunny'
  | 'weathercloudy'
  | 'weathercloudypartly'
  | 'weatherraining'
type UnknownWeatherClass = 'weatherunknown'

export type WeatherClass = KnownWeatherClasses | UnknownWeatherClass

type WeatherClassMap = Record<KnownWeatherClasses, string[]>

export class WeatherClassUtil {
  private static classes: WeatherClassMap = {
    weathersunny: ['sunny', 'clear', 'good', 'fine'],

    weathercloudy: ['cloudy', 'cloud'],

    weathercloudypartly: ['overcast', 'average'],

    weatherraining: [
      'rain',
      'rainy',
      'raining',
      'drizzle',
      'storm',
      'stormy',
      'awful',
      'bad',
      'showery',
      'showers',
    ],
  }

  public static readonly defaultClass: UnknownWeatherClass = 'weatherunknown'

  public static getClassForWeather(Weather: string): string {
    Weather = Weather.toLowerCase()

    for (var weatherClass in WeatherClassUtil.classes) {
      if (WeatherClassUtil.classes[weatherClass as KnownWeatherClasses].indexOf(Weather) !== -1) {
        return weatherClass
      }
    }

    return WeatherClassUtil.defaultClass
  }
}
