import React from 'react'
import { callCenterPhoneNumber } from '@mobi/utils'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { List, ListItem, ListLink } from '@core/Components/List'
import { FeedbackForm } from './Components/FeedbackForm/FeedbackForm'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableImmutable } from '@core/Utils/hooks'

export const ContactUsPage = (): JSX.Element | null => {
  const { accountNumber, isLoggedIn } = useObservableImmutable(userAccountState$, [
    'accountNumber',
    'isLoggedIn',
  ])
  const shouldEnableFeedbackForm = isLoggedIn && !!accountNumber

  return (
    <MainContainer data-testid='contact-us-page'>
      <ContentHeader title='CONTACT US' />
      <h3>Customer Service</h3>
      <List>
        <ListItem>
          <ListLink href={callCenterPhoneNumber}>1300 36 36 88</ListLink>
        </ListItem>
      </List>

      <h3>1300BETLIVE</h3>
      <List>
        <ListItem>
          <ListLink href='tel:1300238548'>1300 23 85 48</ListLink>
        </ListItem>
      </List>

      <h3>Betting</h3>
      <List>
        <ListItem>
          <ListLink href='tel:132369'>13 23 69</ListLink>
        </ListItem>
      </List>

      <h3>Email</h3>
      <List>
        <ListItem>
          <ListLink href='mailto:ContactUs@tabtouch.com.au'>ContactUs@tabtouch.com.au</ListLink>
        </ListItem>
      </List>

      {shouldEnableFeedbackForm && (
        <>
          <h3>Feedback</h3>
          <FeedbackForm accountNumber={accountNumber} />
        </>
      )}
    </MainContainer>
  )
}
