import React from 'react'
import styled from '@emotion/styled'
import { useInfiniteQuery } from 'react-query'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { Button } from '@mobi/component-library/Common/V2'
import { radius, spacing } from '@mobi/component-library/Theme/Common'
import { debounceFn } from '@mobi/utils/functions'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { get } from '@classic/Foundation/Services/ApiService'
import type { Tabs, TicketResponseData } from '@core/Areas/AccountActivity/types'

export const useAccountActivityData = ({
  activeTab,
  isOrderByEventStart,
  selectedDate,
}: {
  activeTab: Tabs
  isOrderByEventStart: boolean
  selectedDate?: string
}) => {
  const { fetchNextPage, isFetchingNextPage, isLoading, isError, data, refetch, remove } =
    useInfiniteQuery(
      queryKeys.accountActivityData(activeTab, isOrderByEventStart, selectedDate),
      ({ pageParam }) =>
        fetchPendingTicketsData({
          nextPage: pageParam,
          activeTab,
          isOrderByEventStart,
          selectedDate,
        }),
      {
        getNextPageParam: prevPage => {
          if (prevPage.isFinalPage) return undefined
          return prevPage._nextPage || null
        },
        staleTime: 1_000 * 60 * 2, // 2 mins
        // Ensure fresh data on entry, rather than invalidate in all locations where bets are placed
        refetchOnMount: 'always',
      }
    )

  const isFinalPage = data && data.pages.length > 0 && data.pages[data.pages.length - 1].isFinalPage
  const isEmptyState = isFinalPage && data.pages[0].sortedIds.length === 0

  return {
    data,
    isError,
    isLoading,
    isEmptyState,
    isFinalPage,
    refetch: () => {
      // Modified refetch to only fetch first page, otherwise could be heavy
      remove()
      refetch()
    },
    /** Will auto fetch next page when in view */
    LoadMoreButton: () => (
      <LoadMorePendingTickets onClick={fetchNextPage} isFetching={isFetchingNextPage} />
    ),
    SkeletonLoader,
  }
}

// =============
// Local Helpers
// =============

const fetchPendingTicketsData = ({
  activeTab,
  isOrderByEventStart,
  nextPage,
  selectedDate,
}: {
  activeTab: Tabs
  isOrderByEventStart: boolean
  nextPage: string | null
  selectedDate?: string
}) => {
  const url = buildDataUrl({ activeTab, isOrderByEventStart, nextPage, selectedDate })
  return get<TicketResponseData>({ url })
}

function buildDataUrl({
  activeTab,
  isOrderByEventStart,
  nextPage,
  selectedDate,
}: {
  activeTab: Tabs | null
  isOrderByEventStart: boolean
  nextPage: string | null
  selectedDate?: string
}): string {
  const apiUrlBase = '/$_/accountActivity'

  let optionalHeadQuery: string = ''
  switch (activeTab) {
    case 'pending': {
      optionalHeadQuery = !nextPage ? '?isOrderByEventDate=' + isOrderByEventStart : ''
      break
    }
    case 'results': {
      optionalHeadQuery = !nextPage
        ? `?isOrderByEventDate=${isOrderByEventStart}` +
          (selectedDate ? `&selectedDate=${selectedDate}` : '')
        : ''
      break
    }
    case 'transactions': {
      optionalHeadQuery = selectedDate ? `?selectedDate=${selectedDate}` : ''
      break
    }
    case 'rebet':
      optionalHeadQuery = !nextPage ? '?isOrderByEventDate=true' : ''
      break
  }

  return !nextPage
    ? `${apiUrlBase}/tickets/${activeTab}/head${optionalHeadQuery}`
    : apiUrlBase + nextPage
}

// ===============
// Local Component
// ===============

const LoadMorePendingTickets: React.FC<{
  onClick: () => void
  isFetching: boolean
}> = ({ onClick, isFetching }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const containerEl = wrapperRef.current
    if (!containerEl || isFetching) return
    const fetchNextPage = debounceFn(onClick, 300)
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) fetchNextPage()
    })
    observer.observe(containerEl)
    return () => observer.disconnect()
  }, [wrapperRef, onClick, isFetching])

  return (
    <div ref={wrapperRef}>
      <Button color='secondary_outline' size='sm' onClick={onClick} isFullWidth>
        {isFetching ? <SpinnerInlineStyled size={2} color='dark' /> : 'Load More'}
      </Button>
    </div>
  )
}

const SkeletonLoader: React.FC = () => (
  <WrapperStyled>
    {Array(9)
      .fill(null)
      .map((_, i) => (
        <LoadingPlaceholder key={i} width='100%' height='10rem' borderRadius={radius.lg} />
      ))}
  </WrapperStyled>
)

const WrapperStyled = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
})
