import { addBreadcrumb, captureException, captureMessage, SeverityLevel } from '@sentry/react'

export type Category = 'deposit' | 'verification'

export const addCrumb = (category: Category, message: string, level: SeverityLevel = 'info') => {
  addBreadcrumb({
    level,
    category,
    message,
  })
}

export const reportWarning = (category: Category, message: string) => {
  addCrumb(category, message, 'warning')
  captureMessage(message)
}

export const reportError = (category: Category, message: string) => {
  addCrumb(category, message, 'error')
  captureException(new Error(message))
}
