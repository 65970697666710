import React from 'react'
import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import { useAppSelector } from '@core/Store/hooks'
import { getActiveToteSwap, getToteSwapOptions } from '@core/Areas/RaceCard/Store/selectors'

export const useToteSwapToggle = (isEnhancedBetslip: boolean) => {
  const toteSwapOptions = useAppSelector(getToteSwapOptions)
  const activeToteSwapOption = useAppSelector(getActiveToteSwap)

  const isInsuranceAvailable = toteSwapOptions.includes('CON')
  const isStartingPriceAvailable = toteSwapOptions.includes('SP')

  // Disable tote field select
  React.useEffect(() => {
    const fieldEl = document.querySelector<HTMLInputElement>(
      '[data-tid-race-starters] .flat-field-section'
    )
    if (!fieldEl) return undefined
    fieldEl.style.display = activeToteSwapOption ? 'none' : 'block'
    return () => {
      if (fieldEl) fieldEl.style.display = 'block'
    }
  }, [activeToteSwapOption])

  // Clear tote selections on active tote swap option
  React.useEffect(() => {
    if (isEnhancedBetslip) return
    if (activeToteSwapOption) amplify.publish('clear-all-selections-command')
  }, [activeToteSwapOption, isEnhancedBetslip])

  return {
    isInsuranceAvailable,
    isStartingPriceAvailable,
    activeToteSwapOption,
  }
}
