import React from 'react'
import dayjs from 'dayjs'
import { Icon } from '@mobi/component-library/Common/Icon'
import { H3 } from '@core/Components/Text/Heading.styles'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import type { BlackbookEntryRacingToday, SmartBetslipState } from '@core/Areas/Blackbook/types'
import { isBettingAvailable } from '@core/Areas/Blackbook/helpers'
import { type BlackbookState, setSortOrderJockeys } from '@core/Areas/Blackbook/Store'
import { JockeyRunningSoonEntries } from './Components/JockeyRunningSoonEntries'
import { sortByStartTime } from '../../helpers'

const sortOptions: BlackbookState['sortOrderJockeys'][] = ['Start Time', 'Alphabetical']

export const JockeysRunningSoon: React.FC<{
  entries: BlackbookEntryRacingToday[]
  smartBetslipState: SmartBetslipState
  incomingIdRef: React.MutableRefObject<string | undefined>
}> = ({ entries, smartBetslipState, incomingIdRef }) => {
  const dispatch = useAppDispatch()
  const sortOrder = useAppSelector(rootState => rootState.blackbook.sortOrderJockeys)

  const entriesGroupedByJockey = sortJockeysAndGroup(entries, sortOrder)

  return (
    <>
      <H3 data-testid='blackbook-list-heading-running-soon'>
        <span>Running Soon</span>
        <div>
          <select
            value={sortOrder}
            onChange={e => {
              dispatch(
                setSortOrderJockeys(e.currentTarget.value as BlackbookState['sortOrderJockeys'])
              )
            }}
            data-testid='blackbook-running-soon-sort'
          >
            {sortOptions.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <span>{sortOrder}</span>
          <Icon type='arrowdown' size='1.2rem' />
        </div>
      </H3>

      {entriesGroupedByJockey.map(({ id, name, runners }) => (
        <JockeyRunningSoonEntries
          key={`${id}.${name}.${runners[0]?.RacingTodayDetails.AdvertisedStartTime}`}
          id={id}
          name={name}
          initialRunners={runners}
          smartBetslipState={smartBetslipState}
          incomingIdRef={incomingIdRef}
        />
      ))}
    </>
  )
}

// =============
// Local Helpers
// =============

function sortJockeysAndGroup(
  entries: BlackbookEntryRacingToday[],
  sortOrder: BlackbookState['sortOrderJockeys']
) {
  const jockeyGroups = entries.reduce(groupByJockeys, [])
  jockeyGroups.forEach(group => group.runners.sort(sortByStartTime))

  if (sortOrder === 'Alphabetical') {
    return jockeyGroups.sort(sortJockeyGroupByAlphabet)
  }

  return jockeyGroups.sort(sortJockeyGroupByStartTime)
}

function groupByJockeys(jockeys: JockeysGrouped, runner: BlackbookEntryRacingToday) {
  const jockeyId = runner.Id
  const index = jockeys.findIndex(jockey => jockey.id === jockeyId)

  if (index === -1) {
    jockeys.push({ id: jockeyId, name: runner.RiderName as string, runners: [runner] })
    return jockeys
  }
  jockeys[index].runners.push(runner)
  return jockeys
}

// Sorting Fns

const sortJockeyGroupByAlphabet: SortFn = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

const sortJockeyGroupByStartTime: SortFn = (a, b) => {
  if (!isBettingAvailable(a.runners[0].RacingTodayDetails)) return 1
  if (!isBettingAvailable(b.runners[0].RacingTodayDetails)) return -1

  if (dayjs(getFirstJockeyStartTime(a)).isBefore(getFirstJockeyStartTime(b))) return -1
  if (dayjs(getFirstJockeyStartTime(b)).isBefore(getFirstJockeyStartTime(a))) return 1
  return 0
}

function getFirstJockeyStartTime(jockeyObj: JockeysGrouped[0]): string {
  return jockeyObj.runners[0].RacingTodayDetails.AdvertisedStartTime
}

// =====
// Types
// =====

type JockeysGrouped = {
  id: BlackbookEntryRacingToday['Id']
  name: string
  runners: BlackbookEntryRacingToday[]
}[]

type SortFn = (a: JockeysGrouped[0], b: JockeysGrouped[0]) => number
