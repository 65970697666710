import React from 'react'
import { BetStatus } from '@core/Areas/AccountActivity/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { hexColors } from '@mobi/settings'

export const BetStatusIcon: React.FC<{ betStatus: BetStatus }> = ({ betStatus }) => {
  switch (betStatus) {
    case 'Won':
      return (
        <div data-testid='status-silk-win'>
          <Icon type='tickCircleSolid' size='2rem' color={hexColors.citrus} />
        </div>
      )
    case 'Lost':
      return (
        <div data-testid='status-silk-lost'>
          <Icon type='crossCircledSolid' size='2rem' color={hexColors.freeSpeechRed} />
        </div>
      )
    case 'Cancelled':
    case 'Pending':
    default:
      return null
  }
}
