import { useSelector } from 'react-redux'
import { PaymentMethod, type InitialData } from '@mobi/api-types'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { type Reason as DepositErrorReason } from '@mobi/account/Areas/Deposit/Components'
import {
  useApplePayAvailabilityInterop,
  useGooglePayAvailability,
  useInitialData,
} from '@mobi/account/Hooks'
import { selectLastUsedPaymentMethod } from '@mobi/account/Store'
import { isApplePayWebSupported } from '@mobi/account/Utils/applePay'
import { tryGetLegacyLastUsedPaymentMethod } from '@mobi/account/Utils/localStorage'

type QuickDepositAvailable = {
  canDeposit: true
  shortfall: number
  lastUsedPaymentMethod: PaymentMethods
  creditCard?: PaymentMethod
  minimumDepositAmount: number
  depositAmount: number
  willDepositMinimumAmount: boolean
  initialData: InitialData
}

export type DepositErrorReasonWithLoading = DepositErrorReason | 'loading'

type QuickDepositUnavailable = {
  canDeposit: false
  reason: DepositErrorReasonWithLoading
  isAccountDepositAvailable: boolean
  initialData?: InitialData
}

type QuickDepositResult = QuickDepositAvailable | QuickDepositUnavailable

// region Validators
type ValidatorParams = {
  initialData: InitialData
  shortfall: number
  depositAmount: number
  lastUsedPaymentMethod: PaymentMethods
}

const makeQuickDepositAvailableResponse = (
  params: ValidatorParams & Pick<QuickDepositAvailable, 'creditCard'>
): QuickDepositAvailable => {
  return {
    canDeposit: true,
    willDepositMinimumAmount: params.shortfall < params.initialData.depositLimits.minimum,
    minimumDepositAmount: params.initialData.depositLimits.minimum,
    ...params,
  }
}

const validateCardDeposit = (params: ValidatorParams): QuickDepositResult => {
  const creditCard = getDefaultCreditCard(params.initialData)

  if (!creditCard || creditCard.isExpired) {
    return {
      canDeposit: false,
      reason: 'no_suitable_card',
      isAccountDepositAvailable: true,
    }
  }

  if (!creditCard.isDebitCard) {
    return {
      canDeposit: false,
      reason: 'selected_card_is_a_credit_card',
      isAccountDepositAvailable: true,
    }
  }

  return makeQuickDepositAvailableResponse({
    ...params,
    creditCard,
  })
}

const validatePayPalDeposit = (params: ValidatorParams): QuickDepositResult =>
  makeQuickDepositAvailableResponse(params)

type GooglePayValidatorParams = ValidatorParams & {
  googlePayAvailability: ReturnType<typeof useGooglePayAvailability>
}

const validateGooglePayDeposit = ({
  googlePayAvailability,
  ...params
}: GooglePayValidatorParams): QuickDepositResult => {
  switch (googlePayAvailability) {
    case 'unknown':
      return {
        canDeposit: false,
        reason: 'loading',
        isAccountDepositAvailable: false,
      }
    case 'unavailable':
      return {
        canDeposit: false,
        reason: 'unable_load_payment_method',
        isAccountDepositAvailable: true,
      }
    case 'available':
      return makeQuickDepositAvailableResponse(params)
  }
}

type ApplePayValidatorParams = ValidatorParams & {
  applePayNativeAvailability: ReturnType<typeof useApplePayAvailabilityInterop>
}

const validateApplePayDeposit = ({
  applePayNativeAvailability,
  ...params
}: ApplePayValidatorParams): QuickDepositResult => {
  if (applePayNativeAvailability === 'unavailable' && !isApplePayWebSupported()) {
    return {
      canDeposit: false,
      reason: 'unsupported_payment_method',
      isAccountDepositAvailable: true,
    }
  }

  return makeQuickDepositAvailableResponse(params)
}
// endregion

export function useQuickDepositValidation(
  accountNumber: number | null,
  betCostShortfall: number
): QuickDepositResult {
  const { data: initialData, isLoading, isError } = useInitialData(accountNumber)
  const applePayNativeAvailability = useApplePayAvailabilityInterop()
  const googlePayAvailability = useGooglePayAvailability(initialData?.clientToken)
  let lastUsedPaymentMethod = useSelector(selectLastUsedPaymentMethod(accountNumber))

  if (isLoading || !accountNumber) {
    return {
      canDeposit: false,
      reason: 'loading',
      isAccountDepositAvailable: false,
    }
  }

  if (isError) {
    return {
      canDeposit: false,
      reason: 'generic_failure',
      isAccountDepositAvailable: true,
    }
  }

  if (betCostShortfall <= 0) {
    return {
      canDeposit: false,
      reason: 'sufficient_balance',
      isAccountDepositAvailable: false,
    }
  }

  if (!initialData?.canDeposit) {
    return {
      canDeposit: false,
      reason: 'cannot_deposit',
      isAccountDepositAvailable: false,
    }
  }

  if (!initialData.isSecurityCompatibleClient) {
    return {
      canDeposit: false,
      reason: 'unsecure_client',
      isAccountDepositAvailable: false,
    }
  }

  const { depositLimits } = initialData

  const depositAmount = Math.max(betCostShortfall, depositLimits.minimum)

  if (depositAmount > depositLimits.maximum) {
    return {
      canDeposit: false,
      reason:
        betCostShortfall > depositLimits.maximum
          ? 'exceeds_deposit_limit'
          : 'minimum_deposit_exceeds_deposit_limit',
      isAccountDepositAvailable: false,
      initialData,
    }
  }

  // TODO: Remove along with tryGetLegacyLastUsedPaymentMethod
  if (!lastUsedPaymentMethod) {
    const [paymentMethod] = tryGetLegacyLastUsedPaymentMethod(accountNumber)
    if (paymentMethod !== 'NoneOrUnknown') {
      lastUsedPaymentMethod = paymentMethod
    }
  }

  if (!lastUsedPaymentMethod) {
    return {
      canDeposit: false,
      reason: 'no_previous_payment_method',
      isAccountDepositAvailable: true,
    }
  }

  const params: ValidatorParams = {
    initialData,
    shortfall: betCostShortfall,
    depositAmount,
    lastUsedPaymentMethod,
  }

  switch (lastUsedPaymentMethod) {
    case 'CreditCard':
      return validateCardDeposit(params)
    case 'PayPal':
      return validatePayPalDeposit(params)
    case 'ApplePay':
      return validateApplePayDeposit({
        ...params,
        applePayNativeAvailability,
      })
    case 'GooglePay':
      return validateGooglePayDeposit({
        ...params,
        googlePayAvailability,
      })
  }
}

function getDefaultCreditCard(initialData: InitialData) {
  const { paymentMethods } = initialData

  if (paymentMethods?.length > 0) {
    return paymentMethods.find(({ isDefault }) => isDefault)
  }
  return undefined
}
