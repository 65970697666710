import React from 'react'
import { useQuery } from 'react-query'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import dayjs from 'dayjs'

import { hexColors } from '@mobi/settings'
import { DateNavigation } from '@core/Components/DateNavigation'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { ResultedPoolsResponse, getResultedPools, queryDefaults } from '../../data/toteSports'

import { LoadingBars } from '../LoadingBars'
import { SingleResultedPool } from '../SingleResultedPool'
import { TippingPoolHeader } from '../TippingPoolHeader'
import { ResultsDateStyled } from '../../TippingPage.styles'
import { RowContainerStyled } from '../../TippingPage.styles'
import { queryKeys } from '@core/Data/ReactQuery/constants'

const SHORT_DATE_FORMAT = 'ddd, DD MMM YYYY'

export const ResultedPoolList = (): JSX.Element => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const selectedDate = new URLSearchParams(useLocation().search).get('date')

  const { isLoading, isError, data } = useQuery<ResultedPoolsResponse>({
    ...queryDefaults,
    queryKey: [queryKeys.toteSportsResultedPools, { date: selectedDate }],
    queryFn: async () => await getResultedPools(selectedDate || dayjs().format('YYYY-MM-DD')),
  })

  if (isLoading) {
    return (
      <>
        <DateDisplay data={data} selectedDate={selectedDate} history={history} />
        <div data-testid='ResultedPoolList.Loading'>
          <LoadingBars />
        </div>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <DateDisplay data={data} selectedDate={selectedDate} history={history} />
        <GridCell padding='1rem'>
          <NoticeBox
            title='Unable to display pools at this time. Please try again later.'
            noticeBoxType={NoticeBoxTypes.Warning}
            hasBorder={true}
          />
        </GridCell>
      </>
    )
  }

  return (
    <div style={{ backgroundColor: hexColors.white }}>
      <DateDisplay data={data} selectedDate={selectedDate} history={history} />

      {!data?.sports?.length && (
        <GridCell padding='1rem'>
          <NoticeBox
            title='There are no pools available for this date.'
            noticeBoxType={NoticeBoxTypes.Info}
            hasBorder={true}
          />
        </GridCell>
      )}

      {data?.sports.map(
        sport =>
          sport.pools?.length > 0 && (
            <div key={sport.key}>
              <TippingPoolHeader
                description={sport.description}
                icon={sport.icon}
                data-tid-tote-sport-header
              />

              <RowContainerStyled>
                <Grid>
                  <GridRow>
                    <GridCell align='left' />
                    <GridCell width='25%' align='left' data-tid-tote-sport-pool-div-official-title>
                      Divs Official at
                    </GridCell>
                    <GridCell
                      width='16.6666%'
                      align='center'
                      data-tid-tote-sport-pool-tips-req-title
                    >
                      Tips Req
                    </GridCell>
                    <GridCell width='8.3333%' />
                    <GridCell
                      width='16.6666%'
                      align='right'
                      data-tid-tote-sport-pool-dividend-title
                    >
                      Dividend
                    </GridCell>
                    <GridCell width='3em' />
                  </GridRow>
                </Grid>
              </RowContainerStyled>

              {sport.pools.map(pool => (
                <Link
                  key={pool.key}
                  style={{ textDecoration: 'none' }}
                  to={`${url}/${sport.icon.toLowerCase()}/${pool.key}`}
                >
                  <SingleResultedPool key={pool.key} pool={pool} />
                </Link>
              ))}
            </div>
          )
      )}
    </div>
  )
}

const DateDisplay = ({
  data,
  selectedDate,
  history,
}: {
  data: ResultedPoolsResponse | undefined
  selectedDate: string | null
  history: ReturnType<typeof useHistory>
}): JSX.Element => (
  <>
    <ResultsDateStyled>
      {data?.startDate && dayjs(data.startDate).format(SHORT_DATE_FORMAT)}&ndash;
      {data?.endDate && dayjs(data.endDate).format(SHORT_DATE_FORMAT)}
    </ResultsDateStyled>

    <DateNavigation
      date={dayjs(selectedDate || '').toDate()}
      onDateChange={date => history.push({ search: `?date=${dayjs(date).format('YYYY-MM-DD')}` })}
    />
  </>
)
