import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'

export const MysteryBonusButtonStyled = styled(BaseButtonStyled)({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  padding: '1rem',
  background: hexColors.orange,
  color: hexColors.black,
})
