import { injectable } from 'inversify'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { Projection } from '../MultiSelectionProjections'
import { IMultiLegSelectionsViewModel } from './IMultiLegSelectionsViewModel'

@injectable()
export class MultiLegSelectionsViewModel implements IMultiLegSelectionsViewModel {
  public bettingContext!: BettingInformation
  public betTypeName!: string
  public selectionStringRaws!: Projection[]

  public init(params: { bettingContext: BettingInformation }): void {
    this.bettingContext = params.bettingContext
    this.betTypeName = this.bettingContext.selectedBetType().name()

    this.selectionStringRaws = []
    for (let legSelection of this.bettingContext.getLegsForProcessing()) {
      let selectionRaw = new Projection(
        'Race ' + legSelection.raceKey().raceNumber(),
        this.bettingContext.resultsForLeg(legSelection.raceKey().leg()).selectionStrings[0]
      )
      //if only one starter selected for a leg than add starter name to selection string
      let selectionNumber = Number(selectionRaw.data)
      if (Number.isInteger(selectionNumber)) {
        let selectedStarters = legSelection.starters()
        selectionRaw.data =
          selectionRaw.data +
          ' - ' +
          (selectedStarters.find(s => s.number() === selectionNumber) as IObservableStarter).name()
      }

      selectionRaw.data = selectionRaw.data.split('.').join('.\u200B') //\u200B is a zero-width space so the string will break over multiple lines

      if (this.bettingContext.selectedBetType().betType() === BetType.AllUp) {
        selectionRaw.data =
          '(' + legSelection.raceKey().poolInfo.selectedPool().name() + ') ' + selectionRaw.data
      }

      this.selectionStringRaws.push(selectionRaw)
    }
  }
}
