import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { GridRowStyled } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { NoticeBox } from '@core/Components/NoticeBox'
import { SpecialIndicator } from '@core/Components/SpecialIndicator'
import { Heading } from '@core/Components/Text/Heading'
import { RaceStatus } from './constants'

export const InfoNoticeBoxStyled = styled(NoticeBox)({
  margin: 0,
  borderBottom: `1px solid ${hexColors.gainsboro}`,
  background: hexColors.white,
})

export const RaceDateHeaderStyled = styled(Heading)(
  {
    margin: 0,
    fontSize: '1.2rem',
    lineHeight: '3.5rem',
    fontWeight: 'normal',
    backgroundColor: hexColors.nightRider,
    color: hexColors.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  ({ level }) => ({
    level: level || 2,
  })
)

export const EventInfoContainerStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: hexColors.nero,
  color: hexColors.white,
})

export const EventInfoIconSectionStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '2em',
  padding: '1em',
})

export const EventInfoIconStyled = styled(Icon)<{
  raceStatus?: RaceStatus
}>(({ raceStatus }) => ({
  backgroundColor:
    raceStatus == RaceStatus.Open
      ? hexColors.sushi
      : raceStatus == RaceStatus.Suspended
        ? hexColors.tangerine
        : raceStatus == RaceStatus.Abandoned
          ? hexColors.lightGrey
          : hexColors.freeSpeechRed,
  padding: '0.1em',
}))

export const EventInfoNumberStyled = styled.div<{
  raceStatus?: RaceStatus
}>(({ raceStatus }) => ({
  backgroundColor:
    raceStatus == RaceStatus.Open
      ? hexColors.sushi
      : raceStatus == RaceStatus.Suspended
        ? hexColors.tangerine
        : raceStatus == RaceStatus.Abandoned
          ? hexColors.lightGrey
          : hexColors.freeSpeechRed,
  width: '1.5em',
  height: '1.5em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '3em',
}))

export const EventInfoTimeStyled = styled.div({
  color: hexColors.white,
  backgroundColor: hexColors.nightRider,
  fontSize: '1em',
  textAlign: 'center',
})

export const EventInfoDescriptionSectionStyled = styled.div({
  color: hexColors.white,
  fontWeight: 'bold',
  fontSize: '1.6em',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '1.2rem 1rem 1.2rem 0.3rem',
})

export const PropositionFooResultsStyled = styled.div({
  display: 'flex',
  flexBasis: '2.5em',
  textAlign: 'center',
  color: hexColors.white,
  margin: 'auto',
  padding: '0rem 0 1.2rem 1em',
})

export const PropositionStartNumberStyled = styled.span({
  fontWeight: 'normal',
})

export const PropositionScatchTimeStyled = styled.span({
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

export const PropositionResultsStyled = styled.div<{
  highlightWinner: boolean
}>(({ highlightWinner }) => ({
  display: 'flex',
  flexBasis: '10em',
  justifyContent: 'flex-end',
  color: highlightWinner ? hexColors.white : hexColors.darkGrey,
}))

export const FavouriteIconWrapper = styled.span({
  position: 'absolute',
  top: '-0.7rem',
  right: '0.2rem',
})

export const PropositionOddsStyled = styled.div({
  position: 'relative',
  flexGrow: 0,
  flexBasis: '5rem',
  minWidth: '5rem',
  paddingRight: '0.1em',
  minHeight: '1.5em',
  textAlign: 'center',
})

export const EventInfoStatusStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  paddingTop: '0.3em',
  fontSize: '0.8em',
})

export const EventInfoStatusTextStyled = styled.div({
  flexGrow: '1',
  color: hexColors.cinnabar,
})

export const EventInfoPayIndicatorTextStyled = styled.span({
  flexGrow: '1',
  color: hexColors.white,
})

export const EventInfoStatusFieldStyled = styled.div({
  flexGrow: '0',
  flexBasis: '3em',
})

export const ImageGridCellStyled = styled(GridCell)({
  backgroundColor: hexColors.whiteSmoke,
  lineHeight: '20px',
  fontWeight: 'bold',
  fontSize: '0.85rem',
})

export const SilkImageStyled = styled.div<{
  offset: number
}>(({ offset }) => ({
  width: '2rem',
  height: '2rem',
  lineHeight: '2rem',
  margin: '1rem',
  display: 'block',
  background:
    '100% no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAAeCAYAAAAFOQOpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQ+SURBVHhe7ZzPaxxlGMfnuGzJkuS62V5UmibZJrvZNcnGUiz+H+lVhepNpSaYxAo9l6oN0YOKtwasB38ctHrxkBSbS/Yw89pSr+8EOxMIDbivzzPZvYTCvAvP+yLD9wsfhr18X4Z9PzwM8zIBgiAIgiBFSC8IFBE7RNEyTOwQ1ev1mNghqnonVRNbJ3H1zpE4p72pSv+pqpPjifjoWVUc7uX+mdWmurTeimfW5sXJeql/bm5ONRqNmK7i9HvVweKfSi124+7CI3GixYO4u7ivWhsd1b55OW5vdOQ57VWTI/eI+/GFkR1xJke+I3YUi56YIDCuoP6UREwI+umMlERMjMNQfzrx6VFy/ktjalsn4nAv9x89m0jMv+fNyXFNHO7l/vpqM5n9uG1ISnG4l/tJxKTZbBqSUhzupf6UhEz+7kQmWjoQ52knNN2F/bS12UkWbl0xr35yWRzuJdFTEjKZqvxoLlbuizNV+cFQf8qiazLlhZJKwFOdRNRnxJSGJ67uO+kkPNVp8mqWkoQUh3u5nyavZilJSHFOe6uaRNQsZX1tXpysl/pJRM1S0lWcfi9PXq2WuoYmrzgsO0/21uayZinbm8viZL002UlEfbHyvZms7IjDsvNkh+iWgeh2QHR7Cif6+Pi4JoxDILoFEN2egejzGx3dvvmaoWd1cbLe9aXiiH54eKgJ44qYAtHzgej2DESfpnupf9QyM6tNcbh3erVRHNF9SAjR84Ho9gxE3328p/eePDS7j+XJev/ag+i2geh2QHR7BqJ72VcjP+ta5VdTq/zkgF8M9UN022R/CETPBaIPF95Xt8uv68/OvWFun7sqTtZbvupHdE2hx2h+lnYC10P0fCC6PT5F75YCHZUD03VA1lsK/Ig+Ojqqx8bGjEMgugUQ3R6fou+WSvpRuWx2HZD1lkp+RA9ojTPvvaWB6BZAdHsg+pBAdDsguj0+Rf/8wbbe+v0Lc/e3bXGy3gfbEH0IILoFEN2egegvvT+tX/mwbl7+YFqcrPe9qeKIPo6TcblAdHt8iu7jCGxhRD/EybhcILo9RRP9D3LwIblCV3H6vX5E9yEhRM8HotvjU3S1sqKfXLtmlAOy3pUViG4biG5H0US/cGNWT601zOSNWXH6vV72LkS3DK8B0fMpmuhvfn1dv/3tu+atb94RJ+v96npxRMfJuHwguj0+Rfexr3ys4UV0nIzLB6LbA9GHC6/hRfSA1jjz3lsaiG4BRLcHog8JRLcDotsD0YcLr8Gi4yuwFqF+f1+B7Tn6Ciz1cj+JWJyvwC4rw7JL83Q5ItH3vewrH2uw6CFPdYdEJGJI8FR3RUQ3ExLaIRFN3LB297nmyStNvzekiRs+P65lk12afm9YX2uGl9bns8kuTdZL/SRiSFJmk12afm9EoofRUlfzZJeGJjpfvewrwvka5AiCIAiCIAiCIP/3BMF/OltdB9Rzg7AAAAAASUVORK5CYII=)',
  border: `1px solid ${hexColors.nero}`,
  backgroundPosition: `${-2 * offset}rem`,
  textAlign: 'center',
  verticalAlign: 'middle',
  padding: 0,
  color: offset == 2 || offset == 4 ? hexColors.nightRider : hexColors.whiteSmoke,
  textShadow:
    offset == 1 || offset == 8 || offset == 9
      ? '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'
      : '',
  boxSizing: 'border-box',
  backgroundSize: '20rem',
}))

export const PropositionGridRowStyled = styled(GridRow)<{
  highlightWinner: boolean
  scratched: boolean
}>(({ highlightWinner, scratched }) => ({
  backgroundColor: highlightWinner ? hexColors.blueDiamond : hexColors.white,
  color: highlightWinner ? hexColors.white : scratched ? hexColors.lightGrey : 'default',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: '1.16em',
  minWidth: '3rem',
}))

export const SeparatorGridRowStyled = styled.div({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: hexColors.gainsboro,
  width: '100%',
})

export const PillStyled = styled.div<{
  color: string
  margin?: string
  toUpper?: boolean
}>(({ color, margin, toUpper = true }) => ({
  backgroundColor: color,
  color: hexColors.white,
  fontSize: '1.2rem',
  borderRadius: '0.25rem',
  display: 'inline-block',
  padding: '0.1rem 0.4rem',
  margin: margin,
  flexGrow: 0,
  flexBasis: '2rem',
  textTransform: toUpper ? 'uppercase' : 'none',
  width: '100%',
}))

export const ExtraInfoHeaderStyled = styled(GridRow)({
  fontWeight: 'bold',
  color: hexColors.whiteSmoke,
  backgroundColor: hexColors.nightRider,
  fontSize: '1.25rem',
  display: 'flex',
  flexDirection: 'row',
  textTransform: 'uppercase',
  textAlign: 'left',
})

export const InfoIconStyled = styled(Icon)({
  backgroundColor: hexColors.infoBlueDark,
  color: hexColors.black,
  fontSize: '1.3rem',
  borderRadius: '0.25rem',
  display: 'inline-block',
  padding: '0.6rem',
  marginTop: '0.6rem',
  marginRight: '0.3rem',
})

export const HeaderSpecialsIconStyled = styled(SpecialIndicator)({
  display: 'inline-block',
  verticalAlign: 'middle',
  paddingBottom: '0.2rem',
})

export const SpecialsIconStyled = styled(SpecialIndicator)({
  display: 'inline-block',
  padding: '0.6rem 0 0.6rem 0.6rem',
  marginRight: '0.3rem',
})

export const ExtraInfoDetailStyled = styled(GridRowStyled)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: hexColors.white,
  fontSize: '1.3rem',
  padding: '0.5rem 0 0.5rem 1rem',
})

export const ButtonWrapStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0.8rem 1rem 0.8rem 0',
})

export const PropositionSummaryResultsStyled = styled.div({
  display: 'flex',
  color: hexColors.dimGrey,
  lineHeight: '1.4rem',
  minHeight: '3rem',
  overflow: 'hidden',
  paddingLeft: '3rem',
  width: '100%',
  textTransform: 'none',
  fontSize: '1.1rem',
})

export const PropositionSummaryStyled = styled.div({
  display: 'flex',
  color: hexColors.dimGrey,
  lineHeight: '1.4rem',
  minHeight: '5rem',
  overflow: 'hidden',
  paddingLeft: '0.6rem',
  width: '100%',
  textTransform: 'none',
  fontSize: '1.1rem',
})

export const SelectionFormTableStyled = styled.div({
  flexGrow: 1,
})

export const Proposition3FieldsStyled = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  textAlign: 'left',
  maxWidth: '17rem',
  marginBottom: '0.25rem',
})

export const PropositionTrotFieldStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
  maxWidth: '10rem',
  marginBottom: '0.25rem',
})

export const PropositionJockeyNameStyled = styled.div({
  marginBottom: '0.25rem',
})

export const PropositionTrainerNameStyled = styled.div({
  marginBottom: '0.25rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  float: 'left',
  marginRight: '1rem',
})

export const PropositionStrongFieldsStyled = styled.strong({
  marginRight: '0.3rem',
})

export const SilkFOODivImageStyled = styled.div({
  display: 'inline-flex',
  justifyContent: 'space-between',
  marginTop: '0.6rem',
})

export const SilkFOODivImageResultsStyled = styled.div({
  display: 'inline-flex',
  justifyContent: 'space-between',
  marginTop: '0.7rem',
  flexDirection: 'column',
  alignItems: 'center',
})

export const SingleSilkDivImageResultsStyled = styled.div({
  marginBottom: '0.75rem',
})
