import { determineUserRegulationId } from '@core/Areas/Kambi/helpers/regulation'
import {
  tryToLoginKambiPlayer,
  tryToLogoutKambiPlayer,
} from '@core/Areas/Kambi/helpers/authentication'
import { createLogger } from '@core/Areas/Kambi/helpers/logging'
import { state$ as kambiState$, BeginKambiLogin, EndKambiLogin } from '@core/Areas/Kambi/driver'
import { ResidentialDetail } from '@core/State/UserAccount/userAccountDriver'
import { List } from 'typings/immutable'

const syncLog = createLogger('orange', 'KAMBI: Sync user login status')

// Handle LOGIN
export async function handleKambiLogin(
  accountNumber: number,
  residentialDetail: List<ResidentialDetail>
): Promise<void> {
  const state = await kambiState$.take(1).toPromise()

  const isKambiLoggedIn = state.get('isLoggedIn')
  if (isKambiLoggedIn) return

  const isKambiLoginPending = state.get('loginPending')
  if (isKambiLoginPending) {
    syncLog('login pending')
    return
  }

  BeginKambiLogin()

  try {
    syncLog('user logged in, trying to log kambi in')

    const regulationId = determineUserRegulationId(residentialDetail)
    await tryToLoginKambiPlayer(accountNumber, regulationId)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('ERROR: Kambi Login', error)
  } finally {
    EndKambiLogin()
  }
}

// Handle LOGOUT
export async function handleKambiLogout(): Promise<void> {
  const kambiState = await kambiState$.take(1).toPromise()
  const isKambiLoggedIn = kambiState.get('isLoggedIn')
  const logoutToken = kambiState.get('logoutToken')

  if (!(isKambiLoggedIn && logoutToken)) return

  try {
    syncLog('user logged out, trying to log kambi out')

    await tryToLogoutKambiPlayer(logoutToken)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('ERROR: Kambi Logout', error)
  }
}
