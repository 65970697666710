import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableFixedOddsRaceInformation {
  constructor() {
    // @ts-expect-error Type issue
    this.bettingConditions = ko.observable()
    // @ts-expect-error Type issue
    this.bettingRules = ko.observable()
    // @ts-expect-error Type issue
    this.bettingTerms = ko.observable()
    // @ts-expect-error Type issue
    this.bettingConditions = ko.observable()
    // @ts-expect-error Type issue
    this.isSuspended = ko.observable()
    // @ts-expect-error Type issue
    this.isClosed = ko.observable()
    // @ts-expect-error Type issue
    this.isResulted = ko.observable()
    // @ts-expect-error Type issue
    this.isPlaceAvailable = ko.observable()
    // @ts-expect-error Type issue
    this.isFixedOddsOnly = ko.observable()
    // @ts-expect-error Type issue
    this.isRaceAvailable = ko.observable()
    // @ts-expect-error Type issue
    this.payoutInformation = ko.observable()
    // @ts-expect-error Type issue
    this.marketSequence = ko.observable()
  }

  public merge(other: ObservableFixedOddsRaceInformation) {
    Guard.notNull(other)

    this.bettingConditions(other.bettingConditions())
    this.bettingRules(other.bettingRules())
    this.bettingTerms(other.bettingTerms())
    this.isSuspended(other.isSuspended())
    this.isClosed(other.isClosed())
    this.isResulted(other.isResulted())
    this.isPlaceAvailable(other.isPlaceAvailable())
    this.isFixedOddsOnly(other.isFixedOddsOnly())
    this.isRaceAvailable(other.isRaceAvailable())
    this.payoutInformation(other.payoutInformation())
    this.marketSequence(other.marketSequence())
  }

  public bettingConditions: ko.Observable<string>
  public bettingRules: ko.Observable<string>
  public bettingTerms: ko.Observable<string>
  public isSuspended: ko.Observable<boolean>
  public isClosed: ko.Observable<boolean>
  public isResulted: ko.Observable<boolean>
  public isPlaceAvailable: ko.Observable<boolean>
  public isFixedOddsOnly: ko.Observable<boolean>
  public isRaceAvailable: ko.Observable<boolean>
  public payoutInformation: ko.Observable<string>
  public marketSequence: ko.Observable<number>
}
