import { getCurrentEnvironment } from '@mobi/utils'
import { initGooglePayWeb } from '../Hooks/GooglePay/initGooglePayWeb'

let googlePaymentClient: google.payments.api.PaymentsClient

export function getGooglePaymentsClient(): Promise<google.payments.api.PaymentsClient> {
  return initGooglePayWeb().then(() => {
    googlePaymentClient ??= new google.payments.api.PaymentsClient({
      environment: getCurrentEnvironment() === 'production' ? 'PRODUCTION' : 'TEST',
    })

    return googlePaymentClient
  })
}

export function getGoogleMerchantId(): string {
  return getCurrentEnvironment() === 'production' ? 'BCR2DN4TUDD2N3BI' : '01234567890123456789'
}

export function isGooglePayAvailable() {
  return window.google?.payments.api !== undefined
}
