import React, { ComponentProps } from 'react'
import { SvgRaceCardIcon } from './Icons.styles'

type Props = ComponentProps<typeof SvgRaceCardIcon>

export const Chat = ({ size, ...other }: Props) => (
  <SvgRaceCardIcon size={size} {...other}>
    <path
      d='M4.6 11.8H11.8V10H4.6V11.8ZM4.6 9.1H15.4V7.3H4.6V9.1ZM4.6 6.4H15.4V4.6H4.6V6.4ZM1 19V2.8C1 2.305 1.1764 1.8811 1.5292 1.5283C1.8814 1.1761 2.305 1 2.8 1H17.2C17.695 1 18.1189 1.1761 18.4717 1.5283C18.8239 1.8811 19 2.305 19 2.8V13.6C19 14.095 18.8239 14.5189 18.4717 14.8717C18.1189 15.2239 17.695 15.4 17.2 15.4H4.6L1 19ZM2.8 14.6575L3.8575 13.6H17.2V2.8H2.8V14.6575Z'
      fill='#512D6D'
    />
  </SvgRaceCardIcon>
)
