import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { radius, colors, spacing, font } from '@mobi/component-library/Theme/Common'
import { AppRoutes } from '@core/AppRoutes'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { Icon } from '@mobi/component-library/Common/Icon'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { RaceCardTicket } from '../RaceCardTicket'

const enum LocalConstants {
  animationTiming = 400,
}

export const RaceTicketsAccordion: React.FC<{
  data: { tickets: Ticket[]; totalTicketsCount: number }
  isLoading: boolean
  isPending: boolean
}> = ({ data, isLoading, isPending }) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle(
    LocalConstants.animationTiming
  )

  const handleToggleClick = () => {
    !isExpanded &&
      trackKey(isPending ? 'race_card_pending_bets_open' : 'race_card_resulted_bets_open')
    toggleExpanded()
  }

  const viewAllLink = `${AppRoutes.AccountActivity[1]}/${isPending ? 'pending' : 'results'}`

  return (
    <WrapperStyled data-js-pending={isPending}>
      <button
        role='button'
        onClick={handleToggleClick}
        disabled={isLoading}
        data-testid='race-card-tickets-toggle'
      >
        <strong>{data.totalTicketsCount}</strong>
        <span>{isPending ? 'Pending' : 'Resulted'} bets on this race</span>
        <Icon size='1.4rem' type={isExpanded ? 'arrowup' : 'arrowdown'} />
      </button>

      {(isExpanded || shouldRender) && (
        <section ref={wrapperRef}>
          <div ref={contentRef}>
            {data.tickets.map(ticket => (
              <RaceCardTicket key={ticket.ticketSequence} ticket={ticket} isPending={isPending} />
            ))}

            <Link
              to={viewAllLink}
              data-testid='race-card-tickets-view-all-bets'
              onClick={() =>
                trackKey(
                  isPending ? 'race_card_pending_bets_view_all' : 'race_card_resulted_bets_view_all'
                )
              }
              style={{ color: colors.neutral[900] }}
            >
              {viewAllBetsLinkText(data.tickets.length, data.totalTicketsCount, isPending)}
              <Icon size='1rem' type='arrowright' />
            </Link>
          </div>
        </section>
      )}
    </WrapperStyled>
  )
}

function viewAllBetsLinkText(ticketCount: number, totalTicketCount: number, isPending: boolean) {
  const type = isPending ? 'pending' : 'resulted'
  if (totalTicketCount - ticketCount > 1) {
    return `View (${totalTicketCount - ticketCount}) other ${type} bets for this race`
  } else if (totalTicketCount - ticketCount === 1) {
    return `View (1) other ${type} bet for this race`
  } else {
    return `View all ${type} bets`
  }
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'relative',
  padding: 0,
  background: colors.white,
  fontFamily: font.family.primary,

  '&[data-js-pending="true"]': {
    paddingBottom: 0,
  },

  '& > button': {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '4rem',
    padding: '0 1.6rem',
    border: 0,

    fontWeight: font.weight.medium,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,

    color: colors.studio[600],
    backgroundColor: colors.lavender[50],

    // Number badge
    strong: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.2rem',
      height: '2.2rem',
      marginRight: '0.8rem',
      borderRadius: '50%',

      fontSize: font.size.sm.fontSize,
      lineHeight: font.size.sm.lineHeight,
      fontWeight: font.weight.bold,

      color: colors.white,
      background: colors.studio[600],
    },

    svg: {
      marginLeft: 'auto',
    },
  },

  '& > section': {
    position: 'relative',
    overflow: 'hidden',
    height: 0,
    transition: 'height ' + LocalConstants.animationTiming + 'ms ease',
    willChange: 'height',
    borderBottom: '0.1rem solid ' + colors.neutral[300],

    '& > div': {
      overflow: 'hidden',
      background: colors.white,

      '& > a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: spacing.smx1 + ' ' + spacing.sm,
        margin: spacing.smx1,
        border: 0,
        borderRadius: radius.lg,

        fontWeight: font.weight.medium,
        fontSize: font.size.lg.fontSize,
        lineHeight: font.size.lg.lineHeight,
        letterSpacing: font.size.lg.letterSpacing,
        textDecoration: 'none',

        color: colors.neutral[900],
        background: colors.surface[50],

        svg: { marginLeft: '0.5rem' },
      },
    },
  },
})
