import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '../../Settings'
import {
  ReverificationAlertStyled,
  ReverificationAlertIconStyled,
  ReverificationAlertMessageStyled,
} from './Alert.styles'

import { observeImmutable } from '../../Components/HOCs'
import { state$ as reverificationState$, ShowReverificationPopup } from './driver'
import { store } from '@core/Store'
import { toggleIsDropDownOpen } from '../AppHeader/Store'

interface ReverificationAlertComponentProps {
  requiresReverification: boolean
}

export const ReverificationAlert = observeImmutable(
  reverificationState$,
  ({ record: { requiresReverification } }) => (
    <ReverificationAlertComponent requiresReverification={requiresReverification} />
  )
)

const handleClick = () => {
  store.dispatch(toggleIsDropDownOpen(false))
  ShowReverificationPopup()
}

export function ReverificationAlertComponent({
  requiresReverification,
}: ReverificationAlertComponentProps): JSX.Element | null {
  if (!requiresReverification) {
    return null
  }

  return (
    <ReverificationAlertStyled onClick={handleClick}>
      <Grid>
        <ReverificationAlertIconStyled>
          <Icon type='infoCircled' size='3.2rem' color={colors.messages.warning.text} />
        </ReverificationAlertIconStyled>
        <GridCell valign='middle'>
          <ReverificationAlertMessageStyled>
            Re-verify your account now
          </ReverificationAlertMessageStyled>
        </GridCell>
      </Grid>
    </ReverificationAlertStyled>
  )
}
