import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const appHeaderInitialState: AppHeaderState = {
  isStickyEnabled: true,
  isAppHeaderVisible: true,
  isBalanceVisible: true,
  isDropDownOpen: false,
  isHamburgerMenuOpen: false,
  shouldDisableAppHeaderRender: false,

  isBetSlipNewOpen: false,
}

const appHeaderSlice = createSlice({
  name: 'app-header',
  initialState: appHeaderInitialState,
  reducers: {
    toggleIsAppHeaderSticky(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isStickyEnabled = payload ?? !state.isStickyEnabled
    },
    toggleIsAppHeaderVisible(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isAppHeaderVisible = payload ?? !state.isAppHeaderVisible
    },
    toggleIsBalanceVisible(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isBalanceVisible = payload ?? !state.isBalanceVisible
    },
    toggleIsDropDownOpen(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isDropDownOpen = payload ?? !state.isDropDownOpen
    },
    toggleIsHamburgerMenuOpen(state, { payload }: PayloadAction<boolean | undefined>) {
      state.isHamburgerMenuOpen = payload ?? !state.isHamburgerMenuOpen
    },
    setDisableAppHeaderRender(state, { payload }: PayloadAction<boolean>) {
      state.shouldDisableAppHeaderRender = payload
    },
    setBetSlipNewIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isBetSlipNewOpen = payload
    },
  },
})

export const {
  toggleIsAppHeaderSticky,
  toggleIsAppHeaderVisible,
  toggleIsBalanceVisible,
  toggleIsDropDownOpen,
  toggleIsHamburgerMenuOpen,
  setDisableAppHeaderRender,

  setBetSlipNewIsOpen,
} = appHeaderSlice.actions

export default persistReducer(
  {
    key: 'app-header',
    whitelist: ['isBalanceVisible'] as Array<keyof AppHeaderState>,
    storage,
    debug: !PRODUCTION,
  },
  appHeaderSlice.reducer
)

// =====
// Types
// =====

export interface AppHeaderState {
  isDropDownOpen: boolean
  isAppHeaderVisible: boolean
  isBalanceVisible: boolean
  isHamburgerMenuOpen: boolean
  isStickyEnabled: boolean
  shouldDisableAppHeaderRender: boolean

  isBetSlipNewOpen: boolean
}
