import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import TrifectaValidator from '@classic/Betting-v2/Validation/TrifectaValidator'
import CheckBoxSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/CheckBoxSelectionResultProcessor'
import type { IConfigureBetType } from './IConfigureBetType'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class TrifectaConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.Trifecta,
      name: 'Trifecta',
      supportsFlexi: true,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'trifecta-template',
      betSelectionTemplate: 'tri-checkbox-selection-template',
      multiBet: false,
      multiSelections: true,
      checkBoxCount: 3,
      fieldCheckBoxCount: 3,
      sameAsCheckBoxCount: 2,
      processor: new CheckboxSelectionProcessor(3),
      validator: new TrifectaValidator(),
      selectionResultProcessor: new CheckBoxSelectionResultProcessor(3),
      legs: 1,
    })
  }
}
