import styled from '@emotion/styled'
import { hideVisually } from 'polished'
import { colors } from '@core/Settings'
import { MoneyGrouped, MoneyGroupedProps } from '@core/Components/Text/MoneyGrouped'
import { PriceChange } from '@core/Components/Text/utils'

export const LegInfoWrapperStyled = styled('dl')({
  color: colors.quickbet.investment.legInfo.text,
  margin: 0,
  fontSize: '1.2rem',
})

export const LegInfoPriceTextStyled = styled('span')({
  ...hideVisually(),
})

interface SingleLegTypeProps {
  isActive: boolean
}

export const SingleLegTypeStyled = styled('dt')<SingleLegTypeProps>(
  {
    lineHeight: '1.7rem',
  },

  ({ isActive }) => ({
    color: isActive ? colors.quickbet.investment.active.text : colors.quickbet.investment.text,
  })
)

function determinePriceChangeColor({
  isActive,
  shouldHighlight,
  priceChange,
}: {
  isActive?: boolean
  shouldHighlight?: boolean
  priceChange: PriceChange
}) {
  // !active + !boosted + pricechanged / priceunchanged = white
  if (
    !isActive &&
    !shouldHighlight &&
    (priceChange === PriceChange.None ||
      priceChange === PriceChange.Increase ||
      priceChange === PriceChange.Decrease)
  ) {
    return { color: colors.quickbet.investment.text }
  }

  // active + !boosted + priceunchanged = blueDiamond
  if (isActive && !shouldHighlight && priceChange === PriceChange.None) {
    return { color: colors.quickbet.investment.active.text }
  }

  // active + !boosted + pricechanged = red
  if (isActive && !shouldHighlight && priceChange === PriceChange.Decrease) {
    return { color: colors.priceChange.decrease.text }
  }

  // active + !boosted + pricechanged = green
  if (isActive && !shouldHighlight && priceChange === PriceChange.Increase) {
    return { color: colors.priceChange.increase.text }
  }

  // active / notactive + boosted + pricechanged / priceunchanged = black
  if (
    (isActive || !isActive) &&
    shouldHighlight &&
    (priceChange === PriceChange.None ||
      priceChange === PriceChange.Increase ||
      priceChange === PriceChange.Decrease)
  ) {
    return { color: colors.quickbet.investment.legInfo.boosted.text }
  }

  // default = blueDiamond
  return { color: colors.quickbet.investment.active.text }
}

interface SingleLegPriceProps {
  isActive?: boolean
  priceChange: PriceChange
  shouldHighlight?: boolean
}

export const SingleLegMoneyGroupedStyled = styled(MoneyGrouped)<SingleLegMoneyGroupedProps>(
  ({ isActive, shouldHighlight, priceChange }) =>
    determinePriceChangeColor({ isActive, shouldHighlight, priceChange })
)

export const EachWayTypeStyled = styled(SingleLegTypeStyled)({
  clear: 'both',
  float: 'left',
  lineHeight: '1.7rem',
  marginTop: 0,
  width: '4rem',
})

export const EachWayPriceStyled = styled('dd')({
  lineHeight: '1.7rem',
  float: 'left',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  margin: 0,
  position: 'relative',
})

export const SingleLegPriceStyled = styled('dd')<SingleLegPriceProps>(
  ({ isActive, shouldHighlight, priceChange }) =>
    determinePriceChangeColor({ isActive, shouldHighlight, priceChange }),
  {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.7rem',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    margin: 0,
    position: 'relative',
  },
  ({ priceChange }) => {
    if (priceChange === PriceChange.Increase || priceChange === PriceChange.Decrease) {
      return {
        '&::after': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '0.4rem solid transparent',
          borderRight: '0.4rem solid transparent',
          marginLeft: '0.4rem',
          position: 'relative',
          // border color is inherited from text color
          borderBottomStyle: 'solid',
          borderBottomWidth: priceChange === PriceChange.Increase ? '0.6rem' : 0,
          borderTopStyle: 'solid',
          borderTopWidth: priceChange === PriceChange.Decrease ? '0.6rem' : 0,
        },
      }
    }
    return {}
  },
  ({ shouldHighlight }) => ({
    '&::before': {
      backgroundColor: shouldHighlight
        ? colors.quickbet.investment.legInfo.boosted.background
        : colors.quickbet.investment.legInfo.background,
      borderRadius: '0.5rem',
      content: '" "',
      height: '100%',
      top: '50%',
      left: '-0.2rem',
      right: '-0.2rem',
      padding: '0.1rem 0.2rem',
      position: 'absolute',
      transform: 'translateY(-50%)',
      zIndex: 0,
    },
  })
)

interface SingleLegMoneyGroupedProps extends MoneyGroupedProps {
  isActive: boolean
  priceChange: PriceChange
  shouldHighlight?: boolean
}

export const LegInfoPriceFinalDigitStyled = styled('div')({
  display: 'flex',
  position: 'relative',
})

export const LegInfoPriceFinalDigitWrapStyled = styled('div')({
  display: 'flex',
  overflow: 'hidden',
})

interface LegInfoPriceFinalDigitInnerProps {
  isDecimal?: boolean
  isArrow?: boolean
}

export const LegInfoPriceFinalDigitInnerStyled = styled('div')<LegInfoPriceFinalDigitInnerProps>(
  {
    display: 'flex',
    height: '1.7rem',
    position: 'relative',
    overflow: 'hidden',
  },
  ({ isDecimal, isArrow }) => ({
    width: isDecimal ? '0.4rem' : isArrow ? 'auto' : '0.8rem',
  })
)

export const LegInfoPriceFinalDigitInnerDigitStyled = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  position: 'relative',
  transform: 'translateY(-100%) translateY(1.7rem)',
  transition: 'none',
})
interface LegInfoPriceChangeProps {
  shouldDisplay: boolean
}

export const LegInfoPriceChangeStyled = styled('div')<LegInfoPriceChangeProps>(
  {
    position: 'absolute',
    background: colors.quickbet.investment.legInfo.boosted.background,
    borderRadius: '0.5rem',
    top: 0,
    bottom: 0,
  },
  ({ shouldDisplay }) => {
    return {
      visibility: shouldDisplay ? 'visible' : 'hidden',
    }
  }
)

export const LegInfoPriceScreenReaderFriendlyStyled = styled('span')({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
  wordWrap: 'normal',
})
