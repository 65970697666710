/**
 * Join all items in an array using a string. The final item can be joined using a separate string.
 *
 * Inspired by Laravel's Arr::join method
 *
 * @link https://github.com/laravel/framework/blob/7d221aa744845861657d0196e197957cb5a447ad/src/Illuminate/Collections/Arr.php#L437
 */
export function join(array: unknown[], glue: string, finalGlue?: string) {
  if (!finalGlue) {
    return array.join(glue)
  }

  if (array.length === 0) {
    return ''
  }

  if (array.length === 1) {
    return `${array[0]}`
  }

  return `${array.slice(0, -1).join(glue)}${finalGlue}${array[array.length - 1]}`
}
