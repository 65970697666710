import type { Contract } from '@mobi/web-native-comms'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { isActive } from '@core/State/LaunchDarklyFeatures/helpers/isActive'
import { isHapticFeedbackSettingEnabled } from '@core/Areas/Settings/Store/selectors'
import { store } from '@core/Store'

export const triggerHapticFeedback = async (feedbackType: Contract['START_HAPTIC_FEEDBACK']) => {
  const isFeatureActive = await isActive('haptic-feedback')
  const isHapticEnabled = isHapticFeedbackSettingEnabled(store.getState())

  if (!isFeatureActive || !isHapticEnabled) return

  // Trigger haptic feedback
  if (isReactNativeApp) {
    sendToNative('START_HAPTIC_FEEDBACK', feedbackType)
  } else {
    window.navigator.vibrate?.(feedbackType == 'success' ? 50 : 25)
  }
}
