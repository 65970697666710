import React from 'react'
import { SkyTvBadge } from '@mobi/component-library/Racing/SkyTvIndicator'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { ListItem } from '@core/Components/List'
import { Icon } from '@mobi/component-library/Common/Icon'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { colors } from '@core/Settings'
import { useFeature } from '@core/Utils/hooks'
import { NextRace as NextRaceData } from '@core/Data/Racing/nextRaces'
import { isThoroughbredNextRaceInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import { getNextRaceTimeDisplayOptions, buildToteRaceUri } from '@core/Areas/Racing/helpers'
import { TimeToJumpComponent } from '../TimeToJump/TimeToJump'
import {
  NextEventMeetingNameStyled,
  NextEventRaceTextStyled,
  NextEventRaceDistanceStyled,
} from '../../NextRaces.styles'

export const IconTypes: Record<string, keyof typeof iconPaths> = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export function NextRace({ race, keyValue }: { race: NextRaceData; keyValue: string }) {
  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')

  const startDetails = getNextRaceTimeDisplayOptions(race.AdvertisedStartTime)

  const href = buildToteRaceUri({
    meetingId: race.MeetingID,
    raceNumber: race.RaceNumber,
    meetingDate: race.MeetingDate,
  })

  const shouldDisplayRacingWA = isRacingWAFeatureActive && isThoroughbredNextRaceInWA(race)

  return (
    <ListItem data-tid-next-event-race={keyValue} href={href} hideChevron={true}>
      <Grid padding='0.5rem'>
        <GridCell width='5em' valign='middle' align='left'>
          <TimeToJumpComponent
            advertisedStartTime={startDetails.advertisedStartTime}
            showCountDown={startDetails.showCountDown}
            showDay={startDetails.showDay}
          />
        </GridCell>
        <GridCell width='3em' valign='middle' align='center'>
          <Icon size='2.8rem' type={IconTypes[race.RaceType as keyof typeof IconTypes]} />
        </GridCell>
        <GridCell>
          <NextEventMeetingNameStyled>{race.MeetingName}</NextEventMeetingNameStyled>
          <NextEventRaceTextStyled>
            R{race.RaceNumber}{' '}
            <NextEventRaceDistanceStyled>{race.Distance}m</NextEventRaceDistanceStyled>
          </NextEventRaceTextStyled>
        </GridCell>
        <GridCell width='3em' align='left' valign='middle'>
          {race.HasJackpot && <Icon type='jackpot' color={colors.nextEvents.jackpot.text} />}
        </GridCell>

        {race.SkyTvChannelId != null && (
          <GridCell width='5em' align='left' valign='middle'>
            <SkyTvBadge
              channel={getVisionChannelForDisplay(race.SkyTvChannelId, shouldDisplayRacingWA)}
              size='M'
            />
          </GridCell>
        )}
      </Grid>
    </ListItem>
  )
}
