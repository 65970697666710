import React from 'react'
import { BACK_BUTTON_ID } from '@core/Areas/AppHeader/Components/Buttons/Buttons'
import { state$ as routeState$ } from '@core/State/Navigation/driver'

export const useBackNav = (): void => {
  React.useEffect(() => {
    const backButtonEl = document.getElementById(BACK_BUTTON_ID)

    const handleBackButtonClick = (e: MouseEvent) => {
      e.preventDefault()
      routeState$.take(1).subscribe(routeState => {
        window.location.href = `/${routeState.get('backUrl')}`
      })
    }
    backButtonEl?.addEventListener('click', handleBackButtonClick)

    return () => {
      backButtonEl?.removeEventListener('click', handleBackButtonClick)
    }
  }, [])
}
