import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { GridRowStyled } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'

export const NothingToShowStyled = styled.div({
  fontSize: '1.2rem',
  fontStyle: 'italic',
  padding: '1em',
  color: hexColors.grey,
  textAlign: 'center',
})

export const BetOfferContainerStyled = styled.div({
  backgroundColor: hexColors.white,
  border: `0.1rem solid ${hexColors.veryLightGrey}`,
  boxShadow: '0 0.1rem 0.1rem rgb(0 0 0 / 10%)',
  borderRadius: '0.25em',
  margin: '1.3em 1em 1.3em 1em',
})

export const BonusHeaderRowStyled = styled(GridRowStyled)({
  fontSize: '1.3em',
  fontWeight: 'bold',
  color: hexColors.blueDiamond,
  backgroundColor: hexColors.orange,
  marginTop: '0',
  verticalAlign: 'middle',
  padding: '1em 0 1em 0',
})

export const BonusCellStyled = styled(GridCell)({
  margin: '0',
})

export const BonusIconStyled = styled(Icon)({
  padding: '0 0.3em 0 0.5em',
})

export const BonusDetailRowStyled = styled(GridRowStyled)({
  fontSize: '1.2em',
  backgroundColor: hexColors.white,
  padding: '1em 0 0 0.85em',
})

export const BonusAmountsRowStyled = styled(GridRowStyled)({
  fontSize: '1.1em',
  backgroundColor: hexColors.white,
  padding: '1em 1em 0 1em',
})

export const BonusAmountsHeadingStyled = styled.div({
  fontSize: '1em',
  color: hexColors.grey,
})

export const BonusAmountsValueStyled = styled.div({
  fontSize: '1.2em',
  fontWeight: 'bold',
})

export const BonusFineprintRowStyled = styled(GridRowStyled)({
  backgroundColor: hexColors.white,
  padding: '1em 1em 0 1em',
  marginBottom: '0.2em',
})

export const BonusButtonRowStyled = styled(GridRowStyled)({
  fontSize: '1.1em',
  backgroundColor: hexColors.white,
  margin: '0 0.2em 0.2em 0.2em',
})

export const BonusButtonCellStyled = styled(GridCell)({
  width: '100%',
  padding: '0.3em',
})

export const FaqContainerStyled = styled.div({
  backgroundColor: hexColors.white,
  border: `0.1rem solid ${hexColors.veryLightGrey}`,
  boxShadow: '0 0.1rem 0.1rem rgb(0 0 0 / 10%)',
  borderRadius: '0.25em',
  margin: '1.3em 1em 1.3em 1em',
})

export const FaqLinkStyled = styled.div({
  margin: '1em',
})
