import styled from '@emotion/styled'
import { newTheme as theme } from '../../../Theme'

type Props = {
  invalid?: boolean
  disabled?: boolean
}

function determineColors(isDisabled: boolean, isInvalid: boolean) {
  const { colors } = theme.components.Input

  if (isDisabled) {
    return {
      background: colors.disabled?.background ?? colors.background,
      border: colors.disabled?.border ?? colors.border,
      helpText: colors.disabled?.helpText ?? colors.helpText,
    }
  }

  if (isInvalid) {
    return {
      background: colors.invalid?.background ?? colors.background,
      border: colors.invalid?.border ?? colors.border,
      helpText: colors.invalid?.helpText ?? colors.helpText,
    }
  }

  return {
    background: colors.background,
    border: colors.border,
    helpText: colors.helpText,
  }
}

export const InputFieldStyled = styled.div<Props>(({ invalid = false, disabled = false }) => {
  const { border, colors, spacing, shadow } = theme.components.Input

  const effectiveColors = determineColors(disabled, invalid)

  return {
    color: disabled ? colors.disabled?.text : colors.text,
    fontFamily: theme.font.family.primary,
    ...theme.font.size.xl2,
    '& > label': {
      display: 'block',
      fontWeight: theme.font.weight.medium,
      marginBottom: spacing.label.margin.bottom,
    },

    '& > div': {
      alignItems: 'center',
      backgroundColor: effectiveColors.background,
      border: `1px solid ${effectiveColors.border}`,
      borderRadius: border.radius,
      boxShadow: shadow,
      display: 'flex',
      height: '4.4rem',
      padding: `0 ${spacing.input.padding.x}`,
      outlineColor: 'transparent',
      outlineStyle: 'solid',
      outlineWidth: '4px',

      '&:focus-within': {
        borderColor: colors.focused?.border,
        outlineColor: colors.focused?.outline,
      },

      '& > input': {
        width: '100%',
        background: 'none',
        border: 'none',
        caretColor: colors.caret,
        color: colors.text,
        flex: '1 1 auto',
        font: 'inherit',
        fontSize: 'inherit',
        fontWeight: theme.font.weight.regular,
        height: '100%',
        letterSpacing: 'inherit',
        lineHeight: 'inherit',
        padding: 0,

        '&:not(:first-child)': {
          marginInlineStart: spacing.input.margin.left,

          '@supports not (margin-inline-start: 0)': {
            marginLeft: spacing.input.margin.left,
          },
        },

        '&:not(:last-child)': {
          marginInlineEnd: spacing.input.margin.right,

          '@supports not (margin-inline-end: 0)': {
            marginRight: spacing.input.margin.right,
          },
        },

        '&::placeholder': {
          color: colors.placeholder,
        },

        '&:disabled::placeholder': {
          color: colors.disabled?.placeholder,
        },

        '&:focus': {
          outline: 'none',
        },
      },
    },

    '& > span': {
      color: effectiveColors.helpText,
      display: 'block',
      marginTop: spacing.helpText.margin.top,
      ...theme.font.size.xl,

      '&:empty': {
        display: 'none',
      },
    },
  }
})
