import React from 'react'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import { roundEstimateDown } from '@mobi/utils/money'

export const Cost: React.FC<Pick<Ticket, 'investment'>> = props => {
  if (props.investment.lines.length == 1 && !props.investment.lines[0].count) return null

  if (props.investment.lines.length == 0)
    return (
      <div>
        <div data-testid='ticket-detail-total-cost'>
          <div>Cost</div>
          <div>
            <Money amount={props.investment.total} />
          </div>
        </div>
      </div>
    )

  return (
    <div>
      <table>
        <thead>
          <tr data-testid='ticket-detail-total-cost-header'>
            <th></th>
            <th className='text-right'>
              {props.investment.lines[0].projectedPay === null ? 'Bet Amount' : 'Proj. Pay'}
            </th>
            <th className='text-right'>Cost</th>
          </tr>
        </thead>

        <tbody>
          {props.investment.lines.map((investmentLine, index) => {
            return (
              <tr key={index}>
                <td className='text-left' data-testid='betcost-displayname'>
                  {investmentLine.displayName}
                  {investmentLine.count > 1 ? ` x ${investmentLine.count}` : ''}
                </td>

                <td className='text-right' data-testid='betcost-projectedpay'>
                  <Money
                    amount={
                      roundEstimateDown(investmentLine.projectedPay, 2) || investmentLine.betValue
                    }
                  />
                </td>

                <td data-testid='betcost-totalcost' className='text-right'>
                  <Money amount={investmentLine.totalValue} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
