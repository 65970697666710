import { sendToNative } from '@mobi/web-native-comms/web'
import React from 'react'

export const useDisableAndroidBackGesture = () => {
  React.useEffect(() => {
    sendToNative('DISABLE_ANDROID_BACK_GESTURE', true)
    return () => {
      sendToNative('DISABLE_ANDROID_BACK_GESTURE', false)
    }
  }, [])
}
