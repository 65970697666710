import { dayjs } from '@mobi/utils/date'
import Guard from '@classic/AppUtils/Framework/Guard'

export class ScratchedViewModel {
  public scratchType: ko.Observable<string>
  public scratchedTime: ko.Observable<string | null>

  constructor(params: {
    scratchType: ko.Observable<string>
    scratchedTime: ko.Observable<string | null>
  }) {
    Guard.notNull(params.scratchType)
    Guard.notNull(params.scratchedTime)
    Guard.stringNotNullOrEmpty(params.scratchType())

    this.scratchType = params.scratchType
    this.scratchedTime = params.scratchedTime
  }

  public isLate() {
    return this.scratchType() === 'Late'
  }

  public scratchedTimeDisplay() {
    return this.scratchedTime() ? dayjs.tz(this.scratchedTime()).local().format('DD/MM, h:mma') : ''
  }
}
