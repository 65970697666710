import React from 'react'
import { List, ListItem } from '@core/Components/List'
import { isLoading, isErrored } from 'rwwa-data-access'
import { ErrorMessage } from '@core/Components/Messages'
import { NextRacesRepoData } from '@core/Data/Racing/nextRaces'
import { NextRaceLoading } from '@core/Areas/NextEvents/Components/NextRaceLoading/NextRaceLoading'
import { FeaturedItemsListStyled } from '../FeaturedItems/FeaturedItems.styles'
import { HomeNextSuperPicksTile } from './HomeNextSuperPicksTile'

export function HomeNextSuperPicksList({ races }: { races: NextRacesRepoData }): JSX.Element {
  if (isErrored(races)) {
    return <ErrorMessage>Error loading next races.</ErrorMessage>
  }

  if (isLoading(races)) {
    return (
      <List>
        <ListItem>
          <NextRaceLoading />
        </ListItem>
      </List>
    )
  }

  if (!races.length) {
    return <></>
  }

  const raceListItems = races.map(race => {
    const keyValue = `${race.MeetingDate}_${race.MeetingName}_${race.RaceNumber}`
    return <HomeNextSuperPicksTile key={keyValue} keyValue={keyValue} race={race} />
  })

  return (
    <FeaturedItemsListStyled count={raceListItems.length}>{raceListItems}</FeaturedItemsListStyled>
  )
}
