export const CountryIsoCode = {
  Australia: 'AU',
  NewZealand: 'NZ',
  HongKong: 'HK',
  Singapore: 'SG',
  Japan: 'JP',
  SouthKorea: 'KR',
  SouthAfrica: 'ZA',
  UnitedArabEmirates: 'AE',
  UnitedKingdom: 'GB',
  Ireland: 'IE',
  France: 'FR',
  Germany: 'DE',
  Sweden: 'SE',
  Norway: 'NO',
  UnitedStates: 'US',
  Argentina: 'AR',
  Brazil: 'BR',
  Chile: 'CL',
  Uruguay: 'UY',
}

export const CountryIsoCodeOrder: string[] = [
  CountryIsoCode.Australia,
  CountryIsoCode.NewZealand,
  CountryIsoCode.HongKong,
  CountryIsoCode.Singapore,
  CountryIsoCode.Japan,
  CountryIsoCode.SouthKorea,
  CountryIsoCode.SouthAfrica,
  CountryIsoCode.UnitedArabEmirates,
  CountryIsoCode.UnitedKingdom,
  CountryIsoCode.Ireland,
  CountryIsoCode.France,
  CountryIsoCode.Germany,
  CountryIsoCode.Sweden,
  CountryIsoCode.Norway,
  CountryIsoCode.UnitedStates,
  CountryIsoCode.Argentina,
  CountryIsoCode.Brazil,
  CountryIsoCode.Chile,
  CountryIsoCode.Uruguay,
]

export const StateCodes = {
  AustralianCapitalTerritory: 'ACT',
  NewSouthWales: 'NSW',
  NorthernTerritory: 'NT',
  Queensland: 'QLD',
  SouthAustralia: 'SA',
  Tasmania: 'TAS',
  Victoria: 'VIC',
  WesternAustralia: 'WA',
}

export const StateOrder: string[] = [
  StateCodes.WesternAustralia,
  StateCodes.Victoria,
  StateCodes.NewSouthWales,
  StateCodes.Queensland,
  StateCodes.SouthAustralia,
  StateCodes.Tasmania,
  StateCodes.AustralianCapitalTerritory,
  StateCodes.NorthernTerritory,
]

export const RaceCourseClassCode = {
  Metro: 'M',
  Provincial: 'P',
  Country: 'C',
  Other: 'O',
}

export const RaceCourseClassCodeOrder: string[] = [
  RaceCourseClassCode.Metro,
  RaceCourseClassCode.Provincial,
  RaceCourseClassCode.Country,
  RaceCourseClassCode.Other,
]
