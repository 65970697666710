import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

interface InputBoxStyledProps {
  error?: string | boolean
}

export const TextAreaStyled = styled('textarea')<InputBoxStyledProps>(
  {
    backgroundColor: hexColors.gainsboro,
    border: `1px solid ${hexColors.veryLightGrey}`,
    borderRadius: 0,
    appearance: 'none',
    boxSizing: 'border-box',
    resize: 'vertical',
    fontSize: '1.4rem',
    margin: '0.5rem 0 0.6rem 0',
    padding: '1rem',
    width: '100%',

    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },

  ({ error }) => ({
    outline: error ? '0.1rem solid red' : undefined,
  })
)
