import { placeKambiBetSingle } from '@core/Areas/Kambi/helpers/kambiBettingApi'

import {
  KambiPlaceBetFailedResponse,
  KambiPlaceBetSuccessResponse,
  KambiPlaceSingleBetRequest,
} from '@core/Areas/Kambi/helpers/types'

import { FobCommitResponse, FobSingleErrorResponse, FobSingleSportsRequest } from './betting'

enum BetResponseCode {
  UnknownError,
  Unauthorized,
  InsufficientFunds,
  PriceChanged,
  HandicapChanged,
  NetworkError,
}

const BET_RESPONSE_UNKNOWN_ERROR = {
  code: BetResponseCode.UnknownError,
  response: {
    message: 'Unknown Error',
  },
} as Readonly<FobSingleErrorResponse>

export function placeKambiBet(request: FobSingleSportsRequest): Promise<FobCommitResponse> {
  const kambiRequest = kambiBetRequestFromRequest(request)

  return placeKambiBetSingle(kambiRequest).then(response => {
    if (response.status === 'SUCCESS') {
      return betResponseFromKambiResponse(response)
    } else if (typeof response.status === 'number') {
      if (response.status === 401) {
        throw {
          code: BetResponseCode.Unauthorized,
          response: {
            message: 'Unauthorized',
          },
        }
      } else {
        throw BET_RESPONSE_UNKNOWN_ERROR
      }
    } else {
      throw betErrorFromKambiResponse(request, response)
    }
  })
}

function kambiBetRequestFromRequest(request: FobSingleSportsRequest): KambiPlaceSingleBetRequest {
  if (!request.externalBetId.startsWith('KAMBI_')) {
    throw new Error('externalBetId does not contain Kambi outcomeId')
  }

  if (request.investmentPlace || request.pricePlace) {
    throw new Error('Unexpected investmentPlace in Sports Single')
  }

  const outcomeId = parseInt(request.externalBetId.split('_')[1], 10)
  const odds = floatToKambiCurrency(request.priceWin ?? 0.0)
  const stake = floatToKambiCurrency(request.investmentWin ?? 0.0)

  return {
    couponRows: [
      {
        index: 0,
        odds: odds,
        outcomeId,
        type: 'SIMPLE',
      },
    ],
    allowOddsChange: 'NO',
    bets: [
      {
        couponRowIndexes: [0],
        eachWay: false,
        stake,
      },
    ],
    requestId: generateGUID(),
    channel: 'MOBILE',
  }
}

function betResponseFromKambiResponse(response: KambiPlaceBetSuccessResponse): FobCommitResponse {
  const bet = response.coupon.bets[0]

  return {
    ticketNumber: response.couponRef,
    betPlacedTime: new Date(response.coupon.placedDate),
    winInvestment: kambiCurrencyToFloat(bet.stake),
    projectedWinPayout: kambiCurrencyToFloat(bet.potentialPayout),
    amountPaid: kambiCurrencyToFloat(bet.stake),

    placeInvestment: 0,
    projectedPlacePayout: 0,

    bonusBet: 0,
    campaignId: null,
    campaignType: null,
    campaignActivatedInd: false,
    hasSpecialOffer: false,
    specialOffers: [],
  }
}

function betErrorFromKambiResponse(
  request: FobSingleSportsRequest,
  response: KambiPlaceBetFailedResponse
): FobSingleErrorResponse {
  if (response.generalErrors && response.generalErrors.length > 0) {
    const error = response.generalErrors[0]

    if (error.type === 'NOT_ENOUGH_FUNDS') {
      return {
        code: BetResponseCode.InsufficientFunds,
        response: { message: 'Insufficient Funds' },
      }
    } else {
      return BET_RESPONSE_UNKNOWN_ERROR
    }
  }

  if (response.couponRowErrors.length > 0 && response.couponRowErrors[0].errors.length > 0) {
    const error = response.couponRowErrors[0].errors[0]

    if (error.type === 'ODDS_CHANGED') {
      const newPriceString = error.arguments.find(a => a.type === 'ODDS')?.value

      return {
        code: BetResponseCode.PriceChanged,
        response: {
          message: 'Price Changed',
          prices: {
            winPrice: newPriceString ? kambiCurrencyToFloat(newPriceString) : undefined,
            previousWinPrice: request.priceWin,
          },
        },
      }
    }
  }

  return BET_RESPONSE_UNKNOWN_ERROR
}

function kambiCurrencyToFloat(n: number | string): number {
  return typeof n === 'string' ? kambiCurrencyToFloat(parseInt(n, 10)) : n / 1000
}

function floatToKambiCurrency(n: number) {
  return Math.floor(n * 1000)
}

function generateGUID(): string {
  function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}
