import React from 'react'
import { dayjs } from '@mobi/utils'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { DirectionalIcon } from '@core/Components/Icons'
import { CalendarDatePickerArrow, SelectStyled, YearStyled } from '../DatePicker.styles'
import {
  trackMeetingsCalendarNextMonth,
  trackMeetingsCalendarPrevMonth,
  trackMeetingsCalendarSelectMonth,
} from '@core/Areas/Meetings/analytics'

export const MonthYearSelect: React.FC<{
  currentDate: dayjs.Dayjs
  setCurrentDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  areaName?: 'meetings'
}> = ({ currentDate, setCurrentDate, areaName }) => {
  const currentYear = currentDate.year()

  const handlePrevMonthChange = () => {
    if (areaName && areaName === 'meetings') trackMeetingsCalendarPrevMonth()
    setCurrentDate(currentDate => dayjs(currentDate).subtract(1, 'months'))
  }

  const handleNextMonthChange = () => {
    if (areaName && areaName === 'meetings') trackMeetingsCalendarNextMonth()
    setCurrentDate(currentDate => dayjs(currentDate).add(1, 'months'))
  }

  const handleMonthSelect: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const selectedMonth = e.currentTarget.value
    if (areaName && areaName === 'meetings') trackMeetingsCalendarSelectMonth({ selectedMonth })
    setCurrentDate(
      dayjs(`${selectedMonth} ${currentDate.format('DD')} ${currentYear}`, 'MMMM DD YYYY')
    )
  }

  return (
    <Grid>
      <GridCell width='20%' align='right' valign='middle'>
        <CalendarDatePickerArrow onClick={handlePrevMonthChange}>
          <DirectionalIcon direction='left' size='2.3rem' />
        </CalendarDatePickerArrow>
      </GridCell>

      <GridCell width='60%' align='center' valign='middle'>
        <SelectStyled
          value={currentDate.format('MMMM')}
          data-tid-selected-month
          onChange={handleMonthSelect}
        >
          {dayjs.months().map(month => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </SelectStyled>{' '}
        <YearStyled>{currentYear}</YearStyled>
      </GridCell>

      <GridCell width='20%' align='left' valign='middle'>
        <CalendarDatePickerArrow onClick={handleNextMonthChange}>
          <DirectionalIcon direction='right' size='2.3rem' />
        </CalendarDatePickerArrow>
      </GridCell>
    </Grid>
  )
}
