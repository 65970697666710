import styled from '@emotion/styled'
import { colors, font, spacing } from '../../../Theme/Common'
import type { ActionOrientation } from './types'
import { CSSProperties } from 'react'

type FooterProps = {
  orientation: ActionOrientation
}

const flexDirections: Record<ActionOrientation, CSSProperties['flexDirection']> = {
  horizontal: 'row',
  vertical: 'column',
  'vertical-reverse': 'column-reverse',
  'horizontal-reverse': 'row-reverse',
}

export const AlertBoxActionsStyled = styled.footer<FooterProps>(({ orientation }) => {
  return {
    display: 'flex',
    flexDirection: flexDirections[orientation],
    gap: spacing.smx1,
    marginTop: spacing.bigx1,

    '> *': {
      flex: '0 1 50%',
    },
  }
})

export const AlertBoxIconStyled = styled.div(() => {
  return {
    width: spacing.lgx1,
    height: spacing.lgx1,
    backgroundColor: colors.surface[100],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    marginBottom: spacing.md,
  }
})

export const AlertBoxContentStyled = styled.div({
  '> header h1': {
    color: colors.black,
    fontWeight: font.weight.semibold,
    margin: 0,
    marginBottom: spacing.smx1,
    ...font.size.xl2,
  },

  '> div': {
    color: colors.neutral[800],
    ...font.size.lg,
  },
})
