import React from 'react'
import type { RacingBetType, Acceptor, Race } from '@mobi/betslip/types'
import {
  isFobMatchedSelection,
  isFobPropositionSelection,
  isSameRaceMultiSelection,
  isStartingPriceMatchedSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { QuickbetState } from '../../driver'
import { addAcceptorNameToSingleSelection, getAcceptorTextForSingleSelection } from './helpers'
import { NoveltySelection } from './NoveltySelection'
import { SingleSelectionStyled, SingleSameRaceMultiSelectionStyled } from './Selection.styles'
import { MultiRaceSelection } from './MultiRaceSelection'
import { QuickbetSilkImage } from '../SilkImage/QuickbetSilkImage'

type SelectionProps = {
  races: Race[]
  acceptors: Acceptor[]
  displayPill: boolean
} & Pick<QuickbetState, 'selection'>

export function SelectionDisplay({ selection, races, acceptors, displayPill }: SelectionProps) {
  if (!selection) {
    return null
  }

  if (isToteSelection(selection)) {
    if (isSingleRaceNoveltyBetType(selection.betType)) {
      return (
        <NoveltySelection selection={selection} acceptors={acceptors} displayPill={displayPill} />
      )
    }

    if (isMultiRaceBetType(selection.betType)) {
      return (
        <MultiRaceSelection
          selection={selection}
          races={races}
          acceptors={acceptors}
          displayPill={displayPill}
        />
      )
    }

    return (
      <SingleSelectionStyled data-tid-single-selection=''>
        {
          <QuickbetSilkImage
            acceptor={extractSelectedAcceptor(selection.selectionString, acceptors)}
          />
        }{' '}
        {
          <span data-tid-selection-text=''>
            {addAcceptorNameToSingleSelection(selection.selectionString, acceptors)}
          </span>
        }
      </SingleSelectionStyled>
    )
  }

  if (isSameRaceMultiSelection(selection)) {
    const sorted = selection.acceptors
      .map(a => ({
        ...a,
        legName: a.legNumber === 0 ? 'WIN' : `TOP ${a.legNumber + 1}`,
      }))
      .sort(sameRaceMultiSortCallback)
    const result = sorted.map((selectionAcceptor, index) => {
      const number = selectionAcceptor.acceptorNumber.toString()
      return (
        <SingleSameRaceMultiSelectionStyled data-tid-single-selection='' key={index}>
          <div>
            {<QuickbetSilkImage acceptor={extractSelectedAcceptor(number, acceptors)} />}{' '}
            <span data-tid-selection-text=''>
              {addAcceptorNameToSingleSelection(number, acceptors)}
            </span>
          </div>
          <div>{selectionAcceptor.legName}</div>
        </SingleSameRaceMultiSelectionStyled>
      )
    })
    return <>{result}</>
  }

  if (isFobMatchedSelection(selection)) {
    return (
      <SingleSelectionStyled data-tid-single-selection=''>
        {
          <QuickbetSilkImage
            acceptor={extractSelectedAcceptor(selection.acceptorNumber.toString(), acceptors)}
          />
        }{' '}
        {
          <span data-tid-selection-text=''>
            {addAcceptorNameToSingleSelection(selection.acceptorNumber.toString(), acceptors)}
          </span>
        }
      </SingleSelectionStyled>
    )
  }

  if (isStartingPriceMatchedSelection(selection)) {
    const acceptor = selection.selectedAcceptor
    if (acceptor) {
      return (
        <SingleSelectionStyled data-tid-single-selection=''>
          <QuickbetSilkImage acceptor={acceptor} />{' '}
          <span data-tid-selection-text=''>{getAcceptorTextForSingleSelection(acceptor)}</span>
        </SingleSelectionStyled>
      )
    }
  }

  if (isFobPropositionSelection(selection)) {
    const acceptor = selection.selectedAcceptors && selection.selectedAcceptors[0]
    if (acceptor) {
      return (
        <SingleSelectionStyled data-tid-single-selection=''>
          <QuickbetSilkImage acceptor={acceptor} />{' '}
          <span data-tid-selection-text=''>{getAcceptorTextForSingleSelection(acceptor)}</span>
        </SingleSelectionStyled>
      )
    } else {
      return (
        <SingleSelectionStyled data-tid-single-selection=''>
          {selection.displayName}
        </SingleSelectionStyled>
      )
    }
  }

  return <span>{JSON.stringify(selection)}</span>
}

export type SameRaceMultiSortCallbackParamType = { legNumber: number; acceptorNumber: number }
export function sameRaceMultiSortCallback(
  a: SameRaceMultiSortCallbackParamType,
  b: SameRaceMultiSortCallbackParamType
) {
  let diff = a.legNumber - b.legNumber

  // lower leg number must come first
  if (diff != 0) return diff

  // if same leg, lower acceptor number must come first
  // we never expect same acceptor number
  return a.acceptorNumber - b.acceptorNumber
}

function extractSelectedAcceptor(
  selectionNumber: string,
  acceptors: Acceptor[]
): Acceptor | undefined {
  return acceptors.find(a => `${a.number}` === selectionNumber)
}

function isSingleRaceNoveltyBetType(value: RacingBetType): boolean {
  const noveltyTypes: RacingBetType[] = ['Exacta', 'Quinella', 'Trifecta', 'First 4']
  return noveltyTypes.includes(value)
}

function isMultiRaceBetType(value: RacingBetType): boolean {
  return value === 'Double' || value === 'Quaddie' || value === 'All Up'
}
