import React from 'react'
import type { AllUpSelection } from '@mobi/betslip/types'
import {
  SingleAllUpFormulaContainerStyled,
  SingleAllUpFormulaItemStyled,
  SingleAllUpFormulaTitleStyled,
} from './SingleAllUpFormula.styles'

const FORMULA_NAME_MAP = {
  F1: 'Singles',
  F2: 'Doubles',
  F3: 'Trebles',
  F4: 'Pick 4',
  F5: 'Pick 5',
  F6: 'Pick 6',
}

export function SingleAllUpFormula({ selection }: { selection: AllUpSelection }) {
  return (
    <SingleAllUpFormulaContainerStyled>
      {selection.formulas.map(
        ({ formula, isSelected, numberOfCombinations }) =>
          isSelected && (
            <SingleAllUpFormulaItemStyled key={formula}>
              <SingleAllUpFormulaTitleStyled>
                {FORMULA_NAME_MAP[`F${formula}` as keyof typeof FORMULA_NAME_MAP]}
              </SingleAllUpFormulaTitleStyled>{' '}
              x{numberOfCombinations}
            </SingleAllUpFormulaItemStyled>
          )
      )}
    </SingleAllUpFormulaContainerStyled>
  )
}
