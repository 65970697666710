/** Z-Index for Element Layers
 * @todo make more generic, share language w/ Figma design system
 */
export const layering = {
  baseLayer: 1,

  stickyPageElements: 8,

  pagePopoverElements: 9,

  toasts: 10,

  /** AppHeader + Account Drop Down */
  appHeader: 11,

  /** Used for Betslip, Search etc */
  overlayLow: 210,

  /** Used for Quickbet etc */
  modalOverlay: 220,

  /** Used for Deposit */
  overlayHigh: 225,

  login: 302,

  skyVideoPlayerFloating: 400,
} as const
