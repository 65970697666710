import React from 'react'

import { DetailsWrapper } from '../TakeABreak.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'

const TakeABreakError = (): JSX.Element | null => {
  return (
    <DetailsWrapper>
      <NoticeBox
        title='Sorry, an error occurred. Please try again later.'
        noticeBoxType={NoticeBoxTypes.Error}
        hasBorder={true}
      />
    </DetailsWrapper>
  )
}

export default TakeABreakError
