import container from '../../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { registerComponentWithParams } from '@classic/AppUtils/Framework/Components/koComponentRegistration'
import template from './resulted.image.tpl.html'
import { ResultedImageViewModel } from './ResultedImageViewModel'
registerComponentWithParams<ResultedImageViewModel>({
  componentName: 'resulted-image',
  template,
  container: container,
  resolver: (container: interfaces.Container) =>
    container.get<ResultedImageViewModel>('ResultedImageViewModel'),
})
