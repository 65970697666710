import * as cookies from 'js-cookie'
import moment from 'moment'

const timezoneOffsetCookieName = 'TimezoneOffset'

export function today(): Date {
  const date = adjustDateTimeByTimezoneOffsetIfRequired(new Date(), getExplicitTimezoneOffset())
  date.setHours(0, 0, 0, 0)
  return date
}

export function todayAsAwst(): Date {
  const date = moment.utc(new Date()).utcOffset(8).toDate()
  date.setHours(0, 0, 0, 0)
  return date
}

export function now(): number {
  return adjustDateTimeByTimezoneOffsetIfRequired(new Date(), getExplicitTimezoneOffset()).getTime()
}

//get the timezone offset - use the cookie set by the android app if present. If not, use javascript Date.
export function getTimezoneOffset(): number {
  let timezoneOffset = getExplicitTimezoneOffset()
  if (timezoneOffset == null) {
    timezoneOffset = new Date().getTimezoneOffset()
  }
  return timezoneOffset
}

export function parseIso8601Date(value: string): Date {
  return moment(value, moment.ISO_8601).toDate()
}

export function parseIso8601DateTime(value: string): Date {
  const result = moment.utc(value, moment.ISO_8601).toDate()
  return adjustDateTimeByTimezoneOffsetIfRequired(result, getExplicitTimezoneOffset())
}

function adjustDateTimeByTimezoneOffsetIfRequired(
  value: Date,
  timezoneOffset: number | null
): Date {
  if (timezoneOffset != null && timezoneOffset !== new Date().getTimezoneOffset()) {
    return moment(value).utc().add(negate(timezoneOffset), 'm').toDate()
  }
  return value
}

function negate(value: number): number {
  return value * -1
}

function getExplicitTimezoneOffset(): number | null {
  if (hasExplicitTimezoneOffset() === false) {
    return null
  }
  return Number(cookieValue(timezoneOffsetCookieName))
}

function hasExplicitTimezoneOffset(): boolean {
  return isCookieSet(timezoneOffsetCookieName)
}

function isCookieSet(cookieName: string): boolean {
  return cookieValue(cookieName) != undefined
}

function cookieValue(cookieName: string): string | undefined {
  return cookies.get(cookieName)
}

export function getTimezoneOffsetHours(): number {
  return new Date().getTimezoneOffset() / -60
}
