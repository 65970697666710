import React from 'react'
import {
  BettableTips,
  type Props as BettableTipsProps,
} from '@core/Areas/Racing/Components/BettableTips/BettableTips'
import { useTipsterDisplay } from '@core/Areas/Racing/Hooks/useTipsterDisplay'
import { Provider } from 'react-redux'
import { store } from '@core/Store'
import { RaceCardIcon } from '@core/Components/Icons'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { CompactPanel } from '@core/Components/Panel'
import { Text } from '@core/Components/Text'

type Props = BettableTipsProps & {
  tipster: string
}

export const BettableTipsPanel = ({
  tipster,
  tips,
  isRaceOpen,
  raceKey,
  race,
}: Props): JSX.Element => {
  const tipsterDisplay = useTipsterDisplay(tipster)

  return (
    <Provider store={store}>
      <CompactPanel
        themeName={ExpandablePanelThemes.lavender}
        headerText={<Text data-testid='bettable-tips-header-text'>{tipsterDisplay} Tips</Text>}
        headerIcon={<RaceCardIcon icon='tips' />}
      >
        <BettableTips
          tips={tips}
          race={race}
          raceKey={raceKey}
          isRaceOpen={isRaceOpen}
          tipSource='tips'
        />
      </CompactPanel>
    </Provider>
  )
}
