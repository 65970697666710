import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setBettingDisabledStatus } from '@mobi/betslip/Store/Workflow'
import { Banner } from '@mobi/component-library/Common/V2'
import { useTakeABreak } from '@core/Areas/Account/TakeABreak/Hooks'

export const TakeABreakStatus = () => {
  const dispatch = useDispatch()

  const { data: takeABreakStatus } = useTakeABreak()

  useEffect(() => {
    dispatch(setBettingDisabledStatus(takeABreakStatus?.inProgress ?? false))
  }, [dispatch, takeABreakStatus])

  return (
    <Banner
      text='Betting is currently disabled for this account. For more information, call 1800 858 858'
      type='error'
    />
  )
}
