import React, { type ComponentProps } from 'react'
import styled from '@emotion/styled'
import { Button, buttonColors, buttonSizing } from './Button'

type Props = ComponentProps<typeof Button>

export const PillButton: React.FC<Props> = ({
  children,
  color = 'primary',
  isFullWidth = false,
  size = 'md',
  ...props
}) => (
  <PillButtonStyled color={color} isFullWidth={isFullWidth} size={size} {...props}>
    {children}
  </PillButtonStyled>
)

const PillButtonStyled = styled(Button)(
  ({ color = 'primary', size = 'md', isFullWidth = false, isIconOnlyButton = false }) => ({
    ...(isFullWidth ? { display: 'flex', width: '100%' } : { display: 'inline-flex' }),

    ...buttonSizing({ isIconOnlyButton, isPillButton: true })[size],
    ...buttonColors[color],
  })
)
