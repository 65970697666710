import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableSubstitute {
  constructor() {
    this.displayNumber = ko.observable<string>('')
    this.displayName = ko.observable<string>('')
    this.displayResult = ko.observable<string>('')

    this.starterNumber = ''
    this.starterName = ''

    this.isAbandoned = false
    this.isWinner = false
    this.isLoser = false
    this.isUnallocated = false

    this.updateDisplayVars()
  }

  merge(other: ObservableSubstitute) {
    Guard.notNull(other)

    this.starterNumber = other.starterNumber.toString()
    this.starterName = other.starterName

    this.isAbandoned = other.isAbandoned
    this.isWinner = other.isWinner
    this.isLoser = other.isLoser
    this.isUnallocated = other.isUnallocated

    this.updateDisplayVars()
  }

  updateDisplayVars() {
    if (this.isAbandoned) {
      this.displayNumber('')
      this.displayName('Abandoned')
      this.displayResult('')
    } else if (this.isUnallocated) {
      this.displayNumber('')
      this.displayName('No substitute')
      this.displayResult('')
    } else {
      this.displayNumber(this.starterNumber + '.')
      this.displayName(this.starterName)

      if (this.isWinner) {
        this.displayResult('Won')
      } else if (this.isLoser) {
        this.displayResult('Lost')
      } else {
        this.displayResult('')
      }
    }
  }

  public displayNumber: ko.Observable<string>
  public displayName: ko.Observable<string>
  public displayResult: ko.Observable<string>

  public starterNumber: string
  public starterName: string

  public isAbandoned: boolean
  public isWinner: boolean
  public isLoser: boolean
  public isUnallocated: boolean
}
