import styled from '@emotion/styled'
import { FeaturedItemsHeaderStyled } from '../FeaturedItems/FeaturedItems.styles'
import { HomeNextRacesListItemStyled } from '../HomeNextRaces/HomeNextRaces.styles'
import { keyframes } from '@emotion/react'
import { hexColors } from '@mobi/settings'

const animationBackground = keyframes({
  '0%': {
    backgroundPosition: `100% 0`,
  },
  '100%': {
    backgroundPosition: `15% 100%`,
  },
})

const gradientColorStop1 = hexColors.orange
const gradientColorStop2 = hexColors.alizarin

export const HomeSuperPicksAnimationContainer = styled('div')({
  backgroundSize: `400% 400%`,
  animation: `${animationBackground} 7s linear infinite`,
  backgroundImage: `linear-gradient(45deg,
    ${gradientColorStop1} 0%,
    ${gradientColorStop2} 33%,
    ${gradientColorStop1} 66%,
    ${gradientColorStop2} 100%)`,
})

export const HomeNextSuperPicksContainerStyled = styled('div')({
  WebkitOverflowScrolling: 'touch',
  overflowX: 'auto',
  WebkitTapHighlightColor: 'transparent',
  width: '100%',
})

export const HomeNextSuperPicksListStyled = styled(HomeNextRacesListItemStyled)({
  background: hexColors.cinnabar,
})

export const HomeNextSuperPicksHeaderStyled = styled(FeaturedItemsHeaderStyled)({
  background: 'transparent',
  padding: '0.75rem 1rem 0',
  color: hexColors.black,
})

export const HomeNextRacesListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

export const HomeNextEventNameEventStyled = styled('div')({
  fontSize: '1.4rem',
  lineHeight: '1.8rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const HomeNextEventRaceTextStyled = styled('span')({
  fontSize: '1.2rem',
  justifyContent: 'space-between',
  display: 'flex',
})

export const HomeTimeToJump = styled('div')({
  textAlign: 'right',
})
