import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import React from 'react'
import { HeaderStyled, IconStyled } from './TippingPoolHeader.styles'

interface TippingPoolHeaderProps {
  description: string
  icon: keyof typeof iconPaths
}

export function TippingPoolHeader({ description, icon }: TippingPoolHeaderProps): JSX.Element {
  return (
    <HeaderStyled>
      <IconStyled type={icon === 'FB' ? 'FBL' : icon || 'tab'} />
      {description}
    </HeaderStyled>
  )
}
