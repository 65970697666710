import {
  type EventKey,
  trackEvent,
  trackKey,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { debounceFn } from '@mobi/utils/functions'
import type { SkyVideoPlayerState } from './Store'

export const trackVisionOpened = (
  opts: { channel: SkyVideoPlayerState['selectedChannel'] } & (
    | { openLocation: 'bottom-navigation'; headerState?: never }
    | { openLocation: 'race-card'; headerState: 'sticky' | 'default' }
    | { openLocation: 'sky-page' }
  )
) => {
  trackEvent(analyticsKeys.visionOpened, opts)
}

export const trackVisionClosed = () => {
  trackKey(analyticsKeys.visionClosed)
}

export const trackFullScreenOn = debounceFn(() => {
  trackKey(analyticsKeys.visionFullScreenOn)
}, 1000)

export const trackFullScreenOff = debounceFn(() => {
  trackKey(analyticsKeys.visionFullScreenOff)
}, 1000)

export const trackVisionUnMute = debounceFn(() => {
  trackKey(analyticsKeys.visionUnMuted)
}, 1000)

export const trackVisionMute = debounceFn(() => {
  trackKey(analyticsKeys.visionMuted)
}, 1000)

export const trackSelectedChannel = (channel: SkyVideoPlayerState['selectedChannel']) => {
  const keys: Record<SkyVideoPlayerState['selectedChannel'], EventKey> = {
    Sky1: analyticsKeys.visionSky1Selected,
    Sky2: analyticsKeys.visionSky2Selected,
    RacingWA: analyticsKeys.visionRacingWASelected,
  }
  trackKey(keys[channel])
}
