import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@mobi/component-library/Common/V2/Inputs/Checkbox'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isFatalMultiBetLegError } from '@mobi/betslip/helpers/state'
import { toggleIsItemInMulti } from '@mobi/betslip/Store/Bets'

export const MultiLegCheckbox: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()

  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const receipt = useAppSelector(state => state.betslip.bets.multiReceipt)

  if (workflowStatus !== 'ready') return null

  const isDisabled = isBusy || receipt != null || isFatalMultiBetLegError(item)

  return (
    <WrapperStyled>
      <Checkbox
        label=''
        name={item.id}
        isDisabled={isDisabled}
        checked={item.isInMulti}
        onChange={() => dispatch(toggleIsItemInMulti({ id: item.id }))}
      />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  'label [role="checkbox"]': { margin: 0 },
})
