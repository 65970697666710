import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { IObservableFinisher } from '../Model/Observables/IObservableFinisher'
import ObservableRaceFinisher from '../Model/Observables/ObservableRaceFinisher'
import { BaseFinisherCodeBuilder } from './BaseFinisherCodeBuilder'

export default class RaceFinisherBuilder extends BaseFinisherCodeBuilder {
  build(dto: dtos.FinisherDataTransferObject): IObservableFinisher {
    const model = super.addBaseInformation(
      new ObservableRaceFinisher(),
      dto
    ) as ObservableRaceFinisher
    model.template('race-finishers-template')
    model.barrier(dto.Barrier)
    model.jockeyWeight(dto.JockeyWeight)
    model.tag('Race')
    return model
  }
}
