import React from 'react'
import { MedicareIconStyled } from './AdditionalDetails.styles'

export function MedicareSvg() {
  return (
    <MedicareIconStyled
      width={'1em'}
      height={'7rem'}
      viewBox='0 0 256 128'
      role='img'
      aria-hidden='false'
    >
      <title>Example Medicare card</title>
      <style type='text/css'>
        {
          '.st0 { fill: none;} .st1 { fill: none;enable-background:new;} .st2 { fill: #989898;} .st3 { fill: #CDCCCC;} .st4 { fill: #E91E25;}'
        }
      </style>
      <g>
        <path d='M56.1,84.2h-3V72.8c-1.1,1-2.4,1.8-3.9,2.3v-2.7c0.8-0.3,1.6-0.7,2.6-1.5s1.6-1.6,1.9-2.5h2.4V84.2z' />
      </g>
      <rect x='47.4' y='68.3' className='st0' width='20' height='20.8' />
      <path
        className='st1'
        d='M55.6,84h-1.9V71.7c-0.5,0.4-1.1,0.9-1.8,1.3c-0.8,0.4-1.4,0.8-2,1v-1.9c1.1-0.5,2-1.1,2.8-1.8 c0.8-0.7,1.4-1.4,1.7-2.1h1.2V84z'
      />
      <g>
        <g>
          <path
            className='st2'
            d='M72.1,49.8H70V36.4c-0.5,0.5-1.2,1-2,1.5s-1.6,0.8-2.2,1.1v-2c1.2-0.6,2.2-1.2,3.1-2s1.5-1.6,1.9-2.3h1.4 V49.8z'
          />
          <path
            className='st2'
            d='M88.6,47.8v2H77.2c0-0.5,0.1-1,0.2-1.5c0.3-0.8,0.8-1.5,1.4-2.3s1.6-1.6,2.8-2.6c1.9-1.5,3.1-2.7,3.8-3.6 s1-1.7,1-2.5c0-0.8-0.3-1.5-0.9-2.1s-1.4-0.9-2.3-0.9c-1,0-1.8,0.3-2.4,0.9s-0.9,1.5-0.9,2.5l-2.2-0.2c0.1-1.6,0.7-2.8,1.7-3.7 s2.3-1.3,3.9-1.3c1.6,0,3,0.5,3.9,1.4s1.4,2,1.4,3.4c0,0.7-0.1,1.4-0.4,2s-0.7,1.4-1.4,2.1s-1.7,1.7-3.3,3 c-1.3,1.1-2.1,1.8-2.4,2.2s-0.7,0.8-0.9,1.1L88.6,47.8L88.6,47.8z'
          />
          <path
            className='st2'
            d='M90.9,45.3L93,45c0.2,1.2,0.7,2.1,1.2,2.6s1.3,0.8,2.1,0.8c1,0,1.8-0.3,2.5-1s1-1.5,1-2.6 c0-1-0.3-1.8-0.9-2.4s-1.4-0.9-2.4-0.9c-0.4,0-0.9,0.1-1.5,0.2l0.2-1.9c0.1,0,0.3,0,0.3,0c0.9,0,1.7-0.2,2.4-0.7s1.1-1.2,1.1-2.2 c0-0.8-0.3-1.4-0.8-1.9s-1.2-0.8-2-0.8c-0.8,0-1.5,0.3-2.1,0.8s-0.9,1.3-1.1,2.3L91.1,37c0.3-1.4,0.8-2.5,1.8-3.3s2.1-1.2,3.4-1.2 c0.9,0,1.8,0.2,2.6,0.6s1.4,1,1.8,1.6s0.6,1.4,0.6,2.2c0,0.7-0.2,1.4-0.6,2s-1,1.1-1.8,1.5c1,0.2,1.8,0.7,2.4,1.5s0.8,1.7,0.8,2.8 c0,1.5-0.5,2.8-1.6,3.8s-2.5,1.6-4.1,1.6c-1.5,0-2.8-0.4-3.8-1.3S91,46.7,90.9,45.3z'
          />
          <path
            className='st2'
            d='M110.9,49.8v-4.1h-7.5v-1.9l7.8-11.1h1.7v11.1h2.3v1.9h-2.3v4.1H110.9z M110.9,43.8V36l-5.4,7.7L110.9,43.8 L110.9,43.8z'
          />
          <path
            className='st2'
            d='M124.2,45.3l2.2-0.2c0.2,1.1,0.5,1.9,1.1,2.4c0.6,0.5,1.3,0.8,2.2,0.8c1,0,1.9-0.4,2.6-1.1s1.1-1.8,1.1-3 c0-1.2-0.3-2.2-1-2.8s-1.6-1-2.7-1c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.3-1,0.7-1.3,1.2l-2-0.3l1.7-8.8h8.5v2h-6.9l-0.9,4.6 c1-0.7,2.1-1.1,3.2-1.1c1.5,0,2.8,0.5,3.8,1.6s1.5,2.4,1.5,4c0,1.6-0.5,2.9-1.4,4c-1.1,1.4-2.6,2.1-4.5,2.1 c-1.6,0-2.8-0.4-3.8-1.3S124.3,46.8,124.2,45.3z'
          />
          <path
            className='st2'
            d='M148.5,36.8l-2.1,0.2c-0.2-0.8-0.5-1.4-0.8-1.8c-0.6-0.6-1.3-0.9-2.1-0.9c-0.7,0-1.3,0.2-1.8,0.6 c-0.7,0.5-1.2,1.2-1.6,2.1s-0.6,2.3-0.6,4c0.5-0.8,1.1-1.3,1.9-1.7s1.5-0.6,2.3-0.6c1.4,0,2.6,0.5,3.6,1.6c1,1,1.5,2.4,1.5,4 c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.1,1.6-1.9,2.1c-0.8,0.5-1.7,0.7-2.8,0.7c-1.8,0-3.2-0.6-4.3-1.9s-1.7-3.4-1.7-6.4 c0-3.3,0.6-5.7,1.8-7.2c1.1-1.3,2.5-2,4.3-2c1.4,0,2.5,0.4,3.3,1.1C147.8,34.5,148.3,35.5,148.5,36.8z M139.9,44.2 c0,0.7,0.2,1.4,0.5,2.1s0.7,1.2,1.3,1.5s1.1,0.5,1.7,0.5c0.9,0,1.7-0.4,2.3-1.1s1-1.7,1-2.9c0-1.2-0.3-2.1-0.9-2.8 c-0.6-0.7-1.4-1-2.4-1c-1,0-1.8,0.3-2.4,1S139.9,43.1,139.9,44.2z'
          />
          <path
            className='st2'
            d='M151,34.9v-2h11.1v1.6c-1.1,1.2-2.2,2.7-3.3,4.6s-1.9,3.9-2.5,6c-0.4,1.4-0.7,3-0.8,4.7h-2.2 c0-1.4,0.3-3,0.8-4.9s1.2-3.8,2.2-5.5s2-3.3,3-4.5L151,34.9L151,34.9z'
          />
          <path
            className='st2'
            d='M167.5,40.5c-0.9-0.3-1.5-0.8-1.9-1.4s-0.6-1.3-0.6-2.1c0-1.3,0.4-2.3,1.3-3.2s2.1-1.3,3.6-1.3 c1.5,0,2.7,0.4,3.6,1.3s1.4,1.9,1.4,3.2c0,0.8-0.2,1.5-0.6,2.1s-1.1,1-1.9,1.4c1.1,0.3,1.9,0.9,2.4,1.7s0.8,1.7,0.8,2.7 c0,1.5-0.5,2.7-1.6,3.7s-2.4,1.5-4.1,1.5s-3.1-0.5-4.1-1.5s-1.6-2.3-1.6-3.8c0-1.1,0.3-2.1,0.9-2.8S166.4,40.8,167.5,40.5z  M166.4,44.9c0,0.6,0.1,1.2,0.4,1.7s0.7,1,1.3,1.3s1.2,0.5,1.8,0.5c1,0,1.8-0.3,2.5-1s1-1.5,1-2.5c0-1-0.3-1.9-1-2.5s-1.5-1-2.5-1 c-1,0-1.8,0.3-2.5,1S166.4,43.9,166.4,44.9z M167.1,36.9c0,0.8,0.3,1.5,0.8,2s1.2,0.8,2,0.8c0.8,0,1.5-0.3,2-0.8s0.8-1.1,0.8-1.9 c0-0.8-0.3-1.4-0.8-2s-1.2-0.8-2-0.8c-0.8,0-1.5,0.3-2,0.8S167.1,36.2,167.1,36.9z'
          />
          <path
            className='st2'
            d='M177.9,45.8l2-0.2c0.2,1,0.5,1.6,1,2.1s1.1,0.6,1.9,0.6c0.6,0,1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2s0.6-1.2,0.8-2 s0.3-1.7,0.3-2.6c0-0.1,0-0.2,0-0.4c-0.4,0.7-1,1.2-1.7,1.6s-1.5,0.6-2.4,0.6c-1.4,0-2.6-0.5-3.6-1.5s-1.5-2.4-1.5-4.1 c0-1.7,0.5-3.1,1.5-4.2s2.3-1.6,3.9-1.6c1.1,0,2.1,0.3,3.1,0.9s1.6,1.5,2.1,2.6s0.7,2.7,0.7,4.8c0,2.2-0.2,3.9-0.7,5.2 s-1.2,2.3-2.1,3s-2,1-3.3,1c-1.3,0-2.4-0.4-3.3-1.1S178.1,47.2,177.9,45.8z M186.5,38.3c0-1.2-0.3-2.2-1-2.9s-1.4-1.1-2.3-1.1 c-0.9,0-1.8,0.4-2.4,1.1s-1,1.8-1,3c0,1.1,0.3,2,1,2.7s1.5,1,2.4,1c1,0,1.8-0.3,2.4-1S186.5,39.5,186.5,38.3z'
          />
          <path
            className='st2'
            d='M197.6,41.3c0-2,0.2-3.7,0.6-4.9s1-2.2,1.9-2.9s1.9-1,3.1-1c0.9,0,1.7,0.2,2.4,0.6s1.3,0.9,1.7,1.6 s0.8,1.6,1.1,2.6s0.4,2.4,0.4,4.1c0,2-0.2,3.6-0.6,4.9s-1,2.2-1.9,2.9s-1.9,1-3.1,1c-1.7,0-3-0.6-3.9-1.8 C198.2,46.9,197.6,44.6,197.6,41.3z M199.8,41.3c0,2.8,0.3,4.7,1,5.6s1.5,1.4,2.4,1.4s1.8-0.5,2.4-1.4s1-2.8,1-5.6 c0-2.8-0.3-4.7-1-5.6s-1.5-1.4-2.5-1.4c-1,0-1.7,0.4-2.3,1.2C200.1,36.6,199.8,38.5,199.8,41.3z'
          />
        </g>
        <g>
          <path
            className='st2'
            d='M72.5,79.7l1.9-0.3c0.1,1.2,0.3,2,0.7,2.5c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1-0.1,1.4-0.4c0.4-0.2,0.6-0.6,0.8-1 c0.1-0.4,0.2-1.1,0.2-2V68.4h2.1v10.7c0,1.3-0.2,2.3-0.5,3.1c-0.3,0.7-0.8,1.3-1.5,1.7c-0.7,0.4-1.5,0.6-2.4,0.6 c-1.4,0-2.4-0.4-3.2-1.2C72.9,82.5,72.5,81.3,72.5,79.7z'
          />
          <path
            className='st2'
            d='M83.6,78.5c0-2.1,0.6-3.7,1.8-4.7c1-0.8,2.2-1.3,3.6-1.3c1.6,0,2.8,0.5,3.8,1.5c1,1,1.5,2.4,1.5,4.3 c0,1.5-0.2,2.6-0.7,3.5c-0.4,0.8-1.1,1.5-1.9,2c-0.8,0.5-1.8,0.7-2.8,0.7c-1.6,0-2.9-0.5-3.9-1.5C84.1,81.9,83.6,80.4,83.6,78.5z  M85.6,78.5c0,1.5,0.3,2.6,1,3.3c0.6,0.7,1.4,1.1,2.4,1.1c1,0,1.8-0.4,2.4-1.1c0.6-0.7,1-1.8,1-3.3c0-1.4-0.3-2.5-1-3.2 c-0.6-0.7-1.4-1.1-2.4-1.1c-1,0-1.8,0.4-2.4,1.1C85.9,75.9,85.6,77,85.6,78.5z'
          />
          <path
            className='st2'
            d='M96.6,84.2V68.4h1.9v5.6c0.9-1,2-1.6,3.4-1.6c0.8,0,1.6,0.2,2.2,0.5s1.1,0.8,1.3,1.4c0.3,0.6,0.4,1.4,0.4,2.6 v7.2H104v-7.2c0-1-0.2-1.7-0.6-2.1s-1-0.7-1.8-0.7c-0.6,0-1.1,0.1-1.6,0.4s-0.9,0.7-1.1,1.2s-0.3,1.2-0.3,2.1v6.2H96.6z'
          />
          <path
            className='st2'
            d='M108.8,84.2V72.8h1.7v1.6c0.8-1.3,2-1.9,3.6-1.9c0.7,0,1.3,0.1,1.9,0.4c0.6,0.2,1,0.6,1.3,1 c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.4,0.1,1,0.1,1.9v7h-1.9v-6.9c0-0.8-0.1-1.4-0.2-1.8c-0.2-0.4-0.4-0.7-0.8-0.9 c-0.4-0.2-0.8-0.3-1.3-0.3c-0.8,0-1.5,0.3-2.1,0.8c-0.6,0.5-0.9,1.5-0.9,3v6.2L108.8,84.2L108.8,84.2z'
          />
          <path
            className='st2'
            d='M127.4,84.2V68.4h5.4c1.2,0,2.2,0.1,2.8,0.2c0.9,0.2,1.7,0.6,2.3,1.1c0.8,0.7,1.5,1.6,1.9,2.7 s0.6,2.3,0.6,3.7c0,1.2-0.1,2.3-0.4,3.2s-0.6,1.7-1.1,2.3c-0.4,0.6-0.9,1.1-1.4,1.4c-0.5,0.3-1.1,0.6-1.9,0.8 c-0.7,0.2-1.6,0.3-2.5,0.3L127.4,84.2L127.4,84.2z M129.5,82.3h3.4c1,0,1.9-0.1,2.4-0.3c0.6-0.2,1.1-0.5,1.4-0.8 c0.5-0.5,0.9-1.2,1.2-2c0.3-0.8,0.4-1.8,0.4-3c0-1.6-0.3-2.9-0.8-3.8c-0.5-0.9-1.2-1.5-2-1.8c-0.6-0.2-1.5-0.3-2.7-0.3h-3.3 L129.5,82.3L129.5,82.3z'
          />
          <path
            className='st2'
            d='M148.7,79.1l2-0.2c0.1,0.8,0.3,1.4,0.6,1.9c0.3,0.5,0.9,0.9,1.6,1.2c0.7,0.3,1.5,0.5,2.4,0.5 c0.8,0,1.5-0.1,2.1-0.4c0.6-0.2,1.1-0.6,1.4-1c0.3-0.4,0.4-0.9,0.4-1.3c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.4-0.8-0.7-1.4-0.9 c-0.4-0.2-1.4-0.4-2.8-0.8c-1.4-0.3-2.5-0.7-3-1c-0.8-0.4-1.3-0.9-1.7-1.5c-0.4-0.6-0.6-1.2-0.6-2c0-0.8,0.2-1.5,0.7-2.2 c0.5-0.7,1.1-1.2,2-1.6c0.9-0.4,1.8-0.5,2.9-0.5c1.2,0,2.2,0.2,3.1,0.6s1.6,0.9,2.1,1.7c0.5,0.7,0.7,1.6,0.8,2.5l-2,0.1 c-0.1-1-0.5-1.7-1.1-2.3c-0.6-0.5-1.5-0.8-2.7-0.8c-1.3,0-2.2,0.2-2.8,0.7c-0.6,0.5-0.9,1-0.9,1.7c0,0.6,0.2,1,0.6,1.4 c0.4,0.4,1.4,0.7,3.1,1.1c1.7,0.4,2.9,0.7,3.5,1c0.9,0.4,1.6,1,2,1.6c0.4,0.6,0.7,1.4,0.7,2.2c0,0.8-0.2,1.6-0.7,2.4 c-0.5,0.7-1.2,1.3-2.1,1.7c-0.9,0.4-1.9,0.6-3,0.6c-1.4,0-2.6-0.2-3.6-0.6c-1-0.4-1.7-1-2.3-1.9C149,81.1,148.7,80.2,148.7,79.1z'
          />
          <path
            className='st2'
            d='M163.9,84.2V72.8h1.7v1.6c0.4-0.6,0.8-1,1.4-1.3c0.6-0.3,1.3-0.5,2-0.5c0.8,0,1.5,0.2,2.1,0.5 c0.5,0.4,0.9,0.8,1.1,1.5c0.9-1.3,2.1-2,3.5-2c1.1,0,2,0.3,2.6,0.9s0.9,1.6,0.9,2.9v7.8h-1.9V77c0-0.8-0.1-1.3-0.2-1.7 c-0.1-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.4-0.8,2.6v6.6h-1.9v-7.4 c0-0.9-0.2-1.5-0.5-1.9s-0.8-0.6-1.5-0.6c-0.5,0-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1,1.3s-0.3,1.3-0.3,2.4v5.9L163.9,84.2 L163.9,84.2z'
          />
          <path
            className='st2'
            d='M182.2,70.6v-2.2h1.9v2.2H182.2z M182.2,84.2V72.8h1.9v11.4H182.2z'
          />
          <path
            className='st2'
            d='M191.3,82.4l0.3,1.7c-0.5,0.1-1,0.2-1.5,0.2c-0.7,0-1.2-0.1-1.6-0.3c-0.4-0.2-0.7-0.5-0.8-0.9 c-0.2-0.4-0.2-1.1-0.2-2.3v-6.6H186v-1.5h1.4v-2.8l1.9-1.2v4h1.9v1.5h-1.9v6.7c0,0.6,0,0.9,0.1,1.1c0.1,0.2,0.2,0.3,0.3,0.4 c0.2,0.1,0.4,0.1,0.7,0.1C190.7,82.5,190.9,82.5,191.3,82.4z'
          />
          <path
            className='st2'
            d='M193.2,84.2V68.4h1.9v5.6c0.9-1,2-1.6,3.4-1.6c0.8,0,1.6,0.2,2.2,0.5s1.1,0.8,1.3,1.4 c0.3,0.6,0.4,1.4,0.4,2.6v7.2h-1.9v-7.2c0-1-0.2-1.7-0.6-2.1c-0.4-0.4-1-0.7-1.8-0.7c-0.6,0-1.1,0.1-1.6,0.4 c-0.5,0.3-0.9,0.7-1.1,1.2s-0.3,1.2-0.3,2.1v6.2H193.2z'
          />
        </g>
        <g>
          <path
            className='st2'
            d='M128.7,114.3v-11.4h1.7v1.6c0.4-0.6,0.8-1,1.4-1.3c0.6-0.3,1.3-0.5,2-0.5c0.8,0,1.5,0.2,2.1,0.5 c0.5,0.4,0.9,0.8,1.1,1.5c0.9-1.3,2.1-2,3.5-2c1.1,0,2,0.3,2.6,0.9c0.6,0.6,0.9,1.6,0.9,2.9v7.8h-1.9v-7.2c0-0.8-0.1-1.3-0.2-1.7 c-0.1-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.4-0.8,2.6v6.6h-1.9v-7.4 c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.8-0.6-1.5-0.6c-0.5,0-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1,1.3c-0.2,0.6-0.3,1.3-0.3,2.4v5.9 L128.7,114.3L128.7,114.3z'
          />
          <path
            className='st2'
            d='M147,114.3v-11.4h1.7v1.6c0.4-0.6,0.8-1,1.4-1.3c0.6-0.3,1.3-0.5,2-0.5c0.8,0,1.5,0.2,2.1,0.5 c0.5,0.4,0.9,0.8,1.1,1.5c0.9-1.3,2.1-2,3.5-2c1.1,0,2,0.3,2.6,0.9c0.6,0.6,0.9,1.6,0.9,2.9v7.8h-1.9v-7.2c0-0.8-0.1-1.3-0.2-1.7 c-0.1-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.4-0.8,2.6v6.6h-1.9v-7.4 c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.8-0.6-1.5-0.6c-0.5,0-1,0.1-1.5,0.4c-0.5,0.3-0.8,0.7-1,1.3c-0.2,0.6-0.3,1.3-0.3,2.4v5.9 L147,114.3L147,114.3z'
          />
          <path className='st2' d='M163.9,114.5l4.6-16.3h1.5l-4.6,16.3H163.9z' />
          <path
            className='st2'
            d='M171.3,118.7l-0.2-1.8c0.4,0.1,0.8,0.2,1.1,0.2c0.4,0,0.8-0.1,1-0.2c0.3-0.1,0.5-0.3,0.6-0.6 c0.1-0.2,0.3-0.7,0.6-1.4c0-0.1,0.1-0.3,0.2-0.5l-4.3-11.4h2.1l2.4,6.6c0.3,0.8,0.6,1.7,0.8,2.6c0.2-0.9,0.5-1.8,0.8-2.6l2.4-6.6 h1.9l-4.3,11.6c-0.5,1.3-0.8,2.1-1.1,2.6c-0.3,0.6-0.7,1.1-1.2,1.4c-0.4,0.3-1,0.4-1.6,0.4C172.2,118.9,171.8,118.8,171.3,118.7z'
          />
          <path
            className='st2'
            d='M182.3,118.7l-0.2-1.8c0.4,0.1,0.8,0.2,1.1,0.2c0.4,0,0.8-0.1,1-0.2c0.3-0.1,0.5-0.3,0.6-0.6 c0.1-0.2,0.3-0.7,0.6-1.4c0-0.1,0.1-0.3,0.2-0.5l-4.3-11.4h2.1l2.4,6.6c0.3,0.8,0.6,1.7,0.8,2.6c0.2-0.9,0.5-1.8,0.8-2.6l2.4-6.6 h1.9l-4.3,11.6c-0.5,1.3-0.8,2.1-1.1,2.6c-0.3,0.6-0.7,1.1-1.2,1.4c-0.4,0.3-1,0.4-1.6,0.4C183.2,118.9,182.8,118.8,182.3,118.7z'
          />
          <path
            className='st2'
            d='M193.3,118.7l-0.2-1.8c0.4,0.1,0.8,0.2,1.1,0.2c0.4,0,0.8-0.1,1-0.2c0.3-0.1,0.5-0.3,0.6-0.6 c0.1-0.2,0.3-0.7,0.6-1.4c0-0.1,0.1-0.3,0.2-0.5l-4.3-11.4h2.1l2.4,6.6c0.3,0.8,0.6,1.7,0.8,2.6c0.2-0.9,0.5-1.8,0.8-2.6l2.4-6.6 h1.9l-4.3,11.6c-0.5,1.3-0.8,2.1-1.1,2.6c-0.3,0.6-0.7,1.1-1.2,1.4c-0.4,0.3-1,0.4-1.6,0.4C194.2,118.9,193.8,118.8,193.3,118.7z'
          />
          <path
            className='st2'
            d='M204.3,118.7l-0.2-1.8c0.4,0.1,0.8,0.2,1.1,0.2c0.4,0,0.8-0.1,1-0.2c0.3-0.1,0.5-0.3,0.6-0.6 c0.1-0.2,0.3-0.7,0.6-1.4c0-0.1,0.1-0.3,0.2-0.5l-4.3-11.4h2.1l2.4,6.6c0.3,0.8,0.6,1.7,0.8,2.6c0.2-0.9,0.5-1.8,0.8-2.6l2.4-6.6 h1.9l-4.3,11.6c-0.5,1.3-0.8,2.1-1.1,2.6c-0.3,0.6-0.7,1.1-1.2,1.4c-0.4,0.3-1,0.4-1.6,0.4C205.2,118.9,204.8,118.8,204.3,118.7z'
          />
        </g>
        <path
          className='st3'
          d='M218.5,137H50.2c-8.6,0-15.7-7.1-15.7-15.7V29.9c0-8.7,7-15.7,15.7-15.7h168.3c8.6,0,15.7,7.1,15.7,15.7v91.4 C234.1,129.9,227.1,137,218.5,137z M50.2,17.2c-7,0-12.7,5.7-12.7,12.7v91.4c0,7,5.7,12.7,12.7,12.7h168.3c7,0,12.7-5.7,12.7-12.7 V29.9c0-7-5.7-12.7-12.7-12.7H50.2z'
        />
      </g>
      <path
        className='st4'
        d='M60,57.2H35.4c-4,0-7.2,3.3-7.2,7.4v10.1h-8.1l0-7.9l-9.8,9.8l9.8,9.8l0-7.9h8.1v10.1c0,4.1,3.2,7.4,7.2,7.4H60 c4,0,7.2-3.3,7.2-7.4v-24C67.2,60.5,64,57.2,60,57.2z M64.2,88.6c0,2.4-1.9,4.4-4.2,4.4H35.4c-2.3,0-4.2-2-4.2-4.4v-24 c0-2.4,1.9-4.4,4.2-4.4H60c2.3,0,4.2,2,4.2,4.4L64.2,88.6L64.2,88.6z'
      />
    </MedicareIconStyled>
  )
}
