import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { ButtonStyled } from '@mobi/component-library/Common/Buttons'

export const AccountPromoWrapperStyled = styled('div')({
  marginBottom: '1.5rem',
  fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
})

export const AccountPromoButtonRowStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',

  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '2.1rem',
  },

  '& div:first-of-type': {
    marginLeft: '0px',
  },
})

export const AccountPromoLabelStyled = styled('span')({
  position: 'relative',
  zIndex: 1,
  display: 'block',
  marginTop: 'auto',
  padding: 0,
  fontSize: '1.2rem',
  lineHeight: 1.2,
})

export const AccountPromoButtonStyled = styled(ButtonStyled)<{ shouldDisable: boolean }>(
  {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    minHeight: '5.8rem',
    width: '100%',
    padding: '0.1rem 0.5rem',
    border: 0,
    margin: 0,
    background: 'linear-gradient(125deg, rgba(81,45,109,1) 65%, rgba(130,70,175,1) 65%);',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '0.4rem',
    color: hexColors.white,
    fontSize: '1rem',
    fontWeight: '500',
  },
  ({ shouldDisable = false }) => ({
    pointerEvents: shouldDisable ? 'none' : undefined,
    boxShadow: shouldDisable ? 'none' : '0 1px 1px rgba(0,0,0,0.1)',
  })
)

export const AccountPromoLinkStyled = AccountPromoButtonStyled.withComponent('a')

export const AccountPromoCountStyled = styled('span')<{ shouldDisable: boolean }>({
  position: 'absolute',
  zIndex: 0,
  top: '0.8rem',
  left: '0.8rem',
  display: 'flex',
  justifyContent: 'flex-start',
  color: hexColors.white,
  WebkitTransform: 'translate3d(0, 0, 0)',

  // Number
  '& > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.6rem',
    fontWeight: '700',
  },
})

export const AccountSuperPickBadgeStyled = styled('span')({
  display: 'inline-block',
  marginLeft: 'auto',
  width: '2rem',
  minWidth: '2rem',
  height: '2rem',
  borderRadius: '50%',
  fontSize: '1.2rem',
  lineHeight: '2rem',
  textAlign: 'center',
  backgroundColor: hexColors.blueDiamond,
  color: hexColors.white,
})

export const AccountPromoSuperpicksWrapStyled = styled('div')({
  height: 0,
  overflow: 'hidden',
})

export const AccountPromoSuperpicksListStyled = styled('ul')({
  position: 'relative',
  margin: 0,
  marginTop: '1rem',
  padding: '0 1rem',
  listStyle: 'none',
  border: `1px solid ${hexColors.veryLightGrey}`,
  background: hexColors.white,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-0.6rem',
    left: '50%',
    width: 0,
    height: 0,
    transform: 'translateX(-0.3rem)',
    borderLeft: '0.6rem solid transparent',
    borderRight: '0.6rem solid transparent',
    borderBottom: `0.6rem solid ${hexColors.veryLightGrey}`,
  },
})

export const AccountPromoSuperpicksItemStyled = styled('li')({
  borderBottom: `1px solid ${hexColors.gainsboro}`,

  '&:last-of-type': {
    border: 0,
  },
})

export const AccountPromoSuperpicksButtonStyled = styled('button')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '1rem 1.5rem 1rem 0',
  fontSize: '1.2rem',
  textTransform: 'uppercase',
  textAlign: 'left',
  border: 0,
  color: hexColors.nero,
  background: 'none',

  '& > span': {
    marginLeft: 'auto',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'rotate(-45deg) translateY(-50%)',
    borderRight: `2px solid ${hexColors.lola}`,
    borderBottom: `2px solid ${hexColors.lola}`,
    width: '0.5rem',
    height: '0.5rem',
  },
})
