import produce from 'immer'
import { ToteRaceStatusChangedPushEvent } from '@core/State/PushData'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'

interface PushRaceClosedEvent {
  event: ToteRaceStatusChangedPushEvent
  meetingId: string
  meetingDate: Date
  raceNumber: number
}

export function pushToteRaceStatusChanged({
  event,
  meetingId,
  meetingDate,
  raceNumber,
}: PushRaceClosedEvent) {
  if (event.status !== 'closed') return
  const queryKey = queryKeys.racePageComplete(meetingId, meetingDate, raceNumber)
  queryClient.setQueryData<RacePageDTO | undefined>(queryKey, updateDataWithNewStatus)
}

const updateDataWithNewStatus = (current: RacePageDTO | undefined) => {
  // No data associated with key
  if (!current) return undefined
  // Only update open races
  if (!isRacePageDto(current)) return undefined

  return produce(current, draft => {
    draft.MeetingInformation.SelectedRace.RaceStatus = 'Closed'
    draft.MeetingInformation.SelectedRace.RaceStatusText = 'Closed'
  })
}
