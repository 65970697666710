import React from 'react'
import { useFeature } from '@core/Utils/hooks'
import { get } from '@classic/Foundation/Services/ApiService'
import { Tabs } from '@core/Areas/AccountActivity/types'

interface RebetTicketsCountResponse {
  rebetTicketsCount: number
}

interface RebetCount {
  isReBetFeatureActive: boolean
  hasRebetBadge: boolean
  isRebetCountMax: boolean
  rebetTicketsCount: number | undefined
  fetchRebetCount: () => void
}

export const useRebetBetCount = (activeTab: Tabs): RebetCount => {
  const isReBetFeatureActive = useFeature('REBET_TAB')
  const [rebetTicketsCount, setRebetTicketsCount] = React.useState(0)
  const isRebetCountMax = !!rebetTicketsCount && rebetTicketsCount >= 99
  const hasRebetBadge = !!rebetTicketsCount && rebetTicketsCount > 0

  const fetchRebetCount = React.useCallback(() => {
    if (isReBetFeatureActive)
      getRebetTicketsCount()
        .then(res => setRebetTicketsCount(res))
        .catch(() => setRebetTicketsCount(0))
  }, [isReBetFeatureActive])

  React.useEffect(() => {
    fetchRebetCount()
  }, [activeTab, fetchRebetCount])

  return {
    isReBetFeatureActive,
    hasRebetBadge,
    isRebetCountMax,
    rebetTicketsCount,
    fetchRebetCount,
  }
}

const getRebetTicketsCount = async (): Promise<number> => {
  let count = 0

  await get<RebetTicketsCountResponse>({ url: '/$_/accountActivity/tickets/rebet/count' }).then(
    res => {
      if (res && res.rebetTicketsCount) {
        count = res.rebetTicketsCount
      }
    }
  )

  return count
}
