import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export enum SearchTextLabelFontSize {
  Small,
  Medium,
  Large,
}

interface SearchTextLabelStyledProps {
  size?: SearchTextLabelFontSize
}

export const SearchTextLabelStyled = styled('span')<SearchTextLabelStyledProps>(
  ({ size }: SearchTextLabelStyledProps) => ({
    fontSize: getTextFontSize(size),
    color: hexColors.nero,
    textAlign: 'center',
    display: 'block',
    margin: '1rem 0 0.25rem 0',
  })
)

export const SearchTextLabelLinkStyled = styled('a')<SearchTextLabelStyledProps>(
  ({ size }: SearchTextLabelStyledProps) => ({
    fontSize: getTextFontSize(size),
    color: hexColors.studio,
    textAlign: 'center',
    display: 'block',
    margin: '1rem 0 0.25rem 0',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  })
)

function getTextFontSize(size?: SearchTextLabelFontSize): string {
  let sizeString: string
  switch (size) {
    case SearchTextLabelFontSize.Large:
      sizeString = '1.8rem'
      break
    case SearchTextLabelFontSize.Small:
      sizeString = '1.2rem'
      break
    default:
      sizeString = '1.6rem'
  }
  return sizeString
}
