export function hasValue(obj: unknown): obj is number {
  // handle casting null or empty string that returns zero value
  if (obj === null || obj === '') {
    return false
  }

  const numberValue = Number(obj)
  return (
    numberValue !== undefined &&
    numberValue !== null &&
    !isNaN(numberValue) &&
    typeof numberValue === 'number'
  )
}
