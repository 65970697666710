import React from 'react'
import { BetAccountRepo, BetAccountKey } from '@core/Data/Account/betAccount'
import {
  ActivitySummaryPopup,
  ActivitySummaryPopupSuccess,
  ActivitySummaryPopupCancellation,
  ErrorPopupNoticeBox,
} from './ActivitySummaryPopup'
import { PopupContainerStyled } from '@core/Components/Popup/Popup.styles'
import { state$ as UserAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { ActivitySummaryPopupStyled, PopupTitleStyled } from './ActivitySummary.styles'
import { post, get } from '@classic/Foundation/Services/ApiService'
import { hasLoadedWithoutError } from 'rwwa-data-access'

enum DisplayState {
  HideAll,
  Prompt,
  Success,
  Cancellation,
  Error,
}

interface ActivitySummaryState {
  displayState: DisplayState
  betAccountNumber: number | null
  checkResponse: CheckResponse | null
  preferredName: string
}

interface CheckResponse {
  answerSought: boolean
  statementStartDate: Date
  statementEndDate: Date
}

let cache = new Map<number, CheckResponse>()

export class ActivitySummary extends React.Component<
  { isDesktop?: boolean },
  ActivitySummaryState
> {
  private subscription: Rx.Disposable | undefined

  constructor(props: { isDesktop?: boolean }) {
    super(props)
    this.state = {
      displayState: DisplayState.HideAll,
      betAccountNumber: null,
      preferredName: '',
      checkResponse: null,
    }
  }

  public async componentDidMount(): Promise<void> {
    this.subscription = UserAccountState$.filter(state => !!(state && state.isLoggedIn))
      .distinctUntilChanged(state => state.isLoggedIn)
      .delay(1000)
      .subscribe(async () => {
        const betAccount = await BetAccountRepo.getPromise(BetAccountKey)
        if (hasLoadedWithoutError(betAccount) && betAccount.BetAccountNumber) {
          if (await this.check(betAccount.BetAccountNumber)) {
            this.setState({
              displayState: DisplayState.Prompt,
              betAccountNumber: betAccount.BetAccountNumber,
              checkResponse: cache.get(betAccount.BetAccountNumber) || null,
              preferredName:
                betAccount.PreferredName ??
                betAccount.AccountHolderList[0]?.FullName ??
                betAccount.AccountHolderList[1]?.FullName ??
                '',
            })
          }
        }
      })
  }

  public componentWillUnmount(): void {
    this.subscription?.dispose()
  }

  private renderPopup = () => {
    switch (this.state.displayState) {
      case DisplayState.Prompt:
        return (
          <ActivitySummaryPopup
            isDesktop={this.props.isDesktop}
            preferredName={this.state.preferredName}
            onSuccess={this.onSuccess}
            onCancellation={this.onCancellation}
          />
        )
      case DisplayState.Success:
        return (
          <ActivitySummaryPopupSuccess isDesktop={this.props.isDesktop} onClose={this.closeAll} />
        )
      case DisplayState.Cancellation:
        return (
          <ActivitySummaryPopupCancellation
            isDesktop={this.props.isDesktop}
            onClose={this.closeAll}
          />
        )
      case DisplayState.Error:
        return <ErrorPopupNoticeBox isDesktop={this.props.isDesktop} onClose={this.closeAll} />
      default:
        return null
    }
  }

  public render(): React.ReactElement | null {
    if (this.state.displayState === DisplayState.HideAll) {
      return null
    }

    return (
      <PopupContainerStyled data-tid-activity-summary=''>
        <ActivitySummaryPopupStyled isDesktop={this.props.isDesktop}>
          <PopupTitleStyled isDesktop={this.props.isDesktop}>
            <span>Activity Summary</span>
          </PopupTitleStyled>
          {this.renderPopup()}
        </ActivitySummaryPopupStyled>
      </PopupContainerStyled>
    )
  }

  private closeAll = () => {
    this.setState({ ...this.state, displayState: DisplayState.HideAll })
  }

  private onSuccess = async () => {
    if (!this.state.checkResponse) return
    const success = await this.postAnswer(
      this.state.betAccountNumber as number,
      true,
      this.state.checkResponse.statementStartDate,
      this.state.checkResponse.statementEndDate
    )
    if (success) {
      this.updateCache(this.state.betAccountNumber as number, false)
      this.setState({ ...this.state, displayState: DisplayState.Success })
    } else {
      this.setState({ ...this.state, displayState: DisplayState.Error })
    }
  }

  private onCancellation = async () => {
    if (!this.state.checkResponse) return
    const success = await this.postAnswer(
      this.state.betAccountNumber as number,
      false,
      this.state.checkResponse.statementStartDate,
      this.state.checkResponse.statementEndDate
    )
    if (success) {
      this.updateCache(this.state.betAccountNumber as number, false)
      this.setState({ ...this.state, displayState: DisplayState.Cancellation })
    } else {
      this.setState({ ...this.state, displayState: DisplayState.Error })
    }
  }

  private updateCache = (betAccountNumber: number, answerSought: boolean) => {
    const value = cache.get(betAccountNumber)

    if (value !== undefined) {
      value.answerSought = answerSought
      cache.set(betAccountNumber, value)
    }
  }

  private check = (betAccountNumber: number): Promise<boolean> => {
    const cached = cache.get(betAccountNumber)
    if (cached) {
      return Promise.resolve(cached.answerSought)
    }

    return get<CheckResponse>({
      url: `/api/activity-statements/check?accountNumber=${betAccountNumber}`,
    })
      .then(response => {
        const answerSought = response.answerSought || false
        cache.set(betAccountNumber, response)
        return answerSought
      })
      .catch(() => false)
  }

  private postAnswerToCrm = (
    betAccountNumber: number,
    statementStartDate: Date,
    statementEndDate: Date,
    receiveStatement: boolean
  ): Promise<boolean> => {
    return post<{}>({
      url: '/$_/api/account/answerActivityStatementPrompt',
      body: {
        Answer: {
          BetAccountNumber: betAccountNumber,
          StatementStartDate: statementStartDate,
          StatementEndDate: statementEndDate,
          RequestFlag: receiveStatement,
        },
      },
    })
      .then(() => true)
      .catch(() => false)
  }

  private postAnswer = (
    betAccountNumber: number,
    receiveStatement: boolean,
    statementStartDate: Date,
    statementEndDate: Date
  ): Promise<boolean> => {
    return this.postAnswerToCrm(
      betAccountNumber,
      statementStartDate,
      statementEndDate,
      receiveStatement
    ).then(result => {
      if (result) {
        return post<{}>({
          url: '/api/activity-statements/answer',
          body: {
            accountNumber: `${betAccountNumber}`,
            receiveStatement,
          },
        })
          .then(() => true)
          .catch(() => false)
      } else {
        return Promise.resolve(false)
      }
    })
  }
}
