import React, { type FormEvent, type InputHTMLAttributes } from 'react'
import { RadioInputStyled, RadioLabelStyled } from './RadioInput.styled'

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | 'id' | 'onChange'>

type Props = InputProps & {
  label: string
  name: string
  id: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
}

export const RadioInput = ({ label, ...inputProps }: Props) => {
  return (
    <div>
      <RadioInputStyled {...inputProps} type='radio' />
      <RadioLabelStyled htmlFor={inputProps.id}>{label}</RadioLabelStyled>
    </div>
  )
}
