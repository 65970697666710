import type { MeetingsAPIResponse } from '@core/Areas/Racing/Hooks/useMeetings/types'
import {
  CountryIsoCode,
  CountryIsoCodeOrder,
  StateOrder,
  RaceCourseClassCodeOrder,
} from './constants'

const enum LocalConstants {
  OtherState = 'OTH',
}

export function sortMeetings<T extends Meeting>(meetings: T[]): SortedMeetings<T> {
  const australianUnsorted = filterAustralianMeetings<T>(meetings)
  const internationalUnsorted = filterInternationalMeetings<T>(meetings)
  return {
    AustralianMeetings: sortAustralianMeetings<T>(australianUnsorted),
    InternationalMeetings: sortInternationalMeetings<T>(internationalUnsorted),
  }
}

export function sortMeetingOnName({ name: name1 }: Meeting, { name: name2 }: Meeting): number {
  return name1.toLocaleUpperCase().localeCompare(name2.toLocaleUpperCase())
}

// =============
// Local Helpers
// =============

function isInternational(meeting: Meeting): boolean {
  return meeting.isoCountryCode !== CountryIsoCode.Australia
}

function filterAustralianMeetings<T extends Meeting>(meetings: T[]): T[] {
  return meetings.filter(meeting => !isInternational(meeting))
}

function filterInternationalMeetings<T extends Meeting>(meetings: T[]): T[] {
  return meetings.filter(meeting => isInternational(meeting))
}

function sortAustralianMeetings<T extends Meeting>(meetings: T[]): T[] {
  const sortedMeetings: Meeting[] = meetings.sort(compareAustralianMeeting)
  const groupedData = reduceSortedArray(sortedMeetings)
  const results = orderGroupedAustralianMeetings(groupedData)

  return results as T[]
}

function sortInternationalMeetings<T extends Meeting>(meetings: T[]): T[] {
  return meetings.sort(compareInternationalMeeting)
}

function reduceSortedArray(meetings: Meeting[]): StateMeeting {
  const groups: StateMeeting = new Map<string, Meeting[]>()
  const groupedData = meetings.reduce(reduceMeetingToStates, groups)

  return groupedData
}

function reduceMeetingToStates(map: StateMeeting, meeting: Meeting): StateMeeting {
  const key =
    StateOrder.indexOf(meeting.geoLocationCode) === -1
      ? LocalConstants.OtherState
      : meeting.geoLocationCode

  if (!map.has(key)) {
    map.set(key, [meeting])
  } else {
    const value = map.get(key)
    value ? value.push(meeting) : map.set(key, [meeting]) // Handle scenario just in case
  }
  return map
}

function orderGroupedAustralianMeetings(groupedData: StateMeeting): Meeting[] {
  let meeting: Meeting | undefined
  let result: Meeting[] = []
  let groupedMeetings: Meeting[] | undefined

  //  If the requirement to put the user's home state first ever eventuates, take the user's first meeting out of their home state and insert it into homeStateFirstMeeting
  //  Store the remainder in homeStateOtherMeetings. The groupedData entry for the home state will then be empty.
  //  The algorithm has been templated with empty values, so this won't occur actually at this time.

  const homeStateFirstMeeting: Meeting | undefined = undefined
  const homeStateOtherMeetings: Meeting[] = []

  if (homeStateFirstMeeting) {
    result.push(homeStateFirstMeeting)
  }

  for (const state of StateOrder) {
    groupedMeetings = groupedData.get(state)

    if (groupedMeetings && groupedMeetings.length > 0) {
      meeting = groupedMeetings.shift()
      if (meeting) {
        result.push(meeting)
      }
    }
  }

  if (homeStateOtherMeetings && homeStateOtherMeetings.length > 0) {
    result = result.concat(homeStateOtherMeetings)
  }

  for (const state of StateOrder) {
    groupedMeetings = groupedData.get(state)

    if (groupedMeetings && groupedMeetings.length > 0) {
      result = result.concat(groupedMeetings)
    }
  }

  // Now handle other
  groupedMeetings = groupedData.get(LocalConstants.OtherState)
  if (groupedMeetings && groupedMeetings.length > 0) {
    result = result.concat(groupedMeetings)
  }

  return result
}

function compareAustralianMeeting(m1: Meeting, m2: Meeting): number {
  const firstState = StateOrder.indexOf(m1.geoLocationCode)
  const secondState = StateOrder.indexOf(m2.geoLocationCode)

  if (firstState < secondState) {
    return -1
  } else if (firstState > secondState) {
    return 1
  }

  const firstClass = RaceCourseClassCodeOrder.indexOf(m1.raceCourseClassCode)
  const secondClass = RaceCourseClassCodeOrder.indexOf(m2.raceCourseClassCode)

  if (firstClass < secondClass) {
    return -1
  } else if (firstClass > secondClass) {
    return 1
  }
  return sortMeetingOnName(m1, m2)
}

function compareInternationalGeoLocationAndName(m1: Meeting, m2: Meeting): number {
  if (m1.geoLocationCode?.toUpperCase() < m2.geoLocationCode?.toUpperCase()) return -1
  if (m1.geoLocationCode > m2.geoLocationCode) return 1
  return sortMeetingOnName(m1, m2)
}

function compareInternationalMeeting(m1: Meeting, m2: Meeting): number {
  const first = getIsoCountryCodeOrdinal(m1.isoCountryCode)
  const second = getIsoCountryCodeOrdinal(m2.isoCountryCode)

  if (first === -1 && second === -1) {
    return compareInternationalGeoLocationAndName(m1, m2)
  } else if (first === -1) {
    return 1
  } else if (second === -1) {
    return -1
  } else if (first < second) {
    return -1
  } else if (first > second) {
    return 1
  } else {
    return compareInternationalGeoLocationAndName(m1, m2)
  }
}

function getIsoCountryCodeOrdinal(isoCode: string | null): number {
  if (isoCode === null) return -1
  return CountryIsoCodeOrder.indexOf(isoCode)
}

// =====
// Types
// =====

interface SortedMeetings<T extends Meeting> {
  AustralianMeetings: T[]
  InternationalMeetings: T[]
}

type StateMeeting = Map<string, Meeting[]>

type Meeting = MeetingsAPIResponse[0]['meetings'][0]
