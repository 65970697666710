import * as ko from 'knockout'
import { inject, injectable } from 'inversify'
import { FeatureFlags } from '@mobi/settings'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import Guard from '@classic/AppUtils/Framework/Guard'
import { FixedFlucs } from '@classic/Betting-v2/Components/Panels/FixedFlucs/FixedFlucs'
import { MarketMovers } from '@classic/Betting-v2/Components/Panels/MarketMovers/MarketMovers'
import { RunnerExpanded } from '@core/Areas/RaceCard/Components'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import { state$ as bettingV2Driver$ } from '@classic/Betting-v2/Components/Commands/driver'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

@injectable()
export default class BaseStarterViewModel extends Disposable {
  public shouldDisplayFavouriteInStarterName!: ko.Observable<boolean>
  public shouldDisplaySelectionsStacked!: ko.Observable<boolean>
  public fixedFlucs!: typeof FixedFlucs | null
  public marketMovers!: typeof MarketMovers | null
  public RunnerExpanded: React.ReactNode
  public ldSubscription!: Rx.IDisposable
  public isFieldSummaryV2Active: ko.Observable<boolean> = ko.observable(false)
  public isRunnerExpanded: ko.Observable<boolean> = ko.observable(false)
  public isExpandable = ko.observable(true)

  public starter!: IObservableStarter
  public raceNumber!: ObservableRaceKey
  public bettingContext!: BettingInformation
  public tag!: string
  public numberOfStartersInRace!: ko.Computed<number>
  public doubleProvDivAllowed!: ko.Observable<boolean>
  public quaddieProvDivAllowed!: ko.Observable<boolean>
  public isRaceClosed!: ko.Observable<boolean>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.eventAggregator = eventAggregator

    this.RunnerExpanded = RunnerExpanded
  }

  public initBase(
    starter: IObservableStarter,
    raceNumber: ObservableRaceKey,
    bettingContext: BettingInformation,
    tag: string,
    numberOfStartersInRace: ko.Computed<number>,
    doubleProvDivAllowed: ko.Observable<boolean>,
    quaddieProvDivAllowed: ko.Observable<boolean>,
    isRaceClosed: ko.Observable<boolean>
  ) {
    Guard.notNull(starter)
    Guard.notNull(raceNumber)
    Guard.notNull(bettingContext)
    Guard.stringNotNullOrEmpty(tag)

    this.starter = starter
    this.raceNumber = raceNumber
    this.bettingContext = bettingContext
    this.tag = tag
    this.numberOfStartersInRace = numberOfStartersInRace
    this.doubleProvDivAllowed = doubleProvDivAllowed
    this.quaddieProvDivAllowed = quaddieProvDivAllowed
    this.isRaceClosed = isRaceClosed
    this.ldSubscription = launchDarkState$.subscribe(record => {
      const fieldSummaryV2 = record.features.get(FeatureFlags.FIELD_SUMMARY_V2.key)
      if (fieldSummaryV2 !== this.isFieldSummaryV2Active()) {
        this.isFieldSummaryV2Active(fieldSummaryV2)
      }
    })

    if (this.starter.name() === '*** VACANT BOX ***') {
      this.isExpandable(false)
    }

    // consumers shouldn't care if it's tote or fixed odds favourite, since this is an implementation detail based on the selected bet type context
    this.shouldDisplayFavouriteInStarterName = ko.observable(
      // novelty favourite
      (this.starter.isFavourite() &&
        !this.bettingContext.selectedBetType().isWinPlace() &&
        !this.bettingContext.selectedBetType().isSameRaceMulti()) ||
        // SRM favourite
        (this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.isFavourite() === true &&
          this.bettingContext.selectedBetType().isSameRaceMulti())
    )

    // novelty favourite
    this.starter.isFavourite.subscribe(val => {
      if (
        !this.bettingContext.selectedBetType().isWinPlace() &&
        !this.bettingContext.selectedBetType().isSameRaceMulti()
      )
        this.shouldDisplayFavouriteInStarterName(val)
    })

    // SRM favourite
    this.starter.fixedOddsStarterInfo.sameRaceMultiPrices.isFavourite.subscribe(val => {
      if (this.bettingContext.selectedBetType().isSameRaceMulti())
        this.shouldDisplayFavouriteInStarterName(val === true)
    })

    this.shouldDisplaySelectionsStacked = ko.observable(
      this.bettingContext.selectedBetType().isSameRaceMulti()
    )

    this.fixedFlucs = !isRaceClosed() ? FixedFlucs : null
    this.marketMovers = !isRaceClosed() ? MarketMovers : null

    this.safeSubscribe(
      'all-form-selected-command',
      (arg: { raceNumber: number; selected: boolean }) => {
        if (this.raceNumber.raceNumber() !== arg.raceNumber) return

        this.isRunnerExpanded(arg.selected)
      }
    )

    this.safeSubscribe(
      'show-formdata-command',
      (command: { raceNumber: number; starterNumber: number }) => {
        if (this.raceNumber.raceNumber() !== command.raceNumber) return
        if (this.starter.number() !== command.starterNumber) return

        this.isRunnerExpanded(!this.isRunnerExpanded())
      }
    )

    bettingV2Driver$.take(1).subscribe(state => {
      this.isRunnerExpanded(state.showAllForm)
    })

    this.configureDisposal()
  }

  public showFormData() {
    if (!this.isRunnerExpanded())
      trackEvent(analyticsKeys.formOpened, {
        meetingName: this.bettingContext.meetingInformation?.meetingName(),
        meetingCode: this.tag.toLowerCase(),
      })
    else trackKey(analyticsKeys.formClosed)

    this.eventAggregator.publish('show-formdata-command', {
      raceNumber: this.raceNumber.raceNumber(),
      starterNumber: this.starter.number(),
    })
  }

  private configureDisposal() {
    this.registerDisposals(() => {
      this.ldSubscription && this.ldSubscription.dispose()
    })
  }

  private eventAggregator: IEventAggregator
}
