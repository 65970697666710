import React from 'react'
import dayjs from 'dayjs'
import { TimeToJumpAbsoluteTimeStyled, TimeToJumpDayFlagStyled } from './TimeToJump.style'
import { RaceCountdown } from '@core/Components/RaceCountdown'

interface AstTimeComponentProps {
  size?: string
  advertisedStartTime: Date
  showDay: boolean
}

interface TimeToJumpComponentProps {
  size?: string
  displaySize?: string
  showDay: boolean
  showCountDown: boolean
  advertisedStartTime: Date
  showDayBeforeTime?: boolean
}

export function TimeToJumpComponent({
  showDay,
  showCountDown,
  advertisedStartTime,
  size,
  showDayBeforeTime = false,
}: TimeToJumpComponentProps): JSX.Element {
  if (showCountDown) return <RaceCountdown raceStartTime={advertisedStartTime} displaySize={size} />

  if (showDayBeforeTime)
    return (
      <AstDayThenTimeComponent
        advertisedStartTime={advertisedStartTime}
        showDay={showDay}
        size={size}
      />
    )

  return (
    <AstTimeComponent advertisedStartTime={advertisedStartTime} showDay={showDay} size={size} />
  )
}

export function AstTimeComponent({
  advertisedStartTime,
  showDay,
  size,
}: AstTimeComponentProps): JSX.Element {
  return (
    <TimeToJumpAbsoluteTimeStyled size={size || '1.2rem'} data-testid='time-ast'>
      <div>{dayjs(advertisedStartTime).format('HH:mm')}</div>
      {showDay && (
        <TimeToJumpDayFlagStyled>
          {dayjs(advertisedStartTime).format('ddd')}
        </TimeToJumpDayFlagStyled>
      )}
    </TimeToJumpAbsoluteTimeStyled>
  )
}

function AstDayThenTimeComponent({
  advertisedStartTime,
  showDay,
  size,
}: AstTimeComponentProps): JSX.Element {
  size ||= '1.2rem'
  return (
    <TimeToJumpAbsoluteTimeStyled size={size}>
      {showDay && (
        <TimeToJumpDayFlagStyled size={size}>
          {dayjs(advertisedStartTime).format('ddd')}
        </TimeToJumpDayFlagStyled>
      )}
      <div>{dayjs(advertisedStartTime).format('HH:mm')}</div>
    </TimeToJumpAbsoluteTimeStyled>
  )
}
