import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableProvDivPool from '@classic/Betting-v2/Model/Observables/ObservableProvDivPool'
import { state$ as bettingV2CommandDriver$ } from '../../Commands/driver'

interface ProvDivsViewModelParams {
  provDivPools: ko.ObservableArray<ObservableProvDivPool>
  doubleProvDivAllowed: ko.Observable<boolean>
  quaddieProvDivAllowed: ko.Observable<boolean>
}

@injectable()
export class ProvDivsViewModel extends Disposable {
  public doubleProvDivAllowed: ko.Observable<boolean>
  public quaddieProvDivAllowed: ko.Observable<boolean>
  public isDoubleSelected: ko.Observable<boolean>
  public isQuaddieSelected: ko.Observable<boolean>
  private isDoubleVisible: ko.PureComputed<boolean>
  private isQuaddieVisible: ko.PureComputed<boolean>
  private doubleProvDivSub: Rx.Disposable
  private quaddieProvDivSub: Rx.Disposable

  public pools: ko.ObservableArray<ObservableProvDivPool>

  constructor(
    @inject('$params') params: ProvDivsViewModelParams,
    @inject('IEventAggregator') eventAggregator: IEventAggregator
  ) {
    super(eventAggregator)

    this.pools = params.provDivPools
    this.doubleProvDivAllowed = params.doubleProvDivAllowed
    this.quaddieProvDivAllowed = params.quaddieProvDivAllowed

    this.isDoubleSelected = ko.observable(false)
    this.isQuaddieSelected = ko.observable(false)

    this.isDoubleVisible = ko.pureComputed(() => {
      return this.isDoubleSelected() && this.doubleProvDivAllowed()
    })

    this.isQuaddieVisible = ko.pureComputed(() => {
      return this.isQuaddieSelected() && this.quaddieProvDivAllowed()
    })

    this.doubleProvDivSub = bettingV2CommandDriver$
      .map(record => {
        return {
          selected: record.showDoubleProvDiv,
        }
      })
      .distinctUntilChanged()
      .subscribe(result => {
        //  This is where the subscription to the driver state is resolved. Put logic here, not in toggleFixedFlucs.
        this.isDoubleSelected(result.selected)
        //this.evtAggregator.publish("toggle-double-prov-div", result.selected);
      })

    this.quaddieProvDivSub = bettingV2CommandDriver$
      .map(record => {
        return {
          selected: record.showQuaddieProvDiv,
        }
      })
      .distinctUntilChanged()
      .subscribe(result => {
        //  This is where the subscription to the driver state is resolved. Put logic here, not in toggleFixedFlucs.
        this.isQuaddieSelected(result.selected)
        // this.evtAggregator.publish("toggle-quaddie-prov-div", result.selected);
      })

    this.configureDisposal()
  }

  private configureDisposal() {
    this.registerDisposals(() => {
      this.doubleProvDivSub.dispose()
      this.quaddieProvDivSub.dispose()
    })
  }

  public isVisible(pool: { name: string }): boolean {
    switch (pool.name) {
      case 'Double':
        return this.isDoubleVisible()
      case 'Quaddie':
        return this.isQuaddieVisible()
      default:
        // eslint-disable-next-line no-console
        console.warn('Prov div pool has no name')
        return false
    }
  }
}
