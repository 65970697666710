import { init, setContext, setUser } from '@sentry/react'
import { AppVersion } from '@mobi/web-native-comms/web'

export function initializeSentry(url: string, environment: string): void {
  init({
    dsn: url,
    environment,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click' && hint !== undefined) {
        const { target } = hint.event
        const node = Object.values(target.attributes as NamedNodeMap)
        const data_tid = node.find(attr => attr.nodeName.startsWith('data-tid'))
        if (data_tid) {
          breadcrumb.message = `${data_tid.nodeName} = ${data_tid.value}` //breadcrumb ui clicks as their data-tids
        }
      }
      return breadcrumb
    },
    beforeSend(event, hint) {
      if (shouldIgnoreError(hint.originalException)) {
        return null
      }
      return event
    },
  })

  setContext('Application version', {
    'Client version': Number.parseFloat(`${VERSION_MAJOR}.${VERSION_MINOR}`),
    'Native app version': AppVersion || 0,
  })
}

export const logUserId = (accountNumber: number | undefined) => {
  setUser(
    accountNumber
      ? {
          id: accountNumber,
        }
      : null
  )
}

function shouldIgnoreError(error: unknown) {
  if (!error || typeof error !== 'string') {
    return false
  }

  // Ignore Kambi errors
  if (error.match(/settings-api.kambicdn.com/i) || error.match(/client-static.bc.kambicdn.com/i)) {
    return false
  }

  // Ignore errors caused by `window.localStorage` accesses by 3rd parties in WebKit/Safari
  if (error.toLowerCase() === "null is not an object (evaluating 'window.localStorage.getItem')") {
    return false
  }

  return true
}
