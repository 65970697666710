import { BettingInformation } from '../../../Model/BettingInformation'
import * as buttons from '../../Core/UIElements/ButtonsSelection'
import { ButtonSelectionType } from '../../Core/UIElements/ButtonSelectionType'
import { FobSelectionResult } from '../../Core/SelectionResults/FobSelectionResult'
import { ISelectionResultProcessor } from '../../Core/SelectionResults/ISelectionResultProcessor'
import WinPlaceSelectionResultProcessor from '../../Core/SelectionResults/WinPlaceSelectionResultProcessor'
import ObservableMeetingInformation from '@classic/Betting-v2/Model/Observables/ObservableMeetingInformation'

export class WinPlaceSelectionsViewModel {
  public raceNumber: number
  public bettingContext: BettingInformation
  public isFixed: boolean
  public selectionsString!: string
  public winPrice: string
  public placePrice: string
  public winPlaceSelectionResultProcessor: ISelectionResultProcessor
  public hasFixedPlacePool: boolean

  constructor(params: { bettingContext: BettingInformation }) {
    this.bettingContext = params.bettingContext
    this.raceNumber = this.bettingContext.raceNumber
    this.isFixed = this.bettingContext.isFixed()
    this.winPrice = ''
    this.placePrice = ''
    this.winPlaceSelectionResultProcessor = new WinPlaceSelectionResultProcessor()
    this.hasFixedPlacePool =
      (
        this.bettingContext.meetingInformation as ObservableMeetingInformation
      ).selectedRace.isFixedOddsRace() &&
      (
        this.bettingContext.meetingInformation as ObservableMeetingInformation
      ).selectedRace.fixedOddsInfo.isPlaceAvailable()
    this.initialise()
  }

  private initialise(): void {
    const selectionResult = this.winPlaceSelectionResultProcessor.getSelectionsResult(
      this.bettingContext,
      this.raceNumber
    )

    const strings = selectionResult.selectionStrings
    this.selectionsString = strings[0] ?? ''
    let starters = this.bettingContext.selections.getStartersForRace(this.raceNumber)()

    if (this.appendStarterName()) {
      this.selectionsString += ` - ${starters?.[0].name()}`
    }

    const fobSelected = starters
      .map(starter => (starter.selection() as buttons.ButtonsSelection).value())
      .some(buttonSelectionType => buttonSelectionType === ButtonSelectionType.Fob)

    if (fobSelected) {
      const fob = selectionResult as FobSelectionResult
      this.winPrice = fob.winPrice
      this.placePrice = fob.placePrice
    }
  }

  private appendStarterName(): boolean {
    return (
      this.bettingContext.selections.getTotalNumberOfStartersForRace(
        this.bettingContext.raceNumber
      ) === 1
    )
  }
}
