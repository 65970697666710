import React, {
  type FC,
  createContext,
  type ReactNode,
  useReducer,
  type Reducer,
  type Dispatch,
} from 'react'
import type { Toast } from './types'

type Props = {
  children: ReactNode
}

type Actions = {
  type: 'add' | 'dismiss'
  toast: Toast
}

const toastReducer: Reducer<Toast[], Actions> = (prevToasts, action) => {
  switch (action.type) {
    case 'add':
      return prevToasts.concat(action.toast)
    case 'dismiss':
      return prevToasts.filter(toast => toast.id !== action.toast.id)
  }
}

type Context = {
  toasts: Toast[]
  dispatch: Dispatch<Actions>
}

export const ToastContext = createContext<Context | undefined>(undefined)

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, [])

  return <ToastContext.Provider value={{ toasts, dispatch }}>{children}</ToastContext.Provider>
}
