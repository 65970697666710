import { type InitialData } from '@mobi/api-types'

export function customerMustUseThreeDSecure(
  { liabilityShiftExempt, minimumLiabilityShiftThreshold }: InitialData,
  depositAmount: number
): boolean {
  return !liabilityShiftExempt && depositAmount >= minimumLiabilityShiftThreshold
}

/**
 * Detects if the Payment Request API is supported by the browser
 * Note: The Google Pay SDK uses the Payment Request API for payments.
 * @returns {boolean}
 */
export function isPaymentRequestApiSupported(): boolean {
  return !!window.PaymentRequest
}
