import React from 'react'

import {
  state$,
  ManualAddressFields,
  ChangeFieldValue,
  SaveButtonClicked,
  ManualAddressState,
  CancelButtonClicked,
  ValidateManualAddressField,
} from './driver'

import { observeImmutable } from '../../../../Components/HOCs'

import { PopupContainerStyled, PopupStyled } from '../../../../Components/Popup/Popup.styles'
import { ButtonBlock, CloseButton } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { InputField } from '@mobi/component-library/Common/Input'
import { SuburbStatePostcodeAutocomplete } from './SuburbStatePostcodeAutocomplete'
import { StreetTypeAutocomplete } from './StreetTypeAutocomplete'

import {
  ManualAddressEntryFormStyled,
  ManualAddressEntryTitleStyled,
} from './ManualAddressEntry.styles'

import { ContactDetailsEditLabelStyled } from '../../ContactDetails.styles'

export const ManualAddressEntry = observeImmutable(state$, ({ record }) => {
  return <ManualAddressEntryComponent {...record.toJS()} />
})

function validateField(field: keyof ManualAddressFields) {
  return () => ValidateManualAddressField({ field })
}

export function ManualAddressEntryComponent({
  unitNumber,
  streetNumber,
  isPostalAddress,
  streetName,
  streetType,
  suburb,
  streetNumberHasError,
  streetNameHasError,
  streetTypeHasError,
  suburbHasError,
  isShown,
}: ManualAddressState) {
  if (!isShown) {
    return null
  }

  const disableSaveButton = isSaveButtonToBeDisabled({
    streetNumberHasError,
    streetNameHasError,
    streetTypeHasError,
    suburbHasError,
  })

  return (
    <PopupContainerStyled>
      <PopupStyled>
        <CloseButton onClick={CancelButtonClicked} icon={<Icon type='cross' />} />
        <ManualAddressEntryTitleStyled>Manually Enter Your Address</ManualAddressEntryTitleStyled>
        <ManualAddressEntryFormStyled>
          <fieldset>
            <InputField
              type='text'
              value={unitNumber || ''}
              onChange={changeUnitNumber}
              onBlur={validateField('unitNumber')}
              name='Unit Number'
              required={false}
              errorMessage={false}
            />
            <InputField
              type='text'
              value={streetNumber || ''}
              onChange={changeStreetNumber}
              onBlur={validateField('streetNumber')}
              name='Street Number'
              required={!isPostalAddress}
              errorMessage={getErrorMessage('streetNumber', streetNumberHasError)}
            />
            <InputField
              type='text'
              value={streetName || ''}
              onChange={changeStreetName}
              onBlur={validateField('streetName')}
              name={isPostalAddress ? 'Street Name / PO Box Number' : 'Street Name'}
              required={true}
              errorMessage={getErrorMessage('streetName', streetNameHasError)}
            />

            <ContactDetailsEditLabelStyled htmlFor='contact-streettype'>
              Street Type<span>{isPostalAddress ? '' : '*'}</span>
            </ContactDetailsEditLabelStyled>
            <StreetTypeAutocomplete
              id='contact-streettype-autocomplete'
              inputId='contact-streettype'
              value={streetType}
              onSelection={changeStreetType}
              onBlur={validateField('streetType')}
              errorMessage={getErrorMessage('streetType', streetTypeHasError)}
            />

            <ContactDetailsEditLabelStyled htmlFor='contact-suburbstatepostcode'>
              Suburb<span>*</span>
            </ContactDetailsEditLabelStyled>
            <SuburbStatePostcodeAutocomplete
              id='contact-suburbstatepostcode-autocomplete'
              inputId='contact-suburbstatepostcode'
              value={suburb}
              onSelection={changeSuburb}
              onBlur={validateField('suburb')}
              errorMessage={getErrorMessage('suburb', suburbHasError)}
            />
          </fieldset>
        </ManualAddressEntryFormStyled>

        <Grid padding='5px'>
          <GridCell>
            <ButtonBlock onClick={CancelButtonClicked} color='secondary'>
              Cancel
            </ButtonBlock>
          </GridCell>
          <GridCell>
            <ButtonBlock disabled={disableSaveButton} onClick={SaveButtonClicked} color='primary'>
              Save Address
            </ButtonBlock>
          </GridCell>
        </Grid>
      </PopupStyled>
    </PopupContainerStyled>
  )
}

export function changeUnitNumber(event: React.FormEvent<HTMLInputElement>): void {
  ChangeFieldValue({ field: 'unitNumber', value: event.currentTarget.value })
}

export function changeStreetNumber(event: React.FormEvent<HTMLInputElement>) {
  ChangeFieldValue({ field: 'streetNumber', value: event.currentTarget.value })
}

export function changeStreetName(event: React.FormEvent<HTMLInputElement>) {
  ChangeFieldValue({ field: 'streetName', value: event.currentTarget.value })
}

export function changeStreetType(value: string) {
  ChangeFieldValue({ field: 'streetType', value })
}

export function changeSuburb(value: string) {
  ChangeFieldValue({ field: 'suburb', value })
}

export function isSaveButtonToBeDisabled({
  streetNumberHasError,
  streetNameHasError,
  streetTypeHasError,
  suburbHasError,
}: {
  streetNumberHasError: boolean
  streetNameHasError: boolean
  streetTypeHasError: boolean
  suburbHasError: boolean
}) {
  return streetNumberHasError || streetNameHasError || streetTypeHasError || suburbHasError
}

export function getErrorMessage(
  field: keyof ManualAddressFields,
  isErrored: boolean
): string | false {
  switch (field) {
    case 'streetNumber':
      return isErrored && 'Please enter a street number'
    case 'streetName':
      return isErrored && 'Please enter a street name'
    case 'streetType':
      return isErrored && 'Please enter a street type'
    case 'suburb':
      return isErrored && 'Please enter a suburb'
    default:
      return false
  }
}
