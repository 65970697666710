import { BettingInformation } from '../../../Model/BettingInformation'
import Guard from '../../../../AppUtils/Framework/Guard'

export class LegInViewModel {
  constructor(params: { context: { bettingContext: BettingInformation } }) {
    Guard.notNull(params.context.bettingContext)
    this.bettingContext = params.context.bettingContext
  }

  public toggle() {
    this.bettingContext.toggleLegIn()
  }

  public isSelected(): boolean {
    return this.bettingContext.isLegIn()
  }

  public bettingContext: BettingInformation
}
