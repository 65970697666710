import { isReactNativeApp, subscribeToNative } from '@mobi/web-native-comms/web'
import { store } from '@core/Store'
import { OverlayClose } from '@core/Components/Overlay'
import { closeAllModals } from '@core/Components/Modal'
import { toggleIsDropDownOpen, toggleIsHamburgerMenuOpen } from '@core/Areas/AppHeader/Store'
import { setIsLoginShown } from '@core/Areas/Login/Store'
import { navigateToLastRacesPath } from '@core/State/Navigation/helpers/navigateToLastRacesPath'
import { navigateToSportsPath } from '@core/State/Navigation/helpers/navigateToSportsPath'

export const startDeepLinkingListeners = (): void => {
  if (isReactNativeApp) {
    subscribeToNative('DEEP_LINK_OPEN', ({ url }) => {
      try {
        closeAllPopups()
        const urlObject = new URL(url)
        const isSearchParamOnly = urlObject.search.includes('=') && urlObject.hash.length === 0

        window.location.href =
          urlObject.hostname === window.location.hostname
            ? isSearchParamOnly
              ? urlObject.search
              : urlObject.hash
            : urlObject.href
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Bad deep link')
      }
    })

    subscribeToNative('NAVIGATE_WEB_RACES', navigateToLastRacesPath)
    subscribeToNative('NAVIGATE_WEB_SPORTS', navigateToSportsPath)
  }
}

function closeAllPopups() {
  OverlayClose()
  store.dispatch(toggleIsHamburgerMenuOpen(false))
  store.dispatch(toggleIsDropDownOpen(false))
  store.dispatch(closeAllModals())
  store.dispatch(setIsLoginShown({ shouldShow: false }))
}
