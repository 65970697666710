import { useQuery } from 'react-query'
import type { FixedOddsEventDetailsResponse } from '@core/Areas/RaceCardFixedOnly/Data/types'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getFixedOddsEventDetails } from '@core/Areas/RaceCardFixedOnly/Data/api'
import { RaceProduct } from '@core/Areas/RaceCardFixedOnly'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

type Props = {
  raceProduct: RaceProduct
  raceCode: RaceCodesLowerCase
  competitionNumber: string
}

export function useFixedOddsEventDetails({ raceProduct, raceCode, competitionNumber }: Props) {
  return useQuery<FixedOddsEventDetailsResponse>(
    [queryKeys.eventDetails, raceProduct, raceCode, competitionNumber],
    () => getFixedOddsEventDetails(raceProduct, raceCode, competitionNumber),
    {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
