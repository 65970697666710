import React from 'react'
import { observeImmutable } from '../../Components/HOCs/observe'
import {
  state$ as passwordState$,
  PasswordStateRecord,
  UpdateNewPassword,
  IsPasswordVisible,
  UpdateConfirmPassword,
  IsConfirmPasswordVisible,
  ConfirmPasswordOnBlur,
  NewPasswordOnBlur,
} from './driver'
import { PasswordThemes, getPasswordTheme } from './themes'
import { PasswordComponent } from './Components'
import { RuleProps, InteractiveRules } from '../../Components/InteractiveRules/InteractiveRules'

interface ComplexPasswordProps {
  themeName?: PasswordThemes
  shouldScrollIntoView?: boolean
}

// As no password field values are stored in state, call `ValidateConfirmPassword` signal in onChangeConfirmPassword passed by consumer
export function ComplexPassword(themeName?: PasswordThemes, shouldScrollIntoView?: boolean) {
  return (
    <ComplexPasswordObserve themeName={themeName} shouldScrollIntoView={shouldScrollIntoView} />
  )
}

// As no password field values are stored in state, call `ValidateConfirmPassword` signal in onChangeConfirmPassword passed by consumer
export const ComplexPasswordObserve = observeImmutable<PasswordStateRecord, ComplexPasswordProps>(
  passwordState$,
  ({ record, ...props }) => {
    const { isPasswordVisible, isConfirmPasswordVisible, validationError, confirmValidationError } =
      record
    const onChangeNewPassword = (event: React.FormEvent<HTMLInputElement>) => {
      UpdateNewPassword(event.currentTarget.value)
    }
    const onChangeConfirmPassword = (event: React.FormEvent<HTMLInputElement>) => {
      UpdateConfirmPassword(event.currentTarget.value)
    }

    return (
      <div>
        <PasswordComponent
          labelName='New Password*'
          themeName={props.themeName}
          isPasswordVisible={isPasswordVisible}
          onChange={onChangeNewPassword}
          onBlur={NewPasswordOnBlur}
          onVisibleToggle={IsPasswordVisible}
          validationError={validationError}
          shouldScrollIntoView={props.shouldScrollIntoView}
          testId='new-password'
        />
        <PasswordRulesList themeName={props.themeName} />
        <PasswordComponent
          labelName='Confirm Password*'
          themeName={props.themeName}
          isPasswordVisible={isConfirmPasswordVisible}
          onChange={onChangeConfirmPassword}
          onBlur={ConfirmPasswordOnBlur}
          onVisibleToggle={IsConfirmPasswordVisible}
          validationError={confirmValidationError}
          testId='confirm-password'
        />
      </div>
    )
  }
)

const PasswordRulesList = observeImmutable<PasswordStateRecord, ComplexPasswordProps>(
  passwordState$,
  ({ record, ...props }) => {
    const { isValidLength, hasNumber, hasSpecialCharacters, hasUpperCase, hasLowerCase } = record
    const hasNumberOrSymbol = hasNumber || hasSpecialCharacters
    const hasUpperAndLowerCaseCharacters = hasUpperCase && hasLowerCase

    const rulesArray: RuleProps[] = [
      { description: '8 or more characters', isRuleMet: isValidLength, key: 'isValidLength' },
      {
        description: 'Uppercase & lowercase characters',
        isRuleMet: hasUpperAndLowerCaseCharacters,
        key: 'hasUpperAndLowerCaseCharacters',
      },
      {
        description: 'At least one number or special character',
        isRuleMet: hasNumberOrSymbol,
        key: 'hasNumberOrSymbol',
      },
    ]
    const themeRules = getPasswordTheme(props.themeName).rules
    return <InteractiveRules theme={themeRules} rules={rulesArray} />
  }
)
