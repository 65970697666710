import React from 'react'
import { QuickbetReceiptState } from './driver'
import { Money } from '@core/Components/Text/Money'
import {
  ReceiptListItemTextStyled,
  ReceiptListItemStyled,
  ReceiptListStyled,
  ReceiptSpecialIndicator,
  ReceiptCampaignTextStyled,
  ReceiptListRowStyled,
} from './Receipt.styles'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { useTrackOptimoveEvent } from '@core/Services/Optimove/hooks'

export type QuickbetReceiptProps = {
  isToteBet: boolean
  isMysteryBet: boolean
  isMysteryCombo: boolean
  isNoveltyBet: boolean
  itemSelectionTag?: string
  showBalance: boolean
} & Pick<
  Immutable<QuickbetReceiptState>,
  | 'accountBalance'
  | 'ticketNumber'
  | 'betCost'
  | 'winInvestment'
  | 'placeInvestment'
  | 'projectedWinPayout'
  | 'projectedPlacePayout'
  | 'specialOffers'
  | 'divXAnnotations'
  | 'campaignActivatedInd'
  | 'campaignType'
  | 'bonusBet'
  | 'amountPaid'
>

export function QuickbetReceipt({ ...props }: QuickbetReceiptProps): JSX.Element {
  useTrackOptimoveEvent({ eventName: 'bet_placed', data: { type: 'quick_bet' } })
  return (
    <NoticeBox
      noticeBoxType={NoticeBoxTypes.Success}
      title={'Your bet has been placed.'}
      hasBorder={true}
    >
      <QuickbetReceiptDetails {...props} />
    </NoticeBox>
  )
}

export function QuickbetReceiptDetails({
  isToteBet,
  isMysteryBet,
  isMysteryCombo,
  isNoveltyBet,
  accountBalance,
  ticketNumber,
  betCost,
  winInvestment,
  placeInvestment,
  projectedWinPayout,
  projectedPlacePayout,
  specialOffers,
  divXAnnotations,
  campaignActivatedInd,
  campaignType,
  bonusBet = 0,
  amountPaid,
  showBalance,
  itemSelectionTag,
}: QuickbetReceiptProps): JSX.Element {
  const derivedBetCost: number =
    isToteBet || isMysteryBet ? betCost ?? 0 : (winInvestment ?? 0) + (placeInvestment ?? 0)
  const shouldDisplayFlexi = (isMysteryBet && !isMysteryCombo) || (isToteBet && isNoveltyBet)
  const projectedPay = (projectedWinPayout ?? 0) + (projectedPlacePayout ?? 0)
  const betCostMessage =
    itemSelectionTag && itemSelectionTag !== '' ? `Bet Cost (${itemSelectionTag})` : 'Bet Cost'
  const wasCampaignActivated = campaignActivatedInd && !!bonusBet
  const isBonusBet = campaignType == 'BonusBetReward'

  return (
    <ReceiptListStyled>
      {shouldDisplayFlexi && (
        <ReceiptListRowStyled data-tid-receipt-flexi=''>
          <ReceiptListItemStyled>Flexi</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>{divXAnnotations}</ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}

      {!(isToteBet || isMysteryBet) && projectedPay > 0 && (
        <ReceiptListRowStyled data-tid-receipt-projrtn=''>
          <ReceiptListItemStyled>Projected Return</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>
            <Money amount={projectedPay} />
          </ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}

      <ReceiptListRowStyled data-tid-receipt-betcost=''>
        <ReceiptListItemStyled>{betCostMessage}</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>
          <Money amount={derivedBetCost} />
        </ReceiptListItemTextStyled>
      </ReceiptListRowStyled>

      {!!wasCampaignActivated && (
        <ReceiptListRowStyled data-tid-receipt-bonusbet=''>
          <ReceiptListItemStyled>
            <ReceiptCampaignTextStyled isBonusBet={isBonusBet}>
              {isBonusBet ? 'Bonus Bet' : 'Bonus Cash'}
            </ReceiptCampaignTextStyled>
          </ReceiptListItemStyled>
          <ReceiptListItemTextStyled>
            <ReceiptCampaignTextStyled isBonusBet={isBonusBet}>
              <Money amount={-(bonusBet ?? 0)} />
            </ReceiptCampaignTextStyled>
          </ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}

      {!!wasCampaignActivated && (
        <ReceiptListRowStyled data-tid-receipt-amountpaid=''>
          <ReceiptListItemStyled>Amount Paid</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>
            <Money amount={amountPaid ?? 0} />
          </ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}

      {showBalance && (
        <ReceiptListRowStyled data-tid-receipt-balance=''>
          <ReceiptListItemStyled>Balance</ReceiptListItemStyled>
          <ReceiptListItemTextStyled>
            <Money amount={accountBalance ?? 0} />
          </ReceiptListItemTextStyled>
        </ReceiptListRowStyled>
      )}

      <ReceiptListRowStyled data-tid-receipt-ticket=''>
        <ReceiptListItemStyled>Ticket</ReceiptListItemStyled>
        <ReceiptListItemTextStyled>{ticketNumber}</ReceiptListItemTextStyled>
      </ReceiptListRowStyled>

      {specialOffers
        ? specialOffers.map((special, i) => {
            return (
              <ReceiptListRowStyled key={i} data-tid-receipt-special=''>
                <ReceiptListItemStyled>{special.title}</ReceiptListItemStyled>
                <ReceiptListItemTextStyled>
                  <ReceiptSpecialIndicator />
                </ReceiptListItemTextStyled>
              </ReceiptListRowStyled>
            )
          })
        : null}
    </ReceiptListStyled>
  )
}
