import { useMemo } from 'react'
import {
  MeetingInformationDataTransferObject,
  RacePageDTO,
  AcceptorDTO,
  FixedOddsAcceptorInfoDTO,
  isResultsPageDto,
} from '@mobi/api-types'
import type { Acceptor as BaseAcceptor } from '@mobi/betslip/types'

// TODO: Merge this into the BaseAcceptor
export type Acceptor = SetOptional<BaseAcceptor, 'fixedOdds'>

export type AcceptorMap = Map<number, Acceptor>

function priceAvailable(price: string): boolean {
  return !['', '0.00'].includes(price)
}

function getToteWinPrice(acceptorDTO: AcceptorDTO): string {
  if ('ToteWinProvDiv' in acceptorDTO) {
    return acceptorDTO.ToteWinProvDiv.toFixed(2)
  }

  return acceptorDTO.DisplayWinDividend
}

function getTotePlacePrice(acceptorDto: AcceptorDTO): string {
  if ('TotePlaceProvDiv' in acceptorDto) {
    return acceptorDto.TotePlaceProvDiv.toFixed(2)
  }

  return acceptorDto.DisplayPlaceDividend
}

function getFixedWinPrice(fixedOddsInfo: FixedOddsAcceptorInfoDTO): string {
  if ('WinDiv' in fixedOddsInfo) {
    return fixedOddsInfo.WinDiv.toFixed(2)
  }

  return fixedOddsInfo.DisplayWinDividend
}

function getFixedPlacePrice(fixedOddsInfo: FixedOddsAcceptorInfoDTO): string {
  if ('PlaceDiv' in fixedOddsInfo) {
    return fixedOddsInfo.PlaceDiv.toFixed(2)
  }

  return fixedOddsInfo.DisplayPlaceDividend
}

function mapAcceptor(
  meetingDTO: MeetingInformationDataTransferObject,
  acceptorDTO: AcceptorDTO
): Acceptor {
  const acceptor: Acceptor = {
    name: acceptorDTO.Name,
    number: acceptorDTO.Number,
    key: `${meetingDTO.SelectedRace.Key}-${acceptorDTO.Number}`,
    imageUrl: acceptorDTO.SilkImages?.small?.url,
    imageUrlWithSizes: acceptorDTO.SilkImages,
    type: acceptorDTO.Type,
    meetingName: meetingDTO.MeetingName,
    isScratched: acceptorDTO.IsScratched,
    scratchType: acceptorDTO.ScratchType,
    toteOdds: {
      win: getToteWinPrice(acceptorDTO),
      place: getTotePlacePrice(acceptorDTO),
    },
  }

  if (
    acceptorDTO.HasFixedOdds &&
    acceptorDTO.FixedOddsInfo &&
    priceAvailable(acceptorDTO.FixedOddsInfo.DisplayWinDividend)
  ) {
    const fixedOddsInfo = acceptorDTO.FixedOddsInfo
    acceptor.fixedOdds = {
      win: getFixedWinPrice(fixedOddsInfo),
      place: getFixedPlacePrice(fixedOddsInfo),
      isScratched: fixedOddsInfo.IsScratched,
      isSuspended: fixedOddsInfo.IsSuspended,
      propositionSeq: fixedOddsInfo.PropositionSequence,
    }
  }

  return acceptor
}

function extractAcceptors(race: RacePageDTO): Acceptor[] {
  if (isResultsPageDto(race)) {
    return race.Finishers.map(finisher => mapAcceptor(race.MeetingInformation, finisher))
  }

  const { RaceNumber: raceNumber } = race.MeetingInformation.SelectedRace
  const starters = race.RaceStarters.find(({ RaceKey }) => RaceKey.RaceNumber === raceNumber)

  if (!starters) {
    return []
  }

  return starters.Starters.map(starter => mapAcceptor(race.MeetingInformation, starter))
}

export function useAcceptors(race: MaybeDefined<RacePageDTO>): MaybeDefined<AcceptorMap> {
  return useMemo(() => {
    if (!race) {
      return undefined
    }

    const acceptors = extractAcceptors(race).map<[number, Acceptor]>(acceptor => [
      acceptor.number,
      acceptor,
    ])

    return new Map(acceptors)
  }, [race])
}
