import { colors } from '../../Common'
import type { ColorDefinition } from '../types'

type LinkColor = { color?: string }
type LinkState = 'link' | 'hover' | 'visited' | 'active'

export type LinkColors = ColorDefinition<LinkState, LinkColor>

export const linkColors: LinkColors = {
  color: colors.black,
  link: {
    color: colors.black,
  },
  hover: {
    color: colors.black,
  },
  visited: {
    color: colors.black,
  },
  active: {
    color: colors.black,
  },
}
