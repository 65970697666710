import styled, { type StyledComponent } from '@emotion/styled'
import { Link, type LinkProps } from 'react-router-dom'
import { layering } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { AccountDropDownBGStyled } from '../AccountDropDown/AccountDropDown.styles'

const HAMBURGER_FONT_FAMILY = '"PF Square Sans Pro", Arial, sans-serif'

export const AccountDetailsSectionStyled = styled.div({
  paddingTop: '2rem',
})

export const LoginSignUpSectionStyled = styled.div({
  paddingTop: '2.5rem',
  display: 'flex',
  '*:not(:last-child)': {
    marginRight: '1.5rem',
  },
})

export const DepositWithdrawSectionStyled = styled.div({
  display: 'flex',
  '*:not(:last-child)': {
    marginRight: '1.5rem',
  },
})

export const AccountNameLabelStyled = styled.div({
  color: hexColors.studio,
  fontWeight: '700',
  fontSize: '2rem',
  fontFamily: HAMBURGER_FONT_FAMILY,
})

export const AccountNumberLabelStyled = styled.div({
  color: hexColors.nero,
  fontWeight: '300',
  fontSize: '1.2rem',
  fontFamily: HAMBURGER_FONT_FAMILY,
})

export const ExternalLinkStyled = styled.a({
  display: 'block',
  color: hexColors.blueDiamond,
  fontSize: '1.4rem',
  fontFamily: HAMBURGER_FONT_FAMILY,
  textDecoration: 'none',
  padding: '0.5em 0',
  em: {
    position: 'relative',
    top: '-0.3rem',
    transform: 'translateY(-50%)',
    padding: '0.1rem 0.5rem',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: 1,
    textTransform: 'uppercase',
    borderRadius: '1rem',
    color: hexColors.white,
    background: hexColors.freeSpeechRed,
  },
})

export const LinkStyled: StyledComponent<LinkProps> =
  styled(ExternalLinkStyled)().withComponent(Link)

export const HamburgerMenuSectionHeaderStyled = styled.h4({
  color: hexColors.grey,
  fontSize: '1.4rem',
  fontFamily: HAMBURGER_FONT_FAMILY,
  fontWeight: '500',
  textTransform: 'uppercase',
  textAlign: 'start',
  margin: '0.5rem 0',
})

export const SeparatorStyled = styled.hr({
  margin: '1rem 0',
  border: 0,
  height: 0,
  borderBottom: '1px solid ' + hexColors.grey3,
})

export const ExternalLinkIconStyled = styled(Icon)({
  verticalAlign: 'text-top',
  marginLeft: '0.5em',
  color: hexColors.deepLilac,
})

export const HamburgerMenuStyled = styled.div(({ isOpen }: { isOpen: boolean }) => {
  return {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: layering.login + 1,
    padding: '1rem',
    width: 'calc(min(30rem, 100vw - 6rem))',
    backgroundColor: hexColors.white,
    overflowY: 'auto',
    willChange: 'transform',
    transition: isOpen ? undefined : 'transform 0.25s ease',
    fontFamily: HAMBURGER_FONT_FAMILY,
    transform: !isOpen ? 'translateX(-100%)' : 'translateX(0)',
  }
})

export const CloseButtonStyled = styled.span({
  padding: '1rem',
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
})

export const HamburgerMenuBackgroundStyled = styled(AccountDropDownBGStyled)({
  backdropFilter: 'blur(1.5px)',
  zIndex: layering.login + 1,
  transform: 'scale(2)',
})

export const JustifiedLinkText = styled.span({
  display: 'inline-flex',
  justifyContent: 'space-between',
  width: '100%',
})
