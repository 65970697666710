import React from 'react'
import { hexColors } from '@mobi/settings'
import { ToggleStyled } from './Toggle.styles'

interface ToggleIndicatorProps {
  character: string
}

export const ToggleIndicator = ({ character }: ToggleIndicatorProps) => {
  return (
    <ToggleStyled viewBox='0 -2 50 34'>
      <defs>
        <clipPath id='indicatorCircle'>
          <circle cx='35' cy='15' r='12' />
        </clipPath>
      </defs>
      <g clipPath='url(#indicatorCircle)'>
        <rect x='0' y='0' width='100' height='30' fill={hexColors.darkGrey} />
        <text x='35' y='20' textAnchor='middle' fill={hexColors.white}>
          {character}
        </text>
      </g>
    </ToggleStyled>
  )
}
