import styled from '@emotion/styled'

export const ListTimeStyled = styled('time')({
  fontWeight: 'bold',
  fontSize: '0.8em',
  width: '100%',
  display: 'block',
  textAlign: 'center',
  paddingTop: '.1em',
  lineHeight: 1.5,
})
