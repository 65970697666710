import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '@core/Settings'
import styled from '@emotion/styled'

export const RaceListContainerStyled = styled('div')({
  padding: '0.5rem 1rem 0.5rem 1rem',
})

export const RaceListItemStyled = styled('div')({
  display: 'flex',
  padding: '0.75rem 0',
})

export const RaceTimeAndNumber = styled('div')({
  width: '4.2rem',
  display: 'flex',
  flexDirection: 'column',
})

export const RaceListDetails = styled('div')({
  flex: '1',
  fontSize: '1.4rem',
  padding: '0 0.25rem 0 1.5rem',
  display: 'flex',
  flexFlow: 'row wrap',
})

export const RaceTimeStyled = styled('span')({
  textAlign: 'center',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  lineHeight: '2',
})

export const RaceListRaceNameStyled = styled('div')({
  flex: '0 0 100%',
})

export const RaceListClassTypeStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const RaceListClassStyled = styled('div')({
  flex: '0 0 55%',
  fontSize: '1.2rem',
  color: colors.raceList.class.text,
})

export const RaceListResultStyled = styled('div')({
  flex: '0 0 45%',
  fontSize: '1rem',
  display: 'block',
  textAlign: 'right',
  marginTop: 'auto',
  color: colors.raceList.class.text,
})

export const RaceListResultDistanceStyled = styled('div')({
  color: colors.raceList.class.text,
})

export const RaceListResultStatusStyled = styled('div')({
  color: colors.raceList.results.text,
})

export const RaceCondtitionIcon = styled(Icon)({
  verticalAlign: 'sub',
  marginRight: '0.5rem',
})

export const QuaddieChip = styled('span')({
  background: colors.raceList.quaddieChip.background,
  borderRadius: '0.25rem',
  color: colors.raceList.quaddieChip.text,
  margin: '0 0.5em 0 0',
  display: 'inline-block',
  lineHeight: '2rem',
  width: '2rem',
  textAlign: 'center',
  fontSize: '1rem',
})

export const RaceExtrasIconStyled = styled(Icon)({
  marginRight: '0.45rem',
})

export enum MeetingStatus {
  Open = 'Open',
  Pending = 'Pending',
  Abandoned = 'Abandoned',
  Suspended = 'Suspended',
  Finished = 'Finished',
  Released = 'Released',
  Results = 'Results', // For Fixed Odds
  Closed = 'Closed',
  Interim = 'Interim',
}

export interface RaceStatusNumberStyledProps {
  raceStatus: MeetingStatus
}

export const RaceStatusNumberStyledProps = styled('span')<RaceStatusNumberStyledProps>(
  {
    textAlign: 'center',
    fontSize: '2.2rem',
    fontWeight: 'bold',
    lineHeight: '1.8',
    borderRadius: '0.8rem',
  },

  ({ raceStatus }) => {
    switch (raceStatus) {
      case MeetingStatus.Abandoned:
        return {
          backgroundColor: colors.fixtureStatus.grey,
          color: colors.raceList.fixtureStatus.textDark,
        }
      case MeetingStatus.Open:
        return {
          backgroundColor: colors.fixtureStatus.green,
          color: colors.raceList.fixtureStatus.textLight,
        }
      case MeetingStatus.Pending: // tote
      case MeetingStatus.Suspended: // fixed
        return {
          backgroundColor: colors.fixtureStatus.orange,
          color: colors.raceList.fixtureStatus.textLight,
        }
      default:
        return {
          backgroundColor: colors.fixtureStatus.red,
          color: colors.raceList.fixtureStatus.textLight,
        }
    }
  }
)
