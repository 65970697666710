import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import type { BetSlipItem, FobDetails, FobSelection } from '@mobi/betslip/types'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiItem/Components/MultiLegCheckbox'
import { MultiLegTypeToggle } from '@mobi/betslip/Components/Multi/Components/MultiItem/Components/MultiLegTypeToggle'

type SelectionDetails = Pick<
  FobDetails,
  | 'eventName'
  | 'competitionName'
  | 'sportIconCode'
  | 'tournamentName'
  | 'propositionName'
  | 'marketName'
  | 'handicapName'
  | 'eventStartTime'
>

export const FobBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
}> = ({ item, isMultiItem }) => {
  const {
    competitionName,
    eventName,
    handicapName,
    marketName,
    propositionName,
    sportIconCode,
    tournamentName,
    eventStartTime,
  } = item.selectionDetails as SelectionDetails
  const selection = item.selection as FobSelection

  const selectionName = `${propositionName} ${handicapName ? ` ${handicapName}` : ''}`
  const price = isMultiItem
    ? item.multiLegBetType === 'W'
      ? selection.winPrice
      : selection.placePrice
    : undefined

  return (
    <>
      <BetCard.EventDetails
        heading={tournamentName ? `${competitionName} ${tournamentName}` : `${competitionName}`}
        subHeading={eventName && eventName !== marketName ? eventName : ''}
        statusComponent={
          <>
            <CountdownBadge
              shouldUseExtendedCountdown
              advertisedStartTime={new Date(eventStartTime)}
            />
            {isMultiItem && <MultiLegTypeToggle item={item} />}
          </>
        }
        iconComponent={<Icon size='2rem' type={sportIconCode || 'tab'} />}
      />

      <BetCard.SelectionInfo
        heading={selectionName}
        subHeading={marketName || ''}
        price={price || undefined}
        rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
      />
    </>
  )
}
