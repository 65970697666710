import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'
import { logError } from '@core/Utils'
import type { Contract } from '@mobi/web-native-comms'

interface LastKnownLocation {
  latitude: string
  longitude: string
  isoCode: string
}

let lastKnownLocation: Contract['LBS_LAST_KNOWN_LOCATION_UPDATED'] | undefined
let lastKnownVenue: Contract['PULSE_LAST_KNOWN_VENUE_UPDATED'] | undefined

export function startLocationServiceListeners(): void {
  if (isReactNativeApp) {
    startReactNativeLocationServiceListeners()
  }
}

function startReactNativeLocationServiceListeners() {
  subscribeToNative('LBS_LAST_KNOWN_LOCATION_UPDATED', payload => {
    lastKnownLocation = payload
  })
  subscribeToNative('PULSE_LAST_KNOWN_VENUE_UPDATED', payload => {
    lastKnownVenue = payload
  })
  subscribeToNative('PULSE_FAILED_TO_UPDATE_LAST_KNOWN_VENUE', payload => {
    logError(`Failed to update last known venue (payload ${JSON.stringify(payload)})`)
  })

  sendToNative('LBS_REQUEST_LAST_KNOWN_LOCATION')
  sendToNative('PULSE_REQUEST_LAST_KNOWN_VENUE')
}

export function getLastKnownLocation(): LastKnownLocation | undefined {
  return lastKnownLocation
}

export function getLastKnownVenue(): Contract['PULSE_LAST_KNOWN_VENUE_UPDATED'] | undefined {
  return lastKnownVenue
}

export function getLastKnownLocationAsString(): string {
  if (!lastKnownLocation) {
    return ''
  }

  const isoCode = lastKnownLocation.isoCode ? `,${lastKnownLocation.isoCode}` : ''
  return `${lastKnownLocation.latitude},${lastKnownLocation.longitude}${isoCode}`
}

export function getLastKnownVenueAsString(): string {
  return `${JSON.stringify(lastKnownVenue)}`
}
