import * as ko from 'knockout'
import { IFixedWinPlaceSelectionsViewModel } from './IFixedWinPlaceSelectionsViewModel'
import container from '../../../../IOC/inversify.config'
import template from './fixed-winplace-selections.tpl.html'

export function register(): void {
  if (!ko.components.isRegistered('fixed-winplace-selections')) {
    ko.components.register('fixed-winplace-selections', {
      viewModel: {
        createViewModel: (params: {
          selectionsString: string
          winPrice: string
          placePrice: string
          hasFixedPlacePool: boolean
        }): IFixedWinPlaceSelectionsViewModel => {
          const viewModel = container.get<IFixedWinPlaceSelectionsViewModel>(
            'IFixedWinPlaceSelectionsViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
