import { ContainerModule, type interfaces } from 'inversify'

import { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import EventAggregator from '../Messaging/EventAggregator'
import { IProgressIndicator } from '../ProgressIndicator/IProgressIndicator'
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator'
import { IAppWindow } from '../WindowManagement/IAppWindow'
import AppWindow from '../WindowManagement/AppWindow'
import { IApiService, ApiService } from '../../../Foundation/Services/ApiServiceInjectable'
import { ILoginHelper } from '../Utils/ILoginHelper'
import { LogInHelper } from '../Utils/LogInHelper'

const services = new ContainerModule((bind: interfaces.Bind) => {
  bind<IEventAggregator>('IEventAggregator').to(EventAggregator).inSingletonScope()
  bind<IAppWindow>('IAppWindow').to(AppWindow).inSingletonScope()
  bind<IApiService>('IApiService').to(ApiService).inSingletonScope()
  bind<IProgressIndicator>('IProgressIndicator').to(ProgressIndicator)
  bind<ILoginHelper>('ILoginHelper').to(LogInHelper)
})

export default services
