import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { BettingHeaderComponentTheme, betslipSimpleBettingHeader } from './themes'
import { OverflowEllipsis } from '../OverflowEllipsis'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import {
  ContainerStyled,
  HeadingStyled,
  SubheadingStyled,
  PilledStyled,
  SingleSelectionStyled,
} from './BettingHeader.styles'
import { RemoveButton } from './RemoveButton'

type BettingHeaderProps = SimpleBettingHeaderProps & {
  subheading: React.ReactNode
  pilled: React.ReactNode
  icon: React.ReactNode
  timeInfo?: React.ReactNode
  theme: BettingHeaderComponentTheme
  displayPill: boolean
  isEditable: boolean
  removeHandler?: () => void
}

interface PilledContentStyledProps {
  displayPill: boolean
  pilledContent: React.ReactNode
}

function PilledContentStyled({
  displayPill,
  pilledContent,
}: PilledContentStyledProps): JSX.Element {
  if (displayPill) {
    return (
      <PilledStyled>
        <OverflowEllipsis>{pilledContent}</OverflowEllipsis>
      </PilledStyled>
    )
  } else {
    return <OverflowEllipsis>{pilledContent}</OverflowEllipsis>
  }
}

export function BettingHeader({
  icon,
  heading,
  subheading,
  pilled,
  timeInfo = null,
  selection,
  theme,
  removeHandler,
  displayPill,
  isEditable,
}: BettingHeaderProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <ContainerStyled data-tid-betting-header=''>
        <Grid>
          <GridCell width={'3.5rem'}>{icon}</GridCell>
          <GridCell valign='middle'>
            <HeadingStyled>
              <OverflowEllipsis>{heading}</OverflowEllipsis>
            </HeadingStyled>
            {subheading && (
              <SubheadingStyled data-tid-betting-header-sub-heading=''>
                <OverflowEllipsis>{subheading}</OverflowEllipsis>
              </SubheadingStyled>
            )}
          </GridCell>
          {removeHandler && isEditable && (
            <GridCell width='2.4rem'>
              <RemoveButton onClick={removeHandler} />
            </GridCell>
          )}
        </Grid>
        <Grid>
          {pilled && (
            <GridCell valign='middle'>
              <PilledContentStyled displayPill={displayPill} pilledContent={pilled} />
            </GridCell>
          )}
          {timeInfo && (
            <GridCell width='10rem' align='right' valign='middle'>
              {timeInfo}
            </GridCell>
          )}
        </Grid>
        <SingleSelectionStyled data-tid-betting-header-selection=''>
          {typeof selection === 'string' ? (
            <OverflowEllipsis>{selection}</OverflowEllipsis>
          ) : (
            selection
          )}
        </SingleSelectionStyled>
      </ContainerStyled>
    </ThemeProvider>
  )
}

interface SimpleBettingHeaderProps {
  heading: React.ReactNode
  selection: React.ReactNode
}

export function SimpleBettingHeader({ heading, selection }: SimpleBettingHeaderProps): JSX.Element {
  return (
    <ThemeProvider theme={betslipSimpleBettingHeader}>
      <ContainerStyled>
        <Grid>
          <GridCell>
            <HeadingStyled data-tid-simple-betting-heading=''>
              <OverflowEllipsis>{heading}</OverflowEllipsis>
            </HeadingStyled>
          </GridCell>
        </Grid>
        <SingleSelectionStyled data-tid-simple-betting-selection=''>
          <OverflowEllipsis>{selection}</OverflowEllipsis>
        </SingleSelectionStyled>
      </ContainerStyled>
    </ThemeProvider>
  )
}
