import React, { useState } from 'react'
import { captureException, captureMessage } from '@sentry/react'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ } from '@core/State/UserAccount/userAccountDriver'
import { useDebounce } from 'rooks'
import { Button } from '@mobi/component-library/Common/Buttons'

export const Sentry = () => {
  const { accountNumber, isLoggedIn } = useObservableImmutable(state$, [
    'isLoggedIn',
    'accountNumber',
  ])
  const [sentTest, setSentTest] = useState(false)

  const clearTestFlag = useDebounce(() => setSentTest(false), 3000)

  const sendToSentry = (message: string | Error) => {
    const captureFn =
      typeof message === 'string'
        ? captureMessage.bind(null, message)
        : captureException.bind(null, message)

    captureFn(scope => {
      scope.clear()
      scope.setTag('is_test', true)
      if (isLoggedIn && accountNumber) {
        scope.setUser({
          id: accountNumber.toString(),
        })
      }
      return scope
    })

    setSentTest(true)
    clearTestFlag()
  }

  return (
    <>
      <h3>Sentry {sentTest && ' - Sent!'}</h3>

      <div style={{ padding: '1rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button onClick={() => sendToSentry('This is a test message')}>Send test message</Button>

          <Button onClick={() => sendToSentry(new Error('This is a test error'))}>
            Send test error
          </Button>
        </div>
      </div>
    </>
  )
}
