import { createSlice } from '@reduxjs/toolkit'

type VerificationState = {
  contactDetailsVerified: boolean
  isVerified: boolean
}

const initialState: VerificationState = {
  contactDetailsVerified: false,
  isVerified: false,
}

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    verifyContactDetails(state) {
      state.contactDetailsVerified = true
    },
    hasBeenVerified(state) {
      state.isVerified = true
    },
  },
})

export const { verifyContactDetails, hasBeenVerified } = verificationSlice.actions

export default verificationSlice.reducer
