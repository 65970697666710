import React from 'react'
import { ChipStyled, ChipsTypes } from './Chips.styles'

export interface ChipProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: (T: React.FormEvent) => void
  children: React.ReactNode
  color?: keyof ChipsTypes
  testId?: string
}

export function Chip({ onClick, children, color = 'hollow', ...ChipProps }: ChipProps) {
  return (
    <ChipStyled {...ChipProps} onClick={onClick} color={color}>
      {children}
    </ChipStyled>
  )
}
