import { ContainerModule, type interfaces } from 'inversify'
import { IPyosService } from '../Services/IPyosService'
import { PyosService } from '../Services/PyosService'
import { ISpecialsUrlProvider } from '../Utils/ISpecialsUrlProvider'
import { SpecialsUrlProvider } from '../Utils/SpecialsUrlProvider'
import { IPyosRewardCalculator, PyosRewardCalculator } from '../Store/PyosRewardCalculator'
import { PyosExtensions } from '../Store/PyosExtensions'
import { PyosStore } from '../Store/PyosStore'

const services = new ContainerModule((bind: interfaces.Bind) => {
  bind<IPyosService>('IPyosService').to(PyosService).inSingletonScope()
  bind<ISpecialsUrlProvider>('ISpecialsUrlProvider').to(SpecialsUrlProvider)
  bind<IPyosRewardCalculator>('IPyosRewardCalculator').to(PyosRewardCalculator).inSingletonScope()
  bind<PyosExtensions>('PyosExtensions').to(PyosExtensions).inSingletonScope()
  bind<PyosStore>('PyosStore').to(PyosStore).inSingletonScope()
})

export default services
