import React from 'react'
import { Global } from '@emotion/react'
import { checkIfIsInIPad } from './helpers'

/** DO NOT add styles here! This is a temporary solution to phase out SCSS hacks */
export const GlobalCss: React.FC<{}> = () => {
  const [isInIPad] = React.useState(checkIfIsInIPad)

  return (
    <Global
      styles={{
        /*  Only use font scaling on iPads, moved from SCSS where media query on width was used
            When true, root font size will be 16px (the default is 10px, allowing 1rem == 10px) */
        ...(isInIPad && { html: { fontSize: '1em' } }),
      }}
    />
  )
}
