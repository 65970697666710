import styled from '@emotion/styled'
import { Icon, type IconProps } from '@mobi/component-library/Common/Icon'
import { buttonColors } from '@mobi/component-library/Common/Buttons'
import { hexColors } from '@mobi/settings'

export const IconStyled = styled(Icon)<IconProps>({
  margin: '0 1rem 0 0',
  zIndex: 1,
  width: '4rem',
  position: 'relative',
})

export const IconContainer = styled.div<{ isSuperPick: boolean }>(
  {
    display: 'flex',
  },
  ({ isSuperPick }) => ({
    marginRight: isSuperPick ? '-1.7rem' : undefined,
  })
)

export const IconBackground = styled.div({
  backgroundColor: '#fff',
  position: 'relative',
  left: '-3.8rem',
  zIndex: 0,
  borderRadius: '4px',
  height: '2.4rem',
  width: '1.7rem',
  top: '0.8rem',
})

export const SpecialTileContainer = styled('title')<{ isSuperPick: boolean }>(
  {
    minHeight: '20rem',
    margin: '1rem',
    color: hexColors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ({ isSuperPick }) => ({
    backgroundColor: isSuperPick ? hexColors.nightRider : hexColors.blueDiamond,
  })
)

export const TitleContainer = styled.div({
  minHeight: '5rem',
  borderBottom: `1px solid ${hexColors.dimGrey}`,
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
})

export const TileTitle = styled.div({
  fontSize: '1.3rem',
  fontWeight: 'bold',
})

export const DescriptionStyled = styled.div({
  fontSize: '1.2rem',
  padding: '1rem',
})

export const ButtonsContainerStyled = styled.div<{ isSuperPick: boolean }>(
  {
    padding: '0.5rem',
  },
  ({ isSuperPick }) => ({
    backgroundColor: isSuperPick ? undefined : hexColors.vividViolet,
  })
)
export const ButtonWrapperStyled = styled.div({
  display: 'flex',
})

export const PrimaryButtonStyled = styled.div<{ isSuperPick?: boolean }>(
  {
    height: '4rem',
    margin: '0.5rem',
    color: buttonColors.primary.text,
    fontWeight: 'bold',
    fontSize: '1.3rem',
    textAlign: 'center',
    lineHeight: '4rem',
    flexGrow: 1,
  },
  ({ isSuperPick }) => ({
    backgroundColor: isSuperPick ? hexColors.orange : buttonColors.primary.background,
    color: isSuperPick ? hexColors.black : buttonColors.primary.text,
  })
)

export const ButtonStyled = styled(PrimaryButtonStyled)({
  border: `1px ${buttonColors.primary.text} solid`,
  backgroundColor: hexColors.vividViolet,
  boxSizing: 'border-box',
})

export const TermsAndConditionsStyled = styled.div<{ isExpanded: boolean }>(
  {
    margin: '-1rem 1rem 0',
    backgroundColor: hexColors.whiteSmoke,
    color: hexColors.black,
    overflow: 'hidden',
    transition: 'max-height 0.25s ease-in-out',
  },
  ({ isExpanded }) => ({
    maxHeight: isExpanded ? 'auto' : 0,
  })
)

export const TermsAndConditionsHeaderStyled = styled('h5')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  padding: '1rem 0',
  textAlign: 'left',
})

export const TermsAndConditionsTextStyled = styled.div({
  fontSize: '1.2rem',
  padding: '0 1rem 1rem',
})
