import * as ko from 'knockout'
import { CheckBoxSelection, CheckedValue } from '../../Core/UIElements/CheckboxSelection'
import { BettingInformation } from '../../../Model/BettingInformation'
import EventAggregator from '../../../../AppUtils/Framework/Messaging/EventAggregator'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'
import Guard from '../../../../AppUtils/Framework/Guard'
import { IDisposable } from '../../../../AppUtils/Framework/Disposable/IDisposable'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import { ISelection } from '../../Core/UIElements/ISelection'

export class CheckboxSelectionViewModel implements IDisposable {
  constructor(params: {
    starter: IObservableStarter
    context: {
      raceNumber: ObservableRaceKey
      bettingContext: BettingInformation
      numberOfStartersInRace: ko.PureComputed<number>
    }
  }) {
    Guard.notNull(params.starter)
    Guard.notNull(params.context.raceNumber)
    Guard.notNull(params.context.bettingContext)
    Guard.notNull(params.context.numberOfStartersInRace)

    this.starter = params.starter
    this.bettingContext = params.context.bettingContext
    this.raceNumber = params.context.raceNumber
    this.numberOfStartersInRace = params.context.numberOfStartersInRace

    this.evtAggregator = new EventAggregator()

    const starterSelection = this.starter.selection

    if (starterSelection === null || typeof this.starter.selection === 'undefined')
      this.starter.selection = ko.observable(
        new CheckBoxSelection(this.starter, this.raceNumber, this.bettingContext)
      ) as unknown as ko.Observable<ISelection>

    if (
      starterSelection &&
      (starterSelection() === null || typeof starterSelection() === 'undefined')
    )
      starterSelection(
        new CheckBoxSelection(
          this.starter,
          this.raceNumber,
          this.bettingContext
        ) as unknown as ISelection
      )

    this.maxAtIndexZeroClass = ko.pureComputed<string>(() => {
      if (this.bettingContext.rovingBanker()) {
        return this.bettingContext.rovingBankersSelected() >=
          this.bettingContext.selectedBetType().checkBoxCount() - 1
          ? 'disabled'
          : ''
      }
      if (this.bettingContext.isLegIn()) {
        return this.bettingContext.rovingBankersSelected() >=
          this.bettingContext.selectedBetType().checkBoxCount() - 1
          ? 'disabled'
          : ''
      }
      return ''
    })

    this.rovingClass = ko.pureComputed<string>(() => {
      if (this.bettingContext.rovingBanker()) return 'roving'
      return ''
    })

    this.boxedClass = ko.pureComputed<string>(() => {
      if (this.bettingContext.isBoxed()) return 'boxed'
      return ''
    })

    this.radioClass = ko.pureComputed<string>(() => {
      if (this.bettingContext.isLegIn()) return 'checked-radio'
      return ''
    })

    if (this.starter.number() == this.numberOfStartersInRace()) {
      this.evtAggregator.publish('last-starter-initialised')
    }
  }

  public dispose() {
    this.rovingClass.dispose()
    this.radioClass.dispose()
    this.boxedClass.dispose()
    this.maxAtIndexZeroClass.dispose()
  }

  public getIdFor(data: CheckedValue): string {
    return `toggle-${this.raceNumber.raceNumber()}-${this.starter.number()}-${data.value()}`
  }

  public getIndexClassFor(data: CheckedValue): string {
    if (data.value() === 1) return `index${data.value()}`
    return ''
  }

  public getLabelFor(data: CheckedValue): string {
    return `toggle-${this.raceNumber.raceNumber()}-${this.starter.number()}-${data.value()}`
  }

  public starter: IObservableStarter
  public bettingContext: BettingInformation
  public raceNumber: ObservableRaceKey
  public numberOfStartersInRace: ko.PureComputed<number>
  public rovingClass: ko.PureComputed<string>
  public boxedClass: ko.PureComputed<string>
  public radioClass: ko.PureComputed<string>
  public maxAtIndexZeroClass: ko.PureComputed<string>
  public evtAggregator: EventAggregator
}
