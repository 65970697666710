let numberFormatter: Intl.NumberFormat
let numberFormatterWithZeroDecimal: Intl.NumberFormat

export function toMoney(value: number, options: { decimalPlaces?: 0 | 2 } = {}): string {
  const { decimalPlaces = 2 } = options

  if (!isFinite(value)) {
    return ''
  }

  if (decimalPlaces === 0) {
    numberFormatterWithZeroDecimal ??= new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return numberFormatterWithZeroDecimal.format(Math.round(value))
  }

  numberFormatter ??= new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return numberFormatter.format(value)
}

export const roundEstimateDown = (value: number | null, decimals: number) => {
  if (value === null) return 0

  const strValue = value.toFixed(10)
  const decimalPosition = strValue.indexOf('.')

  if (strValue.indexOf('.') === -1) return value

  const sliceEndPosition = strValue.slice(0, decimalPosition + decimals + 1)
  return Number(sliceEndPosition)
}
