import React from 'react'
import styled from '@emotion/styled'
import type { Contract } from '@mobi/web-native-comms'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { ShowPopup, HidePopup } from '@core/Components/Popup/driver'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { subscribeToNative, sendToNative, isReactNativeApp } from '@mobi/web-native-comms/web'

export function ShowNotificationAuthPopup({
  reason,
  notificationAuthStatus,
}: {
  reason: string
  notificationAuthStatus: Contract['RESPONSE_PUSH_AUTH_STATUS']['status']
}): void {
  ShowPopup({
    content: NotificationAuthPopup,
    contentProps: { reason, notificationAuthStatus },
  })
}

export function checkNotificationStatusAsync(): Promise<Contract['RESPONSE_PUSH_AUTH_STATUS']> {
  const promise = new Promise<Contract['RESPONSE_PUSH_AUTH_STATUS']>(resolve => {
    if (isReactNativeApp) {
      const { dispose } = subscribeToNative('RESPONSE_PUSH_AUTH_STATUS', payload => {
        dispose()
        resolve(payload)
      })
      sendToNative('REQUEST_PUSH_AUTH_STATUS')
    }
  })
  return promise
}

function NotificationAuthPopup({
  reason,
  notificationAuthStatus,
}: {
  reason: string
  notificationAuthStatus: Contract['RESPONSE_PUSH_AUTH_STATUS']['status']
}): JSX.Element {
  const enableHandler = notificationAuthStatus === 'NotDetermined' ? hardReprompt : openSettings

  return (
    <NotificationAuthPopupContainerStyled>
      <IconStyled type='pushNotification' size='4rem' color={hexColors.chocolate} />
      <NotificationAuthPopupTitleStyled>
        <strong>Enable Notifications</strong>
      </NotificationAuthPopupTitleStyled>
      <NotificationAuthDescriptionStyled>{reason}</NotificationAuthDescriptionStyled>
      <ButtonStyled>
        <ButtonBlock color='primary' onClick={enableHandler}>
          {notificationAuthStatus === 'NotDetermined' ? 'Enable Notifications' : 'Open Settings'}
        </ButtonBlock>
      </ButtonStyled>
      <ButtonStyled>
        <ButtonBlock color='secondary' onClick={HidePopup}>
          Maybe Later
        </ButtonBlock>
      </ButtonStyled>
    </NotificationAuthPopupContainerStyled>
  )
}

function hardReprompt() {
  if (isReactNativeApp) {
    sendToNative('SHOW_PUSH_AUTH')
  }
  HidePopup()
}

function openSettings() {
  if (isReactNativeApp) {
    const { dispose } = subscribeToNative('USER_OPENED_NOTIFICATION_SETTINGS', () => {
      dispose()
      HidePopup()
    })
    sendToNative('UPDATE_PUSH_NOTIFICATION_SETTINGS')
  }
}

const IconStyled = styled(Icon)({
  margin: '3rem 0rem 2rem',
})

const NotificationAuthPopupTitleStyled = styled.div({
  fontSize: '2rem',
})

const NotificationAuthDescriptionStyled = styled.div({
  fontSize: '1.4rem',
  width: '90%',
  margin: '1rem 0',
  textAlign: 'center',
})

const ButtonStyled = styled.div({
  fontSize: '1.4rem',
  width: '92%',
  margin: '1rem 0',
})

const NotificationAuthPopupContainerStyled = styled.div({
  top: '50%',
  minHeight: '20rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
