import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

export function copyToClipboard(contents: string) {
  const copyFn = isReactNativeApp ? copyToClipboardNative : copyToClipboardWeb

  return copyFn(contents)
}

function copyToClipboardWeb(contents: string): Promise<void> {
  // navigator.clipboard.writeText isn't available in older versions of Safari
  if (typeof navigator.clipboard?.writeText === 'function') {
    return navigator.clipboard.writeText(contents)
  }

  if (copyToClipboardLegacy(contents)) {
    return Promise.resolve()
  }

  return Promise.reject(
    new DOMException('Copying is not supported on this device', 'NotAllowedError')
  )
}

function copyToClipboardNative(contents: string): Promise<void> {
  return new Promise((resolve, reject) => {
    subscribeToNative('COPY_TO_CLIPBOARD_RESULT', ({ didCopy }) => {
      if (didCopy) {
        return resolve()
      }

      reject(new DOMException('Copying is not supported on this device', 'NotAllowedError'))
    })

    sendToNative('COPY_TO_CLIPBOARD', {
      contents,
    })
  })
}

/**
 * Ponyfill for navigator.clipboard.write
 *
 * This function is aimed to iOS Safari < 13.4 and macOS Safari < 13.1 as they don't support the clipboard API
 *
 * This is solved by creating a textarea with the contents to write.
 *
 * The textarea is then selected and `document.execCommand('copy')` is run, the textarea is then removed
 *
 * @param {String} contents The contents that should be copy to the clipboard
 * @return {Boolean} `true` if the contents were successfully written to the clipboard, `false` otherwise
 *
 * Reasons `false` could be returned:
 *
 * - Copying isn't supported in the current context (e.g. it was not user-initiated)
 * - Copying is disabled
 *
 * @link https://caniuse.com/mdn-api_navigator_clipboard
 */
function copyToClipboardLegacy(contents: string) {
  const textarea = document.createElement('textarea')

  // Ensure that the user doesn't see or notice the textarea by moving it to another galaxy
  Object.assign(textarea.style, {
    position: 'fixed',
    top: '-9999px',
    left: '-9999px',
  })

  textarea.textContent = contents

  document.body.append(textarea)

  textarea.select()

  const didCopy = document.execCommand('copy')

  textarea.remove()

  return didCopy
}
