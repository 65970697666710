import React, { type ReactNode, type InputHTMLAttributes } from 'react'
import { InputFieldStyled } from './Input.styled'
import type { SharedProps } from './types'

type Props = SharedProps & {
  /**
   * The type of the input.
   *
   * Setting the type will also change the input's behaviour and how it displays on particular devices.
   *
   * When needing to deal with decimal numbers, prefer using the `"text"` type and set `inputMode` to `"numeric"`.
   */
  type?: 'text' | 'password' | 'email' | 'tel' | 'number'
  /**
   * A string or React node to use as the prefix.
   */
  prefix?: ReactNode
  /**
   * A string or React node to use as the suffix.
   */
  suffix?: ReactNode
  /**
   * The placeholder text for the input.
   *
   * Only shows when the input is empty.
   */
  placeholder?: string
  /**
   * The value of the input.
   *
   * Setting this property will turn the input into a controlled input, doing so will require an `onChange` or `onInput` handler.
   * This property is mutually exclusive with `defaultValue`.
   */
  value?: string
  /**
   * The default value of the input.
   *
   * Setting this property will turn the input into an uncontrolled input.
   * This property is mutually exclusive with `value`.
   */
  defaultValue?: string
  /**
   * Whether the value of the input is invalid.
   */
  isInvalid?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'disabled'>

export const Input = ({
  id,
  type = 'text',
  name,
  className,
  label,
  helpText,
  prefix,
  suffix,
  isInvalid = false,
  isDisabled = false,
  ...inputProps
}: Props) => {
  id ||= name

  prefix = typeof prefix === 'string' ? <span>{prefix}</span> : prefix
  suffix = typeof suffix === 'string' ? <span>{suffix}</span> : suffix

  return (
    <InputFieldStyled className={className} invalid={isInvalid} disabled={isDisabled}>
      {label && <label htmlFor={id}>{label}</label>}
      <div>
        {prefix}
        <input name={name} id={id} type={type} {...inputProps} disabled={isDisabled} />
        {suffix}
      </div>
      <span>{helpText}</span>
    </InputFieldStyled>
  )
}

Input.displayName = 'Input'
