// As specified in https://www.kambipartner.com/techhub/api_docs/gen_doc/client_documentation/widgets/basic_setup
export default {
  widgets: [
    {
      widgetId: 'NavivationSectionWidget',
      tag: 'navigation-section',
      widgetType: 'web-component',
    },
  ],
  targets: {
    navPanel: [
      {
        widgetId: 'NavivationSectionWidget',
        order: 999, // bottom
      },
    ],
  },
}
