/**
 * Web component required to use inside the Kambi client
 * https://developer.mozilla.org/en-US/docs/Web/Web_Components
 */
class NavigationSection extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <section id="NavigationSectionWidget" class="web-component">
        <h3 class="web-component__title">Other</h3>
        <ul class="web-component__unordered">
            <li class="web-component__list-item">
                <a class="web-component__link" href="#totesports" title="Tipping">
                    Tipping
                </a>
            </li>
        </ul>
      </section>
    `
  }
}

// Consider having a high level ts file to define all widgets if we end up having
// more widgets
export const defineNavigationSectionWidget = (): void => {
  if (window.customElements && !window.customElements.get('navigation-section')) {
    window.customElements.define('navigation-section', NavigationSection)
  }
}
