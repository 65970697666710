import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const centered = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
})

export const Centered = styled.div(centered)
