import styled from '@emotion/styled'
import { ButtonSelectionStyled } from '@mobi/component-library/Common/Buttons'
import { greaterThan } from '@mobi/component-library/Utils/media'
import { hexColors } from '@mobi/settings'
import { Text } from '@core/Components/Text'
import { columnGap } from '@core/Utils/emotion'

export const TipButtonStyled = styled(ButtonSelectionStyled)(props => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  ...columnGap('0.4rem'),
  cursor: `${props.isDisabled ? 'default' : 'pointer'}`,
  display: 'inline-flex',
  fontSize: '1.4rem',
  height: '5rem',
  justifyContent: 'center',
  margin: 0,
  overflow: 'hidden',
  padding: '1rem 0.5rem',
  width: '100%',

  [`${greaterThan('px350')}`]: {
    ...columnGap('0.7rem'),
    padding: '1rem 0.7rem',
  },
}))

export const TipInformationStyled = styled.div({
  alignItems: 'center',
  borderRadius: '0.5rem',
  display: 'inline-flex',
  flexDirection: 'column',
  flexGrow: 1,
  fontWeight: 400,
  height: '100%',
  justifyContent: 'space-between',
  padding: '0 0.25rem',
  width: '3.8rem',
})

export const ScratchedTextStyled = styled(Text)({
  color: hexColors.red,
  textTransform: 'uppercase',
})
