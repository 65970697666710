import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { debounceFn } from '@mobi/utils'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { AppRoutes } from '@core/AppRoutes'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import type { BlackbookEntry } from '@core/Areas/Blackbook/types'
import { RunnerListWithData } from './Components/RunnerListWithData'
import { radius } from '@mobi/component-library/Theme/Common'

export const RunnerList: React.FC<{
  isLoading: boolean
  isError: boolean
  refetch(): void
  entries?: BlackbookEntry[] | null
}> = ({ isLoading, isError, entries, refetch }) => {
  const history = useHistory()
  if (isLoading) {
    return (
      <WrapperStyled data-testid='blackbook-loading'>
        <LoadingPlaceholder width='100%' height='4rem' borderRadius={radius.lg} />
      </WrapperStyled>
    )
  }

  if (isError || !entries) {
    return (
      <WrapperStyled data-testid='blackbook-data-error'>
        <NoticeBox
          hasBorder
          title='Oops, Unable to Get Data'
          subtitle='Please check your network connection.'
          buttonText='Try Again'
          buttonClick={debounceFn(() => refetch(), 1000)}
          noticeBoxType={NoticeBoxTypes.Error}
        />
      </WrapperStyled>
    )
  }

  if (!entries || entries.length === 0) {
    return (
      <WrapperStyled data-testid='blackbook-empty'>
        <NoticeBox
          hasBorder
          title='Your Blackbook is Open!'
          subtitle='New to Blackbook? Go to an upcoming race to add your favourite runners to Blackbook and receive alerts when they’re next up.'
          noticeBoxType={NoticeBoxTypes.Info}
          buttonText='Go to Next Races'
          buttonClick={() => history.push(AppRoutes.NextEvents[0])}
        />
      </WrapperStyled>
    )
  }

  return (
    <WrapperStyled>
      <RunnerListWithData entries={entries} />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: '0.5rem',
})
