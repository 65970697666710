import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import type { useAddToBlackbook } from './Hooks/useAddToBlackbook'

export const trackBlackbookEntryToggled = (isExpanded: boolean) => {
  trackEvent('blackbook-entry-expanded', { isExpanded })
}
export const trackBlackbookAlertPreferencesUpdated = (
  isEmailAlertActive: boolean,
  isSmsAlertActive: boolean
) => {
  trackEvent('blackbook-alerts-updated', { isEmailAlertActive, isSmsAlertActive })
}
export const trackBlackbookNavigateToRace = () => {
  trackKey('blackbook-navigate-to-race')
}
export const trackBlackbookOddsClicked = (
  data: Omit<BlackbookControlAnalyticsData, 'source'> & { action: 'betslip' | 'quickbet' }
) => {
  const { code, type } = getCodeAndType(data)
  trackEvent('blackbook-odds-clicked', { ...data, code, type })
}
export const trackBlackbookEntryDeleteClicked = () => {
  trackKey('blackbook-entry-delete-clicked')
}
export const trackBlackbookEntryDeleted = (type: string) => {
  trackEvent('blackbook-entry-deleted', { type })
}
export const trackBlackbookEntryDeleteCanceled = () => {
  trackKey('blackbook-entry-delete-canceled')
}
export const trackBlackbookSortChanged = (sortBy: string) => {
  trackEvent('blackbook-sort-changed', { sortBy })
}
export const trackBlackbookAddCommentClicked = () => {
  trackKey('blackbook-comment-clicked')
}
export const trackBlackbookAddCommentCancel = () => {
  trackKey('blackbook-comment-cancel')
}
export const trackBlackbookAddCommentSave = (
  data: Omit<BlackbookControlAnalyticsData, 'source'>
) => {
  const { code, type } = getCodeAndType(data)
  trackEvent('blackbook-comment-save', { ...data, code, type })
}
export const trackBlackbookChangeView = (type: string) => {
  trackEvent('blackbook-change-view', { type })
}

export const trackBlackbookUnableToAdd = (
  data: BlackbookControlAnalyticsData & { reason: 'not-matched' | 'limit-exceeded' | 'other' }
) => trackEvent('blackbook-unable-to-add', data)

export const trackBlackbookAddRunner = (data: BlackbookControlAnalyticsData) => {
  const { code, type } = getCodeAndType(data)
  trackEvent('blackbook-add-runner', { ...data, code, type })
}

export const trackBlackbookGoToBlackbook = (data: BlackbookControlAnalyticsData) => {
  const { code, type } = getCodeAndType(data)
  trackEvent('blackbook-go-to-blackbook', { ...data, code, type })
}

// =============
// Local Helpers
// =============

function getCodeAndType(data: Omit<BlackbookControlAnalyticsData, 'source'>): {
  code: BlackbookControlAnalyticsData['code']
  type: BlackbookEntryType
} {
  const code: BlackbookControlAnalyticsData['code'] = data.code === 'Jockeys' ? 'Races' : data.code
  const type = data.code === 'Jockeys' ? 'jockey' : 'runner'
  return { code, type }
}

// =====
// Types
// =====

type BlackbookEntryType = 'runner' | 'jockey'

type BlackbookControlAnalyticsData = {
  name: string
  code: Parameters<typeof useAddToBlackbook>[0]['starterType']
} & Pick<Parameters<typeof useAddToBlackbook>[0], 'source'>
