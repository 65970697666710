import React from 'react'
import { useRaceFieldFOOFormData } from '@core/Areas/Racing/Hooks/useRaceFieldFormData'
import {
  isRacesFormData,
  isTrotsFormData,
  isDogsFormData,
} from '@core/Areas/Racing/Hooks/useRaceFormData'
import { Races, Trots, Dogs } from './Components'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

export const FOOForm = React.memo<{
  racecourseSeq?: number
  meetingDate?: string
  raceNumber: number
  starterNumber: number
}>(({ racecourseSeq, meetingDate, raceNumber, starterNumber }) => {
  const { isLoading, isError, data } = useRaceFieldFOOFormData({
    racecourseSeq,
    meetingDate,
    raceNumber,
  })

  if (isLoading)
    return <LoadingPlaceholder testId='form-loading-placeholder' width='100%' height='20rem' />

  if (!data || isError) return null

  if (isRacesFormData(data))
    return <Races form={data.Forms.find(form => form.StarterNumber === starterNumber)} />

  if (isTrotsFormData(data))
    return <Trots form={data.Forms.find(form => form.StarterNumber === starterNumber)} />

  if (isDogsFormData(data))
    return <Dogs form={data.Forms.find(form => form.StarterNumber === starterNumber)} />

  return null
})
