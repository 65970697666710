import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { colors, hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { trackMeetingsRaceCodeToggle } from '@core/Areas/Meetings/analytics'
import type { useMeetingsData } from '@core/Areas/Meetings/hooks'
import { setMeetingsRaceCode } from '@core/Areas/Meetings/Store'

const enum LocalConstants {
  NavActiveClass = 'meetings__navigation--active',
}

export const SecondaryCodeNavigation: React.FC<
  Pick<ReturnType<typeof useMeetingsData>, 'state'>
> = ({ state }) => {
  const dispatch = useDispatch()
  const simplifiedDate = dayjs(state.currentDate).format('YYYY-MM-DD')

  const createRaceCodeToggle = (raceCode: RaceCodesLowerCase) => () => {
    trackMeetingsRaceCodeToggle({ raceCode })
    dispatch(setMeetingsRaceCode(raceCode))
  }

  return (
    <RaceCodeNavigationStyled>
      <div>
        <button
          onClick={createRaceCodeToggle('races')}
          className={state.raceCode === 'races' ? LocalConstants.NavActiveClass : ''}
          data-testid='meetings-code-races'
        >
          <Icon type='races' size='3rem' />
        </button>

        <button
          onClick={createRaceCodeToggle('trots')}
          className={state.raceCode === 'trots' ? LocalConstants.NavActiveClass : ''}
          data-testid='meetings-code-trots'
        >
          <Icon type='trots' size='3rem' />
        </button>

        <button
          onClick={createRaceCodeToggle('dogs')}
          className={state.raceCode === 'dogs' ? LocalConstants.NavActiveClass : ''}
          data-testid='meetings-code-dogs'
        >
          <Icon type='dogs' size='3rem' />
        </button>
      </div>

      <div>
        <Link
          to={`/fobracing/FR/${state.raceCode}/${simplifiedDate}?selectiondate=${simplifiedDate}`}
          data-testid='meetings-feature-races-link'
        >
          Futures
        </Link>

        {state.raceCode === 'races' && (
          <Link
            to={`/fobracing/CLG/races/${simplifiedDate}?selectiondate=${simplifiedDate}`}
            data-testid='meetings-jockey-challenge-link'
          >
            Jockey Challenge
          </Link>
        )}

        {state.raceCode === 'dogs' && (
          <Link
            to={`/fobracing/CLG/dogs/${simplifiedDate}?selectiondate=${simplifiedDate}`}
            data-testid='meetings-grey-box-challenge-link'
          >
            Greys Box Challenge
          </Link>
        )}
      </div>
    </RaceCodeNavigationStyled>
  )
}

// ======
// Styles
// ======

const RaceCodeNavigationStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  minHeight: '5rem',
  overflowX: 'auto',

  scrollBehavior: 'smooth',
  WebkitOverflowScrolling: 'touch',
  WebkitTapHighlightColor: 'transparent',

  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  div: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: '0 0.5rem',
  },

  'div:first-of-type': {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    marginRight: 0,
    background: hexColors.white,
    borderRadius: '2rem',
    border: `0.1rem solid ${hexColors.veryLightGrey}`,

    button: {
      border: 0,
      background: 'transparent',
      height: '3rem',
      minWidth: '6rem',
      borderRadius: '1.5rem',
      color: colors.brand.primary,

      [`&.${LocalConstants.NavActiveClass}`]: {
        background: colors.brand.primary,
        color: hexColors.white,
      },
    },
  },

  'div:last-of-type': {
    height: '3rem',

    a: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
      marginRight: '0.5rem',
      borderRadius: '1.5rem',
      border: `0.1rem solid ${hexColors.veryLightGrey}`,
      background: hexColors.white,
      whiteSpace: 'nowrap',
      fontSize: '1.3rem',
      textDecoration: 'none',
      lineHeight: 1,
      color: colors.brand.primary,
    },
  },
})
