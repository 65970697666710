import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IFinisherPricesViewModel } from './IFinisherPricesViewModel'
import { IFieldFinisherPricesViewModel } from './IFieldFinisherPricesViewModel'
import finisherPriceTemplate from './finisher.prices.tpl.html'
import fieldFinisherPriceTemplate from './field.finisher.prices.tpl.html'
import { ObservableFinisher } from '@classic/Betting-v2/Model/Observables/ObservableFinisher'

export function register(): void {
  if (!ko.components.isRegistered('finisher-prices')) {
    ko.components.register('finisher-prices', {
      viewModel: {
        createViewModel: (params: { finisher: ObservableFinisher }): IFinisherPricesViewModel => {
          const viewModel = container.get<IFinisherPricesViewModel>('IFinisherPricesViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template: finisherPriceTemplate,
    })
  }

  if (!ko.components.isRegistered('field-finisher-prices')) {
    ko.components.register('field-finisher-prices', {
      viewModel: {
        createViewModel: (params: {
          finisher: ObservableFinisher
        }): IFieldFinisherPricesViewModel => {
          const viewModel = container.get<IFieldFinisherPricesViewModel>(
            'IFieldFinisherPricesViewModel'
          )
          viewModel.init(params)
          return viewModel
        },
      },
      template: fieldFinisherPriceTemplate,
    })
  }
}
