import React from 'react'
import anime from 'animejs'
import { ToastStateRecord, RemoveToast, ToastItem } from './ToastDriver'
import {
  ToastContainerStyled,
  ToastStyled,
  ToastCloseIconStyled,
  ToastTextStyled,
  ToastSpinnerStyled,
} from './Toast.styles'
import { Icon } from '@mobi/component-library/Common/Icon'

const ANIMATION_DURATION = 400

export class ToastMessage extends React.Component<{ item: ToastItem }> {
  private toastRef = React.createRef<HTMLDivElement>()

  public componentDidMount() {
    const { position, timeout = 0.6 } = this.props.item

    const isPositionTop = position === 'top'
    const isClickable = timeout === 0
    const animateStartPoint = isPositionTop ? '-150%' : '100%'

    anime({
      targets: this.toastRef.current,
      opacity: [0, 1],
      translateY: [animateStartPoint, 0],
      duration: ANIMATION_DURATION,
      direction: isClickable ? 'normal' : 'alternate',
      delay: 200,
      endDelay: isClickable ? undefined : timeout * 500,
      complete: () => {
        if (!isClickable) {
          RemoveToast(this.props.item.id)
        }
      },
    })
  }

  public render() {
    const isClickable = this.props.item.timeout === 0

    return (
      <ToastStyled
        type={this.props.item.type || 'default'}
        ref={this.toastRef}
        onClick={this.handleToastClick}
        data-testid='toast-item'
      >
        <ToastTextStyled>
          {this.props.item.spinner ? <ToastSpinnerStyled /> : null}
          {this.props.item.message}
        </ToastTextStyled>
        {isClickable && (
          <ToastCloseIconStyled>
            <Icon type='cross' size='1.5rem' />
          </ToastCloseIconStyled>
        )}
      </ToastStyled>
    )
  }

  private handleToastClick = () => {
    if (this.props.item.timeout !== 0) return

    const isPositionTop = this.props.item.position === 'top'
    const animateStartPoint = isPositionTop ? '-150%' : '100%'

    anime({
      targets: this.toastRef.current,
      opacity: [1, 0],
      translateY: [0, animateStartPoint],
      duration: ANIMATION_DURATION,
      direction: 'normal',
      complete: () => {
        RemoveToast(this.props.item.id)
      },
    })
  }
}

export const ToastComponent: React.FC<{
  items: ToastStateRecord['items']
  isHeaderVisible?: boolean
}> = ({ items, isHeaderVisible }) => {
  if (items.isEmpty()) return null

  const itemsOnTop = items.filter(item => item.position === 'top')
  const itemsOnBottom = items.filter(item => item.position === 'bottom')

  return (
    <>
      {!itemsOnTop.isEmpty() && (
        <ToastContainerStyled isHeaderVisible={isHeaderVisible}>
          {itemsOnTop.map(item => (
            <ToastMessage key={item.id} item={item} />
          ))}
        </ToastContainerStyled>
      )}

      {!itemsOnBottom.isEmpty() && (
        <ToastContainerStyled bottom={true}>
          {itemsOnBottom.map(item => (
            <ToastMessage key={item.id} item={item} />
          ))}
        </ToastContainerStyled>
      )}
    </>
  )
}
