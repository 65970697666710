import { store } from '@core/Store'
import { closeModal, openModal } from '@core/Components/Modal'
import { PromptBetslipChange } from '../PromptBetslipChange'

export type PromptBetslipChangeSelecton = 'cancel' | 'add' | 'replace'

export function promptUserAndReturnDecision() {
  return new Promise<PromptBetslipChangeSelecton>(resolve => {
    const USER_PROMPT_ID = 'user-prompt-for-betslip-clear'

    // Callback to capture decision
    const handleSelection = (decision: PromptBetslipChangeSelecton) => {
      store.dispatch(closeModal({ id: USER_PROMPT_ID }))
      resolve(decision)
    }

    // Present prompt to user
    store.dispatch(
      openModal({
        id: USER_PROMPT_ID,
        modalComponent: PromptBetslipChange,
        modalComponentProps: {
          handleSelection,
        },
        isDismissable: false,
      })
    )
  })
}
