import React from 'react'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'

// =========
// Constants
// =========

const LOCAL_PORTRAIT_KEY = 'VISION_POSITION_PORTRAIT_KEY'
const LOCAL_LANDSCAPE_KEY = 'VISION_POSITION_LANDSCAPE_KEY'
const DEFAULT_POSITION = { x: 0, y: 0 }

// =========
// Main Hook
// =========

export const usePlayerState = (): UsePlayerStateReturn => {
  const [width, setWidth] = React.useState<number>(() => getWidth())
  const [{ x, y }, setPosition] = React.useState<PositionState>(() => getPosition())

  const [orientation, setOrientation] = React.useState<OrientationState>(() => getOrientation())

  const timerIdRef = React.useRef<number>()

  // Store Player State in Local Storage
  React.useEffect(() => {
    setPlayerStateInLocalStorage({ x, y, width })
  }, [x, y, width])

  // Handle Screen Orientation Change
  React.useEffect(() => {
    setPosition(getPosition())
    setWidth(getWidth())
  }, [orientation])

  // Listen to Screen Orientation Change Event
  React.useEffect(() => {
    const handleOrientationChange = () => {
      clearTimeout(timerIdRef.current)
      // Wait for orientation change to complete
      timerIdRef.current = window.setTimeout(() => {
        setOrientation(getOrientation())
      }, 500)
    }
    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  const handleSetPosition = (state: PositionState | 'RESET') => {
    setPosition(state === 'RESET' ? DEFAULT_POSITION : state)
  }

  const handleSetWidth = (state: number | 'RESET') => {
    setWidth(state === 'RESET' ? calculateDefaultWidth() : state)
  }

  // usePlayerState Hook Return
  return [{ x, y, width }, handleSetPosition, handleSetWidth]
}

// =======
// Helpers
// =======

function setPlayerStateInLocalStorage({ x, y, width }: UsePlayerStateReturn[0]) {
  setInLocalStorage(
    getLocalStorageKey(),
    JSON.stringify({
      key: getScreenSizeString(),
      x,
      y,
      width,
    })
  )
}

function getScreenSizeString() {
  const height = window.screen?.height || window.outerHeight
  return `${window.outerWidth}_${height}`
}

function getLocalStorageKey() {
  return isScreenLandscape() ? LOCAL_LANDSCAPE_KEY : LOCAL_PORTRAIT_KEY
}

function getPosition(): PositionState {
  const localStorage = getFromLocalStorage(getLocalStorageKey())
  if (!localStorage) {
    return DEFAULT_POSITION
  }
  const { x, y, key } = JSON.parse(localStorage)
  if (key !== getScreenSizeString()) {
    return DEFAULT_POSITION
  }
  return { x, y }
}

function getWidth(): number {
  const localStorage = getFromLocalStorage(getLocalStorageKey())
  if (!localStorage) {
    return calculateDefaultWidth()
  }
  const { width, key } = JSON.parse(localStorage)
  if (key !== getScreenSizeString()) {
    return calculateDefaultWidth()
  }
  return width
}

function getOrientation() {
  return isScreenLandscape() ? 'landscape' : 'portrait'
}

function calculateDefaultWidth(): number {
  const viewportWidth = Math.min(document.documentElement.clientWidth, window.innerWidth)
  const scale = isScreenLandscape() ? 0.4 : 0.8
  return Math.floor(viewportWidth * scale)
}

function isScreenLandscape() {
  if (window.matchMedia('(orientation: landscape)')?.matches) {
    return true
  }

  const isLandscape = (angle: number) => [-90, 90].includes(angle)
  const orientation = window.screen?.orientation?.angle || window.orientation

  if (typeof orientation === 'number') {
    return isLandscape(orientation)
  }

  if (typeof orientation === 'string') {
    return (orientation as string).toLowerCase() === 'landscape'
  }

  return false
}

// =====
// Types
// =====

export interface PositionState {
  x: number
  y: number
}

type UsePlayerStateReturn = [
  PositionState & { width: number },
  (x: PositionState | 'RESET') => void,
  (x: number | 'RESET') => void,
]

type OrientationState = 'landscape' | 'portrait'
