import styled, { type CSSObject } from '@emotion/styled'
import { colors, radius, font } from '@mobi/component-library/Theme/Common'

export const TooltipStyled = styled.div<TooltipStyledProps>(
  {
    zIndex: 1,

    '> div': {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem',
      borderRadius: radius.md,
      marginRight: '1rem',
      boxShadow: '0 10px 15px -3px rgba(0,0,0,0.2)',
      maxWidth: '32rem',
      fontWeight: font.weight.regular,
      ...font.size.md,

      animationDuration: '0.2s',
      animationName: 'animateIn',
      animationTimingFunction: 'cubic-bezier(.26, .53, .74, 1.48)',

      '> div': {
        position: 'absolute',
        width: '0.8rem',
        height: '0.8rem',
        backgroundColor: 'inherit',
        transform: 'rotate(45deg)',
      },

      svg: {
        opacity: 0.6,
        padding: '0.75rem',
      },

      strong: {
        display: 'block',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        paddingRight: '0.5rem',
        fontSize: '1.2rem',
      },

      a: {
        color: 'inherit',
      },
    },

    '@keyframes animateIn': {
      '0%': { opacity: 0, transform: 'scale(0.8, 0.8)' },
      '100%': { opacity: 1, transform: 'scale(1, 1)' },
    },
  },
  ({ color = 'blue' }) => ({
    '> div': {
      ...tooltipColors[color],
    },
  })
)

export const tooltipColors: Record<TooltipStyledProps['color'], CSSObject> = {
  blue: { backgroundColor: colors.mariner[600], color: colors.white },
  black: { backgroundColor: colors.black, color: colors.white },
}

export const TooltipReferenceContainer = styled.div({ display: 'grid' })

export type TooltipColor = 'blue' | 'black'

export type TooltipStyledProps = {
  /**
   * The color variant of the tooltip
   */
  color: TooltipColor
}
