import React from 'react'
import { AddingToBetslip, QuickbetClosed } from '@core/Areas/Quickbet/signals'
import { QuickbetState, state$ as quickbetState$ } from '@core/Areas/Quickbet/driver'

export const useClearSelectionOnPlacement = (clearSelection: () => void) => {
  React.useEffect(() => {
    const subscription = QuickbetClosed.signal$
      .withLatestFrom(quickbetState$, (_, quickbetState) => ({ quickbetState }))
      .subscribe(x => {
        const quickbetState: QuickbetState = x.quickbetState.toJS()
        if (quickbetState.betPlaced) clearSelection()
      })
    const subscriptionForBetslip = AddingToBetslip.signal$.subscribe(() => {
      clearSelection()
    })
    return () => {
      subscription.dispose()
      subscriptionForBetslip.dispose()
    }
  }, [clearSelection])
}
