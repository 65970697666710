import React from 'react'
import { SearchTextLabelStyled, SearchTextLabelFontSize } from './SearchTextLabelStyled.styles'

interface SearchTextLabelProps {
  children: React.ReactNode
  size?: SearchTextLabelFontSize
}

export function SearchTextLabel({ children, size }: SearchTextLabelProps) {
  return <SearchTextLabelStyled size={size}>{children}</SearchTextLabelStyled>
}
