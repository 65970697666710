import React from 'react'
import styled from '@emotion/styled'
import { colors } from '@mobi/component-library/Theme/Common'

export const BusyIndicator: React.FC<{
  isBusy: boolean
  color?: string
}> = ({ isBusy, color = colors.white }) => {
  if (!isBusy) {
    return null
  }
  return <LoaderBarsStyled role='progressbar' aria-label='Loading' style={{ background: color }} />
}

// ======
// Styles
// ======

const LoaderBarsStyled = styled.div<{ color?: string }>({
  animation: 'animate 0.6s infinite ease-in-out',
  width: '0.4rem',
  height: '1.6rem',
  margin: '0 auto',
  position: 'relative',
  fontSize: '1rem',
  transform: 'translateZ(0)',
  animationDelay: '-0.16s',
  background: 'inherit',

  '&::after, &::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    width: '0.4rem',
    height: '1.6rem',
    animation: 'animate 1s infinite ease-in-out',
    background: 'inherit',
  },

  '&::before': {
    left: '-0.7rem',
    animationDelay: '-0.32s',
  },

  '&::after': {
    left: '0.7rem',
  },

  '@keyframes animate': {
    '40%': { transform: 'scaleY(1.25)' },
    '0%, 80%, 100%': { transform: 'scaleY(1)' },
  },
})
