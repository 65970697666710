import { GridCell } from '@mobi/component-library/Common/Grid'
import { Money } from '@core/Components/Text/Money'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

interface BonusBetListItemButtonProps {
  selected: boolean
  disabled?: boolean
  isBonusCash: boolean
}

export const BonusBetListItemButtonStyled = styled('button')<BonusBetListItemButtonProps>(
  {
    padding: '0.75rem 1.25rem 0.75rem 1.25rem',
    width: '100%',
    marginBottom: '0.5rem',
  },

  ({ selected, disabled, isBonusCash }) => ({
    background:
      selected && !disabled
        ? isBonusCash
          ? hexColors.mariner
          : hexColors.orange
        : hexColors.white,
    color: selected && isBonusCash ? hexColors.white : hexColors.black,
    border: `0.1rem solid ${hexColors.veryLightGrey}`,
  }),

  ({ disabled }) =>
    disabled && {
      opacity: 0.3,
    }
)

export const BonusBetLabelStyled = styled(GridCell)<{ isBonusCash?: boolean; selected?: boolean }>(
  {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ({ isBonusCash, selected }) => ({
    color: isBonusCash ? (selected ? hexColors.white : hexColors.mariner) : undefined,
  })
)

export const ExpiryLabelStyled = styled(GridCell)<{ isBonusCash: boolean; selected?: boolean }>(
  {
    fontSize: '1rem',
    fontWeight: 'normal',
    textAlign: 'center',
    alignItems: 'center',
  },
  ({ isBonusCash, selected }) => ({
    color: isBonusCash ? (selected ? hexColors.white : hexColors.mariner) : undefined,
  })
)

export const AmountLabelStyled = styled(GridCell)<{ isBonusCash: boolean; selected?: boolean }>(
  {
    textAlign: 'right',
    alignItems: 'center',
  },
  ({ isBonusCash, selected }) => ({
    color: isBonusCash ? (selected ? hexColors.white : hexColors.mariner) : undefined,
  })
)

export const MoneyStyled = styled(Money)({
  fontSize: '1.2rem',
  fontWeight: 'bold',
})
