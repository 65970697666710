import jQuery from 'jquery'
import * as ko from 'knockout'
import * as common from '../Common'
import * as msgBus from '../Services/MessageBusService'
import * as navService from '../Services/NavigationService'
import * as navLoading from './NavigationLoading'
import { SubAppRouteManager, RouteResult } from './SubAppRouter'
import * as navigationDriver from '@core/State/Navigation'
import { store } from '@core/Store'
import { HOME_ROUTE_OVERRIDE } from '@core/Settings'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import * as analytics from '../Analytics/Analytics'
import * as screenNameMatcher from './ScreenNameMatcher'

let lastRequestedUrl = ''

export const subAppRouting = new SubAppRouteManager()
export const isHome = () => isHomeUrl(window.location.hash)

export function init() {
  common.bindLiveEvent('click', '[data-nav]', function (this: HTMLAnchorElement) {
    store.dispatch(toggleIsDropDownOpen(false))
    nav(this.href)
  })
}

export function getHashFragment(url?: string) {
  url = url || window.location.href
  return url.replace(/^[^#]*#?(.*)$/, '$1')
}

// Standard navigation but does not change the URL hash
export function navChange(url: string) {
  navLoading.hideLoading()
  nav(url)
  location.hash = getHashFragment(url)
}

export function hashChange() {
  if (lastRequestedUrl !== getHashFragment(window.location.hash)) {
    msgBus.publish('nav.hashchange')
    nav(window.location.href)
  }
}

export function setBackUrl(backUrl: string) {
  navService.setBackUrl(backUrl)
  const btn = jQuery('a#backButton')
  if (btn.length) {
    btn.show().attr('href', navService.peekUrl())
  }
}

/** Handles legacy navigation
 * @deprecated Use react router instead */
export function nav(url: string) {
  const toUrl = getHashFragment(url)
  const navConfig = configFor(toUrl)

  if (navConfig.useNavStack) {
    const fromUrl = getHashFragment(location.href)

    if (fromUrl.substring(0, toUrl.length) != toUrl) {
      const returnUrl = navConfig.returnUrl || fromUrl
      navService.pushUrl('#' + returnUrl)
    }
  } else {
    navService.clearUrlStack()
  }

  const fragment = getHashFragment(url)
  updateNavigationInDataLayer(fragment)

  msgBus.publish('nav.nav', url)
  return _performNav(url)
}

// =============
// Local Helpers
// =============

function _performNav(url: string) {
  let actualUrl = getHashFragment(url)
  lastRequestedUrl = actualUrl

  const content = document.getElementById('content')
  if (content) ko.cleanNode(content)

  navService.pruneUrl('#' + actualUrl)

  if (isHomeUrl(actualUrl)) {
    actualUrl = HOME_ROUTE_OVERRIDE
  }

  subAppRouting
    .execute(actualUrl)
    .then(routeResult => {
      switch (routeResult) {
        case RouteResult.IsMatchSuccess:
          navigationDriver.NavigatedTo({ url: actualUrl })
          jQuery('#ajax-error').hide()
          break
        case RouteResult.IsMatchError:
          jQuery('#ajax-error').show()
          break
        default:
          window.location.href = ''
      }
    })
    .catch(() => {
      jQuery('#ajax-error').show()
    })
}

// Updates the data layer based upon a change to the url fragment
function updateNavigationInDataLayer(urlFragment: string) {
  const currentScreen = screenNameMatcher.lookupScreenName(urlFragment)
  analytics.navigateScreen(currentScreen.code, currentScreen.name)
}

const NAV_CONFIG = [
  { urlPattern: '^betbuilder.*$', useNavStack: true, returnUrl: null },
  { urlPattern: '^account.*$', useNavStack: true, returnUrl: null },
  { urlPattern: '^password.*$', useNavStack: true, returnUrl: null },
  { urlPattern: '^settings.*$', useNavStack: true, returnUrl: null },
  { urlPattern: '^cms.*$', useNavStack: true, returnUrl: null },
  { urlPattern: '^.*$', useNavStack: false, returnUrl: null },
]

function configFor(url: string) {
  for (var i = 0; i < NAV_CONFIG.length; ++i) {
    var config = NAV_CONFIG[i]
    var pattern = new RegExp(config.urlPattern)
    if (pattern.test(url)) {
      return config
    }
  }
  return { urlPattern: null, useNavStack: false, returnUrl: null }
}

function isHomeUrl(url: string): boolean {
  return url === '' || url === '#' || url.search('^[?].*$') > -1
}
