import styled from '@emotion/styled'

export const TransactionRowStyled = styled('dl')({
  display: 'flex',
  margin: '0.5rem 0',
  lineHeight: '1.2rem',

  '& > dt': {
    display: 'inline-block',
    flex: 1,
  },

  '& > dd': {
    display: 'inline-block',
    flex: 1,
  },
})
