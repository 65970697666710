import { FcmTokenUpdated } from '@core/State/Device'
import { GetNotificationRaceKeys } from '@core/State/Device/notificationDriver'
import { get } from '@classic/Foundation/Services/ApiService'
import { subscribeToNative, isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'

// Get FCM Token from native app then call API / registrations
// and get all notification registrations for that FCM Token
export function startRaceKeyNotification() {
  if (!isReactNativeApp) return
  const { dispose } = subscribeToNative(
    'RESPONSE_FCM_REGISTRATION_TOKEN_SUCCESS',
    (data: { token: string }) => {
      dispose()
      handleFCMToken(data)
    }
  )
  sendToNative('REQUEST_FCM_REGISTRATION_TOKEN')
}

function handleFCMToken(data: { token: string }) {
  const fcmToken = data.token

  FcmTokenUpdated({ fcmToken, fcmTokenStatus: 'Success' })

  get<string[]>({ url: `/api/notification/registrations?token=${fcmToken}` }).then(raceKeys => {
    GetNotificationRaceKeys({ raceKeys })
  })
}
