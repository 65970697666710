import React from 'react'
import { Flex } from '@mobi/component-library/Common/Flex'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const SkeletonLoader = () => {
  return (
    <div style={{ padding: '0rem 1rem', overflow: 'hidden', backgroundColor: 'white' }}>
      <Flex flexDirection='column' justifyContent='flex-start'>
        {/* Bet Selectors */}
        <BetSelectorsSkeleton />
        {/* Bet Informers Etc */}
        <BetInformersSkeleton />
        {/* Starter Headers */}
        <StarterHeadersSkeleton />
        {/* Starter */}
        <StarterSkeleton />
        <StarterSkeleton />
        <StarterSkeleton />
        <StarterSkeleton />
        <StarterSkeleton />
        <StarterSkeleton />
        <StarterSkeleton />
      </Flex>
    </div>
  )
}

const BetSelectorsSkeleton = () => (
  <div style={{ width: '105%', margin: '1.25rem 0' }}>
    <Flex justifyContent='flex-start' gap='1rem'>
      <LoadingPlaceholder width='8rem' height='1.5rem' borderRadius={radius.lg} />
      <LoadingPlaceholder width='5rem' height='1.5rem' borderRadius={radius.lg} />
      <LoadingPlaceholder width='7rem' height='1.5rem' borderRadius={radius.lg} />
      <LoadingPlaceholder width='5rem' height='1.5rem' borderRadius={radius.lg} />
      <LoadingPlaceholder width='15rem' height='1.5rem' borderRadius={radius.lg} />
    </Flex>
  </div>
)

const BetInformersSkeleton = () => (
  <div style={{ width: '105%', margin: '1.5rem 0' }}>
    <Flex justifyContent='flex-start' gap='1rem'>
      <LoadingPlaceholder width='13rem' height='3rem' borderRadius={radius.lgx3} />
      <LoadingPlaceholder width='15rem' height='3rem' borderRadius={radius.lgx3} />
      <LoadingPlaceholder width='8rem' height='3rem' borderRadius={radius.lgx3} />
      <LoadingPlaceholder width='5rem' height='3rem' borderRadius={radius.lgx3} />
    </Flex>
  </div>
)

const StarterHeadersSkeleton = () => (
  <div style={{ margin: '0.25rem 0' }}>
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex justifyContent='flex-start' alignItems='center' gap='1rem'>
        <LoadingPlaceholder width='2rem' height='2rem' borderRadius={radius.sm} />
        <LoadingPlaceholder width='8rem' height='3rem' borderRadius={radius.lgx2} />
      </Flex>
      <Flex justifyContent='flex-end' alignItems='center' gap='0.5rem'>
        <LoadingPlaceholder width='5rem' height='2rem' borderRadius={radius.lg} />
        <LoadingPlaceholder width='5rem' height='2rem' borderRadius={radius.lg} />
      </Flex>
    </Flex>
  </div>
)

const StarterSkeleton = () => (
  <div style={{ margin: '1.5rem 0' }}>
    <Flex flexDirection='row' justifyContent='space-between'>
      <Flex flexDirection='row' gap='0.75rem'>
        <Flex flexDirection='column' gap='0.25rem'>
          <LoadingPlaceholder width='1.5rem' height='1.5rem' borderRadius={radius.sm} />
          <LoadingPlaceholder width='2rem' height='3rem' borderRadius={radius.lg} />
        </Flex>
        <Flex flexDirection='column' gap='0.75rem'>
          <LoadingPlaceholder width='12rem' height='1.5rem' borderRadius={radius.lg} />
          <Flex flexDirection='row' gap='2rem'>
            <LoadingPlaceholder width='4rem' height='1rem' borderRadius={radius.lg} />
            <LoadingPlaceholder width='3rem' height='1rem' borderRadius={radius.lg} />
            <LoadingPlaceholder width='4rem' height='1rem' borderRadius={radius.lg} />
          </Flex>
          <LoadingPlaceholder width='15rem' height='1rem' borderRadius={radius.lg} />
          <LoadingPlaceholder width='8rem' height='1rem' borderRadius={radius.lg} />
        </Flex>
      </Flex>
      <Flex flexDirection='row' justifyContent='flex-end' gap='0.5rem'>
        <LoadingPlaceholder width='5rem' height='5rem' borderRadius={radius.lg} />
        <LoadingPlaceholder width='5rem' height='5rem' borderRadius={radius.lg} />
      </Flex>
    </Flex>
  </div>
)
