import styled from '@emotion/styled'

export const JackpotTitleStyled = styled('strong')({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '1em',
  margin: '0 0 0.2em',
})

export const JackpotTextStyled = styled('p')({
  fontSize: '1em',
  margin: '0 0 0.2em',
  padding: 0,
})
