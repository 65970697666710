import styled from '@emotion/styled'

export const CheckboxElementStyled = styled('div')({
  position: 'relative',
})

interface CheckboxIconProps {
  includePadding?: boolean
}

export const CheckboxIconStyled = styled('div')<CheckboxIconProps>(
  {
    position: 'absolute',
  },
  ({ includePadding = true }) => ({
    top: includePadding ? '1.1rem' : '0',
  })
)

export const CheckboxStyled = styled('input')({
  visibility: 'hidden',
  margin: 0,
  width: 0,
  position: 'absolute',
})

interface CheckboxLabelStyledProps {
  color: string
  includePadding?: boolean
}

export const CheckboxLabelStyled = styled('label')<CheckboxLabelStyledProps>(
  {
    fontSize: '1.2rem',
    lineHeight: 'normal',
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
  },

  ({ color, includePadding = true }) => ({
    color,
    padding: includePadding ? '1.3rem 0 1.3rem 2.5rem' : '0.1em 0 0 2.5rem',
  })
)
