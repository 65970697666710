import React from 'react'
import type { Acceptor } from '@mobi/betslip/types'

/** // TODO: Completely remove this technique, use modern single image URL */
export const QuickbetSilkImage: React.FC<{ acceptor?: Acceptor }> = ({ acceptor }) => {
  if (!acceptor) return null

  return (
    <div style={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%', background: 'red' }} />
  )
}
