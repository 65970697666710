import jQuery from 'jquery'
import type { IUrlProvider } from '../Utils/IUrlProvider'
import { IBettingService } from './IBettingService'
import { injectable, inject } from 'inversify'
import { ILegacyBetModel } from '../Model/ILegacyBetModel'
import { ILegacyMultiLegModel } from '../Model/ILegacyMultiLegModel'

@injectable()
export class BettingService implements IBettingService {
  private urlProvider: IUrlProvider

  constructor(@inject('IUrlProvider') urlProvider: IUrlProvider) {
    this.urlProvider = urlProvider
  }

  getCost(betViewModelDto: ILegacyBetModel): JQueryXHR {
    var request = this.createRequest(
      this.urlProvider.getBetCost,
      betViewModelDto,
      'html',
      'application/json',
      false
    )
    return request
  }

  propose(betViewModelDto: ILegacyBetModel): JQueryXHR {
    var request = this.createRequest(
      this.urlProvider.proposeBet,
      betViewModelDto,
      'html',
      'application/json',
      true
    )
    return request
  }

  commit(betViewModelDto: ILegacyBetModel): JQueryXHR {
    var request = this.createRequest(
      this.urlProvider.commitBet,
      { betViewModel: betViewModelDto },
      'html',
      'application/json',
      true
    )
    return request
  }

  addLegToMultiBet(legacyMultiLegModel: ILegacyMultiLegModel): JQueryXHR {
    var request = this.createRequest(
      this.urlProvider.addLegToMultiBet,
      legacyMultiLegModel,
      '',
      'application/json',
      false
    )
    return request
  }

  private createRequest(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    betViewModelDto: any,
    dataType: string,
    contentType: string,
    includeAntiForgeryToken: boolean
  ): JQueryXHR {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let headers: { [key: string]: any } = {}
    if (includeAntiForgeryToken) {
      let token = jQuery('#anti-forgery [name=__RequestVerificationToken]').val()
      if (token != undefined && (token as unknown as string[]).length > 0) {
        headers['__RequestVerificationToken'] = token
      }
    }
    var request = jQuery.ajax({
      url: url,
      data: JSON.stringify(betViewModelDto),
      type: 'POST',
      dataType: dataType,
      contentType: contentType,
      headers: headers,
    })
    return request
  }
}
