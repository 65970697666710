import React from 'react'
import { PaypalLogo, GooglePayLogo, ApplePayLogo, BPayLogo } from '../Icons'
import type { PaymentMethodsAll } from '../types'
import { Icon } from '../../Common/V2'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'

type Props = {
  paymentMethod: PaymentMethodsAll
}

export const PaymentMethodLogo = ({ paymentMethod }: Props) => {
  switch (paymentMethod) {
    case 'CreditCard':
      return <Icon name='LineCreditCard02' size='2.4rem' />
    case 'PayPal':
      return <PaypalLogo />
    case 'GooglePay':
      return <GooglePayLogo />
    case 'ApplePay':
      return <ApplePayLogo />
    case 'BPay':
      return <BPayLogo />
    case 'Cash':
      return <Icon name='LineBankNote03' size='2.4rem' />
    case 'ScanMyBet':
      return <Icon name='CustomScanMyBet' size='2.4rem' />
    case 'PayID':
      return <SvgLazy name='PayIDLogo' color='black' width='4.2rem' />
  }
}
