import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '../../../../Components/NoticeBox'

interface ReviewChangesMessageProps {
  includeCancellationWarning: boolean
}

export function ReviewChangesMessage({ includeCancellationWarning }: ReviewChangesMessageProps) {
  const subtitle = includeCancellationWarning ? 'Bets cannot be cancelled once confirmed.' : ''
  return (
    <NoticeBox
      title='Please review changes in your betslip.'
      subtitle={subtitle}
      noticeBoxType={NoticeBoxTypes.Warning}
      hasBorder={false}
    />
  )
}
