import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { hexColors } from '@mobi/settings'
import {
  InteractiveRulesListStyled,
  SingleRuleStyled,
  TickedIconStyled,
} from './InteractiveRules.styles'

export interface RulesThemeSettings {
  background?: string
  text: {
    color: string
    size: string
  }
  tickIcon: {
    size: string
  }
  color: string
  margin: string
  padding: string
  size: string
  top: string
}

export interface RuleProps {
  description: string
  isRuleMet: boolean
  key: string
}
interface InteractiveRulesProps {
  theme?: RulesThemeSettings
  rules: RuleProps[]
}

export function InteractiveRules({ theme, rules }: InteractiveRulesProps): JSX.Element {
  const defaultTheme = {
    text: {
      color: hexColors.dimGrey,
      size: '1.4rem',
    },
  }

  return (
    <ThemeProvider theme={theme ? theme : defaultTheme}>
      <InteractiveRulesListStyled>{Rules(rules)}</InteractiveRulesListStyled>
    </ThemeProvider>
  )
}

function Rules(rules: RuleProps[]) {
  return rules.map(rule => {
    return (
      <SingleRuleStyled data-tid={rule.key} isHighlighted={rule.isRuleMet} key={rule.key}>
        <TickedIconStyled isHighlighted={rule.isRuleMet} type='tickCircled' />
        {rule.description}
      </SingleRuleStyled>
    )
  })
}
