import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '../../../../Components/NoticeBox'

export function ConfirmMessage() {
  return (
    <NoticeBox
      title={'Bets cannot be cancelled once confirmed.'}
      noticeBoxType={NoticeBoxTypes.Info}
      hasBorder={false}
    />
  )
}
