import React, { type ReactNode, type FC } from 'react'
import { AlertBoxActionsStyled, AlertBoxIconStyled, AlertBoxContentStyled } from './AlertBox.styled'
import { Button, type ButtonColor } from '../Buttons'
import { Modal as ModalOverlay } from '../../../Overlays/Modal'
import type { ActionOrientation } from './types'

type Props = {
  /**
   * Whether the alert box is open
   */
  isOpen: boolean
  /**
   * The title within the alert box
   */
  title: string
  /**
   * The alert box text
   */
  text: ReactNode
  /**
   * The svg icon to display
   */
  icon?: JSX.Element
  /**
   * The color of the confirm action
   */
  confirmColor?: ButtonColor
  /**
   * The text of the confirm action
   */
  confirmText: string
  /**
   * The callback for the confirm action
   */
  onConfirm: VoidFunction
  /**
   * The color of the cancel action
   */
  cancelColor?: ButtonColor
  /**
   * The text of the cancel action
   */
  cancelText: string
  /**
   * The callback for the cancel action
   */
  onCancel: VoidFunction
  /**
   * Which direction the actions are oriented
   *
   * These values align to flex directions. `vertical` maps to `column` and horizontal to `row`. Adding the `-reverse` suffix will make them `column-reverse` and `row-reverse` respectively.
   */
  actionOrientation?: ActionOrientation
}

export const AlertBox: FC<Props> = ({
  isOpen,
  title,
  text,
  icon,
  confirmColor = 'primary',
  confirmText,
  onConfirm,
  cancelColor = 'destructive',
  cancelText,
  onCancel,
  actionOrientation = 'vertical',
}) => {
  return (
    <ModalOverlay isOpen={isOpen} dismissStatus='disallowed'>
      <AlertBoxContentStyled>
        {icon && <AlertBoxIconStyled>{icon}</AlertBoxIconStyled>}

        <header>
          <h1>{title}</h1>
        </header>

        <div>{text}</div>

        <AlertBoxActionsStyled orientation={actionOrientation}>
          <Button color={confirmColor} onClick={onConfirm}>
            {confirmText}
          </Button>

          <Button color={cancelColor} onClick={onCancel}>
            {cancelText}
          </Button>
        </AlertBoxActionsStyled>
      </AlertBoxContentStyled>
    </ModalOverlay>
  )
}

AlertBox.displayName = 'AlertBox'
