import { SignalEmitter, attachDriver, createSignal } from 'rwwa-rx-state-machine'
import { StateMap } from 'typings/immutable'
import { fromJS } from 'immutable'

export const RaceBettingPageMysterySelection = createSignal<string>(
  'RaceBettingPageMysterySelection'
)

export const ClearRaceBettingPageMysterySelection = createSignal(
  'ClearRaceBettingPageMysterySelection'
)

export interface MysteryRaceBettingPageWrapperState {
  selectedKey: string | null
}
export type MysteryStateMap = StateMap<MysteryRaceBettingPageWrapperState>

export const defaultState: Readonly<MysteryRaceBettingPageWrapperState> = {
  selectedKey: null,
}

export function mysteryRaceBettingPageWrapperDriver(
  state = fromJS(defaultState),
  signal: { tag: SignalEmitter<string>; data: string }
): MysteryStateMap {
  switch (signal.tag) {
    case RaceBettingPageMysterySelection: {
      const key: string = signal.data
      const selectedKey = state.toJS().selectedKey

      const newSelectedKeyValue = key === selectedKey ? null : key

      const newState = state.merge({
        selectedKey: newSelectedKeyValue,
      })
      return newState
    }
    case ClearRaceBettingPageMysterySelection: {
      const newState = state.merge({
        ...defaultState,
      })
      return newState
    }

    default:
      return state
  }
}

export const mysteryRaceBettingPageWrapperState$ = attachDriver<MysteryStateMap>({
  driver: mysteryRaceBettingPageWrapperDriver,
  path: 'mystery-race-betting-page-wrapper-dDriver',
})
