import React from 'react'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Pagination } from '@core/Components/Pagination'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { RecoverFooter } from '@core/Areas/Login/Components'
import {
  Step1AccountNumberInput,
  Step2SendVerification,
  Step3VerifyCode,
  Step4SetNewPassword,
} from './Components'
import { usePasswordReset } from './hooks/usePasswordReset'
import type { useLoginState } from '@core/Areas/Login/hooks/useLoginState'

export const PasswordReset: React.FC<PasswordResetProps> = ({
  accountNumber: initialAccountNumber,
  setWorkflowState,
  handleSignUp,
}) => {
  const { state, handlers } = usePasswordReset({
    initialAccountNumber,
    setWorkflowState,
  })

  const isValid =
    (state.currentStep === 1 && !!state.accountNumber) ||
    (state.currentStep === 2 && !!state.selectedDeliveryMethod) ||
    (state.currentStep === 3 && !!state.validationCode)
  const shouldDisableNext = state.isBusy || !isValid

  const handleRecoverAccountNumber = () => setWorkflowState('Account Number Recovery')
  const handleReturnToLogin = () => setWorkflowState('Login')

  return (
    <>
      <div>
        <Pagination dotCount={4} index={state.currentStep - 1} />
      </div>

      {state.currentStep === 1 && (
        <Step1AccountNumberInput
          accountNumber={state.accountNumber}
          onAccountNumberChange={handlers.onAccountNumberChange}
          handleRecoverAccountNumber={handleRecoverAccountNumber}
        />
      )}

      {state.currentStep === 2 && (
        <Step2SendVerification
          deliveryMethods={state.deliveryMethods}
          selectedDeliveryMethod={state.selectedDeliveryMethod}
          onDeliveryMethodChange={handlers.onDeliveryMethodChange}
        />
      )}

      {state.currentStep === 3 && (
        <Step3VerifyCode
          deliveryMethods={state.deliveryMethods}
          selectedDeliveryMethod={state.selectedDeliveryMethod}
          validationCode={state.validationCode}
          onValidationCodeChange={handlers.onValidationCodeChange}
        />
      )}

      {state.currentStep === 4 ? (
        <Step4SetNewPassword
          handleReturnToLogin={handleReturnToLogin}
          selectedDeliveryMethod={state.selectedDeliveryMethod}
          accountNumber={state.accountNumber}
          validationCode={state.validationCode}
          currentValidationCodeRef={state.currentValidationCodeRef}
        />
      ) : (
        <>
          {state.errorMessage && (
            <NoticeBoxSingle
              title={state.errorMessage}
              noticeBoxType={NoticeBoxTypes.Error}
              testId='password-reset-error'
              hasBorder
            />
          )}

          <Grid padding='0.5rem'>
            <GridCell width='50%'>
              <ButtonBlock
                data-testid='password-reset-back'
                color='secondary'
                onClick={handlers.backClick}
                disabled={state.isBusy}
              >
                Back
              </ButtonBlock>
            </GridCell>

            <GridCell width='50%'>
              <ButtonBlock
                id='submit-next'
                testId='password-reset-next'
                color='primary'
                onClick={handlers.nextClick}
                disabled={shouldDisableNext}
              >
                {state.isBusy ? <SpinnerInlineStyled size={2} /> : 'Next'}
              </ButtonBlock>
            </GridCell>
          </Grid>

          <RecoverFooter isSignUpVisible={state.currentStep === 1} handleSignUp={handleSignUp} />
        </>
      )}
    </>
  )
}

// =====
// Types
// =====

interface PasswordResetProps {
  accountNumber: string
  setWorkflowState: ReturnType<typeof useLoginState>['handlers']['setWorkflow']
  handleSignUp(): void
}
