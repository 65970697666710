import React, { type FC } from 'react'
import { useDispatch } from 'react-redux'
import { openDepositModal } from '@core/Areas/Deposit'
import {
  DepositButtonComponent,
  EditBetButtonComponent,
} from '@core/Areas/Quickbet/Components/Buttons/Buttons'
import { ChangeInvestment } from '@core/Areas/Quickbet/signals'
import { closeQuickDeposit } from '@core/Areas/QuickDeposit/Store'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Text } from '@core/Components/Text'
import type { Reason } from '@mobi/account/Areas/Deposit/Components'

type Props = {
  accountDepositAvailable: boolean
  unavailabilityReason?: Reason
}

export const QuickDepositUnavailableActions: FC<Props> = ({
  accountDepositAvailable,
  unavailabilityReason,
}) => {
  const dispatch = useDispatch()

  const depositButtonText =
    unavailabilityReason === 'selected_card_is_a_credit_card' ? 'Other Deposit' : 'Deposit'

  return (
    <Grid padding='0.5rem'>
      <GridCell>
        <EditBetButtonComponent
          onClick={() => {
            ChangeInvestment()
            dispatch(closeQuickDeposit())
          }}
        />
      </GridCell>
      <GridCell>
        <DepositButtonComponent
          color='primary'
          isDisabled={!accountDepositAvailable}
          onClick={() => {
            ChangeInvestment()
            openDepositModal('quick-deposit')
            dispatch(closeQuickDeposit())
          }}
        >
          <Text>{depositButtonText}</Text>
        </DepositButtonComponent>
      </GridCell>
    </Grid>
  )
}
