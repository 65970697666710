import * as ko from 'knockout'
import type { BetTypeDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/DataTransferObjects'
import { IObservableStarter } from './IObservableStarter'
import ObservableRaceStarters from './ObservableRaceStarters'
import Guard from '../../../AppUtils/Framework/Guard'
import ObservableRaceKey from './ObservableRaceKey'
import ObservablePage from './ObservablePage'
import { BettingInformation } from '../BettingInformation'
import BetTypeInformation from '../Betting/BetTypeInformation'
import BetTypeInformationBuilder from '../Betting/BetTypeInformationBuilder'
import { BetType } from '../Betting/BetType'
import { timed } from '@core/Utils'

export default class ObservableRacePage extends ObservablePage {
  public raceStarters: ObservableRaceStarters
  public availableBetTypes: ko.ObservableArray<BetTypeInformation>
  public betIdentifier: string
  public giddyUpRaceKey: ko.Observable<string>
  public giddyUpFixtureKey: ko.Observable<string>

  constructor() {
    super()
    this.raceStarters = new ObservableRaceStarters()
    this.availableBetTypes = ko.observableArray<BetTypeInformation>()
    this.betIdentifier = ''
    // @ts-expect-error Type issue
    this.giddyUpRaceKey = ko.observable()
    // @ts-expect-error Type issue
    this.giddyUpFixtureKey = ko.observable()
  }

  addBetType(betType: BetTypeDataTransferObject) {
    Guard.notNull(betType)
    Guard.stringNotNullOrEmpty(betType.Type)
    let mappedBetType = BetTypeInformationBuilder.fromUnTyped(betType.Type).build()
    if (betType.IsAbandoned) {
      mappedBetType.isAbandoned(betType.IsAbandoned)
      mappedBetType.displayText(mappedBetType.displayText() + ' - Abandoned')
    }
    this.availableBetTypes.push(mappedBetType)
  }

  addStartersForRace(raceKey: ObservableRaceKey, starters: IObservableStarter[]) {
    Guard.notNull(raceKey)
    Guard.notNull(starters)

    this.raceStarters.addStartersForRace(raceKey, starters)
  }

  mergeStartersForRace(raceNumber: number, starters: IObservableStarter[]) {
    Guard.greaterThanZero(raceNumber)
    Guard.notNull(starters)

    let raceKey = this.getRaceKeyForRaceNumber(raceNumber)

    if (raceKey) {
      this.mergeStartersForRaceKey(raceKey, starters)
    }
  }

  mergeStartersForRaceKey(raceKey: ObservableRaceKey, starters: IObservableStarter[]) {
    Guard.notNull(raceKey)
    Guard.greaterThanZero(raceKey.raceNumber())
    Guard.notNull(starters)

    this.raceStarters.mergeStartersForRace(raceKey, starters)
  }

  getStartersForRace(raceNumber: number): ko.ObservableArray<IObservableStarter> {
    return (
      this.raceStarters
        .starters()
        .filter(keyValue => keyValue.Key.raceNumber() === raceNumber)
        .map(keyValue => keyValue.Value)[0] ?? ko.observableArray<IObservableStarter>()
    )
  }

  getAllStarters(): ko.ObservableArray<IObservableStarter>[] {
    const results = this.raceStarters.starters().map(starter => starter.Value)
    if (results === null) return [ko.observableArray<IObservableStarter>([])]
    return results
  }

  getStartersForLeg(legNumber: number): ko.ObservableArray<IObservableStarter> {
    return (
      this.raceStarters
        .starters()
        .filter(keyValue => keyValue.Key.leg() === legNumber)
        .map(keyValue => keyValue.Value)[0] ?? ko.observableArray<IObservableStarter>()
    )
  }

  resetStartersSelections() {
    timed('ObservableRacePage::resetStartersSelections', () => {
      this.raceStarters.clearOutStarterSelections()
    })
  }

  getRaceKeyForRaceNumber(raceNumber: number): ObservableRaceKey | undefined {
    return this.raceStarters
      .starters()
      .filter(keyValue => keyValue.Key.raceNumber() === raceNumber)
      .map(keyValue => keyValue.Key)[0]
  }

  getRaceKeyForLeg(legNumber: number): ObservableRaceKey | undefined {
    return this.raceStarters
      .starters()
      .filter(keyValue => keyValue.Key.leg() === legNumber)
      .map(keyValue => keyValue.Key)[0]
  }

  getRaceNumbers(): Array<ObservableRaceKey> {
    return this.raceStarters.getRaceNumbers()
  }

  getDoubleRaceNumbers(): Array<number> {
    return this.raceStarters
      .starters()
      .filter(keyValue => keyValue.Key.isDoubleLeg() && keyValue.Value().length > 0)
      .map(keyValue => keyValue.Key.raceNumber())
  }

  getQuaddieRaceNumbers(): Array<number> {
    return this.raceStarters
      .starters()
      .filter(keyValue => keyValue.Key.isQuaddieLeg() && keyValue.Value().length > 0)
      .map(keyValue => keyValue.Key.raceNumber())
  }

  public provisionBettingInformation(
    lastSelectedBetType: ko.Observable<BetType | null>
  ): BettingInformation {
    return new BettingInformation(
      this.meetingInformation.meetingId(),
      this.meetingInformation.selectedRace.raceNumber(),
      this.meetingInformation.meetingDate(),
      lastSelectedBetType,
      this.betIdentifier,
      this.meetingInformation.selectedRace.fixedPlacesPaying()
    )
  }

  public merge(other: ObservableRacePage, forceClear?: boolean) {
    Guard.notNull(other)
    this.mergeDone(false)
    super.merge(other)

    this.betIdentifier = other.betIdentifier
    this.raceStarters.merge(other.raceStarters, forceClear)
    this.availableBetTypes(other.availableBetTypes())
    this.giddyUpRaceKey(other.giddyUpRaceKey())
    this.giddyUpFixtureKey(other.giddyUpFixtureKey())
    this.hasDoubleProvDivs(other.hasDoubleProvDivs())
    this.hasQuaddieProvDivs(other.hasQuaddieProvDivs())
    this.mergeDone(true)
  }
}
