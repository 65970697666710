import React from 'react'
import { useHistory } from 'react-router-dom'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'
import { useHistoryBackNav } from '@core/Utils/hooks/useHistoryBackNav'
import { BetOffers } from './Components/BetOffers'
import { getRaceUrl } from '../helpers/getRaceUrl'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'
import { useIsAuthenticated } from '@core/Utils/hooks/useIsAuthenticated'
import { openDeposit } from '@core/Areas/Deposit'

export const BetOfferPage: React.FC<{}> = () => {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()
  useHistoryBackNav()

  React.useEffect(() => {
    if (isAuthenticated) fetchCampaignsAsync()
  }, [isAuthenticated])

  const handleCampaignClick = (campaign: Campaign) => {
    campaign.triggerType === 'AccountDepositTrigger' && campaign.isLive
      ? openDeposit()
      : history.push(getRaceUrl(campaign))
  }

  return (
    <MainContainer data-tid-bonus-detail-heading background='grey'>
      <ContentHeader title='BONUS OFFERS' />
      <BetOffers handleCampaignClick={handleCampaignClick} />
    </MainContainer>
  )
}
