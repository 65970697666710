import React from 'react'
import styled from '@emotion/styled'
import {
  colors,
  measurements,
  radius,
  spacing,
  layering,
} from '@mobi/component-library/Theme/Common'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { store } from '@core/Store'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import { SharedRaceSelection } from '../Common/SharedRaceSelection'
import { NoRacesMessage } from '../NoRacesMessage'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { toggleIsSkyRaceListViewOpen } from '../../Store'
import { getIsSkyRaceListViewOpen } from '../../Store/selectors'
import { RaceFilter } from '../RaceFilter'
import { ShowMoreSkyRacesButton } from '../Common/ShowMoreSkyRacesButton'

const enum LocalConstants {
  UnmountClassName = 'js-sky-list-view--unmount',
  FilterClass = 'js-sky-list-view__filter',
  RaceListClass = 'js-sky-list-view__list',
  BackdropClass = 'js-sky-list-view__bg',
}

/** Race list view w/ render control */
export const RaceListView: React.FC<{
  races: NextSkyRace[]
  setCurrentRace: React.Dispatch<React.SetStateAction<NextSkyRace>>
}> = ({ races, setCurrentRace }) => {
  const isRaceListViewOpen = useAppSelector(getIsSkyRaceListViewOpen)
  const shouldRenderListView = useRenderTimeoutControl({
    shouldRender: isRaceListViewOpen,
    timeoutMs: 300,
  })

  // Reset state on unmount (change of path etc)
  React.useEffect(() => {
    return () => {
      store.dispatch(toggleIsSkyRaceListViewOpen(false))
    }
  }, [])

  if (!shouldRenderListView) return null

  return (
    <RaceListViewRender
      races={races}
      isUnmounting={!isRaceListViewOpen}
      setCurrentRace={setCurrentRace}
    />
  )
}

// ===============
// Local Component
// ===============

const RaceListViewRender: React.FC<{
  races: NextSkyRace[]
  setCurrentRace: React.Dispatch<React.SetStateAction<NextSkyRace>>
  isUnmounting: boolean
}> = ({ races, setCurrentRace, isUnmounting }) => {
  const dispatch = useAppDispatch()

  const raceListViewRef = useBodyScrollLock()

  const makeOnRaceClickHandler = (skyRace: NextSkyRace) => () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    dispatch(toggleIsSkyRaceListViewOpen(false))
    setCurrentRace(skyRace)
  }

  return (
    <WrapperStyled
      className={isUnmounting ? LocalConstants.UnmountClassName : ''}
      data-testid='RaceListView'
    >
      <div className={LocalConstants.FilterClass}>
        <RaceFilter races={races} />
      </div>

      <div ref={raceListViewRef} className={LocalConstants.RaceListClass}>
        <ul>
          {races.map(skyRace => (
            <li key={skyRace.AdvertisedStartTime + skyRace.MeetingID}>
              <SharedRaceSelection
                isListViewDisplay
                skyRace={skyRace}
                onClick={makeOnRaceClickHandler(skyRace)}
              />
            </li>
          ))}
          <li>
            <ShowMoreSkyRacesButton />
          </li>
        </ul>

        {races.length < 1 && <NoRacesMessage />}
      </div>

      <div className={LocalConstants.BackdropClass} />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.nav({
  position: 'fixed',
  zIndex: layering.overlayHigh,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  minWidth: measurements.mobi.minWidth,
  maxWidth: measurements.mobi.maxWidth,
  margin: '0 auto',

  [`.${LocalConstants.FilterClass}`]: {
    position: 'relative',
    zIndex: 1,
    animation: 'ease filterIn 0.3s forwards',
  },

  [`.${LocalConstants.RaceListClass}`]: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: '4.8rem',
    bottom: measurements.mobi.bottomTabsHeight,
    left: 0,
    right: 0,
    zIndex: 1,
    margin: spacing.smx1 + ' ' + spacing.md,
    padding: 0,
    overflowY: 'auto',
    borderRadius: radius.lgx1,
    animation: 'ease listIn 0.3s forwards',

    '> ul': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '0.1rem',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      'li > button': {
        display: 'flex',
        width: '100%',
        border: 0,
        boxShadow: 'none',
        background: colors.white,
      },

      'li:first-of-type > button': { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
      'li:last-of-type > button': { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
      'li:not(:last-of-type, :first-of-type) > button': { borderRadius: 0 },
    },
  },

  [`.${LocalConstants.BackdropClass}`]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    opacity: 1,
    background: colors.surface[50],
    animation: 'ease fadeIn 0.3s forwards',
  },

  [`&.${LocalConstants.UnmountClassName}`]: {
    [`.${LocalConstants.RaceListClass}`]: { animation: 'ease fadeOut 0.2s forwards' },
    [`.${LocalConstants.BackdropClass}`]: { animation: 'ease fadeOut 0.2s forwards' },
    [`.${LocalConstants.FilterClass}`]: { animation: 'ease fadeOut 0.2s forwards' },
  },

  '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },
  '@keyframes fadeOut': { from: { opacity: 1 }, to: { opacity: 0 } },

  '@keyframes filterIn': {
    from: { transform: 'translateY(-100%)' },
    to: { transform: 'translateY(0)' },
  },

  '@keyframes listIn': {
    '0%': { transform: 'translateY(5rem)', opacity: 0 },
    '30%': { opacity: 1 },
    '100%': { transform: 'translateY(0)' },
  },
})
