import ObservableRacePage from '../../../Model/Observables/ObservableRacePage'
import type { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import type { ISelectionResult } from '../SelectionResults/ISelectionResult'
import type { ISelectionContext } from './ISelectionContext'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import Guard from '../../../../AppUtils/Framework/Guard'
import { timed } from '@core/Utils'

type CallbackMap = Map<string, Array<Function>>

export default class SelectionProcessor {
  constructor(
    private eventAggregator: IEventAggregator,
    private bettingContext: BettingInformation,
    private model: ObservableRacePage
  ) {
    Guard.notNull(bettingContext)

    this.disposableCallbacks = new Map<string, Array<Function>>()

    this.safeSubscribe(
      'selection-made-command',
      (command: {
        raceNumber: number
        starter: IObservableStarter
        context: ISelectionContext
      }) => {
        this.processSelection(command)
      }
    )

    this.safeSubscribe(
      'field-selected-command',
      (command: { raceNumber: number; position: number; selected: boolean }) => {
        this.processField(command)
      }
    )

    this.safeSubscribe(
      'sameas-selected-command',
      (command: { raceNumber: number; row: number; column: number; selected: boolean }) => {
        this.processSameAs(command)
      }
    )

    this.safeSubscribe('clear-all-selections-command', () => {
      this.clearAllSelections()
    })
  }

  public callBacksForDisposal() {
    return this.disposableCallbacks
  }

  public getSelections(race: number): ISelectionResult {
    return timed('Process Generate Selection Result', () => {
      return this.bettingContext
        .selectedBetType()
        .selectionStringProcessor.selections(
          this.bettingContext,
          race,
          this.bettingContext.selections
        )
    })
  }

  private processSelection(command: {
    raceNumber: number
    starter: IObservableStarter
    context: ISelectionContext
  }) {
    timed('Process Selection', () => {
      let updateSelections: IObservableStarter[] = []
      const selectedBetType = this.bettingContext.selectedBetType()

      if (selectedBetType && selectedBetType.processor) {
        updateSelections = selectedBetType.processor.generateSelections(
          command.starter,
          this.model.getStartersForRace(command.raceNumber),
          this.bettingContext,
          command.context
        )
      }

      this.bettingContext.selections.assignSelectionForRace(command.raceNumber, updateSelections)
    })
  }

  private processField(command: { raceNumber: number; position: number; selected: boolean }) {
    timed('Process Field', () => {
      this.bettingContext
        .selectedBetType()
        .processor.processFieldSelection(
          this.model.getStartersForRace(command.raceNumber),
          this.bettingContext.selections.getStartersForRace(command.raceNumber),
          this.bettingContext,
          command.position,
          command.selected
        )
    })
  }

  private processSameAs(command: {
    raceNumber: number
    row: number
    column: number
    selected: boolean
  }) {
    timed('Process Same As', () => {
      this.bettingContext
        .selectedBetType()
        .processor.processSameAsSelection(
          this.model.getStartersForRace(command.raceNumber),
          this.bettingContext.selections.getStartersForRace(command.raceNumber),
          this.bettingContext,
          command.row,
          command.column,
          command.selected
        )
    })
  }

  private clearAllSelections() {
    timed('Clear all selections', () => {
      this.bettingContext
        .selectedBetType()
        .processor.clearAllSelections(
          this.model.getAllStarters(),
          this.bettingContext.selections.getAllSelectedStarters()
        )
      this.bettingContext.turnOffAllSelections()
    })
  }

  private safeSubscribe(topic: string, func: Function) {
    if (!this.disposableCallbacks.has(topic)) {
      this.disposableCallbacks.set(topic, [])
    }

    this.disposableCallbacks.get(topic)?.push(func)

    this.eventAggregator.subscribe(topic, func)
  }

  private readonly disposableCallbacks: CallbackMap
}
