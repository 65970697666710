import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'
import { Icon, type IconProps } from '@mobi/component-library/Common/Icon'

export const GameGroupStyled = styled('div')({
  fontSize: '1.4rem',
  margin: '0.25rem 0.5rem',
  padding: '0.25rem 0.5rem',
  marginBlockEnd: 0,
})

export const InfoHeaderStyled = styled('div')({
  backgroundColor: hexColors.nightRider,
  padding: '1rem',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: hexColors.grey,
})

export const PoolNameStyled = styled('div')({
  fontSize: '1.6rem',
  color: hexColors.white,
})

export const InfoTitleStyled = styled('div')({
  backgroundColor: '#2e2c30',
  padding: '0 1rem',
  display: 'flex',
  lineHeight: '3rem',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  justifyContent: 'space-around',
})

export const GameHeaderStyled = styled('div')({
  color: hexColors.grey,
  fontSize: '1.2rem',
  padding: '0.5rem 0',
  textAlign: 'center',
  '> strong': {
    color: hexColors.nightRider,
  },
})

export const ButtonsContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
})

interface ButtonGroupButtonProps {
  selected: boolean
  disabled?: boolean
}

export const ButtonGroupItemStyled = styled(BaseButtonStyled)<ButtonGroupButtonProps>(
  {
    flex: '1',
    height: '4rem',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    padding: '0.2rem',
    margin: 0,
    outline: `1px solid ${hexColors.veryLightGrey}`,
    '&:focus': {
      outline: `1px solid ${hexColors.veryLightGrey}`,
    },
  },
  ({ selected, disabled }) => ({
    fontWeight: selected && !disabled ? 'bold' : 'normal',
    background: selected && !disabled ? hexColors.blueDiamond : hexColors.white,
    color: selected && !disabled ? hexColors.white : hexColors.black,
  }),
  ({ disabled }) =>
    disabled && {
      opacity: 0.3,
    }
)

export const TeamNameContainerStyled = styled.div({
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  height: '4rem',
})

export const TeamNameStyled = styled.div(
  ({
    isSelected,
    maxWidth,
    isAbandoned,
  }: {
    isSelected: boolean
    isAbandoned: boolean
    maxWidth?: string
  }) => ({
    outline: `1px solid ${hexColors.veryLightGrey}`,
    backgroundColor: isAbandoned
      ? 'transparent'
      : isSelected
        ? hexColors.blueDiamond
        : hexColors.white,
    color: isSelected ? hexColors.white : 'inherit',
    fontWeight: isSelected ? 'bolder' : 'inherit',
    flex: '1 1 0',
    textAlign: 'center',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth,
  })
)

const TippingPillStyled = styled.span({
  display: 'inline-block',
  borderRadius: '3px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: hexColors.white,
  padding: '0.25rem 0.5rem',
  margin: '0 0.5rem',
})

export const DrawPillStyled = styled(TippingPillStyled)({
  backgroundColor: hexColors.studio,
})

export const AbandonedPillStyled = styled(TippingPillStyled)({
  backgroundColor: hexColors.chocolate,
})

export const ButtonDrawStyled = styled(ButtonGroupItemStyled)({
  maxWidth: '9rem',
  padding: 0,
})

export const DisclaimerContainerStyled = styled('ul')({
  margin: '1rem 1rem 1rem -1rem',
})

export const DisclaimerStyled = styled('li')({
  fontSize: '1.2rem',
})

export const JackpotStyled = styled('span')({
  color: '#f2a900',
  fontSize: '1.2rem',
  margin: '0 0 0 1.2rem',
  '& > span:first-of-type': {
    marginLeft: '0.5rem',
  },
})

export const IconStyled = styled(Icon)<IconProps>({
  margin: '0 1rem 0 0',
  zIndex: 1,
  position: 'relative',
})
