import { queryKeys } from '@core/Data/ReactQuery/constants'
import { useQuery } from 'react-query'
import { getAccountContactDetails, getFeedbackSubjectsFromApi } from './api'

export const useFeedbackFormQuery = (accountNumber: number) => {
  const {
    data: subjects,
    isError: isErrorSubjectsApi,
    isLoading: isLoadingSubjects,
  } = useQuery(queryKeys.feedBackSubjects, getFeedbackSubjectsFromApi, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const {
    data: contactDetails,
    isError: isErrorContactDetails,
    isLoading: isLoadingContactDetails,
  } = useQuery([queryKeys.feedBackContacts, accountNumber], getAccountContactDetails, {
    refetchOnWindowFocus: false,
    staleTime: 1_000 * 60 * 60, // 1hr
  })

  const hasApiErrored = isErrorContactDetails || isErrorSubjectsApi
  const shouldDisableSubmission =
    !contactDetails || hasApiErrored || isLoadingContactDetails || isLoadingSubjects

  return {
    subjects,
    contactDetails,
    shouldDisableSubmission,
    hasApiErrored,
  }
}
