import * as ko from 'knockout'
import { SelectionViewModel } from './SelectionViewModel'
import template from './selection.tpl.html'

if (!ko.components.isRegistered('starter-selection')) {
  ko.components.register('starter-selection', {
    viewModel: SelectionViewModel,
    template,
  })
}
