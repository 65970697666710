import React from 'react'
import { FixedOddsMarketCodes } from '@mobi/api-types'
import { Icon, type IconType } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import type {
  Race,
  Acceptor,
  RaceDetails,
  BetSlipItem,
  BettingType,
  Selection,
  FobPropositionSelection,
  ToteSelection,
  RacingBetType,
} from '@mobi/betslip/types'
import {
  isFobPropositionSelection,
  isNoveltyBetType,
  isToteSelection,
  isFobMatchedSelection,
  isSameRaceMultiSelection,
  isStartingPriceMatchedSelection,
} from '@mobi/betslip/helpers/typeGuards'
import {
  addAcceptorNameToSingleSelection,
  getAcceptorTextForSingleSelection,
} from './Components/Selection/helpers'
import { NoveltySelection } from './Components/Selection/NoveltySelection'
import { SingleSameRaceMultiSelectionStyled } from './Components/Selection/Selection.styles'
import { MultiRaceSelection } from './Components/Selection/MultiRaceSelection'
import { QuickbetSilkImage } from './Components/SilkImage/QuickbetSilkImage'
import { MultiLegTypeToggle } from '@mobi/betslip/Components/Multi/Components/MultiItem/Components/MultiLegTypeToggle'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiItem/Components/MultiLegCheckbox'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'

const FixedOddsMarketDisplayNames = {
  [FixedOddsMarketCodes.HeadToHead]: 'Head to Head',
  [FixedOddsMarketCodes.RunnerVsField]: 'Runner vs Field',
  [FixedOddsMarketCodes.StartingPrice]: 'SP - Fixed',
} as const

const mapCodeToIcon: Record<string, IconType> = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export const RaceBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
}> = ({ item, isMultiItem }) => {
  const { selection } = item
  const bettingType = item.bettingType === null ? 'tote-racing' : item.bettingType
  const { races = [], acceptors = [] } = (item.selectionDetails || {}) as RaceDetails

  let heading = 'Race information unavailable'
  let raceStartTime: string | null = null
  let racingIconCode: string | undefined

  const hasFatalError = isFatalErrorType(item.betErrorType)

  if (races && races.length && races[0]) {
    const { raceNumber, meetingName, raceTime, meetingCode } = races[0] as Race
    heading = meetingName + ': R' + raceNumber
    raceStartTime = raceTime
    racingIconCode = meetingCode
  }

  if (acceptors && acceptors.length && races[0]) {
    const { type } = acceptors[0] as Acceptor
    racingIconCode = type
  }

  const iconType = mapCodeToIcon[racingIconCode || 'Trots']

  return (
    <>
      <BetCard.EventDetails
        heading={heading}
        subHeading={getFixedOddsMarketName(selection as unknown as Selection)}
        statusComponent={
          <>
            {!hasFatalError && raceStartTime && (
              <CountdownBadge
                shouldUseExtendedCountdown
                advertisedStartTime={new Date(raceStartTime)}
              />
            )}
            {isMultiItem && item.shouldAllowPlaceInvestment && <MultiLegTypeToggle item={item} />}
          </>
        }
        iconComponent={<Icon size='2rem' type={iconType} />}
      />

      {(() => {
        if (!selection) return null

        const fixedOrToteDisplay = getFixedOrToteDisplay(
          selection as unknown as Selection,
          bettingType
        )
        const selectionType =
          getMarketTitle(selection as unknown as ToteSelection) +
          (fixedOrToteDisplay.length > 0 ? ` ${fixedOrToteDisplay}` : '')

        if (isToteSelection(selection)) {
          if (isSingleRaceNoveltyBetType(selection.betType)) {
            return <NoveltySelection selection={selection} acceptors={acceptors} />
          }

          if (isMultiRaceBetType(selection.betType)) {
            return <MultiRaceSelection selection={selection} races={races} acceptors={acceptors} />
          }

          return (
            <BetCard.SelectionInfo
              iconComponent={
                <QuickbetSilkImage
                  acceptor={extractSelectedAcceptor(selection.selectionString, acceptors)}
                />
              }
              heading={addAcceptorNameToSingleSelection(selection.selectionString, acceptors)}
              subHeading={selectionType}
            />
          )
        }

        // TODO: Use shared component for selection info
        if (isSameRaceMultiSelection(selection)) {
          const sorted = selection.acceptors
            .map(a => ({
              ...a,
              legName: a.legNumber === 0 ? 'WIN' : `TOP ${a.legNumber + 1}`,
            }))
            .sort(sameRaceMultiSortCallback)
          const result = sorted.map((selectionAcceptor, index) => {
            const number = selectionAcceptor.acceptorNumber.toString()
            return (
              <SingleSameRaceMultiSelectionStyled key={index}>
                <div>
                  {<QuickbetSilkImage acceptor={extractSelectedAcceptor(number, acceptors)} />}{' '}
                  <span>{addAcceptorNameToSingleSelection(number, acceptors)}</span>
                </div>
                <div>{selectionAcceptor.legName}</div>
              </SingleSameRaceMultiSelectionStyled>
            )
          })
          return <>{result}</>
        }

        if (isFobMatchedSelection(selection)) {
          const price = isMultiItem
            ? item.multiLegBetType === 'W'
              ? selection.winPrice
              : selection.placePrice
            : undefined
          return (
            <BetCard.SelectionInfo
              iconComponent={
                <QuickbetSilkImage
                  acceptor={extractSelectedAcceptor(selection.acceptorNumber.toString(), acceptors)}
                />
              }
              heading={addAcceptorNameToSingleSelection(
                selection.acceptorNumber.toString(),
                acceptors
              )}
              subHeading={selectionType}
              price={price || undefined}
              rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
            />
          )
        }

        if (isStartingPriceMatchedSelection(selection)) {
          const acceptor = selection.selectedAcceptor
          if (acceptor) {
            return (
              <BetCard.SelectionInfo
                iconComponent={<QuickbetSilkImage acceptor={acceptor} />}
                heading={getAcceptorTextForSingleSelection(acceptor) || ''}
                subHeading={selectionType}
              />
            )
          }
        }

        if (isFobPropositionSelection(selection)) {
          const acceptor = selection.selectedAcceptors && selection.selectedAcceptors[0]
          if (acceptor) {
            return (
              <BetCard.SelectionInfo
                iconComponent={<QuickbetSilkImage acceptor={acceptor} />}
                heading={getAcceptorTextForSingleSelection(acceptor) || ''}
                subHeading={selectionType}
              />
            )
          } else {
            return <BetCard.SelectionInfo heading={selection.displayName || ''} subHeading='' />
          }
        }

        return null
      })()}
    </>
  )
}

// =============
// Local Helpers
// =============

function getFixedOddsMarketName(selection: Selection | null, isMultiDisplay: boolean = false) {
  const isFobProposition = isFobPropositionSelection(selection)
  // Only display market name to add clarity where starter can appear in multiple markets
  const shouldDisplay =
    isFobProposition &&
    ((isMultiDisplay && selection.marketCode === FixedOddsMarketCodes.Concession) ||
      selection.marketCode === FixedOddsMarketCodes.RunnerVsField ||
      selection.marketCode === FixedOddsMarketCodes.HeadToHead)

  const marketName =
    shouldDisplay &&
    (FixedOddsMarketDisplayNames[
      selection.marketCode as keyof typeof FixedOddsMarketDisplayNames
    ] ||
      selection.marketName)

  return shouldDisplay ? `${isMultiDisplay ? ' - ' : ''}${marketName}` : ''
}

// TODO: Used for multi (likely not needed for new impl.)
// function getFobSelectionText(
//   selection: Selection,
//   acceptors: Acceptor[]
// ): string | null | undefined {
//   if (isFobMatchedSelection(selection)) {
//     return addAcceptorNameToSingleSelection(`${selection.acceptorNumber}`, acceptors)
//   }
//   if (!isFobPropositionSelection(selection)) return null
//   const { number: sNumber, name } = selection.selectedAcceptors?.[0] || {}
//   if (sNumber && name) return `${sNumber} - ${name}`
//   return selection.displayName
// }

// function addAcceptorNameToSingleSelection(
//   selectionForRace: string,
//   acceptorsForRace: AcceptorBase[]
// ): string {
//   if (selectionForRace && /^\d+$/.test(selectionForRace)) {
//     const acceptor = getAcceptorName(selectionForRace, acceptorsForRace)
//     if (acceptor) return `${selectionForRace} - ${acceptor}`
//   }
//   return selectionForRace
// }

// function getAcceptorName(acceptorNumber: string, acceptorsForRace: AcceptorBase[]): string {
//   const acceptor = acceptorsForRace.find(x => `${x.number}` === acceptorNumber)
//   return acceptor ? acceptor.name : ''
// }
// *

function getMarketTitle(selection: Selection) {
  if ((selection as ToteSelection).betType) return (selection as ToteSelection).betType
  if ((selection as FobPropositionSelection).marketCode) {
    return (selection as FobPropositionSelection).marketName
  }
  return 'Win & Place'
}

function getFixedOrToteDisplay(selection: Selection, bettingType: BettingType) {
  if (isNoveltyBetType((selection as ToteSelection).betType)) {
    return ''
  }
  if (bettingType === 'fixed-odds-racing' && !(selection as FobPropositionSelection).marketCode) {
    return 'Fixed'
  }
  if (bettingType === 'tote-racing') {
    return 'Tote'
  }
  return ''
}

type SameRaceMultiSortCallbackParamType = { legNumber: number; acceptorNumber: number }
function sameRaceMultiSortCallback(
  a: SameRaceMultiSortCallbackParamType,
  b: SameRaceMultiSortCallbackParamType
) {
  const diff = a.legNumber - b.legNumber

  // lower leg number must come first
  if (diff != 0) return diff

  // if same leg, lower acceptor number must come first
  // we never expect same acceptor number
  return a.acceptorNumber - b.acceptorNumber
}

function extractSelectedAcceptor(
  selectionNumber: string,
  acceptors: Acceptor[]
): Acceptor | undefined {
  return acceptors.find(a => `${a.number}` === selectionNumber)
}

function isSingleRaceNoveltyBetType(value: RacingBetType): boolean {
  const noveltyTypes: RacingBetType[] = ['Exacta', 'Quinella', 'Trifecta', 'First 4']
  return noveltyTypes.includes(value)
}

function isMultiRaceBetType(value: RacingBetType): boolean {
  return value === 'Double' || value === 'Quaddie' || value === 'All Up'
}
