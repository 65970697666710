import React from 'react'
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import * as TabTouch from '@classic/Foundation/TabTouch'

import { ContentHeader } from '@core/Components/ContentHeader'
import { Tab } from '@core/Components/Tabs'
import { KAMBI_HOME_HASH_ROUTE } from '@core/Areas/Kambi/constants'

import { Games } from './Components/Games/Games'
import { CurrentPoolList } from './Components/PoolLists/CurrentPoolList'
import { ResultedPoolList } from './Components/PoolLists/ResultedPoolList'
import { BoldedTabList, TippingMainContainerStyled } from './TippingPage.styles'

export const TippingPage = (): JSX.Element | null => {
  const { path } = useRouteMatch()
  const history = useHistory()

  React.useEffect(() => {
    const backNav = KAMBI_HOME_HASH_ROUTE
    setTimeout(() => TabTouch.initCommon(backNav), 150)
  }, [])

  const isShowingResults = /\/results/i.test(history.location.pathname)
  const navigateToResultsIfNotAlreadyThere = React.useCallback(() => {
    if (!isShowingResults) {
      history.push(`${path}/results?date=${dayjs().format('YYYY-MM-DD')}`)
    }
  }, [history, isShowingResults, path])

  return (
    <TippingMainContainerStyled>
      <ContentHeader title='TIPPING' iconCode='TIPPING' />
      <BoldedTabList colorscheme='dark' uppercaseText={true}>
        <Tab selected={!isShowingResults} onClick={() => history.push(path)}>
          Current
        </Tab>
        <Tab selected={isShowingResults} onClick={navigateToResultsIfNotAlreadyThere}>
          Results
        </Tab>
      </BoldedTabList>
      <Switch>
        <Route exact path={path}>
          <CurrentPoolList />
        </Route>

        <Route
          path={[`${path}/results/:sportIconCode/:poolKey`, `${path}/:sportIconCode/:poolKey`]}
        >
          <Games />
        </Route>

        <Route path={`${path}/:results(results.*)`}>
          <ResultedPoolList />
        </Route>

        <Route>
          <Redirect to='/totesports' />
        </Route>
      </Switch>
    </TippingMainContainerStyled>
  )
}
