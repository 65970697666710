import { PaymentMethod } from '@mobi/api-types'
import React, { memo, type ReactNode } from 'react'
import styled from '@emotion/styled'
import { nameCardIssuerMap } from '../helpers'
import { spacing } from '../../Theme/Common'
import { CreditCardName } from './CreditCardName'
import { makeAccessibleName } from '@mobi/component-library/Deposit/Buttons/CreditCard/helpers'

type CardProps = Pick<PaymentMethod, 'lastFour' | 'formattedExpiryDate' | 'issuerName'> & {
  isSelected: boolean
  alertLabel?: ReactNode
}

type Props = CardProps & {
  icon: ReactNode
}

export const CreditCardDisplay = memo<Props>(
  ({ lastFour, formattedExpiryDate, alertLabel, issuerName, icon }) => {
    return (
      <CreditCardDisplayStyled
        aria-label={makeAccessibleName(nameCardIssuerMap[issuerName], lastFour)}
      >
        {icon ?? <span></span>}

        <CreditCardName issuerName={issuerName} lastFour={lastFour} />

        {alertLabel ?? <span style={{ textAlign: 'right' }}>{formattedExpiryDate}</span>}
      </CreditCardDisplayStyled>
    )
  }
)

const CreditCardDisplayStyled = styled.span({
  alignItems: 'center',
  display: 'inline-grid',
  gap: spacing.smx2,
  gridTemplateColumns: 'minmax(0, max-content) 3.2rem 1fr minmax(0, min-content)',
  textAlign: 'left',
  width: '100%',
})
