import React from 'react'
import { Button } from '@mobi/component-library/Common/V2'
import { useNextSkyRaces } from '../../Hooks'

export const ShowMoreSkyRacesButton = () => {
  const { isLoading, isMoreToFetch, fetchMoreRaces } = useNextSkyRaces({
    enabled: false,
  })

  if (!isMoreToFetch) return null

  return (
    <Button
      color='secondary_outline'
      style={{ display: 'flex', justifyContent: 'center' }}
      onClick={fetchMoreRaces}
      data-testid='show-more-sky-races'
    >
      {isLoading ? 'Loading...' : 'Show More'}
    </Button>
  )
}
