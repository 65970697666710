import moment from 'moment'
import {
  PageDataTransferObject,
  RacePageDataTransferObject,
  ResultsPageDataTransferObject,
} from '../DataTransferObjects/DataTransferObjects'
import MeetingRaceMapper from './MeetingRaceMapper'
import StartersMapper from './StartersMapper'
import PoolMapper from './PoolMapper'
import BetTypesMapper from './BetTypesMapper'
import ObservableRacePage from '../Model/Observables/ObservableRacePage'
import { RacePageResponseType } from '../Types/RacePageResponseType'
import { IObservablePage } from '../Model/Observables/IObservablePage'
import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import ObservablePage from '../Model/Observables/ObservablePage'
import FinishersMapper from './FinishersMapper'
import SubstitutePoolMapper from './SubstitutePoolMapper'
import ScratchingsMapper from './ScratchingsMapper'
import NoveltyDividendsMapper from './NoveltyDividendsMapper'

export default class Mapper {
  static map(dto: PageDataTransferObject): IObservablePage {
    let model: IObservablePage = new ObservablePage()

    switch (dto.Tag) {
      case 'Starters':
        model = this.mapStarters(dto as RacePageDataTransferObject)
        break
      case 'Results':
        model = this.mapResults(dto as ResultsPageDataTransferObject)
        break
    }

    model.pageType = RacePageResponseType[dto.Tag]
    model.meetingInformation = MeetingRaceMapper.map(dto)
    PoolMapper.map(dto as RacePageDataTransferObject, model)

    for (const race of dto.MeetingInformation.Races) {
      model.addRace(MeetingRaceMapper.mapRace(dto.MeetingInformation.MeetingId, race))
    }
    model.meetingInformation.selectedRace = MeetingRaceMapper.mapRace(
      dto.MeetingInformation.MeetingId,
      dto.MeetingInformation.SelectedRace,
      dto.AvailablePools
    )

    return model
  }

  static mapResults(dto: ResultsPageDataTransferObject): ObservableResultsPage {
    const resultsModel = new ObservableResultsPage()
    resultsModel.raceStatus(dto.RaceStatus)
    resultsModel.protestStatus(dto.ProtestStatus)
    resultsModel.simplePlacings(dto.SimplePlacings)
    resultsModel.giddyUpRaceKey(dto.GiddyUpRaceKey)
    resultsModel.giddyUpFixtureKey(dto.GiddyUpFixtureKey)
    FinishersMapper.map(dto, resultsModel)
    NoveltyDividendsMapper.map(dto, resultsModel)
    SubstitutePoolMapper.map(dto.AvailableSubstitutePools, resultsModel)
    ScratchingsMapper.map(
      dto.AvailableScratchings,
      resultsModel,
      dto.MeetingInformation.MeetingCode
    )
    this.mapRaceReplay(dto, resultsModel)
    return resultsModel
  }

  static mapStarters(dto: RacePageDataTransferObject): ObservableRacePage {
    const raceModel = new ObservableRacePage()

    BetTypesMapper.map(dto, raceModel)
    StartersMapper.map(dto, raceModel)

    raceModel.specialOffersInfo(dto.SpecialOffersInfo)
    raceModel.giddyUpRaceKey(dto.GiddyUpRaceKey)
    raceModel.giddyUpFixtureKey(dto.GiddyUpFixtureKey)
    raceModel.hasDoubleProvDivs(dto.HasDoubleProvDivs)
    raceModel.hasQuaddieProvDivs(dto.HasQuaddieProvDivs)

    raceModel.betIdentifier = this.generateBetIdentifier(dto)

    return raceModel
  }

  static generateBetIdentifier(dto: RacePageDataTransferObject): string {
    const meetingDateText = moment(dto.MeetingInformation.MeetingDate).format('YYYYMMDD')
    const unixTimestamp = moment().format('x')
    return `TOTE_${meetingDateText}_${dto.MeetingInformation.MeetingId}_${dto.MeetingInformation.SelectedRace.RaceNumber}_${dto.BetType}_${unixTimestamp}`
  }

  static mapRaceReplay(dto: ResultsPageDataTransferObject, resultsModel: ObservableResultsPage) {
    resultsModel.raceReplay.hasVideo(dto.RaceReplay.HasVideo)
    resultsModel.raceReplay.videoKey(dto.RaceReplay.VideoKey)
    resultsModel.raceReplay.raceLocation(dto.RaceReplay.RaceLocation)
    resultsModel.raceReplay.date(dto.RaceReplay.Date)
  }
}
