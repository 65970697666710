import ko from 'knockout'
import ObservableRace from '@classic/Betting-v2/Model/Observables/ObservableRace'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import EventAggregator from '@classic/AppUtils/Framework/Messaging/EventAggregator'

export class ResultsColumnHeaderViewModel extends Disposable {
  constructor(params: {
    race: ObservableRace
    raceFinishers: ko.ObservableArray<IObservableFinisher>
  }) {
    super(new EventAggregator())

    this.race = params.race

    this.shouldShowSp = ko.pureComputed(
      () => !!params.raceFinishers?.().some(finisher => finisher.spWinPrice() > 0)
    )
  }

  public race: ObservableRace
  public shouldShowSp: ko.PureComputed<boolean>
}
