import { googlePayment, type Client } from 'braintree-web'
import { coerceIntoError, scriptLoader, unwrapErrorMessage } from '@mobi/utils'
import { isGooglePayAvailable, getGooglePaymentsClient } from '../../Utils/googlePay'
import { reportErrorIfNeeded } from '../../Utils/sentry'

const enum LocalConstant {
  SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js',
  POLL_TIME_MS = 250,
  POLL_TIMEOUT_TIME_MS = 5000,
}

export async function initGooglePayWeb(): Promise<HTMLScriptElement> {
  if (isGooglePayAvailable()) {
    return getGooglePayScriptElement()
  }

  return scriptLoader(LocalConstant.SCRIPT_URL)
    .then(scriptElement => {
      if (isGooglePayAvailable()) {
        return Promise.resolve(scriptElement)
      }

      let intervalId = -1
      let timeoutId = -1

      const pollPromise = new Promise<HTMLScriptElement>(resolve => {
        intervalId = window.setInterval(() => {
          if (isGooglePayAvailable()) {
            window.clearInterval(intervalId)
            window.clearTimeout(timeoutId)

            return resolve(scriptElement)
          }
        }, LocalConstant.POLL_TIME_MS)
      })

      const timeoutPromise = new Promise<HTMLScriptElement>((_, reject) => {
        timeoutId = window.setTimeout(() => {
          window.clearInterval(intervalId)
          reject(new Error('Loading timed out'))
        }, LocalConstant.POLL_TIMEOUT_TIME_MS)
      })

      return Promise.race([pollPromise, timeoutPromise])
    })
    .catch(err => {
      reportErrorIfNeeded({
        message: `Unable to load Google Pay script for web: ${unwrapErrorMessage(coerceIntoError(err))}`,
      })
      return Promise.reject(err)
    })
}

export function isUserReadyToPay() {
  return getGooglePaymentsClient()
    .then(client => {
      return client.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['CRYPTOGRAM_3DS', 'PAN_ONLY'],
              allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
            },
          },
        ],
        existingPaymentMethodRequired: true,
      })
    })
    .then(({ result: isReady }) => isReady)
}

type TokenizeParams = {
  googlePayClient: google.payments.api.PaymentsClient
  braintreeClient: Client
  merchantId: string
  depositAmount: number
}

export async function tokenize({
  depositAmount,
  braintreeClient,
  googlePayClient,
  merchantId,
}: TokenizeParams) {
  const braintreeGooglePayClient = await googlePayment.create({
    client: braintreeClient,
    googlePayVersion: 2,
    googleMerchantId: merchantId,
  })

  const paymentDataRequest = await braintreeGooglePayClient.createPaymentDataRequest({
    merchantInfo: {
      merchantId,
    },
    transactionInfo: {
      totalPrice: depositAmount.toFixed(2),
      totalPriceStatus: 'FINAL',
      currencyCode: 'AUD',
    },
  })

  paymentDataRequest.allowedPaymentMethods = paymentDataRequest.allowedPaymentMethods.map(
    paymentMethod => {
      if (paymentMethod.type === 'CARD') {
        paymentMethod.parameters.billingAddressRequired = true
        paymentMethod.parameters.billingAddressParameters = {
          format: 'FULL',
        }
      }

      return paymentMethod
    }
  )

  const paymentData = await googlePayClient.loadPaymentData(paymentDataRequest)
  return braintreeGooglePayClient.parseResponse(paymentData)
}

export function isGooglePayError(error: unknown): error is google.payments.api.PaymentsError {
  if (typeof error !== 'object' || !error) {
    return false
  }

  return 'statusCode' in error && typeof error.statusCode === 'string'
}

function getGooglePayScriptElement(): HTMLScriptElement {
  return window.document.head.querySelector(
    `script[src="${LocalConstant.SCRIPT_URL}"]`
  ) as HTMLScriptElement
}
