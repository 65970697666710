import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { ICheckBoxPipelineProcessor } from './ICheckBoxPipelineProcessor'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import { ISelectionContext } from './ISelectionContext'

function hasSelectedAt(starter: IObservableStarter) {
  return (
    starter.selection() &&
    typeof (starter.selection() as CheckBoxSelection).selectedAt === 'function'
  )
}
export abstract class CheckBoxPipelineProcessor implements ICheckBoxPipelineProcessor {
  constructor(protected next: ICheckBoxPipelineProcessor | null) {}

  protected isStarterRovingBanker(starter: IObservableStarter): boolean {
    if (!hasSelectedAt(starter)) return false
    return (starter.selection() as CheckBoxSelection).selectedAt(0)
  }

  protected isBoxed(starter: IObservableStarter): boolean {
    if (!hasSelectedAt(starter)) return false
    return (starter.selection() as CheckBoxSelection).selectedAt(0)
  }

  protected isWith(starter: IObservableStarter): boolean {
    if (!hasSelectedAt(starter)) return false
    return (starter.selection() as CheckBoxSelection).selectedAt(1)
  }

  protected isLegIn(starter: IObservableStarter): boolean {
    if (!hasSelectedAt(starter)) return false
    return (starter.selection() as CheckBoxSelection).selectedAt(0)
  }

  protected legIns(starters: Array<IObservableStarter>): Array<IObservableStarter> {
    return starters
      .filter(s => !s.isScratched() && !s.isScratchedToteAndFob())
      .filter(s => this.isLegIn(s))
  }

  protected totalLegIns(starters: Array<IObservableStarter>, current: IObservableStarter): number {
    return starters
      .filter(
        s => s.number() !== current.number() && !s.isScratched() && !s.isScratchedToteAndFob()
      )
      .filter(s => this.isLegIn(s)).length
  }

  protected rovingBankers(starters: Array<IObservableStarter>): Array<IObservableStarter> {
    return starters
      .filter(s => !s.isScratched() && !s.isScratchedToteAndFob())
      .filter(s => this.isStarterRovingBanker(s))
  }

  protected totalRovingBankers(
    starters: Array<IObservableStarter>,
    current: IObservableStarter
  ): number {
    return starters
      .filter(
        s => s.number() !== current.number() && !s.isScratched() && !s.isScratchedToteAndFob()
      )
      .filter(s => this.isStarterRovingBanker(s)).length
  }

  protected maintainExistingSetOfStarters(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    results: Array<IObservableStarter>
  ) {
    all()
      .filter(
        starter =>
          starter.number() !== current.number() &&
          !starter.isScratched() &&
          !starter.isScratchedToteAndFob()
      )
      .forEach(starter => {
        const selections = starter.selection() as CheckBoxSelection
        if (selections.anySelected()) {
          results.push(starter)
        }
      })
  }

  protected maintainExistingSetOfStartersWithCurrent(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    results: Array<IObservableStarter>
  ) {
    all()
      .filter(
        starter =>
          starter.number() !== current.number() &&
          !starter.isScratched() &&
          !starter.isScratchedToteAndFob()
      )
      .forEach(starter => {
        if (!starter || !starter.selection) return

        const selections = starter.selection() as CheckBoxSelection
        if (selections && selections.anySelected()) {
          results.push(starter)
        }
      })

    if (current.selection) {
      const supplied: CheckBoxSelection = current.selection() as CheckBoxSelection

      if (supplied.anySelected()) {
        results.push(current)
      }
    }
  }

  abstract process(
    current: IObservableStarter,
    all: ko.ObservableArray<IObservableStarter>,
    context: BettingInformation,
    selectionContext: ISelectionContext,
    count: number
  ): Array<IObservableStarter>
}
