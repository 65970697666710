import React from 'react'
import { HighlightedDisplay } from './HighlightDisplay.styles'
import { MetaDataHighlight, HighlightToken } from '../../../../Data/Search/Search'

interface HighlightDisplayProps {
  displayText: string
  highlights: MetaDataHighlight
  highlightKey: string
}

export function HighlightDisplay({
  displayText,
  highlights,
  highlightKey,
}: HighlightDisplayProps): JSX.Element | null {
  if (!highlights[highlightKey]) return <>{displayText}</>

  const transform = (token: HighlightToken, index: number) => {
    const key = `${token.value}_${index}`
    return token.isMatch ? (
      <HighlightedDisplay key={key}>{token.value}</HighlightedDisplay>
    ) : (
      <span key={key}>{token.value}</span>
    )
  }

  const elements = highlights[highlightKey].map(transform)

  return <>{elements}</>
}
