import { dayjs } from '@mobi/utils'
import { get } from '@classic/Foundation/Services/ApiService'
import type { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'

export const getRaceFormDataFromApi = (
  meetingId: string,
  meetingDate: Date | string,
  raceNumber: number
) => {
  const url = getFormForRaceApiUrl(meetingId.toLowerCase(), meetingDate, raceNumber)
  return get<FormRaceResponse>({ url })
}

// Local Helpers

function getFormForRaceApiUrl(meetingId: string, meetingDate: Date | string, raceNumber: number) {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  return `/api/meetings/${meetingId}/${formattedDate}/race/${raceNumber}/form`.toLowerCase()
}

// Types

export type FormRaceResponse = DogsFormRaceResponse | RacesFormRaceResponse | TrotsFormRaceResponse

export type DogsFormRaceResponse = {
  Forms: DogsRunnerForm[]
  StarterType: Extract<RaceCodes, 'Dogs'>
}

export type RacesFormRaceResponse = {
  Forms: RacesRunnerForm[]
  StarterType: Extract<RaceCodes, 'Races'>
}

export type TrotsFormRaceResponse = {
  Forms: TrotsRunnerForm[]
  StarterType: Extract<RaceCodes, 'Trots'>
}

export interface DogsRunnerForm extends RunnerFormBase {
  Histories: DogHistoryFormData[]
}

export interface RacesRunnerForm extends RunnerFormBase {
  RaceHistories: RaceHistoryFormData[]
}

export interface TrotsRunnerForm extends RunnerFormBase {
  TrotHistories: TrotHistoryFormData[]
}

interface RunnerFormBase {
  Age: string
  Career: string
  Color: string
  DamName: string
  // TODO remove, as it's only used in tests
  DisplayLastTenStarts: string
  LastTenStarts: string
  PrizeMoneyWon: string
  Sex: string
  SireName: string
  StarterNumber: number
  TrackDistance: string
  // Optional
  BestTime?: string
  Comments?: string | null
  DisplayLastFiveStarts?: string
  Distance?: string
  FirstUp?: string
  GearChanges?: string | null
  LastTime?: string
  OwnerName?: string
  RatingDry?: string
  RecommendedPrice?: string
  SecondUp?: string
  StartType?: string
  TrackConditions?: Array<{ Name: string; Stats: string | null }>
  TrackName?: string
  TrainerName?: string
}

// Runner History

interface RunnerHistoryBase {
  Distance: string
  FixtureDate: string
  Place: string
  RaceClass: string
  RaceNumber: string
  RaceReplay: {
    HasVideo: boolean
    VideoKey: string
    RaceLocation: string
    Date: string
  }
  TrackName: string
  StewardsComments?: string | null
}

interface DogHistoryFormData extends RunnerHistoryBase {
  BarrierBox: string
  BestOfNight: string
  BodyWeight: string
  Margin: string
  Placings: Pick<PlacingsBase, 'Margin' | 'Place' | 'StarterName'>[]
  RaceTime: string
  StartingPrice: string
  WinnerTime: string
  WinnerOrSecondPlaceName?: string
}

interface RaceHistoryFormData extends RunnerHistoryBase {
  Barrier: string
  FieldSize: string
  JockeyName: string
  Margin: string
  Placings: Omit<PlacingsBase, 'Handicap'>[]
  Position400: string
  Position800: string
  RaceTime: string
  StartPrice: string
  TrackCondition: string
  Weight: string
}

interface TrotHistoryFormData extends RunnerHistoryBase {
  DriverName: string
  FieldSize: string
  Handicap: string
  Margin: string
  MileRate: string
  Placings: Pick<PlacingsBase, 'Handicap' | 'Margin' | 'Place' | 'StarterName'>[]
  StartPrice: string
  StartType: string
}

interface PlacingsBase {
  Barrier: string
  Handicap: string
  JockeyName: string
  Margin: string
  Place: string
  StarterName: string
  Weight: string
}
