import styled from '@emotion/styled'
import { ExpandablePanelComponentTheme } from './themes'
import { isEmpty } from '@core/Utils/object/object'
import { Icon } from '@mobi/component-library/Common/Icon'

interface Theme {
  theme?: ExpandablePanelComponentTheme
}

export const ExpandablePanelStyled = styled('div')<Theme>(
  {
    position: 'relative',
    width: '100%',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          color: theme.header.text.color,
        }
)

interface ExpandablePanelHeaderStyled extends Theme {
  hideHeaderTap?: boolean
  customHeader?: boolean
}

export const ExpandablePanelHeaderStyled = styled('header')<ExpandablePanelHeaderStyled>(
  {
    cursor: 'pointer',
    padding: '1rem 0.8rem',
    borderWidth: '1px',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          background: theme.header.background,
          color: theme.header.text.color,
          margin: theme.header.hasMargin ? '0.5rem' : '0',
          padding: theme.header.hasPadding ? '1rem 0.8rem' : '1rem 0rem',
          borderTop: theme.header.borderTop,
          borderBottom: theme.header.borderBottom,
        },
  ({ hideHeaderTap }) =>
    hideHeaderTap !== true
      ? {}
      : {
          WebkitTapHighlightColor: 'transparent',
        },
  ({ customHeader }) =>
    customHeader && {
      padding: 0,
    }
)

export const ExpandablePanelTitleStyled = styled('div')<Theme>(
  {
    paddingRight: '2rem',
    fontSize: '1.4rem',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          fontWeight: theme.header.text.fontWeight,
        }
)

export const IconStyled = styled(Icon)<Theme>(({ theme }) =>
  isEmpty(theme)
    ? {}
    : {
        color: theme.toggle.text.color,
      }
)

export const HiddenContentStyled = styled('div')({
  height: '1px',
})

export const FormulaHeader = styled('header')<Theme>(
  {
    cursor: 'pointer',
    borderWidth: '1px',
    fontSize: '1.4rem',
  },
  ({ theme }) =>
    isEmpty(theme)
      ? {}
      : {
          padding: theme.header.hasPadding ? '1rem 0.8rem' : '1rem 0rem',
          fontWeight: theme.header.text.fontWeight,
        }
)
