import React, { type ComponentPropsWithoutRef } from 'react'
import {
  PaymentMethodSelectionChevronStyled,
  PaymentMethodSelectionIconStyled,
  PaymentMethodSelectionTextStyled,
  PaymentMethodSelectionButtonStyled,
  NewPaymentMethodBadgeStyled,
} from './PaymentMethodSelectionButton.styled'
import type { Button } from '../../Common/V2'
import type { DepositSourceAll } from '../types'
import { PaymentMethodLogo, ChevronRight } from '../Icons'

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'onClick'> & {
  title: string
  paymentMethod: DepositSourceAll
  isDesktop: boolean
  selected?: boolean
  showNewPaymentMethodBadge?: boolean
  onClick: (paymentMethod: DepositSourceAll) => void
}

/**
 * A button that represents a selectable payment method within TABTouch.
 */
export const PaymentMethodSelectionButton = ({
  selected = false,
  title,
  paymentMethod,
  isDesktop,
  onClick,
  showNewPaymentMethodBadge,
  ...buttonAttributes
}: Props) => {
  return (
    <PaymentMethodSelectionButtonStyled
      color={selected ? 'secondary_color' : 'secondary_grey'}
      size='md'
      aria-label={`Pay with ${title}`}
      onClick={() => onClick(paymentMethod)}
      {...buttonAttributes}
    >
      <PaymentMethodSelectionIconStyled>
        <PaymentMethodLogo paymentMethod={paymentMethod} />
      </PaymentMethodSelectionIconStyled>
      <PaymentMethodSelectionTextStyled>{title}</PaymentMethodSelectionTextStyled>
      {isDesktop && (
        <PaymentMethodSelectionChevronStyled>
          <ChevronRight />
        </PaymentMethodSelectionChevronStyled>
      )}
      {showNewPaymentMethodBadge && <NewPaymentMethodBadgeStyled>NEW</NewPaymentMethodBadgeStyled>}
    </PaymentMethodSelectionButtonStyled>
  )
}
