import React from 'react'
import { dayjs } from '@mobi/utils'
import styled from '@emotion/styled'
import { useIntervalWhen } from 'rooks'
import { StatusBadge } from '../../Common/StatusBadge/StatusBadge'

/**
 * A badge showing a countdown to a particular time (usually the start of a race) passed as a `Date`.
 * When the time has passed the badge will show a negative duration. The color of the badge
 * changes from green to yellow to red as the time approaches.
 */
export function CountdownBadge({
  badgeSize,
  advertisedStartTime,
  shouldUseExtendedCountdown = false,
}: {
  advertisedStartTime: Date
  /** Allows countdown from 7 days (default is from 1hr) - used primarily for Sports */
  shouldUseExtendedCountdown?: boolean
} & StatusBadgeSize) {
  const previousTextRef = React.useRef<string>('')

  const [state, setState] = React.useState<CountdownBadgeState>(() => {
    const state = getCountdownState(advertisedStartTime, shouldUseExtendedCountdown)
    previousTextRef.current = state.text
    return state
  })

  useIntervalWhen(
    () => {
      const state = getCountdownState(advertisedStartTime, shouldUseExtendedCountdown)
      if (state.text !== previousTextRef.current) {
        setState(state)
        previousTextRef.current = state.text
      }
    },
    1_000,
    true
  )

  return (
    <WrapperStyled>
      <StatusBadge color={state.color} badgeSize={badgeSize}>
        {state.text}
      </StatusBadge>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getCountdownState(
  advertisedStartTime: Date,
  shouldUseExtendedCountdown: boolean
): CountdownBadgeState {
  return {
    text: formatCountdownDuration(advertisedStartTime, shouldUseExtendedCountdown),
    color: badgeColorForCountdown(advertisedStartTime, shouldUseExtendedCountdown),
  }
}

function badgeColorForCountdown(
  advertisedStartTime: Date,
  shouldUseExtendedCountdown: boolean
): StatusBadgeColor {
  const secondsToJump = (advertisedStartTime.getTime() - Date.now()) / 1_000

  if (secondsToJump < 60 * 3) return 'red'
  if (secondsToJump < 60 * 10) return 'yellow'
  if (secondsToJump < 60 * 60) return 'green'
  if (shouldUseExtendedCountdown && secondsToJump < 60 * 60 * 12) return 'green'
  return 'gray'
}

function formatCountdownDuration(
  advertisedStartTime: Date,
  shouldUseExtendedCountdown: boolean = false
) {
  const ms = advertisedStartTime.getTime() - Date.now()
  const durationToJump = dayjs.duration(Math.abs(ms))
  const absSeconds = Math.abs(durationToJump.asSeconds())
  const prefix = ms < 0 ? '-' : ''

  if (absSeconds < 60) {
    return prefix + durationToJump.format('s[s]')
  }

  if (absSeconds < 60 * 10) {
    return prefix + durationToJump.format('m[m] ss[s]')
  }

  if (absSeconds < 60 * 60) {
    return prefix + durationToJump.format('m[m]')
  }

  if (shouldUseExtendedCountdown && absSeconds < 24 * 60 * 60) {
    return prefix + durationToJump.format('H[h] m[m]')
  }

  if (shouldUseExtendedCountdown && absSeconds < 7 * 24 * 60 * 60) {
    const days = durationToJump.days()
    const hours = durationToJump.hours()
    return `${prefix}${days}d ${hours}h`
  }

  return dayjs(advertisedStartTime).format('HH:mm')
}

// ======
// Styles
// ======

const WrapperStyled = styled.span({
  display: 'flex',
  alignItems: 'stretch !important',

  '> *': {
    minWidth: '5.5rem',
  },
})

// =====
// Types
// =====

type CountdownBadgeState = {
  text: string
  color: StatusBadgeColor
}

type StatusBadgeSize = Pick<React.ComponentProps<typeof StatusBadge>, 'badgeSize'>
type StatusBadgeColor = React.ComponentProps<typeof StatusBadge>['color']
