import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import { BaseButtonStyled } from './ButtonsBase.styles'
import { buttonColors, ButtonTypes } from './themes'
import { hexColors } from '@mobi/settings'

export interface ButtonProps {
  color?: keyof ButtonTypes
  selected?: boolean
  uppercaseText?: boolean
}

export const ButtonStyled = styled(BaseButtonStyled)<ButtonProps>(
  ({ color = 'primary', selected, uppercaseText }) => ({
    backgroundColor: selected
      ? buttonColors[color].selected.background
      : buttonColors[color].background,
    borderColor: buttonColors[color].border,
    color: buttonColors[color].text,
    textTransform: uppercaseText ? 'uppercase' : undefined,
    userSelect: 'none',

    '&:hover': {
      backgroundColor: selected
        ? buttonColors[color].selected.background
        : buttonColors[color].background,
    },

    '&:active': {
      backgroundColor: selected
        ? buttonColors[color].selected.hover.background
        : buttonColors[color].hover.background,
    },

    '&[disabled]': {
      backgroundColor: buttonColors[color].disabled.background,
      color: buttonColors[color].disabled.text
        ? buttonColors[color].disabled.text
        : buttonColors[color].text,

      '&:hover': {
        backgroundColor: buttonColors[color].disabled.background,
      },
    },
  })
)

export const ButtonBlockStyled = styled(ButtonStyled)<{ isDesktop?: boolean }>(
  {
    height: '4rem',
    textAlign: 'center',
    width: '100%',
  },
  ({ isDesktop }) => ({
    margin: isDesktop ? '0 0.5rem' : undefined,
    fontSize: isDesktop ? '1.2rem' : undefined,
    lineHeight: isDesktop ? '1.4rem' : undefined,
  })
)

export const ButtonSubTextStyled = styled('span')({
  display: 'block',
})

export interface ButtonWithIconProps {
  color?: 'dark' | 'light'
}

export const ButtonWithIconStyled = styled('button')<ButtonWithIconProps>(
  {
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    padding: 0,
  },

  ({ color = 'dark' }) => ({
    color: color === 'dark' ? hexColors.black : hexColors.white,
  })
)

export const ButtonCloseStyled = styled('button')<ButtonWithIconProps>({
  backgroundColor: hexColors.grey,
  border: 0,
  color: hexColors.white,
  cursor: 'pointer',
  fontSize: '1rem',
  height: '3.4rem',
  position: 'absolute',
  right: '0',
  top: '0',
  width: '3.4em',
})

const BaseMaterialButtonStyled = styled('a')({
  fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
  fontSize: '1.6rem',
  fontWeight: '300',
  marginTop: '0.5rem',
  borderRadius: '0.4rem',
  boxShadow: '0px 0.5rem 1rem rgba(0, 0, 0, 0.1)',
  marginBottom: '0.5rem',
  flex: '1',
  height: '3rem',
  textAlign: 'center',
  verticalAlign: 'middle',
  display: 'inline-block',
  transition: 'background-color 200ms',
  touchAction: 'manipulation',
  lineHeight: 1,
  paddingTop: '1.5rem',
  textDecoration: 'none',
  '& svg': {
    verticalAlign: 'middle',
    marginRight: '0.8rem',
    marginTop: '-0.25rem',
  },
  '&:disabled': {
    backgroundColor: hexColors.darkGrey,
  },
})

export const SecondaryButtonStyled = styled(BaseMaterialButtonStyled)({
  color: hexColors.nero,
  backgroundColor: hexColors.white,
  '&:active:not(:disabled)': {
    backgroundColor: darken(0.1, hexColors.white),
  },
})

export const PrimaryButtonStyled = styled(BaseMaterialButtonStyled)({
  color: hexColors.white,
  backgroundColor: hexColors.studio,
  '&:active:not(:disabled)': {
    backgroundColor: darken(0.1, hexColors.studio),
  },
})

// ================
// Button Selection
// ================

// FIXME: Change this to just reference hexColors directly
const {
  default: { background: defaultBackground, color: defaultColor, borderColor: defaultBorderColor },
  selected: {
    background: selectedBackground,
    color: selectedColor,
    borderColor: selectedBorderColor,
  },
  disabled: { background: disabledBackground, color: disabledColor },
} = {
  default: {
    background: `linear-gradient(to bottom, ${hexColors.white}, ${hexColors.whiteSmoke})`,
    color: hexColors.nero,
    borderColor: hexColors.veryLightGrey,
  },
  selected: {
    background: hexColors.nero,
    color: hexColors.white,
    borderColor: hexColors.nero,
  },
  disabled: {
    background: hexColors.gainsboro,
    color: hexColors.grey,
  },
}

export interface ButtonSelectionStyled {
  isSelected: boolean
  isDisabled?: boolean
}

export const ButtonSelectionStyled = styled('button')<ButtonSelectionStyled>(
  {
    position: 'relative',
    boxSizing: 'border-box',

    padding: 0,
    borderRadius: '0.3rem',

    fontFamily: 'inherit',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: 1,
    textAlign: 'right',
    cursor: 'pointer',
  },
  ({ isSelected, isDisabled = false }) => ({
    background: isSelected
      ? selectedBackground
      : isDisabled
        ? disabledBackground
        : defaultBackground,
    color: isSelected ? selectedColor : isDisabled ? disabledColor : defaultColor,
    border: isSelected
      ? `0.1rem solid ${selectedBorderColor}`
      : `0.1rem solid ${defaultBorderColor}`,
    boxShadow: isDisabled ? 'none' : '0 0.1rem 0.1rem rgba(0,0,0,0.1)',
  })
)

/**
 * CSS reset from modern-normalize
 *
 * @see https://github.com/sindresorhus/modern-normalize/blob/main/modern-normalize.css#L160
 * @see https://github.com/sindresorhus/modern-normalize/blob/main/modern-normalize.css#L179
 * @see https://github.com/sindresorhus/modern-normalize/blob/main/modern-normalize.css#L188
 */
export const buttonReset = css({
  fontFamily: 'inherit',
  fontSize: '100%',
  lineHeight: 1.15,
  margin: 0,
  textTransform: 'none',
  WebkitAppearance: 'button',
})

export const ButtonResetStyled = styled.button(buttonReset)

export const UnstyledButton = styled.button([
  buttonReset,
  {
    background: 'none',
    border: 'none',
    padding: 0,
  },
])
