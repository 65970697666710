import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIconStyled } from '@mobi/component-library/Common/Icon/Icon.styles'

const SunAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const RayAnimation = keyframes({
  '0%': {
    strokeDasharray: '3px 10px',
    strokeDashoffset: '0px',
  },
  '50%': {
    strokeDasharray: '0.1px 10px',
    strokeDashoffset: '-1px',
  },
  '100%': {
    strokeDasharray: '3px 10px',
    strokeDashoffset: '0px',
  },
})

const Sun = styled('g')({
  animationName: SunAnimation,
  animationDuration: '9s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
})

const Ray = styled('line')({
  animationName: RayAnimation,
  animationDuration: '2s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
})

export const Day = () => {
  return (
    <SvgIconStyled
      width='40'
      height='40'
      viewBox='0 0 64 64'
      fill='none'
      size='3rem'
      data-testid='day'
    >
      <defs>
        <filter id='blur' width='200%' height='200%'>
          <feGaussianBlur in='SourceAlpha' stdDeviation='3' />
          <feOffset dx='0' dy='4' result='offsetblur' />
          <feComponentTransfer>
            <feFuncA type='linear' slope='0.05' />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g filter='url(#blur)' id='day'>
        <g transform='translate(32,32)'>
          <Sun>
            <g>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(45)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(90)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(135)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(180)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(225)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(270)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
            <g transform='rotate(315)'>
              <Ray
                fill='none'
                stroke='orange'
                strokeLinecap='round'
                strokeWidth='2'
                transform='translate(0,9)'
                x1='0'
                x2='0'
                y1='0'
                y2='3'
              />
            </g>
          </Sun>
          <circle cx='0' cy='0' fill='orange' r='5' stroke='orange' strokeWidth='2' />
        </g>
      </g>
    </SvgIconStyled>
  )
}
