import React from 'react'
import { PasswordThemes, getPasswordTheme } from '../themes'
import { ThemeProvider } from '@emotion/react'
import {
  PasswordVisibilityToggleStyled,
  PasswordHideIconStyled,
  PasswordVisibilityToggleLabelStyled,
} from '../Password.styles'

interface PasswordVisibilityToggleProps {
  isPasswordVisible: boolean
  onClick: () => void
  themeName?: PasswordThemes
  hasText?: boolean
}

export function PasswordVisibilityToggle({
  themeName,
  onClick,
  isPasswordVisible,
  hasText = true,
}: PasswordVisibilityToggleProps): JSX.Element {
  const theme = getPasswordTheme(themeName)
  // This is to prevent onBlur of input field when selecting toggle
  const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()
  const testAutomationId = { [`data-tid-password-${isPasswordVisible ? 'hide' : 'show'}`]: '' }

  return (
    <ThemeProvider theme={theme}>
      <PasswordVisibilityToggleStyled
        onClick={onClick}
        onMouseDown={onMouseDown}
        {...testAutomationId}
        aria-label={`${isPasswordVisible ? 'Hide' : 'Show'} password`}
      >
        {hasText && (
          <PasswordVisibilityToggleLabelStyled>
            {isPasswordVisible ? 'Hide' : 'Show'}
          </PasswordVisibilityToggleLabelStyled>
        )}
        <PasswordHideIconStyled type={isPasswordVisible ? 'visibilityoff' : 'visibilityon'} />
      </PasswordVisibilityToggleStyled>
    </ThemeProvider>
  )
}
