import { Ticket } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import React from 'react'

export const TippingOrFooto: React.FC<{ investment: Ticket['investment']['lines'][0] }> = ({
  investment,
}) => {
  if (investment.count === 1) return null

  return (
    <div>
      <table>
        <thead>
          <tr data-testid='ticket-detail-investment-header'>
            <th className='text-left'>Combs</th>
            <th className='text-right'>Bet Amount</th>
            <th className='text-right'>Cost</th>
          </tr>
        </thead>

        <tbody>
          <tr data-testid='ticket-detail-investment-line'>
            <td className='text-left'>{investment.count}</td>
            <td className='text-right'>
              <Money amount={investment.betValue} />
            </td>
            <td className='text-right'>
              <Money amount={investment.totalValue} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
