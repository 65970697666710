import { fromJS, Map } from 'immutable'
import { createSignal } from 'rwwa-rx-state-machine'
import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { StateMap } from 'typings/immutable'
import { isBetslipResponses } from '@core/Data/betslip'
import { ConfirmBetslipBetsSucceeded, QuickbetClosed } from '../Quickbet/signals'

// Signals

export const ToggleSelectionForGame = createSignal<ToggleSelectionForGame>('ToggleSelectionForGame')
export const ClearToteSportSelections = createSignal('ClearToteSportSelections')

// Driver

export const defaultState: TippingState = {
  selections: {},
  betConfirmed: false,
}

type TippingStateMap = StateMap<TippingState>

export const driver = (state = fromJS(defaultState), signal: Signal): TippingStateMap => {
  switch (signal.tag) {
    case ToggleSelectionForGame: {
      const { gameId, selectionType } = signal.data as ToggleSelectionForGame
      const current = state.getIn(['selections', gameId])
      if (current) {
        switch (selectionType) {
          case SelectionType.Home:
            return state.setIn(['selections', gameId, 'isHome'], !current.get('isHome'))
          case SelectionType.Draw:
            return state.setIn(['selections', gameId, 'isDraw'], !current.get('isDraw'))
          case SelectionType.Away:
            return state.setIn(['selections', gameId, 'isAway'], !current.get('isAway'))
        }
      }
      const newGame = {
        isHome: selectionType === SelectionType.Home,
        isDraw: selectionType === SelectionType.Draw,
        isAway: selectionType === SelectionType.Away,
      }
      return state.setIn(['selections', gameId], Map(newGame))
    }

    case ClearToteSportSelections: {
      return fromJS(defaultState)
    }

    case ConfirmBetslipBetsSucceeded: {
      if (isBetslipResponses(signal.data)) {
        return state.merge({
          betConfirmed: true,
        })
      }
      return state
    }

    case QuickbetClosed: {
      if (state.get('betConfirmed')) {
        return fromJS(defaultState)
      }
      return state
    }

    default:
      return state
  }
}

export const state$ = attachDriver<TippingStateMap>({
  driver,
  path: 'sports-tipping',
}).map(s => s.toJS())

// Types

export enum SelectionType {
  Home,
  Draw,
  Away,
}

interface ToggleSelectionForGame {
  gameId: string
  selectionType: SelectionType
}

interface Selection {
  isHome: boolean
  isAway: boolean
  isDraw: boolean
}

export interface Selections {
  [gameKey: string]: Selection
}

interface TippingState {
  selections: Selections
  betConfirmed: false
}
