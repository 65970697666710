import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const ContentBodyStyled = styled('div')({
  margin: '0 1rem',
})

export const GeneralSettingStyled = styled('div')({
  padding: '1rem 0',
  borderBottom: `1px solid ${hexColors.darkGrey}`,
})

export const BiometricsPageSubHeaderStyled = styled('div')({
  fontWeight: 'bold',
  fontSize: '1.4rem',
})
