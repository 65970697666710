import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { selectBetSlipItemCount } from '@mobi/betslip/Store/Bets/selectors'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { Badge } from '@mobi/component-library/Feedback/Badge'
import { font, colors, spacing, measurements } from '@mobi/component-library/Theme/Common'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { useLogon } from '@core/Utils/hooks'
import { BalancePill } from '@core/Areas/AppHeader/Components/Balance/BalancePill'
import { usePendingTicketsCount } from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/usePendingTicketsCount'
import { ACCOUNT_ACTIVITY_PENDING_ROUTE } from '@core/Areas/AccountActivity/constants'
import { showLogin } from '@core/Areas/Login/helpers'
import { AppRoutes } from '@core/AppRoutes'

export const HeaderPanel: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { isLoggedIn } = useLogon()
  const betslipCount = useAppSelector(selectBetSlipItemCount)
  const { pendingBetsCount } = usePendingTicketsCount({ enabled: !!isLoggedIn })

  return (
    <HeaderPanelWrapperStyled>
      <div>
        <h2>
          <button onClick={() => dispatch(setBetSlipNewIsOpen(false))}>
            <Icon name='SolidChevronLeft' size='2.4rem' color={colors.lavender[700]} />
          </button>
          Bet Slip
        </h2>

        <Badge color='purple'>{betslipCount}</Badge>
      </div>

      {isLoggedIn ? (
        <div>
          <div>
            <BalancePill renderContext='betslip' />
          </div>

          <Button
            size='sm'
            color='secondary_color'
            onClick={() => {
              history.push(`/#${ACCOUNT_ACTIVITY_PENDING_ROUTE}`)
              dispatch(setBetSlipNewIsOpen(false))
            }}
          >
            <div>
              <span>{pendingBetsCount}</span>

              <span>Pending</span>
            </div>
          </Button>
        </div>
      ) : (
        <div>
          <Button size='sm' color='secondary_color' onClick={() => showLogin()}>
            <div>
              <span>Login</span>
            </div>
          </Button>

          <Button
            size='sm'
            color='primary'
            onClick={() => {
              history.push(AppRoutes.SignUp)
              dispatch(setBetSlipNewIsOpen(false))
            }}
          >
            <div>
              <span>Sign up</span>
            </div>
          </Button>
        </div>
      )}
    </HeaderPanelWrapperStyled>
  )
}

const HeaderPanelWrapperStyled = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: spacing.sm,
  paddingLeft: 0,
  height: measurements.mobi.headerHeight,
  fontFamily: font.family.primary,

  '> div:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.smx1,

    // title
    '> h2': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: font.weight.semibold,
      fontSize: font.size.xl2.fontSize,
      lineHeight: font.size.xl2.lineHeight,
      letterSpacing: font.size.xl2.letterSpacing,
      color: colors.black,
      textTransform: 'unset',
      textShadow: 'none',

      // chevron
      '> button': {
        alignSelf: 'stretch',
        margin: 0,
        padding: 0,
        border: 0,
        background: 'transparent',
        minWidth: '5rem',
      },
    },

    // number of bets
    '> span': {
      fontWeight: font.weight.semibold,
    },
  },

  // balance and pending
  '> div:nth-of-type(2)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.smx1,

    '> button': {
      paddingLeft: spacing.smx1,
      paddingRight: spacing.smx1,

      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,

        '> span:nth-of-type(1)': {
          fontWeight: font.weight.medium,
          fontSize: font.size.lg.fontSize,
          lineHeight: font.size.lg.lineHeight,
          letterSpacing: font.size.lg.letterSpacing,
        },

        '> span:nth-of-type(2)': {
          marginTop: '-0.2rem',
          fontWeight: font.weight.regular,
          fontSize: font.size.xs.fontSize,
          lineHeight: font.size.xs.lineHeight,
        },
      },
    },
  },
})
