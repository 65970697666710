import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export interface SingleSilkImageProps {
  isDogsSilk: boolean
}

export const SingleSilkImageStyled = styled.img<SingleSilkImageProps>(({ isDogsSilk }) => {
  return {
    maxWidth: '2.5rem',
    height: 'fit-content',
    boxSizing: 'border-box',
    border: (isDogsSilk && `1px solid ${hexColors.nero}`) || undefined,
  }
})
