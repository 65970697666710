import React from 'react'
import { ThemeProvider } from '@emotion/react'
import {
  PasswordFormStyled,
  PasswordInputStyled,
  PasswordLabelStyled,
  PasswordGroupStyled,
} from '../Password.styles'
import { getPasswordTheme, PasswordThemes } from '../../Password/themes'
import { PasswordVisibilityToggle } from './VisibilityToggle'

export interface PasswordComponent {
  labelName: string
  themeName?: PasswordThemes
  onChange: (event: React.FormEvent<HTMLInputElement>) => void | null
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void | null
  onVisibleToggle: () => void
  isPasswordVisible: boolean
  validationError: false | string
  shouldScrollIntoView?: boolean
  testId?: string
}

export function PasswordComponent({
  labelName,
  themeName,
  onChange,
  onBlur,
  onVisibleToggle,
  isPasswordVisible,
  validationError,
  shouldScrollIntoView,
  testId,
}: PasswordComponent): JSX.Element {
  const labelRef = React.useRef<HTMLLabelElement>(null)
  const theme = getPasswordTheme(themeName)
  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    onChange(event)
  }

  const onFocus = () =>
    shouldScrollIntoView && labelRef.current?.scrollIntoView && labelRef.current?.scrollIntoView()

  return (
    <ThemeProvider theme={theme}>
      <PasswordFormStyled>
        <PasswordLabelStyled htmlFor={labelName} ref={labelRef}>
          {labelName || 'Password*'}
        </PasswordLabelStyled>
        <PasswordGroupStyled>
          <PasswordInputStyled
            id={labelName}
            onChange={onChangePassword}
            onFocus={onFocus}
            onBlur={onBlur}
            type={isPasswordVisible ? 'text' : 'password'}
            errorMessage={validationError}
            testId={testId}
          />
          <PasswordVisibilityToggle
            isPasswordVisible={isPasswordVisible}
            onClick={onVisibleToggle}
            themeName={themeName}
          />
        </PasswordGroupStyled>
      </PasswordFormStyled>
    </ThemeProvider>
  )
}
