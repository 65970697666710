import React from 'react'
import { getDeliveryMethodName, DeliveryMethods } from '../..'
import type { usePasswordReset } from '../hooks/usePasswordReset'

export const Step2SendVerification: React.FC<Step2SendVerificationProps> = ({
  deliveryMethods,
  selectedDeliveryMethod,
  onDeliveryMethodChange,
}) => {
  const isSingle = deliveryMethods.length === 1
  const title = isSingle
    ? `A verification code will be sent to your ${
        getDeliveryMethodName(deliveryMethods[0].type) === 'Email'
          ? 'email account'
          : 'phone via SMS'
      }`
    : 'Where should we send a verification code to?'

  return (
    <>
      <label>{title}</label>

      <DeliveryMethods
        deliveryMethods={deliveryMethods}
        onDeliveryMethodChange={onDeliveryMethodChange}
        selectedDeliveryMethod={selectedDeliveryMethod}
      />
    </>
  )
}

// Types

type Step2SendVerificationProps = Pick<
  ReturnType<typeof usePasswordReset>['handlers'],
  'onDeliveryMethodChange'
> &
  Pick<ReturnType<typeof usePasswordReset>['state'], 'deliveryMethods' | 'selectedDeliveryMethod'>
