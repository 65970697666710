import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getPendingTicketsCount } from '@core/Areas/AccountActivity/helpers/api'

export const usePendingTicketsCount = (isEnabled: boolean) => {
  const { data } = useQuery({
    queryKey: queryKeys.pendingTicketsCount,
    queryFn: getPendingTicketsCount,
    enabled: isEnabled,
  })

  const pendingBets = data?.pendingTicketsCount ?? 0

  return {
    pendingBetsCount: Math.min(pendingBets, 99),
  }
}
