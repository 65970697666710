import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { useClickOutside } from '@core/Utils/hooks/useClickOutside'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import {
  getTicketLayoutType,
  isFobRacingWithIncorrectStarterNumbers,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { TicketFooterButtonStyled as FooterButton } from '../../Footer.styles'
import { BlackbookMenu } from './Components/BlackbookMenu'

export const BlackbookButton: React.FC<{
  ticket: Ticket
  activeTab: Extract<Tabs, 'pending' | 'results'>
}> = ({ ticket, activeTab }) => {
  const ticketLayoutType = getTicketLayoutType(ticket)

  const shouldDisplayBlackbookControl =
    ticketLayoutType === 'single-single' &&
    !isFobRacingWithIncorrectStarterNumbers(ticket.planSeq, ticket.legs[0].betType)

  if (!shouldDisplayBlackbookControl) return null

  const leg = ticket.legs[0]
  const starterName = leg.selections[0].selectionDetails[0].acceptorDetails[0]?.name.toUpperCase()
  const starterType = meetingIdToContestType(leg.fixtureId, true)

  if (!(starterName && starterType)) return null

  return (
    <BlackbookRender
      starterName={starterName}
      starterType={starterType}
      fixtureId={leg.fixtureId}
      fixtureDate={leg.fixtureDate}
      raceNumber={leg.raceNumber}
      source={activeTab}
    />
  )
}

// ===============
// LOCAL COMPONENT
// ===============

const BlackbookRender = ({
  starterName,
  starterType,
  fixtureId,
  fixtureDate,
  raceNumber,
  source,
}: BlackbookRenderProps) => {
  const blackbookBtnRef = React.useRef<HTMLButtonElement>(null)
  const clickAwayRef = React.useRef<HTMLDivElement>(null)

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  useClickOutside(clickAwayRef, () => setIsMenuOpen(false))

  const handleClick = () => setIsMenuOpen(curr => !curr)

  return (
    <div ref={clickAwayRef}>
      <FooterButton
        data-testid='blackbook-button'
        color='link'
        onClick={handleClick}
        ref={blackbookBtnRef}
      >
        <Icon type={isMenuOpen ? 'plusCircledFill' : 'plusCircled'} size='1.7rem' />{' '}
        <span>Blackbook</span>
      </FooterButton>

      {isMenuOpen && (
        <BlackbookMenu
          blackbookBtnRef={blackbookBtnRef}
          starterName={starterName}
          starterType={starterType}
          fixtureId={fixtureId}
          fixtureDate={fixtureDate}
          raceNumber={raceNumber}
          source={source}
        />
      )}
    </div>
  )
}

// =====
// Types
// =====

type BlackbookRenderProps = Parameters<typeof useAddToBlackbook>[0] &
  Pick<Ticket['legs'][0], 'fixtureId' | 'fixtureDate' | 'raceNumber'>
