import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'

import { NextRaces } from './NextRaces'

export const NextEventsPage = () => {
  return (
    <MainContainer>
      <ContentHeader title='Next Races' />
      <NextRaces />
    </MainContainer>
  )
}
