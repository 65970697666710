import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { IObservableStarter } from '@classic/Betting-v2/Model/Observables/IObservableStarter'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { BaseBetType } from '@classic/Betting-v2/Components/BetTypes/BaseBetType/BaseBetType'
import { IUseGiddyUp } from '@classic/Betting-v2/Components/Pages/Starters/IUseGiddyUp'

export class SameRaceMultiViewModel extends BaseBetType {
  constructor(params: {
    raceInfo: ObservableRacePage
    raceStarters: ko.Observable<{
      Key: ObservableRaceKey
      Value: ko.ObservableArray<IObservableStarter>
    }>
    selections: ko.ObservableArray<IObservableStarter>
    context: { bettingContext: BettingInformation }
    useGiddyUp: IUseGiddyUp
  }) {
    super(
      params.raceInfo,
      params.raceStarters,
      params.selections,
      params.context.bettingContext,
      params.useGiddyUp
    )
  }
}
