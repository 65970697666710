import React from 'react'
import { VIDEO_PLAYER_PARENT_ID } from '../VideoPlayer'
import { store } from '@core/Store'
import { setIsFullscreen } from '@core/Areas/SkyVideoPlayer/Store'

// ==========================
// Method Listening to Events
// ==========================
export const handleFullscreenChange = () => {
  const videoEl = getVisionVideoEl()

  if (videoEl?.webkitSupportsPresentationMode) {
    if (videoEl.webkitPresentationMode === 'picture-in-picture') {
      exitPresentationModeFullscreen()
      return
    }
    store.dispatch(setIsFullscreen(isVideoElInPresentationModeFullscreen(videoEl)))
    return
  }

  store.dispatch(setIsFullscreen(isElementInFullscreen()))
}

// ===============
// Main React Hook
// ===============
export const useFullscreen = (isFullscreen: boolean) => {
  React.useEffect(() => {
    // On unmount Exit Fullscreen
    return () => exitFullscreen()
  }, [])

  React.useEffect(() => {
    if (isFullscreen) {
      try {
        enterFullscreen()
      } catch {
        store.dispatch(setIsFullscreen(false))
      }
    }

    if (!isFullscreen) {
      exitFullscreen()
    }
  }, [isFullscreen])
}

// ===============
// General Helpers
// ===============

function getVisionVideoEl() {
  return document.querySelector(`#${VIDEO_PLAYER_PARENT_ID} video`) as HTMLVideoElement | null
}

function getVisionParentEl() {
  return document.getElementById(VIDEO_PLAYER_PARENT_ID) as HTMLDivElement | null
}

function enterFullscreen() {
  enterPresentationModeFullscreen() || enterElementFullscreen()
}

function exitFullscreen() {
  if (!isFullscreenActive()) {
    return
  }
  exitElementFullscreen() || exitPresentationModeFullscreen()
}

function isFullscreenActive() {
  if (isElementInFullscreen()) {
    return true
  }
  const videoEl = getVisionVideoEl()
  return videoEl && isVideoElInPresentationModeFullscreen(videoEl)
}

// ==========================
// Element Fullscreen Helpers
// ==========================
function isElementInFullscreen() {
  return (
    !!document.fullscreenElement ||
    !!document.webkitFullscreenElement ||
    !!document.webkitCurrentFullScreenElement
  )
}

function enterElementFullscreen() {
  const parentEl = getVisionParentEl()
  if (!parentEl) {
    return false
  }
  if (parentEl.requestFullscreen) {
    parentEl.requestFullscreen()
    return true
  }
  if (parentEl.webkitRequestFullscreen) {
    parentEl.webkitRequestFullscreen()
    return true
  }
  if (parentEl.webkitRequestFullScreen) {
    parentEl.webkitRequestFullScreen()
    return true
  }
  return false
}

function exitElementFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
    return true
  }
  if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
    return true
  }
  if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen()
    return true
  }
  return false
}

// =========================
// Presentation Mode Helpers
// =========================
// https://developer.apple.com/documentation/webkitjs/htmlvideoelement
function isVideoElInPresentationModeFullscreen(videoEl: HTMLVideoElement): boolean {
  return videoEl.webkitPresentationMode === 'fullscreen'
}

function exitPresentationModeFullscreen() {
  const videoEl = getVisionVideoEl()
  if (videoEl && videoEl.webkitPresentationMode) {
    videoEl.webkitSetPresentationMode('inline')
    return true
  }
  return false
}

function enterPresentationModeFullscreen(): boolean {
  const videoEl = getVisionVideoEl()
  if (videoEl && videoEl.webkitPresentationMode) {
    videoEl.webkitSetPresentationMode('fullscreen')
    return true
  }
  return false
}
