import React from 'react'
import { callCenterPhoneNumber } from '@mobi/utils'
import { HeaderStyled, PaddingStyled, ParagraphStyled } from './Precommitment.styles'

export const PrecommitmentInfo = ({
  coolingOffPeriod,
}: {
  coolingOffPeriod: number
}): JSX.Element => (
  <>
    <HeaderStyled id='bet-limit-info' level={3}>
      More Information
    </HeaderStyled>
    <PaddingStyled>
      <h4>Set and control your betting and deposit limits</h4>
      <ParagraphStyled>
        We want your betting experience with TABtouch to be a positive and happy one; therefore we
        have put in place a number of limits that you can set on your account to ensure you are not
        betting beyond your means. You can set a single bet limit and/or a total weekly deposit
        limit on your TABtouch account.
      </ParagraphStyled>
      <ParagraphStyled>
        <strong>Please note:</strong> When setting your ‘Total Weekly Deposit Limit’ please be aware
        that the new limit set will be inclusive of any deposits made to your account within the
        last 7 days.
      </ParagraphStyled>
      <ParagraphStyled>
        <em>
          These limits apply to both racing and sports betting and must be in whole dollars.
          Increasing and removing bet limits from your account will be subject to a{' '}
          <strong>{coolingOffPeriod} day cooling off period</strong>.
        </em>
      </ParagraphStyled>
      <ParagraphStyled>
        If you require any assistance in setting or editing your bet limits please call our Customer
        Management Team on <a href={callCenterPhoneNumber}>1300&nbsp;36&nbsp;36&nbsp;88</a>.
      </ParagraphStyled>
    </PaddingStyled>
  </>
)
