import { useRef, useState, useEffect } from 'react'
import { useMutation, useIsMutating, useQueryClient } from 'react-query'
import { type PayIdDetails } from '@mobi/api-types/src/account/betAccount'
import { put } from '../../Utils/network'
import { track } from '../../Utils/analytics'
import { reportErrorIfNeeded } from '../../Utils/sentry'
import { addCrumb } from '@mobi/utils'
import { queryKeys } from '../../Areas/Deposit/Utils'

export function usePayID({ accountNumber, payIdDetailList }: Params) {
  const isGeneratingPayId = useIsMutating({ mutationKey: [queryKeys.generatePayId] })
  const [status, setStatus] = useState<PayIdStatuses>(
    getPayIdStatus(payIdDetailList, isGeneratingPayId)
  )
  const queryClient = useQueryClient()

  useEffect(() => setStatus(getPayIdStatus(payIdDetailList)), [payIdDetailList])

  // Official failureCount support is in @tanstack-query v4+
  // This is a ponyfill for it
  const failureCount = useRef(0)

  const { mutate: generatePayId } = useMutation({
    mutationFn: () =>
      put('/api/eft-mgt/payid/requestcreate', {
        betAccountNumber: accountNumber,
      }),
    mutationKey: queryKeys.generatePayId,
    onError: (response: Response) => {
      switch (response.status) {
        case 500:
        case 504:
          setStatus('retry')
          break
        case 400:
        case 401:
        case 404:
        case 405:
        default:
          setStatus('error')
          break
      }

      incrementFailureCount()
      track('deposit_payid_failed', {
        accountNumber,
        timeStamp: new Date().toISOString(),
      })
      reportErrorIfNeeded({
        message: `PayId /requestcreate error: ${response.statusText}`,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.depositBetAccount] })
      addCrumb('deposit', 'PayId /requestcreate success')
    },
    onMutate: () => addCrumb('deposit', 'PayId /requestcreate mutate'),
  })

  const incrementFailureCount = () => {
    failureCount.current++
    if (failureCount.current >= 3) {
      setStatus('error')
    }
  }

  const generate = () => {
    if (isGeneratingPayId) return

    track('deposit_generate_payid', {
      accountNumber,
      timeStamp: new Date().toISOString(),
    })
    setStatus('loading')
    generatePayId()
  }

  return {
    generate,
    activePayID: findActivePayId(payIdDetailList),
    status,
  }
}

// #region Types

type Params = {
  accountNumber: number
  payIdDetailList: PayIdDetails[]
}

type PayIdStatuses = 'no-payid' | 'loading' | 'error' | 'retry' | 'generated'

// #endregion

// #region Utils

const getPayIdStatus = (payIds: PayIdDetails[], isGeneratingPayId: number = 0): PayIdStatuses => {
  if (isGeneratingPayId || findPayIdPendingActivation(payIds)) return 'loading'
  if (payIds.length === 0) return 'no-payid'
  if (findActivePayId(payIds)) return 'generated'

  return 'error' // zepto status failed | deregistered | disabled
}

const findActivePayId = (payIds: PayIdDetails[]) =>
  payIds.find(payID => payID.CreationStatus === 'active')

const findPayIdPendingActivation = (payIds: PayIdDetails[]) =>
  payIds.find(payID => payID.CreationStatus === 'pending')

// #endregion
