import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const LocationTimeStyled = styled('div')({
  fontSize: '1rem',
  display: 'flex',
})

export const RaceNumberStyled = styled('span')({
  paddingLeft: '0.25rem',
})

export const SearchResultIconStyled = styled('span')({
  marginLeft: '0',
})

export const SearchResultRaceStatusStyled = styled('span')({
  color: hexColors.freeSpeechRed,
  fontSize: '1rem',
  marginRight: '0.5rem',
})

export const SearchResultASTTimerSTyled = styled('div')({
  'div > strong': {
    fontWeight: 400,
    fontSize: '1rem',
    color: hexColors.dimGrey,
  },
  paddingRight: '.5rem',
  paddingBottom: '.1rem',
})

export const SearchLocationNameStyled = styled('span')({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: 'auto',
  marginTop: '-.2rem',
  color: hexColors.dimGrey,
  '@media (max-width: 350px)': {
    maxWidth: '20rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
})

export const ScratchedAcceptorStyled = styled('span')({
  color: hexColors.freeSpeechRed,
  marginRight: '0.5rem',
})
