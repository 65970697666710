import React from 'react'
import styled from '@emotion/styled'
import { colors, hexColors } from '@mobi/settings'
import { store } from '@core/Store'
import { H3 } from '@core/Components/Text/Heading.styles'
import { closeModal } from '@core/Components/Modal'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import {
  addToBetslip,
  addToQuickbet,
  getErrorMessage,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/helpers/loadBet/loadBet'
import { LoadBetErrors } from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/helpers/loadBet/types'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { getShareBetDataWithKey } from './helpers/api'
import { trackShareBetLinkOpen } from './helpers/analytics'

export const ShareBetIncoming: React.FC<ShareIncomingProps> = ({ shareId }) => {
  const betslipPartialValuesRef = React.useRef<OpenSharedBetReturn>({ total: 0, success: 0 })
  const [state, setState] = React.useState<'loading' | 'error' | 'closed' | 'partial-multi'>(
    'loading'
  )

  React.useEffect(() => {
    openSharedBet(shareId)
      .then(({ total, success, isClosed }) => {
        if (isClosed) {
          setState('closed')
          return
        }
        if (success > 0 && total !== success) {
          betslipPartialValuesRef.current = { total, success }
          setState('partial-multi')
          return
        }
        dismiss(shareId)
      })
      .catch(() => {
        setState('error')
      })
  }, [shareId])

  return (
    <Wrapper data-testid='share-bet-modal'>
      <H3>Opening a Shared Bet</H3>

      {state === 'loading' && (
        <div aria-busy>
          <SpinnerInlineStyled size={5} color='light' />
        </div>
      )}

      {state === 'partial-multi' && (
        <>
          <NoticeBox
            noticeBoxType={NoticeBoxTypes.Warning}
            title='Betting is closed for some legs'
            subtitle={`${betslipPartialValuesRef.current.success} of ${betslipPartialValuesRef.current.total} legs were added to Betslip`}
          />
          <CloseButton shareId={shareId} />
        </>
      )}

      {state === 'closed' && (
        <>
          <NoticeBox
            noticeBoxType={NoticeBoxTypes.Error}
            title='Betting is closed for this shared bet'
          />
          <CloseButton shareId={shareId} />
        </>
      )}

      {state === 'error' && (
        <>
          <NoticeBox
            noticeBoxType={NoticeBoxTypes.Error}
            title='Unable to open shared bet'
            subtitle='Sorry, an error occurred while trying to open this share'
          />
          <CloseButton shareId={shareId} />
        </>
      )}
    </Wrapper>
  )
}

// =============
// Local Helpers
// =============

async function openSharedBet(shareId: string): Promise<OpenSharedBetReturn> {
  const { data, accountNumber: origin } = await getShareBetDataWithKey(shareId)

  if (!data || data.length == 0) {
    throw new Error('No Bets Provided')
  }

  try {
    if (data.length === 1) {
      await addToQuickbet(data[0], { shouldNavigateToRace: true })

      trackShareBetLinkOpen({ origin, betType: 'single' })
      return { total: 1, success: 1 }
    }
  } catch (error) {
    if (getErrorMessage(error) == LoadBetErrors.BettingClosed) {
      trackShareBetLinkOpen({ origin, betType: 'closed' })
      return { total: 1, success: 0, isClosed: true }
    }
    throw error
  }

  try {
    const addToBetslipResult = await addToBetslip(data)

    trackShareBetLinkOpen({
      origin,
      betType: addToBetslipResult.success !== addToBetslipResult.total ? 'multi-partial' : 'multi',
    })

    return addToBetslipResult
  } catch (error) {
    if (error == LoadBetErrors.BettingClosed) {
      trackShareBetLinkOpen({ origin, betType: 'closed' })
      return { total: data.length, success: 0, isClosed: true }
    }
    throw error
  }
}

function dismiss(shareId: string) {
  store.dispatch(closeModal({ id: shareId }))
}

const CloseButton = ({ shareId }: Pick<ShareIncomingProps, 'shareId'>) => (
  <ButtonBlock color='primary' onClick={() => dismiss(shareId)}>
    Close
  </ButtonBlock>
)

// ================
// Styled Component
// ================

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '10rem',
  background: colors.brand.primary,
  padding: '1rem',
  color: hexColors.white,

  h3: {
    margin: 0,
    textAlign: 'center',
    marginBottom: '0.5rem',
    color: hexColors.white,
    fontWeight: 'bold',
  },

  'div[aria-busy="true"]': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.5rem',
  },

  button: {
    marginTop: 'auto',
  },
})

// =====
// Types
// =====
interface ShareIncomingProps {
  shareId: string
}

interface OpenSharedBetReturn {
  total: number
  success: number
  isClosed?: boolean
}
