import React from 'react'
import { openModal } from '@core/Components/Modal'
import { store } from '@core/Store'
import { ShareBetIncoming } from './ShareBet'
import { ShareBlackbookIncoming } from './ShareBlackbook'

const enum LocalConstants {
  ShareBetSearchKey = 'share-bet',
  ShareBackbookRunnerSearchKey = 'share-blackbook',
}

export const useShareIncomingListener = () => {
  const query = useQuery()

  React.useEffect(() => {
    const shareBetId = query.get(LocalConstants.ShareBetSearchKey)
    if (shareBetId) {
      cleanSearchParamsFromUrl()
      store.dispatch(
        openModal({
          id: shareBetId,
          modalComponent: ShareBetIncoming,
          modalComponentProps: { shareId: shareBetId },
          isDismissable: false,
          position: 'middle',
          cornerStyle: 'round',
        })
      )
      return
    }

    const shareBlackbookPayload = query.get(LocalConstants.ShareBackbookRunnerSearchKey)
    if (shareBlackbookPayload) {
      cleanSearchParamsFromUrl()
      const type = shareBlackbookPayload.slice(0, shareBlackbookPayload.indexOf(','))
      const name = shareBlackbookPayload.slice(shareBlackbookPayload.indexOf(',') + 1)
      if (!type || !name) return

      store.dispatch(
        openModal({
          id: shareBlackbookPayload,
          modalComponent: ShareBlackbookIncoming,
          modalComponentProps: { type, name, id: shareBlackbookPayload },
          isDismissable: false,
          position: 'middle',
          cornerStyle: 'round',
        })
      )
      return
    }
  }, [query])
}

// =============
// Local Helpers
// =============

function cleanSearchParamsFromUrl() {
  window.history.replaceState('', document.title, '/#')
}

function useQuery() {
  const search = window.location.search
  return React.useMemo(() => new URLSearchParams(search), [search])
}
