import React, { useCallback, useMemo, useState } from 'react'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { TicketFooterButtonStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/Footer.styles'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { logError } from '@core/Utils'
import { useQueryClient } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getReplayInfoFromApi } from './api'
import { ReplayInfo } from './types'
import { trackRaceReplayVideoLaunched } from './analytics'

export function TicketReplayButton({
  ticket,
  onPlayTapped,
}: {
  ticket: Ticket
  onPlayTapped?: (replayInfo: ReplayInfo) => void
}) {
  const ticketType = getTicketLayoutType(ticket)
  const firstLeg = ticket.legs[0]
  const canReplay =
    (ticketType === 'single-single' || ticketType === 'same-race-multi') &&
    firstLeg &&
    firstLeg.fixtureId &&
    ticket.status === 'Resolved'

  if (!canReplay) {
    return null
  }

  return <ReplayButton ticket={ticket} onReady={onPlayTapped} />
}

function ReplayButton({
  ticket,
  onReady,
}: {
  ticket: Ticket
  onReady?: (replayInfo: ReplayInfo) => void
}) {
  const [buttonState, setButtonState] = useState<'ready' | 'loading' | 'no_replay'>('ready')
  const buttonText = useMemo(() => {
    switch (buttonState) {
      case 'ready':
        return '\u25B6\uFE0E Replay'
      case 'loading':
        return '\u25B6\uFE0E Loading...'
      case 'no_replay':
        return 'No replay found'
    }
  }, [buttonState])

  const queryClient = useQueryClient()

  const handleClick = useCallback(async () => {
    setButtonState('loading')
    try {
      const { fixtureId, fixtureDate, raceNumber } = ticket.legs[0]
      const data = await queryClient.fetchQuery({
        queryKey: queryKeys.raceReplayRequest(fixtureId, fixtureDate, +raceNumber),
        queryFn: () => getReplayInfoFromApi(ticket),
        staleTime: Infinity,
      })

      if (data) {
        setButtonState('ready')
        onReady && onReady(data)
      } else {
        setButtonState('no_replay')
      }

      trackRaceReplayVideoLaunched('resulted-bets', !!data)
    } catch (ex) {
      setButtonState('no_replay')
      logError('Could not load race replay')
    }
  }, [ticket, queryClient, onReady, setButtonState])

  if (!ticket.legs[0]) {
    return null
  }

  return (
    <TicketFooterButtonStyled
      disabled={buttonState != 'ready'}
      color='link'
      onClick={handleClick}
      data-testid='ticket-footer-replay-button'
    >
      {buttonText}
    </TicketFooterButtonStyled>
  )
}
