import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

const listItemFontSize = 1.4
const listItemPadding = 1
const listItemHeight = listItemFontSize + listItemPadding * 2

export const SuggestionsContainerStyled = styled.ul<{ maxItems: number }>(
  {
    backgroundColor: hexColors.white,
    border: `1px solid ${hexColors.veryLightGrey}`,
    boxSizing: 'border-box',
    width: '100%',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    lineHeight: 1,

    /* Hide the list when empty */
    '&:empty': {
      border: 0,
    },

    '& > li': {
      padding: `${listItemPadding}rem`,
      fontSize: `${listItemFontSize}rem`,
    },

    '& > li[data-highlighted="true"]': {
      backgroundColor: hexColors.gainsboro,
    },
  },
  ({ maxItems }) => ({
    maxHeight: `${listItemHeight * maxItems}rem`,
  })
)
