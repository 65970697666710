export const isSwipeDisabled = (e: TouchEvent) => {
  const shouldDisableSwipe = hasAncestorWithAttribute(
    e.target as Element,
    'data-js-race-card-no-swipe'
  )
  if (shouldDisableSwipe) {
    return true
  }
  preventEdgeSwipe(e)
  return false
}

// Local Helpers

/** Checks if an element or any of its ancestors has a specific attribute. */
const hasAncestorWithAttribute = (element: Element | null, attributeName: string): boolean => {
  while (element) {
    if (element.hasAttribute(attributeName)) {
      return true
    }
    element = element.parentElement
  }
  return false
}

const preventEdgeSwipe = (ev: TouchEvent) => {
  const threshhold = 0.05
  if (ev.touches.length === 1) {
    const touch = ev.touches[0]

    if (
      touch.clientX < window.innerWidth * threshhold ||
      touch.clientX > window.innerWidth - threshhold
    ) {
      if (ev.cancelable) {
        ev.preventDefault()
      }
    }
  }
}
