import { useAppSelector } from '@core/Store/hooks'
import { getCurrentRacePageQueryKey } from '@core/Areas/RaceCard/Store/selectors'
import { useQuery } from 'react-query'
import { RacePageDataTransferObject } from '@mobi/api-types'
import React from 'react'
import { getSingleSilkImageUri } from '@core/Areas/RaceCardFixedOnly/Hooks/Silks/api'
import styled from '@emotion/styled'
import { SingleSilkImageStyled } from '@core/Components/SilkImage/SingleSilkImage.styles'

export const MatchedSilkImage: React.FC<{ starterNumber: number }> = ({ starterNumber }) => {
  const racePageQueryKey = useAppSelector(getCurrentRacePageQueryKey)
  const { data } = useQuery<RacePageDataTransferObject>({
    queryKey: racePageQueryKey || [],
    enabled: !!racePageQueryKey,
    staleTime: Infinity,
  })

  if (data == undefined || data.MeetingInformation == undefined) return null

  const singleSilkImageUri = getSingleSilkImageUri({
    racecourseSeq: data?.RacecourseSeq,
    meetingDate: data?.MeetingInformation?.MeetingDate,
    raceNumber: data?.MeetingInformation?.SelectedRace.RaceNumber,
    isTrots: data?.MeetingInformation.MeetingCode === 'Trots',
    isDogs: data?.MeetingInformation.MeetingCode === 'Dogs',
    starterNumber,
    isFieldSummaryPopulated: true,
    meetingCode: data?.MeetingInformation.MeetingCode,
  })

  if (singleSilkImageUri == null) return null

  return (
    <SilkWrapperStyled>
      <SingleSilkImageStyled
        src={singleSilkImageUri.largest.url}
        width={singleSilkImageUri.largest.size.width}
        height={singleSilkImageUri.largest.size.height}
        isDogsSilk={data?.MeetingInformation.MeetingCode === 'Dogs'}
      ></SingleSilkImageStyled>
    </SilkWrapperStyled>
  )
}

const SilkWrapperStyled = styled.div({
  display: 'block',
  marginTop: '0.4rem',
  marginLeft: '-0.2rem',
})
