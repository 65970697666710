import styled from '@emotion/styled'
import { isEmpty } from '@core/Utils/object/object'
import { ExpandablePanelComponentTheme } from './themes'

export const ExpandableSectionStyled = styled('div')({
  overflow: 'hidden',
  position: 'relative',
  transition: 'height 0.3s linear',
  boxSizing: 'border-box',
})

export const ExpandablePanelContentStyled = styled('div')<{
  theme?: ExpandablePanelComponentTheme
}>(({ theme }) =>
  isEmpty(theme)
    ? {}
    : {
        color: theme.content.text.color,
      }
)
