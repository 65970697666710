export const fontFamilies = {
  /** Default font stack using system fonts like Arial & Helvetica */
  default: 'Arial, Helvetica, sans-serif',
  /** TABtouch brand font */
  get brand() {
    return `"PFSquare", "Arial Bold", ${fontFamilies.default}`
  },
}

export const fontFamiliesBold = {
  /** Default font stack using system fonts like Arial & Helvetica */
  default: 'Arial, Helvetica, sans-serif',
  /** TABtouch brand font */
  get brand() {
    return `"PFSquareBold", "Bold", ${fontFamilies.default}`
  },
}

/** NOTE: emotion converts numbers into pixel values. */
export const fontSizes = {
  root: 10, // the <html> element
  vision: {
    root: '14px',
  },
  betslip: {
    summary: {
      fontSize: '1.2rem',
      fontSizeMedium: '1.4rem',
      fontSizeLarge: '1.6rem',
    },
  },
  informationPanel: {
    fontSize: '1.2rem',
  },
}
