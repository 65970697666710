import * as apiService from '@classic/Foundation/Services/ApiService'
import { UserAccountStateRecord } from '@core/State/UserAccount/userAccountDriver'
import { Contract } from '@mobi/web-native-comms'

export interface AuthStatus {
  deviceId?: Contract['RESPONSE_PUSH_AUTH_STATUS']['deviceId']
  status?: Contract['RESPONSE_PUSH_AUTH_STATUS']['status']
  accountNumber: UserAccountStateRecord['accountNumber']
  lastUpdated: Date
}

export function updatePushNotificationsAuthStatus(body: AuthStatus) {
  return apiService.post<AuthStatus>({
    url: '/api/account/updatePushNotificationsAuthStatus',
    body,
  })
}
