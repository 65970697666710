import React from 'react'
import { InputLabelStyled, ValidationErrorStyled } from '@mobi/component-library/Common/Input'
import { TextAreaStyled } from './TextArea.styles'

interface TextAreaProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  required?: boolean
  errorMessage?: string | boolean
  maxCharacters?: number
  testId?: string
}

export function TextArea(
  props: Partial<React.InputHTMLAttributes<HTMLTextAreaElement>> & TextAreaProps
): JSX.Element {
  const { name, onChange, required, errorMessage, maxCharacters, testId, ...testAreaProps } = props

  const [textAreaRows, setTextAreaRows] = React.useState(8)

  return (
    <div>
      <InputLabelStyled htmlFor={name} data-testid={testId + '-label'}>
        {name}
        <span>{required ? '*' : null}</span>
      </InputLabelStyled>

      <TextAreaStyled
        id={name}
        onChange={onChange}
        onInput={evt => {
          if (evt.currentTarget.value === '') {
            setTextAreaRows(8)
            return
          }
          if (textAreaRows > 100) return
          if (evt.currentTarget.clientHeight < evt.currentTarget.scrollHeight) {
            setTextAreaRows(curr => ++curr)
          }
        }}
        required={required}
        error={errorMessage}
        rows={textAreaRows}
        minLength={20}
        maxLength={maxCharacters ?? 2000}
        name={name}
        role='textbox'
        data-testid={testId}
        {...testAreaProps}
      />

      {errorMessage && (
        <ValidationErrorStyled data-testid={testId + '-error'}>
          {errorMessage}
        </ValidationErrorStyled>
      )}
    </div>
  )
}
