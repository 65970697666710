import * as ko from 'knockout'
import { ExactaViewModel } from './ExactaViewModel'
import template from './exacta.tpl.html'

if (!ko.components.isRegistered('exacta-bettype')) {
  ko.components.register('exacta-bettype', {
    viewModel: ExactaViewModel,
    template,
  })
}
