import { range } from '@mobi/utils'

export const medicareCardColours = [
  { value: '', label: 'Card Colour' },
  { value: 'Green', label: 'Green' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Yellow', label: 'Yellow' },
]

export const medicareExpiryNext10Years = () => {
  const thisYear = new Date().getFullYear()
  return range(thisYear, thisYear + 10)
}
