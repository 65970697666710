import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Ticket } from '@core/Areas/AccountActivity/types'
import styled from '@emotion/styled'

const IconContainer = styled.span({
  paddingLeft: '0.1em !important',
})

export const TitleWithSuperPickIcon: React.FC<{
  isSpecial: boolean
  specials: Ticket['specialInformation']
}> = ({ isSpecial, specials, children }) => {
  const hasSuperPick = specials && specials.filter(s => s.isPowerPlay).length > 0

  return (
    <h3 data-testid='ticket-title'>
      {children}
      {(hasSuperPick || isSpecial) && ' '}
      {(hasSuperPick || isSpecial) && (
        <IconContainer>
          <Icon type='specials' color='orange' size='1.8rem' />
        </IconContainer>
      )}
    </h3>
  )
}
