import { ContainerModule, type interfaces } from 'inversify'

import { PyosMessageViewModel } from '../Components/PyosMessage/PyosMessageViewModel'
import { PyosSelectViewModel } from '../Components/PyosSelect/PyosSelectViewModel'

const viewModels = new ContainerModule((bind: interfaces.Bind) => {
  bind<PyosMessageViewModel>('PyosMessageViewModel').to(PyosMessageViewModel)
  bind<PyosSelectViewModel>('PyosSelectViewModel').to(PyosSelectViewModel)
})

export default viewModels
