import { isReactNativeApp } from '@mobi/web-native-comms/web'

let canUserShare: boolean | null = null

export function doesClientSupportShare(): boolean {
  if (canUserShare !== null) {
    return canUserShare
  }

  canUserShare =
    isReactNativeApp ||
    // Determines if browser allows sharing with provided parameters
    !!navigator.canShare?.({
      title: 'sample',
      text: 'testing if the text param is accepted',
      files: [
        new File([new Blob(['Hello, world!'], { type: 'text/plain' })], 'test', {
          type: 'text/plain',
        }),
      ],
    })

  return canUserShare
}
