import { Container } from 'inversify'
import parentContainer from '../../Specials/IOC/inversify.config'
import services from './services.config'
import viewmodels from './viewmodels.config'
import { tap } from '@mobi/utils'

const container = tap(new Container(), container => {
  container.parent = parentContainer
  container.load(services)
  container.load(viewmodels)
})

export default container
