import React, { MouseEvent, TouchEvent } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { css, Global } from '@emotion/react'
import { hexColors } from '@mobi/settings'
import type { Contract } from '@mobi/web-native-comms'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { colors, font, measurements } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useFeature } from '@core/Utils/hooks'
import { isInIosBrowser } from '@classic/Foundation/Services/Device/DeviceIdentificationService'
import { navigateToLastRacesPath } from '@core/State/Navigation/helpers/navigateToLastRacesPath'
import { AppRoutes } from '@core/AppRoutes'
import { KAMBI_DIV_ID, KAMBI_ROOT_ROUTE } from '@core/Areas/Kambi/constants'
import { toggleSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/helpers/toggleSkyVideoPlayer'
import { useAppSelector } from '@core/Store/hooks'
import { selectIsSkyVideoPlayerOpen } from '@core/Areas/SkyVideoPlayer/Store/selectors'
import { useRadio } from './hooks/useRadio'
import { trackBottomNavigationClicked } from '@core/Areas/Home/analytics'
import { navigateToSkyRacePage } from '@core/Areas/RaceCardSky/helpers/navigateToSkyRacePage'
import { useIsNewSportsFeatureEnabled } from '@core/Utils/hooks/useIsNewSportsFeatureEnabled'

const iosBottomPaddingCss = css`
  :root {
    --ios-bottom-app-switcher-height: env(safe-area-inset-bottom, 0px);
  }
`

const enum LocalConstants {
  ActiveTabClassName = 'js-bottom-tab-nav--active',
}

export const WebOnlyNav = ({
  activeTab,
}: {
  activeTab: Contract['SET_ACTIVE_TAB']['activeTab']
}) => {
  const isSkyVideoPlayerOpen = useAppSelector(selectIsSkyVideoPlayerOpen)

  const isSkyRacePageFeatureActive = useFeature('SKY_LIVE_RACE_PAGE')
  const isNewSportsFeatureEnabled = useIsNewSportsFeatureEnabled()

  const { isRadioBusy } = useRadio(isSkyVideoPlayerOpen)

  const isHomePage = activeTab === 'Home'
  const isSports = activeTab === 'Sports'
  const isRaces = activeTab === 'Races'

  const needsExtraBottomPadding = !isReactNativeApp && isInIosBrowser()

  const getKambiBetslipTransform = (heightCss: string) => {
    if (!isReactNativeApp) {
      return css`
        .mod-KambiBC-betslip,
        .mod-KambiBC-betslip__outer-wrapper-mask {
          transform: translateY(calc(${heightCss} * -1));
        }

        .mod-KambiBC-betslip-container--pinned .mod-KambiBC-betslip,
        .mod-KambiBC-betslip-container--pinned .mod-KambiBC-betslip__outer-wrapper-mask {
          transform: unset;
        }

        #${KAMBI_DIV_ID} {
          max-width: ${measurements.mobi.maxWidth} !important;
          margin: auto !important;
        }
      `
    }
    return ''
  }

  const bottomNavRef = React.useRef<HTMLDivElement>(null)
  const height = needsExtraBottomPadding
    ? 'calc(5rem + var(--ios-bottom-app-switcher-height, 0))'
    : '5rem'

  const isSkyRacingTabActive = isSkyRacePageFeatureActive
    ? activeTab === 'SkyRacing'
    : isSkyVideoPlayerOpen

  const handleSkyRacingClick = () => {
    isSkyRacePageFeatureActive
      ? navigateToSkyRacePage()
      : toggleSkyVideoPlayer({
          analyticsData: { openLocation: 'bottom-navigation', channel: 'Sky1' },
        })
    trackBottomNavigationClicked('Watch')
  }

  const handleRacesClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigateToLastRacesPath()
    onNavItemClicked(e)
  }

  return (
    <WebOnlyContainerStyled ref={bottomNavRef} id='bottomTabNavigator' height={height}>
      {/* Make sure there's always padding at the bottom of the body so that scrolling can reach
      all the way to the end without the nav bar blocking anything.*/}
      <Global
        styles={css`
          ${needsExtraBottomPadding ? iosBottomPaddingCss : ''}

          body {
            border-bottom: ${height} solid
              ${bottomNavRef.current
                ? getComputedStyle(bottomNavRef.current).backgroundColor
                : hexColors.white};
          }

          ${getKambiBetslipTransform(height)}
        `}
      />

      <Link
        to='/'
        data-nav=''
        data-tid-bottom-nav-home
        onClick={onNavItemClicked}
        className={isHomePage ? LocalConstants.ActiveTabClassName : ''}
      >
        <Icon type='house' size='2.5rem' color='currentColor' />
        <strong>Home</strong>
      </Link>

      <a
        data-tid-bottom-nav-races
        onClick={handleRacesClick}
        className={isRaces ? LocalConstants.ActiveTabClassName : ''}
      >
        <Icon type='racesall' size='2.5rem' color='currentColor' />
        <strong>Races</strong>
      </a>

      <Link
        to={isNewSportsFeatureEnabled ? AppRoutes.Sports : `/${KAMBI_ROOT_ROUTE}/home`}
        data-nav=''
        data-tid-bottom-nav-sports
        onClick={onNavItemClicked}
        className={isSports ? LocalConstants.ActiveTabClassName : ''}
      >
        <Icon type='FBL' size='2.5rem' color='currentColor' />
        <strong>Sports</strong>
      </Link>

      <button disabled={isRadioBusy} data-tid-bottom-nav-watch onClick={handleSkyRacingClick}>
        <SvgLazy
          name='SkyRacing'
          color={isSkyRacingTabActive ? '' : hexColors.grey}
          height='2.5rem'
        />
        <strong className={isSkyRacingTabActive ? LocalConstants.ActiveTabClassName : ''}>
          Racing
        </strong>
        {isSkyRacePageFeatureActive && <span>New</span>}
      </button>
    </WebOnlyContainerStyled>
  )
}

// =============
// Local Helpers
// =============

function onNavItemClicked(e: MouseEvent | TouchEvent) {
  const text = (e.currentTarget?.textContent ?? '').trim()
  if (text) trackBottomNavigationClicked(text)
}

// ======
// Styles
// ======

const WebOnlyContainerStyled = styled.div<{ height: string }>(
  {
    display: 'flex',
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    height: measurements.mobi.bottomTabsHeight,
    width: '100%',
    minWidth: measurements.mobi.minWidth,
    maxWidth: measurements.mobi.maxWidth,
    background: '#FEFEFE',
    color: hexColors.grey,
    borderTop: `1px solid ${hexColors.gainsboro}`,
    alignItems: 'flex-start',
    paddingTop: '0.5rem',

    strong: {
      paddingTop: '0.2rem',
      fontFamily: font.family.primary,
      fontSize: font.size.xs.fontSize,
      letterSpacing: font.size.xs.letterSpacing,
      lineHeight: font.size.xs.lineHeight,
      fontWeight: font.weight.regular,
    },

    'button, a': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      background: 'none',
      color: 'inherit',
      border: 0,
      margin: 0,
      padding: 0,

      '&:active': {
        background: hexColors.veryLightGrey,
      },
    },

    [`.${LocalConstants.ActiveTabClassName}`]: {
      color: colors.lavender[600],
    },

    button: {
      position: 'relative',

      '> span': {
        position: 'absolute',
        top: '0.2rem',
        left: '50%',
        transform: 'translateX(-50%)',
        marginLeft: '-0.9rem',
        padding: '0.2rem 0.3rem',
        fontSize: '0.6rem',
        fontStyle: 'italic',
        fontWeight: 700,
        lineHeight: 1,
        textTransform: 'uppercase',
        borderRadius: '0.2rem',
        background: colors.error[500],
        color: colors.white,
      },
    },
  },
  ({ height }) => ({ height })
)
