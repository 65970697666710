import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { layering, measurements } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { ToastItem } from './ToastDriver'

export const ToastContainerStyled = styled.div<{ bottom?: boolean; isHeaderVisible?: boolean }>(
  {
    position: 'fixed',
    zIndex: layering.toasts,
    pointerEvents: 'none',
    margin: '0 auto',
    left: 0,
    right: 0,
    maxWidth: measurements.mobi.maxWidth,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '1rem',
  },
  ({ bottom, isHeaderVisible }) => ({
    top: bottom ? undefined : 0,
    bottom: bottom ? 0 : undefined,
    paddingTop: bottom ? 0 : undefined,
    transition: bottom ? undefined : 'all 125ms ease',
    transform: isHeaderVisible ? 'translateY(6rem)' : 'translateY(0)',
  })
)

type ToastStyledTypes = Exclude<ToastItem['type'], undefined>
export const ToastStyled = styled.div<{ type: ToastStyledTypes }>(
  {
    pointerEvents: 'all',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: '0.5rem',
    boxShadow: '0 0.5rem 0.5rem 0.2rem rgba(0,0,0,0.2)',
    borderRadius: '0.2rem',
    minHeight: '5rem',
  },
  ({ type = 'default' }: { type: ToastStyledTypes }) => toastTypeStyles[type]
)

const toastTypeStyles: { [K in ToastStyledTypes]: { background: string } } = {
  default: {
    background: transparentize(0.05, hexColors.charCoal),
  },
  error: {
    background: transparentize(0.05, hexColors.freeSpeechRed),
  },
}

export const ToastCloseIconStyled = styled.span({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  height: '4rem',
  marginLeft: '0.5rem',
  color: hexColors.white,
  opacity: 0.8,
})

export const ToastTextStyled = styled.span({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.6rem',
  color: hexColors.white,
  textAlign: 'center',
})

export const ToastSpinnerStyled = styled(SpinnerInlineStyled)({
  marginRight: '0.5rem',
})
