import { fetchFromApi } from '@classic/Foundation/Services/ApiService'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'

enum ToteSportsResult {
  HomeWin,
  AwayWin,
  Draw,
  Abandoned,
}

export interface OpenPoolsResponse {
  sports: ToteSportsSport[]
}

export interface ResultedPoolsResponse {
  sports: ToteSportsSport[]
  startDate: string
  endDate: string
}

interface ToteSportsSport {
  key: string
  description: string
  icon: keyof typeof iconPaths
  pools: ToteSportsPool[]
}

interface ToteSportsDividend {
  winningOutcome: string
  return: number
  numberOfTipsRequired: number
  gameKey: string
}

export interface ToteSportsPool {
  key: string
  name: string
  nameSuffix: string
  seq: number
  isOpen: boolean
  isAbandoned: boolean
  releaseTime: Date
  total: number
  grossJackpot: number
  netJackpot: number
  dividend: ToteSportsDividend
  sportKey: string
  isRetailSlipSupported: boolean
  disclaimers: string[]
  numberOfClosedGames: number
  numberOfGames: number
  numberOfResultedGames: number
  tippingPoolNumber: number
  scheduledCloseTime: Date
  statusDesc: string
  statusSeq: number
  games: ToteSportsGame[]
}

export interface ToteSportsGame {
  key: string
  homeTeamName: string
  awayTeamName: string
  isDrawFootoAllower: boolean
  isDrawResultAllowed: boolean
  isDrawSupported: boolean
  league: string
  result: keyof typeof ToteSportsResult
  roundDate: Date
  sportsKey: string
  startTime: Date
  statusDesc: string
  statusSeq: string
  venue: string
}

export const queryDefaults = {
  retry: false,
  staleTime: Infinity,
  cacheTime: 10000,
  refetchOnWindowFocus: false,
}

export const getCurrentPools = async (): Promise<OpenPoolsResponse> => {
  const pools = await fetchFromApi('/api/tipping/current-pools')
  return await pools.json()
}

export const getResultedPools = async (date: string): Promise<ResultedPoolsResponse> => {
  const pools = await fetchFromApi(`/api/tipping/resulted-pools/${date}`)
  return await pools.json()
}

export const getPool = async (poolKey: string): Promise<ToteSportsPool> => {
  const pools = await fetchFromApi(`/api/tipping/pool/${poolKey}`)
  return await pools.json()
}
