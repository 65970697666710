import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getMeetingsFromApi } from './api'

const enum LocalConstants {
  ThirtySeconds = 30_000,
}

export const useMeetingsQuery = (
  /** date format should be YYYY-MM-DD */
  date: string
) =>
  useQuery(queryKeys.meetings(date), () => getMeetingsFromApi(date), {
    staleTime: LocalConstants.ThirtySeconds as number,
    refetchInterval: LocalConstants.ThirtySeconds as number,
    refetchOnWindowFocus: true,
    retry: false,
  })
