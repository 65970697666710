import { ObservableFinisher } from '../../../Model/Observables/ObservableFinisher'
import * as ko from 'knockout'
import Guard from '../../../../AppUtils/Framework/Guard'
import { injectable } from 'inversify'
import { IFieldFinisherPricesViewModel } from './IFieldFinisherPricesViewModel'

@injectable()
export class FieldFinisherPricesViewModel implements IFieldFinisherPricesViewModel {
  public finisher!: ObservableFinisher
  public displayToteWinDividend!: ko.Computed<string>
  public displayFobWinDividend!: ko.Computed<string>
  public displayTotePlaceDividend!: ko.Computed<string>
  public displayFobPlaceDividend!: ko.Computed<string>
  public showFixedOdds!: ko.Computed<boolean>
  public showScratchedFixedOdds!: ko.Computed<boolean>
  public showSuspendedFixedOdds!: ko.Computed<boolean>
  public shouldShowFixedFavourite!: ko.Computed<boolean>

  init(params: { finisher: ObservableFinisher }) {
    Guard.notNull(params.finisher)

    this.finisher = params.finisher

    this.displayToteWinDividend = ko.pureComputed<string>(() => {
      return this.finisher.toteWinProvDiv().toFixed(2)
    })

    this.displayTotePlaceDividend = ko.pureComputed<string>(() => {
      return this.finisher.totePlaceProvDiv().toFixed(2)
    })

    this.displayFobWinDividend = ko.pureComputed<string>(() => {
      if (this.finisher.fixedOddsFinisherInfo.winDiv() != undefined) {
        return this.finisher.fixedOddsFinisherInfo.winDiv().toFixed(2)
      }
      return ''
    })

    this.displayFobPlaceDividend = ko.pureComputed<string>(() => {
      if (this.finisher.fixedOddsFinisherInfo.placeDiv() != undefined) {
        return this.finisher.fixedOddsFinisherInfo.placeDiv().toFixed(2)
      }
      return ''
    })

    this.showFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.finisher.hasFixedOdds() &&
        !this.finisher.fixedOddsFinisherInfo.isScratched() &&
        !this.finisher.fixedOddsFinisherInfo.isSuspended()
    )

    this.showScratchedFixedOdds = ko.pureComputed<boolean>(
      () => this.finisher.hasFixedOdds() && this.finisher.fixedOddsFinisherInfo.isScratched()
    )

    this.showSuspendedFixedOdds = ko.pureComputed<boolean>(
      () =>
        this.finisher.hasFixedOdds() &&
        this.finisher.fixedOddsFinisherInfo.isSuspended() &&
        !this.finisher.fixedOddsFinisherInfo.isScratched()
    )

    this.shouldShowFixedFavourite = ko.pureComputed<boolean>(
      () => this.finisher.hasFixedOdds() && this.finisher.fixedOddsFinisherInfo.isFavourite()
    )
  }
}
