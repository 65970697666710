import {
  createListenerMiddleware,
  type TypedStartListening,
  type TypedStopListening,
} from '@reduxjs/toolkit'
import { type RootState, type AppDispatch } from './index'

export const listenerMiddleware = createListenerMiddleware()

export const startListening = listenerMiddleware.startListening as AppStartListening
export const stopListening = listenerMiddleware.stopListening as AppStopListening

// Types

type AppStartListening = TypedStartListening<RootState, AppDispatch>
type AppStopListening = TypedStopListening<RootState, AppDispatch>
