import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const NoRacesMessage = () => {
  return (
    <WrapperStyled data-testid='no-races-container'>
      <div>
        <Icon size='12rem' type='allraces' color={colors.neutral[400]} />
      </div>
      <div>
        <strong>No races available</strong>
        <br />
        Please change your filters
      </div>
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '4.5rem',

  '> div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16.4rem',
    height: '16.4rem',
    borderRadius: '50%',
    background: colors.neutral[100],
  },

  '> div:last-of-type': {
    fontFamily: font.family.primary,
    fontSize: font.size.lg.fontSize,
    lineHeight: font.size.lg.lineHeight,
    letterSpacing: font.size.lg.letterSpacing,
    textAlign: 'center',
    color: colors.neutral[900],
    paddingTop: spacing.big,
  },

  '> div:last-of-type strong': {
    fontWeight: font.weight.bold,
  },
})
