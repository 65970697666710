import * as CSS from 'csstype'
import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { ArrowDirection } from './PriceChangeDisplay'

const {
  default: { color: defaultColor, secondary: secondaryColor },
  priceChange: {
    background: priceChangeBG,
    selected: { background: priceChangeSelectedBG },
    arrow: { borderColor: arrowBorderColor },
    background: lightArrowBorderColor,
  },
} = colors.starters.buttonSelection

export const PriceChangeContainerStyled = styled('span')<{
  isInline?: boolean
  containerStyle?: CSS.Properties
}>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',

    width: '100%',
    padding: '0 0.3rem',
    margin: 0,

    fontFamily: 'inherit',
    fontSize: '1.2rem',
    lineHeight: 1,
    textAlign: 'right',
  },
  ({ isInline = false, containerStyle = {} }) => ({
    height: (isInline && '3rem') || undefined,
    alignItems: (isInline && 'center') || undefined,
    ...containerStyle,
  })
)

interface PriceWrapperStyled {
  isPriceChanging: boolean
  isSelected?: boolean
  isInline?: boolean
  isWidthDynamic?: boolean
}
export const PriceWrapperStyled = styled('span')<PriceWrapperStyled>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    padding: '0.2rem',
  },
  ({ isWidthDynamic }) => ({
    flexDirection: isWidthDynamic ? 'row' : 'column',
    justifyContent: isWidthDynamic ? 'space-between' : 'center',
    alignItems: isWidthDynamic ? 'center' : undefined,
    height: isWidthDynamic ? '2rem' : undefined,
    paddingRight: isWidthDynamic ? '0.5rem' : undefined,
  }),
  ({ isPriceChanging, isSelected = false, isInline = false, isWidthDynamic }) => ({
    width: isInline ? '6.5rem' : '100%',

    '&:not(:only-of-type):first-of-type': {
      marginBottom: (!isInline && '0.2rem') || undefined,
      marginRight: (isInline && '0.2rem') || undefined,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      transition: 'opacity 0.2s ease-in-out',
      opacity: isPriceChanging ? 1 : 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '0.3rem',
      background: isSelected ? priceChangeSelectedBG : priceChangeBG,
      height: isWidthDynamic ? '2rem' : 'auto',
      paddingRight: isWidthDynamic ? '0.5rem' : 'auto',
    },
  })
)

export const PriceInnerStyled = styled('span')<{
  shouldForceColor?: boolean
  centerContent?: boolean
}>(
  {
    position: 'relative',
    zIndex: 1,
  },
  // Force color to fix inherited style
  ({ shouldForceColor = false, centerContent = false }) => ({
    textAlign: centerContent ? 'center' : 'right',
    color: (shouldForceColor && `${defaultColor} !important`) || undefined,
  })
)

export const PriceLableStyled = styled('span')({
  display: 'inline-block',
  width: '1.2rem',
  color: `${secondaryColor} !important`,
})

const PriceChangeIndicatorsStyled = styled('div')<{
  arrowDirection: ArrowDirection
  light?: boolean
}>(
  {
    display: 'flex',
    justifyContent: 'center',

    '&::before, &::after': {
      content: '""',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderWidth: 0,
      transitionProperty: 'transform, opacity',
      transitionTimingFunction: 'ease-in',
      transitionDuration: '0.2s',
      opacity: 0,
    },

    '&::before': {
      transform: 'translateY(3px)',
    },
    '&::after': {
      transform: 'translateY(-3px)',
    },
  },
  ({ arrowDirection, light = false }) => ({
    opacity: arrowDirection ? 1 : 0,
    ...getArrowStyles(arrowDirection, light),
  })
)

const getArrowStyles = (arrowDirection: ArrowDirection, light: boolean) => {
  const borderColor = light ? lightArrowBorderColor : arrowBorderColor
  const up = {
    '&::before': {
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderColor: `transparent transparent ${borderColor} transparent`,
      transform: 'translateY(0)',
      opacity: 1,
    },
  }
  const down = {
    '&::after': {
      borderWidth: '0.5rem 0.5rem 0 0.5rem',
      borderColor: `${borderColor} transparent transparent transparent`,
      transform: 'translateY(0)',
      opacity: 1,
    },
  }

  if (arrowDirection === 'UP') {
    return up
  }
  if (arrowDirection === 'DOWN') {
    return down
  }

  if (arrowDirection === 'BOTH') {
    return { ...up, ...down }
  }

  return {}
}

export const PriceChangeIndicatorInsideStyled = styled(PriceChangeIndicatorsStyled)<{
  isWidthDynamic?: boolean
}>(
  {
    zIndex: 1,
  },
  ({ isWidthDynamic }) => ({
    minWidth: isWidthDynamic ? '1.8rem' : undefined,
    position: isWidthDynamic ? undefined : 'absolute',
    left: isWidthDynamic ? undefined : '0.2rem',
    top: isWidthDynamic ? undefined : '50%',
    transform: isWidthDynamic ? undefined : 'translateY(-50%)',
  })
)

export const PriceChangeIndicatorAboveStyled = styled(PriceChangeIndicatorsStyled)({
  position: 'absolute',
  top: '-0.9rem',
  left: '50%',
  transform: 'translateX(-50%)',
})
