import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { MarketDataTransferObject, RacePageDTO } from '@mobi/api-types'
import { useAppSelector } from '@core/Store/hooks'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { SecondaryHeader } from '../Common/SecondaryHeader'
import { FixedOddsMarketDisplayNames, BettingInformationOverrides } from '../../constants'
import { getCurrentBetType, getToteSwapOptions } from '../../Store/selectors'

export const BettingInfo: React.FC<{
  raceData?: RacePageDTO
}> = ({ raceData }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const currentBetType = useAppSelector(getCurrentBetType)
  const isStartingPriceAvailable = useAppSelector(getToteSwapOptions).includes('SP')

  if (!raceData || currentBetType !== BetType.WinPlace) return null

  const fixedOddsInfo = raceData.MeetingInformation.SelectedRace.FixedOddsInfo
  const fixedOddsMarkets = raceData.FixedOddsMarkets || []

  if (!(fixedOddsMarkets.length > 0) && !fixedOddsInfo && !isStartingPriceAvailable) return null

  return (
    <div data-testid='betting-info'>
      <ExpandablePanel
        title=''
        expanded={isExpanded}
        headerClicked={() => setIsExpanded(val => !val)}
        headerComponent={
          <SecondaryHeader
            title='Betting Information - Fixed'
            isExpanded={isExpanded}
            icon='infoCircled'
          />
        }
      >
        <div style={{ padding: '0.5em', marginBottom: '1.5em' }}>
          {!!fixedOddsInfo?.MarketSequence && (
            <Info
              MarketName='Win + Place'
              BettingConditions={fixedOddsInfo.BettingConditions}
              BettingRules={fixedOddsInfo.BettingRules}
            />
          )}

          {isStartingPriceAvailable && (
            <Info
              MarketName='Starting Price (SP)'
              BettingConditions={BettingInformationOverrides.StartingPrice[0].detail[0]}
              BettingRules={BettingInformationOverrides.StartingPrice[1].detail[0]}
            />
          )}

          {fixedOddsMarkets.map(({ MarketName, MarketCode, BettingConditions, BettingRules }) => (
            <Info
              key={MarketName}
              MarketName={
                FixedOddsMarketDisplayNames[
                  MarketCode as keyof typeof FixedOddsMarketDisplayNames
                ] || MarketName
              }
              BettingConditions={BettingConditions}
              BettingRules={BettingRules}
            />
          ))}
        </div>
      </ExpandablePanel>
    </div>
  )
}

// ===============
// Local Component
// ===============

const Info: React.FC<
  Pick<MarketDataTransferObject, 'MarketName' | 'BettingConditions' | 'BettingRules'>
> = ({ MarketName, BettingConditions, BettingRules }) => (
  <CardStyled key={MarketName} data-testid='betting-info__section'>
    <h4>
      <span>{MarketName}</span>
    </h4>
    <div>
      <dl>
        <dt>Betting Conditions</dt>
        <dd>{BettingConditions}</dd>
        <dt>Betting Rules</dt>
        <dd>{BettingRules} </dd>
      </dl>
    </div>
  </CardStyled>
)

// ======
// Styles
// ======

const CardStyled = styled.div({
  backgroundColor: hexColors.white,
  boxShadow: '0 -1px 0 0 #eee, 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  padding: '0.5rem',

  h4: {
    margin: '1rem 0',
    fontSize: '1.2rem',
    textAlign: 'left',

    span: {
      padding: '0.5rem',
      backgroundColor: hexColors.nightRider,
      color: '#fff',
    },
  },

  '& > div': {
    marginBottom: 0,
    marginTop: '0.5rem',
    fontSize: '1.2rem',
  },

  dt: { fontWeight: 'bold' },

  dd: {
    marginLeft: 0,
    marginBottom: '1rem',
    lineHeight: 1.5,
  },
})
