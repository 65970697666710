import React from 'react'
import { DisplayKambiClient, HideKambiClient } from '@core/Areas/Kambi/driver'
import { toggleKambiVisiblity } from '../helpers/visibility'

export const useToggleVisibility = (isHidden: boolean): void => {
  React.useEffect(() => {
    if (isHidden) {
      hideKambi()
    } else {
      DisplayKambiClient()
    }

    return () => {
      hideKambi()
    }
  }, [isHidden])
}

// Ensure visiblity state is hidden and in-sync with driver
// - Fixes issue where kambi initiates own display, making signal not work due to same state (distinctUntilChanged)
function hideKambi() {
  HideKambiClient()
  toggleKambiVisiblity(false)
}
