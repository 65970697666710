import { PaymentMethod } from '@mobi/api-types'
import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, spacing } from '../../Theme/Common'
import { Alert } from '../Icons'

type Props = Pick<PaymentMethod, 'isDebitCard' | 'isExpired'>

export const CreditCardAlertLabel = ({ isDebitCard, isExpired }: Props) => {
  let alertLabel: string | undefined
  if (!isDebitCard) {
    alertLabel = 'Disabled'
  } else if (isExpired) {
    alertLabel = 'Expired'
  }
  if (!alertLabel) return null

  return (
    <BadgeStyled>
      <Alert /> <span>{alertLabel}</span>
    </BadgeStyled>
  )
}

const BadgeStyled = styled.span({
  alignItems: 'center',
  backgroundColor: colors.error['50'],
  borderRadius: radius.sm,
  color: colors.error['500'],
  display: 'inline-flex',
  padding: spacing.smx2,
  columnGap: spacing.smx2,
})
