import React, { type ReactNode, useMemo, useState } from 'react'
import { useCombobox, type UseComboboxStateChange, type UseComboboxPropGetters } from 'downshift'
import { debounceFn } from '@mobi/utils'
import { InputField, type InputFieldProps } from './InputField'
import { SuggestionsContainerStyled } from './AutocompleteInputField.styles'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'

export type Suggestion = {
  id: string
  value: string
}

type AutocompleteState = 'idle' | 'busy' | 'error'

export type AutocompleteInputProps = InputFieldProps &
  ReturnType<UseComboboxPropGetters<unknown>['getInputProps']>

type AutocompleteInputFieldProps = Omit<InputFieldProps, 'defaultValue' | 'onSelect'> & {
  defaultValue?: string
  minChars?: number
  debounceMs?: number
  fetchSuggestionsCallback: (inputValue: string) => Promise<Suggestion[]>
  onSelect?: (selection: string) => void
  onUnselect?: VoidFunction
  /**
   * A function that determines what component is used to render the input.
   *
   * Leaving this undefined will render the InputField defined in the Common namespace.
   */
  renderInput?: (props: AutocompleteInputProps) => ReactNode
}

export const AutocompleteInputField = ({
  name,
  inputName,
  placeholder,
  defaultValue,
  required,
  minChars = 3,
  debounceMs = 500,
  fetchSuggestionsCallback,
  onSelect,
  onUnselect,
  renderInput = renderDefaultInput,
}: AutocompleteInputFieldProps) => {
  const [state, setState] = useState<AutocompleteState>('idle')
  const [items, setItems] = useState<Suggestion[]>([])

  const debouncedOnInputValueChange = useMemo(() => {
    const debounced = debounceFn(({ inputValue }: UseComboboxStateChange<Suggestion>) => {
      if (inputValue && inputValue.length >= minChars) {
        setState('busy')
        fetchSuggestionsCallback(inputValue)
          .then(suggestions => {
            setItems(suggestions)
            setState('idle')
          })
          .catch(() => setState('error'))
      } else {
        setItems([])
      }
    }, debounceMs)
    return (suggestion: UseComboboxStateChange<Suggestion>) => {
      setState('idle')
      return debounced(suggestion)
    }
  }, [setState, debounceMs, minChars, fetchSuggestionsCallback])

  const {
    isOpen,
    highlightedIndex,
    selectedItem,
    reset,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items,
    initialSelectedItem: defaultValue
      ? {
          id: defaultValue,
          value: defaultValue,
        }
      : undefined,
    itemToString: item => item?.value ?? '',
    onInputValueChange: debouncedOnInputValueChange,
    onSelectedItemChange(changes) {
      if (changes.type === useCombobox.stateChangeTypes.FunctionReset) {
        onUnselect?.()
      } else if (changes.selectedItem?.id) {
        onSelect?.(changes.selectedItem.id)
      }
    },
  })

  const isBusy = state === 'busy' && !selectedItem

  return (
    <div data-testid={`${inputName}-autocomplete`} style={{ paddingBottom: '0.6rem' }}>
      {renderInput({
        name,
        inputName,
        style: { marginBottom: 0 },
        ...getInputProps({
          placeholder,
          required,
          autoComplete: 'off',
          onKeyDown: () => selectedItem !== null && reset(),
        }),
      })}

      <SuggestionsContainerStyled {...getMenuProps()} maxItems={5}>
        {isBusy && (
          <li>
            <BusyIndicator color={'black'} isBusy />
          </li>
        )}
        {state === 'error' && <li>Address lookup failed. Enter address manually.</li>}
        {isOpen &&
          state === 'idle' &&
          !selectedItem &&
          items.map((item, index) => (
            // eslint-disable-next-line react/jsx-key
            <li
              data-highlighted={highlightedIndex === index}
              {...getItemProps({
                key: `${item.id}`,
                item,
                index,
              })}
            >
              {item.value}
            </li>
          ))}
      </SuggestionsContainerStyled>
    </div>
  )
}

// region Helpers
const renderDefaultInput: NonNullable<AutocompleteInputFieldProps['renderInput']> = props => (
  <InputField {...props} />
)
// endregion
