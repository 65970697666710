import { Icon } from '@mobi/component-library/Common/Icon'
import { ErrorMessage } from '@core/Components/Messages'
import { Money } from '@core/Components/Text/Money'
import React, { useEffect, useState } from 'react'
import { isErrored, isLoading } from 'rwwa-data-access/dist/data-access'
import {
  FavouriteNumberBettingPool,
  FavouriteNumbersPools,
  UpcomingPoolsKey,
} from '@core/Data/favouriteNumbers'
import {
  PoolSelectionButtonStyled,
  PoolSelectionColumn,
  PoolGroupHeader,
  FavouriteNumbersPoolTotalStyled,
  ShowAllPoolsButton,
  FavouriteNumbersFlex,
  FavouriteNumbersSection,
  FavouriteNumbersColumn,
  FavouriteNumbersRow,
  FavouriteNumbersDataTable,
} from './FavouriteNumbers.styles'
import { DeselectPool, SetShowAll, state$, TogglePool } from './driver'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { useObservableProperties, useDataAccess } from '@core/Utils/hooks'
import dayjs from 'dayjs'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'

interface PoolSelectionProps {
  weekendPools: FavouriteNumberBettingPool[]
  midweekPools: FavouriteNumberBettingPool[]
  selectedPoolDates: Date[]
  showAll: boolean
}

export function PoolSelection(): JSX.Element | null {
  const { pools: selectedPoolDates, showAllPools: showAll } = useObservableProperties(
    state$,
    ['pools', 'showAllPools'],
    { pools: [], showAllPools: false }
  )

  const state = useDataAccess(FavouriteNumbersPools, UpcomingPoolsKey)

  if (isLoading(state.data)) {
    return <LoadingPlaceholder testId='poolselection-loading' width='100%' height='3em' />
  }

  if (isErrored(state.data)) {
    return <ErrorMessage>Unable to load Favourite Number Pools</ErrorMessage>
  }

  const pools = [...state.data.weekendPools, ...state.data.midweekPools].map(x => x.poolDate)
  selectedPoolDates.forEach(selectedPoolDate => {
    if (pools.indexOf(selectedPoolDate) < 0) {
      DeselectPool({ pool: selectedPoolDate })
    }
  })

  return (
    <PoolSelectionComponent
      weekendPools={state.data.weekendPools}
      midweekPools={state.data.midweekPools}
      selectedPoolDates={selectedPoolDates}
      showAll={showAll}
    />
  )
}

function PoolSelectionComponent({
  weekendPools,
  midweekPools,
  selectedPoolDates,
  showAll,
}: PoolSelectionProps): JSX.Element | null {
  const [hasPools, setHasPools] = useState<boolean>(false)
  useEffect(() => {
    const hasPools = weekendPools.length > 0 || midweekPools.length > 0
    // default pools (if none selected)
    if (hasPools && selectedPoolDates.length === 0) {
      if (weekendPools.length > 0) {
        TogglePool({ pool: weekendPools[0].poolDate })
      } else if (midweekPools.length > 0) {
        TogglePool({ pool: midweekPools[0].poolDate })
      }
    }
    setHasPools(hasPools)
  }, [selectedPoolDates, selectedPoolDates.length, midweekPools, weekendPools])

  const toggleShowAll = () => {
    SetShowAll(!showAll)
  }

  let earliestSelectedPool: FavouriteNumberBettingPool | undefined
  if (selectedPoolDates.length > 0) {
    const earliestPoolDate = selectedPoolDates[0]
    earliestSelectedPool = [...weekendPools, ...midweekPools].find(
      pool => pool.poolDate === earliestPoolDate
    )
  }

  const expectedCloseTime = `${dayjs(earliestSelectedPool?.expectedCloseTime).format(
    'DD/MM/YYYY'
  )} at ${dayjs(earliestSelectedPool?.expectedCloseTime).format('HH:mm')}`

  const earliestUpdateTime = dayjs(earliestSelectedPool?.lastUpdated).format('DD/MM/YYYY HH:mm')

  return (
    <>
      {!hasPools && <NoPools />}
      {hasPools && (
        <FavouriteNumbersSection>
          <FavouriteNumbersFlex>
            <PoolSelectionColumn>
              <PoolGroupHeader>SATURDAY</PoolGroupHeader>
              {PoolButtons(weekendPools, showAll, selectedPoolDates, 'weekend')}
            </PoolSelectionColumn>
            <PoolSelectionColumn>
              <PoolGroupHeader>MIDWEEK</PoolGroupHeader>
              {PoolButtons(midweekPools, showAll, selectedPoolDates, 'midweek')}
            </PoolSelectionColumn>
          </FavouriteNumbersFlex>
          {!showAll && (weekendPools.length > 1 || midweekPools.length > 1) && (
            <ShowAllPoolsButton onClick={toggleShowAll} data-tid-show-all-button=''>
              SHOW ALL POOLS
            </ShowAllPoolsButton>
          )}
          <FavouriteNumbersDataTable>
            <FavouriteNumbersRow>
              <FavouriteNumbersColumn data-tid-close-time-label=''>
                1st selected pool closes
              </FavouriteNumbersColumn>
              {earliestSelectedPool && (
                <FavouriteNumbersColumn data-tid-close-time=''>
                  {expectedCloseTime}
                </FavouriteNumbersColumn>
              )}
            </FavouriteNumbersRow>
            <FavouriteNumbersRow>
              <FavouriteNumbersColumn data-tid-updated-at-label=''>
                Updated at
              </FavouriteNumbersColumn>
              {earliestSelectedPool && (
                <FavouriteNumbersColumn data-tid-last-update=''>
                  {earliestUpdateTime}
                </FavouriteNumbersColumn>
              )}
            </FavouriteNumbersRow>
          </FavouriteNumbersDataTable>
        </FavouriteNumbersSection>
      )}
    </>
  )
}

function NoPools(): JSX.Element {
  return (
    <NoticeBox
      noticeBoxType={NoticeBoxTypes.Warning}
      title='There are no available Favourite Number pools'
      subtitle='Please check again later'
      hasBorder={false}
    ></NoticeBox>
  )
}

function PoolSelectionButton({
  pool,
  isSelected,
  showPoolTotal,
}: {
  pool: FavouriteNumberBettingPool
  isSelected: boolean
  showPoolTotal: boolean
}): JSX.Element {
  return (
    <PoolSelectionButtonStyled
      data-tid-pool-button={pool.poolNumber}
      color={isSelected ? 'dark' : 'secondary'}
      onClick={() => {
        TogglePool({ pool: pool.poolDate })
      }}
    >
      <span>{dayjs(pool.poolDate).format('DD MMM YYYY')}</span>
      {showPoolTotal && (
        <div style={{ marginTop: '0.5em' }}>
          {
            <FavouriteNumbersPoolTotalStyled data-tid-pool-total=''>
              <Money amount={Math.floor(pool.poolTotal)} decimalPlaces={0} />
            </FavouriteNumbersPoolTotalStyled>
          }
          {pool.hasJackpot && (
            <FavouriteNumbersPoolTotalStyled data-tid-pool-jackpot=''>
              <Icon type='jackpot' color='#f2a900' />
              <Money amount={Math.floor(pool.jackpot)} decimalPlaces={0} />
            </FavouriteNumbersPoolTotalStyled>
          )}
        </div>
      )}
    </PoolSelectionButtonStyled>
  )
}

function PoolButtons(
  pools: readonly FavouriteNumberBettingPool[],
  showAll: boolean,
  selectedPools: readonly Date[],
  poolType: 'midweek' | 'weekend'
): React.ReactNode {
  return pools
    .filter((p, i) => i == 0 || showAll)
    .map((pool, index) => {
      return (
        <PoolSelectionButton
          key={`${poolType}-${index}`}
          pool={pool}
          isSelected={selectedPools.indexOf(pool.poolDate) >= 0}
          showPoolTotal={index === 0}
        />
      )
    })
}
