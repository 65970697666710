import { useQuery, UseQueryResult } from 'react-query'

import { get } from '@classic/Foundation/Services/ApiService'
import { NextRace } from '@core/Data/Racing/nextRaces'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import type { RaceCodes } from '../Racing/Types/MeetingInformation'

const MaxRefetchInterval = 120_000

export const MaxNumberOfRaces = 20
export const MaxNumberOfRacesForHome = 7

export type NextEventsFilter = Record<RaceCodes, boolean>

export function isToteRaceChangePushEvent(status: string): boolean {
  return ['raceopened', 'raceclosed', 'raceabandon', 'raceunabandon'].includes(status)
}

export const useNextRaces = (refetchInterval?: number): UseQueryResult<NextRace[]> => {
  return useQuery(
    queryKeys.nextRaces,
    () =>
      get<{
        nextRaces: NextRace[]
      }>({ url: '/webapi/race/GetNextRaces' }).then(response => response.nextRaces),
    {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchInterval: refetchInterval || MaxRefetchInterval,
    }
  )
}

export let nextEventsfilters: NextEventsFilter = { Races: true, Trots: true, Dogs: true }

export function setNextEventFilters(filters: NextEventsFilter) {
  if (!Object.values(filters).every(x => x === false)) {
    nextEventsfilters = filters
  }
}
