import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import dayjs from 'dayjs'

import {
  RaceListHeaderStyled,
  RaceListHeaderDetails,
  RaceListCenteredHeaderStyled,
} from './RaceListHeader.styles'
import {
  ContentHeaderLinkStyled,
  ContentHeaderStyled,
  ContentHeaderTitleStyled,
} from '../ContentHeader/ContentHeader.styles'
import { ButtonWithIcon } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'
import { RaceCondtitionIcon } from '@core/Areas/RaceList/RaceList.styles'
import { IconTypes, unknownWeather, WeatherTypes } from './utils'
export function RaceListHeader({
  title,
  code,
  weather,
  condition,
  eventDate,
}: {
  title: string
  code: string
  weather: string
  condition: string
  eventDate: string
}): JSX.Element {
  return (
    <div>
      <ContentHeaderStyled>
        <Grid>
          <GridCell width='2rem' valign='middle'>
            <ButtonWithIcon
              onClick={navigateHome}
              icon={<Icon type='house' title='Home' />}
              color='light'
            />
          </GridCell>
          <GridCell valign='middle' align='center'>
            <ContentHeaderTitleStyled>
              <ContentHeaderLinkStyled
                href={`#tote?code=${(code || '').toLowerCase()}&date=${dayjs(eventDate).format(
                  'YYYY-MM-DD'
                )}`}
                data-nav
              >
                <Icon type={IconTypes[code]} /> {title}
              </ContentHeaderLinkStyled>
            </ContentHeaderTitleStyled>
          </GridCell>
          <GridCell width='2rem' valign='middle'></GridCell>
        </Grid>
      </ContentHeaderStyled>
      <RaceListHeaderStyled data-tid-page-sub-title=''>
        <RaceListCenteredHeaderStyled>
          <Grid>
            <RaceListHeaderDetails>
              <GridCell valign='middle' align='center'>
                {dayjs(eventDate).format('ddd DD MMM YYYY')}
              </GridCell>
              <GridCell valign='middle' align='center'>
                <RaceCondtitionIcon size='1.75rem' type={WeatherTypes[weather] || unknownWeather} />
                {weather}
              </GridCell>
              <GridCell valign='middle' align='center'>
                <RaceCondtitionIcon type='trackConditions' size='1.75rem' />
                {condition}
              </GridCell>
            </RaceListHeaderDetails>
          </Grid>
        </RaceListCenteredHeaderStyled>
      </RaceListHeaderStyled>
    </div>
  )
}
