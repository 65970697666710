import styled from '@emotion/styled'
import { ButtonSelectionStyled } from '@mobi/component-library/Common/Buttons'

const BUTTON_HEIGHT = '4rem'
const BORDER_WIDTH = '0.1rem'

export const BettingOptionsSelectionButtonStyled = styled(ButtonSelectionStyled)({
  flex: 1,
  height: BUTTON_HEIGHT,
  margin: 0,
  borderWidth: BORDER_WIDTH,

  '&:first-of-type': {
    marginRight: '0.5rem',
  },
  '&:last-of-type': {
    marginLeft: '0.5rem',
  },
})

export const BettingOptionsSelectionInnerStyled = styled('span')({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: `calc((${BUTTON_HEIGHT} - ${BORDER_WIDTH}) - ${BORDER_WIDTH})`,
})

export const BettingOptionsSelectionLabelStyled = styled('span')({
  fontWeight: 'normal',
  paddingLeft: '0.7rem',
})
