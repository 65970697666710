import dayjs from 'dayjs'
import type {
  SameRaceMultiSelection,
  ToteSelection,
  StartingPriceMatchedSelection,
  FobMatchedSelection,
} from '@mobi/betslip/types'
import { get } from '@classic/Foundation/Services/ApiService'
import { isRovingBanker } from '@core/Data/Betting/selections'
import { starterToAcceptor } from '@core/Areas/RaceCard/Components/MoreBettingOptions/FixedOddsMarkets/Utils/createBetSelection'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import type { StarterDataTransferObject } from '@classic/Betting-v2/DataTransferObjects/DataTransferObjects'
import type { BetDetails } from '../../types'
import { getSelectionPrice } from '@core/Areas/Racing/Components/SameRaceMulti/SameRaceMultiBettingDrawer/SameRaceMultiPrice'
import { getToteBetType } from '../../../getToteBetType'

// Parses a selection string based on '.' and '/' notation
// eg. '1/3.4.6/FD/2.7' will convert to [['1'], ['2','4','6'], ['FD'], ['2','7']]
export const parseSelectionsString = (selectionString: string): string[][] => {
  const selections = selectionString
    .split('/')
    .map(secondaryDivision => secondaryDivision.split('.'))

  return selections
}

export const getToteSelection = async (betDetails: BetDetails) => {
  const { fixtureId, fixtureDate, races, selectionString, planSeq, numberOfCombinations } =
    betDetails

  const singleSelectionGroup = parseSelectionsString(selectionString).length === 1
  const betType = getToteBetType(planSeq)

  if (!betType) throw new Error('Unsupported bet type')

  const toteSelection: ToteSelection = {
    fixtureId,
    fixtureDate: dayjs(fixtureDate).format('YYYY-MM-DD'),
    raceNumber: races[0],
    selectionString,
    numberOfCombinations: numberOfCombinations ?? 1,
    isRovingBanker: isRovingBanker(planSeq),
    isAllways: singleSelectionGroup,
    isLegIn: betType === 'Quinella' && !singleSelectionGroup,
    betType,
  }
  return toteSelection
}

export const getFOBSelection = async (
  betDetails: BetDetails
): Promise<FobMatchedSelection | SameRaceMultiSelection | StartingPriceMatchedSelection> => {
  const {
    fixtureId,
    fixtureDate: fixtureDateAsDate,
    races,
    selectionString,
    srmSelections,
    betType,
  } = betDetails

  const fixtureDate = dayjs(fixtureDateAsDate).format('YYYY-MM-DD')
  const starters = await get<StarterDataTransferObject[]>({
    url: `/api/meetings/${fixtureId}/${fixtureDate}/race/${races[0]}/starters`,
  })
  const starter = starters.find(starter => starter.Number === parseInt(selectionString))

  if (!starter) throw new Error('Could not match starter')

  const winPrice = starter.FixedOddsStarterInfo.DisplayWinDividend
  const placePrice = starter.FixedOddsStarterInfo.DisplayPlaceDividend

  if (betType === FixedOddsMarketDisplayNames.SP) {
    const spSelection: StartingPriceMatchedSelection = {
      fixtureId,
      fixtureDate,
      raceNumber: races[0],
      acceptorNumber: parseInt(selectionString, 10),
      marketCode: 'SP',
      marketName: 'Starting Price',
      selectedAcceptor: starterToAcceptor(starter),
    }
    return spSelection
  }

  if (srmSelections && srmSelections.length >= 2) {
    const selectionString = srmSelections
      .map(s => `selection=${s.acceptorNumber}-${s.legNumber + 1}`)
      .join('&')

    const srmSelectionPrice = await getSelectionPrice(
      fixtureDateAsDate,
      fixtureId,
      races[0],
      selectionString
    )

    const srmSelection: SameRaceMultiSelection = {
      type: 'same-race-multi',
      fixtureId,
      fixtureDate,
      raceNumber: races[0],
      acceptors: srmSelections,
      winPrice: Number(srmSelectionPrice.price),
    }
    return srmSelection
  }

  const fobMatchedSelection: FobMatchedSelection = {
    type: 'fob-matched',
    fixtureId,
    fixtureDate,
    raceNumber: races[0],
    acceptorNumber: parseInt(selectionString),
    propositionSeq: starter.FixedOddsStarterInfo?.PropositionSequence?.toString(),
    winPrice: Number(winPrice),
    winPriceLastSeen: Number(winPrice),
    placePrice: placePrice !== '-' ? Number(placePrice) : null,
    placePriceLastSeen: placePrice !== '-' ? Number(placePrice) : null,
    priceSource: 'selection',
  }
  return fobMatchedSelection
}
