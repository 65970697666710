import { useCallback, useState } from 'react'

type Options = {
  startOpen?: boolean
}

export function useToggleControl(options?: Options) {
  const [isOpen, setOpen] = useState(options?.startOpen ?? false)

  const open = useCallback(() => {
    setOpen(true)
  }, [])

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  const toggle = useCallback(() => {
    setOpen(isOpen => !isOpen)
  }, [])

  return {
    isOpen,
    open,
    close,
    toggle,
  }
}
