import styled from '@emotion/styled'
import { PopupStyled, PopupTitleStyledProps } from '@core/Components/Popup/Popup.styles'

interface ActivitySummaryPopupProps {
  isDesktop?: boolean
}

export const ActivitySummaryPopupStyled = styled(PopupStyled)<ActivitySummaryPopupProps>(
  {
    color: '#000',
    position: 'relative',
  },
  ({ isDesktop }) => ({
    fontSize: isDesktop ? '1.4rem' : '1.2rem',
    lineHeight: isDesktop ? '1.7rem' : '1.4rem',
    width: isDesktop ? '40rem' : '26rem',
    height: isDesktop ? '30rem' : undefined,
    margin: isDesktop ? '5rem 0' : undefined,
    transform: isDesktop ? 'translate(-50%,0)' : undefined,
    padding: isDesktop ? '2rem' : '1rem',
  })
)

ActivitySummaryPopupStyled.displayName = 'ActivitySummaryPopup'

export const PopupTitleStyled = styled('div')<PopupTitleStyledProps>(
  {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ isDesktop }) => ({
    fontSize: isDesktop ? '2.4rem' : '1.6rem',
    lineHeight: isDesktop ? '2.9rem' : '1.8rem',
    color: isDesktop ? '#512D6D' : undefined,
    boxShadow: isDesktop ? '0px 4px 2px rgba(0, 0, 0, 0.1)' : undefined,
    height: isDesktop ? '7rem' : undefined,
    margin: isDesktop ? '-2rem -2rem 0' : undefined,
    fontWeight: isDesktop ? undefined : 'bold',
  })
)
PopupTitleStyled.displayName = 'PopupTitle'
