import React from 'react'
import {
  silkHeight,
  TrendingBetsListItemStyled,
  TrendingBetsListItemContainer,
  TrendingBetNameStyled,
  TrendingBetEventRaceTimeStyled,
  TrendingWinDividendStyled,
  TrendingPlaceDividendStyled,
  TrendingBetGreyhoundsSilkImageStyled,
  TrendingBetSilkImageContainerStyled,
  TrendingRacerStyled,
  TrendingRaceNameStyled,
  TrendingDividendsStyled,
} from './TrendingBets.styles'
import { dayjs } from '@mobi/utils'
import { getNextRaceTimeDisplayOptions, buildToteRaceUri } from '@core/Areas/Racing/helpers'
import { Icon } from '@mobi/component-library/Common/Icon'
import { TimeToJumpComponent } from '@core/Areas/NextEvents/Components/TimeToJump/TimeToJump'
import { BuildImageUrlWithSizesProps, getImageUrlWithSizes } from '@core/Components/SilkImage/utils'
import { AcceptorDetail, RacingCode } from '@core/Data/StructuredSearch/StructuredSearchResult'
import { SilkImageStyled } from '@core/Components/SilkImage/SilkImage.styles'
import { trackTrendingBetSelected } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { IconTypes } from './TrendingBetsList'
import { TrendingBetFilterEnum } from './TrendingBetsFilter'
import { AcceptorResult, TrendingBet } from '../types'

export function TrendingBetTile({
  bet,
  index,
  sorting,
  acceptors,
}: {
  bet: TrendingBet
  index: number
  sorting: TrendingBetFilterEnum
  acceptors: AcceptorResult
}): JSX.Element | null {
  const { acceptor } = acceptors[bet.acceptorLegIds[0].acceptorId]
  const { race } = acceptor
  const nextEvent = getNextRaceTimeDisplayOptions(acceptor.race.advertisedStartTime)
  const [shouldShowDay, setShouldShowDay] = React.useState<boolean>(() => nextEvent.showDay)

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const { showDay } = getNextRaceTimeDisplayOptions(race.advertisedStartTime)
      setShouldShowDay(current => (showDay !== current ? showDay : current))
    }, 1_000)

    return () => window.clearInterval(interval)
  }, [race.advertisedStartTime])

  const someParams = {
    meetingId: race.fixture.id,
    raceNo: race.number,
    isTrots: race.fixture.contestType === RacingCode.Trots,
    meetingDate: race.fixture.date,
  }

  const href = buildToteRaceUri({
    ...someParams,
    raceNumber: race.number,
    starter: acceptor.numberOrBox,
    source: 'trending-bets-single',
  })

  const startTime = new Date(race.advertisedStartTime)

  const silkProps = computeSilkProps(
    someParams,
    acceptor.numberOrBox,
    acceptor.race.fixture.contestType
  )

  const name = `${acceptor.numberOrBox} - ${acceptor.name}`

  const winDividends = !acceptor.fixedOddsPrices?.win.isValid
    ? '-'
    : `W:$${acceptor.fixedOddsPrices.win?.dividend?.toFixed(2)}`
  const placeDividends = !acceptor.fixedOddsPrices?.place.isValid
    ? ' -'
    : ` P:$${acceptor.fixedOddsPrices.place?.dividend?.toFixed(2)}`

  let racer = ''
  switch (race.fixture.contestType) {
    case RacingCode.Races:
      racer = 'J: ' + acceptor.riderName
      break
    case RacingCode.Dogs:
      racer = 'T: ' + acceptor.trainerName
      break
    default:
      racer = 'D: ' + acceptor.driverName
      break
  }

  const raceName = `R${race.number} ${race.fixture.name}`

  return (
    <TrendingBetsListItemStyled
      data-tid-home-trending-bets='home-trending-bet-item'
      data-testid='trending-bet-item'
      onClick={() => handleClick(bet, acceptor, index, sorting)}
      href={href}
    >
      <TrendingBetsListItemContainer>
        <TrendingBetNameStyled>{name}</TrendingBetNameStyled>

        <TrendingDividendsStyled>
          <TrendingWinDividendStyled>{winDividends}</TrendingWinDividendStyled>
          <TrendingPlaceDividendStyled>{placeDividends}</TrendingPlaceDividendStyled>
        </TrendingDividendsStyled>

        <TrendingBetSilkImageContainerStyled>
          {silkProps.isDogsSilk ? (
            <TrendingBetGreyhoundsSilkImageStyled {...silkProps} />
          ) : (
            <SilkImageStyled {...silkProps} />
          )}
        </TrendingBetSilkImageContainerStyled>

        <TrendingRacerStyled>{racer}</TrendingRacerStyled>
        <TrendingRaceNameStyled>{raceName}</TrendingRaceNameStyled>

        <TrendingBetEventRaceTimeStyled>
          <Icon size='2rem' type={IconTypes[race.fixture.contestType as keyof IconTypes]} />
          <TimeToJumpComponent
            size='1rem'
            advertisedStartTime={startTime}
            showCountDown={!shouldShowDay}
            showDay={shouldShowDay}
            showDayBeforeTime={true}
          />
        </TrendingBetEventRaceTimeStyled>
      </TrendingBetsListItemContainer>
    </TrendingBetsListItemStyled>
  )
}

export const computeSilkProps = (
  someParams: BuildImageUrlWithSizesProps,
  numberOrBox: number,
  contestType: RacingCode
) => {
  const imageUrl = getImageUrlWithSizes(someParams).larger
  const url = imageUrl.url
  let { height, width } = imageUrl.size
  const ratio = silkHeight / height
  width *= ratio
  height *= ratio

  const baseWidth = width
  const isDogsSilk = contestType === RacingCode.Dogs
  const xOffset = numberOrBox - 1
  return { url, height, width, isDogsSilk, xOffset, baseWidth }
}

function handleClick(
  bet: TrendingBet,
  acceptor: AcceptorDetail,
  index: number,
  sorting: TrendingBetFilterEnum
) {
  const timeToJump = dayjs(acceptor.race.advertisedStartTime).diff(dayjs(), 'second')

  trackTrendingBetSelected({
    bet: bet,
    acceptor,
    timeToJump,
    index,
    sorting,
    trendingType: 'single',
  })
}
