import { useQueries } from 'react-query'
import { kambiPlayerApi } from '@core/Areas/Kambi/helpers'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getPendingTicketsCount } from '@core/Areas/AccountActivity/helpers/api'

const enum LocalConstants {
  DEFAULT_MAX_COUNT = 99,
}

export const usePendingTicketsCount = ({ enabled }: { enabled: boolean }) => {
  const [{ data: pendingTicketsCount }, { data: kambiPendingTicketsCount }] = useQueries([
    {
      queryKey: queryKeys.pendingTicketsCount,
      queryFn: getPendingTicketsCount,
      enabled,
    },
    {
      queryKey: queryKeys.kambiPendingTicketsCount,
      queryFn: fetchKambiPendingTicketCount,
      enabled,
    },
  ])

  const pendingBets =
    (pendingTicketsCount?.pendingTicketsCount ?? 0) + (kambiPendingTicketsCount ?? 0)

  return {
    pendingBetsCount: Math.min(pendingBets, LocalConstants.DEFAULT_MAX_COUNT),
    doPendingBetsExceedMaxCount: pendingBets > LocalConstants.DEFAULT_MAX_COUNT,
  }
}

// =============
// Local Helpers
// =============

async function fetchKambiPendingTicketCount() {
  try {
    const response = await kambiPlayerApi().PendingBetHistory()

    return response?.range.size
  } catch {
    return undefined
  }
}
