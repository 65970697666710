import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, font, radius, shadow } from '../../Theme/Common'
import { Icon } from '../../Common/V2/Icon'
import { SkyTvSvg } from '../../Common/Svg'

export const SkyTvButton: React.FC<{
  channel: Channels
  isLive: boolean
  isPlaying: boolean
  onClick: () => void
}> = ({ channel, isLive, isPlaying, onClick }) => {
  const displayType = isLive ? 'live' : channel

  return (
    <SkyButtonStyled onClick={onClick} display={displayType} isPlaying={isPlaying}>
      {isLive ? (
        'Live'
      ) : (
        <SkyTvSvg
          channel={channel}
          colorOverride={isPlaying ? colors.white : undefined}
          width='3.2rem'
        />
      )}

      <Icon color='inherit' name={isPlaying ? 'SolidXCircle' : 'SolidPlayCircle'} size='1.4rem' />
    </SkyButtonStyled>
  )
}

// ======
// Styles
// ======

const SkyButtonStyled = styled.button<{ display: Channels | 'live'; isPlaying: boolean }>(
  {
    '@keyframes pulseAnimation': {
      '0%': { opacity: 1, transform: 'scale(1)' },
      to: { opacity: 0, transform: 'scale(1.7)', transformOrigin: 'center' },
    },

    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    width: '6.4rem',
    height: '3.2rem',

    border: 0,
    borderRadius: radius.sm,
    cursor: 'pointer',

    '*': { flexShrink: 0 },

    '> span': {
      marginLeft: '0.4rem',
    },
  },
  ({ display, isPlaying }) => {
    if (display === 'live') {
      return {
        backgroundColor: colors.error[600],
        color: colors.white,
        fontFamily: font.family.primary,
        fontSize: font.size.lg.fontSize,
        lineHeight: font.size.lg.lineHeight,
        letterSpacing: font.size.lg.letterSpacing,
        fontWeight: font.weight.bold,

        '> span': {
          svg: { color: colors.white },

          '&::before': !isPlaying
            ? {
                content: '""',
                display: 'block',
                boxSizing: 'border-box',
                border: '0.2rem solid white',
                borderRadius: '100%',
                animation: 'pulseAnimation 1.5s infinite',
                height: '1.2rem',
                width: '1.2rem',
                position: 'absolute',
              }
            : {},
        },
      }
    }

    const channelColor =
      display === 'Sky1'
        ? hexColors.skyTvRed
        : display === 'Sky2'
          ? hexColors.skyTvBlue
          : hexColors.racingWa
    const colorInvert = isPlaying ? channelColor : colors.white

    return {
      backgroundColor: colorInvert,
      border: isPlaying ? 0 : `0.05rem solid ${colors.neutral[200]}`,
      boxShadow: isPlaying ? undefined : shadow.xs,

      '> span > svg': {
        color: isPlaying ? colors.white : channelColor,
      },
    }
  }
)

// =====
// Types
// =====

type Channels = 'Sky1' | 'Sky2' | 'RacingWA'
