import React from 'react'
import { InputField } from '@mobi/component-library/Common/Input'
import { LoginFieldOptionsStyled } from '@core/Areas/Login/Login.styles'

export const Step1AccountNumberInput: React.FC<Step1AccountNumberInputProps> = ({
  accountNumber,
  onAccountNumberChange,
  handleRecoverAccountNumber,
}) => {
  return (
    <>
      <fieldset data-testid='password-reset-account-number'>
        <InputField
          name='Account Number'
          id='account-number-input'
          value={accountNumber}
          onChange={onAccountNumberChange}
          maxLength={8}
          type='tel'
          tabIndex={100}
          data-tid-login-account
        />

        <LoginFieldOptionsStyled>
          <span />
          <button data-testid='login-forgot-account-number' onClick={handleRecoverAccountNumber}>
            Forgot account number
          </button>
        </LoginFieldOptionsStyled>
      </fieldset>
    </>
  )
}

// Types
import type { usePasswordReset } from '../hooks/usePasswordReset'

type Step1AccountNumberInputProps = Pick<
  ReturnType<typeof usePasswordReset>['handlers'],
  'onAccountNumberChange'
> &
  Pick<ReturnType<typeof usePasswordReset>['state'], 'accountNumber'> & {
    handleRecoverAccountNumber(): void
  }
