import React from 'react'
import * as amplify from '@classic/AppUtils/Framework/Messaging/amplifyjs'
import { RacePageDataTransferObject } from '@mobi/api-types'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { getSelectedProposition } from '@core/Areas/RaceCard/Store/selectors'
import { ClearRaceBettingPageMysterySelection } from '@core/Areas/Racing/Components/Mystery/MysteryRaceBettingPageWrapperDriver'
import {
  setActiveToteSwap,
  setSelectedProposition,
  setToteSwapOptions,
} from '@core/Areas/RaceCard/Store'

export const useFixedOddsMarkets = (raceData: RacePageDataTransferObject) => {
  const dispatch = useAppDispatch()
  const selectedProposition = useAppSelector(getSelectedProposition)

  // Clear Win/Place selections on FixedOddsMarkets selection
  React.useEffect(() => {
    if (selectedProposition) {
      amplify.publish('clear-all-selections-command')
      ClearRaceBettingPageMysterySelection()
    }
  }, [selectedProposition])

  // Clear FixedOddsMarkets selection on Win/Place selections
  React.useEffect(() => {
    const clearFobMarketSelection = () => dispatch(setSelectedProposition(null))
    amplify.subscribe('selection-made-command', clearFobMarketSelection)
    return () => {
      amplify.unsubscribe('selection-made-command', clearFobMarketSelection)
    }
  }, [dispatch])

  // Capture additional fixed odds markets for display elsewhere
  const isInsuranceAvailable = raceData.FixedOddsMarkets.some(market => market.MarketCode === 'CON')
  const isStartingPriceAvailable =
    raceData.MeetingInformation.SelectedRace.IsSpAvailable &&
    !raceData.MeetingInformation.SelectedRace.FixedOddsInfo.IsClosed

  React.useEffect(() => {
    let toteSwapOptions: Parameters<typeof setToteSwapOptions>[0] = []
    if (isInsuranceAvailable) toteSwapOptions.push('CON')
    if (isStartingPriceAvailable) toteSwapOptions.push('SP')
    dispatch(setToteSwapOptions(toteSwapOptions))
  }, [dispatch, isInsuranceAvailable, isStartingPriceAvailable])

  // Activate SP by default
  React.useEffect(() => {
    if (!isStartingPriceAvailable) return
    const isFixedOddsAvailable =
      !!raceData.MeetingInformation.SelectedRace.FixedOddsInfo &&
      !raceData.MeetingInformation.SelectedRace.FixedOddsInfo.IsSuspended
    const isToteDisabled = raceData.RaceStarters.find(
      race => race.RaceKey.Key === raceData.MeetingInformation.SelectedRace.Key
    )?.Starters.every(starter => !starter.IsToteEnabled)

    if (!isFixedOddsAvailable && isToteDisabled) dispatch(setActiveToteSwap('SP'))
  }, [isStartingPriceAvailable, raceData, dispatch])

  // Clear selection on race change
  React.useEffect(() => {
    dispatch(setSelectedProposition(null))
  }, [dispatch, raceData.MeetingInformation.SelectedRace.RaceNumber])

  return {
    selectedProposition,
    isInsuranceAvailable,
    isStartingPriceAvailable,
  }
}
