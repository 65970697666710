import React from 'react'

import { Button } from '@mobi/component-library/Common/Buttons'
import { NoticeBox } from '@core/Components/NoticeBox'

import { FooterButtonContainerStyled } from '../Common.styles'
import { PromptBetslipChangeSelecton } from './helpers'

export const PromptBetslipChange: React.FC<{
  handleSelection: (choice: PromptBetslipChangeSelecton) => void
}> = ({ handleSelection }) => {
  return (
    <div>
      <NoticeBox
        title='Betslip Already Contains Bets'
        subtitle='What should we do with existing bets?'
        testId='non-empty-betslip-prompt'
      />
      <FooterButtonContainerStyled>
        <Button onClick={() => handleSelection('cancel')} color='secondary'>
          Cancel
        </Button>

        <Button onClick={() => handleSelection('replace')} color='primary'>
          Replace Existing
        </Button>

        <Button onClick={() => handleSelection('add')} color='primary'>
          Add to Existing
        </Button>
      </FooterButtonContainerStyled>
    </div>
  )
}
