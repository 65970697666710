import { addToast, ToastContent } from '@mobi/component-library/Feedback/Toast'
import React from 'react'

export const FeedbackBalanceDepositChange = () => {
  addToast({
    id: 'deposit-success',
    isDismissable: true,
    content: <ToastContent title={`Your balance has been updated.`} icon='success' />,
  })
}
