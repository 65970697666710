import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon as LegacyIcon } from '@mobi/component-library/Common/Icon'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2'
import { trackEvent, trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { getIsSkyRaceListViewOpen, getSkyRaceFilters } from '../../Store/selectors'
import { toggleFilter, toggleIsSkyRaceListViewOpen, type FilterOptions } from '../../Store'
import type { NextSkyRace } from '../../types'

const enum LocalConstants {
  FilterActiveClass = 'js-sky-race-filter--active',
}

export const RaceFilter: React.FC<{
  races: NextSkyRace[]
}> = ({ races }) => {
  const dispatch = useDispatch()

  const filters = useSelector(getSkyRaceFilters)
  const isRaceListViewOpen = useSelector(getIsSkyRaceListViewOpen)

  const filterBarElRef = React.useRef<HTMLDivElement>(null)

  const makeOnFilterToggle = (filterName: keyof FilterOptions) => () => {
    dispatch(toggleFilter(filterName))
    trackFilterUpdate(filterName, filters)
  }

  const handleRaceListViewClick = () => {
    dispatch(toggleIsSkyRaceListViewOpen())
    if (!isRaceListViewOpen) trackKey(analyticsKeys.skyRaceCardListViewOpen)
  }

  return (
    <WrapperStyled ref={filterBarElRef}>
      <div>
        <div>
          <button
            className={setActiveClass(filters.races)}
            onClick={makeOnFilterToggle('races')}
            data-testid='RaceFilter.Races'
          >
            <LegacyIcon type='races' size='1.8rem' color='currentColor' />
          </button>

          <button
            className={setActiveClass(filters.dogs)}
            onClick={makeOnFilterToggle('dogs')}
            data-testid='RaceFilter.Dogs'
          >
            <LegacyIcon type='dog' size='1.8rem' color='currentColor' />
          </button>

          <button
            className={setActiveClass(filters.trots)}
            onClick={makeOnFilterToggle('trots')}
            data-testid='RaceFilter.Trots'
          >
            <LegacyIcon type='trot' size='1.8rem' color='currentColor' />
          </button>
        </div>

        <div>
          <button
            className={setActiveClass(filters.local)}
            onClick={makeOnFilterToggle('local')}
            data-testid='RaceFilter.AusNz'
          >
            Aus/NZ
          </button>

          <button
            className={setActiveClass(filters.international)}
            onClick={makeOnFilterToggle('international')}
            data-testid='RaceFilter.Int'
          >
            Int'l
          </button>
        </div>
      </div>

      <Button
        size='sm'
        color='secondary_outline'
        disabled={!isRaceListViewOpen && !races.length}
        onClick={handleRaceListViewClick}
        data-testid='RaceListViewToggle'
      >
        <Icon name={isRaceListViewOpen ? 'SolidXClose' : 'SolidList'} size='2rem' />
      </Button>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function trackFilterUpdate(filterName: keyof FilterOptions, currentFilters: FilterOptions) {
  const copiedCurrentFilters = Object.assign({}, currentFilters)
  copiedCurrentFilters[filterName] = !copiedCurrentFilters[filterName]

  if (filterName === 'international' || filterName === 'local') {
    const { local, international } = copiedCurrentFilters
    if (!local && !international) return
    trackEvent(analyticsKeys.skyRaceCardFilterRegionUpdate, { international, local } as TrackRegion)
  } else {
    const { dogs, races, trots } = copiedCurrentFilters
    if (!dogs && !races && !trots) return
    trackEvent(analyticsKeys.skyRaceCardFilterCodeUpdate, { dogs, races, trots } as TrackCodes)
  }
}

const setActiveClass = (isActive: boolean) => (isActive ? LocalConstants.FilterActiveClass : '')

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  height: '4.4rem',
  padding: '0 ' + spacing.md,
  paddingTop: spacing.smx1,

  // Filter toggles
  '> div': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    div: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: spacing.smx2,
    },

    '> div:first-of-type > button': {
      height: '3.2rem',
      width: '3.2rem',
    },

    '> div > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '3.2rem',
      padding: spacing.smx1,

      fontFamily: font.family.primary,
      fontWeight: font.weight.regular,
      fontSize: font.size.sm.fontSize,
      letterSpacing: font.size.sm.letterSpacing,
      lineHeight: font.size.sm.lineHeight,

      border: 0,
      borderRadius: '1.75rem',
      transition: 'background-color 0.2s, color 0.2s',
      backgroundColor: colors.neutral[200],
      color: colors.neutral[700],

      [`&.${LocalConstants.FilterActiveClass}`]: {
        backgroundColor: colors.studio[600],
        color: colors.white,
      },

      '> svg': { flexShrink: 0 },
    },
  },

  // List view buton
  '> button': {
    maxWidth: '3.6rem',
    marginLeft: spacing.smx1,

    ':active, :focus': {
      backgroundColor: colors.neutral[100],
      borderColor: colors.neutral[100],
    },
  },
})

// =====
// Types
// =====

type TrackCodes = Pick<FilterOptions, 'dogs' | 'races' | 'trots'>
type TrackRegion = Pick<FilterOptions, 'international' | 'local'>
