import React from 'react'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { InsuranceButton } from './Components/InsuranceButton'
import { StartingPriceButton } from './Components/StartingPriceButton'
import {
  WinPlaceButtonsContainerStyled,
  WinPlaceScratchedStyled,
  WinPlaceToteStyled,
  WinPlaceToteSwapOptionsStyled,
} from './WinPlaceButtons.styles'
import { useAppSelector } from '@core/Store/hooks'
import { getActiveToteSwap, getToteSwapOptions } from '../../Store/selectors'

export const WinPlaceButtons: React.FC<{
  raceStatus: string
  starterNumber: number
  makeFobSelection(): void
  shouldDisplayFixed: boolean
  shouldDisplayFixedScratched: boolean
  shouldDisplayFixedSuspended: boolean
  isFixedSuspended: boolean
  isFixedFavourite: boolean
  isFixedSelected: boolean
  fixedPriceWin: string
  fixedPricePlace: string
  makeToteSelection(): void
  shouldDisplayTote: boolean
  isToteFavourite: boolean
  isToteScratched: boolean
  isToteSelected: boolean
  toteScratchType: string
  totePriceWin: string
  totePricePlace: string
}> = ({
  raceStatus,
  starterNumber,
  makeFobSelection,
  shouldDisplayFixed,
  shouldDisplayFixedScratched,
  shouldDisplayFixedSuspended,
  isFixedSuspended,
  isFixedFavourite,
  isFixedSelected,
  fixedPriceWin,
  fixedPricePlace,
  makeToteSelection,
  shouldDisplayTote,
  isToteFavourite,
  isToteScratched,
  isToteSelected,
  toteScratchType,
  totePriceWin,
  totePricePlace,
}) => {
  const toteSwapOptions = useAppSelector(getToteSwapOptions)
  const activeToteSwap = useAppSelector(getActiveToteSwap)

  const isInsuranceAvailable = toteSwapOptions.includes('CON')
  const isStartingPriceAvailable = toteSwapOptions.includes('SP')
  const isRaceClosed = raceStatus === 'Closed'

  return (
    <WinPlaceButtonsContainerStyled>
      {isRaceClosed ? (
        // ===========
        // Race Closed
        // ===========
        <>
          {shouldDisplayFixed && (
            <span data-tid-dividend-fixed={starterNumber}>
              <span>
                <strong>{fixedPriceWin}</strong>
                <br />
                {fixedPricePlace && <strong>{fixedPricePlace}</strong>}
              </span>
            </span>
          )}

          {shouldDisplayFixedSuspended && (
            <span data-tid-dividend-fixed={starterNumber}>&ndash;</span>
          )}

          {shouldDisplayFixedScratched && (
            <WinPlaceScratchedStyled data-tid-dividend-fixed={starterNumber}>
              SCR
            </WinPlaceScratchedStyled>
          )}

          {shouldDisplayTote && (
            <span data-tid-dividend-tote={starterNumber}>
              <span>
                <strong>{totePriceWin}</strong>
                <br />
                {totePricePlace && <strong>{totePricePlace}</strong>}
              </span>
            </span>
          )}

          {isToteScratched && (
            <span data-tid-dividend-tote={starterNumber}>
              {toteScratchType === 'Late' && (
                <>
                  <WinPlaceScratchedStyled>LATE</WinPlaceScratchedStyled>
                  <br />
                </>
              )}
              <WinPlaceScratchedStyled>SCR</WinPlaceScratchedStyled>
            </span>
          )}
        </>
      ) : (
        // =========
        // Race Open
        // =========
        <>
          <WinPlaceSelection
            tidAttribute='data-tid-dividend-fixed'
            selectionName={isFixedSelected ? 'fixed' : ''}
            isVisible={shouldDisplayFixed}
            isDisabled={isFixedSuspended}
            isFavourite={isFixedFavourite}
            isSelected={isFixedSelected}
            onClick={makeFobSelection}
            priceWin={fixedPriceWin}
            pricePlace={fixedPricePlace}
            starterNumber={starterNumber}
            favouritePosition='left'
          />

          {shouldDisplayFixedSuspended && (
            <span data-tid-dividend-fixed={starterNumber}>&ndash;</span>
          )}

          {shouldDisplayFixedScratched && (
            <WinPlaceScratchedStyled data-tid-dividend-fixed={starterNumber}>
              SCR
            </WinPlaceScratchedStyled>
          )}

          <span style={{ position: 'relative' }}>
            <>
              {!shouldDisplayTote && !isToteScratched && (
                <span data-tid-dividend-tote={starterNumber}>&ndash;</span>
              )}

              <WinPlaceToteStyled isVisible={!activeToteSwap}>
                <>
                  <WinPlaceSelection
                    tidAttribute='data-tid-dividend-tote'
                    selectionName={isToteSelected ? 'tote' : ''}
                    isVisible={shouldDisplayTote}
                    isDisabled={false}
                    isFavourite={isToteFavourite}
                    isSelected={isToteSelected}
                    onClick={makeToteSelection}
                    priceWin={totePriceWin}
                    pricePlace={totePricePlace}
                    starterNumber={starterNumber}
                  />

                  {isToteScratched && (
                    <span data-tid-dividend-tote={starterNumber}>
                      {toteScratchType === 'Late' && (
                        <>
                          <WinPlaceScratchedStyled>LATE</WinPlaceScratchedStyled>
                          <br />
                        </>
                      )}
                      <WinPlaceScratchedStyled>SCR</WinPlaceScratchedStyled>
                    </span>
                  )}
                </>
              </WinPlaceToteStyled>

              {isInsuranceAvailable && (
                <WinPlaceToteSwapOptionsStyled isVisible={activeToteSwap === 'CON'}>
                  <InsuranceButton
                    starterNumber={starterNumber}
                    isFixedOddsScratched={shouldDisplayFixedScratched}
                    isFixedOddsSuspended={shouldDisplayFixedSuspended}
                  />
                </WinPlaceToteSwapOptionsStyled>
              )}

              {isStartingPriceAvailable && (
                <WinPlaceToteSwapOptionsStyled isVisible={activeToteSwap === 'SP'}>
                  <StartingPriceButton
                    starterNumber={starterNumber}
                    isFixedOddsScratched={shouldDisplayFixedScratched}
                  />
                </WinPlaceToteSwapOptionsStyled>
              )}
            </>
          </span>
        </>
      )}
    </WinPlaceButtonsContainerStyled>
  )
}
