import React from 'react'
import { useAppDispatch } from '@core/Store/hooks'
import { setActiveToteSwap } from '@core/Areas/RaceCard/Store'

export const useResetActiveToteSwap = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    return () => {
      dispatch(setActiveToteSwap(null))
    }
  }, [dispatch])
}
