import React from 'react'
import { BaseStyled, HighlightStyled } from './Common.styles'
import { useFeature } from '@core/Utils/hooks/useFeature'

export const HighlightStat: React.FC<{
  title: string
  value: unknown
  highlightOn: boolean
}> = ({ title, value, highlightOn, ...props }) => {
  const isFeatureActive = useFeature('INTELLIGENT_FORM_DISPLAY')

  const elm = (
    <>
      <strong>{title}</strong> {value}
    </>
  )

  if (!isFeatureActive || !highlightOn) {
    return <BaseStyled {...props}>{elm}</BaseStyled>
  }

  return <HighlightStyled {...props}>{elm}</HighlightStyled>
}
