import container from '../../IOC/inversify.config'
import { registerComponent } from '../../../AppUtils/Framework/Components/koComponentRegistration'
import { PyosMessageViewModel } from './PyosMessageViewModel'
import template from './pyos-message.tpl.html'

registerComponent({
  componentName: 'pyos-message',
  template,
  resolver: () => container.get<PyosMessageViewModel>('PyosMessageViewModel'),
})
