import { fromJS } from 'immutable'
import { attachDriver, createSignal, Signal } from 'rwwa-rx-state-machine'
import { StateMap } from 'typings/immutable'

export interface KambiState {
  isVisible: boolean
  isLoggedIn: boolean
  isBetslipMaximized: boolean
  logoutToken: string | null
  loginPending: boolean
  isKambiRendered: boolean
  playerSessionId?: string
  isBrowserSupported: boolean
}

export const DisplayKambiClient = createSignal('DisplayKambi')
export const HideKambiClient = createSignal('HideKambi')
export const LoggedInKambiPlayer = createSignal<string>('LoggedInKambi')
export const LoggedOutKambiPlayer = createSignal('LoggedOutKambi')
export const BeginKambiLogin = createSignal('BeginKambiLogin')
export const EndKambiLogin = createSignal('EndKambiLogin')
export const RemoveKambiSpinner = createSignal('RemoveKambiSpinner')
export const UnsupportedBrowser = createSignal('UnsupportedBrowser')

/** This is ONLY used to track Kambi state - use wapi.set() to change betslip maximize */
export const UpdateBetslipMaximized = createSignal<boolean>('UpdateBetslipMaximized')

/** This is ONLY used to track kambi user state - DO NOT change */
export const UpdateUserData = createSignal<string>('UpdateUserData')

export const defaultState: KambiState = {
  isVisible: false,
  isLoggedIn: false,
  isBetslipMaximized: false,
  logoutToken: null,
  loginPending: false,
  isKambiRendered: false,
  isBrowserSupported: true,
}

export type KambiStateStateMap = StateMap<KambiState>

export function driver(state = fromJS(defaultState), signal: Signal): KambiStateStateMap {
  switch (signal.tag) {
    case DisplayKambiClient: {
      return state.merge({ isVisible: true })
    }

    case HideKambiClient: {
      return state.merge({ isVisible: false })
    }

    case BeginKambiLogin: {
      return state.merge({ loginPending: true })
    }

    case EndKambiLogin: {
      return state.merge({ loginPending: false })
    }

    case LoggedInKambiPlayer: {
      return state.merge({ isLoggedIn: true, logoutToken: signal.data, loginPending: false })
    }

    case LoggedOutKambiPlayer: {
      return state.merge({ isLoggedIn: false, logoutToken: null, loginPending: false })
    }

    case UpdateBetslipMaximized: {
      return state.merge({
        isBetslipMaximized: signal.data,
      })
    }

    case UpdateUserData: {
      return state.merge({
        playerSessionId: signal.data,
      })
    }

    case RemoveKambiSpinner: {
      return state.merge({
        isKambiRendered: true,
      })
    }

    case UnsupportedBrowser: {
      return state.merge({
        isBrowserSupported: false,
        isKambiRendered: true,
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver<KambiStateStateMap>({
  driver,
  path: 'kambi',
})
