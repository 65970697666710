import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import {
  isSameRaceMulti,
  isTippingTicket,
  isRefundedStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { StatusSilksContainerStyled } from './StatusSilks.styles'
import { LegStatusSilks } from './LegStatusSilks/LegStatusSilks'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SelectionStatusSilks } from './SelectionStatusSilks/SelectionStatusSilks'
import { sortSameRaceMultiTicketLegs } from '@core/Areas/AccountActivity/helpers'

export const StatusSilks: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  var legs = ticket.legs
  if (isSameRaceMulti(ticket)) {
    legs = sortSameRaceMultiTicketLegs(ticket)
  }

  const isMulti = !isTippingTicket(ticket) && ticket.legs.length > 1
  const isDoubleOrQuaddie = [PlanSeq.Double, PlanSeq.Quaddie].includes(ticket.planSeq)

  // don't display status silks for SRM if the bet is refunded (including the derived refunded states)
  const isRefundedSameRaceMulti = isSameRaceMulti(ticket) && isRefundedStatus(ticket)

  if ((!isMulti && !isDoubleOrQuaddie) || isRefundedSameRaceMulti) return null

  return (
    <StatusSilksContainerStyled data-tid-header-status-silks>
      {isMulti ? (
        <LegStatusSilks legs={legs} planSeq={ticket.planSeq} />
      ) : (
        <SelectionStatusSilks
          selections={legs[0].selections}
          contestType={meetingIdToContestType(legs[0].fixtureId)}
        />
      )}
    </StatusSilksContainerStyled>
  )
}
