let numberFormatter: Intl.NumberFormat

export function toOdds(value: number) {
  if (!isFinite(value)) return ''

  numberFormatter ??= new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return numberFormatter.format(value)
}
