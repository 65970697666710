import React from 'react'
import { PoolDataTransferObject } from '@mobi/api-types'

export const JurisdictionDescription: React.FC<{ pools: PoolDataTransferObject[] }> = ({
  pools,
}) => {
  return (
    <div className='card l-table-fixed l-faux-table l-table-padded w100 thalfem'>
      <div className='l-faux-cell cell-icon pr0 aligntop text-center'>
        <span className='tticon tticon-infoinvert fg-ui-info fz-24'></span>
      </div>

      <div className='l-faux-cell aligntop'>
        <p className='fz-12 t0 b0 text-left'>{getJurisdictionDescription(pools)}</p>
        <p className='fz-12 t0 b0 text-left'>
          Please refer to Help for the{' '}
          <a
            href='https://help.tabtouch.com.au/knowledge-base/international-pooling-rules/'
            rel='noopener noreferrer'
            target='_blank'
            className='fg-pri-light'
          >
            International Betting Rules
          </a>
        </p>
      </div>
    </div>
  )
}

function getJurisdictionDescription(pools: PoolDataTransferObject[]) {
  let description = pools.reduce((accum: string, pool: PoolDataTransferObject) => {
    return (
      accum +
      (pool.PoolingIcon &&
      pool.PoolingIcon === 'INT' &&
      accum.indexOf(pool.JurisdictionDescription) === -1
        ? (accum.length > 0 ? ', ' : '') + pool.JurisdictionDescription
        : '')
    )
  }, '')
  if (description !== undefined && description.length > 0) {
    description = `${description} Betting Rules Apply`
  }
  return description
}
