export const getStartupParameters = (environment: string): StartupParameters => {
  switch (environment) {
    case 'PROD':
      return prodStartupParameters
    case 'QUAL':
      return ctsStartupParameters
    default:
      return ctnStartupParameters
  }
}

interface StartupParameters {
  currency: string
  locale: string
  market: string
  playerId: string
  ticket: string
  oddsFormat: string
  racingMode: string
  channelId: number
  offering: string

  // Parameters to overwrite in order to use CTN servers w non-CTN bundle
  environment?: string
  apiBaseUrl?: string
  apiStatisticsBaseUrl?: string
  apiStatisticsGraphqlUrl?: string
  apiLiveOccurrenceBaseUrl?: string
  apiLiveOccurrencePushUrl?: string
  apiRetailOperatorBaseUrl?: string
  apiAuthBaseUrl?: string
  apiPlayerBaseUrl?: string
  pushUrl?: string
  apiSupportUrl?: string
  externalWidgetConfigUrl?: string
  featureTogglesBaseUrl?: string
}

export const defaultStartupParameters: StartupParameters = {
  currency: 'AUD',
  locale: 'en_AU',
  market: 'AU',
  playerId: '',
  ticket: '',
  oddsFormat: 'decimal',
  racingMode: 'false',
  channelId: 3 /* Mobile */,
  offering: 'rwwawa',
}

const ctnStartupParameters: StartupParameters = {
  ...defaultStartupParameters,

  // CTN Parameters
  environment: 'ctn',
  apiBaseUrl: 'https://ctn-api.kambi.com/{api}/{apiVersion}/',
  apiStatisticsBaseUrl: 'https://ctn-api.kambi.com/statistics/api/',
  apiStatisticsGraphqlUrl: 'https://ctn-api.kambi.com/statistics/v2018/graphql/',
  apiLiveOccurrenceBaseUrl: 'https://ctn-api.kambi.com/liveoccurrence/{apiVersion}/',
  apiLiveOccurrencePushUrl: 'wss://ctn-ws.kambi.com/liveoccurrence/{apiVersion}/',
  apiRetailOperatorBaseUrl: 'https://ctn-shop-retail-operator.api.kambi.com/retail-operator-api/',
  apiAuthBaseUrl: 'https://ctn-auth-api.kambicdn.com/{api}/{apiVersion}/',
  apiPlayerBaseUrl: 'https://ctn-auth-api.kambicdn.com/player/api/v2019/rwwawa/',
  pushUrl: 'https://ctn-push.kambi.com',
  apiSupportUrl: 'https://ctn-bo.customer.kambi.com/support/api/',
  externalWidgetConfigUrl: 'https://ctn-static.kambi.com/client/rwwawa/../widget-disabled.js',
  featureTogglesBaseUrl: 'https://ctn-settings-api.kambicdn.com',
}

const ctsStartupParameters: StartupParameters = {
  ...defaultStartupParameters,

  // CTS Parameters
  environment: 'cts',
  apiBaseUrl: 'https://cts-api.kambi.com/{api}/{apiVersion}/',
  apiStatisticsBaseUrl: 'https://cts-api.kambi.com/statistics/api/',
  apiStatisticsGraphqlUrl: 'https://cts-api.kambi.com/statistics/v2018/graphql/',
  apiLiveOccurrenceBaseUrl: 'https://cts-api.kambi.com/liveoccurrence/{apiVersion}/',
  apiLiveOccurrencePushUrl: 'wss://cts-ws.kambi.com/liveoccurrence/{apiVersion}/',
  apiRetailOperatorBaseUrl: 'https://cts-shop-retail-operator.api.kambi.com/retail-operator-api/',
  apiAuthBaseUrl: 'https://cts-auth-api.kambicdn.com/{api}/{apiVersion}/',
  apiPlayerBaseUrl: 'https://cts-auth-api.kambicdn.com/player/api/v2019/rwwawa/',
  pushUrl: 'https://cts-push.kambi.com',
  apiSupportUrl: 'https://cts-bo.customer.kambi.com/support/api/',
  externalWidgetConfigUrl: 'https://cts-static.kambi.com/client/rwwawa/../widget-disabled.js',
  featureTogglesBaseUrl: 'https://cts-settings-api.kambicdn.com',
}

export const prodStartupParameters: StartupParameters = {
  ...defaultStartupParameters,

  // PROD Parameters
  apiPlayerBaseUrl: 'https://cf-al-auth-api.kambicdn.com/player/api/v2019/rwwawa/',
}
