import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import type { BlackbookPreferencesRequestBody } from '@core/Areas/Blackbook/types'
import { postBlackbookAlertPreferences } from './api'

export const changeBlackbookAlertPreference = (
  newPreferences: BlackbookPreferencesRequestBody,
  shouldRefetchBlackbook = true
) =>
  postBlackbookAlertPreferences(newPreferences).then(() => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.blackbookDetails,
      refetchActive: shouldRefetchBlackbook,
    })
  })
