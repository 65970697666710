import * as immutable from 'immutable'
import Decimal from 'decimal.js'
import type { FobSelection, FobPropositionSelection } from '@mobi/betslip/types'
import { isStartingPriceSelection, isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { calculateBetCost, calculateProjectedReturn } from '@core/Areas/Quickbet/helpers/calculator'
import type { BetslipItem, MultiBetError, MultiInvestment, MultiInvestmentKey } from '../driver'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import {
  MIN_LEGS_IN_MULTI,
  MAX_LEGS_FOR_MULTI_FORMULA,
  MULTI_FORMULA_COMBINATIONS,
} from '../constants'
import {
  getBetsToPlace,
  hasTooManyMultiLegs,
  hasTooFewMultiLegs,
  getBetsInMulti,
  isValidMulti,
  isDuplicateBonusBetErrorType,
  isFatalErrorType,
  isUnspecifiedErrorType,
} from './state'

export function calculateBetCostSingleItem(betslipItem: BetslipItem): number {
  const {
    bettingType,
    isEachWay,
    investment: { win, place },
  } = betslipItem
  if (isToteSelection(betslipItem.selection)) {
    return calculateBetCost(
      bettingType,
      win.value,
      place.value,
      isEachWay,
      betslipItem.selection.betType,
      betslipItem.numberOfCombinations
    )
  }
  return calculateBetCost(bettingType, win.value, place.value, isEachWay)
}

export const calculateTotalStake = (
  betslipItems: immutable.List<BetslipItem>,
  multiInvestment: MultiInvestment,
  multiError: MultiBetError | null,
  hasMultiBeenPlaced: boolean
) => {
  const combinedSingleCost = getBetsToPlace(betslipItems).reduce(
    (total, item) => (calculateBetCostSingleItem(item) || 0) + total,
    0
  )

  const multiItems = getBetsInMulti(betslipItems)
  if (
    hasMultiBeenPlaced ||
    hasTooFewMultiLegs(multiItems) ||
    hasTooManyMultiLegs(multiItems) ||
    isDuplicateBonusBetErrorType(multiError?.betErrorType) ||
    isFatalErrorType(multiError?.betErrorType) ||
    isUnspecifiedErrorType(multiError?.betErrorType)
  ) {
    return combinedSingleCost
  }
  return combinedSingleCost + calclulateCombinedMultiInvestment(multiItems, multiInvestment)
}

export function calculateEstReturnSingleItem(betslipItem: BetslipItem) {
  const {
    bettingType,
    isEachWay,
    selection,
    investment: { win, place, bonusBet },
    selectedSuperPickOffer,
  } = betslipItem

  if (isStartingPriceSelection(selection)) return 0

  const { winPrice, placePrice } = selection as FobSelection
  return calculateProjectedReturn(
    bettingType,
    winPrice,
    placePrice || 0,
    win.value,
    place.value || 0,
    isEachWay,
    bonusBet?.value,
    selectedSuperPickOffer
  )
}

function calculateEstReturnForSingles(items: immutable.List<BetslipItem>) {
  const singleItems = items.filter(item => item.bettingType === 'fixed-odds-racing')
  if (singleItems.count() === 0) {
    return null
  }
  return singleItems.reduce(
    (total: number, nextItem: BetslipItem) => total + calculateEstReturnSingleItem(nextItem),
    0
  )
}

export function calculateEstReturn(
  placeableItems: immutable.List<BetslipItem>,
  placeableMultis: immutable.List<BetslipItem>,
  multiInvestment: MultiInvestment
): number {
  const singlesEstRtn = calculateEstReturnForSingles(placeableItems) || 0
  const multiAndFormulaEstRtn = calculateMultiProjectedPay(placeableMultis, multiInvestment)
  return new Decimal(singlesEstRtn + multiAndFormulaEstRtn).toDecimalPlaces(2).toNumber()
}

export function calculateBoosts(selectedSuperPickOffer: BetSpecialOffer | null) {
  let winBoost = 0
  let placeBoost = 0
  if (selectedSuperPickOffer) {
    const elementWithPriceIncrease = selectedSuperPickOffer.elements?.find(
      element => element.priceIncrease !== null
    )
    if (elementWithPriceIncrease) {
      const priceIncrease = elementWithPriceIncrease.priceIncrease
      winBoost = priceIncrease?.win ? new Decimal(priceIncrease.win).toNumber() : 0
      placeBoost = priceIncrease?.place ? new Decimal(priceIncrease.place).toNumber() : 0
    }
  }
  return [winBoost, placeBoost]
}

// ==================
// Multi Calculations
// ==================

type FobPriceSelector = (selection: FobSelection) => [number, number | null]

const getFobPrices: FobPriceSelector = (selection: FobSelection) => {
  return [selection.winPrice, selection.placePrice]
}

const getLastSeenFobPrices: FobPriceSelector = selection => {
  return [selection.winPriceLastSeen ?? 0, selection.placePriceLastSeen]
}

const calculateMultiReturnInternal = (
  items: immutable.List<BetslipItem>,
  { shouldRound, priceSelector }: { shouldRound: boolean; priceSelector: FobPriceSelector } = {
    shouldRound: false,
    priceSelector: getFobPrices,
  }
): number => {
  const multiItems = getBetsInMulti(items)
  const tooManyBets = hasTooManyMultiLegs(multiItems)
  const isValidNumberOfMultiLegs = multiItems.count() >= MIN_LEGS_IN_MULTI && !tooManyBets
  if (!isValidNumberOfMultiLegs) {
    return 0
  }
  const multiReturn = new Decimal(
    multiItems.reduce((total: number, item: BetslipItem) => {
      const [winPrice, placePrice] = priceSelector(item.selection as FobSelection)
      const price = item.multiLegBetType === 'P' ? placePrice || 0 : winPrice || 0
      return new Decimal(total).times(price).toNumber()
    }, 1)
  )

  if (shouldRound) {
    return multiReturn.toDecimalPlaces(2, Decimal.ROUND_DOWN).toNumber()
  }
  return multiReturn.toNumber()
}

export function calculateMultiReturn(
  items: immutable.List<BetslipItem>,
  { shouldRound }: { shouldRound: boolean } = { shouldRound: false }
): number {
  return calculateMultiReturnInternal(items, { shouldRound, priceSelector: getFobPrices })
}

export function calculateLastSeenMultiReturn(
  items: immutable.List<BetslipItem>,
  { shouldRound }: { shouldRound: boolean } = { shouldRound: false }
): number {
  return calculateMultiReturnInternal(items, { shouldRound, priceSelector: getLastSeenFobPrices })
}

export function calclulateCombinedMultiInvestment(
  multiItems: immutable.List<BetslipItem>,
  multiInvestment: MultiInvestment
) {
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  let formulaTotal = 0
  const multiItemsCount = multiItems.count()

  if (multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const legKey =
        `legs${multiItemsCount}` as keyof (typeof MULTI_FORMULA_COMBINATIONS)[typeof multiInvestmentKey]

      formulaTotal += new Decimal(multiInvestment[multiInvestmentKey])
        .times(MULTI_FORMULA_COMBINATIONS[multiInvestmentKey][legKey])
        .toNumber()
    }
  }
  return formulaTotal + multiInvestment.value
}

function generateMultiFormulaCombos(prices: number[], formulaNumber: number) {
  const combos: number[][] = []
  function doGenerateCombinations(offset: number, combo: number[]) {
    if (combo.length === formulaNumber) {
      combos.push(combo)
      return
    }
    for (let i = offset; i < prices.length; i++) {
      doGenerateCombinations(i + 1, combo.concat(prices[i]))
    }
    return
  }
  doGenerateCombinations(0, [])
  return combos
}

export function calculateMultiFormulaReturn(
  prices: number[],
  formulaNumber: number,
  currentInvestment: number
) {
  return generateMultiFormulaCombos(prices, formulaNumber).reduce((total, combo) => {
    const amount = combo.reduce((prev, cur) => Decimal(prev).times(cur).toNumber(), 1)
    const comboCalc = Decimal(amount)
      .times(100)
      .times(currentInvestment)
      .toDecimalPlaces(0, Decimal.ROUND_DOWN)
    return Decimal(comboCalc).div(100).plus(total).toNumber()
  }, 0)
}

export function calculateMultiProjectedPay(
  multiItems: immutable.List<BetslipItem>,
  multiInvestment: MultiInvestment
): number {
  const multiItemsCount = multiItems.count()
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  // Calculate multi projected pay
  const multiProjectedPay =
    multiInvestment.value > 0
      ? new Decimal(calculateMultiReturn(multiItems))
          .times(multiInvestment.value)
          .minus(multiInvestment.bonusBetId ? multiInvestment.value : 0)
      : 0

  // Calculate formula projected pay total
  const prices = multiItems
    .toArray()
    .map(item =>
      item.multiLegBetType === 'W'
        ? (item.selection as FobPropositionSelection).winPrice
        : (item.selection as FobPropositionSelection).placePrice
    ) as number[]

  let formulaProjectedPay = 0

  const isValidLegsForFormula =
    multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA
  if (isValidLegsForFormula) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const currentInvestment = multiInvestment[multiInvestmentKey]
      if (currentInvestment > 0) {
        formulaProjectedPay += new Decimal(
          calculateMultiFormulaReturn(prices, i, currentInvestment)
        ).toNumber()
      }
    }
  }

  const finalMultiProjectedPay = new Decimal(multiProjectedPay)
    .toDecimalPlaces(2, Decimal.ROUND_DOWN)
    .toNumber()
  const finalFormulaProjectedPay = new Decimal(formulaProjectedPay).toDecimalPlaces(2).toNumber()

  return finalMultiProjectedPay + finalFormulaProjectedPay
}
