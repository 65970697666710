import React from 'react'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { hexColors } from '@mobi/settings'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import {
  persistedSortOption as matchedSortOrder,
  setPersistedSortOption,
} from '@classic/Betting-v2/Sorting/PersistedSorting'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import type { Proposition, SelectedFOOProposition } from '../Data/types'
import { RaceProduct, RaceStatus } from '../constants'
import { PropositionItem } from './PropositionItem'
import { FieldsResponse } from '@core/Areas/RaceCardFixedOnly/Hooks/useRaceFieldSummaryData/api'

const enum LocalConstants {
  ActiveSortClassName = 'js-race-col-header__sort--active',
}

export const PropositionList: React.FC<{
  raceStatus: RaceStatus
  raceProduct: RaceProduct
  fieldSummary?: FieldsResponse
  raceCode: RaceCodesLowerCase
  items: Proposition[]
  selectedItem: SelectedFOOProposition | null
  onItemSelected: (item: SelectedFOOProposition) => void
  isFieldSelected: boolean
  isStartingPriceAvailable: boolean
  payIndicator?: number
  racecourseSeq?: number | undefined
  meetingDate: string
  raceNumber: number
  raceLocation: string
}> = ({
  fieldSummary,
  raceProduct,
  raceStatus,
  raceCode,
  items,
  selectedItem,
  onItemSelected,
  isFieldSelected,
  isStartingPriceAvailable,
  payIndicator,
  racecourseSeq,
  meetingDate,
  raceNumber,
  raceLocation,
}) => {
  const isFixedOddsOrFutureFF =
    raceProduct === RaceProduct.FixedOddsOnly || raceProduct === RaceProduct.FutureFinalField

  const [sortOrder, setSortOrder] = React.useState<SortOrder | null>(() =>
    translateMatchedPersistedSort(isFixedOddsOrFutureFF)
  )

  React.useEffect(() => {
    if (raceStatus === RaceStatus.Resulted || raceStatus === RaceStatus.Settled) {
      setSortOrder(isFieldSelected ? 'NumberAsc' : 'ResultAsc')
    }
  }, [isFieldSelected, raceStatus])

  let sortedItems: Proposition[] = []

  if (sortOrder) {
    sortedItems = Array.from(items).sort(sortOrderFns[sortOrder])
  }

  const handleSortOrderChange = React.useCallback(
    (property: Extract<SortOption['property'], 'Number' | 'Name' | 'FixedPrice'>) => () => {
      setSortOrder(currSort => {
        if (property === 'Number') {
          const isDesc = currSort === 'NumberAsc'
          setPersistedSortOption({ property, direction: isDesc ? 'Descending' : 'Ascending' })
          return isDesc ? 'NumberDesc' : 'NumberAsc'
        }
        if (property === 'Name') {
          const isDesc = currSort == 'NameAsc'
          setPersistedSortOption({ property, direction: isDesc ? 'Descending' : 'Ascending' })
          return isDesc ? 'NameDesc' : 'NameAsc'
        }
        if (property === 'FixedPrice') {
          const isDesc = currSort == 'OddsAsc'
          setPersistedSortOption({ property, direction: isDesc ? 'Descending' : 'Ascending' })
          return isDesc ? 'OddsDesc' : 'OddsAsc'
        }
        return null
      })
    },
    []
  )

  const isRaceStatusOpenOrSuspended =
    raceStatus === RaceStatus.Open || raceStatus === RaceStatus.Suspended
  const shouldRenderStartingPriceColHeaderWithResults =
    isStartingPriceAvailable &&
    !isRaceStatusOpenOrSuspended &&
    items.some(runner => !!runner.spWinReturn)

  return (
    <>
      <SortRowStyled>
        {isFixedOddsOrFutureFF ? (
          <button
            data-testid='sortbynumber'
            onClick={handleSortOrderChange('Number')}
            className={
              sortOrder === 'NumberAsc' || sortOrder === 'NumberDesc'
                ? LocalConstants.ActiveSortClassName
                : ''
            }
          >
            #{' '}
            <Icon
              size='1.4rem'
              name={sortOrder == 'NumberAsc' ? 'SolidChevronUp' : 'SolidChevronDown'}
              color={colors.neutral[500]}
            />
          </button>
        ) : (
          <button
            data-testid='sortbyrunner'
            onClick={handleSortOrderChange('Name')}
            className={
              sortOrder == 'NameAsc' || sortOrder == 'NameDesc'
                ? LocalConstants.ActiveSortClassName
                : ''
            }
          >
            Runner{' '}
            <Icon
              size='1.4rem'
              name={sortOrder == 'NameAsc' ? 'SolidChevronUp' : 'SolidChevronDown'}
              color={colors.neutral[500]}
            />
          </button>
        )}

        {shouldRenderStartingPriceColHeaderWithResults && <span>SP</span>}

        <button
          data-testid='sortbyodds'
          onClick={handleSortOrderChange('FixedPrice')}
          className={
            sortOrder == 'OddsAsc' || sortOrder == 'OddsDesc'
              ? LocalConstants.ActiveSortClassName
              : ''
          }
        >
          Fixed{' '}
          <Icon
            size='1.4rem'
            name={sortOrder == 'OddsAsc' ? 'SolidChevronUp' : 'SolidChevronDown'}
            color={colors.neutral[500]}
          />
        </button>

        {isStartingPriceAvailable && isRaceStatusOpenOrSuspended && <span>SP</span>}
      </SortRowStyled>

      {(sortOrder && sortedItems.length ? sortedItems : items).map(
        proposition =>
          shouldDisplayItem(raceProduct, raceStatus, proposition, isFieldSelected) && (
            <PropositionItem
              key={proposition.sequenceNumber}
              fieldSummary={fieldSummary}
              proposition={proposition}
              raceProduct={raceProduct}
              raceStatus={raceStatus}
              raceCode={raceCode}
              selectedItem={selectedItem}
              onItemSelected={onItemSelected}
              isFieldSelected={isFieldSelected}
              isStartingPriceAvailable={isStartingPriceAvailable}
              payIndicator={payIndicator}
              racecourseSeq={racecourseSeq}
              meetingDate={meetingDate}
              raceNumber={raceNumber}
              raceLocation={raceLocation}
            />
          )
      )}
    </>
  )
}

// =============
// Local Helpers
// =============

const translateMatchedPersistedSort = (isNumberSortEnabled: boolean): SortOrder | null => {
  const isAscending = matchedSortOrder.direction === 'Ascending'
  if (matchedSortOrder.property === 'FixedPrice') return isAscending ? 'OddsAsc' : 'OddsDesc'
  if (isNumberSortEnabled) {
    if (matchedSortOrder.property === 'Number') return isAscending ? 'NumberAsc' : 'NumberDesc'
  } else {
    if (matchedSortOrder.property === 'Name') return isAscending ? 'NameAsc' : 'NameDesc'
  }
  return null
}

const sortOrderFns: Record<SortOrder, (a: Proposition, b: Proposition) => number> = {
  NumberAsc: (a, b) => a.starterNumber - b.starterNumber,
  NumberDesc: (a, b) => b.starterNumber - a.starterNumber,
  NameAsc: (a, b) => a.name.localeCompare(b.name),
  NameDesc: (a, b) => b.name.localeCompare(a.name),
  ResultAsc: (a, b) => a.resultPlace - b.resultPlace,
  OddsAsc: (a, b) => {
    // Scratched runners sorted to bottom of list
    if (a.scratchTime) {
      return 1
    } else if (b.scratchTime) {
      return -1
    }

    return a.winReturn - b.winReturn
  },
  OddsDesc: (a, b) => {
    // Scratched runners sorted to bottom of list
    if (a.scratchTime) {
      return 1
    } else if (b.scratchTime) {
      return -1
    }

    return b.winReturn - a.winReturn
  },
}

const shouldDisplayItem = (
  raceProduct: RaceProduct,
  raceStatus: RaceStatus,
  proposition: Proposition,
  fieldSelected: boolean
) =>
  raceProduct !== RaceProduct.FixedOddsOnly ||
  (raceStatus !== RaceStatus.Resulted && raceStatus !== RaceStatus.Settled) ||
  !!proposition.resultPlace ||
  fieldSelected

// ======
// Styles
// ======

const SortRowStyled = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  backgroundColor: colors.white,
  borderBottom: '0.1rem solid ' + hexColors.gainsboro,

  '& > button, & > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '5rem',
    height: '4rem',
    marginRight: '1rem',
    padding: 0,
    border: 0,
    background: 0,

    fontFamily: font.family.primary,
    fontWeight: font.weight.regular,
    fontSize: font.size.sm.fontSize,
    letterSpacing: font.size.sm.letterSpacing,
    lineHeight: font.size.sm.lineHeight,
    color: colors.black,

    '> span': { paddingLeft: '0.3rem' },
  },

  'button:first-of-type': {
    justifyContent: 'flex-start',
    marginRight: 'auto',
    marginLeft: '1rem',
  },

  [`& > button.${LocalConstants.ActiveSortClassName}`]: {
    fontWeight: font.weight.semibold,
    svg: { color: colors.black },
  },
})

// =====
// Types
// =====

type SortOrder =
  | 'NumberDesc'
  | 'NumberAsc'
  | 'NameDesc'
  | 'NameAsc'
  | 'OddsDesc'
  | 'OddsAsc'
  | 'ResultAsc'
