import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState: BetSlipConfigState = {
  presetKeypadAmounts: [
    { default: 5, user: null },
    { default: 10, user: null },
    { default: 20, user: null },
  ],
}

const configSlice = createSlice({
  name: 'betslip/config',
  initialState,
  reducers: {
    setUserPresetDenomination(
      state,
      { payload }: PayloadAction<Array<{ index: number; value: number | null }>>
    ) {
      payload.forEach(({ index, value }) => {
        const preset = state.presetKeypadAmounts[index]
        if (preset && value !== null) {
          preset.user = value
        }
      })
    },
  },
})

// =============
// Slice Exports
// =============

export const { setUserPresetDenomination } = configSlice.actions

const allowPersistForKeys: (keyof BetSlipConfigState)[] = ['presetKeypadAmounts']

export default persistReducer(
  {
    version: 1,
    key: 'betslip/config',
    storage,
    debug: !PRODUCTION,
    whitelist: allowPersistForKeys,
  },
  configSlice.reducer
)

// =====
// Types
// =====

export type BetSlipConfigState = {
  presetKeypadAmounts: [
    { default: number; user: number | null },
    { default: number; user: number | null },
    { default: number; user: number | null },
  ]
}
