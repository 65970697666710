import styled from '@emotion/styled'
import { clearFix, ellipsis } from 'polished'
import { HeaderPillStyled } from '@core/Areas/Quickbet/Components/Header/Components/Common/Header.styles'

export const NoveltyListStyled = styled.dl({
  margin: '1rem 0 0',
  ...clearFix(),
})

export const NoveltyPlaceStyled = styled.dt({
  marginBottom: '0.5rem',
  marginLeft: 0,
  width: '7rem',
  clear: 'both',
  float: 'left',

  '&:last-of-type': {
    marginBottom: 0,
  },
})

export const NoveltyStarterStyled = styled(NoveltyPlaceStyled.withComponent('dd'))({
  width: 'calc(100% - 7rem)',
  clear: 'none',
  float: 'right',
  wordBreak: 'break-all',
  whiteSpace: 'normal',
})

export const NoveltyPlacePillStyled = styled(HeaderPillStyled)({
  ...ellipsis('100%'),
  marginLeft: '-0.7rem',
  minWidth: '2rem', // so that `1st`, `2nd`, `3rd`, and `4th` pills all have the same width
})

export const NoveltyPlaceTextStyled = styled.span({
  fontSize: '1.2rem',
})

export const SingleSelectionStyled = styled.div({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  lineHeight: 'normal',
  padding: '1rem 0 0',
  wordBreak: 'break-all',
  wordWrap: 'normal',
})

export const SingleSameRaceMultiSelectionStyled = styled(SingleSelectionStyled)({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
})

export const AbbrStyled = styled.abbr({ textDecoration: 'none' })

export const ComboBetLinesStyled = styled(NoveltyListStyled)()

export const MysteryComboSelectionRowStyled = styled.div({
  display: 'flex',
  margin: '1rem 0',
})

export const MysteryComboCodeStyled = styled.div({ minWidth: '6.5rem' })

export const MysteryComboSelectionStyled = styled.div({ flexGrow: 1 })
