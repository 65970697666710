import { createSelector } from '@reduxjs/toolkit'
import type { FobSelection } from '@mobi/betslip/types'
import { isFobSelection } from '@mobi/betslip/helpers/typeGuards'
import type { AppState } from '..'

export const selectBetSlipItems = (state: AppState) => state.betslip.bets.items

export const selectBetSlipItemCount = createSelector([selectBetSlipItems], items => {
  return items.length
})

export const selectBetSlipFobSelectionsIds = createSelector([selectBetSlipItems], items =>
  items
    .filter(item => isFobSelection(item.selection))
    // TODO: Extend for sports
    .map(item => (item.selection as FobSelection).propositionSeq)
)
