import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import template from './info-toolbar-wrapper.tpl.html'
import '../../../../AppUtils/Framework/CustomBindings/StickyBinding'
import { IInfoToolbarViewModel, IInfoToolbarViewModelParams } from './IInfoToolbarViewModel'

export function register(): void {
  if (!ko.components.isRegistered('info-toolbar')) {
    ko.components.register('info-toolbar', {
      viewModel: {
        createViewModel: (params: IInfoToolbarViewModelParams): IInfoToolbarViewModel => {
          const viewModel = container.get<IInfoToolbarViewModel>('IInfoToolbarViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
