import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { layering } from '@mobi/component-library/Theme/Common'
import { colors } from '@core/Settings'

export const PillStyled = styled.span({
  background: hexColors.whiteSmoke,
  borderRadius: '0.25rem',
  color: hexColors.grey,
  display: 'inline-block',
  fontSize: '1.0rem',
  lineHeight: '0.8',
  margin: '0 0.5rem 0 auto',
  padding: '0.5rem',
  textAlign: 'center',
  width: '2.8rem',
})

export const StructuredSearchContainerStyled = styled.div({
  backgroundColor: colors.structuredSearch.container.background,
})

export const StructureSearchGroupHeader = styled.div({
  color: colors.structuredSearch.groupheader.text,
  fontSize: '1.4rem',
  fontWeight: 700,
  padding: '0.25rem 0 0.5rem 0',
})

export const StructureSearchGroupSubHeader = styled.div({
  color: colors.structuredSearch.groupheader.text,
  fontSize: '1.4rem',
  padding: '1rem 0 0.75rem 0',
  fontWeight: 700,
})

export const StructureSearchGroup = styled.div({
  backgroundColor: colors.structuredSearch.section.background,
  fontSize: '1.4rem',
  margin: '0.25rem 0.5rem',
  padding: '0.25rem 0.5rem',
})

export const StructuredSearchTabListContainerStyled = styled.div({
  backgroundColor: colors.structuredSearch.tabs.background,
})

export const TabLabelResultsStyled = styled.span({
  backgroundColor: colors.structuredSearch.tabs.count.background,
  border: '0.3rem',
  color: colors.structuredSearch.tabs.count.text,
  marginLeft: '0.5rem',
  padding: '0.25rem 0.5rem',
})

export const CriteriaSubtitleLabelStyled = styled.span({
  display: 'block',
  padding: '0.75rem 0',
})

export const CriteriaFixedFooterStyled = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'rgba(256, 256, 256, 0.9)',
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: '0.25rem 0.5rem',
  boxSizing: 'border-box',
  zIndex: layering.overlayLow,
})

export const ToggleBlockStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
})

export const ToggleNoLabelBlockStyled = styled(ToggleBlockStyled)({
  marginBottom: '0.25rem',
})

export const ToggleFirstBlockStyled = styled(ToggleBlockStyled)({
  marginTop: '1rem',
})

export const ToggleLabelStyled = styled.div({
  fontSize: '1.4rem',
})

export const ToggleSubLabelStyled = styled.div({
  fontSize: '1.2rem',
  color: colors.structuredSearch.toggle.text,
})
