import colors from '@core/Settings/colors'

interface ThemeSettings {
  background: string
  text: {
    color: string
    size: string | number
    weight: 'bold' | 'normal'
  }
  padding: string
  margin: string
}

export interface BettingHeaderComponentTheme {
  container: ThemeSettings
  heading: ThemeSettings
  subheading: ThemeSettings
  pill: ThemeSettings
  selection: ThemeSettings
  removeButton?: ThemeSettings
}

export const betslipBettingHeader: BettingHeaderComponentTheme = {
  container: {
    background: 'inherit',
    text: {
      color: 'inherit',
      size: '1.2rem',
    },
    padding: '0 0 1rem 0',
  } as ThemeSettings,
  heading: {
    text: {
      size: '1.4rem',
      weight: 'bold',
    },
  } as ThemeSettings,
  subheading: {
    text: {
      size: '1.2rem',
    },
    padding: '0 2.5rem 0.5rem 0',
  } as ThemeSettings,
  pill: {
    background: colors.betslip.pill.background,
    text: {
      color: colors.betslip.pill.text,
      size: '1.1rem',
    },
  } as ThemeSettings,
  selection: {
    text: {
      size: '1.6rem',
    },
    padding: '0.5rem 0 0',
  } as ThemeSettings,
  removeButton: {
    text: {
      color: colors.betslip.removeButton.color,
    },
  } as ThemeSettings,
}

export const betslipSimpleBettingHeader: BettingHeaderComponentTheme = {
  ...betslipBettingHeader,
  heading: {
    text: {
      size: '1.2rem',
      weight: 'normal',
    },
  } as ThemeSettings,
  selection: {
    text: {
      size: '1.4rem',
    },
    padding: '0',
  } as ThemeSettings,
  container: {
    ...betslipBettingHeader.container,
    padding: '0',
  },
}

export const quickbetBettingHeader: BettingHeaderComponentTheme = {
  container: {
    background: colors.quickbet.header.background,
    text: {
      color: colors.quickbet.header.text,
      size: '1.2rem',
    } as ThemeSettings['text'],
    padding: '2.2rem',
  } as ThemeSettings,
  heading: {
    text: {
      size: '1.4rem',
      weight: 'bold',
    } as ThemeSettings['text'],
    padding: '0 2.5rem 0.2rem 0',
  } as ThemeSettings,
  subheading: {
    text: {
      size: '1.2rem',
    } as ThemeSettings['text'],
    padding: '0 2.5rem 1.2rem 0',
  } as ThemeSettings,
  pill: {
    background: colors.quickbet.header.pill.background,
    text: {
      size: '1.1rem',
    } as ThemeSettings['text'],
    margin: '0 0 0 -1rem',
  } as ThemeSettings,
  selection: {
    text: {
      size: '1.6rem',
    } as ThemeSettings['text'],
    padding: '1.5rem 0 0',
  } as ThemeSettings,
}
