import * as ko from 'knockout'
import { inject, injectable } from 'inversify'
import Guard from '@classic/AppUtils/Framework/Guard'
import { ObservableFinisher } from '@classic/Betting-v2/Model/Observables/ObservableFinisher'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import { IFinisherPricesViewModel } from './IFinisherPricesViewModel'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'

@injectable()
export class FinisherPricesViewModel extends Disposable implements IFinisherPricesViewModel {
  public finisher!: ObservableFinisher
  public showToteWin!: ko.PureComputed<boolean>
  public showFobWin!: ko.PureComputed<boolean>
  public showSpWinDividend!: ko.Computed<boolean>
  public displaySpWinDividend!: ko.Computed<string>
  public shouldShowFixedFavourite!: ko.Computed<boolean>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: { finisher: ObservableFinisher }) {
    Guard.notNull(params.finisher)

    this.finisher = params.finisher

    this.showSpWinDividend = ko.pureComputed<boolean>(() => this.finisher.spWinPrice() > 0)
    this.displaySpWinDividend = ko.pureComputed<string>(() => this.finisher.spWinPrice().toFixed(2))

    this.showToteWin = ko.pureComputed<boolean>((): boolean => {
      return (
        this.finisher.placing() === 1 ||
        this.finisher.toteWinDiv() > 0 ||
        this.finisher.isCountBack()
      )
    })

    this.showFobWin = ko.pureComputed<boolean>((): boolean => {
      return this.finisher.placing() === 1
    })

    this.shouldShowFixedFavourite = ko.pureComputed<boolean>(
      () => this.finisher.hasFixedOdds() && this.finisher.fixedOddsFinisherInfo.isFavourite()
    )
  }
}
