import React from 'react'
import { observeImmutable } from '../../../../../Components/HOCs'
import { Campaign, state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as quickEditState$ } from '../driver'
import { Observable } from 'rx'
import {
  BonusBetButtonComponent,
  BonusBetButtonStateProps,
  BonusBetExternalProps,
} from '@core/Areas/Quickbet/Components/BonusBetButton/BonusBetButton'

const isBonusCampaign = (campaign: Campaign) =>
  campaign.rewardType === 'BonusBetReward' || campaign.rewardType === 'BonusCashReward'

const state$ = Observable.combineLatest(
  userAccountState$,
  quickEditState$,
  (userAccountState, quickEditState): BonusBetButtonStateProps => {
    return {
      isLoggedIn: userAccountState.isLoggedIn ?? false,
      isUsingBonusBet: quickEditState.isUsingBonusBet,
      isBusy: false,
      canConfirmBet: false,
      hasNotification: false,
      activeCampaigns: userAccountState.activeCampaigns?.filter(isBonusCampaign).toArray(),
      insufficientFundsForBet: false,
    }
  }
)

export const BonusBetButton = observeImmutable<BonusBetButtonStateProps, BonusBetExternalProps>(
  state$,
  ({ record, toggleBonusBet, trackBonusBetClick }) => {
    return (
      <BonusBetButtonComponent
        {...record}
        toggleBonusBet={toggleBonusBet}
        trackBonusBetClick={trackBonusBetClick}
      />
    )
  }
)
