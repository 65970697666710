import { useState, useEffect } from 'react'
import { TypedRecord } from 'typed-immutable-record'

export function useObservable<T, TRecord extends TypedRecord<TRecord> & T>(
  driver: Rx.Observable<TRecord>,
  initial: T
): TRecord

export function useObservable<T>(driver: Rx.Observable<T>, initial: T): T

export function useObservable<T>(driver: Rx.Observable<T>, initial: T): T {
  const [state, setState] = useState<T>(initial)
  useEffect(() => {
    const subscription = driver.distinctUntilChanged().subscribe(data => {
      setState(data)
    })
    return () => subscription.dispose()
  }, [driver])
  return state
}

export function useObservableProperties<T, K extends keyof T>(
  driver: Rx.Observable<T>,
  properties: Array<K>,
  initial: Pick<T, K>
): Pick<T, K> {
  const [state, setState] = useState<Pick<T, K>>(initial)
  useEffect(() => {
    const subscription = driver
      .map(value => {
        const result: Pick<T, K> = {} as Pick<T, K>
        properties.forEach(p => (result[p] = value[p]))
        return result
      })
      .distinctUntilChanged()
      .subscribe(data => {
        setState(data)
      })
    return () => subscription.dispose()
  }, [])
  return state
}
