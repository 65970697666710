import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const MarketMoverContainer = styled('div')({
  borderTop: `1px solid ${colors.fixedFlucs.separator}`,
  padding: '0.75rem',
  lineHeight: '1.5rem',
})

export const MarketMoversDividend = styled('span')({
  display: 'block',
})

export const MarketMoversHeader = styled('span')({
  display: 'block',
  color: colors.fixedFlucs.label,
})
