import { attachDriver, Signal } from 'rwwa-rx-state-machine'
import { makeTypedFactory, TypedRecord } from 'typed-immutable-record'
import { Status, OnStatusChanged } from './signals'

interface PushDataState {
  status: Status
}

const defaultState: Readonly<PushDataState> = {
  status: 'default',
}

interface PushDataStateRecord extends TypedRecord<PushDataStateRecord>, PushDataState {}

const Factory = makeTypedFactory<PushDataState, PushDataStateRecord>(defaultState)

function featureFlagDriver(state = Factory(), signal: Signal): PushDataStateRecord {
  switch (signal.tag) {
    case OnStatusChanged:
      if (!signal.data) {
        return state
      }
      return state.merge({
        status: signal.data,
      })

    default:
      return state
  }
}

export const state$ = attachDriver<PushDataStateRecord>({
  path: 'pushDataState',
  driver: featureFlagDriver,
})
