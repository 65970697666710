import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import type { IconType } from '@mobi/component-library/Common/Icon/Icon'
import type { BlackbookEntryRacingToday, BlackbookEntry } from '@core/Areas/Blackbook/types'

export function isBettingAvailable(
  RacingTodayDetails: BlackbookEntryRacingToday['RacingTodayDetails']
): boolean {
  return !!(
    !RacingTodayDetails.AbandonedInd &&
    !RacingTodayDetails.CloseTime &&
    !RacingTodayDetails.ResultedInd &&
    !RacingTodayDetails.ScratchedInd &&
    !RacingTodayDetails.SuspendedInd
  )
}

const iconDict: IconDict = { Dogs: 'dog', Races: 'races', Trots: 'trot', Jockeys: 'jockey' }

export function getIconName(code: BlackbookEntry['Code'], meetingId?: string): IconType {
  if (meetingId) {
    const meetingToIconKey = meetingIdToContestType(meetingId)
    if (meetingToIconKey) return meetingToIconKey
  }
  return iconDict[code] || 'tab'
}

// Types

type IconDict = Record<BlackbookEntry['Code'], IconType>
