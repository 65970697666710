import React from 'react'
import type { BetSlipItem, FobSelection } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
// import { SelectedSuperPick } from './Components/SuperPick/SelectedSuperPick'
// import { SuperPickAvailable } from './Components/SuperPick/SuperPickAvailable'
import { isFobSportsSelection, isStartingPriceSelection } from '@mobi/betslip/helpers/typeGuards'
// import { isSpecialUsed } from '@mobi/betslip/helpers/state'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { Investment, InvestmentsWrapperStyled } from '@mobi/betslip/Components/Common/Investment'
import { calculateEstReturnSingleItem } from '@mobi/betslip/helpers/calculator/singles'
// import { ProjectedReturn } from './Components/ProjectedReturn/ProjectedReturn'

export const FobInvestment: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const activeInvestment = useAppSelector(selectActiveInvestment)
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  const isItemInputActive = activeInvestment?.itemId === item.id

  // TODO: Use correct state
  // const isLoggedIn = true

  // TODO: Find best way to toggle can show inducements
  // const canShowInducements = true // useInducementService()

  // TODO: Hook up with state
  // const deselectSuperPick = () => null // DeselectSuperPick(item)

  const { selection } = item
  const prices = selection as FobSelection

  // const [winBoost, placeBoost] = calculateBoosts(selectedSuperPickOffer ?? null)

  const hasWinPrice = !!prices.winPrice

  const hasPlacePrice = !!prices.placePrice
  const isStartingPriceBet = isStartingPriceSelection(selection)
  const isSportedSelection = isFobSportsSelection(selection)
  const isEditable = !item.receipt && workflowStatus === 'ready' && !isBusy

  // const handleEditBetslipWin = () => editBetslipItem({ item, investmentType: 'win', isEditable })
  // const handleEditBetslipPlace = () =>
  //   editBetslipItem({ item, investmentType: 'place', isEditable })

  // const isSuperPickAvailable =
  // canShowInducements && isLoggedIn && !!item.specialOffers && item.specialOffers.length > 0
  // const shouldShowSuperPickButton = isSuperPickAvailable && !isSpecialUsed(item)
  // const shouldShowSuperPickSelected = isSuperPickAvailable && !!selectedSuperPickOffer

  if (isSportedSelection) {
    return (
      <InvestmentsWrapperStyled>
        <Investment
          value={item.investment.win.value}
          itemId={item.id}
          investmentType='Win'
          label='Stake'
          // isBonusBet={!!investment.win.isBonusBet}
          isEditable={isEditable}
          isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
        />

        <Investment
          value={calculateEstReturnSingleItem(item)}
          itemId={item.id}
          label='Potential Payout'
          investmentType='ReverseStake'
          isEditable={isEditable}
          isActive={isItemInputActive && activeInvestment.investmentType === 'ReverseStake'}
        />
      </InvestmentsWrapperStyled>
    )
  }

  return (
    <div>
      {/* // TODO: Logic below should be inside selection info */}
      {/* <InvestmentPriceStyled
            price={new Decimal(prices.winPrice || 0).plus(winBoost).toNumber()}
            priceChange={winPriceChange}
            isEditable={isEditable}
            shouldHighlight={winBoost > 0}
          /> */}

      {/* // TODO: Logic below should be inside selection info */}
      {/* <InvestmentPriceStyled
            price={new Decimal(prices.placePrice || 0).plus(placeBoost).toNumber()}
            priceChange={placePriceChange}
            isEditable={isEditable}
            shouldHighlight={placeBoost > 0}
          /> */}

      {item.isEachWayAvailable ? (
        <div>
          <Investment
            value={item.investment.win.value}
            itemId={item.id}
            label='Stake'
            investmentType='Win'
            isEditable={isEditable}
            // isBonusBet={!!investment.win.isBonusBet}
            isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
          />

          {/* // TODO: Toggle/checkbox to enable eachway */}
          {/* item.isEachWay */}
        </div>
      ) : (
        <InvestmentsWrapperStyled isStacked={isEditable}>
          {(hasWinPrice || isStartingPriceBet) && (
            <Investment
              value={item.investment.win.value}
              itemId={item.id}
              investmentType='Win'
              display='stacked'
              isEditable={isEditable}
              // isBonusBet={!!investment.win.isBonusBet}
              isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
            />
          )}

          {hasPlacePrice && (
            <Investment
              value={item.investment.place.value}
              itemId={item.id}
              investmentType='Place'
              display='stacked'
              isEditable={isEditable}
              // isBonusBet={!!investment.place.isBonusBet}
              isActive={isItemInputActive && activeInvestment.investmentType === 'Place'}
            />
          )}
        </InvestmentsWrapperStyled>
      )}

      {/* // TODO: SuperPick enabling */}
      {/*
        {shouldShowSuperPickSelected && (
          <SelectedSuperPick
            label={selectedSuperPickOffer?.title ?? ''}
            onDeselectClick={deselectSuperPick}
          />
        )}

        {shouldShowSuperPickButton && !shouldShowSuperPickSelected && (
          <SuperPickAvailable
            onClick={handleEditBetslipWin}
            disabled={false}
            isEditable={isEditable}
          />
        )}
      */}

      {/* // TODO: Display projected return */}
      {/* <ProjectedReturn item={item} /> */}
    </div>
  )
}

// =============
// Local Helpers
// =============

// const editBetslipItem = ({
//   item,
//   investmentType = 'win',
//   isEditable,
// }: {
//   item: BetSlipItem
//   investmentType: Extract<keyof BetSlipItem['investment'], 'win' | 'place'>
//   isEditable: boolean
// }) => {
//   // eslint-disable-next-line no-console
//   console.log('editBetslipItem', investmentType)

//   if (!isFatalErrorType(item.betErrorType) && isEditable) {
//     if (item.investment.win.isBonusBet) {
//       // SetActiveInvestment(InvestmentType.Win)
//     } else if (item.investment.place.isBonusBet) {
//       // SetActiveInvestment(InvestmentType.Place)
//     } else {
//       // SetActiveInvestment(investmentType)
//     }

//     // EditBetslipItem(item)
//     // let shouldShowSuperPicks = !(isFobPropositionSelection(item.selection) && item.selection.marketCode === FixedOddsMarketCodes.Concession)

//     // TODO: Checks all items if special is used already
//     // shouldShowSuperPicks = !isSpecialUsed(item, items)

//     if (item.investment.bonusBet) {
//       // ToggleBonusBetUsage()
//       // SetBonusBet(item.investment.bonusBet)
//     }

//     // ToggleBonusCashUsage(item.isUsingBonusCash ?? true)
//   }
// }
