import * as ko from 'knockout'

export class InvestmentAmountModel {
  constructor(amount: number, min: number, max: number, step: number, title: string) {
    this.title = ko.observable(title)
    this.amount = ko.observable(amount.toString())
    this.step = ko.observable(step)
    this.min = min
    this.max = max
    this.numberCombinations = ko.observable(0)
    this.supportFlexi = ko.observable(false)
  }

  public min: number
  public max: number
  public amount: ko.Observable<string>
  public step: ko.Observable<number>
  public title: ko.Observable<string>
  public numberCombinations: ko.Observable<number>
  public supportFlexi: ko.Observable<boolean>
}
