import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import accountActivity from '@core/Areas/AccountActivity/Store'
import appHeader from '@core/Areas/AppHeader/Store'
import blackbook from '@core/Areas/Blackbook/Store'
import login, { setIsLoginShown } from '@core/Areas/Login/Store'
import modal, { closeModal, openModal } from '@core/Components/Modal/store'
import signUp from '@core/Areas/Account/SignUp/Old/Store'
import toolbox from '@core/Areas/Toolbox/Store'
import navigation from '@core/Areas/Navigation/Store'
import meetings from '@core/Areas/Meetings/Store'
import raceCard from '@core/Areas/RaceCard/Store'
import settings from '@core/Areas/Settings/Store'
import skyVideoPlayer from '@core/Areas/SkyVideoPlayer/Store'
import quickDeposit from '@core/Areas/QuickDeposit/Store'
import deposit from '@mobi/account/Store/deposit'
import verification from '@core/Areas/Account/Verification/Store'
import skyRaces from '@core/Areas/RaceCardSky/Store'
import { betslip } from '@mobi/betslip/Store'
import { listenerMiddleware } from './listenerMiddleware'

export const store = configureStore({
  reducer: {
    accountActivity,
    appHeader,
    betslip,
    blackbook,
    deposit,
    login,
    meetings,
    modal,
    navigation,
    raceCard,
    settings,
    signUp,
    skyRaces,
    skyVideoPlayer,
    toolbox,
    quickDeposit,
    verification,
  },
  devTools: !PRODUCTION,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          openModal.type,
          closeModal.pending.type,
          setIsLoginShown.type,
        ],
        ignoredPaths: ['modal.items', 'login.onLoginSuccessCallback'],
      },
    }).prepend(listenerMiddleware.middleware),
})

export const persistor = persistStore(store)

// Types
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
