import * as ko from 'knockout'

export enum PoolType {
  Win,
  Place,
  WinPlace,
  Quinella,
}

export class PoolInfo {
  public constructor(raceNumber: number, type: PoolType, description: string, friendly: string) {
    this.id = ko.observable(`${friendly}-${raceNumber}`)
    this.type = ko.observable(type)
    this.description = ko.observable(description)
    this.name = ko.observable(friendly)
  }

  public id: ko.Observable<string>
  public type: ko.Observable<PoolType>
  public description: ko.Observable<string>
  public name: ko.Observable<string>
}

export default class ObservableRaceKeyPoolInfo {
  public constructor(raceNumber: number) {
    this.raceNumber = raceNumber
    this.hasPlacePool = ko.observable(false)
    this.hasQuinellaPool = ko.observable(false)
    this.hasWinPool = ko.observable(false)
    this.hasWinPlacePool = ko.observable(false)
    this.values = ko.observableArray([] as PoolInfo[])
    // @ts-expect-error Type issue
    this.selectedPool = ko.observable<PoolInfo>()
  }

  public merge(other: ObservableRaceKeyPoolInfo) {
    this.raceNumber = other.raceNumber
    this.hasPlacePool(other.hasPlacePool())
    this.hasQuinellaPool(other.hasQuinellaPool())
    this.hasWinPool(other.hasWinPool())
    this.hasWinPlacePool(other.hasWinPlacePool())
    this.configure()
  }

  public configure() {
    if (this.hasWinPool()) {
      this.values.push(new PoolInfo(this.raceNumber, PoolType.Win, 'Win', 'Win'))
    }

    if (this.hasPlacePool()) {
      this.values.push(new PoolInfo(this.raceNumber, PoolType.Place, 'Place', 'Place'))
    }

    if (this.hasWinPlacePool()) {
      this.values.push(new PoolInfo(this.raceNumber, PoolType.WinPlace, 'E/W', 'Eachway'))
    }

    if (this.hasQuinellaPool()) {
      this.values.push(new PoolInfo(this.raceNumber, PoolType.Quinella, 'Quinella', 'Quinella'))
    }

    this.selectedPool(this.values()[0])
  }

  public raceNumber: number
  public hasPlacePool: ko.Observable<boolean>
  public hasQuinellaPool: ko.Observable<boolean>
  public hasWinPool: ko.Observable<boolean>
  public hasWinPlacePool: ko.Observable<boolean>
  public values: ko.ObservableArray<PoolInfo>
  public selectedPool: ko.Observable<PoolInfo>
}
