import React, { ComponentProps } from 'react'
import { SvgRaceCardIcon } from './Icons.styles'

type Props = ComponentProps<typeof SvgRaceCardIcon>

export const Tips = ({ size }: Props) => (
  <SvgRaceCardIcon size={size}>
    <path d='M0 8.4H2.06897V14H4.13793V8.4H6.25501V6H0V8.4Z' fill='#512D6D' />
    <path d='M8.96552 6H6.89655V14H8.96552V6Z' fill='#512D6D' />
    <path
      d='M9.65517 6V14H11.7241V11.6H14.4828V6H9.65517ZM11.7241 9.2L11.7423 8.36552H12.4138V9.2H11.7241Z'
      fill='#512D6D'
    />
    <path d='M20 8.4V6H15.1724V10.8H17.931V11.6H15.1724V14H20V9.2H17.3376V8.4H20Z' fill='#512D6D' />
  </SvgRaceCardIcon>
)
