import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { layering } from '@mobi/component-library/Theme/Common'

export const BG_ANIMATION_TIMEOUT_MS = 200

export const AccountDropDownBGStyled = styled('div')<{ isVisible: boolean; zIndex?: number }>(
  {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.7)',
    width: '100%',
    zIndex: layering.appHeader - 1,
    transition: `opacity ${BG_ANIMATION_TIMEOUT_MS}ms linear`,
    animation: `${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} ${BG_ANIMATION_TIMEOUT_MS}ms linear`,
  },
  ({ isVisible = false, zIndex = layering.appHeader - 1 }) => ({
    opacity: isVisible ? 1 : 0,
    zIndex: zIndex != null ? zIndex : layering.appHeader - 1,
  })
)
