import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IFinisherPlaceViewModel } from './IFinisherPlaceViewModel'

@injectable()
export class FinisherPlaceViewModel extends Disposable implements IFinisherPlaceViewModel {
  public finisher?: IObservableFinisher
  public ordinal?: ko.PureComputed<string>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: { finisher: IObservableFinisher }) {
    Guard.notNull(params.finisher)

    this.finisher = params.finisher
    this.ordinal = ko.pureComputed<string>(() => {
      switch ((this.finisher as IObservableFinisher).placing()) {
        case 1:
          return 'ST'
        case 2:
          return 'ND'
        case 3:
          return 'RD'
      }
      return 'TH'
    })
  }
}
