import ObservableRace from './ObservableRace'
import Guard from '../../../AppUtils/Framework/Guard'
import * as ko from 'knockout'
import { WeatherClassUtil } from '../../Utils/WeatherClassUtil'
import ObservablePool from './ObservablePool'

export default class ObservableMeetingInformation {
  constructor() {
    // @ts-expect-error Type issue
    this.meetingId = ko.observable()
    // @ts-expect-error Type issue
    this.meetingCode = ko.observable()
    // @ts-expect-error Type issue
    this.meetingName = ko.observable()
    // @ts-expect-error Type issue
    this.meetingDate = ko.observable()
    // @ts-expect-error Type issue
    this.nextRaceNumber = ko.observable()
    // @ts-expect-error Type issue
    this.previousRaceNumber = ko.observable()
    // @ts-expect-error Type issue
    this.hasNextRace = ko.observable()
    // @ts-expect-error Type issue
    this.hasPreviousRace = ko.observable()
    // @ts-expect-error Type issue
    this.trackCondition = ko.observable()
    // @ts-expect-error Type issue
    this.weatherAtLocation = ko.observable()
    this.selectedRace = new ObservableRace()
    this.races = ko.observableArray(new Array<ObservableRace>())
    this.mergeDone = ko.observable(false).extend({ notify: 'always' })
    this.availablePools = ko.observableArray(new Array<ObservablePool>())
  }

  public merge(other: ObservableMeetingInformation) {
    Guard.notNull(other)

    this.mergeDone(false)

    this.meetingId(other.meetingId())
    this.meetingCode(other.meetingCode())
    this.meetingName(other.meetingName())
    this.meetingDate(other.meetingDate())
    this.nextRaceNumber(other.nextRaceNumber())
    this.previousRaceNumber(other.previousRaceNumber())
    this.hasNextRace(other.hasNextRace())
    this.hasPreviousRace(other.hasPreviousRace())
    this.trackCondition(other.trackCondition())
    this.weatherAtLocation(other.weatherAtLocation())
    this.selectedRace.merge(other.selectedRace)
    this.races(other.races())
    this.availablePools(other.availablePools())
    this.mergeDone(true)
  }

  public meetingId: ko.Observable<string>
  public meetingCode: ko.Observable<string>
  public meetingName: ko.Observable<string>
  public meetingDate: ko.Observable<Date>
  public nextRaceNumber: ko.Observable<number>
  public previousRaceNumber: ko.Observable<number>
  public hasNextRace: ko.Observable<boolean>
  public hasPreviousRace: ko.Observable<boolean>
  public trackCondition: ko.Observable<string>
  public weatherAtLocation: ko.Observable<string>
  public selectedRace: ObservableRace
  public races: ko.ObservableArray<ObservableRace>
  public mergeDone: ko.Observable<boolean>
  public availablePools: ko.ObservableArray<ObservablePool>

  public getRaceForGiddyUp(raceNumber: number) {
    const race = this.races().filter(r => r.raceNumber() === raceNumber)[0]

    if (!race) {
      return null
    }

    const weatherClass = 'tticon-' + WeatherClassUtil.getClassForWeather(this.weatherAtLocation())

    return {
      raceCourse: this.meetingName(),
      raceNumber: race.raceNumber(),
      distance: race.distance(),
      trackCondition: this.trackCondition(),
      weatherClass: weatherClass,
      raceStatus: race.raceStatus(),
      raceTimeLocal: race.raceTime(),
    }
  }
}
