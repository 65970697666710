import React from 'react'
import styled, { CSSObject } from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'

export const WeatherIcon: React.FC<{ courseWeather: string }> = ({ courseWeather }) => {
  const weatherIconName = getWeatherIcon(courseWeather)

  if (!weatherIconName) return null

  return (
    <WrapperStyled data-testid='weather' className={weatherIconName}>
      <Icon size='1.4rem' name={weatherIconName} />
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const sunnyWeather = ['sunny', 'clear', 'good', 'fine']
const cloudyWeather = ['cloudy', 'cloud']
const partlyCloudyWeather = ['overcast', 'average']
const rainyWeather = [
  'rain',
  'rainy',
  'raining',
  'drizzle',
  'storm',
  'stormy',
  'awful',
  'bad',
  'showery',
  'showers',
]

const getWeatherIcon = (courseWeather: string): WeatherIconTypes | null => {
  const weather = courseWeather.toLowerCase()

  if (sunnyWeather.includes(weather)) return 'DuocolorSun'
  if (cloudyWeather.includes(weather)) return 'DuocolorCloud01'
  if (partlyCloudyWeather.includes(weather)) return 'DuocolorCloudSun02'
  if (rainyWeather.includes(weather)) return 'DuocolorCloudRaining05'

  return null
}

// ============
// Local Styles
// ============

const WrapperStyled = styled.span(
  {
    display: 'flex',
    alignItems: 'center',

    // Styles to add multiple colours to SVGs
    '> span svg': { fill: 'none' },
  },
  {
    '&.DuocolorSun': {
      '> span svg': { color: colors.warning[500] },
    },

    '&.DuocolorCloudSun02': {
      transform: 'translateY(-0.1rem)',
      'svg > path:first-of-type': { color: colors.warning[500] },
    },

    '&.DuocolorCloudRaining05': {
      'svg > path:first-of-type': { color: colors.mariner[500] },
    },
  } as Partial<Record<`&.${WeatherIconTypes}`, CSSObject>>
)

// =====
// Types
// =====

type WeatherIconTypes = Extract<
  React.ComponentProps<typeof Icon>['name'],
  'DuocolorSun' | 'DuocolorCloud01' | 'DuocolorCloudSun02' | 'DuocolorCloudRaining05'
>
