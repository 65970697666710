import React from 'react'
import ReactDOM from 'react-dom'
import { BettingDrawerStyled, MAX_DRAWER_CONTENT_WIDTH } from './BettingDrawer.styles'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'

export const BettingDrawer: React.FC<{
  open: boolean
  handleQuickbetClick(): void
  handleAddToBetslipClick?(): void
  dataTId?: string
}> = ({ open, handleQuickbetClick, handleAddToBetslipClick, dataTId = 'forward-buttons' }) =>
  ReactDOM.createPortal(
    <BettingDrawerStyled
      open={open}
      {...{ [`data-tid-bet-${dataTId}`]: '' }}
      data-testid='betting-drawer'
    >
      <Grid margin='auto' maxWidth={MAX_DRAWER_CONTENT_WIDTH}>
        <GridRow>
          {handleAddToBetslipClick && (
            <GridCell padding='0.5rem' valign='middle'>
              <ButtonBlock
                onClick={handleAddToBetslipClick}
                data-tid-add-to-betslip={`bet-${dataTId}`}
                color='secondary'
              >
                Add to Betslip
              </ButtonBlock>
            </GridCell>
          )}

          <GridCell padding='0.5rem' valign='middle'>
            <ButtonBlock onClick={handleQuickbetClick} data-tid-place-single-btn={`bet-${dataTId}`}>
              Quickbet
            </ButtonBlock>
          </GridCell>
        </GridRow>
      </Grid>
    </BettingDrawerStyled>,
    document.body
  )
