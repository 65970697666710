import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { TransitionStatus } from 'react-transition-group'
import { layering } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { lessOrEqualThan } from '@mobi/component-library/Utils/media'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { ModalItem } from './types'

const enum LocalConstants {
  BezierUp = 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  BezierDown = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
}

export const ANIMATION_DURATION_MS = 400

export const ModalScreenCoverStyled = styled('div')({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  top: 0,
  bottom: 0,
  width: '100%',
  zIndex: layering.modalOverlay,
  // Prevent iPhone zooming when tapping buttons quickly
  touchAction: 'manipulation',
})

export const ModalPositionStyled = styled('div')<{ position: ModalItem['position'] }>(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '48rem',
    minHeight: '10rem',
    margin: '0 auto',
    backgroundColor: 'transparent',
    willChange: 'auto',
  },
  ({ position = 'bottom' }) => ({
    justifyContent: { bottom: 'flex-end', middle: 'center' }[position],
  })
)

export const ModalAnimationContainerStyled = styled('div')<{
  status: TransitionStatus
  exitAnimation?: ModalItem['exitAnimation']
}>(
  {
    position: 'relative',
    zIndex: 2,
    padding: '0 1.5rem 1.5rem',
    [lessOrEqualThan('px350')]: {
      padding: 0,
    },

    overflowY: 'auto',
    overscrollBehavior: 'contain',
  },
  ({ status, exitAnimation }) => {
    if (status === 'entering') {
      return {
        animation: `${moveUp} ${ANIMATION_DURATION_MS}ms ${LocalConstants.BezierUp} forwards`,
      }
    }

    if (status === 'exiting') {
      return {
        animation:
          exitAnimation && customAnimations.hasOwnProperty(exitAnimation)
            ? customAnimations[exitAnimation]()
            : `${moveDown} ${ANIMATION_DURATION_MS}ms ${LocalConstants.BezierDown} forwards`,
      }
    }

    return undefined
  }
)

export const ModalContentContainerStyled = styled('div')<{ cornerStyle: ModalItem['cornerStyle'] }>(
  {
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: hexColors.white,
  },
  ({ cornerStyle = 'square' }) =>
    cornerStyle === 'round' && {
      maskImage: 'radial-gradient(white, black)',
      borderRadius: '1rem',
    }
)

export const ModalCloseButtonStyled = styled('button')<Pick<ModalItem, 'color'>>(
  {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '5.5rem',
    height: '4rem',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    fontSize: '1.6rem',
  },
  ({ color = 'light' }) => ({
    color: color === 'light' ? hexColors.black : hexColors.white,
  })
)

export const ModalBackgroundStyled = styled('div')<{ status: TransitionStatus }>(
  {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    width: '100%',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    background: 'rgba(0, 0, 0, 0.7)',
  },
  ({ status }) => {
    if (status === 'exiting') {
      return {
        animation: `${fadeOut} ${ANIMATION_DURATION_MS}ms ${LocalConstants.BezierDown} forwards`,
      }
    } else {
      return {
        animation: `${fadeIn} ${ANIMATION_DURATION_MS}ms ${LocalConstants.BezierUp} forwards`,
      }
    }
  }
)

const moveUp = keyframes({ from: { transform: 'translateY(100%)' } })
const moveDown = keyframes({ to: { transform: 'translateY(100%)' } })
const fadeIn = keyframes({ to: { opacity: 1 } })
const fadeOut = keyframes({ from: { opacity: 1 } })

// =================
// Animation Helpers
// =================

const customAnimations = {
  addToBetslip: () =>
    modalAnimation(
      document.getElementById(QUICKBET_MODAL_ID),
      document.querySelector('#betslipButton')
    ),
}

type ModalAnimationElement = HTMLElement | null
const modalAnimation = (source: ModalAnimationElement, target: ModalAnimationElement) => {
  if (!source || !target) return `${fadeOut} ${ANIMATION_DURATION_MS + 50}ms linear`

  const {
    left: sourceLeft,
    top: sourceTop,
    height: sourceHeight,
    width: sourceWidth,
  } = source.getBoundingClientRect()
  const {
    left: targetLeft,
    top: targetTop,
    height: targetHeight,
    width: targetWidth,
  } = target.getBoundingClientRect()

  const scale = targetHeight / sourceHeight
  const translateX = Math.floor(targetLeft - sourceLeft - sourceWidth / 2 + targetWidth / 2)
  const translateY = Math.floor(targetTop - sourceTop - sourceHeight / 2 + targetHeight)

  const translateToTarget = keyframes({
    to: {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      opacity: 0.5,
    },
  })

  return `${translateToTarget} ${ANIMATION_DURATION_MS + 50}ms linear`
}
