import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRaceFieldSummaryDataFromApi } from './api'

export const useRaceFieldSummaryData = ({
  racecourseSeq,
  selectionDate,
  raceNumber,
  isFormSummaryFeatureActive,
}: {
  racecourseSeq?: number
  selectionDate?: string
  raceNumber?: number
  isFormSummaryFeatureActive: boolean
}) =>
  useQuery(
    [queryKeys.racePageFieldSummary(racecourseSeq, selectionDate, raceNumber)],
    () => getRaceFieldSummaryDataFromApi(racecourseSeq, selectionDate, raceNumber),
    {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!racecourseSeq && !!selectionDate && !!raceNumber && isFormSummaryFeatureActive,
    }
  )
