import { attachDriver, createSignal, Signal } from 'rwwa-rx-state-machine'
import {
  StructuredSearchResult,
  StructuredSearchAggregations,
} from '@core/Data/StructuredSearch/StructuredSearchResult'
import { StateMap } from 'typings/immutable'
import { fromJS } from 'immutable'
import { RangeBoundaries } from '../RangeBoundaries'
import { filterSelectedTracksTrackIds } from '../Components/StructuredSearchLocation/track-utils'

export type StructuredSearchValueChangedType = string | boolean | Date | number | SelectedTrackType

export const StructuredSearchValueChanged = createSignal<{
  field: keyof StructuredSearchFields
  value: StructuredSearchValueChangedType
}>('StructuredSearchValueChanged')

export const StructuredSearchResults =
  createSignal<IStructuredSearchResults>('StructuredSearchResults')

export const StructuredSearchSetState = createSignal<StructuredSearchFields>(
  'StructuredSearchSetState'
)

export const StructuredSearchSetSelectedTracks = createSignal<SelectedTrackType>(
  'StructuredSearchSetSelectedTracks'
)

export type SelectedTrackType = string[] | null

export interface StructuredSearchFields {
  contestTypeRace: boolean
  contestTypeTrot: boolean
  contestTypeDogs: boolean
  dateToday: boolean
  dateTomorrow: boolean
  dateBeyondTomorrow: boolean
  wonLastStart: boolean
  placedLastStart: boolean
  gearChange: boolean
  firstUp: boolean
  secondUp: boolean
  careerPrizeMoney: string
  careerWinPercentage: number
  trackWinPercentage: number
  distanceWinPercentage: number
  trackdistanceWinPercentage: number
  barrierNumberOneToFive: boolean
  barrierNumberSixPlus: boolean
  boxNumberOneToFour: boolean
  boxNumberFivePlus: boolean
  apprentice: boolean
  favouriteLastStart: boolean
  weightChangedUp: boolean
  weightChangedDown: boolean
  weightNotChanged: boolean
  distanceChangedUp: boolean
  distanceChangedDown: boolean
  distanceNotChanged: boolean
  minFixedOddsPrice: number
  maxFixedOddsPrice: number
  minDaysSinceLastStart: number
  maxDaysSinceLastStart: number
  minDaysSinceLastWin: number
  maxDaysSinceLastWin: number
  minStartsSinceLastSpell: number
  maxStartsSinceLastSpell: number
  winTrackConditionFirm: boolean
  winTrackConditionGood: boolean
  winTrackConditionHeavy: boolean
  winTrackConditionSoft: boolean
  winTrackConditionSynthetic: boolean
  locationCountryAustralia: boolean
  locationCountryNewZealand: boolean
  locationCountryInternational: boolean
  selectedTracks: SelectedTrackType
}

export interface IStructuredSearchResults {
  results: StructuredSearchResult[]
  totalResultCount: number
  aggregations: StructuredSearchAggregations | null
}

export interface StructuredSearchState {
  searchCriteria: StructuredSearchFields
  searchResults: IStructuredSearchResults
}

export type StructuredSearchStateMap = StateMap<StructuredSearchState>

export const defaultStructuredSearchFieldState: Readonly<StructuredSearchFields> = {
  contestTypeRace: false,
  contestTypeTrot: false,
  contestTypeDogs: false,
  dateToday: false,
  dateTomorrow: false,
  dateBeyondTomorrow: false,
  wonLastStart: false,
  placedLastStart: false,
  gearChange: false,
  firstUp: false,
  secondUp: false,
  careerPrizeMoney: '',
  careerWinPercentage: RangeBoundaries.minPercentage,
  trackWinPercentage: RangeBoundaries.minPercentage,
  distanceWinPercentage: RangeBoundaries.minPercentage,
  trackdistanceWinPercentage: RangeBoundaries.minPercentage,
  barrierNumberOneToFive: false,
  barrierNumberSixPlus: false,
  boxNumberOneToFour: false,
  boxNumberFivePlus: false,
  apprentice: false,
  favouriteLastStart: false,
  weightChangedUp: false,
  weightChangedDown: false,
  weightNotChanged: false,
  distanceChangedUp: false,
  distanceChangedDown: false,
  distanceNotChanged: false,
  winTrackConditionFirm: false,
  winTrackConditionGood: false,
  winTrackConditionHeavy: false,
  winTrackConditionSoft: false,
  winTrackConditionSynthetic: false,
  locationCountryAustralia: false,
  locationCountryNewZealand: false,
  locationCountryInternational: false,
  selectedTracks: null,
  ...RangeBoundaries,
}

const defaultStructuredSearchResultsState: Readonly<IStructuredSearchResults> = {
  results: [],
  totalResultCount: 0,
  aggregations: null,
}

export const defaultState: Readonly<StructuredSearchState> = {
  searchCriteria: {
    ...defaultStructuredSearchFieldState,
  },
  searchResults: {
    ...defaultStructuredSearchResultsState,
  },
}

export function structuredSearchDriver(
  state = fromJS(defaultState),
  signal: Signal
): StructuredSearchStateMap {
  switch (signal.tag) {
    case StructuredSearchValueChanged: {
      const { field, value } = signal.data
      return state.setIn(['searchCriteria', field], value)
    }
    case StructuredSearchResults: {
      const { results, totalResultCount, aggregations } = signal.data as IStructuredSearchResults

      let searchResult = state
        .setIn(['searchResults', 'totalResultCount'], fromJS(totalResultCount))
        .setIn(['searchResults', 'results'], fromJS(results))

      if (aggregations) {
        let selectedTracks: SelectedTrackType = state.getIn(['searchCriteria', 'selectedTracks'])
        if (aggregations.tracks) {
          const tracks = aggregations.tracks
          selectedTracks = filterSelectedTracksTrackIds(tracks, selectedTracks)
        }
        searchResult = searchResult
          .setIn(['searchResults', 'aggregations'], fromJS(aggregations))
          .setIn(['searchCriteria', 'selectedTracks'], fromJS(selectedTracks))
      }
      return searchResult
    }
    case StructuredSearchSetState: {
      const searchCriteria = signal.data as StructuredSearchFields
      return state.set('searchCriteria', fromJS(searchCriteria))
    }
    case StructuredSearchSetSelectedTracks: {
      return state.setIn(['searchCriteria', 'selectedTracks'], fromJS(signal.data))
    }
    default:
      return state
  }
}

export const state$ = attachDriver<StructuredSearchStateMap>({
  driver: structuredSearchDriver,
  path: 'structured-search',
})

export const observableSearchCriteria = (): Rx.Observable<StructuredSearchFields> => {
  return state$
    .map<StructuredSearchFields>(x => {
      return x.toJS().searchCriteria
    })
    .debounce(500)
    .distinctUntilChanged()
}
