import React from 'react'
import { SilkImageStyled, SilkImageProps } from './SilkImage.styles'

export function SilkImage({
  width,
  height,
  url,
  xOffset,
  isDogsSilk,
  baseWidth,
  breakpoints,
}: SilkImageProps) {
  return (
    <SilkImageStyled
      width={width}
      height={height}
      url={url}
      xOffset={xOffset}
      baseWidth={baseWidth}
      isDogsSilk={isDogsSilk}
      breakpoints={breakpoints}
    />
  )
}
