import { z } from 'zod'
import { addressSchema } from './primaryAccountHolderDetails'

/**
 * Schema for endpoint:
 * POST /api/bet-account/verify
 */

export const verifyRequestSchema = z.object({
  accountNumber: z.number(),
  driversLicenceDetails: z
    .object({
      number: z.string(),
      cardNumber: z.string(),
      state: z.string(),
    })
    .optional(),
  residentialAddressDetails: addressSchema.optional(),
  previousResidentialAddressDetails: addressSchema.optional(),
  nameAndBirthDateDetails: z
    .object({
      birthDate: z.string(),
      firstName: z.string(),
      middleName: z.string().optional(),
      surname: z.string(),
    })
    .optional(),
  passportDetails: z
    .object({
      number: z.string(),
      passportCountry: z.string(),
    })
    .optional(),
  medicareDetails: z
    .object({
      number: z.string().length(10),
      reference: z.string().length(1),
      middleNameOnCard: z.string().nullable(),
      cardColour: z.string(),
      expiryDay: z.number().nullable(),
      expiryMonth: z.number(),
      expiryYear: z.number(),
    })
    .optional(),
})

export type VerifyRequest = z.infer<typeof verifyRequestSchema>

export const verifyResponseSchema = z
  .object({
    accountNumber: z.number(),
    canNameOrDOBChange: z.boolean(),
    isIDVerified: z.boolean(),
    isNameVerified: z.boolean(),
    isAddressVerified: z.boolean(),
    isDOBVerified: z.boolean(),
    remainingAttempts: z.number(),
    addressChangeCount: z.number(),
  })
  .readonly()

export type VerifyResponse = z.infer<typeof verifyResponseSchema>
