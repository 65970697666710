import React from 'react'

import { KeypadWrapperStyled, KeypadItemStyled } from './Keypad.styles'
import { KeypadButton } from './KeypadButton'
import { KeypadModes, SpecialKeys, KeyPressed, Keypress } from './KeyPress'

interface KeypadKeyDefinition {
  label: string
  ariaLabel?: string
  value: KeyPressed['value']
  isDisabled?: boolean
  isHidden?: boolean
}

function getNumericValues(
  allowDecimal: boolean,
  isIncrementAvailable: boolean,
  isDisabled: boolean
): KeypadKeyDefinition[] {
  return [
    { label: '1', value: 1, isDisabled },
    { label: '2', value: 2, isDisabled },
    { label: '3', value: 3, isDisabled },
    { label: '4', value: 4, isDisabled },
    { label: '5', value: 5, isDisabled },
    {
      label: allowDecimal && isIncrementAvailable ? '.' : '\u0020',
      value: allowDecimal && isIncrementAvailable ? SpecialKeys.Decimal : SpecialKeys.Blank,
      isDisabled: allowDecimal && isIncrementAvailable ? isDisabled : true,
    }, // blank square
    { label: '6', value: 6, isDisabled },
    { label: '7', value: 7, isDisabled },
    { label: '8', value: 8, isDisabled },
    { label: '9', value: 9, isDisabled },
    { label: '0', value: 0, isDisabled },
    { label: '\u232B', ariaLabel: 'Backspace', value: SpecialKeys.Backspace, isDisabled }, // backspace symbol
  ]
}

function getDenominationValues(
  allowDecimal: boolean,
  showHighStake: boolean,
  isDisabled: boolean
): KeypadKeyDefinition[] {
  return [
    { label: '50c', value: 0.5, isHidden: !allowDecimal, isDisabled },
    { label: '$1', value: 1, isHidden: allowDecimal && showHighStake, isDisabled },
    { label: '$2', value: 2, isDisabled },
    { label: '$5', value: 5, isDisabled },
    { label: '$10', value: 10, isDisabled },
    { label: '$20', value: 20, isDisabled },
    { label: '$50', value: 50, isDisabled },
    { label: '$100', value: 100, isHidden: allowDecimal && !showHighStake, isDisabled },
    { label: 'Clear', value: SpecialKeys.Clear, isDisabled },
  ]
}

const KeypadItem = ({ setting, keysPerRow }: { setting: KeypadKeyProps; keysPerRow: number }) => (
  <KeypadItemStyled keysPerRow={keysPerRow}>
    <KeypadButton
      clickHandler={setting.onClick}
      disabled={setting.disabled}
      label={setting.label}
      ariaLabel={setting.ariaLabel || ''}
    />
  </KeypadItemStyled>
)

interface KeypadProps {
  keypadMode?: KeypadModes
  allowDecimals?: boolean
  rowCount?: number
  disabled?: boolean
  horizontalPadding?: string
  showHighStake?: boolean
  isIncrementAvailable?: boolean
}

interface KeypadKeyProps {
  key: number
  label: string
  ariaLabel: string | null
  disabled: boolean
  hidden: boolean
  onClick: () => void
}

export function Keypad({
  keypadMode = KeypadModes.Denomination,
  allowDecimals = false,
  rowCount = 2,
  disabled = false,
  horizontalPadding,
  showHighStake = true,
  isIncrementAvailable = false,
}: KeypadProps) {
  const keypadValues: KeypadKeyDefinition[] =
    keypadMode === KeypadModes.Numeric
      ? getNumericValues(allowDecimals, isIncrementAvailable, disabled)
      : getDenominationValues(allowDecimals, showHighStake, disabled)

  const keypadSettings: KeypadKeyProps[] = keypadValues
    .map((entry, index) => ({
      key: index,
      label: entry.label,
      ariaLabel: entry.ariaLabel ? entry.ariaLabel : null,
      disabled: !!entry.isDisabled,
      hidden: !!entry.isHidden,
      onClick: () => Keypress({ mode: keypadMode, value: entry.value }),
    }))
    .filter(setting => !setting.hidden)

  const keysPerRow = Math.ceil(keypadSettings.length / rowCount)
  const testId = { 'data-tid-keypad': keypadMode }

  return (
    <KeypadWrapperStyled horizontalPadding={horizontalPadding} {...testId}>
      {keypadSettings.map(setting => (
        <KeypadItem key={setting.key} keysPerRow={keysPerRow} setting={setting} />
      ))}
    </KeypadWrapperStyled>
  )
}
