import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { WithdrawContainerStyled } from './Withdraw.styles'
import { navigateToAccount } from './helpers/navigaton'
import { trackWithdrawalError } from './analytics'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2/'

export const WithdrawUnavailable = (): JSX.Element => {
  React.useEffect(() => {
    trackWithdrawalError('Withdraw Not Available')
  }, [])

  return (
    <MainContainer data-tid-withdraw-unavailable='' forceSizing={false}>
      <ContentHeader title='Withdrawal' />
      <WithdrawContainerStyled>
        <NoticeBoxSingle
          title={
            <>
              Withdrawals are currently unavailable. Please try again later or contact Customer
              Service on <CustomerServiceTelephone />
            </>
          }
          noticeBoxType={NoticeBoxTypes.Warning}
        />
        <ButtonBlock onClick={navigateToAccount} color='primary' data-tid-back-to-account-btn=''>
          Back to Account
        </ButtonBlock>
      </WithdrawContainerStyled>
    </MainContainer>
  )
}
