import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { Keypad } from './Components/Keypad'
import { ButtonsSection } from './Components/ButtonsSection'
import { SummarySection } from './Components/SummarySection'

export const Footer: React.FC = () => {
  const activeInvestment = useSelector(selectActiveInvestment)

  return (
    <WrapperStyled>
      {activeInvestment ? <Keypad /> : <SummarySection />}

      <ButtonsSection />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: spacing.md,
  paddingBottom: spacing.lg,
  borderTopLeftRadius: radius.lgx4,
  borderTopRightRadius: radius.lgx4,
  backgroundColor: colors.lavender[900],
})
