import styled from '@emotion/styled'
import { InputBoxStyled } from '@mobi/component-library/Common/Input'
import { Heading } from '@core/Components/Text/Heading'
import { hexColors } from '@mobi/settings/src/hexColors'

export const HeaderStyled = styled(Heading)(
  {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
  },
  ({ level }) => ({
    level: level || 3,
  })
)

export const InfoHeaderStyled = styled(HeaderStyled)(
  {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    svg: {
      margin: 'auto',
    },
  },
  () => ({
    level: 3,
  })
)

export const BoldedInputBoxStyled = styled(InputBoxStyled)({
  background: hexColors.gainsboro,
  fontWeight: 'bold',
  fontSize: '1.5rem',
})

export const ParagraphStyled = styled('p')({
  textAlign: 'left',
  fontSize: '1.2rem',
  lineHeight: '1.5em',
})

export const FieldHeaderStyled = styled('div')({
  color: hexColors.dimGrey,
  fontSize: '1.2rem',
})

export const ReadOnlyFieldStyled = styled('div')({
  fontSize: '1.8rem',
  paddingBottom: '0.75rem',
  borderBottom: '1px solid ' + hexColors.veryLightGrey,
  marginTop: '0.5rem',
  marginBottom: '0.75rem',
})

export const PaddingStyled = styled('div')({
  padding: '1rem',
})

export const CreditCardBlockStyled = styled('div')({
  margin: '1em',
})

export const NoticeBoxStyled = styled('div')({
  margin: '2em 0 1em 0',
})
