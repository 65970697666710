import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const trackWithdrawalSuccess = (amount: number) =>
  trackEvent('withdrawal_success', { amount })

export const trackWithdrawalError = (message?: string) =>
  trackEvent('withdrawal_error', { message })

export const trackWithdrawalCancel = (hoursDiff: number) =>
  trackEvent('withdrawal_cancel', { hoursDiff })
