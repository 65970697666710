import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { RacePageDataTransferObject } from '@mobi/api-types/src/racePage/types'
import { AppRoutes } from '@core/AppRoutes'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'
import { MORE_BETTING_OPTIONS_ID } from '@core/Areas/RaceCard/constants'
import { CHANGE_BET_TYPE_EVENT } from '@core/Areas/RaceCard/constants'
import { useAppSelector } from '@core/Store/hooks'
import { getCurrentBetType } from '../../Store/selectors'
import { getAvailableBetTypesFromData } from './helpers/getAvailableBetTypesFromData'
import { trackBetTypeChange } from './helpers/analytics'

const enum LocalConstants {
  BetTypeActiveClassname = 'js-bet-type-selector-btn--active',
  SkyRaceCardClassname = 'js-bet-type-selector--sky-race',
  ActiveIndicatorWidthPx = 1000,
}

export const BetTypeSelector: React.FC<{
  raceData: RacePageDataTransferObject
}> = ({ raceData }) => {
  const scrollContainerRef = React.useRef<HTMLUListElement>(null)
  const activeIndicatorRef = React.useRef<HTMLSpanElement>(null)
  const timerRef = React.useRef<number>()

  const selectedBetType = useAppSelector(getCurrentBetType)

  const eventAggregator = useEventAggregator()
  const availableBetTypes = getAvailableBetTypesFromData(raceData)

  const createBetTypeClickHandler =
    ({ betType, name }: BetTypeObject) =>
    () => {
      if (betType === selectedBetType) return
      eventAggregator.publish(CHANGE_BET_TYPE_EVENT, { betType })
      trackBetTypeChange({ betType, productName: name })
    }

  const onMoreOptionsClick = () => {
    trackBetTypeChange({ betType: BetType.WinPlace, productName: 'More Betting Options' })
    timerRef.current && clearTimeout(timerRef.current)
    if (selectedBetType === BetType.WinPlace) {
      const moreBettingOptionsEl = document.getElementById(MORE_BETTING_OPTIONS_ID)
      moreBettingOptionsEl?.scrollIntoView({ behavior: 'smooth' })
    } else {
      const domObserver = new MutationObserver((_, observer) => {
        const moreBettingOptionsEl = document.getElementById(MORE_BETTING_OPTIONS_ID)
        if (!moreBettingOptionsEl) return
        timerRef.current = window.setTimeout(() => {
          moreBettingOptionsEl.scrollIntoView({ behavior: 'smooth' })
        }, 1_000)
        observer.disconnect()
      })
      domObserver.observe(document.body, { childList: true, subtree: true })
      eventAggregator.publish(CHANGE_BET_TYPE_EVENT, { betType: BetType.WinPlace })
    }
  }

  React.useEffect(() => {
    if (!activeIndicatorRef.current) return
    activeIndicatorRef.current.style.transition = 'transform 0.2s'
  }, [])

  React.useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [])

  const isOnSkyRacePage = window.location.hash === AppRoutes.RaceCardSky.replace('/', '#')

  return (
    <WrapperStyled
      data-js-race-card-no-swipe
      data-testid='BetTypeSelector'
      className={isOnSkyRacePage ? (LocalConstants.SkyRaceCardClassname as string) : ''}
    >
      <ul ref={scrollContainerRef}>
        {availableBetTypes.map(betType => (
          <BetTypeSelectorButton
            key={betType.betType}
            name={betType.name}
            hasJackpot={betType.hasJackpot}
            activeIndicatorRef={activeIndicatorRef}
            scrollContainerRef={scrollContainerRef}
            isSelected={betType.betType === selectedBetType}
            handleClick={createBetTypeClickHandler(betType)}
          />
        ))}

        <BetTypeSelectorButton
          name='More Betting Options'
          isSelected={false}
          hasJackpot={false}
          activeIndicatorRef={activeIndicatorRef}
          scrollContainerRef={scrollContainerRef}
          handleClick={onMoreOptionsClick}
        />

        <span aria-hidden ref={activeIndicatorRef} />
      </ul>
    </WrapperStyled>
  )
}

// ===============
// Local Component
// ===============

const BetTypeSelectorButton: React.FC<
  Omit<BetTypeObject, 'betType' | 'isAbandoned'> & {
    isSelected: boolean
    handleClick: () => void
    activeIndicatorRef: React.RefObject<HTMLSpanElement>
    scrollContainerRef: React.RefObject<HTMLUListElement>
  }
> = ({ isSelected, name, handleClick, hasJackpot, activeIndicatorRef, scrollContainerRef }) => {
  const buttonContainerRef = React.useRef<HTMLLIElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const repositionActiveIndicator = React.useCallback(() => {
    if (!buttonRef.current || !activeIndicatorRef.current) return
    const percentageDiff = buttonRef.current.offsetWidth / LocalConstants.ActiveIndicatorWidthPx
    activeIndicatorRef.current.style.transform = `translateX(${buttonRef.current.offsetLeft}px) scaleX(${percentageDiff})`
  }, [activeIndicatorRef])

  React.useEffect(() => {
    if (!isSelected || !buttonContainerRef.current || !scrollContainerRef.current) {
      return
    }
    repositionActiveIndicator()
    scrollContainerRef.current.scrollLeft =
      buttonContainerRef.current.offsetLeft - buttonContainerRef.current.offsetWidth
  }, [isSelected, repositionActiveIndicator, scrollContainerRef])

  React.useEffect(() => {
    if (!window.ResizeObserver || !isSelected) return
    const observer = new ResizeObserver(repositionActiveIndicator)
    observer.observe(document.body)
    return () => observer.disconnect()
  }, [isSelected, repositionActiveIndicator])

  return (
    <ListItemStyled ref={buttonContainerRef}>
      <button
        role='button'
        ref={buttonRef}
        onClick={handleClick}
        className={`${isSelected ? LocalConstants.BetTypeActiveClassname : ''}`}
        data-testid={`bet-type-option-${name.toLowerCase().replaceAll(' ', '-')}`}
      >
        <span>
          {hasJackpot && (
            <span data-testid='BetTypeSelector.Jackpot'>
              <Icon name='DuocolorCurrencyDollarCircle' color={colors.orange[500]} size='1.8rem' />
            </span>
          )}
          {name}
        </span>

        {/* Duplicate of above used to prevent font weight jump on active transition */}
        <span aria-hidden>
          {hasJackpot && (
            <span data-testid='BetTypeSelector.Jackpot'>
              <Icon name='DuocolorCurrencyDollarCircle' color={colors.orange[500]} size='1.8rem' />
            </span>
          )}
          {name}
        </span>
      </button>
    </ListItemStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  overflow: 'hidden',

  '> ul': {
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    borderSpacing: 0,
    overflowX: 'auto',
    overscrollBehavior: 'contain',
    width: '100%',
    minHeight: '4.8rem',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    background: colors.white,
    borderBottom: `0.05rem solid ${colors.neutral[200]}`,

    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
    WebkitTapHighlightColor: 'transparent',

    scrollbarWidth: 'thin',
    scrollbarColor: colors.neutral[300] + ' ' + colors.surface[50],

    '@media (pointer: coarse)': {
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': { display: 'none' },
    },
  },

  // Active Indicator
  '> ul > span': {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: LocalConstants.ActiveIndicatorWidthPx + 'px',
    height: '0.2rem',
    backgroundColor: colors.studio[500],

    transform: 'scaleX(0)',
    transformOrigin: 'left',
  },
})

const ListItemStyled = styled.li({
  paddingLeft: spacing.smx1,
  paddingRight: spacing.smx1,

  '&:first-of-type': { marginLeft: spacing.smx1 },
  '&:last-of-type': { marginRight: spacing.smx1 },

  [`.${LocalConstants.SkyRaceCardClassname} &:first-of-type`]: {
    marginLeft: 0,
  },

  '> button': {
    flexShrink: 0,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.smx2,
    minHeight: '4.8rem',
    padding: 0,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    border: 0,
    backgroundColor: 'transparent',

    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,
    letterSpacing: font.size.md.letterSpacing,
    fontWeight: font.weight.regular,
    color: colors.neutral[800],

    '> span:first-of-type': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',

      transition: 'color 0.3s',
    },

    [`&.${LocalConstants.BetTypeActiveClassname} > span:first-of-type`]: {
      color: colors.black,
      fontWeight: font.weight.medium,
    },

    '> span > span': {
      fontSize: 0,
      lineHeight: 1,
      paddingRight: spacing.smx2,
    },

    // Used to prevent font weight jump on active state
    '> span:last-of-type': {
      visibility: 'hidden',
      fontWeight: font.weight.medium,
    },
  },
})

// =====
// Types
// =====

type BetTypeObject = ReturnType<typeof getAvailableBetTypesFromData>[0]
