import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { GridCell } from '@mobi/component-library/Common/Grid'
import * as TabTouch from '@classic/Foundation/TabTouch'
import { fetchFromApi } from '@classic/Foundation/Services/ApiService'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { SpecialsErrorNoticeBoxStyled } from './SpecialsPage'

export const SpecialsTermsPage = (): JSX.Element => {
  const fetchSpecialsTerms = () =>
    fetchFromApi('/cms/specials-terms').then(response => response.text())
  const { isLoading, isError, data } = useQuery<string>({
    queryKey: queryKeys.specialsTerms,
    queryFn: fetchSpecialsTerms,
    retry: false,
  })

  useEffect(() => {
    // override the 'default' back navigation as set by ReactRouterHandler
    setTimeout(() => TabTouch.initCommon('#specials'), 150)
  }, [])

  if (isLoading) {
    return (
      <GridCell padding='0 1rem'>
        <LoadingPlaceholder width='100%' height='2rem' />
        <LoadingPlaceholder width='100%' height='2rem' />
        <LoadingPlaceholder width='100%' height='2rem' />
      </GridCell>
    )
  }

  if (isError) {
    return (
      <SpecialsErrorNoticeBoxStyled>
        <NoticeBox
          title='Unable to display Superpick terms and conditions at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      </SpecialsErrorNoticeBoxStyled>
    )
  }

  return <div dangerouslySetInnerHTML={{ __html: data || '' }}></div>
}
