import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'

interface ButtonGroupButtonProps {
  selected: boolean
  disabled?: boolean
}

export const ButtonGroupStyled = styled('div')({
  display: 'flex',
  '& > button:not(:last-of-type)': {
    marginRight: '0.25rem',
  },
  '& > button:first-of-type': {
    borderRadius: '0.5rem 0 0 0.5rem',
  },
  '& > button:last-of-type': {
    borderRadius: '0px 0.5rem 0.5rem 0',
  },
})

export const ButtonGroupItemStyled = styled(BaseButtonStyled)<ButtonGroupButtonProps>(
  {
    flex: '1',
    height: '4rem',
  },

  ({ selected, disabled }) => ({
    background: selected && !disabled ? hexColors.charCoal : hexColors.white,
    color: selected && !disabled ? hexColors.white : hexColors.grey,
    border:
      selected && !disabled ? `1px solid transparent` : `1px solid ${hexColors.veryLightGrey}`,
  }),

  ({ disabled }) =>
    disabled && {
      opacity: 0.3,
    }
)
