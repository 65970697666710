import React from 'react'
import styled from '@emotion/styled'
import { colors, hexColors } from '@mobi/settings'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { H3 } from '@core/Components/Text/Heading.styles'

export const ShareLoading = () => {
  return (
    <Wrapper>
      <H3>Creating your Share</H3>
      <div aria-busy>
        <SpinnerInlineStyled size={5} color='light' />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '10rem',
  background: colors.brand.primary,
  padding: '1rem',
  color: hexColors.white,

  h3: {
    margin: 0,
    textAlign: 'center',
    marginBottom: '0.5rem',
    color: hexColors.white,
    fontWeight: 'bold',
  },

  'div[aria-busy="true"]': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.5rem',
  },
})
