import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { dayjs } from '@mobi/utils'
import { useAppSelector } from '@core/Store/hooks'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { sortMeetings } from '@core/Areas/Meetings/helpers/sortMeetings'
import type { useMeetingsData } from '@core/Areas/Meetings/hooks'
import { DataError, Loading, MeetingRow } from './Components'

const enum LocalConstants {
  PushAustralianListDownClassName = 'meetings__push-aust-down',
}

export const MeetingsList: React.FC<Omit<ReturnType<typeof useMeetingsData>, 'handlers'>> = ({
  data,
  state,
}) => {
  const newFavRef = React.useRef<NonNullable<Meetings>[0]>()
  const favouriteMeetings = useAppSelector(state => state.meetings.favourites)

  if (data.isError) return <DataError refetch={data.refetch} />
  if (data.isLoading) return <Loading />

  const { AustralianMeetings, InternationalMeetings } = sortMeetings(
    getMeetingsForCode(state.raceCode, data.meetings)
  )

  const favouriteList = favouriteMeetings.length
    ? AustralianMeetings.concat(InternationalMeetings)
        .filter(meeting => favouriteMeetings.includes(meeting.name))
        .sort((a, b) => a.name.localeCompare(b.name))
    : []

  const australianList = AustralianMeetings.filter(
    meeting => !favouriteList.includes(meeting)
  ).sort(sortOpenToTop)
  const internationalList = InternationalMeetings.filter(
    meeting => !favouriteList.includes(meeting)
  ).sort(sortOpenToTop)

  const shouldReverseAustAndIntOrder =
    dayjs(state.currentDate).isSameOrAfter(dayjs().format('YYYY/MM/DD')) &&
    australianList.every(meet => !meet.nextRaceNumber) &&
    internationalList.some(meet => !!meet.nextRaceNumber)

  return (
    <MeetingsListWrapperStyled>
      {!AustralianMeetings.length && !InternationalMeetings.length ? (
        <strong data-testid='meetings-list-empty'>
          <span>No Meetings Available</span>
        </strong>
      ) : (
        <>
          {!!favouriteList.length && (
            <div>
              <h3 data-testid='meetings-header-fav'>Favourites</h3>
              {favouriteList.map(meeting => (
                <MeetingRow meeting={meeting} key={meeting.id} newFavRef={newFavRef} isFavourite />
              ))}
            </div>
          )}

          {!!australianList.length && (
            <div
              className={
                shouldReverseAustAndIntOrder ? LocalConstants.PushAustralianListDownClassName : ''
              }
            >
              <h3 data-testid='meetings-header-au'>
                Australian {getRaceCodeLabel(state.raceCode)} Racing
              </h3>
              {australianList.map(meeting => (
                <MeetingRow meeting={meeting} key={meeting.id} newFavRef={newFavRef} />
              ))}
            </div>
          )}

          {!!internationalList.length && (
            <div>
              <h3 data-testid='meetings-header-int'>
                International {getRaceCodeLabel(state.raceCode)} Racing
              </h3>
              {internationalList.map(meeting => (
                <MeetingRow meeting={meeting} key={meeting.id} newFavRef={newFavRef} />
              ))}
            </div>
          )}
        </>
      )}
    </MeetingsListWrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getMeetingsForCode(
  code: RaceCodesLowerCase,
  allMeetings?: Meetings
): NonNullable<Meetings>[0][] {
  if (!allMeetings) return []
  return Object.values(allMeetings).filter(meeting => meeting.code.toLowerCase() === code)
}

const getRaceCodeLabel = (code: RaceCodesLowerCase): string => {
  switch (code) {
    case 'dogs':
      return 'Greyhound'
    case 'races':
      return 'Horse'
    case 'trots':
      return 'Harness'
  }
}

function sortOpenToTop(a: Meeting, b: Meeting): -1 | 0 | 1 {
  if (a.status === 'Open' && b.status === 'Open') return 0
  if (a.status === 'Open') return -1
  if (b.status === 'Open') return 1
  return 0
}

// ======
// Styles
// ======

const MeetingsListWrapperStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '5rem',
  marginTop: '-0.5rem',

  '& > strong': {
    padding: '2rem 0',
    textAlign: 'center',
    fontSize: '1.2rem',
  },

  h3: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    margin: 0,
    marginTop: '1rem',
    padding: '0 1rem',
    lineHeight: 1,
    fontWeight: 'normal',
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    background: 'none',
    color: hexColors.nero,
  },

  [`& > div.${LocalConstants.PushAustralianListDownClassName}`]: {
    order: 5, // NOTE: if more items are added this number will affect layout
  },
})

// =====
// Types
// =====

type Meetings = ReturnType<typeof useMeetingsData>['data']['meetings']
type Meeting = NonNullable<Meetings>[0]
