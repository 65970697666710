import { dayjs } from '.'
import moment from 'moment'

type DateFormat = 'DD MMM YYYY' | 'YYYYMMDD' | (string & {})

export type Month = {
  number: number
  name: string
}

export const ONE_MINUTE = 60_000

export function formatDate(date: Date | null | undefined, format: DateFormat) {
  return date != null ? moment(date).format(format) : 'Invalid Date'
}

export function toISODate(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD')
}

export function formatDateStringWithoutChangingTimezone(
  dateString: string,
  format: 'MM/DD/YYYY' | 'YYYY-MM-DD'
): string {
  return moment.parseZone(dateString).format(format)
}

const birthDateFormat = 'DD/MM/YYYY'

export function parseBirthDate(birthDateString: string) {
  return dayjs(birthDateString, birthDateFormat).toDate()
}

export function formatBirthDate(birthDate: Date) {
  return dayjs(birthDate).format(birthDateFormat)
}

export const months: Month[] = [
  { number: 1, name: 'January' },
  { number: 2, name: 'February' },
  { number: 3, name: 'March' },
  { number: 4, name: 'April' },
  { number: 5, name: 'May' },
  { number: 6, name: 'June' },
  { number: 7, name: 'July' },
  { number: 8, name: 'August' },
  { number: 9, name: 'September' },
  { number: 10, name: 'October' },
  { number: 11, name: 'November' },
  { number: 12, name: 'December' },
]

export const daysInMonth = (year: number, month: number) => {
  if (!month || month < 1 || month > 12) {
    return 31
  }
  const currentYear = new Date().getFullYear()

  if (!year || year < currentYear) {
    year = currentYear
  }

  //day 0 gives us number of days in previous month
  return new Date(year, month, 0).getDate()
}
