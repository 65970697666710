import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import type { RequestType } from '@mobi/betslip/types'
import { buildBetSlipRequest } from '@mobi/betslip/helpers/requestBuilder'
import {
  betslipPropose as propose,
  betslipCommit as commit,
  betslipRefresh as refresh,
} from '@core/Data/betting'

export async function postAllBetsToServer(queryType: RequestType, state: BetSlipBetsState) {
  const request = buildBetSlipRequest(queryType, state)

  if (queryType === 'propose') {
    const response = await propose(request)
    return response
  }

  if (queryType === 'confirm') {
    const response = await commit(request)
    return response
  }

  const response = await refresh(request)
  return response
}
