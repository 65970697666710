import React from 'react'
import { ToastContent } from '@mobi/component-library/Feedback/Toast'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { hasBeenPlaced } from '@mobi/betslip/helpers/state'

export const BetsPlacedSuccessToast: React.FC = () => {
  const items = useAppSelector(state => state.betslip.bets.items)
  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)

  let placedBetsCount = items.filter(hasBeenPlaced).length
  if (multiReceipt) ++placedBetsCount

  return (
    <ToastContent
      title={`${placedBetsCount} Bet${placedBetsCount === 1 ? '' : 's'} successfully placed`}
      icon='success'
    />
  )
}
