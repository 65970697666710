import jQuery from 'jquery'

export function bindLiveEvent(
  eventType: string,
  elementQuerySelector: string,
  cb: (e: Event) => void
) {
  document.addEventListener(eventType, event => {
    const qs = document.querySelectorAll(elementQuerySelector)

    if (!qs) return

    let el = event.target
    let index = -1
    while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
      el = (el as HTMLElement).parentElement
    }

    if (index > -1) {
      return cb.call(el, event)
    }
  })
}

export function showPopup(popupDiv: JQuery) {
  //delay 50 milliseconds to allow background layout completed
  setTimeout(() => {
    jQuery('div .popup').hide()
    const documentHeight = jQuery(document).height()
    popupDiv.css({ height: documentHeight }).fadeIn('fast')
    popupDiv.children('.overlay').animate({ opacity: 0.6 }, 'fast')

    const scrollTop = window.pageYOffset
      ? window.pageYOffset
      : document.body.parentElement
        ? document.body.parentElement.scrollTop
        : 0

    const newHeight = popupDiv.children('.window').height() + scrollTop + 100
    if (newHeight > documentHeight) {
      popupDiv.children('.overlay').css({ height: newHeight })
    }
  }, 50)
}
