import { StructuredSearchResult } from '@core/Data/StructuredSearch/StructuredSearchResult'
import dayjs from 'dayjs'

export const ONE_HOUR = 3598_000 // Fudge it less than an hour so it only shows 59m, not 1hr
export const ONE_DAY = 86_400_000

interface GetAstDetailsInterface {
  advertisedStartTime: Date
  showCountDown: boolean
  showDay: boolean
}

export function getAstDetails(date: string, currentDateMilli: number): GetAstDetailsInterface {
  const advertisedStartTime = new Date(date)
  const milliToAst = advertisedStartTime.getTime() - currentDateMilli
  const showCountDown: boolean = milliToAst < ONE_HOUR
  const showDay: boolean = milliToAst > ONE_DAY

  return {
    advertisedStartTime,
    showCountDown,
    showDay,
  }
}

export function getRedirectUrl(searchResult: StructuredSearchResult): string {
  let url: string = ''

  const acceptorResult = searchResult as StructuredSearchResult
  const formattedDate = dayjs(acceptorResult.acceptor.race.fixture.date).format('YYYY-MM-DD')

  url = `#tote/meetings/${acceptorResult.acceptor.race.fixture.id}/${acceptorResult.acceptor.race.number}?date=${formattedDate}`

  return url
}
