import React from 'react'
import { GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { Range, getTrackBackground } from 'react-range'
import {
  RangeInputContainerStyled,
  RangeInputBackgroundTrackStyled,
  RangeInputRenderThumbStyled,
  RangeInputRenderTooltipStyled,
  RangeInputDragIndicatorStyled,
} from './RangeInput.styles'
import { colors } from '@core/Settings'

interface RangeInputProps {
  step: number
  values: number[]
  minRange: number
  maxRange: number
  minChangedCallback: (value: number) => void
  maxChangedCallback?: (value: number) => void
  testId: string
  id: string
  isMaxInfinite?: boolean
  isCurrency?: boolean
  isPercentage?: boolean
}

export function RangeInput({
  step,
  values,
  minRange,
  maxRange,
  minChangedCallback,
  maxChangedCallback,
  testId,
  id,
  isMaxInfinite,
  isCurrency,
  isPercentage,
}: RangeInputProps): JSX.Element {
  const changedCallback = (newValues: number[]) => {
    if (newValues[0] !== values[0]) {
      minChangedCallback(newValues[0])
    }

    if (newValues[1] !== values[1]) {
      if (maxChangedCallback) maxChangedCallback(newValues[1])
    }
  }

  return (
    <div>
      <GridRow>
        <GridCell padding='0 0.5rem 0 0'>
          <RangeInputComponent
            minRange={minRange}
            maxRange={maxRange}
            step={step}
            values={values}
            testId={testId}
            id={id}
            changedCallback={changedCallback}
            isMaxInfinite={isMaxInfinite}
            isCurrency={isCurrency}
            isPercentage={isPercentage}
          />
        </GridCell>
      </GridRow>
    </div>
  )
}

interface RangeInputComponentProps {
  step: number
  id: string
  values: number[]
  changedCallback: (value: number[]) => void
  minRange: number
  maxRange: number
  testId: string
  isMaxInfinite?: boolean
  isCurrency?: boolean
  isPercentage?: boolean
}

export function RangeInputComponent({
  step,
  values,
  id,
  testId,
  maxRange,
  minRange,
  changedCallback,
  isMaxInfinite,
  isCurrency,
  isPercentage,
}: RangeInputComponentProps): JSX.Element {
  const inputTestId = testId ? { [`data-tid-${testId}`]: '' } : {}

  return (
    <RangeInputContainerStyled id={id}>
      <Range
        values={values}
        step={step}
        min={minRange}
        max={maxRange}
        onChange={newValues => changedCallback(newValues)}
        renderTrack={({ props, children }) => (
          <RangeInputBackgroundTrackStyled
            {...inputTestId}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '0.5rem',
                width: '100%',
                borderRadius: '0.4rem',
                background: getTrackBackground({
                  values: values,
                  colors:
                    values.length > 1
                      ? [
                          colors.rangeInput.track.background,
                          colors.rangeInput.track.foreground,
                          colors.rangeInput.track.background,
                        ]
                      : [colors.rangeInput.track.background, colors.rangeInput.track.foreground],
                  min: minRange,
                  max: maxRange,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </RangeInputBackgroundTrackStyled>
        )}
        renderThumb={({ index, props, isDragged }) => {
          const inputTestThumbId = testId ? { [`data-tid-${testId}-thumb`]: index } : {}

          let label: string | number = values[index]
          if (index === 1 && isMaxInfinite && values[index] === maxRange) {
            label = `${values[index]}+`
          }

          return (
            <RangeInputRenderThumbStyled {...(props as {})} {...inputTestThumbId}>
              <RangeInputRenderTooltipStyled>
                {isCurrency ? '$' : ''}
                {label}
                {isPercentage ? '%' : ''}
              </RangeInputRenderTooltipStyled>

              <RangeInputDragIndicatorStyled isDragged={isDragged} />
            </RangeInputRenderThumbStyled>
          )
        }}
      />
    </RangeInputContainerStyled>
  )
}
