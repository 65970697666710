import React from 'react'
import Decimal from 'decimal.js'
import * as immutable from 'immutable'
import type { FobSelection } from '@mobi/betslip/types'
import { isStartingPriceSelection } from '@mobi/betslip/helpers/typeGuards'
import { BetslipItem } from '../../driver'
import { DeselectSuperPick } from '../../signals'
import { SelectedSuperPick } from '../SuperPick/SelectedSuperPick'
import { SuperPickAvailable } from '../SuperPick/SuperPickAvailable'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import {
  InvestmentTextStyled,
  BetEachWayInvestmentGroupStyled,
  BetInvestmentGroupStyled,
  InvestmentPriceStyled,
  InvestmentAmountStyled,
  GridCellStyled,
} from './BetInvestment.styles'
import { EditBetslipItemArgs } from './BetInvestment'
import { ProjectedReturn } from '../ProjectedReturn/ProjectedReturn'
import { InvestmentType } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import { calculateBoosts } from '@core/Areas/Betslip/helpers/calculator'
import { isSpecialUsed } from '../../helpers/state'
import { getPriceChange, PriceChange } from '@core/Components/Text/utils'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { useFeature } from '@core/Utils/hooks'

interface FobInvestmentProps {
  item: BetslipItem
  isLoggedIn: boolean
  isEditable: boolean
  editBetslipItem: ({ item, investmentType, isEditable }: EditBetslipItemArgs) => void
  items: immutable.List<BetslipItem>
}

export function FobInvestment({
  item,
  isLoggedIn,
  isEditable,
  editBetslipItem,
  items,
}: FobInvestmentProps) {
  const canShowInducements = useInducementService()
  const is50cIncrementActive = useFeature('CENTS_INCREMENTS_BET')
  const decimalPlaces = is50cIncrementActive ? 2 : 0

  const deselectSuperPick = () => DeselectSuperPick(item)

  const { selectedSuperPickOffer, investment, selection } = item
  const prices = selection as FobSelection

  const winPriceChange = prices.winPriceLastSeen
    ? getPriceChange(prices.winPriceLastSeen, prices.winPrice)
    : PriceChange.None

  const placePriceChange =
    prices.placePriceLastSeen && prices.placePrice
      ? getPriceChange(prices.placePriceLastSeen, prices.placePrice)
      : PriceChange.None

  const [winBoost, placeBoost] = calculateBoosts(selectedSuperPickOffer ?? null)

  const hasWinPrice = !!prices.winPrice

  const hasPlacePrice = !!prices.placePrice
  const hasSecondaryDisplay = hasWinPrice && hasPlacePrice
  const isStartingPriceBet = isStartingPriceSelection(selection)

  const handleEditBetslipWin = () =>
    editBetslipItem({
      item,
      investmentType: InvestmentType.Win,
      isEditable,
      items,
    })
  const handleEditBetslipPlace = () =>
    editBetslipItem({
      item,
      investmentType: InvestmentType.Place,
      isEditable,
      items,
    })

  const isSuperPickAvailable =
    canShowInducements && isLoggedIn && !!item.specialOffers && item.specialOffers.length > 0
  const shouldShowSuperPickButton =
    isSuperPickAvailable && items.count() > 0 && !isSpecialUsed(item, items)
  const shouldShowSuperPickSelected = isSuperPickAvailable && !!selectedSuperPickOffer

  return (
    <Grid>
      {item.isEachWayAvailable ? (
        <GridRow>
          <BetEachWayInvestmentGroupStyled isEditable={isEditable} onClick={handleEditBetslipWin}>
            <GridCell width='100%' valign='middle' padding='0.5rem 0'>
              <Grid>
                <GridCellStyled>
                  <InvestmentTextStyled data-tid-investment-text-w='' isEditable={isEditable}>
                    W
                  </InvestmentTextStyled>
                  <InvestmentPriceStyled
                    data-tid-investment-price-w=''
                    price={new Decimal(prices.winPrice || 0).plus(winBoost).toNumber()}
                    priceChange={winPriceChange}
                    isEditable={isEditable}
                    shouldHighlight={winBoost > 0}
                  />
                </GridCellStyled>
                <GridCellStyled align='right'>
                  <InvestmentAmountStyled
                    data-tid-investment-amount-w=''
                    amount={investment.win.value}
                    isEditable={isEditable}
                    decimalPlaces={decimalPlaces}
                    isBonusBet={!!investment.win.isBonusBet}
                  />
                </GridCellStyled>
              </Grid>
            </GridCell>

            {item.isEachWay && (
              <GridCell width='100%' valign='middle' padding='0.5rem 0'>
                <Grid>
                  <GridCell>
                    <InvestmentTextStyled
                      data-tid-each-way-investment-text-p=''
                      isEditable={isEditable}
                    >
                      P
                    </InvestmentTextStyled>
                    <InvestmentPriceStyled
                      data-tid-each-way-investment-price-p=''
                      price={new Decimal(prices.placePrice || 0).plus(placeBoost).toNumber()}
                      priceChange={placePriceChange}
                      isEditable={isEditable}
                      shouldHighlight={placeBoost > 0}
                    />
                  </GridCell>
                  <GridCell align='right'>
                    <InvestmentAmountStyled
                      data-tid-each-way-investment-amount-p=''
                      amount={investment.place.value}
                      isEditable={isEditable}
                      decimalPlaces={decimalPlaces}
                      isBonusBet={false}
                    />
                  </GridCell>
                </Grid>
              </GridCell>
            )}
          </BetEachWayInvestmentGroupStyled>
        </GridRow>
      ) : (
        <GridRow>
          {(hasWinPrice || isStartingPriceBet) && (
            <GridCell width={hasSecondaryDisplay ? '49%' : 'auto'}>
              <BetInvestmentGroupStyled
                isEditable={isEditable}
                onClick={handleEditBetslipWin}
                data-tid-investment-w
              >
                <Grid>
                  <GridCell valign='middle'>
                    <InvestmentTextStyled
                      data-tid-win-price-investment-text-w=''
                      isEditable={isEditable}
                    >
                      W
                    </InvestmentTextStyled>
                    {isStartingPriceBet ? (
                      <InvestmentTextStyled
                        data-tid-place-price-investment-text-p=''
                        isEditable={isEditable}
                      >
                        SP
                      </InvestmentTextStyled>
                    ) : (
                      <InvestmentPriceStyled
                        data-tid-win-price-investment-price-w=''
                        price={new Decimal(prices.winPrice || 0).plus(winBoost).toNumber()}
                        priceChange={winPriceChange}
                        isEditable={isEditable}
                        shouldHighlight={winBoost > 0}
                      />
                    )}
                  </GridCell>
                  <GridCell valign='middle' align='right'>
                    <InvestmentAmountStyled
                      data-tid-win-price-investment-amount-w=''
                      amount={investment.win.value}
                      isEditable={isEditable}
                      decimalPlaces={decimalPlaces}
                      isBonusBet={!!investment.win.isBonusBet}
                    />
                  </GridCell>
                </Grid>
              </BetInvestmentGroupStyled>
            </GridCell>
          )}

          {hasSecondaryDisplay && <GridCell width='2%' />}

          {hasPlacePrice && (
            <GridCell width='49%'>
              <BetInvestmentGroupStyled
                isEditable={isEditable}
                onClick={handleEditBetslipPlace}
                data-tid-investment-p
              >
                <Grid>
                  <GridCell>
                    <InvestmentTextStyled
                      data-tid-place-price-investment-text-p=''
                      isEditable={isEditable}
                    >
                      P
                    </InvestmentTextStyled>
                    <InvestmentPriceStyled
                      data-tid-place-price-investment-price-p=''
                      price={new Decimal(prices.placePrice || 0).plus(placeBoost).toNumber()}
                      priceChange={placePriceChange}
                      isEditable={isEditable}
                      shouldHighlight={placeBoost > 0}
                    />
                  </GridCell>
                  <GridCell align='right'>
                    <InvestmentAmountStyled
                      data-tid-place-price-investment-amount-p=''
                      amount={investment.place.value}
                      isEditable={isEditable}
                      decimalPlaces={decimalPlaces}
                      isBonusBet={!!investment.place.isBonusBet}
                    />
                  </GridCell>
                </Grid>
              </BetInvestmentGroupStyled>
            </GridCell>
          )}
        </GridRow>
      )}

      <GridRow>
        <GridCell width='auto' valign='middle'>
          {shouldShowSuperPickSelected && (
            <SelectedSuperPick
              label={selectedSuperPickOffer?.title ?? ''}
              onDeselectClick={deselectSuperPick}
            />
          )}

          {shouldShowSuperPickButton && !shouldShowSuperPickSelected && (
            <SuperPickAvailable
              onClick={handleEditBetslipWin}
              disabled={false}
              isEditable={isEditable}
            />
          )}

          {item.isEachWay ? (
            <InvestmentTextStyled data-tid-investment-text-each-way='' isEditable={isEditable}>
              Each Way
            </InvestmentTextStyled>
          ) : null}
        </GridCell>
        <GridCell width='auto' align='right'>
          <ProjectedReturn item={item} />
        </GridCell>
      </GridRow>
    </Grid>
  )
}
