import React from 'react'
import { FeatureFlags } from '@mobi/settings'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'

export function useKambiMaintenanceFeature(): false | string {
  const [maintenancePayload, setMaintenancePayload] = React.useState<false | string>(false)

  // Kambi Feature Toggle
  React.useEffect(() => {
    const subscription = featureState$
      .map(state => ({
        isMaintenanceActive: state.get('features').get(FeatureFlags.KAMBI_MAINTENANCE.key),
      }))
      .distinctUntilChanged(
        s => s,
        (a, b) => JSON.stringify(a) === JSON.stringify(b)
      )
      .subscribe(({ isMaintenanceActive }) => {
        setMaintenancePayload(isMaintenanceActive?.get('endTime'))
      })

    return () => subscription.dispose()
  }, [])

  return maintenancePayload
}
