import React from 'react'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import type { PromptForUserDecisionProps } from './helpers'

export const PromptForUserDecision: React.FC<PromptForUserDecisionProps> = ({
  handleSelection,
  testId,
  buttons,
  title,
  subtitle,
}) => {
  return (
    <div data-testid={testId}>
      <NoticeBox title={title} subtitle={subtitle} noticeBoxType={NoticeBoxTypes.Warning} />
      <Grid>
        <GridRow>
          {buttons.map(({ label, color }) => (
            <GridCell padding='1rem' key={label}>
              <ButtonBlock onClick={() => handleSelection(label)} color={color}>
                {label}
              </ButtonBlock>
            </GridCell>
          ))}
        </GridRow>
      </Grid>
    </div>
  )
}
