import React from 'react'
import { state$ as kambiState$ } from '@core/Areas/Kambi/driver'
import { getWapi } from '@core/Areas/Kambi/helpers'

export const useKambiVisible = (): [isKambiVisible: boolean, isKambiBetslipMaximized: boolean] => {
  const [isKambiVisible, setIsKambiVisible] = React.useState<boolean>(false)
  const [isKambiBetslipMaximized, setIsKambiBetslipMaximized] = React.useState(false)

  React.useEffect(() => {
    const subscription = kambiState$
      .map(kambiState => kambiState.get('isVisible'))
      .distinctUntilChanged()
      .debounce(20)
      .subscribe(isVisible => {
        setIsKambiVisible(isVisible)
      })
    return () => {
      subscription.dispose()
    }
  }, [])

  React.useEffect(() => {
    let subscription: Rx.IDisposable

    if (!isKambiVisible) return undefined

    getWapi().then(wapi => {
      // On entering sports route, show betslip minimized
      wapi.set(wapi.BETSLIP_MAXIMIZED, { maximized: false })

      subscription = kambiState$
        .map(kambiState => kambiState.get('isBetslipMaximized'))
        .distinctUntilChanged()
        .subscribe(isBetslipMaximizedState => {
          setIsKambiBetslipMaximized(isBetslipMaximizedState)
        })
    })
    return () => {
      subscription?.dispose()
    }
  }, [isKambiVisible])

  return [isKambiVisible, isKambiBetslipMaximized]
}
