import * as ko from 'knockout'
import { BoxedViewModel } from './BoxedViewModel'
import template from './boxed.tpl.html'

if (!ko.components.isRegistered('all-ways')) {
  ko.components.register('all-ways', {
    viewModel: BoxedViewModel,
    template,
  })
}
