import { UseQueryOptions } from 'react-query'
import { get } from '@classic/Foundation/Services/ApiService'
import { queryKeys } from '../ReactQuery/constants'
import type { ServerConfig } from './types'

// TODO: Upgrade react-query to get full type support with queryOptions api

export const serverConfigQuery: UseQueryOptions<ServerConfig> = {
  queryKey: queryKeys.serverConfig,
  queryFn: () => get<ServerConfig>({ url: '/api/config' }),
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime: 1_000 * 60 * 60, // 1hr
} as const
