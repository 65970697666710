import type {
  AddressSuggestions,
  CountryList,
  FindByMoniker,
  StreetTypeSuggestions,
  SuburbStateSuggestions,
} from '@mobi/api-types'
import { get } from '@classic/Foundation/Services/ApiService'

export function searchSuburbStatePostcodes(search: string) {
  return get<SuburbStateSuggestions>({
    url: `/$_/api/signup/GetSuburbStatePostcodeList/?searchString=${encodeURIComponent(search)}`,
  })
}

export function searchStreetTypes(search: string) {
  return get<StreetTypeSuggestions>({
    url: `/$_/api/signup/GetStreetTypeList/?searchString=${encodeURIComponent(search)}`,
  })
}

export function searchAddresses(search: string) {
  return get<AddressSuggestions>({
    url: `/$_/api/signup/GetAddressList/?searchString=${encodeURIComponent(search)}`,
  })
}

type SearchFunction = (search: string) => Promise<{ value: string }[]>

export function withValueMapping(searchFn: SearchFunction) {
  return (search: string) =>
    searchFn(search).then(results =>
      results.map(({ value }) => {
        return {
          id: value,
          value,
        }
      })
    )
}

export function getCountryList() {
  return get<CountryList>({ url: '/$_/api/SignUp/GetCountryList' })
}

export function findByMoniker(moniker: string) {
  return get<FindByMoniker>({
    url: `api/address/findByMoniker?moniker=${moniker}`,
  })
}
