import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'

import { CampaignStyled, CampaignTitleStyled, RemoveCampaignButtonStyled } from './Campaigns.styles'

type ActiveCampaignsProps = {
  handleRemoveBonusCashButtonClick: () => void
}

export function ActiveCampaignsComponent({
  handleRemoveBonusCashButtonClick,
}: ActiveCampaignsProps) {
  const title = `Bonus cash applied`

  return (
    <CampaignStyled>
      <Grid>
        <GridCell width='3rem' valign='middle'>
          <Icon type='infoInvert' />
        </GridCell>
        <GridCell valign='middle'>
          <CampaignTitleStyled>{title}</CampaignTitleStyled>
        </GridCell>
        <GridCell width='3rem' valign='middle' align='right'>
          <RemoveCampaignButtonStyled onClick={handleRemoveBonusCashButtonClick}>
            <Icon type='minusCircled' title='Remove' size='1.8rem' />
          </RemoveCampaignButtonStyled>
        </GridCell>
      </Grid>
    </CampaignStyled>
  )
}
