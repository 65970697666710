import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils'

const enum LocalConstant {
  STORAGE_KEY = 'successfulDepositCount',
}

type CountMap = Record<number, number>

export function initializeWithCountIfNotInitialized(accountNumber: number, count: number) {
  if (!(accountNumber in loadCounts())) {
    saveCount(accountNumber, count)
  }
}

export function incrementSuccessCount(accountNumber: number): number {
  const count = loadCounts()[accountNumber]

  if (count === undefined) {
    return -1
  }

  const nextCount = count + 1

  saveCount(accountNumber, nextCount)

  return nextCount
}

function loadCounts(): CountMap {
  const item = getFromLocalStorage(LocalConstant.STORAGE_KEY)

  return JSON.parse(item ?? '{}')
}

function saveCount(accountNumber: number, count: number) {
  setInLocalStorage(
    LocalConstant.STORAGE_KEY,
    JSON.stringify({
      ...loadCounts(),
      [accountNumber]: count,
    })
  )
}
