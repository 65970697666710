import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'

export interface NotificationState {
  eventKeys: string[]
}

export const defaultNotificationState: NotificationState = {
  eventKeys: [],
}

export const GetNotificationRaceKeys = createSignal<{ raceKeys: string[] }>(
  'GetNotificationRaceKeys'
)
export const RegisterRaceNotification = createSignal<string>('RegisterRaceNotification')
export const UnregisterRaceNotification = createSignal<string>('UnregisterRaceNotification')
export interface NotificationStateRecord
  extends TypedRecord<NotificationStateRecord>,
    NotificationState {}
const NotificationStateFactory = makeTypedFactory<NotificationState, NotificationStateRecord>(
  defaultNotificationState
)

export function notificationStateDriver(
  state = NotificationStateFactory(),
  signal: Signal
): NotificationStateRecord {
  switch (signal.tag) {
    case GetNotificationRaceKeys:
      return state.merge({
        eventKeys: signal.data.raceKeys,
      })
    case RegisterRaceNotification: {
      const newRacekeys = state.eventKeys.concat(signal.data)
      return state.merge({
        eventKeys: newRacekeys,
      })
    }
    case UnregisterRaceNotification: {
      const removedRacekey = signal.data as string
      const keys = state.eventKeys.filter(e => e.split('|')[0] !== removedRacekey)
      return state.merge({
        eventKeys: keys,
      })
    }

    default:
      return state
  }
}

export const notificationState$ = attachDriver<NotificationStateRecord>({
  path: 'notificationStateRecord',
  driver: notificationStateDriver,
})
