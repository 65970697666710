import { dayjs } from '@mobi/utils/index'
import { get } from '@classic/Foundation/Services/ApiService'
import { FieldSummary } from '../../Types/FieldSummary'

export const getRaceFieldSummaryDataFromApi = async (
  racecourseSeq?: number,
  meetingDate?: string,
  raceNumber?: number
) => {
  const url = getFieldSummaryForRaceApiUrl(racecourseSeq, meetingDate, raceNumber)
  return await get<FieldsResponse>({ url })
}

function getFieldSummaryForRaceApiUrl(
  racecourseSeq?: number,
  meetingDate?: string,
  raceNumber?: number
) {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  return `/api/meetings/${racecourseSeq}/${formattedDate}/race/${raceNumber}/fields`.toLowerCase()
}

// Types

export type FieldsResponse = {
  fields: FieldSummary[]
  raceCode: string
}
