import { get } from '@classic/Foundation/Services/ApiService'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { RaceProduct } from '../constants'
import { FixedOddsEventDetailsResponse } from './types'

export const getFixedOddsEventDetails = async (
  product: RaceProduct,
  racingCode: RaceCodesLowerCase,
  competitionSequence: string
): Promise<FixedOddsEventDetailsResponse> => {
  const details = await get<FixedOddsEventDetailsResponse>({
    url: `/api/fobracing/eventDetails/${product}/${racingCode}/${competitionSequence}`,
  })
  return details
}
