import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { setIsPendingBetsDrawerOpen } from '@core/Areas/SkyVideoPlayer/Store'
import {
  selectCanRenderPendingBetsDrawer,
  selectIsPendingBetsDrawerOpen,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { PlayerControlButtonStyled } from '../Buttons'
import { usePendingTicketsCount } from './Hooks/usePendingBetsCount'

const enum LocalConstants {
  activeButtonClassName = 'pending-bets-button-active',
}

export const PendingBetsDrawerButton = () => {
  const dispatch = useAppDispatch()
  const isPendingBetsOpen = useAppSelector(selectIsPendingBetsDrawerOpen)
  const canRenderPendingBetsDrawer = useAppSelector(selectCanRenderPendingBetsDrawer)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(setIsPendingBetsDrawerOpen(!isPendingBetsOpen))
  }

  const { pendingBetsCount } = usePendingTicketsCount(canRenderPendingBetsDrawer)

  if (!canRenderPendingBetsDrawer) return null

  return (
    <PendingBetsDrawerButtonStyled isPendingBetsOpen={isPendingBetsOpen}>
      <PlayerControlButtonStyled
        className={isPendingBetsOpen ? LocalConstants.activeButtonClassName : ''}
        onClick={handleClick}
      >
        Pending Bets <div>{pendingBetsCount}</div>
      </PlayerControlButtonStyled>
    </PendingBetsDrawerButtonStyled>
  )
}

// ======
// Styles
// ======

const PendingBetsDrawerButtonStyled = styled.div<{ isPendingBetsOpen: boolean }>({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',

  '> button': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 'min-content',
    marginLeft: '0.5rem',
    paddingLeft: spacing.smx1,
    paddingRight: spacing.smx1,
    gap: spacing.smx2,

    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.medium,
    whiteSpace: 'nowrap',

    color: colors.white,
    cursor: 'pointer',

    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2.2rem',
      height: '2.2rem',
      background: colors.white,
      color: colors.black,
      borderRadius: '50%',
      letterSpacing: '-1px',
    },

    ['&.' + LocalConstants.activeButtonClassName]: {
      background: colors.white,
      color: colors.black,

      '> div': {
        background: 'rgba(0,0,0,0.8)',
        color: colors.white,
      },
    },
  },
})
