import React, { useEffect, useRef, useState } from 'react'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import {
  MultiToastPriceContainerStyled,
  MultiToastPriceTitleStyled,
  MultiToastPriceAmountStyled,
  MultiToastPriceAmountPrefixStyled,
} from '@core/Areas/Racing/Components/MultiToast/MultiToastPrice.styles'
import { Odometer } from '@core/Components/Odometer'
import { calculateMultiReturn } from '@core/Areas/Betslip/helpers/calculator'
import * as immutable from 'immutable'

const animationDuration = 700

export function MultiToastPrice({ betslipItems }: { betslipItems: BetslipItem[] }) {
  // deliberately using ref instead of state to avoid unnecessary re-rendering of the Odometer
  const oldPrice = useRef<number>(0)
  const newPrice = useRef<number>(0)

  const [odometerReady, setOdometerReady] = useState(false)
  const [startAnim, setStartAnim] = useState(false)

  useEffect(() => {
    // Odometer workaround - animation does not start if it's used 'too soon' after being mounted
    // - likely an issue with the underlying browser (worse on safari) and/or React's render/commit.. but not proven
    let timerId = setTimeout(() => setOdometerReady(true), 30)
    return () => clearTimeout(timerId)
  }, [])

  useEffect(() => {
    oldPrice.current = newPrice.current
    newPrice.current = calculateMultiReturn(immutable.List(betslipItems), { shouldRound: true })
    setStartAnim(true)

    let timeoutId = setTimeout(() => {
      // Odometer workaround - when setting animation to false it will revert the display to the start value, which is not desirable
      oldPrice.current = newPrice.current

      // Odometer workaround - the animation must be assigned false for a 'period of time' before it can be started again
      setStartAnim(false)
    }, animationDuration)

    return () => clearTimeout(timeoutId)
  }, [betslipItems])

  return (
    <MultiToastPriceContainerStyled>
      <MultiToastPriceTitleStyled>Multi</MultiToastPriceTitleStyled>
      <MultiToastPriceAmountStyled>
        <MultiToastPriceAmountPrefixStyled>$</MultiToastPriceAmountPrefixStyled>
        <Odometer
          start={oldPrice.current}
          end={newPrice.current}
          duration={animationDuration}
          startAnim={odometerReady && startAnim}
          height={'2rem'}
          width={'0.9rem'}
        />
      </MultiToastPriceAmountStyled>
    </MultiToastPriceContainerStyled>
  )
}
