import React from 'react'
import { useAppDispatch } from '@core/Store/hooks'
import { setDisableAppHeaderRender } from '../Store'

/** Only to be used on top level page routes - otherwise changes required */
export const useDisableAppHeaderRender = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setDisableAppHeaderRender(true))
    return () => {
      dispatch(setDisableAppHeaderRender(false))
    }
  }, [dispatch])
}
