import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import QuinellaValidator from '@classic/Betting-v2/Validation/QuinellaValidator'
import CheckBoxSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/CheckBoxSelectionResultProcessor'
import type { IConfigureBetType } from './IConfigureBetType'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class QuinellaConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.Quinella,
      name: 'Quinella',
      supportsFlexi: true,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'quinella-template',
      betSelectionTemplate: 'dual-checkbox-selection-template',
      multiBet: false,
      multiSelections: true,
      checkBoxCount: 1,
      fieldCheckBoxCount: 1,
      sameAsCheckBoxCount: 0,
      processor: new CheckboxSelectionProcessor(1),
      validator: new QuinellaValidator(),
      selectionResultProcessor: new CheckBoxSelectionResultProcessor(2),
      legs: 1,
    })
  }
}
