import styled from '@emotion/styled'
import { PasswordComponentTheme } from './themes'
import { Icon } from '@mobi/component-library/Common/Icon'
import { InputField } from '@mobi/component-library/Common/Input'

interface Theme {
  theme?: PasswordComponentTheme
}

export const PasswordVisibilityToggleStyled = styled('button')<Theme>(
  {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0.5rem',
    font: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '0.5rem',

    '&:focus': {
      outline: 'auto 5px -webkit-focus-ring-color', // override mobi outline cancelling
    },
  },

  ({ theme }) => ({
    color: theme.icon.text.color,
    backgroundColor: theme.icon.background,
    top: theme.toggle.top,
  })
)

export const PasswordVisibilityToggleLabelStyled = styled('span')<Theme>(
  {
    marginRight: '0.3rem',
  },

  ({ theme }) => ({
    color: theme.icon.text.color,
    fontSize: theme.icon.text.size,
  })
)

export const PasswordHideIconStyled = styled(Icon)<Theme>(({ theme }) => ({
  color: theme.icon.text.color,
  fontSize: theme.icon.size,
}))

export const PasswordInputStyled = styled(InputField)<Theme>(
  {
    margin: '0.5rem 0 1rem 0',
    paddingRight: '8rem',
  },

  ({ theme }) => ({
    backgroundColor: theme.input.background,
    margin: theme.input.margin,
    padding: theme.input.padding,
  })
)

export const PasswordGroupStyled = styled('div')<Theme>({
  position: 'relative',
})

export const PasswordFormStyled = styled('div')<Theme>(
  {
    display: 'block',
    position: 'relative',
    fontSize: '1.2rem',
  },

  ({ theme }) => ({
    color: theme.label.text.color,
    fontSize: theme.label.text.size,
  })
)

export const PasswordLabelStyled = styled('label')<Theme>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
