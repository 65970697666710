const defaults: CurrencySettings = {
  errorOnInvalid: false,
  precision: 2,
  pattern: '!#',
  negativePattern: '-!#',
  fromCents: false,
}

export class Currency {
  public intValue: number
  public value: number
  private _settings: CurrencySettings
  private _precision: number

  constructor(value: number | string | Currency, opts?: Partial<CurrencySettings>) {
    const settings = { ...defaults, ...opts }
    const precision = pow(settings.precision)
    const v = parse(value, settings)

    this.intValue = v
    this.value = v / precision

    // Set default incremental value
    settings.increment = settings.increment || 1 / precision

    this._settings = settings
    this._precision = precision
  }

  add(number: number | string | Currency): Currency {
    const { intValue, _settings, _precision } = this
    return new Currency(
      (intValue + parse(number, _settings)) / (_settings.fromCents ? 1 : _precision),
      _settings
    )
  }

  subtract(number: number | string | Currency): Currency {
    const { intValue, _settings, _precision } = this
    return new Currency(
      (intValue - parse(number, _settings)) / (_settings.fromCents ? 1 : _precision),
      _settings
    )
  }

  multiply(number: number): Currency {
    const { intValue, _settings } = this
    return new Currency(
      (intValue * number) / (_settings.fromCents ? 1 : pow(_settings.precision)),
      _settings
    )
  }

  divide(number: number | string | Currency): Currency {
    const { intValue, _settings } = this
    return new Currency(intValue / parse(number, _settings, false), _settings)
  }

  distribute(count: number): Currency[] {
    const { intValue, _precision, _settings } = this
    const distribution: Currency[] = []
    const split = Math[intValue >= 0 ? 'floor' : 'ceil'](intValue / count)
    let cents = Math.abs(intValue - split * count)
    const precision = _settings.fromCents ? 1 : _precision

    for (; count !== 0; count--) {
      let item = new Currency(split / precision, _settings)

      // Add any left over cents
      if (cents-- > 0) {
        item = item[intValue >= 0 ? 'add' : 'subtract'](1 / precision)
      }

      distribution.push(item)
    }

    return distribution
  }

  dollars(): number {
    return ~~this.value
  }

  cents(): number {
    return ~~(this.intValue % this._precision)
  }

  format(options?: Partial<Pick<CurrencySettings, 'pattern' | 'negativePattern'>>): string {
    const { _settings } = this
    const { pattern, negativePattern } = { ..._settings, ...options }
    const split = Math.abs(this.value).toFixed(_settings.precision).split('.')
    const dollars = split[0]
    const cents = split[1]

    return (this.value >= 0 ? pattern : negativePattern)
      .replace('!', '$')
      .replace('#', dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1' + ',') + (cents ? '.' + cents : ''))
  }

  toString(): string {
    const { intValue, _precision, _settings } = this
    return rounding(intValue / _precision, _settings.increment!).toFixed(_settings.precision)
  }

  toJSON(): number {
    return this.value
  }
}

// =============
// Local Helpers
// =============

const round = (v: number): number => Math.round(v)
const pow = (p: number): number => Math.pow(10, p)
const rounding = (value: number, increment: number): number => round(value / increment) * increment

function parse(
  value: number | string | Currency,
  opts: CurrencySettings,
  useRounding: boolean = true
): number {
  let v = 0
  const { errorOnInvalid, precision: decimals, fromCents } = opts
  const precision = pow(decimals)

  if (value instanceof Currency && fromCents) {
    return value.intValue
  }

  if (typeof value === 'number' || value instanceof Currency) {
    v = value instanceof Currency ? value.value : value
  } else if (typeof value === 'string') {
    const regex = new RegExp(`[^-\\d.]`, 'g')
    const decimalString = new RegExp(`\\.`, 'g')
    v =
      parseFloat(
        value
          .replace(/\((.*)\)/, '-$1') // allow negative e.g. (1.99)
          .replace(regex, '') // replace any non numeric values
          .replace(decimalString, '.') // convert any decimal values
      ) || 0
  } else {
    if (errorOnInvalid) {
      throw new Error('Invalid Input')
    }
    v = 0
  }

  if (!fromCents) {
    v *= precision // scale number to integer value
    v = parseFloat(v.toFixed(4)) // Handle additional decimal for proper rounding.
  }

  return useRounding ? round(v) : v
}

// =====
// Types
// =====

interface CurrencySettings {
  errorOnInvalid: boolean
  precision: number
  pattern: string
  negativePattern: string
  fromCents: boolean
  increment?: number
}
