import styled from '@emotion/styled'
import { ellipsis } from 'polished'
import { colors } from '@core/Settings'
import { PillStyled } from '@core/Components/Pill'
import { BetInvestmentStyled } from '@core/Areas/Quickbet/Components/BetInvestment/BetInvestment.styles'
import { FormulaCostChildStyled } from '../MultiFormula/MultiFormula.styles'

export const QuickEditTitleStyled = styled('div')({
  ...ellipsis('100%'),
  width: 'calc(100% - 2.5rem)',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  lineHeight: 1.3,
})

export const QuickEditSubTitleStyled = styled(PillStyled)({
  backgroundColor: colors.quickbet.header.pill.background,
  lineHeight: 1.2,
  padding: '0.3rem 0.7rem',
  boxSizing: 'border-box',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginLeft: '-0.7rem',
  maxWidth: 'calc(100% + 0.7rem)',
  whiteSpace: 'nowrap',
  marginTop: '0.1rem',
})

export const QuickEditComboTitleStyled = styled('span')({
  fontSize: '1.4rem',
  color: colors.quickbet.header.text,
})

export const QuickEditComboTextStyled = styled(QuickEditComboTitleStyled)({
  fontSize: '1.6rem',
})

export const QuickEditFormulaInvestmentStyled = styled(BetInvestmentStyled)({
  padding: '0 1.5rem 2.5rem',
})

export const QuickEditFormulaCostStyled = styled(FormulaCostChildStyled)({
  position: 'absolute',
  bottom: '-1.8rem',
  width: '100%',
  left: 0,
})
