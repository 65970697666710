import styled from '@emotion/styled'
import { fontFamilies } from '@core/Settings'
import { hexColors } from '@mobi/settings'
import { SilkImageStyled } from '@core/Components/SilkImage/SilkImage.styles'
import {
  HomeScrollableContainerStyled,
  HomeScrollHeaderStyled,
  HOME_CSS_SHARED_VALUES,
} from '@core/Areas/Home/Home.styles'

export const silkHeight = 30

export const Wrapper = styled('div')({
  backgroundColor: '#512D6D',
  background: `linear-gradient(102deg, #512D6D 0%, #512D6D 45%, #814AAE 45%, #814AAE 100%)`,
  position: 'relative',
  minHeight: '2.5rem',
  marginTop: '0.5rem',
  padding: '1rem 0 1rem 0',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
})

export const SubheadingContainer = styled('div')({
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0.5rem 1rem 0 1rem',
})

export const HeaderContainer = styled(HomeScrollHeaderStyled)({
  flex: 1,
  minHeight: '2.5rem',
  justifyContent: 'space-between',
  flexBasis: '100%',
})

export const Header = styled('div')({
  fontSize: '1.6rem',
  minHeight: '2.6rem',
  paddingLeft: '2.5rem',
  fontFamily: fontFamilies.brand,
  fontWeight: 'bold',
  fontStyle: 'italic',
  textTransform: 'uppercase',
  textShadow: `${hexColors.charCoal} 0.1rem 0.1rem 0.5rem`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left 0 top 0',
  backgroundImage: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyMyAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfNjcwXzM0MikiPg0KPHBhdGggZD0iTTE5IDEyLjQ2NTZDMTkgMTYuNjM3NSAxNS42MzY3IDIwLjAwMDggMTEuNSAyMC4wMDA4QzcuMzYzMjggMjAuMDAwOCA0IDE2LjYzNjMgNCAxMi41MDA4QzQgMTAuMjA5IDUuMDg2NzIgOC4zMjg4OSA2LjEzMTY0IDcuMjYyNDhDNi43MTY0MSA2LjYxNDA0IDcuNzUgNy4wMjQyIDcuNzUgNy44NzE4NVYxMS4yMTE3QzcuNzUgMTIuNTg1NSA4Ljg0MjU4IDEzLjczMTIgMTAuMjE3MiAxMy43NDg0QzExLjYxMzMgMTMuNzcwMyAxMi43NSAxMi42NDE0IDEyLjc1IDExLjI1MDhDMTIuNzUgNy44MTMyNiA1LjkxMDE2IDcuNDk2MDcgMTAuNzEyOSAwLjQyMjYzNUMxMS4yNDAyIC0wLjM0NzY3NyAxMi40Mzc1IDAuMDAxOTMyMDggMTIuNDM3NSAwLjkzMTYyQzEyLjQwMjMgNC45NjE3IDE5IDUuODQ4NDIgMTkgMTIuNDY1NloiIGZpbGw9IiNFMzg3MDYiLz4NCjwvZz4NCjxkZWZzPg0KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzY3MF8zNDIiIHg9IjAiIHk9IjAiIHdpZHRoPSIyMyIgaGVpZ2h0PSIyOC4wMDA3IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+DQo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPg0KPGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMTI3IDAiIHJlc3VsdD0iaGFyZEFscGhhIi8+DQo8ZmVPZmZzZXQgZHk9IjQiLz4NCjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz4NCjxmZUNvbXBvc2l0ZSBpbjI9ImhhcmRBbHBoYSIgb3BlcmF0b3I9Im91dCIvPg0KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMjUgMCIvPg0KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjcwXzM0MiIvPg0KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjcwXzM0MiIgcmVzdWx0PSJzaGFwZSIvPg0KPC9maWx0ZXI+DQo8L2RlZnM+DQo8L3N2Zz4NCg==)`,
  maxWidth: 'calc(100% - 8rem)',
  '@media (max-width: 350px)': {
    maxWidth: 'calc(100% - 12rem)',
  },
})

export const HeaderContent = styled('span')({
  color: '#fdfdfc',
  lineHeight: '2.6rem', // must match minHeight of TiltedScrollHeader
  letterSpacing: '0.1rem',
  display: 'inline-block',
})

export const HomeTrendingBetsScrollableContainerStyled = styled(HomeScrollableContainerStyled)({
  padding: '1rem',
  backgroundColor: 'none',
  flex: 1,
})

export const TrendingBetsListItemStyled = styled('a')({
  background: hexColors.white,
  boxShadow: HOME_CSS_SHARED_VALUES.boxShadow,
  color: hexColors.black,
  display: 'inline-block',
  borderRadius: HOME_CSS_SHARED_VALUES.borderRadius,
  alignItems: 'center',
  fontFamily: fontFamilies.default,
  fontSize: '1.1rem',
  margin: '0',
  marginRight: '1rem',
  padding: '.3rem .6rem',
  position: 'relative',
  textAlign: 'left',
  textDecoration: 'none',
  verticalAlign: 'middle',
  height: '13rem',
  width: '9.8rem',
  minWidth: '9.8rem',

  '&:hover': {
    backgroundColor: 'none',
  },

  'a:last-of-type': {
    marginRight: '0',
  },
})

export const TrendingBetsListItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
})

export const TrendingDividendsStyled = styled('div')({
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
})

export const TrendingWinDividendStyled = styled('span')({
  textAlign: 'left',
})

export const TrendingPlaceDividendStyled = styled(TrendingWinDividendStyled)({
  textAlign: 'right',
})

export const TrendingBetNameStyled = styled('div')({
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const TrendingRaceNameStyled = styled(TrendingBetNameStyled)({
  fontSize: '1.1rem',
})

export const TrendingRacerStyled = styled(TrendingRaceNameStyled)({
  color: '#666666',
  marginBottom: '-2px',
})

export const TrendingBetEventRaceTimeStyled = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  fontSize: '1.1rem',

  '& > span': {
    display: 'flex',
    alignItems: 'center',

    'div:last-of-type': {
      paddingLeft: '0.2rem',
    },
  },
})

export const TrendingBetGreyhoundsSilkImageStyled = styled(SilkImageStyled)({
  marginTop: '.4rem',
})

export const TrendingBetSilkImageContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  background: '#eee',
  padding: '.4rem .8rem',
  height: '3.6rem',
  width: '8.2rem',
  borderRadius: '.8rem',
})
