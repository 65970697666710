import React from 'react'
import { GridStyled, GridCellStyled, GridCellInnerStyled, GridRowStyled } from './Grid.styles'
import { type StandardProperties } from 'csstype'

export type GridProps = {
  padding?: StandardProperties['padding']
  margin?: StandardProperties['margin']
  children?: React.ReactChild | React.ReactChild[] | React.ReactNode
  maxWidth?: StandardProperties['maxWidth']
}

export type GridCellProps = {
  width?: StandardProperties['width']
  padding?: StandardProperties['padding']
  minWidth?: StandardProperties['minWidth']
  flexGrow?: StandardProperties['flexGrow']
  flexBasis?: StandardProperties['flexBasis']
  valign?: 'top' | 'middle' | 'bottom'
  align?: 'left' | 'right' | 'center'
  children?: React.ReactNode
  onClick?: () => void
}

function passPropsToChildren(props: GridProps) {
  const propsToPass = { ...props }
  delete propsToPass.children
  return React.Children.map(props.children, child => {
    if (child) {
      return React.cloneElement(child as React.ReactElement, { ...propsToPass })
    } else {
      return child
    }
  })
}

export function Grid(props: GridProps): JSX.Element {
  const children = passPropsToChildren(props)
  return <GridStyled {...props}>{children}</GridStyled>
}

export function GridRow(props: GridProps): JSX.Element {
  const children = passPropsToChildren(props)
  return <GridRowStyled>{children}</GridRowStyled>
}

export function GridCell(props: GridCellProps): JSX.Element {
  return (
    <GridCellStyled {...props}>
      <GridCellInnerStyled {...props}>{props.children}</GridCellInnerStyled>
    </GridCellStyled>
  )
}
