import styled from '@emotion/styled'
import { BetInvestmentGroupStyled } from '../BetInvestment/BetInvestment.styles'
import { calculateInvestmentFontSizeFactor } from '../../helpers/calculator'
import { lessOrEqualThan } from '@mobi/component-library/Utils/media'

export const FlexiDisplayStyled = BetInvestmentGroupStyled.withComponent('section')

const defaultValueFontSize = 2.6

export const AmountStyled = styled('span')<{ amount: number }>(
  {
    flex: '1 0',
    fontSize: `${(defaultValueFontSize * 0.8).toFixed(2)}rem`,
    fontWeight: 'bold',
    textAlign: 'right',
    // Increase the lineHeight to match the fontSize, to prevent artifacts from text above and below the amount of space
    // for the line, as given by the lineHeight (iOS 11 only; see MOBILE-865)
    lineHeight: 'normal',
  },
  ({ amount }) => {
    return {
      [lessOrEqualThan('px450')]: {
        fontSize: `${(
          defaultValueFontSize *
          calculateInvestmentFontSizeFactor({ amount }) *
          1.2
        ).toFixed(2)}rem`,
      },
      [lessOrEqualThan('px400')]: {
        fontSize: `${(
          defaultValueFontSize *
          calculateInvestmentFontSizeFactor({ amount }) *
          1.08
        ).toFixed(2)}rem`,
      },
      [lessOrEqualThan('px350')]: {
        fontSize: `${(defaultValueFontSize * calculateInvestmentFontSizeFactor({ amount })).toFixed(
          2
        )}rem`,
      },
    }
  }
)
