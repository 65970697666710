import React from 'react'
import { InputBoxStyled, InputLabelStyled, ValidationErrorStyled } from './InputField.styles'
import type { InputFieldProps } from './InputField'

export const CurrencyInputField = ({
  name,
  type,
  onChange,
  required,
  errorMessage,
  testId,
  ...inputProps
}: InputFieldProps): JSX.Element => {
  const inputTestId = testId ? { [`data-tid-${testId}`]: '' } : {}
  const errorTestId = testId ? { [`data-tid-${testId}-error`]: '' } : {}

  return (
    <div>
      <InputLabelStyled htmlFor={name}>
        {name}
        <span>{required ? '*' : null}</span>
      </InputLabelStyled>
      <InputBoxStyled
        {...inputProps}
        type={type}
        id={name}
        onChange={onChange}
        required={required}
        error={errorMessage}
        {...inputTestId}
      />
      {errorMessage && (
        <ValidationErrorStyled {...errorTestId}>{errorMessage}</ValidationErrorStyled>
      )}
    </div>
  )
}

CurrencyInputField.displayName = 'CurrencyInputField'
