import { ContactDetailsUpdateRequest } from '../../Data/Account/contactDetails'
import { NameDetails, PhoneNumber, Address, BetAccountHolder } from '@mobi/api-types'

import { ContactDetailsFields, ContactDetailsState } from './driver'
import { formatDate } from '@mobi/utils'

const defaultPhoneCountryCode = '61'

export function getContactDetailsFields(contactDetails: BetAccountHolder): ContactDetailsFields {
  return {
    betAccountHolderNumber:
      contactDetails.BetAccountHolderNumber && contactDetails.BetAccountHolderNumber,
    fullName: contactDetails.NameDetails && buildName(contactDetails.NameDetails),
    preferredName:
      (contactDetails.NameDetails &&
        contactDetails.NameDetails.PreferredName !== contactDetails.NameDetails.FirstName &&
        contactDetails.NameDetails.PreferredName) ||
      '',
    dateOfBirth: buildDateOfBirth(contactDetails.DateOfBirth as Date),
    email: (contactDetails.Contact && contactDetails.Contact.Email) || '',
    phoneMobile:
      (contactDetails.Contact &&
        contactDetails.Contact.Mobile &&
        buildPhoneNumber(contactDetails.Contact.Mobile)) ||
      '',
    phoneHome:
      (contactDetails.Contact &&
        contactDetails.Contact.HomePhone &&
        buildPhoneNumber(contactDetails.Contact.HomePhone)) ||
      '',
    phoneWork:
      (contactDetails.Contact &&
        contactDetails.Contact.WorkPhone &&
        buildPhoneNumber(contactDetails.Contact.WorkPhone)) ||
      '',
    addressResidential: contactDetails.ResidentialAddress || null,
    addressResidentialMoniker: '',
    addressPostal: contactDetails.PostalAddressSameAsResidential
      ? null
      : contactDetails.PostalAddress || null,
    addressPostalMoniker: '',
    addressPostalSame: !!contactDetails.PostalAddressSameAsResidential,
    driversLicenceNumber:
      (contactDetails.DriversLicence && contactDetails.DriversLicence.Number) || '',
    driversLicenceState:
      (contactDetails.DriversLicence && contactDetails.DriversLicence.State) || '',
    driversLicenceCardNumber:
      (contactDetails.DriversLicence && contactDetails.DriversLicence.CardNumber) || '',
    passportNumber: (contactDetails.Passport && contactDetails.Passport.Number) || '',
    passportCountry: (contactDetails.Passport && contactDetails.Passport.PassportCountry) || '',
    medicareBlueYellowExpiryDate:
      (contactDetails.Medicare && contactDetails.Medicare.BlueYellowMedicareExpiryDate) || null,
    medicareGreenExpiry:
      (contactDetails.Medicare && contactDetails.Medicare.GreenMedicareExpiry) || '',
    medicareCardColour:
      (contactDetails.Medicare && contactDetails.Medicare.MedicareCardColour) || '',
    medicareMiddleNameOnCard:
      (contactDetails.Medicare && contactDetails.Medicare.MedicareMiddleNameOnCard) || '',
    medicareNumber: (contactDetails.Medicare && contactDetails.Medicare.Number) || '',
    medicareReference: (contactDetails.Medicare && contactDetails.Medicare.Reference) || '',
    nameDateOfBirthChanged: !!contactDetails.NameDateOfBirthChanged,
    nameDateOfBirthChangedInitial: !!contactDetails.NameDateOfBirthChanged,
    isInternationalCustomer: isInternationalCustomer(contactDetails.ResidentialAddress),
  }
}

export function isInternationalCustomer(residentialAddress?: Address): boolean {
  return (
    residentialAddress !== undefined &&
    residentialAddress.Country.length > 0 &&
    residentialAddress.Country.toLowerCase() !== 'australia'
  )
}

export function buildName(name: NameDetails): string {
  return [name.FirstName, name.MiddleName, name.Surname].filter(n => !!n).join(' ')
}

export function buildDateOfBirth(dateOfBirth: Date): string {
  return formatDate(dateOfBirth, 'DD MMM YYYY')
}

export function buildPhoneNumber(phone: PhoneNumber): string | undefined {
  if (phone.PhoneNumber === '') {
    return undefined
  }

  return `${phone.AreaCode ? phone.AreaCode : ''}${phone.PhoneNumber}`
}

export function buildAddress(address: Address): string | null {
  if (!address) {
    return null
  }
  const streetNumber = (address.UnitNumber ? `${address.UnitNumber}/` : '') + address.StreetNumber
  const addressLine = `${streetNumber} ${address.StreetName} ${address.StreetType}`

  // two spaces between suburb and state as the address lookup returns in this format and we want it to be consistent
  const suburbStatePostcode = `${address.Suburb}  ${address.State} ${address.Postcode}`

  return `${addressLine}, ${suburbStatePostcode}`
}

export function serializeContactDetails(state: ContactDetailsState): ContactDetailsUpdateRequest {
  return {
    BetAccountHolder: {
      BetAccountHolderNumber: state.betAccountHolderNumber || undefined,
      NameChanged: state.nameDateOfBirthChanged,
      Contact: {
        HomePhone: parseLandlinePhoneNumber(state.phoneHome),
        WorkPhone: parseLandlinePhoneNumber(state.phoneWork),
        Mobile: parseMobilePhoneNumber(state.phoneMobile),
        Email: state.email,
      },
      PostalAddressSameAsResidential: state.addressPostalSame,
      DriversLicence: {
        Number: state.driversLicenceNumber,
        State: state.driversLicenceState,
        CardNumber: state.driversLicenceCardNumber,
      },
      Passport: {
        Number: state.passportNumber,
        PassportCountry: state.passportCountry,
      },
      Medicare: {
        BlueYellowMedicareExpiryDate: state.medicareBlueYellowExpiryDate || undefined,
        GreenMedicareExpiry: state.medicareGreenExpiry,
        MedicareCardColour: state.medicareCardColour,
        MedicareMiddleNameOnCard: state.medicareMiddleNameOnCard,
        Number: state.medicareNumber,
        Reference: state.medicareReference,
      },
      ResidentialAddress: state.addressResidential || undefined,
      PostalAddress: state.addressPostalSame ? undefined : state.addressPostal || undefined,
    },
    ResidentialAddressMoniker: state.addressResidentialMoniker,
    VerifyResidentialAddress: state.verifyResidentialAddress,
    PostalAddressMoniker: state.addressPostalMoniker,
    VerifyPostalAddress: state.verifyPostalAddress,
  }
}

export function parseLandlinePhoneNumber(phoneNumber: string): PhoneNumber {
  const regex = /^\s*(\d{2})(\d{8})\s*$/
  const result = phoneNumber && regex.exec(phoneNumber.replace(/\s/g, ''))
  return result
    ? {
        CountryCode: defaultPhoneCountryCode,
        AreaCode: result[1],
        PhoneNumber: result[2],
      }
    : {
        CountryCode: '',
        AreaCode: '',
        PhoneNumber: '',
      }
}

export function parseMobilePhoneNumber(phoneNumber: string): PhoneNumber {
  const regex = /^\s*(\d{10})\s*$/
  const result = phoneNumber && regex.exec(phoneNumber.replace(/\s/g, ''))
  return result
    ? {
        CountryCode: defaultPhoneCountryCode,
        AreaCode: '',
        PhoneNumber: result[1],
      }
    : {
        CountryCode: '',
        AreaCode: '',
        PhoneNumber: '',
      }
}
