import styled from '@emotion/styled'

export const OdometerStyled = styled('div')({
  display: 'inline-block',
  overflow: 'hidden',
})

interface DigitContainerStyledProps {
  duration?: number
  startAnim?: boolean
  height: string
}

export const DigitContainerStyled = styled('div')<DigitContainerStyledProps>(
  {
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
    transform: 'translateY(0)',
  },
  ({ startAnim, duration, height }) => ({
    transform: startAnim ? `translateY(-100%) translateY(${height})` : 'none',
    transition: startAnim ? `transform ${duration}ms ease-out 50ms` : 'none',
  })
)
interface DigitProps {
  isDecimalSeparator?: boolean
  isExtraDigit?: boolean
  height: string
  width: string
}

export const DigitStyled = styled('div')<DigitProps>(
  {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
  },
  ({ isDecimalSeparator, isExtraDigit, height, width }) => {
    const adjustedWidth = isExtraDigit ? '0' : isDecimalSeparator ? '0.4rem' : width

    return { width: adjustedWidth, height }
  }
)
