import { PlanSeq } from '@mobi/api-types'
import type { RacingBetType } from '../types'

export function getToteProductPlanSequence(
  betType: RacingBetType,
  isRefreshing: boolean,
  isRovingBanker: boolean = false,
  hasWinInvestment: boolean = false,
  hasPlaceInvestment: boolean = false
): number {
  switch (betType) {
    case 'Win & Place': {
      if (isRefreshing) {
        return PlanSeq.Win
      }
      if (hasWinInvestment && hasPlaceInvestment) {
        return PlanSeq.WinAndPlace
      }
      if (hasWinInvestment) {
        return PlanSeq.Win
      }
      if (hasPlaceInvestment) {
        return PlanSeq.Place
      }
      break
    }
    case 'Exacta': {
      return isRovingBanker ? PlanSeq.ExactaWithRovingBanker : PlanSeq.Exacta
    }
    case 'Quinella': {
      return PlanSeq.Quinella
    }
    case 'Trifecta': {
      return isRovingBanker ? PlanSeq.TrifectaWithRovingBanker : PlanSeq.Trifecta
    }
    case 'First 4': {
      return isRovingBanker ? PlanSeq.First4WithRovingBanker : PlanSeq.First4
    }
    case 'Double': {
      return PlanSeq.Double
    }
    case 'Quaddie': {
      return PlanSeq.Quaddie
    }
    case 'All Up': {
      return PlanSeq.AllUp
    }
  }
  throw new Error('Unsupported bet type')
}
