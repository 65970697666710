import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import Guard from '@classic/AppUtils/Framework/Guard'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IFieldViewModel } from './IFieldViewModel'

@injectable()
export class FieldViewModel extends Disposable implements IFieldViewModel {
  public bettingContext!: BettingInformation
  public raceKey!: ObservableRaceKey
  public selected!: ko.PureComputed<boolean>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  public init(params: { raceKey: ObservableRaceKey; bettingContext: BettingInformation }) {
    Guard.notNull(params.bettingContext)
    Guard.notNull(params.raceKey)

    this.bettingContext = params.bettingContext
    this.raceKey = params.raceKey
    this.selected = ko.pureComputed<boolean>(() => {
      if (params.bettingContext.field()[this.raceKey.leg() - 1] !== undefined) {
        var field: boolean = params.bettingContext.field()[this.raceKey.leg() - 1]()
        return field
      }
      return false
    })
    this.registerDisposals(() => {
      this.selected.dispose()
    })
  }

  public toggle(option: unknown, event: Event): void {
    event.preventDefault()
    this.evtAggregator.publish('field-selected-command', {
      raceNumber: this.raceKey.raceNumber(),
      position: this.raceKey.leg() - 1,
      selected: !this.selected(),
    })
  }
}
