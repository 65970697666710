import type {
  Selection,
  FavouriteNumbersSelection,
  ToteSportsSelection,
  StartingPriceFOOSelection,
} from '@mobi/betslip/types'
import { isMysterySelection } from '@mobi/betslip/helpers/typeGuards'

export enum ProductCode {
  Win,
  Place,
  WinPlace,
  Quinella,
  Exacta,
  Trifecta,
  First4,
  Double,
  Quaddie,
  Combo,
  AllUp,
  FavouriteNumbers,
  Footo,
  FootyTipping,
  SportsTipping,
  FobRacing,
  V4,
  V5,
  V64,
  V65,
  V75,
  FobSports,
  FobMixed,
  ExactaRovingBanker,
  TrifectaRovingBanker,
  First4RovingBanker,
  AllUpOld,
}

export type PriceSources = 'selection' | 'localstorage' | 'api' | 'push'

// =======================
// Type Narrowing Function
// =======================

export function isFavouriteNumbersSelection(
  value: Selection | null
): value is FavouriteNumbersSelection {
  return (
    !!value &&
    !isMysterySelection(value) &&
    (value as FavouriteNumbersSelection).bets &&
    Array.isArray((value as FavouriteNumbersSelection).bets)
  )
}

export function isToteSportsSelection(
  value: Selection | Immutable<Selection> | null
): value is ToteSportsSelection {
  return !!value && (value as ToteSportsSelection).poolSequence !== undefined
}

export function isRovingBanker(productPlanSequence: number): boolean {
  return [122, 123, 124].includes(productPlanSequence)
}

export function isStartingPriceFOOSelection(
  selection: Selection | null
): selection is StartingPriceFOOSelection {
  return !!(
    selection &&
    'propositionSeq' in selection &&
    selection.type === 'fob-proposition' &&
    'marketCode' in selection &&
    selection.marketCode === 'SP'
  )
}
