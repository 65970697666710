import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { PriceChange } from './utils'
import { MoneyGroupedProps } from './MoneyGrouped'
import { Money } from './Money'

export const MoneyGroupedStyled = styled(Money)<MoneyGroupedProps>(
  {
    display: 'inline-block',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    margin: 0,
    position: 'relative',
    zIndex: 1,
  },

  ({ priceChange }) => {
    if (priceChange !== PriceChange.Increase && priceChange !== PriceChange.Decrease) {
      return undefined
    }
    return {
      marginRight: '1rem',
      color:
        priceChange === PriceChange.Increase
          ? colors.priceChange.increase.text
          : colors.priceChange.decrease.text,

      '&::after': {
        content: '""',
        position: 'absolute',
        width: 0, // Refer to link about CSS triangle usage:
        height: 0, // https://css-tricks.com/snippets/css/css-triangle/
        borderLeft: '0.5rem solid transparent',
        borderRight: '0.5rem solid transparent',
        borderBottomStyle: priceChange === PriceChange.Increase ? 'solid' : 'none',
        borderBottomWidth: priceChange === PriceChange.Increase ? '0.7rem' : 'none',
        borderTopStyle: priceChange === PriceChange.Decrease ? 'solid' : 'none',
        borderTopWidth: priceChange === PriceChange.Decrease ? '0.7rem' : 'none',
        transform: 'translateY(-50%)',
        top: '50%',
        right: '-1.2rem',
      },
    }
  }
)
