import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import ObservableSubstitutePool from '../Model/Observables/ObservableSubstitutePool'
import ObservableSubstitute from '../Model/Observables/ObservableSubstitute'
import { SubstitutePoolDataTransferObject } from '@mobi/api-types'

export default class SubstitutePoolMapper {
  public static map(dto: Array<SubstitutePoolDataTransferObject>, model: ObservableResultsPage) {
    dto.forEach(availablePool => {
      //Create new observable
      var pool = new ObservableSubstitutePool()
      pool.number(availablePool.RaceNumber)
      pool.name(availablePool.Name)

      //Convert legs
      // @ts-expect-error Type issue
      for (var leg of availablePool.RaceLegs) {
        pool.legs().push(leg)
      }

      //Convert DTO subs into observable subs
      for (var subInPool of availablePool.AvailableSubstitutesInPool) {
        var sub = new ObservableSubstitute()
        sub.starterName = subInPool.Name
        sub.starterNumber = subInPool.StarterNumber.toString()

        sub.isAbandoned = subInPool.IsAbandoned
        sub.isWinner = subInPool.IsWinner
        sub.isLoser = subInPool.IsLoser
        sub.isUnallocated = subInPool.IsUnallocated

        sub.updateDisplayVars()

        pool.subs().push(sub)
      }

      //Finally add it to model for template
      model.addSubstitutePool(pool)
    })
  }
}
