import React from 'react'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { FeaturedEventsItemStyled, FeaturedEventsItemTitleStyled } from './Item.styles'
import { Icon } from '@mobi/component-library/Common/Icon'

interface FeaturedItemProps {
  text: string
  iconPathName: keyof typeof iconPaths
  url: string
  internalTrackingCode: string | null
  featureItemAction?: () => void
}

export function FeaturedItemListElement({
  text,
  iconPathName,
  url,
  internalTrackingCode,
  featureItemAction,
}: FeaturedItemProps) {
  return (
    <FeaturedEventsItemStyled
      href={url}
      data-nav=''
      data-featureditem-internaltrackingcode={internalTrackingCode ?? ''}
      onClick={featureItemAction}
    >
      <Icon size='3rem' type={iconPathName} />
      <FeaturedEventsItemTitleStyled>{text}</FeaturedEventsItemTitleStyled>
    </FeaturedEventsItemStyled>
  )
}
