import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

interface LinkListStyledProps {
  hideChevron?: boolean
}

export const ListLinkStyled = styled('a')<LinkListStyledProps>(
  {
    textDecoration: 'none',
    color: 'currentColor',
    position: 'relative',
    display: 'block',
  },

  ({ hideChevron = false }) => {
    if (hideChevron) {
      return undefined
    }

    return {
      '&::after': {
        fontFamily: 'tticons',
        content: '"\\3e"',
        display: 'block',
        width: '10px',
        height: '10px',
        position: 'absolute',
        top: '50%',
        right: '10px',
        lineHeight: '10px',
        textAlign: 'center',
        marginTop: '-5px',
        color: hexColors.veryLightGrey,
      },
    }
  }
)
