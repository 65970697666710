export function calculateBetCostShortfall(
  betCostInDollars: number,
  accountBalanceInDollars: number,
  isUsingBonusCash: boolean,
  bonusCashBalanceInDollars: number
): number {
  const betAmountInCents = Math.round(betCostInDollars * 100)
  const accountBalanceInCents = Math.round(accountBalanceInDollars * 100)

  // This will always be 0 when the user is not using bonus cash
  const bonusCashBalanceInCents = isUsingBonusCash ? Math.round(bonusCashBalanceInDollars * 100) : 0
  const availableBalance = accountBalanceInCents + bonusCashBalanceInCents

  return Math.max(0, (betAmountInCents - availableBalance) / 100)
}
