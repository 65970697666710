import * as ko from 'knockout'
import { CheckboxSelectionViewModel } from './CheckboxSelectionViewModel'
import template from './checkbox.selection.tpl.html'

if (!ko.components.isRegistered('checkbox-selection')) {
  ko.components.register('checkbox-selection', {
    viewModel: CheckboxSelectionViewModel,
    template,
  })
}
