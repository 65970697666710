import styled from '@emotion/styled'
import { spacing } from '@mobi/component-library/Theme/Common'

export const AppStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const PageStyled = styled.div({
  boxSizing: 'border-box',
  width: 'min(60rem, 100%)',
  margin: '0 auto',
  padding: spacing.md,
})
