import React, { memo } from 'react'
import styled from '@emotion/styled'
import type { PaymentMethod } from '@mobi/api-types'
import { nameCardIssuerMap, cardIssuerNameMap } from '../helpers'
import { colors, radius, spacing } from '../../Theme/Common'

type Props = Pick<PaymentMethod, 'issuerName' | 'lastFour'>

export const CreditCardName = memo(({ lastFour, issuerName }: Props) => {
  const Logo = cardIssuerNameMap[issuerName]
  const cardIssuer = nameCardIssuerMap[issuerName]

  return (
    <>
      <LogoContainerStyled issuerName={issuerName}>
        <Logo />
      </LogoContainerStyled>

      <span>
        <CardIssuerStyled>{cardIssuer}</CardIssuerStyled> .... {lastFour}
      </span>
    </>
  )
})

CreditCardName.displayName = 'CreditCardName'

// ======
// Styles
// ======

const LogoContainerStyled = styled.span(({ issuerName }: { issuerName: Props['issuerName'] }) => ({
  alignItems: 'center',
  backgroundColor: 'white',
  border: `0.5px solid ${colors.neutral['200']}`,
  borderRadius: radius.md,
  display: 'inline-flex',
  height: spacing.bigx1,
  width: spacing.bigx3,
  justifyContent: 'center',
  color: issuerName === 'VISA' ? '#1434CB' : 'unset',
}))
const CardIssuerStyled = styled.span(() => ({
  display: 'none',

  '@media (min-width: 350px)': {
    display: 'inline',
  },
}))
