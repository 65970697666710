import type { BetSlipItem } from '@mobi/betslip/types'

export function clearBonusBetFromItemInvestment(
  investment: BetSlipItem['investment']
): BetSlipItem['investment'] {
  return {
    ...investment,
    bonusBet: undefined,
    win: {
      ...investment.win,
      value: investment.win.isBonusBet ? 0 : investment.win.value,
      isBonusBet: false,
    },
    place: {
      ...investment.place,
      value: investment.place.isBonusBet ? 0 : investment.place.value,
      isBonusBet: false,
    },
  }
}
