import React from 'react'
import { Constants } from '@mobi/settings'
import { trackTakeABreakInformation } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Icon } from '@mobi/component-library/Common/Icon'
import {
  Button,
  ContainerStyled,
  ExternalLinkStyled,
  HeaderImageStyled,
  HeaderStyled,
  LinkListStyled,
  LinkStyled,
  ListItemStyled,
  ListStyled,
  TextStyled,
} from './TakeABreakInfo.styled'

import takeABreakImage from './images/take-a-break-header-v1.png'

interface TakeABreakInfoProps {
  setUserHasAgreedToBreakTerms: (setHasUserAgreedTabTerms: boolean) => void
  accountNumber: number
}

const TakeABreakInfo = ({
  setUserHasAgreedToBreakTerms,
  accountNumber,
}: TakeABreakInfoProps): JSX.Element => {
  React.useEffect(() => {
    trackTakeABreakInformation({ accountNumber })
  }, [accountNumber])

  return (
    <ContainerStyled data-testid='take-a-break-info'>
      <HeaderImageStyled src={takeABreakImage} />

      <TextStyled>
        We want your betting experience with TABtouch to be a positive and happy one, therefore we
        have put in place a number of features within your account to ensure you are betting
        responsibly. Take a Break is a way to take some time off betting for a selected period,
        chosen by you.
      </TextStyled>

      <HeaderStyled>Responsible Wagering at TABtouch</HeaderStyled>

      <TextStyled>
        TABtouch supports responsible wagering. In addition to Take a Break, we have a number of
        additional tools which can assist you with your responsible wagering requirements.
      </TextStyled>

      <LinkListStyled>
        <ListItemStyled>
          <LinkStyled href='#account/deposit-limits'>Deposit Limits</LinkStyled>
        </ListItemStyled>
        <ListItemStyled>
          <LinkStyled href='#account/precommitment'>Bet Limits</LinkStyled>
        </ListItemStyled>
        <ListItemStyled>
          <ExternalLinkStyled href={Constants.ResponsibleWageringURL} target='_blank'>
            Responsible Wagering Policy <Icon type='shareWeb' />
          </ExternalLinkStyled>
        </ListItemStyled>
      </LinkListStyled>

      <HeaderStyled>Take a Break Details</HeaderStyled>

      <TextStyled>By taking a break you acknowledge that:</TextStyled>

      <ListStyled>
        <ListItemStyled>You will be unable to bet</ListItemStyled>
        <ListItemStyled>You will be unable to deposit into your account</ListItemStyled>
        <ListItemStyled>
          Pending bets will stand and your remaining account balance can be withdrawn
        </ListItemStyled>
        <ListItemStyled>
          You may continue to receive marketing material for up to 72 hours after your break has
          been confirmed
        </ListItemStyled>
      </ListStyled>

      <Button
        data-testid='take-a-break-info-continue'
        onClick={() => setUserHasAgreedToBreakTerms(true)}
      >
        Continue
      </Button>
    </ContainerStyled>
  )
}

export default TakeABreakInfo
