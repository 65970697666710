import styled from '@emotion/styled'
import { colors, spacing } from '@mobi/component-library/Theme/Common'

export const LoadingContainerStyled = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& :not(:last-child)': {
    marginBottom: '1rem',
  },
})

export const PanelStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
})

export const PaymentMethodsStyled = styled.div({
  padding: '2.5rem 0',
  flex: 1,
  borderTop: `0.1rem solid ${colors.neutral[200]}`,
  marginTop: spacing.bigx1,
})
