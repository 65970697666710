import { Selections } from '../driver'

export const calculateCombinations = (selections: string): number => {
  if (selections.length === 0) return 0
  return selections.split('/').reduce((prevSel, currentSel) => {
    prevSel = prevSel * currentSel.split('.').length
    return prevSel
  }, 1)
}

export const calculateSelectionString = (selections: Selections): string =>
  Object.keys(selections)
    .map(key => {
      const parts = []
      const selection = selections[key]
      if (selection.isHome) parts.push('H')
      if (selection.isDraw) parts.push('D')
      if (selection.isAway) parts.push('A')
      return parts.join('.')
    })
    .join('/')
