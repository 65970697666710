import styled from '@emotion/styled'
import { ellipsis } from 'polished'
import { HeaderPillStyled } from './Header/Components/Common/Header.styles'

export const TitleStyled = styled.div({
  ...ellipsis('100%'),
  width: 'calc(100% - 2.5rem)',
  fontSize: '1.4rem',
  fontWeight: 'bold',
  lineHeight: 1.3,
})

export const CountdownPillStyled = styled(HeaderPillStyled)({
  fontWeight: 'bold',
  marginLeft: '0.7rem',
  maxWidth: '100%',
})
