import type { FavouriteNumbersSelection } from '@mobi/betslip/types'

type CombinationMap = { [key: string]: number | undefined }

export const ComboLookup: CombinationMap = {
  7: 1,
  8: 8,
  9: 36,
  10: 120,
  11: 330,
  12: 792,
  13: 1716,
  14: 3432,
  15: 6435,
  16: 11440,
}

const getCombinations = (numbers: number): number => ComboLookup[numbers.toString()] ?? 0

export const costCalculator = (numbers: number): number => getCombinations(numbers) * 0.5

const isPotLuck = (bet: FavouriteNumbersSelection['bets'][0]): boolean => {
  return bet.game1Selections.length === 1 && bet.game2Selections.length === 0
}

export const calculateCombinations = (bets: FavouriteNumbersSelection['bets']): number => {
  return bets.reduce((total, bet) => {
    const game1Numbers = isPotLuck(bet) ? bet.game1Selections[0] : bet.game1Selections.length
    const game2Numbers = bet.game2Selections.length
    return total + getCombinations(game1Numbers) + getCombinations(game2Numbers)
  }, 0)
}

export function calculateEasyBetCost(
  game1Selections: number,
  game2Selections: number,
  poolNumberSelections: number
): number {
  return (costCalculator(game1Selections) + costCalculator(game2Selections)) * poolNumberSelections
}

export function calculatePotLuckCost(
  potLuckNumbers: number,
  potLuckBets: number,
  numberOfPools: number
): number {
  return numberOfPools * potLuckBets * costCalculator(potLuckNumbers)
}
