import styled from '@emotion/styled'
import { PopupStyled } from '@core/Components/Popup/Popup.styles'
import { Paragraph } from '@core/Components/Text/Paragraph'

interface AnniversaryPopupProps {
  isDesktop?: boolean
}

export const AnniversaryPopup = styled(PopupStyled)<AnniversaryPopupProps>(({ isDesktop }) => ({
  fontSize: '1.4rem',
  width: isDesktop ? '30rem' : '26rem',
}))

AnniversaryPopup.displayName = 'AnniversaryPopup'

interface InfoCopyStyledProps {
  isDesktop?: boolean
}

export const InfoCopyStyled = styled(Paragraph)<InfoCopyStyledProps>(({ isDesktop }) => ({
  fontSize: isDesktop ? '1.5rem' : '1.2rem',
  marginBottom: 0,
}))

InfoCopyStyled.displayName = 'InfoCopyStyled'
