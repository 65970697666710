import React, { useMemo } from 'react'
import type { RacePageDTO } from '@mobi/api-types'
import { useAcceptors } from '@core/Areas/Racing/Hooks/useAcceptors'
import { TipButtonsContainerStyled } from './BettableTips.styles'
import {
  getAcceptorsFromTips,
  getPriceAndScratchings,
} from '@core/Areas/Racing/Components/BettableTips/helpers'
import { BettableTip } from '@core/Areas/Racing/Components/BettableTips/BettableTip'
import type { RawTips } from '@core/Areas/Racing/Components/BettableTips/types'
import type { TipSource } from '@core/Areas/Racing/Components/BettableTips/Hooks/useBettableTipQuickBetting'

export type Props = {
  tips: RawTips
  raceKey: string
  isRaceOpen: boolean
  tipSource: TipSource
  race?: RacePageDTO
}

export const BettableTips = ({
  tips,
  isRaceOpen,
  race,
  tipSource,
}: Props): Nullable<JSX.Element> => {
  const acceptors = useAcceptors(race)

  const selectedAcceptors = useMemo(
    () => getAcceptorsFromTips(tips, acceptors ?? new Map()),
    [tips, acceptors]
  )

  if (race === undefined || selectedAcceptors.length < 1) {
    return null
  }

  return (
    <TipButtonsContainerStyled
      data-testid={tipSource === 'tips' ? 'bettable-tips' : 'preview-tips'}
    >
      {selectedAcceptors.map((acceptor, i) => (
        <BettableTip
          key={acceptor.number}
          buttonPosition={i + 1}
          meeting={race.MeetingInformation}
          acceptor={acceptor}
          isRaceOpen={isRaceOpen}
          tipSource={tipSource}
          {...getPriceAndScratchings(acceptor)}
        />
      ))}
    </TipButtonsContainerStyled>
  )
}
