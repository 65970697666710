import { getRaceStartersApiUrl } from '@classic/Betting-v2/Utils/UrlProvider'
import { get } from '@classic/Foundation/Services/ApiService'
import { QueryKey, useQuery } from 'react-query'
import { PageDataTransferObject } from '@mobi/api-types'
import { queryKeys } from '@core/Data/ReactQuery/constants'

type Props = {
  meetingId: string
  meetingDate: Date
  raceNumbers: number[]
}

export const useStartersData = ({ meetingId, meetingDate, raceNumbers }: Props) => {
  return useQuery(
    queryKey(meetingId, meetingDate, raceNumbers),
    () => queryFn(meetingId, meetingDate, raceNumbers),
    options
  )
}

export const queryKey = (meetingId: string, meetingDate: Date, raceNumbers: number[]): QueryKey => {
  return [queryKeys.racePageStartersForRaces, meetingId.toLowerCase(), meetingDate, raceNumbers]
}

export const queryFn = async (
  meetingId: string,
  meetingDate: Date,
  raceNumbers: number[]
): Promise<PageDataTransferObject> => {
  const url = getRaceStartersApiUrl(meetingId, meetingDate, raceNumbers)
  return await get<PageDataTransferObject>({
    url,
  })
}

const options = {
  staleTime: Infinity,
}
