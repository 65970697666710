import React from 'react'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import {
  StarterDisplayStyled as Wrapper,
  StarterAcceptorStyled as Acceptor,
} from '../SingleMultiple/SingleMultiple.styles'
import {
  getSilk,
  sameRaceMultiPositionToText,
  sortSameRaceMultiTicketLegs,
} from '@core/Areas/AccountActivity/helpers'
import { isRefundedStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { HeaderWrapper } from '../HeaderWrapper'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { StarterHighlightStyled } from '../SingleMultiple/StarterHighlight.styles'

export const SameRaceMulti: React.FC<{
  ticket: Ticket
}> = ({ ticket }) => {
  const sortedLegs = sortSameRaceMultiTicketLegs(ticket)
  const isRefunded = isRefundedStatus(ticket)

  return (
    <Wrapper data-testid='details-selection-info'>
      {sortedLegs.map((leg, index) => {
        const { selections, fixtureId, raceNumber: legRaceNumber, fixtureDate, result } = leg
        const { raceNumber, selectionDetails } = selections[0]
        const srmPosition = sameRaceMultiPositionToText(
          selectionDetails[0].acceptorDetails[0].srmPosition
        )

        const { silkUrl, silkHeight, silkWidth, isDogsSilk } = getSilk({
          fixtureId,
          fixtureDate,
          raceNumber: raceNumber || legRaceNumber,
        })

        return (
          <HeaderWrapper
            key={index}
            header={srmPosition}
            leg={leg}
            betStatus={null}
            raceNumber={0 /* don't make a link */}
          >
            {selectionDetails[0].acceptorDetails.map(({ number, name }) => {
              return (
                <Acceptor key={number + name} data-testid='details-selection-info-starter'>
                  <div data-testid='details-selection-info-starter-silk'>
                    <SilkImage
                      url={silkUrl}
                      height={silkHeight}
                      width={silkWidth}
                      isDogsSilk={isDogsSilk}
                      xOffset={number - 1}
                      baseWidth={20}
                    />
                  </div>

                  <strong data-testid='details-selection-info-starter-number'>
                    <StarterHighlightStyled isWin={result == 'WIN' && !isRefunded}>
                      {number}.
                    </StarterHighlightStyled>
                  </strong>
                  <span data-testid='details-selection-info-starter-name'>{name}</span>
                </Acceptor>
              )
            })}
          </HeaderWrapper>
        )
      })}
    </Wrapper>
  )
}
