import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import {
  QuickbetAddToBetSlipButtonStyled,
  QuickbetBetButtonStyled,
  QuickbetButtonMoneyStyled,
  QuickbetButtonStyled,
} from './QuickbetButton.styles'
import { BusyIndicator } from '../BusyIndicator/BusyIndicator'
import { navigateToPendingBets } from '@classic/AppUtils/Framework/Intent/navigation'
import { QUICKBET_MODAL_ID } from '../../constants'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { closeModal } from '@core/Components/Modal'
import { RemoveCampaignButtonStyled } from '../Campaigns/Campaigns.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { ButtonTypes } from '@mobi/component-library/Common/Buttons'

export function LoginButtonComponent() {
  return (
    <QuickbetButtonStyled type='button' onClick={() => showLogin()} data-tid-quickbet-login-button>
      Log in to place bet
    </QuickbetButtonStyled>
  )
}

interface BettingButtonProps {
  onClick: () => void
  betCost: number
  isDisabled: boolean
  label: string
}

export function BettingButtonComponent({
  onClick,
  label,
  betCost,
  isDisabled,
}: BettingButtonProps) {
  return (
    <QuickbetBetButtonStyled onClick={onClick} disabled={isDisabled} data-tid-quickbet-betting-btn>
      {label} <QuickbetButtonMoneyStyled amount={betCost} />
    </QuickbetBetButtonStyled>
  )
}

export function BusyButtonComponent() {
  return (
    <QuickbetButtonStyled disabled={true}>
      <BusyIndicator />
    </QuickbetButtonStyled>
  )
}

type EditBetButtonProps = {
  isDisabled?: boolean
  onClick: VoidFunction
}

export function EditBetButtonComponent({ isDisabled = false, onClick }: EditBetButtonProps) {
  return (
    <QuickbetButtonStyled
      color='secondary'
      type='button'
      role='button'
      onClick={onClick}
      disabled={isDisabled}
      data-tid-quickbet-edit-bet-btn
    >
      Edit your Bet
    </QuickbetButtonStyled>
  )
}

export function PendingBetsButtonComponent() {
  const dispatch = useDispatch()

  const pendingBetsButtonClick = () => {
    dispatch(closeModal({ id: QUICKBET_MODAL_ID }))
    navigateToPendingBets()
  }

  return (
    <QuickbetButtonStyled
      color='secondary'
      type='button'
      onClick={pendingBetsButtonClick}
      data-tid-quickbet-pending-bets-btn
    >
      Pending Bets
    </QuickbetButtonStyled>
  )
}

export function NextEventButtonComponent({ onClick }: { onClick: () => void }) {
  return (
    <QuickbetButtonStyled
      color='secondary'
      type='button'
      onClick={onClick}
      data-tid-quickbet-nextevents-btn
    >
      Next Events
    </QuickbetButtonStyled>
  )
}

export function KeepBetsButtonComponent({
  theRef,
  onClick,
}: {
  theRef: React.RefObject<HTMLDivElement>
  onClick: () => void
}) {
  return (
    <>
      <div ref={theRef}>
        <QuickbetButtonStyled type='button' color='secondary' onClick={onClick}>
          Keep Bets
        </QuickbetButtonStyled>
      </div>
    </>
  )
}

export function CloseButtonComponent() {
  const dispatch = useDispatch()
  return (
    <QuickbetButtonStyled
      type='button'
      onClick={() => dispatch(closeModal({ id: QUICKBET_MODAL_ID }))}
      data-tid-quickbet-close-btn
    >
      Close
    </QuickbetButtonStyled>
  )
}

type DepositButtonProps = {
  color?: Extract<keyof ButtonTypes, 'primary' | 'secondary'>
  children: ReactNode
  onClick?: () => void
  isDisabled?: boolean
}

export function DepositButtonComponent({
  isDisabled = false,
  children,
  color = 'primary',
  onClick,
}: DepositButtonProps) {
  return (
    <QuickbetButtonStyled
      color={color}
      type='button'
      role='button'
      onClick={onClick}
      disabled={isDisabled}
      data-tid-quickbet-deposit-btn
    >
      {children}
    </QuickbetButtonStyled>
  )
}

interface BetslipButtonProps {
  onClick: () => void
  isDisabled: boolean
}

export function AddToBetslipButtonComponent({ onClick, isDisabled }: BetslipButtonProps) {
  return (
    <QuickbetAddToBetSlipButtonStyled
      data-tid-quickbet-add-to-betslip=''
      color='secondary'
      type='button'
      onClick={onClick}
      disabled={isDisabled}
    >
      Add to Betslip
    </QuickbetAddToBetSlipButtonStyled>
  )
}

export function UpdateBetslipItemButtonComponent({ onClick, isDisabled }: BetslipButtonProps) {
  return (
    <QuickbetButtonStyled type='button' onClick={onClick} disabled={isDisabled}>
      Done
    </QuickbetButtonStyled>
  )
}

export function RemoveCampaignButtonComponent({ onClick, isDisabled }: BetslipButtonProps) {
  return (
    <RemoveCampaignButtonStyled onClick={onClick} disabled={isDisabled}>
      <Icon type='minusCircled' title='Remove' size='1.8rem' />
    </RemoveCampaignButtonStyled>
  )
}
