import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { FavouriteNumbersPools, UpcomingPoolsKey } from '@core/Data/favouriteNumbers'
import { useDataAccess } from '@core/Utils/hooks'
import React from 'react'
import { isLoading } from 'rwwa-data-access'
import { HeaderStyled } from './FavouriteNumbers.styles'

export function HeaderWithLoadingIndicator({ title }: { title: string }): JSX.Element {
  const state = useDataAccess(FavouriteNumbersPools, UpcomingPoolsKey)

  return (
    <div style={{ minHeight: '3rem' }}>
      {isLoading(state.data) && <LoadingPlaceholder width='100%' height='2.6rem' />}
      {!isLoading(state.data) && <HeaderStyled>{title}</HeaderStyled>}
    </div>
  )
}
