import * as ko from 'knockout'
import { TrifectaTopPanelViewModel } from './TrifectaTopPanelViewModel'
import template from './trifecta.top.panel.tpl.html'

if (!ko.components.isRegistered('trifecta-top-panel')) {
  ko.components.register('trifecta-top-panel', {
    viewModel: TrifectaTopPanelViewModel,
    template,
  })
}
