import React from 'react'
import { TotalStakeValueStyled } from './TotalStake.styles'
import { Money } from '@core/Components/Text/Money'
import { observeImmutable } from '@core/Components/HOCs'
import { state$ } from '../../driver'
import { calculateTotalStake } from '@core/Areas/Betslip/helpers/calculator'

interface TotalStakeProps {
  totalStake: number
}

export function TotalStakeComponent({ totalStake }: TotalStakeProps) {
  return (
    <TotalStakeValueStyled data-tid-betslip-total-stake=''>
      <Money amount={totalStake} />
    </TotalStakeValueStyled>
  )
}

export const TotalStake = observeImmutable(state$, ({ record }) => (
  <TotalStakeComponent
    totalStake={calculateTotalStake(
      record.get('items'),
      record.get('multiInvestment').toJS(),
      record.get('multiBetError')?.toJS(),
      !!record.get('multiReceipt') // hasMultiBeenPlaced: boolean
    )}
  />
))
