import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { ObservableStarter } from '../Model/Observables/ObservableStarter'
import { ObservableFinisher } from '../Model/Observables/ObservableFinisher'
import { SilkImages, SilkImage, SilkImageSize } from '../Model/SilkImages'

export default class SilkImageMapper {
  public static map(
    dto: dtos.SilkImagesDataTransferObject,
    model: ObservableStarter | ObservableFinisher
  ) {
    const silks = new SilkImages()
    silks.small = this.mapSilkImage(dto.small)
    silks.large = this.mapSilkImage(dto.large)
    model.silkImages(silks)
  }

  private static mapSilkImage(dto: dtos.SilkImageDataTransferObject): SilkImage {
    const silkImage = new SilkImage()
    silkImage.url = dto.url
    silkImage.size = new SilkImageSize()
    silkImage.size.width = dto.size.width
    silkImage.size.height = dto.size.height
    silkImage.size.y = dto.size.y
    return silkImage
  }
}
