import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const CheckboxInputStyled = styled('input')({
  margin: 0,
  opacity: 0,
  position: 'absolute',
  zIndex: 0,

  '&:checked + label::after': {
    backgroundImage: `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMiAzMic+ICA8cGF0aCBmaWxsPScjMzEzMTMxJyBkPSdNMjUuNiwxMS40YzAuMi0wLjIsMC4yLTAuNSwwLTAuN2wtMi4zLTIuM2MtMC4yLTAuMi0wLjUtMC4yLTAuNywwTDE0LDE3bC0zLjYtMy42Yy0wLjItMC4yLTAuNS0wLjItMC43LDBsLTIuMywyLjMgYy0wLjIsMC4yLTAuMiwwLjUsMCwwLjdsNi4zLDYuM2MwLjIsMC4yLDAuNSwwLjIsMC43LDBMMjUuNiwxMS40TDI1LjYsMTEuNHonLz48L3N2Zz4=)`,
    backgroundSize: '2.4rem',
    backgroundRepeat: 'no-repeat',
    height: '2.4rem',
    width: '2.4rem',
  },

  '&[disabled] + label::before': {
    backgroundColor: hexColors.gainsboro,
  },
})

export const CheckboxInputLabelStyled = styled('label')(
  {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: '2.4rem',
    minHeight: '2.4rem',
    color: hexColors.black,
    paddingLeft: '2.4rem',
    margin: '0 0 0.7rem',

    '& > span': {
      marginLeft: '0.5rem',
    },

    '&::after, &::before': {
      position: 'absolute',
      content: '""',
      display: 'inline-block',
      left: 0,
      top: 0,
    },

    '&::before': {
      height: '2rem',
      width: '2rem',
      border: `0.2rem solid ${hexColors.darkGrey}`,
    },
  },
  ({ boxPosition }: { boxPosition?: 'center' }) => ({
    '&::before': {
      top: boxPosition === 'center' ? '50%' : undefined,
      transform: boxPosition === 'center' ? 'translateY(-50%)' : undefined,
    },
    '&::after': {
      top: boxPosition === 'center' ? '50%' : undefined,
      transform: boxPosition === 'center' ? 'translateY(-50%)' : undefined,
    },
  })
)
