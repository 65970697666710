import styled from '@emotion/styled'
import { measurements } from '@mobi/component-library/Theme/Common'
import { AppHeaderButtonStyled } from '../AppHeader/Components/Buttons/Buttons.styles'
import { colors } from '@core/Settings'
import { MainContainer } from '@core/Components/Containers'

export const SearchOverlayStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  background: colors.search.background,
  display: 'flex',
  flexDirection: 'column',
})

export const SearchOverlayHeaderStyled = styled.div({
  position: 'fixed',
  width: '100%',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: measurements.mobi.headerHeight,
  minHeight: measurements.mobi.headerHeight,
  background: colors.search.header.background,
})

export const CloseButtonStyled = styled(AppHeaderButtonStyled)({
  marginLeft: 'auto',
})

export const SearchResultsContainerStyled = styled(MainContainer)({
  overflowY: 'scroll', // scroll, not auto for momentum scroll to work
  WebkitOverflowScrolling: 'touch', // enables momentum scroll < IOS11
  minHeight: 0,
  flex: 1,
  background: colors.search.list.background,
  marginTop: measurements.mobi.headerHeight,
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '22rem', // Height of footer and notification(s)
  },
})
