import React from 'react'
import { deregisterBodyScrollLock, registerBodyScrollLock } from '../functions/bodyScrollLock'

export const useBodyScrollLock = (isEnabled = true): React.RefObject<HTMLDivElement> => {
  const scrollableContentRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!scrollableContentRef.current || !isEnabled) return undefined
    const el = scrollableContentRef.current
    registerBodyScrollLock(el)
    return () => {
      deregisterBodyScrollLock(el)
    }
  }, [isEnabled])

  return scrollableContentRef
}
