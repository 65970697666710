import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { darken } from 'polished'

export interface ChipsTypes {
  hollow: ChipsType
  filled: ChipsType
}

const chipsColors: ChipsTypes = {
  hollow: {
    text: hexColors.grey,
    background: hexColors.white,
    border: hexColors.veryLightGrey,
    hover: {
      background: darken(0.1, hexColors.grey),
    },
  },
  filled: {
    text: hexColors.white,
    background: hexColors.charCoal,
    border: 'transparent',
    hover: {
      background: darken(0.1, hexColors.charCoal),
    },
  },
}

export interface ChipProps {
  color?: keyof ChipsTypes
  selected?: boolean
}

const ChipBaseButtonStyled = styled('button')({
  alignItems: 'center',
  boxSizing: 'border-box',
  border: '1px solid',
  display: 'inline-block',
  fontSize: '1.2rem',
  lineHeight: '1.8',
  padding: '0.3rem 1.25rem 0.3rem 0.75rem',
  textAlign: 'center',
  transition: 'background-color 200ms',
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  borderRadius: '2rem',
})

export const ChipStyled = styled(ChipBaseButtonStyled)<ChipProps>(({ color = 'hollow' }) => ({
  backgroundColor: chipsColors[color].background,
  borderColor: chipsColors[color].border,
  color: chipsColors[color].text,
  userSelect: 'none',
  '&:hover': {
    backgroundColor: chipsColors[color].background,
  },
}))

export const ChipContainerStyled = styled('div')({
  marginBottom: '-1rem',
  '> *': {
    marginBottom: '0.75rem',
    marginRight: '0.5rem',
  },
})

interface ChipsType {
  text: string
  background: string
  border: string
  hover: {
    background: string
  }
}
