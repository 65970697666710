import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const modalXPadding = '1.5rem'

export interface QuickbetBodyProps {
  superPickEnabled?: boolean
}

export const QuickbetBodyStyled = styled('div')<QuickbetBodyProps>(
  {
    padding: `1.5rem ${modalXPadding} 0rem ${modalXPadding}`,
    background: hexColors.whiteSmoke,
    minHeight: '13rem',
    overflow: 'auto',
  },
  ({ superPickEnabled }) =>
    superPickEnabled && {
      minHeight: '23.9rem',
    }
)

export const QuickbetActionsStyled = styled('footer')({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  padding: modalXPadding,
})
