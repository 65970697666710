import type { SortOption } from './types'

export default class SortConverter {
  public static for(property: SortOption['property']): string {
    if (property == 'Number') {
      return 'Number'
    }
    if (property == 'FixedPrice') {
      return 'Fixed'
    }
    if (property == 'Price') {
      return 'Tote'
    }
    if (property == 'Name') {
      return 'Name'
    }
    if (property == 'SameRaceMultiWinPrice') {
      return 'Win Price'
    }
    return ''
  }
}
