export const arrowup = 'M0,176l32,32l96-96l96,96l32-32L128,48L0,176z'

export const arrowdown = 'M256,80l-32-32l-96,96L32,48L0,80l128,128L256,80z'

export const arrowleft = 'M152.5,198l16.3-17.5l-49-52.5l49-52.5L152.5,58l-65.3,70L152.5,198z'

export const arrowright = 'M80,0,48,32l96,96L48,224l32,32L208,128Z'

export const arrowdownwithtail =
  'M228.8333 101.8667C223.4999 96.5333 215.5008 97.0667 210.1675 101.8667L149.3666 166.4V12.8C149.3666 5.8667 143.4999 0 136.5666 0H119.49994C112.03328 0 106.69994 5.86667 106.69994 12.8V166.4L45.36661 101.86667C40.03328 97.06667 32.03328 96.53333 26.69994 101.86667L14.966664 113.6C10.166664 118.4 10.166665 126.9333 14.966665 131.7333L118.9666 235.19915C123.76661 240.534187 131.76661 240.534187 137.09995 235.19915L240.56661 131.73333C245.36747 126.9333 245.36747 118.9333 240.56661 113.6L228.83328 101.8667Z'

export const arrowupwithtail =
  'M18.16661 154.66667C23.49995 160 31.49995 159.46667 36.83328 154.66667L98.16661 90.13333V243.2C98.16661 250.66667 103.49995 256 110.96661 256H128.03328C134.96661 256 140.83328 250.66667 140.83328 243.2V90.13333L201.63413 154.13333C206.96747 159.46667 214.96661 159.46667 220.29995 154.66667L232.03328 142.93333C236.83413 137.6 236.83413 129.6 232.03328 124.8L128.03328 20.8C123.23328 16 115.23328 16 110.43328 20.8L6.43333 124.8C1.63333 129.6 1.63333 137.6 6.43333 142.93333L18.16661 154.666667Z'
