import { BonusType } from '@core/Areas/AccountActivity/types'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const BonusBetRowStyled = styled.tr<{ bonusAmount: number; bonusType: BonusType | null }>(
  ({ bonusAmount, bonusType }) => ({
    fontWeight: 'bold',
    color:
      bonusAmount && bonusAmount > 0 && bonusType
        ? bonusType === 'BonusBet'
          ? hexColors.tangerine
          : hexColors.cobalt
        : 'default',
  })
)
