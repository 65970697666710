import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { RacePageDataTransferObject } from '@mobi/api-types'

export function getAvailableBetTypesFromData(data: RacePageDataTransferObject) {
  return data.AvailableBetTypes.flatMap(betType => {
    if (betType.IsAbandoned) return []

    switch (betType.Type) {
      case 'WinPlace':
        return createBetType('Win & Place', BetType.WinPlace, data)

      case 'Trifecta':
        return createBetType('Trifecta', BetType.Trifecta, data)

      case 'Mystery':
        return createBetType('Mystery', BetType.Mystery, data)

      case 'AllUp':
        return createBetType('All Up', BetType.AllUp, data)

      case 'Quaddie':
        return createBetType('Quaddie', BetType.Quaddie, data)

      case 'Double':
        return createBetType('Double', BetType.Double, data)

      case 'First4':
        return createBetType('First 4', BetType.First4, data)

      case 'SameRaceMulti':
        return createBetType('Same Race Multi', BetType.SameRaceMulti, data)

      case 'Exacta':
        return createBetType('Exacta', BetType.Exacta, data)

      case 'Quinella':
        return createBetType('Quinella', BetType.Quinella, data)

      default:
        return []
    }
  })
}

// =============
// Local Helpers
// =============

function createBetType(name: string, betType: BetType, data: RacePageDataTransferObject) {
  return [
    {
      name,
      betType,
      hasJackpot: data.AvailablePools.some(
        pool =>
          isPoolForBetType(pool, betType) &&
          isPoolForRaceNumber(pool, data.MeetingInformation.SelectedRace.RaceNumber) &&
          pool.HasJackpot
      ),
    },
  ]
}

function isPoolForBetType(
  pool: RacePageDataTransferObject['AvailablePools'][0],
  betType: BetType
): boolean {
  let lowerCasePoolName = pool.Name.toLowerCase()
  switch (betType) {
    case BetType.WinPlace:
      return lowerCasePoolName === 'win' || lowerCasePoolName === 'place'

    case BetType.Quinella:
      return lowerCasePoolName === 'quinella'

    case BetType.Exacta:
      return lowerCasePoolName === 'exacta'

    case BetType.Trifecta:
      return lowerCasePoolName === 'trifecta'

    case BetType.First4:
      return lowerCasePoolName === 'first 4'

    case BetType.Double:
      return lowerCasePoolName === 'double'

    case BetType.Quaddie:
      return lowerCasePoolName === 'quaddie'
  }

  return false // mystery, all-up, etc.
}

function isPoolForRaceNumber(
  pool: RacePageDataTransferObject['AvailablePools'][0],
  raceNumber: number
): boolean {
  const hasRaceNumbers = Array.isArray(pool.RaceNumbers) && pool.RaceNumbers.length > 0
  return hasRaceNumbers ? pool.RaceNumbers[0] === raceNumber : true
}
