import { BettingInformation } from '../../../Model/BettingInformation'
import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import ObservableRaceKey from '../../../Model/Observables/ObservableRaceKey'
import ObservableRacePage from '../../../Model/Observables/ObservableRacePage'
import { BaseBetType } from '../../BetTypes/BaseBetType/BaseBetType'
import { IUseGiddyUp } from '../../Pages/Starters/IUseGiddyUp'

export class BettingClosedViewModel extends BaseBetType {
  constructor(params: {
    raceInfo: ObservableRacePage
    raceStarters: ko.Observable<{
      Key: ObservableRaceKey
      Value: ko.ObservableArray<IObservableStarter>
    }>
    selections: ko.ObservableArray<IObservableStarter>
    context: { bettingContext: BettingInformation }
    useGiddyUp: IUseGiddyUp
  }) {
    super(
      params.raceInfo,
      params.raceStarters,
      params.selections,
      params.context.bettingContext,
      params.useGiddyUp
    )
  }
}
