import styled from '@emotion/styled'
import { colors } from '@core/Settings'

export const RangeInputContainerStyled = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: '2.2rem 2.5rem 0rem 2.5rem',
})

export const RangeInputBackgroundTrackStyled = styled('div')({
  height: '3.6rem',
  display: 'flex',
  width: '100%',
})

export const RangeInputRenderThumbStyled = styled('div')({
  height: '2.2rem',
  width: '2.2rem',
  borderRadius: '1rem',
  backgroundColor: colors.rangeInput.indicator.notdragged,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: `0px 2px 6px ${colors.rangeInput.indicator.shadow}`,
  outline: 'none',
})

export const RangeInputRenderTooltipStyled = styled('div')({
  position: 'absolute',
  top: '-2.6rem',
  color: colors.rangeInput.tooltip.color,
  fontSize: '1.2rem',
  fontWeight: 'bold',
  padding: '0rem 0.5rem',
  borderRadius: '0.5rem',
  minWidth: '2.2rem',
  textAlign: 'center',
  backgroundColor: colors.rangeInput.tooltip.background,
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '1.1rem',
    top: '1.6em',
    width: '0',
    height: '0',
    borderLeft: '0.5rem solid transparent',
    borderRight: '0.5rem solid transparent',
    borderTop: `0.5rem solid ${colors.rangeInput.tooltip.background}`,
    clear: 'both',
  },
})

interface RangeInputDragIndicatorProps {
  isDragged: boolean
}

export const RangeInputDragIndicatorStyled = styled('div')<RangeInputDragIndicatorProps>(
  {
    height: '2.2rem',
    width: '2.2rem',
    borderRadius: '1rem',
  },
  ({ isDragged }) => ({
    backgroundColor: isDragged
      ? colors.rangeInput.indicator.notdragged
      : colors.rangeInput.indicator.dragged,
  })
)
