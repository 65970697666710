import { IObservableStarter } from '../../../Model/Observables/IObservableStarter'
import { BettingInformation } from '../../../Model/BettingInformation'
import { CheckBoxSelection } from '../UIElements/CheckboxSelection'
import {
  sameAsColumnsDependendentOn,
  notifyDependentColumnOfSelection,
  cascadeSameAsDeselectedThroughColumns,
  cascadeSameAsSelectedThroughColumns,
  updateColumnMatchingSameAs,
} from './SameAsAlgorithms'
import Guard from '../../../../AppUtils/Framework/Guard'
import SameAs from '@classic/Betting-v2/Model/SameAs'

export default class CheckBoxSameAsProcessor {
  public process(
    starters: ko.ObservableArray<IObservableStarter>,
    selections: ko.ObservableArray<IObservableStarter>,
    bettingContext: BettingInformation,
    row: number,
    column: number,
    selected: boolean
  ) {
    Guard.notNull(bettingContext)
    Guard.notNull(selections)
    Guard.notNull(starters)
    Guard.greaterThanOrEqualZero(row)
    Guard.greaterThanOrEqualZero(column)

    const sameAsNode = bettingContext.sameAsAt(row, column) as SameAs

    if (selected) {
      notifyDependentColumnOfSelection(starters(), row, column)
      const dependencies = sameAsColumnsDependendentOn(bettingContext, sameAsNode)
      cascadeSameAsSelectedThroughColumns(bettingContext, starters(), dependencies)
      sameAsNode.enable()
    } else {
      let affected = updateColumnMatchingSameAs(bettingContext, starters(), sameAsNode)
      const dependencies = sameAsColumnsDependendentOn(bettingContext, sameAsNode)
      cascadeSameAsDeselectedThroughColumns(bettingContext, affected, dependencies)
      sameAsNode.disable()
    }

    selections(
      starters().filter(starter => (starter.selection() as CheckBoxSelection).anySelected())
    )

    bettingContext.turnOffFieldAt(column - 1)
  }
}
