import React from 'react'
import { state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { hasNotBeenPlaced } from '@core/Areas/Betslip/helpers/state'

export const useBetslipCount = (): [count: number, prevCount: number | null] => {
  const prevCountRef = React.useRef<number | null>(null)
  const [count, setCount] = React.useState<number>(getInitialBetslipCount)

  React.useEffect(() => {
    prevCountRef.current = count
  }, [count])

  React.useEffect(() => {
    const betslipSubscription = betslipState$
      .map(state => state.items.filter(hasNotBeenPlaced).count())
      .distinctUntilChanged()
      .debounce(200)
      .subscribe(count => {
        setCount(count)
      })
    return () => {
      betslipSubscription.dispose()
    }
  }, [])

  return [count, prevCountRef.current]
}

function getInitialBetslipCount() {
  let count = 0
  betslipState$.take(1).subscribe(state => {
    count = state.items.filter(hasNotBeenPlaced).count()
  })
  return count
}
