import React from 'react'
import { isLoading, isErrored, LoadingInstance, ErroredInstance } from 'rwwa-data-access'
import { List as ImmutableList } from 'immutable'

import { connect } from '../../Components/HOCs/connect'
import { List, ListItem } from '../../Components/List'
import { Message, ErrorMessage } from '../../Components/Messages'
import { Jackpot, JackpotLoading } from './Components/Jackpot'
import { MainContainer } from '../../Components/Containers'
import { ContentHeader } from '../../Components/ContentHeader'

import { PoolKeys } from '../../Data/Racing/pools'

export const Jackpots = () => <ConnectedJackpots poolKeys='currentJackpotsByDate' />

const ConnectedJackpots = connect({ poolKeys: PoolKeys })(({ poolKeys }) => (
  <JackpotsComponent poolKeys={poolKeys} />
))

function JackpotsComponent({
  poolKeys,
}: {
  poolKeys: ImmutableList<string> | typeof LoadingInstance | typeof ErroredInstance
}) {
  const testId = { 'data-tid-jackpots-container': '' }
  return (
    <MainContainer {...testId}>
      <ContentHeader title='Jackpots' />
      <JackpotsList poolKeys={poolKeys} />
    </MainContainer>
  )
}

function JackpotsList({
  poolKeys,
}: {
  poolKeys: ImmutableList<string> | typeof LoadingInstance | typeof ErroredInstance
}) {
  if (isLoading(poolKeys)) {
    return (
      <List data-testid='jackpotlist-loading'>
        <ListItem>
          <JackpotLoading />
        </ListItem>
      </List>
    )
  }

  if (isErrored(poolKeys)) {
    return <ErrorMessage>Error loading Jackpots</ErrorMessage>
  }

  if (!poolKeys.size) {
    return <Message>No Jackpots for today</Message>
  }

  const jackpotListItems = poolKeys.map(poolKey => {
    return (
      <ListItem key={poolKey}>
        <Jackpot pool={poolKey} />
      </ListItem>
    )
  })

  return <List>{jackpotListItems}</List>
}
