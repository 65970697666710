import React from 'react'
import { hexColors } from '@mobi/settings'

export const useIncomingIndicator = (
  incomingIdRef: React.MutableRefObject<string | undefined>,
  runnerId: number
) => {
  const runnerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!incomingIdRef.current || !runnerRef.current) return undefined
    const isIncoming = !!(incomingIdRef.current && incomingIdRef.current === runnerId.toString())
    if (!isIncoming) return

    const el = runnerRef.current

    const elementRect = el.getBoundingClientRect()
    window.scrollTo({
      top: elementRect.top + window.pageYOffset - window.innerHeight / 2,
      behavior: 'smooth',
    })

    el.style.background = hexColors.capeHoney
    const timerId = setTimeout(() => {
      el.style.background = ''
    }, 1000)

    window.history.replaceState('', document.title, '/#account/blackbook')
    incomingIdRef.current = undefined

    return () => {
      clearTimeout(timerId)
    }
  }, [incomingIdRef, runnerId])

  return runnerRef
}
