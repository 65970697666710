import React from 'react'
import styled from '@emotion/styled'
import { Pagination } from '@core/Components/Pagination'
import { InputField } from '@mobi/component-library/Common/Input'
import { SelectField } from '@mobi/component-library/Common/Select'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { trackLoginForgotAccountNumberSent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { postDeliverAccountNumber, postDeliveryMethod } from './helpers/api'
import {
  RecoverFooter,
  DeliveryMethodSent,
  DeliveryMethods,
  getDeliveryMethodName,
  type DeliveryMethod,
} from '..'

export const AccountNumberRecovery: React.FC<AccountNumberRecoveryProps> = ({
  handleSignUp,
  handleReturnToLogin,
}) => {
  const [days] = React.useState(generateDays)
  const [months] = React.useState(generateMonths)
  const [years] = React.useState(generateValidYears)

  const [currentStep, setCurrentStep] = React.useState(1)
  const [isBusy, setIsBusy] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>()

  const [emailOrMobile, setEmailOrMobile] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')

  const [dobDay, setDobDay] = React.useState(() => days[0])
  const [dobMonth, setDobMonth] = React.useState(() => months[0])
  const [dobYear, setDobYear] = React.useState(() => years[0])

  const [deliveryMethods, setDeliveryMethods] = React.useState<DeliveryMethod[]>([])
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = React.useState<number>()

  const onDeliverMethodChange: InputChange = e => setSelectedDeliveryMethod(Number(e.target.value))

  const onInputChange: OnInput = setState => e => {
    setErrorMessage(undefined)
    setState(e.target.value)
  }

  const onSelectChange: OnSelect = setState => e => {
    setErrorMessage(undefined)
    setState(e.target.value)
  }

  const handleNextClick = async () => {
    if (currentStep === 1) {
      setIsBusy(true)
      const res = await postDeliveryMethod(
        createQuery({ dobDay, dobMonth, dobYear, emailOrMobile, firstName, lastName })
      )
      setIsBusy(false)
      if (typeof res === 'string') return setErrorMessage(res)
      if (res.deliveryMethods.length === 1) setSelectedDeliveryMethod(res.deliveryMethods[0].id)
      setDeliveryMethods(res.deliveryMethods)
      setCurrentStep(2)
      return
    }

    if (currentStep === 2 && selectedDeliveryMethod) {
      const deliveryMethod = getDeliveryMethod(deliveryMethods, selectedDeliveryMethod)
      setIsBusy(true)
      const res = await postDeliverAccountNumber({
        query: createQuery({ dobDay, dobMonth, dobYear, emailOrMobile, firstName, lastName }),
        deliveryMethod: deliveryMethod,
      })
      setIsBusy(false)
      if (typeof res === 'string') return setErrorMessage(res)

      setCurrentStep(3)
      trackLoginForgotAccountNumberSent(getDeliveryMethodName(deliveryMethod.type))
      return
    }

    if (currentStep === 3) handleReturnToLogin()
  }

  const shouldDisplayBackButton = currentStep === 1
  const isFinalStep = currentStep === 3
  const isValid = isFinalStep || (!isBusy && !!(emailOrMobile && firstName && lastName))

  return (
    <>
      <div>
        <Pagination dotCount={3} index={currentStep - 1} />
      </div>

      {currentStep === 1 && (
        <AccountNumberFieldsetStyled disabled={isBusy}>
          <InputField
            type='text'
            name='Registered Email or Mobile'
            data-testid='email-or-mobile-input'
            value={emailOrMobile}
            onChange={onInputChange(setEmailOrMobile)}
          />

          <InputField
            type='text'
            name='First Name'
            data-testid='first-name-input'
            value={firstName}
            onChange={onInputChange(setFirstName)}
          />

          <InputField
            type='text'
            name='Last Name'
            data-testid='last-name-input'
            value={lastName}
            onChange={onInputChange(setLastName)}
          />

          <DateOfBirthStyled>
            <label>Date of Birth</label>

            <Grid padding='0.5rem'>
              <GridCell>
                <SelectField
                  hideLabel
                  name='Day'
                  options={days}
                  data-testid='dob-day-input'
                  onChange={onSelectChange(setDobDay)}
                  value={dobDay}
                />
              </GridCell>

              <GridCell>
                <SelectField
                  hideLabel
                  name='Month'
                  options={months}
                  data-testid='dob-month-input'
                  onChange={onSelectChange(setDobMonth)}
                  value={dobMonth}
                />
              </GridCell>

              <GridCell>
                <SelectField
                  hideLabel
                  name='Year'
                  options={years}
                  data-testid='dob-year-input'
                  onChange={onSelectChange(setDobYear)}
                  value={dobYear}
                />
              </GridCell>
            </Grid>
          </DateOfBirthStyled>
        </AccountNumberFieldsetStyled>
      )}

      {currentStep === 2 && (
        <>
          <label>Please send my account number to</label>
          <DeliveryMethods
            selectedDeliveryMethod={selectedDeliveryMethod}
            deliveryMethods={deliveryMethods}
            onDeliveryMethodChange={onDeliverMethodChange}
          />
        </>
      )}

      {currentStep === 3 && (
        <DeliveryMethodSent
          deliveryMethods={deliveryMethods}
          selectedDeliveryMethod={selectedDeliveryMethod}
        />
      )}

      {errorMessage && (
        <NoticeBoxSingle title={errorMessage} noticeBoxType={NoticeBoxTypes.Error} hasBorder />
      )}

      <Grid padding='0.5rem'>
        {shouldDisplayBackButton && (
          <GridCell width='50%'>
            <ButtonBlock
              data-testid='account-recovery-back'
              color='secondary'
              onClick={handleReturnToLogin}
              disabled={isBusy}
            >
              Back
            </ButtonBlock>
          </GridCell>
        )}

        <GridCell width={shouldDisplayBackButton ? '50%' : '100%'}>
          <ButtonBlock
            id='submit-next'
            data-testid='account-recovery-next'
            color='primary'
            onClick={handleNextClick}
            disabled={!isValid}
          >
            {isBusy ? <SpinnerInlineStyled size={2} /> : isFinalStep ? 'Log in now' : 'Next'}
          </ButtonBlock>
        </GridCell>
      </Grid>

      <RecoverFooter isSignUpVisible={shouldDisplayBackButton} handleSignUp={handleSignUp} />
    </>
  )
}

// =======
// Helpers
// =======

const generateDays = () => [...Array(31).keys()].map(num => (++num).toString())

const generateMonths = () => [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

function generateValidYears() {
  const max = new Date().getFullYear() - 17
  const min = max - 100
  const years = []
  for (var i = max; i >= min; i--) years.push(i)
  return years.map(year => year.toString())
}

function createQuery(query: CreateQuery): Parameters<typeof postDeliveryMethod>[0] {
  return {
    dobDay: Number(query.dobDay),
    dobMonth: generateMonths().indexOf(query.dobMonth) + 1,
    dobYear: Number(query.dobYear),
    emailOrMobile: query.emailOrMobile,
    firstName: query.firstName,
    lastName: query.lastName,
  }
}

function getDeliveryMethod(
  deliveryMethods: DeliveryMethod[],
  selectedDeliveryMethod: number
): DeliveryMethod {
  return deliveryMethods.find(method => selectedDeliveryMethod === method.id) as DeliveryMethod
}

// ======
// Styles
// ======

const AccountNumberFieldsetStyled = styled('fieldset')({
  'input[type="tel"], input[type="password"], input[type="text"]': {
    fontSize: '1.4rem',
  },

  '& > div': {
    marginBottom: '0.5rem',
  },
})

const DateOfBirthStyled = styled('div')({
  '& > div': {
    display: 'flex',

    '& > div': {
      width: 'calc(100% / 3)',
    },
  },

  select: {
    fontSize: '1.4rem',
  },
})

// =====
// Types
// =====

interface AccountNumberRecoveryProps {
  handleSignUp(): void
  handleReturnToLogin(): void
}

type InputChange = React.ChangeEventHandler<HTMLInputElement>
type OnSelect = (setState: ReactStateFn) => React.ChangeEventHandler<HTMLSelectElement>
type OnInput = (setState: ReactStateFn) => React.ChangeEventHandler<HTMLInputElement>

type DobValues = 'dobDay' | 'dobMonth' | 'dobYear'
type CreateQuery = Omit<Parameters<typeof postDeliveryMethod>[0], DobValues> & {
  [K in keyof Pick<Parameters<typeof postDeliveryMethod>[0], DobValues>]: string
}

type ReactStateFn = React.Dispatch<React.SetStateAction<string>>
