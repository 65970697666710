import { useQuery } from 'react-query'

import { useLogon } from '@core/Utils/hooks'
import { get } from '@classic/Foundation/Services/ApiService'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { logError } from '@core/Utils'

export type InducementEntitlement = {
  Entitlement: {
    BamEntitlementId: string
  }
  IsEntitled: boolean
  ReasonText: string
  HiddenReasonText: string
  Condition: string
  CallToAction: null
}

export const fetchInducements = async (): Promise<InducementEntitlement> => {
  return await get<InducementEntitlement>({ url: '/api/pyos/inducemententitlement' })
}

export const useInducementService = (): boolean => {
  const { isLoggedIn, accountNumber } = useLogon()

  const queryKey = isLoggedIn
    ? [queryKeys.inducement, accountNumber]
    : [queryKeys.inducement, 'logged-out']

  const { isError, error, data } = useQuery(queryKey, fetchInducements, {
    enabled: isLoggedIn !== null,
    staleTime: 60 * 60 * 1000, // 60 min
  })

  if (isError) {
    logError(`Unable to check 'can show inducements' flag: ${(error as Error).message}`)
    return false
  }

  return data?.IsEntitled ?? false
}
