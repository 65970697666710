import { post } from '@classic/Foundation/Services/ApiService'
import type { DeliveryMethod } from '../../Shared/DeliveryMethods/types'

const enum LocalConstants {
  ApiRoot = '/$_/accountrecovery/recoverAccountNumber',
  GenericError = 'Sorry, something went wrong. Please try again.',
  NotFoundError = 'Sorry we could not find your account from the details provided.',
}

export const postDeliveryMethod = (body: PostDeliveryMethodBody) =>
  post<PostDeliveryMethodReturn>({ url: `${LocalConstants.ApiRoot}/deliveryMethods`, body })
    .then(data => {
      if (data.deliveryMethods.length === 0) {
        return "Sorry, we don't have a registered email or mobile number for this account. Please call for assistance."
      }
      return data
    })
    .catch(err =>
      err.response?.status === 409
        ? LocalConstants.NotFoundError
        : err.response?.status === 404
          ? LocalConstants.NotFoundError
          : LocalConstants.GenericError
    )

export const postDeliverAccountNumber = (body: PostDeliverAccountNumber) =>
  post<PostDeliverAccountNumberResponse>({
    url: `${LocalConstants.ApiRoot}/deliverAccountNumber`,
    body,
  })
    .then(res => {
      if (!!res.query?.email || !!res.query?.mobile) {
        return { isSuccessful: true }
      }
      return LocalConstants.GenericError
    })
    .catch(() => {
      return LocalConstants.GenericError
    })

// =====
// Types
// =====

// Step 1

interface PostDeliveryMethodBody {
  dobDay: number
  dobMonth: number
  dobYear: number
  firstName: string
  lastName: string
  emailOrMobile: string
}

type PostDeliveryMethodReturn = Pick<
  PostDeliveryMethodBody,
  'dobDay' | 'dobMonth' | 'dobYear' | 'firstName' | 'lastName' | 'emailOrMobile'
> & {
  deliveryMethods: DeliveryMethod[]
}

// Step 2

interface PostDeliverAccountNumber {
  deliveryMethod: DeliveryMethod
  query: Pick<
    PostDeliveryMethodBody,
    'dobDay' | 'dobMonth' | 'dobYear' | 'emailOrMobile' | 'firstName' | 'lastName'
  >
}
type PostDeliverAccountNumberResponse = Pick<PostDeliverAccountNumber, 'deliveryMethod'> & {
  query: PostDeliverAccountNumber['query'] & { email: string; mobile: string }
}
