import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Comments } from '../Comments'
import { useAddToBlackbook } from '../../Hooks/useAddToBlackbook'
import type { BlackbookControlProps } from '@core/Areas/Blackbook/types'
import { getIconName } from '@core/Areas/Blackbook/helpers'
import { AddJockeyButton } from './Components/AddJockeyButton'

export const BlackbookControl: React.FC<BlackbookControlProps> = React.memo(
  ({ starterName, starterType, jockeyName, source }) => {
    const { matchedRunnerInBlackbook, isLoading, clickHandler } = useAddToBlackbook({
      starterName,
      starterType,
      source,
    })

    const isRunnerInBlackbook = !!matchedRunnerInBlackbook
    const testId = isRunnerInBlackbook ? 'button-go-to-blackbook' : 'button-add-to-blackbook'
    const iconType = isRunnerInBlackbook ? 'tickCircleSolid' : getIconName(starterType)
    const buttonText = isRunnerInBlackbook ? 'See Runner in Blackbook' : 'Add Runner to Blackbook'

    const shouldRenderComments =
      isRunnerInBlackbook && source === 'racecard' && !!matchedRunnerInBlackbook.NumberOfNoteEntries

    return (
      <>
        {shouldRenderComments && (
          <Comments
            runnerId={matchedRunnerInBlackbook.Id}
            type={matchedRunnerInBlackbook.Code}
            name={matchedRunnerInBlackbook.StarterName}
            isExternalDisplay
          />
        )}

        <WrapperStyled>
          <ButtonBlock
            color='link'
            disabled={isLoading}
            data-testid={testId}
            onClick={clickHandler}
          >
            <Icon type={iconType} size='2rem' /> {buttonText}
          </ButtonBlock>

          {jockeyName && <AddJockeyButton jockeyName={jockeyName} source={source} />}
        </WrapperStyled>
      </>
    )
  }
)

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '1.5rem 0',

  // TODO: Make generic button component with this style
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.1rem solid ' + hexColors.studio,
    borderRadius: '0.5rem',

    svg: {
      marginRight: '0.5rem',
    },

    '&:first-of-type': { marginBottom: '1rem' },
    '&:last-of-type': { marginBottom: 0 },
  },
})
