import styled from '@emotion/styled'

export const NotificationButtonStyled = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '3rem',
  width: '3rem',
  padding: 0,
  background: 'none',
  border: 'none',
  borderRadius: '100%',
})
