import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import type { Tabs, Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { TicketFooterStyled as TicketFooter } from './Footer.styles'
import { FooterInvestment, ReBetButton, ShareBet, BlackbookButton } from './Components'
import { useFeature } from '@core/Utils/hooks'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { ReplayInfo, TicketReplayButton } from '@core/Areas/Racing/Components/RaceReplay'
import { TicketReplayVideoPlayer } from './Components/ReplayButton/TicketReplayVideoPlayer'
import { setActiveReplayTicketId } from '@core/Areas/AccountActivity/Store'

export const Footer: React.FC<FooterProps> = ({ ticket, status }) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()
  const ticketDisplayContext = React.useContext(TicketDisplayContext)
  const isPreviewMode = ticketDisplayContext.type === 'share-preview'
  const shouldHideCost = isPreviewMode && ticketDisplayContext.data.shouldHideCost

  const [activeReplayInfo, setActiveReplayInfo] = useState<ReplayInfo | null>(null)
  const isReplayTicketFeatureActive = useFeature('RESULTED_TICKET_REPLAYS')

  const dispatch = useDispatch()

  return (
    <>
      {!shouldHideCost && <FooterInvestment status={status} ticket={ticket} />}

      {!isPreviewMode && (
        <TicketFooter data-tid-footer-container>
          <>
            <ReBetButton activeTab={activeTab} ticket={ticket} />
            <ShareBet ticket={ticket} />
            {(activeTab === 'pending' || activeTab === 'results') && (
              <BlackbookButton ticket={ticket} activeTab={activeTab} />
            )}
            {isReplayTicketFeatureActive && !activeReplayInfo && (
              <TicketReplayButton
                ticket={ticket}
                onPlayTapped={info => {
                  dispatch(setActiveReplayTicketId(ticket.ticketSequence))
                  setActiveReplayInfo(info)
                  trackKey(keys.raceReplayOpened)
                }}
              />
            )}
          </>
        </TicketFooter>
      )}

      {activeReplayInfo && (
        <TicketReplayVideoPlayer
          ticketId={ticket.ticketSequence}
          raceLocation={activeReplayInfo.raceLocation}
          raceDate={activeReplayInfo.raceDate}
          videoSource={activeReplayInfo.videoSource}
          onCloseTapped={() => setActiveReplayInfo(null)}
          onFinished={() => setActiveReplayInfo(null)}
        />
      )}
    </>
  )
}

type FooterProps =
  | {
      ticket: Ticket
      status: TicketStatus
      isPreviewMode?: never
      shouldHideCost?: never
    }
  | {
      ticket: Ticket
      status: TicketStatus
      isPreviewMode: boolean
      shouldHideCost: boolean
    }
