import * as ko from 'knockout'
import { dayjs } from '@mobi/utils'
import { FeatureFlags } from '@mobi/settings'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IRaceDisplayStateManagerViewModel } from './IRaceDisplayStateManagerViewModel'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IStartersService } from '@classic/Betting-v2/Services/IStartersService'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IAppWindow } from '@classic/AppUtils/Framework/WindowManagement/IAppWindow'
import { injectable, inject } from 'inversify'
import { RacePageResponseType } from '@classic/Betting-v2/Types/RacePageResponseType'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { IObservablePage } from '@classic/Betting-v2/Model/Observables/IObservablePage'
import { ErrorHandler } from '@classic/Betting-v2/Utils/ErrorHandler'
import type { IErrorHandler } from '@classic/Betting-v2/Utils/IErrorHandler'
import type { IFormService } from '@classic/Betting-v2/Services/IFormService'
import type { IUrlProvider } from '@classic/Betting-v2/Utils/IUrlProvider'
import { state$ as launchDarkState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { state$ as deviceState$ } from '@core/State/Device'
import {
  subscribe as subscribeToRaceStateEvents,
  unsubscribe as unsubscribeFromRaceStateEvents,
  EventData,
  RaceDelayStatus,
  ToteRaceResultsDelayedPushEvent,
  ToteRaceAbandonedPushEvent,
  ToteRaceStatusChangedPushEvent,
  isToteRaceStatusChangedPushEvent,
  isToteRacePoolCompletedPushEvent,
  isToteRaceResultsDelayedPushEvent,
  isToteRaceAbandonedPushEvent,
  getToteRaceTopics,
  getFobMarketSettlementTopic,
  hasRaceId,
  hasMarketId,
  isFobMarketSettlementPushEvent,
} from '@core/State/PushData'
import { RaceDisplayType } from './RaceDisplayType'

@injectable()
export class RaceDisplayStateManagerViewModel
  extends Disposable
  implements IRaceDisplayStateManagerViewModel
{
  private startersService: IStartersService
  private formService: IFormService
  private errorHandler: IErrorHandler
  private urlProvider: IUrlProvider
  private currentTopics: string[] = []
  private isRaceCloseFeatureActive: boolean = false
  private raceStatusSubscription!: Rx.IDisposable | null
  private ldSubscription!: Rx.IDisposable
  private deviceSubscription!: Rx.IDisposable
  private refetchTimer!: number
  public meetingId!: string
  public raceNumber!: number
  public meetingDate!: Date
  public raceDisplayState!: ko.Observable<string>
  public component!: ko.Computed<string>
  public raceInformation!: IObservablePage
  public ready: ko.Observable<boolean>
  private raceRefreshCommandHandler!: (forceClear: boolean) => void | null

  private appWindow: IAppWindow

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('IStartersService') startersService: IStartersService,
    @inject('IFormService') formService: IFormService,
    @inject('IAppWindow') appWindow: IAppWindow,
    @inject('IErrorHandler') errorHandler: IErrorHandler,
    @inject('IUrlProvider') urlProvider: IUrlProvider
  ) {
    super(eventAggregator)
    this.startersService = startersService
    this.formService = formService
    this.errorHandler = errorHandler
    this.urlProvider = urlProvider
    this.ready = ko.observable<boolean>(false).extend({ notify: 'always' })
    this.appWindow = appWindow
  }

  public init(params: { meetingId: string; raceNumber: number; meetingDate: Date }) {
    this.ready(false)
    Guard.notNull(params)
    Guard.notNull(params.meetingId)
    Guard.greaterThanZero(params.raceNumber)

    this.meetingId = params.meetingId
    this.raceNumber = params.raceNumber
    this.meetingDate = params.meetingDate
    this.raceInformation = new ObservableRacePage() as unknown as IObservablePage
    this.raceDisplayState = ko.observable(RaceDisplayType[RaceDisplayType.Unknown])
    this.loadDataFromApi(this.meetingId, this.raceNumber, this.meetingDate, true)
    this.registerEventHandlers()
    this.ldSubscription = launchDarkState$.subscribe(record => {
      const isRaceCloseFeatureActive = record.features.get(FeatureFlags.PUSHDATA_RACECLOSE.key)

      if (isRaceCloseFeatureActive !== this.isRaceCloseFeatureActive) {
        this.isRaceCloseFeatureActive = isRaceCloseFeatureActive
        this.resubscribeToPushData()
      }
    })

    this.deviceSubscription = deviceState$
      .map(value => ({
        hidden: value.get('hidden'),
        hiddenLastChanged: value.get('hiddenLastChange'),
      }))
      .distinctUntilChanged()
      .pairwise()
      // only trigger refresh if in background > 20s
      .filter(
        data => data[1].hiddenLastChanged - data[0].hiddenLastChanged > 20000 && !data[1].hidden
      )
      .delay(1000)
      .subscribe(() => {
        if (this.raceNumber) {
          this.evtAggregator.publish('race-refresh-command', [this.raceNumber], false, false)
        }
      })

    this.registerDisposals(() => {
      if (this.ldSubscription) {
        this.ldSubscription.dispose()
      }
      if (this.deviceSubscription) {
        this.deviceSubscription.dispose()
      }
      this.unsubscribeFromPushData()
      window.clearInterval(this.refetchTimer)
    })
  }

  protected registerEventHandlers() {
    this.safeSubscribe(
      'race-refresh-command',
      (raceNumbersToRefresh: Array<number>, shouldClearSelections: boolean = false) => {
        this.raceRefreshCommandHandler = (forceClear: boolean) => {
          if (raceNumbersToRefresh != null && raceNumbersToRefresh.length > 0) {
            if (raceNumbersToRefresh.length === 1) {
              this.loadDataFromApi(this.meetingId, this.raceNumber, this.meetingDate, forceClear)
            } else {
              this.refreshDataFromApi(this.meetingId, this.meetingDate, raceNumbersToRefresh)
            }
          }
          if (shouldClearSelections) {
            this.evtAggregator.publish('clear-all-selections-command')
          }
        }

        this.raceRefreshCommandHandler(false)
      }
    )

    this.safeSubscribe('race-navigate-command', (command: { raceNumber: number }) => {
      this.urlProvider
        .getRacePageWebUrl(this.meetingId, this.meetingDate, command.raceNumber)
        .then(url => {
          this.appWindow.changeHashTo(url)
          this.resubscribeToPushData()
        })
    })

    this.safeSubscribe('race-route-updated-command', (command: { raceNumber: number }) => {
      this.loadDataFromApi(this.meetingId, command.raceNumber, this.meetingDate, true)
      // raceNumber is updated in the above function call
      this.resubscribeToPushData()
      window.clearInterval(this.refetchTimer)
    })

    this.safeSubscribe('load-mystery-bet', (command: { raceNumber: number }) => {
      this.evtAggregator.publish('stop-all-race-replay')
      let mysteryDate = dayjs(this.meetingDate).format('YYYY-MM-DD')
      this.appWindow.changeHashTo(
        `#tote/mystery/${this.meetingId}/${command.raceNumber}?date=${mysteryDate}`
      )
    })
  }

  protected loadDataFromApi(
    meetingId: string,
    raceNumber: number,
    meetingDate: Date,
    forceClear: boolean
  ) {
    this.raceNumber = raceNumber
    this.formService.updateQueryCache(meetingId, meetingDate, raceNumber)
    this.fetchDataFromApi(
      this.startersService.retrieveCompleteRace(meetingId, raceNumber, meetingDate),
      forceClear
    )
  }

  protected refreshDataFromApi(meetingId: string, meetingDate: Date, raceNumbers: number[]) {
    const promise = this.startersService.retrieveStartersForRaces(
      meetingId,
      meetingDate,
      raceNumbers
    )
    this.fetchDataFromApi(promise, false)
  }

  protected fetchDataFromApi(promise: Promise<IObservablePage>, forceClear: boolean) {
    this.evtAggregator.publish('stop-all-race-replay')
    this.ready(false)
    promise
      .then(model => {
        if (!forceClear) {
          forceClear = this.raceInformation.pageType !== model.pageType
        }

        switch (model.pageType) {
          case RacePageResponseType.Starters:
            if (model.pageType !== this.raceInformation.pageType) {
              let observableRacePage = new ObservableRacePage()
              observableRacePage.meetingInformation = this.raceInformation.meetingInformation
              this.raceInformation = observableRacePage as unknown as IObservablePage
            }
            this.raceInformation.merge(model, forceClear)
            this.raceDisplayState(RaceDisplayType[RaceDisplayType.Starters])
            break
          case RacePageResponseType.Results:
            if (model.pageType !== this.raceInformation.pageType) {
              let observableResultsPage = new ObservableResultsPage()
              observableResultsPage.meetingInformation = this.raceInformation.meetingInformation
              observableResultsPage.raceNumber(this.raceNumber)
              this.raceInformation = observableResultsPage
            }
            this.raceInformation.merge(model, forceClear)
            ;(this.raceInformation as ObservableResultsPage).raceNumber(this.raceNumber)
            this.raceDisplayState(RaceDisplayType[RaceDisplayType.Results])
            break
          default:
            this.raceDisplayState(RaceDisplayType[RaceDisplayType.Unknown])
            break
        }
        this.ready(true)
        this.resubscribeToPushData()
      })
      .catch(error => {
        if (error.silent) return

        if (!forceClear && this.raceRefreshCommandHandler) {
          this.raceRefreshCommandHandler(true)
          return
        }

        if (
          error &&
          error.response &&
          error.response.status === ErrorHandler.ERROR_CODE_NOT_FOUND
        ) {
          this.errorHandler.showErrorMessage('Error', 'Sorry - race not found.')
          throw error
        } else {
          let errorTitle = 'Error'
          let errorMessage = 'Sorry - something went wrong. Please try again.'

          if (window && window.navigator && !window.navigator.onLine) {
            errorTitle = 'Network Error'
            errorMessage = 'Please check your internet connection.'
          }

          this.errorHandler.showErrorMessage(errorTitle, errorMessage)
          throw error
        }
      })
  }

  private unsubscribeFromPushData() {
    if (this.currentTopics && this.currentTopics.length > 0) {
      unsubscribeFromRaceStateEvents(this.currentTopics)
      this.currentTopics.length = 0
    }
    if (this.raceStatusSubscription) {
      this.raceStatusSubscription.dispose()
      this.raceStatusSubscription = null
    }
  }

  private resubscribeToPushData() {
    this.unsubscribeFromPushData()

    if (this.isRaceCloseFeatureActive) {
      const raceId = this.getRaceKey(this.raceNumber)
      if (raceId && raceId.length > 0) {
        this.currentTopics = getToteRaceTopics(raceId, [
          'resultsDelay',
          'statusChanged',
          'poolCompleted',
          'abandonedChanged',
        ])
      }
      const marketId = this.getMarketId(this.raceNumber)
      if (marketId && marketId.length > 0) {
        this.currentTopics.push(getFobMarketSettlementTopic(marketId))
      }
    }

    if (this.currentTopics.length > 0) {
      let raceState$ = subscribeToRaceStateEvents(this.currentTopics).filter(data => {
        if (!data) {
          return false
        }
        const payload = data.payload
        if (hasRaceId(payload)) {
          const isRaceIdMatched = payload.raceId.toString() === this.getRaceKey(this.raceNumber)
          const canHandle =
            isToteRacePoolCompletedPushEvent(payload) ||
            isToteRaceResultsDelayedPushEvent(payload) ||
            isToteRaceStatusChangedPushEvent(payload) ||
            isToteRaceAbandonedPushEvent(payload)
          return isRaceIdMatched && canHandle
        }

        if (hasMarketId(payload)) {
          return payload.marketId.toString() === this.getMarketId(this.raceNumber)
        }

        return false
      })

      let [poolComplete$, others$] = raceState$.partition(data => {
        return isToteRacePoolCompletedPushEvent(data.payload)
      })

      poolComplete$ = poolComplete$.debounce(4000)
      others$ = others$.debounce(2000)
      raceState$ = others$.merge(poolComplete$)
      this.raceStatusSubscription = raceState$.subscribe(this.handlePushData.bind(this))
    }
  }

  private getRaceKey(raceNumber: number): string {
    var observableRace = this.raceInformation.meetingInformation
      .races()
      .find(x => x.raceNumber() === raceNumber)
    return observableRace != undefined ? observableRace.key() : ''
  }

  private getMarketId(raceNumber: number): string {
    const race = this.raceInformation.meetingInformation
      .races()
      .find(x => x.raceNumber() === raceNumber)

    return race && race.fixedOddsInfo && race.fixedOddsInfo.marketSequence()
      ? race.fixedOddsInfo.marketSequence().toString()
      : ''
  }

  private handlePushData(event: EventData) {
    if (!event || !event.payload) {
      return
    }
    const payload = event.payload

    if (isToteRaceResultsDelayedPushEvent(payload)) {
      return this.handleToteRaceDelayChange(payload)
    }

    if (isToteRaceStatusChangedPushEvent(payload)) {
      return this.handleRaceStatusChange(payload)
    }

    if (isToteRacePoolCompletedPushEvent(payload)) {
      return this.handleTotePoolCompleted()
    }

    if (isToteRaceAbandonedPushEvent(payload)) {
      return this.handleRaceAbandoned(payload)
    }

    if (isFobMarketSettlementPushEvent(payload)) {
      this.handleFobMarketSettlement()
    }
  }

  private handleRaceStatusChange(event: ToteRaceStatusChangedPushEvent) {
    const newRaceStatus = event.status.toLowerCase()

    // Race Closed event is handled in starter page when status is open
    if (newRaceStatus === 'closed' && this.getRaceStatus().toLowerCase() === 'open') {
      return
    }
    const viewModel = this
    this.refreshUntil(() => {
      if (newRaceStatus === 'interim_fourth') {
        if (viewModel.raceDisplayState() === RaceDisplayType[RaceDisplayType.Results]) {
          const resultsInformation = viewModel.raceInformation as ObservableResultsPage
          const simplePlacings = ko.unwrap(resultsInformation.simplePlacings) as unknown as string
          const isFullyResulted = !!simplePlacings && simplePlacings.split(/[,-]/).length === 4
          return isFullyResulted
        }
        return false
      }

      const isStatusMatched = viewModel.getRaceStatus().toLowerCase() === newRaceStatus
      return isStatusMatched
    })
  }

  private handleTotePoolCompleted() {
    this.evtAggregator.publish('race-refresh-command', [this.raceNumber], false, false)
  }

  private handleRaceAbandoned(event: ToteRaceAbandonedPushEvent) {
    this.refreshUntil(() => {
      let raceStatus = this.getRaceStatus().toLowerCase()
      return event.status === 'abandoned' ? raceStatus === 'abandoned' : raceStatus !== 'abandoned'
    })
  }

  private handleFobMarketSettlement() {
    this.evtAggregator.publish('race-refresh-command', [this.raceNumber], false, false)
  }

  private handleToteRaceDelayChange({ status, comment }: ToteRaceResultsDelayedPushEvent) {
    const protestMsg = this.getRaceDelayDisplayText(status, comment)
    if (!!status && this.raceDisplayState() === RaceDisplayType[RaceDisplayType.Results]) {
      ;(this.raceInformation as ObservableResultsPage).protestStatus(protestMsg)
    } else {
      this.refreshUntil(() => {
        if (this.raceDisplayState() === RaceDisplayType[RaceDisplayType.Results]) {
          return (this.raceInformation as ObservableResultsPage).protestStatus() === protestMsg
        }
        return false
      })
    }
  }

  private getRaceDelayDisplayText(status: RaceDelayStatus, comment?: string): string | undefined {
    const mappedRaceDelayStatuses: { [K in RaceDelayStatus]: string | undefined } = {
      holdAllTickets: 'HOLD ALL TICKETS',
      protestPending: 'PROTEST PENDING',
      protestDismissed: 'PROTEST DISMISSED',
      protestUpheld: 'PROTEST UPHELD',
      none: undefined,
    }
    const raceDelayStatus = mappedRaceDelayStatuses[status]
    const raceDelayComment = comment ? ' - ' + comment : ''
    return raceDelayStatus ? raceDelayStatus + raceDelayComment : undefined
  }

  private getRaceStatus() {
    return ko.unwrap(
      this.raceInformation.meetingInformation.selectedRace.raceStatus
    ) as unknown as string
  }

  private refreshUntil(isRefreshed: () => boolean) {
    if (!isRefreshed()) {
      this.evtAggregator.publish('race-refresh-command', [this.raceNumber], false, false)
      if (!isRefreshed()) {
        window.clearInterval(this.refetchTimer)
        const eventReceivedTime = Date.now()
        this.refetchTimer = window.setInterval(() => {
          if (isRefreshed()) {
            window.clearInterval(this.refetchTimer)
            return
          }
          this.evtAggregator.publish('race-refresh-command', [this.raceNumber], false, false)
          const fetchTimeout = 10000
          if (Date.now() > eventReceivedTime + fetchTimeout) {
            window.clearInterval(this.refetchTimer)
          }
        }, 3000)
      }
    }
  }
}
