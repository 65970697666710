import React from 'react'
import { useQuery } from 'react-query'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { SpecialTile, SpecialTileProp } from './SpecialTile'
import { ApiServiceError, fetchFromApi } from '@classic/Foundation/Services/ApiService'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { SpecialsTermsPage } from './SpecialsTermsPage'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import styled from '@emotion/styled'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { GridCell } from '@mobi/component-library/Common/Grid'

export const SpecialsPage = (): JSX.Element | null => {
  const { path } = useRouteMatch()

  return (
    <MainContainer>
      <ContentHeader title='SPECIALS' />
      <Switch>
        <Route exact path={path}>
          <SpecialsTiles />
        </Route>
        <Route path={`${path}/terms`}>
          <SpecialsTermsPage />
        </Route>
        <Route>
          <Redirect to='/specials' />
        </Route>
      </Switch>
    </MainContainer>
  )
}

const SpecialsTiles = () => {
  const { isLoading, isError, data } = useQuery<SpecialTileProp[], ApiServiceError>({
    queryKey: queryKeys.specials,
    queryFn: getSpecials,
    retry: false,
  })

  if (isLoading) {
    return (
      <GridCell padding='0 1rem'>
        <LoadingPlaceholder width='100%' height='7rem' />
        <LoadingPlaceholder width='100%' height='7rem' />
        <LoadingPlaceholder width='100%' height='7rem' />
      </GridCell>
    )
  }

  if (isError) {
    return (
      <SpecialsErrorNoticeBoxStyled>
        <NoticeBox
          title='Unable to display specials at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      </SpecialsErrorNoticeBoxStyled>
    )
  }

  return (
    <>
      <SpecialTile special={superPick as SpecialTileProp} />
      {data?.map((special, index) => {
        return <SpecialTile key={index} special={special} />
      })}
    </>
  )
}

const getSpecials = async (): Promise<SpecialTileProp[]> => {
  const specials = await fetchFromApi('/api/specials/offers')
  return await specials.json()
}

export const SpecialsErrorNoticeBoxStyled = styled('div')({
  padding: '1rem 1rem 0rem',
})

const superPick = {
  title: 'SuperPick',
  description:
    'The power to choose your special. Simply pick your race, pick your runner and pick your special.',
  iconCode: 'specials',
}
