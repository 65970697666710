import { fontSizes } from '../../Settings/fonts'

type Options = {
  roundSizeBeforeCalc?: boolean
}

const defaultOptions: Options = {
  roundSizeBeforeCalc: true,
}

export function calculateRem(sizeInPx: number, options?: Options) {
  if (!isFinite(sizeInPx)) {
    return ''
  }

  const { roundSizeBeforeCalc } = {
    ...defaultOptions,
    ...(options ?? {}),
  }

  if (roundSizeBeforeCalc && sizeInPx % 1 !== 0) {
    sizeInPx = Math.round(sizeInPx)
  }

  const size = sizeInPx / fontSizes.root

  return size !== 0 ? `${size}rem` : 0 // '0' will result in '0px'; we want unitless zero values.
}
