import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '../../../../Components/NoticeBox'

export function InvalidBetsMessage() {
  return (
    <NoticeBox
      title={'Unable to proceed as bets are invalid'}
      noticeBoxType={NoticeBoxTypes.Error}
      hasBorder={false}
    />
  )
}
