import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors } from '@core/Settings'

const SuperPickBadgeStyled = styled('div')({
  minHeight: '2.0rem',
  width: '12rem',
  border: '1px #e8e8e8 solid',
  borderRadius: '0.2rem',
  display: 'flex',
  alignItems: 'center',
  margin: '0.2rem 0',
  padding: '0.2rem',
})

export const SelectedSuperPickBadgeStyled = styled(SuperPickBadgeStyled)({
  backgroundColor: colors.betslip.superPick.selected.background,
  borderColor: colors.betslip.superPick.selected.border,
  justifyContent: 'center',
})

export const AvailableSuperPickBadgeStyled = styled(SuperPickBadgeStyled)<{ isEditable: boolean }>(
  {
    borderColor: colors.betslip.superPick.available.border,
    boxShadow: `0 0.1rem 0.2rem ${colors.betslip.item.boxShadow}`,
  },
  ({ isEditable }) => ({
    boxShadow: !isEditable ? 'none' : undefined,
  })
)

export const DisabledSuperPickBadgeStyled = styled(SuperPickBadgeStyled)({
  color: colors.betslip.superPick.disabled.text,
  backgroundColor: colors.betslip.superPick.disabled.background,
  borderColor: colors.betslip.superPick.disabled.border,
})

export const SuperPickRemovedButtonStyled = styled('button')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: hexColors.black,
  color: hexColors.white,
  cursor: 'pointer',
  border: 0,
  padding: '0',
  borderRadius: '100%',
  width: '1.8rem',
  height: '1.8rem',
})

export const SelectedSuperPickTextStyled = styled('div')({
  fontSize: '1.2rem',
  textAlign: 'center',
  lineHeight: '1.5rem',
  minHeight: '2.0rem',
  width: '9rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
})

export const AvailableSuperPickTextStyled = styled('span')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  marginLeft: '1rem',
})

export const AvailableSuperPickIconStyled = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '1rem',
})
