import {
  depositResponseSchema,
  paymentNonceResponseSchema,
  ZodError,
  type DepositRequest,
  type PaymentNonceResponse,
  type DepositResponse,
  type PaymentNonce,
} from '@mobi/api-types'
import { DepositError } from '../Areas/Deposit/Components'
import { addCrumb, unwrapErrorMessage, coerceIntoError } from '@mobi/utils'
import { reportErrorIfNeeded } from '../Utils/sentry'
import { post, DepositUrl } from './network'

export async function generatePaymentNonce(
  token: string,
  paymentAmount: number,
  transactionId: string
): Promise<PaymentNonceResponse> {
  try {
    const rawResponse = await post<PaymentNonce>(DepositUrl.GeneratePaymentNonce, {
      token,
      amount: paymentAmount,
    })

    const response = paymentNonceResponseSchema.parse(rawResponse)

    if (response.isSuccess) {
      addCrumb('deposit', 'generate card nonce/token success')
    } else {
      reportErrorIfNeeded({
        message: `generate card nonce/token failed: trx:${transactionId}`,
      })
    }

    return response
  } catch (error) {
    reportErrorIfNeeded({
      message: `/generatePaymentMethodNonce exception: ${unwrapErrorMessage(coerceIntoError(error))} trx:${transactionId}`,
    })

    return {
      nonce: '',
      message: null,
      isSuccess: false,
    }
  }
}

export async function deposit<TData extends DepositRequest>(data: TData): Promise<DepositResponse> {
  try {
    const parser = depositResponseSchema.parse
    const response = await post<TData, DepositResponse>(DepositUrl.Deposit, data, parser)

    if (response.isSuccess) {
      addCrumb(
        'deposit',
        `depositing success: type:${data.depositSource} amount:${data.amount} trx:${data.transactionId}`
      )
    } else {
      reportErrorIfNeeded({
        message: `depositing failed: ${JSON.stringify(response)}`,
      })
    }

    return response
  } catch (error) {
    let message: string = 'Unknown error'

    if (error instanceof ZodError) {
      message = 'Invalid server response'
    } else if (error instanceof Error) {
      message = error.message
    }

    reportErrorIfNeeded({ message: `/deposit exception: ${message}` })

    throw DepositError.coerce(message, data.transactionId)
  }
}
