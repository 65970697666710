import CheckBoxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import ExactaValidator from '@classic/Betting-v2/Validation/ExactaValidator'
import CheckBoxSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/CheckBoxSelectionResultProcessor'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'
import type { IConfigureBetType } from './IConfigureBetType'

export default class ExactaConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.Exacta,
      name: 'Exacta',
      supportsFlexi: true,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'exacta-template',
      betSelectionTemplate: 'dual-checkbox-selection-template',
      multiBet: false,
      multiSelections: true,
      checkBoxCount: 2,
      fieldCheckBoxCount: 2,
      sameAsCheckBoxCount: 1,
      processor: new CheckBoxSelectionProcessor(2),
      selectionResultProcessor: new CheckBoxSelectionResultProcessor(2),
      validator: new ExactaValidator(),
      legs: 1,
    })
  }
}
