import React from 'react'
import { toTitleCase } from '@mobi/utils/string'
import { Ticket } from '@core/Areas/AccountActivity/types'
import {
  StarterDisplayStyled as Wrapper,
  StarterAcceptorStyled as Acceptor,
} from './SingleMultiple.styles'
import { getSilk } from '@core/Areas/AccountActivity/helpers'
import { HeaderWrapper } from '../HeaderWrapper'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { StarterHighlightStyled } from './StarterHighlight.styles'
import { getSelectionStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers/betStatus'

export const SingleMultiple: React.FC<{
  leg: Ticket['legs'][0]
  isDoubleOrQuaddie: boolean
}> = ({ leg, isDoubleOrQuaddie }) => {
  const { selections, fixtureId, raceNumber: legRaceNumber, fixtureDate } = leg

  return (
    <Wrapper data-testid='details-selection-info'>
      {selections.map((selection, index) => {
        const {
          raceNumber,
          raceResult,
          header,
          expandedHeader,
          expandedHeaderSecondLine,
          selectionDetails,
        } = selection

        const { silkUrl, silkHeight, silkWidth, isDogsSilk } = getSilk({
          fixtureId,
          fixtureDate,
          raceNumber: raceNumber || legRaceNumber,
        })

        return (
          <HeaderWrapper
            key={index}
            header={expandedHeader || header}
            leg={leg}
            betStatus={isDoubleOrQuaddie ? getSelectionStatus(selection) : null}
            raceNumber={raceNumber}
            subHeader={
              (selectionDetails[0].selectionString
                ?.split(' ')
                .some(a => a.toLowerCase() === 'fd') &&
                selectionDetails[0].selectionString) ||
              expandedHeaderSecondLine
            }
          >
            {selectionDetails[0].acceptorDetails.map(({ number, name }) => {
              const isWinner = isDoubleOrQuaddie && !!(raceResult && raceResult[number - 1] === '1')

              return (
                <Acceptor key={number + name} data-testid='details-selection-info-starter'>
                  <div data-testid='details-selection-info-starter-silk'>
                    <SilkImage
                      url={silkUrl}
                      height={silkHeight}
                      width={silkWidth}
                      isDogsSilk={isDogsSilk}
                      xOffset={number - 1}
                      baseWidth={20}
                    />
                  </div>

                  <strong data-testid='details-selection-info-starter-number'>
                    {number ? (
                      <StarterHighlightStyled isWin={isWinner}>
                        {number}
                        {!isWinner && '.'}
                      </StarterHighlightStyled>
                    ) : (
                      ''
                    )}
                  </strong>
                  <span data-testid='details-selection-info-starter-name'>{toTitleCase(name)}</span>
                </Acceptor>
              )
            })}
          </HeaderWrapper>
        )
      })}
    </Wrapper>
  )
}
