import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import MultiLegValidator from '@classic/Betting-v2/Validation/MultiLegValidator'
import MultiLegSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/MultiLegSelectionResultProcessor'
import type { IConfigureBetType } from './IConfigureBetType'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'

export default class DoubleConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.Double,
      name: 'Double',
      supportsFlexi: true,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'multi-leg-template',
      betSelectionTemplate: 'dual-checkbox-selection-template',
      multiBet: true,
      multiSelections: true,
      processor: new CheckboxSelectionProcessor(1),
      validator: new MultiLegValidator(2),
      selectionResultProcessor: new MultiLegSelectionResultProcessor(),
      checkBoxCount: 1,
      fieldCheckBoxCount: 2,
      sameAsCheckBoxCount: 0,
      legs: 2,
    })
  }
}
