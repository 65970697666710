export class Projection {
  constructor(title: string, data: string) {
    this.title = title
    this.data = data
  }

  title: string
  data: string
}

export class RegularProjection extends Projection {
  constructor(index: number, data: string) {
    let _title: string
    switch (index) {
      case 1:
        _title = '1st'
        break
      case 2:
        _title = '2nd'
        break
      case 3:
        _title = '3rd'
        break
      case 4:
        _title = '4th'
        break
      default:
        _title = ''
    }
    super(_title, data)
  }
}

export class RovingBankerProjection extends Projection {
  constructor(index: number, data: string) {
    const _title = index === 1 ? 'RB' : 'WITH'
    super(_title, data)
  }
}

export class AllwaysProjection extends Projection {
  constructor(index: number, data: string) {
    const _title = index === 1 ? 'ALLWAYS' : ''
    super(_title, data)
  }
}

export class LegInProjection extends Projection {
  constructor(index: number, data: string) {
    const _title = index === 1 ? 'LEG-IN' : 'WITH'
    super(_title, data)
  }
}
