import React from 'react'
import styled from '@emotion/styled'
import { useFloating, autoUpdate, offset, type Placement, shift } from '@floating-ui/react-dom'
import { colors, layering, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  PopoverPresetStylesClass = 'js-popover--preset-style',
}

export const Popover: React.FC<{
  isOpen: boolean
  /** Popover will be placed relative to reference element */
  referenceElRef: React.RefObject<HTMLElement>
  /** Relative to reference, where should popover be placed */
  placement?: Placement
  /** Offset in pixels from reference on placement axis */
  offsetPx?: number
  /** Determines if preset styles should be applied to popover @default true */
  shouldUsePresetStyles?: boolean
}> = ({
  isOpen,
  referenceElRef,
  placement = 'bottom',
  offsetPx = 0,
  shouldUsePresetStyles = true,
  children,
}) => {
  if (!isOpen) return null

  return (
    <PopoverComponent
      referenceElRef={referenceElRef}
      placement={placement}
      offsetPx={offsetPx}
      shouldUsePresetStyles={shouldUsePresetStyles}
    >
      {children}
    </PopoverComponent>
  )
}

// ==========================
// Local Component w/ Content
// ==========================

const PopoverComponent: React.FC<
  Pick<
    React.ComponentProps<typeof Popover>,
    'offsetPx' | 'placement' | 'referenceElRef' | 'shouldUsePresetStyles'
  >
> = ({ referenceElRef, placement, offsetPx, shouldUsePresetStyles, children }) => {
  const { floatingStyles, refs } = useFloating({
    placement,
    middleware: [offset(offsetPx), shift()],
    whileElementsMounted: autoUpdate,
    elements: { reference: referenceElRef.current },
  })

  return (
    <PopoverStyled ref={refs.setFloating} data-testid='Overlay.Popover' style={floatingStyles}>
      <div className={shouldUsePresetStyles ? LocalConstants.PopoverPresetStylesClass : ''}>
        {children}
      </div>
    </PopoverStyled>
  )
}

// ======
// Styles
// ======

const PopoverStyled = styled.div({
  zIndex: layering.pagePopoverElements,

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    animation: 'cubic-bezier(0.165, 0.84, 0.44, 1) animateIn 0.3s forwards',

    [`&.${LocalConstants.PopoverPresetStylesClass}`]: {
      padding: spacing.smx1,
      borderRadius: radius.md,
      backgroundColor: colors.white,
      border: `0.1rem solid ${colors.neutral[200]}`,
      boxShadow: shadow.md,
    },
  },

  '@keyframes animateIn': {
    ['0%']: { transform: 'scale(0.92)', opacity: 0 },
    ['70%']: { opacity: 1 },
    ['100%']: { transform: 'translateY(0)' },
  },
})
