import React from 'react'
import { useQuery } from 'react-query'
import { Link, useRouteMatch } from 'react-router-dom'
import { hexColors } from '@mobi/settings'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { OpenPoolsResponse, getCurrentPools, queryDefaults } from '../../data/toteSports'
import { LoadingBars } from '../LoadingBars'
import { TippingPoolHeader } from '../TippingPoolHeader'
import { SingleOpenPool } from '../SingleOpenPool'
import { RowContainerStyled } from '../../TippingPage.styles'

export const CurrentPoolList: React.FC<{}> = () => {
  const { url } = useRouteMatch()

  const { isLoading, isError, data } = useQuery<OpenPoolsResponse>({
    ...queryDefaults,
    queryKey: queryKeys.toteSportsCurrentPools,
    queryFn: getCurrentPools,
  })

  if (isLoading) {
    return (
      <div data-testid='current-pool-list-loading'>
        <LoadingBars />
      </div>
    )
  }

  if (isError) {
    return (
      <GridCell padding='1rem'>
        <NoticeBox
          title='Unable to display pools at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Warning}
          hasBorder={true}
        />
      </GridCell>
    )
  }

  if (!data || data?.sports.length === 0) {
    return (
      <GridCell padding='1rem'>
        <NoticeBox
          title='There are no open pools at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Info}
          hasBorder={true}
        />
      </GridCell>
    )
  }

  return (
    <div style={{ background: hexColors.white }}>
      {data.sports.map(sport => (
        <div key={sport.key}>
          <TippingPoolHeader
            description={sport.description}
            icon={sport.icon}
            data-tid-tote-sport-header=''
          />

          <RowContainerStyled>
            <Grid>
              <GridRow>
                <GridCell width='40%' align='left' />
                <GridCell width='30%' align='left' data-tid-tote-sport-pool-closetime-title=''>
                  Closes at
                </GridCell>
                <GridCell width='10%' align='center' />
                <GridCell width='20%' align='right' data-tid-tote-sport-pool-total-title=''>
                  Pool Total
                </GridCell>
              </GridRow>
            </Grid>
          </RowContainerStyled>

          {sport.pools.map(pool => (
            <Link
              key={pool.key}
              style={{ textDecoration: 'none' }}
              to={`${url}/${sport.icon.toLowerCase()}/${pool.key}`}
            >
              <SingleOpenPool
                key={pool.key}
                name={pool.name}
                nameSuffix={pool.nameSuffix}
                numberOfGames={pool.numberOfGames}
                scheduledCloseTime={pool.scheduledCloseTime}
                grossJackpot={pool.grossJackpot}
                total={pool.total}
              />
            </Link>
          ))}
        </div>
      ))}
    </div>
  )
}
