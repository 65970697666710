import React from 'react'
import * as immutable from 'immutable'
import { EstimatedReturnHeadingStyled } from './EstimatedReturn.styles'
import { Money } from '@core/Components/Text/Money'
import { observeImmutable } from '@core/Components/HOCs'
import { state$, MultiInvestmentRecord, MultiInvestment } from '../../driver'
import { calculateEstReturn } from '../../helpers/calculator'
import { getBetsToPlace, getBetsInMulti, isValidMulti } from '../../helpers/state'

interface EstimatedReturnProps {
  estimatedReturn: number
}

export function EstimatedReturnComponent({ estimatedReturn }: EstimatedReturnProps) {
  const displayValue = Number.isFinite(estimatedReturn) && estimatedReturn > 0
  return (
    <EstimatedReturnHeadingStyled>
      Est. Return{' '}
      <span>{displayValue ? <Money amount={estimatedReturn} /> : <span>N/A</span>}</span>
    </EstimatedReturnHeadingStyled>
  )
}

export const EstimatedReturn = observeImmutable(
  state$,
  ({ record: { items, multiInvestment, multiReceipt } }) => {
    const multiInvestmentJS: MultiInvestment = (multiInvestment as MultiInvestmentRecord).toJS()
    const multiItems = getBetsInMulti(items)
    const placeableItems = getBetsToPlace(items)
    const placeableMultis =
      !multiReceipt && isValidMulti(multiInvestmentJS, null, multiItems)
        ? multiItems
        : immutable.List([])
    const estimatedReturn = calculateEstReturn(placeableItems, placeableMultis, multiInvestmentJS)
    return <EstimatedReturnComponent estimatedReturn={estimatedReturn} />
  }
)
