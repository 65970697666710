import * as ko from 'knockout'
import { ExpandPanelViewModel } from './ExpandPanelViewModel'
import template from './expandPanel.tpl.html'

if (!ko.components.isRegistered('expand-panel')) {
  ko.components.register('expand-panel', {
    viewModel: {
      createViewModel: function (
        params: ConstructorParameters<typeof ExpandPanelViewModel>[0],
        info: {
          element: ConstructorParameters<typeof ExpandPanelViewModel>[1]
          templateNodes: ConstructorParameters<typeof ExpandPanelViewModel>[2]
        }
      ) {
        return new ExpandPanelViewModel(params, info.element, info.templateNodes)
      },
    },
    template,
  })
}
