import React from 'react'
import dayjs from 'dayjs'
import { ExpandablePanelThemes } from '@core/Components/ExpandablePanel/themes'
import { RaceCardIcon } from '@core/Components/Icons'
import { CompactPanel } from '@core/Components/Panel'
import { useStewardsComments } from '@core/Areas/Racing/Hooks/useStewardsComments'
import { StewardsCommentsStyled, StewardsCommentTextStyled } from './StewardsComments.styles'

type Props = {
  meetingId: string
  meetingDate: Date
  raceNumber: number
}

const StewardsComments = ({ meetingId, meetingDate, raceNumber }: Props): JSX.Element | null => {
  const { hasValidStewardsComments, stewardsComments: comments } = useStewardsComments({
    meetingId,
    meetingDate,
    raceNumber,
  })

  if (!hasValidStewardsComments) return null

  return (
    <CompactPanel
      headerIcon={<RaceCardIcon icon='chat' />}
      headerText='Stewards Comments'
      themeName={ExpandablePanelThemes.lavender}
    >
      <StewardsCommentsStyled>
        {comments.map(({ acceptorName, comment, timestamp }, i) => (
          <li key={i}>
            <StewardsCommentTextStyled>
              <strong>{acceptorName}:</strong> {comment}{' '}
              <time dateTime={timestamp}>{dayjs(timestamp).format('DD MMM YY[.]')}</time>
            </StewardsCommentTextStyled>
          </li>
        ))}
      </StewardsCommentsStyled>
    </CompactPanel>
  )
}

export default StewardsComments
