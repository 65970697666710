import React from 'react'
import type { Acceptor } from '@mobi/betslip/types'
import { colors } from '@core/Settings'
import { SilkImageFlexibleStyled } from './QuickbetSilkImage.styles'

interface SilkImageProps {
  acceptor?: Acceptor
}

export const QuickbetSilkImage: React.FC<SilkImageProps> = ({ acceptor }) => {
  if (!acceptor) {
    return null
  }

  const { imageUrlWithSizes, number: acceptorNumber } = acceptor
  if (!imageUrlWithSizes || !imageUrlWithSizes.large) {
    return null
  }

  // We always use the large size in order to scale it down for retina devices
  const {
    url,
    size: { width, height },
  } = imageUrlWithSizes.large
  if (!(url && width && height)) {
    return null
  }

  // Starter numbers start at 1, but the offset has to begin at 0
  const xOffset = acceptorNumber - 1

  return (
    <SilkImageFlexibleStyled
      sprite={{ url, width, height, xOffset }}
      size={30}
      padding={6}
      backgroundColor={colors.quickbet.silk.background}
    />
  )
}
