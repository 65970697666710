import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'

type BadgeColor = 'gray' | 'green' | 'yellow' | 'red' | 'blue' | 'purple'

/**
 * A badge that displays some text
 */
export const Badge: React.FC<{
  children: ReactNode
  color?: BadgeColor
}> = ({ color = 'gray', children }) => <BadgeStyled badgeColor={color}>{children}</BadgeStyled>

// Styles

const BadgeStyled = styled.span<{ badgeColor: BadgeColor }>(
  {
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 ' + spacing.smx1,

    borderRadius: radius.lgx2,
    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,
    letterSpacing: font.size.md.letterSpacing,
    fontWeight: font.weight.medium,
  },
  ({ badgeColor }) => {
    switch (badgeColor) {
      case 'gray':
        return {
          color: colors.neutral['900'],
          backgroundColor: colors.neutral['200'],
        }
      case 'green':
        return {
          color: colors.success['600'],
          backgroundColor: colors.success['50'],
        }
      case 'yellow':
        return {
          color: colors.chocolate['600'],
          backgroundColor: colors.warning['50'],
        }
      case 'red':
        return {
          color: colors.error['600'],
          backgroundColor: colors.error['50'],
        }
      case 'blue':
        return {
          color: colors.mariner['600'],
          backgroundColor: colors.mariner['50'],
        }
      case 'purple':
        return {
          backgroundColor: colors.lavender['50'],
          color: colors.lavender['700'],
        }
    }
  }
)
