import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

interface ListBadgeProps {
  color: string
}

export const ListBadgeStyled = styled('div')<ListBadgeProps>(
  {
    color: hexColors.white,
    padding: '0.2em',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 1,
    width: '3em',
    height: '3em',
    display: 'table',
    boxSizing: 'border-box',
  },

  ({ color }) => ({
    backgroundColor: color || hexColors.black,
  })
)

interface ListBadgeInnerProps {
  prefix: string
}

export const ListBadgeInnerStyled = styled('div')<ListBadgeInnerProps>(
  {
    fontSize: '1.4em',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
  },

  ({ prefix }) => ({
    '&::before': {
      content: `"${prefix || ''}"`,
      fontSize: '0.5em',
      lineHeight: 1,
      display: 'block',
    },
  })
)
