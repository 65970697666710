import React from 'react'
import ReactDOM from 'react-dom'
import * as TabTouch from '@classic/Foundation/TabTouch'
import { SubApplicationHandler, RouteResult } from '../Navigation/SubAppRouter'
import { DepositLimits } from '@core/Areas/DepositLimits'
import { showLogin, getIsLoggedOn } from '@core/Areas/Login/helpers'

export class DepositLimitsEditHandler implements SubApplicationHandler {
  async enter(url: string): Promise<RouteResult> {
    this.leave()

    const isLoggedIn = await getIsLoggedOn()
    if (isLoggedIn) {
      // Sets the back btn url and changes from i to < -- do this BEFORE setting ID below
      TabTouch.initCommon('#')
      document.body.id = 'deposit-limits-edit' // For styling - ID is reset when you navigate away
      document.body.scrollIntoView()

      const accountHolderNumberPattern = /\d+$/
      const accountHolderNumberString = accountHolderNumberPattern.test(url)
        ? url.match(accountHolderNumberPattern)?.[0]
        : undefined
      const accountHolderNumber = Number(accountHolderNumberString)

      if (!accountHolderNumber) {
        return RouteResult.IsMatchError
      }

      ReactDOM.render(
        <DepositLimits selectedAccountHolderNumber={accountHolderNumber} mode='edit' />,
        document.getElementById('main-container')
      )
    } else {
      showLogin({
        onLoginSuccessRedirectUrl: window.location.hash,
        onLoginCancelledRedirectUrl: '/#',
      })
    }

    return RouteResult.IsMatchSuccess
  }

  leave(): void {
    // Clear body id as nav away does not guarantee it is cleared
    document.body.id = ''

    const container = document.getElementById('main-container')

    if (container) {
      ReactDOM.unmountComponentAtNode(container)
    }
  }
}
