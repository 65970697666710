import { hexColors } from '@mobi/settings'
import styled from '@emotion/styled'

type Props = {
  showPlusIcon?: boolean
}

export const BadgeStyled = styled('span')<Props>(
  {
    display: 'inline-block',
    padding: '0.2rem 1rem 0 1rem',
    borderRadius: '2rem',
    marginRight: '0.5rem',
    textAlign: 'center',
    backgroundColor: hexColors.deepLilac,
    color: hexColors.white,
  },
  ({ showPlusIcon = false }) => ({
    fontSize: showPlusIcon ? '1rem' : '1.2rem',
  })
)
