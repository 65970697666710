import template from './race.sub.header.tpl.html'
import * as registration from '../../../../AppUtils/Framework/Components/koComponentRegistration'
import container from '../../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { RaceSubHeaderViewModel } from './RaceSubHeaderViewModel'

export function register(): void {
  registration.registerComponentWithParams<RaceSubHeaderViewModel>({
    componentName: 'race-sub-header',
    template,
    container,
    resolver: (k: interfaces.Container) => k.get<RaceSubHeaderViewModel>('RaceSubHeaderViewModel'),
  })
}
