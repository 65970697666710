type Environment = 'production' | 'testing' | 'dev'

const PRODUCTION_HOST_NAMES = [
  'tabtouch.mobi',
  'deploy.tabtouch.mobi',
  'tabtouch.com.au',
  'deploy.tabtouch.com.au',
]

export function getCurrentEnvironment(): Environment {
  const hostname = window.location.hostname.replace(/^www\./i, '').toLowerCase()

  if (PRODUCTION && PRODUCTION_HOST_NAMES.includes(hostname)) {
    return 'production'
  }

  if (hostname === 'localhost') {
    return 'dev'
  }

  return 'testing'
}
