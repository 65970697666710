import React from 'react'
export const useDisableEdgeBackNav = () => {
  React.useEffect(() => {
    const touchMove = (ev: TouchEvent) => {
      if (ev.touches.length === 1) {
        const touch = ev.touches[0]
        // Prevent default touch left edge swipe
        if (touch.clientX < window.innerWidth * 0.05) {
          if (ev.cancelable) {
            ev.preventDefault()
          }
        }
      }
    }

    const eventListenerOptions: AddEventListenerOptions = { passive: false }
    window.addEventListener('touchstart', touchMove, { passive: false })

    return () => window.removeEventListener('touchstart', touchMove, eventListenerOptions)
  }, [])
}
