export enum RouteResult {
  IsMatchSuccess,
  IsMatchError,
  NoMatch,
}

export interface SubApplicationHandler {
  enter: (url: string) => Promise<RouteResult>
  leave: (leaveToUrl: string) => void
}

class SubAppRouteEntry {
  public pattern: string | string[]
  public handler: SubApplicationHandler

  constructor(thePattern: string | string[], theHandler: SubApplicationHandler) {
    this.pattern = thePattern
    this.handler = theHandler
  }

  public isMatch(url: string): boolean {
    if (Array.isArray(this.pattern)) {
      return this.pattern.some(string => RegExp(string, 'i').test(url))
    }
    return new RegExp(this.pattern, 'i').test(url)
  }
}

export class SubAppRouteManager {
  private routes: Array<SubAppRouteEntry> = []
  private activeRoute: SubAppRouteEntry | null = null

  public async execute(url: string, routingStarted?: () => void): Promise<RouteResult> {
    const newRoute = this.routes.find(route => route.isMatch(url))

    if (this.activeRoute && newRoute !== this.activeRoute) {
      this.unloadAll(url)
    }

    if (newRoute) {
      if (routingStarted) {
        routingStarted()
      }

      let navResult = await newRoute.handler.enter(url)

      if (navResult === RouteResult.IsMatchSuccess) {
        this.activeRoute = newRoute
      }

      return navResult
    }

    return RouteResult.NoMatch
  }

  public defineRoute(pattern: string | string[], handler: SubApplicationHandler): void {
    this.routes.unshift(new SubAppRouteEntry(pattern, handler))
  }

  public unloadAll(leaveToUrl?: string): void {
    if (this.activeRoute) {
      this.activeRoute.handler.leave(leaveToUrl || '')
      this.activeRoute = null
    }
  }
}
