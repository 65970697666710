import * as ko from 'knockout'
import { IStartersPageViewModel } from './IStartersPageViewModel'
import container from '../../../IOC/inversify.config'
import template from './starters.tpl.html'
import '../../../../AppUtils/Framework/CustomBindings/StickyBinding'
import ObservableRacePage from '@classic/Betting-v2/Model/Observables/ObservableRacePage'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'

export function register(): void {
  if (!ko.components.isRegistered('starters-page')) {
    ko.components.register('starters-page', {
      viewModel: {
        createViewModel: (params: {
          raceInformation: ObservableRacePage
          bettingInformation: BettingInformation
        }): IStartersPageViewModel => {
          const viewModel = container.get<IStartersPageViewModel>('IStartersPageViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
