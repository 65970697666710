import React from 'react'
import styled from '@emotion/styled'
import { Toggle } from '@core/Components/Toggle/Toggle'

interface SingleNotificationSettingProp {
  text: string
  isDisabled?: boolean
  toggleState?: boolean
  toggleTitle?: string
  onToggle: () => void
}

export function SingleNotificationSetting({
  text,
  isDisabled = false,
  toggleState = false,
  toggleTitle,
  onToggle,
}: SingleNotificationSettingProp) {
  return (
    <SingleNotificationSettingStyled>
      <SingleNotificationTextContainerStyled>
        <SingleNotificationTextStyled data-tid-notification-text>
          {text}
        </SingleNotificationTextStyled>
      </SingleNotificationTextContainerStyled>
      <SwitchStyled data-tid-notification-toggle={toggleState}>
        <Toggle
          toggledOn={toggleState}
          isDisabled={isDisabled}
          onToggle={onToggle}
          title={toggleTitle}
        />
      </SwitchStyled>
    </SingleNotificationSettingStyled>
  )
}

const SingleNotificationSettingStyled = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SingleNotificationTextContainerStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const SingleNotificationTextStyled = styled('span')({
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
})

const SwitchStyled = styled('div')({
  WebkitTapHighlightColor: 'transparent',
})
