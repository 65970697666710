import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import {
  RegisterRaceNotification,
  UnregisterRaceNotification,
  notificationState$,
} from '@core/State/Device/notificationDriver'
import { isReactNativeAndroid } from '@mobi/web-native-comms/web'
import {
  NotificationTime,
  TimePickerPopover,
  type RaceAlertAnalyticsData,
} from './TimePickerPopover/TimePickerPopover'
import { NotificationButtonStyled } from './NotificationButton.styles'
import {
  ShowNotificationAuthPopup,
  checkNotificationStatusAsync,
} from '@core/Areas/Settings/Components/PayoutNotification/PayoutNotificationPopup'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { useClickOutside } from '@core/Utils/hooks/useClickOutside'
import { useFeature } from '@core/Utils/hooks'
import { buildToteRaceUri } from '@core/Areas/Racing/helpers'

export const NotificationButtonMain: React.FC<{
  raceKey: string
  ast: string
  meetingCode: string
  raceNumber: number
  meetingName: string
  meetingDate: string
  isHeaderPinned?: boolean
}> = ({ raceKey, ast, meetingCode, raceNumber, meetingName, meetingDate, isHeaderPinned }) => {
  const [notificationTimes, setNotificationTimes] = React.useState<string[]>()

  React.useEffect(() => {
    const sub = notificationState$.subscribe(state => {
      const notificationState = state?.toJS()
      if (notificationState) {
        const keys = notificationState?.eventKeys as string[]
        let newNotificationTimes: string[] = []

        keys?.forEach(key => {
          const [keyBeforePipe, timeString] = key.split('|')
          if (keyBeforePipe === raceKey) {
            if (timeString) {
              newNotificationTimes = timeString.split(',')
            }
          }
        })

        setNotificationTimes(newNotificationTimes)
      }
    })

    return () => {
      sub.dispose()
    }
  }, [raceKey])

  if (!notificationTimes) return null

  return (
    <NotificationButton
      raceKey={raceKey}
      ast={ast}
      meetingCode={meetingCode}
      raceNumber={raceNumber}
      meetingName={meetingName}
      meetingDate={meetingDate}
      notificationTimes={notificationTimes.map(nt => parseInt(nt) as NotificationTime)}
      isHeaderPinned={isHeaderPinned}
    />
  )
}

// ===============
// Local Component
// ===============

const NotificationButton: React.FC<
  RaceDetails & {
    notificationTimes: NotificationTime[]
    isHeaderPinned?: boolean
  }
> = ({
  raceKey,
  ast,
  meetingCode,
  raceNumber,
  meetingName,
  meetingDate,
  notificationTimes,
  isHeaderPinned,
}) => {
  const isMultipleRaceAlertsEnabled = useFeature('MULTIPLE_RACE_ALERTS_ENABLED')

  const [selectedTimes, setSelectedTimes] = React.useState<Set<NotificationTime>>(
    () => new Set(notificationTimes)
  )
  const prevSelectedTimes = new Set(selectedTimes)

  const [shouldShake, setShouldShake] = React.useState(false)
  const [isTimeSelectPopoverOpen, setIsTimeSelectPopoverOpen] = React.useState<boolean>(false)

  const isNotificationOn = selectedTimes.size > 0 || notificationTimes.length > 0

  const popoverRef = useRef<HTMLDivElement>(null)

  useClickOutside(popoverRef, () => {
    setIsTimeSelectPopoverOpen(false)
  })

  const analyticsDataRef = React.useRef<RaceAlertAnalyticsData>({
    selectedTimes,
    ast,
    meetingCode,
    raceNumber,
    isHeaderPinned,
  })
  analyticsDataRef.current = { selectedTimes, ast, meetingCode, raceNumber, isHeaderPinned }

  const onNotificationToggle = async (
    action: 'register' | 'deregister',
    _selectedTimes?: Set<NotificationTime>
  ) => {
    const notificationStatus = await checkNotificationStatusAsync()

    if (notificationStatus.status !== 'Authorized') {
      ShowNotificationAuthPopup({
        reason:
          'Notifications must be enabled on TABtouch to set a race alert. Enable notifications then try again.',
        notificationAuthStatus: notificationStatus.status,
      })
      return
    }

    const fcmToken = notificationStatus.fcmRegistrationToken

    if (!fcmToken) {
      RegisterToast({
        message: 'Unable to set alert',
        position: 'top',
        type: 'error',
        timeout: 0,
      })
      return
    }

    let respSuccess = false
    if (action === 'register') {
      respSuccess = await doRegister(
        { raceKey, ast, meetingCode, raceNumber, meetingName, meetingDate },
        fcmToken,
        Array.from(_selectedTimes ?? []).sort((a, b) => a - b),
        isReactNativeAndroid,
        isMultipleRaceAlertsEnabled
      )
    } else if (action === 'deregister') {
      respSuccess = await doDeregister({ raceKey, fcmToken }, isMultipleRaceAlertsEnabled)
    }

    if (!respSuccess) {
      setSelectedTimes(prevSelectedTimes)
      RegisterToast({
        message: 'There was a problem. Please try later.',
        position: 'top',
      })
    }
  }

  const onRegister = (notificationTimes: Set<NotificationTime>) => {
    setShouldShake(true)
    setTimeout(() => setShouldShake(false), 820)
    onNotificationToggle('register', notificationTimes)
  }

  const onDeregister = () => onNotificationToggle('deregister')

  return (
    <div ref={popoverRef}>
      <ShakeWrapperStyled shake={shouldShake}>
        <NotificationButtonStyled
          data-testid='notification-button'
          style={{ background: isTimeSelectPopoverOpen ? colors.neutral[100] : 'none' }}
          onClick={e => {
            e.stopPropagation()
            if (isMultipleRaceAlertsEnabled) {
              setIsTimeSelectPopoverOpen(prev => !prev)
            } else {
              if (isNotificationOn) {
                setSelectedTimes(new Set([]))
                onDeregister()
              } else {
                const defaultTime: Set<NotificationTime> = new Set([5])
                setSelectedTimes(defaultTime)
                onRegister(defaultTime)
              }
            }
          }}
        >
          <Icon
            size='2rem'
            name={isNotificationOn ? 'SolidBellRinging01' : 'LineBellPlus'}
            color={isNotificationOn ? colors.studio[500] : colors.neutral[900]}
          />
        </NotificationButtonStyled>
      </ShakeWrapperStyled>

      {isTimeSelectPopoverOpen && (
        <TimePickerPopover
          data-testid='notification-popover'
          registerAlert={onRegister}
          deregisterAlert={onDeregister}
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          ast={ast}
          popoverRef={popoverRef}
          analyticsDataRef={analyticsDataRef}
        />
      )}
    </div>
  )
}

// =============
// Local Helpers
// =============

function doRegister(
  raceDetails: RaceDetails,
  fcmToken: string,
  minutesBeforeJump: number[],
  isAndroid: boolean,
  isMultipleRaceAlertsEnabled: boolean
) {
  const payload = {
    ...raceDetails,
    fcmToken,
    minutesBeforeJump: minutesBeforeJump.join(','),
    deepLink:
      window.location.origin +
      buildToteRaceUri({
        meetingId: raceDetails.meetingCode,
        meetingDate: raceDetails.meetingDate,
        raceNumber: raceDetails.raceNumber,
      }),
    isAndroid,
  }

  return fetch('/api/notification/register', {
    method: 'post',
    body: JSON.stringify(payload),
  })
    .then(response => {
      if (response.ok) {
        RegisterRaceNotification(`${raceDetails.raceKey}|${minutesBeforeJump.join(',')}`)
        if (!isMultipleRaceAlertsEnabled) {
          RegisterToast({
            message: 'Alert set 5 minutes before start time.',
            position: 'top',
          })
        }
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

function doDeregister(
  raceDeregistration: { raceKey: string; fcmToken: string },
  isMultipleRaceAlertsEnabled: boolean
) {
  return fetch('/api/notification/deregister', {
    method: 'post',
    body: JSON.stringify(raceDeregistration),
  })
    .then(response => {
      if (response.ok) {
        UnregisterRaceNotification(raceDeregistration.raceKey)
        if (!isMultipleRaceAlertsEnabled) {
          RegisterToast({ message: 'Alert has been cancelled.', position: 'top' })
        }
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

// =====
// Style
// =====

const ShakeWrapperStyled = styled.div<{ shake: boolean }>(
  {
    display: 'inline-block',

    '@keyframes shakeAnimation': {
      '10%, 90%': {
        transform: 'translate3d(-0.1rem, 0, 0)',
      },
      '20%, 80%': {
        transform: 'translate3d(0.1rem, 0, 0)',
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-0.1rem, 0, 0)',
      },
      '40%, 60%': {
        transform: 'translate3d(0.1rem, 0, 0)',
      },
    },
  },
  ({ shake }) =>
    shake && {
      animation: 'shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both',
    }
)

// =====
// Types
// =====

interface RaceDetails {
  raceKey: string
  ast: string
  meetingCode: string
  raceNumber: number
  meetingName: string
  meetingDate: string
}
