import styled from '@emotion/styled'
import { newTheme } from '@mobi/component-library/Theme'
import React from 'react'

interface LoadingPlaceholderProps {
  width?: string
  height?: string
  borderRadius?: string
  margin?: string
  testId?: string
}

export const LoadingPlaceholder = ({
  width,
  height,
  borderRadius,
  margin,
  testId,
}: LoadingPlaceholderProps) => {
  return (
    <LoadingPlaceholderStyled
      style={{
        width: width || '100%',
        height: height || '100%',
        minHeight: height,
        borderRadius,
        margin: margin,
      }}
      data-testid={testId}
    />
  )
}

const LoadingPlaceholderStyled = styled.div({
  background: newTheme.colors.neutral[300],
  position: 'relative',
  overflow: 'hidden',

  animation: 'pulse 2s infinite ease-in',

  '@keyframes pulse': {
    '0%': {
      opacity: 0.35,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.35,
    },
  },
})
