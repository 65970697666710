import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { BaseButtonStyled } from '@mobi/component-library/Common/Buttons'

interface BonusBetButtonProps {
  selected: boolean
  disabled?: boolean
}

export const BonusBetButtonStyled = styled(BaseButtonStyled)<BonusBetButtonProps>(
  {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '1rem',
  },

  ({ selected, disabled }) => ({
    background: selected ? hexColors.white : hexColors.orange,
    color: disabled ? 'default' : hexColors.black,
  })
)
