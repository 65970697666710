import { ISelectionResult } from './ISelectionResult'
import Guard from '../../../../AppUtils/Framework/Guard'

export class FobSelectionResult implements ISelectionResult {
  constructor(
    public starterNumber: number,
    public propositionNumber: number,
    public propositionSequence: number,
    public winPrice: string,
    public placePrice: string
  ) {
    Guard.greaterThanZero(starterNumber)
    Guard.greaterThanZero(propositionNumber)
    Guard.greaterThanZero(propositionSequence)
    Guard.stringNotNullOrEmpty(winPrice)
    Guard.stringNotNullOrEmpty(placePrice)

    this.selectionStrings = [starterNumber.toString()]
  }

  public selectionStrings: string[]
}
