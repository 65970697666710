import React, { ComponentProps } from 'react'
import { SvgRaceCardIcon } from './Icons.styles'

type Props = ComponentProps<typeof SvgRaceCardIcon>

export const ChevronDown = ({ size, ...other }: Props) => (
  <SvgRaceCardIcon size={size} {...other}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 7.8487L16.7733 6L10 11.2081L3.22669 6L2 7.8487L10 14L18 7.8487Z'
      fill='black'
    />
  </SvgRaceCardIcon>
)
