import * as ko from 'knockout'
import { range } from '@mobi/utils'
import { store } from '@core/Store'
import Guard from '@classic/AppUtils/Framework/Guard'
import { BetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { getEnhancedBetslipSetting } from '@core/Areas/Settings/Store/selectors'
import { getCurrentBetType } from '@core/Areas/RaceCard/Store/selectors'
import type { ISelectionResult } from '../Components/Core/SelectionResults/ISelectionResult'
import type { IObservableStarter } from './Observables/IObservableStarter'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import { persistedSortOption } from '@classic/Betting-v2/Sorting/PersistedSorting'
import ObservableMeetingInformation from './Observables/ObservableMeetingInformation'
import ObservableSelectedRaceStarters from './Observables/ObservableSelectedRaceStarters'
import ObservableRaceKey from './Observables/ObservableRaceKey'
import BetTypeInformationBuilder from './Betting/BetTypeInformationBuilder'
import BetTypeInformation from './Betting/BetTypeInformation'
import { BetType } from './Betting/BetType'
import { AllUpFormulas } from './AllUpFormulas'
import { InvestmentAmountModel } from './InvestmentAmountModel'
import SameAs from './SameAs'

export class BettingInformation {
  public allUpFormulas: AllUpFormulas
  public betIdentifier?: string
  public field: ko.ObservableArray<ko.Observable<boolean>>
  public fixedPlacesPaying?: number
  public investment1: InvestmentAmountModel
  public investment2: InvestmentAmountModel
  public isBoxed: ko.Observable<boolean>
  public isFixed: ko.Observable<boolean>
  public isLegIn: ko.Observable<boolean>
  public lastSelectionBetType: ko.Observable<BetType | null>
  public legVisible: ko.Observable<number | null>
  public meetingDate: Date
  public meetingId: string
  public meetingInformation: ObservableMeetingInformation | null
  public mergeDone: ko.Observable<boolean>
  public numberOfCombinations: number
  public raceNumber: number
  public responseContent: string
  public rovingBanker: ko.Observable<boolean>
  public rovingBankersSelected: ko.Observable<number>
  public sameAs: Array<Array<SameAs>>
  public selectedBetType: ko.Observable<BetTypeInformation>
  public selectedSpecial: BetSpecialOffer | null
  public selections: ObservableSelectedRaceStarters // starter selections across all races belonging to a specific fixture
  public sortOption: SortOption
  public specialOffers: BetSpecialOffer[]
  private minimumNumberOfFieldsRequired
  public isEnhancedBetslip: ko.Observable<boolean> = ko.observable(false)

  constructor(
    meetingId: string,
    raceNumber: number,
    meetingDate: Date,
    lastSelectedBetType: ko.Observable<BetType | null>,
    betIdentifier?: string,
    fixedPlacesPaying?: number
  ) {
    this.mergeDone = ko.observable(false).extend({ notify: 'always' })

    this.allUpFormulas = new AllUpFormulas()
    this.betIdentifier = betIdentifier != null ? betIdentifier : ''
    this.field = ko.observableArray<ko.Observable<boolean>>([])
    this.fixedPlacesPaying = fixedPlacesPaying != null ? fixedPlacesPaying : 0
    this.investment1 = new InvestmentAmountModel(0.0, 0.0, 99999.5, 0.5, 'Win')
    this.investment2 = new InvestmentAmountModel(0.0, 0.0, 99999.5, 0.5, 'Place')
    this.isBoxed = ko.observable(false)
    this.isFixed = ko.observable(false)
    this.isLegIn = ko.observable(false)
    this.lastSelectionBetType = lastSelectedBetType
    this.legVisible = ko.observable(null)
    this.meetingDate = meetingDate
    this.meetingId = meetingId
    this.meetingInformation = null
    this.numberOfCombinations = 0
    this.raceNumber = raceNumber
    this.responseContent = ''
    this.rovingBanker = ko.observable(false)
    this.rovingBankersSelected = ko.observable(0)
    this.sameAs = []
    this.selectedBetType = ko.observable<BetTypeInformation>(
      BetTypeInformationBuilder.fromUnTyped(
        BetType[lastSelectedBetType() || BetType.WinPlace]
      ).build()
    )
    this.selectedSpecial = null
    this.selections = new ObservableSelectedRaceStarters()
    this.sizeSameAsToBetType()
    this.sortOption = persistedSortOption
    this.specialOffers = []

    this.minimumNumberOfFieldsRequired = 4

    this.mergeDone(true)
  }

  public merge(
    meetingId: string,
    raceNumber: number,
    meetingDate: Date,
    betIdentifier?: string,
    fixedPlacesPaying?: number
  ) {
    this.meetingId = meetingId
    this.meetingDate = meetingDate
    this.raceNumber = raceNumber
    this.betIdentifier = betIdentifier
    this.fixedPlacesPaying = fixedPlacesPaying
  }

  public setSortOption({ property, direction }: SortOption) {
    this.sortOption.property = property
    this.sortOption.direction = direction
  }

  public isAllwaysBet(): boolean {
    if (this.isBoxed()) return true

    if (this.selectedBetType().multiBet()) return false
    if (
      (this.selectedBetType().isExacta() ||
        this.selectedBetType().isTrifecta() ||
        this.selectedBetType().isFirst4()) &&
      this.selections.onlyFirstLegHasSelections(this.raceNumber)
    ) {
      return true
    }

    let checkboxDefaultCount = this.selectedBetType().checkBoxCount()
    //Quinella has 1 as checkbox count as default but if LegIn selected than it should be 2.
    if (this.selectedBetType().isQuinella() && this.isLegIn()) {
      checkboxDefaultCount++
    }

    return this.selections.isAlwaysBet(this.raceNumber, checkboxDefaultCount)
  }

  public checkForAllwaysBet(): boolean {
    return (
      (this.selectedBetType().betType() === BetType.Trifecta ||
        this.selectedBetType().betType() === BetType.First4 ||
        this.selectedBetType().betType() === BetType.Exacta) &&
      !this.isBoxed() &&
      !this.rovingBanker()
    )
  }

  public clearAll() {
    this.clearFieldSelections()
    this.resetSameAsSelections()
    this.rovingBanker(false)
    this.rovingBankersSelected(0)
    this.isLegIn(false)
    this.isBoxed(false)
    this.selectedSpecial = null
    this.specialOffers = []
  }

  public turnOffAllSelections() {
    this.turnOffAllFields()
    this.turnOffAllSameAsSelections()
    this.rovingBanker(false)
    this.rovingBankersSelected(0)
    this.isLegIn(false)
    this.isBoxed(false)
    this.selections.clearAll()
  }

  public toggleRovingBanker() {
    this.isBoxed(false)
    this.isLegIn(false)
    this.clearFieldIfNoRovingBanker()
    this.turnOffAllSameAsSelections()
    this.rovingBankersSelected(0)
    this.rovingBanker(!this.rovingBanker())
  }

  public toggleLegIn() {
    this.isBoxed(false)
    this.rovingBanker(false)
    this.rovingBankersSelected(0)
    this.turnOffFieldAt(0)
    this.turnOffAllSameAsSelections()
    this.isLegIn(!this.isLegIn())
  }

  public toggleBoxed() {
    this.turnOffAllSameAsSelections()
    if (this.isBoxed() || this.isLegIn() || this.rovingBanker()) {
      this.turnOffAllFields()
    } else {
      if (this.fieldSelectedAtAnyIndex()) {
        this.turnOffAllFields()
        this.toggleFieldAt(0)
      }
    }

    this.rovingBanker(false)
    this.rovingBankersSelected(0)
    this.isLegIn(false)

    this.isBoxed(!this.isBoxed())
  }

  public setUpFields() {
    let size = this.selections.legCount()

    if (size < this.minimumNumberOfFieldsRequired) {
      size = this.minimumNumberOfFieldsRequired
    }

    this.field(range(size).map((): ko.Observable<boolean> => ko.observable(false)))
  }

  public clearFieldSelections() {
    this.field([])
  }

  public resetSameAsSelections() {
    this.sameAs = []
  }

  private turnOffAllSameAsSelections() {
    this.sameAs.flat().forEach(sameAs => sameAs.selected(false))
  }

  public clearFieldIfNoRovingBanker() {
    if (this.rovingBankersSelected() === 0) {
      this.turnOffAllFields()
    }
  }

  public turnOffAllFields() {
    this.field().forEach(field => {
      field(false)
    })
  }

  public turnOffFieldAt(index: number) {
    Guard.greaterThanOrEqualZero(index)
    this.field()[index](false)
  }

  public bettingOptionsSelected(): boolean {
    return this.fieldSelectedAtAnyIndex()
  }

  public checkBoxOptionsSelected(): boolean {
    return this.rovingBanker() || this.isLegIn() || this.isBoxed()
  }

  public sizeSameAsToBetType() {
    const size = this.selectedBetType().sameAsCheckBoxCount()

    if (size === 0) return

    this.sameAs = range(1, size + 1).map(row =>
      range(row + 1, size + 2).map(column => new SameAs(size, row, column))
    )
  }

  public fieldSelectedAtIndex(index: number): boolean {
    if (index < 0) return false

    if (index >= this.field().length) return false

    if (!this.field() || this.field().length === 0) return false

    return this.field()[index]()
  }

  public sameAsSelectedAtIndex(column: number): boolean {
    for (let i = 0; i < this.sameAs.length; ++i) {
      for (let j = 0; j < this.sameAs[i].length; ++j) {
        if (this.sameAs[i][j].column === column && this.sameAs[i][j].selected()) return true
      }
    }

    return false
  }

  public sameAsSelectedAtIndexString(column: number): Array<number> {
    let values = []

    for (let i = 0; i < this.sameAs.length; ++i) {
      for (let j = 0; j < this.sameAs[i].length; ++j) {
        if (this.sameAs[i][j].column === column && this.sameAs[i][j].selected())
          values.push(this.sameAs[i][j].row)
      }
    }

    return values
  }

  public getFieldAtIndex(index: number): ko.Observable<boolean> {
    return this.field()[index]
  }

  public fieldSelectedAtAnyIndex(): boolean {
    if (!this.field() || this.field().length === 0) return false

    return this.field().some(selection => selection())
  }

  public sameAsSelectedAtAnyIndex(): boolean {
    if (!this.sameAs || this.sameAs.length === 0) return false

    return this.sameAs.flat().some(sameAs => sameAs.selected())
  }

  public toggleFieldAt(index: number) {
    Guard.greaterThanOrEqualZero(index)

    this.field()[index](!this.field()[index]())
  }

  public toggleSameAs(row: number, column: number) {
    if (!this.sameAs || this.sameAs.length === 0) {
      this.sizeSameAsToBetType()
    }

    this.getSameAtRowAndColumn(row, column).forEach(sameAs => {
      sameAs.selected(!sameAs.selected())
    })
  }

  public turnOffSameAs(row: number, column: number) {
    if (!this.sameAs || this.sameAs.length === 0) {
      this.sizeSameAsToBetType()
    }

    this.getSameAtRowAndColumn(row, column).forEach(sameAs => {
      sameAs.selected(false)
    })
  }

  public sameAsAt(row: number, column: number): SameAs | null {
    const sameAt = this.getSameAtRowAndColumn(row, column)[0]

    return sameAt ?? null
  }

  public turnOffSameAsForColumn(column: number) {
    if (!this.sameAs || this.sameAs.length === 0) {
      this.sizeSameAsToBetType()
    }

    this.getSameAtColumn(column).forEach(sameAs => {
      sameAs.selected(false)
    })
  }

  public turnOffAllwaysField() {
    this.turnOffFieldAt(0)
  }

  public turnOffRovingBankerField() {
    this.turnOffFieldAt(0)
  }

  public turnOffLegInField() {
    this.turnOffFieldAt(0)
  }

  public assignTotalRovingBankers(totalRovingBankers: number) {
    this.rovingBankersSelected(totalRovingBankers)
  }

  public assignMeetingInfo(meeting: ObservableMeetingInformation) {
    Guard.notNull(meeting)
    this.meetingInformation = meeting
  }

  public results(): ISelectionResult {
    return this.selectedBetType().selectionStringProcessor.selections(
      this,
      this.raceNumber,
      this.selections
    )
  }

  public getLegsForProcessing(): Array<{
    raceKey: ko.Observable<ObservableRaceKey>
    starters: ko.ObservableArray<IObservableStarter>
  }> {
    return this.selections.selections().filter(selection => {
      return selection.starters().length > 0
    })
  }

  public resultsForLeg(leg: number): ISelectionResult {
    return this.selectedBetType().selectionStringProcessor.selections(
      this,
      this.selections.getRaceNumberForLeg(leg),
      this.selections
    )
  }

  public updateSelectionsForCurrentRace(updatedStarters: IObservableStarter[]): void {
    this.selections.updateSelectionForRace(this.raceNumber, updatedStarters)
  }

  public updateEnhancedBetslip(isLaunchDarklyFeatureActive: boolean) {
    let enhancedBetslipSetting = getEnhancedBetslipSetting(store.getState())
    let currentBetType = getCurrentBetType(store.getState())

    const useEnhancedBetslip =
      isLaunchDarklyFeatureActive && enhancedBetslipSetting && currentBetType == BetType.WinPlace

    this.isEnhancedBetslip(useEnhancedBetslip)
  }

  private getSameAtRowAndColumn(row: number, column: number): SameAs[] {
    return this.sameAs.flat().filter(sameAs => sameAs.row === row && sameAs.column === column)
  }

  private getSameAtColumn(column: number): SameAs[] {
    return this.sameAs.flat().filter(sameAs => sameAs.column === column)
  }
}
