import type { AllUpSelection, BetSlipItem, BetSelection } from '../types'
import { isToteSelection, isAllUpSelection, isStartingPriceSelection } from './typeGuards'

/** Used by client wrapper to create bet slip item */
export const createBetSlipItem = ({
  bettingType,
  isEachWayAvailable,
  selection,
  selectionDetails,
  shouldAllowPlaceInvestment,
  betSource,
}: BetSelection): BetSlipItem => {
  const isAllowedInMulti = !isToteSelection(selection) && !isStartingPriceSelection(selection)

  return {
    id: Date.now().toString(),
    bettingType,
    isEachWayAvailable,
    isEachWay: false,
    investment: {
      win: { value: 0 },
      place: { value: 0 },
      bonusBet: undefined,
    },
    isSuperPickAvailable: false,
    selection,
    selectionDetails,
    shouldAllowPlaceInvestment,
    numberOfCombinations: isAllUpSelection(selection)
      ? getNumCombinationsSelected(selection.formulas)
      : 1,
    errorMessage: '',
    selectedSuperPickOffer: null,
    isInMulti: isAllowedInMulti,
    multiLegBetType: isAllowedInMulti ? 'W' : undefined,
    multiBetLegError: null,
    specialOffers: [],
    tags: [],
    betSource,
  }
}

// =============
// Local Helpers
// =============

function getNumCombinationsSelected(selections: AllUpSelection['formulas']): number {
  return selections
    .filter(f => f.isSelected)
    .reduce((accumulator, currentValue) => accumulator + currentValue.numberOfCombinations, 0)
}
