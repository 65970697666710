import React from 'react'

export const Visa = () => (
  <svg
    width='22'
    height='8'
    viewBox='0 0 22 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    role='img'
    aria-label='Visa'
  >
    <path
      d='M8.3531 0.46263L5.47281 7.55545H3.59366L2.17627 1.89501C2.09022 1.54645 2.01539 1.41867 1.75369 1.27179C1.32641 1.03256 0.62081 0.808074 0 0.668763L0.0421667 0.46263H3.06704C3.45261 0.46263 3.79922 0.727541 3.88676 1.18579L4.63534 5.29001L6.48538 0.462507L8.3531 0.46263ZM15.716 5.23967C15.7235 3.36767 13.208 3.26456 13.2252 2.42834C13.2306 2.17379 13.4654 1.90323 13.9793 1.83412C14.234 1.79967 14.9358 1.77345 15.7318 2.15156L16.0441 0.647474C15.6163 0.487219 15.066 0.333008 14.3813 0.333008C12.6241 0.333008 11.3876 1.29712 11.3771 2.67756C11.3658 3.69867 12.2598 4.26845 12.9333 4.60779C13.6262 4.95523 13.8587 5.17856 13.856 5.48934C13.8512 5.96512 13.3034 6.17512 12.7916 6.18345C11.898 6.19756 11.3796 5.9339 10.9662 5.73556L10.644 7.28945C11.0594 7.48612 11.8261 7.65767 12.621 7.66634C14.4885 7.66634 15.7102 6.71423 15.716 5.23967ZM20.3558 7.55545H22L20.5648 0.46263H19.0474C18.706 0.46263 18.4182 0.667697 18.2908 0.983008L15.6232 7.55545H17.4899L17.8604 6.49601H20.1412L20.3558 7.55545ZM18.3723 5.04234L19.308 2.37923L19.8465 5.04234H18.3723ZM10.893 0.46263L9.42301 7.55545H7.64542L9.11598 0.46263H10.893Z'
      fill='currentColor'
    />
  </svg>
)
