import React from 'react'
import type { InitialData } from '@mobi/api-types'
import type { DepositErrorReasonWithLoading } from '@core/Areas/QuickDeposit/Hooks/useQuickDepositValidation'
import { messages } from '@mobi/account/Areas/Deposit/Components'
import { toMoney } from '@mobi/utils/money'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

type DepositLimits = InitialData['depositLimits']

export const QuickDepositUnavailableReason = ({
  shortfall,
  reason,
  depositLimits,
}: {
  shortfall: number
  depositLimits?: DepositLimits
  reason: DepositErrorReasonWithLoading
}) => {
  if (reason === 'loading') {
    return (
      <div>
        <LoadingPlaceholder width='100%' height='5rem' />
      </div>
    )
  }

  let message = messages[reason]

  if (depositLimits) {
    const { minimum: min, maximum: max } = depositLimits
    if (reason === 'exceeds_deposit_limit') {
      message = `This bet would require a deposit of ${toMoney(
        shortfall
      )} which exceeds your deposit limit of ${toMoney(max)}`
    }
    if (reason === 'minimum_deposit_exceeds_deposit_limit') {
      message = `This bet would require a minimum deposit of ${toMoney(
        min
      )} which exceeds your deposit limit of ${toMoney(max)}`
    }
  }

  return <p>{`Unable to deposit funds. ${message}.`}</p>
}
