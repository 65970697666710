export function withTimeout<TPromise extends Promise<unknown>, _TResolve = Awaited<TPromise>>(
  promise: TPromise,
  timeoutInMs: number,
  timeoutValue: _TResolve
) {
  const timeout = new Promise<_TResolve>(resolve => {
    window.setTimeout(() => resolve(timeoutValue), timeoutInMs)
  })

  return Promise.race([promise, timeout])
}
