import { injectable } from 'inversify'
import { IFormService } from './IFormService'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getRaceFormDataFromApi } from '@core/Areas/Racing/Hooks/useRaceFormData/api'

@injectable()
export class FormReactQueryService implements IFormService {
  public updateQueryCache(meetingId: string, meetingDate: Date, raceNumber: number): void {
    queryClient.fetchQuery(queryKeys.racePageForm(meetingId, meetingDate, raceNumber), {
      queryFn: () => getRaceFormDataFromApi(meetingId, meetingDate, raceNumber),
      staleTime: Infinity,
    })
  }
}
