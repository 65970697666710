import React from 'react'
import { StructuredSearchSilkImage } from './StructuredSearchSilkImage'
import {
  RacingCode,
  StructuredSearchResult,
} from '@core/Data/StructuredSearch/StructuredSearchResult'
import {
  DriverStyled,
  StructuredSearchResultPrimaryInfoStyled,
  StructuredSearchResultSecondaryInfoStyled,
} from './StructuredSearchResults.styles'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { StructuredLocationTime } from './StructuredLocationTime'
import { StructuredSearchResultPricesStyled } from './StructuredLocationTime.styles'

interface SearchStructuredResultComponentProps {
  result: StructuredSearchResult
  currentDateMilli: number
}

export function StructuredSearchResultComponent({
  result,
  currentDateMilli,
}: SearchStructuredResultComponentProps): JSX.Element {
  let driver = null

  switch (result.acceptor.race.fixture.contestType) {
    case RacingCode.Races:
      if (result.acceptor.riderName && result.acceptor.riderName !== '') {
        driver = (
          <DriverStyled>
            <>J: </>
            {result.acceptor.riderName}
          </DriverStyled>
        )
      }
      break
    case RacingCode.Trots:
      if (result.acceptor.driverName && result.acceptor.driverName !== '') {
        driver = (
          <DriverStyled>
            <>D: </>
            {result.acceptor.driverName}
          </DriverStyled>
        )
      }
      break
    default:
      driver = null
  }

  let trainer = null

  if (result.acceptor.trainerName && result.acceptor.trainerName !== '') {
    trainer = (
      <DriverStyled>
        <>T: </>
        {result.acceptor.trainerName}
      </DriverStyled>
    )
  }

  let spacer = null

  if (driver && trainer) {
    spacer = <> </>
  }

  return (
    <>
      <Grid>
        <GridRow>
          <GridCell>
            <StructuredLocationTime
              locationName={result.acceptor.race.fixture.name}
              raceNumber={result.acceptor.race.number}
              advertisedStartTime={result.acceptor.race.advertisedStartTime}
              currentDateMilli={currentDateMilli}
              code={result.acceptor.race.fixture.contestType}
              status={result.acceptor.race.status}
              scratchedStatus={result.acceptor.scratchedStatus}
            />
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell flexGrow={0} flexBasis='auto'>
            <StructuredSearchSilkImage
              meetingId={result.acceptor.race.fixture.id}
              meetingDate={result.acceptor.race.fixture.date}
              raceNo={result.acceptor.race.number}
              isDogsSilk={result.acceptor.race.fixture.contestType === RacingCode.Dogs}
              isTrots={result.acceptor.race.fixture.contestType === RacingCode.Trots}
              acceptorNumber={result.acceptor.numberOrBox}
            />
          </GridCell>
          <GridCell>
            <StructuredSearchResultPrimaryInfoStyled
              data-tid-search-results-acceptor-number={result.acceptor.numberOrBox}
              data-tid-search-results-acceptor-name={result.acceptor.name}
            >
              {result.acceptor.numberOrBox}
              <> - </>
              {result.acceptor.name}
            </StructuredSearchResultPrimaryInfoStyled>
            <StructuredSearchResultSecondaryInfoStyled>
              {driver}
              {spacer}
              {trainer}
            </StructuredSearchResultSecondaryInfoStyled>
          </GridCell>

          <GridCell width='11.5rem' align='right'>
            {!result.acceptor.fixedOddsPrices ? (
              '-'
            ) : (
              <StructuredSearchResultPricesStyled>
                <span data-tid-structured-search-results-win-dividend=''>
                  {!result.acceptor.fixedOddsPrices.win.isValid
                    ? '-'
                    : result.acceptor.fixedOddsPrices.win?.dividend?.toFixed(2)}
                </span>
                <span data-tid-structured-search-results-place-dividend=''>
                  {!result.acceptor.fixedOddsPrices.place.isValid
                    ? '-'
                    : result.acceptor.fixedOddsPrices.place?.dividend?.toFixed(2)}
                </span>
              </StructuredSearchResultPricesStyled>
            )}
          </GridCell>
        </GridRow>
      </Grid>
    </>
  )
}
