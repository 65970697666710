import React from 'react'
import { KAMBI_WRAPPER_DIV_ID } from '../constants'

// Used to ensure that Kambi is hidden on non Kambi routes
export const useKambiRouteGuard = (): void => {
  React.useEffect(() => {
    document.getElementById(KAMBI_WRAPPER_DIV_ID)?.style.setProperty('display', 'block')

    return () => {
      document.getElementById(KAMBI_WRAPPER_DIV_ID)?.style.setProperty('display', 'none')
    }
  }, [])
}
