import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { INoveltyDividendsViewModel } from './INoveltyDividendsViewModel'
import template from './novelty.dividends.tpl.html'
import NoveltyDividend from '@classic/Betting-v2/Model/NoveltyDividend'

interface Params {
  noveltyDividends: ko.ObservableArray<NoveltyDividend>
}

export function register(): void {
  if (!ko.components.isRegistered('novelty-dividends')) {
    ko.components.register('novelty-dividends', {
      viewModel: {
        createViewModel: (params: Params): INoveltyDividendsViewModel => {
          const viewModel = container.get<INoveltyDividendsViewModel>('INoveltyDividendsViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
