import React from 'react'
import { Observable } from 'rx'
import { observeImmutable } from '../../../../Components/HOCs'
import { notificationData } from './NotificationTypes'
import { NoticeBox } from '../../../../Components/NoticeBox'
// State
import { state$ as quickbetState$, QuickbetState } from '../../driver'
import {
  state$ as userAccountState$,
  UserAccountState,
} from '../../../../State/UserAccount/userAccountDriver'
import { state$ as formulaState$, FormulaState } from '../Formula/driver'
import { state$ as investmentState$, InvestmentState } from '../BetInvestment/betInvestmentDriver'

export type NotificationsProps = Pick<UserAccountState, 'accountBalance' | 'bonusCashBalance'> &
  Pick<
    QuickbetState,
    | 'canBet'
    | 'notificationType'
    | 'notificationTitle'
    | 'notificationSubtitle'
    | 'bettingType'
    | 'selection'
    | 'isEachWay'
    | 'isUsingBonusBet'
    | 'isUsingBonusCash'
  > &
  Pick<FormulaState, 'numberOfCombinationsSelected'> & {
    winInvestment: number
    placeInvestment: number
  }

export function NotificationsComponent({
  accountBalance,
  canBet,
  bettingType,
  notificationType,
  notificationTitle,
  notificationSubtitle,
  numberOfCombinationsSelected,
  selection,
  isEachWay,
  winInvestment,
  placeInvestment,
  isUsingBonusBet,
  isUsingBonusCash,
  bonusCashBalance,
}: NotificationsProps) {
  if (!notificationType) {
    return null
  }

  // Destructure from corresponding notification data object returned from notificationData()
  const {
    icon,
    title = null,
    subtitle = null,
    children = () => null,
  } = notificationData({
    notificationTitle,
    notificationSubtitle,
    accountBalance,
    canBet,
    bettingType,
    winInvestment,
    placeInvestment,
    isEachWay,
    selection,
    numberOfCombinationsSelected,
    isUsingBonusBet,
    isUsingBonusCash,
    bonusCashBalance,
  })[notificationType] // Immediately access property with 'notificationType' key or set it conditionally

  return (
    <NoticeBox title={title} subtitle={subtitle} noticeBoxType={icon} hasBorder={true}>
      {children()}
    </NoticeBox>
  )
}

const notificationState$ = Observable.combineLatest(
  quickbetState$,
  userAccountState$,
  formulaState$,
  investmentState$,
  (quickbetRecord, userAccountRecord, formulaRecord, investmentRecord) => {
    const investmentState = investmentRecord.toJS() as InvestmentState
    return {
      accountBalance: userAccountRecord.accountBalance,
      canBet: quickbetRecord.canBet,
      bettingType: quickbetRecord.bettingType,
      notificationType: quickbetRecord.notificationType,
      notificationTitle: quickbetRecord.notificationTitle,
      notificationSubtitle: quickbetRecord.notificationSubtitle,
      isEachWay: quickbetRecord.isEachWay,
      selection: (quickbetRecord.toJS() as QuickbetState).selection,
      numberOfCombinationsSelected: formulaRecord.numberOfCombinationsSelected,
      winInvestment: investmentState.win.value,
      placeInvestment: investmentState.place.value,
      isUsingBonusBet: quickbetRecord.isUsingBonusBet,
      isUsingBonusCash: quickbetRecord.isUsingBonusCash,
      bonusCashBalance: userAccountRecord.bonusCashBalance,
    }
  }
)

export const Notifications: React.ComponentClass = observeImmutable(
  notificationState$,
  ({ record }) => <NotificationsComponent {...record} />
)

Notifications.displayName = 'Notifications'
