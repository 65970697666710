import React from 'react'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import {
  SameRaceMultiButtonsContainerStyled,
  SameRaceMultiScratchedStyled as SameRaceMultiErrorStyled,
} from './SameRaceMultiButtons.styles'
import { css } from '@emotion/css'

interface SameRaceMultiProp {
  prices: number[]
  shouldDisplayFixedScratched: boolean
  shouldDisplayFixedSuspended: boolean
  makeFobLegSelection(leg: number): void
  isLegSelected(leg: number): boolean
}

export const SameRaceMultiButtons = ({
  prices,
  shouldDisplayFixedScratched,
  shouldDisplayFixedSuspended,
  makeFobLegSelection,
  isLegSelected,
}: SameRaceMultiProp): JSX.Element => {
  const titles = ['WIN', 'TOP 2', 'TOP 3', 'TOP 4']
  const buttons = titles.map((title, index) => (
    <WinPlaceSelection
      key={index}
      title={title}
      priceWin={prices[index]?.toFixed(2)}
      isSelected={isLegSelected(index)}
      onClick={() => makeFobLegSelection(index)}
      arrowLocation='IN'
      buttonStyleOverride={css({ height: '4rem', flex: '1' })}
      centerContent={true}
      isVisible={true}
      isDisabled={false}
      isFavourite={false}
      pricePlace=''
      starterNumber={0}
      favouritePosition={undefined}
    />
  ))

  return (
    <>
      {!shouldDisplayFixedScratched && !shouldDisplayFixedSuspended && (
        <SameRaceMultiButtonsContainerStyled>{buttons}</SameRaceMultiButtonsContainerStyled>
      )}

      {shouldDisplayFixedSuspended && (
        <SameRaceMultiErrorStyled>SUSPENDED</SameRaceMultiErrorStyled>
      )}

      {/* although it shouldn't be possible, guarding against both scratchign and suspended being enabled.. with preference given to suspended */}
      {!shouldDisplayFixedSuspended && shouldDisplayFixedScratched && (
        <SameRaceMultiErrorStyled>SCRATCHED</SameRaceMultiErrorStyled>
      )}
    </>
  )
}
