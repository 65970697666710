import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { newTheme } from '@mobi/component-library/Theme'
import { getPayIndicatorText, setSelectedRace } from '@core/Areas/Racing/helpers'
import { toTitleCase } from '@mobi/utils/string'
import { RaceCodesLowerCase } from '../../Types/MeetingInformation'
import { useRaceList } from '../../Hooks/useRaceList/useRaceList'
import type { RaceListResponse } from '../../Hooks/useRaceList/types'
import { MeetingsDropdown, PayoutInformation, RaceSelection, RaceInfoHeader } from './Components'
import { getRaceLink } from '../../helpers/getRaceLink'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'
import { CHANGE_BET_TYPE_EVENT } from '@core/Areas/RaceCard/constants'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { WeatherIcon } from '../WeatherIcon'
import { SkeletonLoader } from './Components/SkeletonLoader'
import { DateTime } from '@core/Components/DateTime'

export const RaceNavigation: React.FC<{
  raceNumber: number
  meetingId: string
  meetingDate: string
  meetingSeq?: string
  product?: string
  fooPayIndicator?: number
  isRacingWA?: boolean | null
}> = ({ raceNumber, meetingId, meetingDate, meetingSeq, product, fooPayIndicator, isRacingWA }) => {
  const history = useHistory()
  const isAllUpSelected = useIsAllUpSelected()
  const { meeting } = useRaceList(meetingDate, meetingId, meetingSeq, product)

  const races = meeting?.races
  const currentRace = races?.find(race => race.raceNumber === raceNumber)

  const hasData = meeting != undefined && races != undefined && currentRace != undefined
  if (!hasData) return <SkeletonLoader />

  const isTrackConditionKnown = meeting.courseCondition.toLowerCase() !== 'unknown'

  const onRaceSelected = (races: RaceListResponse['races'], raceNumber: number): void => {
    const selectedRace = races.find(race => race.raceNumber === raceNumber)

    if (selectedRace != undefined) {
      // To determine whether we are navigating between FOO and Matched
      const bothTote = !currentRace.fixed && !selectedRace?.fixed
      if (bothTote) setSelectedRace(selectedRace.raceNumber)
      else {
        const selectRaceLink = getRaceLink({
          meetingId: meeting.meetingId,
          raceNumber: selectedRace.raceNumber,
          meetingDate: meeting.meetingDate,
          code: meeting.meetingCode,
          meetingSeq: meeting.fixed?.competitionSequence,
          product: selectedRace.fixed?.product,
          raceSeq: selectedRace.fixed?.eventSequence,
          isFOO: !!selectedRace.fixed,
        })
        history.push(selectRaceLink)
      }
    }
  }

  return (
    <>
      <RaceNavigationStyled>
        <div>
          <MeetingsDropdown
            meetingName={meeting.courseName}
            meetingCode={meeting.meetingCode.toLowerCase() as RaceCodesLowerCase}
            meetingDate={meeting.meetingDate}
          />

          <div>
            <span>
              <DateTime date={new Date(currentRace.advertisedStartTime)} format='ddd MMM D' />
            </span>
            {isTrackConditionKnown && (
              <span data-testid='track-condition'>{toTitleCase(meeting.courseCondition)}</span>
            )}{' '}
            <WeatherIcon courseWeather={meeting.courseWeather} />
          </div>
        </div>

        {!isAllUpSelected && (
          <div style={{ overflow: 'hidden' }}>
            <RaceSelection
              meetingId={meeting.meetingId}
              meetingDate={meeting.meetingDate}
              races={races}
              selectedRace={currentRace.raceNumber}
              onRaceSelected={raceNumber => onRaceSelected(races, raceNumber)}
              firstLegsOfQuaddies={
                new Set(races.filter(race => race.quaddie.includes(1)).map(race => race.raceNumber))
              }
            />
          </div>
        )}
      </RaceNavigationStyled>

      {!isAllUpSelected && (
        <>
          <RaceInfoHeader
            currentRace={currentRace}
            meetingDate={meeting.meetingDate}
            meetingId={meeting.meetingId}
            courseName={meeting.courseName}
            isRacingWA={isRacingWA}
          />

          <PayoutInformation
            totePayoutInformation={getPayIndicatorText(currentRace.totePayIndicator) ?? ''}
            fixedPayoutInformation={
              getPayIndicatorText(fooPayIndicator ?? currentRace.fixedPayIndicator) ?? ''
            }
          />
        </>
      )}

      <hr />
    </>
  )
}

// =============
// Local Helpers
// =============

const useIsAllUpSelected = () => {
  const eventAggregator = useEventAggregator()
  const [isAllUp, setIsAllUp] = React.useState(false)

  React.useEffect(() => {
    eventAggregator.subscribe(CHANGE_BET_TYPE_EVENT, (params: { betType: BetType }) =>
      setIsAllUp(params.betType === BetType.AllUp)
    )
    return () => {
      eventAggregator.unsubscribe(CHANGE_BET_TYPE_EVENT, (params: { betType: BetType }) =>
        setIsAllUp(params.betType === BetType.AllUp)
      )
    }
  }, [eventAggregator])

  return isAllUp
}

// ======
// Styles
// ======

const RaceNavigationStyled = styled.nav({
  paddingTop: '0.5rem',
  color: newTheme.colors.black,
  fontFamily: newTheme.font.family.primary,
  fontSize: newTheme.font.size.sm.fontSize,
  lineHeight: newTheme.font.size.sm.lineHeight,

  // Meeting Dropdown & Weather
  '> div:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: newTheme.spacing.md,
    paddingRight: newTheme.spacing.md,

    '> div:last-of-type': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      '> span:first-of-type': {
        fontWeight: newTheme.font.weight.semibold,
        paddingRight: newTheme.spacing.smx1,
      },
    },
  },

  // Race Number Selection
  '> div:nth-of-type(2)': {
    overflow: 'hidden',
  },

  '& ~ hr:first-of-type': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    border: 0,
    height: '0.05rem',
    borderBottom: '0.05rem solid ' + newTheme.colors.neutral[200],
  },
})
