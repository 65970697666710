import { dayjs } from '../date'

export const creditCardExpirationPattern = /^(\d{1,2})\s*\/\s*(\d{2,4})$/

export function parseExpirationDate(date: string) {
  const matches = date.trim().match(creditCardExpirationPattern)

  if (!matches || matches.length < 3) {
    return undefined
  }

  const [, monthFromCard, yearFromCard] = matches
  const year = yearFromCard.slice(-2) // We only want the trailing digits
  const month = monthFromCard.padStart(2, '0') // Month requires a leading zero

  return dayjs(`${year}-${month}`, 'YY-MM').toDate()
}

export function formatExpirationDate(date: Date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().slice(-2)

  return `${month} / ${year}`
}
