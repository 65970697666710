import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const ReverificationAlertStyled = styled('div')({
  fontSize: '1.4rem',
  padding: '1rem',
  marginBottom: '1rem',
  backgroundColor: hexColors.white,
})

export const ReverificationAlertIconStyled = styled('div')({
  width: '4rem',
})

export const ReverificationAlertMessageStyled = styled('strong')({
  margin: 0,
  textDecoration: 'underline',
  color: hexColors.sushi,
  lineHeight: '3rem',
})
