import React from 'react'
import {
  fetchAccountBalanceAsync,
  fetchCampaignsAsync,
} from '@core/State/UserAccount/async-signals'
import { useObservableImmutable } from '@core/Utils/hooks/useObservableImmutable'
import { state$ as accountState$ } from '@core/Areas/Account/driver'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as featuresState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { FeatureFlags } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

import { FobSpecialToken } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useGetSpecialTokens } from '../AccountDropDown/Components/helpers/useGetSpecialTokens'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { getIsBalanceVisible, getIsDropDownOpen } from '../../Store/selectors'
import styled from '@emotion/styled'
import { colors } from '@mobi/component-library/Theme/Common'
import { Money } from '@core/Components/Text/Money'

type RenderContext = 'app-header' | 'betslip'

const enum LocalConstants {
  betslipHeaderClassname = 'js-balance-pill--betslip',
}

// NOT main export, see below for <BalancePill />
export const BalancePillComponent: React.FC<{
  cashBalance: number
  bonusBalance: number
  bonusesCount: number
  isVisible: boolean
  onWalletToggle: () => void
  renderContext: RenderContext
}> = ({ cashBalance, bonusBalance, bonusesCount, isVisible, onWalletToggle, renderContext }) => {
  const hideDecimalsInCashBalance = cashBalance >= 100000
  const hideDecimalsInBonusBalance = bonusBalance >= 1000

  const balanceToDisplay = hideDecimalsInCashBalance ? Math.floor(cashBalance) : cashBalance
  const bonusBalanceToDisplay = hideDecimalsInBonusBalance ? Math.floor(bonusBalance) : bonusBalance

  return (
    <BalanceContainerStyled
      onClick={renderContext === 'app-header' ? onWalletToggle : undefined}
      className={renderContext === 'betslip' ? LocalConstants.betslipHeaderClassname : ''}
      data-testid='app-header-balance'
    >
      <BalanceSectionStyled
        hasOffers={!!bonusesCount}
        hasBonusBalance={!!bonusBalanceToDisplay}
        isBalanceVisible={isVisible}
      >
        {isVisible ? (
          <>
            <BalanceMoneyStyled
              amount={balanceToDisplay as number}
              decimalPlaces={hideDecimalsInCashBalance ? 0 : 2}
            />
            {!!bonusBalanceToDisplay && (
              <BonusBalanceMoneyStyled
                prefix={'Bonus: '}
                amount={bonusBalanceToDisplay as number}
                decimalPlaces={hideDecimalsInBonusBalance ? 0 : 2}
              />
            )}
          </>
        ) : (
          <Icon type='personMinimal' size='2.5rem' />
        )}
      </BalanceSectionStyled>
      {!!bonusesCount && <BonusSectionStyled>{bonusesCount}</BonusSectionStyled>}
    </BalanceContainerStyled>
  )
}

export const BalancePill: React.FC<{ renderContext: RenderContext }> = ({ renderContext }) => {
  const dispatch = useAppDispatch()

  const {
    accountBalance: balance = 0,
    accountBalanceUpdatedTime: accountBalanceLastUpdated = 0,
    bonusBetBalance = 0,
    bonusCashBalance = 0,
    activeCampaigns,
  } = useObservableImmutable(userAccountState$, [
    'accountBalance',
    'accountBalanceUpdatedTime',
    'bonusBetBalance',
    'bonusCashBalance',
    'activeCampaigns',
  ])

  const { accountDetails } = useObservableImmutable(accountState$, ['accountDetails'])
  const { features } = useObservableImmutable(featuresState$, ['features'])

  const isDropDownOpen = useAppSelector(getIsDropDownOpen)
  const isBalanceVisible = useAppSelector(getIsBalanceVisible)

  const isAccountPushFeatureEnabled = features?.get(FeatureFlags.PUSHDATA_USERINFO.key)
  const accountBalanceTTL = features?.get(FeatureFlags.CONFIG_BALANCE_POLL_INTERVAL.key)

  const superPicksLastUpdated = React.useRef<number | null>(null)
  const standardSuperPickCount = React.useRef(0)
  const bonusSuperPickCount = React.useRef(0)
  const [canShowBonusCount, setCanShowBonusCount] = React.useState(false)
  const {
    tokens,
    errorMessage,
    isLoading: isLoadingSuperPicks,
  } = useGetSpecialTokens(canShowBonusCount)

  React.useEffect(() => {
    if (accountDetails?.get('activeCampaignsCount') != (activeCampaigns?.count() ?? -1)) {
      fetchCampaignsAsync()
    }
  }, [accountDetails, activeCampaigns])

  React.useEffect(() => {
    const bonusCountTimeoutId = window.setTimeout(() => {
      setCanShowBonusCount(true)
    }, 500)
    return () => {
      window.clearTimeout(bonusCountTimeoutId)
    }
  }, [])

  // Timer Setup: Handle Balance Polling Based on Interval
  React.useEffect(() => {
    let balanceTimerId: number

    if (accountBalanceTTL && isBalanceVisible && !isAccountPushFeatureEnabled) {
      if (
        !!accountBalanceLastUpdated &&
        Date.now() - accountBalanceLastUpdated > accountBalanceTTL
      ) {
        fetchAccountBalanceAsync()
      }
      balanceTimerId = window.setInterval(() => {
        if (
          !!accountBalanceLastUpdated &&
          Date.now() - accountBalanceLastUpdated > accountBalanceTTL
        ) {
          fetchAccountBalanceAsync()
        }
      }, accountBalanceTTL)
    }

    return () => {
      window.clearInterval(balanceTimerId)
    }
  }, [isBalanceVisible, accountBalanceTTL, accountBalanceLastUpdated, isAccountPushFeatureEnabled])

  const handleDropDownToggle = () => {
    const accountNumber = accountDetails?.toJS()?.accountNumber
    if (!isDropDownOpen && accountNumber) {
      trackBonusBetEvent(keys.accountDropDownOpened, {
        accountNumber,
        accountBalance: balance,
        bonusBetBalance,
        bonusCashBalance,
      })
    }
    dispatch(toggleIsDropDownOpen())
  }

  const bonusBalance = (bonusBetBalance ?? 0) + (bonusCashBalance ?? 0)
  const campaignsCount = activeCampaigns?.count() || 0

  const standardSuperPicks = tokens.filter(token => !token.IsBonusToken)
  const bonusSuperPicks = tokens.filter(token => token.IsBonusToken)

  const isStandardSuperPickAvailable =
    !errorMessage && standardSuperPicks && standardSuperPicks.length > 0
  const isBonusSuperPicksAvailable = !errorMessage && bonusSuperPicks && bonusSuperPicks.length > 0

  if (!isLoadingSuperPicks) {
    standardSuperPickCount.current = isStandardSuperPickAvailable
      ? standardSuperPicks[0].BetsAvailable
      : 0
    bonusSuperPickCount.current = isBonusSuperPicksAvailable
      ? calculateTotalBonusSuperPicks(bonusSuperPicks)
      : 0
  }

  const totalOffersCount =
    campaignsCount + standardSuperPickCount.current + bonusSuperPickCount.current
  superPicksLastUpdated.current = Date.now()

  return (
    <BalancePillComponent
      renderContext={renderContext}
      cashBalance={balance || 0}
      bonusBalance={bonusBalance}
      bonusesCount={totalOffersCount}
      isVisible={isBalanceVisible}
      onWalletToggle={handleDropDownToggle}
    />
  )
}

// Local Helpers

function calculateTotalBonusSuperPicks(array: FobSpecialToken[]): number {
  return array.reduce((count, { BetsAvailable = 0 }) => BetsAvailable + count, 0)
}

// Styles

export const BalanceContainerStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: '0.5rem',
  height: '3.6rem',
  cursor: 'pointer',

  ['&.' + LocalConstants.betslipHeaderClassname]: {
    marginRight: 0,

    '> div:first-of-type': {
      border: '1px solid ' + colors.lavender[50],
      borderRight: 0,
    },
  },
})

type BalanceSectionProps = {
  hasOffers: boolean
  hasBonusBalance: boolean
  isBalanceVisible: boolean
}

export const BalanceSectionStyled = styled.div(
  ({ hasOffers, hasBonusBalance, isBalanceVisible }: BalanceSectionProps) => {
    return {
      backgroundColor: colors.white,
      color: colors.studio[500],
      display: 'inline-block',
      fontFamily: 'Arial, sans-serif',
      fontWeight: '400',
      textAlign: 'end',
      borderRadius: hasOffers ? '0.4rem 0 0 0.4rem' : '0.4rem',
      padding: isBalanceVisible ? '0.2rem 1rem' : '0.5rem',
      fontSize: hasBonusBalance ? '1.2rem' : '1.4rem',
      lineHeight: hasBonusBalance || !isBalanceVisible ? '1.5rem' : '3rem',
    }
  }
)

export const BonusSectionStyled = styled.div({
  alignSelf: 'stretch',
  borderRadius: '0 0.4rem 0.4rem 0',
  backgroundColor: colors.studio[500],
  color: colors.white,
  display: 'inline-block',
  padding: '0.9rem 0.6rem 0 0.6rem',
  fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
  fontSize: '1.6rem',
  fontWeight: '700',
})

export const BalanceMoneyStyled = styled
  .div({
    margin: 'unset',
    display: 'block',
  })
  .withComponent(Money)

export const BonusBalanceMoneyStyled = styled
  .div({
    color: colors.orange[500],
    margin: 'unset',
    display: 'block',
    whiteSpace: 'nowrap',
  })
  .withComponent(Money)
