import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { keyframes } from '@emotion/react'

export const AccountActivityHeaderDateStyled = styled('div')({
  flex: 1,
  position: 'relative',
  overflowY: 'hidden',
  marginLeft: '1rem',
})

type DateAnimationDirection = 'up' | 'down' | 'neutral'

const animateUp = keyframes({
  '0%': {
    transform: 'translate3d(0, 100%, 0)',
    opacity: 0,
  },
  '90%': {
    opacity: 1,
  },
  '100%': {
    transform: 'translate3d(0, 0, 0)',
  },
})

const animateDown = keyframes({
  '0%': {
    transform: 'translate3d(0, -100%, 0)',
    opacity: 0,
  },
  '90%': {
    opacity: 1,
  },
  '100%': {
    transform: 'translate3d(0, 0, 0)',
  },
})

export const AccountActivityDateStyled = styled('div')<{
  previousDate: string
  direction: DateAnimationDirection
}>(
  {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    fontSize: '1.4rem',
    color: hexColors.black,
    transition: 'transform 1s ease',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '4rem',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      minHeight: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  ({ previousDate, direction }) => {
    if (!previousDate || direction === 'neutral') return undefined

    return {
      '& > span': {
        animation: `${direction === 'down' ? animateDown : animateUp} 0.4s ease-out`,
      },

      '&::after': {
        content: `"${previousDate}"`,
        transform: `translateY(${direction === 'down' ? '100%' : '-100%'})`,
        animation: `${direction === 'down' ? animateUp : animateDown} 0.4s ease-in reverse`,
      },
    }
  }
)
