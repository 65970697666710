import styled from '@emotion/styled'
import { colors } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'

export const TICKET_HEIGHT_TRANSITION_MS = 400

export const DetailsStyled = styled('div')<{ isPreviewMode: boolean }>(
  {
    backgroundColor: hexColors.white,
    overflow: 'hidden',
    transition: `height ${TICKET_HEIGHT_TRANSITION_MS}ms ease`,
    willChange: 'height',

    // Inner Wrap
    '& > div': {
      padding: '1rem 0',
      margin: '0 1rem',
      borderTop: `0.5px solid ${colors.neutral[200]}`,

      '& > div:not(:last-of-type)': {
        marginBottom: '1rem',
      },
    },

    '& table': {
      fontSize: '1.2rem',
      color: hexColors.nero,
      width: '100%',
      padding: 0,
      border: 0,
      borderSpacing: 0,
    },
  },
  ({ isPreviewMode }) => ({
    height: isPreviewMode ? 'auto' : 0,

    ...(isPreviewMode && {
      '& > div:empty': {
        display: 'none',
      },
    }),
  })
)

export const DetailsSecondaryInfoStyled = styled('div')({
  fontSize: '1rem',
  color: hexColors.dimGrey,
  lineHeight: 1.5,

  dl: {
    margin: 0,
  },

  'dl > div': {
    display: 'flex',

    dd: {
      marginLeft: '1rem',
      padding: 0,
    },
  },

  p: {
    fontSize: 'inherit',
    textAlign: 'left',
    margin: '0.5rem 0',
  },

  '& > *:not(:last-child)': {
    marginBottom: '1rem',
  },
})

export const DetailsEventStartNoticeStyled = styled('strong')({
  color: hexColors.summerSky,
})
