import { initializeWithCountIfNotInitialized } from '@mobi/account/Utils/depositCount'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'
import { setIsLoginShown } from './Store'
import { getIsLoginShown } from './Store/selectors'
import { LoginContainerStyled, LoginCoverLoadingStyled } from './Login.styles'
import { useNativeBioAuth, useLoginState } from './hooks'
import { LoginMain, PasswordReset, AccountNumberRecovery } from './Components'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'

export const Login: React.FC<{}> = () => {
  const isLoginShown = useSelector(getIsLoginShown)

  const dispatch = useDispatch()
  React.useEffect(() => {
    const subscription = navigationState$
      .map(navState => navState.toJS().currentUrl)
      .skip(1)
      .distinctUntilChanged()
      .subscribe(() => dispatch(setIsLoginShown({ shouldShow: false })))

    return () => subscription?.dispose()
  }, [dispatch])

  return isLoginShown ? <LoginOverlay /> : null
}

const LoginOverlay = () => {
  const loginContainerRef = useBodyScrollLock()
  const { state, handlers } = useLoginState()

  const isBioLoginLoading = useNativeBioAuth(accountNumber => {
    handlers.performLoginSuccessActions()
    initializeWithCountIfNotInitialized(accountNumber, 2)
  })

  return (
    <LoginContainerStyled data-testid='login-overlay' ref={loginContainerRef}>
      <h1>
        <Icon type='person' size='1.8rem' /> {state.workflow}
        <button type='button' onClick={handlers.close} disabled={isBioLoginLoading}>
          <Icon type='cross' title='Close' />
        </button>
      </h1>

      {isBioLoginLoading ? (
        <LoginCoverLoadingStyled>
          <SpinnerInlineStyled color='light' size={5} />
        </LoginCoverLoadingStyled>
      ) : (
        <div>
          {state.workflow === 'Login' && <LoginMain state={state} handlers={handlers} />}

          {state.workflow === 'Password Reset' && (
            <PasswordReset
              accountNumber={state.accountNumber}
              setWorkflowState={handlers.setWorkflow}
              handleSignUp={handlers.signUp}
            />
          )}

          {state.workflow === 'Account Number Recovery' && (
            <AccountNumberRecovery
              handleSignUp={handlers.signUp}
              handleReturnToLogin={() => handlers.setWorkflow('Login')}
            />
          )}
        </div>
      )}
    </LoginContainerStyled>
  )
}
