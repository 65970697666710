import React, { type ButtonHTMLAttributes, type FC } from 'react'
import styled from '@emotion/styled'
import { spacing } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

export const SetDepositLimitButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick }) => (
  <ButtonStyled size='xs' color='secondary_grey' onClick={onClick}>
    <div>Set a Deposit Limit</div>
    <Icon name='DuocolorArrowNarrowRight' size='1.6rem' />
  </ButtonStyled>
)

const ButtonStyled = styled(Button)({
  div: {
    marginRight: spacing.smx1,
  },
})
