import React from 'react'
import styled from '@emotion/styled'
import { isRacePageDto, RacePageDTO } from '@mobi/api-types'
import { hexColors } from '@mobi/settings'
import { useAppSelector } from '@core/Store/hooks'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'
import { SecondaryHeader } from '../Common/SecondaryHeader'
import { getCurrentBetType } from '../../Store/selectors'

export const SpecialsInfo: React.FC<{
  raceData?: RacePageDTO
}> = ({ raceData }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const currentBetType = useAppSelector(getCurrentBetType)
  const isEntitled = useInducementService()

  if (
    !raceData ||
    !isEntitled ||
    !isRacePageDto(raceData) ||
    currentBetType !== BetType.WinPlace ||
    raceData.MeetingInformation.SelectedRace.RaceStatus === 'Closed' ||
    !raceData.SpecialOffersInfo ||
    !raceData.SpecialOffersInfo.length
  )
    return null

  return (
    <div data-testid='specials-info'>
      <ExpandablePanel
        title=''
        expanded={isExpanded}
        headerComponent={<SecondaryHeader title='Specials Information' isExpanded={isExpanded} />}
        headerClicked={() => setIsExpanded(val => !val)}
      >
        <div style={{ padding: '0.5em', marginBottom: '1.5em' }}>
          <CardStyled>
            <dl>
              {raceData.SpecialOffersInfo.map(({ Title, Description }) => (
                <React.Fragment key={Title}>
                  <dt className='faux-strong' data-tid-special-info-title>
                    {Title}
                  </dt>
                  <dd className='l0' data-tid-special-info-desc>
                    {Description}
                  </dd>
                </React.Fragment>
              ))}
            </dl>
          </CardStyled>
        </div>
      </ExpandablePanel>
    </div>
  )
}

// ======
// Styles
// ======

const CardStyled = styled.div({
  backgroundColor: hexColors.white,
  boxShadow: '0 -1px 0 0 #eee, 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  padding: '0.5rem',
  fontSize: '1.2rem',

  dt: { fontWeight: 'bold' },

  dd: {
    marginLeft: 0,
    marginBottom: '1rem',
    lineHeight: 1.5,
  },
})
