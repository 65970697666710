import Rx from 'rx'
import { BetAccountHolder } from '@mobi/api-types'
import type { BetAccount, BamAccountHolder } from '@mobi/api-types'
import { TypedRecord, recordify } from 'typed-immutable-record'
import { declareResourceType } from 'rwwa-data-access'
import * as apiService from '@classic/Foundation/Services/ApiService'
import { HasLoggedIn } from '@core/State/UserAccount/userAccountDriver'

export type AccountHolder = BetAccountHolder

export interface AccountHolders {
  primaryAccountHolder: AccountHolder
  secondaryAccountHolder: AccountHolder | null
}

interface AccountHoldersRecord extends TypedRecord<AccountHoldersRecord>, AccountHolders {}

const AccountHoldersTypeKey = 'accountHolders'
export const AccountHoldersKey = 'all'

export const AccountHolders = declareResourceType<AccountHolders, unknown>({
  typeKey: AccountHoldersTypeKey,
  // @ts-expect-error Legacy - use better data management solution
  fetchMultipleNoPromise,
  hardTimeToLive: 120000, // 2 minutes
  map: x => recordify<AccountHolders, AccountHoldersRecord>(x as AccountHolders),
})

function fetchMultipleNoPromise(keys: string[]) {
  return Rx.Observable.fromPromise(
    fetchAccountHolders()
      .then(response => ({
        [AccountHoldersTypeKey]: {
          [AccountHoldersKey]: response,
        },
      }))
      .then(result => ({ keys, result }))
      .catch(error => ({ keys, error }))
  )
}

function fetchAccountHolders() {
  return apiService.get<AccountHolders>({ url: `/$_/api/account/accountHolders` })
}

// @ts-expect-error Legacy - use better data management solution
HasLoggedIn.signal$.subscribe(() => AccountHolders.hardInvalidate(null, AccountHoldersKey))

export type UpdateDepositLimitAnniversaryRequest = Pick<BetAccount, 'BetAccountNumber'> &
  Pick<BamAccountHolder, 'AccountHolderId'> & {
    Location: number
    Keep: boolean
  }

export function updateDepositLimitAnniversary(
  model: UpdateDepositLimitAnniversaryRequest
): Promise<{}> {
  return apiService.post<{}>({ url: '/$_/api/account/updateDepositLimitAnniversary', body: model })
}
