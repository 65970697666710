import styled, { CSSObject } from '@emotion/styled'

type Props = {
  gutterSize?: string
}

/**
 * scrollable should be set to true when dealing with scrollable areas
 *
 * @see https://web.archive.org/web/20170707053030/http://www.brunildo.org/test/overscrollback.html
 */
type GapXProps = Props & {
  scrollable?: boolean
}

const DEFAULT_GUTTER_SIZE = '1rem'

export const GapX = styled.div<GapXProps>(props => makeGapXCSS(props))

export const GapY = styled.div<Props>(({ gutterSize = DEFAULT_GUTTER_SIZE }) => ({
  '> *:not(:last-child)': {
    marginBottom: gutterSize,
  },
}))

export function makeGapXCSS({
  gutterSize = DEFAULT_GUTTER_SIZE,
  scrollable = false,
}: GapXProps): CSSObject {
  const margin: CSSObject = {
    marginRight: gutterSize,
  }

  const selector = scrollable ? '> *' : '> *:not(:last-child)'

  return {
    [selector]: margin,
  }
}
