import React from 'react'
import styled from '@emotion/styled'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { callCenterPhoneNumber } from '@mobi/utils'
import { hexColors } from '@mobi/settings'

export const RecoverFooter: React.FC<RecoverFooterProps> = ({ isSignUpVisible, handleSignUp }) => {
  return (
    <RecoverFooterStyled>
      <Grid padding='0.5rem'>
        <GridCell width={isSignUpVisible ? '50%' : '100%'}>
          <a href={callCenterPhoneNumber} data-testid='login-recovery-phone'>
            <span>For assistance, please call</span>
            <span>
              <Icon type='phone' size='inherit' /> <u>1300 36 36 88</u>
            </span>
          </a>
        </GridCell>

        {isSignUpVisible && (
          <GridCell width='50%'>
            <button onClick={handleSignUp} type='button' data-testid='login-recovery-signup'>
              <span>Don't have an account?</span>
              <span>
                <u>Sign up now</u>
              </span>
            </button>
          </GridCell>
        )}
      </Grid>
    </RecoverFooterStyled>
  )
}

// ======
// Styles
// ======

const RecoverFooterStyled = styled('div')({
  margin: '5rem 0 2rem',

  'a, button': {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: hexColors.white,
    textDecoration: 'none',
    width: '100%',
    border: 0,
    background: 0,
    padding: 0,
    margin: 0,

    span: {
      lineHeight: 1,
      display: 'block',
    },

    '& span:nth-of-type(2)': {
      marginTop: '0.5rem',
      fontSize: '1.6rem',
    },

    svg: {
      verticalAlign: 'middle',
    },
  },
})

// =====
// Types
// =====
interface RecoverFooterProps {
  isSignUpVisible: boolean
  handleSignUp(): void
}
