import styled from '@emotion/styled'

export const KeypadWrapperStyled = styled('ul')<{ horizontalPadding?: string }>(
  {
    boxSizing: 'border-box',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',

    display: 'inline-flex',
    flexWrap: 'wrap',
  },

  ({ horizontalPadding }) => ({
    padding: horizontalPadding ? `0 calc(${horizontalPadding} - 0.2rem)` : undefined,
  })
)

interface KeypadItemProps {
  keysPerRow: number
}

export const KeypadItemStyled = styled('li')<KeypadItemProps>(
  {
    boxSizing: 'border-box',
    padding: '0.2rem',
  },

  ({ keysPerRow }) => ({
    width: `calc(100% / ${keysPerRow})`,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: `calc(100% / ${keysPerRow})`,
  })
)
