import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { DividendChange } from '@core/Components/Text/utils'
import { RightArrowIcon } from '@core/Components/Icons'

export const FixedFlucsContainer = styled('div')({
  borderTop: `1px solid ${colors.fixedFlucs.separator}`,
  padding: '0.75rem',
})
interface SingleLegDividendProps {
  dividendChange?: DividendChange
}

export const FixedFlucsDividend = styled('span')<SingleLegDividendProps>(
  {
    display: 'block',
  },
  ({ dividendChange }) => {
    if (dividendChange === DividendChange.Increase || dividendChange === DividendChange.Decrease) {
      return {
        '&::after': {
          content: '" "',
          width: 0,
          height: 0,
          borderLeft: '0.4rem solid transparent',
          borderRight: '0.4rem solid transparent',
          marginBottom: '0.1rem',
          marginLeft: '0.4rem',
          position: 'relative',
          display: 'inline-block',
          borderBottomStyle: 'solid',
          borderBottomWidth: dividendChange === DividendChange.Increase ? '0.6rem' : 0,
          borderTopWidth: dividendChange === DividendChange.Decrease ? '0.6rem' : 0,
          borderTopStyle: 'solid',
          borderTopColor:
            dividendChange === DividendChange.Increase
              ? colors.priceChange.decrease.text
              : colors.priceChange.increase.text,
          borderBottomColor:
            dividendChange === DividendChange.Increase
              ? colors.priceChange.decrease.text
              : colors.priceChange.increase.text,
        },
      }
    }
    return {}
  }
)

export const FixedFlucsDividendLabel = styled('span')({
  color: colors.fixedFlucs.label,
})

export const FixedFlucsSeperator = styled('div')({
  width: '1px',
  borderLeft: `1px solid ${colors.fixedFlucs.dividends.separator}`,
})

export const FixedFlucsChevron = styled(RightArrowIcon)({
  color: colors.fixedFlucs.label,
})
