import styled from '@emotion/styled'
import { ButtonStyled } from '@mobi/component-library/Common/Buttons'
import { Money } from '@core/Components/Text/Money'

export const QuickbetButtonStyled = styled(ButtonStyled)({
  width: '100%',
  height: '4rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  whiteSpace: 'pre-line',
})

export const QuickbetBetButtonStyled = styled(QuickbetButtonStyled)({
  fontSize: '1.3rem',
})

export const QuickbetButtonMoneyStyled = styled(Money)({
  fontSize: '1.5rem',
})

export const QuickbetAddToBetSlipButtonStyled = styled(QuickbetBetButtonStyled)<{
  isInQuickbet?: boolean
}>(
  ({ isInQuickbet = true }) =>
    !isInQuickbet && {
      fontSize: '1.2rem',
    }
)
