import * as ko from 'knockout'
import Guard from '../../../../AppUtils/Framework/Guard'

export default class CheckedValue {
  constructor(value: number, checked: ko.Observable<boolean>, label: string) {
    Guard.greaterThanZero(value)
    Guard.notNull(label)

    this.checked = checked
    this.value = ko.observable(value)
    this.label = ko.observable(label)
    this.visible = ko.observable(true)
  }

  public value: ko.Observable<number>
  public checked: ko.Observable<boolean>
  public label: ko.Observable<string>
  public visible: ko.Observable<boolean>
}
