import React from 'react'
import { Banner } from '@core/Components/Banner'
import { setBackUrl } from '@classic/Foundation/Navigation/Nav'
import { MainContainerStyled } from '@core/Components/Containers/MainContainer.styles'
import { MeetingsList, PrimaryDateNavigation, SecondaryCodeNavigation } from './Components'
import { useMeetingsData } from './hooks'

export const MeetingsPage: React.FC<{}> = () => {
  React.useEffect(() => {
    setBackUrl('/#')
  }, [])

  const { state, handlers, data } = useMeetingsData()

  return (
    <MainContainerStyled background='grey' data-testid='meetings'>
      <Banner areaName='Meetings' />

      <PrimaryDateNavigation data={data} state={state} handlers={handlers} />
      <SecondaryCodeNavigation state={state} />

      <MeetingsList data={data} state={state} />
    </MainContainerStyled>
  )
}
