import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { ErrorMessage } from '@core/Components/Messages'
import { Spinner } from '@mobi/component-library/Common/Spinner/Spinner'

export const Loading = (): JSX.Element => (
  <MainContainer data-tid-bank-account-loading=''>
    <ContentHeader title='Bank Account' />
    <Spinner />
  </MainContainer>
)

export const Error = (): JSX.Element => (
  <MainContainer data-tid-bank-account-error=''>
    <ContentHeader title='Bank Account' />
    <ErrorMessage>Unable to load bank details at the moment. Please try again later.</ErrorMessage>
  </MainContainer>
)
