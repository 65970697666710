import styled from '@emotion/styled'
import { measurements } from '@mobi/component-library/Theme/Common'
import { colors, hexColors } from '@mobi/settings'

export const SharePreviewOverlayHeaderStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: measurements.mobi.headerHeight,
  minHeight: measurements.mobi.headerHeight,
  background: colors.brand.primary,
  filter: 'drop-shadow(0 0.1rem 0.2rem rgba(0,0,0,0.2))',

  h2: {
    flex: 1,
    marginLeft: '1rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '1.8rem',
    lineHeight: 1.2,
    color: hexColors.white,

    span: {
      display: 'block',
      fontSize: '1.1rem',
      fontWeight: 'normal',
      textTransform: 'none',
      color: hexColors.lola,
    },
  },

  button: {
    span: {
      fontSize: '1.2rem',
      paddingRight: '0.5rem',
    },
  },
})

export const SharePreviewContentWrapperStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '0',
  overflowY: 'scroll', // scroll, not auto for momentum scroll to work
  WebkitOverflowScrolling: 'touch', // enables momentum scroll < IOS11
  overscrollBehavior: 'contain',
  padding: '0.5rem 0',
  background: hexColors.black,
})

export const SharePreviewContentStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  // DIV used for image output
  '& > div': {
    boxSizing: 'border-box',
    position: 'relative',
    minWidth: measurements.mobi.minWidth,
    maxWidth: '40rem',
    margin: 'auto 0',
    padding: '1rem',
    paddingBottom: 0,
    backgroundColor: colors.brand.primary,

    // Fixes styles issues on output image
    fontFamily: 'sans-serif',
    fontVariant: 'normal !important',
    fontVariantLigatures: 'none !important',
    letterSpacing: '0.01rem',

    figure: {
      display: 'none',
    },

    '& > *': {
      position: 'relative',
      zIndex: 2,
    },

    // Brand logo
    '& > div:first-of-type': {
      boxSizing: 'border-box',
      textAlign: 'center',
      paddingBottom: '1.5rem',

      svg: {
        width: '15rem',
        maxHeight: '8rem',
        margin: 0,
        padding: 0,
      },
    },

    // Responsible text
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '1rem 0',

      p: {
        margin: 0,
        fontSize: '1rem',
        color: hexColors.lola,
      },
    },
  },
})
