import { createSignal } from 'rwwa-rx-state-machine'
import { SuperPicksResponse, SuperPicksErrorResponse } from '../../Data/betting'

export const LoadSuperPicks = createSignal<void>('LoadSuperPicks')
export const ChangeSelectedSuperPick = createSignal<number>('ChangeSelectedSuperPick')
export const GetSuperPicksSucceeded = createSignal<SuperPicksResponse>('SuperPickEnquirySucceeded')
export const UpdateSelectedSuperPick = createSignal<SuperPicksResponse>('UpdateSelectedSuperPick')
export const GetSuperPicksFailed = createSignal<SuperPicksErrorResponse>('SuperPickEnquiryFailed')
export const SetInvalidSuperPickSelection = createSignal<string>('SetInvalidSuperPickSelection')
export const ResetInvalidSuperPickSelection = createSignal<void>('ResetInvalidSuperPickSelection')
