import React from 'react'

export const ApplePayLogo = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='apple-pay-logo-title'
      aria-describedby='apple-pay-logo-description'
      role='graphics-symbol'
    >
      <title id='apple-pay-logo-title'>Apple Pay Logo</title>
      <desc id='apple-pay-logo-description'>
        The logo for Apple Pay, depicting Apple&apos;s logo along with the word &quot;Pay&quot; to
        the right.
      </desc>
      <mask
        id='mask0_924_20880'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='11'
        width='40'
        height='26'
      >
        <path d='M43.9109 11H4V36.53H43.9109V11Z' fill='white' />
      </mask>
      <g mask='url(#mask0_924_20880)'>
        <path
          d='M40.3368 11H7.5742C7.43776 11 7.30107 11 7.16485 11.0008C7.04971 11.0016 6.93485 11.0029 6.81998 11.006C6.56945 11.0128 6.31678 11.0276 6.06938 11.072C5.81804 11.1172 5.58415 11.1909 5.35596 11.307C5.13165 11.421 4.92629 11.5701 4.74831 11.748C4.57027 11.9259 4.42107 12.1307 4.30702 12.3551C4.19082 12.5831 4.11699 12.8169 4.07207 13.0682C4.02736 13.3155 4.01248 13.5679 4.00573 13.818C4.00265 13.9327 4.00132 14.0475 4.00057 14.1623C3.99978 14.2987 4.00004 14.4349 4.00004 14.5716V32.9588C4.00004 33.0954 3.99978 33.2313 4.00057 33.3681C4.00132 33.4828 4.00265 33.5977 4.00573 33.7124C4.01248 33.9622 4.02736 34.2147 4.07207 34.4618C4.11699 34.7132 4.19082 34.947 4.30702 35.175C4.42107 35.3994 4.57027 35.6044 4.74831 35.7821C4.92629 35.9604 5.13165 36.1093 5.35596 36.223C5.58415 36.3395 5.81804 36.4132 6.06938 36.4585C6.31678 36.5026 6.56945 36.5175 6.81998 36.5243C6.93485 36.5269 7.04971 36.5285 7.16485 36.529C7.30107 36.53 7.43776 36.53 7.5742 36.53H40.3368C40.4729 36.53 40.6097 36.53 40.7458 36.529C40.8606 36.5285 40.9755 36.5269 41.091 36.5243C41.341 36.5175 41.5936 36.5026 41.8415 36.4585C42.0925 36.4132 42.3265 36.3395 42.5547 36.223C42.7793 36.1093 42.984 35.9604 43.1623 35.7821C43.3401 35.6044 43.4894 35.3994 43.6036 35.175C43.7201 34.947 43.7939 34.7132 43.8386 34.4618C43.8833 34.2147 43.8979 33.9622 43.9046 33.7124C43.9078 33.5977 43.9093 33.4828 43.9098 33.3681C43.9109 33.2313 43.9109 33.0954 43.9109 32.9588V14.5716C43.9109 14.4349 43.9109 14.2987 43.9098 14.1623C43.9093 14.0475 43.9078 13.9327 43.9046 13.818C43.8979 13.5679 43.8833 13.3155 43.8386 13.0682C43.7939 12.8169 43.7201 12.5831 43.6036 12.3551C43.4894 12.1307 43.3401 11.9259 43.1623 11.748C42.984 11.5701 42.7793 11.421 42.5547 11.307C42.3265 11.1909 42.0925 11.1172 41.8415 11.072C41.5936 11.0276 41.341 11.0128 41.091 11.006C40.9755 11.0029 40.8606 11.0016 40.7458 11.0008C40.6097 11 40.4729 11 40.3368 11Z'
          fill='black'
        />
        <path
          d='M40.3367 11.8506L40.7397 11.8514C40.8489 11.8521 40.958 11.8533 41.0679 11.8563C41.2588 11.8615 41.4822 11.8718 41.6904 11.9091C41.8714 11.9417 42.0233 11.9912 42.169 12.0653C42.3127 12.1383 42.4445 12.234 42.5597 12.3488C42.6752 12.4644 42.7711 12.5963 42.845 12.7416C42.9189 12.886 42.968 13.037 43.0005 13.2192C43.0376 13.425 43.048 13.6488 43.0532 13.8408C43.0561 13.9492 43.0576 14.0575 43.0582 14.1685C43.0592 14.3027 43.0592 14.4368 43.0592 14.5712V32.9584C43.0592 33.0927 43.0592 33.2266 43.0581 33.3637C43.0576 33.4721 43.0561 33.5804 43.0532 33.689C43.048 33.8807 43.0378 34.1045 43 34.3126C42.968 34.4924 42.9189 34.6433 42.8447 34.7885C42.7709 34.9333 42.675 35.0651 42.5602 35.18C42.4443 35.2956 42.3131 35.391 42.1675 35.4647C42.0228 35.5385 41.8713 35.588 41.6922 35.6203C41.4796 35.6581 41.247 35.6685 41.0716 35.6732C40.9613 35.6758 40.8516 35.6772 40.7392 35.6777C40.6052 35.6787 40.4707 35.6787 40.3367 35.6787H7.5741C7.57232 35.6787 7.57058 35.6787 7.56878 35.6787C7.4363 35.6787 7.30357 35.6787 7.16868 35.6777C7.0587 35.6772 6.94898 35.6758 6.84287 35.6733C6.66358 35.6685 6.43067 35.6581 6.21996 35.6204C6.03919 35.588 5.88764 35.5385 5.74114 35.4637C5.59697 35.3907 5.46574 35.2955 5.34996 35.1796C5.2351 35.0649 5.13963 34.9337 5.06583 34.7885C4.99195 34.6434 4.94251 34.4921 4.91002 34.3102C4.87244 34.1024 4.86212 33.8796 4.85696 33.6891C4.85403 33.5801 4.8528 33.4711 4.8521 33.3627L4.85156 33.0428L4.85159 32.9584V14.5712L4.85156 14.4867L4.85207 14.1675C4.8528 14.0585 4.85403 13.9495 4.85696 13.8405C4.86212 13.6499 4.87244 13.427 4.91033 13.2175C4.94255 13.0373 4.99195 12.8858 5.06621 12.7401C5.13944 12.596 5.23506 12.4645 5.35055 12.3492C5.46556 12.2342 5.59723 12.1386 5.74232 12.0649C5.88727 11.9912 6.03909 11.9417 6.21986 11.9092C6.42814 11.8718 6.65169 11.8615 6.84315 11.8563C6.95227 11.8533 7.06141 11.8521 7.16972 11.8514L7.5741 11.8506H40.3367Z'
          fill='white'
        />
        <path
          d='M14.8956 19.5865C15.2373 19.1594 15.4692 18.586 15.4081 18C14.9078 18.0249 14.2974 18.3298 13.944 18.7572C13.6266 19.1232 13.3457 19.7206 13.419 20.2822C13.9805 20.3308 14.5415 20.0016 14.8956 19.5865Z'
          fill='black'
        />
        <path
          d='M15.402 20.3919C14.5865 20.3434 13.8931 20.8545 13.5036 20.8545C13.114 20.8545 12.5176 20.4164 11.8726 20.4283C11.0331 20.4406 10.2541 20.9148 9.82805 21.6692C8.95178 23.1782 9.59681 25.4168 10.4489 26.6458C10.8627 27.2538 11.3615 27.9234 12.0186 27.8994C12.6394 27.8751 12.8828 27.4977 13.6375 27.4977C14.3917 27.4977 14.6109 27.8994 15.2681 27.8872C15.9497 27.8751 16.3758 27.2788 16.7896 26.6701C17.2643 25.977 17.4586 25.3078 17.4709 25.2709C17.4586 25.2588 16.1566 24.7597 16.1445 23.2632C16.1322 22.0101 17.1668 21.414 17.2155 21.377C16.6312 20.5138 15.7184 20.4164 15.402 20.3919Z'
          fill='black'
        />
        <path
          d='M22.502 18.6963C24.2744 18.6963 25.5088 19.9171 25.5088 21.6946C25.5088 23.4782 24.2491 24.7054 22.4575 24.7054H20.4951V27.8238H19.0771V18.6963H22.502ZM20.4951 23.5162H22.122C23.3565 23.5162 24.059 22.8521 24.059 21.7009C24.059 20.5496 23.3565 19.8919 22.1283 19.8919H20.4951V23.5162Z'
          fill='black'
        />
        <path
          d='M25.8799 25.9321C25.8799 24.7682 26.7725 24.0535 28.3552 23.9649L30.1783 23.8574V23.345C30.1783 22.605 29.6781 22.1623 28.8427 22.1623C28.0511 22.1623 27.5574 22.5416 27.4372 23.1364H26.1458C26.2217 21.9345 27.2472 21.0488 28.8932 21.0488C30.5074 21.0488 31.5393 21.9029 31.5393 23.2374V27.8233H30.2289V26.729H30.1972C29.8113 27.4691 28.9692 27.9372 28.0956 27.9372C26.7915 27.9372 25.8799 27.1276 25.8799 25.9321ZM30.1783 25.3313V24.8063L28.5387 24.9074C27.7219 24.9643 27.2599 25.3248 27.2599 25.8941C27.2599 26.4761 27.7411 26.8555 28.4754 26.8555C29.4311 26.8555 30.1783 26.1977 30.1783 25.3313Z'
          fill='black'
        />
        <path
          d='M32.7756 30.272V29.1651C32.8768 29.1904 33.1046 29.1904 33.2186 29.1904C33.8517 29.1904 34.1935 28.9248 34.4023 28.2416C34.4023 28.229 34.5227 27.8368 34.5227 27.8305L32.1172 21.1699H33.5984L35.2825 26.5845H35.3076L36.9917 21.1699H38.435L35.9407 28.172C35.3711 29.785 34.7127 30.3037 33.3327 30.3037C33.2186 30.3037 32.8768 30.291 32.7756 30.272Z'
          fill='black'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.3151 11.8923H6.59583C5.655 11.8923 4.89232 12.655 4.89232 13.5958V33.8758C4.89232 34.8166 5.655 35.5793 6.59583 35.5793H41.3151C42.2559 35.5793 43.0186 34.8166 43.0186 33.8758V13.5958C43.0186 12.655 42.2559 11.8923 41.3151 11.8923ZM6.59583 11C5.16219 11 4 12.1622 4 13.5958V33.8758C4 35.3095 5.16219 36.4716 6.59583 36.4716H41.3151C42.7488 36.4716 43.9109 35.3095 43.9109 33.8758V13.5958C43.9109 12.1622 42.7488 11 41.3151 11H6.59583Z'
        fill='black'
      />
    </svg>
  )
}
