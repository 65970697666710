import { hexColors } from '@mobi/settings'
import React from 'react'

import {
  SearchFieldStyled,
  SearchInputStyled,
  SearchClearButtonStyled,
  SearchIcon,
  SearchFormContainerStyled,
} from './SearchFieldStyled.style'

interface SearchFieldProps {
  performSearch: (textVal: string) => void
  clearResults: () => void
  previousSearches: string[]
  searchFocused: () => void
}

export function SearchField({
  performSearch,
  clearResults,
  previousSearches,
  searchFocused,
}: SearchFieldProps): JSX.Element {
  const [textVal, setTextVal] = React.useState('')

  let inputField = React.useRef<HTMLInputElement>(null)

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      invokeSearch()
    }
  }

  const invokeSearch = (text?: string) => {
    const searchTerm = text || textVal

    if (searchTerm.length === 0) return

    performSearch(searchTerm)
    const node = inputField.current
    if (node) {
      node.blur()
    }
  }

  const textUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.target.value
    setTextVal(value)
  }

  const clearText = () => {
    setTextVal('')
    clearResults()
    focus()
  }

  const focus = () => {
    if (!inputField) return

    const node = inputField.current
    if (node) {
      node.focus()
      node.setAttribute('autoFocus', '')
    }
  }

  React.useEffect(() => {
    focus()
  }, [])

  const repeatPreviousSearch = (text: string) => {
    setTextVal(text)
    invokeSearch(text)
  }

  const getPreviousSearches = (candidates: string[], textVal: string) => {
    const term = textVal.toLowerCase()
    const filtered = candidates.filter(element => element.toLowerCase().indexOf(term) >= 0)
    if (filtered.length === 0) return null
    return (
      <div>
        <ul>
          <li>
            <span className='title'>My previous searches</span>
          </li>
          {filtered.map((text, index) => (
            <li key={`${text}_${index}`}>
              <button
                type='button'
                onClick={() => repeatPreviousSearch(text)}
                role='button'
                title={text}
              >
                {text}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <SearchFieldStyled>
      <SearchIcon type='search' size='2rem' color={hexColors.white} />
      <SearchFormContainerStyled onSubmit={e => e.preventDefault()} action='.'>
        <SearchInputStyled
          value={textVal}
          onChange={textUpdated}
          onKeyUp={handleKeyUp}
          onFocus={searchFocused}
          placeholder='Search for upcoming races...'
          autoFocus={true}
          ref={inputField}
          type='search'
          data-tid-search-input-field
        />
        {getPreviousSearches(previousSearches || [], textVal)}
      </SearchFormContainerStyled>
      <SearchClearButtonStyled onClick={clearText} type={'button'} data-tid-search-button-clear>
        Clear
      </SearchClearButtonStyled>
    </SearchFieldStyled>
  )
}
