import jQuery from 'jquery'
import { IAppWindow } from './IAppWindow'
import { injectable } from 'inversify'

@injectable()
export default class AppWindow implements IAppWindow {
  public addEventListener(evt: string, func: (e: Event) => void, capture: boolean) {
    window.addEventListener(evt, func, capture)
  }

  public removeEventListener(evt: string, func: (e: Event) => void) {
    window.removeEventListener(evt, func)
  }

  public scrollTo(selector: string, offset: number, delay: number) {
    jQuery('html, body').animate(
      {
        scrollTop: (jQuery(selector).offset() as JQueryCoordinates).top + offset,
      },
      delay
    )
  }

  public domElementExists(selector: string) {
    return !!jQuery(selector).length
  }

  public changeHashTo(suffix: string) {
    window.location.hash = suffix
  }

  public redirectTo(href: string) {
    window.location.href = href
  }

  public requestAnimationFrame(fn: FrameRequestCallback) {
    window.requestAnimationFrame(fn)
  }

  public setInterval(handler: Function, timeout: number): number {
    return window.setInterval(handler, timeout)
  }

  public clearInterval(timerId: number) {
    window.clearInterval(timerId)
  }

  public innerWidth(): number {
    return window.innerWidth
  }
}
