import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export const SilkFallback: React.FC<{
  contestType?: RaceCodesLowerCase
  isSmall?: boolean
}> = ({ contestType, isSmall = false }) => {
  return (
    <Icon
      data-testid='fallback-icon'
      type={contestType || 'tab'}
      size={isSmall ? '1.5rem' : undefined}
    />
  )
}
