import React, { memo, type MouseEventHandler, useEffect, useRef, useState } from 'react'
import { Icon } from '../Icon'
import { CopyButtonStyled, CopyFieldStyled } from './CopyField.styled'
import { copyToClipboard } from '@mobi/utils'

const enum LocalConstants {
  COPY_TEXT_RESET_TIME_MS = 2_000,
}

type Props = {
  /**
   * The value that is displayed and copied to the clipboard
   */
  value: string
  /**
   * The callback to fire when the value was successfully copied
   */
  onCopied?: VoidFunction
  /**
   * The callback to fire when the copy action has failed
   *
   * If the clipboard API is supported, the error will be the DOMException thrown by writeText
   *
   * If the clipboard API is not supported and `document.execCommand` is not supported, the error will be a constructed DOMException with the same name
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
   * @link https://developer.mozilla.org/en-US/docs/Web/API/DOMException
   * @link https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#copy
   */
  onCopyError?: (e: DOMException) => void
}

export const CopyField = memo<Props>(({ value, onCopied, onCopyError }) => {
  const timeoutRef = useRef(-1)
  const [valueWasRecentlyCopied, setValueWasRecentlyCopied] = useState(false)

  const copy: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard(value)
      .then(() => {
        onCopied?.()

        setValueWasRecentlyCopied(true)

        window.clearTimeout(timeoutRef.current)

        timeoutRef.current = window.setTimeout(() => {
          setValueWasRecentlyCopied(false)
        }, LocalConstants.COPY_TEXT_RESET_TIME_MS)
      })
      .catch((err: DOMException) => {
        onCopyError?.(err)
      })
  }

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <CopyFieldStyled>
      <span>{value}</span>

      <CopyButtonStyled onClick={copy}>
        <Icon name='DuocolorCopy02' size='1.2rem' />
        <span>{valueWasRecentlyCopied ? 'Copied' : 'Copy'}</span>
      </CopyButtonStyled>
    </CopyFieldStyled>
  )
})

CopyField.displayName = 'CopyField'
