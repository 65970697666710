import React from 'react'
import { FlexiDisplayStyled, AmountStyled } from '../FlexiDisplay/FlexiDisplay.styles'
import { Money } from '@core/Components/Text/Money'

interface MysterySpendDisplayProps {
  investment: number
}

export function MysterySpendDisplay({ investment }: MysterySpendDisplayProps) {
  return (
    <FlexiDisplayStyled isActive={false}>
      <span>Spend</span>{' '}
      <AmountStyled amount={1}>
        <Money amount={investment}></Money>
      </AmountStyled>
    </FlexiDisplayStyled>
  )
}
