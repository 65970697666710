import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { baseAppHeaderButtonStyle } from '../../AppHeader.styles'

export const AppHeaderButtonStyled = styled.button(baseAppHeaderButtonStyle, {
  position: 'relative',
  width: 'auto',
  minWidth: '5rem',
  background: 'none',
  border: 0,
  color: hexColors.white,

  '& *': {
    margin: 'auto',
  },
})

type AppHeaderMaterialButtonProps = {
  isPrimary: boolean
}

export const AppHeaderMaterialButtonStyled = styled('a')(({
  isPrimary,
}: AppHeaderMaterialButtonProps) => {
  return {
    backgroundColor: isPrimary ? hexColors.studio : hexColors.white,
    color: isPrimary ? hexColors.white : hexColors.nero,
    marginRight: '0.5rem',
    fontFamily: '"PF Square Sans Pro", Arial, sans-serif',
    borderRadius: '0.4rem',
    textDecoration: 'none',
    padding: '0.8rem 0 0.7rem 0',
    width: '6rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: '300',
    cursor: 'pointer',
  }
})
