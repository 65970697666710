import React from 'react'
import dayjs from 'dayjs'
import { RaceEvent } from '../../RaceEventsApis'
import {
  RaceItemStyled,
  RaceEventItemContainerStyled,
  RaceEventDetailStyled,
  RaceItemTimerStyled,
  RaceEventItemDateStyled,
  RaceEventResultStatusStyled,
} from './RaceEventsItem.styles'
import { RaceCountdown } from '@core/Components/RaceCountdown'

const enum LocalConstants {
  dateTimeFormat = 'ddd, DD MMM YYYY HH:mm',
}

export const RaceEventsItem: React.FC<RaceEvent> = ({ title, date, product, racingStatus }) => {
  const showLikeChallenge = ['CLG', 'SR'].includes(product.toUpperCase())
  const eventRaceStatus = getRaceStatus(racingStatus)

  return (
    <RaceEventItemContainerStyled data-tid-event-item>
      <RaceItemStyled>
        {!showLikeChallenge ? (
          <div />
        ) : (
          <RaceItemTimerStyled data-tid-race-list-race-status={eventRaceStatus}>
            {eventRaceStatus === 'Open' ? (
              <RaceCountdown displaySize='1.2rem' raceStartTime={date} />
            ) : (
              <RaceEventResultStatusStyled data-testid='RaceEventsItem.Status'>
                <span>{eventRaceStatus}</span>
              </RaceEventResultStatusStyled>
            )}
          </RaceItemTimerStyled>
        )}

        <RaceEventDetailStyled>
          <span data-tid-race-list-race-name>{title}</span>

          {showLikeChallenge ? null : (
            <RaceEventItemDateStyled
              data-testid='RaceEventsItem.Date'
              data-tid-race-list-race-status=''
            >
              {dayjs(date).format(LocalConstants.dateTimeFormat)}
            </RaceEventItemDateStyled>
          )}
        </RaceEventDetailStyled>
      </RaceItemStyled>
    </RaceEventItemContainerStyled>
  )
}

// =============
// Local Helpers
// =============

function getRaceStatus(status?: string): string {
  switch (status) {
    case 'Open':
    case 'Closed':
    case 'Suspended':
    case 'Abandoned':
    case 'Interim': // as per Ben's comments.. unlikely to happen in PROD for challenges, but being handled for completeness and backward consistency with MVC page
      return status
    case 'Resulted':
    case 'Settled':
      return 'Resulted'
    default:
      //Scratched is not valid for challenges
      return ''
  }
}
