import * as ko from 'knockout'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { store } from '@core/Store'
import { queryClient } from '@core/Data/ReactQuery/queryClient'

ko.bindingHandlers.react = {
  init: (element: Element) => {
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      ReactDOM.unmountComponentAtNode(element)
    })
    return { controlsDescendantBindings: true }
  },
  update: (
    element: Element,
    valueAccessor: () => ko.MaybeSubscribable<React.FunctionComponent<unknown>>,
    allBindingsAccessor?
  ) => {
    const componentType = ko.unwrap(valueAccessor())
    const options = ko.unwrap(allBindingsAccessor?.())

    if (options && componentType) {
      const props = ko.toJS(options.props)
      const nonObservables = options.nonObservables ?? {}

      let wrappedComponent = React.createElement(componentType, { ...props, ...nonObservables })

      if (options.shouldWrapWithRedux) {
        // @ts-expect-error Typing issue
        wrappedComponent = React.createElement(Provider, { store }, wrappedComponent)
      }

      if (options.shouldWrapWithReactQuery) {
        // @ts-expect-error Typing issue
        wrappedComponent = React.createElement(
          QueryClientProvider,
          { client: queryClient },
          wrappedComponent
        )
      }

      ReactDOM.render(wrappedComponent, element)
    }
  },
}
