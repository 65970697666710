import React from 'react'
import * as Rx from 'rx'
import { observe } from '@core/Components/HOCs'

const delayCount$ = Rx.Observable.fromArray([6000])
  .flatMap(interval => Rx.Observable.timer(interval))
  .scan(countHelper, 0)
  .map(count => ({ count }))
  .startWith({ count: 0 })

export const Loading = observe<
  { count: number },
  { message?: string; inline?: boolean; showProcessingStatus?: boolean }
>(delayCount$, ({ message, count, showProcessingStatus = true }) => {
  return (
    <div className='modalSection m1em pb70 pt70 fadeIn center-text'>
      <div data-tid-loading-indicator className='spinner spinner-purple fz-24'></div>
      {showStatusMessage(count, message as string, showProcessingStatus)}
    </div>
  )
})

// Local Helpers

function showStatusMessage(count: number, message: string, showProcessingStatus: boolean) {
  const messageText = showProcessingStatus ? getMessage(count, message) : message
  if (!messageText) {
    return null
  }
  return (
    <div data-tid-loading-message className='p5'>
      {messageText}
    </div>
  )
}

function getMessage(count: number, message: string) {
  switch (count) {
    case 0:
      return message
    case 1:
      return "It seems like it's taking longer than usual. We're still processing..."
    default:
      return 'Still processing...'
  }
}

function countHelper(current: number) {
  return current + 1
}
