import React from 'react'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  SummaryPopupStyled,
  ButtonBlockStyled,
  NoticeboxStyled,
  ParagraphStyled,
} from './ActivitySummaryPopup.styles'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

interface ActivitySummaryPopupProps {
  isDesktop?: boolean
}

interface PromptPopupProps extends ActivitySummaryPopupProps {
  preferredName: string
  onSuccess(): Promise<void>
  onCancellation(): Promise<void>
}

interface PopupProps extends ActivitySummaryPopupProps {
  onClose(): void
}

export function ActivitySummaryPopup(props: PromptPopupProps): React.ReactElement {
  const { isDesktop, preferredName, onSuccess, onCancellation } = props

  const [isSuccessBusy, setIsSuccessBusy] = React.useState(false)
  const [isCancellationBusy, setIsCancellationBusy] = React.useState(false)
  const disabled = isSuccessBusy || isSuccessBusy
  const onSuccessClick = async () => {
    setIsSuccessBusy(true)
    await onSuccess()
  }

  const onCancellationClick = async () => {
    setIsCancellationBusy(true)
    await onCancellation()
  }

  return (
    <SummaryPopupStyled isDesktop={isDesktop}>
      <ParagraphStyled>
        <strong>Hi {preferredName ?? ''}</strong>, your monthly summary for the past 12 months is
        now available.
        <br />
        <br />
        Would you like to have this summary emailed to you?
      </ParagraphStyled>
      <ButtonBlockStyled isDesktop={isDesktop}>
        <ButtonBlock
          data-tid-activity-summary-yes=''
          onClick={async () => await onSuccessClick()}
          color='primary'
          isDesktop={isDesktop}
          style={{ margin: isDesktop ? '0 0.5rem 0 0' : undefined }}
          disabled={disabled}
          isBusy={isSuccessBusy}
        >
          Yes, please email it to me
        </ButtonBlock>
        <ButtonBlock
          data-tid-activity-summary-no=''
          onClick={async () => await onCancellationClick()}
          color='secondary'
          isDesktop={isDesktop}
          style={{ margin: isDesktop ? '0 0 0 0.5rem' : '1rem 0 0 0' }}
          disabled={disabled}
          isBusy={isCancellationBusy}
          busyIndicatorColour={'#689F38'}
        >
          No thanks
        </ButtonBlock>
      </ButtonBlockStyled>
    </SummaryPopupStyled>
  )
}

export function ActivitySummaryPopupSuccess(props: PopupProps): React.ReactElement {
  const { isDesktop, onClose } = props

  return (
    <SummaryPopupStyled isDesktop={isDesktop}>
      <NoticeboxStyled>
        <NoticeBoxSingle
          title={<strong>Your request has been sent</strong>}
          noticeBoxType={NoticeBoxTypes.Success}
          hasBorder={true}
          isDesktop={isDesktop}
        />
      </NoticeboxStyled>
      <ParagraphStyled>
        You should receive an email with your account monthly summary within the next 2 business
        days.
      </ParagraphStyled>
      <ButtonBlockStyled>
        <ButtonBlock onClick={onClose} color='primary' isDesktop={isDesktop}>
          Close
        </ButtonBlock>
      </ButtonBlockStyled>
    </SummaryPopupStyled>
  )
}

export function ActivitySummaryPopupCancellation(props: PopupProps): React.ReactElement {
  const { isDesktop, onClose } = props

  return (
    <SummaryPopupStyled isDesktop={isDesktop}>
      <ParagraphStyled>
        You can request to have your account monthly summary emailed to you by contacting the
        Customer Management Centre on <CustomerServiceTelephone />
      </ParagraphStyled>
      <ButtonBlockStyled>
        <ButtonBlock onClick={onClose} color='primary'>
          Close
        </ButtonBlock>
      </ButtonBlockStyled>
    </SummaryPopupStyled>
  )
}

export function ErrorPopupNoticeBox(props: PopupProps): React.ReactElement {
  const { isDesktop, onClose } = props

  return (
    <SummaryPopupStyled isDesktop={isDesktop}>
      <ParagraphStyled>
        We were unable to record your answer, and will ask again later.
        <br />
        <br />
        For any difficulties, please call our Customer Management Centre on{' '}
        <CustomerServiceTelephone isInternational={false} /> or internationally on{' '}
        <CustomerServiceTelephone isInternational={true} />.
      </ParagraphStyled>
      <ButtonBlockStyled>
        <ButtonBlock onClick={onClose} color='primary'>
          Close
        </ButtonBlock>
      </ButtonBlockStyled>
    </SummaryPopupStyled>
  )
}
