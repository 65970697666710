import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { Spinner } from '@mobi/component-library/Common/Spinner/Spinner'

export const WithdrawLoading = (): JSX.Element => {
  return (
    <MainContainer data-tid-withdraw-loading=''>
      <ContentHeader title='Withdrawal' />
      <Spinner />
    </MainContainer>
  )
}
