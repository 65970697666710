import React from 'react'
import { observeImmutable } from '../../../../Components/HOCs/observe'
import { state$ } from '../../driver'
import { NoticeBox } from '../../../../Components/NoticeBox/NoticeBox'
import { NoticeBoxTypes } from '../../../../Components/NoticeBox/NoticeBox.styles'

export const NameDateOfBirthChangedNotice = observeImmutable(state$, ({ record }) => {
  return (
    <NameDateOfBirthChangedNoticeComponent nameDateOfBirthChanged={record.nameDateOfBirthChanged} />
  )
})

export function NameDateOfBirthChangedNoticeComponent({
  nameDateOfBirthChanged,
}: {
  nameDateOfBirthChanged: boolean
}) {
  if (nameDateOfBirthChanged) {
    return (
      <NoticeBox
        title='To allow us to contact you!'
        noticeBoxType={NoticeBoxTypes.Warning}
        hasBorder={true}
      >
        Please make sure your contact number is correct or update it before you select save.
      </NoticeBox>
    )
  }

  return null
}
