import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { MoneyGroupedProps, MoneyGrouped } from '@core/Components/Text/MoneyGrouped'
import { Money } from '@core/Components/Text/Money'
import { PriceChange } from '@core/Components/Text/utils'
import { hexColors } from '@mobi/settings/src/hexColors'
import { GridCell } from '@mobi/component-library/Common/Grid'

export interface BetInvestmentGroupStyledProps {
  isEditable: boolean
}

export const BetInvestmentGroupStyled = styled('div')<BetInvestmentGroupStyledProps>(
  {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: 'auto',
    height: '4rem',
    padding: '0 0.5rem',
    marginBottom: '0.5rem',
    border: `1px solid ${colors.betslip.investment.border}`,
    backgroundColor: colors.betslip.investment.background,
    boxShadow: `0 0.1rem 0.2rem ${colors.betslip.investment.boxShadow}`,
    fontSize: '1.4rem',
    cursor: 'pointer',
  },
  ({ isEditable }) => ({
    backgroundColor: !isEditable ? colors.betslip.investment.disabled.background : undefined,
    boxShadow: !isEditable ? 'none' : undefined,
  })
)

export const BetEachWayInvestmentGroupStyled = styled(BetInvestmentGroupStyled)<{
  isEditable: boolean
}>(
  {
    width: '100%',
    height: 'auto',
    minHeight: '4rem',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  ({ isEditable }) => ({
    backgroundColor: !isEditable ? colors.betslip.investment.disabled.background : undefined,
    boxShadow: !isEditable ? 'none' : undefined,
  })
)

type InvestmentStyledProps = MoneyGroupedProps & {
  isEditable: boolean
  shouldHighlight?: boolean
  isBonusBet?: boolean
}

export const InvestmentStyled = styled(MoneyGrouped)<InvestmentStyledProps>(
  {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    zIndex: 'unset',
  },
  ({ isEditable, isBonusBet }) => ({
    color: isEditable ? colors.betslip.investment.text : colors.betslip.investment.disabled.text,
    backgroundColor: isBonusBet ? hexColors.orange : undefined,
    padding: isBonusBet ? '0.1em 0.5em' : undefined,
    borderRadius: isBonusBet ? '0.4em' : undefined,
  })
)

export const InvestmentPriceStyled = styled(MoneyGrouped)<InvestmentStyledProps>(
  {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    zIndex: 0,
    position: 'relative',
    alignItems: 'center',
  },
  ({ isEditable, priceChange, shouldHighlight }) => {
    let color: string | undefined = isEditable
      ? colors.betslip.investment.text
      : colors.betslip.investment.disabled.text

    // if price change and no highlight, leave color to be decided by it children
    if (priceChange && priceChange !== PriceChange.None && !shouldHighlight) {
      color = undefined
    }

    return shouldHighlight
      ? {
          color,
          fontWeight: 'bold',
          '&::before': {
            backgroundColor: colors.quickbet.investment.legInfo.boosted.background,
            borderRadius: '0.5rem',
            content: '" "',
            height: '100%',
            width: priceChange && priceChange !== PriceChange.None ? 'calc(100% + 1.5rem)' : '100%',
            top: '50%',
            left: '-0.2rem',
            right: '-0.2rem',
            padding: '0.1rem 0.2rem',
            position: 'absolute',
            transform: 'translateY(-50%)',
            zIndex: -1,
          },
        }
      : {
          color,
        }
  }
)

export const InvestmentAmountStyled = styled(Money)<{ isEditable: boolean; isBonusBet?: boolean }>(
  {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.6rem',
    margin: 0,
    position: 'relative',
    color: colors.betslip.investment.text,
  },
  ({ isEditable, isBonusBet }) => ({
    color: !isEditable ? colors.betslip.investment.disabled.text : undefined,
    backgroundColor: isBonusBet ? hexColors.orange : undefined,
    padding: isBonusBet ? '0.1em 0.5em' : undefined,
    borderRadius: isBonusBet ? '0.4em' : undefined,
  })
)

export const InvestmentTextStyled = styled('span')<{ isEditable: boolean }>(
  {
    paddingRight: '0.5rem',
    display: 'inline-block',
    fontSize: '1.2rem',
    margin: 0,
    position: 'relative',
    color: colors.betslip.investment.text,
  },
  ({ isEditable }) => ({
    color: !isEditable ? colors.betslip.investment.disabled.text : undefined,
  })
)

export const GridCellStyled = styled(GridCell)({
  alignSelf: 'center',
})
