import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { formatDateStringWithoutChangingTimezone } from '@mobi/utils'
import type { MeetingsAPIResponse, Meeting } from './types'
import { useMeetingsQuery } from './useMeetingsQuery'

export const useMeetings = (raceCode: RaceCodesLowerCase, incomingDate: string): Meeting[] => {
  const cleanedDate = formatDateStringWithoutChangingTimezone(incomingDate, 'YYYY-MM-DD')
  const { data } = useMeetingsQuery(cleanedDate)

  const meetingsData = data ? data[cleanedDate] : undefined

  return getMeetingsForCode(raceCode, meetingsData?.meetings)
}

// =============
// Local Helpers
// =============

function getMeetingsForCode(
  code: RaceCodesLowerCase,
  allMeetings?: MeetingsAPIResponse[0]['meetings']
): NonNullable<MeetingsAPIResponse[0]['meetings']>[0][] {
  if (!allMeetings) return []
  return Object.values(allMeetings).filter(meeting => meeting.code.toLowerCase() === code)
}
