import { ContainerModule, type interfaces } from 'inversify'

import { IRaceDisplayStateManagerViewModel } from '../Components/Pages/Workflow/IRaceDisplayStateManagerViewModel'
import { RaceDisplayStateManagerViewModel } from '../Components/Pages/Workflow/RaceDisplayStateManagerViewModel'
import { IBettingStateManagerViewModel } from '../Components/Pages/Workflow/IBettingStateManagerViewModel'
import { BettingStateManagerViewModel } from '../Components/Pages/Workflow/BettingStateManagerViewModel'
import { IStartersPageViewModel } from '../Components/Pages/Starters/IStartersPageViewModel'
import { StartersPageViewModel } from '../Components/Pages/Starters/StartersPageViewModel'
import { IResultsPageViewModel } from '../Components/Pages/Results/IResultsPageViewModel'
import { ResultsPageViewModel } from '../Components/Pages/Results/ResultsPageViewModel'
import { IMultiLegSelectionsViewModel } from '../Components/MySelection/MultiLeg/IMultiLegSelectionsViewModel'
import { MultiLegSelectionsViewModel } from '../Components/MySelection/MultiLeg/MultiLegSelectionsViewModel'
import { DogFinisherViewModel } from '../Components/Finishers/Dog/DogFinisherViewModel'
import { IDogFinisherViewModel } from '../Components/Finishers/Dog/IDogFinisherViewModel'
import { IRaceFinisherViewModel } from '../Components/Finishers/Race/IRaceFinisherViewModel'
import { RaceFinisherViewModel } from '../Components/Finishers/Race/RaceFinisherViewModel'
import { ITrotFinisherViewModel } from '../Components/Finishers/Trot/ITrotFinisherViewModel'
import { TrotFinisherViewModel } from '../Components/Finishers/Trot/TrotFinisherViewModel'
import { IFinishersListViewModel } from '../Components/Finishers/List/IFinishersListViewModel'
import { FinishersListViewModel } from '../Components/Finishers/List/FinishersListViewModel'
import { IFinisherPlaceViewModel } from '../Components/Finishers/Place/IFinisherPlaceViewModel'
import { FinisherPlaceViewModel } from '../Components/Finishers/Place/FinisherPlaceViewModel'
import { IFinisherPricesViewModel } from '../Components/Finishers/Prices/IFinisherPricesViewModel'
import { FinisherPricesViewModel } from '../Components/Finishers/Prices/FinisherPricesViewModel'
import { IFieldViewModel } from '../Components/Selection/Field/IFieldViewModel'
import { FieldViewModel } from '../Components/Selection/Field/FieldViewModel'
import { MultiFieldViewModel } from '../Components/Selection/Field/MultiFieldViewModel'
import { IMultiFieldViewModel } from '../Components/Selection/Field/IMultiFieldViewModel'
import { StandardTopPanel } from '../Components/Panels/Standard/StandardTopPanel'
import { IStandardTopPanel } from '../Components/Panels/Standard/IStandardTopPanel'
import { SubstitutesViewModel } from '../Components/Panels/Substitutes/SubstitutesViewModel'
import { ISubstitutesViewModel } from '../Components/Panels/Substitutes/ISubstitutesViewModel'
import { ScratchingsViewModel } from '../Components/Panels/Scratchings/ScratchingsViewModel'
import { IScratchingsViewModel } from '../Components/Panels/Scratchings/IScratchingsViewModel'
import { IMessageBoxViewModel } from '../Components/Panels/MessageBox/IMessageBoxViewModel'
import { MessageBoxViewModel } from '../Components/Panels/MessageBox/MessageBoxViewModel'
import { IResultsViewModel } from '../Components/Finishers/view/IResultsViewModel'
import { ResultsViewModel } from '../Components/Finishers/view/ResultsViewModel'
import { IResultsFieldViewModel } from '../Components/Finishers/view/IResultsFieldViewModel'
import { ResultsFieldViewModel } from '../Components/Finishers/view/ResultsFieldViewModel'
import { IFieldFinisherPricesViewModel } from '../Components/Finishers/Prices/IFieldFinisherPricesViewModel'
import { FieldFinisherPricesViewModel } from '../Components/Finishers/Prices/FieldFinisherPricesViewModel'
import { IAllUpViewModel } from '../Components/BetTypes/AllUp/IAllUpViewModel'
import { AllUpViewModel } from '../Components/BetTypes/AllUp/AllUpViewModel'
import { IMultiLegViewModel } from '../Components/BetTypes/MultiLeg/IMultiLegViewModel'
import { MultiLegViewModel } from '../Components/BetTypes/MultiLeg/MultiLegViewModel'
import { NoveltyDividendsViewModel } from '../Components/Panels/NoveltyDividends/NoveltyDividendsViewModel'
import { INoveltyDividendsViewModel } from '../Components/Panels/NoveltyDividends/INoveltyDividendsViewModel'
import { ProvDivsViewModel } from '../Components/Selection/ProvDivs/ProvDivsViewModel'
import { RaceReplayViewModel } from '../Components/General/RaceReplay/RaceReplayViewModel'
import { IRaceReplayViewModel } from '../Components/General/RaceReplay/IRaceReplayViewModel'
import { FormGiddyUpRaceInformation } from '../Components/Form/FormGiddyUpRaceInformation'
import { StartersListViewModel } from '../Components/Starters/List/StartersListViewModel'
import { IMultiSelectionPriceViewModel } from '../Components/Starters/Pricing/IMultiSelectionPriceViewModel'
import { MultiSelectionPriceViewModel } from '../Components/Starters/Pricing/MultiSelectionPriceViewModel'
import { RaceSubHeaderViewModel } from '../Components/Panels/MultiRaceSubHeader/RaceSubHeaderViewModel'
import { IFixedWinPlaceSelectionsViewModel } from '../Components/MySelection/WinPlace/Fixed/IFixedWinPlaceSelectionsViewModel'
import { FixedWinPlaceSelectionsViewModel } from '../Components/MySelection/WinPlace/Fixed/FixedWinPlaceSelectionsViewModel'
import { StarterImageViewModel } from '../Components/Starters/Image/StarterImageViewModel'
import { ResultedImageViewModel } from '../Components/Starters/ResultedImage/ResultedImageViewModel'
import { InfoToolbarViewModel } from '../Components/Commands/InfoToolboxWrapper/InfoToolbarViewModel'
import { IInfoToolbarViewModel } from '../Components/Commands/InfoToolboxWrapper/IInfoToolbarViewModel'
import { IRaceStarterViewModel } from '../Components/Starters/Race/IRaceStarterViewModel'
import { DogStarterViewModel } from '../Components/Starters/Dog/DogStarterViewModel'
import { RaceStarterViewModel } from '../Components/Starters/Race/RaceStarterViewModel'
import { TrotStarterViewModel } from '../Components/Starters/Trot/TrotStarterViewModel'
import { IDogStarterViewModel } from '../Components/Starters/Dog/IDogStarterViewModel'
import { ITrotStarterViewModel } from '../Components/Starters/Trot/ITrotStarterViewModel'

const viewmodels = new ContainerModule((bind: interfaces.Bind) => {
  bind<IRaceDisplayStateManagerViewModel>('IRaceDisplayStateManagerViewModel').to(
    RaceDisplayStateManagerViewModel
  )
  bind<IBettingStateManagerViewModel>('IBettingStateManagerViewModel').to(
    BettingStateManagerViewModel
  )
  bind<IStartersPageViewModel>('IStartersPageViewModel').to(StartersPageViewModel)
  bind<IDogStarterViewModel>('IDogStarterViewModel').to(DogStarterViewModel)
  bind<IRaceStarterViewModel>('IRaceStarterViewModel').to(RaceStarterViewModel)
  bind<ITrotStarterViewModel>('ITrotStarterViewModel').to(TrotStarterViewModel)

  bind<IResultsPageViewModel>('IResultsPageViewModel').to(ResultsPageViewModel)
  bind<IMultiLegSelectionsViewModel>('IMultiLegSelectionsViewModel').to(MultiLegSelectionsViewModel)
  bind<IResultsViewModel>('IResultsViewModel').to(ResultsViewModel)
  bind<IResultsFieldViewModel>('IResultsFieldViewModel').to(ResultsFieldViewModel)
  bind<IDogFinisherViewModel>('IDogFinisherViewModel').to(DogFinisherViewModel)
  bind<IRaceFinisherViewModel>('IRaceFinisherViewModel').to(RaceFinisherViewModel)
  bind<ITrotFinisherViewModel>('ITrotFinisherViewModel').to(TrotFinisherViewModel)
  bind<IFinishersListViewModel>('IFinishersListViewModel').to(FinishersListViewModel)
  bind<IFinisherPlaceViewModel>('IFinisherPlaceViewModel').to(FinisherPlaceViewModel)
  bind<IFinisherPricesViewModel>('IFinisherPricesViewModel').to(FinisherPricesViewModel)
  bind<IFieldFinisherPricesViewModel>('IFieldFinisherPricesViewModel').to(
    FieldFinisherPricesViewModel
  )
  bind<IFieldViewModel>('IFieldViewModel').to(FieldViewModel)
  bind<IMultiFieldViewModel>('IMultiFieldViewModel').to(MultiFieldViewModel)
  bind<IStandardTopPanel>('IStandardTopPanel').to(StandardTopPanel)
  bind<ISubstitutesViewModel>('ISubstitutesViewModel').to(SubstitutesViewModel)
  bind<IScratchingsViewModel>('IScratchingsViewModel').to(ScratchingsViewModel)
  bind<IMessageBoxViewModel>('IMessageBoxViewModel').to(MessageBoxViewModel)
  bind<IAllUpViewModel>('IAllUpViewModel').to(AllUpViewModel)
  bind<IMultiLegViewModel>('IMultiLegViewModel').to(MultiLegViewModel)
  bind<INoveltyDividendsViewModel>('INoveltyDividendsViewModel').to(NoveltyDividendsViewModel)
  bind<ProvDivsViewModel>('ProvDivsViewModel').to(ProvDivsViewModel)
  bind<IRaceReplayViewModel>('IRaceReplayViewModel').to(RaceReplayViewModel)
  bind<FormGiddyUpRaceInformation>('FormGiddyUpRaceInformation').to(FormGiddyUpRaceInformation)
  bind<StartersListViewModel>('StartersListViewModel').to(StartersListViewModel)
  bind<IMultiSelectionPriceViewModel>('IMultiSelectionPriceViewModel').to(
    MultiSelectionPriceViewModel
  )
  bind<RaceSubHeaderViewModel>('RaceSubHeaderViewModel').to(RaceSubHeaderViewModel)
  bind<IFixedWinPlaceSelectionsViewModel>('IFixedWinPlaceSelectionsViewModel').to(
    FixedWinPlaceSelectionsViewModel
  )
  bind<StarterImageViewModel>('StarterImageViewModel').to(StarterImageViewModel)
  bind<ResultedImageViewModel>('ResultedImageViewModel').to(ResultedImageViewModel)
  bind<IInfoToolbarViewModel>('IInfoToolbarViewModel').to(InfoToolbarViewModel)
})

export default viewmodels
