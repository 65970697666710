import React from 'react'
import { InputField } from '@mobi/component-library/Common/Input'
import { DeliveryMethodSent } from '../..'
import type { usePasswordReset } from '../hooks/usePasswordReset'

export const Step3VerifyCode: React.FC<Step3VerifyCodeProps> = ({
  validationCode,
  onValidationCodeChange,
  deliveryMethods,
  selectedDeliveryMethod,
}) => {
  return (
    <>
      <DeliveryMethodSent
        deliveryMethods={deliveryMethods}
        selectedDeliveryMethod={selectedDeliveryMethod}
        isValidationCode
      />

      <fieldset>
        <InputField
          name='Validation Code'
          id='verification-code-input'
          value={validationCode}
          onChange={onValidationCodeChange}
          type='tel'
        />
      </fieldset>
    </>
  )
}

// =====
// Types
// =====

type Step3VerifyCodeProps = Pick<
  ReturnType<typeof usePasswordReset>['handlers'],
  'onValidationCodeChange'
> &
  Pick<
    ReturnType<typeof usePasswordReset>['state'],
    'validationCode' | 'deliveryMethods' | 'selectedDeliveryMethod'
  >
