import React from 'react'
import type {
  MarketCodesType,
  PropDataTransferObject,
  StarterDataTransferObject,
} from '@mobi/api-types'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import { getSelectedProposition } from '@core/Areas/RaceCard/Store/selectors'
import { RatingTacho } from '../RatingTacho/RatingTacho'
import {
  MarketPropositionStyled,
  MarketPropositionIdSectionStyled,
  MarketPropositionInfoSectionStyled,
  MarketPropositionHeaderStyled,
  MarketPropositionInfoSubSectionStyled,
  MarketPropositionInfoStyled,
  MarketPropositionActionSectionStyled,
  MarketPropositionSratchedStyled,
} from './MarketProposition.styles'

export const MarketProposition: React.FC<{
  proposition: PropDataTransferObject
  marketCode: MarketCodesType
  meetingCode?: RaceCodes
  matchedStarter?: StarterDataTransferObject
}> = ({ proposition, matchedStarter, marketCode, meetingCode }) => {
  const dispatch = useAppDispatch()
  const selectedProposition = useAppSelector(getSelectedProposition)

  const dataTid = `data-tid-${marketCode.toLowerCase()}-proposition`
  const dataTidProposition = `data-tid-proposition-sequence`

  const silkImage = matchedStarter?.SilkImages.largest
  const isDogsSilk = meetingCode ? meetingCode.toLowerCase() === 'dogs' : false
  const isSelected = !!(
    selectedProposition &&
    selectedProposition[0] === marketCode &&
    selectedProposition[1] === proposition.PropositionSequence
  )

  return (
    <MarketPropositionStyled
      {...{
        [dataTid]: matchedStarter?.Number || '',
        [dataTidProposition]: proposition?.PropositionSequence || '',
      }}
    >
      {matchedStarter?.Number && (
        <MarketPropositionIdSectionStyled>
          <span>{matchedStarter.Number}</span>
          {silkImage && (
            <SilkImage
              isDogsSilk={isDogsSilk}
              xOffset={matchedStarter.Number - 1}
              url={silkImage.url}
              height={silkImage.size.height}
              width={silkImage.size.width}
            />
          )}
        </MarketPropositionIdSectionStyled>
      )}

      <MarketPropositionInfoSectionStyled
        style={!matchedStarter ? { justifyContent: 'center' } : undefined}
      >
        <MarketPropositionHeaderStyled
          isMatchedStarter={!!matchedStarter}
          {...{ [`${dataTid}-name`]: '' }}
        >
          {proposition.Description}
        </MarketPropositionHeaderStyled>

        <MarketPropositionInfoSubSectionStyled>
          <div>
            {matchedStarter?.Barrier && (
              <MarketPropositionInfoStyled>
                <strong>Br:</strong> {matchedStarter.Barrier}
              </MarketPropositionInfoStyled>
            )}

            {matchedStarter?.JockeyWeight && (
              <MarketPropositionInfoStyled>
                <strong>W:</strong> {matchedStarter.JockeyWeight}
              </MarketPropositionInfoStyled>
            )}
          </div>

          {!!matchedStarter?.Rating && <RatingTacho rating={matchedStarter.Rating} />}
        </MarketPropositionInfoSubSectionStyled>
      </MarketPropositionInfoSectionStyled>

      <MarketPropositionActionSectionStyled>
        {proposition.IsScratched ? (
          <MarketPropositionSratchedStyled>Scratched</MarketPropositionSratchedStyled>
        ) : (
          <WinPlaceSelection
            starterNumber={proposition.Number || 0}
            isVisible={true}
            isDisabled={!proposition.IsAvailable || isNaN(+proposition.PriceToDisplay)}
            isFavourite={false}
            priceWin={proposition.PriceToDisplay}
            pricePlace=''
            tidAttribute='data-tid-dividend-fixed'
            isSelected={isSelected}
            onClick={() =>
              dispatch(setSelectedProposition([marketCode, proposition.PropositionSequence]))
            }
          />
        )}
      </MarketPropositionActionSectionStyled>
    </MarketPropositionStyled>
  )
}
