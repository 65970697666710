import { attachDriver, createSignal, Signal } from 'rwwa-rx-state-machine'
import { fromJS } from 'immutable'
import { StateMap } from 'typings/immutable'
import { NextRace } from '@core/Data/Racing/nextRaces'

export const UpdateSuperBoostRaceList = createSignal<NextRace[]>('UpdateRaceList')
export const RemoveJumpedRaces = createSignal<void>('RemoveJumpedRaces')

interface SuperBoostRacesState {
  superBoostRaces: NextRace[]
}

export type SuperBoostRacesStateMap = StateMap<SuperBoostRacesState>

export const defaultState: SuperBoostRacesState = {
  superBoostRaces: [],
}

export function SuperBoostRacesStateDriver(
  state = fromJS(defaultState),
  signal: Signal
): SuperBoostRacesStateMap {
  switch (signal.tag) {
    case UpdateSuperBoostRaceList: {
      const data = signal.data as NextRace[]
      const races = applySuperBoostFilterRules(data)
      return state.merge({ superBoostRaces: races })
    }

    case RemoveJumpedRaces: {
      const { superBoostRaces } = state.toJS() as SuperBoostRacesState
      const races = applySuperBoostFilterRules(superBoostRaces)
      return state.merge({ superBoostRaces: races })
    }

    default: {
      return state
    }
  }
}

export const superBoostRacesState$ = attachDriver<SuperBoostRacesStateMap>({
  driver: SuperBoostRacesStateDriver,
  path: `SuperBoostRaces`,
}).map(state => state.toJS())

export const applySuperBoostFilterRules = (data: NextRace[] | null): NextRace[] => {
  if (data === null || data.length === 0) {
    return []
  }

  try {
    const races = data
      .filter((race, index, races) => {
        if (isNaN(Date.parse(race.AdvertisedStartTime))) {
          return false
        }

        if (new Date(race.AdvertisedStartTime) <= new Date()) {
          return false
        }

        const isDuplicate =
          index !==
          races.findIndex(
            otherRace =>
              otherRace.AdvertisedStartTime === race.AdvertisedStartTime &&
              otherRace.MeetingDate === race.MeetingDate &&
              otherRace.RaceNumber === race.RaceNumber &&
              otherRace.RaceType === race.RaceType &&
              otherRace.MeetingName === race.MeetingName &&
              otherRace.MeetingID === race.MeetingID
          )

        if (isDuplicate) {
          return false
        }

        return true
      })
      .sort((a: NextRace, b: NextRace) => {
        return +new Date(a.AdvertisedStartTime) - +new Date(b.AdvertisedStartTime)
      })

    return races
  } catch (error) {
    return []
  }
}
