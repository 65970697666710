import React from 'react'
import { Flex } from '@mobi/component-library/Common/Flex'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { radius } from '@mobi/component-library/Theme/Common'

export const SkeletonLoader = () => {
  return (
    <div style={{ width: '105%', margin: '0.5rem' }}>
      <Flex justifyContent='space-between'>
        <LoadingPlaceholder width='20%' height='3rem' borderRadius={radius.lg} margin='0.5rem' />
        <LoadingPlaceholder width='30%' height='3rem' borderRadius={radius.lg} margin='0.5rem' />
        <LoadingPlaceholder width='20%' height='3rem' borderRadius={radius.lg} margin='0.5rem' />
        <LoadingPlaceholder width='20%' height='3rem' borderRadius={radius.lg} margin='0.5rem' />
        <LoadingPlaceholder width='20%' height='3rem' borderRadius={radius.lg} margin='0.5rem' />
      </Flex>
    </div>
  )
}
