import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { darkTheme } from '@core/Components/ExpandablePanel/themes'
import { ExpandablePanelContent } from '@core/Components/ExpandablePanel/ExpandablePanelContent'
import {
  BetslipExpandablePanelStyled,
  BetslipExpandablePanelHeaderStyled,
  BetslipExpandablePanelToggleStyled,
  BetslipExpandablePanelTitleStyled,
  BetslipExpandablePanelBadgeStyled,
} from './BetslipExpandablePanel.styles'

interface BetslipExpandablePanelProps {
  title: string
  count: number
  refreshButton?: React.ReactChild
  expanded?: boolean
  headerClicked?: () => void
  children?: React.ReactNode
  scrollIntoViewWhenOpened?: boolean
}

export function BetslipExpandablePanel({
  title,
  count,
  expanded,
  headerClicked,
  children,
  scrollIntoViewWhenOpened = false,
  refreshButton,
}: BetslipExpandablePanelProps): JSX.Element {
  const testTid = { [`data-tid-betslip-section-${title.toLowerCase()}`]: '' }
  return (
    <ThemeProvider theme={darkTheme}>
      <BetslipExpandablePanelStyled {...testTid}>
        <BetslipExpandablePanelHeaderStyled onClick={headerClicked} isExpanded={!!expanded}>
          <Grid>
            <GridCell valign='middle' align='left' width='2rem'>
              <BetslipExpandablePanelToggleStyled>
                <Icon type={expanded ? 'arrowup' : 'arrowdown'} size='1.2rem' />
              </BetslipExpandablePanelToggleStyled>
            </GridCell>
            <GridCell valign='middle' align='left' flexGrow={0} flexBasis='auto'>
              <BetslipExpandablePanelTitleStyled>
                {title || 'Please enter a title'}
              </BetslipExpandablePanelTitleStyled>
            </GridCell>
            <GridCell valign='middle' align='left'>
              <BetslipExpandablePanelBadgeStyled data-tid-betslip-count=''>
                {count}
              </BetslipExpandablePanelBadgeStyled>
            </GridCell>
            {refreshButton && (
              <GridCell valign='middle' align='right' width='2rem'>
                {refreshButton}
              </GridCell>
            )}
          </Grid>
        </BetslipExpandablePanelHeaderStyled>
        <ExpandablePanelContent
          expanded={!!expanded}
          scrollIntoViewWhenOpened={scrollIntoViewWhenOpened}
        >
          {children}
        </ExpandablePanelContent>
      </BetslipExpandablePanelStyled>
    </ThemeProvider>
  )
}
