import React from 'react'
import { DepositLimitFrequency } from '@mobi/api-types'
import { NoticeBoxTypes, NoticeBoxSingle } from '@core/Components/NoticeBox'
import { Paragraph } from '@core/Components/Text/Paragraph'
import { PopupNoticeBox } from '@core/Components/PopupNoticeBox'
import { formatDate } from '@mobi/utils'
import { toMoney } from '@mobi/utils/money'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

export const SuccessMessage = () => (
  <NoticeBoxSingle
    title='Your deposit limit has been set.'
    noticeBoxType={NoticeBoxTypes.Success}
    hasBorder={true}
    testId='deposit-success'
  />
)

interface CoolingOffNoticeBoxProps {
  coolingOffEndDate: Date
}

export function CoolingOffNoticeBox({ coolingOffEndDate }: CoolingOffNoticeBoxProps) {
  // format the date and replace spaces with non-breaking-spaces ('\u00A0') to prevent line wrapping
  const formattedDate = formatDate(coolingOffEndDate, 'DD MMM YYYY').replace(/\s/g, '\u00A0')

  return (
    <NoticeBoxSingle
      title={
        <span>
          Your increase in deposit limit will be <strong>effective on the {formattedDate}</strong>,
          after the mandatory 7 day cooling off period.
        </span>
      }
      noticeBoxType={NoticeBoxTypes.Warning}
      hasBorder={true}
      testId='cooling-off-period'
    />
  )
}

interface CurrentFrequencyNoticeBoxProps {
  frequency: DepositLimitFrequency
}

export function CurrentFrequencyNoticeBox({ frequency }: CurrentFrequencyNoticeBoxProps) {
  if (frequency === null) {
    return null
  }

  return (
    <NoticeBoxSingle
      title={
        <span data-tid-current-frequency=''>
          Currently set to <strong>{frequency}</strong>
        </span>
      }
      noticeBoxType={NoticeBoxTypes.Info}
      hasBorder={true}
      testId='current-frequency'
    />
  )
}

interface CurrentAmountNoticeBoxProps {
  amount: number | null
}

export function CurrentAmountNoticeBox({ amount }: CurrentAmountNoticeBoxProps) {
  if (amount === null) {
    return null
  }

  return (
    <NoticeBoxSingle
      title={
        <span data-tid-current-amount=''>
          Currently set to <strong>{toMoney(amount, { decimalPlaces: 0 })}</strong>
        </span>
      }
      noticeBoxType={NoticeBoxTypes.Info}
      hasBorder={true}
      testId='current-amount'
    />
  )
}

interface DepositPopupNoticeBoxProps {
  onClose(): void
}

export function ErrorPopupNoticeBox(props: DepositPopupNoticeBoxProps) {
  const subTitle = (
    <React.Fragment>
      <Paragraph>
        We are experiencing technical difficulties that are stopping you from being able to set your
        deposit limits.
      </Paragraph>
      <Paragraph>
        Please try again or call the Customer Management Centre on{' '}
        <CustomerServiceTelephone isInternational={false} /> or internationally on{' '}
        <CustomerServiceTelephone isInternational={true} />.
      </Paragraph>
    </React.Fragment>
  )

  return (
    <PopupNoticeBox
      title='Deposit Limits'
      noticeBoxType={NoticeBoxTypes.Error}
      onClose={props.onClose}
    >
      {subTitle}
    </PopupNoticeBox>
  )
}

export function SuccessPopupNoticeBox(props: DepositPopupNoticeBoxProps) {
  return (
    <PopupNoticeBox
      title='Deposit Limit'
      noticeBoxType={NoticeBoxTypes.Success}
      onClose={props.onClose}
    >
      Thank you for reviewing your deposit limit.
    </PopupNoticeBox>
  )
}
