import React from 'react'
import { useQuery } from 'react-query'
import { PropDataTransferObject, RacePageDataTransferObject } from '@mobi/api-types'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import {
  getCurrentRacePageQueryKey,
  getSelectedProposition,
} from '@core/Areas/RaceCard/Store/selectors'
import {
  WinPlaceScratchedStyled as SCR,
  WinPlaceSpacerSpanStyled as SUS,
} from '../WinPlaceButtons.styles'

export const InsuranceButton = ({
  starterNumber,
  isFixedOddsScratched,
  isFixedOddsSuspended,
}: {
  starterNumber: number
  isFixedOddsScratched: boolean
  isFixedOddsSuspended: boolean
}) => {
  const racePageQueryKey = useAppSelector(getCurrentRacePageQueryKey)
  const { data } = useQuery<RacePageDataTransferObject>({
    queryKey: racePageQueryKey || [],
    enabled: !!racePageQueryKey,
    staleTime: Infinity,
  })

  const market = data?.FixedOddsMarkets?.find(market => market.MarketCode === 'CON')
  if (!market) return null

  const matchedStarter = market.Propositions.find(({ Number }) => Number === starterNumber)
  if (!matchedStarter) return null

  if (isFixedOddsScratched || matchedStarter.IsScratched)
    return <SCR data-tid-dividend-insurance={starterNumber}>SCR</SCR>
  if (isFixedOddsSuspended) return <SUS data-tid-dividend-insurance={starterNumber}>&ndash;</SUS>

  return <InsuranceButtonComponent matchedStarter={matchedStarter} />
}

// ===============
// Local Component
// ===============

const InsuranceButtonComponent: React.FC<{
  matchedStarter: PropDataTransferObject
}> = ({ matchedStarter }) => {
  const dispatch = useAppDispatch()
  const isSelected =
    useAppSelector(getSelectedProposition)?.[1] === matchedStarter.PropositionSequence

  const price = matchedStarter.PriceToDisplay

  return (
    <WinPlaceSelection
      tidAttribute='data-tid-dividend-insurance'
      isVisible={true}
      isDisabled={!matchedStarter.IsAvailable || isNaN(+price)}
      isFavourite={false}
      isSelected={isSelected}
      onClick={() => {
        dispatch(setSelectedProposition(['CON', matchedStarter.PropositionSequence]))
      }}
      priceWin={price}
      pricePlace=''
      starterNumber={matchedStarter.Number as number}
    />
  )
}
