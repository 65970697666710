export function toDisplayPrice(price: number | null) {
  if (price === null) {
    return '-'
  }
  // Truncate to 0 decimal place
  if (price >= 1000) {
    return price.toString().split('.')[0]
  }
  let int
  let dec
  // Truncate to 1 decimal place
  if (price >= 100) {
    ;[int, dec = '0'] = price.toString().split('.')
    return `${int}.${dec.split('')[0] || '0'}`
  }
  // Ensure 2 decimals places
  ;[int, dec = '00'] = price.toString().split('.')
  return `${int}.${dec.length === 1 ? dec + '0' : dec}`
}
