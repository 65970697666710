import React from 'react'
import { FeatureFlags } from '@mobi/settings'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'

type Features = keyof typeof FeatureFlags

type ExtractPartialType<TKey extends string> = TKey extends Features
  ? (typeof FeatureFlags)[TKey]['defaultValue'] extends boolean
    ? boolean
    : (typeof FeatureFlags)[TKey]['defaultValue']
  : never

type FeatureValueType<
  TKey extends Features,
  _TValue = ExtractPartialType<TKey>,
> = _TValue extends boolean
  ? boolean
  : _TValue extends number
    ? number
    : _TValue extends string
      ? string
      : _TValue

export function useFeature<T extends Features>(feature: T): FeatureValueType<T> {
  const { key } = FeatureFlags[feature]

  const [state, setState] = React.useState(() => {
    // initialise state from featureState$ to avoid temporarily assigning incorrect hard coded default values, i.e. avoid unnecessary rerender
    // - refer synchronous behavior: https://rxjs.dev/guide/observable#observables-as-generalizations-of-functions
    let currentValue = FeatureFlags[feature].defaultValue
    featureState$
      .subscribe(record => {
        // if unavailable (e.g. during LD initialisation), then revert to the hardcoded default value
        currentValue = record.get('features').get(key) ?? currentValue
      })
      .dispose()

    return currentValue
  })

  React.useEffect(() => {
    const subscription = featureState$
      .map(state => state.get('features').get(key))
      // if unavailable (e.g. during LD initialisation), then filter it out as we already have our state initialised, i.e. avoid unnecessary rerender
      .filter(data => data !== undefined)
      .distinctUntilChanged()
      .subscribe(data => setState(data))
    return () => subscription.dispose()
  }, [key])

  return state as FeatureValueType<T>
}

export function getFeatureValue<T extends Features>(feature: T): Promise<FeatureValueType<T>> {
  return new Promise(resolve => {
    featureState$.take(1).subscribe(state => {
      resolve(state.features.get(FeatureFlags[feature].key))
    })
  })
}
