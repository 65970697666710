import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import { Ticket } from '@core/Areas/AccountActivity/types'
import {
  HeaderSelectionIconBoxStyled as IconBox,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { LinkWrapper } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { TitleWithSuperPickIcon } from '../Common/TitleWithSuperPickIcon'

export const SingleMultiple: React.FC<{
  ticket: Ticket
}> = ({ ticket }) => {
  const contestType = meetingIdToContestType(ticket.legs[0].fixtureId)
  const isRaceNumbersVisible = shouldDisplayRaceNumbers(ticket.planSeq)
  const [title, productName] = getSelectionTitles(ticket)

  return (
    <HeaderSelectionInfoStyled>
      <IconBox data-testid='bet-icon'>
        <Icon type={contestType || 'tab'} size='2.5rem' />
      </IconBox>

      <div>
        <TitleWithSuperPickIcon isSpecial={ticket.isSpecial} specials={ticket.specialInformation}>
          {title}
        </TitleWithSuperPickIcon>

        {ticket.formula?.formulaString && (
          <span data-testid='bet-formula-string'>{ticket.formula?.formulaString}</span>
        )}

        {productName ? (
          <span data-testid='bet-product-name'>{productName}</span>
        ) : [PlanSeq.Mystery$3Combo, PlanSeq.Mystery$10Combo].includes(ticket.planSeq) ? (
          <ul>
            {ticket.legs[0].selections.map(selection => {
              const mysterySelectionTitle = `${selection.header} ${selection.selectionDetails[0].selectionString}`
              return (
                <li data-testid='mystery-selection' key={mysterySelectionTitle}>
                  {mysterySelectionTitle}
                </li>
              )
            })}
          </ul>
        ) : null}

        {isRaceNumbersVisible ? (
          <>
            <span data-testid='bet-race-numbers'>
              Races{' '}
              {ticket.legs[0].selections
                .map(selection => selection.header?.replace('R', ''))
                .join(', ')}
            </span>
            <span data-testid='bet-event-name'>{ticket.legs[0].eventName}</span>
          </>
        ) : (
          <LinkWrapper
            fixtureId={ticket.legs[0].fixtureId}
            fixtureDate={ticket.legs[0].fixtureDate}
            raceNumber={ticket.legs[0].raceNumber}
            label={ticket.legs[0].eventName}
          />
        )}
      </div>
    </HeaderSelectionInfoStyled>
  )
}

// =============
// Local Helpers
// =============

function getSelectionTitles(ticket: Ticket): [title: string, productName?: string] {
  const selectionString = ticket.legs[0].selectionString

  if (!selectionString || [PlanSeq.AllUp].includes(ticket.planSeq)) return [ticket.productName]

  return [buildSelectionString(ticket.legs[0], selectionString), ticket.productName]
}

function buildSelectionString(leg: Ticket['legs'][0], selectionString: string): string {
  // Instead of showing all acceptor numbers, use shorter selection string containing FD, S1, S2 etc
  if (
    leg.selections.length > 1 &&
    leg.selections.every(selection => !!selection.selectionDetails[0].selectionString)
  ) {
    return leg.selections
      .map(selection => selection.selectionDetails[0].selectionString)
      .join(selectionString.includes('/') ? '/' : '.')
  }

  return selectionString
}

function shouldDisplayRaceNumbers(planSeq: number): boolean {
  return [
    PlanSeq.Double,
    PlanSeq.Quaddie,
    PlanSeq.AllUp,
    PlanSeq.MysteryDouble1,
    PlanSeq.MysteryDouble2,
    PlanSeq.MysteryDouble3,
    PlanSeq.MysteryDouble4,
    PlanSeq.MysteryDouble5,
    PlanSeq.MysteryDouble6,
    PlanSeq.MysteryDouble7,
    PlanSeq.MysteryQuaddie1,
    PlanSeq.MysteryQuaddie2,
    PlanSeq.MysteryQuaddie3,
    PlanSeq.MysteryQuaddie4,
    PlanSeq.MysteryQuaddie5,
    PlanSeq.MysteryQuaddie6,
    PlanSeq.MysteryQuaddie7,
  ].includes(planSeq)
}
