import { get } from '@classic/Foundation/Services/ApiService'
import type { Ticket } from '@core/Areas/AccountActivity/types'

const enum LocalConstants {
  apiBase = '/$_/accountActivity/tickets',
}

export const getPendingTicketsForRaceFromApi = (raceKey: string) =>
  get<Response>({ url: `${LocalConstants.apiBase}/pending/race-card?raceSeq=${raceKey}` })

export const getResultedTicketsForRaceFromApi = ({ id, date, raceNum, raceKey }: ResultedArgs) => {
  const query = `fixtureDate=${date}&fixtureId=${id}&raceNumber=${raceNum}&raceSeq=${raceKey}`
  return get<Response>({
    url: LocalConstants.apiBase + '/results/race-card?' + query,
  })
}

// Types

type ResultedArgs = {
  id: string
  date: string
  raceNum: string
  raceKey: string
}

type Response = {
  tickets: Ticket[]
  totalTicketsCount: number
}
