import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const trackRaceReplayVideoLaunched = (replayLocation: string, replayAvailable: boolean) =>
  trackEvent(keys.raceReplayLaunched, {
    replayLocation: replayLocation,
    replayAvailable: replayAvailable,
  })

export const trackRaceReplayVideoFullScreen = (isFullScreen: boolean) =>
  trackEvent(keys.raceReplayFullScreen, { isFullScreen: isFullScreen })

export const trackRaceReplayVideoMute = (isMuted: boolean) =>
  trackEvent(keys.raceReplayMute, { isMuted: isMuted })

export const trackRaceReplayVideoClosed = () => trackEvent(keys.raceReplayClosed, {})
