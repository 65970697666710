import React from 'react'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'

export const LoadingBars = (): JSX.Element => (
  <GridCell padding='1rem'>
    <LoadingPlaceholder width='100%' height='4rem' />
    <LoadingPlaceholder width='100%' height='4rem' />
    <LoadingPlaceholder width='100%' height='4rem' />
    <LoadingPlaceholder width='100%' height='4rem' />
    <LoadingPlaceholder width='100%' height='4rem' />
  </GridCell>
)
