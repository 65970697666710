import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../../Common/V2'
import { defer } from '@mobi/utils'
import { gapX, gapY } from '../../Utils/gap'
import { MoneyInput } from '../Common/MoneyInput'

export type PaymentAmountProps = {
  helpText: string
  isDisabled: boolean
  maximumDepositLimit: number
  inputValue: string
  onDepositInputChanged: (value: string) => void
}

export const fiveDigitMoneyInputRegex = /^(\d{0,5}(\.\d{0,2})?)?.*/
export function sanitizeInput(input: string, regex: RegExp) {
  return input.replace(regex, '$1')
}

export const PaymentAmount = ({
  helpText,
  isDisabled,
  inputValue,
  onDepositInputChanged,
}: PaymentAmountProps) => {
  const onInputValueChanged = (inputValue: string) => {
    const sanitizedValue = sanitizeInput(inputValue, fiveDigitMoneyInputRegex)
    onDepositInputChanged(sanitizedValue)
  }

  const onAddDefinedAmount = (amount: number) => {
    const currentAmount = (+inputValue || 0) * 100
    const amountToAdd = amount * 100
    const newAmount = (currentAmount + amountToAdd) / 100
    onDepositInputChanged(newAmount.toFixed(2))
  }

  // Tack on cents if not entered by user
  const onBlur = (inputValue: string) => {
    if (inputValue.length > 0 && !inputValue.includes('.')) {
      onDepositInputChanged(inputValue.concat('.00'))
    }
  }

  return (
    <DepositAmountContainerStyled>
      <InputContainerStyled>
        <MoneyInput
          id='deposit-amount'
          label=''
          name='deposit-amount'
          inputValue={inputValue}
          helpText={helpText}
          onClearButtonPress={() => onDepositInputChanged('')}
          onInputValueChange={e => {
            onInputValueChanged(e.currentTarget.value)
          }}
          onBlur={e => {
            // ensures onBlur callback is queued only after onClearButtonPress
            defer(() => {
              onBlur(e.target.value)
            })
          }}
          isDisabled={isDisabled}
        />
      </InputContainerStyled>

      <AmountButtonsContainerStyled>
        {[5, 10, 20, 50, 100].map(amount => (
          <AmountButtonStyled
            key={amount}
            color='secondary_outline'
            onClick={() => {
              onAddDefinedAmount(amount)
            }}
            disabled={isDisabled}
          >
            ${amount}
          </AmountButtonStyled>
        ))}
      </AmountButtonsContainerStyled>
    </DepositAmountContainerStyled>
  )
}

const DepositAmountContainerStyled = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gapY('3rem')
)

const InputContainerStyled = styled.div({
  width: '32rem',
})

const AmountButtonsContainerStyled = styled.div(
  {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  gapX('1rem')
)

const AmountButtonStyled = styled(Button)({
  width: '0',
  flex: '1 1 0px',
})
