import * as ko from 'knockout'
import { FirstFourViewModel } from './FirstFourViewModel'
import template from './first4.tpl.html'

if (!ko.components.isRegistered('first-four-bettype')) {
  ko.components.register('first-four-bettype', {
    viewModel: FirstFourViewModel,
    template,
  })
}
