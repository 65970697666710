import { useFeature } from '@core/Utils/hooks/useFeature'
import { FeatureFlags } from '@mobi/settings'
import React from 'react'
import { TrendingBetsType } from './Components/TrendingBetsList'
import { TrendingBets } from './TrendingBets'

// a 'Toggled' wrapper component is used because of a bug in useFeature which fires an unnecessary undefined state causing an unnecessary render
export function TrendingBetsToggled({
  flagKey,
  type,
}: {
  flagKey: keyof typeof FeatureFlags
  type: TrendingBetsType
}) {
  const isEnabled = useFeature(flagKey)

  return isEnabled ? <TrendingBets data-testid='trending-bets' type={type} /> : null
}
