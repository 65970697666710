export enum Breakpoint {
  small = 576,
  medium = 768,
  large = 992,
  xlarge = 1200,

  // Pixel Values
  px350 = 350,
  px375 = 375,
  px400 = 400,
  px425 = 425,
  px450 = 450,
}

type Operators = '>=' | '>' | '<=' | '<'

export type BreakpointKey = keyof typeof Breakpoint

function media(operator: Operators, breakpoint: BreakpointKey) {
  const prefix = getMediaQueryPrefix(operator)
  const pixelValue = getMediaQueryValue(operator, breakpoint)
  return `@media only screen and (${prefix}-width: ${pixelValue}px)`
}

export function greaterThan(breakpoint: BreakpointKey): string {
  return media('>', breakpoint)
}

export function greaterOrEqualThan(breakpoint: BreakpointKey): string {
  return media('>=', breakpoint)
}

export function lessThan(breakpoint: BreakpointKey): string {
  return media('<', breakpoint)
}

export function lessOrEqualThan(breakpoint: BreakpointKey): string {
  return media('<=', breakpoint)
}

// Get dimension prefix based on an operator.
function getMediaQueryPrefix(operator: Operators) {
  return operator.indexOf('<') !== -1 ? 'max' : 'min'
}

// Get value based on an operator.
function getMediaQueryValue(operator: Operators, breakpoint: BreakpointKey) {
  const value = Breakpoint[breakpoint]

  switch (operator) {
    case '>':
      return value + 1
    case '<':
      return value - 1
    default:
      return value
  }
}
