import React from 'react'
import { PaymentMethodButtonStyled } from '../PaymentMethodButton.styled'
import type { PaymentMethodButton } from '../types'

export const PayPalButton: PaymentMethodButton = props => {
  return (
    <PaymentMethodButtonStyled provider='paypal' aria-label='PayPal' {...props}>
      <svg
        width='60'
        height='20'
        viewBox='0 0 69 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        role='img'
        aria-label='PayPal'
      >
        <g clipPath='url(#clip0_554_7)'>
          <path
            d='M8.37794 3.89648H3.02677C2.68375 3.89648 2.34073 4.1709 2.27212 4.51393L0.145377 18.2349C0.076772 18.5093 0.282586 18.7151 0.557005 18.7151H3.09538C3.4384 18.7151 3.78142 18.4407 3.85003 18.0976L4.39887 14.393C4.46747 14.05 4.74189 13.7756 5.15352 13.7756H6.86863C10.3675 13.7756 12.4256 12.0604 12.9744 8.69881C13.1803 7.25811 12.9744 6.09183 12.2884 5.26858C11.5337 4.37672 10.1617 3.89648 8.37794 3.89648ZM8.99538 8.90462C8.72096 10.8256 7.21166 10.8256 5.83956 10.8256H5.01631L5.56514 7.25811C5.56514 7.0523 5.77096 6.91509 5.97677 6.91509H6.3198C7.28026 6.91509 8.17212 6.91509 8.65235 7.46393C8.99538 7.73834 9.13259 8.21858 8.99538 8.90462Z'
            fill='#003087'
          />
          <path
            d='M24.2942 8.83619H21.7558C21.55 8.83619 21.3442 8.9734 21.3442 9.17921L21.207 9.86526L21.0012 9.59084C20.4524 8.76758 19.2175 8.49316 17.9826 8.49316C15.1698 8.49316 12.7686 10.6199 12.2884 13.6385C12.014 15.1478 12.357 16.5885 13.2489 17.549C14.0035 18.4408 15.1698 18.8525 16.4733 18.8525C18.7372 18.8525 20.0407 17.4118 20.0407 17.4118L19.9035 18.0978C19.8349 18.3722 20.0407 18.6467 20.3152 18.6467H22.6477C22.9907 18.6467 23.3338 18.3722 23.4024 18.0292L24.7745 9.24782C24.8431 9.11061 24.5686 8.83619 24.2942 8.83619ZM20.7954 13.7757C20.521 15.2164 19.4233 16.2455 17.914 16.2455C17.1593 16.2455 16.6105 16.0397 16.1989 15.5594C15.7872 15.0792 15.65 14.4618 15.7872 13.7757C15.9931 12.335 17.2279 11.306 18.6686 11.306C19.4233 11.306 19.9721 11.5804 20.3838 11.992C20.7268 12.4722 20.864 13.0897 20.7954 13.7757Z'
            fill='#003087'
          />
          <path
            d='M37.9466 8.83594H35.4082C35.1338 8.83594 34.928 8.97315 34.7908 9.17896L31.2233 14.3929L29.714 9.38477C29.6454 9.04175 29.3024 8.83594 29.028 8.83594H26.4896C26.2152 8.83594 25.9408 9.11036 26.078 9.45338L28.8908 17.7545L26.2152 21.4592C26.0094 21.7336 26.2152 22.1452 26.5582 22.1452H29.0966C29.371 22.1452 29.5768 22.008 29.714 21.8022L38.2896 9.45338C38.4954 9.24757 38.2896 8.83594 37.9466 8.83594Z'
            fill='#003087'
          />
          <path
            d='M46.4535 3.89648H41.1023C40.7593 3.89648 40.4163 4.1709 40.3477 4.51393L38.2209 18.1663C38.1523 18.4407 38.3581 18.6465 38.6326 18.6465H41.3768C41.6512 18.6465 41.857 18.4407 41.857 18.2349L42.4744 14.3244C42.543 13.9814 42.8174 13.7069 43.2291 13.7069H44.9442C48.443 13.7069 50.5012 11.9918 51.05 8.63021C51.2558 7.18951 51.05 6.02323 50.364 5.19997C49.5407 4.37672 48.2372 3.89648 46.4535 3.89648ZM47.0709 8.90462C46.7965 10.8256 45.2872 10.8256 43.9151 10.8256H43.0919L43.6407 7.25811C43.6407 7.0523 43.8465 6.91509 44.0523 6.91509H44.3954C45.3558 6.91509 46.2477 6.91509 46.7279 7.46393C47.0709 7.73834 47.1395 8.21858 47.0709 8.90462Z'
            fill='#009CDE'
          />
          <path
            d='M62.3698 8.83619H59.8314C59.6256 8.83619 59.4198 8.9734 59.4198 9.17921L59.2826 9.86526L59.0768 9.59084C58.5279 8.76758 57.293 8.49316 56.0582 8.49316C53.2454 8.49316 50.8442 10.6199 50.364 13.6385C50.0895 15.1478 50.4326 16.5885 51.3244 17.549C52.0791 18.4408 53.2454 18.8525 54.5489 18.8525C56.8128 18.8525 58.1163 17.4118 58.1163 17.4118L57.9791 18.0978C57.9105 18.3722 58.1163 18.6467 58.3907 18.6467H60.7233C61.0663 18.6467 61.4093 18.3722 61.4779 18.0292L62.85 9.24782C62.85 9.11061 62.6442 8.83619 62.3698 8.83619ZM58.8023 13.7757C58.5279 15.2164 57.4303 16.2455 55.9209 16.2455C55.1663 16.2455 54.6175 16.0397 54.2058 15.5594C53.7942 15.0792 53.657 14.4618 53.7942 13.7757C54 12.335 55.2349 11.306 56.6756 11.306C57.4303 11.306 57.9791 11.5804 58.3907 11.992C58.8023 12.4722 58.9396 13.0897 58.8023 13.7757Z'
            fill='#009CDE'
          />
          <path
            d='M65.3885 4.23951L63.1931 18.1663C63.1245 18.4407 63.3303 18.6465 63.6047 18.6465H65.8001C66.1431 18.6465 66.4861 18.3721 66.5547 18.029L68.7501 4.37672C68.8187 4.1023 68.6129 3.89648 68.3385 3.89648H65.8687C65.5943 3.89648 65.4571 4.03369 65.3885 4.23951Z'
            fill='#009CDE'
          />
        </g>
        <defs>
          <clipPath id='clip0_554_7'>
            <rect
              width='68.6047'
              height='21.9535'
              fill='white'
              transform='translate(0.145386 0.523438)'
            />
          </clipPath>
        </defs>
      </svg>
    </PaymentMethodButtonStyled>
  )
}
