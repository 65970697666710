import React, { useMemo, type PropsWithChildren } from 'react'
import { arrow, offset, shift, useFloating, flip } from '@floating-ui/react-dom'
import { Icon } from '../../Icon'
import { TooltipColor, TooltipStyled } from './Tooltip.styles'
import type { TooltipId } from './'

type Props = PropsWithChildren<{
  /** Element reference to trigger tooltip from. */
  triggerElRef: React.RefObject<HTMLElement>
  /** Tooltip reference key to be stored in local storage. */
  tooltipId: TooltipId
  /** Distance in pixels away from element reference. */
  offsetPx?: number
  /** Callback for determining whether the user has seen this tooltip before. */
  hasTooltipBeenSeen: (tooltipId: TooltipId) => boolean
  /** Callback when tooltip is dismissed. Useful for setting flag of whether tooltip has been seen or for iterating sequenced tooltips. */
  onDismissed?: (tooltipId: TooltipId) => void
  /** Title of the tooltip text, formatted differently to the children elements. */
  title?: string
  /**
   * Changes the placement of the tooltip to one that will fit if the initially specified placement does not.
   * (See: https://floating-ui.com/docs/flip)
   */
  flipElement?: boolean
  /** End date of when tooltip should render until. */
  untilDate?: Date
  /** Whether to hide tooltip. Eg. useSequencedTooltips hides tooltips not currently iterated on. */
  isHidden?: boolean
  /** Colour of the tooltip. */
  color?: TooltipColor
}>

/**
 * Tooltip component that is displayed if it has not been previously dismissed (seen).
 */
export const Tooltip = ({
  triggerElRef,
  tooltipId,
  offsetPx = 0,
  hasTooltipBeenSeen,
  title = '',
  children,
  onDismissed,
  flipElement = true,
  untilDate,
  isHidden = false,
  color = 'blue',
}: Props): Nullable<JSX.Element> => {
  const arrowRef = React.useRef<HTMLDivElement>(null)
  const [isBeforeUntilDate] = React.useState(() =>
    untilDate ? new Date().getTime() <= untilDate.getTime() : true
  )
  const [isDismissed, setIsDismissed] = React.useState(false)

  React.useEffect(() => {
    setIsDismissed(isHidden)
  }, [isHidden])

  const shouldRender = useMemo(
    () => !isDismissed && isBeforeUntilDate && !hasTooltipBeenSeen(tooltipId),
    [isDismissed, isBeforeUntilDate, hasTooltipBeenSeen, tooltipId]
  )

  const middleware = [offset(offsetPx), shift({ padding: 5 }), arrow({ element: arrowRef })]
  if (flipElement) middleware.push(flip())

  const {
    refs,
    placement,
    floatingStyles,
    update: updatePosition,
    middlewareData,
  } = useFloating({
    middleware,
    elements: { reference: triggerElRef.current },
  })

  const handleDismiss = React.useCallback(() => {
    setIsDismissed(true)
    onDismissed?.(tooltipId)
  }, [tooltipId, onDismissed])

  // Update Position on Changes to Trigger Element Size
  React.useEffect(() => {
    const targetNode = triggerElRef.current
    if (!shouldRender || !window.ResizeObserver || !targetNode) return undefined
    const resizeObserver = new ResizeObserver(updatePosition)
    resizeObserver.observe(targetNode)
    return () => resizeObserver.disconnect()
  }, [triggerElRef, updatePosition, shouldRender])

  // Update Position on DOM Changes (additions/removals)
  React.useEffect(() => {
    const targetNode = triggerElRef.current
    if (!shouldRender || !targetNode) return undefined
    const mutationObserver = new MutationObserver(updatePosition)
    mutationObserver.observe(document.body, { childList: true, subtree: true })
    return () => mutationObserver.disconnect()
  }, [triggerElRef, updatePosition, shouldRender])

  // Handle Dismiss on Reference Node Click
  React.useEffect(() => {
    const targetNode = triggerElRef.current
    if (!shouldRender || !targetNode) return undefined
    targetNode.addEventListener('click', handleDismiss)
    return () => targetNode.removeEventListener('click', handleDismiss)
  }, [shouldRender, handleDismiss, triggerElRef])

  if (!shouldRender) return null

  return (
    <TooltipStyled
      color={color}
      data-testid={`tooltip-${tooltipId}`}
      ref={refs.setFloating}
      style={floatingStyles}
      role='log'
      aria-live='polite'
      onClick={handleDismiss}
    >
      <div>
        <span>
          {title && <strong>{title}</strong>}
          {children}
        </span>
        <span>
          <Icon type='cross' size='1.5rem' />
        </span>

        <div
          ref={arrowRef}
          style={{
            left: middlewareData.arrow?.x ? `${middlewareData.arrow.x}px` : '',
            top: middlewareData.arrow?.y ? `${middlewareData.arrow.y}px` : '',
            right: '',
            bottom: '',
            [{
              top: 'bottom',
              right: 'left',
              bottom: 'top',
              left: 'right',
            }[placement.split('-')[0]] as string]: '-4px',
          }}
        />
      </div>
    </TooltipStyled>
  )
}
