import { useEffect, MutableRefObject, useRef } from 'react'

type Event = MouseEvent | TouchEvent
type Ref = MutableRefObject<HTMLElement | null>
type Callback = (event: Event) => void

const events = ['click', 'touchstart'] as const

export function useClickOutside(target: Ref, callback: Callback): void {
  const calledRecently = useRef(false)

  // The time between a touchstart and a click is 300ms
  // so setting the time a little further will make
  // sure that the callback is not called twice
  const callTimeout = 350

  useEffect(() => {
    function onClickOutside(e: Event): void {
      const dropdown = target.current

      if (!dropdown) {
        return
      }

      if (!dropdown.contains(e.target as HTMLElement)) {
        if (calledRecently.current) {
          return
        }

        callback(e)

        calledRecently.current = true

        window.setTimeout(() => {
          calledRecently.current = false
        }, callTimeout)
      }
    }

    events.forEach(event => {
      window.addEventListener(event, onClickOutside)
    })

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, onClickOutside)
      })
    }
  }, [target, callback])
}
