import React from 'react'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import {
  CheckboxElementStyled,
  CheckboxIconStyled,
  CheckboxStyled,
  CheckboxLabelStyled,
} from './Checkbox.styles'

interface CheckboxProps extends Partial<React.InputHTMLAttributes<HTMLInputElement>> {
  label: string
  id: string
  checked: boolean
  color?: string
  iconSize?: string
  includePadding?: boolean
}

export function Checkbox({
  label,
  checked,
  color,
  iconSize = '1.7rem',
  includePadding = true,
  ...inputProps
}: CheckboxProps) {
  const checkboxColor = color ? color : hexColors.black
  return (
    <CheckboxElementStyled>
      <CheckboxIconStyled includePadding={includePadding}>
        {checked && <Icon type='checked' color={checkboxColor} size={iconSize} />}
        {!checked && <Icon type='unchecked' color={checkboxColor} size={iconSize} />}
      </CheckboxIconStyled>
      <CheckboxStyled {...inputProps} type='checkbox' />
      <CheckboxLabelStyled
        color={checkboxColor}
        htmlFor={inputProps.id}
        includePadding={includePadding}
      >
        {label}
      </CheckboxLabelStyled>
    </CheckboxElementStyled>
  )
}
