import React from 'react'
import { TextAlignProperty } from 'csstype'
import { ParagraphStyled } from './Paragraph.styles'

export interface ParagraphProps {
  children: React.ReactNode
  align?: TextAlignProperty
  className?: string
  isDesktop?: boolean
}

export function Paragraph({
  align = 'left',
  children,
  className,
  isDesktop,
  ...options
}: ParagraphProps) {
  return (
    <ParagraphStyled align={align} className={className} isDesktop={isDesktop} {...options}>
      {children}
    </ParagraphStyled>
  )
}
