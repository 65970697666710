import { Money } from '@core/Components/Text/Money'
import React from 'react'
import { defaultState, state$, FavouriteNumbersBettingState } from './driver'
import {
  FavouriteNumbersColumn,
  FavouriteNumbersDataTable,
  FavouriteNumbersRow,
  FavouriteNumbersSection,
  Filler,
} from './FavouriteNumbers.styles'
import { useObservable } from '@core/Utils/hooks/useObservable'
import { calculateEasyBetCost, calculatePotLuckCost, costCalculator } from './calculator'
import { FavouriteNumbersBettingDrawer } from './FavouriteNumbersBettingDrawer'
import styled from '@emotion/styled'

const EmptyBettingDrawerStyled = styled('div')({
  height: '8rem',
})
interface FavouriteNumbersBetSummaryProps {
  mode: 'easyBet' | 'potLuck'
}

function isGameSelectionValid(numberOfSelections: number): boolean {
  return numberOfSelections >= 7 && numberOfSelections <= 16
}

export function isEasyBetValid(
  gameOneSelections: number,
  gameTwoSelections: number,
  poolSelections: number
): boolean {
  return (
    poolSelections > 0 &&
    isGameSelectionValid(gameOneSelections) &&
    (isGameSelectionValid(gameTwoSelections) || gameTwoSelections == 0)
  )
}

function isPotLuckBetValid(potLuckNumbers: number, potLuckBets: number, numberOfPools: number) {
  return potLuckNumbers > 0 && potLuckBets > 0 && numberOfPools > 0
}

export function FavouriteNumbersBetSummary({
  mode,
}: FavouriteNumbersBetSummaryProps): JSX.Element | null {
  const { gameOne, gameTwo, pools, potLuckBets } = useObservable(state$, defaultState)

  return (
    <FavouriteNumbersBetSummaryComponent
      mode={mode}
      pools={pools}
      gameOne={gameOne}
      gameTwo={gameTwo}
      potLuckBets={potLuckBets}
    />
  )
}

function FavouriteNumbersBetSummaryComponent({
  gameOne,
  gameTwo,
  pools,
  potLuckBets,
  mode,
}: Pick<FavouriteNumbersBettingState, 'gameOne' | 'gameTwo' | 'pools' | 'potLuckBets'> &
  FavouriteNumbersBetSummaryProps): JSX.Element {
  const gameOneNumbers = Math.min(gameOne.length, 16)
  const gameTwoNumbers = Math.min(gameTwo.length, 16)
  const numberOfPools = pools.length
  const potLuckNumbers = gameOne[0] ?? 0
  const betCost =
    mode === 'easyBet'
      ? calculateEasyBetCost(gameOneNumbers, gameTwoNumbers, numberOfPools)
      : calculatePotLuckCost(potLuckNumbers, potLuckBets, numberOfPools)
  const isValidBet =
    mode === 'easyBet'
      ? isEasyBetValid(gameOne.length, gameTwo.length, numberOfPools)
      : isPotLuckBetValid(potLuckNumbers, potLuckBets, numberOfPools)

  return (
    <FavouriteNumbersSection data-testid='favnum-betsummary'>
      <FavouriteNumbersDataTable>
        {mode == 'easyBet' && (
          <FavouriteNumbersRow>
            <FavouriteNumbersColumn data-tid-game1-numbers=''>
              Game 1 - {gameOneNumbers} numbers
            </FavouriteNumbersColumn>
            <FavouriteNumbersColumn>
              <span data-tid-game1-cost=''>
                <Filler>&nbsp;</Filler>
                <Money amount={costCalculator(gameOneNumbers)} />
              </span>
            </FavouriteNumbersColumn>
          </FavouriteNumbersRow>
        )}
        {mode == 'easyBet' && (
          <FavouriteNumbersRow>
            <FavouriteNumbersColumn data-tid-game-2-numbers=''>
              Game 2 - {gameTwoNumbers} numbers
            </FavouriteNumbersColumn>
            <FavouriteNumbersColumn>
              <span data-tid-game2-cost=''>
                <Filler>+</Filler>
                <Money amount={costCalculator(gameTwoNumbers)} />
              </span>
            </FavouriteNumbersColumn>
          </FavouriteNumbersRow>
        )}
        {mode == 'potLuck' && (
          <FavouriteNumbersRow>
            <FavouriteNumbersColumn data-tid-potluck-numbers=''>
              {potLuckNumbers} numbers
            </FavouriteNumbersColumn>
            <FavouriteNumbersColumn>
              <span data-tid-potluck-cost=''>
                <Filler>&nbsp;</Filler>
                <Money amount={costCalculator(potLuckNumbers)} />
              </span>
            </FavouriteNumbersColumn>
          </FavouriteNumbersRow>
        )}
        <FavouriteNumbersRow>
          <FavouriteNumbersColumn data-tid-number-of-pool-dates-label=''>
            Number of pool dates
          </FavouriteNumbersColumn>
          <FavouriteNumbersColumn>
            <span data-tid-number-of-pool-dates=''>
              <Filler>x</Filler> {numberOfPools}
            </span>
          </FavouriteNumbersColumn>
        </FavouriteNumbersRow>
        {mode == 'potLuck' && (
          <FavouriteNumbersRow>
            <FavouriteNumbersColumn data-tid-number-of-bets-label=''>
              Number of bets
            </FavouriteNumbersColumn>
            <FavouriteNumbersColumn>
              <span data-tid-pot-luck-bets=''>
                <Filler>x</Filler> {potLuckBets}
              </span>
            </FavouriteNumbersColumn>
          </FavouriteNumbersRow>
        )}
        <FavouriteNumbersRow>
          <FavouriteNumbersColumn data-tid-bet-cost=''>Bet cost</FavouriteNumbersColumn>
          <FavouriteNumbersColumn>
            <span data-tid-bet-cost=''>
              <Filler>&nbsp;</Filler>
              <Money amount={betCost} />
            </span>
          </FavouriteNumbersColumn>
        </FavouriteNumbersRow>
      </FavouriteNumbersDataTable>
      <FavouriteNumbersBettingDrawer open={isValidBet} />
      {isValidBet && <EmptyBettingDrawerStyled />}
    </FavouriteNumbersSection>
  )
}
