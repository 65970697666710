import * as ko from 'knockout'
import { IDogStarterViewModel } from './IDogStarterViewModel'
import template from './dog.starter.tpl.html'
import container from '@classic/Betting-v2/IOC/inversify.config'

if (!ko.components.isRegistered('dog-starter')) {
  ko.components.register('dog-starter', {
    viewModel: {
      createViewModel: (
        params: Parameters<IDogStarterViewModel['init']>[0]
      ): IDogStarterViewModel => {
        const viewModel = container.get<IDogStarterViewModel>('IDogStarterViewModel')
        viewModel.init(params)
        return viewModel
      },
    },
    template,
  })
}
