/**
 * Create an array from 0 to stop (exclusive)
 *
 * Examples:
 *
 * ```
 * range(5) // [0, 1, 2, 3, 4]
 * range(0) // []
 * ```
 */
export function range(stop: number): number[]

/**
 * Create an array from start (inclusive) to stop (exclusive)
 *
 * Examples:
 *
 * ```
 * range(0, 5) // [0, 1, 2, 3, 4]
 * range(5, 8) // [5, 6, 7]
 * ```
 */
export function range(start: number, stop: number): number[]
export function range(start: number, stop?: number): number[] {
  if (stop === undefined) {
    stop = start
    start = 0
  }

  const length = stop - start

  if (length === 0) {
    return []
  }

  return Array.from(Array(length), (_, index) => start + index)
}
