import dayjs from 'dayjs'
import { Campaign } from '@core/State/UserAccount/userAccountDriver'

export const getRaceUrl = (campaign: Campaign): string => {
  if (campaign?.redemptionDetails?.race) {
    if (campaign.redemptionDetails.race.fixtureId && campaign.redemptionDetails.race.fixtureDate) {
      let baseUrl = `/#tote/meetings/${campaign.redemptionDetails.race.fixtureId}`

      if (campaign.redemptionDetails.race.raceNumber) {
        baseUrl += `/${campaign.redemptionDetails.race.raceNumber}`
      }

      baseUrl += `?date=${dayjs(campaign.redemptionDetails.race.fixtureDate).format('YYYY-MM-DD')}`
      return baseUrl
    }
  }

  return '/#next-events'
}
