import React from 'react'

export const CreditCardLogo = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='credit-card-logo-title'
      aria-describedby='credit-card-logo-description'
      role='graphics-symbol'
    >
      <title id='credit-card-logo-title'>Credit Card Icon</title>
      <desc id='credit-card-logo-description'>An icon depicting a debit or credit card.</desc>
      <mask
        id='mask0_924_20838'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='8'
        width='32'
        height='32'
      >
        <rect x='8' y='8' width='32' height='32' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_924_20838)'>
        <path
          d='M37.3337 15.9997V31.9997C37.3337 32.733 37.0725 33.3608 36.5503 33.883C36.0281 34.4052 35.4003 34.6663 34.667 34.6663H13.3337C12.6003 34.6663 11.9725 34.4052 11.4503 33.883C10.9281 33.3608 10.667 32.733 10.667 31.9997V15.9997C10.667 15.2663 10.9281 14.6386 11.4503 14.1163C11.9725 13.5941 12.6003 13.333 13.3337 13.333H34.667C35.4003 13.333 36.0281 13.5941 36.5503 14.1163C37.0725 14.6386 37.3337 15.2663 37.3337 15.9997ZM13.3337 18.6663H34.667V15.9997H13.3337V18.6663ZM13.3337 23.9997V31.9997H34.667V23.9997H13.3337Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}
