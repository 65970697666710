import { z } from 'zod'

export const ContactDetailsApiPath = `/$_/api/contactDetails`

export const contactDetailsSchema = z.object({
  NameDetails: z.object({
    FirstName: z.string().nullable(),
    Surname: z.string().nullable(),
  }),
  PostalAddress: z.object({
    UnitNumber: z.string().nullable(),
    StreetNumber: z.string().nullable(),
    StreetName: z.string().nullable(),
    StreetType: z.string().nullable(), // Road, Avenue, etc
    Suburb: z.string().nullable(),
    Postcode: z.string().nullable(),
    State: z.string().nullable(),
    Country: z.union([z.literal('Australia'), z.string()]).nullable(),
  }),
  Contact: z.object({
    Email: z.string().nullable(),
    Mobile: z.object({
      PhoneNumber: z.string().nullable(),
    }),
  }),
})

export type ContactDetails = z.infer<typeof contactDetailsSchema>

export interface NameDetails {
  Title: string
  FirstName: string
  MiddleName: string
  Surname: string
  PreferredName: string
}

export interface PhoneNumber {
  CountryCode: string
  AreaCode?: string
  PhoneNumber: string
}

export interface Address {
  UnitNumber: string
  StreetNumber: string
  StreetName: string
  StreetType: string
  Suburb: string
  Postcode: string
  State: string
  Country: string
}

export interface DriversLicence {
  Number: string
  State: string
  CardNumber: string
}

export interface Passport {
  Number: string
  PassportCountry: string
}

export interface Medicare {
  BlueYellowMedicareExpiryDate?: Date
  GreenMedicareExpiry?: string
  MedicareCardColour?: string
  MedicareMiddleNameOnCard?: string
  Number?: string
  Reference?: string
}

export type DepositLimitFrequency = 'Daily' | 'Weekly' | 'Fortnightly' | 'Monthly' | null

export interface BetAccountHolder {
  BetAccountHolderNumber: number
  NameDetails: NameDetails
  DateOfBirth?: Date
  Contact?: {
    HomePhone?: PhoneNumber
    WorkPhone?: PhoneNumber
    Mobile?: PhoneNumber
    Email?: string
  }
  ResidentialAddress?: Address
  PostalAddress?: Address
  PostalAddressSameAsResidential?: boolean
  DriversLicence?: DriversLicence
  Passport?: Passport
  Medicare?: Medicare
  ReverificationAlertDate?: Date | null
  ReverificationDueDate?: Date | null
  NameDateOfBirthChanged?: boolean
  ReverificationFirstAttemptDate?: Date | null
  PrimaryContact?: boolean
  PreCommitmentDetails: PreCommitmentDetails
}

export interface PreCommitmentDetails {
  OptInIndicator: boolean
  ProposedOptInIndicator: boolean
  ProposedOptOutDate: Date | null
  MaxBetAmount: string
  ProposedMaxBetAmount: string
  ProposedMaxBetDate: Date | null
  MaxDepositAmount: number | null
  MaxDepositFrequency: DepositLimitFrequency
  ProposedMaxDepositAmount: number | null
  ProposedMaxDepositFrequency: DepositLimitFrequency
  ProposedMaxDepositDate: Date | null
  OptInMarketingIndicator: boolean
  UserCreditCardBlockedIndicator: boolean
  ProposedUserCreditCardBlockedDate: Date | null
  CreditCardDisputedIndicator: boolean
  BlockPayPalIndicator: boolean
  ProposedPayPalReleaseDate: Date | null
}

export interface FullSingleBetAccountHolder {
  BetAccountHolderNumber?: number
  NameChanged: boolean
  Contact?: {
    HomePhone?: PhoneNumber
    WorkPhone?: PhoneNumber
    Mobile?: PhoneNumber
    Email?: string
  }
  ResidentialAddress?: Address
  PostalAddress?: Address
  PostalAddressSameAsResidential: boolean
  Medicare?: Medicare
  Passport?: Passport
  DriversLicence?: DriversLicence
}
