import colors from '@core/Settings/colors'
import { hexColors } from '@mobi/settings'
import { FontWeightProperty } from 'csstype'

interface ThemeSettings {
  background?: string
  text: {
    color?: string
    fontWeight?:
      | number
      | '-moz-initial'
      | 'inherit'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'bold'
      | 'normal'
      | 'bolder'
      | 'lighter'
      | FontWeightProperty[]
      | undefined
  }
  boxShadow?: string
  specialIcon?: string
  borderTop?: string
  borderLeft?: string
  borderRight?: string
  borderBottom?: string
  hasPadding?: boolean
  hasMargin?: boolean
}

export enum ExpandablePanelThemes {
  dark = 'dark',
  light = 'light',
  white = 'white',
  whiteNested = 'whiteNested',
  purple = 'purple',
  greyWhite = 'greyWhite',
  lavender = 'lavender',
  yellow = 'yellow',
}

export interface ExpandablePanelComponentTheme {
  content: ThemeSettings
  header: ThemeSettings
  toggle: ThemeSettings
}

export const darkTheme: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.dark.content.background,
    text: {
      color: colors.expandablePanel.dark.content.text,
    },
    boxShadow: colors.expandablePanel.dark.content.boxShadow,
  },
  header: {
    background: colors.expandablePanel.dark.header.background,
    text: {
      color: colors.expandablePanel.dark.header.text,
      fontWeight: 'bold',
    },
    hasPadding: true,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.dark.toggle.text,
    },
  },
}

const lightTheme: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.light.content.background,
    text: {
      color: colors.expandablePanel.light.content.text,
    },
    boxShadow: colors.expandablePanel.light.content.boxShadow,
  },
  header: {
    text: {
      color: colors.expandablePanel.light.header.text,
      fontWeight: 'bold',
    },
    background: colors.expandablePanel.light.header.background,
    hasPadding: true,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.light.toggle.text,
    },
  },
}

const whiteTheme: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.white.content.background,
    text: {
      color: colors.expandablePanel.white.content.text,
    },
    boxShadow: colors.expandablePanel.white.content.boxShadow,
  },
  header: {
    text: {
      color: colors.expandablePanel.white.header.text,
      fontWeight: 'bold',
    },
    background: colors.expandablePanel.white.header.background,
    borderBottom: `1px solid ${colors.expandablePanel.white.header.border}`,
    borderTop: `1px solid ${colors.expandablePanel.white.header.border}`,
    hasPadding: true,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.white.toggle.text,
    },
  },
}

const whiteNestedTheme: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.white.content.background,
    text: {
      color: colors.expandablePanel.white.content.text,
    },
    boxShadow: colors.expandablePanel.white.content.boxShadow,
  },
  header: {
    text: {
      color: colors.expandablePanel.white.header.text,
      fontWeight: 'bold',
    },
    background: colors.expandablePanel.white.header.background,
    hasMargin: false,
    hasPadding: false,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.white.toggle.text,
    },
  },
}

const purpleTheme: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.purple.content.background,
    text: {
      color: colors.expandablePanel.purple.content.text,
    },
    boxShadow: colors.expandablePanel.purple.content.boxShadow,
  },
  header: {
    text: {
      color: colors.expandablePanel.purple.header.text,
      fontWeight: 'bold',
    },
    specialIcon: colors.expandablePanel.purple.header.specialIcon,
    background: colors.expandablePanel.purple.header.background,
    hasPadding: true,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.purple.toggle.text,
    },
  },
}

const greyWhite: ExpandablePanelComponentTheme = {
  content: {
    background: colors.expandablePanel.greyWhite.content.background,
    text: {
      color: colors.expandablePanel.greyWhite.content.text,
    },
    boxShadow: colors.expandablePanel.greyWhite.content.boxShadow,
  },
  header: {
    text: {
      color: colors.expandablePanel.greyWhite.header.text,
    },
    background: colors.expandablePanel.greyWhite.header.background,
    hasMargin: false,
    hasPadding: true,
  },
  toggle: {
    text: {
      color: colors.expandablePanel.greyWhite.toggle.text,
    },
  },
}

const lavender: ExpandablePanelComponentTheme = {
  content: {
    text: {
      color: hexColors.black,
    },
    background: hexColors.lavenderBlue,
  },
  header: {
    text: {
      color: hexColors.black,
    },
    background: hexColors.lavenderBlue,
  },
  toggle: {
    text: {
      color: hexColors.black,
    },
  },
}

const yellow: ExpandablePanelComponentTheme = {
  content: {
    text: {
      color: hexColors.black,
    },
    background: hexColors.capeHoney,
  },
  header: {
    text: {
      color: hexColors.black,
    },
    background: hexColors.capeHoney,
  },
  toggle: {
    text: {
      color: hexColors.black,
    },
  },
}

export function getExpandablePanelTheme(
  theme: ExpandablePanelThemes | undefined
): ExpandablePanelComponentTheme {
  switch (theme) {
    case ExpandablePanelThemes.dark:
      return darkTheme
    case ExpandablePanelThemes.light:
      return lightTheme
    case ExpandablePanelThemes.white:
      return whiteTheme
    case ExpandablePanelThemes.whiteNested:
      return whiteNestedTheme
    case ExpandablePanelThemes.purple:
      return purpleTheme
    case ExpandablePanelThemes.greyWhite:
      return greyWhite
    case ExpandablePanelThemes.lavender:
      return lavender
    case ExpandablePanelThemes.yellow:
      return yellow
    default:
      return darkTheme
  }
}
