import { deleteAsync, get, post } from '@classic/Foundation/Services/ApiService'
import type {
  BlackbookComment,
  BlackbookControlProps,
  BlackbookDetailsResponse,
  BlackbookEntryRacingToday,
  BlackbookPreferencesRequestBody,
  BlackbookRunner,
} from '@core/Areas/Blackbook/types'

const enum LocalConstants {
  apiBase = '/api/blackbook/',
}

export function getAllJockeyRacesRunningSoon(starterName: string) {
  return get<{ Entries: BlackbookEntryRacingToday[] }>({
    url: `${LocalConstants.apiBase}details/jockey/${encodeURIComponent(starterName)}`,
  })
    .then(data => data.Entries.filter(runner => !!runner.RacingTodayDetails))
    .catch(() => [] as BlackbookEntryRacingToday[])
}

export const postAddRunnerToBlackbook = ({
  starterName,
  starterType: fixType,
}: Pick<BlackbookControlProps, 'starterName' | 'starterType'>) =>
  post<{
    success: boolean
    errorCode: number
    errorMessage: string
  }>({ url: LocalConstants.apiBase + 'add', body: { starterName, fixType } })

export const getBlackbookDetails = () =>
  get<BlackbookDetailsResponse>({ url: LocalConstants.apiBase + 'details' })

export const postBlackbookAlertPreferences = (preferences: BlackbookPreferencesRequestBody) =>
  post<void>({ url: LocalConstants.apiBase + 'preferences', body: preferences })

export const deleteBlackbookEntry = (id: number) =>
  deleteAsync<void>({ url: LocalConstants.apiBase + id })

/** Lightwight call to only retrieve list of runner names in BB */
export const postGetBlackbookRunners = () =>
  post<{
    success: boolean
    errorCode: number
    errorMessage: string
    data?: BlackbookRunner[]
  }>({ url: LocalConstants.apiBase + 'getblackbookrunners' })

// ========
// Comments
// ========

export const postBlackbookComment = ({
  id,
  noteID,
  comment,
}: {
  id: number
  noteID?: number
  comment: string
}) =>
  post<void>({
    url: LocalConstants.apiBase + 'AddOrUpdateNote',
    body: { BlackBookNotesSeq: noteID, BlackbookSeq: id, Comment: comment },
  })

export const getBlackbookComments = (blackbookSeq: number) =>
  get<BlackbookComment[]>({ url: LocalConstants.apiBase + `GetNotes?blackbookSeq=${blackbookSeq}` })

export const deleteBlackbookComment = (blackbookSeq: number, blackbookNotesSeq: number) =>
  post<void>({
    url:
      LocalConstants.apiBase +
      `DeleteNote?blackbookSeq=${blackbookSeq}&blackbookNotesSeq=${blackbookNotesSeq}`,
  })
