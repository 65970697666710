import React, { ComponentProps } from 'react'
import { SvgRaceCardIcon } from './Icons.styles'

type Props = ComponentProps<typeof SvgRaceCardIcon>

export const Video = ({ size }: Props) => (
  <SvgRaceCardIcon size={size}>
    <path
      d='M7.5 13.9375L14.5 10L7.5 6.0625V13.9375ZM2 17C1.45 17 0.979333 16.8288 0.588 16.4864C0.196 16.1434 0 15.7312 0 15.25V4.75C0 4.26875 0.196 3.85692 0.588 3.5145C0.979333 3.1715 1.45 3 2 3H18C18.55 3 19.021 3.1715 19.413 3.5145C19.8043 3.85692 20 4.26875 20 4.75V15.25C20 15.7312 19.8043 16.1434 19.413 16.4864C19.021 16.8288 18.55 17 18 17H2ZM2 15.25H18V4.75H2V15.25Z'
      fill='#512D6D'
    />
  </SvgRaceCardIcon>
)
