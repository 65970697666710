import CheckboxSelectionProcessor from '@classic/Betting-v2/Components/Core/Processors/CheckboxSelectionProcessor'
import MultiLegSelectionResultProcessor from '@classic/Betting-v2/Components/Core/SelectionResults/MultiLegSelectionResultProcessor'
import AllUpValidator from '@classic/Betting-v2/Validation/AllUpValidator'
import { BetType } from '../Betting/BetType'
import BetTypeInformation from '../Betting/BetTypeInformation'
import type { IConfigureBetType } from './IConfigureBetType'

export default class AllUpConfigurator implements IConfigureBetType {
  build(): BetTypeInformation {
    return new BetTypeInformation({
      betType: BetType.AllUp,
      name: 'All Up',
      supportsFlexi: false,
      sortOptions: ['Number', 'FixedPrice', 'Price'],
      template: 'allup-template',
      betSelectionTemplate: 'dual-checkbox-selection-template',
      multiBet: true,
      legs: 1,
      multiSelections: true,
      processor: new CheckboxSelectionProcessor(1),
      validator: new AllUpValidator(2),
      selectionResultProcessor: new MultiLegSelectionResultProcessor(),
      checkBoxCount: 1,
      fieldCheckBoxCount: 1,
      sameAsCheckBoxCount: 0,
    })
  }
}
