import * as ko from 'knockout'
import container from '../../../IOC/inversify.config'
import { IFinisherPlaceViewModel } from './IFinisherPlaceViewModel'
import template from './finisher.place.tpl.html'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'

export function register(): void {
  if (!ko.components.isRegistered('finisher-place')) {
    ko.components.register('finisher-place', {
      viewModel: {
        createViewModel: (params: { finisher: IObservableFinisher }): IFinisherPlaceViewModel => {
          const viewModel = container.get<IFinisherPlaceViewModel>('IFinisherPlaceViewModel')
          viewModel.init(params)
          return viewModel
        },
      },
      template,
    })
  }
}
