import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const StatusSilksContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '0 0.7rem 0.5rem 1rem',
  justifyContent: 'flex-end',
  marginTop: '-0.5rem',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    marginLeft: '0.2rem',
  },
})

export const StatusSilkStyled = styled('div')<{ isFallback: boolean }>(
  {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    color: hexColors.dimGrey,
  },
  ({ isFallback }) =>
    isFallback && {
      backgroundColor: hexColors.gainsboro,
      borderRadius: '50%',
    }
)
