import { toTitleCase } from '@mobi/utils/string'

const regexRuleToCaptureCountryAbbr = / - [A-Za-z]{2,3}$/

export function getCleanMeetingName(meetingName: string) {
  let name = toTitleCase(meetingName)
  const matchResult = name.match(regexRuleToCaptureCountryAbbr)

  if (matchResult) {
    const meeting = name.replace(regexRuleToCaptureCountryAbbr, '')
    const country = matchResult[0].replace('-', '').trim()
    name = `${meeting} - ${country.toUpperCase()}`
  }

  return name
}
