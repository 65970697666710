import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const GreetingLabelStyled = styled('span')({
  color: hexColors.charCoal,
  fontWeight: '500',
  fontSize: '1.4rem',
  paddingTop: '0.15rem',
  marginLeft: '-0.5rem',
})

export const GreetingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '-1rem',
})
