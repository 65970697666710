import React from 'react'
import { Observable } from 'rx'
import { hexColors } from '@mobi/settings'
import { observeImmutable } from '@core/Components/HOCs'
import { Checkbox } from '../Checkbox/Checkbox'
import { state$ as investmentState$, InvestmentState } from '../BetInvestment/betInvestmentDriver'
import { QuickbetState, state$ as quickbetState$ } from '../../driver'
import { ToggleEachWay } from '../../signals'

interface EachWayCheckboxProps {
  isEachWay: boolean
  isUsingBonusBet: boolean
}

const state$ = Observable.combineLatest(
  quickbetState$,
  investmentState$,
  (quickbetRecord, investmentRecord): EachWayCheckboxProps => {
    const quickbetState = quickbetRecord.toJS() as QuickbetState
    const investmentState = investmentRecord.toJS() as InvestmentState
    return {
      isEachWay: quickbetState.isEachWay,
      isUsingBonusBet: !!investmentState.bonusBet,
    }
  }
)

export function EachWayCheckboxComponent({
  isEachWay,
  isUsingBonusBet,
}: EachWayCheckboxProps): JSX.Element {
  const toggleEachWay = () => ToggleEachWay()
  return (
    <Checkbox
      id='quickbet-eachway-checkbox'
      color={hexColors.white}
      label='Each Way'
      checked={isEachWay}
      onChange={toggleEachWay}
      disabled={isUsingBonusBet}
      includePadding={false}
    />
  )
}

export default observeImmutable(state$, ({ record }) => <EachWayCheckboxComponent {...record} />)
