import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import {
  formatDateTime,
  getLegStatus,
  getNumberFromString,
  getSingleTicketTitle,
  shouldUseFallbackIcon,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { DetailsSelectionInfoStyled as SelectionInfo } from '../../SelectionInfo.styles'
import { MultiSilkStyled, StarterMultiStyled, StatusInfoStyled } from './Multi.styles'
import { LinkWrapper } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import {
  SilkFallback,
  StatusPillStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Common'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'

export const Multi: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const isFooto = ticket.planSeq === PlanSeq.Footo
  return (
    <>
      {ticket.legs.map(leg => {
        let legStatus = getLegStatus(leg.result)
        if (ticket.status === 'Resolved' && legStatus == 'Pending') {
          legStatus = null
        }

        const shouldHideSilk = shouldUseFallbackIcon(leg, ticket.planSeq)

        return (
          <StarterMultiStyled key={leg.header}>
            <MultiSilkStyled data-testid='ticket-detail-multi-silk'>
              {shouldHideSilk ? (
                <SilkFallback contestType={meetingIdToContestType(leg.fixtureId)} />
              ) : (
                <SilkImageContained
                  fixtureDate={leg.fixtureDate}
                  fixtureId={leg.fixtureId}
                  raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
                  acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
                />
              )}
            </MultiSilkStyled>

            <SelectionInfo data-testid='ticket-detail-multi-selection-info'>
              <div>
                <h4>{getStarterTitle(leg, ticket.planSeq)}</h4>
                {leg.eventNameLine2 && <span>{leg.eventNameLine2}</span>}
                <LinkWrapper
                  fixtureId={leg.fixtureId}
                  fixtureDate={leg.fixtureDate}
                  raceNumber={leg.raceNumber}
                  label={leg.eventName}
                />
                {leg.sportName && <span>{leg.sportName}</span>}
                {leg.advertisedStartTime && <span>{formatDateTime(leg.advertisedStartTime)}</span>}
                {isFooto && (
                  <span>{leg.selections[1].selectionDetails[0].acceptorDetails[0].name}</span>
                )}
              </div>
            </SelectionInfo>

            <StatusInfoStyled>
              {legStatus && (
                <StatusPillStyled status={legStatus} size='small' data-testid='multi-leg-status'>
                  {legStatus}
                </StatusPillStyled>
              )}
              <div>{leg.selections[0].header || leg.betType}</div>
            </StatusInfoStyled>
          </StarterMultiStyled>
        )
      })}
    </>
  )
}

// Helpers
function getStarterTitle(leg: Ticket['legs'][0], planSeq: PlanSeq) {
  const isPlace = leg.betType?.toLowerCase().includes('place')
  const returnRate = isPlace ? leg.return?.split(',')[1] ?? leg.return : leg.return
  const singleTicketTitle = getSingleTicketTitle(leg, planSeq).title
  const ticketReturn = returnRate ? ` @ ${getNumberFromString(returnRate)}` : ''

  return singleTicketTitle + ticketReturn
}
