import React, { memo, type ComponentPropsWithoutRef } from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { PillButton } from '@mobi/component-library/Common/V2/Buttons'
import { Input } from '@mobi/component-library/Common/V2/Inputs'
import styled from '@emotion/styled'
import { newTheme as theme } from '@mobi/component-library/Theme'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

type Props = {
  name: string
  inputValue: string
  helpText: string
  onClearButtonPress: React.MouseEventHandler<HTMLButtonElement>
  onInputValueChange: React.ChangeEventHandler<HTMLInputElement>
} & Omit<ComponentPropsWithoutRef<typeof Input>, 'type' | 'suffix'>

const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
]

export const MoneyInput = memo<Props>(
  ({ name, inputValue, helpText, onClearButtonPress, onInputValueChange, ...inputProps }) => {
    const renderSuffix = () =>
      inputValue !== '' && (
        <PillButtonStyled
          onClick={onClearButtonPress}
          aria-label='Clear deposit amount'
          disabled={inputProps.isDisabled}
          color='secondary_grey'
          isIconOnlyButton={true}
          size='xs'
        >
          <Icon type='cross' size='1.3rem' />
        </PillButtonStyled>
      )

    const inputWidth = React.useMemo(() => {
      const numbersWidth = inputValue.replace('.', '').length
      const decimalWidth = inputValue.includes('.') ? 0.5 : 0
      return numbersWidth + decimalWidth
    }, [inputValue])

    return (
      <MoneyInputStyled
        inputWidth={inputWidth}
        type='text'
        inputMode='decimal'
        autoComplete='off'
        name={name}
        value={inputValue}
        placeholder='0.00'
        helpText={helpText}
        onChange={onInputValueChange}
        prefix='$'
        suffix={renderSuffix()}
        onKeyDown={e => {
          if (!allowedKeys.includes(e.key)) {
            e.preventDefault()
          }
        }}
        {...inputProps}
      />
    )
  }
)

MoneyInput.displayName = 'MoneyInput'

const MoneyInputStyled = styled(Input)<{
  inputWidth: number
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  '& > span': {
    textAlign: 'center',
    marginTop: spacing.smx1,
    ...font.size.sm,
  },

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.surface[50],
    boxShadow: 'none',
    border: '0',
    height: '7.2rem',
    width: '19.9rem',
    ...theme.font.size.xl8,

    '& > span': {
      color: props.inputWidth > 0 ? 'inherit' : colors.neutral[400],
    },

    '& > input': {
      width: props.inputWidth > 0 ? `${props.inputWidth}ch` : '4ch',
      maxWidth: '7ch',
      flex: 'none',

      '&::placeholder': {
        color: colors.neutral[400],
      },

      '&:not(:first-child)': {
        marginInlineStart: '0.25ch',

        '@supports not (margin-inline-start: 0)': {
          marginLeft: '0.25ch',
        },
      },

      '&:not(:last-child)': {
        marginInlineEnd: '0.25ch',
      },
    },
  },
}))

const PillButtonStyled = styled(PillButton)({
  backgroundColor: colors.neutral[200],
})
