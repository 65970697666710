import React from 'react'
import { useHistory } from 'react-router-dom'

import { BACK_BUTTON_ID } from '@core/Areas/AppHeader/Components/Buttons/Buttons'

/**
 * Use this when you want the built-in back button in the app header to do the
 * same thing as the browser back button.
 */
export const useHistoryBackNav = (): void => {
  const history = useHistory()
  React.useEffect(() => {
    const backButtonEl = document.getElementById(BACK_BUTTON_ID)

    const handleBackButtonClick = (e: MouseEvent) => {
      e.preventDefault()
      history.goBack()
    }
    backButtonEl?.addEventListener('click', handleBackButtonClick)

    return () => {
      backButtonEl?.removeEventListener('click', handleBackButtonClick)
    }
  }, [history])
}
