import React from 'react'
import { useDispatch } from 'react-redux'
import { throttle } from '@core/Utils'
import { DATE_HEADER_DATA_SELECTOR } from '@core/Areas/AccountActivity/Components/PagesDateHeader/DateHeader'

interface UseHeaderScrollEvents {
  firstDate: string
}

interface UseHeaderScrollEventsReturn {
  fixedHeaderWrapperElRef: React.MutableRefObject<HTMLDivElement | null>
  fixedHeaderElRef: React.MutableRefObject<HTMLDivElement | null>
  displayDate: string
}

const scrollThrottleMs = 50

export const useHeaderScrollEvents = ({
  firstDate,
}: UseHeaderScrollEvents): UseHeaderScrollEventsReturn => {
  const dispatch = useDispatch()

  const fixedHeaderWrapperElRef = React.useRef<HTMLDivElement>(null)
  const fixedHeaderElRef = React.useRef<HTMLDivElement>(null)

  const [displayDate, setDisplayDate] = React.useState(firstDate)
  const prevDisplayDateRef = React.useRef<string | null>(null)

  // Reset first date
  React.useEffect(() => {
    setDisplayDate(firstDate)
  }, [firstDate])

  // Set up scroll listener
  React.useEffect(() => {
    if (!fixedHeaderWrapperElRef.current || !fixedHeaderElRef.current) return undefined

    const headerContainerEl = fixedHeaderWrapperElRef.current
    const fixedHeaderContainerEl = fixedHeaderElRef.current

    const handleScroll = throttle(() => {
      // =============================
      // Handle fixing header position
      // =============================
      const shouldFix = headerContainerEl.getBoundingClientRect().top < 1

      headerContainerEl.style.height = shouldFix
        ? fixedHeaderContainerEl.offsetHeight + 'px'
        : 'auto'

      fixedHeaderContainerEl.style.position = shouldFix ? 'fixed' : ''

      // ==========================
      // Handle setting header date
      // ==========================
      let closestToHeader = Number.MIN_SAFE_INTEGER
      let newDisplayDate = firstDate
      const dateHeaders = document.querySelectorAll(`[${DATE_HEADER_DATA_SELECTOR}]`)
      dateHeaders.forEach(dateHeader => {
        const topPosition = dateHeader.getBoundingClientRect().top
        const isDateBelowFixedHeader = topPosition > fixedHeaderContainerEl.offsetHeight
        if (isDateBelowFixedHeader || topPosition < closestToHeader) return

        closestToHeader = topPosition
        newDisplayDate = dateHeader.textContent || ''
      })

      if (
        newDisplayDate &&
        (!prevDisplayDateRef.current || prevDisplayDateRef.current !== newDisplayDate)
      ) {
        setDisplayDate(newDisplayDate)
        prevDisplayDateRef.current = newDisplayDate
      }
    }, scrollThrottleMs)

    window.document.addEventListener('scroll', handleScroll)
    return () => {
      window.document.removeEventListener('scroll', handleScroll)
    }
  }, [firstDate, dispatch])

  return { fixedHeaderElRef, fixedHeaderWrapperElRef, displayDate }
}
