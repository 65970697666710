import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const FooterButtonContainerStyled = styled('div')({
  display: 'flex',
  padding: '0.5rem',
  background: hexColors.white,

  button: {
    flex: 1,
    minHeight: '4rem',
    margin: 0,

    '&:first-of-type': {
      marginRight: '0.5rem',
    },
    '&:last-of-type': {
      marginLeft: '0.5rem',
    },

    'svg:last-of-type': {
      marginLeft: '0.2rem',
    },
    'svg:first-of-type': {
      marginLeft: '0.2rem',
    },
  },
})
