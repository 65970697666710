import * as ko from 'knockout'
import { QuinellaTopPanelViewModel } from './QuinellaTopPanelViewModel'
import template from './quinella.top.panel.tpl.html'

if (!ko.components.isRegistered('quinella-top-panel')) {
  ko.components.register('quinella-top-panel', {
    viewModel: QuinellaTopPanelViewModel,
    template,
  })
}
