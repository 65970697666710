import { BettingInformation } from '../Model/BettingInformation'
import { ICheckBoxValidatorProcessor } from './ICheckBoxValidatorProcessor'
import { intersection } from '../../AppUtils/Utils/Algorithms'
import { CheckBoxSelection } from '../Components/Core/UIElements/CheckboxSelection'
import { ForwardingCheckBoxValidatorProcessor } from './ForwardingCheckBoxValidatorProcessor'
import Guard from '../../AppUtils/Framework/Guard'

export default class RovingBankerCheckBoxValidatorProcessor extends ForwardingCheckBoxValidatorProcessor {
  constructor(next: ICheckBoxValidatorProcessor) {
    super(next)
  }

  public validate(count: number, bettingContext: BettingInformation): boolean {
    Guard.notNull(bettingContext)

    if (!bettingContext.rovingBanker()) return this.next.validate(count, bettingContext)

    let selections = bettingContext.selections
    let starters = selections.getStartersForRace(bettingContext.raceNumber)()

    if (!starters || starters.length === 0) return false

    const checkboxes = starters.map(starter => starter.selection() as CheckBoxSelection)

    const sets = [new Set<number>(), new Set<number>()]
    this.populateSet(checkboxes, sets)

    if (!(sets[0].size >= 1 && sets[0].size <= count - 1)) return false

    if (sets[1].size === 0) return false

    if (sets[0].size + sets[1].size < count) return false

    if (intersection(sets[0], sets[1]).size !== 0) return false

    return true
  }

  private populateSet(checkboxes: Array<CheckBoxSelection>, sets: Array<Set<number>>) {
    for (let checkbox of checkboxes) {
      for (let i = 0; i < 2; ++i) {
        if (checkbox.selectedAt(i)) sets[i].add(checkbox.starter.number())
      }
    }
  }
}
