import React from 'react'
import type { BetSlipWorkflowState } from './Store/Workflow'
import type { globalToastEmitter } from '@mobi/component-library/Feedback/Toast/helpers/events'

export interface BetSlipGlobalContext {
  registeredInvestmentInputsRef: React.MutableRefObject<
    NonNullable<BetSlipWorkflowState['activeInvestment']>[]
  >
  toastEmitter: typeof globalToastEmitter
}

export const BetSlipContext = React.createContext<BetSlipGlobalContext | undefined>(undefined)
