import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const SectionTitle = styled('legend')({
  fontSize: '1.6rem',
  lineHeight: 1.25,
  margin: '1rem 0',
  color: hexColors.black,
})

export const HorizontalDivider = styled('div')({
  margin: '1rem 0',
  border: 0,
  borderBottom: `1px solid ${hexColors.veryLightGrey}`,
})

export const MedicareIconStyled = styled('svg')({
  display: 'inline-block',
  fill: 'currentColor',
  fontSize: '10rem',
  verticalAlign: 'bottom',
})

export const CheckboxContainer = styled('div')({
  margin: '1rem 0',
})
