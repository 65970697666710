import React from 'react'
import { NotificationWireResource } from '@core/Data/Contentful/notification'
import { Button } from '@mobi/component-library/Common/V2'
import { useHistory } from 'react-router-dom'

type Props = {
  notification: NotificationWireResource
}

const NotificationLink = ({ notification }: Props) => {
  const history = useHistory()

  if (!notification.NavigationLink || !notification.NavigationLinkText) return null

  return (
    <Button
      onClick={() => {
        if (!notification.NavigationLink) return

        const isHashLink = notification.NavigationLink.startsWith('/#')
        if (isHashLink) {
          history.push(notification.NavigationLink)
        } else {
          window.open(notification.NavigationLink, '_blank')
        }
      }}
      color='secondary_outline'
      size='sm'
      role='link'
      data-internaltrackingcode={notification.InternalTrackingCode || ''}
    >
      {notification.NavigationLinkText}
    </Button>
  )
}

export default NotificationLink
