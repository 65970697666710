import React from 'react'
import { state$ as userAccountState$, Campaign } from '@core/State/UserAccount/userAccountDriver'
import { state$ as quickbetState$ } from '../../driver'
import { observeImmutable } from '../../../../Components/HOCs'
import { BonusBetButtonStyled } from './BonusBetButton.styles'
import { Observable } from 'rx'

const isBonusCampaign = (campaign: Campaign) =>
  campaign.rewardType === 'BonusBetReward' || campaign.rewardType === 'BonusCashReward'

const state$ = Observable.combineLatest(
  userAccountState$,
  quickbetState$,
  (userAccountState, quickbetState): BonusBetButtonStateProps => {
    return {
      isLoggedIn: userAccountState.isLoggedIn ?? false,
      isUsingBonusBet: quickbetState.isUsingBonusBet,
      isBusy: quickbetState.isBusy,
      canConfirmBet: quickbetState.canConfirmBet,
      hasNotification: !!quickbetState.notificationType,
      activeCampaigns: userAccountState.activeCampaigns?.filter(isBonusCampaign).toArray(),
      insufficientFundsForBet: quickbetState.insufficientFundsForBet,
    }
  }
)

export type BonusBetButtonStateProps = {
  isLoggedIn: boolean
  isUsingBonusBet: boolean
  isBusy: boolean
  canConfirmBet: boolean
  hasNotification: boolean
  activeCampaigns: Campaign[] | undefined
  insufficientFundsForBet: boolean
}

export type BonusBetExternalProps = {
  toggleBonusBet: () => void
  trackBonusBetClick: () => void
}

export function BonusBetButtonComponent({
  isUsingBonusBet,
  toggleBonusBet,
  trackBonusBetClick,
  canConfirmBet,
  hasNotification,
  isBusy,
  isLoggedIn,
  activeCampaigns,
  insufficientFundsForBet,
}: BonusBetButtonStateProps & BonusBetExternalProps): JSX.Element | null {
  const hasActiveCampaigns = !!(activeCampaigns?.length ?? 0)

  const shouldShowBonusBetButton =
    !insufficientFundsForBet &&
    isLoggedIn &&
    hasActiveCampaigns &&
    !canConfirmBet &&
    !hasNotification

  if (!shouldShowBonusBetButton) {
    return null
  }

  return (
    <BonusBetButtonStyled
      id='quickbet-bonusbet-button'
      onClick={() => {
        toggleBonusBet()
        trackBonusBetClick()
      }}
      selected={isUsingBonusBet}
      disabled={isBusy}
    >
      {isUsingBonusBet ? 'Use Balance' : 'Use My Bonus'}
    </BonusBetButtonStyled>
  )
}

export const BonusBetButton = observeImmutable<BonusBetButtonStateProps, BonusBetExternalProps>(
  state$,
  ({ record, toggleBonusBet, trackBonusBetClick }) => {
    return (
      <BonusBetButtonComponent
        {...record}
        toggleBonusBet={toggleBonusBet}
        trackBonusBetClick={trackBonusBetClick}
      />
    )
  }
)
