import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, measurements } from '@mobi/component-library/Theme/Common'

type BackgroundColors = 'white' | 'grey' | 'black' | 'surface50' | 'surface300'

const containerColors: Record<BackgroundColors, string> = {
  grey: hexColors.gainsboro,
  black: hexColors.black,
  white: colors.white,
  surface50: colors.surface[50],
  surface300: colors.surface[300],
}

export interface MainContainerProps {
  background?: BackgroundColors
  padding?: string
  forceSizing?: boolean
}

export const MainContainerStyled = styled.section<MainContainerProps>(
  {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '80em',
    margin: '0 auto',
  },

  ({ background, padding, forceSizing = true }) => ({
    backgroundColor: background ? containerColors[background] : undefined,
    minWidth: forceSizing ? measurements.mobi.minWidth : undefined,
    minHeight: forceSizing ? `calc(100vh - ${measurements.mobi.headerHeight})` : undefined,
    padding,
  })
)
