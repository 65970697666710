import styled from '@emotion/styled'
import { font, colors } from '@mobi/component-library/Theme/Common'
import { gapX } from '@mobi/component-library/Utils/gap'

const desktopMediaQuery = `@media only screen and (min-width: 1280px)`

export const FooterStyled = styled.footer({
  fontFamily: font.family.primary,
  ...font.size.sm,

  backgroundColor: 'white',
  padding: '1.2rem 3.6rem 2rem',

  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'socials'
    'gambling-helpline'
    'rwwa-responsible-wagering'
    'app-downloads'
    'copyright'`,

  '> div': {
    padding: '1.6rem 0',
  },

  '> div > p': {
    ...font.size.md,
    margin: 0,

    // Leave gaps equivalent to an empty line
    ['&:not(:first-of-type):not(:last-of-type)']: {
      margin: '1em 0',
    },
  },

  a: {
    color: colors.studio[500],
  },

  [desktopMediaQuery]: {
    ...font.size.md,

    padding: '4.5rem 5.3125rem',
    columnGap: '125px',
    rowGap: '60px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
      'logo socials'
      'gambling-helpline rwwa-responsible-wagering'
      'copyright app-downloads'`,

    '> div': {
      padding: 0,
    },

    '> div > p': {
      ...font.size.lg,
      textAlign: 'left',
    },
  },
})

export const LogoStyled = styled.div({
  gridArea: 'logo',
  display: 'none',
  borderBottom: `0.5px solid ${colors.neutral[300]}`,

  [desktopMediaQuery]: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
})

export const SocialsStyled = styled.div({
  gridArea: 'socials',
  display: 'flex',
  justifyContent: 'center',
  borderBottom: `0.5px solid ${colors.neutral[300]}`,

  a: {
    margin: '0rem 1rem',
  },

  [desktopMediaQuery]: {
    border: 'none',
    justifyContent: 'flex-end',
  },
})

export const GamblingHelplineStyled = styled.div({
  gridArea: 'gambling-helpline',
  textAlign: 'center',
  borderBottom: `0.5px solid ${colors.neutral[300]}`,

  h1: {
    ...font.size.xl,
    fontWeight: font.weight.bold,
    marginTop: '1.6rem',
  },

  button: {
    marginTop: '2rem',
  },

  [desktopMediaQuery]: {
    border: 'none',
    textAlign: 'left',
  },
})

export const RwwaResponsibleWageringStyled = styled.div({
  gridArea: 'rwwa-responsible-wagering',
  margin: '0 auto',

  p: {
    textAlign: 'left',

    '&:first-of-type': {
      marginTop: '1.6rem',
    },
  },
})

export const DownloadAppStyled = styled.div({
  gridArea: 'app-downloads',

  h1: {
    ...font.size.lg,
    fontWeight: font.weight.bold,
    color: colors.neutral[900],
    textAlign: 'center',
    margin: '0 0 1.2rem',
  },

  'span.links': [
    {
      display: 'flex',
      justifyContent: 'center',

      svg: {
        width: '10rem',
        maxHeight: '4rem',
        fill: 'black',
      },
    },
    gapX('1rem'),
  ],

  [desktopMediaQuery]: {
    h1: {
      textAlign: 'left',
    },

    'span.links': [
      {
        justifyContent: 'flex-start',
      },
      gapX('2rem'),
    ],
  },
})

export const CopyrightStyled = styled.div({
  gridArea: 'copyright',
  marginTop: '1.2rem',
  borderTop: `0.5px solid ${colors.neutral[300]}`,

  [desktopMediaQuery]: {
    marginTop: 0,
    border: 'none',
  },
})
