import * as ko from 'knockout'
import jQuery from 'jquery'

ko.bindingHandlers['slideIn'] = {
  init: function (element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor())
    if (!value) {
      element.style.display = 'none'
    }
  },
  update: function (element, valueAccessor, allBindings) {
    var duration = allBindings.get('slideDuration') || 400 // 400ms is default duration from jQuery
    var newVisibility = ko.utils.unwrapObservable(valueAccessor()),
      isCurrentlyVisible = !(element.style.display === 'none')
    if (newVisibility !== isCurrentlyVisible) {
      newVisibility ? jQuery(element).slideDown(duration) : jQuery(element).slideUp(duration)
    }
  },
}
