import { Text } from '@core/Components/Text'
import styled from '@emotion/styled'
import { tap } from '@mobi/utils'
import { GapY } from '@core/Components/Gap'

export const StewardsCommentsStyled = tap(
  styled(GapY)({
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }),
  component => {
    component.defaultProps = {
      as: 'ul',
      gutterSize: '0.3rem',
    }
  }
)

export const StewardsCommentTextStyled = tap(
  styled(Text)({
    lineHeight: 'inherit',
    margin: 0,
  }),
  component => {
    component.defaultProps = {
      as: 'p',
      size: 'sm',
    }
  }
)
