import { ObservableStarter } from './ObservableStarter'
import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableStarter } from './IObservableStarter'

export default class ObservableDogStarter extends ObservableStarter {
  constructor() {
    super()
    // @ts-expect-error Type issue
    this.boxNumber = ko.observable()
    // @ts-expect-error Type issue
    this.stripeCount = ko.observable()
  }

  public merge(other: ObservableDogStarter) {
    Guard.notNull(other)
    super.merge(other)

    this.boxNumber(other.boxNumber())
    this.stripeCount(other.stripeCount())
  }

  public untypedMerge(other: IObservableStarter) {
    this.merge(other as ObservableDogStarter)
  }

  public boxNumber: ko.Observable<number>
  public stripeCount: ko.Observable<number>
}
