import * as ko from 'knockout'
import { Container, type interfaces } from 'inversify'

type ViewModelResolver<TViewModel> = (container: interfaces.Container) => TViewModel

/* use this if viewmodel takes no 'params=".."' input from knockout */
export function registerComponent<TViewModel>(config: {
  componentName: string
  template: unknown
  resolver: () => TViewModel
}): void {
  if (!ko.components.isRegistered(config.componentName)) {
    ko.components.register(config.componentName, {
      viewModel: {
        createViewModel: config.resolver,
      },
      template: config.template,
    })
  }
}

/* knockout <my-component params="..." /> binding will be injected as "$params" to view model */
export function registerComponentWithParams<TViewModel>(config: {
  componentName: string
  template: unknown
  container: interfaces.Container
  resolver: ViewModelResolver<TViewModel>
}): void {
  if (!ko.components.isRegistered(config.componentName)) {
    ko.components.register(config.componentName, {
      viewModel: {
        createViewModel: (params: unknown) =>
          resolveWithParams(params, config.container, config.resolver),
      },
      template: config.template,
    })
  }
}

function resolveWithParams<TViewModel, TParams>(
  params: TParams,
  container: interfaces.Container,
  resolver: ViewModelResolver<TViewModel>
): TViewModel {
  const privateContainer = new Container()
  privateContainer.parent = container
  privateContainer.bind<TParams>('$params').toConstantValue(params)
  return resolver(privateContainer)
}
