import React from 'react'
import dayjs from 'dayjs'
import { generateMatrix } from './utils'
import { CalendarDatePickerHolderStyled } from '../DateNavigation/DateNavigation.styles'
import { CalendarDatePickerContainerStyled, MatrixStyled } from './DatePicker.styles'
import { GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Day } from './Components/DayCell'
import { Abbreviations } from './Components/WeekDayHeaders'
import { MonthYearSelect } from './Components/MonthYearSelect'
import { trackMeetingsCalendarSelectedDay } from '@core/Areas/Meetings/analytics'

const DefaultStartDay: number = 1

interface CalendarDatePickerProps {
  value: Date
  onChange: (value: Date) => void
  areaName?: 'meetings'
}

export const CalendarDatePicker: React.FC<CalendarDatePickerProps> = ({
  value,
  onChange,
  areaName,
}) => {
  const [currentDate, setCurrentDate] = React.useState(() => dayjs(value))

  const onChangeLocal = (value: Date) => {
    setCurrentDate(dayjs(value))
    if (areaName && areaName === 'meetings') trackMeetingsCalendarSelectedDay()
    onChange(value)
  }

  const matrix = generateMatrix(currentDate.toDate(), DefaultStartDay)

  return (
    <CalendarDatePickerHolderStyled data-testid='calendar-date-picker'>
      <CalendarDatePickerContainerStyled tabIndex={0}>
        <GridRow>
          <GridCell>
            <MonthYearSelect
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              areaName={areaName}
            />
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell>
            <Abbreviations weekStart={DefaultStartDay} />
          </GridCell>
        </GridRow>

        <GridRow>
          <MatrixStyled>
            {matrix.map(row => (
              <GridRow key={'row' + row[0].toString()}>
                {row.map(day => (
                  <Day
                    key={day.toString()}
                    day={day}
                    value={value}
                    screen={currentDate.toDate()}
                    onClick={() => onChangeLocal(day)}
                  />
                ))}
              </GridRow>
            ))}
          </MatrixStyled>
        </GridRow>
      </CalendarDatePickerContainerStyled>
    </CalendarDatePickerHolderStyled>
  )
}
