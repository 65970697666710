import container from '../../../IOC/inversify.config'
import type { interfaces } from 'inversify'
import { registerComponentWithParams } from '@classic/AppUtils/Framework/Components/koComponentRegistration'
import template from './starter.image.tpl.html'
import { StarterImageViewModel } from './StarterImageViewModel'

registerComponentWithParams<StarterImageViewModel>({
  componentName: 'starter-image',
  template,
  container: container,
  resolver: (container: interfaces.Container) =>
    container.get<StarterImageViewModel>('StarterImageViewModel'),
})
