import { post, get } from '@classic/Foundation/Services/ApiService'
import { BetAccountHolder, PostFeedbackDataToApiPayload } from '@mobi/api-types'

export function postFeedbackDataToApi(body: PostFeedbackDataToApiPayload) {
  return post({ url: '/api/feedback/submit', body })
}

export function getFeedbackSubjectsFromApi(): Promise<string[]> {
  return get({ url: '/api/feedback/subjects' })
}

export function getAccountContactDetails(): Promise<BetAccountHolder> {
  return get({ url: '/$_/api/contactDetails' })
}
