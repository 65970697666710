import React, { PropsWithChildren } from 'react'
import { ActivityStatementContactDetailsLink } from '../ActivityStatement.styles'
import { trackActivityStatementsUpdateEmail } from '@classic/Foundation/Analytics/GoogleTagManagerService'

interface ContactDetailsLinkProps {
  accountNumber?: number
}

export const ContactDetailsLink = ({
  accountNumber,
  children = 'here',
}: PropsWithChildren<ContactDetailsLinkProps>): JSX.Element => {
  return (
    <ActivityStatementContactDetailsLink
      data-testid='contact-details-link'
      href='#account/contactdetails'
      onClick={() => {
        accountNumber && trackActivityStatementsUpdateEmail(accountNumber)
      }}
    >
      {children}
    </ActivityStatementContactDetailsLink>
  )
}
