import { createRef, useCallback, useContext } from 'react'
import { v4 } from 'uuid'
import { ToastContext } from '../ToastContext'
import type { Toast } from '../types'

type ToastData = Pick<Toast, 'text' | 'type'>

type AddToastOptions = {
  duration: number
}

const enum LocalConstants {
  DEFAULT_TIMEOUT_MS = 3_000,
}

/**
 * @deprecated a new toast component has been implemented,
 * within /Feedback/Toast, this refactored version was necessary
 * in order to use toasts within mobi.
 **/
export function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('No toast context found')
  }

  const { toasts, dispatch } = context

  const dismissToast = useCallback(
    (toast: Toast) => {
      window.clearTimeout(toast.timeoutId)

      dispatch({
        type: 'dismiss',
        toast,
      })
    },
    [dispatch]
  )

  const addToast = useCallback(
    (toastData: ToastData, options?: AddToastOptions) => {
      const toast: Toast = {
        id: v4(),
        elementRef: createRef(),
        timeoutId: -1,
        ...toastData,
      }

      const duration = options?.duration ?? LocalConstants.DEFAULT_TIMEOUT_MS

      if (duration > 0) {
        toast.timeoutId = window.setTimeout(() => {
          dismissToast(toast)
        }, duration)
      }

      dispatch({
        type: 'add',
        toast,
      })
    },
    [dispatch, dismissToast]
  )

  return {
    toasts,
    addToast,
    dismissToast,
  }
}
