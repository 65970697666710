import React from 'react'
import styled from '@emotion/styled'
import { radius, colors, font } from '@mobi/component-library/Theme/Common'
import { useRacePromotions } from '@core/Areas/Racing/Hooks/RacePromotion/useRacePromotions'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { filterRacePromotionsByEntitlement } from '@core/Utils/race-promotions'
import type { RaceListEntry } from '@core/Areas/Racing/Hooks/useRaceList/types'
import { RaceCardIcon } from '@core/Components/Icons'

const enum LocalConstants {
  RaceButtonActiveClassName = 'js-race-btn--active',
  RaceButtonClosedClassName = 'js-race-btn--closed',
  QuaddieIndicatorClassName = 'js-race-btn__quaddie-ind',
  SpecialIndicatorClassName = 'js-race-btn__special-ind',
}

export const RaceButton: React.FC<{
  meetingId: string
  meetingDate: string
  race: RaceListEntry
  isSelected: boolean
  firstLegsOfQuaddies: Set<number>
  onClick: (raceNumber: number) => void
  activeIndicatorRef: React.RefObject<HTMLSpanElement>
  scrollContainerRef: React.RefObject<HTMLOListElement>
}> = ({
  meetingId,
  meetingDate,
  race,
  isSelected,
  firstLegsOfQuaddies,
  onClick,
  activeIndicatorRef,
  scrollContainerRef,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const { promotions } = useRacePromotions({
    meetingDate: new Date(meetingDate),
    meetingId,
    raceNumber: race.raceNumber,
  })
  const isEntitled = useInducementService()

  const entitledPromotions = React.useMemo(
    () => filterRacePromotionsByEntitlement(promotions, isEntitled),
    [promotions, isEntitled]
  )

  const repositionActiveIndicator = React.useCallback(() => {
    if (!buttonRef.current || !activeIndicatorRef.current) return
    activeIndicatorRef.current.style.transform = `translateX(${buttonRef.current.offsetLeft}px)`
  }, [activeIndicatorRef])

  React.useEffect(() => {
    if (!isSelected || !buttonRef.current || !scrollContainerRef.current) return
    repositionActiveIndicator()
    scrollContainerRef.current.scrollLeft =
      buttonRef.current.offsetLeft - buttonRef.current.offsetWidth
  }, [isSelected, scrollContainerRef, repositionActiveIndicator])

  React.useEffect(() => {
    if (!window.ResizeObserver || !isSelected) return
    const observer = new ResizeObserver(repositionActiveIndicator)
    observer.observe(document.body)
    return () => observer.disconnect()
  }, [isSelected, repositionActiveIndicator])

  const buttonClassName = isSelected
    ? LocalConstants.RaceButtonActiveClassName
    : race.raceStatus !== 'Open'
      ? LocalConstants.RaceButtonClosedClassName
      : ''

  const isQuaddieIndicatorShown = firstLegsOfQuaddies.has(race.raceNumber)
  const isSpecialIndicatorShown = race.raceStatus === 'Open' && entitledPromotions.length > 0

  return (
    <RaceButtonStyled
      ref={buttonRef}
      aria-selected={isSelected}
      onClick={() => onClick(race.raceNumber)}
      className={buttonClassName}
    >
      <>R{race.raceNumber}</>

      {(isQuaddieIndicatorShown || isSpecialIndicatorShown) && (
        <div>
          {isQuaddieIndicatorShown && (
            <span
              data-testid='quaddie-indicator'
              className={LocalConstants.QuaddieIndicatorClassName}
            >
              {firstLegsOfQuaddies.size > 1 && Math.min(...firstLegsOfQuaddies) === race.raceNumber
                ? 'EQ'
                : 'Q'}
            </span>
          )}

          {isSpecialIndicatorShown && (
            <span
              data-testid='specials-indicator'
              className={LocalConstants.SpecialIndicatorClassName}
            >
              <RaceCardIcon icon='specialsNew' />
            </span>
          )}
        </div>
      )}
    </RaceButtonStyled>
  )
}

// ======
// Styles
// ======

const RaceButtonStyled = styled.button({
  flex: 1,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  WebkitUserSelect: 'none',
  userSelect: 'none',

  margin: 0,
  padding: 0,
  border: 0,
  borderRadius: '1.2rem',

  fontFamily: font.family.primary,
  fontSize: font.size.lg.fontSize,
  lineHeight: font.size.lg.lineHeight,
  letterSpacing: font.size.lg.letterSpacing,
  fontWeight: font.weight.medium,
  textTransform: 'uppercase',

  color: colors.black,
  backgroundColor: 'transparent',
  cursor: 'pointer',

  transition: 'color 0.2s ease',

  [`&.${LocalConstants.RaceButtonActiveClassName}`]: {
    color: colors.white,
  },

  [`&.${LocalConstants.RaceButtonClosedClassName}`]: {
    fontWeight: font.weight.regular,
    color: colors.neutral[800],
  },

  // Indicators
  '> div': {
    position: 'absolute',
    top: 0,
    left: '50%',
    display: 'flex',
    gap: '0.2rem',
    transform: 'translate(-50%, -60%)',
    height: font.size.xs.lineHeight,
    lineHeight: 1,

    '> span': {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '1.5rem',
      height: font.size.xs.lineHeight,
      padding: '0 0.3rem',
      borderRadius: radius.smx1,

      fontSize: font.size.xs.fontSize,
      lineHeight: font.size.xs.lineHeight,

      [`&.${LocalConstants.QuaddieIndicatorClassName}`]: {
        color: colors.studio[500],
        backgroundColor: colors.lavender[50],
      },
      [`&.${LocalConstants.SpecialIndicatorClassName}`]: {
        color: colors.warning[500],
        backgroundColor: colors.warning[50],

        '> svg': {
          width: '100%',
          height: font.size.xs.fontSize,
        },
      },
    },
  },
})
