import * as ko from 'knockout'
import Guard from '../../../AppUtils/Framework/Guard'
import ObservableSubstitute from './ObservableSubstitute'

export default class ObservableSubstitutePool {
  constructor() {
    this.number = ko.observable<number>(0)
    this.name = ko.observable<string>('')
    this.legs = ko.observableArray([] as number[])
    this.subs = ko.observableArray([] as ObservableSubstitute[])
  }

  public merge(other: ObservableSubstitutePool) {
    Guard.notNull(other)

    this.number(other.number())
    this.name(other.name())
    this.legs(other.legs())
    this.subs(other.subs())
  }

  public number: ko.Observable<number>
  public name: ko.Observable<string>
  public legs: ko.ObservableArray<number>
  public subs: ko.ObservableArray<ObservableSubstitute>
}
