import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDisableAppHeaderSticky } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderSticky'
import { RacingSportsTabs } from './Components/RacingSportsTabs'
import { AccountActivityMain } from './Components/Main'
import type { Tabs } from './types'
import { MainContainer } from '@core/Components/Containers'
import { ACCOUNT_ACTIVITY_ROUTE } from './constants'

const tabs: Tabs[] = ['pending', 'results', 'rebet', 'transactions']

/** MOBI Only */
export const AccountActivity: React.FC<{}> = () => {
  let { tab = 'pending' } = useParams<{ tab: Tabs }>()
  useDisableAppHeaderSticky()

  if (!tabs.includes(tab)) {
    return <Redirect to={`/${ACCOUNT_ACTIVITY_ROUTE}/${tabs[0]}`} />
  }

  return (
    <MainContainer background='surface300'>
      <RacingSportsTabs isRacing activeTab={tab} />
      <AccountActivityMain activeTab={tab} />
    </MainContainer>
  )
}
