import React, { forwardRef, type HTMLProps } from 'react'
import styled from '@emotion/styled'
import { callCenterPhoneNumber, callCenterInternationalPhoneNumber } from '@mobi/utils/phone'
import { colors } from '@mobi/component-library/Theme/Common'

export const CustomerServiceCentre = ({
  prefixText,
  suffixText,
}: {
  prefixText?: string
  suffixText?: string
}) => (
  <div>
    {prefixText ?? ''} Please contact our Customer Service Centre on <CustomerServiceTelephone />{' '}
    for more information {suffixText ?? ''}
  </div>
)

type Props = HTMLProps<typeof LinkStyled> & { isInternational?: boolean }

export const CustomerServiceTelephone = forwardRef<HTMLAnchorElement, Props>(
  ({ isInternational }, ref) => {
    const [linkText, text] = isInternational
      ? [callCenterInternationalPhoneNumber, <>+61&nbsp;8&nbsp;9445&nbsp;5391</>]
      : [callCenterPhoneNumber, <>1300&nbsp;36&nbsp;36&nbsp;88</>]

    return (
      <LinkStyled href={linkText} ref={ref}>
        {text}
      </LinkStyled>
    )
  }
)

CustomerServiceTelephone.displayName = 'CustomerServiceTelephone'

const LinkStyled = styled('a')({
  color: colors.studio[500],
})
