import React from 'react'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'

import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { ButtonWithIcon } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'

import {
  ContentHeaderStyled,
  ContentHeaderTitleStyled,
  Rotated180Icon,
} from './ContentHeader.styles'

export function ContentHeader({
  title,
  refreshCallback,
  navigateLeftCallback,
  navigateRightCallback,
  iconCode,
  shouldShowHome = true,
}: {
  title: string
  refreshCallback?: () => void
  navigateLeftCallback?: () => void
  navigateRightCallback?: () => void
  iconCode?: keyof typeof iconPaths
  shouldShowHome?: boolean
}): JSX.Element {
  const testId = { 'data-tid-page-title': '' }
  return (
    <ContentHeaderStyled>
      <Grid>
        <GridCell width='2rem' valign='middle'>
          {shouldShowHome && (
            <ButtonWithIcon
              onClick={navigateHome}
              icon={<Icon type='house' title='Home' />}
              color='light'
            />
          )}
        </GridCell>

        {(navigateLeftCallback || navigateRightCallback) && (
          <GridCell valign='middle' padding='0 0 0 1em'>
            {navigateLeftCallback && (
              <ButtonWithIcon
                onClick={navigateLeftCallback}
                icon={<Rotated180Icon type='arrowright' title='Previous' size='1.17em' />}
                color='light'
              />
            )}
          </GridCell>
        )}

        <GridCell valign='middle' align='center'>
          <ContentHeaderTitleStyled>
            {iconCode && <Icon type={iconCode} size='inherit' />}
            <span {...testId}>{title}</span>
          </ContentHeaderTitleStyled>
        </GridCell>

        {(navigateRightCallback || navigateLeftCallback) && (
          <GridCell valign='middle' padding='0 1em 0 0' align='right'>
            {navigateRightCallback && (
              <ButtonWithIcon
                onClick={navigateRightCallback}
                icon={<Icon type='arrowright' title='Next' size='1.17em' />}
                color='light'
              />
            )}
          </GridCell>
        )}

        <GridCell width='2rem' valign='middle'>
          {refreshCallback && (
            <ButtonWithIcon
              onClick={refreshCallback}
              icon={<Icon type='refresh' title='Refresh' />}
              color='light'
            />
          )}
        </GridCell>
      </Grid>
    </ContentHeaderStyled>
  )
}
