import * as dtos from '../DataTransferObjects/DataTransferObjects'
import MissingStartersException from '../Exceptions/MissingStartersException'
import ObservableResultsPage from '../Model/Observables/ObservableResultsPage'
import FinisherBuilder from './FinisherBuilder'

export default class FinishersMapper {
  public static map(dto: dtos.ResultsPageDataTransferObject, model: ObservableResultsPage) {
    if (!dto || !dto.Finishers) throw new MissingStartersException('No finishers available')

    dto.Finishers.forEach(finisher => {
      model.addFinisher(FinisherBuilder.from(finisher.Type).build(finisher))
    })
  }
}
