import * as ko from 'knockout'
import { WinPlaceSelectionsViewModel } from './WinPlaceSelectionsViewModel'
import template from './winplace-selections.tpl.html'

if (!ko.components.isRegistered('winplace-selections')) {
  ko.components.register('winplace-selections', {
    viewModel: WinPlaceSelectionsViewModel,
    template,
  })
}
