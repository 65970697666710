import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { greaterOrEqualThan } from '@mobi/component-library/Utils/media'

export const ListItemStyled = styled('li')({
  padding: '0.8rem',
  fontSize: '1.4rem',
  borderTop: `1px solid ${hexColors.gainsboro}`,

  [greaterOrEqualThan('xlarge')]: {
    padding: '1rem',
  },

  '&:first-of-type': {
    border: 0,
  },
})
