import React from 'react'
import { PoolDataTransferObject, RacePageDTO } from '@mobi/api-types'
import { useAppSelector } from '@core/Store/hooks'
import { ExpandablePanel } from '@core/Components/ExpandablePanel'
import { SecondaryHeader } from '../Common/SecondaryHeader'
import { getCurrentBetType } from '../../Store/selectors'
import { JurisdictionDescription } from './Components/JurisdictionDescription'
import { PoolTotalsItem } from './Components/PoolTotalsItem'
import { BetType } from '@classic/Betting-v2/Model/Betting/BetType'

export const PoolTotals: React.FC<{
  raceData?: RacePageDTO
}> = ({ raceData }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const currentBetType = useAppSelector(getCurrentBetType)

  if (!raceData || currentBetType === BetType.AllUp) return null

  return (
    <div data-tid-results-pools>
      <ExpandablePanel
        expanded={isExpanded}
        headerComponent={<SecondaryHeader title='Pool Totals' isExpanded={isExpanded} />}
        title=''
        headerClicked={() => setIsExpanded(val => !val)}
      >
        <div className='phalfem mb1halfem'>
          <ul className='card nobullet l-faux-table l-table-fixed l-table-rowlines w100 m0'>
            {raceData.AvailablePools.map(pool => {
              return (
                <PoolTotalsItem key={`${pool.Name}-${pool.RaceNumbers.join('.')}`} pool={pool} />
              )
            })}
          </ul>

          {shouldDisplayJurisdictionDescription(raceData.AvailablePools) && (
            <JurisdictionDescription pools={raceData.AvailablePools} />
          )}
        </div>
      </ExpandablePanel>
    </div>
  )
}

// Local Helpers

function shouldDisplayJurisdictionDescription(availablePools: PoolDataTransferObject[]) {
  // IsPoolInternational
  return availablePools.some(pool => pool.PoolingIcon && pool.PoolingIcon === 'INT')
}
