import styled from '@emotion/styled'
import { colors } from '@mobi/settings'

export const BannerContainerStyled = styled('div')<{ isDesktop?: boolean }>(
  {
    backgroundColor: colors.brand.primary,
    overflow: 'hidden',
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      // 5:2 aspect ratio
      paddingTop: '40%',
    },
  },
  ({ isDesktop }) => {
    return !isDesktop
      ? {
          '&::before': {
            // 8: aspect ratio
            '@media (min-width: 768px)': {
              paddingTop: '25%',
            },
          },
        }
      : {}
  }
)

export const BannerContainerInnerStyled = styled('div')({
  color: 'inherit',
  position: 'absolute',
  width: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  img: {
    display: 'block',
    width: '100%',
  },
})

export const BannerPaginationStyled = styled('div')({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  height: '2rem',
  width: '100%',
  justifyContent: 'center',
  pointerEvents: 'none',
})
