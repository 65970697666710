import styled from '@emotion/styled'
import { measurements } from '@mobi/component-library/Theme/Common'
import { hexColors } from '@mobi/settings'
import { fontSizes } from '@core/Settings'
import { Money } from '@core/Components/Text/Money'

export const AccountBalanceStyled = styled.div({
  flexGrow: 1,
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
})

export const AccountBalanceTouchableStyled = styled.button({
  textAlign: 'right',
  lineHeight: 1.2,
  fontSize: fontSizes.betslip.summary.fontSize,
  color: hexColors.lilac,
  background: 'none',
  border: 'none',
  borderRadius: '0.2rem',
  paddingLeft: '2rem',
  minHeight: measurements.mobi.headerHeight,

  '&:active': {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
})

export const AccountBalanceMoneyStyled = styled(Money)({
  position: 'relative',
  color: hexColors.white,
  fontSize: '1.4rem',

  '&::before': {
    content: '"+"',
    fontSize: '2rem',
    fontWeight: 600,
    position: 'absolute',
    top: '50%',
    left: '-2rem',
    transform: 'translateY(-50%)',
  },
})

export const BonusBalanceMoneyStyled = styled
  .div({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    padding: '0 0.5rem',
    color: hexColors.orange,
    fontSize: '1.2em',
    margin: 'unset',
  })
  .withComponent(Money)
