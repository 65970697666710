import { z } from 'zod'

export type Platform = 'browser_non_safari' | 'browser_safari' | 'ios' | 'android'

const inAppPayments = ['CreditCard', 'PayPal', 'ApplePay', 'GooglePay'] as const

export const PaymentMethodsSchema = z.enum([...inAppPayments])
export type PaymentMethods = z.infer<typeof PaymentMethodsSchema>

export const PaymentMethodsSelfServeSchema = z.enum(['BPay', 'Cash', 'ScanMyBet', 'PayID'])
export type PaymentMethodsSelfServe = z.infer<typeof PaymentMethodsSelfServeSchema>

export const PaymentMethodsAvailabilitySchema = z.enum(['PayID', ...inAppPayments])
export type PaymentMethodsAvailability = z.infer<typeof PaymentMethodsAvailabilitySchema>

export const PaymentMethodsAllSchema = z.union([
  PaymentMethodsSchema,
  PaymentMethodsSelfServeSchema,
])

export type PaymentMethodsAll = z.infer<typeof PaymentMethodsAllSchema>

/** @deprecated Use PaymentMethodsAll */
export type DepositSourceAll = PaymentMethodsAll

/** @deprecated Use PaymentMethods */
export type DepositSource = PaymentMethods

export type DeviceType = 'desktop' | 'mobile'
