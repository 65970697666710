import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '../../../../Components/NoticeBox'
import { ProvideExtraPersonalIdMessage } from '../ProvideExtraPersonalIdMessage'

export class PastReverificationMessage extends React.Component<{
  isReverificationSuccess: boolean
}> {
  private reverificationFailureRef: HTMLElement | null = null

  public componentDidMount() {
    // on error scrolls to the error
    if (this.reverificationFailureRef && this.reverificationFailureRef.scrollIntoView) {
      this.reverificationFailureRef.scrollIntoView()
    }
  }

  public render() {
    const isError = this.props.isReverificationSuccess === false
    const title = isError ? 'Re-verification failed' : 'Provide extra personal ID'
    const noticeBoxType = isError ? NoticeBoxTypes.Error : NoticeBoxTypes.Info

    return (
      <div
        ref={node => {
          this.reverificationFailureRef = node
        }}
      >
        <NoticeBox title={title} noticeBoxType={noticeBoxType} hasBorder={true}>
          <ProvideExtraPersonalIdMessage />
        </NoticeBox>
      </div>
    )
  }
}
