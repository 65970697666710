import React from 'react'
import { observeImmutable } from '../../../Components/HOCs'
import {
  state$ as changePasswordState$,
  ChangePasswordStateRecord,
  IsCurrentPasswordVisible,
  UpdateCurrentPassword,
} from '../driver'
import { PasswordComponent } from '../../Password/Components'
import { PasswordThemes } from '../../Password/themes'

interface CurrentPasswordProps {
  labelName: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void | null
}

export const CurrentPassword = observeImmutable<ChangePasswordStateRecord, CurrentPasswordProps>(
  changePasswordState$,
  ({ record, ...props }) => {
    const { isCurrentPasswordVisible } = record
    const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
      UpdateCurrentPassword(event.currentTarget.value)
    }

    return (
      <PasswordComponent
        {...props}
        themeName={PasswordThemes.light}
        onChange={onChangePassword}
        onVisibleToggle={IsCurrentPasswordVisible}
        isPasswordVisible={isCurrentPasswordVisible}
        validationError={false}
        testId='current-password'
      />
    )
  }
)
