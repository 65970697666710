import styled from '@emotion/styled'
import { greaterThan } from '@mobi/component-library/Utils/media'

export const CloseAccountContainerStyled = styled('div')({
  padding: '0 1rem',
  margin: '1rem 0 2rem',
  fontSize: '1.2rem',

  [greaterThan('xlarge')]: {
    fontSize: '1.4rem',
  },
})

export const FieldSetStyled = styled('fieldset')({
  marginBottom: '1rem',
})
