import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import {
  getNumberFromString,
  getSingleTicketTitle,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { Ticket } from '@core/Areas/AccountActivity/types'
import {
  HeaderSelectionIconBoxStyled,
  HeaderSelectionInfoStyled,
} from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Header.styles'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'

export const Multi: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const isPreviewMode = React.useContext(TicketDisplayContext).type === 'share-preview'

  return (
    <HeaderSelectionInfoStyled data-testid='ticket-header-multi'>
      <HeaderSelectionIconBoxStyled>
        <Icon type='betslip' />
      </HeaderSelectionIconBoxStyled>

      <div>
        <h3 data-tid-bet-product-name>
          {ticket.shortProductName} @{' '}
          {getNumberFromString(ticket.combinedDividend?.toString() || '')}
        </h3>

        {ticket.formula?.formulaString && (
          <span data-testid='multi-formula'>{ticket.formula.formulaString}</span>
        )}

        {!isPreviewMode && (
          <ul data-test='ticket-header-multi-legs'>
            {ticket.legs.map(leg => {
              const legTitle =
                leg.betType === FixedOddsMarketDisplayNames.H2H
                  ? `${leg.betType} - ${leg.runners}`
                  : getSingleTicketTitle(leg, ticket.planSeq).title
              return (
                <li data-testid='multi-leg' key={legTitle}>
                  {legTitle}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </HeaderSelectionInfoStyled>
  )
}
