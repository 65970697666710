import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { trackShareBet } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { logError } from '@core/Utils'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { createLoadBetDetails } from '@core/Areas/AccountActivity/Components/Ticket/Components/Footer/helpers'
import { createImageUrlFromHtml } from './createImageUrlFromHtml'
import { postShareBetDataAndGenerateLink } from '@core/Services/ShareIncoming/ShareBet/helpers/api'

const enum ShareCopy {
  Title = 'My TABTouch Bet',
  MessageWin = "I've got the touch! Check out this bet I placed on TABtouch.\n",
  MessagePending = "Here's hoping I've got the touch! Check out this bet I placed on TABtouch.\n",
  MessageNoPayout = "I'll have the touch next time! Check out this bet I placed on TABtouch.\n",
  MessageDefault = 'Check out this bet I placed on TABtouch.\n',
  LinkStatic = 'https://tabtouch.page.link/tcn4',
}

export async function handleShareBetClick({
  ticket,
  ticketStatus,
  fileBlob,
  previewEl,
  isImageOnlyShare,
  isCostShared,
}: HandleShareBetClick) {
  try {
    const data: ShareBetData = {
      title: ShareCopy['Title'],
      message: (() => {
        switch (ticketStatus) {
          case 'Won':
            return ShareCopy['MessageWin']
          case 'Pending':
            return ShareCopy['MessagePending']
          case 'NoPayout':
            return ShareCopy['MessageNoPayout']
          default:
            return ShareCopy['MessageDefault']
        }
      })(),
    }

    if (isReactNativeApp) {
      await shareBetWithReactNative({ ticket, data, previewEl, isImageOnlyShare })
    } else {
      await shareBetWithBrowser({ ticket, data, fileBlob, isImageOnlyShare })
    }

    // Analytics
    trackShareBet({
      product: ticket.productName,
      spend: ticket.investment.total,
      payout: ticket.return,
      betStatus: ticket.status,
      costShared: isCostShared,
    })
  } catch (err) {
    const errorString: string | undefined = (err as Error).toString?.().toLowerCase()
    if (errorString && (errorString.includes('cancel') || errorString.includes('abort'))) return

    logError(err as Error, true)

    RegisterToast({
      timeout: 0,
      type: 'error',
      position: 'bottom',
      message: 'Sorry, we are unable to create your share',
    })
  }
}

// =============
// Local Helpers
// =============

async function createShareBetAndPost(ticket: Ticket) {
  const betsToShare = createLoadBetDetails(ticket)
  const response = await postShareBetDataAndGenerateLink(betsToShare)

  if (!response.link) throw Error('ShareBet: Error ' + response.message)

  return response
}

// React Native
async function shareBetWithReactNative({
  ticket,
  data: { title, message },
  isImageOnlyShare,
  previewEl,
}: ShareBetWithReactNative) {
  if (!previewEl) throw Error('No HTML element to generate preview')

  const [{ link }, url] = await Promise.all([
    isImageOnlyShare
      ? Promise.resolve({ link: ShareCopy['LinkStatic'] })
      : createShareBetAndPost(ticket),
    createImageUrlFromHtml(previewEl),
  ])

  return sendToNative('SHARE_SHEET_REQUEST', {
    url,
    ...(!isImageOnlyShare && {
      title,
      subject: title,
      message: message + link + ' 🤘',
    }),
  })
}

// Browser
async function shareBetWithBrowser({
  ticket,
  data: { title, message },
  isImageOnlyShare,
  fileBlob,
}: ShareBetWithBrowser) {
  if (!fileBlob) throw Error('Unexpected image format')

  const { link } = isImageOnlyShare
    ? { link: ShareCopy['LinkStatic'] }
    : await createShareBetAndPost(ticket)

  const navigatorShareData: ShareData = {
    ...(!isImageOnlyShare && { title, text: message + link + ' 🤘' }),
    files: [
      new File([fileBlob], 'share-bet-preview.png', {
        type: fileBlob.type,
      }),
    ],
  }

  if (navigator.canShare?.(navigatorShareData)) {
    return await navigator.share(navigatorShareData)
  }
  throw Error('No capability to share')
}

// ==========
// Types Defs
// ==========

interface HandleShareBetClick {
  ticket: Ticket
  ticketStatus: TicketStatus
  isImageOnlyShare: boolean
  fileBlob?: Blob
  previewEl?: HTMLDivElement | null
  isCostShared?: boolean
}

interface ShareBetWithShared {
  ticket: Ticket
  data: ShareBetData
  isImageOnlyShare: boolean
}
type ShareBetWithReactNative = ShareBetWithShared & { previewEl?: HTMLDivElement | null }
type ShareBetWithBrowser = ShareBetWithShared & { fileBlob?: Blob }

interface ShareBetData {
  title: string
  message: string
}
