import React from 'react'
import type { BetSlipItem } from '@mobi/betslip/types'
import { InlineAlert } from '@mobi/betslip/Components/Common/InlineAlert'

export const ItemMessage: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  if (item.receipt) return <InlineAlert type='success' text='Your bet has been placed' />

  if (item.betErrorType === 'Closed')
    return <InlineAlert type='error' text='This event has closed' />

  if (item.betErrorType === 'HandicapChanged')
    return <InlineAlert type='error' text='Handicap has changed' />

  return null
}
