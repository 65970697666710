import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

const enum LocalConstants {
  Padding = '0.5rem',
}

export const RunnerCardStyled = styled.div({
  position: 'relative',
  minHeight: '3rem',
  marginBottom: '0.5rem',
  padding: LocalConstants.Padding,
  fontSize: '1.2rem',
  color: hexColors.nero,
  background: hexColors.white,
  borderRadius: '0.5rem',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  transition: 'background 0.5s ease',
})
