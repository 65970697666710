import React from 'react'
import dayjs from 'dayjs'
import { useObservable } from '@core/Utils/hooks'
import { state$ as favouriteNumbersState$, defaultState } from '@core/Areas/FavouriteNumbers/driver'
import { costCalculator } from '@core/Areas/FavouriteNumbers/calculator'
import {
  ConfirmInfoBetStyled,
  ConfirmInfoStyled,
  ConfirmInfoTextStyled,
  HeaderStyled,
  LineContainerStyled,
  LineInfoStyled,
  PoolDateContainerStyled,
  PoolDateStyled,
  SubheaderStyled,
  SubheaderTextStyled,
  TextContainerStyled,
  TotalBetsStyled,
  TotalContainerStyled,
} from './FavouriteNumbersHeader.styles'

export function FavouriteNumbersHeader(): JSX.Element {
  const { gameOne, gameTwo, pools, potLuckBets } = useObservable(
    favouriteNumbersState$,
    defaultState
  )

  const isPotLuck = gameOne.length == 1

  return (
    <>
      <HeaderStyled data-tid-fav-number-header=''>Favourite Numbers</HeaderStyled>
      <SubheaderStyled>
        <SubheaderTextStyled data-tid-fav-number-bet-type=''>
          {isPotLuck ? 'Pot Luck' : 'Easy Bet'}
        </SubheaderTextStyled>
      </SubheaderStyled>
      {!isPotLuck && (
        <LineContainerStyled>
          <TextContainerStyled>
            <SubheaderTextStyled data-tid-fav-number-easy-bet-game-one-title=''>
              Game 1
            </SubheaderTextStyled>
          </TextContainerStyled>
          <LineInfoStyled data-tid-fav-number-easy-bet-game-one-selection=''>
            {gameOne?.join('. ') || 'none'}
          </LineInfoStyled>
        </LineContainerStyled>
      )}
      {!!gameTwo.length && (
        <LineContainerStyled>
          <TextContainerStyled>
            <SubheaderTextStyled data-tid-fav-number-easy-bet-game-two-title=''>
              Game 2
            </SubheaderTextStyled>
          </TextContainerStyled>
          <LineInfoStyled data-tid-fav-number-easy-bet-game-two-selection=''>
            {gameTwo?.join('. ') || 'none'}
          </LineInfoStyled>
        </LineContainerStyled>
      )}
      <LineContainerStyled>
        <TextContainerStyled>
          <SubheaderTextStyled data-tid-fav-number-pool-dates-title=''>
            Pool Dates
          </SubheaderTextStyled>
        </TextContainerStyled>
        <LineInfoStyled>
          <PoolDateContainerStyled data-tid-fav-number-pool-dates-selection=''>
            {pools?.map((p, i) => {
              const poolDate = dayjs(p).format('DD MMM YYYY')
              return <PoolDateStyled key={`poolDate-${i}`}>{poolDate}</PoolDateStyled>
            })}
          </PoolDateContainerStyled>
        </LineInfoStyled>
      </LineContainerStyled>
      {!!isPotLuck && (
        <LineContainerStyled>
          <TextContainerStyled>
            <SubheaderTextStyled data-tid-fav-number-pot-luck-bets-per-pool-title=''>
              Bets Per Pool
            </SubheaderTextStyled>
          </TextContainerStyled>
          <LineInfoStyled data-tid-fav-number-pot-luck-bets-per-pool=''>
            {potLuckBets}
          </LineInfoStyled>
        </LineContainerStyled>
      )}
      {!isPotLuck && (
        <ConfirmInfoStyled data-tid-fav-number-easy-bet-game-one-cost=''>
          <ConfirmInfoTextStyled>Game 1</ConfirmInfoTextStyled>
          <ConfirmInfoBetStyled>${costCalculator(gameOne.length).toFixed(2)}</ConfirmInfoBetStyled>
        </ConfirmInfoStyled>
      )}
      {!!gameTwo.length && !isPotLuck && (
        <ConfirmInfoStyled data-tid-fav-number-easy-bet-game-two-cost=''>
          <ConfirmInfoTextStyled>Game 2</ConfirmInfoTextStyled>
          <ConfirmInfoBetStyled>${costCalculator(gameTwo.length).toFixed(2)}</ConfirmInfoBetStyled>
        </ConfirmInfoStyled>
      )}
      {isPotLuck && (
        <ConfirmInfoStyled data-tid-fav-number-pot-luck-each-bet-cost=''>
          <ConfirmInfoTextStyled>{`${gameOne} Numbers`}</ConfirmInfoTextStyled>
          <ConfirmInfoBetStyled>${costCalculator(gameOne[0]).toFixed(2)}</ConfirmInfoBetStyled>
        </ConfirmInfoStyled>
      )}
      <TotalContainerStyled>
        <TextContainerStyled>
          <SubheaderTextStyled data-tid-fav-number-total-bets-title=''>
            Total Bets
          </SubheaderTextStyled>
        </TextContainerStyled>
        <TotalBetsStyled data-tid-fav-number-total-bets=''>
          {isPotLuck ? pools.length * potLuckBets : pools.length}
        </TotalBetsStyled>
      </TotalContainerStyled>
    </>
  )
}
