import React from 'react'
import { colors } from '../../Settings'
import { SpecialIndicatorStyled, SpecialIndicatorProps } from './SpecialIndicator.styles'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { hexColors } from '@mobi/settings'
import { useInducementService } from '@core/Utils/hooks/useInducementService'

export const SpecialIndicator: React.FC<SpecialIndicatorProps> = ({
  height,
  width,
  className,
  viewBox,
  themeName,
}) => {
  const canShowInducements = useInducementService()

  return canShowInducements ? (
    <SpecialIndicatorStyled
      width={width || '24'}
      height={height || '24'}
      viewBox={viewBox || '0 0 256 256'}
      role='presentation'
      themeName={themeName}
      className={className}
      data-tid-special-icon=''
    >
      <path
        fill={themeName === 'purple' ? hexColors.studio : colors.specialIndicator.background}
        d={iconPaths.specialsBackground}
      />
      <path d={iconPaths.specials} />
    </SpecialIndicatorStyled>
  ) : null
}
