import React from 'react'
import { observeImmutable } from '../HOCs'
import { state$, ToastStateRecord } from './ToastDriver'
import { ToastComponent } from './Toast'

export default observeImmutable<ToastStateRecord, { isHeaderVisible?: boolean }>(
  state$,
  ({ record, isHeaderVisible = false }) => (
    <ToastComponent items={record.items} isHeaderVisible={isHeaderVisible} />
  )
)
