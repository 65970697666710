import * as ko from 'knockout'
import { ObservableFinisher } from './ObservableFinisher'
import Guard from '../../../AppUtils/Framework/Guard'
import { IObservableFinisher } from './IObservableFinisher'

export default class ObservableTrotFinisher extends ObservableFinisher {
  constructor() {
    super()

    // @ts-expect-error Type issue
    this.handicap = ko.observable()
  }

  merge(other: ObservableTrotFinisher) {
    Guard.notNull(other)
    super.merge(other)

    this.handicap(other.handicap())
  }

  public untypedMerge(other: IObservableFinisher) {
    this.merge(other as ObservableTrotFinisher)
  }

  public handicap: ko.Observable<string>
}
