export function findAndStartChildVideoElement(parent?: HTMLDivElement) {
  const videoElements = parent?.getElementsByTagName('video')
  const videoElement = videoElements && videoElements[0]

  if (videoElement && videoElement.paused) {
    videoElement.play()?.catch(e => {
      // eslint-disable-next-line no-console
      console.error('Could not start <video/>:', e)
    })
  }
}
