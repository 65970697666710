import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const WithdrawContainerStyled = styled('div')({
  padding: '2rem',
  fontSize: '1.4rem',
})

export const WithdrawDetailStyled = styled('div')({
  position: 'relative',
  fontSize: 'inherit',
  padding: '0.4em 0',
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${hexColors.veryLightGrey}`,
})

export const WithdrawDetailNoBorderStyled = styled(WithdrawDetailStyled)({
  borderBottom: 'unset',
})

export const FormContainerStyled = styled(WithdrawDetailStyled)({
  display: 'block',
  borderBottom: 'unset',
  paddingTop: '0.8em',
})

export const SuccessMessageStyled = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
})

export const BankDetailContainerStyled = styled('ul')({
  listStyleType: 'unset',
  margin: 0,
  padding: 0,
})

export const BankDetailItemStyled = styled('li')({
  fontSize: 'inherit',
  textAlign: 'right',
})

export const WithdrawLabelStyled = styled('span')({
  color: hexColors.dimGrey,
  fontSize: '1.2rem',
})

export const ButtonContainerStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',

  '& > button': {
    margin: '0 0.5rem',

    ':first-of-type': { marginLeft: 0 },
    ':last-of-type': { marginRight: 0 },
  },
})
