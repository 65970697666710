import { dayjs } from '@mobi/utils'
import { get } from '@classic/Foundation/Services/ApiService'
import { FormRaceResponse } from '../useRaceFormData/api'

export const getRaceFieldFormDataFromApi = async ({
  racecourseSeq,
  meetingDate,
  raceNumber,
}: GetRaceFieldFormDataParams) => {
  const url = getFieldFormForRaceApiUrl(racecourseSeq, meetingDate, raceNumber)
  return await get<FormRaceResponse>({ url })
}

function getFieldFormForRaceApiUrl(
  racecourseSeq?: number,
  meetingDate?: string,
  raceNumber?: number
) {
  const formattedDate = dayjs(meetingDate).format('YYYY-MM-DD')
  return `/api/meetings/${racecourseSeq}/${formattedDate}/${raceNumber}/form`.toLowerCase()
}

export type GetRaceFieldFormDataParams = {
  racecourseSeq?: number
  meetingDate?: string
  raceNumber?: number
}
