import React from 'react'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { updateUserSettingsOnDataLayer } from '@core/Services/Analytics/helpers'
import { usePrevious } from '@core/Utils/hooks'
import { store } from '@core/Store'
import { SettingItem } from '../SettingItem'

export const SettingItemWithCheckbox: React.FC<{
  isEnabled: boolean
  label: string
  onToggle: () => void
  desciption?: string
  isLoading?: boolean
  shouldDisable?: boolean
}> = ({
  isEnabled,
  label,
  onToggle,
  desciption = '',
  shouldDisable = false,
  isLoading = false,
}) => {
  const prevIsEnabled = usePrevious(isEnabled)
  const settingNameKebabCase = label.toLowerCase().replace(/ /g, '-')

  React.useEffect(() => {
    if (
      [typeof prevIsEnabled, typeof isEnabled].every(val => val === 'boolean') &&
      prevIsEnabled !== isEnabled
    ) {
      trackSettingChange(settingNameKebabCase, isEnabled)
    }
  }, [isEnabled, settingNameKebabCase, prevIsEnabled])

  return (
    <SettingItem
      onClick={e => {
        e.preventDefault()
        if (shouldDisable) return
        onToggle()
      }}
      label={label}
      isLoading={isLoading}
      desciption={desciption}
      rightComponent={
        <CheckboxInput
          id={`toggle-${settingNameKebabCase}`}
          data-testid={`toggle-${settingNameKebabCase}`}
          checked={isEnabled}
          readOnly
          label=''
          disabled={shouldDisable}
        />
      }
      data-testid={`setting-item-${settingNameKebabCase}`}
    />
  )
}

// =============
// Local Helpers
// =============

function trackSettingChange(setting: string, isOn: boolean) {
  trackEvent('profile-settings-change', { setting, isOn })
  const appState = store.getState()
  updateUserSettingsOnDataLayer(appState)
}
