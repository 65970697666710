import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { H3 } from '@core/Components/Text/Heading.styles'
import { BlackbookState, setSortOrder } from '@core/Areas/Blackbook/Store'
import { RunningSoon } from '@core/Areas/Blackbook/Components/RunnerItem/Components/RunningSoon'
import { trackBlackbookSortChanged } from '@core/Areas/Blackbook/analytics'
import type { BlackbookEntryRacingToday, SmartBetslipState } from '@core/Areas/Blackbook/types'
import { isBettingAvailable } from '@core/Areas/Blackbook/helpers'
import { NoRunnersStyled } from '../Common/Common.styles'
import { sortByStartTime } from '../../helpers'

const sortOptions: BlackbookState['sortOrder'][] = [
  'Start Time',
  'Win Price ▲',
  'Win Price ▼',
  'Alphabetical',
]

export const RunnersRunningSoon: React.FC<{
  entries: BlackbookEntryRacingToday[]
  smartBetslipState: SmartBetslipState
  incomingIdRef: React.MutableRefObject<string | undefined>
}> = ({ entries, smartBetslipState, incomingIdRef }) => {
  const dispatch = useAppDispatch()
  const sortOrder = useAppSelector(rootState => rootState.blackbook.sortOrder)

  const sortedRunners = sortRunners(entries, sortOrder)

  return (
    <>
      <H3 data-testid='blackbook-list-heading-running-soon'>
        <span>Running Soon</span>
        <div>
          <select
            value={sortOrder}
            onChange={e => {
              dispatch(setSortOrder(e.currentTarget.value as BlackbookState['sortOrder']))
              trackBlackbookSortChanged(e.currentTarget.value)
            }}
            data-testid='blackbook-running-soon-sort'
          >
            {sortOptions.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <span>{sortOrder}</span>
          <Icon type='arrowdown' size='1.2rem' />
        </div>
      </H3>

      {sortedRunners.length > 0 ? (
        sortedRunners.map(runner => (
          <RunningSoon
            key={`${runner.Id}.${runner.RacingTodayDetails.EventSeq}`}
            runner={runner}
            incomingIdRef={incomingIdRef}
            shouldAutoAddToBetslip={smartBetslipState.shouldAutoAddToBetslip}
            isSelected={
              smartBetslipState.shouldAutoAddToBetslip &&
              smartBetslipState.betslipRunnerPropSeqs.includes(
                runner.RacingTodayDetails.PropositionSeq
              )
            }
          />
        ))
      ) : (
        <NoRunnersStyled data-testid='blackbook-running-soon-none'>None</NoRunnersStyled>
      )}
    </>
  )
}

// =============
// Local Helpers
// =============

function sortRunners(entries: BlackbookEntryRacingToday[], sortOrder: BlackbookState['sortOrder']) {
  let sortedRunners = entries.sort(sortOrderFns[sortOrder] || sortOrderFns['Start Time'])
  if (sortOrder === 'Win Price ▲' || sortOrder === 'Win Price ▼') {
    sortedRunners = Object.values(
      sortedRunners.reduce<{
        openWithPrice: BlackbookEntryRacingToday[]
        openWithNoPrice: BlackbookEntryRacingToday[]
        notOpen: BlackbookEntryRacingToday[]
      }>(
        (sortMap, curr) => {
          if (!isBettingAvailable(curr.RacingTodayDetails)) {
            sortMap.notOpen.push(curr)
          } else if (!curr.RacingTodayDetails.WinDividend) {
            sortMap.openWithNoPrice.push(curr)
          } else {
            sortMap.openWithPrice.push(curr)
          }
          return sortMap
        },
        { openWithPrice: [], openWithNoPrice: [], notOpen: [] }
      )
    ).flat()
  }

  return sortedRunners
}

const sortOrderFns: Record<
  BlackbookState['sortOrder'],
  (a: BlackbookEntryRacingToday, b: BlackbookEntryRacingToday) => number
> = {
  ['Start Time']: (a, b) => sortByStartTime(a, b),
  ['Win Price ▲']: ({ RacingTodayDetails: a }, { RacingTodayDetails: b }) => {
    if (!isBettingAvailable(a) || !a.WinDividend) return 1
    if (!isBettingAvailable(b) || !b.WinDividend) return -1
    if (a.WinDividend === b.WinDividend) return 0
    return a.WinDividend > b.WinDividend ? 1 : -1
  },
  ['Win Price ▼']: ({ RacingTodayDetails: a }, { RacingTodayDetails: b }) => {
    if (!isBettingAvailable(a) || !a.WinDividend) return 1
    if (!isBettingAvailable(b) || !b.WinDividend) return -1
    if (a.WinDividend === b.WinDividend) return 0
    return a.WinDividend > b.WinDividend ? -1 : 1
  },
  ['Alphabetical']: (a, b) =>
    a.StarterName === b.StarterName ? 0 : a.StarterName > b.StarterName ? 1 : -1,
}
