import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { newTheme } from '@mobi/component-library/Theme'
import { radius, spacing, font } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

const defaultPayout = 'Pay 1,2,3'
const ignoreValues = [defaultPayout, undefined]

const enum LocalConstants {
  SkyRacePageClassname = 'js-payout-info--sky-race-card',
}

export const PayoutInformation: React.FC<{
  totePayoutInformation?: string
  fixedPayoutInformation?: string
  isOnSkyRacePage?: boolean
}> = ({ totePayoutInformation, fixedPayoutInformation, isOnSkyRacePage }) => {
  const payoutInformation = useMemo(() => {
    const toteShouldBeIgnored = ignoreValues.includes(totePayoutInformation)
    const fixedShouldBeIgnored = ignoreValues.includes(fixedPayoutInformation)

    if (toteShouldBeIgnored && fixedShouldBeIgnored) return undefined

    if (fixedPayoutInformation === totePayoutInformation) return fixedPayoutInformation

    return [
      fixedPayoutInformation ? `Fixed ${fixedPayoutInformation}` : undefined,
      totePayoutInformation ? `Tote ${totePayoutInformation}` : undefined,
    ]
      .filter(x => x)
      .join(' and ')
  }, [totePayoutInformation, fixedPayoutInformation])

  if (!payoutInformation) {
    return null
  }

  return (
    <PayoutInformationStyled
      data-testid='payout-information'
      className={isOnSkyRacePage ? LocalConstants.SkyRacePageClassname : ''}
    >
      <Icon name='LineInfoCircle' />
      <span data-testid='payout-information-text'>{payoutInformation}</span>
    </PayoutInformationStyled>
  )
}

// ======
// Styles
// ======

const PayoutInformationStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  height: '2.8rem',
  margin: `0 ${spacing.md} ${spacing.smx1} ${spacing.md}`,
  padding: spacing.smx2 + ' ' + spacing.smx1,

  fontFamily: font.family.primary,
  fontSize: font.size.md.fontSize,
  lineHeight: font.size.md.lineHeight,

  borderRadius: radius.md,
  color: newTheme.colors.mariner[600],
  backgroundColor: newTheme.colors.mariner[50],

  '> span:first-of-type': { marginRight: spacing.smx1 },

  [`&.${LocalConstants.SkyRacePageClassname}`]: {
    margin: `0 ${spacing.smx1} ${spacing.smx1} ${spacing.smx1}`,
  },
})
