import * as ko from 'knockout'
import { QuinellaViewModel } from './QuinellaViewModel'
import template from './quinella.tpl.html'

if (!ko.components.isRegistered('quinella-bettype')) {
  ko.components.register('quinella-bettype', {
    viewModel: QuinellaViewModel,
    template,
  })
}
