import React from 'react'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'
import { store } from '@core/Store'
import { resetSkyVideoPlayerPersistedState } from '@core/Areas/SkyVideoPlayer/Store'

export const useNativeAppLaunchState = () => {
  React.useEffect(() => {
    if (!isReactNativeApp) return

    const subscription = subscribeToNative('APP_LAUNCH_STATE_RESPONSE', ({ isColdStart }) => {
      if (isColdStart) {
        store.dispatch(resetSkyVideoPlayerPersistedState())
      }
    })

    sendToNative('APP_LAUNCH_STATE_REQUEST')

    return () => subscription.dispose()
  }, [])
}
