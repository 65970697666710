import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings/src/hexColors'

export const MultiToastPriceContainerStyled = styled('div')({
  fontSize: '1.6rem',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const MultiToastPriceTitleStyled = styled('div')({
  color: hexColors.white,
})

export const MultiToastPriceAmountStyled = styled('div')({
  color: hexColors.lemon,
  fontWeight: 'bold',
  display: 'flex',
})

export const MultiToastPriceAmountPrefixStyled = styled('div')({
  marginTop: '0px',
  marginLeft: '10px',
})
