import React from 'react'
import type { TicketResponseData } from '@core/Areas/AccountActivity/types'
import { getIdFromSortedString, isFauxTicketTransaction } from '@core/Areas/AccountActivity/helpers'
import { useNextSkyRaces } from '@core/Areas/RaceCardSky/Hooks'
import { SkyPendingTicket } from '../SkyPendingTicket'

export const PendingTicketsPage: React.FC<{ pageData: TicketResponseData }> = ({ pageData }) => {
  const { nextRaces } = useNextSkyRaces({ refetchOnMount: false })

  if (pageData.sortedIds.length === 0) return null

  return (
    <>
      {pageData.sortedIds.map(ticketID => {
        const item = pageData.ticketsById[getIdFromSortedString(ticketID)]

        if (isFauxTicketTransaction(item)) return null

        return <SkyPendingTicket key={item.ticketSequence} ticket={item} nextSkyRaces={nextRaces} />
      })}
    </>
  )
}
