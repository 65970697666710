import { font, radius, spacing, shadow, colors } from './Common'
import * as input from './Components/Input'
import * as link from './Components/Link'

export type Theme = Readonly<{
  font: typeof font
  colors: typeof colors
  spacing: typeof spacing

  /** @todo co-locate component specific styles with markup @deprecated */
  components: {
    Input: {
      border: {
        radius: string
      }
      colors: input.InputColors
      spacing: {
        input: {
          padding: Spacing
          margin: Spacing
        }
        label: {
          margin: Spacing
        }
        helpText: {
          margin: Spacing
        }
      }
      shadow: string
    }
    Link: {
      colors: link.LinkColors
    }
  }
}>

export const newTheme: Theme = {
  font,
  colors,
  spacing,
  components: {
    Input: {
      border: {
        radius: radius.lgx1,
      },
      colors: input.inputColors,
      spacing: {
        input: {
          padding: {
            x: spacing.bigx2,
            y: spacing.lg,
          },
          margin: {
            left: spacing.sm,
            right: spacing.lg,
          },
        },
        label: {
          margin: {
            bottom: spacing.smx1,
          },
        },
        helpText: {
          margin: {
            top: spacing.sm,
          },
        },
      },
      shadow: shadow.xs,
    },
    Link: {
      colors: link.linkColors,
    },
  },
}

// Types

type Spacing = {
  x?: string
  y?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
}
