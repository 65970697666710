import { StructuredSearchRoute } from '@core/Areas/StructuredSearch/constants'

export interface ScreenNameMatch {
  code: string
  name: string
}

interface ScreenNameLookup extends ScreenNameMatch {
  regEx: string
}

export const screenCodes = {
  Home: {
    code: 'HOME',
    name: 'Home',
  },
  RaceMeetingsList: {
    code: 'RCEMTLST',
    name: 'Meetings List',
  },
  RacesList: {
    code: 'RCELST',
    name: 'Races List',
  },
  RaceBettingPage: {
    code: 'RBP',
    name: 'Race Betting Page',
  },
  NextEvents: {
    code: 'NEXTEVTS',
    name: 'Next Events Page',
  },
  MysteryBet: {
    code: 'MYSTBET',
    name: 'Mystery Bet',
  },
  GoodThings: {
    code: 'GOODTHINGS',
    name: 'Good Things',
  },
  Jackpots: {
    code: 'JAKPOTS',
    name: 'Jackpots',
  },
  FixedOddsOnlyRacesList: {
    code: 'FOORL',
    name: 'Fixed Odds Only Races List',
  },
  FixedOddsOnlyBettingPage: {
    code: 'FOOBP',
    name: 'Fixed Odds Only Betting Page',
  },
  StructuredSearch: {
    code: 'STRUCSRCH',
    name: 'Structured Search',
  },
  Withdrawal: {
    code: 'WITHDRAWAL',
    name: 'Withdrawal Page',
  },
  Specials: {
    code: 'SPECIALS',
    name: 'Specials Page',
  },
  FavouriteNumbers: {
    code: 'FAVNOS',
    name: 'Favourite Numbers',
  },
}

export const screenMatches: ScreenNameLookup[] = [
  {
    regEx: '^tote\\?code=(races|trots|dogs)(\\&date=\\d{4}-\\d{1,2}-\\d{1,2})?$', //  date param set if navigating back up the nav path from lower down
    code: screenCodes.RaceMeetingsList.code,
    name: screenCodes.RaceMeetingsList.name,
  },
  {
    regEx: '^tote/meetings/\\w{2,3}(\\?date=(\\d{4}-\\d{1,2}-\\d{1,2}|undefined))?$', //  date=undefined if navigating back up the nav path after using next events page to get to RBP
    code: screenCodes.RacesList.code,
    name: screenCodes.RacesList.name,
  },
  {
    regEx: '^tote/meetings/\\w{2,3}\\/\\d{1,2}(\\?date=\\d{4}-\\d{1,2}-\\d{1,2})?$',
    code: screenCodes.RaceBettingPage.code,
    name: screenCodes.RaceBettingPage.name,
  },
  {
    regEx: '^tote/nextevents$',
    code: screenCodes.NextEvents.code,
    name: screenCodes.NextEvents.name,
  },
  {
    regEx: '^next-events',
    code: screenCodes.NextEvents.code,
    name: screenCodes.NextEvents.name,
  },
  {
    regEx: '^tote/mystery$',
    code: screenCodes.MysteryBet.code,
    name: screenCodes.MysteryBet.name,
  },
  {
    regEx: '^goodthings$',
    code: screenCodes.GoodThings.code,
    name: screenCodes.GoodThings.name,
  },
  {
    regEx: '^jackpots$',
    code: screenCodes.Jackpots.code,
    name: screenCodes.Jackpots.name,
  },
  {
    regEx:
      '^(fobracing\\/foo\\/races\\/)\\d*/\\d{4}-\\d{1,2}-\\d{1,2}\\?(selectiondate=)\\d{4}-\\d{1,2}-\\d{1,2}$',
    code: screenCodes.FixedOddsOnlyRacesList.code,
    name: screenCodes.FixedOddsOnlyRacesList.name,
  },
  {
    regEx:
      '^(fobracing\\/propositions\\/foo\\/races)\\/\\d*\\?(selectiondate=)\\d{4}-\\d{1,2}-\\d{1,2}$',
    code: screenCodes.FixedOddsOnlyBettingPage.code,
    name: screenCodes.FixedOddsOnlyBettingPage.name,
  },
  {
    regEx: `^${StructuredSearchRoute}`,
    code: screenCodes.StructuredSearch.code,
    name: screenCodes.StructuredSearch.name,
  },
  {
    regEx: '^account/withdrawal$',
    code: screenCodes.Withdrawal.code,
    name: screenCodes.Withdrawal.name,
  },
  {
    regEx: '^specials$',
    code: screenCodes.Specials.code,
    name: screenCodes.Specials.name,
  },
  {
    regEx: '^favourite-numbers$',
    code: screenCodes.FavouriteNumbers.code,
    name: screenCodes.FavouriteNumbers.name,
  },
]

export function lookupScreenName(hashUrl: string): ScreenNameMatch {
  let currentScreen: ScreenNameMatch = {
    code: '',
    name: '',
  }

  if (isHomeUrl(hashUrl)) {
    currentScreen = {
      code: screenCodes.Home.code,
      name: screenCodes.Home.name,
    }
  } else {
    for (let element of screenMatches) {
      const regex = new RegExp(element.regEx, 'i')
      const result = regex.test(hashUrl)
      if (result) {
        currentScreen = {
          code: element.code,
          name: element.name,
        }
        break
      }
    }
  }

  return currentScreen
}

function isHomeUrl(url: string): boolean {
  return url === '' || url === '#' || url.search('^[?].*$') > -1
}
