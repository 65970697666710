import * as ko from 'knockout'
import ObservableRaceKeyPoolInfo from './ObservableRaceKeyPoolInfo'
import ObservablePool from './ObservablePool'
import Guard from '../../../AppUtils/Framework/Guard'

export default class ObservableRaceKey {
  constructor() {
    // @ts-expect-error Type issue
    this.raceNumber = ko.observable()
    // @ts-expect-error Type issue
    this.startTime = ko.observable()
    // @ts-expect-error Type issue
    this.leg = ko.observable()
    // @ts-expect-error Type issue
    this.tips = ko.observable()
    // @ts-expect-error Type issue
    this.tipster = ko.observable()
    // @ts-expect-error Type issue
    this.name = ko.observable()
    // @ts-expect-error Type issue
    this.pay = ko.observable()
    // @ts-expect-error Type issue
    this.distance = ko.observable()
    this.poolInfo = new ObservableRaceKeyPoolInfo(-1)
    this.allUpPools = ko.observableArray([] as ObservablePool[])
    // @ts-expect-error Type issue
    this.isDoubleLeg = ko.observable()
    // @ts-expect-error Type issue
    this.isQuaddieLeg = ko.observable()
  }

  public merge(other: ObservableRaceKey) {
    this.raceNumber(other.raceNumber())
    this.startTime(other.startTime())
    this.leg(other.leg())
    this.tips(other.tips())
    this.tipster(other.tipster())
    this.name(other.name())
    this.pay(other.pay())
    this.distance(other.distance())
    this.poolInfo.merge(other.poolInfo)
    this.allUpPools(other.allUpPools())
    this.isDoubleLeg(other.isDoubleLeg())
    this.isQuaddieLeg(other.isQuaddieLeg())
  }

  public addAllUpPool(poolTotal: ObservablePool) {
    Guard.notNull(poolTotal)
    this.allUpPools.push(poolTotal)
  }

  public raceNumber: ko.Observable<number>
  public leg: ko.Observable<number>
  public startTime: ko.Observable<Date>
  public tips: ko.Observable<string>
  public tipster: ko.Observable<string>
  public distance: ko.Observable<string>
  public name: ko.Observable<string>
  public pay: ko.Observable<string>
  public poolInfo: ObservableRaceKeyPoolInfo
  public allUpPools: ko.ObservableArray<ObservablePool>
  public isDoubleLeg: ko.Observable<boolean>
  public isQuaddieLeg: ko.Observable<boolean>
}
