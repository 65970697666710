import React from 'react'
import * as icons from './material'
import { MaterialSvgStyled } from './MaterialIcon.styled'

type Props = {
  type: keyof typeof icons
  title?: string
  description?: string
}

const MaterialIcon = ({ type, title, description }: Props): JSX.Element => {
  const Icon = icons[type]

  return (
    <MaterialSvgStyled viewBox='0 0 24 24' role='graphics-symbol'>
      {title && <title>{title}</title>}
      {description && <desc>{description}</desc>}

      <Icon />
    </MaterialSvgStyled>
  )
}

export default MaterialIcon
