import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { measurements } from '@mobi/component-library/Theme/Common'
import { colors } from '@core/Settings'
import { MainContainer } from '@core/Components/Containers'
import { OverlayHeaderStyled, OverlayHeadingStyled } from '@core/Components/Overlay/Overlay.styles'

export const BetslipOverlayStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  background: colors.betslip.background,
  display: 'flex',
  flexDirection: 'column',
})

export const BetslipOverlayHeaderStyled = styled(OverlayHeaderStyled)({
  position: 'fixed',
})

export const BetslipOverlayHeaderTextStyled = styled(OverlayHeadingStyled)({
  color: colors.betslip.header.text,
})

export const BetslipMainContainerStyled = styled(MainContainer)({
  overflowY: 'scroll', // scroll, not auto for momentum scroll to work
  WebkitOverflowScrolling: 'touch', // enables momentum scroll < IOS11
  overscrollBehavior: 'contain',
  minHeight: 0,
  flex: 1,
  background: hexColors.gainsboro,
  marginTop: measurements.mobi.headerHeight,
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '22rem', // Height of footer and notification(s)
  },
})

export const EmptyBetslipMessageStyled = styled.div({
  fontSize: '1.6rem',
  textAlign: 'center',
  color: colors.betslip.summary.title.text,
  textShadow: `0 0.1rem 0 ${colors.betslip.summary.color}`,
  paddingTop: `calc(50vh - (${measurements.mobi.headerHeight} + 4rem))`, // 6rem:header + 4rem:~half-footer
})

EmptyBetslipMessageStyled.displayName = 'EmptyBetslipMessageStyled'
