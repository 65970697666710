import type { PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { paymentMethodTypeNameMap } from './schema'

export type DepositAnalyticsKeys =
  | 'deposit_add_card'
  | 'deposit_cancel'
  | 'deposit_change_card'
  | 'deposit_change_default_card'
  | 'deposit_close'
  | 'deposit_copy_content'
  | 'deposit_delete_card'
  | 'deposit_find_tab'
  | 'deposit_generate_payid'
  | 'deposit_payid_active'
  | 'deposit_payid_deregistered'
  | 'deposit_payid_disabled'
  | 'deposit_payid_failed'
  | 'deposit_payid_help'
  | 'deposit_manage_cards'
  | 'deposit_method_selection'
  | 'deposit-3d-secure-error'
  | 'deposit-3d-secure-not-shifted'
  | 'deposit-3d-secure-user-data-fail'
  | 'deposit-3d-secure-user-data-success'
  | 'deposit-3d-secure'
  | 'deposit-error'
  | 'deposit-non-3d-secure'
  | 'deposit-open'
  | 'deposit_scan_my_bet'
  | 'deposit-started'
  | 'deposit-success'

type AnalyticsEventData = {
  accountNumber: number
  paymentMethod?: PaymentMethodsAll
  [key: string]: unknown
}

export type TrackDepositAnalytics = (
  eventKey: DepositAnalyticsKeys,
  data: AnalyticsEventData
) => void

export const track: TrackDepositAnalytics = (eventKey, data) => {
  const { accountNumber, paymentMethod, ...other } = data
  window.dataLayer?.push({
    depositVersion: 'new-deposit',
    event: eventKey,
    accountNumber,
    ...(paymentMethod && { depositMethod: paymentMethodTypeNameMap[paymentMethod] }),
    ...other,
  })
}
