import * as dtos from '../DataTransferObjects/DataTransferObjects'
import { IObservableFinisher } from '../Model/Observables/IObservableFinisher'
import ObservableTrotFinisher from '../Model/Observables/ObservableTrotFinisher'
import { BaseFinisherCodeBuilder } from './BaseFinisherCodeBuilder'

export default class TrotFinisherBuilder extends BaseFinisherCodeBuilder {
  build(dto: dtos.FinisherDataTransferObject): IObservableFinisher {
    let model = super.addBaseInformation(
      new ObservableTrotFinisher(),
      dto
    ) as ObservableTrotFinisher
    model.template('trot-finishers-template')
    model.tag('Trot')
    model.handicap(dto.Handicap)
    return model
  }
}
