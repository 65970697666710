import ObservableProvDiv from './ObservableProvDiv'

export default class ObservableProvDivPool {
  public name: string
  public provDivs: ObservableProvDiv[]
  public quaddieProvDivLegNums: number[]

  constructor() {
    this.name = ''
    this.provDivs = []
    this.quaddieProvDivLegNums = []
  }

  public label(): string {
    switch (this.name) {
      case 'Double':
        return 'Double Prov Div'
      case 'Quaddie':
        return 'Quaddie (' + this.quaddieProvDivLegNums.join(',') + ') Prov Div'
      default:
        return 'Prov Div'
    }
  }

  public showOutcomes(): boolean {
    return this.provDivs.length > 1
  }

  public addProvDiv(div: string, outcome: number[]) {
    var provDiv = new ObservableProvDiv()
    provDiv.div = div
    provDiv.outcome = outcome

    this.provDivs.push(provDiv)
  }
}
