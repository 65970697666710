import { fromJS, List } from 'immutable'
import type { State } from 'rwwa-data-access'
import { attachDriver, createSignal, Signal } from 'rwwa-rx-state-machine'
import { StateMap } from 'typings/immutable'
import { isBetslipResponses } from '@core/Data/betslip'
import {
  FavouriteNumbersPools,
  UpcomingFavouriteNumberPools,
  UpcomingPoolsKey,
} from '@core/Data/favouriteNumbers'
import { ConfirmBetslipBetsSucceeded, QuickbetClosed } from '../Quickbet/signals'

export interface FavouriteNumbersBettingState {
  gameOne: number[]
  gameTwo: number[]
  pools: Date[]
  potLuckBets: number
  betConfirmed: boolean
  resultKey: string
  showAllPools: boolean
}

type PoolSelectionProps = { pool: Date }

export const ToggleGameNumber = createSignal<{ number: number; game: number }>('ToggleGameNumber')
export const TogglePool = createSignal<PoolSelectionProps>('TogglePool')
export const ClearSelections = createSignal('ClearSelections')
export const SetNumberOfBets = createSignal<number>('SetNumberOfBets')
export const SetPotLuckNumbers = createSignal<number>('SetPotLuckNumbers')
export const SetResultKey = createSignal<string>('SetResultKey')
export const DeselectPool = createSignal<PoolSelectionProps>('DeselectPool')
export const SetShowAll = createSignal<boolean>('SetShowAll')
export const LoadNumbers = createSignal<{ numbers: number[]; game: number }>('LoadNumbers')

export const defaultState: FavouriteNumbersBettingState = {
  gameOne: [],
  gameTwo: [],
  pools: [],
  potLuckBets: 1,
  betConfirmed: false,
  resultKey: 'current',
  showAllPools: false,
}

type FavouriteNumbersBettingStateMap = StateMap<FavouriteNumbersBettingState>

function driver(state = fromJS(defaultState), signal: Signal): FavouriteNumbersBettingStateMap {
  switch (signal.tag) {
    case ToggleGameNumber: {
      const game = state.get(signal.data.game === 1 ? 'gameOne' : 'gameTwo') as List<number>
      const updatedGame = game.contains(signal.data.number)
        ? game.filter(x => x !== signal.data.number)
        : game.count() < 16
          ? game.push(signal.data.number)
          : game
      const newState =
        signal.data.game === 1 ? { gameOne: updatedGame.sort() } : { gameTwo: updatedGame.sort() }
      return state.merge(newState)
    }

    case TogglePool: {
      const pools = state.get('pools') as List<Date>
      const selectedPools = pools.contains(signal.data.pool)
        ? pools.count() > 1
          ? pools.filter(x => x !== signal.data.pool)
          : pools
        : pools.push(signal.data.pool)
      return state.merge({ pools: selectedPools.sort() })
    }

    case DeselectPool: {
      const pools = state.get('pools') as List<Date>
      const selectedPools = pools.filter(x => x !== signal.data.pool)
      return state.merge({ pools: selectedPools.sort() })
    }

    case SetNumberOfBets: {
      return state.merge({ potLuckBets: signal.data })
    }

    case SetPotLuckNumbers: {
      return state.merge({ gameOne: [signal.data] })
    }

    case ClearSelections: {
      return fromJS(defaultState)
    }

    case ConfirmBetslipBetsSucceeded: {
      if (isBetslipResponses(signal.data)) {
        return state.merge({
          betConfirmed: true,
        })
      }
      return state
    }

    case SetResultKey: {
      return state.merge({ resultKey: signal.data })
    }

    case QuickbetClosed: {
      if (state.get('betConfirmed')) {
        FavouriteNumbersPools.hardInvalidate(
          null as unknown as State<UpcomingFavouriteNumberPools>,
          UpcomingPoolsKey
        )
        return fromJS(defaultState)
      }
      return state
    }

    case SetShowAll: {
      return state.merge({ showAllPools: signal.data })
    }

    case LoadNumbers: {
      if (signal.data.game == 1) {
        return state.merge({ gameOne: signal.data.numbers })
      } else {
        return state.merge({ gameTwo: signal.data.numbers })
      }
    }

    default:
      return state
  }
}

export const state$ = attachDriver<FavouriteNumbersBettingStateMap>({
  driver,
  path: 'favourite-numbers',
}).map(s => s.toJS())
