import * as ko from 'knockout'
import { LegInViewModel } from './LegInViewModel'
import template from './legin.tpl.html'

if (!ko.components.isRegistered('leg-in')) {
  ko.components.register('leg-in', {
    viewModel: LegInViewModel,
    template,
  })
}
