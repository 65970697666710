import { get, post } from '@classic/Foundation/Services/ApiService'

const enum LocalConstants {
  apiEndpoint = '/services/preferences/api/communicationpreferences',
}

export const getPreferencesFromApi = () =>
  get<CommunicationPreferences>({ url: LocalConstants.apiEndpoint })

export const postPreferencesToApi = (newPrefs: OptInPreferences) =>
  post<OptInPreferences>({ url: LocalConstants.apiEndpoint, body: newPrefs })

// Types

export interface OptInPreferences {
  Email: boolean
  Mail: boolean
  Phone: boolean
  Sms: boolean
  Marketing: boolean
}

interface CommunicationPreferences {
  ContactInfo: {
    Email: string
    MailingAddress: string
    Mobile: string
    Phone: string
  }
  OptInPreferences: OptInPreferences
}
