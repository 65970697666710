import React from 'react'
import {
  HeaderStyled,
  PotLuckNumbersButtonStyled,
  FavouriteNumbersFlex,
  PotLuckNumberButtonStyled,
  PotLuckNumberCostStyled,
  PotLuckNumbersStyled,
  FavouriteNumbersSection,
} from './FavouriteNumbers.styles'
import { PoolSelection } from './PoolSelection'
import { ClearSelections, state$, SetNumberOfBets, SetPotLuckNumbers } from './driver'
import { NumberButtons } from './NumberSelection'
import { useObservableProperties } from '@core/Utils/hooks/useObservable'
import { FavouriteNumbersBetSummary } from './FavouriteNumbersBetSummary'
import { Money } from '@core/Components/Text/Money'
import { ComboLookup, costCalculator } from './calculator'
import { HeaderWithLoadingIndicator } from './HeaderWithLoader'

export const PotLuckTab = (): JSX.Element => {
  React.useEffect(() => {
    return ClearSelections
  })

  return (
    <div style={{ marginTop: '1em' }} data-testid='pot-luck'>
      <HeaderWithLoadingIndicator title='SELECT NUMBERS'></HeaderWithLoadingIndicator>
      <PotLuckNumbers />
      <HeaderStyled data-tid-select-pools-header=''>SELECT POOL DATES</HeaderStyled>
      <PoolSelection />
      <HeaderStyled data-tid-num-bets-header=''>NUMBER OF BETS</HeaderStyled>
      <NumberOfBets />
      <HeaderStyled data-tid-bet-summary-header=''>BET SUMMARY</HeaderStyled>
      <FavouriteNumbersBetSummary mode='potLuck' />
    </div>
  )
}

const NumberOfBets = (): JSX.Element => {
  const { potLuckBets } = useObservableProperties(state$, ['potLuckBets'], {
    potLuckBets: 1,
  })

  return (
    <FavouriteNumbersSection>
      <NumberButtons
        numbers={10}
        selectedNumbers={[potLuckBets]}
        onClickHandler={(number: number) => {
          SetNumberOfBets(number)
        }}
      />
    </FavouriteNumbersSection>
  )
}

const PotLuckNumbers = (): JSX.Element => {
  const { gameOne } = useObservableProperties(state$, ['gameOne'], {
    gameOne: [],
  })

  React.useEffect(() => {
    if (gameOne.length === 0) {
      SetPotLuckNumbers(7)
    }
  }, [gameOne, gameOne.length])

  return (
    <PotLuckNumbersStyled data-testid='potluck-numbers'>
      {Object.keys(ComboLookup).map(key => {
        const numbers = Number.parseInt(key)
        const isSelected = gameOne.length > 0 && gameOne[0] === numbers
        return (
          <PotLuckNumbersButtonStyled
            data-tid-potluck-numbers=''
            key={`numbers-${numbers}`}
            onClick={() => SetPotLuckNumbers(numbers)}
            color={isSelected ? 'dark' : 'secondary'}
          >
            <FavouriteNumbersFlex>
              <PotLuckNumberButtonStyled>{numbers}</PotLuckNumberButtonStyled>
              <PotLuckNumberCostStyled>
                <Money amount={costCalculator(numbers ?? 0)} />
              </PotLuckNumberCostStyled>
            </FavouriteNumbersFlex>
          </PotLuckNumbersButtonStyled>
        )
      })}
    </PotLuckNumbersStyled>
  )
}
