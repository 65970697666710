import styled from '@emotion/styled'
import type { Props } from './Grid'

export const GridStyled = styled.div<Props>(
  {
    display: 'grid',
  },
  ({ gap = '1rem', cols = 1, rows = 1 }) => ({
    gap,
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  })
)
