import React from 'react'
import { NoticeBox, NoticeBoxTypes } from '../../../../Components/NoticeBox'
import { FailedSaveMessageContainer } from './FailedSaveMessage.styles'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'

export class FailedSaveMessage extends React.Component<{ showFailedMessage: boolean }> {
  private element: HTMLElement | null = null

  public componentDidUpdate() {
    // on error scrolls to the error
    if (this.element && this.element.scrollIntoView) {
      this.element.scrollIntoView()
    }
  }

  public render() {
    if (!this.props.showFailedMessage) {
      return null
    }

    return (
      <FailedSaveMessageContainer
        ref={node => {
          this.element = node
        }}
      >
        <NoticeBox
          title='Failed to save your contact details'
          noticeBoxType={NoticeBoxTypes.Error}
          hasBorder={true}
        >
          <p>
            Please try again or call <CustomerServiceTelephone /> for assistance.
          </p>
        </NoticeBox>
      </FailedSaveMessageContainer>
    )
  }
}
