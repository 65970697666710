import { SelectedBetType, StarterForRace } from '../types'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'
import { useMemo, useState } from 'react'

type UseFixedOdds = {
  hasFixedOdds: boolean
  shouldDisplayFixedOddsToggle: boolean
  isFixedOddsOn: boolean
  toggleFixedOdds: () => void
}

export const useFixedOdds = (
  selectedBetType: Omit<SelectedBetType, 'betType'>,
  startersForRace: StarterForRace[]
): UseFixedOdds => {
  const [isFixedOddsOn, setIsFixedOddsOn] = useState(selectedBetType.isSameRaceMulti)

  const eventAggregator = useEventAggregator()

  function toggleFixedOdds(): void {
    const nextFixedOddsOn = !isFixedOddsOn

    setIsFixedOddsOn(nextFixedOddsOn)
    eventAggregator.publish('fixed-odds-display-state-changed', nextFixedOddsOn)
  }

  const hasFixedOdds = useMemo(() => {
    return startersForRace.some(starter => starter.hasFixedOdds)
  }, [startersForRace])

  const shouldDisplayFixedOddsToggle = useMemo(() => {
    // SRM doesn't have TOTE pricing, so the toggle should always be hidden and fixed price enabled
    if (selectedBetType.isSameRaceMulti) {
      return false
    }

    return hasFixedOdds && !selectedBetType.isWinPlace
  }, [hasFixedOdds, selectedBetType.isSameRaceMulti, selectedBetType.isWinPlace])

  return { hasFixedOdds, shouldDisplayFixedOddsToggle, isFixedOddsOn, toggleFixedOdds }
}
