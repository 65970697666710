import styled from '@emotion/styled'
import { QuickbetBodyStyled } from '@core/Areas/Quickbet/Quickbet.styles'
import { Text } from '@core/Components/Text'

type BodyProps = {
  centered: boolean
}

export const QuickbetDepositBodyStyled = styled(QuickbetBodyStyled)<BodyProps>(({ centered }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: centered ? 'center' : 'normal',
  minHeight: '18rem',
  padding: '1.8rem 1.5rem 1rem',
}))

type BodyContainerProps = {
  hidden: boolean
}

export const QuickDepositBodyContainerStyled = styled.div<BodyContainerProps>(({ hidden }) => ({
  display: hidden ? 'none' : 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
}))

export const LoadingContainerStyled = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& :not(:last-child)': {
    marginBottom: '1rem',
  },
})

export const QuickDepositLabelStyled = styled(Text)({
  display: 'inline-block',
  lineHeight: 1,
  margin: 0,
  padding: 0,
  textAlign: 'center',
  width: '100%',
})

export const QuickDepositBodySectionStyled = styled.section({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',

  '> header > *': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '6.1rem',
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '> *': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '4.1rem',
    },

    '> button:last-child': {
      marginBottom: '4.1rem',
    },
  },
})

export const PayPalSDKButtonContainerStyled = styled.div({
  display: 'flex',
})
