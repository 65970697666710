import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors } from '@core/Settings'
import { Icon } from '@mobi/component-library/Common/Icon'

export const ContentHeaderStyled = styled('header')({
  background: hexColors.black,
  color: hexColors.white,
  padding: '1em',
})

export const ContentHeaderTitleStyled = styled('h2')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.6rem',
  textTransform: 'uppercase',
  lineHeight: '1.2',

  svg: {
    marginRight: '0.5rem',
  },
})

export const ContentHeaderLinkStyled = styled('a')({
  color: colors.subContentHeader.text,
  textDecoration: 'none',
})

export const Rotated180Icon = styled(Icon)({
  transform: 'rotate(180deg)',
})
