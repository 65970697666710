import React from 'react'
import styled from '@emotion/styled'
import { useQuery } from 'react-query'
import { hexColors } from '@mobi/settings'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { getBlackbookComments } from '@core/Areas/Blackbook/helpers/api'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { SingleComment } from './Components/SingleComment'
import { BlackbookEntryRacingToday } from '../../types'
import { radius } from '@mobi/component-library/Theme/Common'

export const Comments: React.FC<{
  runnerId: number
  name: BlackbookEntryRacingToday['StarterName']
  type: BlackbookEntryRacingToday['Code']
  /** Used to signify if rendered outside of Blackbook page */
  isExternalDisplay?: boolean
}> = ({ runnerId, name, type, isExternalDisplay = false }) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: [queryKeys.blackbookComments, runnerId],
    queryFn: () => getBlackbookComments(runnerId),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  if ((!isLoading && !data?.length) || isError) return null

  const comments = data ? (isExternalDisplay ? data.slice(0, 1) : data) : []
  const commentCount = isExternalDisplay ? data?.length : undefined

  return (
    <CommentWrapperStyled data-testid='blackbook-my-comments'>
      {isLoading ? (
        <LoadingPlaceholder
          testId='blackbook-comments-loading-placeholder'
          width='100%'
          height='5rem'
          borderRadius={radius.lg}
        />
      ) : (
        comments.map(comment => (
          <SingleComment
            key={comment.BlackbookNotesSeq}
            comment={comment}
            commentCount={commentCount}
            id={runnerId}
            name={name}
            type={type}
          />
        ))
      )}
    </CommentWrapperStyled>
  )
}

// ======
// Styles
// ======

const CommentWrapperStyled = styled.div({
  margin: '0.5rem',
  padding: '0 1rem',
  border: '0.1rem solid ' + hexColors.gainsboro,
  borderRadius: '1rem',
  background: hexColors.white,
  boxShadow: '0 0.5rem 1rem -0.3rem rgb(0 0 0 / 0.1)',

  '& > div': {
    borderBottom: '0.1rem solid ' + hexColors.gainsboro,

    '&:last-of-type': { border: 0 },
  },
})
