import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors, font } from '@mobi/component-library/Theme/Common'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import SortConverter from '@classic/Betting-v2/Sorting/SortConverter'
import { useAppSelector } from '@core/Store/hooks'
import { getActiveToteSwap } from '@core/Areas/RaceCard/Store/selectors'
import { RaceStatus } from '@core/Areas/Racing/Types'
import {
  cssClass,
  shouldDisplayColumn,
  shouldResetFixedPriceSort,
  shouldResetTotePriceSort,
} from './Helpers/StarterPageHeaderHelper'
import { FobToteSwitch } from './Components/FobToteSwitch'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import { BetModifiers } from '../BetModifiers/BetModifiers'
import type { SelectedBetType, StarterForRace } from './types'
import { useColumnSort, useFixedOdds } from './Hooks'
import { trackRaceCardSorting } from './analytics'

const enum LocalConstants {
  ActiveSortClassName = 'js-race-col-header__sort--active',
}

type StarterPageProps = {
  currentSortForRace: SortOption
  startersForRace: StarterForRace[]
  selectedBetType: SelectedBetType
  raceNumber: number
  raceStatus: RaceStatus
}

export const StartersColumnHeader: React.FC<
  StarterPageProps & { bettingContext: BettingInformation }
> = ({
  currentSortForRace,
  startersForRace,
  selectedBetType,
  raceNumber,
  raceStatus,
  bettingContext,
}) => {
  const activeToteSwapOption = useAppSelector(getActiveToteSwap)
  const { hasFixedOdds, shouldDisplayFixedOddsToggle, isFixedOddsOn, toggleFixedOdds } =
    useFixedOdds(selectedBetType, startersForRace)

  const { sortOptions, sortSelected, sort } = useColumnSort(
    selectedBetType,
    raceNumber,
    currentSortForRace
  )

  const resetSortBackToDefaultIfRequired = React.useCallback(() => {
    if (
      shouldResetTotePriceSort(selectedBetType, sortSelected, isFixedOddsOn) ||
      shouldResetFixedPriceSort(sortOptions, selectedBetType, sortSelected, isFixedOddsOn)
    ) {
      const numberSortOption = sortOptions?.find((so: SortOption) => {
        return so.property === 'Number'
      })
      if (numberSortOption != null) {
        sort(numberSortOption)
      }
    }
  }, [isFixedOddsOn, selectedBetType, sort, sortOptions, sortSelected])

  React.useEffect(() => {
    resetSortBackToDefaultIfRequired()
  }, [
    selectedBetType.isWinPlace,
    sortSelected.property,
    isFixedOddsOn,
    sortSelected,
    resetSortBackToDefaultIfRequired,
  ])

  const shouldShowBetModifiers = React.useCallback(
    (index: number) => raceStatus === RaceStatus.Open && index === 0,
    [raceStatus]
  )

  const handleToggleFixedOdds = () => {
    toggleFixedOdds()
    resetSortBackToDefaultIfRequired()
  }

  return (
    // TODO: All references to SCSS should have been removed on rewrite to react
    <div className='flat-display-list-header bg-gray-lightest'>
      {sortOptions?.map((sortOption, i) => {
        if (!shouldDisplayColumn(sortOption, selectedBetType, isFixedOddsOn, hasFixedOdds))
          return null

        const sortNameText =
          sortOption.property === 'Number' ? '#' : SortConverter.for(sortOption.property)

        return (
          <ColumnHeaderPanelStyled
            key={i}
            className={`pt1em pb1em ${cssClass(sortOption)}`}
            data-testid={`sort-order-${sortNameText}`}
          >
            {sortNameText.toLowerCase() === 'tote' && !!activeToteSwapOption ? (
              <ColumnHeaderItemStyled data-testid={activeToteSwapOption}>
                <span>{activeToteSwapOption === 'CON' ? 'Ins.' : 'SP'}</span>
              </ColumnHeaderItemStyled>
            ) : (
              <ColumnHeaderItemStyled
                onClick={() => {
                  sort(sortOption)
                  trackRaceCardSorting(sortOption, raceStatus)
                }}
                className={
                  sortOption.property === sortSelected.property
                    ? LocalConstants.ActiveSortClassName
                    : ''
                }
              >
                <span>{sortNameText}</span>
                <Icon
                  size='1.4rem'
                  name={
                    sortOption.direction === 'Ascending' ? 'SolidChevronUp' : 'SolidChevronDown'
                  }
                  color={colors.neutral[500]}
                />
              </ColumnHeaderItemStyled>
            )}

            <ColumnHeaderItemStyled>
              {/* show bet modifiers only after # column */}
              {shouldShowBetModifiers(i) && (
                <BetModifiers
                  key={i}
                  bettingContext={bettingContext}
                  betType={selectedBetType.betType}
                />
              )}
            </ColumnHeaderItemStyled>
          </ColumnHeaderPanelStyled>
        )
      })}

      {shouldDisplayFixedOddsToggle && (
        <ColumnHeaderItemStyled className='l-faux-cell alignmid faux-strong posr toggle-col'>
          <div className='alignmid' onClick={handleToggleFixedOdds}>
            <FobToteSwitch isFixedOddsOn={isFixedOddsOn} />
          </div>
        </ColumnHeaderItemStyled>
      )}
    </div>
  )
}

// ======
// Styles
// ======

const ColumnHeaderPanelStyled = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: '2rem',
  justifyContent: 'center',
})

const ColumnHeaderItemStyled = styled.div({
  display: 'inline-flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '0.2rem',
  fontFamily: font.family.primary,
  fontWeight: font.weight.regular,
  fontSize: font.size.sm.fontSize,
  letterSpacing: font.size.sm.letterSpacing,
  lineHeight: font.size.sm.lineHeight,
  color: colors.black,

  [`&.${LocalConstants.ActiveSortClassName}`]: {
    fontWeight: font.weight.semibold,
    svg: { color: colors.black },
  },
})
