import React from 'react'

import { hexColors } from '@mobi/settings'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Money } from '@core/Components/Text/Money'
import { ToteSportsPool } from '../data/toteSports'

import {
  SinglePoolStyled,
  PoolNameStyled,
  PoolTotalStyled,
  AbandonedStyled,
} from '../TippingPage.styles'
import { getDisplayTime } from '../helpers/getDisplayTime'

export function SingleResultedPool({ pool }: { pool: ToteSportsPool }): JSX.Element {
  return (
    <SinglePoolStyled>
      <Grid>
        <GridRow>
          <GridCell align='left' valign='middle' data-tid-tote-sport-pool-name>
            <PoolNameStyled>{`${pool.name} ${pool.nameSuffix} ${pool.numberOfGames}`}</PoolNameStyled>
          </GridCell>
          <GridCell width='25%' align='left' valign='middle' data-tid-tote-sport-release-time>
            {getDisplayTime(pool.releaseTime)}
          </GridCell>
          <GridCell width='16.6666%' align='center' valign='middle'>
            {pool.isAbandoned
              ? null
              : `${pool.dividend?.numberOfTipsRequired || 0}/${pool.numberOfGames}`}
          </GridCell>
          <GridCell width='8.3333%' align='center' valign='middle'>
            {!!pool.grossJackpot && (
              <Icon
                type={'jackpot'}
                color={hexColors.orange}
                data-tid-tote-sport-pool-jackpot-icon
              />
            )}
          </GridCell>
          <GridCell
            width='16.6666%'
            align='right'
            valign='middle'
            data-tid-tote-sport-pool-dividend
          >
            <PoolTotalStyled>
              {pool.isAbandoned && <AbandonedStyled />}
              {!pool.isAbandoned && pool.dividend?.return && (
                <Money amount={pool.dividend.return} decimalPlaces={2} />
              )}
            </PoolTotalStyled>
          </GridCell>
          <GridCell width='3em' align='center' valign='middle'>
            <Icon type='arrowright' size='1em' color={hexColors.darkGrey} />
          </GridCell>
        </GridRow>
      </Grid>
    </SinglePoolStyled>
  )
}
