import * as ko from 'knockout'
import { ToteWinPlaceSelectionsViewModel } from './ToteWinPlaceSelectionsViewModel'
import template from './tote-winplace-selections.tpl.html'

if (!ko.components.isRegistered('tote-winplace-selections')) {
  ko.components.register('tote-winplace-selections', {
    viewModel: ToteWinPlaceSelectionsViewModel,
    template,
  })
}
