import { BettingInformation } from '../../Model/BettingInformation'
import { BetType } from '../../Model/Betting/BetType'
import Guard from '../../../AppUtils/Framework/Guard'

export class MySelectionsViewModel {
  constructor(params: { bettingContext: BettingInformation }) {
    Guard.notNull(params)
    Guard.notNull(params.bettingContext)

    this.bettingContext = params.bettingContext
    this.betTypeName = BetType[this.bettingContext.selectedBetType().betType()]
  }

  public bettingContext: BettingInformation
  public betTypeName: string
}
