import styled, { type CSSObject } from '@emotion/styled'

type SvgIconProps = {
  color?: string
  size: string
  transformation?: string
  valign?: string
  height?: string | number
  width?: string | number
}

export const SvgIconStyled = styled.svg<SvgIconProps>(
  ({
    color,
    size,
    transformation,
    valign = 'bottom',
    height = '1em',
    width = '1em',
  }: SvgIconProps) => {
    // height and width are done in a separate object, so it doesn't update 110 snapshots
    const sizes: CSSObject = {}

    if (typeof width === 'string') {
      sizes.width = width
    }

    if (typeof height === 'string') {
      sizes.height = height
    }

    const style: CSSObject = {
      display: 'inline-block',
      fill: color || 'currentColor',
      fontSize: size,
      ...sizes,
      verticalAlign: valign,
      transform: transformation,
    }

    return style
  }
)
