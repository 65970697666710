import React from 'react'
import { observeImmutable } from '@core/Components/HOCs'
import { ClearBetslip } from '../../signals'
import { GridButtonStyled } from './Buttons.styles'
import { state$ } from '../../driver'

const clearBetslip = () => ClearBetslip()

interface ClearBetslipButtonComponentProps {
  isDisabled: boolean
}

function ClearBetslipButtonComponent({ isDisabled }: ClearBetslipButtonComponentProps) {
  return (
    <GridButtonStyled
      disabled={isDisabled}
      onClick={clearBetslip}
      color='secondary'
      type='button'
      data-testid='clear-betslip-button'
    >
      Clear Betslip
    </GridButtonStyled>
  )
}

export const ClearBetslipButton = observeImmutable(state$, ({ record: { items } }) => (
  <ClearBetslipButtonComponent isDisabled={items.isEmpty()} />
))
