import React from 'react'
import ReactDOM from 'react-dom'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { BetslipExpandableTypes, BetslipItem } from '@core/Areas/Betslip/driver'
import { CloseBetslipExpandable } from '@core/Areas/Betslip/signals'
import { OverlayOpen } from '@core/Components/Overlay'
import { Betslip } from '@core/Areas/Betslip/Betslip'
import { MultiToastContainerStyled } from './MultiToast.styles'
import { MultiToastPrice } from './MultiToastPrice'

export const MultiToast: React.FC<{ show: boolean; betslipItems: BetslipItem[] }> = ({
  show,
  betslipItems,
}) => {
  const [changed, setChanged] = React.useState(false)

  React.useEffect(() => {
    // show == true means there is a valid multi in selected betslip items
    if (!show) {
      setChanged(false)
      return
    }

    setChanged(true)

    const timerId = window.setTimeout(() => {
      setChanged(false)
    }, 4000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [show, betslipItems])

  return ReactDOM.createPortal(
    <MultiToastContainerStyled show={show} changed={changed} onClick={handleOnClick}>
      {show && <MultiToastPrice betslipItems={betslipItems} />}
    </MultiToastContainerStyled>,
    document.body
  )
}

function handleOnClick() {
  CloseBetslipExpandable(BetslipExpandableTypes.single)
  OverlayOpen(Betslip)
  trackKey('multi_toast_clicked')
}
