import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { isRacePageDto, isResultsPageDto } from '@mobi/api-types'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { useEventAggregator } from '@core/Utils/hooks/useEventAggregator'
import { useBetInformerAnalytics } from '@core/Areas/Racing/Components/BetInformers/Hooks/useBetInformerAnalytics'
import { AppRoutes } from '@core/AppRoutes'
import { useBetInformers } from './Hooks/useBetInformers'
import { hasTips } from '@core/Areas/Racing/Components/BettableTips'
import { useRacePageData } from '@core/Areas/Racing/Hooks/useRacePageData'
import { useStewardsComments } from '@core/Areas/Racing/Hooks/useStewardsComments'
import { RaceStatus } from '../../Types'
import { hasResulted, hasResults } from '@core/Areas/Racing/Hooks/useRaceStatus'
import { buttonText } from './constants'

const enum LocalConstants {
  BetInformerActiveClassName = 'js-bet-informer-btn--active',
  SkyRaceCardClassname = 'js-bet-informer--sky-race',
  ReplayBtnClassName = 'js-bet-informer-bnt__replay',
}

export const BetInformers: React.FC<{
  meetingId: string
  meetingDate: Date
  raceNumber: number
  isGiddyUpDisabled: boolean
  toggleGiddyUp: () => void
  raceReplayId?: string
}> = ({
  meetingId,
  meetingDate,
  raceNumber,
  isGiddyUpDisabled,
  toggleGiddyUp,
  raceReplayId = '',
}) => {
  const { isLoading, isError, race } = useRacePageData({ meetingId, meetingDate, raceNumber })

  const { buttons, toggleButton } = useBetInformers()
  const {
    trackFieldVisible,
    trackCommentsVisible,
    trackResultsVisible,
    trackReplayVisible,
    trackShowMarketMovers,
    trackShowFixedFlucs,
    trackShowDoubleProvDiv,
    trackShowQuaddieProvDiv,
    trackTipsVisible,
    trackShowAllForm,
    trackGiddyUp,
  } = useBetInformerAnalytics(meetingId, meetingDate, raceNumber)
  const eventAggregator = useEventAggregator()
  const isFormDisabled = useMemo(() => race && isResultsPageDto(race), [race])
  const hasRaceReplayVideo = useMemo(
    () =>
      (race &&
        isResultsPageDto(race) &&
        hasResults(RaceStatus[race.RaceStatus]) &&
        race?.RaceReplay?.HasVideo) ??
      false,
    [race]
  )

  const meetingHasResults = useMemo(
    () => race && isResultsPageDto(race) && hasResulted(RaceStatus[race.RaceStatus]),
    [race]
  )

  const { hasValidStewardsComments } = useStewardsComments({ meetingId, meetingDate, raceNumber })

  // On initial render, ensure that results are shown
  // This may need to change as we move away from KO
  useEffect(() => {
    if (meetingHasResults) {
      toggleButton('resultsVisible')
    }
  }, [toggleButton, meetingHasResults])

  useEffect(
    () => () => {
      eventAggregator.publish('stop-all-race-replay')
    },
    [eventAggregator]
  )

  useEffect(() => {
    const toggle = () => toggleButton('replayVisible')
    const toggleOff = () => buttons.replayVisible && toggleButton('replayVisible')
    if (hasRaceReplayVideo) {
      eventAggregator.subscribe(`race-replay-started-for-${raceReplayId}`, toggle)
      eventAggregator.subscribe(`race-replay-stopped-for-${raceReplayId}`, toggleOff)
    }
    return () => {
      if (hasRaceReplayVideo) {
        eventAggregator.unsubscribe(`race-replay-started-for-${raceReplayId}`, toggle)
        eventAggregator.unsubscribe(`race-replay-stopped-for-${raceReplayId}`, toggleOff)
      }
    }
  }, [buttons.replayVisible, eventAggregator, hasRaceReplayVideo, raceReplayId, toggleButton])

  if (isLoading || isError || !race) return null

  let isFixedFlucsDisabled = true
  let isMarketMoversDisabled = true
  let isDoubleProvDivDisabled = true
  let isQuaddieProvDivDisabled = true

  if (isRacePageDto(race)) {
    const startersForRace = race.RaceStarters.find(s => s.RaceKey.RaceNumber === raceNumber)
    if (startersForRace) {
      isFixedFlucsDisabled = !startersForRace.Starters.some(s => s.HasFixedOdds)
      isMarketMoversDisabled = !startersForRace.Starters.some(s => s.HasMarketMovers)
    }
    isDoubleProvDivDisabled = !(race.BetType === 'WinPlace' && race.HasDoubleProvDivs)
    isQuaddieProvDivDisabled = !(race.BetType === 'WinPlace' && race.HasQuaddieProvDivs)
  }

  const tips = race.MeetingInformation.SelectedRace.Tips
  const tipster = race.MeetingInformation.SelectedRace.Tipster
  const hasValidTips = hasTips(tips, tipster)

  const dontShowBetInformersPanel =
    !meetingHasResults &&
    !hasRaceReplayVideo &&
    isFormDisabled &&
    isGiddyUpDisabled &&
    !hasValidTips &&
    !hasValidStewardsComments &&
    isFixedFlucsDisabled &&
    isMarketMoversDisabled &&
    isDoubleProvDivDisabled &&
    isQuaddieProvDivDisabled

  if (dontShowBetInformersPanel) {
    return null
  }

  const isOnSkyRacePage = window.location.hash === AppRoutes.RaceCardSky.replace('/', '#')

  return (
    <BetInformersContainerStyled
      data-js-race-card-no-swipe
      className={isOnSkyRacePage ? LocalConstants.SkyRaceCardClassname : ''}
    >
      {meetingHasResults && (
        <>
          <li>
            <button
              className={buttons.resultsVisible ? LocalConstants.BetInformerActiveClassName : ''}
              data-testid='bet-informer-button-results'
              onClick={() => {
                if (buttons.replayVisible) {
                  eventAggregator.publish('stop-all-race-replay')
                }
                toggleButton('resultsVisible')
                trackResultsVisible()
              }}
            >
              <Icon name='SolidTrophy01' size='inherit' /> {buttonText['resultsVisible']}
            </button>
          </li>

          <li>
            <button
              className={buttons.fieldVisible ? LocalConstants.BetInformerActiveClassName : ''}
              data-testid='bet-informer-button-field'
              onClick={() => {
                if (buttons.replayVisible) {
                  eventAggregator.publish(`stop-all-race-replay`)
                }
                toggleButton('fieldVisible')
                trackFieldVisible()
              }}
            >
              <Icon name='SolidList' size='inherit' /> {buttonText['fieldVisible']}
            </button>
          </li>
        </>
      )}

      {hasRaceReplayVideo && (
        <li>
          <button
            className={`${buttons.replayVisible ? LocalConstants.BetInformerActiveClassName : ''} ${
              LocalConstants.ReplayBtnClassName
            }`}
            data-testid='bet-informer-button-race-replay'
            onClick={() => {
              eventAggregator.publish(`toggle-race-replay-for-${raceReplayId}`)
              trackReplayVisible()
            }}
          >
            <Icon
              name={buttons.replayVisible ? 'SolidStopCircle' : 'SolidPlayCircle'}
              size='inherit'
            />{' '}
            {buttonText['replayVisible']}
          </button>
        </li>
      )}

      {!isGiddyUpDisabled && (
        <li>
          <button
            data-testid='bet-informer-button-guddy-up'
            onClick={() => {
              toggleGiddyUp()
              trackGiddyUp()
            }}
          >
            <SvgLazy name='GiddyUpLogo' height='1.4rem' color='#0051A0' />
          </button>
        </li>
      )}

      {hasValidTips && (
        <li>
          <button
            className={buttons.tipsVisible ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-tips'
            onClick={() => {
              toggleButton('tipsVisible')
              trackTipsVisible()
            }}
          >
            <Icon name='SolidStar01' size='inherit' /> {buttonText['tipsVisible']}
          </button>
        </li>
      )}

      {hasValidStewardsComments && (
        <li>
          <button
            className={buttons.commentsVisible ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-stewards-comments'
            onClick={() => {
              toggleButton('commentsVisible')
              trackCommentsVisible()
            }}
          >
            <Icon name='SolidFile06' size='inherit' /> {buttonText['commentsVisible']}
          </button>
        </li>
      )}

      {!isFixedFlucsDisabled && (
        <li>
          <button
            className={buttons.showFixedFlucs ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-fixed-flucs'
            onClick={() => {
              toggleButton('showFixedFlucs')
              trackShowFixedFlucs()
            }}
          >
            <Icon name='SolidTarget03' size='inherit' /> {buttonText['showFixedFlucs']}
          </button>
        </li>
      )}

      {!isMarketMoversDisabled && (
        <li>
          <button
            className={buttons.showMarketMovers ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-market-movers'
            onClick={() => {
              toggleButton('showMarketMovers')
              trackShowMarketMovers()
            }}
          >
            <Icon name='SolidTrendUp01' size='inherit' /> {buttonText['showMarketMovers']}
          </button>
        </li>
      )}

      {!isDoubleProvDivDisabled && (
        <li>
          <button
            className={buttons.showDoubleProvDiv ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-double-prov-div'
            onClick={() => {
              toggleButton('showDoubleProvDiv')
              trackShowDoubleProvDiv()
            }}
          >
            <Icon name='SolidRows02' size='inherit' /> {buttonText['showDoubleProvDiv']}
          </button>
        </li>
      )}

      {!isQuaddieProvDivDisabled && (
        <li>
          <button
            className={buttons.showQuaddieProvDiv ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-quaddie-prov-div'
            onClick={() => {
              toggleButton('showQuaddieProvDiv')
              trackShowQuaddieProvDiv()
            }}
          >
            <Icon name='SolidLayoutGrid01' size='inherit' /> {buttonText['showQuaddieProvDiv']}
          </button>
        </li>
      )}

      {!isFormDisabled && (
        <li>
          <button
            className={buttons.showAllForm ? LocalConstants.BetInformerActiveClassName : ''}
            data-testid='bet-informer-button-all-form'
            onClick={() => {
              toggleButton('showAllForm')
              trackShowAllForm()
            }}
          >
            <Icon name='SolidBarChart10' size='inherit' /> {buttonText['showAllForm']}
          </button>
        </li>
      )}
    </BetInformersContainerStyled>
  )
}

// ======
// Styles
// ======

const BetInformersContainerStyled = styled.ul({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  minHeight: '5rem',
  maxWidth: '100vw',
  margin: 0,
  padding: 0,
  paddingLeft: spacing.md,
  boxSizing: 'border-box',
  backgroundColor: colors.white,
  borderBottom: '0.05rem solid ' + colors.neutral[200],
  overflowX: 'auto',
  whiteSpace: 'nowrap',

  listStyle: 'none',

  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  scrollbarColor: colors.neutral[300] + ' ' + colors.surface[50],

  '@media (pointer: coarse)': {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': { display: 'none' },
  },

  '> *:last-child': { marginRight: '1rem' },

  '> li': {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '5rem',
  },

  'li > button': {
    boxSizing: 'border-box',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3.2rem',
    padding: '0 1rem',
    border: `0.1rem solid ${colors.neutral[200]}`,
    borderRadius: radius.lgx2,
    WebkitTapHighlightColor: 'transparent',

    fontSize: font.size.md.fontSize,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    fontFamily: font.family.primary,

    backgroundColor: colors.white,
    color: colors.black,
    transition: 'all 0.2s ease',

    '> span': {
      marginRight: '0.5rem',

      '> svg': {
        color: colors.studio[500],
        fontSize: '1.4rem',
      },
    },

    [`&.${LocalConstants.ReplayBtnClassName}`]: {
      '> span > svg': {
        color: colors.bondi[500],
      },
    },

    [`&.${LocalConstants.BetInformerActiveClassName}`]: {
      backgroundColor: colors.studio[500],
      color: colors.white,
      borderColor: colors.studio[500],

      '> span > svg': { color: colors.white },
    },

    [`&.${LocalConstants.BetInformerActiveClassName}.${LocalConstants.ReplayBtnClassName}`]: {
      borderColor: colors.bondi[500],
      backgroundColor: colors.bondi[500],
    },
  },

  [`&.${LocalConstants.SkyRaceCardClassname}`]: {
    paddingLeft: spacing.smx1,
  },
})
