import React from 'react'
import anime from 'animejs'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import {
  selectCanRenderPendingBetsDrawer,
  selectIsPendingBetsDrawerOpen,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'
import {
  setIsPendingBetsDrawerOpen,
  setSkyVideoPlayerStatus,
} from '@core/Areas/SkyVideoPlayer/Store'

const enum LocalConstants {
  MinMoveThreshold = 40,
  XSuccessThreshold = 100,
  YSuccessThreshold = 200,
}

export const useSwipeActions = (
  fullscreenElRef: React.RefObject<HTMLDivElement>,
  pendingBetsElRef: React.RefObject<HTMLDivElement>
) => {
  const dispatch = useAppDispatch()

  const canRenderPendingBetsRef = React.useRef(false)
  const isPendingBetsOpenRef = React.useRef(false)

  const canRenderPendingBets = useAppSelector(selectCanRenderPendingBetsDrawer)
  const isPendingBetsOpen = useAppSelector(selectIsPendingBetsDrawerOpen)

  canRenderPendingBetsRef.current = canRenderPendingBets
  isPendingBetsOpenRef.current = isPendingBetsOpen

  React.useEffect(() => {
    const fullscreenEl = fullscreenElRef.current
    const pendingBetsEl = pendingBetsElRef.current
    if (!fullscreenEl || !pendingBetsEl) return

    let startX = 0
    let startY = 0
    let moveX = 0
    let moveY = 0
    let moveAxis: 'y' | 'x' | null = null
    let moveSign: '-' | '+' | null = null
    let wasYActionSuccessful = false
    let wasXActionSuccessful = false

    // ===========
    // TOUCH START
    // ===========
    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX
      startY = e.touches[0].clientY
      moveX = 0
      moveY = 0
      moveAxis = null
      moveSign = null
      wasYActionSuccessful = false
      wasXActionSuccessful = false
    }

    // ==========
    // TOUCH MOVE
    // ==========
    const handleTouchMove = (e: TouchEvent) => {
      moveX = Math.round(e.touches[0].clientX - startX)
      moveY = Math.round(e.touches[0].clientY - startY)

      const moveXAbs = Math.abs(moveX)
      const moveYAbs = Math.abs(moveY)

      const hasMoveSatisfiedThreshold =
        Math.max(moveXAbs, moveYAbs) > LocalConstants.MinMoveThreshold

      if (wasXActionSuccessful || (!moveAxis && hasMoveSatisfiedThreshold)) return

      moveAxis ??= moveXAbs < moveYAbs ? 'y' : 'x'
      const signEval = moveAxis === 'y' ? moveYAbs / moveY : moveXAbs / moveX
      const currMoveSign = Math.sign(signEval) == -1 ? '-' : '+'
      moveSign ??= currMoveSign

      if (currMoveSign !== moveSign) return

      // Handle sky fullscreen video player swipe move
      if (moveAxis === 'y' && !isPendingBetsOpenRef.current) {
        e.preventDefault()

        const movePosition = moveYAbs - LocalConstants.MinMoveThreshold
        anime.set(fullscreenEl, { translateY: `${moveSign}${movePosition}px` })
        wasYActionSuccessful =
          moveYAbs > Math.min(LocalConstants.YSuccessThreshold, innerHeight / 2)
        return
      }

      // Handle pending bets drawer swipe actions
      if (
        moveAxis === 'x' &&
        canRenderPendingBetsRef.current &&
        moveXAbs > LocalConstants.XSuccessThreshold
      ) {
        wasXActionSuccessful = true
        e.preventDefault()

        if (isPendingBetsOpenRef.current && moveSign === '+') {
          dispatch(setIsPendingBetsDrawerOpen(false))
          return
        }

        if (!isPendingBetsOpenRef.current && moveSign === '-') {
          dispatch(setIsPendingBetsDrawerOpen(true))
          return
        }
      }
    }

    // =========
    // TOUCH END
    // =========
    const handleTouchEnd = () => {
      if (wasYActionSuccessful) {
        // Animate out, change player status
        anime({
          targets: fullscreenEl,
          translateY: [
            `${moveY + LocalConstants.MinMoveThreshold * (moveSign === '-' ? 1 : -1)}px`,
            `${moveSign}${innerHeight * 1.5}px`,
          ],
          duration: 200,
          easing: 'cubicBezier(0.3, 0.0, 0.8, 0.15)',
        })
        setTimeout(() => dispatch(setSkyVideoPlayerStatus({})), 300)
      } else {
        // Cancelled swipe, reset fullscreen component position
        anime({
          targets: fullscreenEl,
          translateY: '0px',
          duration: 150,
          easing: 'cubicBezier(0.4, 0.0, 0.2, 1)',
        })
      }
    }

    fullscreenEl.addEventListener('touchstart', handleTouchStart, { passive: true })
    fullscreenEl.addEventListener('touchmove', handleTouchMove, { passive: false })
    fullscreenEl.addEventListener('touchend', handleTouchEnd, { passive: true })
    return () => {
      fullscreenEl.removeEventListener('touchstart', handleTouchStart)
      fullscreenEl.removeEventListener('touchmove', handleTouchMove)
      fullscreenEl.removeEventListener('touchend', handleTouchEnd)
    }
  }, [fullscreenElRef, pendingBetsElRef, dispatch])
}
