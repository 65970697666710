import { reportError } from '@mobi/utils'
import { isPaymentProcessorErrorCode } from './braintree'

type Params = {
  errorCode?: string
  message: string
}

export function reportErrorIfNeeded({ errorCode, message }: Params) {
  if (!errorCode || (errorCode !== 'validation_error' && !isPaymentProcessorErrorCode(errorCode))) {
    reportError('deposit', message)
  }
}
