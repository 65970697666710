export const RangeBoundaries = {
  minDaysSinceLastStart: 1,
  maxDaysSinceLastStart: 60,
  minDaysSinceLastWin: 1,
  maxDaysSinceLastWin: 60,
  minStartsSinceLastSpell: 1,
  maxStartsSinceLastSpell: 60,
  minPercentage: 0,
  maxPercentage: 100,
  minFixedOddsPrice: 1,
  maxFixedOddsPrice: 30,
}
