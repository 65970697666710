import React from 'react'
import type { ToteSelection, AllUpSelection, Acceptor, Race } from '@mobi/betslip/types'
import {
  NoveltyListStyled,
  NoveltyPlaceStyled as Place,
  NoveltyStarterStyled as Starter,
} from './Selection.styles'
import { parseMultiLegSelectionString, parseAllUpSelections } from './helpers'
import { PlaceTextStyled } from '../Selection/NoveltySelection'

interface MultiRaceSelectionProps {
  selection: ToteSelection | AllUpSelection
  races: Race[]
  acceptors: Acceptor[]
  displayPill: boolean
}

export function MultiRaceSelection({
  selection,
  races,
  acceptors,
  displayPill,
}: MultiRaceSelectionProps) {
  const parsed =
    selection.betType === 'All Up'
      ? parseAllUpSelections(selection as AllUpSelection, races, acceptors)
      : parseMultiLegSelectionString(selection as ToteSelection, races, acceptors)
  return (
    <NoveltyListStyled data-tid-multi-selection=''>
      {Object.keys(parsed).map(key => [
        <Place key={`__${key}`}>
          <PlaceTextStyled text={key} displayPill={displayPill} />
        </Place>,
        <Starter key={key}>{parsed[key]}</Starter>,
      ])}
    </NoveltyListStyled>
  )
}
