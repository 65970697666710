import React, { forwardRef, type InputHTMLAttributes, type ComponentPropsWithRef } from 'react'
import { Icon, type IconType } from '../Icon'
import {
  InputBoxStyled,
  InputLabelStyled,
  InputPasswordVisibilityStyled,
  ValidationErrorStyled,
} from './InputField.styles'

type Props = {
  /**
   * The label of the input.
   *
   * This is a remnant of older code where this attribute was used incorrectly. To set the name of the input, use `inputName` instead.
   */
  name?: string
  /**
   * The name of the input.
   */
  inputName?: string
  /**
   * The error message in case of validation errors.
   *
   * Set to `false` or `undefined` to hide.
   */
  errorMessage?: string | boolean
  /**
   * The test-id of the input.
   *
   * Setting this property will apply `data-tid-{testId}` to the input itself and `data-tid-{testId}-error to the error, if present.
   * Use of this attribute is discouraged and should only be used where using other matchers make no sense, such as with highly dynamic text where text/role matching can't work.
   *
   * See https://testing-library.com/docs/queries/about#priority
   */
  testId?: string
  /**
   * The event handler to invoke when the addon has been clicked.
   */
  handleAddOnAction?(): void
  /**
   * The icon to show in the addon on the far right of the input field.
   *
   * Leaving this blank will hide the addon.
   */
  addOnIcon?: IconType
  /**
   * The aria-label attribute for the addon.
   */
  addonAriaLabel?: string
} & InputHTMLAttributes<HTMLInputElement>

export type InputFieldProps = ComponentPropsWithRef<typeof InputField>

export const InputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      inputName,
      type,
      onChange,
      required,
      errorMessage,
      testId,
      handleAddOnAction,
      addOnIcon,
      addonAriaLabel,
      ...inputProps
    }: Props,
    ref
  ): JSX.Element => {
    const inputTestId = testId ? { [`data-tid-${testId}`]: '' } : {}
    const errorTestId = testId ? { [`data-tid-${testId}-error`]: '' } : {}

    // nameProp gets destructured, so we can prevent a ton of snapshots
    // needing updating for a prop they don't use (but should)
    const nameProp = inputName ? { name: inputName } : {}

    const inputType: HTMLInputElement['type'] =
      type === 'password' && addOnIcon === 'visibilityoff' ? 'text' : type || 'text'

    return (
      <div>
        <InputLabelStyled htmlFor={name}>
          {name}
          <span>{required ? '*' : null}</span>
        </InputLabelStyled>

        <InputPasswordVisibilityStyled>
          <InputBoxStyled
            ref={ref}
            {...inputProps}
            {...nameProp}
            type={inputType}
            id={name}
            onChange={onChange}
            required={required}
            error={errorMessage}
            {...inputTestId}
          />
          {handleAddOnAction && (
            <button type='button' onClick={handleAddOnAction} aria-label={addonAriaLabel}>
              <Icon type={addOnIcon} />
            </button>
          )}
        </InputPasswordVisibilityStyled>

        {errorMessage && (
          <ValidationErrorStyled {...errorTestId}>{errorMessage}</ValidationErrorStyled>
        )}
      </div>
    )
  }
)

InputField.displayName = 'InputField'
