import { darken, lighten, transparentize } from 'polished'
import { hexColors } from '@mobi/settings'

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'light'
  | 'dark'
  | 'muted'
  | 'danger'
  | 'link'
  | 'tab'
  | 'tab_invert'

export type ButtonTypes = Record<ButtonColor, ButtonType>

// We lock down the signature of the button object to ensure that all buttons share the
// same properties to assign a color to. This enables us to safely use it as follows:
// `colors.buttons[color]`
type ButtonType = {
  text: string
  background: string
  gradientBackgroundTop?: string
  gradientBackgroundBottom?: string
  border: string
  hover: {
    background: string
  }
  selected: {
    background: string
    text?: string
    border?: string
    hover: {
      background: string
    }
  }
  disabled: {
    text?: string
    background: string
  }
}

export const buttonColors: ButtonTypes = {
  primary: {
    text: hexColors.white,
    background: hexColors.sushi,
    border: 'transparent',
    hover: {
      background: darken(0.1, hexColors.sushi),
    },
    selected: {
      background: darken(0.2, hexColors.sushi),
      hover: {
        background: darken(0.3, hexColors.sushi),
      },
    },
    disabled: {
      background: lighten(0.3, hexColors.sushi),
    },
  },
  secondary: {
    text: hexColors.nightRider,
    background: hexColors.white,
    border: hexColors.veryLightGrey,
    hover: {
      background: hexColors.whiteSmoke,
    },
    selected: {
      background: darken(0.2, hexColors.whiteSmoke),
      hover: {
        background: darken(0.3, hexColors.whiteSmoke),
      },
    },
    disabled: {
      text: lighten(0.3, hexColors.nightRider),
      background: lighten(0.3, hexColors.whiteSmoke),
    },
  },
  light: {
    text: hexColors.black,
    background: hexColors.white,
    border: 'transparent',
    hover: {
      background: darken(0.1, hexColors.white),
    },
    selected: {
      background: darken(0.2, hexColors.white),
      hover: {
        background: darken(0.3, hexColors.white),
      },
    },
    disabled: {
      background: darken(0.2, hexColors.white),
    },
  },
  dark: {
    text: hexColors.white,
    background: hexColors.nero,
    border: 'transparent',
    hover: {
      background: hexColors.charCoal,
    },
    selected: {
      background: lighten(0.1, hexColors.charCoal),
      hover: {
        background: lighten(0.2, hexColors.charCoal),
      },
    },
    disabled: {
      background: lighten(0.2, hexColors.charCoal),
    },
  },
  muted: {
    text: hexColors.black,
    background: hexColors.whiteSmoke,
    border: 'transparent',
    hover: {
      background: darken(0.1, hexColors.whiteSmoke),
    },
    selected: {
      background: darken(0.2, hexColors.whiteSmoke),
      hover: {
        background: darken(0.3, hexColors.whiteSmoke),
      },
    },
    disabled: {
      background: darken(0.2, hexColors.whiteSmoke),
    },
  },
  danger: {
    text: hexColors.white,
    background: hexColors.freeSpeechRed,
    border: 'transparent',
    hover: {
      background: darken(0.1, hexColors.freeSpeechRed),
    },
    selected: {
      background: darken(0.2, hexColors.freeSpeechRed),
      hover: {
        background: darken(0.3, hexColors.freeSpeechRed),
      },
    },
    disabled: {
      text: transparentize(0.15, hexColors.charCoal),
      background: hexColors.veryLightGrey,
    },
  },
  link: {
    text: hexColors.studio,
    background: 'transparent',
    border: 'transparent',
    hover: {
      background: lighten(0.1, hexColors.gainsboro),
    },
    selected: {
      background: lighten(0.2, hexColors.gainsboro),
      hover: {
        background: lighten(0.3, hexColors.gainsboro),
      },
    },
    disabled: {
      text: transparentize(0.15, hexColors.charCoal),
      background: hexColors.veryLightGrey,
    },
  },
  tab: {
    text: hexColors.white,
    background: hexColors.blueDiamond,
    border: 'transparent',
    hover: {
      background: lighten(0.2, hexColors.blueDiamond),
    },
    selected: {
      background: darken(0.1, hexColors.blueDiamond),
      hover: {
        background: darken(0.1, hexColors.blueDiamond),
      },
    },
    disabled: {
      background: hexColors.darkGrey,
    },
  },
  tab_invert: {
    text: hexColors.blueDiamond,
    background: hexColors.white,
    border: 'transparent',
    hover: {
      background: darken(0.2, hexColors.white),
    },
    selected: {
      background: darken(0.1, hexColors.white),
      hover: {
        background: darken(0.1, hexColors.white),
      },
    },
    disabled: {
      background: hexColors.darkGrey,
    },
  },
}
