import { ConfirmBet } from './signals'
import { state$ as quickbetState$ } from './driver'
import { post } from '@classic/Foundation/Services/ApiService'
import { QUICKBET_MODAL_ID } from './constants'

interface BettingConfirmationLogRequest {
  betTransactionId: string | null
  snapshot: string
  timestamp: Date
}

export function register(): { dispose(): void } {
  const confirmBetSubscription = ConfirmBet.signal$
    .withLatestFrom(quickbetState$, (confirmBet, quickbetState) => ({ confirmBet, quickbetState }))
    .subscribe(x => {
      const request: BettingConfirmationLogRequest = {
        betTransactionId: x.quickbetState.id,
        snapshot: window.btoa(getSnapshot()),
        timestamp: new Date(),
      }
      post<{}>({ url: '/api/betting/log/confirmation', body: request })
    })
  return { dispose: () => confirmBetSubscription.dispose() }
}

function getSnapshot(): string {
  return encodeURIComponent(
    document.getElementById(QUICKBET_MODAL_ID)?.innerText.replace(/[\r\n\t]+/gm, '|') || ''
  )
    .replace(/%7C/g, ' | ')
    .replace(/%20/g, ' ')
    .replace(/%24/g, '$')
    .replace(/%([0-9A-F]{2})/g, '')
}
