import styled from '@emotion/styled'
import { colors } from '@core/Settings'
import { Button } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { hexColors } from '@mobi/settings'

export const SearchFieldStyled = styled('div')({
  marginLeft: '1rem',
  color: colors.search.container.text,
  backgroundColor: colors.search.container.background,
  display: 'flex',
  flex: '1',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem 0.75rem',
  borderRadius: '0.35em',
})

export const SearchInputStyled = styled('input')({
  backgroundColor: 'transparent',
  border: 'none',
  margin: '0 1rem',
  padding: '0.5rem 0',
  fontSize: '1.4rem',
  color: colors.search.input.text,
  width: '100%',
  '&::placeholder': {
    color: colors.search.input.placeholder,
  },
  '&:focus': {
    outline: 'none',
  },
  type: 'search',
})

export const SearchClearButtonStyled = styled(Button)({
  backgroundColor: 'transparent',
  border: 'none',
  color: hexColors.white,
  fontWeight: 'normal',
  paddingRight: '0',
  fontSize: '1.4rem',
  '&:focus': {
    backgroundColor: 'transparent',
  },

  '&:active': {
    backgroundColor: 'transparent',
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
})

export const SearchIcon = styled(Icon)({
  color: colors.search.input.placeholder,
})

export const SearchFormContainerStyled = styled('form')({
  display: 'contents',

  // adapted from MeetingsDropdown.tsx
  position: 'relative',

  '@keyframes animateIn': {
    from: { transform: 'translateY(2rem)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },

  '& > div': {
    backgroundColor: hexColors.grey1,
    borderRadius: '0.5rem',
    boxShadow: '0 0.5rem 0.3rem rgba(0, 0, 0, 0.25)',
    color: 'black',
    overflow: 'hidden',
    position: 'absolute',
    top: '100%',
    animation: 'ease animateIn .2s',
    transform: 'translateY(0)',
    zIndex: 5,

    padding: '1rem',
    overflowY: 'auto',
    width: '50vw',
    margin: '0px',
    maxWidth: '60rem',
    left: '4rem',

    ul: {
      listStyle: 'none',
      margin: 0,
      maxHeight: '70vh',
      padding: '0',
      top: '5rem',

      li: {
        alignItems: 'center',
        display: 'flex',
        lineHeight: 1,
        height: '3rem',
        whiteSpace: 'nowrap',

        'span.title': {
          color: hexColors.nero,
          fontWeight: 'bold',
        },

        button: {
          flex: 1,
          paddingLeft: 0,
          textAlign: 'left',
          fontSize: '1.4rem',
          fontWeight: 'normal',
          color: hexColors.nero,
          backgroundColor: hexColors.grey1,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          borderWidth: '0',
        },
      },
    },
  },
})
